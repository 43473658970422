import { gql } from 'graphql-tag';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { getExportPayablesDownloadQueryKey } from './query-key-registry';

export type ExportPayablesDownloadVariables = {
  id: string;
};

export type ExportPayablesDownloadResponse = {
  bookkeepingExport:
    | {
        id: string;
        payablesDownload: {
          id: string;
          fileExport: { id: string; url: string } | null;
          status: 'created' | 'pending' | 'failed' | 'completed';
        } | null;
      }
    | undefined;
};

type FileExportPollingStatus =
  | {
      isPolling: false;
      exportId: undefined;
      showFileExportSuccessPage: false;
    }
  | {
      isPolling: boolean;
      exportId: string;
      showFileExportSuccessPage: boolean;
    };

const query = gql`
  query GetExport($id: ID!) {
    bookkeepingExport(id: $id) {
      id
      payablesDownload {
        id
        status
        fileExport {
          id
          url
        }
      }
    }
  }
`;

export const useExportPayablesDownload = ({
  isPolling,
  exportId,
}: FileExportPollingStatus): QueryState<ExportPayablesDownloadResponse> => {
  const companyId = useCompanyId();

  return useQuery<
    ExportPayablesDownloadResponse,
    ExportPayablesDownloadResponse
  >({
    key: getExportPayablesDownloadQueryKey(companyId),
    request: {
      type: 'graphQL',
      target: 'v2',
      query,
      variables: { id: exportId },
    },
    isEnabled: isPolling && Boolean(exportId),
    options: {
      refetchInterval: () => (isPolling ? 3000 : false),
    },
    reshapeData: (body) => body,
  });
};
