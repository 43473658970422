import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type QueryState } from 'src/core/api/queryState';

import { useFetchWalletDetailQuery } from '.';

export const useAreScheduledTransfersIncludedInWallet =
  (): QueryState<boolean> => {
    const companyId = useCompanyId();
    const useFetchWalletDetailQueryState = useFetchWalletDetailQuery(companyId);

    if (useFetchWalletDetailQueryState.status === 'success') {
      return {
        status: 'success',
        data: Boolean(
          useFetchWalletDetailQueryState.data.walletSummary.walletDetails
            .scheduledTransfers,
        ),
      };
    }

    return useFetchWalletDetailQueryState;
  };
