import { useMutation } from '@apollo/react-hooks';
import { type QueryClient, useQueryClient } from 'react-query';

import {
  type AccountingSoftware,
  hasExternalConnection,
  toAccountingSoftwareGraphQl,
} from 'modules/bookkeep/integration/status';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import {
  reshapeDefaultEmployeeAccounts,
  reshapeDefaultSupplierAccounts,
  reshapeEmployeeAccount,
} from './mappers';
import {
  ASSIGN_DEFAULT_EMPLOYEE_ACCOUNT_TO_MEMBER,
  SET_ACCOUNTING_INTEGRATION,
  SWITCH_ACCOUNTING_INTEGRATION,
} from './mutations';
import {
  GET_DEFAULT_EMPLOYEE_ACCOUNTS,
  GET_DEFAULT_SUPPLIER_ACCOUNTS,
  GET_EMPLOYEE_ACCOUNT,
  GET_PAYABLES_COUNT,
} from './queries';
import { type PayableState } from '../../../graphql/mappers/payableState';
import { usePaginatedQuery } from '../../../hooks/usePaginatedQuery';
import { useQuery } from '../../../hooks/useQuery';
import { useBuildConsentUrlMutation } from '../../integrations/hooks/useBuildConsentUrlMutation';
import { useBuildExternalConsentUrlMutation } from '../../integrations/hooks/useBuildExternalConsentUrlMutation';
import { useGetOauth1ConsentUrlMutation } from '../../integrations/hooks/useGetOauth1ConsentUrlMutation';

export type Oauth1TokenSet = {
  accountId: string;
  consumerId: string;
  consumerSecret: string;
};

export const useGetDefaultEmployeeAccountsQuery = () => {
  const companyId = useCompanyId();

  return usePaginatedQuery(
    GET_DEFAULT_EMPLOYEE_ACCOUNTS,
    {
      variables: { companyId },
    },
    {
      reshapeData: reshapeDefaultEmployeeAccounts,
    },
    'company.chartOfAccounts.employeeAccounts',
  );
};

export const useGetDefaultSupplierAccountsQuery = () => {
  const companyId = useCompanyId();

  return usePaginatedQuery(
    GET_DEFAULT_SUPPLIER_ACCOUNTS,
    {
      variables: { companyId },
    },
    {
      reshapeData: reshapeDefaultSupplierAccounts,
    },
    'company.chartOfAccounts.supplierAccounts',
  );
};

export const useAssignDefaultEmployeeAccountToMemberMutation = () => {
  const companyId = useCompanyId();

  const [assignDefaultEmployeeAccountToMemberMutation] = useMutation(
    ASSIGN_DEFAULT_EMPLOYEE_ACCOUNT_TO_MEMBER,
  );

  return async (memberId: string): Promise<void> => {
    const { data } = await assignDefaultEmployeeAccountToMemberMutation({
      variables: {
        companyId,
        memberId,
      },
    });

    if (data.setDefaultEmployeeAccountToMember.reason) {
      throw new Error(data.setDefaultEmployeeAccountToMember.reason);
    }
  };
};

export const useGetEmployeeAccountQuery = (userId: string) => {
  const companyId = useCompanyId();

  return usePaginatedQuery(
    GET_EMPLOYEE_ACCOUNT,
    {
      variables: { companyId, userId },
    },
    {
      reshapeData: reshapeEmployeeAccount,
    },
    'company.chartOfAccounts.employeeAccounts',
  );
};

export const useSetAccountingIntegrationMutation = ({
  isAccountingSwitch,
}: {
  isAccountingSwitch?: boolean;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const companyId = useCompanyId();

  const [setAccountingIntegration] = useMutation(
    isAccountingSwitch
      ? SWITCH_ACCOUNTING_INTEGRATION
      : SET_ACCOUNTING_INTEGRATION,
  );

  const queryClient = useQueryClient();

  const [buildConsentUrl] = useBuildConsentUrlMutation();

  const [buildOauth1ConsentUrl] = useGetOauth1ConsentUrlMutation();

  const [buildExternalConsentUrl] = useBuildExternalConsentUrlMutation();

  return async (
    accountingSoftware: AccountingSoftware,
    tokenSet?: Oauth1TokenSet,
  ): Promise<{
    error: string | undefined;
    errorDetail?: string | undefined;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }> => {
    const { data } = await setAccountingIntegration({
      variables: {
        companyId,
        accountingSoftware: toAccountingSoftwareGraphQl(accountingSoftware),
      },
      refetchQueries: ['GetActiveAccountingIntegration'],
      awaitRefetchQueries: true,
    });

    const reason = isAccountingSwitch
      ? data.switchAccountingIntegration.reason
      : data.setAccountingIntegration.reason;
    const errorDetail = data?.switchAccountingIntegration?.error;

    if (!reason) {
      // Redirecting to login for all integrations that have it
      if (hasExternalConnection(accountingSoftware)) {
        if (accountingSoftware === 'Netsuite' && tokenSet) {
          try {
            window.location.assign(
              // eslint-disable-next-line unicorn/no-await-expression-member
              (await buildOauth1ConsentUrl(tokenSet)).url,
            );
          } catch {
            invalidateRequiredQueries(queryClient);

            return {
              error: 'oauth1Error',
            };
          }
        }

        if (['Sage100', 'ACD'].includes(accountingSoftware)) {
          await buildExternalConsentUrl();
        }

        await buildConsentUrl();
      } else {
        invalidateRequiredQueries(queryClient);
      }
    }

    return {
      error: reason,
      errorDetail,
    };
  };
};

const invalidateRequiredQueries = (queryClient: QueryClient) => {
  [
    'useIntegrationStatusQuery',
    'useActiveAccountingIntegration',
    'useAccountingIntegrationHistory',
    'useExpenseAccountsQuery',
    'useTaxAccountsQuery',
    'useEmployeeAccountCodesQuery',
  ].forEach((key) => queryClient.invalidateQueries(key));
};

export const useGetPayablesCount = (
  filter: {
    state: PayableState[];
    expenseAccounts?: { ids: string[] };
  },
  isEnabled?: boolean,
) => {
  const companyId = useCompanyId();

  return useQuery<number, unknown>(
    GET_PAYABLES_COUNT,
    { variables: { companyId, filter }, skip: isEnabled === false },
    {
      reshapeData: (data: { company: { payables: { totalCount: number } } }) =>
        data.company.payables.totalCount,
    },
  );
};
