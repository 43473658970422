/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';

import { type LogContext } from '../core/common/components/withErrorBoundary';

type MessageContext = LogContext & Record<string, unknown>;

export const logger = {
  debug: (message: string, messageContext: MessageContext) => {
    if (process.env.REACT_APP_LOG_TRACKING_EVENT) {
      console.log('datadog debug log: ', message, messageContext);
    }
    return datadogLogs.logger.debug(message, messageContext);
  },
  info: (message: string, messageContext: MessageContext) => {
    if (process.env.REACT_APP_LOG_TRACKING_EVENT) {
      console.log('datadog info log: ', message, messageContext);
    }
    return datadogLogs.logger.info(message, messageContext);
  },
  warn: (message: string, messageContext: MessageContext) => {
    if (process.env.REACT_APP_LOG_TRACKING_EVENT) {
      console.log('datadog warn log: ', message, messageContext);
    }
    datadogLogs.logger.warn(message, messageContext);
  },
  error: (message: string, messageContext: MessageContext) => {
    if (process.env.REACT_APP_LOG_TRACKING_EVENT) {
      console.log('datadog error log: ', message, messageContext);
    }
    return datadogLogs.logger.error(message, messageContext);
  },
};
