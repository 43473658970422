import { useFormik } from 'formik';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';

import { useCreateSupplier, useUpdateSupplier } from '../api';

type Props = {
  supplier?: {
    id: string;
    name: string;
    country?: string;
    vatNumber?: string;
  };
  onSupplierSelected: (supplier: { id: string; name: string }) => void;
  shouldDisplayDPRInformations: boolean | undefined;
};

export const useSupplierEditForm = ({
  supplier,
  onSupplierSelected,
  shouldDisplayDPRInformations,
}: Props) => {
  const { t } = useTranslation('global');
  const { successNotif } = useNotifications();

  const [
    createSupplier,
    createSupplierQueryState,
    resetCreateSupplierQueryState,
  ] = useCreateSupplier({
    onSupplierCreationSuccess: () => {
      successNotif(t('suppliers.updateSuccess'));
    },
  });

  const [
    updateSupplier,
    updateSupplierQueryState,
    resetUpdateSupplierQueryState,
  ] = useUpdateSupplier(supplier?.id ?? '', {
    onSupplierUpdateSuccess: () => {
      successNotif(t('suppliers.updateSuccess'));
    },
  });

  const resetQueryStates = () => {
    resetCreateSupplierQueryState();
    resetUpdateSupplierQueryState();
  };

  const formikProps = useFormik<{
    newSupplierName?: string;
    vatNumber?: string;
    country?: string;
  }>({
    initialValues: {
      vatNumber: supplier?.vatNumber,
      country: supplier?.country,
      newSupplierName: undefined,
    },
    onSubmit: async (values) => {
      resetQueryStates();
      try {
        if (supplier?.id) {
          await updateSupplier({
            id: supplier?.id,
            address: { country: values.country },
            legalInfos: {
              vatNumber: values.vatNumber,
            },
          });
          return;
        }

        if (values.newSupplierName?.length) {
          const newSupplier = await createSupplier({
            name: values.newSupplierName,
            address: { country: values.country },
            legalInfos: {
              legalName: values.newSupplierName,
              vatNumber: values.vatNumber,
            },
            isArchived: false,
            isSupplierValidated: false,
          });
          onSupplierSelected(newSupplier);
        }
      } catch {
        // handled by the query state
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validate: async (values) => {
      if (!supplier && !values.newSupplierName) {
        return { newSupplierName: 'required' };
      }
      if (
        shouldDisplayDPRInformations &&
        (!values.country?.length || !values.vatNumber?.length)
      ) {
        return {
          country: !values.country?.length && 'required',
          vatNumber: !values.vatNumber?.length && 'required',
        };
      }
    },
  });

  return {
    formikProps: {
      ...formikProps,
      errors: formikProps.errors,
    },
    createSupplierQueryState,
    updateSupplierQueryState,
  };
};
