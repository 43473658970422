export enum PayableState {
  Created = 'created',
  Prepared = 'prepared',
  Unprepared = 'unprepared',
  Discarded = 'discarded',
  ToAccountingPending = 'to_accounting_pending',
  ToAccountingFailed = 'to_accounting_failed',
  InAccounting = 'in_accounting',
  InAccountingManually = 'in_accounting_manually',
}

export const unexportedPayableStates = [
  PayableState.Prepared,
  PayableState.ToAccountingFailed,
];

export const exportedPayableStates = [
  PayableState.InAccounting,
  PayableState.InAccountingManually,
];

export const unpreparedPayableStates = [
  PayableState.Created,
  PayableState.Unprepared,
];
