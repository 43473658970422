import { useUserId } from 'modules/app/hooks/useUserId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type UpdateProfilePayload = Partial<{
  display_gender: string;
  first_name: string;
  last_name: string;
  lang: string;
  gender: string;
  birth_date?: string;
  birth_location_city?: string;
  birth_location_country_code?: string;
  email?: string;
}>;

type UpdateProfileError = {
  error:
    | 'email_update_not_allowed'
    | 'not_account_owner'
    | 'sso_or_saml_enabled'
    | 'sfs_kyc_review_not_started'
    | 'sfs_kyc_review_already_completed';
};

type Props = {
  onError?: () => void;
};
export const useUpdateProfileMutation = ({
  onError,
}: Props = {}): MutationState<
  UpdateProfilePayload,
  object,
  UpdateProfileError
> => {
  const userId = useUserId();

  return useMutation<UpdateProfilePayload, object, object, UpdateProfileError>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: `/users/${userId}`,
    },
    reshapeData(data) {
      return data;
    },
    options: {
      ...(onError ? { onError } : {}),
    },
  });
};
