import {
  Button,
  DATE_FORMAT,
  DropdownItem,
  Popover,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { type LocaleFormat, useTranslation } from 'common/hooks/useTranslation';

import styles from './BudgetPeriodSelector.module.css';
import { BudgetPeriodSelectorForm } from './BudgetPeriodSelectorForm';
import { type PeriodRange } from '../../models/period';

type Props = {
  range: PeriodRange;
  maxRange: { from: Date; to: Date };
  onChange: (range: PeriodRange) => void;
};

export const BudgetPeriodSelector = ({ onChange, range, maxRange }: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const options = [
    {
      key: 'currentMonth',
      label: t('budget.breakdownSpendings.periods.currentMonth'),
    },
    {
      key: 'previousMonth',
      label: t('budget.breakdownSpendings.periods.previousMonth'),
    },
    {
      key: 'currentQuarter',
      label: t('budget.breakdownSpendings.periods.currentQuarter'),
    },
    {
      key: 'previousQuarter',
      label: t('budget.breakdownSpendings.periods.previousQuarter'),
    },
    {
      key: 'overall',
      label: t('budget.breakdownSpendings.periods.overall'),
    },
    {
      key: 'custom',
      label: t('budget.breakdownSpendings.periods.customForm'),
    },
  ] satisfies { key: PeriodRange['key']; label: string }[];

  return (
    <div data-testid="BudgetPeriodSelector">
      <Popover
        renderTrigger={(triggerProps) => (
          <Button
            {...triggerProps}
            text={rangeToLabel(range, options, localeFormat)}
            iconName="caret-down"
            iconPosition="right"
            variant="contrasted"
            className={classNames('w-[200px]', styles.button)}
          />
        )}
      >
        {(onClose) => {
          return (
            <div className="flex-col">
              {options.map((option) => (
                <>
                  {option.key === 'custom' ? (
                    <BudgetPeriodSelectorForm
                      onChange={({ from, to }) => {
                        onChange({ key: 'custom', from, to });
                        onClose();
                      }}
                      selectedDateRange={
                        range.key === 'custom'
                          ? { from: range.from, to: range.to }
                          : undefined
                      }
                      maxRange={maxRange}
                    />
                  ) : (
                    <button
                      type="button"
                      className="block w-full rounded-xs border-none bg-white"
                      onClick={() => {
                        onChange({ key: option.key });
                        onClose();
                      }}
                    >
                      <DropdownItem label={option.label} />
                    </button>
                  )}
                </>
              ))}
            </div>
          );
        }}
      </Popover>
    </div>
  );
};

const rangeToLabel = (
  range: PeriodRange,
  options: { key: string; label: string }[],
  localeFormat: LocaleFormat,
): string => {
  if (range.key === 'custom') {
    return `${localeFormat(range.from, DATE_FORMAT.CUSTOM, {
      month: 'short',
      year: 'numeric',
    })} - ${localeFormat(range.to, DATE_FORMAT.CUSTOM, {
      month: 'short',
      year: 'numeric',
    })}`;
  }
  return options.find((option) => option.key === range.key)?.label ?? '';
};
