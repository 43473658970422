import { Skeleton, SkeletonAvatar, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const PaymentsListSectionLoader = () => {
  return (
    <div className="h-[500px] w-[800px]">
      <div className="mb-s flex items-center gap-s">
        <SkeletonAvatar variant="square" />
        <div className="flex flex-col gap-xs">
          <SkeletonText width="310px" />
          <Skeleton height="var(--sizing-xs)" width="61px" />
        </div>
      </div>
      {Array.from({ length: 5 }, (_, key) => (
        <div
          className="flex items-center justify-between border-0 border-b border-solid border-neutral-lighter py-s"
          key={key}
        >
          <div className="flex items-center gap-s">
            <SkeletonAvatar />
            <div className="flex flex-col gap-xs">
              <SkeletonText width="100px" />
              <Skeleton height="var(--sizing-xs)" width="61px" />
            </div>
          </div>
          <SkeletonText width="75px" />
          <SkeletonText width="58px" />
          <SkeletonText width="98px" />
        </div>
      ))}
    </div>
  );
};
