import { useQueryClient } from 'react-query';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { logger } from 'src/utils/datadog-log-wrapper';

import { useProcedure } from './useProcedure';

type Payload = object;

type Response = {
  procedureId: string;
};

const getKycUrl = (
  bankingProvider: 'bankable' | 'marqeta' | 'sfs',
  id: string,
) => (bankingProvider === 'sfs' ? `/kyc/${id}` : `/onboarding-v2/${id}`);

export const useStartOrContinueProcedure = () => {
  const company = useCompany();
  const { id: companyId, banking_provider: bankingProvider } = company;

  const procedure = useProcedure();
  const queryClient = useQueryClient();

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      queryClient.invalidateQueries(['procedure', companyId]);
      queryClient.invalidateQueries(['kybStatus', companyId]);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  };

  const [initiateKyc] = useMutation<Payload, Response, Response>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/onboarding/kyc/initiate',
    },
    reshapeData: (data) => data,
  });

  return async () => {
    if (!bankingProvider) {
      logger.error(
        'Cannot start or continue KYC procedure when the company has no banking provider',
        {
          scope: 'self-serve',
          team: 'growth',
          companyId,
        },
      );
      return;
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    if (procedure) {
      const onboardingLink = getKycUrl(bankingProvider, companyId);
      window.open(onboardingLink, '_blank');
      return;
    }

    await initiateKyc({});
    const onboardingLink = getKycUrl(bankingProvider, companyId);
    window.open(onboardingLink, '_blank');
  };
};
