import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import type { QueryState } from 'src/core/api/queryState';

import type { WalletAccountsHistory } from '../wallet-accounts-history';

export type GetWalletAccountsResponse = WalletAccountsHistory;

export type RawWalletAccountsHistory = {
  id: string;
  currency: string;
  version: string;
  active: boolean;
  bank_name: string | null;
  banking_provider: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}[];

export const useGetWalletAccountsHistoryQuery = (
  isEnabled = true,
): QueryState<WalletAccountsHistory> => {
  const companyId = useCompanyId();

  return useQuery<WalletAccountsHistory, RawWalletAccountsHistory>({
    key: ['wallet-accounts-history', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/accounts/history`,
    },
    isEnabled,
    reshapeData: (data) => {
      // Object.values because when using mock the array is turned into an object { 0: <...>, 1: <...>, <...> }
      return Object.values(data).map((account) => ({
        id: account.id,
        currency: account.currency as WalletAccountsHistory[number]['currency'],
        bankName: account.bank_name,
        version: account.version as WalletAccountsHistory[number]['version'],
        active: account.active,
        bankingProvider:
          account.banking_provider as WalletAccountsHistory[number]['bankingProvider'],
        createdAt: new Date(account.created_at),
        updatedAt: new Date(account.updated_at),
        deletedAt: account.deleted_at ? new Date(account.deleted_at) : null,
      }));
    },
  });
};
