// That's basically a promisified version of `setTimeout`
export const wait = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

type PollStrategy = (previous: number, iteration: number) => number;

export const POLL_STRATEGIES: { [name: string]: PollStrategy } = {
  SQUARE: (previous: number, iteration: number): number =>
    previous + iteration ** 2,
  LINEAR: (previous: number): number => previous,
};

type PollOptions<
  TResult,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TFunction extends (...params: any[]) => Promise<TResult>,
> = {
  fn: TFunction;
  fnParams: Parameters<TFunction>;
  predicate: (data: TResult) => boolean;
  initialWaitDuration?: number;
  timeStrategy?: PollStrategy;
  maxIterations?: number;
};

/**
 * Polls the `fn` function with `fnParams`, according to `initialWaitDuration`
 * and `timeStrategy`, until its resolved value satisfies the `predicate`
 * function.
 *
 * @param options
 * @param options.fn An async function to poll.
 * @param options.fnParams An array of parameters to pass to `fn` at each iteration.
 * @param options.predicate A function which will be called at each iteration with the resolved value of
 * `fn`. It should return `true` if the value is satisfaying and `false` otherwise.
 * @param options.initialWaitDuration A duration in milliseconds which will be passed to `timeStrategy` on
 * first iteration.
 * @param options.timeStrategy A function which will be called at each iteration to
 * determine the duration to wait for before executing `fn`. It will receive its previous return value (or `baseTime`
 * if it's the first iteration) and the current iteration number. It should return a duration in milliseconds.
 * @param options.maxIterations The maximum number of iterations to perform before considering the polling
 * failed to satisfy `predicate`.
 * @returns Whatever your `fn` function resolves to.
 */
export const poll = async <
  TResult,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TFunction extends (...params: any[]) => Promise<TResult>,
>({
  fn,
  fnParams,
  predicate,
  initialWaitDuration = 0,
  timeStrategy = POLL_STRATEGIES.SQUARE,
  maxIterations = 10,
}: PollOptions<TResult, TFunction>): Promise<TResult> => {
  // Store last resolved value of `fn`
  let lastResult;
  // Store count of current iteration
  let iteration = 0;
  // Store last duration we waited for before executing `fn`
  let waitDuration = initialWaitDuration;

  const run = async (): Promise<TResult> => {
    // If we reached `maxIterations`, throw an error to avoid max call
    // stack size errors
    if (iteration === maxIterations) {
      throw new Error(
        'Could not satisfy `predicate` before reaching `maxIterations`.',
      );
    }

    // Increase iteration count
    iteration += 1;
    // Get time to wait before executing `fn` for this iteration
    waitDuration = timeStrategy(waitDuration, iteration);

    // Wait...
    await wait(waitDuration);

    // Execute `fn` with `fnParams` and store the resolved value in `lastResult`
    lastResult = await fn(...fnParams);

    // Return the last resolved value of `fn` when predicate is matched
    if (predicate(lastResult)) {
      return lastResult;
    }

    // Predicate not matched, fire a new iteration
    return run();
  };

  // Fire first iteration
  return run();
};
