import { useParams, useHistory } from 'react-router-dom';

import { routes, routeFor } from "src/core/constants/routes";

export const useRedirectToBudgetaryExercise = (): ((
  budgetaryExerciseId: string,
) => void) => {
  const history = useHistory();
  const { company: companyId, id: selectedBudgetaryExerciseId } = useParams();

  return (budgetaryExerciseId: string): void => {
    if (budgetaryExerciseId !== selectedBudgetaryExerciseId) {
      history.push(
        routeFor(routes.BUDGETARY_EXERCISES.path, {
          id: budgetaryExerciseId,
          company: companyId,
        }),
      );
    }
  };
};
