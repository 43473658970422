import classNames from 'classnames';

import { useTranslation } from 'common/hooks/useTranslation';
import logout from 'src/core/utils/logout';

type Props = {
  className?: string;
};

export const LogoutButton = ({ className }: Props) => {
  const { t } = useTranslation('global');

  return (
    <button
      className={classNames(
        'cursor-pointer border-none bg-[inherit] text-[inherit] title-m',
        className,
      )}
      onClick={async () => {
        await logout();
        window.location.href = '/auth/login';
      }}
      type="button"
    >
      {t('dropdownLinks.logout')}
    </button>
  );
};
