import { SkeletonButton } from '@dev-spendesk/grapes';
import React from 'react';

import { CountryAutocomplete } from 'common/components/CountryAutocomplete';
import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';

import { useAllowedShippingCountries } from '../../../apis/useAllowedShippingCountries';

type ShippingCountryAutocompleteProps = {
  countryCode: string;
  onSelect: (country: string) => void;
};

export const ShippingCountryAutocomplete = ({
  countryCode,
  onSelect,
}: ShippingCountryAutocompleteProps) => {
  const shippingCountriesResult = useAllowedShippingCountries();

  return (
    <QuerySuspense
      queryState={shippingCountriesResult}
      loading={<SkeletonButton />}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {(allowedCountries) => (
        <>
          <CountryAutocomplete
            countryCode={countryCode}
            onSelect={onSelect}
            countryFilter={(countries) => {
              if (!allowedCountries.restricted) {
                return countries;
              }
              return countries.filter((country) =>
                allowedCountries.countries.includes(country.alpha2),
              );
            }}
            className="w-full"
          />
        </>
      )}
    </QuerySuspense>
  );
};
