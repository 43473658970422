import { computeStackTrace } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import { Callout } from '@dev-spendesk/grapes';
import { useEffect } from 'react';

import { ErrorState } from 'common/components/ErrorState/';
import {
  type LogContext,
  toStackTraceString,
} from 'common/components/withErrorBoundary';
import { type I18nKey } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import {
  type HttpStatusCode,
  type QueryError as QueryErrorType,
} from 'src/core/api/queryError';

import { useQueryError } from './useQueryError';

type QueryErrorProps<TError, TParams> = {
  className?: string;
  queryError: QueryErrorType<TError>;
  componentType: 'Notification' | 'Callout' | 'ErrorState' | 'Text';
  translations?: {
    serverError?: I18nKey;
    networkError?: I18nKey;
    requestError?: (
      reason: TError,
      httpStatusCode: HttpStatusCode,
    ) => I18nKey | undefined;
  };
  translationsParams?: TParams;
  logContext?: {
    team: LogContext['team'];
    scope?: LogContext['scope'];
  };
};

export const QueryError = <
  TError,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TParams extends object = any,
>({
  className,
  componentType,
  queryError,
  translations = {},
  translationsParams,
  logContext,
}: QueryErrorProps<TError, TParams>) => {
  const { pushNotif } = useNotifications();

  const errorTitle = useQueryError<TError, TParams>(translations)(
    queryError,
    translationsParams,
  );

  useEffect(() => {
    if (componentType === 'Notification') {
      pushNotif({
        type: NotificationType.Danger,
        message: errorTitle,
      });
    }
  }, [errorTitle, componentType]);

  useEffect(() => {
    // Temporary: Start sending log to datadog only for QueryError components that have defined a logContext
    if (logContext) {
      const stackTrace = computeStackTrace(queryError);
      const formattedStack = toStackTraceString(stackTrace);

      datadogLogs.logger.error(formattedStack, {
        message: `${queryError.name ? `${queryError.name} ` : ''}${
          queryError.message
        }`,

        team: logContext?.team ?? 'none',
        scope: logContext?.scope ?? 'QueryError',
        error: { ...queryError, stack: formattedStack },
      });
    }
  }, [queryError]);

  switch (componentType) {
    case 'Callout':
      return (
        <Callout title={errorTitle} variant="alert" className={className} />
      );
    case 'ErrorState':
      return <ErrorState className={className} title={errorTitle} />;
    case 'Text':
      return <span className={className}>{errorTitle}</span>;
    default:
      return null;
  }
};
