import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routeFor, routes } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_account_owner: boolean;
    is_controller: boolean;
    is_requester: boolean;
    is_group_admin: boolean;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
};

export const SubscriptionsLink = ({
  company,
  className,
  isInDropdown,
  onClick,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavigationLink
      data-marketing="top-menu_subscriptions"
      key="nav_subscriptions"
      className={className}
      onClick={onClick}
      path={routeFor(routes.SUBSCRIPTIONS.path, {
        company: company.id,
      })}
      text={t('misc.subscription_plural')}
      isInDropdown={isInDropdown}
    />
  );
};
