type BulkUpdateProcessesStore = {
  init: () => void;
  add: (processId: string) => void;
  read: () => string[];
  clear: () => void;
};

export const bulkUpdateProcessesStore = ((): BulkUpdateProcessesStore => {
  let processIds: Set<string>;

  return {
    init(): void {
      processIds = new Set();
    },
    add(processId): void {
      processIds.add(processId);
    },
    read(): string[] {
      return Array.from(processIds);
    },
    clear(): void {
      processIds.clear();
    },
  };
})();
