export const sortNamedItems = <T extends { name: string }>(
  items: T[],
  locale: string,
): T[] => {
  return items.sort((item1, item2) => {
    return item1.name.localeCompare(item2.name, locale, {
      sensitivity: 'base',
    });
  });
};
