/* eslint-disable @typescript-eslint/no-explicit-any */
import map from 'lodash/map';
import queryString from 'query-string';

import { formatDateToApiString } from 'src/core/common/utils/formatDateToApiString';

import { type SortFilter, type GroupFilter, type Filters } from '../../models';

const FIELD_FILTER_PREFIX = 'f_';
const CUSTOM_FIELD_FILTER_PREFIX = 'cf_';

type Params = {
  sort?: SortFilter;
  group?: GroupFilter;
  search?: string;
  startDate?: string;
  endDate?: string;
  [key: string]: any;
};

export const filtersToUrlQueryString = (filters: Filters) => {
  const params: Params = {};

  if (filters.sort) {
    params.sort = filters.sort;
  }
  if (filters.group) {
    params.group = filters.group;
  }
  if (filters.search) {
    params.search = filters.search;
  }

  if (filters.period && filters.period.startDate && filters.period.endDate) {
    params.startDate = formatDateToApiString(
      new Date(filters.period.startDate),
    );
    params.endDate = formatDateToApiString(new Date(filters.period.endDate));
  }

  if (filters.additional) {
    filters.additional.forEach((additionalFilter) => {
      const values = map(additionalFilter.values, (value: any) =>
        // For amount filter, stringify monetary value object
        additionalFilter.id === 'amount'
          ? queryString.stringify({
              amount: value.amount,
              precision: value.precision,
              currency: value.currency,
            })
          : value,
      );
      if (
        additionalFilter.type === 'CustomBoolean' ||
        additionalFilter.type === 'CustomList'
      ) {
        // Store custom fields filters (prefix the key with CUSTOM_FIELD_FILTER_PREFIX)
        params[`${CUSTOM_FIELD_FILTER_PREFIX}${additionalFilter.id}`] = values;
      } else {
        // Store non-custom fields filters (prefix the key with FIELD_FILTER_PREFIX)
        params[`${FIELD_FILTER_PREFIX}${additionalFilter.id}`] = values;
      }
    });
  }

  return queryString.stringify(params, { arrayFormat: 'index' });
};
