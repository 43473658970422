import { type BankingProvider } from '../reducers/global';

export const isOnboardingPathname = (
  pathname: string = window.location.pathname,
) => {
  return pathname.startsWith('/onboarding-v2');
};

type GetOnboardingUrlParams = {
  bankingProvider?: BankingProvider;
  origin?: string;
  pathname?: string;
};

export const getOnboardingUrl = ({
  bankingProvider,
  origin = window.location.origin,
  pathname = '',
}: GetOnboardingUrlParams) => {
  if (bankingProvider === 'sfs') {
    return `${origin}/kyc/${pathname}`;
  }

  return `${origin}/onboarding-v2/${pathname}`;
};
