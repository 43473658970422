import { FormField, TextInput } from '@dev-spendesk/grapes';
import { type FormikErrors } from 'formik';
import React from 'react';

import { CountryAutocomplete } from 'src/core/common/components/CountryAutocomplete/CountryAutocomplete';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type AddressFormValues } from './form';

import './AddressForm.css';

type Props = {
  areFieldsDisabled?: boolean;
  // FormikProps
  errors: FormikErrors<AddressFormValues>;
  values: AddressFormValues;
  setFieldValue: (
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: (e: React.ChangeEvent<any>) => void;
};

export const AddressForm = ({
  areFieldsDisabled = false,
  // Formik props
  errors,
  values,
  handleChange,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <FormField
        className="AddressForm__form-field"
        label={t('generalSettings.address.companyNameLabel')}
        alertMessage={
          errors.name
            ? t('generalSettings.address.companyNameError')
            : undefined
        }
      >
        <TextInput
          name="name"
          value={values.name}
          placeholder={t('generalSettings.address.companyNamePlaceholder')}
          onChange={handleChange}
          isDisabled={areFieldsDisabled}
        />
      </FormField>
      <FormField
        className="AddressForm__form-field"
        label={t('generalSettings.address.addressLabel')}
        alertMessage={
          errors.address ? t('generalSettings.address.addressError') : undefined
        }
      >
        <TextInput
          name="address"
          value={values.address}
          placeholder={t('generalSettings.address.addressPlaceholder')}
          onChange={handleChange}
          isDisabled={areFieldsDisabled}
        />
      </FormField>
      <div className="AddressForm__field-container">
        <FormField
          className="AddressForm__form-field"
          label={t('generalSettings.address.address2Label')}
          alertMessage={
            errors.address2
              ? t('generalSettings.address.address2Error')
              : undefined
          }
        >
          <TextInput
            name="address2"
            value={values.address2}
            placeholder={t('generalSettings.address.address2Placeholder')}
            onChange={handleChange}
            isDisabled={areFieldsDisabled}
          />
        </FormField>
        <FormField
          className="AddressForm__form-field"
          label={t('generalSettings.address.zipcodeLabel')}
          alertMessage={
            errors.zipcode
              ? t('generalSettings.address.zipcodeError')
              : undefined
          }
        >
          <TextInput
            name="zipcode"
            value={values.zipcode}
            placeholder={t('generalSettings.address.zipcodePlaceholder')}
            onChange={handleChange}
            isDisabled={areFieldsDisabled}
          />
        </FormField>
      </div>
      <div className="AddressForm__field-container">
        <FormField
          className="AddressForm__form-field"
          label={t('generalSettings.address.cityLabel')}
          alertMessage={
            errors.city ? t('generalSettings.address.cityError') : undefined
          }
        >
          <TextInput
            name="city"
            value={values.city}
            placeholder={t('generalSettings.address.cityPlaceholder')}
            onChange={handleChange}
            isDisabled={areFieldsDisabled}
          />
        </FormField>
        <FormField
          className="AddressForm__form-field"
          label={t('generalSettings.address.countryLabel')}
          alertMessage={
            errors.country
              ? t('generalSettings.address.countryError')
              : undefined
          }
        >
          <CountryAutocomplete
            name="country"
            fit="parent"
            countryCode={values.country}
            placeholder={t('generalSettings.address.countryPlaceholder')}
            onSelect={(selectedKey: string | undefined) => {
              setFieldValue('country', selectedKey || '');
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              if (e.target.value === '') {
                setFieldValue('country', '');
              }
            }}
            isDisabled={areFieldsDisabled}
          />
        </FormField>
      </div>
    </>
  );
};
