import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useUnskipOnboardingTaskMutation = (
  type: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
): MutationState<{}> => {
  const companyId = useCompanyId();

  // eslint-disable-next-line @typescript-eslint/ban-types
  return useMutation<{}>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: `/onboarding-tasks/operational/type/${type}/unskip`,
    },
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['welcomeDashboard', companyId]);
      },
    },
  });
};
