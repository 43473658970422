import {
  Button,
  Callout,
  CheckboxField,
  FormField,
  Modal,
  RadioField,
  RadioGroup,
} from '@dev-spendesk/grapes';
import React, { useState, type Dispatch } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { CostCenterInfo } from './CostCenterInfo';
import { CustomFieldSelectAndInfo } from './CustomFieldSelectAndInfo';
import { type ModalState } from './ModalState';
import { type AccountingSoftware } from '../../../../../../../integration/status';
import { type CustomField } from '../../../../../hooks/useCustomFieldsQuery';
import { type AnalyticalFieldMappingUpdate } from '../../../../../hooks/useUpdateAnalyticalFieldMappingMutation';

interface Props {
  integration: AccountingSoftware;
  customFields: CustomField[];
  hasCostCenterFeature: boolean;
  hasCustomFieldsActive: boolean;
  hasCostCenterActive: boolean;
  modalState: ModalState;
  setModalState: Dispatch<ModalState>;
  onUpdate: (
    editedAnalyticalField: AnalyticalFieldMappingUpdate & {
      externalName: string;
    },
  ) => void;
}

const noMappingSelectedKey = 'none';
const costCenterSelectedKey = 'costCenter';
const customFieldSelectedKey = 'customField';

export const StaticMapAndPullAndMapModal = ({
  integration,
  customFields,
  hasCostCenterFeature,
  hasCustomFieldsActive,
  hasCostCenterActive,
  modalState,
  setModalState,
  onUpdate,
}: Props) => {
  const { t } = useTranslation('global');

  const [isConfigurationError, setConfigurationError] = useState(false);

  function getCurrentSelectValue() {
    if (modalState.modalKind === 'map' && modalState?.kind === 'mapped') {
      if (modalState.codeType === 'costCenter') {
        return costCenterSelectedKey;
      }

      if (modalState.codeType === 'customField') {
        return customFieldSelectedKey;
      }
    }

    return noMappingSelectedKey;
  }

  const currentSelectValue = getCurrentSelectValue();

  let isModalVisible = false;
  let isAnalyticalFieldMapped = false;
  let isAnalyticalFieldNotMapped = false;
  let isCostCenterAnalyticalField = false;
  let isCostCenterEnabled = false;
  let isCustomFieldAnalyticalField = false;
  let isCustomFieldEnabled = false;

  if (modalState.modalKind === 'map') {
    isModalVisible = true;
    if (modalState?.kind === 'mapped') {
      isAnalyticalFieldMapped = true;
      if (modalState.codeType === 'costCenter') {
        isCostCenterAnalyticalField = true;
        isCostCenterEnabled = hasCostCenterFeature && hasCostCenterActive;
      }
      if (modalState.codeType === 'customField') {
        isCustomFieldAnalyticalField = true;
        isCustomFieldEnabled =
          hasCustomFieldsActive &&
          !!customFields.length &&
          !!modalState.internalId;
      }
    } else if (modalState?.kind === 'notMapped') {
      isAnalyticalFieldNotMapped = true;
    }
  }

  function isSaveDisabled(): boolean {
    return (
      (isModalVisible &&
        (isAnalyticalFieldNotMapped ||
          (isAnalyticalFieldMapped &&
            ((isCostCenterAnalyticalField && !isCostCenterEnabled) ||
              (isCustomFieldAnalyticalField && !isCustomFieldEnabled))))) ||
      (isModalVisible && isConfigurationError)
    );
  }

  function shouldShowConfiguration(): boolean {
    switch (integration) {
      case 'Netsuite':
        return (
          modalState.modalKind === 'map' &&
          ['location', 'class', 'department'].includes(modalState.externalId)
        );
      default:
        return false;
    }
  }

  return (
    <Modal
      actions={[
        <Button
          key="no"
          onClick={() => setModalState({ modalKind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          isDisabled={isSaveDisabled()}
          text={t('misc.saveChanges')}
          variant="primary"
          onClick={() => {
            if (modalState.modalKind === 'map') {
              if (
                shouldShowConfiguration() &&
                !modalState.sendOnLine &&
                !modalState.sendOnHeader
              ) {
                setConfigurationError(true);
                return;
              }

              onUpdate(modalState);
            }
          }}
        />,
      ]}
      onClose={() => {
        setModalState({ modalKind: 'closed' });
      }}
      iconName="pen"
      title={t('bookkeep.integrations.settings.mapping', {
        externalName: modalState.modalKind === 'map' && modalState.externalName,
      })}
      iconVariant="primary"
      isOpen={isModalVisible}
    >
      {isModalVisible && (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.analyticalFieldModalSubtitle',
            )}
            htmlFor="set-mapping-autocomplete-input"
          >
            <RadioGroup
              name="codeType"
              value={currentSelectValue}
              onChange={(event) => {
                if (event.target.value === 'costCenter') {
                  setModalState({
                    ...modalState,
                    kind: 'mapped',
                    codeType: 'costCenter',
                  });
                }

                if (event.target.value === 'customField') {
                  setModalState({
                    ...modalState,
                    kind: 'mapped',
                    codeType: 'customField',
                    internalId: '',
                    internalName: '',
                  });
                }
              }}
            >
              <RadioField
                value={costCenterSelectedKey}
                label={t(
                  'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenters',
                )}
              />
              <RadioField
                value={customFieldSelectedKey}
                label={t(
                  'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFields',
                )}
              />
            </RadioGroup>
          </FormField>

          {shouldShowConfiguration() && (
            <FormField
              label={t(
                'bookkeep.integrations.settings.analyticalFieldsMappingsTable.configuration',
              )}
              htmlFor="set-configuration-radio-input"
              className="mt-s"
            >
              <div className="flex flex-wrap">
                <CheckboxField
                  className="mr-s"
                  label={t(
                    'bookkeep.integrations.settings.analyticalFieldsMappingsTable.line',
                  )}
                  isChecked={
                    modalState.modalKind !== 'closed' && !!modalState.sendOnLine
                  }
                  onChange={() => {
                    if (modalState.modalKind === 'closed') {
                      return;
                    }
                    setConfigurationError(false);

                    setModalState({
                      ...modalState,
                      sendOnLine: !modalState.sendOnLine,
                    });
                  }}
                />

                <CheckboxField
                  label={t(
                    'bookkeep.integrations.settings.analyticalFieldsMappingsTable.header',
                  )}
                  isChecked={
                    modalState.modalKind !== 'closed' &&
                    !!modalState.sendOnHeader
                  }
                  onChange={() => {
                    if (modalState.modalKind === 'closed') {
                      return;
                    }

                    setConfigurationError(false);

                    setModalState({
                      ...modalState,
                      sendOnHeader: !modalState.sendOnHeader,
                    });
                  }}
                />

                {isConfigurationError && (
                  <Callout
                    className="my-xs basis-full"
                    title={t(
                      'bookkeep.integrations.settings.analyticalFieldsMappingsTable.missingConfiguration',
                    )}
                    variant="alert"
                  />
                )}
              </div>
            </FormField>
          )}

          <CustomFieldSelectAndInfo
            modalState={modalState}
            setModalState={setModalState}
            hasCustomFieldsActive={hasCustomFieldsActive}
            integration={integration}
            customFields={customFields}
          />
          <CostCenterInfo
            hasCostCenterFeature={hasCostCenterFeature}
            hasCostCenterActive={hasCostCenterActive}
            integration={integration}
            modalState={modalState}
          />
        </>
      )}
    </Modal>
  );
};
