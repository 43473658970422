import { useDeleteDelegationMutation } from '../../../hooks/useDeleteDelegationMutation';

export const useEditDelegationMutation = (memberId: string) => {
  const [deleteDelegation] = useDeleteDelegationMutation();
  return [
    async (role: 'wt_confirmation') => {
      await deleteDelegation({
        endpointParams: {
          role,
          userId: memberId,
        },
      });
    },
  ];
};
