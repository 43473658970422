import { Button, Tooltip } from '@dev-spendesk/grapes';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { getLegacyFiltersFromUrl } from './PaymentFilters/types';
import { type Selection } from './types';
import { getExportExtension } from '../../utils/getExportExtension';

type Props = {
  selection: Selection;
  counters?: {
    remindable_for_invoice: number;
    downloadable: number;
    editable: number;
  };
  bulkActions?: {
    download?: { processing: boolean };
    edit?: { processing: boolean };
    remindInvoices?: { processing: boolean };
    markAsMissing?: { processing: boolean };
  };
  isSupervisionActive: boolean;
  bulkEdit: (arguments_: {
    selection: Selection;
    filters: Record<string, string | string[]>;
  }) => void;
  download: (arguments_: {
    selection: Selection;
    withReceipts: boolean;
    filters: Record<string, string | string[]>;
  }) => void;
  remindInvoices: (arguments_: {
    selection: Selection;
    filters: Record<string, string | string[]>;
  }) => void;
  bulkMarkAsMissing: (arguments_: {
    selection: Selection;
    filters: Record<string, string | string[]>;
  }) => Promise<{ nbPaymentsMarked: number }>;
};

export const PaymentsListHeader = ({
  selection,
  counters,
  bulkActions,
  isSupervisionActive,
  bulkEdit,
  download,
  remindInvoices,
  bulkMarkAsMissing,
}: Props) => {
  const { t } = useTranslation('global');
  const { successNotif, dangerNotif } = useNotifications();
  const history = useHistory();

  const companyId = useCompanyId();

  const hasSelectedPayments = selection.all || selection.include?.length > 0;

  if (!counters || !hasSelectedPayments) {
    return <div className="h-[52px]" />;
  }

  const numberOfSelectedRows = counters.downloadable;

  const { remindable_for_invoice, downloadable, editable } = counters;

  const filters = getLegacyFiltersFromUrl(history.location?.search);

  const renderDownloadActions = () => {
    const extension = getExportExtension();

    if (downloadable > 10_000) {
      return (
        <Button
          variant="secondary"
          isDisabled
          text={t('payments.actions.tooManyPaymentsToDownload')}
          className="truncate"
        />
      );
    }

    if (downloadable > 300) {
      return (
        <>
          <Button
            variant="secondary"
            text={t('payments.actions.downloadPaymentCsvOnly', {
              count: downloadable,
              extension,
            })}
            isLoading={bulkActions?.download?.processing}
            onClick={() => {
              download({ selection, withReceipts: false, filters });
              track(AnalyticEventName.PAYMENTS_DOWNLOAD_BUTTON_CLICKED, {
                withReceipts: true,
                fileType: 'purchaseJournal',
              });
            }}
            className="truncate"
          />
          <Button
            variant="secondary"
            isDisabled
            text={t('payments.actions.tooManyPaymentsToDownloadWithReceipts')}
            className="truncate"
          />
        </>
      );
    }

    return (
      <>
        <Button
          variant="secondary"
          text={t('payments.actions.downloadPaymentCsvOnly', {
            count: downloadable,
            extension,
          })}
          isLoading={bulkActions?.download?.processing}
          onClick={() => {
            download({ selection, withReceipts: false, filters });
            track(AnalyticEventName.PAYMENTS_DOWNLOAD_BUTTON_CLICKED, {
              withReceipts: true,
              fileType: 'purchaseJournal',
            });
          }}
          className="truncate"
        />
        <Button
          variant="secondary"
          text={t('payments.actions.downloadPaymentWithReceipts', {
            count: downloadable,
            extension,
          })}
          isLoading={bulkActions?.download?.processing}
          onClick={() => {
            download({ selection, withReceipts: true, filters });
            track(AnalyticEventName.PAYMENTS_DOWNLOAD_BUTTON_CLICKED, {
              withReceipts: true,
              fileType: 'purchaseJournal',
            });
          }}
          className="truncate"
        />
      </>
    );
  };

  return (
    <div
      className="flex h-[52px] items-center justify-between py-xs"
      data-testid="payable-list-actions"
    >
      <div className="text-neutral-dark">
        {t('payments.actions.nPaymentsSelected', {
          count: numberOfSelectedRows,
        })}
      </div>

      <div className="flex items-center gap-xs">
        {remindable_for_invoice > 0 && (
          <Button
            variant="secondary"
            isDisabled={remindable_for_invoice > 100}
            text={
              remindable_for_invoice > 100
                ? t('payments.actions.sendReminderLimitDisclaimer', {
                    count: 100,
                  })
                : t('payments.actions.editPayment', {
                    count: remindable_for_invoice,
                  })
            }
            isLoading={bulkActions?.remindInvoices?.processing}
            onClick={() => {
              remindInvoices({ selection, filters });
            }}
            className="truncate"
          />
        )}
        {downloadable > 0 && renderDownloadActions()}
        {editable > 1 && (
          <Button
            variant="secondary"
            text={t('payments.actions.editPayment', { count: editable })}
            isLoading={bulkActions?.edit?.processing}
            onClick={() => {
              bulkEdit({ selection, filters });
              history.push({
                pathname: routeFor(routes.PAYMENTS_ALL.path, {
                  company: companyId,
                }),
                search: history.location?.search,
              });
            }}
            className="truncate"
          />
        )}
        {isSupervisionActive && hasSelectedPayments && (
          <Tooltip
            content={t('payments.actions.markAsMissingTooltip')}
            triggerAsChild
          >
            <Button
              variant="secondary"
              text={t('payments.actions.markAsMissing')}
              isLoading={bulkActions?.markAsMissing?.processing}
              onClick={async () => {
                try {
                  const result = await bulkMarkAsMissing({
                    selection,
                    filters,
                  });
                  successNotif(
                    t('payments.actions.markAsMissingSuccess', {
                      count: result.nbPaymentsMarked,
                    }),
                  );
                } catch {
                  dangerNotif(t('payments.actions.markAsMissingError'));
                }
              }}
              className="truncate"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
