import { type GetDuplicatesResponse } from '@dev-spendesk/spendesk-types';

import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';

import { type Duplicate } from '../types';

export const getDuplicateTitle = ({
  duplicate,
  translator,
}: {
  duplicate: Duplicate;
  translator: TGlobalFunctionTyped;
}): string => {
  if (duplicate.matchingData.includes('checksum')) {
    return translator('request.duplicates.warningTitleExpenseReceipt');
  }
  return translator('request.duplicates.warningTitleExpenseDetails');
};

export const getDuplicateLinkText = ({
  duplicate,
  translator,
}: {
  duplicate: Duplicate;
  translator: TGlobalFunctionTyped;
}): string => {
  switch (duplicate.matchingEntity.type) {
    case 'expenseClaimRequest': {
      if (duplicate.matchingEntity.reimbursementPaymentId) {
        return translator('request.duplicates.warningLinkPayment');
      }
      return translator('request.duplicates.warningLinkExpense');
    }
    default:
      return translator('request.duplicates.warningLinkPayment');
  }
};

export const getDuplicateDesc = ({
  duplicate,
  userId,
  translator,
}: {
  duplicate: Duplicate | GetDuplicatesResponse[0];
  userId: string;
  translator: TGlobalFunctionTyped;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): string => {
  const isSelf = userId === duplicate?.matchingEntity?.owner?.id;
  // @ts-expect-error reimbursementPaymentId does not exist in the case of GetDuplicatesResponse[0]
  const isReimbursed = Boolean(duplicate.matchingEntity.reimbursementPaymentId);

  const ownerName = duplicate?.matchingEntity?.owner?.name;

  if (duplicate.matchingData.includes('checksum')) {
    // attachment match
    if (isSelf) {
      switch (duplicate.matchingEntity.type) {
        case 'invoiceRequest':
          return translator(
            'request.duplicates.matchesOnAttachmentAsInvoiceBySelf',
          );
        case 'expenseClaimRequest': {
          return isReimbursed
            ? translator(
                'request.duplicates.matchesOnAttachmentAsExpenseClaimReimbursedBySelf',
              )
            : translator(
                'request.duplicates.matchesOnAttachmentAsExpenseClaimRequestBySelf',
              );
        }
        case 'payment':
          return translator(
            'request.duplicates.matchesOnAttachmentAsPaymentBySelf',
          );
        default:
          return translator('request.duplicates.matchesOnAttachmentBySelf');
      }
    }

    switch (duplicate.matchingEntity.type) {
      case 'invoiceRequest':
        return translator(
          'request.duplicates.matchesOnAttachmentAsInvoiceByOther',
          {
            author: ownerName ?? '',
          },
        );
      case 'expenseClaimRequest': {
        return isReimbursed
          ? translator(
              'request.duplicates.matchesOnAttachmentAsExpenseClaimReimbursedByOther',
              {
                author: ownerName ?? '' ?? '',
              },
            )
          : translator(
              'request.duplicates.matchesOnAttachmentAsExpenseClaimRequestByOther',
              {
                author: ownerName ?? '',
              },
            );
      }
      case 'payment':
        return translator(
          'request.duplicates.matchesOnAttachmentAsPaymentByOther',
          {
            author: ownerName ?? '',
          },
        );
      default:
        return translator('request.duplicates.matchesOnAttachmentByOther', {
          author: ownerName ?? '',
        });
    }
  }

  if (
    duplicate.matchingData.includes('supplierId') &&
    duplicate.matchingData.includes('invoiceNumber')
  ) {
    return isSelf
      ? translator('request.duplicates.matchesOnSupplierAndInvoiceNumberBySelf')
      : translator(
          'request.duplicates.matchesOnSupplierAndInvoiceNumberByOther',
          { author: ownerName ?? '' },
        );
  }

  if (!duplicate.matchingData.includes('checksum')) {
    // details match
    if (isSelf) {
      switch (duplicate.matchingEntity.type) {
        case 'expenseClaimRequest': {
          return isReimbursed
            ? translator(
                'request.duplicates.matchesOnDetailsAsExpenseClaimReimbursedBySelf',
              )
            : translator(
                'request.duplicates.matchesOnDetailsAsExpenseClaimRequestBySelf',
              );
        }
        case 'payment':
          return translator(
            'request.duplicates.matchesOnDetailsAsPaymentBySelf',
          );
        default:
          return translator('request.duplicates.matchesOnDetailsBySelf');
      }
    }

    switch (duplicate.matchingEntity.type) {
      case 'expenseClaimRequest': {
        return isReimbursed
          ? translator(
              'request.duplicates.matchesOnDetailsAsExpenseClaimReimbursedByOther',
              { author: ownerName ?? '' },
            )
          : translator(
              'request.duplicates.matchesOnDetailsAsExpenseClaimRequestByOther',
              { author: ownerName ?? '' },
            );
      }
      case 'payment':
        return translator(
          'request.duplicates.matchesOnDetailsAsPaymentByOther',
          { author: ownerName ?? '' },
        );
      default:
        return translator('request.duplicates.matchesOnDetailsByOther', {
          author: ownerName ?? '',
        });
    }
  }

  return isSelf
    ? translator('request.duplicates.matchesOnDetailsBySelf')
    : translator('request.duplicates.matchesOnDetailsByOther', {
        author: ownerName ?? '',
      });
};
