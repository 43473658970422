import { Icon, SwitchField, colors } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  isChecked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const AutoAssignSwitchField = ({ isChecked, onChange }: Props) => {
  const { t } = useTranslation('global');

  return (
    <SwitchField
      id="members-auto-assign"
      className="mt-m"
      label={
        <div className="flex items-center gap-xs">
          <Icon name="team" color={colors.neutral} />
          {t('controlRulesModal.autoAssignLabel')}
        </div>
      }
      helpText={t('controlRulesModal.autoAssignDesc')}
      isChecked={isChecked}
      onChange={onChange}
    />
  );
};
