import { useHistory } from 'react-router-dom';

import { routeFor } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import {
  type OperationalOnboardingTask,
  getRouteForOperationalOnboardingTask,
} from '../../../../models';

export const useNavigateToOnboardingTask = (
  task: OperationalOnboardingTask,
) => {
  const companyId = useCompanyId();
  const history = useHistory();
  return () => {
    track(AnalyticEventName.WELCOME_ONBOARDING_TASK_START, {
      type: task.type,
      status: task.status,
    });

    history.push(
      routeFor(
        getRouteForOperationalOnboardingTask({
          locationKey: task.redirectionLocation,
        }),
        {
          company: companyId,
          ...(task.redirectionLocation === 'payment-request'
            ? { type: 'all', id: 'new' }
            : {}),
        },
      ),
    );
  };
};
