import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export type CardActivity = {
  id: string;
  created_at: string;
  activity_type: string;
  user: {
    fullname: string;
  };
};

export const useCardActivityQuery = (
  cardId: string,
): QueryState<CardActivity[]> => {
  return useQuery<CardActivity[], CardActivity[]>({
    key: ['cards', cardId, 'activity'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/cards/${cardId}/activity`,
    },
    reshapeData: (data) => data,
  });
};
