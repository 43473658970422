import { PaymentMethod } from 'modules/company';
import { type QueryState } from 'src/core/api/queryState';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { useFetchPaymentMethods } from './useFetchPaymentMethods';

export const useIsPaymentMethodValidQuery = (
  paymentMethod: PaymentMethod,
): QueryState<boolean> => {
  const isWireTransferForInvoicesEnabled = useFeature(
    FEATURES.WIRE_TRANSFER_FOR_INVOICES,
  );
  const paymentMethodsQueryState = useFetchPaymentMethods();
  if (
    !isWireTransferForInvoicesEnabled &&
    paymentMethod === PaymentMethod.WireTransfer
  ) {
    return {
      status: 'success',
      data: false,
    };
  }

  if (paymentMethodsQueryState.status === 'success') {
    const availablePaymentMethods = [
      ...paymentMethodsQueryState.data.fromBank,
      ...paymentMethodsQueryState.data.fromSpendesk,
    ];
    return {
      status: 'success',
      data: availablePaymentMethods.includes(paymentMethod),
    };
  }

  return paymentMethodsQueryState;
};
