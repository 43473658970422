import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchUsers } from 'src/core/actions/users';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { type AppDispatch } from 'src/core/modules/app/redux/store';
import { MembersTabContainer } from 'src/core/modules/members/containers/MembersTabContainer';

export const SettingsOrganisationMembersPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const hasTemporaryMembersTableRowQuery = useFeature(
    FEATURES.TMP_MEMBER_TABLE_ROWS_QUERY,
  );
  useEffect(() => {
    if (!hasTemporaryMembersTableRowQuery) {
      dispatch(fetchUsers(true));
    }
  }, []);
  return <MembersTabContainer />;
};
