import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getProcedureId } from 'modules/StrongCustomerAuthentication/redux/selectors';
import { type ScaState } from 'modules/StrongCustomerAuthentication/types';

import { ScaWireTransferConfirmationModal } from './ScaWireTransferConfirmationModal';
import { useScaProcedure } from './useScaProcedure';

interface Props {
  isOpen: boolean;
  onUseAlternateMethod: () => void;
  onScaProceed: (state: ScaState) => void;
  onClose: () => void;
}

export const ScaWireTransferConfirmationModalContainer = ({
  isOpen,
  onUseAlternateMethod,
  onScaProceed,
  onClose,
}: Props) => {
  const procedureId = useSelector(getProcedureId);
  const scaProcedure = useScaProcedure({
    procedureId,
    shouldRefresh: isOpen,
    shouldFetch: Boolean(procedureId),
  });

  useEffect(() => {
    if (scaProcedure.status === 'success' && isOpen) {
      onScaProceed(scaProcedure.data.scaProcedure.state);
    }
  }, [scaProcedure, isOpen]);

  if (scaProcedure.status !== 'success') {
    return null;
  }

  return (
    <ScaWireTransferConfirmationModal
      isOpen={isOpen}
      onUseAlternateMethod={onUseAlternateMethod}
      onClose={onClose}
    />
  );
};
