import {
  AutocompleteNoOptions as GrapesAutocompleteNoOptions,
  type AutocompleteProps,
  DropdownItem,
  FormField,
} from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { getSelf as getCurrentUser } from 'src/core/selectors/users';
import {
  getValuesFromCustomField,
  shouldUseAsyncCustomFieldAutocomplete,
  userCanCreateCfValue,
} from 'src/core/utils/custom-fields';

import { CustomFieldAsyncAutocomplete } from './CustomFieldAsyncAutocomplete';

/**
 *
 * In this component, we'll use the following naming:
 * - we call CustomField the type representing a custom field: it has an id, a label and a list of values
 * - we call CustomFieldValue the type representing the value a custom field can take
 * - we call CustomFieldAssociation the entity associating a custom field and a custom field value
 *
 */
type CustomField = {
  id: string;
  type: 'list' | 'boolean';
  is_required: boolean;
  deleted_at: string | null;
  total_values?: number;
  perms_add_values: string[];
  custom_fields_values: {
    id: string;
    value: string;
    is_default: boolean;
    created_at: string;
  }[];
};
type CustomFieldValue = { key: string; name: string };
type CustomFieldAssociation = {
  customFieldId: string;
  customFieldValueId: string | null;
  value: string;
};

type Props = {
  fit?: AutocompleteProps<{ key: string; label: string }>['fit'];
  placement?: AutocompleteProps<{ key: string; label: string }>['placement'];
  className?: string;
  customField: CustomField;
  customFieldAssociation: CustomFieldAssociation | undefined;
  isDisabled?: boolean;
  isInvalid?: boolean;
  label: string;
  placeholder: string;
  disableCreateNewValue?: boolean;
  disableSelectUndefined?: boolean;
  onSelectNewCustomFieldValue(rawValue: string): void;
  onSelectCustomFieldValue(
    customFieldValue: CustomFieldValue | undefined,
  ): void;
};

export const CustomFieldFormField = ({
  className,
  customField,
  customFieldAssociation,
  isDisabled,
  isInvalid,
  label,
  placeholder,
  disableCreateNewValue = false,
  disableSelectUndefined = true,
  onSelectNewCustomFieldValue,
  onSelectCustomFieldValue,
  fit,
  placement,
}: Props) => {
  const { t } = useTranslation('global');
  const user = useSelector(getCurrentUser);

  const canCreateCustomFieldValue =
    !disableCreateNewValue && userCanCreateCfValue(user, customField);
  const shouldUseAsyncAutocomplete =
    shouldUseAsyncCustomFieldAutocomplete(customField);

  const renderNoOptions = (_rawValue: string) => {
    return (
      <GrapesAutocompleteNoOptions>
        {shouldUseAsyncAutocomplete
          ? t('misc.fieldHasTooManyValues')
          : t('forms.customField.asyncEmptyState')}
      </GrapesAutocompleteNoOptions>
    );
  };

  const options = getValuesFromCustomField(customField).map((value) => ({
    key: value.key,
    label: value.name,
  }));

  if (!disableSelectUndefined) {
    options.push({ key: '', label: t('customFields.notProvided') });
  }

  const autocompleteProps = {
    isDisabled,
    placeholder,
    value:
      customFieldAssociation && customFieldAssociation.value
        ? {
            key: customFieldAssociation.customFieldValueId ?? '',
            label: customFieldAssociation.value,
          }
        : undefined,
    onSelect: (
      customFieldValue: { key: string; label: string } | undefined,
    ) => {
      // HACK to be able to differentiate new values from already saved ones
      if (customFieldValue && customFieldValue.key === customFieldValue.label) {
        onSelectNewCustomFieldValue(customFieldValue.label);
      } else {
        const reshapedCustomFieldValue = customFieldValue && {
          key: customFieldValue.key,
          name: customFieldValue.label,
        };
        onSelectCustomFieldValue(reshapedCustomFieldValue);
      }
    },
  };

  if (customField.type === 'boolean') {
    autocompleteProps.value = options.find(
      (v) => v.key === customFieldAssociation?.customFieldValueId,
    );
  }

  const addOptionsProps = canCreateCustomFieldValue
    ? {
        onAddOption: (rawValue: string) => {
          return { key: rawValue, label: rawValue };
        },
        renderAddOption: (inputValue: string) => {
          return (
            <DropdownItem
              label={
                <Trans
                  i18nKey="misc.createNewAutocompleteOption"
                  values={{ value: inputValue }}
                  components={[
                    <span className="text-complementary" key={inputValue} />,
                  ]}
                />
              }
            />
          );
        },
        renderNoOptions,
      }
    : {
        renderNoOptions,
      };

  return (
    <FormField
      className={className}
      hint={!customField.is_required && t('misc.optional')}
      label={label}
      alertMessage={isInvalid ? t('forms.customField.errorAlt') : undefined}
    >
      <>
        {shouldUseAsyncAutocomplete ? (
          <CustomFieldAsyncAutocomplete
            fit={fit}
            placement={placement}
            customField={customField}
            disableSelectUndefined={disableSelectUndefined}
            {...autocompleteProps}
            {...addOptionsProps}
          />
        ) : (
          <AutocompleteSearch
            fit={fit}
            placement={placement}
            options={options}
            {...autocompleteProps}
            {...addOptionsProps}
          />
        )}
      </>
    </FormField>
  );
};
