import { Button, DATE_FORMAT, ListBox } from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { type CurrenciesKey } from 'src/core/config/money';
import { formatMoney } from 'src/core/utils/money';

import { getLoadedBy, titleFromActivityType } from './helpers';
import { type CardActivity } from '../../hooks/useCardActivityQuery';
import { type CardLoad } from '../../hooks/useCardLoadsQuery';
import { type CardPayment } from '../../hooks/useCardPaymentsQuery';

import './CardFeedActivity.css';

type Props = {
  isInPanel?: boolean;
  cardActivity: CardActivity[] | undefined;
  cardPayments: CardPayment[] | undefined;
  cardLoads: CardLoad[] | undefined;
};

type Activity = {
  id: string;
  type: 'payment' | 'load' | 'action';
  title: string | null;
  subtitle: string | null;
  amount: number | null;
  currency: CurrenciesKey | null;
  date: string;
};

const MAX_ITEMS_SHOWN = 5;

export const CardFeedActivity = ({
  isInPanel = false,
  cardActivity,
  cardPayments,
  cardLoads,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');

  const companyCurrency = useCompanyCurrency();

  const [activities, setActivities] = useState<Activity[]>([]);
  const [offset, setOffset] = useState(MAX_ITEMS_SHOWN);

  useEffect(() => {
    setActivities(getHarmonisedActivities());
  }, [cardLoads, cardActivity, cardPayments]);

  const getHarmonisedActivities = () => {
    const harmonizedActivities: Activity[][] = [];

    // Load payments activities
    if (cardPayments && cardPayments.length > 0) {
      harmonizedActivities.push(formatCardPaymentsToActivities(cardPayments));
    }
    // Load card activities (paused, blocked, lost etc.)
    if (cardActivity && cardActivity.length > 0) {
      harmonizedActivities.push(formatCardActionsToActivity(cardActivity, t));
    }
    // Load card loads
    if (cardLoads && cardLoads.length > 0) {
      harmonizedActivities.push(
        formatCardLoadsToActivities(cardLoads, companyCurrency, t),
      );
    }

    // Merge & sort rows
    return harmonizedActivities
      .flat()
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  };

  const handleLoadMore = () => {
    setOffset(offset + MAX_ITEMS_SHOWN);
  };

  return (
    <>
      <ListBox
        className={isInPanel ? 'CardFeedActivity--without-border' : undefined}
        options={[...activities].splice(0, offset)}
        footer={
          !isInPanel &&
          activities.length > offset && (
            <div className="flex justify-center p-s">
              <Button
                variant="secondary"
                text={t('misc.loadMore')}
                onClick={handleLoadMore}
              />
            </div>
          )
        }
        getOptionId={({ id }) => id}
      >
        {(activity) => (
          <>
            <div
              key={activity.id}
              className="flex items-center justify-between"
            >
              <div className="activity-left">
                <div className="text-complementary body-m">
                  {activity.title}
                </div>
                {activity.subtitle && (
                  <div className="text-neutral-dark body-s">
                    {activity.subtitle}
                  </div>
                )}
              </div>
              <div className="text-right">
                {activity.amount && Number.isFinite(activity.amount) && (
                  <div className="text-complementary title-m">
                    {formatMoney(
                      activity.amount,
                      activity.currency ?? undefined,
                    )}
                  </div>
                )}
                <div className="text-neutral-dark body-s">
                  {localeFormat(new Date(activity.date), DATE_FORMAT.SHORT)}
                </div>
              </div>
            </div>
          </>
        )}
      </ListBox>
      {isInPanel && activities.length > offset && (
        <div className="flex justify-center p-s">
          <Button
            variant="secondary"
            text={t('misc.loadMore')}
            onClick={handleLoadMore}
          />
        </div>
      )}
    </>
  );
};

const formatCardPaymentsToActivities = (
  payments: CardPayment[],
): Activity[] => {
  return payments.map((payment) => ({
    id: payment.id,
    type: 'payment',
    title: payment.description,
    subtitle: payment.supplier?.name ?? null,
    amount: payment.amount_declared,
    currency: payment.currency_declared,
    date: payment.paid_at ?? payment.created_at,
  }));
};

const formatCardActionsToActivity = (
  actions: CardActivity[],
  t: TGlobalFunctionTyped,
): Activity[] => {
  return actions.map((activity) => ({
    id: activity.id,
    type: 'action',
    title: titleFromActivityType(activity, t),
    subtitle: null,
    amount: null,
    currency: null,
    date: activity.created_at,
  }));
};

const formatCardLoadsToActivities = (
  loads: CardLoad[],
  companyCurrency: CurrenciesKey,
  t: TGlobalFunctionTyped,
): Activity[] => {
  return loads.map((load) => {
    let title =
      load.amount > 0 ? t('cards.feed.loaded') : t('cards.feed.unloaded');

    if (load.by_user) {
      title =
        load.amount > 0
          ? getLoadedBy(load, t)
          : t('cards.feed.unloadedBy', { name: load.by_user });
    }

    return {
      id: load.id,
      type: 'load',
      title,
      subtitle: null,
      amount: load.amount,
      currency: companyCurrency,
      date: load.created_at,
    };
  });
};
