import { useLazyQuery } from 'src/core/api/hooks/useLazyQuery';
import { type LazyState } from 'src/core/api/queryState';

export type ExpenseCategoryExpenseAccountRule = {
  expenseAccountIds: string[];
  expenseCategoryId: string;
  companyId: string;
  archivedAt?: Date;
};

export const useGetExpenseCategoryExpenseAccountRulesLazyQuery = (): LazyState<
  ExpenseCategoryExpenseAccountRule[]
> =>
  useLazyQuery<
    ExpenseCategoryExpenseAccountRule[],
    { expenseAccountExpenseCategoryRules: ExpenseCategoryExpenseAccountRule[] }
  >({
    key: 'expenseCategoryExpenseAccountRules',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/bookkeeping/automation/expense-accounts-expense-categories',
    },
    reshapeData: (data: {
      expenseAccountExpenseCategoryRules: ExpenseCategoryExpenseAccountRule[];
    }) => data.expenseAccountExpenseCategoryRules,
  });
