export const getCustomFieldsActivationPendingKey = (companyId: string) =>
  `spx:custom-fields-activation-pending:${companyId}`;

export const SESSION_EXPIRE_LOCAL_STORAGE_KEY = 'spx:last-activity-tracker';

export const LANG_LOCAL_STORAGE_KEY = 'spx:lang';

export const COOKIE_TRACKING_PREFERENCES_SEGMENT = 'tracking-preferences';

export const LAST_REMINDER_LOCAL_STORAGE_KEY =
  'spx:app-desktop:last-reminder-modal';

export const LAST_ACCOUNTING_SETTINGS_REFRESH =
  'spx:app-desktop:last-accounting-settings-refresh';

export const LAST_SERVER_STATUS_REFRESH =
  'spx:app-desktop:last-server-status-refresh';

export enum LocalStorageKey {
  InvitationToken = 'invitation-token',
  DismissSwitchToMobileAppPopup = 'dismissSwitchToMobileAppPopup',
  LastSupervisedUserName = 'last-supervised-user-name',
  LastSupervisedUserId = 'last-supervised-user-id',
  SfsEmptyBankAccountCodeExport = 'sfs-empty-bank-account-code-export',
  /** @knipignore used with process.env.NODE_ENV === 'development' */
  __DEV_ONLY_me = 'dev-only:me',
  AutocatUpsellCalloutClosedDate = 'autocat-upsell-callout-closed-date',
  AutocatUpsellCalloutClosureCount = 'autocat-upsell-callout-closure-count',
  MyCardMobileBannerDismissed = 'my-card-mobile-banner-dismissed',
  OnboardingHubWelcomeAdminModalDismissed = 'onboarding-hub-welcome-admin-modal-dismissed',
  LastOnboardingSectionOpened = 'last-onboarding-section-opened',
  LastUsedExportTemplate = 'last-used-export-template',
  LastUsedExportTemplateV2 = 'last-used-export-template-v2',
  ExpandNavigation = 'expand-navigation',
  OrganisationReportingIntroModalEncountered = 'organisation-reporting-intro-modal-encountered',
  OrganisationReportingPinnedEntities = 'organisation-reporting-pinned-entities',
  PreparePayablesCreationDateHiddenCallout = 'prepare-payables-creation-date-hidden-callout',
  PreparePayablesInvoiceNumberHiddenCallout = 'prepare-payables-invoice-number-hidden-callout',
  EditAfterExportPromoCalloutDismissed = 'edit-after-export-promo-callout-dismissed',
}

export enum SessionStorageKey {
  CheckYourBankStatement = 'ach:check-your-bank-statement:seen-modal',
  LastRejectedInvoiceToResubmitId = 'last-rejected-invoice-to-resubmit-id',
  CardActivated = 'card-activated',
}
