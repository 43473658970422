import { createReducer } from '@reduxjs/toolkit';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import without from 'lodash/without';

import * as types from './actionTypes';
import { type ApiCard } from '../../card';

// TODO: TYPE ACTIONS PAYLOAD

export type CardsState = {
  cards: ApiCard[] | undefined;
  isCardPageLoading: boolean;
  cardPageHasError: boolean;
  selectedCards: string[]; // card IDs
  areAllCardsSelected: boolean;
  hasWalletInsufficientFunds: boolean | undefined;
};

const initialState: CardsState = {
  cards: undefined,
  isCardPageLoading: false,
  cardPageHasError: false,
  selectedCards: [],
  areAllCardsSelected: false,
  hasWalletInsufficientFunds: undefined,
};

const nukeState = () => initialState;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchCardsSuccess = (state: CardsState, action: any) => ({
  ...state,
  cards: action.payload,
  isCardPageLoading: false,
  cardPageHasError: false,
});

const fetchCardsLoading = (state: CardsState) => ({
  ...state,
  isCardPageLoading: true,
  cardPageHasError: false,
});

const fetchCardsError = (state: CardsState) => ({
  ...state,
  isCardPageLoading: false,
  cardPageHasError: true,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateCardSuccess = (state: CardsState, action: any) => {
  const { updatedCard } = action.payload;

  return {
    ...state,
    cards: map(state.cards, (card) => {
      return card.id === updatedCard.id ? updatedCard : card;
    }),
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkCard = (state: CardsState, action: any) => ({
  ...state,
  selectedCards: (state.selectedCards ?? []).concat(action.payload.id),
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const uncheckCard = (state: CardsState, action: any) => {
  const payment = action.payload;
  const selectedCards = without(state.selectedCards, payment.id);
  return { ...state, selectedCards, areAllCardsSelected: false };
};

const checkAllCards = (state: CardsState) => {
  const selectedCards = map(get(state, 'cards', []), (card) => card.id);
  return { ...state, selectedCards, areAllCardsSelected: true };
};
const uncheckAllCards = (state: CardsState) => ({
  ...state,
  selectedCards: [],
  areAllCardsSelected: false,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeCardLocally = (state: CardsState, action: any) => ({
  ...state,
  cards: (state.cards ?? []).filter((card) => card.id !== action.payload),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deleteCardSuccess = (state: CardsState, action: any) => ({
  ...state,
  cards: (state.cards ?? []).filter((card) => card.id !== action.payload),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reloadCardSuccess = (state: CardsState, action: any) => {
  const reloadedCard = action.payload;
  const cards = map(state.cards, (card) => {
    if (card.id === reloadedCard.id) {
      return reloadedCard;
    }
    return card;
  });
  return { ...state, cards };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateCardsLocally = (state: CardsState, action: any) => {
  const updatedCards = action.payload;
  const cards = map(state.cards, (card) => {
    const matchingCard = find(
      updatedCards,
      (updatedCard) => updatedCard.id === card.id,
    );
    if (matchingCard) {
      return { ...card, ...matchingCard };
    }
    return card;
  });
  return { ...state, cards };
};

const fetchHasWalletInsufficientFundsSuccess = (
  state: CardsState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any,
) => {
  return {
    ...state,
    hasWalletInsufficientFunds: action.payload,
  };
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(types.NUKE_CARDS_STATE, nukeState);
  builder.addCase(types.FETCH_PLASTIC_CARDS_SUCCESS, fetchCardsSuccess);
  builder.addCase(types.FETCH_PLASTIC_CARDS_LOADING, fetchCardsLoading);
  builder.addCase(types.FETCH_PLASTIC_CARDS_FAILURE, fetchCardsError);
  builder.addCase(types.UPDATE_PLASTIC_CARD_SUCCESS, updateCardSuccess);
  builder.addCase(types.CHECK_CARD, checkCard);
  builder.addCase(types.UNCHECK_CARD, uncheckCard);
  builder.addCase(types.CHECK_ALL_CARDS, checkAllCards);
  builder.addCase(types.UNCHECK_ALL_CARDS, uncheckAllCards);
  builder.addCase(types.REMOVE_CARD_LOCALLY, removeCardLocally);
  builder.addCase(types.RELOAD_PLASTIC_CARD_SUCCESS, reloadCardSuccess);
  builder.addCase(types.DELETE_CARD_SUCCESS, deleteCardSuccess);
  builder.addCase(types.UPDATE_CARDS_LOCALLY, updateCardsLocally);
  builder.addCase(
    types.FETCH_HAS_WALLET_INSUFFICIENT_FUNDS_SUCCESS,
    fetchHasWalletInsufficientFundsSuccess,
  );
});
