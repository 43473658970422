import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useShouldDisplayBankInformation } from 'modules/company/general-settings';
import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = Readonly<{
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
  };
  className?: string;
  onClick?: () => void;
}>;

export const GeneralSettingsLink = ({ company, className, onClick }: Props) => {
  const { t } = useTranslation('global');
  const shouldDisplayBankInformation = useShouldDisplayBankInformation();
  const { pathname: currentPath } = useLocation();

  let route: string;
  if (shouldDisplayBankInformation) {
    route = routes.COMPANY_GENERAL_SETTINGS_BANK_INFORMATION.path;
  } else {
    route = routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path;
  }

  return (
    <NavigationLink
      data-marketing="top-menu_general-settings"
      key="nav_general_settings"
      className={className}
      onClick={onClick}
      path={routeFor(route, {
        company: company.id,
      })}
      text={t('settings.links.generalSettings')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [routes.COMPANY_GENERAL_SETTINGS.path],
        }),
      )}
    />
  );
};
