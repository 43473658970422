import { transformError } from '../helpers';
import {
  type AchState,
  type AchAction,
  GET_ACH_FUNDING_SOURCES_LOADING,
  GET_ACH_FUNDING_SOURCES_FAILURE,
  GET_ACH_FUNDING_SOURCES_SUCCESS,
  CHECK_BANK_STATEMENT_LOADING,
  CHECK_BANK_STATEMENT_FAILURE,
  CHECK_BANK_STATEMENT_SUCCESS,
  GET_AUTOMATED_FUNDING_RULE_LOADING,
  GET_AUTOMATED_FUNDING_RULE_FAILURE,
  GET_AUTOMATED_FUNDING_RULE_SUCCESS,
  CREATE_FUNDING_REQUEST_LOADING,
  CREATE_FUNDING_REQUEST_FAILURE,
  CREATE_FUNDING_REQUEST_SUCCESS,
  CREATE_AUTOMATED_FUNDING_RULE_LOADING,
  CREATE_AUTOMATED_FUNDING_RULE_FAILURE,
  CREATE_AUTOMATED_FUNDING_RULE_SUCCESS,
  DELETE_AUTOMATED_FUNDING_RULE_LOADING,
  DELETE_AUTOMATED_FUNDING_RULE_FAILURE,
  DELETE_AUTOMATED_FUNDING_RULE_SUCCESS,
  VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_LOADING,
  VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_FAILURE,
  VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_SUCCESS,
  VALIDATE_AUTOMATED_FUNDING_RULE_PIN_LOADING,
  VALIDATE_AUTOMATED_FUNDING_RULE_PIN_FAILURE,
  VALIDATE_AUTOMATED_FUNDING_RULE_PIN_SUCCESS,
  GET_GPA_ORDERS_LOADING,
  GET_GPA_ORDERS_FAILURE,
  GET_GPA_ORDERS_SUCCESS,
  SET_WALLET_FUNDING_STEP,
  SET_WALLET_AUTOMATED_FUNDING_STEP,
  SET_WALLET_AUTOMATED_FUNDING_CREATE_STEP,
  SET_WALLET_AUTOMATED_FUNDING_DELETE_STEP,
  SET_MFA_PROCEDURE_ID,
  SET_MFA_TYPE,
  SET_MFA_PARAMS,
  type GetAchFundingSourcesSuccess,
  OneOffFundingRequestCreateSteps,
  AutomatedFundingRuleLoadingSteps,
  AutomatedFundingRuleDeleteSteps,
  AutomatedFundingRuleCreateSteps,
  type GpaOrder,
  type AutomatedFundingRule,
} from '../types';

export const initialState: AchState = {
  hasPendingAch: false,
  hasValidatedAch: false,
  oneOffFundingRequestCreateStep:
    OneOffFundingRequestCreateSteps.OneOffFundingRequestCreateForm,
  automatedFundingRuleLoadingStep:
    AutomatedFundingRuleLoadingSteps.AutomatedFundingRuleToBeLoaded,
  automatedFundingRuleDeleteStep:
    AutomatedFundingRuleDeleteSteps.AutomatedFundingRuleDeleteForm,
  automatedFundingRuleCreateStep:
    AutomatedFundingRuleCreateSteps.AutomatedFundingRuleCreateForm,
  automatedFundingRuleAmount: null,
  automatedFundingRuleThreshold: null,
  automatedFundingRuleCreatedAt: null,
  pendingAchCreatedAt: null,
  achSourceToken: null,
  achSourceAccountSuffix: null,
  mfaProcedureId: null,
  mfaType: null,
  mfaParams: null,
  gpaOrders: [],
  isLoading: false,
  error: null,
  hasJustCreatedAutomatedFundingRule: false,
  hasJustCreatedOneOffFundingRequest: false,
  fundingActionsCount: 0,
};

const getAchFundingSourcesLoading = (state: AchState): AchState => ({
  ...state,
  isLoading: true,
  error: null,
});

const getAchFundingSourcesFailure = (
  state: AchState,
  error: Error,
): AchState => ({
  ...state,
  isLoading: false,
  error: transformError(error),
});

const getAchFundingSourcesSuccess = (
  state: AchState,
  {
    achSourceToken,
    hasPendingAch,
    hasValidatedAch,
    pendingAchCreatedAt,
    achSourceAccountSuffix,
  }: GetAchFundingSourcesSuccess['payload'],
): AchState => ({
  ...state,
  achSourceToken,
  achSourceAccountSuffix,
  hasPendingAch,
  hasValidatedAch,
  pendingAchCreatedAt,
  isLoading: false,
  error: null,
});

const checkBankStatementLoading = (state: AchState): AchState => ({
  ...state,
  isLoading: true,
  error: null,
});

const checkBankStatementFailure = (
  state: AchState,
  error: Error,
): AchState => ({
  ...state,
  isLoading: false,
  error: transformError(error),
});

const checkBankStatementSuccess = (state: AchState): AchState => ({
  ...state,
  hasPendingAch: false,
  hasValidatedAch: true,
  pendingAchCreatedAt: null,
  isLoading: false,
  error: null,
});

const getAutomatedFundingRuleLoading = (state: AchState): AchState => ({
  ...state,
  isLoading: true,
  error: null,
  automatedFundingRuleAmount: null,
  automatedFundingRuleThreshold: null,
  automatedFundingRuleLoadingStep:
    AutomatedFundingRuleLoadingSteps.AutomatedFundingRuleLoading,
});

const getAutomatedFundingRuleFailure = (
  state: AchState,
  error: Error,
): AchState => ({
  ...state,
  isLoading: false,
  error: transformError(error),
  automatedFundingRuleAmount: null,
  automatedFundingRuleThreshold: null,
});

const getAutomatedFundingRuleSuccess = (
  state: AchState,
  automatedFundingRule: AutomatedFundingRule,
): AchState => ({
  ...state,
  error: null,
  automatedFundingRuleAmount: automatedFundingRule.amount,
  automatedFundingRuleThreshold: automatedFundingRule.threshold,
  automatedFundingRuleCreatedAt: automatedFundingRule.createdAt,
});

const createFundingRequestLoading = (state: AchState): AchState => ({
  ...state,
  isLoading: true,
  error: null,
});

const createFundingRequestFailure = (
  state: AchState,
  error: Error,
): AchState => ({
  ...state,
  isLoading: false,
  error: transformError(error),
});

const createFundingRequestSuccess = (
  state: AchState,
  { mfaProcedureId }: { mfaProcedureId: string },
): AchState => {
  return {
    ...state,
    oneOffFundingRequestCreateStep:
      OneOffFundingRequestCreateSteps.OneOffFundingRequestCreateModal,
    mfaProcedureId,
    isLoading: false,
    error: null,
  };
};

const createAutomatedFundingRuleLoading = (state: AchState): AchState => ({
  ...state,
  isLoading: true,
  error: null,
});

const createAutomatedFundingRuleFailure = (
  state: AchState,
  error: Error,
): AchState => ({
  ...state,
  isLoading: false,
  error: transformError(error),
});

const createAutomatedFundingRuleSuccess = (
  state: AchState,
  { mfaProcedureId }: { mfaProcedureId: string },
): AchState => {
  return {
    ...state,
    mfaProcedureId,
    automatedFundingRuleCreateStep:
      AutomatedFundingRuleCreateSteps.AutomatedFundingRuleCreateModal,
    error: null,
  };
};

const deleteAutomatedFundingRuleLoading = (state: AchState): AchState => ({
  ...state,
  isLoading: true,
  error: null,
});

const deleteAutomatedFundingRuleFailure = (
  state: AchState,
  error: Error,
): AchState => ({
  ...state,
  isLoading: false,
  error: transformError(error),
});

const deleteAutomatedFundingRuleSuccess = (state: AchState): AchState => {
  return {
    ...state,
    automatedFundingRuleCreateStep:
      AutomatedFundingRuleCreateSteps.AutomatedFundingRuleCreateForm,
    automatedFundingRuleDeleteStep:
      AutomatedFundingRuleDeleteSteps.AutomatedFundingRuleDeleteForm,
    automatedFundingRuleLoadingStep:
      AutomatedFundingRuleLoadingSteps.AutomatedFundingRuleToBeLoaded,
    automatedFundingRuleAmount: null,
    automatedFundingRuleThreshold: null,
  };
};

const setMfaProcedureId = (
  state: AchState,
  mfaProcedureId: string,
): AchState => ({
  ...state,
  mfaProcedureId,
  isLoading: false,
  error: null,
});

const setMfaType = (state: AchState, mfaType: string): AchState => ({
  ...state,
  mfaType,
});

const setMfaParams = (state: AchState, mfaParams: string): AchState => ({
  ...state,
  mfaParams,
});

const validateOneOffFundingRequestPinLoading = (state: AchState): AchState => ({
  ...state,
  isLoading: true,
  error: null,
});

const validateOneOffFundingRequestPinFailure = (
  state: AchState,
  error: Error,
): AchState => ({
  ...state,
  isLoading: false,
  error: transformError(error),
});

const validateOneOffFundingRequestPinSuccess = (state: AchState): AchState => ({
  ...state,
  oneOffFundingRequestCreateStep:
    OneOffFundingRequestCreateSteps.OneOffFundingRequestCreateForm,
  hasJustCreatedOneOffFundingRequest: true,
  isLoading: false,
  error: null,
  fundingActionsCount: state.fundingActionsCount + 1,
});

const validateAutomatedFundingRulePinLoading = (state: AchState): AchState => ({
  ...state,
  isLoading: true,
  error: null,
});

const validateAutomatedFundingRulePinFailure = (
  state: AchState,
  error: Error,
): AchState => ({
  ...state,
  isLoading: false,
  error: transformError(error),
});

const validateAutomatedFundingRulePinSuccess = (state: AchState): AchState => ({
  ...state,
  automatedFundingRuleCreateStep:
    AutomatedFundingRuleCreateSteps.AutomatedFundingRuleCreateForm,
  automatedFundingRuleLoadingStep:
    AutomatedFundingRuleLoadingSteps.AutomatedFundingRuleToBeLoaded,
  hasJustCreatedAutomatedFundingRule: true,
  error: null,
  fundingActionsCount: state.fundingActionsCount + 1,
});

const setWalletOneOffFundingStep = (
  state: AchState,
  oneOffFundingRequestCreateStep: OneOffFundingRequestCreateSteps,
): AchState => ({
  ...state,
  oneOffFundingRequestCreateStep,
});

const setWalletAutomatedFundingStep = (
  state: AchState,
  automatedFundingRuleLoadingStep: AutomatedFundingRuleLoadingSteps,
): AchState => ({
  ...state,
  automatedFundingRuleLoadingStep,
});

const setWalletAutomatedFundingDeleteStep = (
  state: AchState,
  automatedFundingRuleDeleteStep: AutomatedFundingRuleDeleteSteps,
): AchState => ({
  ...state,
  automatedFundingRuleDeleteStep,
});

const setWalletAutomatedFundingCreateStep = (
  state: AchState,
  automatedFundingRuleCreateStep: AutomatedFundingRuleCreateSteps,
): AchState => ({
  ...state,
  automatedFundingRuleCreateStep,
});

const getGpaOrdersLoading = (state: AchState): AchState => ({
  ...state,
  isLoading: true,
  error: null,
});

const getGpaOrdersFailure = (state: AchState, error: Error): AchState => ({
  ...state,
  isLoading: false,
  error: transformError(error),
});

const getGpaOrdersSuccess = (
  state: AchState,
  gpaOrders: GpaOrder[],
): AchState => ({
  ...state,
  gpaOrders,
  isLoading: false,
  error: null,
});

export const reducer = (
  state: AchState = initialState,
  action: AchAction,
): AchState => {
  switch (action.type) {
    default:
      return state;
    case GET_ACH_FUNDING_SOURCES_LOADING:
      return getAchFundingSourcesLoading(state);
    case GET_ACH_FUNDING_SOURCES_FAILURE:
      return getAchFundingSourcesFailure(state, action.payload);
    case GET_ACH_FUNDING_SOURCES_SUCCESS:
      return getAchFundingSourcesSuccess(state, action.payload);
    case CHECK_BANK_STATEMENT_LOADING:
      return checkBankStatementLoading(state);
    case CHECK_BANK_STATEMENT_FAILURE:
      return checkBankStatementFailure(state, action.payload);
    case CHECK_BANK_STATEMENT_SUCCESS:
      return checkBankStatementSuccess(state);
    case CREATE_FUNDING_REQUEST_LOADING:
      return createFundingRequestLoading(state);
    case CREATE_FUNDING_REQUEST_FAILURE:
      return createFundingRequestFailure(state, action.payload);
    case CREATE_FUNDING_REQUEST_SUCCESS:
      return createFundingRequestSuccess(state, action.payload);
    case GET_AUTOMATED_FUNDING_RULE_LOADING:
      return getAutomatedFundingRuleLoading(state);
    case GET_AUTOMATED_FUNDING_RULE_FAILURE:
      return getAutomatedFundingRuleFailure(state, action.payload);
    case GET_AUTOMATED_FUNDING_RULE_SUCCESS:
      return getAutomatedFundingRuleSuccess(state, action.payload);
    case CREATE_AUTOMATED_FUNDING_RULE_LOADING:
      return createAutomatedFundingRuleLoading(state);
    case CREATE_AUTOMATED_FUNDING_RULE_FAILURE:
      return createAutomatedFundingRuleFailure(state, action.payload);
    case CREATE_AUTOMATED_FUNDING_RULE_SUCCESS:
      return createAutomatedFundingRuleSuccess(state, action.payload);
    case DELETE_AUTOMATED_FUNDING_RULE_LOADING:
      return deleteAutomatedFundingRuleLoading(state);
    case DELETE_AUTOMATED_FUNDING_RULE_FAILURE:
      return deleteAutomatedFundingRuleFailure(state, action.payload);
    case DELETE_AUTOMATED_FUNDING_RULE_SUCCESS:
      return deleteAutomatedFundingRuleSuccess(state);
    case SET_MFA_PROCEDURE_ID:
      return setMfaProcedureId(state, action.payload);
    case SET_MFA_TYPE:
      return setMfaType(state, action.payload);
    case SET_MFA_PARAMS:
      return setMfaParams(state, action.payload);
    case VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_LOADING:
      return validateOneOffFundingRequestPinLoading(state);
    case VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_FAILURE:
      return validateOneOffFundingRequestPinFailure(state, action.payload);
    case VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_SUCCESS:
      return validateOneOffFundingRequestPinSuccess(state);
    case VALIDATE_AUTOMATED_FUNDING_RULE_PIN_LOADING:
      return validateAutomatedFundingRulePinLoading(state);
    case VALIDATE_AUTOMATED_FUNDING_RULE_PIN_FAILURE:
      return validateAutomatedFundingRulePinFailure(state, action.payload);
    case VALIDATE_AUTOMATED_FUNDING_RULE_PIN_SUCCESS:
      return validateAutomatedFundingRulePinSuccess(state);
    case SET_WALLET_FUNDING_STEP:
      return setWalletOneOffFundingStep(state, action.payload);
    case SET_WALLET_AUTOMATED_FUNDING_STEP:
      return setWalletAutomatedFundingStep(state, action.payload);
    case SET_WALLET_AUTOMATED_FUNDING_DELETE_STEP:
      return setWalletAutomatedFundingDeleteStep(state, action.payload);
    case SET_WALLET_AUTOMATED_FUNDING_CREATE_STEP:
      return setWalletAutomatedFundingCreateStep(state, action.payload);
    case GET_GPA_ORDERS_LOADING:
      return getGpaOrdersLoading(state);
    case GET_GPA_ORDERS_FAILURE:
      return getGpaOrdersFailure(state, action.payload);
    case GET_GPA_ORDERS_SUCCESS:
      return getGpaOrdersSuccess(state, action.gpaOrders);
  }
};
