export type WireTransferPayableEntity = 'expenses' | 'invoices';

type FeatureNotAvailableReason =
  | 'noWireTransferFeature'
  | 'accountIsNotEligibleForWireTransfer';
type CannotActivateReason =
  | 'ongoingKybProcess'
  | 'providerSetupFailed'
  | 'providerSetupPending'
  | 'missingCompanyBankInfo';
type CannotDeactivateReason =
  | 'ongoingWireTransferRequests'
  | 'inAppDeactivationNotAllowed';

export type WireTransferCannotToggleReason =
  | CannotActivateReason
  | CannotDeactivateReason
  | FeatureNotAvailableReason;

export const isCannotActivateReason = (
  reason: WireTransferCannotToggleReason,
): reason is CannotActivateReason =>
  reason === 'ongoingKybProcess' ||
  reason === 'providerSetupFailed' ||
  reason === 'providerSetupPending' ||
  reason === 'missingCompanyBankInfo';

export const isFeatureNotAvailableReason = (
  reason: WireTransferCannotToggleReason,
): reason is FeatureNotAvailableReason =>
  reason === 'noWireTransferFeature' ||
  reason === 'accountIsNotEligibleForWireTransfer';

type DeactivationToggleStatus =
  | { canToggle: true }
  | {
      canToggle: false;
      reason: CannotDeactivateReason;
    };

type ActivationToggleStatus =
  | { canToggle: true }
  | {
      canToggle: false;
      reason: CannotActivateReason | FeatureNotAvailableReason;
    };

export type WireTransferActivationStatus = {
  isProviderBalanceNegative: boolean;
} & (
  | {
      isActivated: true;
      toggle: DeactivationToggleStatus;
      isVerifyingBankAccount: boolean;
    }
  | {
      isActivated: false;
      toggle: ActivationToggleStatus;
      isVerifyingBankAccount: boolean;
    }
);

export const isWireTransferFeatureAvailable = (
  wireTransferActivationStatus: WireTransferActivationStatus,
): boolean =>
  !(
    !wireTransferActivationStatus.toggle.canToggle &&
    isFeatureNotAvailableReason(wireTransferActivationStatus.toggle.reason)
  );
