import { PageModal } from '@dev-spendesk/grapes';
import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import { type BreakdownPart } from '../../models/breakdown';
import { type ExpenseCategoryBreakdown } from '../../models/expenseCategoryBreakdown';
import { type PeriodRangePayload } from '../../models/period';
import { BreakdownSpendingsPageDrawerContent } from '../BreakdownSpendingsPageDrawerContent';

type Props = {
  isOpen: boolean;
  budgetId: string;
  title: string;
  breakdownPart: BreakdownPart;
  breakdownComputedAt: Date;
  periodRange: PeriodRangePayload;
  budgetTotalAmount: MonetaryValue;
  expenseCategory?: ExpenseCategoryBreakdown['expenseCategory'];
  onCloseDrawer(): void;
};

export const BreakdownSpendingsPageDrawer = ({
  isOpen,
  budgetId,
  title,
  breakdownPart,
  breakdownComputedAt,
  periodRange,
  budgetTotalAmount,
  expenseCategory,
  onCloseDrawer,
}: Props) => {
  return (
    <PageModal isOpen={isOpen} onClose={onCloseDrawer}>
      <BreakdownSpendingsPageDrawerContent
        budgetId={budgetId}
        title={title}
        breakdownPart={breakdownPart}
        breakdownComputedAt={breakdownComputedAt}
        periodRange={periodRange}
        budgetTotalAmount={budgetTotalAmount}
        expenseCategory={expenseCategory}
      />
    </PageModal>
  );
};
