import {
  isQueryErrorState,
  isQueryLoadingState,
  type QueryState,
} from '../queryState';

export function useQueryStates<
  TResult,
  TData extends { [key in string]: unknown },
>(input: {
  states: { [key in keyof TData]: QueryState<TData[key]> };
  reshapeData(data: TData): TResult;
}): QueryState<TResult>;

export function useQueryStates<
  TData extends { [key in string]: unknown },
>(input: {
  states: { [key in keyof TData]: QueryState<TData[key]> };
  reshapeData?: undefined; // this allows the key to appear in the autocomplete
}): QueryState<TData>;

export function useQueryStates<
  TResult,
  TData extends { [key in string]: unknown },
>(input: {
  states: { [key in keyof TData]: QueryState<TData[key]> };
  reshapeData?(data: TData): TResult;
}) {
  const { states, reshapeData } = input;

  const statesValues = Object.keys(states).map((key) => states[key]);

  const isLoading = statesValues.some(isQueryLoadingState);
  if (isLoading) {
    return {
      status: 'loading',
    };
  }

  const erroredState = statesValues.find(isQueryErrorState);
  if (erroredState) {
    return {
      status: 'error',
      error: erroredState.error,
    };
  }

  const data = {} as TData;
  for (const key in states) {
    const state = states[key];

    // we know the state is "success", but typescript doesn't
    if (state.status !== 'success') {
      continue;
    }

    data[key] = state.data;
  }

  return {
    status: 'success',
    data: reshapeData ? reshapeData(data) : data,
  };
}
