import { type I18nKey } from 'src/core/common/hooks/useTranslation';

import { useCompany } from './useCompany';

export const useIsCurrencyBranchAllowed = () => {
  const company = useCompany();
  const bankingProvider = company.banking_provider;

  if (bankingProvider === 'bankable') {
    return { isAllowed: true, reasonKey: '' as I18nKey };
  }

  if (bankingProvider === 'sfs') {
    return { isAllowed: true, reasonKey: '' as I18nKey };
  }

  return {
    isAllowed: false,
    reasonKey: 'branch.doNotSupportCurrencyBranch' as I18nKey,
  };
};
