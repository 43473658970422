import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  user_id: string;
  endpointParams: {
    policyId: string;
  };
};

export const useUpdateUserPolicyMutation = () => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: ({ policyId }) => `/userpolicies/${policyId}`,
      method: 'put',
    },
    options: {
      throwOnError: true,
    },
  });
};
