import React from 'react';

import { TypeGuardError } from 'src/core/utils/switchGuard';

import { type Integration } from '../../../types';
import { isKomboIntegration } from '../../../utils';
import { IntegrationBenefits } from '../IntegrationBenefits/IntegrationBenefits';

type Props = {
  integration: Integration;
  noSeparator?: boolean;
};

export const IntegrationBenefitsBox = ({ noSeparator, ...props }: Props) => {
  return (
    <>
      <Benefits {...props} />
      {noSeparator ? null : (
        <div className="flex h-[1px] w-full flex-col rounded-xxs bg-neutral-light" />
      )}
    </>
  );
};

const Benefits = (props: Props) => {
  const { integration } = props;

  if (integration.id === 'travelperk') {
    return (
      <div className="box flex flex-col gap-s">
        <IntegrationBenefits integration={integration} />
      </div>
    );
  }

  if (isKomboIntegration(integration.id)) {
    return (
      <div className="flex flex-col gap-s">
        <IntegrationBenefits integration={integration} />
      </div>
    );
  }
  if (integration.id === 'spendesk-oauth2') {
    return null;
  }

  throw new TypeGuardError(
    integration.id,
    'IntegrationBenefits: Unknown integrationId',
  );
};
