import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import { CompanyDropdown } from './CompanyDropdown';
import { CompanyDropdownToggleButton } from './CompanyDropdownToggleButton';
import { useCompanyWalletSummariesQuery } from '../../hooks/useCompanyWalletSummariesQuery';

export const CompanyDropdownContainer = () => {
  const user = useUser();
  const companyId = useCompanyId();

  const companyWalletSummariesQueryState =
    useCompanyWalletSummariesQuery(companyId);

  if (
    companyWalletSummariesQueryState.status === 'loading' ||
    companyWalletSummariesQueryState.status === 'error'
  ) {
    return null;
  }

  const companies = companyWalletSummariesQueryState.data;
  if (
    companies.length === 0 ||
    (companies.length === 1 && !user.is_controller)
  ) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentCompany = companies.find((company) => company.id === companyId)!;

  return (
    <CompanyDropdown companies={companies}>
      {(triggerProps, isOpen) => {
        return (
          <CompanyDropdownToggleButton
            company={currentCompany}
            isDropdownOpen={isOpen}
            getToggleButtonProps={triggerProps}
          />
        );
      }}
    </CompanyDropdown>
  );
};
