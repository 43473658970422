import * as types from './actionTypes';

type Procedure = {
  procedureId: string;
};

type RequestScaProcedureSuccess = {
  type: types.REQUEST_SCA_PROCEDURE_SUCCESS;
  payload: Procedure;
};

export type SCAAction = RequestScaProcedureSuccess;

export const requestScaProcedureSuccess = (
  procedure: Procedure,
): RequestScaProcedureSuccess => ({
  type: types.REQUEST_SCA_PROCEDURE_SUCCESS,
  payload: procedure,
});
