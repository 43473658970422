import { Navigation } from '@dev-spendesk/grapes';
import { useLocation } from 'react-router-dom';

import { NavigationLink } from 'common/components/NavigationLink';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';

import { type RequestsStatByType, SubnavigationItem } from '../../utils';

type RequestsSubNavProps = {
  stats: RequestsStatByType;
};

export const RequestsSubNav = ({ stats }: RequestsSubNavProps) => {
  const companyId = useCompanyId();
  const { t } = useTranslation('global');

  const isSubmitMyInvoiceFeatureEnabled = useFeature(
    FEATURES.SUBMIT_MY_INVOICE,
  );
  const hasInboxInvoiceFeature = useFeature(FEATURES.BILL_INBOX);
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  const { search: filters } = useLocation();

  const getLeftNavigationItems = () => {
    const links = [];

    links.push(
      <NavigationLink
        key="sub_nav_your_requests"
        path={`${routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: SubnavigationItem.Mine,
        })}${filters}`}
        text={t('requests.yourRequests')}
        count={stats.mine}
      />,
    );

    links.push(
      <NavigationLink
        key={SubnavigationItem.All}
        path={`${routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: SubnavigationItem.All,
        })}${filters}`}
        text={t('requests.allSection')}
        count={stats.all}
      />,
    );

    if (isSubmitMyInvoiceFeatureEnabled) {
      links.push(
        <NavigationLink
          key={SubnavigationItem.Drafts}
          path={`${routeFor(routes.REQUESTS.path, {
            company: companyId,
            type: SubnavigationItem.Drafts,
          })}${filters}`}
          text={t('requests.draftsSection')}
          count={stats.drafts}
        />,
      );
    }

    if (hasInboxInvoiceFeature && !hasNewNavigation) {
      links.unshift(
        <NavigationLink
          key={SubnavigationItem.InboxInvoices}
          path={routeFor(routes.INBOX_INVOICES.path, {
            company: companyId,
          })}
          text={t('requests.inboxInvoicesSection')}
          count={stats.inbox_invoices}
          withDivider
        />,
      );
    }

    return links;
  };

  return (
    <Navigation
      variant="subNavigation"
      aria-label="Sub navigation"
      leftNavigationItems={getLeftNavigationItems()}
      className="shrink-0"
    />
  );
};
