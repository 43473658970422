import {
  Button,
  FormField,
  Input,
  SkeletonText,
  SwitchField,
  TextInput,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import {
  type IntegrationVatAccount,
  type TaxAccountUpdate,
  getDefaultTaxAccountName,
} from 'modules/bookkeep';
import { useHasAuxiliaryAccountsEnabled } from 'modules/bookkeep/hooks';
import {
  type MutationQueryState,
  type QueryState,
} from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './TaxAccountLocalOnlySection.module.css';
import { type IntegrationStatusWithIntegration } from '../../../../../../../integration/status';
import { type Result as GetDefaultTaxAccountQueryResult } from '../../../../../hooks/useGetDefaultTaxAccountQuery';
import { type Result as SetDefaultTaxAccountMutationResult } from '../../../../../hooks/useSetDefaultTaxAccountMutation';
import { DefaultAccountDeleteConfirmationModal } from '../../../components/DefaultAccountConfirmationModal/DefaultAccountDeleteConfirmationModal';
import { DefaultAccountEditConfirmationModal } from '../../../components/DefaultAccountConfirmationModal/DefaultAccountEditConfirmationModal';
import { validateTaxAccount } from '../../../utils';

type ErrorState = {
  error:
    | 'nameAndRateAlreadyExists'
    | 'required'
    | 'codeRequired'
    | 'rateRequired'
    | 'negativeTaxRate'
    | 'taxRateExceedsMaximum'
    | 'unknown';
  existingAccount?: IntegrationVatAccount;
};

function getErrorTranslation(
  errorState: ErrorState,
  t: TGlobalFunctionTyped,
): string | undefined {
  switch (errorState.error) {
    case 'required':
    case 'rateRequired':
    case 'codeRequired':
      return t(
        'bookkeep.integrations.settings.defaultTaxAccountForm.requiredError',
      );
    case 'nameAndRateAlreadyExists':
      return t('misc.errors.unknownError');
    case 'negativeTaxRate':
      return t(
        'bookkeep.integrations.settings.defaultTaxAccountForm.negativeTaxRateError',
      );
    case 'taxRateExceedsMaximum':
      return t(
        'bookkeep.integrations.settings.defaultTaxAccountForm.taxRateExceedsMaximumError',
      );
    case 'unknown':
      return t('misc.loadingError');
    default:
      return undefined;
  }
}

interface Props {
  onDefaultChange: (defaultTaxAccount: TaxAccountUpdate) => Promise<void>;
  defaultTaxAccount: IntegrationVatAccount | undefined;
  getDefaultTaxAccountQueryState: QueryState<GetDefaultTaxAccountQueryResult>;
  setDefaultTaxAccountQueryState: MutationQueryState<SetDefaultTaxAccountMutationResult>;
  integrationStatus: IntegrationStatusWithIntegration;
  isInitialToggleChecked: boolean;
}

export const DefaultTaxAccountForm = ({
  onDefaultChange,
  defaultTaxAccount,
  getDefaultTaxAccountQueryState,
  setDefaultTaxAccountQueryState,
  integrationStatus,
  isInitialToggleChecked,
}: Props) => {
  const { t } = useTranslation('global');
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();
  const [newDefaultTaxAccount, setNewDefaultTaxAccount] = useState(
    getInitialDefaultAccount(integrationStatus, defaultTaxAccount),
  );
  const [errorState, setErrorState] = useState<ErrorState | undefined>();
  const [codeInputChanged, setCodeInputChanged] = useState(false);
  const [rateInputChanged, setRateInputChanged] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  const [isToggleChecked, setIsToggleChecked] = useState(
    isInitialToggleChecked,
  );

  const [modalState, setModalState] = useState<{
    kind: 'closed' | 'confirmEdit' | 'confirmDelete';
  }>({ kind: 'closed' });

  useEffect(() => {
    setIsToggleChecked(isInitialToggleChecked);
  }, [isInitialToggleChecked]);

  useEffect(() => {
    setValueChanged(false);
    setNewDefaultTaxAccount(
      getInitialDefaultAccount(integrationStatus, defaultTaxAccount),
    );
  }, [defaultTaxAccount]);

  useEffect(() => {
    if (
      setDefaultTaxAccountQueryState.status === 'success' &&
      setDefaultTaxAccountQueryState.data.outcome === 'notSet' &&
      setDefaultTaxAccountQueryState.data.reason === 'nameAndRateAlreadyExists'
    ) {
      setErrorState({
        error: 'nameAndRateAlreadyExists',
        existingAccount: setDefaultTaxAccountQueryState.data.existingAccount,
      });
    } else if (
      setDefaultTaxAccountQueryState.status === 'success' &&
      setDefaultTaxAccountQueryState.data.outcome === 'notSet' &&
      (setDefaultTaxAccountQueryState.data.reason === 'negativeTaxRate' ||
        setDefaultTaxAccountQueryState.data.reason === 'taxRateExceedsMaximum')
    ) {
      setErrorState({
        error: setDefaultTaxAccountQueryState.data.reason,
      });
    } else if (
      setDefaultTaxAccountQueryState.status === 'error' ||
      (setDefaultTaxAccountQueryState.status === 'success' &&
        setDefaultTaxAccountQueryState.data.outcome === 'notSet')
    ) {
      setErrorState({
        error: 'unknown',
      });
    }
  }, [setDefaultTaxAccountQueryState]);

  const handleSwitchToggle = () => {
    if (defaultTaxAccount) {
      if (isToggleChecked) {
        setModalState({ kind: 'confirmDelete' });
        return;
      }

      onDefaultChange({ ...defaultTaxAccount, isArchived: isToggleChecked });
    }
    setIsToggleChecked(!isToggleChecked);
  };

  const handleSubmit = () => {
    const result = validateTaxAccount(newDefaultTaxAccount);

    setCodeInputChanged(false);
    setRateInputChanged(false);

    if (result.outcome === 'valid') {
      setErrorState(undefined);

      // don't do anything if the Expense account hasn't change
      if (
        !defaultTaxAccount ||
        defaultTaxAccount.code !== newDefaultTaxAccount.code ||
        defaultTaxAccount.rate !== newDefaultTaxAccount.rate
      ) {
        setModalState({ kind: 'confirmEdit' });
      }
      return;
    }

    setErrorState({
      error: result.reason,
    });
  };

  const hasRateError =
    !!errorState &&
    !rateInputChanged &&
    (errorState.error === 'negativeTaxRate' ||
      errorState.error === 'taxRateExceedsMaximum' ||
      errorState.error === 'rateRequired' ||
      errorState.error === 'nameAndRateAlreadyExists' ||
      errorState.error === 'required');

  const hasCodeError =
    !!errorState &&
    !codeInputChanged &&
    errorState.error !== 'negativeTaxRate' &&
    errorState.error !== 'taxRateExceedsMaximum' &&
    errorState.error !== 'rateRequired' &&
    errorState.error !== 'nameAndRateAlreadyExists';

  if (getDefaultTaxAccountQueryState.status === 'loading') {
    return (
      <div className="box mt-xl">
        <SkeletonText size="xl" />
        <SkeletonText size="m" />
      </div>
    );
  }

  return (
    <div className="box mt-xl">
      <SwitchField
        fit="parent"
        id="useDefaultAccount"
        name="useDefaultAccount"
        label={t('bookkeep.integrations.settings.defaultTaxAccountForm.title')}
        helpText={t(
          'bookkeep.integrations.settings.defaultTaxAccountForm.description',
        )}
        isChecked={isToggleChecked && !!defaultTaxAccount}
        onChange={handleSwitchToggle}
      />
      {isToggleChecked && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.defaultForm__inputWrapper}>
            <FormField
              className={styles.defaultForm__input}
              label={t(
                'bookkeep.integrations.settings.defaultTaxAccountForm.codeName',
              )}
              htmlFor="set-account-code-input"
              alertMessage={
                hasCodeError ? getErrorTranslation(errorState, t) : undefined
              }
            >
              <TextInput
                placeholder={t(
                  'bookkeep.integrations.settings.defaultTaxAccountForm.codePlaceholder',
                )}
                isInvalid={hasCodeError}
                value={newDefaultTaxAccount?.code ?? ''}
                onChange={(e) => {
                  const code = e.target.value;
                  setNewDefaultTaxAccount({
                    ...newDefaultTaxAccount,
                    code,
                    isArchived: false,
                  });
                  setCodeInputChanged(true);
                  setValueChanged(defaultTaxAccount?.code !== code);
                  setErrorState(undefined);
                }}
                id="set-account-code-input"
              />
            </FormField>
            <FormField
              className={styles.defaultForm__input}
              label={t(
                'bookkeep.integrations.settings.defaultTaxAccountForm.rateName',
              )}
              htmlFor="set-account-rate-input"
              alertMessage={
                hasRateError ? getErrorTranslation(errorState, t) : undefined
              }
            >
              <Input
                placeholder={t(
                  'bookkeep.integrations.settings.defaultTaxAccountForm.ratePlaceholder',
                )}
                isInvalid={hasRateError}
                value={
                  newDefaultTaxAccount?.rate
                    ? Number.parseFloat(newDefaultTaxAccount.rate)
                    : ''
                }
                type="number"
                rightAddon={
                  <div className={classNames(styles.rateRightAddon, 'body-m')}>
                    %
                  </div>
                }
                min={0}
                max={100}
                step="0.01"
                onChange={(e) => {
                  const rate = e.target.value;
                  setNewDefaultTaxAccount({
                    ...newDefaultTaxAccount,
                    rate,
                    isArchived: false,
                  });
                  setRateInputChanged(true);
                  setValueChanged(defaultTaxAccount?.rate !== rate);
                  setErrorState(undefined);
                }}
                id="set-account-rate-input"
              />
            </FormField>
          </div>

          <Button
            variant={valueChanged ? 'primary' : 'secondary'}
            text={t('misc.saveChanges')}
            className={styles.defaultForm__submit}
            isDisabled={
              !valueChanged ||
              setDefaultTaxAccountQueryState.status === 'loading'
            }
            type="submit"
          />

          {newDefaultTaxAccount ? (
            <DefaultAccountEditConfirmationModal
              integration={integrationStatus.integration}
              isOpen={modalState.kind === 'confirmEdit'}
              setModalState={setModalState}
              onSetDefault={onDefaultChange}
              existingDefaultAccount={defaultTaxAccount}
              newDefaultAccount={newDefaultTaxAccount}
              actionOrigin="defaultTaxAccountForm"
              auxiliaryAccountsEnabled={auxiliaryAccountsEnabled}
            />
          ) : (
            <></>
          )}

          {defaultTaxAccount ? (
            <DefaultAccountDeleteConfirmationModal
              integration={integrationStatus.integration}
              isOpen={modalState.kind === 'confirmDelete'}
              setModalState={setModalState}
              onSetDefault={onDefaultChange}
              defaultAccount={defaultTaxAccount}
              actionOrigin="defaultTaxAccountForm"
            />
          ) : (
            <></>
          )}
        </form>
      )}
    </div>
  );
};

function getInitialDefaultAccount(
  integrationStatus: IntegrationStatusWithIntegration,
  defaultTaxAccount: IntegrationVatAccount | undefined,
): TaxAccountUpdate {
  return defaultTaxAccount
    ? {
        ...defaultTaxAccount,
        name:
          defaultTaxAccount.name ||
          getDefaultTaxAccountName(integrationStatus.accountingCountry),
      }
    : {
        name: getDefaultTaxAccountName(integrationStatus.accountingCountry),
        code: '',
        rate: '',
      };
}
