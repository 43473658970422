import { GridContainer, GridItem } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { type ReactNode, Children } from 'react';

import styles from './CenteredLayout.module.css';

type Props = {
  className?: string;
  itemClassName?: string;
  columnCount?: number;
  width?: 'medium' | 'full';
  verticalAlign?: 'top' | 'middle';
  children: ReactNode;
};

export const CenteredLayout = ({
  className,
  itemClassName,
  columnCount = 1,
  width = 'full',
  verticalAlign = 'top',
  children,
}: Props) => {
  return (
    <div
      className={cx(styles.CenteredLayout, className, {
        [styles.middle]: verticalAlign === 'middle',
      })}
    >
      <GridContainer>
        {Children.map(children, (child, index) => (
          <GridItem
            key={index} // eslint-disable-line react/no-array-index-key
            columnSpan={toColumnSpan(width, columnCount)}
            columnStartLine={toColumnStartLine(width)}
            className={itemClassName}
          >
            {child}
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

const toColumnSpan = (
  width: 'medium' | 'full',
  columnCount: number,
): number => {
  if (width === 'medium') {
    return 8 / columnCount;
  }
  return 12 / columnCount;
};

const toColumnStartLine = (width: 'medium' | 'full'): number => {
  if (width === 'medium') {
    return 3;
  }
  return 0;
};
