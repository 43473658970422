import React from 'react';

import { useFeature } from "src/core/common/hooks/useFeature";
import FEATURES from "src/core/constants/features";

import { NotificationSlackSetting } from './NotificationSlackSetting';
import { useGetSlackProviderSettingsQuery } from '../../hooks/useGetSlackProviderSettingsQuery';

export const NotificationSlackSettingContainer = () => {
  const getSlackProviderSettingsQuery = useGetSlackProviderSettingsQuery();
  const isSlackIntegrationFeatureEnabled = useFeature(
    FEATURES.SLACK_INTEGRATION,
  );

  if (
    !isSlackIntegrationFeatureEnabled ||
    getSlackProviderSettingsQuery.status !== 'success'
  ) {
    return null;
  }

  return (
    <NotificationSlackSetting
      slackProviderSettings={getSlackProviderSettingsQuery.data}
    />
  );
};
