import { createContext, type ReactNode, useContext } from 'react';

import { logger } from 'src/utils/datadog-log-wrapper';

import { type LogContext } from '../../withErrorBoundary';

const initialViewer = () => {};

export const PageViewerContext =
  createContext<(content: ReactNode | undefined) => void>(initialViewer);

export const usePageViewer = (context: LogContext) => {
  const pageViewerContext = useContext(PageViewerContext);
  if (pageViewerContext === initialViewer) {
    logger.info(
      'pageViewerContext - usePageViewer needs to used within a PageViewerContext',
      context,
    );
  }
  return pageViewerContext;
};
