import React from 'react';
import { Trans } from 'react-i18next';

import { formatMonetaryValue } from "src/core/utils/monetaryValue";

import { type SpendingPolicy } from '../../../../models/rules';

type Props = {
  spendingPolicy: SpendingPolicy;
};

export const DefaultRequesterSpendingPolicy = ({ spendingPolicy }: Props) => {
  return (
    <div>
      <Trans
        i18nKey="control.approvalPolicyWidget.spendingPolicyDetails"
        values={{
          maxTransaction: formatMonetaryValue(spendingPolicy.maxTransaction),
          spendingLimit: formatMonetaryValue(spendingPolicy.spendingLimit),
        }}
      />
    </div>
  );
};
