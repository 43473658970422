import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useUser } from 'modules/app/hooks/useUser';
import { type AppDispatch } from 'modules/app/redux/store';
import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { fetchRequestsStats } from 'src/core/modules/requests/redux/legacy/actions';

export const ExpenseHeader = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useUser();
  const company = useCompany();
  const companyId = useCompanyId();
  const { t } = useTranslation('global');

  const isPayablesEnabled = useFeature(FEATURES.BOOKKEEP_PAYABLES);

  useEffect(() => {
    dispatch(fetchRequestsStats());
  }, []);

  const isAoOrController = user.is_account_owner || user.is_controller;

  const getRequestNavigationLinks = () => {
    const links = [
      {
        key: 'sub_nav_all-payments',
        path: routeFor(routes.PAYMENTS_ALL.path, { company: companyId }),
        text: t('payments.allPaymentsTitle'),
      },
    ];

    if (isPayablesEnabled && !company.churning_at && isAoOrController) {
      links.push({
        key: 'sub_nav_all-payables',
        path: routeFor(routes.PAYABLES_ALL.path, { company: companyId }),
        text: t('payables.allPayablesTitle'),
      });
    }

    return links;
  };

  return (
    <ErrorBoundary
      context={{ scope: 'requests::subnav', team: 'capture' }}
      fallbackComponent={<></>}
    >
      <HeaderWithNavigation links={getRequestNavigationLinks()}>
        {t('navigation.allExpenses')}
      </HeaderWithNavigation>
    </ErrorBoundary>
  );
};
