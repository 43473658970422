import { gql } from 'graphql-tag';

export const GET_SETTLEMENTS_IN_PERIOD = gql`
  query GetSettlementsInPeriod(
    $companyId: ID!
    $excludeNonPreparedPayables: Boolean!
    $includeAlreadyExported: Boolean!
    $fromCreationDate: Date!
    $toCreationDate: Date!
  ) {
    company(id: $companyId) {
      id
      settlements(
        filter: {
          excludeNonPreparedPayables: $excludeNonPreparedPayables
          includeAlreadyExported: $includeAlreadyExported
          creationDate: { from: $fromCreationDate, to: $toCreationDate }
        }
      ) {
        totalCount
      }
    }
  }
`;
