import { type BankAccountPurpose } from 'modules/bookkeep';

type PullAndSelectListAccount = {
  capability: 'pullAndSelect';
  purpose: BankAccountPurpose;
  name: string;
  code: string;
  tooltip: string | null;
  accountId: string | null;
  currency: string | null;
  error: undefined | { kind: 'notSelected' };
  warning?: true;
};

type LocalOnlyListAccount = {
  capability: 'localOnly';
  purpose: BankAccountPurpose;
  name: string;
  tooltip: string | null;
  accountId: string;
  code: string;
  currency: string | null;
  error: undefined | { kind: 'invalidValue' };
  warning?: true;
};

export type ListAccount = PullAndSelectListAccount | LocalOnlyListAccount;

export const isLocalOnlyListAccount = (
  account: ListAccount,
): account is LocalOnlyListAccount => {
  return account.capability === 'localOnly';
};
