import { isBefore, isSameDay, parse } from 'date-fns';

export const sortByDateAsc = <TItems extends { date: string }[]>(
  items: TItems,
): TItems =>
  // NOTE: we copy the array first because `sort` mutates the array.
  ([...items] as TItems).sort((a, b) => {
    const dateA = parse(a.date, 'yyyy-MM-dd', new Date());
    const dateB = parse(b.date, 'yyyy-MM-dd', new Date());
    if (isSameDay(dateA, dateB)) {
      return 0;
    }

    if (isBefore(dateA, dateB)) {
      return -1;
    }

    return 1;
  });
