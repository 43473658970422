import * as types from './actionTypes';
import { type SCAAction } from './actions';

type State = {
  procedureId: string | undefined;
  behavior: {
    isRequestingScaProcedure: boolean;
  };
};

export const initialState: State = {
  procedureId: undefined,
  behavior: {
    isRequestingScaProcedure: false,
  },
};

const scaActivationReducer = (
  state: State = initialState,
  action: SCAAction,
): State => {
  switch (action.type) {
    case types.REQUEST_SCA_PROCEDURE_SUCCESS:
      return {
        ...state,
        procedureId: action.payload.procedureId,
        behavior: {
          ...state.behavior,
          isRequestingScaProcedure: true,
        },
      };
    default:
      return state;
  }
};

export default scaActivationReducer;
