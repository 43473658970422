import { useState, useCallback } from 'react';

/**
 * @example
 * import { useModal } from 'common/hooks/useModalGrapes';
 * const MyComponent = ({ onSuccess }) => {
 * 	 const [
 *     myModal,
 *     showMyModal,
 *   ] = useModal(({isOpen, onClose}) => (
 *     <Modal
 *       isOpen={isOpen}
 *       onConfirm={() => onSuccess(successValue)}
 *       onClose={onClose}
 *     />
 *   ));
 *
 *   return (
 * 		<>
 * 			<button onClick={() => showMyModal()}>Show modal</buttton>
 * 			{myModal}
 * 		</>
 * 	);
 * };
 */

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const useModal = <T extends Object>(
  renderComponent: (modalProps: ModalProps & T) => React.ReactElement,
): [React.ReactElement, (params?: T) => void, () => void] => {
  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    parameters: T;
  }>({
    isOpen: false,
    parameters: {} as T,
  });
  const hideModal = useCallback(
    () =>
      setModalState((state) => ({
        isOpen: false,
        parameters: state.parameters,
      })),
    [],
  );

  return [
    renderComponent({
      ...modalState.parameters,
      onClose: hideModal,
      isOpen: modalState.isOpen,
    }),
    (parameters: T = {} as T) =>
      setModalState({
        isOpen: true,
        parameters,
      }),
    hideModal,
  ];
};
