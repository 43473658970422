import { Link } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';

import {
  getCookiePolicyLink,
  getLegalNoticeLink,
  getPrivacyPolicyLink,
  getTermsOfServiceLink,
} from '../helpers';

import './Legals.css';

export const Legals = () => {
  const { t, activeLanguage } = useTranslation('global');

  return (
    <div className="page__container flex items-center bg-page-background px-xl pb-xl pt-3xl">
      <section aria-labelledby="legals" className="Legals__section">
        <h1 id="legals" className="text-complementary title-xl">
          {t('legals.title')}
        </h1>
        <p className="text-neutral-dark body-m">{t('legals.subtitle')}</p>
        <div className="box mt-s space-x-l">
          <Link href={getTermsOfServiceLink(activeLanguage)} isExternal>
            {t('legals.termsOfServiceLink')}
          </Link>
          <Link href={getPrivacyPolicyLink(activeLanguage)} isExternal>
            {t('legals.privacyPolicyLink')}
          </Link>
          <Link href={getLegalNoticeLink()} isExternal>
            {t('legals.legalNoticeLink')}
          </Link>
          <Link href={getCookiePolicyLink(activeLanguage)} isExternal>
            {t('legals.cookiePolicyLink')}
          </Link>
          <button
            className="cursor-pointer border-none bg-white text-[inherit] underline body-m"
            onClick={() => {
              window.consentManager.openConsentManager();
            }}
            type="button"
          >
            {t('legals.cookieSettingsLink')}
          </button>
        </div>
      </section>
    </div>
  );
};
