import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import WireTransferControllerModal from './WireTransferControllerModal';

type Props = {
  amount: MonetaryValue;
  count: number;
  isSendingToPayment: boolean;
  isOpen: boolean;
  showNotEnoughFundsWarning: boolean;
  onClose: () => void;
  onSendToPayment: (options?: { onSuccess: () => void }) => void;
  onComplete: () => void;
};

const WireTransferModal = ({
  amount,
  count,
  isSendingToPayment,
  isOpen,
  showNotEnoughFundsWarning,
  onClose,
  onSendToPayment,
  onComplete,
}: Props) => {
  return (
    <WireTransferControllerModal
      amount={amount}
      count={count}
      showNotEnoughFundsWarning={showNotEnoughFundsWarning}
      isSendingToPayment={isSendingToPayment}
      isOpen={isOpen}
      onClose={onClose}
      onSendToPayment={onSendToPayment}
      onComplete={onComplete}
    />
  );
};

export default WireTransferModal;
