import { useMutation } from 'src/core/api/hooks/useMutation';

export const useDeleteBankInfoMutation = (memberId: string) => {
  return useMutation({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users/${memberId}/bank-info`,
      method: 'delete',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }): void => {
        client.refetchQueries(['users']);
        client.invalidateQueries(['users', memberId], { exact: true });
      },
    },
  });
};
