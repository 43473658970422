/* eslint-disable @typescript-eslint/naming-convention */

import { type Route } from 'src/core/constants/routes';

export const BASE_PATH = '/kyc';
export const BASE_MIGRATION_PATH = '/kyc/free-upgrade';
export const BASE_ACCOUNT_OWNER_CHANGE_REQUEST =
  '/kyc/account-owner-change-request';

export const routes: Record<string, Route> = {
  WELCOME: {
    path: '/:companyId',
    analytics: {
      category: 'KYC',
      name: 'welcome',
    },
  },
  ENTITY_TYPE: {
    path: '/:companyId/entity-type',
    analytics: {
      category: 'KYC',
      name: 'entityType',
    },
  },
  COMPANY: {
    path: '/:companyId/business-information/company',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  COMPANY_DETAILS: {
    path: '/:companyId/business-information/company-details',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  MONTHLY_SPEND: {
    path: '/:companyId/business-information/monthly-spend',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  TYPE_OF_EXPENSES: {
    path: '/:companyId/business-information/type-of-expenses',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  NUMBER_OF_USERS: {
    path: '/:companyId/business-information/number-of-users',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  CERTIFICATE_OF_INCORPORATION: {
    path: '/:companyId/business-information/certificate-of-incorporation',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  ASSOCIATION_CERTIFICATE: {
    path: '/:companyId/business-information/association-certificate',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  FUNDING: {
    path: '/:companyId/business-information/funding',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  ARTICLES_OF_ASSOCIATION: {
    path: '/:companyId/business-information/articles-of-association',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  FINANCIAL_STATEMENTS: {
    path: '/:companyId/business-information/financial-statements',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  GENERAL_ASSEMBLY_MINUTES: {
    path: '/:companyId/business-information/general-assembly-minutes',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  OWNERSHIP: {
    path: '/:companyId/company-structure/ownership',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  COMPANY_ADDRESS: {
    path: '/:companyId/business-information/company-address',
    analytics: {
      category: 'KYC',
      name: 'company',
    },
  },
  AUTHORIZED_REPRESENTATIVE: {
    path: '/:companyId/company-structure/authorized-representative',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  ASSOCIATION_ROLE: {
    path: '/:companyId/company-structure/ao-association-role',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  POWER_ATTORNEY: {
    path: '/:companyId/company-structure/power-attorney',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  DIRECTOR_IDENTITY: {
    path: '/:companyId/company-structure/director-identity',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  DIRECTOR_PROOF_OF_IDENTITY: {
    path: '/:companyId/company-structure/director-identity-proof',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  DIRECTOR_SHARES: {
    path: '/:companyId/director-shares',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  BOARD_DIRECTORS_PREFILL_REVIEW: {
    path: '/:companyId/company-structure/board-directors-prefill-review',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  BOARD_DIRECTORS: {
    path: '/:companyId/company-structure/board-directors',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  BENEFICIAL_OWNERSHIP: {
    path: '/:companyId/company-structure/beneficial-ownership',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  BENEFICIAL_OWNERSHIP_DOCUMENT: {
    path: '/:companyId/company-structure/beneficial-ownership-document',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  SHAREHOLDING_CHART: {
    path: '/:companyId/company-structure/shareholding-chart',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  BOARD_SHAREHOLDERS: {
    path: '/:companyId/company-structure/board-shareholders',
    analytics: {
      category: 'KYC',
      name: 'ownership',
    },
  },
  ADYEN_BENEFICIAL_OWNERS: {
    path: '/:companyId/adyen/beneficial-owners',
    analytics: {
      category: 'KYC',
      name: 'adyen',
    },
  },
  ADYEN_UPDATE_TAX_INFORMATION: {
    path: '/:companyId/adyen/update-tax-information',
    analytics: {
      category: 'KYC',
      name: 'adyen',
    },
  },
  ADYEN_ORGANIZATION_INFORMATION: {
    path: '/:companyId/adyen/organization-information',
    analytics: {
      category: 'KYC',
      name: 'adyen',
    },
  },
  ADYEN_TERMS_AND_CONDITIONS: {
    path: '/:companyId/adyen/terms-and-conditions',
    analytics: {
      category: 'KYC',
      name: 'adyen',
    },
  },
  ADYEN_REDIRECT: {
    path: '/:companyId/adyen/redirect-to-hosted-onboarding',
    analytics: {
      category: 'KYC',
      name: 'adyen',
    },
  },
  VERIFY_EMAIL: {
    path: '/:companyId/verify-email',
    analytics: {
      category: 'KYC',
      name: 'verify email',
    },
  },
  EMAIL_CONFIRMED: {
    path: '/:companyId/account-verification/email-confirmed',
    analytics: {
      category: 'KYC',
      name: 'email confirmed',
    },
  },
  NEW_ACCOUNT_OWNER_IDENTITY: {
    path: '/:companyId/ao-change-request/new-ao-identity',
    analytics: {
      category: 'AO Change Request',
      name: 'New AO Identity',
    },
  },
  NEW_ACCOUNT_OWNER_CONTACT_DETAILS: {
    path: '/:companyId/ao-change-request/new-ao-contact-details',
    analytics: {
      category: 'AO Change Request',
      name: 'New AO Contact Details',
    },
  },
  NEW_ACCOUNT_OWNER_PROOF_OF_IDENTITY: {
    path: '/:companyId/ao-change-request/new-ao-identity-proof',
    analytics: {
      category: 'AO Change Request',
      name: 'New AO Proof of Identity',
    },
  },
  NEW_ACCOUNT_OWNER_LEGAL_REPRESENTATIVE: {
    path: '/:companyId/ao-change-request/new-ao-legal-representative',
    analytics: {
      category: 'AO Change Request',
      name: 'New AO Legal Representative',
    },
  },
  NEW_ACCOUNT_OWNER_POWER_OF_ATTORNEY: {
    path: '/:companyId/ao-change-request/new-ao-power-of-attorney',
    analytics: {
      category: 'AO Change Request',
      name: 'New AO Power of Attorney',
    },
  },
  NEW_ACCOUNT_OWNER_CONFIRMATION: {
    path: '/:companyId/ao-change-request/new-ao-confirmation',
    analytics: {
      category: 'AO Change Request',
      name: 'New AO Confirmation',
    },
  },
  NEW_ACCOUNT_OWNER_ADYEN_REDIRECT: {
    path: '/:companyId/ao-change-request/new-ao-adyen-redirect',
    analytics: {
      category: 'AoChange',
      name: 'confirmation',
    },
  },
  NEW_ACCOUNT_OWNER_ADYEN_REQUEST_CALLBACK: {
    path: '/:companyId/ao-change-request/new-ao-adyen-request-callback',
    analytics: {
      category: 'AoChange',
      name: 'confirmation',
    },
  },
  DONE: {
    path: '/:companyId/account-verification/account-verification/done',
    analytics: {
      category: 'KYC',
      name: 'done',
    },
  },
};

type KYCRoutes = typeof routes;
type KYCRouteKeys = keyof KYCRoutes;
export type KYCRoutesUrl = KYCRoutes[KYCRouteKeys]['path'];
