import { isBefore, isValid, isSameSecond } from 'date-fns';
import { type MonetaryValue, toNumber } from 'ezmoney';

import { isStringEmpty } from 'common/utils/isStringEmpty';

import { type CostCenter } from './costCenter';
import { type CustomFieldAssociation } from './customFieldAssociation';
import { type CustomFieldDefinition } from './customFieldDefinition';
import { type PurchaseOrderRequestItem } from './purchaseOrderRequest';
import { type Team } from './team';
import {
  FILE_SIZES_CONFIGURATION,
  validateFileSizes,
} from '../utils/validateFileSize';

export interface DraftPurchaseOrderRequest {
  description: string;
  startDate: string | null;
  endDate: string | null;
  teamId: string | null;
  costCenterId: string | null;
  expenseCategoryId: string | null;
  amount: MonetaryValue | null;
  netAmount: MonetaryValue | null;
  customFieldAssociations: CustomFieldAssociation[];
  supplierId?: string;
  newSupplierName?: string;
  quotes: File[];
  items?: PurchaseOrderRequestItem[];
}

export const isPurchaseOrderRequestDescriptionValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
): boolean => {
  return !isStringEmpty(purchaseOrderRequest.description);
};

export const isPurchaseOrderRequestTeamValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
  hasTeamsFeature: boolean,
  teams: Team[],
): boolean => {
  if (!hasTeamsFeature || teams.length === 0) {
    return true;
  }
  return (
    !!purchaseOrderRequest.teamId && !isStringEmpty(purchaseOrderRequest.teamId)
  );
};

export const isPurchaseOrderRequestCostCenterValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
  hasCostCentersFeature: boolean,
  costCenters: CostCenter[],
): boolean => {
  if (!hasCostCentersFeature || costCenters.length === 0) {
    return true;
  }
  return (
    !!purchaseOrderRequest.costCenterId &&
    !isStringEmpty(purchaseOrderRequest.costCenterId)
  );
};

export const isPurchaseOrderRequestStartDateValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
): boolean => {
  return (
    !!purchaseOrderRequest.startDate &&
    isValid(new Date(purchaseOrderRequest.startDate))
  );
};

export const isPurchaseOrderRequestEndDateValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
): boolean => {
  return (
    !!purchaseOrderRequest.endDate &&
    isValid(new Date(purchaseOrderRequest.endDate))
  );
};

export const isPurchaseOrderRequestStartDateBeforeEndDate = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
): boolean => {
  if (
    !isPurchaseOrderRequestStartDateValid(purchaseOrderRequest) ||
    !isPurchaseOrderRequestEndDateValid(purchaseOrderRequest)
  ) {
    return true;
  }
  const startDate = purchaseOrderRequest.startDate as string;
  const endDate = purchaseOrderRequest.endDate as string;
  return (
    isSameSecond(new Date(startDate), new Date(endDate)) ||
    isBefore(new Date(startDate), new Date(endDate))
  );
};

export const isPurchaseOrderRequestAmountValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
): boolean => {
  return !!purchaseOrderRequest.amount;
};

export const isPurchaseOrderRequestNetAmountExists = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
): boolean => {
  return !!purchaseOrderRequest.netAmount;
};

export const isPurchaseOrderRequestNetAmountValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
): boolean => {
  if (purchaseOrderRequest.amount && purchaseOrderRequest.netAmount) {
    return (
      toNumber(purchaseOrderRequest.amount) >=
      toNumber(purchaseOrderRequest.netAmount)
    );
  }

  return true;
};

export const isPurchaseOrderRequestCustomFieldValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
  customField: CustomFieldDefinition,
): boolean => {
  if (!customField.is_required || customField.deleted_at) {
    return true;
  }
  const matchingCustomFieldAssociation =
    purchaseOrderRequest.customFieldAssociations.find(
      (customFieldAssociation) =>
        customFieldAssociation.customFieldId === customField.id,
    );
  return !!matchingCustomFieldAssociation;
};

export const isPurchaseOrderRequestSupplierValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
): boolean => {
  if (purchaseOrderRequest.supplierId) {
    return !isStringEmpty(purchaseOrderRequest.supplierId);
  }
  if (purchaseOrderRequest.newSupplierName) {
    return !isStringEmpty(purchaseOrderRequest.newSupplierName);
  }
  return false;
};

export const purchaseOrderRequestQuotesValidation = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
): { status: 'ok' } | { status: 'error'; name: string; max: string } => {
  const result = validateFileSizes(
    purchaseOrderRequest.quotes,
    FILE_SIZES_CONFIGURATION,
  );

  if (result.status === 'tooLarge') {
    return {
      status: 'error',
      name: result.fileName,
      max: `${result.maxSizeInMegaBytes.toFixed(1)}`,
    };
  }
  return { status: 'ok' };
};

export const isPurchaseOrderRequestExpenseCategoryValid = (
  purchaseOrderRequest: DraftPurchaseOrderRequest,
  hasExpenseCategoryValues: boolean,
): boolean => {
  if (hasExpenseCategoryValues) {
    return !!purchaseOrderRequest.expenseCategoryId;
  }
  return true;
};
