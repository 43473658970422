import { useAccountingIntegrationStatusQuery } from 'modules/accounting-integration/apis';

const DEFAULT_ACCOUNT_LENGTH = 1;
export function useAccountLength(): number {
  const result = useAccountingIntegrationStatusQuery();
  if (result.status !== 'success') {
    return DEFAULT_ACCOUNT_LENGTH;
  }
  if (
    result.data.integration === 'switchInProgress' ||
    result.data.integration === 'noIntegration'
  ) {
    return DEFAULT_ACCOUNT_LENGTH;
  }
  const accountLengthFieldValue =
    result.data.descriptor.settingsAccountingHeaderFields?.find(
      ({ fieldName }) => fieldName === 'accountLength',
    )?.fieldValue;
  return (
    ((accountLengthFieldValue ?? {}) as { accountsPayableLength: number })
      .accountsPayableLength ?? DEFAULT_ACCOUNT_LENGTH
  );
}
