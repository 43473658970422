import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

interface Props {
  isOpen: boolean;
  next: () => void;
  onClose: () => void;
}

export const MfaXeroWarningModal = ({ isOpen, next, onClose }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      iconName="info"
      iconVariant="info"
      title={t('bookkeep.integrations.xero.modal.mfa.warningMfa.title')}
      isOpen={isOpen}
      onClose={() => onClose()}
      actions={[
        <Button
          key="no"
          onClick={() => onClose()}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          onClick={next}
          text={t('misc.continue')}
          variant="primary"
        />,
      ]}
    >
      {t('bookkeep.integrations.xero.modal.mfa.warningMfa.desc')}
    </Modal>
  );
};
