import { type AnyAction, type Reducer } from '@reduxjs/toolkit';

import {
  type Selection,
  type SelectionNodeRef as SelectionNodeReference,
  SelectionNodeState,
  buildSelection,
  insertSelectionNodes,
  handleSelectionNodeStateChange,
  isSelectionNodeState,
  removeSelectionNodes,
} from './selection';

export type SelectionState = Selection | null;

type ActionTypes = {
  populate: string;
  toggle: string;
  restore?: string;
  remove?: string;
  reset?: string;
};

type Options<TItem> = {
  rootRef: string;
  actionTypes: ActionTypes;
  accessors: {
    populate: (action: AnyAction) => {
      items: TItem[];
      populateAs?: SelectionNodeState.Selected | SelectionNodeState.None;
    };
    toggle: (action: AnyAction) => string;
  };
  getItemRef: (item: TItem) => SelectionNodeReference;
};

const initialSelectionState: SelectionState = null;

/**
 * This helper creates a standardized Redux reducer for Selection management.
 * It takes the action types on which you want to populate, toggle or reset the
 * selection plus some accessors to read the data from those actions, and then
 * it handles all the internal selection manipulation for you.
 */
// TODO: add unit tests
export function createSelectionReducer<TItem>({
  rootRef,
  actionTypes,
  accessors,
  getItemRef,
}: Options<TItem>): Reducer<SelectionState> {
  const { populate, restore, toggle, remove, reset } = actionTypes;

  return (
    state: SelectionState = initialSelectionState,
    action: AnyAction,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): SelectionState => {
    switch (action.type) {
      case populate: {
        const { items, populateAs = SelectionNodeState.Selected } =
          accessors.populate(action);

        if (!items || items.length === 0) {
          // Skip if there are no items
          return state;
        }

        if (state === null) {
          // Build a first selection if it wasn't already built
          return buildSelection(
            items.reduce(
              (previous, current) => ({
                [rootRef]: {
                  ...previous[rootRef],
                  [getItemRef(current)]: {},
                },
              }),
              {
                [rootRef]: {},
              },
            ),
            populateAs,
          );
        }

        // If the selection is already initialized, we insert the new items into
        // it.
        return insertSelectionNodes(
          state,
          rootRef,
          items.reduce(
            (previous, current) => ({
              ...previous,
              [getItemRef(current)]: {},
            }),
            {},
          ),
        );
      }
      case toggle: {
        // We can't toggle anything if the selection isn't initialized.
        // It should never happen but throwing an error in a reducer isn't
        // acceptable so we simply pass through the previous state.
        if (state === null) {
          return state;
        }

        const nodeReference = accessors.toggle(action);

        return handleSelectionNodeStateChange(
          nodeReference,
          isSelectionNodeState(
            nodeReference,
            SelectionNodeState.Selected,
            state,
          )
            ? SelectionNodeState.None
            : SelectionNodeState.Selected,
          state,
        );
      }
      case restore: {
        // We can't restore anything if the selection isn't initialized.
        // It should never happen but throwing an error in a reducer isn't
        // acceptable so we simply pass through the previous state.
        if (state === null) {
          return state;
        }

        const previousSelection = action.payload as Selection;
        let nextSelection = state;

        for (const [nodeReference, node] of previousSelection.entries()) {
          const existingNode = state.get(nodeReference);

          if (existingNode && node.state !== existingNode.state) {
            // we can't restore partial selection — it's computed from the children
            if (node.children || node.state === SelectionNodeState.Partial) {
              continue;
            }

            nextSelection = handleSelectionNodeStateChange(
              nodeReference,
              node.state,
              nextSelection,
            );
          }
        }

        return nextSelection;
      }
      case remove: {
        // We can't remove anything if the selection isn't initialized.
        // It should never happen but throwing an error in a reducer isn't
        // acceptable so we simply pass through the previous state.
        if (state === null) {
          return state;
        }

        return removeSelectionNodes(state, action.payload);
      }
      case reset:
        return initialSelectionState;
      default:
        return state;
    }
  };
}
