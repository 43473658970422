/* eslint-disable unicorn/no-await-expression-member */
import React from 'react';

import {
  EmptyStateError,
  EmptyStateLoading,
  lazyLoad,
} from 'src/core/common/components/LazyLoad';

export const OnboardingHub = lazyLoad({
  loader: async () => ({
    default: (
      await import(
        /* webpackChunkName: "OnboardingHub" */ './pages/OnboardingHubRoutes'
      )
    ).default,
  }),
  loading: <EmptyStateLoading />,
  fallback: (props) => <EmptyStateError {...props} />,
});
