import { Tag } from '@dev-spendesk/grapes';
import React from 'react';

import './GringottsProfileItem.css';

interface Props {
  onToggleGringottsIframe: () => void;
}

export const GringottsProfileItem = ({ onToggleGringottsIframe }: Props) => {
  return (
    <button
      type="button"
      className="GringottsProfileItem resetButtonStyle"
      onClick={onToggleGringottsIframe}
    >
      Gringotts
      <Tag className="ml-xs" variant="warning">
        Demo and dev
      </Tag>
    </button>
  );
};
