import type React from 'react';
import { createContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { type BucketType, type GroupId } from '../../models';

type ActivePayableContextState = {
  payableId: null | string;
  nextPayableId: null | string;
  group: null | GroupId;
  bucket: null | BucketType;
};

const defaultState: ActivePayableContextState = {
  payableId: null,
  nextPayableId: null,
  group: null,
  bucket: null,
};

export const usePreparePayablesActivePayableContext = (): [
  ActivePayableContextState,
  React.Dispatch<React.SetStateAction<ActivePayableContextState>>,
] => {
  const { payableId } = useParams();

  const [state, setState] = useState(defaultState);

  useEffect(() => {
    setState({
      payableId,
      nextPayableId: null,
      group: null,
      bucket: null,
    });
  }, [payableId]);

  return [state, setState];
};

export const PreparePayablesActivePayableContext: React.Context<{
  activePayableContext: ActivePayableContextState;
  setActivePayableContext: (state: ActivePayableContextState) => void;
}> = createContext({
  activePayableContext: defaultState,
  setActivePayableContext: (_) => {},
});
