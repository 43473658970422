import { type AccountLoad, type WalletInfos, type UserFactor } from '../wallet';

export const FETCH_WALLET_INFOS_LOADING = 'FETCH_WALLET_INFOS_LOADING';
export const FETCH_WALLET_INFOS_FAILURE = 'FETCH_WALLET_INFOS_FAILURE';
export const FETCH_WALLET_INFOS_SUCCESS = 'FETCH_WALLET_INFOS_SUCCESS';

export const FETCH_ACCOUNT_LOADS_LOADING = 'FETCH_ACCOUNT_LOADS_LOADING';
export const FETCH_ACCOUNT_LOADS_FAILURE = 'FETCH_ACCOUNT_LOADS_FAILURE';
export const FETCH_ACCOUNT_LOADS_SUCCESS = 'FETCH_ACCOUNT_LOADS_SUCCESS';

export const FETCH_ACCOUNT_LOADING = 'FETCH_ACCOUNT_LOADING';
export const FETCH_ACCOUNT_FAILURE = 'FETCH_ACCOUNT_FAILURE';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';

export const FETCH_USER_MFA_LOADING = 'FETCH_USER_MFA_LOADING';
export const FETCH_USER_MFA_FAILURE = 'FETCH_USER_MFA_FAILURE';
export const FETCH_USER_MFA_SUCCESS = 'FETCH_USER_MFA_SUCCESS';

export type FetchWalletInfosLoading = Readonly<{
  type: typeof FETCH_WALLET_INFOS_LOADING;
}>;

export type FetchWalletInfosFailure = Readonly<{
  type: typeof FETCH_WALLET_INFOS_FAILURE;
}>;

export type FetchWalletInfosSuccess = Readonly<{
  type: typeof FETCH_WALLET_INFOS_SUCCESS;
  payload: Readonly<{
    walletInfos: WalletInfos;
  }>;
}>;

export type FetchAccountLoadsLoading = Readonly<{
  type: typeof FETCH_ACCOUNT_LOADS_LOADING;
}>;

export type FetchAccountLoadsFailure = Readonly<{
  type: typeof FETCH_ACCOUNT_LOADS_FAILURE;
}>;

export type FetchAccountLoadsSuccess = Readonly<{
  type: typeof FETCH_ACCOUNT_LOADS_SUCCESS;
  payload: Readonly<{
    loads: AccountLoad[];
  }>;
}>;

export type FetchAccountLoading = Readonly<{
  type: typeof FETCH_ACCOUNT_LOADING;
}>;

export type FetchAccountFailure = Readonly<{
  type: typeof FETCH_ACCOUNT_FAILURE;
}>;

export type FetchAccountSuccess = Readonly<{
  type: typeof FETCH_ACCOUNT_SUCCESS;
  payload: Readonly<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    account: any;
  }>;
}>;

export type FetchUserMfaLoading = Readonly<{
  type: typeof FETCH_USER_MFA_LOADING;
}>;

export type FetchUserMfaFailure = Readonly<{
  type: typeof FETCH_USER_MFA_FAILURE;
}>;

export type FetchUserMfaSuccess = Readonly<{
  type: typeof FETCH_USER_MFA_SUCCESS;
  payload: Readonly<{
    phoneUserFactor: UserFactor | undefined;
  }>;
}>;

export type Actions =
  | FetchWalletInfosLoading
  | FetchWalletInfosFailure
  | FetchWalletInfosSuccess
  | FetchAccountLoadsLoading
  | FetchAccountLoadsFailure
  | FetchAccountLoadsSuccess
  | FetchAccountLoading
  | FetchAccountFailure
  | FetchAccountSuccess
  | FetchUserMfaLoading
  | FetchUserMfaFailure
  | FetchUserMfaSuccess;
