import { Button, Icon, Tooltip, colors } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  ctaText: string;
  ctaLink: string;
  formatedAmount: string;
  formatedAmountDescription: string;
  hasWarning: boolean;
  hideTooltip: boolean;
  tooltipContent?: string | undefined;
};

export const HomepageWalletDetailPrimaryAmount = ({
  ctaText,
  ctaLink,
  formatedAmount,
  formatedAmountDescription,
  hasWarning,
  hideTooltip,
  tooltipContent = '',
}: Props) => {
  return (
    <div
      className={cx(
        'flex flex-col gap-xs rounded-xxs bg-neutral-lightest px-m py-l text-center',
        hasWarning && 'bg-warning-lightest',
      )}
    >
      <div>
        <div className="flex items-center justify-center gap-s">
          {hasWarning && (
            <Tooltip
              triggerAsChild
              content={tooltipContent}
              isDisabled={hideTooltip}
            >
              <Icon name="warning" size="l" color={colors.warning} />
            </Tooltip>
          )}
          <h2
            className={cx(
              'leading-[48px] title-xxl',
              hasWarning ? 'text-warning' : 'text-primary',
            )}
          >
            {formatedAmount}
          </h2>
        </div>
        <p className="leading-[20px] text-neutral-darker body-l">
          {formatedAmountDescription}
        </p>
      </div>
      <Link
        to={ctaLink}
        onClick={() => {
          track(AnalyticEventName.HOMEPAGE_WALLET_DETAILS_BUTTON_CLICKED);
        }}
      >
        <Button text={ctaText} variant="contrasted" />
      </Link>
    </div>
  );
};
