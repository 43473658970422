import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type SubscriptionCount = { count: number };

export const useMemberSubscriptionCount = (
  memberId: string,
): QueryState<SubscriptionCount> => {
  return useQuery<SubscriptionCount>({
    key: ['users', memberId, 'subscriptions_count'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users/${memberId}/subscriptions_count`,
    },
    reshapeData: (data) => ({ count: data }),
  });
};
