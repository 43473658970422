import { useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications, NotificationType } from 'modules/app/notifications';

import { AssignControlRuleModal } from '../../components/AssignControlRuleModal';
import { useAssignControlRuleMutation } from '../../hooks/useAssignControlRuleMutation';
import {
  useControlRulesQuery,
  type ControlRule,
} from '../../hooks/useControlRulesQuery';

type Props = {
  user: {
    fullname: string;
    id: string;
  };
  onExit(): void;
};

export type FormValues = {
  selectedControlRule?: ControlRule;
};

export const AssignControlRuleModalContainer = ({ user, onExit }: Props) => {
  const controlRulesQueryState = useControlRulesQuery();
  const formikProps = useFormik<FormValues>({
    initialValues: {
      selectedControlRule: undefined,
    },
    onSubmit: async (values) => {
      if (user && values.selectedControlRule) {
        try {
          await assignControlRuleMutation({ userId: user.id });
          onExit();
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('homepage.missingReceipts.assignControlRuleModal.error'),
          });
        }
      }
    },
  });
  const [assignControlRuleMutation] = useAssignControlRuleMutation(
    formikProps.values.selectedControlRule?.id,
  );
  const { pushNotif } = useNotifications();
  const { t } = useTranslation('global');

  return (
    <AssignControlRuleModal
      isLoading={controlRulesQueryState.status === 'loading'}
      controlRules={
        controlRulesQueryState.status === 'success'
          ? controlRulesQueryState.data
          : undefined
      }
      userName={user?.fullname}
      onExit={onExit}
      {...formikProps}
    />
  );
};
