import { ApolloProvider } from '@apollo/react-hooks';
import React, { useEffect } from 'react';

import { graphQLClient } from './graphQLClient';

type Props = {
  children: React.ReactNode;
};

export const GraphQLProvider = ({ children }: Props) => {
  useEffect(() => {
    // We're only interested in clearing the store when the provided is
    // unmounted so we only use the clean-up function of the effect here.
    return () => {
      graphQLClient.clearStore();
    };
  }, []);

  return <ApolloProvider client={graphQLClient}>{children}</ApolloProvider>;
};
