import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { ManagersPrimaryCallout } from './ManagersPrimaryCallout';
import { ManagersWarningCallout } from './ManagersWarningCallout';
import { useHasReportingManagerAsApprover } from '../../hooks/useHasReportingManagerAsApprover';
import { type Member } from '../../models/member';

type Props = {
  activeMembers: Member[];
};

export const MembersTableManagersInformation = ({ activeMembers }: Props) => {
  const isReportingManagersFeatureToggleEnabled = useFeature(
    FEATURES.REPORTING_MANAGERS,
  );
  const isTeamsFeatureToggleEnabled = useFeature(FEATURES.TEAMS);

  const hasReportingManagerAsApprover = useHasReportingManagerAsApprover();

  if (!isReportingManagersFeatureToggleEnabled || isTeamsFeatureToggleEnabled) {
    return null;
  }

  const membersWithMissingManagers = activeMembers.filter(
    (member) => !member.manager,
  );

  if (membersWithMissingManagers.length === 0) {
    return null;
  }

  return hasReportingManagerAsApprover ? (
    <ManagersWarningCallout
      membersWithMissingManagers={membersWithMissingManagers}
    />
  ) : (
    <ManagersPrimaryCallout
      membersWithMissingManagers={membersWithMissingManagers}
    />
  );
};
