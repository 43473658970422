import { useMutation } from 'src/core/api/hooks/useMutation';

import { type Member } from '../models/member';

type Payload = NonNullable<Member['bankInfo']>;

export const useUpdateBankInfoMutation = (memberId: string) => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users/${memberId}/bank-info`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }): void => {
        client.refetchQueries(['users']);
        client.invalidateQueries(['users', memberId], { exact: true });
      },
    },
  });
};
