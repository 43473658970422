import includes from 'lodash/includes';

/**
  Moving function for user.js one helper at a time
  When user.js (same directory) is empty, delete it and rename this file to user.ts
 */

export const isAccountOwner = (
  user: {
    data_by_company?: {
      [key: string]: {
        is_account_owner?: boolean;
      };
    };
  } = {},
  company: { id: string } | undefined,
) => {
  if (!company || !user) {
    return false;
  }
  return !!user.data_by_company?.[company.id]?.is_account_owner;
};

export const isGroupAdmin = (
  user: {
    data_by_company?: {
      [key: string]: {
        is_account_owner?: boolean;
      };
    };
    groups_admins: string[];
  },
  company: { id: string } | undefined,
  group_id?: string,
) => {
  if (isAccountOwner(user, company)) {
    return true;
  }

  const group_admins = user.groups_admins;
  if (group_id && group_admins) {
    return includes(group_admins, group_id);
  }

  return group_admins && group_admins.length > 0;
};
