import { Link, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { track, AnalyticEventName } from 'src/core/utils/analytics';

import { SpenderRulesModal } from '../SpenderRulesModal';

type Props = {
  incompletePaymentsLimit: number;
  completionDeadline: number;
};

export const PlayByTheRulesSpendingRule = ({
  incompletePaymentsLimit,
  completionDeadline,
}: Props) => {
  const { t } = useTranslation('global');
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <>
      <Modal
        isOpen={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        iconName="info"
        iconVariant="info"
        title={t('homepage.rules.modal.title')}
        subtitle={t('homepage.rules.modal.desc')}
      >
        <div className="text-left">
          <SpenderRulesModal
            incompletePaymentsLimit={incompletePaymentsLimit}
            completionDeadline={completionDeadline}
          />
        </div>
      </Modal>
      <div className="flex flex-col content-stretch">
        <div className="text-neutral-dark body-m">
          <Trans
            i18nKey="homepage.rules.panel.description"
            values={{ count: incompletePaymentsLimit }}
          >
            <strong className="text-primary-light title-m">-</strong>
          </Trans>
        </div>
        <Link
          href="#"
          onClick={() => {
            setIsModalOpened(true);
            track(AnalyticEventName.HOMEPAGE_REQUESTER_HOW_LINK_CLICKED);
          }}
        >
          {t('homepage.rules.panel.learnMore')}
        </Link>
      </div>
    </>
  );
};
