import { SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { CenteredLayout } from 'common/components/layout';

export const CustomFieldsLoader = () => {
  return (
    <CenteredLayout className="CustomFieldsContainer" width="medium">
      <div className="mt-xl mb-s">
        <SkeletonText size="xl" width="150px" className="mb-xs" />
        <SkeletonText />
      </div>
      <SkeletonTable
        numberOfRows={3}
        columns={[
          {
            header: <SkeletonText width="30%" />,
            cell: <SkeletonText width="50%" />,
            width: '50%',
          },
          {
            header: <SkeletonText width="30%" />,
            cell: <SkeletonText width="50%" />,
            width: '50%',
          },
        ]}
        rowHeight="compact"
        withHeader
      />
    </CenteredLayout>
  );
};
