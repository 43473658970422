import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type AnalyticalFieldMapping } from '../../accounting';

export const useAnalyticalFieldMappingsQuery = (): QueryState<
  AnalyticalFieldMapping[]
> => {
  const { company: companyId } = useParams();

  return useQuery<AnalyticalFieldMapping[], AnalyticalFieldMapping[]>({
    key: ['getAnalyticalFieldMappings', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint:
        '/accounting-integration/chart-of-accounts/analytical-fields-mappings',
    },
    reshapeData(analyticalFieldMappings) {
      return analyticalFieldMappings;
    },
  });
};
