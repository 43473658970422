import { Button } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { HubTaskWrapper } from 'modules/onboarding/components/HubTaskWrapper';
import { routes as setupHubRoutes } from 'modules/onboarding/setup-hub/pages/routes';
import {
  type HubTask,
  type HubTaskType,
} from 'modules/onboarding/types/hubTask';
import { routeFor, routes } from 'src/core/constants/routes';
import { LocalStorageKey } from 'src/core/constants/storage';
import { MarkAsDoneButton } from 'src/core/modules/onboarding/components/MarkAsDoneButton';
import { setLocalItem } from 'src/core/utils/storage';

type Props = {
  task: HubTask;
};

export const CONTROL_SETTINGS_TASKS: HubTaskType[] = [
  'physical_cards',
  'expense_categories',
  'analytical_fields',
  'control_rules',
];

const getContentByTaskType = (
  type: HubTaskType,
): { title: I18nKey; description: I18nKey } | null => {
  switch (type) {
    case 'physical_cards':
      return {
        title: 'setupHub.sections.controlSettings.physicalCards.title',
        description:
          'setupHub.sections.controlSettings.physicalCards.description',
      };
    case 'expense_categories':
      return {
        title: 'setupHub.sections.controlSettings.expenseCategories.title',
        description:
          'setupHub.sections.controlSettings.expenseCategories.description',
      };
    case 'analytical_fields':
      return {
        title: 'setupHub.sections.controlSettings.analyticalFields.title',
        description:
          'setupHub.sections.controlSettings.analyticalFields.description',
      };
    case 'control_rules':
      return {
        title: 'setupHub.sections.controlSettings.controlRules.title',
        description:
          'setupHub.sections.controlSettings.controlRules.description',
      };
    default:
      return null;
  }
};

export const ControlSettingHubTaskItem = ({ task }: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const history = useHistory();

  const content = getContentByTaskType(task.type);
  const openTaskCta: I18nKey =
    task.status === 'completed'
      ? 'setupHub.sections.controlSettings.reviewCta'
      : 'setupHub.sections.controlSettings.startCta';

  if (!content) {
    return;
  }

  const openTask = () => {
    setLocalItem(
      LocalStorageKey.LastOnboardingSectionOpened,
      'control_settings',
      true,
    );
    switch (task.type) {
      case 'physical_cards':
        history.push(
          routeFor(
            task.status === 'completed'
              ? routes.CARDS.path
              : routes.CARDS_ORDER.path,
            { company: company.id },
          ),
        );
        break;
      case 'expense_categories':
        history.push(
          routeFor(
            task.status === 'completed'
              ? routes.COMPANY_CATEGORIES.path
              : setupHubRoutes.CONTROL_SETTINGS_NEW_EXPENSE_CATEGORY.path,
            {
              company: company.id,
            },
          ),
        );
        break;
      case 'analytical_fields':
        history.push(
          routeFor(
            task.status === 'completed'
              ? routes.COMPANY_CATEGORIES.path
              : setupHubRoutes.CONTROL_SETTINGS_CUSTOM_FIELD_NAME.path,
            {
              company: company.id,
            },
          ),
        );
        break;
      case 'control_rules':
        history.push(
          routeFor(
            task.status === 'completed'
              ? routes.COMPANY_CONTROL_RULES.path
              : setupHubRoutes.CONTROL_SETTINGS_CONTROL_RULES.path,
            {
              company: company.id,
            },
          ),
        );
        break;
      default:
        throw new Error(`Unexpected task type ${task.type} in Setup Hub`);
    }
  };

  return (
    <HubTaskWrapper
      title={t(content.title)}
      description={t(content.description)}
      task={task}
      actions={[
        <MarkAsDoneButton
          task={task}
          key="mark-as-done"
          text={t('misc.skip')}
        />,
        <Button
          variant={task.status === 'ongoing' ? 'primary' : 'secondary'}
          onClick={openTask}
          text={t(openTaskCta)}
          key="open"
          isDisabled={task.status === 'disabled'}
        />,
      ]}
    />
  );
};
