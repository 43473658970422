import { Button, IconButton, Tooltip } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useState } from 'react';

import { useGetSupplierAccountsQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSupplierAccountsQuery';
import { useAccountLength } from 'modules/bookkeep/hooks';
import {
  type MutationQueryState,
  type QueryState,
} from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { apiUrl } from 'src/core/utils/api';

import { DefaultEmployeeAccountForm } from './DefaultEmployeeAccountForm';
import { DefaultEmployeeAccountFormDeprecated } from './DefaultEmployeeAccountForm__deprecated';
import { type ModalState } from './ModalState';
import { EmployeeAccountAddModal } from './modals/EmployeeAccountAddModal';
import { EmployeeAccountCreateConfirmationModal } from './modals/EmployeeAccountCreateConfirmationModal';
import { EmployeeAccountDeleteModal } from './modals/EmployeeAccountDeleteModal';
import { EmployeeAccountAdvice } from '../../../../../../../components/AccountAdvice';
import { type IntegrationStatusWithIntegration } from '../../../../../../../integration/status';
import {
  type DefaultEmployeeAccount,
  type EmployeeAccount,
} from '../../../../../../accounting/employeeAccount';
import { type Result as GetDefaultEmployeeAccountQueryResult } from '../../../../../hooks/useGetDefaultEmployeeAccountQuery';
import { useGetEmployeeAccountCodesQuery } from '../../../../../hooks/useGetEmployeeAccountCodesQuery';
import { type Result as SetDefaultEmployeeAccountMutationResult } from '../../../../../hooks/useSetDefaultEmployeeAccountMutation';
import styles from '../EmployeeAccountsSection.module.css';
import { IndividualEmployeeAccountsTable } from '../IndividualEmployeeAccountsTable';
import {
  getEmployeeAccountsDescriptionI18nKey,
  getEmployeeAccountsHelpCenterLink,
  getSpecificEmployeeAccountsDescriptionI18nKey,
  getSpecificEmployeeAccountsHelpCenterLink,
} from '../translations';

interface Props {
  employeeAccounts: EmployeeAccount[];
  hasNextPage: boolean;
  fetchNextPage: () => Promise<void>;
  defaultEmployeeAccount: DefaultEmployeeAccount | undefined;
  onAdd: (employeeAccount: EmployeeAccount) => Promise<void>;
  onDelete: (employeeAccount: EmployeeAccount) => Promise<void>;
  onSetDefault: (
    defaultEmployeeAccount: DefaultEmployeeAccount,
  ) => Promise<void>;
  integrationStatus: IntegrationStatusWithIntegration;
  isLoading: boolean;
  isDefaultEmployeeAccountToggleChecked: boolean;
}

type PropsWithDefaultAccounts = Omit<Props, 'defaultEmployeeAccount'> & {
  setDefaultEmployeeAccountQueryState: MutationQueryState<SetDefaultEmployeeAccountMutationResult>;
  getDefaultEmployeeAccountQueryState: QueryState<GetDefaultEmployeeAccountQueryResult>;
};

export function EmployeeAccountsLocalOnlySection(props: Props) {
  return (
    <EmployeeAccountsSection
      {...props}
      withDefaultAccount={false}
      setDefaultEmployeeAccountQueryState={{ status: 'idle' }}
      getDefaultEmployeeAccountQueryState={{ status: 'loading' }}
    />
  );
}

export function EmployeeAccountsLocalOnlyWithDefaultAccountsSection(
  props: PropsWithDefaultAccounts,
) {
  return (
    <EmployeeAccountsSection
      {...props}
      defaultEmployeeAccount={
        props.getDefaultEmployeeAccountQueryState.status === 'success'
          ? props.getDefaultEmployeeAccountQueryState.data
          : undefined
      }
      withDefaultAccount
    />
  );
}

function EmployeeAccountsSection({
  employeeAccounts,
  hasNextPage,
  fetchNextPage,
  defaultEmployeeAccount,
  onAdd,
  onDelete,
  onSetDefault,
  integrationStatus,
  isLoading,
  isDefaultEmployeeAccountToggleChecked,
  withDefaultAccount,
  setDefaultEmployeeAccountQueryState,
  getDefaultEmployeeAccountQueryState,
}: PropsWithDefaultAccounts & {
  withDefaultAccount: boolean;
  defaultEmployeeAccount: DefaultEmployeeAccount | undefined;
}) {
  const companyId = useCompanyId();
  const getSupplierAccountsQueryState = useGetSupplierAccountsQuery({
    includeArchived: false,
  });
  const getEmployeeAccountCodesQueryState = useGetEmployeeAccountCodesQuery();
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<ModalState>({ kind: 'closed' });
  const accountLength = useAccountLength();

  const nonDefaultEmployeeAccountsErrorsList =
    integrationStatus.settingsValidation.employeeAccounts.filter(
      (error) =>
        error.error === 'invalidAccount' &&
        (!defaultEmployeeAccount?.id ||
          (defaultEmployeeAccount?.id &&
            error.id !== defaultEmployeeAccount?.id)),
    );

  return (
    <div data-testid="employee-accounts-section">
      {!withDefaultAccount ? (
        <>
          <div>
            <h3 className="IntegrationAccountingPage__section-title title-xl">
              {t(
                'bookkeep.integrations.settings.sectionDefaultEmployeeAccount',
              )}
            </h3>
            <p className={classNames(styles.headerTextDescription, 'body-m')}>
              {t(
                getEmployeeAccountsDescriptionI18nKey(
                  integrationStatus.integration,
                ),
              )}
              &nbsp;|&nbsp;
              <a
                href={getEmployeeAccountsHelpCenterLink(
                  integrationStatus.integration,
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('misc.helpCenterArticle')}
              </a>
            </p>
          </div>

          <DefaultEmployeeAccountFormDeprecated
            integrationStatus={integrationStatus}
            getSupplierAccountsQueryState={getSupplierAccountsQueryState}
            getEmployeeAccountCodesQueryState={
              getEmployeeAccountCodesQueryState
            }
            accountLength={accountLength}
            onSetDefault={onSetDefault}
            defaultEmployeeAccount={defaultEmployeeAccount}
            isInitialToggleChecked={isDefaultEmployeeAccountToggleChecked}
          />
        </>
      ) : null}

      {withDefaultAccount ? (
        <>
          <div className={styles.header}>
            <div className={styles.headerText}>
              <h3 className="IntegrationAccountingPage__section-title title-xl">
                {t(
                  'bookkeep.integrations.settings.sectionDefaultEmployeeAccount',
                )}
              </h3>
              <p className={classNames(styles.headerTextDescription, 'body-m')}>
                {t(
                  'bookkeep.integrations.settings.defaultEmployeeAccountsDescription',
                )}
                &nbsp;|&nbsp;
                <a
                  href="https://helpcenter.spendesk.com/en/articles/6202577-default-accounting-values"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('misc.helpCenterArticle')}
                </a>
              </p>
            </div>
          </div>

          <DefaultEmployeeAccountForm
            integrationStatus={integrationStatus}
            getSupplierAccountsQueryState={getSupplierAccountsQueryState}
            getDefaultEmployeeAccountQueryState={
              getDefaultEmployeeAccountQueryState
            }
            setDefaultEmployeeAccountQueryState={
              setDefaultEmployeeAccountQueryState
            }
            accountLength={accountLength}
            onSetDefault={onSetDefault}
            defaultEmployeeAccount={defaultEmployeeAccount}
            isInitialToggleChecked={isDefaultEmployeeAccountToggleChecked}
          />
        </>
      ) : null}

      <div
        id="IntegrationAccountingPage__employee-accounts-individual-employees"
        className="IntegrationAccountingPage__employee-accounts-individual-employees"
      >
        <div className={styles.header}>
          <div className={styles.headerText}>
            <h3 className="IntegrationAccountingPage__section-title title-xl">
              {t(
                'bookkeep.integrations.settings.sectionSpecificEmployeeAccounts',
              )}
            </h3>
            <p className={classNames(styles.headerTextDescription, 'body-m')}>
              {t(
                getSpecificEmployeeAccountsDescriptionI18nKey(
                  integrationStatus.integration,
                ),
              )}
              &nbsp;|&nbsp;
              <a
                href={getSpecificEmployeeAccountsHelpCenterLink(
                  integrationStatus.integration,
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('misc.helpCenterArticle')}
              </a>
            </p>
          </div>
          <Button
            data-testid="new-employee-account"
            className={styles.headerBtn}
            text={t(
              'bookkeep.integrations.settings.employeeAccountsTable.newEmployee',
            )}
            variant="primary"
            onClick={() =>
              setModalState({
                kind: 'add',
                employeeAccount: {},
                inputChanged: false,
                error: 'none',
              })
            }
          />
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={apiUrl(
              `/accounting-integration/chart-of-accounts/employee-accounts/export`,
              companyId,
            )}
            aria-label={t(
              'bookkeep.integrations.settings.employeeAccountsTable.downloadList',
            )}
          >
            <Tooltip
              content={t(
                'bookkeep.integrations.settings.employeeAccountsTable.downloadList',
              )}
              triggerAsChild
            >
              <IconButton
                iconName="download"
                variant="border"
                className="ml-xs"
                aria-hidden="true"
              />
            </Tooltip>
          </a>
        </div>
      </div>

      {!!nonDefaultEmployeeAccountsErrorsList.length && (
        <EmployeeAccountAdvice
          className={styles.errorsCallout}
          integrationStatus={integrationStatus}
          showError
          title={t(
            'bookkeep.integrations.datev.accountCodeAdvice.employeeAccounts.title',
            {
              count: nonDefaultEmployeeAccountsErrorsList.length,
            },
          )}
        />
      )}

      <IndividualEmployeeAccountsTable
        employeeAccounts={employeeAccounts}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isLoading={isLoading}
        integrationStatus={integrationStatus}
        onDelete={(employeeAccount) => {
          setModalState({
            kind: 'confirmDelete',
            employeeAccount,
          });
        }}
      />

      <EmployeeAccountAddModal
        getSupplierAccountsQueryState={getSupplierAccountsQueryState}
        getEmployeeAccountCodesQueryState={getEmployeeAccountCodesQueryState}
        accountLength={accountLength}
        integrationStatus={integrationStatus}
        modalState={modalState}
        setModalState={setModalState}
      />

      <EmployeeAccountCreateConfirmationModal
        integration={integrationStatus.integration}
        modalState={modalState}
        onAdd={onAdd}
        setModalState={setModalState}
      />

      <EmployeeAccountDeleteModal
        integration={integrationStatus.integration}
        onDelete={onDelete}
        modalState={modalState}
        setModalState={setModalState}
      />
    </div>
  );
}
