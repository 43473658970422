import { RejectPayableApi } from '@finance-review/apis/payable';
import type { CreditNoteId } from '@finance-review/models/credit-note';
import { type MutationState } from 'src/core/api/hooks/useMutation';

import { useRemoveCreditNotesCacheEntries } from './useCreditNoteListQuery';

type Payload = RejectPayableApi.Payload;
type Response = RejectPayableApi.Response;

export const useRejectCreditNoteRequest = (
  creditNoteId: CreditNoteId,
  options: {
    onSuccess?: (requestId: CreditNoteId) => void;
    onError?: () => void;
  } = {},
): MutationState<Payload, Response, unknown> => {
  const removeCreditNotesCacheEntries = useRemoveCreditNotesCacheEntries();

  return RejectPayableApi.useMutation(creditNoteId, {
    onSuccess: async (requestId) => {
      removeCreditNotesCacheEntries([requestId]);
      options.onSuccess?.(requestId);
    },
    onError: options.onError,
  });
};
