import type { ExpenseClaimsCounts } from 'modules/reimbursements/types';
import { useQuery } from 'src/core/api/hooks/useQuery';

type Params = {
  isEnabled?: boolean;
};

export const useExpensesCountsQuery = ({ isEnabled = true }: Params = {}) => {
  return useQuery<ExpenseClaimsCounts, ExpenseClaimsCounts>({
    key: ['scheduled_payments', 'expense-claim', 'counts'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/scheduled_payments/expense-claim/counts`,
    },
    reshapeData: (data) => data,
    isEnabled,
  });
};
