import { datadogLogs } from '@datadog/browser-logs';

import CONFIG from '../core/config';

if (CONFIG.datadog) {
  datadogLogs.init({
    clientToken: CONFIG.datadog.clientToken,
    site: 'datadoghq.eu',
    service: 'app-desktop',
    env: CONFIG.environmentName,
    sessionSampleRate: 100,
    version: process.env.REACT_APP_SHA,
    forwardErrorsToLogs: false,
  });
}

export const addSpendeskContextToDatadogLogs = ({
  userId,
  companyId,
}: {
  userId: string;
  companyId: string;
}) => {
  if (CONFIG.datadog && Boolean(datadogLogs.getInitConfiguration())) {
    datadogLogs.setGlobalContextProperty('userId', userId);
    datadogLogs.setGlobalContextProperty('companyId', companyId);
  }
};
