import { AutocompleteNoOptions as GrapesAutocompleteNoOptions } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  value: string;
};

export const AutocompleteNoOptions = ({ value }: Props) => {
  const { t } = useTranslation('global');

  return value ? (
    <GrapesAutocompleteNoOptions>
      <Trans
        i18nKey="misc.noOptions"
        values={{ account: value }}
        // eslint-disable-next-line react/jsx-key
        components={[<span className="text-complementary" />]}
      />
    </GrapesAutocompleteNoOptions>
  ) : (
    <GrapesAutocompleteNoOptions>
      {t('misc.asyncAutocompleteEmptyState')}
    </GrapesAutocompleteNoOptions>
  );
};
