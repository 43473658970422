import {
  fromNumber,
  greaterThanOrEqual,
  type MonetaryValue,
  subtract,
  toNumber,
} from 'ezmoney';
import isNil from 'lodash/isNil';

import { useCompany } from 'modules/app/hooks/useCompany';
import { customQueryRequestErrorFactory } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';
import { type CurrenciesKey } from 'src/core/config/money';
import { convertToCompanyCurrency } from 'src/core/utils/money';

import { useFetchCompanyWalletAmountQuery } from './useFetchCompanyWalletAmountQuery';
import { type WalletAmountAllocation } from '../wallet/walletSummary';

export type WalletTransactionForecast = {
  hasEnoughFunds: boolean;
  walletAmount: MonetaryValue;
  transactionAmount: MonetaryValue;
  walletAmountAfterTransaction: MonetaryValue;
};

export const useWalletTransactionForecastQuery = () => {
  const company = useCompany();
  const fetchCompanyWalletAmountQueryState = useFetchCompanyWalletAmountQuery();

  return (
    transactionAmount: MonetaryValue,
    walletAmountAllocation: WalletAmountAllocation = 'total',
  ): QueryState<WalletTransactionForecast> => {
    if (
      company.balance_available_all_accounts === undefined &&
      company.balance_loaded_cards === undefined
    ) {
      return {
        status: 'success',
        data: {
          hasEnoughFunds: false,
          walletAmount: fromNumber(0, company.currency),
          transactionAmount: fromNumber(0, company.currency),
          walletAmountAfterTransaction: fromNumber(0, company.currency),
        },
      };
    }

    if (fetchCompanyWalletAmountQueryState.status === 'success') {
      if (
        isNil(fetchCompanyWalletAmountQueryState.data[walletAmountAllocation])
      ) {
        // the API was designed in a way that does not necessarily return the expected data
        // in this case, we consider it as an error in the front end
        return {
          status: 'error',
          error: customQueryRequestErrorFactory({
            method: 'GET',
            url: '/wallet-summary',
            data: null,
          }),
        };
      }

      const walletAmount = fetchCompanyWalletAmountQueryState.data[
        walletAmountAllocation
      ] as MonetaryValue;
      const walletTransactionForecast = getWalletTransactionForecast(
        walletAmount,
        transactionAmount,
        company.currency,
        company,
      );
      return { status: 'success', data: walletTransactionForecast };
    }

    if (fetchCompanyWalletAmountQueryState.status === 'error') {
      return {
        status: 'error',
        error: fetchCompanyWalletAmountQueryState.error,
      };
    }

    return { status: 'loading' };
  };
};

export const getWalletTransactionForecast = (
  walletAmount: MonetaryValue,
  transactionAmount: MonetaryValue,
  companyCurrency: string,
  company: {
    rates: Record<CurrenciesKey, number>;
    currency: CurrenciesKey;
  },
): WalletTransactionForecast => {
  const convertedAmount = convertToCompanyCurrency(
    toNumber(transactionAmount),
    companyCurrency,
    company,
  );
  const transactionAmountInCompanyCurrency = fromNumber(
    convertedAmount,
    companyCurrency,
    2,
  );

  const walletAmountAfterTransaction = subtract(
    walletAmount,
    transactionAmountInCompanyCurrency,
  );

  const hasEnoughFunds = greaterThanOrEqual(
    walletAmount,
    transactionAmountInCompanyCurrency,
  );

  return {
    walletAmount,
    walletAmountAfterTransaction,
    transactionAmount,
    hasEnoughFunds,
  };
};
