import { type DateRange } from '@dev-spendesk/grapes';

export type PayableExportId = string;

export type PayableExportRequest = {
  includeExportedPayables: boolean;
  includePayablesFromPreviousPeriod: boolean;
  forceExportAsSupervisor: boolean;
  purchaseJournalTemplate?: {
    id: string;
    name: string;
  };
  bankJournalTemplate?: {
    id: string;
    name: string;
  };
  period: DateRange;
  includeDocumentaryEvidences?: boolean;
  exportPurchaseJournal: boolean;
  exportBankJournal: boolean;
  exportReceipts: boolean;
};

export type PayableState =
  | 'created'
  | 'prepared'
  | 'unprepared'
  | 'discarded'
  | 'to_accounting_pending'
  | 'to_accounting_failed'
  | 'in_accounting'
  | 'in_accounting_manually';

export const unexportedPayableStates: PayableState[] = [
  'prepared',
  'to_accounting_failed',
];

export const exportedPayableStates: PayableState[] = [
  'in_accounting',
  'in_accounting_manually',
];

export const unpreparedPayableStates: PayableState[] = [
  'created',
  'unprepared',
];

export type BankJournalExportRequest = {
  includeNonPreparedPayables: boolean;
  includeExportedPayables: boolean;
  forceExportAsSupervisor: boolean;
  period: DateRange;
};

export type BankJournalFileExportId = string;
