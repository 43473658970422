/* eslint-disable @typescript-eslint/naming-convention */
import cx from 'classnames';
import { type MonetaryValue } from 'ezmoney';
import React, { useState } from 'react';

import { BudgetOverviewHeader } from './BudgetOverviewHeader';
import { type Breakdown } from '../../models/breakdown';
import { type BreakdownLegendState } from '../../models/breakdownLegendState';
import { BudgetGauge } from '../BudgetGauge';
import { BudgetGaugeLegend } from '../BudgetGaugeLegend';

type Props = {
  title?: string;
  withHoverInteractions?: boolean;
  name: string;
  amount: MonetaryValue;
  breakdown: Breakdown;
  isUnavailable?: boolean;
  mode: 'compact' | 'medium' | 'full';
  onBreakdownPartClick?(breakdownPart: 'committed' | 'used'): void;
};

export const BudgetOverview = ({
  title,
  name,
  amount,
  breakdown,
  withHoverInteractions,
  isUnavailable,
  mode,
  onBreakdownPartClick,
}: Props) => {
  const [currentSectionHovered, setCurrentSectionHovered] = useState<
    BreakdownLegendState | undefined
  >();

  const handleHover = (hovered: BreakdownLegendState | undefined) => {
    if (withHoverInteractions) {
      setCurrentSectionHovered(hovered);
    }
  };

  return (
    <div
      className={cx(
        'flex',
        'flex-col',
        'items-start',
        'p-m',
        'shadow',
        'border',
        'border-solid',
        'border-neutral-light',
        'rounded-xs',
        isUnavailable ? 'bg-neutral-lightest' : 'bg-white',
      )}
    >
      <BudgetOverviewHeader
        title={title}
        name={name}
        amount={amount}
        breakdown={breakdown}
        isUnavailable={isUnavailable}
        mode={mode}
        date={new Date()}
      />
      <BudgetGauge
        budgetBreakdown={breakdown}
        gaugeConfiguration={{
          tooltip: withHoverInteractions ? { mode: 'normal' } : undefined,
          highlightSegmentOnHover: withHoverInteractions,
          hasAnimation: withHoverInteractions,
        }}
        hoveredBreakdownKey={currentSectionHovered}
        onGaugeSegmentClick={onBreakdownPartClick}
      />
      {(mode === 'full' || mode === 'medium') && (
        <BudgetGaugeLegend
          setCurrentSectionHovered={handleHover}
          currentSectionHovered={currentSectionHovered}
          breakdown={breakdown}
          onBreakdownPartClick={onBreakdownPartClick}
          mode={mode}
        />
      )}
    </div>
  );
};
