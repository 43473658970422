import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import PayableListEmptyStateImg from './PayableListEmptyState.svg';

export const PayableListEmptyState = () => {
  const { t } = useTranslation('global');

  return (
    <div className="height-[calc(100%_-_42px)] flex flex-col items-center justify-center gap-s">
      <img
        src={PayableListEmptyStateImg}
        alt={t('payables.list.noResultsAlt')}
      />
      <div className="text-complementary title-xl">
        {t('payables.list.noResultsTitle')}
      </div>
    </div>
  );
};
