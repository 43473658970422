import { gql } from 'graphql-tag';

export const GET_SUPPLIER_ACCOUNTS = gql`
  query GetSupplierAccounts($companyId: ID!, $filter: AccountPayableFilter) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        supplierAccounts(filter: $filter) {
          totalCount
          edges {
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              defaultFor
            }
          }
        }
      }
    }
  }
`;
