import { gql } from 'graphql-tag';

export const GET_ACCOUNT_PAYABLE_SUPPLIERS = gql`
  query GetAccountPayableSuppliers(
    $accountPayableId: ID!
    $after: ID
    $filter: SupplierFilter!
  ) {
    supplierAccount(id: $accountPayableId) {
      id
      generalAccountCode
      suppliers(first: 20, after: $after, filter: $filter) {
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            name
            thumbnailUrl
            id
            payablesAggregate {
              sum {
                functionalAmount
              }
            }
          }
        }
      }
    }
  }
`;
