import { type DateRange } from '@dev-spendesk/grapes';
import { type MonetaryValue } from 'ezmoney';

import { type ExpenseType } from '../types';

export enum ExportType {
  CsvWithoutReceipts = 'CsvWithoutReceipts',
  CsvWithReceipts = 'CsvWithReceipts',
}

export type ExportOptions = {
  type: ExportType;
  includeExportedPayables: boolean;
  includePayablesFromPreviousPeriod: boolean;
  forceExportAsSupervisor: boolean;
};

export type ExportFilters = {
  period: DateRange;
  includePayablesFromPreviousPeriod: boolean;
  includeExportedPayables: boolean;
  payableListType: PayableListType;
};

export type ExportOption = {
  name: string;
  isChecked: boolean;
  count: number;
  translationKey: string;
  route: string;
  track: () => void;
};

export enum PayableListType {
  all = 'all',
  exported = 'exported',
  previousPeriod = 'previous-period',
  allFailed = 'all-failed',
  lastFailed = 'last-failed',
}

export enum BookkeepingExportStatus {
  Pending = 'pending',
  Processing = 'processing',
  Processed = 'processed',
  Sent = 'sent',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Downloaded = 'downloaded',
}

export type BookkeepingExport = Readonly<{
  id: string;
  status: BookkeepingExportStatus;
  error?: string;
}>;

export type ExportReviewFiltersValues = {
  payableTypes: ExpenseType[];
  amount: {
    min: MonetaryValue | null;
    max: MonetaryValue | null;
  };
};
