import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { logger } from 'src/utils/datadog-log-wrapper';

import { AffidavitCertifyModal } from './AffidavitCertifyModal';
import { AffidavitFinalModal } from './AffidavitFinalModal';
import { AffidavitPurchaseInfoModal } from './AffidavitPurchaseInfoModal';
import { AffidavitReasonModal } from './AffidavitReasonModal';
import { AffidavitSignatureModal } from './AffidavitSignatureModal';
import { AffidavitStartModal } from './AffidavitStartModal';
import { AffidavitUserRoleModal } from './AffidavitUserRoleModal';
import { type DeclarationData } from './declarationData';
import { useCreateAffidavit } from './hooks/useCreateAffidavit';
import { useSignAffidavit } from './hooks/useSignAffidavit';
import { reshapeDeclarationData } from './reshapeDeclarationData';
import { validateAffidavitForm } from './validator';

type Payment = {
  id: string;
  userId: string;
  amount: number;
  currency: string;
  description: string;
  supplierName: string;
};

type Props = Readonly<{
  payment: Payment;
  company: {
    id: string;
    country: string;
    name: string;
  };
  onFinished: () => void;
  onCancel: () => void;
}>;

export const AffidavitModalContainer = ({
  payment,
  company,
  onFinished,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const [step, setStep] = useState(1);
  const [signatureImageUrl, setSignatureImageUrl] = useState('');
  const isGermanCompany = company.country === 'DE';

  const [signAffidavit, signAffidavitQueryState] = useSignAffidavit(payment.id);
  const [createAffidavit, createAffidavitQueryState] = useCreateAffidavit(
    payment.id,
  );
  const isSignAffidavitLoading = signAffidavitQueryState.status === 'loading';
  const isCreateAffidavitLoading =
    createAffidavitQueryState.status === 'loading';

  useEffect(() => {
    logger.info('Mounting the AffidavitModalContainer', {
      href: window.location.href,
      team: 'capture',
      scope: 'affidavit-signature',
      paymentId: payment.id,
    });
  }, []);

  const formikProps = useFormik<DeclarationData>({
    initialValues: {
      type: 'affidavit',
      format: isGermanCompany ? 'german' : 'default',
      reason: '',
      userRole: '',
      supplierAddressThoroughfare: '',
      supplierAddressZipCode: '',
      supplierAddressCity: '',
      supplierAddressCountry: '',
      purchaseDescription: payment.description,
      supplierName: payment.supplierName ?? '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validate: (values) => {
      return validateAffidavitForm(values, isGermanCompany, t);
    },
    onSubmit: () => {},
  });

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSigned = async () => {
    await signAffidavit();
    track(AnalyticEventName.PAYMENTS_PANEL_UPLOAD_RECEIPT_BUTTON_CLICKED, {
      paymentId: payment.id,
      paymentUserId: payment.userId,
      uploadType: 'affidavit',
    });
  };

  const handleCreateAffidavit = async () => {
    const { signatureUrl } = await createAffidavit(
      reshapeDeclarationData(formikProps.values),
    );
    setSignatureImageUrl(signatureUrl);
  };

  if (step === 1) {
    return (
      <AffidavitStartModal
        onCancel={onCancel}
        onContinue={() => {
          if (isGermanCompany) {
            setStep(2);
          } else {
            setStep(4);
          }
        }}
      />
    );
  }

  if (step === 2) {
    return (
      <AffidavitUserRoleModal
        {...formikProps}
        companyName={company.name}
        onBack={handleBack}
        onCancel={onCancel}
        onContinue={() => {
          setStep(3);
        }}
      />
    );
  }

  if (step === 3) {
    return (
      <AffidavitPurchaseInfoModal
        {...formikProps}
        supplierName={payment.supplierName ?? ''}
        onBack={handleBack}
        onCancel={onCancel}
        onContinue={() => {
          setStep(4);
        }}
      />
    );
  }

  if (step === 4) {
    return (
      <AffidavitReasonModal
        {...formikProps}
        onCancel={onCancel}
        onBack={() => {
          if (isGermanCompany) {
            handleBack();
          } else {
            setStep(1);
          }
        }}
        onContinue={() => {
          setStep(5);
        }}
      />
    );
  }

  if (step === 5) {
    return (
      <AffidavitCertifyModal
        {...formikProps}
        onCancel={onCancel}
        onBack={handleBack}
        onContinue={async () => {
          await handleCreateAffidavit();
          setStep(6);
        }}
        isLoading={isCreateAffidavitLoading}
        paymentAmount={payment.amount}
        currency={payment.currency}
      />
    );
  }

  if (step === 6) {
    return (
      <AffidavitSignatureModal
        onBack={handleBack}
        onSigned={handleSigned}
        onFinish={() => setStep(7)}
        isLoading={isSignAffidavitLoading}
        signatureImageUrl={signatureImageUrl}
      />
    );
  }

  if (step === 7) {
    return <AffidavitFinalModal onFinish={onFinished} />;
  }
};
