import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  bank_country: string;
  iban: string;
  bic: string;
  cuc: string | null;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type Response = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type RawResponse = {};

export const useUpdateBankInformationMutation = (): MutationState<
  Payload,
  Response
> => {
  const companyId = useCompanyId();

  return useMutation<Payload, Response, RawResponse>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: '/bank-info',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['bankInformation', companyId]);
      },
    },
  });
};
