import cx from 'classnames';
import React, { useState, useEffect, useRef, type CSSProperties } from 'react';

import './SwitchButton.css';

type Props = {
  className?: string;
  activeButton: 'left' | 'right' | null;
  leftButton: {
    label: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
  rightButton: {
    label: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
};

export const SwitchButton = ({
  className,
  activeButton,
  leftButton,
  rightButton,
}: Props) => {
  const [backgroundStyle, setBackgroundStyle] = useState<CSSProperties>({});
  const buttonLeftReference = useRef<HTMLButtonElement>(null);
  const buttonRightReference = useRef<HTMLButtonElement>(null);

  const computeBackgroundStyle = (button: 'left' | 'right' | null) => {
    if (button === 'left' && buttonLeftReference.current) {
      const { width } = buttonLeftReference.current.getBoundingClientRect();
      setBackgroundStyle({ left: 4, width: width - 6 });
    }
    if (
      button === 'right' &&
      buttonRightReference.current &&
      buttonLeftReference.current
    ) {
      const { width: buttonLeftWidth } =
        buttonLeftReference.current.getBoundingClientRect();
      const { width } = buttonRightReference.current.getBoundingClientRect();
      setBackgroundStyle({ left: buttonLeftWidth + 2, width: width - 6 });
    }
  };

  useEffect(() => {
    computeBackgroundStyle(activeButton);
  }, [activeButton]);

  return (
    <div className={cx('SwitchButton', className)}>
      <button
        ref={buttonLeftReference}
        type="button"
        className={cx(
          'SwitchButton__button SwitchButton__button--left body-m',
          {
            'SwitchButton__button--active': activeButton === 'left',
          },
        )}
        onClick={leftButton.onClick}
        onMouseEnter={() => computeBackgroundStyle('left')}
        onMouseLeave={() => computeBackgroundStyle(activeButton)}
      >
        {leftButton.label}
      </button>
      <button
        ref={buttonRightReference}
        type="button"
        className={cx(
          'SwitchButton__button SwitchButton__button--right body-m',
          {
            'SwitchButton__button--active': activeButton === 'right',
          },
        )}
        onClick={rightButton.onClick}
        onMouseEnter={() => computeBackgroundStyle('right')}
        onMouseLeave={() => computeBackgroundStyle(activeButton)}
      >
        {rightButton.label}
      </button>
      {activeButton && (
        <div className="SwitchButton__background" style={backgroundStyle} />
      )}
    </div>
  );
};
