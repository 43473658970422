import { useTranslation } from 'common/hooks/useTranslation';

import { type OperationalOnboardingTask } from '../../../models';

class TypeGuardError extends Error {
  constructor(_switchCase: never, message: string) {
    super(message);
  }
}

export function useTaskName(onboardingTask: OperationalOnboardingTask): string {
  const { t } = useTranslation('global');
  switch (onboardingTask.type) {
    case 'addFunds':
      return t('homepage.welcomeDashboard.tasks.addFunds.name');
    case 'createControlRule':
      return t('homepage.welcomeDashboard.tasks.createControlRule.name');
    case 'createCostCenter':
      return t('homepage.welcomeDashboard.tasks.createCostCenter.name');
    case 'createExpenseCategory':
      return t('homepage.welcomeDashboard.tasks.createExpenseCategory.name');
    case 'createPaymentRequest':
      return t('homepage.welcomeDashboard.tasks.createPaymentRequest.name');
    case 'inviteMembers':
      return t('homepage.welcomeDashboard.tasks.inviteMembers.name');
    case 'orderPhysicalCard':
      return t('homepage.welcomeDashboard.tasks.orderPhysicalCard.name');
    case 'setAccountingIntegration':
      return t('homepage.welcomeDashboard.tasks.setAccountingIntegration.name');
    case 'reviewControlRule':
      return t('homepage.welcomeDashboard.tasks.reviewControlRule.name');
    default:
      throw new TypeGuardError(onboardingTask, 'Unknown onboarding task type');
  }
}
export function useTaskDescription(
  onboardingTask: OperationalOnboardingTask,
): string {
  const { t } = useTranslation('global');
  switch (onboardingTask.type) {
    case 'addFunds':
      return t('homepage.welcomeDashboard.tasks.addFunds.description');
    case 'createControlRule':
      return t('homepage.welcomeDashboard.tasks.createControlRule.description');
    case 'createCostCenter':
      return t('homepage.welcomeDashboard.tasks.createCostCenter.description');
    case 'createExpenseCategory':
      return t(
        'homepage.welcomeDashboard.tasks.createExpenseCategory.description',
      );
    case 'createPaymentRequest':
      return t(
        'homepage.welcomeDashboard.tasks.createPaymentRequest.description',
      );
    case 'inviteMembers':
      return t(`homepage.welcomeDashboard.tasks.inviteMembers.description`, {
        count: onboardingTask.state.threshold,
      });
    case 'orderPhysicalCard':
      return t('homepage.welcomeDashboard.tasks.orderPhysicalCard.description');
    case 'setAccountingIntegration':
      return t(
        'homepage.welcomeDashboard.tasks.setAccountingIntegration.description',
      );
    case 'reviewControlRule':
      return t('homepage.welcomeDashboard.tasks.reviewControlRule.description');
    default:
      throw new TypeGuardError(onboardingTask, 'Unknown onboarding task type');
  }
}

type LearnMoreLink = {
  uri: string;
  text: string;
};

export function useTaskLearnMoreLink(
  onboardingTask: OperationalOnboardingTask,
): LearnMoreLink | null {
  const { t } = useTranslation('global');
  switch (onboardingTask.type) {
    case 'createExpenseCategory':
      return {
        text: t(
          'homepage.welcomeDashboard.tasks.createExpenseCategory.learnMoreText',
        ),
        uri: t(
          'homepage.welcomeDashboard.tasks.createExpenseCategory.learnMoreUri',
        ),
      };
    case 'inviteMembers':
      return {
        text: t(`homepage.welcomeDashboard.tasks.inviteMembers.learnMoreText`),
        uri: t('homepage.welcomeDashboard.tasks.inviteMembers.learnMoreUri'),
      };
    case 'addFunds':
    case 'createControlRule':
    case 'createCostCenter':
    case 'createPaymentRequest':
    case 'orderPhysicalCard':
    case 'setAccountingIntegration':
    case 'reviewControlRule':
      return null;
    default:
      throw new TypeGuardError(onboardingTask, 'Unknown onboarding task type');
  }
}

export function useTaskLearnMoreCta(
  onboardingTask: OperationalOnboardingTask,
): string | null {
  const { t } = useTranslation('global');
  switch (onboardingTask.type) {
    case 'addFunds':
      return t('homepage.welcomeDashboard.tasks.addFunds.learnMoreUri');
    case 'orderPhysicalCard':
      return t(
        'homepage.welcomeDashboard.tasks.orderPhysicalCard.learnMoreUri',
      );
    case 'createControlRule':
    case 'createCostCenter':
    case 'createExpenseCategory':
    case 'createPaymentRequest':
    case 'inviteMembers':
    case 'setAccountingIntegration':
    case 'reviewControlRule':
      return null;
    default:
      throw new TypeGuardError(onboardingTask, 'Unknown onboarding task type');
  }
}

export function useTaskRedirectionCta(
  onboardingTask: OperationalOnboardingTask,
): string {
  const { t } = useTranslation('global');
  switch (onboardingTask.type) {
    case 'addFunds':
      return t('homepage.welcomeDashboard.tasks.addFunds.redirectionCta');
    case 'createControlRule':
      return t(
        'homepage.welcomeDashboard.tasks.createControlRule.redirectionCta',
      );
    case 'createCostCenter':
      return t(
        'homepage.welcomeDashboard.tasks.createCostCenter.redirectionCta',
      );
    case 'createExpenseCategory':
      return t(
        'homepage.welcomeDashboard.tasks.createExpenseCategory.redirectionCta',
      );
    case 'createPaymentRequest':
      return t(
        'homepage.welcomeDashboard.tasks.createPaymentRequest.redirectionCta',
      );
    case 'inviteMembers':
      return t('homepage.welcomeDashboard.tasks.inviteMembers.redirectionCta');
    case 'orderPhysicalCard':
      return t(
        'homepage.welcomeDashboard.tasks.orderPhysicalCard.redirectionCta',
      );
    case 'setAccountingIntegration':
      return t(
        'homepage.welcomeDashboard.tasks.setAccountingIntegration.redirectionCta',
      );
    case 'reviewControlRule':
      return t(
        'homepage.welcomeDashboard.tasks.reviewControlRule.redirectionCta',
      );
    default:
      throw new TypeGuardError(onboardingTask, 'Unknown onboarding task type');
  }
}
