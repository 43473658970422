// TODO: split the default modals in one that uses `code` one that uses `generalAccountCode` and `auxiliaryAccountCode`
import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

interface DefaultAccountBase {
  code: string;
  auxiliaryAccountCode?: string | undefined;
  isArchived?: boolean;
}

interface Props<TAccount extends DefaultAccountBase> {
  integration: AccountingSoftware;
  isOpen: boolean;
  setModalState: Dispatch<
    SetStateAction<{ kind: 'closed' | 'confirmEdit' | 'confirmDelete' }>
  >;
  onSetDefault: (defaultAccount: TAccount) => Promise<void>;
  existingDefaultAccount: TAccount | undefined;
  newDefaultAccount: TAccount;
  actionOrigin:
    | 'defaultEmployeeAccountForm'
    | 'defaultSupplierAccountForm'
    | 'defaultExpenseAccountForm'
    | 'defaultTaxAccountForm';
  auxiliaryAccountsEnabled: boolean;
}

export const DefaultAccountEditConfirmationModal = <
  TAccount extends DefaultAccountBase,
>({
  integration,
  isOpen,
  setModalState,
  onSetDefault,
  existingDefaultAccount,
  newDefaultAccount,
  actionOrigin,
  auxiliaryAccountsEnabled,
}: Props<TAccount>) => {
  const { t } = useTranslation('global');

  const getModalTitleTranslationKey = () => {
    switch (actionOrigin) {
      case 'defaultEmployeeAccountForm':
        return 'bookkeep.integrations.settings.defaultEmployeeAccountForm.confirmDefaultEditTitle';
      case 'defaultSupplierAccountForm':
        return 'bookkeep.integrations.settings.defaultSupplierAccountForm.confirmDefaultEditTitle';
      case 'defaultExpenseAccountForm':
        return 'bookkeep.integrations.settings.defaultExpenseAccountForm.confirmDefaultEditTitle';
      case 'defaultTaxAccountForm':
        return 'bookkeep.integrations.settings.defaultTaxAccountForm.confirmDefaultEditTitle';
      default:
        throw new Error(`Unexpected action origin ${actionOrigin}`);
    }
  };

  const modalTitle = t(getModalTitleTranslationKey(), {
    codeFrom: existingDefaultAccount ? existingDefaultAccount.code : '',
    auxiliaryAccountCodeFrom:
      auxiliaryAccountsEnabled &&
      existingDefaultAccount &&
      existingDefaultAccount.auxiliaryAccountCode
        ? existingDefaultAccount.auxiliaryAccountCode
        : '',
    codeTo: newDefaultAccount ? newDefaultAccount.code : '',
    auxiliaryAccountCodeTo:
      auxiliaryAccountsEnabled &&
      newDefaultAccount &&
      newDefaultAccount.auxiliaryAccountCode
        ? newDefaultAccount.auxiliaryAccountCode
        : '',
  });

  const getModalSubtitleTranslationKey = () => {
    switch (actionOrigin) {
      case 'defaultEmployeeAccountForm':
        return 'bookkeep.integrations.settings.defaultEmployeeAccountForm.confirmDefaultEditSubtitle';
      case 'defaultSupplierAccountForm':
        return 'bookkeep.integrations.settings.defaultSupplierAccountForm.confirmDefaultEditSubtitle';
      case 'defaultExpenseAccountForm':
        return 'bookkeep.integrations.settings.defaultExpenseAccountForm.confirmDefaultEditSubtitle';
      case 'defaultTaxAccountForm':
        return 'bookkeep.integrations.settings.defaultTaxAccountForm.confirmDefaultEditSubtitle';
      default:
        throw new Error(`Unexpected action origin ${actionOrigin}`);
    }
  };
  const modalSubtitle = hasIntegrationFileBasedExport(integration)
    ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
    : t(getModalSubtitleTranslationKey(), {
        code: existingDefaultAccount ? existingDefaultAccount.code : '',
      });

  return (
    <Modal
      title={modalTitle}
      subtitle={modalSubtitle}
      isOpen={isOpen}
      iconVariant="warning"
      iconName="pen"
      actions={[
        <Button
          autoFocus
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          id="confirm-edit-default-account"
          onClick={() => {
            onSetDefault(newDefaultAccount);
            setModalState({ kind: 'closed' });
          }}
          text={t('misc.yesConfirm')}
          variant="warning"
        />,
      ]}
    />
  );
};
