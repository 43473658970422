import { type Language } from 'src/core/config/i18n';

export const getNewReleaseBaseUrl = (lang: Language) => {
  switch (lang) {
    case 'de':
      return 'https://spendesk.com/de/platform/platform-releases/herbst-updates-2024';
    case 'fr':
      return 'https://spendesk.com/fr/platform/platform-releases/nouveautes-dautomne-2024';
    default:
      return 'https://spendesk.com/en/platform/platform-releases/autumn-updates-2024';
  }
};
