import deepEqual from 'fast-deep-equal';
import React, {
  useMemo,
  createContext,
  useContext,
  useReducer,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { logger } from 'src/utils/datadog-log-wrapper';

import { trackPayablesFiltersChanged } from '../analytics';

export type FiltersState = {
  documentaryEvidenceStatus: string | undefined;
  payableTypes: string[] | undefined;
  payableStates: string[] | undefined;
  settlementState: string | undefined;
  search: string | undefined;
  costCenter: string | undefined;
  creationDate: { from?: string; to: string } | undefined;
};

const filtersStateSchema = yup.object().shape({
  documentaryEvidenceStatus: yup.string().trim().default(undefined),
  payableTypes: yup.array().of(yup.string().required()).default(undefined),
  payableStates: yup.array().of(yup.string().required()).default(undefined),
  settlementState: yup.string().trim().default(undefined),
  search: yup.string().trim().default(undefined),
  costCenter: yup.string().trim().default(undefined),
  creationDate: yup
    .object()
    .shape({
      from: yup.string().trim().required(),
      to: yup.string().trim().required(),
    })
    .default(undefined),
});

const defaultState: FiltersState = {
  documentaryEvidenceStatus: undefined,
  payableTypes: undefined,
  payableStates: undefined,
  settlementState: undefined,
  search: undefined,
  costCenter: undefined,
  creationDate: undefined,
};

type FilterReducerAction =
  | { type: 'SET_RECEIPT_STATUS_FILTER'; status: string | undefined }
  | { type: 'SET_SEARCH_FILTER'; search: string }
  | {
      type: 'SET_CREATION_DATE_FILTER';
      creationDate: { from: string; to: string } | undefined;
    }
  | { type: 'SET_COST_CENTER_FILTER'; costCenter: string }
  | { type: 'SET_PAYABLE_TYPES_FILTER'; payableTypes: string[] }
  | { type: 'SET_PAYABLE_STATES_FILTER'; payableStates: string[] }
  | { type: 'SET_SETTLEMENT_STATE_FILTER'; settlementState: string }
  | { type: 'RESET_FILTERS' }
  | { type: 'SET_FILTERS_FROM_URL'; state: FiltersState };

export type FilterActions = ReturnType<typeof actionsFactory>;

const reducer = (
  state: FiltersState,
  action: FilterReducerAction,
): FiltersState => {
  switch (action.type) {
    case 'SET_RECEIPT_STATUS_FILTER': {
      return { ...state, documentaryEvidenceStatus: action.status };
    }
    case 'SET_SEARCH_FILTER': {
      return { ...state, search: action.search };
    }
    case 'SET_CREATION_DATE_FILTER': {
      return { ...state, creationDate: action.creationDate };
    }
    case 'SET_COST_CENTER_FILTER': {
      return { ...state, costCenter: action.costCenter };
    }
    case 'SET_PAYABLE_TYPES_FILTER': {
      return { ...state, payableTypes: action.payableTypes };
    }
    case 'SET_PAYABLE_STATES_FILTER': {
      return { ...state, payableStates: action.payableStates };
    }
    case 'SET_SETTLEMENT_STATE_FILTER': {
      return { ...state, settlementState: action.settlementState };
    }
    case 'RESET_FILTERS': {
      return defaultState;
    }
    case 'SET_FILTERS_FROM_URL': {
      return { ...state, ...action.state };
    }
    default:
      throw new Error(`Unexpected action ${action}`);
  }
};

const actionsFactory = (dispatch: (action: FilterReducerAction) => void) => ({
  setReceiptStatus: (status: string) => {
    trackPayablesFiltersChanged('SET_RECEIPT_STATUS_FILTER');
    dispatch({ type: 'SET_RECEIPT_STATUS_FILTER', status });
  },
  setSearch: (search: string) => {
    trackPayablesFiltersChanged('SET_SEARCH_FILTER');
    dispatch({ type: 'SET_SEARCH_FILTER', search });
  },
  setCreationDate: (creationDate: { from: string; to: string } | undefined) => {
    trackPayablesFiltersChanged('SET_CREATION_DATE_FILTER');
    dispatch({ type: 'SET_CREATION_DATE_FILTER', creationDate });
  },
  setCostCenter: (costCenter: string) => {
    trackPayablesFiltersChanged('SET_COST_CENTER_FILTER');
    dispatch({ type: 'SET_COST_CENTER_FILTER', costCenter });
  },
  setPayableTypes: (payableTypes: string[]) => {
    trackPayablesFiltersChanged('SET_PAYABLE_TYPES_FILTER');
    dispatch({ type: 'SET_PAYABLE_TYPES_FILTER', payableTypes });
  },
  setPayableStates: (payableStates: string[]) => {
    trackPayablesFiltersChanged('SET_PAYABLE_STATES_FILTER');
    dispatch({ type: 'SET_PAYABLE_STATES_FILTER', payableStates });
  },
  setSettlementState: (settlementState: string) => {
    trackPayablesFiltersChanged('SET_SETTLEMENT_STATE_FILTER');
    dispatch({ type: 'SET_SETTLEMENT_STATE_FILTER', settlementState });
  },
  reset: () => {
    trackPayablesFiltersChanged('RESET_FILTERS');
    dispatch({ type: 'RESET_FILTERS' });
  },
  setFiltersFromUrl: (filters: FiltersState) => {
    trackPayablesFiltersChanged('SET_FILTERS_FROM_URL');
    dispatch({ type: 'SET_FILTERS_FROM_URL', state: filters });
  },
});

const FiltersContext: React.Context<{
  state: FiltersState;
  actions: FilterActions;
  updateUrlParams: (filters: FiltersState) => void;
}> = createContext({
  state: defaultState,
  actions: actionsFactory(() => {}),
  updateUrlParams: (_) => {},
});

const getRawFiltersFromURLSearch = (search: string) => {
  const urlParams = new URLSearchParams(search);

  const creationDateTo = urlParams.get('creationDate.to');
  const creationDateFrom = urlParams.get('creationDate.from');

  return {
    documentaryEvidenceStatus:
      urlParams.get('documentaryEvidenceStatus') ?? undefined,
    payableTypes: urlParams.getAll('payableTypes')?.length
      ? urlParams.getAll('payableTypes')
      : undefined,
    payableStates: urlParams.getAll('payableStates')?.length
      ? urlParams.getAll('payableStates')
      : undefined,
    settlementState: urlParams.get('settlementState') ?? undefined,
    search: urlParams.get('search') ?? undefined,
    costCenter: urlParams.get('costCenter') ?? undefined,
    creationDate:
      creationDateTo && creationDateFrom
        ? {
            to: creationDateTo,
            from: creationDateFrom,
          }
        : undefined,
  };
};

const getFilterStateFromUrlSearch = (
  search: string,
  state: FiltersState = defaultState,
): FiltersState | undefined => {
  if (search) {
    const rawFilters = getRawFiltersFromURLSearch(search);

    if (!deepEqual(rawFilters, state)) {
      try {
        return {
          ...defaultState,
          ...filtersStateSchema.validateSync(rawFilters),
        };
      } catch (error) {
        logger.warn('Unable to safely parse url params on all payables', {
          error,
          search,
          team: 'finance-accountant',
          scope: '',
        });
      }
    }
  }
};

export const FiltersContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const history = useHistory();

  const [state, dispatch] = useReducer(
    reducer,
    getFilterStateFromUrlSearch(location.search) || defaultState,
  );

  const actions = useMemo(() => actionsFactory(dispatch), []);

  const updateUrlParams = (filters: FiltersState) => {
    const searchParams = new URLSearchParams();

    Object.entries(filters).forEach(([key, value]) => {
      if (typeof value === 'string') {
        searchParams.append(key, value);
      } else if (Array.isArray(value)) {
        value.forEach((v) => {
          searchParams.append(key, v);
        });
      } else if (key === 'creationDate' && value?.from) {
        searchParams.append('creationDate.from', value.from);
        searchParams.append('creationDate.to', value.to);
      }
    });

    history.push(`${location.pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    const newFilterState = getFilterStateFromUrlSearch(location.search, state);
    if (newFilterState) {
      actions.setFiltersFromUrl(newFilterState);
    }
  }, [location.search]);

  const contextValue = { state, actions, updateUrlParams };

  return (
    <FiltersContext.Provider value={contextValue}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = () => useContext(FiltersContext);
