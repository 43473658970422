/* eslint-disable @typescript-eslint/naming-convention */

export interface AchState {
  hasPendingAch: boolean;
  hasValidatedAch: boolean;
  pendingAchCreatedAt: Date | null;
  achSourceToken: string | null;
  achSourceAccountSuffix: string | null;
  mfaProcedureId: string | null;
  mfaType: string | null;
  mfaParams: string | null;
  gpaOrders: GpaOrder[];
  oneOffFundingRequestCreateStep: OneOffFundingRequestCreateSteps;
  automatedFundingRuleLoadingStep: AutomatedFundingRuleLoadingSteps;
  automatedFundingRuleDeleteStep: AutomatedFundingRuleDeleteSteps;
  automatedFundingRuleCreateStep: AutomatedFundingRuleCreateSteps;
  automatedFundingRuleAmount: number | null;
  automatedFundingRuleThreshold: number | null;
  automatedFundingRuleCreatedAt: Date | null;
  hasJustCreatedAutomatedFundingRule: boolean;
  hasJustCreatedOneOffFundingRequest: boolean;
  isLoading: boolean;
  error: string | null;
  fundingActionsCount: number;
}

export enum OneOffFundingRequestCreateSteps {
  OneOffFundingRequestCreateForm = 'OneOffFundingRequestCreateForm',
  OneOffFundingRequestCreateModal = 'OneOffFundingRequestCreateModal',
}

export enum AutomatedFundingRuleLoadingSteps {
  AutomatedFundingRuleToBeLoaded = 'AutomatedFundingRuleToBeLoaded',
  AutomatedFundingRuleLoading = 'AutomatedFundingRuleLoading',
  AutomatedFundingRuleLoaded = 'AutomatedFundingRuleLoaded',
}

export enum AutomatedFundingRuleDeleteSteps {
  AutomatedFundingRuleDeleteForm = 'AutomatedFundingRuleDeleteForm',
  AutomatedFundingRuleDeleteModal = 'AutomatedFundingRuleDeleteModal',
}

export enum AutomatedFundingRuleCreateSteps {
  AutomatedFundingRuleCreateForm = 'AutomatedFundingRuleCreateForm',
  AutomatedFundingRuleCreateModal = 'AutomatedFundingRuleCreateModal',
}

export interface CheckBankStatementFormValues {
  firstAmount: string;
  secondAmount: string;
}

export interface ParsedCheckBankStatementFormValues {
  firstAmount: number;
  secondAmount: number;
}

export interface CreateFundingRequestFormValues {
  amount: string;
}

export interface CreateAutomatedFundingRuleFormValues {
  amount: string;
  threshold: string;
}

export interface DeleteAutomatedFundingRuleFormValues {}

export interface ParsedCreateFundingRequestFormValues {
  amount: number;
}

export interface ParsedCreateAutomatedFundingRuleFormValues {
  amount: number;
  threshold: number;
}
export interface ParsedDeleteAutomatedFundingRuleFormValues {}

export interface ValidatePinFormValues {
  pin: string[];
}

export interface ParsedValidatePinFormValues {
  pin: string;
}

export interface MarqetaAchSource {
  type: string;
  token: string;
  createdTime: string;
  lastModifiedTime: string;
  accountSuffix: string;
  accountType: string;
  active: boolean;
  isDefaultAccount: boolean;
  expDate: string;
  userToken: string;
  verificationStatus: string;
}

export interface MarqetaAchSources {
  count: number;
  startIndex: number;
  endIndex: number;
  isMore: boolean;
  data: MarqetaAchSource[];
}

export const GET_ACH_FUNDING_SOURCES_LOADING =
  'ach/GET_ACH_FUNDING_SOURCES_LOADING';
export const GET_ACH_FUNDING_SOURCES_FAILURE =
  'ach/GET_ACH_FUNDING_SOURCES_FAILURE';
export const GET_ACH_FUNDING_SOURCES_SUCCESS =
  'ach/GET_ACH_FUNDING_SOURCES_SUCCESS';
export const CHECK_BANK_STATEMENT_LOADING = 'ach/CHECK_BANK_STATEMENT_LOADING';
export const CHECK_BANK_STATEMENT_FAILURE = 'ach/CHECK_BANK_STATEMENT_FAILURE';
export const CHECK_BANK_STATEMENT_SUCCESS = 'ach/CHECK_BANK_STATEMENT_SUCCESS';

export const GET_AUTOMATED_FUNDING_RULE_LOADING =
  'ach/GET_AUTOMATED_FUNDING_RULE_LOADING';
export const GET_AUTOMATED_FUNDING_RULE_FAILURE =
  'ach/GET_AUTOMATED_FUNDING_RULE_FAILURE';
export const GET_AUTOMATED_FUNDING_RULE_SUCCESS =
  'ach/GET_AUTOMATED_FUNDING_RULE_SUCCESS';

export const CREATE_FUNDING_REQUEST_LOADING =
  'ach/CREATE_FUNDING_REQUEST_LOADING';
export const CREATE_FUNDING_REQUEST_FAILURE =
  'ach/CREATE_FUNDING_REQUEST_FAILURE';
export const CREATE_FUNDING_REQUEST_SUCCESS =
  'ach/CREATE_FUNDING_REQUEST_SUCCESS';
export const CREATE_AUTOMATED_FUNDING_RULE_LOADING =
  'ach/CREATE_AUTOMATED_FUNDING_RULE_LOADING';
export const CREATE_AUTOMATED_FUNDING_RULE_FAILURE =
  'ach/CREATE_AUTOMATED_FUNDING_RULE_FAILURE';
export const CREATE_AUTOMATED_FUNDING_RULE_SUCCESS =
  'ach/CREATE_AUTOMATED_FUNDING_RULE_SUCCESS';
export const DELETE_AUTOMATED_FUNDING_RULE_LOADING =
  'ach/DELETE_AUTOMATED_FUNDING_RULE_LOADING';
export const DELETE_AUTOMATED_FUNDING_RULE_FAILURE =
  'ach/DELETE_AUTOMATED_FUNDING_RULE_FAILURE';
export const DELETE_AUTOMATED_FUNDING_RULE_SUCCESS =
  'ach/DELETE_AUTOMATED_FUNDING_RULE_SUCCESS';
export const SET_MFA_PROCEDURE_ID = 'ach/SET_MFA_PROCEDURE_ID';
export const SET_MFA_TYPE = 'ach/SET_MFA_TYPE';
export const SET_MFA_PARAMS = 'ach/SET_MFA_PARAMS';
export const VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_LOADING =
  'ach/VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_LOADING';
export const VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_FAILURE =
  'ach/VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_FAILURE';
export const VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_SUCCESS =
  'ach/VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_SUCCESS';
export const VALIDATE_AUTOMATED_FUNDING_RULE_PIN_LOADING =
  'ach/VALIDATE_AUTOMATED_FUNDING_RULE_PIN_LOADING';
export const VALIDATE_AUTOMATED_FUNDING_RULE_PIN_FAILURE =
  'ach/VALIDATE_AUTOMATED_FUNDING_RULE_PIN_FAILURE';
export const VALIDATE_AUTOMATED_FUNDING_RULE_PIN_SUCCESS =
  'ach/VALIDATE_AUTOMATED_FUNDING_RULE_PIN_SUCCESS';
export const SET_WALLET_FUNDING_STEP = 'ach/SET_WALLET_FUNDING_STEP';
export const SET_WALLET_AUTOMATED_FUNDING_STEP =
  'ach/SET_WALLET_AUTOMATED_FUNDING_STEP';
export const SET_WALLET_AUTOMATED_FUNDING_CREATE_STEP =
  'ach/SET_WALLET_AUTOMATED_FUNDING_CREATE_STEP';
export const SET_WALLET_AUTOMATED_FUNDING_DELETE_STEP =
  'ach/SET_WALLET_AUTOMATED_FUNDING_DELETE_STEP';
export const GET_GPA_ORDERS_LOADING = 'ach/GET_GPA_ORDERS_LOADING';
export const GET_GPA_ORDERS_FAILURE = 'ach/GET_GPA_ORDERS_FAILURE';
export const GET_GPA_ORDERS_SUCCESS = 'ach/GET_GPA_ORDERS_SUCCESS';

export interface GetAchFundingSourcesLoading {
  readonly type: typeof GET_ACH_FUNDING_SOURCES_LOADING;
}

export interface GetAchFundingSourcesFailure {
  readonly type: typeof GET_ACH_FUNDING_SOURCES_FAILURE;
  readonly payload: Error;
}

export interface GetAchFundingSourcesSuccess {
  readonly type: typeof GET_ACH_FUNDING_SOURCES_SUCCESS;
  readonly payload: {
    hasPendingAch: boolean;
    hasValidatedAch: boolean;
    pendingAchCreatedAt: Date | null;
    achSourceToken: string;
    achSourceAccountSuffix: string | null;
  };
}

export interface CheckBankStatementLoading {
  readonly type: typeof CHECK_BANK_STATEMENT_LOADING;
}

export interface CheckBankStatementFailure {
  readonly type: typeof CHECK_BANK_STATEMENT_FAILURE;
  readonly payload: Error;
}

export interface CheckBankStatementSuccess {
  readonly type: typeof CHECK_BANK_STATEMENT_SUCCESS;
}

export interface GetAutomatedFundingLoading {
  readonly type: typeof GET_AUTOMATED_FUNDING_RULE_LOADING;
}

export interface GetAutomatedFundingFailure {
  readonly type: typeof GET_AUTOMATED_FUNDING_RULE_FAILURE;
  readonly payload: Error;
}

export interface GetAutomatedFundingSuccess {
  readonly type: typeof GET_AUTOMATED_FUNDING_RULE_SUCCESS;
  readonly payload: {
    amount: number | null;
    threshold: number | null;
    createdAt: Date | null;
  };
}

export interface CreateFundingRequestLoading {
  readonly type: typeof CREATE_FUNDING_REQUEST_LOADING;
}

export interface CreateFundingRequestFailure {
  readonly type: typeof CREATE_FUNDING_REQUEST_FAILURE;
  readonly payload: Error;
}

export interface CreateFundingRequestSuccess {
  readonly type: typeof CREATE_FUNDING_REQUEST_SUCCESS;
  readonly payload: {
    mfaProcedureId: string;
  };
}

export interface CreateAutomatedFundingRuleLoading {
  readonly type: typeof CREATE_AUTOMATED_FUNDING_RULE_LOADING;
}

export interface CreateAutomatedFundingRuleFailure {
  readonly type: typeof CREATE_AUTOMATED_FUNDING_RULE_FAILURE;
  readonly payload: Error;
}

export interface CreateAutomatedFundingRuleSuccess {
  readonly type: typeof CREATE_AUTOMATED_FUNDING_RULE_SUCCESS;
  readonly payload: {
    mfaProcedureId: string;
    amount: number;
    threshold: number;
    date: string;
  };
}

export interface DeleteAutomatedFundingRuleLoading {
  readonly type: typeof DELETE_AUTOMATED_FUNDING_RULE_LOADING;
}

export interface DeleteAutomatedFundingRuleFailure {
  readonly type: typeof DELETE_AUTOMATED_FUNDING_RULE_FAILURE;
  readonly payload: Error;
}

export interface DeleteAutomatedFundingRuleSuccess {
  readonly type: typeof DELETE_AUTOMATED_FUNDING_RULE_SUCCESS;
}

export interface SetMfaType {
  readonly type: typeof SET_MFA_TYPE;
  readonly payload: string;
}

export interface SetMfaParams {
  readonly type: typeof SET_MFA_PARAMS;
  readonly payload: string;
}

export interface SetMfaProcedureId {
  readonly type: typeof SET_MFA_PROCEDURE_ID;
  readonly payload: string;
}

export interface ValidateOneOffFundingRequestPinLoading {
  readonly type: typeof VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_LOADING;
}

export interface ValidateOneOffFundingRequestPinFailure {
  readonly type: typeof VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_FAILURE;
  readonly payload: Error;
}

export interface ValidateOneOffFundingRequestPinSuccess {
  readonly type: typeof VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_SUCCESS;
}

export interface ValidateAutomatedFundingRulePinLoading {
  readonly type: typeof VALIDATE_AUTOMATED_FUNDING_RULE_PIN_LOADING;
}

export interface ValidateAutomatedFundingRulePinFailure {
  readonly type: typeof VALIDATE_AUTOMATED_FUNDING_RULE_PIN_FAILURE;
  readonly payload: Error;
}

export interface ValidateAutomatedFundingRulePinSuccess {
  readonly type: typeof VALIDATE_AUTOMATED_FUNDING_RULE_PIN_SUCCESS;
}

export interface SetWalletFundingStep {
  readonly type: typeof SET_WALLET_FUNDING_STEP;
  readonly payload: OneOffFundingRequestCreateSteps;
}

export interface SetWalletAutomatedFundingStep {
  readonly type: typeof SET_WALLET_AUTOMATED_FUNDING_STEP;
  readonly payload: AutomatedFundingRuleLoadingSteps;
}

export interface SetWalletAutomatedFundingCreateStep {
  readonly type: typeof SET_WALLET_AUTOMATED_FUNDING_CREATE_STEP;
  readonly payload: AutomatedFundingRuleCreateSteps;
}

export interface SetWalletAutomatedFundingDeleteStep {
  readonly type: typeof SET_WALLET_AUTOMATED_FUNDING_DELETE_STEP;
  readonly payload: AutomatedFundingRuleDeleteSteps;
}

export interface GetGpaOrdersLoading {
  readonly type: typeof GET_GPA_ORDERS_LOADING;
}

export interface GetGpaOrdersFailure {
  readonly type: typeof GET_GPA_ORDERS_FAILURE;
  readonly payload: Error;
}

export interface GetGpaOrdersSuccess {
  readonly type: typeof GET_GPA_ORDERS_SUCCESS;
  readonly gpaOrders: GpaOrder[];
}

export type GpaOrder = {
  token: string;
  status: 'PENDING' | 'CLEARED' | 'COMPLETION' | 'DECLINED' | 'REVERSED';
  amount: number;
  userTransactionDateTime: string;
  currencyCode: string;
};

export type AutomatedFundingRule = {
  amount: number | null;
  threshold: number | null;
  createdAt: Date | null;
};

export type AchAction =
  | GetAchFundingSourcesLoading
  | GetAchFundingSourcesFailure
  | GetAchFundingSourcesSuccess
  | CheckBankStatementLoading
  | CheckBankStatementFailure
  | CheckBankStatementSuccess
  | GetAutomatedFundingLoading
  | GetAutomatedFundingFailure
  | GetAutomatedFundingSuccess
  | CreateFundingRequestLoading
  | CreateFundingRequestFailure
  | CreateFundingRequestSuccess
  | CreateAutomatedFundingRuleLoading
  | CreateAutomatedFundingRuleFailure
  | CreateAutomatedFundingRuleSuccess
  | DeleteAutomatedFundingRuleLoading
  | DeleteAutomatedFundingRuleFailure
  | DeleteAutomatedFundingRuleSuccess
  | SetMfaProcedureId
  | SetMfaType
  | SetMfaParams
  | ValidateOneOffFundingRequestPinLoading
  | ValidateOneOffFundingRequestPinFailure
  | ValidateOneOffFundingRequestPinSuccess
  | ValidateAutomatedFundingRulePinLoading
  | ValidateAutomatedFundingRulePinFailure
  | ValidateAutomatedFundingRulePinSuccess
  | SetWalletFundingStep
  | SetWalletAutomatedFundingStep
  | SetWalletAutomatedFundingCreateStep
  | SetWalletAutomatedFundingDeleteStep
  | GetGpaOrdersLoading
  | GetGpaOrdersFailure
  | GetGpaOrdersSuccess;
