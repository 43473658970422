import { type FormikErrors } from 'formik';

import { validate as validateCustomFieldValues } from 'modules/custom-fields/components/CustomFieldCreationModal/validate';
import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { type FormValues } from './types';

export const validate = ({
  values,
  t,
}: {
  values: FormValues;
  t: TGlobalFunctionTyped;
}) => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.name.trim()) {
    errors.name = t('customFields.creation.nameRequired');
  }

  const valuesErrors = validateCustomFieldValues(values);
  if (Object.keys(valuesErrors).length > 0) {
    // Not setting a translation here
    // because error display is handled in the CustomFieldValuesFormField component
    errors.customFieldValues = 'invalid_field';
  }

  return errors;
};
