import { useMutation } from 'src/core/api/hooks/useMutation';

export const useUpdateCustomFieldValuesMutation = (customFieldId: string) => {
  return useMutation<{
    values: {
      id?: string;
      value: string;
      isDefault: boolean;
    }[];
  }>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/custom-fields-package/custom-fields/${customFieldId}/values`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['customFields']);
        client.invalidateQueries(['useCustomFieldValuesQuery', customFieldId]);
      },
    },
  });
};
