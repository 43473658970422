import cx from 'classnames';
import React from 'react';

import { track, AnalyticEventName } from "src/core/utils/analytics";
import {
  mobileAppDownloadLinks,
  MobileDeviceTypes,
} from "src/core/utils/devices";

import styles from './DownloadAppBadges.module.css';
import AppStoreBadge from './images/app-store-badge.svg';
import GooglePlayBadge from './images/google-play-badge.svg';

interface Props {
  className?: string;
}

const onDownloadAppClick = (device: 'ios' | 'android'): void => {
  track(AnalyticEventName.MOBILE_APP_SCA, {
    device,
  });
};

export const DownloadAppBadges = ({ className }: Props) => {
  return (
    <div className={cx(styles.DownloadAppBadges, className)}>
      <a
        type="button"
        onClick={() => onDownloadAppClick('ios')}
        href={mobileAppDownloadLinks[MobileDeviceTypes.IOS]}
        target="_blank"
        rel="noreferrer"
      >
        <img src={AppStoreBadge} alt="Download app on Apple Store" />
      </a>
      <a
        type="button"
        onClick={() => onDownloadAppClick('android')}
        target="_blank"
        href={mobileAppDownloadLinks[MobileDeviceTypes.Android]}
        rel="noreferrer"
      >
        <img src={GooglePlayBadge} alt="Download app on Google Play" />
      </a>
    </div>
  );
};
