import { compose } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { CenteredLayout } from 'common/components/layout';
import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useModal } from 'common/hooks/useModalGrapes';
import { ExpenseCategoriesContainer } from 'modules/company/expense-categories';
import * as groupsActions from 'src/core/actions/groups';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';
import { getCustomFieldsActivationPendingKey } from 'src/core/constants/storage';
import authorize from 'src/core/utils/authorize';

import { CustomFields } from './components/CustomFields';
import { CustomFieldsLoader } from './components/CustomFieldsLoader';
import { CustomFieldsPageHeader } from './components/CustomFieldsPageHeader';
import { CustomFieldCreationModalContainer } from './containers/CustomFieldCreationModalContainer';
import { useCustomFieldsQuery } from './hooks/useCustomFieldsQuery';
import { useDesignatedCustomFieldQuery } from './hooks/useDesignatedCustomFieldQuery';
import { type CustomField } from './models/customField';
import { QuerySuspense } from '../../common/components/QuerySuspense';
import { useTranslation } from '../../common/hooks/useTranslation';
import { type AppState } from '../../reducers';
import { useCompany } from '../app/hooks/useCompany';
import { useCompanyId } from '../app/hooks/useCompanyId';
import { useUser } from '../app/hooks/useUser';

import './CustomFieldsContainer.css';

const TOTAL_CUSTOM_FIELDS_AMOUNT = 5;

type BaseProps = {
  groups: { id: string; name: string }[];
  fetchGroups: () => unknown;
};

// TODO: this container should be moved in the company module
const CustomFieldsContainer = ({ groups, fetchGroups }: BaseProps) => {
  const customFieldsQueryState = useCustomFieldsQuery({ isEnabled: true });
  const designatedCustomFieldQueryState = useDesignatedCustomFieldQuery();

  const { t } = useTranslation('global');

  return (
    <ErrorBoundary
      context={{ scope: 'custom-fields', team: 'finance-controller' }}
    >
      <QuerySuspense
        queryState={customFieldsQueryState}
        fallback={() => (
          <CenteredLayout
            className="CustomFieldsContainer text-center"
            verticalAlign="middle"
          >
            {t('misc.errors.networkError')}
          </CenteredLayout>
        )}
        loading={<CustomFieldsLoader />}
      >
        {(customFields) => (
          <CustomFieldsContainerWithData
            expenseCategoryCustomFieldId={
              designatedCustomFieldQueryState.status === 'success'
                ? designatedCustomFieldQueryState.data.customFieldId
                : undefined
            }
            customFields={customFields ?? []}
            groups={groups}
            fetchGroups={fetchGroups}
          />
        )}
      </QuerySuspense>
    </ErrorBoundary>
  );
};
const CustomFieldsContainerWithData = ({
  groups,
  fetchGroups,
  customFields,
  expenseCategoryCustomFieldId,
}: BaseProps & {
  customFields: CustomField[];
  expenseCategoryCustomFieldId?: string;
}) => {
  const company = useCompany();

  const isExpenseCategoriesFeatureEnabled = useFeature(
    FEATURES.EXPENSE_CATEGORIES,
  );

  const expenseCategory = customFields.find(
    (customField) => customField.id === expenseCategoryCustomFieldId,
  );
  const filteredCustomFields = customFields.filter(
    ({ id }) => id !== expenseCategoryCustomFieldId,
  );

  // we only consider custom fields that are not flagged as representing
  // expense categories
  const hasCustomFields = filteredCustomFields.length > 0;

  const [customFieldCreationModal, showCustomFieldCreationModal] = useModal(
    ({ onClose, isOpen }) => {
      return (
        <CustomFieldCreationModalContainer
          isOpen={isOpen}
          onClose={() => onClose()}
        />
      );
    },
  );

  useEffect(() => {
    fetchGroups();

    const localStorageKey = getCustomFieldsActivationPendingKey(company.id);
    if (hasCustomFields && localStorage.getItem(localStorageKey)) {
      // Clear local storage flag because it's no longer necessary
      localStorage.removeItem(localStorageKey);
    }
  }, []);

  const canCreateMoreCustomFields =
    filteredCustomFields.length < TOTAL_CUSTOM_FIELDS_AMOUNT;

  return (
    <>
      {customFieldCreationModal}
      <CustomFieldsPageHeader
        onButtonClick={() => showCustomFieldCreationModal()}
        isButtonDisabled={!canCreateMoreCustomFields}
      />
      {isExpenseCategoriesFeatureEnabled && (
        <div className="CustomFieldsContainer__expenseCategory">
          <ExpenseCategoriesContainer
            expenseCategoryCustomField={expenseCategory}
          />
        </div>
      )}

      {hasCustomFields ? (
        <CustomFields customFields={filteredCustomFields} groups={groups} />
      ) : null}
    </>
  );
};

const mapDispatchToProps = {
  fetchGroups: groupsActions.fetchGroups,
};

const mapStateToProps = (state: AppState) => {
  const {
    categories: { customFields, groups },
  } = state;
  return {
    customFields,
    groups,
  };
};

const withAuthorization = authorize(
  () => {
    const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);
    const user = useUser();
    return (
      (user.is_account_owner || user.is_admin || user.is_controller) &&
      hasCustomFieldsFeature
    );
  },
  () => {
    const companyId = useCompanyId();
    return (
      <Redirect
        to={{
          pathname: routeFor(routes.NOT_FOUND.path, {
            company: companyId,
          }),
        }}
      />
    );
  },
);

export default compose<() => JSX.Element>(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization,
)(CustomFieldsContainer);
