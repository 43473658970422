import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type CustomFieldPayload } from '../customField';

export const useCreateCustomFieldMutation = (): MutationState<
  CustomFieldPayload,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {}
> => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return useMutation<CustomFieldPayload, {}, {}>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/custom-fields-package/custom-fields',
      method: 'post',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      throwOnError: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSuccess({ client }: any): void {
        client.invalidateQueries(['customFields']);
      },
    },
  });
};
