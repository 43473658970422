import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type RawUser = {
  id: string;
  fullName: string;
  email: string;
  first_name: string;
};

export const useCompanyAccountOwnerQuery = (
  isEnabled = true,
): QueryState<RawUser | undefined> => {
  const companyId = useCompanyId();

  return useQuery<RawUser | undefined, RawUser>({
    key: ['accountOwner', companyId],
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/users/account-owner',
    },
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
  });
};
