import { Icon } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { FeedbackPopover } from './FeedbackPopover';

export const FeedbackButton = () => {
  const { t } = useTranslation('global');

  return (
    <li>
      <FeedbackPopover>
        {({ isDropdown: _, ...rest }) => {
          return (
            <button {...rest} className="navigation-item h-xl" type="button">
              <Icon name="speaker" aria-hidden="true" className="shrink-0" />
              <span className="navigation-toggle-display grow">
                {t('betaFeature.giveFeedback')}
              </span>
            </button>
          );
        }}
      </FeedbackPopover>
    </li>
  );
};
