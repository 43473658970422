import React from 'react';

import { ModalError } from 'common/components/ModalError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import history from 'src/core/history';
import {
  useAccountingIntegrationName,
  useHasAccountingIntegrationCapability,
} from 'src/core/modules/accounting-integration/apis';

import { MemberDetailsModal } from '../../components/MemberDetailsModal';
import { MemberModalLoader } from '../../components/MemberModalLoader';
import {
  useDeleteMemberMutation,
  useMemberQuery,
  useResendInvitationMutation,
  useUpdateCardsAccessMutation,
} from '../../hooks';
import { useMemberSyncQuery } from '../../hooks/useMemberSyncStatusQuery';
import { type Member } from '../../models/member';

type Props = {
  memberId: string;
  onEdit(): void;
  onDelete(): void;
  onClose(): void;
};

export function MemberDetailsModalContainer({
  memberId,
  onClose,
  onDelete,
  onEdit,
}: Props) {
  const { t } = useTranslation();
  const { pushNotif } = useNotifications();

  const memberQueryState = useMemberQuery(memberId);
  const company = useCompany(); // no react query hook?
  const user = useUser();
  const integrationName = useAccountingIntegrationName();
  const memberSyncIsEnabled =
    useHasAccountingIntegrationCapability('memberSync');
  const memberSyncStatus = useMemberSyncQuery(memberId);

  const [resendInvitationMutation] = useResendInvitationMutation(
    memberQueryState.status === 'success'
      ? memberQueryState.data.inviteId
      : undefined,
  );
  const [updateCardsAccessMutation] = useUpdateCardsAccessMutation(memberId);
  const [deleteMemberMutation] = useDeleteMemberMutation(memberId);

  const handleResendInvitation = async () => {
    try {
      await resendInvitationMutation();
      pushNotif({
        type: NotificationType.Success,
        message: t('members.invitationSent'),
      });
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('misc.errors.serverError'),
      });
    }
  };

  const handleUpdateCardsAccess = async (hasAccess: boolean) => {
    try {
      await updateCardsAccessMutation({ hasAccess });
      pushNotif({
        type: NotificationType.Success,
        message: hasAccess
          ? t('members.cardsAccessAllowed')
          : t('members.cardsAccessRestricted'),
      });
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('misc.errors.serverError'),
      });
    }
  };

  const handleDeleteMember = async (member: Member) => {
    if (member.pending) {
      try {
        await deleteMemberMutation({});

        onClose();

        pushNotif({
          type: NotificationType.Success,
          message: t('members.invitationDeleted'),
        });
      } catch {
        pushNotif({
          type: NotificationType.Danger,
          message: t('misc.errors.serverError'),
        });
      }
    } else {
      onDelete();
    }
  };

  return (
    <QuerySuspense
      queryState={memberQueryState}
      loading={<MemberModalLoader />}
      fallback={() => (
        <ModalError
          message={t('members.errorModal.fetchFailedMessage')}
          onClose={onClose}
          onRetry={() => history.go(0)}
        />
      )}
    >
      {(member) => (
        <MemberDetailsModal
          member={member}
          company={company}
          isCurrentUser={user.id === member.id}
          memberSync={{
            status:
              memberSyncStatus.status === 'success'
                ? memberSyncStatus.data
                : undefined,
            isEnabled: memberSyncIsEnabled,
            integrationName,
          }}
          onEdit={onEdit}
          onDelete={() => handleDeleteMember(member)}
          onResendInvitation={handleResendInvitation}
          onUpdateCardsAccess={handleUpdateCardsAccess}
          onClose={onClose}
        />
      )}
    </QuerySuspense>
  );
}
