import { IconButton } from '@dev-spendesk/grapes';
import cx from 'classnames';
import isFunction from 'lodash/isFunction';
import React, {
  Component,
  createRef,
  type PropsWithChildren,
  type ReactNode,
} from 'react';
import ReactDOM from 'react-dom';

import './Modal.css';

type ModalProps = {
  title?: ReactNode;
  exitAction?: () => void;
  triggerAction?: () => void;
  outsideClickAction?: () => void;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  hasExitButton?: boolean;
  children: ReactNode;
};

/**
 * @deprecated use Modal from Grapes instead
 */
class Modal extends Component<ModalProps> {
  static defaultProps = {
    size: 'medium',
    hasExitButton: true,
  };

  modalRootRef = createRef<HTMLDivElement>();

  componentDidMount() {
    const { exitAction, triggerAction } = this.props;
    if (exitAction && (isFunction(exitAction) || isFunction(triggerAction))) {
      window.addEventListener('keydown', this.listenToKeyboardEvents);
    }
  }

  componentWillUnmount() {
    const { exitAction, triggerAction } = this.props;
    if (exitAction && (isFunction(exitAction) || isFunction(triggerAction))) {
      window.removeEventListener('keydown', this.listenToKeyboardEvents);
    }
  }

  getModalTitle = () => (
    <div className="Modal-title-wrapper">
      {this.props.title && (
        <div className="Modal-title">{this.props.title}</div>
      )}
      {this.props.hasExitButton && (
        <IconButton
          className="Modal-close"
          iconName="cross"
          onClick={this.props.exitAction}
          aria-label="close"
        />
      )}
    </div>
  );

  outsideClickAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { outsideClickAction } = this.props;
    if (!outsideClickAction) {
      return;
    }
    if (this.modalRootRef.current === e.target) {
      outsideClickAction();
    }
  };

  listenToKeyboardEvents = (e: KeyboardEvent) => {
    const { exitAction, triggerAction } = this.props;
    if (exitAction && e.keyCode === 27) {
      // Escape = close the modal
      exitAction.call(null);
    } else if (triggerAction && e.keyCode === 13) {
      // Enter = submit modal action
      triggerAction.call(null);
    }
  };

  render() {
    const { title, className, size, children, hasExitButton } = this.props;
    const content = (
      <div
        className={cx('Modal', className, size)}
        onMouseDown={this.outsideClickAction}
        ref={this.modalRootRef}
      >
        <div className="Modal-wrapper">
          {(title || hasExitButton) && this.getModalTitle()}
          <div className="Modal-content">{children}</div>
        </div>
      </div>
    );

    const domNode = document && document.getElementById('react__page-modal');
    if (domNode === null) {
      return content;
    }

    return ReactDOM.createPortal(content, domNode);
  }
}

type ModalActionsProps = {
  className?: string;
};

export const ModalActions = ({
  children,
  className = '',
}: PropsWithChildren<ModalActionsProps>) => (
  <div className={cx('Modal-actions', className)}>{children}</div>
);

export default Modal;
