import { type UserToTransferRightsTo } from 'modules/profile/models/user';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export const useUsersForOwnershipTransferQuery = (
  type: 'company' | 'organisation' | 'approval-rights' | undefined,
): QueryState<UserToTransferRightsTo[]> => {
  return useQuery<UserToTransferRightsTo[]>({
    key: ['users, ownership-transfer', type],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `users/ownership-transfer/${type}`,
    },
    isEnabled: type !== undefined,
  });
};
