import * as R from '@dev-spendesk/general-type-helpers/Result';
import { IconButton } from '@dev-spendesk/grapes';
import { useQueryClient } from 'react-query';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCreateOrUpdateAccountPayable } from 'src/core/modules/accounting-integration/apis/useCreateOrUpdateAccountPayable';

import { type SupplierAccount } from '../../types';

type Props = {
  shouldShowArchiveButton: boolean;
  amount: string;
  accountPayable?: SupplierAccount;
};

export const AccountPayableSuppliersAmountCell = ({
  amount,
  shouldShowArchiveButton,
  accountPayable,
}: Props) => {
  const { t } = useTranslation();
  const { pushNotif } = useNotifications();
  const queryClient = useQueryClient();

  const [updateSupplierAccount] =
    useCreateOrUpdateAccountPayable('supplierAccount');

  return shouldShowArchiveButton && accountPayable ? (
    <IconButton
      variant="borderless"
      iconName="trash"
      onClick={async () => {
        const updateSupplierAccountResult = await updateSupplierAccount({
          ...accountPayable,
          isArchived: true,
        });
        if (R.isFailure(updateSupplierAccountResult)) {
          pushNotif({
            type: NotificationType.Danger,
            message: t(
              'bookkeep.accountsPayable.list.archiveAccountPayableFailureNotification',
            ),
          });
          throw new Error(`Couldn't create new account payable`);
        }

        pushNotif({
          type: NotificationType.Success,
          message: t(
            'bookkeep.accountsPayable.list.archiveAccountPayableSuccessNotification',
          ),
        });
        await Promise.all([
          queryClient.invalidateQueries([
            'useAccountPayableSuppliersListQuery',
          ]),
          queryClient.invalidateQueries([
            'useGetSuppliersWithoutAccountPayableListQuery',
          ]),
          queryClient.invalidateQueries(['useIntegrationStatusQuery']),
        ]);
      }}
      aria-label={t('misc.delete')}
    />
  ) : (
    <>{amount}</>
  );
};
