import React, { type PropsWithChildren } from 'react';

import './CellWithIconButton.css';

interface Props {
  isButtonVisible: boolean;
  button: JSX.Element;
}
/**
 * To have the button aligned properly, you need to add the
 * `CellWithButton__button` class on the button element
 *
 * @param props
 */
export const CellWithButton = (props: PropsWithChildren<Props>) => {
  const { button, isButtonVisible, children } = props;

  return (
    <div className="CellWithButton">
      {children}
      {isButtonVisible && button}
    </div>
  );
};
