import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import Modal from 'common/components/legacy/Modal/Modal';
import { useTranslation } from 'common/hooks/useTranslation';
import { LazyLoadedSignature } from 'src/onboarding/components/Signature/LazyLoadedSignature';
import { logger } from 'src/utils/datadog-log-wrapper';

import { AffidavitSignatureLoader } from './AffidavitSignatureLoader';

import './AffidavitSignatureModal.css';

type Props = {
  isLoading: boolean;
  signatureImageUrl: string;
  onBack: () => void;
  onSigned: () => void;
  onFinish: () => void;
};

export const AffidavitSignatureModal = ({
  isLoading,
  signatureImageUrl,
  onBack,
  onSigned,
  onFinish,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      className="AffidavitSignatureModal"
      triggerAction={() => {}}
      hasExitButton={false}
      size="large"
    >
      <div className="AffidavitSignatureModal__signature">
        {isLoading && <AffidavitSignatureLoader />}
        {signatureImageUrl && (
          <LazyLoadedSignature
            signatureURL={signatureImageUrl}
            onSignSuccess={async () => {
              logger.info('DropboxSign signed', {
                href: window.location.href,
                team: 'capture',
                scope: 'affidavit-signature',
              });
              await onSigned();
              onFinish();
            }}
            onSignError={(error) => {
              logger.warn('DropboxSign error', {
                href: window.location.href,
                team: 'capture',
                scope: 'affidavit-signature',
                error,
              });
            }}
            className="MissingInvoiceSignature"
          />
        )}
      </div>
      <div className="AffidavitSignatureModal__footer">
        <Button
          text={t('payments.affidavitModal.signatureModal.backButton')}
          isDisabled={isLoading}
          onClick={onBack}
        />
      </div>
    </Modal>
  );
};
