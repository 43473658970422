// Fetch single payment
export const FETCH_SINGLE_PAYMENT_LOADING = 'FETCH_SINGLE_PAYMENT_LOADING';
export const FETCH_SINGLE_PAYMENT_SUCCESS = 'FETCH_SINGLE_PAYMENT_SUCCESS';
export const FETCH_SINGLE_PAYMENT_FAILURE = 'FETCH_SINGLE_PAYMENT_FAILURE';

// Update single payment
export const UPDATE_SINGLE_PAYMENT_LOCALLY = 'UPDATE_SINGLE_PAYMENT_LOCALLY';

// Payment's invoices
export const FETCH_SINGLE_PAYMENT_INVOICES_LOADING =
  'FETCH_SINGLE_PAYMENT_INVOICES_LOADING';
export const FETCH_SINGLE_PAYMENT_INVOICES_SUCCESS =
  'FETCH_SINGLE_PAYMENT_INVOICES_SUCCESS';
export const FETCH_SINGLE_PAYMENT_TO_EXPORT_INVOICES_SUCCESS =
  'FETCH_SINGLE_PAYMENT_TO_EXPORT_INVOICES_SUCCESS';

// All payments
export const FETCH_ALL_PAYMENTS_LOADING = 'FETCH_ALL_PAYMENTS_LOADING';
export const FETCH_ALL_PAYMENTS_SUCCESS = 'FETCH_ALL_PAYMENTS_SUCCESS';
export const FETCH_ALL_PAYMENTS_FAILURE = 'FETCH_ALL_PAYMENTS_FAILURE';

// Reset
export const HIDE_PAYMENT_PANEL = 'HIDE_PAYMENT_PANEL';
export const RESET_ALL_PAYMENTS = 'RESET_ALL_PAYMENTS';
export const RESET_ALL_PAYMENTS_SELECTION = 'RESET_ALL_PAYMENTS_SELECTION';

// All payments counters for selection
export const FETCH_ALL_PAYMENTS_COUNTERS_LOADING =
  'FETCH_ALL_PAYMENTS_COUNTERS_LOADING';
export const FETCH_ALL_PAYMENTS_COUNTERS_SUCCESS =
  'FETCH_ALL_PAYMENTS_COUNTERS_SUCCESS';
export const FETCH_ALL_PAYMENTS_COUNTERS_FAILURE =
  'FETCH_ALL_PAYMENTS_COUNTERS_FAILURE';

// Filters and selection
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_ALL_PAYMENTS_SELECTION = 'UPDATE_ALL_PAYMENTS_SELECTION';

// Delete invoice
export const DELETE_INVOICE_LOADING = '@payments/DELETE_INVOICE_LOADING';
export const DELETE_INVOICE_SUCCESS = '@payments/DELETE_INVOICE_SUCCESS';

export const INCREMENT_PAYMENT_INVOICES = 'INCREMENT_PAYMENT_INVOICES';

// Bulk actions
export const REMIND_INVOICES_LOADING = 'REMIND_INVOICES_LOADING';
export const REMIND_INVOICES_SUCCESS = 'REMIND_INVOICES_SUCCESS';
export const REMIND_INVOICES_FAILURE = 'REMIND_INVOICES_FAILURE';
export const DOWNLOAD_PAYMENTS_LOADING = 'DOWNLOAD_PAYMENTS_LOADING';
export const DOWNLOAD_PAYMENTS_SUCCESS = 'DOWNLOAD_PAYMENTS_SUCCESS';
export const MARK_PAYMENTS_MISSING_RECEIPT_LOADING =
  'MARK_PAYMENTS_MISSING_RECEIPT_LOADING';
export const MARK_PAYMENTS_MISSING_RECEIPT_FINISH =
  'MARK_PAYMENTS_MISSING_RECEIPT_FINISH';

export const BULK_EDIT_PAYMENTS_LOADING = 'BULK_EDIT_PAYMENTS_LOADING';
export const BULK_EDIT_PAYMENTS_SUCCESS = 'BULK_EDIT_PAYMENTS_SUCCESS';
export const BULK_EDIT_PAYMENTS_FAILURE = 'BULK_EDIT_PAYMENTS_FAILURE';
export const RESET_BULK_EDIT_PAYMENTS = 'RESET_BULK_EDIT_PAYMENTS';

export const SET_PAYMENTS_ALL_OPENED_PAYMENT =
  'SET_PAYMENTS_ALL_OPENED_PAYMENT';

// User incomplete payments
export const FETCH_USER_PAYMENTS_TO_REMIND_SUCCESS =
  'FETCH_USER_PAYMENTS_TO_REMIND_SUCCESS';
export const FETCH_USER_PAYMENTS_TO_REMIND_FAILURE =
  'FETCH_USER_PAYMENTS_TO_REMIND_FAILURE';

// User first payment id
export const SET_FIRST_USER_PAYMENT_ID = 'SET_FIRST_USER_PAYMENT_ID';

export const DELETE_DOCUMENTARY_EVIDENCE_LOADING =
  'DELETE_DOCUMENTARY_EVIDENCE_LOADING';
export const DELETE_DOCUMENTARY_EVIDENCE_SUCCESS =
  'DELETE_DOCUMENTARY_EVIDENCE_SUCCESS';
export const DELETE_DOCUMENTARY_EVIDENCE_FAILURE =
  'DELETE_DOCUMENTARY_EVIDENCE_FAILURE';
