import { ValidatePayablesApi } from '@finance-review/apis/payable';
import { type DraftExpense } from '@finance-review/models/expense';
import { type MutationState } from 'src/core/api/hooks/useMutation';

import { useInvalidateExpenses } from './useExpensesQuery';
import { useFetchLegacyExpenseClaimCounts } from './useFetchLegacyExpenseClaimCounts';

export type Response = ValidatePayablesApi.Response;
export type QueryState = ValidatePayablesApi.QueryState;
export type Payload = DraftExpense[];

export const useMutation = (
  options: {
    onError?(): void;
    onSuccess?(requestId: string): void;
    onComplete?(): void;
  } = {},
): MutationState<Payload, Response, unknown> => {
  const legacyFetchCount = useFetchLegacyExpenseClaimCounts();
  const invalidateExpenses = useInvalidateExpenses();
  const [validatePayableRequestMutation, ...rest] =
    ValidatePayablesApi.useMutation({
      onSuccess: options.onSuccess,
      onComplete: () => {
        legacyFetchCount();
        invalidateExpenses();
        options.onComplete?.();
      },
      onError: options.onError,
    });

  return [
    async (draftExpenses) =>
      validatePayableRequestMutation(
        draftExpenses.map((draftExpense) => ({
          id: draftExpense.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          amount: draftExpense.amountToReimburse!,
          currency: draftExpense.currency,
        })),
      ),
    ...rest,
  ];
};
