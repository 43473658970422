import cx from 'classnames';
import React, { type ReactNode } from 'react';

import './FileViewerToolbar.css';

export enum Modes {
  Compact = 'compact',
  Normal = 'normal',
}

type Props = {
  mode?: Modes;
  leftActions?: ReactNode[];
  rightActions?: ReactNode[];
};

export const FileViewerToolbar = ({
  mode = Modes.Normal,
  leftActions = [],
  rightActions = [],
}: Props) => {
  return (
    <div
      className={cx('FileViewerToolbar', {
        'FileViewerToolbar--compact': mode === Modes.Compact,
      })}
    >
      {leftActions.length > 0 && (
        <div className="FileViewerToolbar__left flex">{leftActions}</div>
      )}
      {rightActions.length > 0 && (
        <div className="FileViewerToolbar__right flex">{rightActions}</div>
      )}
    </div>
  );
};
