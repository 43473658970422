import cx from 'classnames';
import replace from 'lodash/replace';
import truncate from 'lodash/truncate';
import React from 'react';

import { CardLabel } from './CardLabel';
import dove from '../../../../../../public/static/img/card-design/dove.png';

import './SFSCardBackDesign.css';

const CARD_WIDTH = 380;
const CARD_HEIGHT = 240;

type Props = {
  numbers: string;
  name: string;
  width?: number;
  className?: string;
};

export const SFSCardBackDesign = ({
  name,
  numbers,
  className,
  width = CARD_WIDTH,
}: Props) => {
  const height = (width * CARD_HEIGHT) / CARD_WIDTH;

  let numbersWithNoSpaces = replace(
    replace(numbers ?? '', /\s/g, ''),
    /\./g,
    '•',
  );
  if (numbersWithNoSpaces.length < 16) {
    numbersWithNoSpaces = `${numbersWithNoSpaces}${'•'.repeat(
      16 - numbersWithNoSpaces.length,
    )}`;
  }
  // We wrap each number in fixed width spans
  // to make the selected font
  // fixed width even if it isn't
  const numbersWithinSpans = (
    <>
      {[...numbersWithNoSpaces].map((character, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{
            width: '14px',
            maxWidth: '14px',
            marginLeft: `${index > 0 && index % 4 === 0 ? 6 : 0}px`,
          }}
        >
          {character}
        </div>
      ))}
    </>
  );

  return (
    <div
      className={cx(
        'SFSCardBackDesign__card',
        'SFSCardBackDesign__physical',
        'SFSCardBackDesign__activating',
        className,
      )}
      style={{
        width,
        height,
      }}
    >
      <BackSVG width={width} height={height}>
        <CardLabel label="CARD NUMBER" />
        <p
          className="mb-s flex text-white"
          style={{
            lineHeight: '23px',
            fontSize: 26,
          }}
        >
          {numbersWithinSpans}
        </p>
        <div>
          {name && <CardLabel label="CARD HOLDER" />}
          <div
            style={{
              lineHeight: '24px',
              fontSize: 20,
            }}
          >
            {truncate(name?.toUpperCase(), { length: 21, omission: '' })}
          </div>
        </div>
      </BackSVG>
    </div>
  );
};

const BackSVG = ({
  children,
  width,
  height,
}: {
  children: React.ReactNode;
  width: number;
  height: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 398 252"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_209_1637)">
        <path
          d="M397.924 239.722C397.924 246.503 392.427 252 385.646 252C261.371 252 137.096 252 12.8217 252C6.04087 252 0.543945 246.503 0.543945 239.722V12.2778C0.543945 5.497 6.04089 4.59521e-05 12.8217 4.8321e-05C137.096 9.17366e-05 261.371 0.000761633 385.646 7.25692e-05C392.427 3.49714e-05 397.924 5.49699 397.924 12.2779V239.722Z"
          fill="url(#paint0_linear_209_1637)"
        />
        <path d="M398 22.8054H0V61.5746H398V22.8054Z" fill="black" />
        <path
          d="M240.994 134.609C224.538 129.107 207.037 142.363 207.037 156.11C207.037 173.295 224.377 186.331 246.774 186.331C269.172 186.331 284.695 174.776 284.695 159.073C284.695 137.741 257.612 126.482 240.994 124.705"
          stroke="#4FDC7B"
          stroke-width="3.37783"
          stroke-linecap="round"
        />
      </g>
      <foreignObject x="34" y="130" width={width * 0.85} height={height * 0.75}>
        {children}
      </foreignObject>
      <foreignObject x="318.42" y="149.34" width="52.78" height="70.26">
        <img className="w-full object-contain" src={dove} alt="" />
      </foreignObject>
      <defs>
        <linearGradient
          id="paint0_linear_209_1637"
          x1="1.01893"
          y1="248.253"
          x2="387.558"
          y2="-6.17397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5D21D2" />
          <stop offset="1" stop-color="#3D2589" />
        </linearGradient>
        <clipPath id="clip0_209_1637">
          <rect width="398" height="252" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
