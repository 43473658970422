import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from './ModalState';
import { type AnalyticalFieldMappingCreate } from '../../../../../hooks/useCreateAnalyticalFieldMappingMutation';

interface Props {
  modalState: ModalState;
  setModalState: Dispatch<ModalState>;
  onCreate: (
    editedAnalyticalField: AnalyticalFieldMappingCreate & {
      externalName: string;
    },
  ) => void;
}

export const PullAndMapWithValuesConfirmChangesModal = ({
  modalState,
  setModalState,
  onCreate,
}: Props) => {
  const { t } = useTranslation();

  const subtitle =
    modalState.modalKind === 'confirmChanges'
      ? t(
          'bookkeep.integrations.settings.analyticalFieldsMappingsTable.confirmChanges.subtitle',
          {
            ...modalState.existingAnalyticalFiledMap,
          },
        )
      : '';

  return (
    <Modal
      actions={[
        <Button
          key="no"
          onClick={() => setModalState({ modalKind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          text={t(
            'bookkeep.integrations.settings.analyticalFieldsMappingsTable.confirmChanges.confirmChanges',
          )}
          variant="primary"
          onClick={() => {
            if (modalState.modalKind === 'confirmChanges') {
              onCreate(modalState);
            }

            setModalState({ modalKind: 'closed' });
          }}
        />,
      ]}
      onClose={() => {
        setModalState({ modalKind: 'closed' });
      }}
      iconName="warning"
      title={t(
        'bookkeep.integrations.settings.analyticalFieldsMappingsTable.confirmChanges.title',
      )}
      subtitle={subtitle}
      iconVariant="warning"
      isOpen={modalState.modalKind === 'confirmChanges'}
    />
  );
};
