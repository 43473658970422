import { type TGlobalFunctionTyped } from '../hooks/useTranslation';

export const formatList = (
  activeLanguage: string,
  items: string[],
  t: TGlobalFunctionTyped,
) => {
  // Check if Intl.ListFormat is supported
  if (Intl.ListFormat) {
    const formatter = new Intl.ListFormat(activeLanguage, {
      style: 'long',
      type: 'conjunction',
    });
    return formatter.format(items);
  }

  // Fallback method for browsers that do not support Intl.ListFormat
  if (items.length === 0) {
    return ''; // Return empty string if the items array is empty
  }
  return (
    items.slice(0, -1).join(', ') +
    (items.length > 1 ? ` ${t('misc.and')} ` : '') +
    items.at(-1)
  );
};
