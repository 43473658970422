import {
  Button,
  FormField,
  Input,
  Modal,
  TextInput,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { AutoAssignSwitchField } from '../AutoAssignSwitchField';
import { DayDropdown } from '../DayDropdown/DayDropdown';

import './EditControlRuleDetails.css';

type FormValues = {
  name: string;
  limit: number;
  delay: number;
  isDefault: boolean;
};

type Props = {
  isInitialRule?: boolean;
  onCancel(): void;
};

export const EditControlRuleDetails = ({
  isInitialRule = false,
  onCancel,
  // Formik props
  values,
  errors,
  isSubmitting,
  setFieldValue,
  handleSubmit,
}: Props & FormikProps<FormValues>) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      title={t('controlRules.editControlRule', { name: values.name })}
      subtitle={t('controlRules.editDescription')}
      iconName="card-lock"
      iconVariant="primary"
      isOpen
      actions={[
        <Button
          key="cancel"
          text={t('misc.cancel')}
          variant="secondary"
          onClick={onCancel}
        />,
        <Button
          key="continue"
          text={t('misc.continue')}
          variant="primary"
          iconName="caret-right"
          iconPosition="right"
          className="EditControlRuleDetails__actions__submit"
          onClick={() => handleSubmit()}
          isDisabled={isSubmitting}
        />,
      ]}
    >
      <div className="EditControlRuleDetails">
        <div className="EditControlRuleDetails__content">
          <div className="EditControlRuleDetails__content__form">
            <div className="EditControlRuleDetails__content__form__row">
              <FormField
                label={t('controlRulesModal.nameLabel')}
                alertMessage={errors.name}
              >
                <TextInput
                  value={
                    isInitialRule
                      ? t('controlRules.initialRuleName')
                      : values.name
                  }
                  isDisabled={isInitialRule}
                  placeholder={t('controlRulesModal.nameLabel')}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                />
              </FormField>
            </div>
            <div className="EditControlRuleDetails__content__form__row EditControlRuleDetails__content__form__row--double">
              <FormField
                label={t('controlRulesModal.limitLabel')}
                alertMessage={errors.limit}
              >
                <Input
                  type="number"
                  min={1}
                  value={values.limit}
                  onChange={(event) => {
                    const value = event.target.valueAsNumber;
                    if (!Number.isNaN(value)) {
                      setFieldValue('limit', value);
                    } else {
                      setFieldValue('limit', undefined);
                    }
                  }}
                />
              </FormField>
              <FormField
                className="EditControlRuleDetails__content__form__row__day-dropdown"
                label={t('controlRulesModal.delayLabel')}
              >
                <DayDropdown
                  value={values.delay}
                  onSelect={(days) => setFieldValue('delay', days)}
                />
              </FormField>
            </div>

            {isInitialRule ? (
              <div className="EditControlRuleDetails__content__form__row">
                <AutoAssignSwitchField
                  isChecked={values.isDefault}
                  onChange={(event) =>
                    setFieldValue('isDefault', event.target.checked)
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};
