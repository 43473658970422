export type CustomFieldId = string;
export interface CustomFieldDefinition {
  id: string;
  perms_add_values: string[];
  perms_visibility: string[];
  name: string;
  type: 'list' | 'boolean';
  is_scopable: boolean;
  is_all_scopes: boolean;
  is_required: boolean;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  total_values: number;
  eligible_types: { type: string }[];
  scopes: { entity_type: string; entity_id: string }[];
  isExpenseCategory?: boolean; // This is not returned anymore by the back-end
  custom_fields_values: {
    id: string;
    value: string;
    is_default: boolean;
    created_at: string;
  }[];
}

export const isCustomFieldRequired = (
  customField: CustomFieldDefinition,
): boolean => customField.is_required && !customField.deleted_at;
