import React from 'react';

import './LegacySubscriptionItems.css';

type Props = {
  children: React.ReactNode;
};

const LegacySubscriptionItems = ({ children }: Props) => (
  <ul className="LegacySubscriptionItems">{children}</ul>
);

export default LegacySubscriptionItems;
