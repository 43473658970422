import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { SET_SUPPLIER_ACCOUNT_TO_SUPPLIER } from '../queries/setSupplierAccountToSupplier';

type Payload = {
  supplierId: string;
  supplierAccountId: string;
};

type RawResponse = {
  setSupplierAccountToSupplier: {
    reason?: string;
  };
};

type Response = {
  setSupplierAccountToSupplier: {
    reason?: string;
  };
};

export const useSetSupplierAccountToSupplierMutation = (): MutationState<
  Payload,
  Response
> => {
  return useMutation<Payload, Response, RawResponse>({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: SET_SUPPLIER_ACCOUNT_TO_SUPPLIER,
    },
    reshapeData: (data) => {
      return data;
    },
  });
};
