import React from 'react';

import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { PullAndMapWithValuesSection } from './PullAndMapWithValuesSection';
import { StaticMapAndPullAndMapSection } from './StaticMapAndPullAndMapSection';
import {
  type AccountingSoftware,
  type AnalyticalFieldsCapability,
} from '../../../../../../integration/status';
import {
  type AnalyticalFieldMappingCreate,
  type AnalyticalFieldMappingDelete,
  useCreateAnalyticalFieldMappingMutation,
  useDeleteAnalyticalFieldMappingMutation,
} from '../../../../hooks/useCreateAnalyticalFieldMappingMutation';
import {
  type AnalyticalFieldMappingUpdate,
  useUpdateAnalyticalFieldMappingMutation,
} from '../../../../hooks/useUpdateAnalyticalFieldMappingMutation';

interface Props {
  integration: AccountingSoftware;
  analyticalFieldsCapability: AnalyticalFieldsCapability;
}

export const AnalyticalFieldsSection = ({
  integration,
  analyticalFieldsCapability,
}: Props) => {
  const [updateAnalyticalFieldMapping] =
    useUpdateAnalyticalFieldMappingMutation();
  const [deleteAnalyticalFieldMapping] =
    useDeleteAnalyticalFieldMappingMutation();
  const [createAnalyticalFieldMapping] =
    useCreateAnalyticalFieldMappingMutation();

  async function handleCreateAnalyticalFieldMapping(
    createdAnalyticalField: AnalyticalFieldMappingCreate & {
      externalName: string;
    },
  ) {
    if (createdAnalyticalField.codeType === 'costCenter') {
      await updateAnalyticalFieldMapping([
        {
          kind: 'mapped',
          codeType: 'costCenter',
          externalId: createdAnalyticalField.externalId,
        },
      ]);
      return;
    }

    await createAnalyticalFieldMapping([
      {
        kind: 'mapped',
        codeType: 'customField',
        externalId: createdAnalyticalField.externalId,
        externalName: createdAnalyticalField.externalName,
        internalName: createdAnalyticalField.internalName,
      },
    ]);
  }

  async function handleDeleteAnalyticalFieldMapping(
    deletedAnalyticalField: AnalyticalFieldMappingDelete & {
      externalName: string;
    },
  ) {
    const deletion: AnalyticalFieldMappingDelete = {
      kind: 'notMapped',
      externalId: deletedAnalyticalField.externalId,
    };
    await deleteAnalyticalFieldMapping([deletion]);
  }

  async function handleUpdateAnalyticalFieldMapping(
    editedAnalyticalField: AnalyticalFieldMappingUpdate & {
      externalName: string;
    },
  ) {
    if (editedAnalyticalField.kind === 'notMapped') {
      await updateAnalyticalFieldMapping([
        {
          kind: 'notMapped',
          externalId: editedAnalyticalField.externalId,
        },
      ]);
    } else if (editedAnalyticalField.kind === 'mapped') {
      const update: AnalyticalFieldMappingUpdate =
        editedAnalyticalField.codeType === 'costCenter'
          ? {
              kind: 'mapped',
              codeType: 'costCenter',
              externalId: editedAnalyticalField.externalId,
              sendOnLine: editedAnalyticalField.sendOnLine,
              sendOnHeader: editedAnalyticalField.sendOnHeader,
            }
          : {
              kind: 'mapped',
              codeType: 'customField',
              externalId: editedAnalyticalField.externalId,
              internalName: editedAnalyticalField.internalName,
              internalId: editedAnalyticalField.internalId,
              sendOnLine: editedAnalyticalField.sendOnLine,
              sendOnHeader: editedAnalyticalField.sendOnHeader,
            };

      await updateAnalyticalFieldMapping([update]);
    }
  }

  switch (analyticalFieldsCapability) {
    case 'staticMap':
    case 'pullAndMapWithStaticFields':
    case 'pullAndMapWithStaticFieldsAndValues':
    case 'pullAndMap': {
      return (
        <StaticMapAndPullAndMapSection
          integration={integration}
          onUpdate={handleUpdateAnalyticalFieldMapping}
        />
      );
    }
    case 'pullAndMapWithValues': {
      return (
        <PullAndMapWithValuesSection
          integration={integration}
          onCreate={handleCreateAnalyticalFieldMapping}
          onUpdate={handleUpdateAnalyticalFieldMapping}
          onDelete={handleDeleteAnalyticalFieldMapping}
        />
      );
    }
    default:
      rejectUnexpectedValue(
        'customFieldsCapability',
        analyticalFieldsCapability,
      );
  }
};
