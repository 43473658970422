import { Button, Callout } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const OrganisationReportingEntityListErrorCallout = ({
  entityIdsWithFailure,
  total,
}: {
  entityIdsWithFailure: string[];
  total: number;
}) => {
  const { t } = useTranslation('global');
  const queryClient = useQueryClient();

  const [isRefreshingFailedEntities, setIsRefreshingFailedEntities] =
    useState(false);

  if (entityIdsWithFailure.length === 0) {
    return null;
  }

  const refreshFailedEntities = async () => {
    setIsRefreshingFailedEntities(true);

    try {
      await Promise.all(
        entityIdsWithFailure.map((entityId) =>
          queryClient.invalidateQueries(['organisation-reporting', entityId]),
        ),
      );
    } finally {
      setIsRefreshingFailedEntities(false);
    }
  };

  return (
    <Callout
      variant="alert"
      title={t('organisation.reporting.page.entities.dataFailureCallout.title')}
      iconName="failure"
    >
      <div className="flex flex-col items-start gap-s">
        <p className="text-complementary body-m">
          {t(
            'organisation.reporting.page.entities.dataFailureCallout.description',
            {
              count: entityIdsWithFailure.length,
              total,
            },
          )}
        </p>
        <Button
          variant="alert"
          isDisabled={isRefreshingFailedEntities}
          text={t(
            'organisation.reporting.page.entities.dataFailureCallout.cta',
          )}
          className="w-[157px]"
          iconName="rotate"
          iconPosition="left"
          onClick={refreshFailedEntities}
        />
      </div>
    </Callout>
  );
};
