import { Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

export const CostCenterImportModalLoader = () => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen
      iconName="sync"
      iconVariant="primary"
      title={t('costCenters.importModal.loader.title')}
      subtitle={t('costCenters.importModal.loader.subtitle')}
      actions={[]}
    />
  );
};
