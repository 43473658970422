import { useCardsAccessQuery } from './useCardsAccessQuery';
import { useCheckOverduePaymentsQuery } from './useCheckOverduePaymentsQuery';
import { useCheckPaymentsToCompleteQuery } from './useCheckPaymentsToCompleteQuery';
import { type TitleState } from '../header';

export const useTitleState = ({
  displayMode,
  userPaymentCount,
}: {
  displayMode: 'requester' | 'controller' | undefined;
  userPaymentCount: number;
}): TitleState => {
  const cardsAccessQueryState = useCardsAccessQuery();
  const checkOverdueQueryState = useCheckOverduePaymentsQuery();
  const checkPaymentsToCompleteState = useCheckPaymentsToCompleteQuery();

  // display title for controller
  if (displayMode === 'controller') {
    return 'controller';
  }

  // if user has never made payment yet
  if (userPaymentCount === 0) {
    return 'requesterOnboarding';
  }

  // access is restricted
  if (
    cardsAccessQueryState.status === 'success' &&
    !cardsAccessQueryState.data.hasAccess
  ) {
    return cardsAccessQueryState.data.initiator === 'controlRuleProcess'
      ? 'requesterRestricted'
      : 'requesterRestrictedByAdmin';
  }

  // has overdue payments payments
  if (
    checkOverdueQueryState.status === 'success' &&
    checkOverdueQueryState.data.hasOverduePayments
  ) {
    return 'requesterPaymentsOverdue';
  }

  // if user has some payments to deal with
  if (
    checkPaymentsToCompleteState.status === 'success' &&
    checkPaymentsToCompleteState.data.hasPaymentsToComplete
  ) {
    return 'requesterPaymentsToComplete';
  }

  // user played by rules and have no incomplete payments
  return 'requester';
};
