import {
  Button,
  DropdownItem,
  FormField,
  TextInput,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';
import { Trans } from 'react-i18next';

import {
  type BankAccountDetailsFile,
  type CreateSupplierFormValues,
  type SupplierAccount,
} from 'modules/bookkeep/accounts-payable/types';
import { SupplierAccountAdvice } from 'modules/bookkeep/components/AccountAdvice';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { CodeInUseCallout } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/components/CodeInUseCallout/CodeInUseCallout';
import { srcFromFile } from 'src/core/utils/files';

import { getCodeWithAuxiliaryAccounts } from '../../../../../../utils/accountPayable';
import { useHasAuxiliaryAccountsEnabled } from '../../../../hooks/useHasAuxiliaryAccountsEnabled';
import { AccountPayableSuppliersFile } from '../../../components/AccountPayableSupplierFile';
import { AccountPayableSuppliersBankFields } from '../../../components/AccountPayableSuppliersBankFields';

import './AccountPayableSuppliersCreateSupplierForm.css';

type Props = {
  bankAccountDetailsFile: BankAccountDetailsFile;
  suppliers: Set<string>;
  accountPayables: SupplierAccount[];
  mustDisplayAccountsPayable: boolean;
  onAddOption(newOptionLabel: string): Promise<{
    key: string;
    label: string;
  }>;
  onCancel: () => void;
  setBankAccountDetailsFile: (
    bankAccountDetailsFile: BankAccountDetailsFile,
  ) => void;
  companyId: string;
  // TODO@integrations understand why we need DATEV-specific behaviour here
  isDatev: boolean;
  failedAccount: string | undefined;
  integrationStatus: IntegrationStatusWithIntegration;
} & FormikProps<CreateSupplierFormValues>;

export const AccountPayableSuppliersCreateSupplierForm = ({
  bankAccountDetailsFile,
  suppliers,
  accountPayables,
  mustDisplayAccountsPayable,
  onAddOption,
  onCancel,
  setBankAccountDetailsFile,
  companyId,
  isDatev,
  failedAccount,
  // Formik props
  errors,
  values,
  handleChange,
  setErrors,
  setFieldError,
  setFieldValue,
  submitForm,
  integrationStatus,
}: Props) => {
  const { t } = useTranslation();
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();

  const reshapedAccountPayables = accountPayables.map((accountPayable) => ({
    key: accountPayable.id,
    label: auxiliaryAccountsEnabled
      ? getCodeWithAuxiliaryAccounts(accountPayable)
      : accountPayable.generalAccountCode,
  }));
  const selectedAccountPayable = reshapedAccountPayables.find(
    (accountPayable) => accountPayable.key === values.accountPayableId,
  );

  const getFileUrl = () => {
    if (bankAccountDetailsFile !== null) {
      if (bankAccountDetailsFile.action === 'delete') {
        return null;
      }
      if (bankAccountDetailsFile.action === 'upload') {
        return bankAccountDetailsFile.fileUrl;
      }
    }
    return null;
  };

  const getFileMediaType = () => {
    if (bankAccountDetailsFile !== null) {
      if (bankAccountDetailsFile.action === 'delete') {
        return null;
      }
      if (bankAccountDetailsFile.action === 'upload') {
        return bankAccountDetailsFile.fileMediaType;
      }
    }
    return null;
  };

  const handleUploadBankAccountDetailsFile = (file: File) => {
    if (!file) {
      return;
    }
    setBankAccountDetailsFile({
      file,
      fileUrl: srcFromFile(file) ?? '',
      fileMediaType: file.type,
      action: 'upload',
    });
  };

  const handleDeleteBankAccountDetailsFile = () => {
    setBankAccountDetailsFile({
      action: 'delete',
    });
  };

  return (
    <form className="AccountPayableSuppliersCreateSupplierForm">
      <FormField
        className="AccountPayableSuppliersCreateSupplierForm__field"
        label={t('bookkeep.accountsPayable.createSupplier.nameLabel')}
        alertMessage={errors.name ? t(errors.name) : undefined}
      >
        <TextInput
          name="name"
          value={values.name}
          placeholder={t(
            'bookkeep.accountsPayable.createSupplier.namePlaceholder',
          )}
          onChange={(event) => {
            const { value } = event.target;
            const supplierName = value.trim().toLowerCase();
            const existingSupplierError = suppliers.has(supplierName)
              ? 'bookkeep.accountsPayable.createSupplier.nameExistsError'
              : '';
            setFieldError('name', existingSupplierError);
            handleChange(event);
            setFieldValue('accountHolderName', value);
          }}
        />
      </FormField>
      <div className="AccountPayableSuppliersCreateSupplierForm__fieldWrapper">
        <FormField
          className="AccountPayableSuppliersCreateSupplierForm__field"
          label={t('bookkeep.accountsPayable.createSupplier.legalNameLabel')}
          alertMessage={errors.legalName ? t(errors.legalName) : undefined}
        >
          <TextInput
            name="legalName"
            value={values.legalName}
            placeholder={t(
              'bookkeep.accountsPayable.createSupplier.legalNamePlaceholder',
            )}
            onChange={handleChange}
          />
        </FormField>

        {mustDisplayAccountsPayable && (
          <FormField
            className="AccountPayableSuppliersCreateSupplierForm__field"
            label={t(
              'bookkeep.accountsPayable.createSupplier.accountPayableLabel',
            )}
            infoTipContent={t(
              'bookkeep.accountsPayable.createSupplier.accountPayableInformationTooltip',
            )}
            hint={t('bookkeep.accountsPayable.panel.optionalField')}
          >
            <AutocompleteSearch
              name="accountPayableId"
              fit="parent"
              placeholder={t(
                'bookkeep.accountsPayable.createSupplier.accountPayablePlaceholder',
              )}
              options={reshapedAccountPayables}
              value={selectedAccountPayable}
              onSelect={(selectedOption) => {
                if (selectedOption?.key) {
                  setErrors({});
                }
                setFieldValue('accountPayableId', selectedOption?.key);
              }}
              onInputChanged={(value) => {
                if (value && errors.accountPayableId) {
                  setErrors({});
                }
              }}
              onAddOption={onAddOption}
              renderAddOption={(rawValue) => (
                <DropdownItem
                  label={
                    <Trans
                      i18nKey="bookkeep.accountsPayable.createSupplier.addOption"
                      values={{ account: rawValue }}
                      components={[
                        <span
                          key="value"
                          className="AccountPayableSuppliersCreateSupplierForm__create-value"
                        />,
                      ]}
                    />
                  }
                />
              )}
            />
          </FormField>
        )}
      </div>
      {errors.accountPayableId !== 'codeAlreadyExists' && (
        <SupplierAccountAdvice
          className="-mt-xs"
          integrationStatus={integrationStatus}
          showError={errors.accountPayableId === 'integrationValidationFailed'}
        />
      )}
      {isDatev &&
        errors.accountPayableId &&
        errors.accountPayableId === 'codeAlreadyExists' && (
          <CodeInUseCallout
            accountInfo={`${t(
              'bookkeep.integrations.settings.supplierAccountsTable.employeeAccount',
            )} - ${failedAccount}`}
            className="-mt-xs"
            linkTo={routeFor(routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path, {
              company: companyId,
            })}
          />
        )}
      <AccountPayableSuppliersBankFields
        errors={errors}
        values={values}
        setFieldValue={setFieldValue}
      />
      <AccountPayableSuppliersFile
        isEditing
        previewName={t('bookkeep.accountsPayable.panel.bankProofSection.title')}
        fileUrl={getFileUrl()}
        fileMediaType={getFileMediaType()}
        withLabel
        onUpload={(files: File[]) =>
          handleUploadBankAccountDetailsFile(files[0])
        }
        onDelete={handleDeleteBankAccountDetailsFile}
      />
      <div className="AccountPayableSuppliersCreateSupplierForm__actions">
        <Button
          variant="secondary"
          text={t('bookkeep.accountsPayable.panel.cancel')}
          onClick={onCancel}
        />
        <Button
          variant="primary"
          text={t('bookkeep.accountsPayable.panel.saveChanges')}
          onClick={async () => {
            await submitForm();
          }}
        />
      </div>
    </form>
  );
};
