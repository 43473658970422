import { Button, ListBox } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useMemo, useEffect, useState, type ReactNode } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import type { Payable } from 'modules/payable/models';

import styles from './PayableList.module.css';
import { PayableListEmptyState } from './PayableListEmptyState';
import { PayableListItem } from './PayableListItem';
import { formatRow } from './formatter';

export type PayableListProps = {
  payables: Payable[];
  totalPayables: number;
  hasNextPage?: boolean;
  activePayableId: string | undefined;
  onFetchNextPage?(): void;
  onClick(payableId: string): void;
  renderActions?: (params: {
    numberOfSelectedRows: number;
    areAllRowsSelected: boolean;
    unselectedRows: string[];
    selectedRows: string[];
  }) => ReactNode;
};

export const PayableList = ({
  payables,
  totalPayables,
  hasNextPage = false,
  activePayableId,
  onFetchNextPage,
  onClick,
  renderActions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: PayableListProps) => {
  const { t, activeLanguage } = useTranslation('global');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [areAllRowsSelected, setAreAllRowsSelected] = useState(false);
  const [unselectedRows, setUnselectedRows] = useState<string[]>([]);

  const data = useMemo(
    () =>
      payables
        .map((payable) => formatRow(payable, t, activeLanguage))
        .filter(isDefined),
    [payables],
  );

  type Option = (typeof data)[number];

  useEffect(() => {
    const unselectedIds = payables
      .filter((payable) => !selectedRows.includes(payable.id))
      .map((payable) => payable.id);
    setUnselectedRows(unselectedIds);
    if (unselectedIds.length) {
      setAreAllRowsSelected(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (areAllRowsSelected) {
      const filteredPayables = payables.filter(
        (payable) => !unselectedRows.includes(payable.id),
      );
      setSelectedRows(payablesToRowSelection(filteredPayables));
    }
  }, [payables]);

  return data.length > 0 ? (
    <ListBox<Option>
      header={
        <PayableListHeader
          totalPayables={totalPayables}
          areAllRowsSelected={areAllRowsSelected}
          unselectedRows={unselectedRows}
          selectedRows={selectedRows}
          renderActions={renderActions}
        />
      }
      footer={
        hasNextPage && (
          <div className="mx-auto my-s">
            <Button
              variant="secondary"
              text={t('payables.loadMore')}
              onClick={onFetchNextPage}
            />
          </div>
        )
      }
      className={styles.listbox}
      options={data}
      getOptionId={({ id }) => id}
      getIsOptionActive={({ id }) => id === activePayableId}
      onOptionClick={({ id }) => onClick(id)}
      {...(renderActions
        ? {
            checkedOptionIds: selectedRows,
            onAllOptionsChange: (_, ids, isChecked) => {
              setAreAllRowsSelected(isChecked);
              return isChecked ? setSelectedRows(ids) : setSelectedRows([]);
            },
            onOptionChange: (_, id, isChecked) => {
              setSelectedRows((state) =>
                isChecked
                  ? state.concat(id)
                  : state.filter((purchaseOrderId) => purchaseOrderId !== id),
              );
            },
          }
        : {
            checkedOptionIds: undefined,
            onAllOptionsChange: undefined,
            onOptionChange: undefined,
          })}
    >
      {(row) => <PayableListItem {...row} />}
    </ListBox>
  ) : (
    <PayableListEmptyState />
  );
};

/**
 * Helpers
 */

function isDefined<T>(input: T | undefined): input is T {
  return input !== undefined;
}

function payablesToRowSelection(payables: Payable[]): string[] {
  return payables.map(({ id }) => id);
}

/**
 * Children components
 */

export const PayableListTitle = ({
  className = '',
  title,
  leftAddon,
}: {
  className?: string;
  leftAddon?: ReactNode;
  title: ReactNode;
}) => {
  return (
    <div className={classNames('mb-xs flex items-center gap-xs', className)}>
      {leftAddon}
      <h2 className="text-complementary title-l">{title}</h2>
    </div>
  );
};

const PayableListHeader = ({
  totalPayables,
  areAllRowsSelected,
  unselectedRows,
  selectedRows,
  renderActions,
}: {
  totalPayables: number;
  areAllRowsSelected: boolean;
  unselectedRows: string[];
  selectedRows: string[];
  renderActions?: PayableListProps['renderActions'];
}) => {
  const { t } = useTranslation('global');

  const numberOfSelectedRows = areAllRowsSelected
    ? totalPayables - unselectedRows.length
    : selectedRows.length;

  if (!renderActions) {
    return <div />;
  }

  if (numberOfSelectedRows === 0) {
    return <div className="h-[52px]" />;
  }

  return (
    <div
      className="flex items-center justify-between py-xs"
      data-testid="payable-list-actions"
    >
      <div className="text-neutral-dark">
        {t('payables.export.payablesSelected', {
          count: numberOfSelectedRows,
        })}
      </div>

      <div className="flex items-center gap-xs">
        {renderActions({
          numberOfSelectedRows,
          areAllRowsSelected,
          unselectedRows,
          selectedRows,
        })}
      </div>
    </div>
  );
};
