import { NavigationItem, SideNavigation } from '@dev-spendesk/grapes';
import {
  Redirect,
  Switch,
  useLocation,
  Route,
  type NavLinkProps,
  NavLink,
} from 'react-router-dom';

import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { ApiAccessManagementSection } from './api-access-management';
import ApiOAuth2AuthorizeModal from './api-oauth2-authorize/ApiOAuth2AuthorizeModal';
import withErrorBoundary from '../../../../common/components/withErrorBoundary';
import { useFeature } from '../../../../common/hooks/useFeature';
import { useTranslation } from '../../../../common/hooks/useTranslation';
import FEATURES from '../../../../constants/features';
import { routeFor, routes } from '../../../../constants/routes';
import IntegrationsList from '../containers/IntegrationsList';

const IntegrationsPage = ({ key }: { key: string }) => {
  const { t } = useTranslation('global');
  const location = useLocation();
  const companyId = useCompanyId();

  const hasApiKeysPageFeature = useFeature(FEATURES.API_KEYS_PAGE);
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  const allIntegrationsRoute = routeFor(routes.COMPANY_INTEGRATIONS_ALL.path, {
    company: companyId,
  });
  const apiAccessManagementRoute = routeFor(
    routes.COMPANY_INTEGRATIONS_API_ACCESS_MANAGEMENT.path,
    {
      company: companyId,
    },
  );

  return (
    <div key={key} className="page__container bg-page-background">
      <div className="relative flex flex-row items-start justify-center gap-m p-3xl pb-l">
        {!hasNewNavigation && (
          <SideNavigation className="sticky top-3xl w-[200px]">
            <NavigationItem
              component={NavigationItemComponent}
              isActive={location.pathname === allIntegrationsRoute}
              to={allIntegrationsRoute}
              text={t('integration.sideNav.all')}
            />
            {hasApiKeysPageFeature && (
              <NavigationItem
                component={NavigationItemComponent}
                isActive={location.pathname.includes(apiAccessManagementRoute)}
                to={apiAccessManagementRoute}
                text={t('integration.sideNav.apiAccessManagement')}
              />
            )}
          </SideNavigation>
        )}
        <div className="max-w-[1100px] grow">
          <Switch>
            <Redirect
              exact
              from={routes.COMPANY_INTEGRATIONS.path}
              to={routes.COMPANY_INTEGRATIONS_ALL.path}
            />
            <Route path={routes.COMPANY_INTEGRATIONS_ALL.path}>
              <IntegrationsList />
              <ApiOAuth2AuthorizeModal />
            </Route>
            {hasApiKeysPageFeature && (
              <Route
                path={routes.COMPANY_INTEGRATIONS_API_ACCESS_MANAGEMENT.path}
              >
                <ApiAccessManagementSection />
              </Route>
            )}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary({
  scope: 'integrations-page',
  team: 'api-integration',
})(IntegrationsPage);

type NavigationItemProps = NavLinkProps & { hasConfigError: boolean };

const NavigationItemComponent = ({
  children: label,
  ...navLinkProps
}: NavigationItemProps) => {
  return <NavLink {...navLinkProps}>{label}</NavLink>;
};
