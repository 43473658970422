// THIS IS A MOCK FILE, NOT A REAL IMPLEMENTATION

export type CostCenter = {
  id: string;
  name: string;
  ownerId: string;
  viewerIds: string[];
};

type Response = {
  status: 'success';
  data: CostCenter;
};

export const useCostCenterQuery = (id: string): Response => {
  return {
    status: 'success',
    data: {
      id,
      name: 'Test cost center',
      ownerId: 'aolDWJcOqtC39l',
      viewerIds: [],
    },
  };
};
