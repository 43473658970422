import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { CompanyPlanSubscription } from './CompanyPlanSubscription';
import { type ChargebeeSubscription } from '../../../../models';
import { useGetCompanyMetrics } from '../../hooks/useGetCompanyMetrics';

import './CompanyPlanSubscription.css';

type Props = {
  companySubscription: ChargebeeSubscription;
};

export const CompanyPlanSubscriptionContainer = ({
  companySubscription,
}: Props) => {
  const { t } = useTranslation();
  const companyMetricsQuery = useGetCompanyMetrics();

  if (companySubscription.parentCompanyName) {
    return (
      <Callout
        iconName="wallet"
        variant="primary"
        title={t('billing.centralized', {
          responsibleCompanyName: companySubscription.parentCompanyName,
        })}
      />
    );
  }

  if (companyMetricsQuery.status !== 'success') {
    return null;
  }

  return (
    <CompanyPlanSubscription
      companySubscription={companySubscription}
      numberOfTransactions={companyMetricsQuery.data.numberOfTransactions}
    />
  );
};
