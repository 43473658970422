import { PaymentMethod } from 'modules/company';

export const paymentMethodsSortedByPriority = [
  PaymentMethod.WireTransfer,
  PaymentMethod.XmlSepa,
  PaymentMethod.Csv,
];
export const defaultPaymentMethod = PaymentMethod.Csv;

export { PaymentMethod } from 'modules/company';
