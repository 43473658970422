import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type CardsAccessBlockingReason =
  | { initiator: 'user'; blockerId: string }
  | { initiator: 'controlRuleProcess' };

export type CardsAccess =
  | { hasAccess: true }
  | ({ hasAccess: false } & CardsAccessBlockingReason);

export type CardsAccessRaw = { accesses: CardsAccess[] };

export const useCardsAccessQuery = (
  memberId: string | undefined,
): QueryState<CardsAccess> => {
  const params = { userId: memberId };

  return useQuery<CardsAccess, CardsAccessRaw>({
    key: ['cards', 'access', params],
    isEnabled: Boolean(memberId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/cards/access',
      params,
    },
    reshapeData: (data) => data.accesses[0],
  });
};
