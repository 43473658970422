const DEFAULT_DATEV_EXPENSE_ACCOUNT_LENGTH = 1;
const DEFAULT_DATEV_ACCOUNTS_PAYABLE_LENGTH = 1;

type AccountingHeaders = {
  fieldName: string;
  fieldValue: unknown;
};

type ExpenseAccountHeader = {
  expenseAccountLength: number;
};
type AccountsPayableHeader = {
  accountsPayableLength: number;
};
type ParsedAccountingHeaders = ExpenseAccountHeader & AccountsPayableHeader;

export const getAccountingCodesLength = (
  headers: AccountingHeaders[] | undefined,
): ParsedAccountingHeaders => {
  const fieldValue = headers?.find(
    ({ fieldName }) => fieldName === 'accountLength',
  )?.fieldValue;
  const expenseAccount = (fieldValue ?? {}) as ExpenseAccountHeader;
  const accountsPayable = (fieldValue ?? {}) as AccountsPayableHeader;
  return {
    ...(expenseAccount?.expenseAccountLength
      ? {
          expenseAccountLength: expenseAccount?.expenseAccountLength,
        }
      : {
          expenseAccountLength: DEFAULT_DATEV_EXPENSE_ACCOUNT_LENGTH,
        }),
    ...(accountsPayable?.accountsPayableLength
      ? {
          accountsPayableLength: accountsPayable?.accountsPayableLength,
        }
      : {
          accountsPayableLength: DEFAULT_DATEV_ACCOUNTS_PAYABLE_LENGTH,
        }),
  };
};
