import { useFormik, type FormikErrors } from 'formik';
import React from 'react';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { isIbanValid, isBicSwiftValid } from "src/core/utils/bankInfoFormats";

import { BankInformationForm } from './BankInformationForm';
import { type BankInformationFormValues } from './form';
import { type BankInformation } from '../../../../models';
import { useUpdateBankInformationMutation } from '../../hooks/useUpdateBankInformationMutation';

type Props = {
  bankInformation?: BankInformation;
};

export const BankInformationFormContainer = ({ bankInformation }: Props) => {
  const { t } = useTranslation();
  const { pushNotif } = useNotifications();
  const [updateBankInformation] = useUpdateBankInformationMutation();

  const formikProps = useFormik<BankInformationFormValues>({
    initialValues: {
      bankCountry: bankInformation?.bankCountry ?? '',
      iban: bankInformation?.iban ?? '',
      bic: bankInformation?.bic ?? '',
      cuc: bankInformation?.cuc ?? '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<BankInformationFormValues> = {};
      if (!isIbanValid(values.iban)) {
        errors.iban = 'generalSettings.bankInformation.ibanError';
      }
      if (!isBicSwiftValid(values.bic)) {
        errors.bic = 'generalSettings.bankInformation.bicError';
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await updateBankInformation({
          bank_country: values.bankCountry,
          iban: values.iban,
          bic: values.bic,
          cuc: values.cuc !== '' ? values.cuc : null,
        });
      } catch {
        pushNotif({
          type: NotificationType.Danger,
          message: t('generalSettings.bankInformation.errorSaveBankInfo'),
        });
        return;
      }
      pushNotif({
        type: NotificationType.Success,
        message: t('generalSettings.bankInformation.successSaveBankInfo'),
      });
    },
  });

  return <BankInformationForm {...formikProps} />;
};
