import { useQueryStates } from 'src/core/api/hooks';
import { type QueryState } from 'src/core/api/queryState';

import { useOrganisationEntityLateReceiptsQuery } from './useOrganisationEntityLateReceiptsQuery';
import { useOrganisationEntityPayablesInfoQuery } from './useOrganisationEntityPayablesInfoQuery';
import { useOrganisationEntityRequestStatsQuery } from './useOrganisationEntityRequestStatsQuery';
import { useOrganisationEntityTransferSchedulingQuery } from './useOrganisationEntityTransferSchedulingQuery';
import {
  type OrganisationReportingStatistics,
  type OrganisationReportingEntity,
} from '../../types';
import { type OrganisationFeatures } from '../useOrganisationFeatures';

export const useOrganisationReportingEntityStatistics = (
  entityBasicData: OrganisationReportingEntity,
  features: OrganisationFeatures,
): QueryState<OrganisationReportingStatistics> => {
  const { id: companyId } = entityBasicData;

  return useQueryStates({
    states: {
      requestStats: useOrganisationEntityRequestStatsQuery(companyId, {
        isEnabled: features.requestsToApprove,
      }),
      transferScheduling: useOrganisationEntityTransferSchedulingQuery(
        companyId,
        {
          isEnabled: features.invoicesToPay,
        },
      ),
      receipts: useOrganisationEntityLateReceiptsQuery(companyId, {
        isEnabled: features.lateReceipts,
      }),
      payablesInfo: useOrganisationEntityPayablesInfoQuery(companyId, {
        isEnabled: features.payablesToReview,
      }),
    },
    reshapeData: ({
      requestStats,
      transferScheduling,
      receipts,
      payablesInfo,
    }) =>
      ({
        requestsToApprove: requestStats.requestsToApprove,
        invoicesToPay: transferScheduling.invoicesToPay,
        lateReceipts: receipts.lateReceipts,
        payablesToReview: payablesInfo.payablesToReview,
      }) satisfies OrganisationReportingStatistics,
  });
};
