import { type ZodError } from 'zod';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useRefreshPayableBuckets } from 'modules/bookkeep/apis/prepare-payable/useRefreshPayableBuckets';
import { type PayableId } from 'modules/payable';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';
import {
  type Translations,
  useQueryError,
} from 'src/core/common/components/QueryError';

export type MarkDocumentaryEvidenceAsCannotBeProvidedError =
  | {
      reason: 'invalidBodyParams';
      error: ZodError;
    }
  | {
      reason:
        | 'unauthorized'
        | 'bookkeepingApiNotFound'
        | 'notFound'
        | 'wrongVersion'
        | 'updateInProgress'
        | 'invalid';
    };

export type MarkDocumentaryEvidenceAsCannotBeProvidedResponse = {
  reminderSent: true;
};

export type MarkDocumentaryEvidenceAsCannotBeProvidedPayload = {
  payableId: PayableId;
  reason: string;
};

/**
 * Mutation
 **/

export const useMarkDocumentaryEvidenceAsCannotBeProvided = () => {
  const companyId = useCompanyId();
  const refreshPayableBuckets = useRefreshPayableBuckets();

  return useMutation<
    MarkDocumentaryEvidenceAsCannotBeProvidedPayload,
    MarkDocumentaryEvidenceAsCannotBeProvidedResponse,
    MarkDocumentaryEvidenceAsCannotBeProvidedResponse,
    MarkDocumentaryEvidenceAsCannotBeProvidedError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/documentary-evidence/cannot-be-provided',
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client, payload }) => {
        client.invalidateQueries([
          'useGetPayableQuery',
          companyId,
          payload.payableId,
        ]);
        refreshPayableBuckets();
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};

/**
 * Error messages
 **/

export const markDocumentaryEvidenceAsCannotBeProvidedErrorTranslations: Translations<MarkDocumentaryEvidenceAsCannotBeProvidedError> =
  {
    requestError: ({ reason }) => {
      switch (reason) {
        case 'updateInProgress':
          return 'documentaryEvidence.api.markDocumentaryEvidenceAsCannotBeProvided.updateInProgress';
        default:
          return 'documentaryEvidence.api.markDocumentaryEvidenceAsCannotBeProvided.error';
      }
    },
    serverError:
      'documentaryEvidence.api.markDocumentaryEvidenceAsCannotBeProvided.error',
  };

export const useMarkDocumentaryEvidenceAsCannotBeProvidedErrorMessage = () => {
  const getErrorMessage =
    useQueryError<MarkDocumentaryEvidenceAsCannotBeProvidedError>(
      markDocumentaryEvidenceAsCannotBeProvidedErrorTranslations,
    );

  return (
    queryError: QueryError<MarkDocumentaryEvidenceAsCannotBeProvidedError>,
    translationParams?: Record<string, unknown>,
  ): string => getErrorMessage(queryError, translationParams);
};
