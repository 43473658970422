import type {
  CreditNoteId,
  CreditNote,
} from '@finance-review/models/credit-note';
import { useQueryError, type Translations } from 'common/components/QueryError';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import type { QueryError } from 'src/core/api/queryError';

import { useInvalidateCreditAvailableQuery } from './useCreditAvailableQuery';

export type MarkCreditNotesAsRefundedOutsideSpendeskError = {
  outcome: 'notRefunded';
} & (
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason: 'nothingToRefund' | 'invalidBodyParams';
    }
  | {
      reason:
        | 'invalidValidationAction'
        | 'inconsistentCurrency'
        | 'creditNoteNotFound';
      creditNoteId: CreditNoteId;
    }
);

export type MarkCreditNotesAsRefundedOutsideSpendeskPayload = {
  creditNoteIds: CreditNoteId[];
};

export type MarkCreditNotesAsRefundedOutsideSpendeskResponse = {
  outcome: 'refunded';
  refundedCreditNoteIds: CreditNoteId[];
};

export const useMarkCreditNotesAsRefundedOutsideSpendesk = (): MutationState<
  MarkCreditNotesAsRefundedOutsideSpendeskPayload,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  MarkCreditNotesAsRefundedOutsideSpendeskError
> => {
  const invalidateCreditAvailableQuery = useInvalidateCreditAvailableQuery();

  return useMutation<
    MarkCreditNotesAsRefundedOutsideSpendeskPayload,
    MarkCreditNotesAsRefundedOutsideSpendeskResponse,
    MarkCreditNotesAsRefundedOutsideSpendeskResponse,
    MarkCreditNotesAsRefundedOutsideSpendeskError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/credit_note_requests/refund_outside_spendesk`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data }) => {
        if (data.outcome === 'refunded') {
          invalidateCreditAvailableQuery();
        }
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};

type TranslationParams = {
  creditNoteNumber: CreditNote['creditNoteNumber'];
};

export const markCreditNoteAsRefundedOutsideSpendeskErrorTranslations: Translations<MarkCreditNotesAsRefundedOutsideSpendeskError> =
  {
    requestError: ({ reason }) => {
      switch (reason) {
        case 'inconsistentCurrency':
          return 'creditNote.query.markCreditNotesAsRefundedOutsideSpendesk.creditNoteInconsistentCurrency';

        case 'creditNoteNotFound':
          return 'creditNote.query.markCreditNotesAsRefundedOutsideSpendesk.creditNoteNotFound';

        case 'invalidValidationAction':
          return 'creditNote.query.markCreditNotesAsRefundedOutsideSpendesk.creditNoteAlreadyUsed';

        default:
          return 'creditNote.query.markCreditNotesAsRefundedOutsideSpendesk.error';
      }
    },
    serverError:
      'creditNote.query.markCreditNotesAsRefundedOutsideSpendesk.error',
  };

export const useMarkCreditNoteAsRefundedOutsideSpendeskErrorMessage = () => {
  const getErrorMessage = useQueryError<
    MarkCreditNotesAsRefundedOutsideSpendeskError,
    TranslationParams
  >(markCreditNoteAsRefundedOutsideSpendeskErrorTranslations);

  return (
    queryError: QueryError<MarkCreditNotesAsRefundedOutsideSpendeskError>,
    translationParams: TranslationParams,
  ): string => getErrorMessage(queryError, translationParams);
};
