import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type ExportBudgetsInput = {
  budgetIds: string[];
};

export const useExportBudgetsMutation =
  (): MutationState<ExportBudgetsInput> => {
    return useMutation<ExportBudgetsInput>({
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: `/budgets/exports`,
        method: 'post',
      },
      options: {
        throwOnError: true,
      },
    });
  };
