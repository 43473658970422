import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { type NavigationLinkProps } from 'src/core/common/components/NavigationLink';
import { ProtectedRoute } from 'src/core/common/components/ProtectedRoute';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { BillingInformationSection } from 'src/core/modules/company/billing/components/BillingInformationSection';
import { CompanyPlanContainer } from 'src/core/modules/company/billing/components/CompanyPlan';
import { CompanyInformationPage } from 'src/core/modules/company/general-settings/components/CompanyInformationPage';

export const SettingsSpendeskPlanPage = () => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const user = useUser();

  const canAccessCompanyInformation = user.is_account_owner || user.is_admin;

  const canAccessBillingPlan = company.churning_at
    ? user.is_account_owner || user.is_controller
    : user.is_account_owner || user.is_admin || user.is_controller;

  const links = [
    canAccessCompanyInformation && {
      key: 'company-information',
      text: t('generalSettings.navLinks.companyInformation'),
      path: routeFor(routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path, {
        company: company.id,
      }),
    },
    canAccessBillingPlan && {
      key: 'billing-plan',
      text: t('billing.navLinks.companyPlan'),
      path: routeFor(routes.COMPANY_BILLING_PLAN.path, {
        company: company.id,
      }),
    },
  ].filter(Boolean) as NavigationLinkProps[];

  return (
    <div className="page__container">
      <HeaderWithNavigation links={links}>
        {t('navigation.mySpendeskPlan')}
      </HeaderWithNavigation>
      <div className="grow bg-page-background p-3xl">
        <div className="flex max-w-[760px] flex-col gap-xxl">
          <Switch>
            <ProtectedRoute
              path={routes.COMPANY_BILLING_PLAN.path}
              isAccessAllowed={canAccessBillingPlan}
            >
              <CompanyPlanContainer />
            </ProtectedRoute>
            <Route
              path={[
                routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path,
                routes.COMPANY_BILLING_INFORMATION.path,
              ]}
            >
              {canAccessCompanyInformation && <CompanyInformationPage />}

              {canAccessBillingPlan && <BillingInformationSection />}
            </Route>

            {links.length > 0 && <Redirect to={links[0].path} />}

            {links.length === 0 && <Redirect to={routes.HOMEPAGE.path} />}
          </Switch>
        </div>
      </div>
    </div>
  );
};
