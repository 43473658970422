import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import merge from 'lodash/merge';

import { capitalize } from 'common/utils/string';
import { type RawMember } from 'src/core/modules/members/models/member';

import { isAccountOwner, isGroupAdmin } from './userTyped';

const displayName = (user: RawMember) => `${user.first_name} ${user.last_name}`;

const isOrganisationOwner = (user: RawMember) => !!user.is_organisation_owner;

export const isAdmin = (
  // @ts-expect-error won't change this during ts migration, but its probably useless
  user: RawMember = {},
  company: { id: string } = { id: '' },
) => {
  return user?.data_by_company?.[company.id]?.is_admin;
};

export const isController = (
  // @ts-expect-error won't change this during ts migration, but its probably useless
  user: RawMember = {},
  company: { id: string } = { id: '' },
) => {
  return user?.data_by_company?.[company.id]?.is_controller;
};

export const isRequester = (
  // @ts-expect-error won't change this during ts migration, but its probably useless
  user: RawMember = {},
  company: { id: string } = { id: '' },
) => {
  return user?.data_by_company?.[company.id]?.is_requester;
};

const getSpendingPolicy = (user: RawMember) => {
  const permission = user.legacy_spending_policy;
  return merge(
    {
      transaction_max: 0,
      spending_limit: 0,
    },
    permission ? permission.params : null,
  );
};

const getCompanyRoles = (user: RawMember) =>
  map(
    // Can not work without spxEnrichRawMember adding the role property
    // @ts-expect-error won't fix role typing
    filter(user.roles, (role) =>
      includes(['company', 'organisation'], role.scope.entity_type),
    ),
  );

const getCompanyRolesNames = (user: RawMember) =>
  // eslint-disable-next-line lodash/prop-shorthand
  map(getCompanyRoles(user), 'name');

const isDeleted = (user: RawMember) => !!user.deleted_at;

const hasSlackLinked = (user: RawMember, company: { id: string }) => {
  const companyId = get(company, 'id');
  const companyData = user.data_by_company[companyId];
  return get(companyData, 'provider_integration_statuses.has_slack_linked');
};

const hasSlackEnabled = (user: RawMember, company: { id: string }) => {
  const companyId = get(company, 'id');
  const companyData = user.data_by_company[companyId];
  return get(companyData, 'provider_integration_statuses.has_slack_enabled');
};

// export const enrichUser = (user: User & { roles: Role[] }, company: Company) =>
export const enrichUser = (user: RawMember, company: { id: string }) =>
  merge(
    {},
    {
      display_name: displayName(user),
      is_organisation_owner: isOrganisationOwner(user),
      is_account_owner: isAccountOwner(user, company),
      is_admin: isAdmin(user, company),
      is_controller: isController(user, company),
      is_requester: isRequester(user, company),
      // @ts-expect-error there is a confusion between User and Member types here
      is_group_admin: isGroupAdmin(user, company),
      spending_policy: getSpendingPolicy(user),

      company_roles: getCompanyRolesNames(user),
      is_deleted: isDeleted(user),
      has_slack_linked: hasSlackLinked(user, company),
      has_slack_enabled: hasSlackEnabled(user, company),
    },
    user,
  );

// Temporary helpers to reproduce the old behaviour of the backbone user model
const getUserAttribute = (
  user: RawMember,
  companyId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attribute: any,
) => {
  if (attribute in user) {
    // @ts-expect-error we cant fix it here, as this function is used with several shapes of users (enriched, raw, etc)
    return user[attribute];
  }
  if (attribute === 'data_by_company') {
    return {};
  }
  // @ts-expect-error won't fix types here
  return user.data_by_company[companyId]?.[attribute];
};

const capitalizeAll = (name: string | null | undefined) => {
  return (name ?? '').split(' ').map(capitalize).join(' ');
};

// Temporary replacement for the backbone model user toJSON method
export const spxEnrichUser = (user: RawMember, company: { id: string }) => {
  const policy = getUserAttribute(
    user,
    company.id,
    'legacy_spending_policy',
  )?.params;
  const roles = getUserAttribute(user, company.id, 'roles');
  const groups_admins = getUserAttribute(user, company.id, 'groups_admins');
  const is_group_admin =
    !!user.data_by_company?.[company.id]?.is_account_owner ||
    (groups_admins && groups_admins.length > 0);

  const fromBackbone = {
    display_name: `${capitalizeAll(user.first_name)} ${capitalizeAll(
      user.last_name,
    )}`,
    is_organisation_owner: getUserAttribute(
      user,
      company.id,
      'is_organisation_owner',
    ),
    is_account_owner: getUserAttribute(user, company.id, 'is_account_owner'),
    is_admin: getUserAttribute(user, company.id, 'is_admin'),
    is_controller: getUserAttribute(user, company.id, 'is_controller'),
    is_requester: getUserAttribute(user, company.id, 'is_requester'),
    is_group_admin: is_group_admin,
    spending_policy: {
      transaction_max: 0,
      spending_limit: 0,
      ...policy,
    },
    transaction_max: policy?.transaction_max ?? 0,
    spending_limit: policy?.spending_limit ?? 0,
    company_roles: roles
      // @ts-expect-error the getUserAttribute fn is impossible to type properly, would need to rework
      ?.map((role) => role.name),

    is_deleted: !!getUserAttribute(user, company.id, 'is_deleted'),
    is_delegating: getUserAttribute(user, company.id, 'is_delegating'),
    user_delegate: getUserAttribute(user, company.id, 'user_delegate'),
    monthly_outgoings: getUserAttribute(user, company.id, 'monthly_outgoings'),
    groups: getUserAttribute(user, company.id, 'groups'),
    groups_admins: groups_admins,
    nbPaymentsFromBeginning: getUserAttribute(
      user,
      company.id,
      'nbPaymentsFromBeginning',
    ),
    requests: getUserAttribute(user, company.id, 'requests'),
    is_mobile_needed: getUserAttribute(user, company.id, 'is_mobile_needed'),
  };

  return {
    ...user,
    ...fromBackbone,
  };
};
