import { useParams, useHistory } from 'react-router-dom';

import { routes, routeFor } from "src/core/constants/routes";

export const useRedirectToBudget = (): ((budgetId: string) => void) => {
  const history = useHistory();
  const { company: companyId, id: selectedBudgetId } = useParams();

  return (budgetId: string): void => {
    if (budgetId !== selectedBudgetId) {
      history.push(
        routeFor(routes.BUDGET_OVERVIEW.path, {
          id: budgetId,
          company: companyId,
        }),
      );
    }
  };
};
