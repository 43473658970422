import { useParams } from 'react-router-dom';

import { routes, routeFor } from 'src/core/constants/routes';
import history from 'src/core/history';

export const useGoToCreateInvoice = () => {
  const { company: companyId }: { company: string } = useParams();

  return (purchaseOrderId?: string) => {
    history.push(
      routeFor(routes.REQUESTS.path, {
        type: 'all',
        id: 'new',
        newRequestType: `invoice${
          purchaseOrderId ? `?purchase-order-id=${purchaseOrderId}` : ''
        }`,
        company: companyId,
      }),
    );
  };
};
