import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routeFor, routes } from "src/core/constants/routes";

type Props = Readonly<{
  company: {
    id: string;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
}>;

export const ExpenseClaimsLink = ({
  company,
  className,
  isInDropdown,
  onClick,
}: Props) => {
  const { t } = useTranslation('global');
  const { pathname: currentPath } = useLocation();

  return (
    <NavigationLink
      data-marketing="top-menu_expense-claims"
      key="nav_expense_claims"
      className={className}
      onClick={onClick}
      path={routeFor(routes.EXPENSE_CLAIMS_REVIEW.path, {
        company: company.id,
      })}
      text={t('misc.expenseClaimsTitle')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [routes.EXPENSE_CLAIMS.path],
        }),
      )}
      isInDropdown={isInDropdown}
    />
  );
};
