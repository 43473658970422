import { useFeature } from 'common/hooks/useFeature';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { shouldDisplayExpenseCategoryField } from 'modules/budgets/models/expenseCategory';
import FEATURES from 'src/core/constants/features';

export const useShouldDisplayExpenseCategoryField = (
  selectedCostCenterId: string | undefined,
  eligibleTypes: { type: string }[],
  requestType: string,
) => {
  const isExpenseCategoriesFeatureEnabled = useFeature(
    FEATURES.EXPENSE_CATEGORIES,
  );
  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const costCentersQueryState = useCostCentersQuery();

  if (costCentersQueryState.status === 'success') {
    return shouldDisplayExpenseCategoryField({
      isCostCentersFeatureEnabled,
      isExpenseCategoriesFeatureEnabled,
      costCenters: costCentersQueryState.data,
      selectedCostCenter: selectedCostCenterId,
      eligibleTypes,
      requestType,
    });
  }

  return false;
};
