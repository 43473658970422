import React from 'react';

import { useFetchWalletDetailQuery } from 'modules/company/wallet/hooks';

import { HomepageWallet } from './HomepageWallet';
import { HomepageWalletPlaceholder } from '../HomepageWalletPlaceholder';

type Props = {
  company: {
    id: string;
    country: string;
    currency: string;
    is_kyb_validated: boolean;
  };
  isAccountLoaded: boolean;
  isLoading: boolean;
  isMarqeta: boolean;
  hasPendingAchSource: boolean;
  hasPendingGpaOrders: boolean;
  hasValidatedAchSource: boolean;
};

export const HomepageWalletContainer = ({
  company,
  isAccountLoaded,
  isLoading,
  isMarqeta,
  hasPendingAchSource,
  hasPendingGpaOrders,
  hasValidatedAchSource,
}: Props) => {
  const { id: companyId } = company;

  const fetchWalletDetailQueryState = useFetchWalletDetailQuery(companyId);

  if (isLoading || fetchWalletDetailQueryState.status !== 'success') {
    return <HomepageWalletPlaceholder />;
  }
  const { walletSummary, hasMissingFunds } = fetchWalletDetailQueryState.data;

  if (!walletSummary) {
    return null;
  }

  return (
    <HomepageWallet
      company={company}
      hasMissingFunds={hasMissingFunds}
      isAccountLoaded={isAccountLoaded}
      isMarqeta={isMarqeta}
      hasPendingGpaOrders={hasPendingGpaOrders}
      mustConnectBankAccount={hasPendingAchSource || !hasValidatedAchSource}
      walletSummary={walletSummary}
    />
  );
};
