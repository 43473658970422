import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ControlSettingsControlRules } from './ControlSettings/ControlSettingsControlRules';
import { ControlSettingsCustomFieldList } from './ControlSettings/ControlSettingsCustomFieldList';
import { ControlSettingsCustomFieldName } from './ControlSettings/ControlSettingsCustomFieldName';
import { ControlSettingsCustomFieldValues } from './ControlSettings/ControlSettingsCustomFieldValues';
import { ControlSettingsExpenseCategoryList } from './ControlSettings/ControlSettingsExpenseCategoryList';
import { ControlSettingsNewExpenseCategory } from './ControlSettings/ControlSettingsNewExpenseCategory';
import { SetupHubPage } from './SetupHubPage';
import { routes } from './routes';

export const SetupHubRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={[routes.SETUP_HUB_TASK_LIST.path]}
        component={SetupHubPage}
      />
      <Route
        exact
        path={routes.CONTROL_SETTINGS_NEW_EXPENSE_CATEGORY.path}
        component={ControlSettingsNewExpenseCategory}
      />
      <Route
        exact
        path={routes.CONTROL_SETTINGS_EXPENSE_CATEGORY_LIST.path}
        component={ControlSettingsExpenseCategoryList}
      />
      <Route
        exact
        path={routes.CONTROL_SETTINGS_CUSTOM_FIELD_NAME.path}
        component={ControlSettingsCustomFieldName}
      />
      <Route
        exact
        path={routes.CONTROL_SETTINGS_CUSTOM_FIELD_VALUES.path}
        component={ControlSettingsCustomFieldValues}
      />
      <Route
        exact
        path={routes.CONTROL_SETTINGS_CUSTOM_FIELD_LIST.path}
        component={ControlSettingsCustomFieldList}
      />
      <Route
        exact
        path={routes.CONTROL_SETTINGS_CONTROL_RULES.path}
        component={ControlSettingsControlRules}
      />

      <Redirect to={routes.SETUP_HUB_TASK_LIST.path} />
    </Switch>
  );
};
