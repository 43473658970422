import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { routes, routeFor } from 'src/core/constants/routes';
import { apiUrl } from 'src/core/utils/api';

/**
 * This component calls a download url and redirects the user to the homepage after downloading the file
 */
export const BookkeepExportDownloader = () => {
  const { company: companyId, exportId } = useParams();
  const history = useHistory();
  const homepageRoute = routeFor(routes.HOMEPAGE.path, {
    company: companyId,
  });
  const handleExportDownload = async () => {
    const downloadUrl = apiUrl(
      `/accounts/statements/download/${exportId}`,
      companyId,
    );

    try {
      await window.open(downloadUrl);
    } finally {
      history.push(homepageRoute);
    }
  };

  useEffect(() => {
    handleExportDownload();
  }, []);

  return null;
};
