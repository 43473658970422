import { createAction, type ThunkDispatch } from '@reduxjs/toolkit';

import { LocalStorageKey } from 'src/core/constants/storage';
import { type AppState } from 'src/core/reducers';
import { getSelf } from 'src/core/selectors/users';
import { setLocalItem, getLocalItem } from 'src/core/utils/storage';

import * as TYPES from './actionTypes';

const showSwitchedSupervisedUserModal = createAction(
  TYPES.SHOW_SWITCHED_SUPERVISED_USER_MODAL,
);

export const hideSwitchedSupervisedUserModal = createAction(
  TYPES.HIDE_SWITCHED_SUPERVISED_USER_MODAL,
);

export const detectSupervisionAccountSwitch =
  () =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<AppState, null, any>,
    getState: () => AppState,
  ): void => {
    const state = getState();
    if (!state.global.supervisor) {
      return;
    }

    const { id: supervisedUserId, fullname: supervisedUserName } =
      getSelf(state);

    setLocalItem(LocalStorageKey.LastSupervisedUserId, supervisedUserId, true);
    setLocalItem(
      LocalStorageKey.LastSupervisedUserName,
      supervisedUserName,
      true,
    );

    /*
     * Every 10 seconds, we check if the supervised user has changed by comparing the user id in the redux store and
     * the user id in the local storage (this one is immediately updated when we log in to a new supervision account)
     */
    setInterval(() => {
      const hasSwitched =
        supervisedUserId !==
        getLocalItem(LocalStorageKey.LastSupervisedUserId, true);

      if (hasSwitched) {
        dispatch(showSwitchedSupervisedUserModal());
      }
    }, 10_000);
  };
