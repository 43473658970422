/* eslint-disable @typescript-eslint/no-redeclare */
export const FETCH_GROUPS_LOADING = 'FETCH_GROUPS_LOADING';
export type FETCH_GROUPS_LOADING = typeof FETCH_GROUPS_LOADING;
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export type FETCH_GROUPS_SUCCESS = typeof FETCH_GROUPS_SUCCESS;
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';
export type FETCH_GROUPS_FAILURE = typeof FETCH_GROUPS_FAILURE;
export const GROUP_ADD_USERS_LOADING = 'GROUP_ADD_USERS_LOADING';
export type GROUP_ADD_USERS_LOADING = typeof GROUP_ADD_USERS_LOADING;
export const GROUP_ADD_USERS_FAILURE = 'GROUP_ADD_USERS_FAILURE';
export type GROUP_ADD_USERS_FAILURE = typeof GROUP_ADD_USERS_FAILURE;
export const GROUP_ADD_USERS_SUCCESS = 'GROUP_ADD_USERS_SUCCESS';
export type GROUP_ADD_USERS_SUCCESS = typeof GROUP_ADD_USERS_SUCCESS;
export const GROUP_REMOVE_USERS_LOADING = 'GROUP_REMOVE_USERS_LOADING';
export type GROUP_REMOVE_USERS_LOADING = typeof GROUP_REMOVE_USERS_LOADING;
export const GROUP_REMOVE_USERS_FAILURE = 'GROUP_REMOVE_USERS_FAILURE';
export type GROUP_REMOVE_USERS_FAILURE = typeof GROUP_REMOVE_USERS_FAILURE;
export const GROUP_REMOVE_USERS_SUCCESS = 'GROUP_REMOVE_USERS_SUCCESS';
export type GROUP_REMOVE_USERS_SUCCESS = typeof GROUP_REMOVE_USERS_SUCCESS;
