import { Button, Callout, DATE_FORMAT, Icon, Tag } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { haveSettingsAtLeastOneValidationError } from 'modules/accounting-integration/models';
import { AccountingBasePage } from 'modules/bookkeep/settings/accounting/components/AccountingBasePage';
import { AccountingBaseInput } from 'modules/bookkeep/settings/accounting/components/AccountingBasePage/components/AccountingBaseInput';
import { AccountingBaseSelect } from 'modules/bookkeep/settings/accounting/components/AccountingBasePage/components/AccountingBaseSelect';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
  type I18nKey,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { AuthFieldsAndLinks } from './AuthFieldsAndLinks';
import styles from './AuthSection.module.css';
import { ChooseSubsidiaryModal } from './ChooseSubsidiaryModal';
import { ChooseTenantDialog } from './ChooseTenantDialog';
import { DefaultVendorsSection } from './DefaultVendorsSection';
import { ExportValidationSection } from './ExportValidationSection';
import { FileBasedAccountingIntegrationSettings } from './FileBasedAccountingIntegrationSettings';
import {
  getBrandName,
  getIntegrationName,
} from '../../../../../../integration/name';
import {
  getConnectionStatus,
  type IntegrationStatus,
  getTenantChooserState,
  hasIntegrationFileBasedExport,
  getSubsidiaryChooserState,
  type NativeAccountingIntegration,
  isIntegrationsPlanEnabled,
  isIntegrationStatusWithIntegrationSupportingSupplierSync,
} from '../../../../../../integration/status';
import {
  getTagVariantFromConnection as getTagVariantFromConnectionStatus,
  getTextFromConnection as getTextFromConnectionStatus,
} from '../../../../connection';
import { useBuildScopedConsentUrlMutation } from '../../../../hooks/useBuildScopedConsentUrlMutation';
import { useChooseSubsidiaryMutation } from '../../../../hooks/useChooseSubsidiaryMutation';
import { useChooseTenantMutation } from '../../../../hooks/useChooseTenantMutation';

interface Props {
  status: IntegrationStatus;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AuthSection = ({ status }: Props) => {
  const { t } = useTranslation('global');

  const [chooseTenant] = useChooseTenantMutation();
  const [chooseSubsidiary] = useChooseSubsidiaryMutation();
  const [buildScopedConsentUrl] = useBuildScopedConsentUrlMutation();

  const isNetsuiteEnterpriseEnabled = useFeature(
    FEATURES.NETSUITE_ACCOUNTING_INTEGRATION,
  );

  const isNetsuiteBetaEnabled = useFeature(FEATURES.TMP_NETSUITE_BETA);

  const isNativeIntegration =
    status.integration !== 'noIntegration' &&
    status.integration !== 'switchInProgress' &&
    !hasIntegrationFileBasedExport(status.integration);

  const connectionStatus = getConnectionStatus(status);
  const tenantChooserState = getTenantChooserState(status);
  const subsidiaryChooserState = getSubsidiaryChooserState(status);

  const [isOpen, setIsOpen] = useState(false);

  const hasOauth1ConnectionError =
    status.integration !== 'noIntegration' &&
    status.integration !== 'switchInProgress' &&
    status.authorization.kind === 'oauth1' &&
    status.authorization.status.status === 'connectionError';

  const handleChooseTenant = async (tenantId: string) => {
    if (
      status.integration !== 'noIntegration' &&
      status.integration !== 'switchInProgress' &&
      status.authorization.kind === 'oauth2ScopedOpenId'
    ) {
      return buildScopedConsentUrl(tenantId);
    }
    await chooseTenant({ tenantId });
  };

  const handleChooseSubsidiary = async (subsidiaryId: string) => {
    await chooseSubsidiary({ id: subsidiaryId });
  };

  const brandName = getBrandName(t, status.integration);
  const integrationName = getIntegrationName(t, status.integration);
  const hasIntegrationErrors =
    'settingsValidation' in status &&
    haveSettingsAtLeastOneValidationError(status.settingsValidation);

  const integrationPlanEnabled =
    status.integration !== 'noIntegration' &&
    status.integration !== 'switchInProgress' &&
    isIntegrationsPlanEnabled({
      integrationName: status.integration,
      featureFlags: {
        netsuite: isNetsuiteEnterpriseEnabled || isNetsuiteBetaEnabled,
      },
    });

  return (
    <div id="accounting-software" data-testid="accounting-software">
      <ChooseTenantDialog
        brandName={brandName}
        chooserState={tenantChooserState}
        onChooseTenant={handleChooseTenant}
      />
      {isNativeIntegration && (
        <ChooseSubsidiaryModal
          brandName={brandName}
          chooserState={subsidiaryChooserState}
          onChooseSubsidiary={handleChooseSubsidiary}
          integration={status.integration as NativeAccountingIntegration}
        />
      )}

      <h3 className="IntegrationAccountingPage__section-title title-xl">
        {t('bookkeep.integrations.settings.sectionAccountingSoftware')}
      </h3>

      <p className="IntegrationAccountingPage__section-description body-m">
        {t('bookkeep.integrations.settings.accountingSoftwareDescription')}
        &nbsp;|&nbsp;
        <a
          href="https://helpcenter.spendesk.com/collections/2383252-integrations-with-accounting-software"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('misc.helpCenterArticle')}
        </a>
      </p>

      {connectionStatus === 'needServerUp' &&
        status.integration === 'Sage100' && (
          <>
            <Callout
              title={t('bookkeep.integrations.chift.serverNeedsToBeUp.title')}
              variant="warning"
            >
              <p>
                {t('bookkeep.integrations.chift.serverNeedsToBeUp.content', {
                  integration: status.integration,
                })}
              </p>
            </Callout>
            <br />
          </>
        )}

      {(connectionStatus === 'connected' || !isNativeIntegration) &&
        hasIntegrationErrors && (
          <Callout
            title={t(
              'bookkeep.integrations.settings.globalSettingsValidationAdvice.title',
            )}
            variant="alert"
            className={styles.settingsErrorCallout}
          />
        )}
      {status.integration === 'noIntegration' ||
      status.integration === 'switchInProgress' ? (
        <AccountingBasePage
          isDefaultOpen={status.integration === 'switchInProgress'}
          integrationStatus={status}
        />
      ) : null}

      {status.integration !== 'noIntegration' &&
      status.integration !== 'switchInProgress' ? (
        <div className={classNames(styles.wrapper, 'box')}>
          <div
            className={classNames(
              styles.titleLine,
              !isNativeIntegration && styles.fileBasedTitle,
            )}
          >
            <span className={classNames(styles.title, 'title-l')}>
              {integrationName}
            </span>

            {isNativeIntegration && (
              <Tag
                variant={getTagVariantFromConnectionStatus(connectionStatus)}
              >
                {t(getTextFromConnectionStatus(connectionStatus))}
              </Tag>
            )}

            {isNativeIntegration &&
              connectionStatus === 'connected' &&
              status.settingsRefresh.kind === 'canRefresh' && (
                <LastRefreshedAt
                  date={status.settingsRefresh.lastRefreshedAt}
                />
              )}
          </div>

          <div className="IntegrationAccountingPage__section-description body-m">
            {isNativeIntegration ? (
              <Trans
                values={{ integration: integrationName }}
                i18nKey={
                  connectionStatus === 'connected'
                    ? getStatusConnectedDescriptionI18nKey(status)
                    : getStatusNotConnectedDescriptionI18nKey(status)
                }
              >
                <a
                  href={getStatusConnectedDescriptionLink(status)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this article
                </a>
              </Trans>
            ) : null}
            {!isNativeIntegration
              ? t(getFileBasedDescriptionI18nKey(status))
              : null}
          </div>

          {status.capabilities.exportValidation &&
            connectionStatus === 'connected' && <ExportValidationSection />}

          <AccountingBaseInput
            className={isNativeIntegration ? styles.switchInput : ''}
            value={status.integration}
            onClick={() => setIsOpen(true)}
          />

          <AccountingBaseSelect
            initialIntegrationStatus={status}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClose={() => setIsOpen(false)}
          />

          {status.integration !== 'Datev' && status.selectedTenantName && (
            <div className="my-m flex items-center justify-between">
              <div>{t('bookkeep.integrations.settings.connectedTenant')}</div>
              <div>{status.selectedTenantName}</div>
            </div>
          )}

          {integrationPlanEnabled &&
            connectionStatus === 'connectionError' &&
            (hasOauth1ConnectionError ? (
              <OAuth1Errors integrationName={integrationName} />
            ) : (
              <ShowConnectionError status={status} t={t} />
            ))}

          {!integrationPlanEnabled && (
            <ShowNoAccessError integration={status.integration} />
          )}

          {!isNativeIntegration && <FileBasedAccountingIntegrationSettings />}

          {integrationPlanEnabled &&
            isIntegrationStatusWithIntegrationSupportingSupplierSync(status) &&
            connectionStatus === 'connected' && (
              <DefaultVendorsSection integration={status.integration} />
            )}

          {isNativeIntegration && <AuthFieldsAndLinks status={status} />}
        </div>
      ) : null}
    </div>
  );
};

const LastRefreshedAt = ({ date }: { date: string | undefined }) => {
  const { t, localeFormat } = useTranslation('global');
  return (
    <div className={styles.lastRefreshedAt}>
      <Icon name="clock" size="s" />
      <span className="ml-xs body-s">
        {date
          ? t('bookkeep.integrations.settings.lastRefreshedAt', {
              date: localeFormat(new Date(date), DATE_FORMAT.LONG_WITH_TIME),
            })
          : t('bookkeep.integrations.settings.neverRefreshed')}
      </span>
    </div>
  );
};

const ShowNoAccessError = ({ integration }: { integration: string }) => {
  const { t } = useTranslation('global');
  return (
    <Callout
      variant="alert"
      className={styles.connectionErrorCallout}
      title={t('bookkeep.integrations.settings.noIntegrationInPlan.title', {
        integration,
      })}
    >
      <div>
        <p className="mb-xs body-m">
          {t(
            'bookkeep.integrations.settings.noIntegrationInPlan.descriptionTop',
            {
              integration,
            },
          )}
        </p>
        <Button
          variant="contrasted"
          text={t(
            'bookkeep.integrations.settings.noIntegrationInPlan.reachOut',
          )}
          onClick={() => window.Intercom('show')}
        />
      </div>
    </Callout>
  );
};

const OAuth1Errors = ({ integrationName }: { integrationName: string }) => {
  const { t } = useTranslation('global');
  return (
    <Callout
      variant="alert"
      className={styles.connectionErrorCallout}
      title={t('bookkeep.integrations.settings.oAuth1Errors.title', {
        integrationName,
      })}
    >
      <div>
        <p className="mb-xs body-m">
          {t('bookkeep.integrations.settings.oAuth1Errors.descriptionTop', {
            integrationName,
          })}
        </p>
        <ul className={classNames(styles.oAuth1ErrorList, 'body-m')}>
          <li>{t('bookkeep.integrations.settings.oAuth1Errors.accountId')}</li>
          <li>{t('bookkeep.integrations.settings.oAuth1Errors.clientId')}</li>
          <li>
            {t('bookkeep.integrations.settings.oAuth1Errors.clientSecret')}
          </li>
        </ul>
        <p className="mb-xs body-m">
          {t('bookkeep.integrations.settings.oAuth1Errors.descriptionBottom')}
        </p>

        <Button
          variant="contrasted"
          text={t('bookkeep.integrations.settings.oAuth1Errors.reachOut')}
          onClick={() => window.Intercom('show')}
        />
      </div>
    </Callout>
  );
};

function getStatusConnectedDescriptionI18nKey(
  status: IntegrationStatus,
): string {
  const { integration } = status;

  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero.statusDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.statusConnectedDescription';
    case 'SpendeskAccounting':
      return 'spendeskAccounting';
    case 'SpendeskAccountingSingleEntry':
      return 'spendeskAccountingSingleEntry';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.statusConnectedDescription';
    case 'Sage':
      return 'sage';
    case 'Cegid':
      return 'cegid';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks.statusConnectedDescription';
    case 'Sage100':
    case 'ACD':
      return 'bookkeep.integrations.chift.statusConnectedDescription';
    case 'noIntegration':
      return 'noIntegration';
    case 'switchInProgress':
      return 'switchInProgress';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

function getStatusConnectedDescriptionLink(status: IntegrationStatus): string {
  const { integration } = status;

  switch (integration) {
    case 'Datev':
      return 'https://helpcenter.spendesk.com/articles/5588518';
    case 'Xero':
      return 'https://helpcenter.spendesk.com/articles/5511455';
    case 'Netsuite':
      return 'https://helpcenter.spendesk.com/articles/6899573';
    case 'Sage100':
    case 'ACD': // TODO
      return 'https://helpcenter.spendesk.com/collections/9677966-sage100-sur-compta-2-0';
    default:
      return 'https://helpcenter.spendesk.com/collections/2341495';
  }
}

function getStatusNotConnectedDescriptionI18nKey(
  status: IntegrationStatus,
): string {
  const { integration } = status;

  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero.statusDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.statusNotConnectedDescription';
    case 'SpendeskAccounting':
      return 'spendeskAccounting';
    case 'SpendeskAccountingSingleEntry':
      return 'spendeskAccountingSingleEntry';
    case 'Sage':
      return 'sage';
    case 'Cegid':
      return 'cegid';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.statusNotConnectedDescription';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks.statusNotConnectedDescription';
    case 'Sage100':
    case 'ACD':
      return 'bookkeep.integrations.chift.statusNotConnectedDescription';
    case 'noIntegration':
      return 'noIntegration';
    case 'switchInProgress':
      return 'switchInProgress';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

function getFileBasedDescriptionI18nKey(status: IntegrationStatus): I18nKey {
  const { integration } = status;

  switch (integration) {
    case 'SpendeskAccounting':
      return 'bookkeep.integrations.doubleEntry.statusDescription';
    case 'SpendeskAccountingSingleEntry':
      return 'bookkeep.integrations.singleEntry.statusDescription';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.statusDescription';
    case 'Sage':
      return 'bookkeep.integrations.sage.statusDescription';
    case 'Cegid':
      return 'bookkeep.integrations.cegid.statusDescription';
    case 'Xero':
      return 'bookkeep.integrations.xero.statusDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.statusConnectedDescription';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks.statusConnectedDescription';
    case 'Sage100':
    case 'ACD':
      return 'bookkeep.integrations.chift.statusConnectedDescription';
    case 'noIntegration':
    case 'switchInProgress':
      return 'bookkeep.integrations.noIntegration.integrationName';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

function ShowConnectionError({
  t,
  status,
}: {
  t: TGlobalFunctionTyped;
  status: IntegrationStatus;
}) {
  const integrationName = getIntegrationName(t, status.integration);

  if (
    status.integration === 'Datev' &&
    status.authorization.kind === 'oauth2ScopedOpenId' &&
    status.authorization.status.status === 'connectionError' &&
    status.authorization.status.error === 'tokenUnauthorized'
  ) {
    return (
      <Trans i18nKey="bookkeep.integrations.settings.connectionError.datev.tokenUnauthorized">
        <a
          href="https://helpcenter.spendesk.com/en/articles/5588518-configure-datev-uo-for-spendesk-integration"
          target="_blank"
          rel="noopener noreferrer"
        >
          this article
        </a>
      </Trans>
    );
  }

  return (
    <Callout
      variant="alert"
      className={styles.connectionErrorCallout}
      title={t('bookkeep.integrations.settings.connectionErrorWarning', {
        integrationName,
      })}
    />
  );
}
