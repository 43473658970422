import { gql } from 'graphql-tag';

export const UNSET_EXPENSE_ACCOUNT_SUPPLIER_RULE = gql`
  mutation UnsetExpenseAccountSupplierRule($companyId: ID!, $supplierId: ID!) {
    unsetExpenseAccountSupplierRule(
      companyId: $companyId
      supplierId: $supplierId
    ) {
      ... on UnsetExpenseAccountSupplierRuleResultNotUnset {
        reason
      }
    }
  }
`;
