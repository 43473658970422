import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { type DefaultSupplierAccount } from 'modules/bookkeep/settings/accounting/supplierAccount';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from '../ModalState';

type ConfirmEditSupplierAccountModalProps = {
  integration: AccountingSoftware;
  setModalState: Dispatch<SetStateAction<ModalState>>;
  modalState: ModalState;
  defaultSupplierAccount: DefaultSupplierAccount[];
  handleUpdateSpendeskAccount: () => void;
};

export const ConfirmEditSupplierAccountModal = ({
  integration,
  setModalState,
  modalState,
  defaultSupplierAccount,
  handleUpdateSpendeskAccount,
}: ConfirmEditSupplierAccountModalProps) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      title={t(
        'bookkeep.integrations.settings.supplierAccountsTable.confirmDefaultEditTitle',
        {
          codeFrom: defaultSupplierAccount[0]
            ? defaultSupplierAccount[0].generalAccountCode
            : '',
          codeTo: modalState.editedAccount
            ? modalState.editedAccount.generalAccountCode
            : '',
        },
      )}
      subtitle={
        hasIntegrationFileBasedExport(integration)
          ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
          : t(
              'bookkeep.integrations.settings.supplierAccountsTable.confirmDefaultEditSubtitle',
              {
                code: defaultSupplierAccount[0]
                  ? defaultSupplierAccount[0].generalAccountCode
                  : '',
              },
            )
      }
      isOpen={modalState.kind === 'confirmation'}
      iconVariant="warning"
      iconName="pen"
      actions={[
        <Button
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          id="confirm-edit-supplier-account"
          onClick={() => handleUpdateSpendeskAccount()}
          text={t(
            'bookkeep.integrations.settings.expenseAccountsTable.confirmEdition',
          )}
          variant="warning"
        />,
      ]}
    />
  );
};
