import { Panel, PanelSection, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

type Props = {
  onClose(): void;
};
export const TransferPanelSkeleton = ({ onClose }: Props) => {
  return (
    <Panel onClose={onClose}>
      <PanelSection>
        <SkeletonText size="m" />
        <SkeletonText size="m" />
      </PanelSection>

      <PanelSection>
        <SkeletonText size="m" />
        <SkeletonText size="m" />
      </PanelSection>
    </Panel>
  );
};
