import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { getAvailableExportTemplateQueryKey } from './query-key-registry';

export type ListExportTemplate = {
  name: string;
  id: string;
  type: 'purchase' | 'bank';
};

export type AvailableExportTemplatesListResponse = ListExportTemplate[];

export type AvailableExportTemplatesListQueryState =
  QueryState<AvailableExportTemplatesListResponse>;

export const useAvailableExportTemplatesListQuery = (
  isEnabled: boolean,
): AvailableExportTemplatesListQueryState => {
  const companyId = useCompanyId();

  return useQuery<
    AvailableExportTemplatesListResponse,
    AvailableExportTemplatesListResponse
  >({
    key: getAvailableExportTemplateQueryKey(companyId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-export-templates/list',
    },
    isEnabled,
  });
};
