import { Button } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

import Icon from './icon.svg';

export const NoCostCenter = () => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const companyId = useCompanyId();

  return (
    <div className="flex h-[600px] w-full items-center justify-center rounded border border-solid border-neutral-lighter bg-white">
      <div className="flex w-[450px] flex-col items-center text-center">
        <img width="125" height="124" alt="" src={Icon} />
        <div className="mt-l text-complementary title-l">
          {t('budget.budgetaryExercise.costCentersEmpty.title')}
        </div>
        <div className="mb-m mt-xs text-neutral-dark body-m">
          {t('budget.budgetaryExercise.costCentersEmpty.desc')}
        </div>
        <Button
          type="button"
          variant="primary"
          text={t('budget.budgetaryExercise.costCentersEmpty.button')}
          onClick={() => {
            history.push(
              routeFor(routes.COST_CENTERS.path, {
                company: companyId,
              }),
            );
          }}
        />
      </div>
    </div>
  );
};
