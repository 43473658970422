import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";
import { AnalyticEventName, track } from "src/core/utils/analytics";

import { type OperationalOnboardingTask } from '../../../../models';
import { useSkipOnboardingTaskMutation } from '../../../hooks/useSkipOnboardingTaskMutation';
import { useUnskipOnboardingTaskMutation } from '../../../hooks/useUnskipOnboardingTaskMutation';
import { useTaskLearnMoreCta } from '../translationHooks';

type Props = {
  task: OperationalOnboardingTask;
  redirectCta: string;
  postSkipAction: () => void;
  postUnskipAction: () => void;
  withCaretRightIcon: boolean;
  handleStartOnboardingTask: (task: OperationalOnboardingTask) => void;
};

export const OnboardingTaskButtons = ({
  task,
  redirectCta,
  postSkipAction,
  postUnskipAction,
  withCaretRightIcon,
  handleStartOnboardingTask,
}: Props) => {
  const { t } = useTranslation('global');

  const [skipOnboardingTask] = useSkipOnboardingTaskMutation(task.type);
  const [unskipOnboardingTask] = useUnskipOnboardingTaskMutation(task.type);

  const learnMore = useTaskLearnMoreCta(task);

  if (task.status === 'disabled' && learnMore) {
    return (
      <Button
        variant="secondary"
        text={t('homepage.welcomeDashboard.learnMore')}
        component="a"
        href={learnMore}
        target="_blank"
      />
    );
  }

  return (
    <>
      <Button
        variant="primary"
        text={redirectCta}
        iconName={withCaretRightIcon ? 'caret-right' : undefined}
        iconPosition="right"
        onClick={() => handleStartOnboardingTask(task)}
      />
      {task.status !== 'done' && (
        <Button
          variant="ghost"
          text={
            task.status === 'ongoing'
              ? t('homepage.welcomeDashboard.skipCta')
              : t('homepage.welcomeDashboard.unskipCta')
          }
          onClick={async () => {
            if (task.status === 'ongoing') {
              track(AnalyticEventName.WELCOME_ONBOARDING_TASK_SKIP, {
                type: task.type,
                status: task.status,
              });
              await skipOnboardingTask({});
              postSkipAction();
            } else {
              track(AnalyticEventName.WELCOME_ONBOARDING_TASK_UNSKIP, {
                type: task.type,
                status: task.status,
              });
              await unskipOnboardingTask({});
              postUnskipAction();
            }
          }}
        />
      )}
    </>
  );
};
