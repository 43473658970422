/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  type LocalStorageKey,
  type SessionStorageKey,
} from 'src/core/constants/storage';

type StorageKey = LocalStorageKey | SessionStorageKey;

// TODO: add utilities to easily manage boolean toggles

let currentCompanyId: string;

export const init = (companyId: string) => {
  currentCompanyId = companyId;
};

const toStorageKey = (key: StorageKey, useGlobalKey = false) => {
  if (useGlobalKey) {
    return `spx:${key}`;
  }
  if (currentCompanyId) {
    return `spx:${currentCompanyId}:${key}`;
  }
};

export const getItem = (
  key: StorageKey,
  storage: Storage,
  useGlobalKey = false,
) => {
  const storageKey = toStorageKey(key, useGlobalKey);
  if (storageKey) {
    return storage.getItem(storageKey);
  }
};

export const setItem = (
  key: StorageKey,
  value: any,
  storage: Storage,
  useGlobalKey = false,
) => {
  const storageKey = toStorageKey(key, useGlobalKey);
  if (storageKey) {
    storage.setItem(storageKey, value);
  }
};

export const removeItem = (
  key: StorageKey,
  storage: Storage,
  useGlobalKey = false,
) => {
  const storageKey = toStorageKey(key, useGlobalKey);
  if (storageKey) {
    storage.removeItem(storageKey);
  }
};

export const getLocalItem = (key: LocalStorageKey, useGlobalKey = false) =>
  getItem(key, localStorage, useGlobalKey);

export const getSessionItem = (key: SessionStorageKey, useGlobalKey = false) =>
  getItem(key, sessionStorage, useGlobalKey);

export const setLocalItem = (
  key: LocalStorageKey,
  value: any,
  useGlobalKey = false,
) => setItem(key, value, localStorage, useGlobalKey);

export const setSessionItem = (
  key: SessionStorageKey,
  value: any,
  useGlobalKey = false,
) => setItem(key, value, sessionStorage, useGlobalKey);

export const removeLocalItem = (key: LocalStorageKey, useGlobalKey = false) =>
  removeItem(key, localStorage, useGlobalKey);
