import React from 'react';

type PreparePayablesSettingsContextType = {
  user: {
    givenName: string;
    hasSlackEnabled: boolean;
  };
  company: { country: string; currency: string };
};

/**
 * There is currently no nice way to type a context for which the value will be
 * set later via its provider and making its children be aware that the value
 * has been set already and is non-null.
 * {@link https://github.com/typescript-cheatsheets/react-typescript-cheatsheet#context}
 */
export const PreparePayablesSettingsContext =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  React.createContext<PreparePayablesSettingsContextType>(null!);
