import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios';
import get from 'lodash/get';
import includes from 'lodash/includes';
import omit from 'lodash/omit';

import appConfig from 'src/core/config';

import { type CompanyAPIAxiosInstance } from './model';

const blobToString = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    fileReader.onerror = (): void => {
      reject(
        new Error('failed to read blob as text when handling error response'),
      );
    };
    fileReader.addEventListener('load', (): void => {
      resolve(fileReader.result as string);
    });
    // eslint-disable-next-line unicorn/prefer-blob-reading-methods
    fileReader.readAsText(blob);
  });
};

// Remove companyId from the request config
const removeCompanyIdFromConfig = <T extends AxiosRequestConfig>(
  config: T,
): Omit<T, 'companyId'> => {
  return omit(config, ['companyId']) as Omit<T, 'companyId'>;
};

const baseConfig = {
  timeout: (appConfig.axios && appConfig.axios.apiTimeout) || 1 * 60 * 1000,
};

const baseAPI = axios.create({
  ...baseConfig,
  baseURL: appConfig.apiUrls.api,
  withCredentials: true,
});

const frontAPI = axios.create({
  ...baseConfig,
  baseURL: appConfig.apiUrls.base,
});

const companyAPI = axios.create({
  ...baseConfig,
  baseURL: appConfig.apiUrls.api,
  withCredentials: true,
}) as CompanyAPIAxiosInstance;

companyAPI.interceptors.request.use((config) => {
  const companyIdInConfig = get(config, 'companyId');
  // FIXME: we should not try to get companyId in the request payload
  const companyIdInPayload = get(config, 'data.companyId');
  const companyId = companyIdInConfig || companyIdInPayload;

  if (!companyIdInConfig && companyIdInPayload) {
    const error = new Error(
      'companyId should be passed via the request config instead of the payload object',
    );

    // eslint-disable-next-line no-console
    console.warn(`[companyAPI]: ${error.message}`);
  }

  if (!companyId) {
    throw new Error('Missing companyId');
  }

  const baseUrl = config.baseURL ?? '';
  const newBaseUrl = `${baseUrl}${
    baseUrl.endsWith('/') || baseUrl.length === 0 ? '' : '/'
  }${companyId}`;

  return {
    ...removeCompanyIdFromConfig(config),
    baseURL: newBaseUrl,
  };
});

companyAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    // when downloading a file (a zip for example) with Axios, we set
    // responseType to 'blob' but if something goes wrong in server, it may
    // responds with json content that describes the error.
    // if we don't do that, the error data will be a Blob, not a JPA
    if (error.request && error.request.responseType === 'blob') {
      const stringErrorBody = await blobToString(error.response.data);
      // eslint-disable-next-line no-param-reassign
      error.response.data = includes(
        error.response.headers['content-type'],
        'application/json',
      )
        ? JSON.parse(stringErrorBody)
        : stringErrorBody;
    }
    throw error;
  },
);

companyAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    throw error;
  },
);

const authAPI = axios.create({
  baseURL: appConfig.apiUrls.auth,
  withCredentials: true,
});

const authScaAPI = axios.create({
  baseURL: appConfig.apiUrls.auth,
  withCredentials: true,
});

authScaAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      window.location.href = '/auth/login';
    }
    throw error;
  },
);

export { baseAPI, authAPI, companyAPI, authScaAPI, frontAPI };

export const getAxiosInstance = (
  target: 'baseAPI' | 'companyAPI' | 'authAPI' | 'authScaAPI',
): AxiosInstance => {
  switch (target) {
    case 'baseAPI':
      return baseAPI;
    case 'authAPI':
      return authAPI;
    case 'companyAPI':
      return companyAPI;
    case 'authScaAPI':
      return authScaAPI;
    default:
      throw new Error(`Unknown target ${target}`);
  }
};
