import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

export type OrganisationFeatures = ReturnType<typeof useOrganisationFeatures>;

export const useOrganisationFeatures = () => {
  return {
    page: useFeature(FEATURES.ORGANISATION_REPORTING_PAGE),
    group: useFeature(FEATURES.TMP_ORGANISATION_REPORTING_GROUP),
    wallet: useFeature(FEATURES.TMP_ORGANISATION_REPORTING_WALLET),
    requestsToApprove: useFeature(
      FEATURES.TMP_ORGANISATION_REPORTING_REQUESTS_TO_APPROVE,
    ),
    invoicesToPay: useFeature(
      FEATURES.TMP_ORGANISATION_REPORTING_INVOICES_TO_PAY,
    ),
    lateReceipts: useFeature(FEATURES.TMP_ORGANISATION_REPORTING_LATE_RECEIPTS),
    payablesToReview: useFeature(
      FEATURES.TMP_ORGANISATION_REPORTING_PAYABLES_TO_REVIEW,
    ),
  };
};
