import { Callout, GridContainer, GridItem } from '@dev-spendesk/grapes';
import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import {
  useTranslation,
  type TGlobalFunctionTyped,
} from "src/core/common/hooks/useTranslation";
import { formatMonetaryValue } from "src/core/utils/monetaryValue";

import { ReactComponent as CommittedIcon } from '../../assets/committed.svg';
import { spendingTypes } from '../../breakdownSpendings';
import { BreakdownSpendingsSectionContainer } from '../../containers/BreakdownSpendingsSectionContainer';
import { type BreakdownPart } from '../../models/breakdown';
import { type ExpenseCategoryBreakdown } from '../../models/expenseCategoryBreakdown';
import { type PeriodRangePayload } from '../../models/period';

type Props = {
  budgetId: string;
  title: string;
  breakdownPart: BreakdownPart;
  breakdownComputedAt: Date;
  periodRange: PeriodRangePayload;
  budgetTotalAmount: MonetaryValue;
  expenseCategory?: ExpenseCategoryBreakdown['expenseCategory'];
};

export const BreakdownSpendingsPageDrawerContent = ({
  budgetId,
  title,
  breakdownPart,
  breakdownComputedAt,
  periodRange,
  budgetTotalAmount,
  expenseCategory,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <GridContainer>
      <GridItem columnSpan={12} columnStartLine={0}>
        <h1 className="mb-m text-complementary title-xl ">
          {t('budget.breakdownSpendings.title', { name: title })}
        </h1>
        {renderAmountPanel(
          formatMonetaryValue(budgetTotalAmount),
          getBreakdownPartName(breakdownPart, t),
          getBreakdownPartIcon(breakdownPart),
        )}
        <Callout
          className="mt-m"
          title={t('budget.breakdownSpendings.netAmounts.callout.title')}
        >
          {t('budget.breakdownSpendings.netAmounts.callout.description')}
        </Callout>
        <div className="separator my-m " />
        <div>
          {breakdownPart &&
            spendingTypes.map((spendingType) => {
              if (
                // FIXME: this case shouldn't not happen, to fix in the backend
                spendingType === 'physicalCard' &&
                breakdownPart === 'committed'
              ) {
                return null;
              }
              return (
                <BreakdownSpendingsSectionContainer
                  key={spendingType}
                  spendingType={spendingType}
                  breakdownPart={breakdownPart}
                  budgetId={budgetId}
                  periodRange={periodRange}
                  breakdownComputedAt={breakdownComputedAt}
                  expenseCategory={expenseCategory}
                />
              );
            })}
        </div>
      </GridItem>
    </GridContainer>
  );
};

const getBreakdownPartName = (
  breakdownPart: BreakdownPart,
  t: TGlobalFunctionTyped,
): string | undefined => {
  if (breakdownPart === 'committed') {
    return t('budget.breakdownSpendings.netAmounts.breakdownPartCommitted');
  }
  if (breakdownPart === 'used') {
    return t('budget.breakdownSpendings.netAmounts.breakdownPartUsed');
  }

  return undefined;
};

const getBreakdownPartIcon = (breakdownPart: BreakdownPart) => {
  if (breakdownPart === 'committed') {
    return <CommittedIcon />;
  }
  if (breakdownPart === 'used') {
    return (
      <div className="inline-block h-[12px] w-[12px] rounded-[100%] bg-primary" />
    );
  }

  return undefined;
};

const renderAmountPanel = (
  amount: string,
  label: string | undefined,
  icon: JSX.Element | undefined,
) => {
  return (
    <div className="flex justify-between rounded bg-neutral-lighter py-xs px-s">
      <div className="flex items-center gap-xs">
        {icon && <div className="relative top-[1px]">{icon}</div>}
        <div className="body-m ">{label}</div>
      </div>
      <div className="title-m ">{amount}</div>
    </div>
  );
};
