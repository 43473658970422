import React from 'react';

import { DefaultRequesterSpendingPolicy } from './DefaultRequesterSpendingPolicy';
import { RequesterApprovalPolicy } from './RequesterApprovalPolicy';
import { type SpendingPolicy } from '../../../models/rules';

type Props = {
  spendingPolicy: SpendingPolicy;
  hasApprovalFlowFeature: boolean;
};

export const BasicRequesterSpendingPolicy = ({
  hasApprovalFlowFeature,
  spendingPolicy,
}: Props) =>
  hasApprovalFlowFeature ? (
    <RequesterApprovalPolicy spendingPolicy={spendingPolicy} />
  ) : (
    <DefaultRequesterSpendingPolicy spendingPolicy={spendingPolicy} />
  );
