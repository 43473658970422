import filter from 'lodash/filter';

import { type AppState } from 'src/core/reducers';

export const getOpenedPayment = (state: AppState) =>
  // @ts-expect-error a nullable field should not be used as a key
  state.payments.allPaymentsById[state.payments.allPaymentsOpenedPayment];

export const getOpenedPaymentInvoices = (state: AppState) =>
  // eslint-disable-next-line lodash/matches-shorthand
  filter(state.payments.allPaymentsInvoicesById, {
    paymentId: state.payments.allPaymentsOpenedPayment,
  });

export const getIsPanelInvoicesLoading = (state: AppState) =>
  state.payments.isPanelInvoicesLoading;
