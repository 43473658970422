import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type RawPricingMetrics = {
  metrics: {
    name: 'transaction_volume' | 'subsidiaries';
    quantityPerMonth: number;
  }[];
};

export const useGetCompanyMetrics = (): QueryState<{
  numberOfTransactions: number;
}> => {
  const companyId = useCompanyId();

  return useQuery<{ numberOfTransactions: number }, RawPricingMetrics>({
    key: ['pricingMetrics', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/pricing/metrics',
    },
    reshapeData: (rawPricingMetrics) => {
      return {
        numberOfTransactions:
          rawPricingMetrics.metrics.find(
            (metric) => metric.name === 'transaction_volume',
          )?.quantityPerMonth ?? 0,
      };
    },
  });
};
