import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { getSelf as getUser } from 'src/core/selectors/users';

import { getUserVisibleTeams as getUserVisibleTeamsUtil } from '../../utils/requestUtils';

export const getRequestsSections = (state: AppState) => {
  return state.requests.requestsSections;
};

export const getRequestsStats = (state: AppState) => {
  return state.requests.stats;
};

export const getIsLoading = (state: AppState) => {
  return state.requests.isLoading;
};

export const getExpenseCategoryCustomFieldId = (state: AppState) => {
  return state.requests.expenseCategoryCustomFieldId;
};

export const getExpenseCategory = (state: AppState) => {
  return state.requests.expenseCategory ?? {};
};

export const getCustomFields = (state: AppState) => {
  return state.requests.customFields ?? [];
};

const getAllTeams = (state: AppState) => {
  return state.requests.groups ?? [];
};

export const getUserVisibleTeams = (state: AppState) => {
  const user = getUser(state);
  const teams = getAllTeams(state);
  const companyId = getCompanyId(state);
  const isCostCenterActivated = getIsFeatureEnabled(
    state,
    FEATURES.COST_CENTERS_ACTIVATED,
  );

  return getUserVisibleTeamsUtil(user, teams, companyId, isCostCenterActivated);
};

export const getSearchFilters = (state: AppState) => {
  return state.requests.searchFilters;
};
