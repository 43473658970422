/**
 * Assign to window.version an object with:
 * - sha: contains the sha of the last deployed commit
 */
export function assignVersionToWindow(): void {
  if (process.env.REACT_APP_SHA) {
    window.version = {
      sha: process.env.REACT_APP_SHA,
    };
  }
}
