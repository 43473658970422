import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';

import { useIsAnalyticalSplitAvailable } from './useAnalyticalSplitAvailability';
import { getAnalyticalSplitStatusQueryKey } from '../query-key-registry';

// This hook is useful to check if a company has the analytical split feature activated
// In order to check if a company is eligible to the feature you shoul use the `useAnalyticalSplitAvailability()` hook
// See src/core/modules/bookkeep/hooks/useAnalyticalSplitAvailability.ts

export type AnalyticalSplitStatusResponse = {
  outcome: 'activated' | 'notActivated';
};

export function useIsAnalyticalSplitActivated() {
  const companyId = useCompanyId();
  const isAnalyticalSplitAvailable = useIsAnalyticalSplitAvailable();

  const queryResult = useQuery<
    { isActivated: boolean },
    AnalyticalSplitStatusResponse
  >({
    key: getAnalyticalSplitStatusQueryKey(companyId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/bookkeeping/analytical-split-status',
    },
    options: {
      cacheTime: 10 * 60 * 1000 /* 10min */,
      staleTime: 10 * 60 * 1000 /* 10min */,
    },
    reshapeData(data) {
      return { isActivated: data.outcome === 'activated' };
    },
  });

  const isAnalyticalSplitActivated =
    queryResult.status === 'success' ? queryResult.data.isActivated : false;

  return isAnalyticalSplitAvailable && isAnalyticalSplitActivated;
}
