import { gql } from 'graphql-tag';

export function makeGetPayablesBucketsStatsQuery({
  filters,
  withProofFilters,
  withoutProofFilters,
  missingProofFilters,
}: {
  filters?: Record<string, unknown>;
  withProofFilters?: Record<string, unknown>;
  withoutProofFilters?: Record<string, unknown>;
  missingProofFilters?: Record<string, unknown>;
}) {
  const variables = [
    filters !== undefined ? `$filters: PayableFilter` : '',
    withProofFilters !== undefined ? `$withProofFilters: PayableFilter` : '',
    withoutProofFilters !== undefined
      ? `$withoutProofFilters: PayableFilter`
      : '',
    missingProofFilters !== undefined
      ? `$missingProofFilters: PayableFilter`
      : '',
  ];

  const payablesFiltered =
    filters !== undefined
      ? `
          payablesFiltered: payables(first: 1, filter: $filters) {
            connectionId: id
            totalCount
            aggregate {
              sum {
                functionalAmount
              }
            }
          }
        `
      : '';

  const payablesWithProof =
    withProofFilters !== undefined
      ? `
          payablesWithProof: payables(first: 1, filter: $withProofFilters) {
            connectionId: id
            totalCount
            aggregate {
              sum {
                functionalAmount
              }
            }
          }
        `
      : '';

  const payablesWithoutProof =
    withoutProofFilters !== undefined
      ? `
          payablesWithoutProof: payables(first: 1, filter: $withoutProofFilters) {
            connectionId: id
            totalCount
            aggregate {
              sum {
                functionalAmount
              }
            }
          }
        `
      : '';

  const payablesMissingProof =
    missingProofFilters !== undefined
      ? `
          payablesMissingProof: payables(first: 1, filter: $missingProofFilters) {
            connectionId: id
            totalCount
            aggregate {
              sum {
                functionalAmount
              }
            }
          }
        `
      : '';

  return gql(`
    query GetPayablesBucketsStats(
      $companyId: ID!
      ${variables.filter(Boolean).join('\n')}
    ) {
      company(id: $companyId) {
        id
        currency
        payables {
          totalCount
        }

        ${payablesFiltered}
        ${payablesWithProof}
        ${payablesWithoutProof}
        ${payablesMissingProof}
      }
    }
  `);
}
