import { isInUnion } from "src/core/utils/validators";

export const requiredError = 'required' as const;
export type RequiredError = typeof requiredError;

export const dateOlderThanMinimumError = 'dateOlderThanMinimum' as const;
export type DateOlderThanMinimumError = typeof dateOlderThanMinimumError;

export const dateLaterThanMaximumError = 'dateLaterThanMaximumError' as const;
export type DateLaterThanMaximumError = typeof dateLaterThanMaximumError;

export const parseToRequiredError = (
  error?: string,
): RequiredError | undefined => (error ? requiredError : undefined);

export const parseFromFormikError = <TError>(
  errors: TError[],
  formikError: string | undefined,
  defaultError: TError,
): TError | undefined => {
  if (formikError === undefined) {
    return undefined;
  }

  return isInUnion<TError>(errors, formikError) ? formikError : defaultError;
};
