import { FormField, Input } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type BankInfosErrors, type SupplierBankInfos } from '../../../models';

type Props = {
  className?: string;
  isOptional?: boolean;
  routingNumber: SupplierBankInfos['routingNumber'];
  error?: BankInfosErrors['routingNumber'];
  onChange(routingNumber: SupplierBankInfos['routingNumber']): void;
};

export const RoutingNumberFormField = ({
  className,
  isOptional,
  routingNumber,
  error,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const getMessageFromError = (
    rawError: BankInfosErrors['routingNumber'],
  ): string => {
    switch (rawError) {
      case 'invalid': {
        return t('submitMyInvoice.fields.routingNumber.error');
      }
      default:
        return t('misc.requiredField');
    }
  };

  return (
    <FormField
      className={className}
      label={t('submitMyInvoice.fields.routingNumber.label')}
      alertMessage={error ? getMessageFromError(error) : undefined}
      hint={isOptional ? t('misc.optional') : undefined}
    >
      <Input
        name="routingNumber"
        value={routingNumber ?? ''}
        placeholder={t('submitMyInvoice.fields.routingNumber.placeholder')}
        pattern="\d{0,9}"
        onChange={(event) => {
          if (event.target.checkValidity()) {
            onChange(event.target.value);
          }
        }}
      />
    </FormField>
  );
};
