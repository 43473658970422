import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useCompany } from 'modules/app/hooks/useCompany';
import type { AppDispatch } from 'modules/app/redux/store';
import { fetchUsers } from 'src/core/actions/users';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';

import { MembersTab } from '../../components/MembersTab';

export const MembersTabContainer = () => {
  const history = useHistory();
  const { id: selectedMemberId }: { id: string } = useParams();
  const company = useCompany();
  const hasTemporaryMembersTableRowQuery = useFeature(
    FEATURES.TMP_MEMBER_TABLE_ROWS_QUERY,
  );

  // this is temporary, and required only for legacy components ie. Invite Modal
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!hasTemporaryMembersTableRowQuery) {
      dispatch(fetchUsers());
    }
  }, []);

  const handleSelectMemberId = (memberId: string | null) => {
    history.push(
      routeFor(routes.COMPANY_MEMBERS.path, {
        company: company.id,
        id: memberId ?? '',
      }),
    );
  };

  return (
    <ErrorBoundary context={{ scope: 'members-tab', team: 'growth' }}>
      <MembersTab
        selectedMemberId={selectedMemberId}
        setSelectedMemberId={handleSelectMemberId}
      />
    </ErrorBoundary>
  );
};
