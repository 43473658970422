import { format } from 'date-fns';

import { useLazyQuery } from 'src/core/api/hooks/useLazyQuery';
import { type LazyState } from 'src/core/api/queryState';

import { type FormValues } from './form';

type FxTransparencyResponse = {
  schemeConversionRate: string;
  ecbConversionRate: string;
  issuerBillingAmount: string;
  ecbReferenceAmount: string;
  issuerVsEcbDiff: string;
  issuerConversionRate: string;
};

type ReshapedFxTransparencyResponse = {
  schemeConversionRate: string;
  ecbConversionRate: string;
  issuerBillingAmount: number;
  ecbReferenceAmount: number;
  issuerVsEcbDiff: string;
  issuerConversionRate: string;
};

export const useGetFxTransparencyQuery = ({
  transactionAmount,
  transactionDate,
  transactionCurrency,
}: FormValues): LazyState<ReshapedFxTransparencyResponse> =>
  useLazyQuery<ReshapedFxTransparencyResponse, FxTransparencyResponse>({
    key: 'fxTransparency',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/cards/fx-transparency`,
      params: {
        transactionAmount,
        transactionDate: format(transactionDate, 'yyyy-MM-dd'),
        transactionCurrency: transactionCurrency.key,
      },
    },
    reshapeData: ({
      schemeConversionRate,
      ecbConversionRate,
      issuerBillingAmount,
      ecbReferenceAmount,
      issuerVsEcbDiff,
      issuerConversionRate,
    }) => ({
      schemeConversionRate,
      ecbConversionRate,
      issuerBillingAmount: Number.parseFloat(issuerBillingAmount),
      ecbReferenceAmount: Number.parseFloat(ecbReferenceAmount),
      issuerVsEcbDiff,
      issuerConversionRate,
    }),
  });
