import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import { upperFirst } from 'common/utils/string';

/**
 * Replaces elements from collection that matches predicate with replacement.
 *
 * @param {Array} collection The collection to replace in
 * @param {*} predicate The condition to determine which elements of collection
 * should be replaced
 * @param {*} replacement The value to use as a replacement of elements of
 * collection that matches the predicate
 */
export const swap = <T, X extends T>(
  collection: T[],
  predicate: (x: T) => boolean,
  replacement: X,
): T[] => {
  return map(collection, (element) => {
    if (predicate(element)) {
      return replacement;
    }

    return element;
  });
};

/**
 * Transform input string to a lowercase string with first letter of each word
 * as uppercase.
 *
 * @param {String} str The string to transform
 * @returns {String} The transformed string
 */
export const uppercaseFirstAllWords = (string_: string): string => {
  const words = string_?.split(/\s+/) ?? [''];
  return map(words, (s) => upperFirst(s.toLowerCase())).join(' ');
};
/**
 * Removes element of `array` at `index`.
 *
 * @param {Array} array The array to remove the element from
 * @param {Number} index The index of the element to remove from `array`
 * @returns {Array} A new array with the element at `index` removed
 */
export const removeAt = <T>(array: T[], index: number): T[] => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

/**
 * Replaces element of `array` at `index` with `replacement`.
 *
 * @param {Array} array The array to replace the element into
 * @param {Number} index The index at which to replace the element in the array
 * @param {*} replacement The replacement to be used
 * @returns {Array} A new array with the given `replacement` replacing the
 * previous element at the `index` position
 */
export const replaceAt = <T>(
  array: T[],
  index: number,
  replacement: T,
): T[] => [...array.slice(0, index), replacement, ...array.slice(index + 1)];

/**
 * Insert `element` at given `index` in `array` and returns the new resulting
 * array.
 *
 * @param {Array} array The array to insert the element into
 * @param {Number} index The index at which to insert the element in the array
 * @param {*} element The element to insert
 * @returns {Array} A new array with the given `element` inserted at the
 * `index` position
 */
export const insertAt = <T>(array: T[], index: number, element: T): T[] => [
  ...array.slice(0, index),
  element,
  ...array.slice(index),
];

/**
 * Performs a deep comparison between two objects and returns an object
 * containing only the key/value pairs (the diff object) for which the values
 * were different (values will be those of the `changed` object).
 *
 * @param {Object} initial Initial object to diff against.
 * @param {Object} changed The (possibly) changed object to diff.
 * @returns {Object} The resulting diff object.
 */
export const diff = <T extends { [key: string]: unknown }>(
  initial: T,
  changed: T,
): Partial<T> =>
  reduce(
    changed,
    (result, value, key) => {
      if (!isEqual(initial[key], value)) {
        return {
          ...result,
          [key]: value,
        };
      }

      return result;
    },
    {} as Partial<T>,
  );
