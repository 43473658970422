type RawPayableCounts = {
  company: {
    toPreparePayables: {
      totalCount: number;
    };
    toExportPayables: {
      totalCount: number;
    };
  };
};

export const reshapePayableCounts = (rawPayableCounts: RawPayableCounts) => {
  return {
    toPrepareCount: rawPayableCounts.company.toPreparePayables.totalCount,
    toExportCount: rawPayableCounts.company.toExportPayables.totalCount,
  };
};
