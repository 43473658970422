import { useAccountingIntegrationStatusQuery } from 'modules/accounting-integration/apis';
import { isUserUnauthorized } from 'modules/bookkeep/integration/status';

export function useIsAccountingIntegrationUserUnauthorized(): boolean {
  const integrationStatusQuery = useAccountingIntegrationStatusQuery();

  if (integrationStatusQuery.status !== 'success') {
    return false;
  }

  return isUserUnauthorized(integrationStatusQuery.data);
}
