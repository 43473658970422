import { getReloadType, HARD_RELOAD, SOFT_RELOAD } from 'src/core/utils/wallet';

import { type Company } from '../../app/hooks/useCompany';

export const getHasWalletWarning = (
  company: Company,
  walletDetails: { monthlyBudget: number },
): boolean => {
  const reloadType = getReloadType(company, walletDetails.monthlyBudget);
  return reloadType === HARD_RELOAD || reloadType === SOFT_RELOAD;
};
