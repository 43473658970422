import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type CustomFieldDeletePayload = {
  fieldValueId: string;
};

export const useDeleteCustomFieldValueMutation = (
  customFieldId: string,
): MutationState<CustomFieldDeletePayload> => {
  return useMutation<CustomFieldDeletePayload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/custom-fields/values',
      method: 'delete',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['useCustomFieldValuesQuery', customFieldId]);
      },
    },
  });
};
