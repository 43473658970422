import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useUsersCountLimitFeatures } from 'modules/members/hooks/useUsersCountLimitFeatures';
import { useTranslation } from "src/core/common/hooks/useTranslation";

import { MemberInviteTipModal } from './MemberInviteTipModal';
import { MemberInviteEmailContentContainer } from '../../containers/MemberInviteEmailContentContainer';
import { MemberInviteLinkContentContainer } from '../../containers/MemberInviteLinkContentContainer';
import { type InvitationResult } from '../../models/invite';
import { MemberInviteFileContent } from '../MemberInviteFileContent';
import { MemberInviteLinkDeleteModal } from '../MemberInviteLinkDeleteModal';

type Props = {
  remainingSeatsForUsersCount: number | undefined;
  handleInviteCreationResult: (result: InvitationResult) => void;
  onClose: () => void;
  openedTabIndex?: number;
};

export const MemberInviteContent = ({
  remainingSeatsForUsersCount,
  handleInviteCreationResult,
  onClose,
  openedTabIndex = 0,
}: Props) => {
  const { t } = useTranslation('global');

  const [isDeleteLinkModalOpen, setIsDeleteLinkModalOpen] = useState(false);
  const [isTipModalOpen, setIsTipModalOpen] = useState(false);

  const hasUsersCountLimitFeature = useUsersCountLimitFeatures();

  return (
    <>
      {hasUsersCountLimitFeature ? (
        <MemberInviteEmailContentContainer
          showTip={() => setIsTipModalOpen(true)}
          remainingSeatsForUsersCount={remainingSeatsForUsersCount}
          handleInviteCreationResult={handleInviteCreationResult}
          onClose={onClose}
        />
      ) : (
        <Tabs defaultTabIndex={openedTabIndex}>
          <TabList isFitted>
            <Tab>{t('members.byEmail')}</Tab>
            <Tab>{t('members.byLink')}</Tab>
            <Tab>{t('members.byFile')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <MemberInviteEmailContentContainer
                showTip={() => setIsTipModalOpen(true)}
                remainingSeatsForUsersCount={remainingSeatsForUsersCount}
                handleInviteCreationResult={handleInviteCreationResult}
                onClose={onClose}
              />
            </TabPanel>
            <TabPanel>
              <MemberInviteLinkContentContainer
                onDelete={() => setIsDeleteLinkModalOpen(true)}
                onClose={onClose}
              />
            </TabPanel>
            <TabPanel>
              <MemberInviteFileContent
                onClose={onClose}
                handleInviteCreationResult={handleInviteCreationResult}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
      <MemberInviteTipModal
        isOpen={isTipModalOpen}
        onClose={() => setIsTipModalOpen(false)}
      />
      <MemberInviteLinkDeleteModal
        isOpen={isDeleteLinkModalOpen}
        onClose={() => {
          setIsDeleteLinkModalOpen(false);
        }}
      />
    </>
  );
};
