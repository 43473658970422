import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type CustomField } from 'modules/bookkeep/prepare-payables/models';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { sortCostCenters } from 'modules/company/cost-centers/utils';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { GET_FIELD_VALUES } from './queries';
import {
  type FieldValues,
  type RawData,
  reshapeFieldValuesResultData,
  type RawCustomField,
  type RawSupplierAccount,
} from './reshaper';
import { getPayableFieldValuesQueryKey } from '../query-key-registry';

/**
 * Query and cache config
 */

export type UpdateFieldValuesQueryCacheUpdate =
  | {
      action: 'ADD_CUSTOM_FIELD_VALUE';
      customFieldId: string;
      value: CustomField['values'][number];
    }
  | {
      action: 'ADD_SUPPLIER_ACCOUNT';
      value: RawSupplierAccount;
    };

export const useUpdatePayableFieldValuesQueryCache = () => {
  const queryClient = useQueryClient();

  return async (
    companyId: string,
    update: UpdateFieldValuesQueryCacheUpdate,
  ): Promise<void> => {
    const existingFieldValues = queryClient.getQueryData<RawData>(
      getPayableFieldValuesQueryKey(companyId),
    );

    if (!existingFieldValues) {
      return;
    }

    let newFieldValues: RawData | undefined;

    if (update.action === 'ADD_CUSTOM_FIELD_VALUE') {
      const customFields = existingFieldValues.company.customFields.map(
        (customField: RawCustomField): RawCustomField => {
          if (customField.id !== update.customFieldId) {
            return customField;
          }

          const existingValue = customField.values.edges.find(
            ({ node }) => node.id === update.value.id,
          );

          const totalCount = existingValue
            ? customField.values.totalCount
            : customField.values.totalCount + 1;

          const edges = customField.values.edges
            .filter(({ node }) => node.id !== update.value.id)
            .concat({ node: update.value });

          return { ...customField, values: { edges, totalCount } };
        },
      );

      newFieldValues = {
        company: { ...existingFieldValues.company, customFields },
      };
    }

    if (update.action === 'ADD_SUPPLIER_ACCOUNT') {
      let totalCount =
        existingFieldValues.company.chartOfAccounts.supplierAccounts.totalCount;

      const supplierAccounts =
        existingFieldValues.company.chartOfAccounts.supplierAccounts.edges
          .filter(({ node: supplierAccount }) => {
            if (supplierAccount.id !== update.value.id) {
              return true;
            }
            totalCount += 1;
            return false;
          })
          .concat({ node: update.value });

      newFieldValues = {
        company: {
          ...existingFieldValues.company,
          chartOfAccounts: {
            supplierAccounts: { edges: supplierAccounts, totalCount },
          },
        },
      };
    }

    await queryClient.setQueryData<RawData>(
      getPayableFieldValuesQueryKey(companyId),
      {
        ...existingFieldValues,
        ...newFieldValues,
      },
    );
  };
};

/**
 * Query hook
 */

export type PayableFieldValuesResponse = RawData;

// Values for teams/customFields/costCenters/supplierAccounts inputs of a payable

export const useGetPayableFieldValues = (): QueryState<FieldValues> => {
  const companyId = useCompanyId();
  const hasCostCentersFeature = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const costCentersQuery = useCostCentersQuery();

  const fieldValuesQuery = useQuery<FieldValues, PayableFieldValuesResponse>({
    key: getPayableFieldValuesQueryKey(companyId),
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_FIELD_VALUES,
    },
    reshapeData: (data) => reshapeFieldValuesResultData(data),
  });

  if (fieldValuesQuery.status === 'error') {
    return fieldValuesQuery;
  }

  if (costCentersQuery.status === 'error') {
    return costCentersQuery;
  }

  if (
    fieldValuesQuery.status === 'loading' ||
    costCentersQuery.status === 'loading'
  ) {
    return { status: 'loading' };
  }

  if (hasCostCentersFeature) {
    fieldValuesQuery.data = <FieldValues>{
      ...fieldValuesQuery.data,
      costCenters: {
        values: sortCostCenters(costCentersQuery.data).map((costCenter) => ({
          key: costCenter.id,
          label: costCenter.name,
        })),
        valuesTotalCount: costCentersQuery.data.length,
      },
    };
  }

  return fieldValuesQuery;
};
