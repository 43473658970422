import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import type { ApiApprovalPolicySavePayload } from './approvalPolicyPayload';
import type { ApiApprovalPolicy } from '../reshapers';

export const useUpdatePolicy = (
  id: string,
): MutationState<ApiApprovalPolicySavePayload, ApiApprovalPolicy> => {
  return useMutation<
    ApiApprovalPolicySavePayload,
    ApiApprovalPolicy,
    ApiApprovalPolicy
  >({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: `/policies/${id}`,
    },
    options: {
      throwOnError: true,
    },
    reshapeData(data) {
      return data;
    },
  });
};
