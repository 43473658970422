import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type DeclarationDataApi } from '../declarationData';

type ResultRaw = {
  missingReceipt: {
    affidavit: {
      signatureUrl: string;
    };
  };
};

type Result = {
  signatureUrl: string;
};

export const useCreateAffidavit = (
  paymentId: string,
): MutationState<DeclarationDataApi, Result> => {
  return useMutation<DeclarationDataApi, Result, ResultRaw>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: `/missing-receipt/${paymentId}`,
    },
    reshapeData: (data) => {
      return data.missingReceipt.affidavit;
    },
  });
};
