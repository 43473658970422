import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

export const BudgetExpenseCategoriesBreakdownListFallback = () => {
  const { t } = useTranslation('global');
  return (
    <Callout variant="alert" title={t('expenseCategories.fallbackTitle')} />
  );
};
