import { addDays, startOfTomorrow } from 'date-fns';
import { type FormikErrors } from 'formik';

import { type CreateApiKeyPayload } from '../hooks';

export type KeyFormData = Omit<CreateApiKeyPayload, 'scope' | 'expiredAt'> & {
  scopes: Set<string>;
  description: string;
  hasExpressedConsent: boolean;
  expiredAt: Date | undefined;
};

export const validateKeyValues = (
  formData: KeyFormData,
): FormikErrors<KeyFormData> | undefined => {
  // TODO translate
  const errors: FormikErrors<KeyFormData> = {};

  if (formData.name.length === 0) {
    errors.name = 'Please give a name to your API access.';
  }

  const tomorrow = startOfTomorrow();

  if (
    !formData.expiredAt ||
    formData.expiredAt.getTime() < tomorrow.getTime() ||
    formData.expiredAt.getTime() > getOneYearAfterNow().getTime()
  ) {
    errors.expiredAt =
      'Please select an expiration date after today and no later than one year from now.';
  }

  if (formData.scopes.size === 0) {
    errors.scopes = 'Please select permissions for at least one scope.';
  }

  if (!formData.hasExpressedConsent) {
    errors.hasExpressedConsent =
      'Please confirm your instructions regarding scopes & permissions.';
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const getOneYearAfterNow = (): Date => addDays(new Date(), 365);
