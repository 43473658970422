import { routes } from "src/core/constants/routes";

export type OperationalOnboardingStatus =
  | 'ongoing'
  | 'skipped'
  | 'done'
  | 'disabled';

export type OperationalOnboardingLocationKey =
  | 'load-funds'
  | 'members'
  | 'control-rules'
  | 'cost-centers'
  | 'payment-request'
  | 'order-physical-card'
  | 'custom-fields'
  | 'accounting-setup';

type DefaultOperationalOnboardingTask = {
  type:
    | 'orderPhysicalCard'
    | 'createPaymentRequest'
    | 'addFunds'
    | 'createExpenseCategory'
    | 'setAccountingIntegration'
    | 'createCostCenter'
    | 'createControlRule'
    | 'reviewControlRule';
  status: OperationalOnboardingStatus;
  isKycDependant?: boolean;
  redirectionLocation: OperationalOnboardingLocationKey;
  disabledReason?: 'kycNotApproved';
};

type InviteMembersOperationalTask = {
  type: 'inviteMembers';
  status: OperationalOnboardingStatus;
  isKycDependant?: boolean;
  redirectionLocation: OperationalOnboardingLocationKey;
  disabledReason?: 'kycNotApproved';
  state: {
    threshold: number;
    count: number;
  };
};

export type OperationalOnboardingTask =
  | DefaultOperationalOnboardingTask
  | InviteMembersOperationalTask;

export type OperationalOnboardingData = {
  tasks: OperationalOnboardingTask[];
};

export function getRouteForOperationalOnboardingTask({
  locationKey,
}: {
  locationKey: OperationalOnboardingLocationKey;
}): string {
  switch (locationKey) {
    case 'load-funds':
      return routes.COMPANY_BANK_FUNDING.path;
    case 'members':
      return routes.COMPANY_MEMBERS.path;
    case 'control-rules':
      return routes.COMPANY_CONTROL_RULES.path;
    case 'cost-centers':
      return routes.COST_CENTERS.path;
    case 'payment-request':
      return routes.REQUESTS.path;
    case 'order-physical-card':
      return routes.CARDS_ORDER.path;
    case 'custom-fields':
      return routes.COMPANY_CATEGORIES.path;
    case 'accounting-setup':
      return routes.COMPANY_ACCOUNTING.path;
    default:
      return routes.HOMEPAGE.path;
  }
}
