import { type PayableId } from 'modules/payable/models';
import { useMutation } from 'src/core/api/hooks/useMutation';

import { useInvalidateBookkeepingNavbarCounters } from '../useInvalidateBookkeepNavbarCounters';

type MarkPayableAsManuallyAccountedError = {
  outcome: 'notMarkedAsManuallyAccounted';
} & (
  | {
      reason: 'expectationFailure';
      error: Error;
    }
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason:
        | 'unauthorized'
        | 'notFound'
        | 'invalidState'
        | 'wrongVersion'
        | 'updateInProgress'
        | 'nothingToUpdate';
    }
);

export type MarkPayableAsManuallyAccountedResponse = {
  outcome: 'markedAsManuallyAccounted';
};

export type MarkPayableAsManuallyAccountedPayload = {
  payableId: PayableId;
  payableVersion: number;
};

/**
 * Mutation
 **/

export const useMarkPayableAsManuallyAccounted = () => {
  const invalidateBookkeepingNavbarCounters =
    useInvalidateBookkeepingNavbarCounters();

  return useMutation<
    MarkPayableAsManuallyAccountedPayload,
    MarkPayableAsManuallyAccountedResponse,
    MarkPayableAsManuallyAccountedResponse,
    MarkPayableAsManuallyAccountedError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/bookkeeping/payable/mark-as-manually-accounted`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data }) => {
        if (data.outcome === 'markedAsManuallyAccounted') {
          invalidateBookkeepingNavbarCounters();
        }
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};
