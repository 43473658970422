import debounce from 'lodash/debounce';
import { useEffect, useCallback, useReducer, type Reducer } from 'react';

import { type QueryError } from 'src/core/api/queryError';
import { type LazyQueryState } from 'src/core/api/queryState';
import { isStringEmpty } from 'src/core/common/utils/isStringEmpty';

import { type Supplier } from './supplier';
import {
  useSearchSuppliersLazyQuery,
  useSearchSuppliersCache,
  type SupplierSearchOptions,
} from './useSearchSuppliersLazyQuery';

type State = LazyQueryState<Supplier[]> & { query: string };
type AddSupplier = (supplier: Supplier) => void;
type HandleSearch = (query: string | undefined) => Promise<void>;
type Action =
  | {
      type: 'preSearch';
    }
  | {
      type: 'startSearch';
      query: string;
      cachedSuppliers: Supplier[] | undefined;
    }
  | { type: 'searchCompleted'; suppliers: Supplier[]; query: string }
  | { type: 'searchFailed'; query: string; error: QueryError<unknown> }
  | { type: 'resetSearch' }
  | { type: 'addSupplier'; supplier: Supplier };

const idleState = {
  status: 'idle',
  query: '',
  suppliers: [] as Supplier[],
} as const;

const getLoadingState = (query: string) =>
  ({
    status: 'loading',
    query,
    suppliers: [] as Supplier[],
  }) as const;

const getSuccessState = (query: string, data: Supplier[]) =>
  ({
    status: 'success',
    data,
    query,
  }) as const;

const getErrorState = (query: string, error: QueryError<unknown>) =>
  ({
    status: 'error',
    query,
    error,
  }) as const;

const initialState = idleState;

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'preSearch': {
      return getLoadingState(state.query);
    }

    case 'startSearch': {
      const { cachedSuppliers, query } = action;
      return cachedSuppliers
        ? getSuccessState(query, cachedSuppliers)
        : getLoadingState(query);
    }

    case 'searchCompleted':
      return state.query === action.query
        ? getSuccessState(action.query, action.suppliers)
        : state;

    case 'searchFailed':
      return state.query === action.query
        ? getErrorState(action.query, action.error)
        : state;

    case 'resetSearch':
      return idleState;

    case 'addSupplier':
      return state.status === 'success'
        ? getSuccessState(state.query, [...state.data, action.supplier])
        : state;

    default:
      return state;
  }
};

export const useSuppliersSearch = (
  supplierSearchOptions: SupplierSearchOptions,
): [LazyQueryState<Supplier[]>, AddSupplier, HandleSearch] => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { query } = state;
  const [searchSuppliers] = useSearchSuppliersLazyQuery(
    query ?? '',
    supplierSearchOptions,
  );
  const getSuppliersInCache = useSearchSuppliersCache(supplierSearchOptions);

  useEffect(() => {
    launchSearch(query);
  }, [query]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const launchSearch = async (query: string): Promise<void> => {
    if (!isStringEmpty(query)) {
      try {
        const suppliers = await searchSuppliers();

        dispatch({
          type: 'searchCompleted',
          query,
          suppliers: suppliers ?? [],
        });
      } catch (error) {
        dispatch({
          type: 'searchFailed',
          query,
          error: error as QueryError<unknown>,
        });
      }
    }
  };

  const debouncedSearch = useCallback(
    debounce((inputQuery: string) => {
      dispatch({
        type: 'startSearch',
        query: inputQuery,
        cachedSuppliers: getSuppliersInCache(inputQuery),
      });
    }, 200),
    [dispatch],
  );

  const handleSearch: HandleSearch = useCallback(
    (inputQuery) => {
      if (inputQuery === undefined) {
        return Promise.resolve();
      }

      if (isStringEmpty(inputQuery)) {
        dispatch({ type: 'resetSearch' });
      } else {
        dispatch({
          type: 'preSearch',
        });

        debouncedSearch(inputQuery);
      }

      return Promise.resolve();
    },
    [dispatch],
  );

  const addSupplier: AddSupplier = useCallback(
    (supplier) => dispatch({ type: 'addSupplier', supplier }),
    [dispatch],
  );

  return [state, addSupplier, handleSearch];
};
