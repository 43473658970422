import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type IntegrationStatus } from '../types';
import { integrationStatusSchema } from '../types/integration-status';

/**
 *
 * TODO: We can improve on this by implementing a separate endpoint for polling the status of a single integration as this endpoint returns all the integrations. https://spendesk.atlassian.net/browse/ECO-291
 */
export const useGetIntegrationsWithPolling = (
  shouldRefetch: boolean,
  callback: (data: IntegrationStatus) => void,
): QueryState<IntegrationStatus> => {
  const { company: companyId } = useParams();
  return useQuery<IntegrationStatus, IntegrationStatus>({
    // Note: we use the same key as the useGetIntegrations hook as we would need to invalidate the cache once the settings change.
    key: ['integrationsStatus', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/integrations/status',
    },
    reshapeData(rawResponse) {
      return integrationStatusSchema.parse(rawResponse);
    },
    isEnabled: shouldRefetch,
    options: {
      onSuccess: (data) => {
        callback(data.rawData);
      },
      refetchInterval: 10_000,
    },
  });
};
