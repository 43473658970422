import { Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import SimplePanelItem, { type SimpleItem } from './SimplePanelItem';

export type ExpandableItem = {
  label: string;
  value?: never;
  deleted?: boolean;
  values: SimpleItem[];
};

const ExpandablePanelItem = ({ item }: { item: ExpandableItem }) => {
  const { t } = useTranslation('global');

  return (
    <li>
      {[
        <>
          <div
            className={cx(
              'w-full',
              item.deleted &&
                'my-xs border-0 border-l border-solid border-warning',
            )}
          >
            <div
              className={cx(
                item.deleted && 'mx-xs',
                'flex flex-row items-center justify-between pr-[12px]',
              )}
            >
              <div className="text-neutral-dark body-m">{item.label}</div>
            </div>
            {item.deleted && (
              <div className="mx-xs flex flex-row items-center space-x-xxs text-warning body-m">
                <Icon name="warning" size="s" />
                <span>
                  {t('common.panelItemsSection.removed', {
                    item: item.label,
                  })}
                </span>
              </div>
            )}
          </div>

          <div
            className={cx(
              'ml-s mt-xs flex flex-col border-0 border-l border-solid border-neutral-light pl-s text-neutral-dark body-m',
              item.deleted ? 'mb-xxs' : 'mb-s',
            )}
          >
            {item.values.map((values) => (
              <SimplePanelItem
                item={values}
                key={values.label}
                className="my-xxs"
              />
            ))}
          </div>
        </>,
      ]}
    </li>
  );
};

export default ExpandablePanelItem;
