import { IconButton, Navigation, Popover } from '@dev-spendesk/grapes';
import { Route, Switch } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routes } from 'src/core/constants/routes';

import styles from './SettingsSubNavigation.module.css';
import {
  getLeftNavigationItems,
  getResponsiveLeftNavigationItems,
  getPlusDropdownItems,
} from './helpers';

export const SettingsSubNavigation = () => {
  const { t } = useTranslation('global');
  const user = useUser();
  const company = useCompany();
  const { width } = useWindowSize();

  const hasAccessToWallet = useFeature(FEATURES.WALLET_ACCESS);
  const isCostCentersFeatureEnabled = useFeature(FEATURES.COST_CENTERS);
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);
  const isBudgetsEnabled = useFeature(FEATURES.BUDGETS);

  const isAccountingSettingsEnabled = useFeature(FEATURES.SETTINGS_ACCOUNTING);
  const isAccountsPayableSettingsEnabled = useFeature(
    FEATURES.SETTINGS_ACCOUNTS_PAYABLE,
  );
  const isAccountingExportsSettingsEnabled = useFeature(
    FEATURES.SETTINGS_ACCOUNTING_EXPORTS,
  );
  const isCostCentersSettingsEnabled = useFeature(
    FEATURES.SETTINGS_COST_CENTERS,
  );

  const features = {
    hasAccessToWallet,
    isCostCentersFeatureEnabled,
    hasCustomFieldsFeature,
    isBudgetsEnabled,
    isAccountingSettingsEnabled,
    isAccountsPayableSettingsEnabled,
    isAccountingExportsSettingsEnabled,
    isCostCentersSettingsEnabled,
  };
  const leftNavigationItems = getLeftNavigationItems({
    user,
    company,
    features,
  });

  const responsiveLeftNavigationItems = getResponsiveLeftNavigationItems({
    width,
    leftNavigationItems,
  });

  const renderPlusDropdown = () => {
    if (width > 1200) {
      return null;
    }

    const itemsInDropdown = getPlusDropdownItems({
      width,
      leftNavigationItems,
    });

    if (itemsInDropdown.length === 0) {
      return null;
    }

    return (
      <Popover
        className={styles.plus}
        placement="bottom-end"
        renderTrigger={(triggerProps) => (
          <IconButton
            variant="borderless"
            iconName="double-caret-right"
            {...triggerProps}
            aria-label={t('misc.seeMore')}
          />
        )}
      >
        {(closeDropdown) => (
          <div className="my-xxs">
            {itemsInDropdown.map((NavigationLink, index) => (
              <NavigationLink
                key={`dropdown-item-${index.toString()}`}
                className="SettingsSubNavigation__plus-item"
                user={user}
                company={company}
                onClick={closeDropdown}
              />
            ))}
          </div>
        )}
      </Popover>
    );
  };

  const links = () => (
    <Navigation
      variant="subNavigation"
      aria-label="Sub navigation"
      className="shrink-0"
      leftNavigationItems={[
        ...responsiveLeftNavigationItems.map((NavigationLink, index) => (
          <NavigationLink
            key={`key-${index.toString()}`}
            user={user}
            company={company}
          />
        )),
        renderPlusDropdown(),
      ]}
    />
  );

  return (
    <Switch>
      <Route path={routes.COMPANY_BILLING_PLAN.path} component={links} />
      <Route path={routes.COMPANY_BILLING_INFORMATION.path} component={links} />
      <Route
        path={routes.COMPANY_GENERAL_SETTINGS_BANK_INFORMATION.path}
        component={links}
      />
      <Route
        path={routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path}
        component={links}
      />
      <Route
        path={routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path}
        component={links}
      />
      <Route
        path={routes.COMPANY_GENERAL_SETTINGS_WIRE_TRANSFER.path}
        component={links}
      />
      <Route
        path={routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path}
        component={links}
      />
      <Route path={routes.COMPANY_MEMBERS.path} component={links} />
      <Route path={routes.COMPANY_TEAMS.path} component={links} />
      <Route path={routes.COMPANY_POLICIES.path} component={links} />
      <Route path={routes.COMPANY_CONTROL_RULES.path} component={links} />
      <Route path={routes.COMPANY_BANK.path} component={links} />
      <Route path={routes.COMPANY_ACCOUNTING.path} component={links} />
      <Route path={routes.COMPANY_INTEGRATIONS.path} component={links} />
      <Route path={routes.COMPANY_ACCOUNTS_PAYABLE.path} component={links} />
      <Route path={routes.COMPANY_EXPORTS.path} component={links} />
      <Route path={routes.COMPANY_CATEGORIES.path} component={links} />
      <Route path={routes.BUDGETS.path} component={links} />
      <Route path={routes.BUDGETARY_EXERCISES.path} component={links} />
      <Route path={routes.COST_CENTERS.path} component={links} />
    </Switch>
  );
};
