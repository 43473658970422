import { Avatar, Icon, TimelineItem, colors } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';

import { SupplierChangeFieldValue } from './SupplierChangeFieldValue';
import { type SupplierDetailsChange } from '../../models';

export type Props = {
  supplierChange: SupplierDetailsChange;
};

export const SupplierChangeTimelineItem = ({ supplierChange }: Props) => {
  const { t } = useTranslation('global');

  const fieldLabel = t(
    `suppliers.latestUpdater.historyModal.fields.${supplierChange.field}`,
  );

  return (
    <div className="text-left">
      <TimelineItem date={new Date(supplierChange.updatedAt)}>
        <section className="flex">
          {supplierChange.updater && (
            <Avatar
              size="l"
              className="ml-xs mr-m"
              src={supplierChange.updater.avatar}
              text={supplierChange.updater.name}
            />
          )}

          {!supplierChange.updater && (
            <div className="w-[88px] pl-xxs">
              <Icon
                name="supplier"
                color={colors.neutralLight}
                size="l"
                className="ml-[20px] mr-m mt-[12px]"
              />
            </div>
          )}

          <div>
            <div>
              {supplierChange.updater &&
                t('suppliers.latestUpdater.historyModal.changeDescription', {
                  field: fieldLabel,
                  username: supplierChange.updater.name,
                })}

              {!supplierChange.updater &&
                t(
                  'suppliers.latestUpdater.historyModal.integrationChangeDescription',
                  {
                    field: fieldLabel,
                  },
                )}
            </div>
            <div className="text-neutral body-s">
              <SupplierChangeFieldValue value={supplierChange.newValue} />
            </div>
            <div className="text-neutral body-s">
              <SupplierChangeFieldValue
                value={supplierChange.oldValue}
                strike
              />
            </div>
          </div>
        </section>
      </TimelineItem>
    </div>
  );
};
