import {
  colors,
  type HighlightIconVariant,
  type IconName,
} from '@dev-spendesk/grapes';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import type { LegacyRequest } from 'modules/requests/types';
import { paymentTypeIconFromRequest } from 'src/core/utils/entities/request';

type RawPayment = {
  id: string;
  missingReceipt?: {
    type: string;
    user?: { fullname: string };
    message: string;
  };
  card?: { type: 'single_purchase' | 'subscription' | 'physical' };
  card_id?: string;
  request?: { type: LegacyRequest['type'] };
  invoices?: { total: number };
  invoice_lost?: boolean;
  invoice_invalid?: boolean;
  completionState?: 'complete' | 'incomplete';
  completionDeadline?: {
    daysLeft: number;
    status: 'late' | 'urgent' | 'onTime';
  };
};

export const shouldShowDeleteInvoiceTooltip = (
  payment: Pick<RawPayment, 'missingReceipt' | 'request'>,
  invoices: unknown[],
) => {
  const { missingReceipt } = payment;

  return (
    (isSinglePurchase(payment) ||
      isPhysicalCardPurchase(payment) ||
      isSubscription(payment)) &&
    invoices.length === 1 &&
    !missingReceipt
  );
};

const isSinglePurchase = (payment: Pick<RawPayment, 'request'>) =>
  payment.request?.type === 'single_purchase';

const isSubscription = (payment: Pick<RawPayment, 'request'>) =>
  payment.request?.type === 'subscription';

const isPhysicalCardPurchase = (
  payment: Pick<RawPayment, 'request' | 'card'>,
) => payment.card?.type === 'physical';

const isMileageAllowance = (payment: Pick<RawPayment, 'request'>) =>
  payment.request?.type === 'mileage_allowance';

export const isPerDiemAllowance = (payment: Pick<RawPayment, 'request'>) =>
  payment.request?.type === 'per_diem_allowance';

const hasInvoices = (payment: Pick<RawPayment, 'invoices'>) => {
  const { invoices } = payment;
  return invoices && invoices.total > 0;
};

const hasLostInvoice = (
  payment: Pick<RawPayment, 'invoices' | 'invoice_lost'>,
) => {
  const { invoice_lost } = payment;
  const paymentHasInvoices = hasInvoices(payment);
  return !paymentHasInvoices && invoice_lost;
};

const hasMissingReceipt = (
  payment: Pick<RawPayment, 'invoices' | 'invoice_lost'>,
) => {
  const { invoice_lost } = payment;
  const paymentHasInvoices = hasInvoices(payment);
  return !paymentHasInvoices && !invoice_lost;
};

export const hasInvalidInvoice = (
  payment: Pick<RawPayment, 'invoice_invalid'>,
) => payment.invoice_invalid;

const isPaymentLate = (
  payment: Pick<RawPayment, 'completionDeadline' | 'completionState'>,
) =>
  payment.completionState === 'incomplete' &&
  payment.completionDeadline?.status === 'late';

const isPaymentUrgent = (
  payment: Pick<RawPayment, 'completionDeadline' | 'completionState'>,
) =>
  payment.completionState === 'incomplete' &&
  payment.completionDeadline?.status === 'urgent';

const isPaymentOnTime = (
  payment: Pick<RawPayment, 'completionDeadline' | 'completionState'>,
) =>
  payment.completionState === 'incomplete' &&
  payment.completionDeadline?.status === 'onTime';

export const getInvoiceIconFromPayment = (
  payment: RawPayment,
  translator: TGlobalFunctionTyped,
): { icon: IconName; variant: HighlightIconVariant; tooltip: string } => {
  if (isPaymentLate(payment)) {
    return {
      icon: 'receipt-cross',
      variant: 'alert',
      tooltip: translator('payments.tooltipStatus.alert'),
    };
  }

  if (isPaymentOnTime(payment)) {
    return {
      icon: 'receipt-cross',
      variant: 'info',
      tooltip: translator('payments.tooltipStatus.warning', {
        count: payment.completionDeadline?.daysLeft,
      }),
    };
  }

  if (isPaymentUrgent(payment)) {
    return {
      icon: 'receipt-cross',
      variant: 'warning',
      tooltip: translator('payments.tooltipStatus.warning', {
        count: payment.completionDeadline?.daysLeft,
      }),
    };
  }

  if (isMileageAllowance(payment) || isPerDiemAllowance(payment)) {
    return {
      icon: 'receipt-checked',
      variant: 'success',
      tooltip: translator('payments.tooltipStatus.provided'),
    };
  }

  if (hasInvalidInvoice(payment)) {
    return {
      icon: 'receipt-cross',
      variant: 'warning',
      tooltip: translator('payments.tooltipStatus.invalid'),
    };
  }

  // "lost invoice" is legacy status
  // we keep it for backward compatibility on old payments
  if (hasLostInvoice(payment)) {
    return {
      icon: 'receipt-question-mark',
      variant: 'neutral',
      tooltip: translator('payments.tooltipStatus.lost'),
    };
  }

  if (hasMissingReceipt(payment)) {
    return {
      icon: 'receipt-cross' as const,
      variant: 'alert',
      tooltip: translator('payments.tooltipStatus.missing'),
    };
  }

  return {
    icon: 'receipt-checked',
    variant: 'success',
    tooltip: translator('payments.tooltipStatus.provided'),
  };
};

export const getPaymentTypeIcon = (
  payment: Omit<RawPayment, 'request'> & {
    request?: { type: LegacyRequest['type'] | 'plastic_card' };
  },
  translator: TGlobalFunctionTyped,
) => {
  const icon = paymentTypeIconFromRequest(payment);

  const base: { icon: IconName; color: string } = {
    icon: icon ?? ('plastic-card' as const),
    color: colors.neutralLight,
  };

  switch (payment.request?.type) {
    case 'single_purchase':
      return { ...base, tooltip: translator('requests.types.single_purchase') };
    case 'subscription':
      return {
        ...base,
        tooltip: translator('requests.types.subscription'),
      };
    case 'subscription_increase':
      return {
        ...base,
        tooltip: translator('requests.types.subscription_increase'),
      };
    case 'card_load':
      return { ...base, tooltip: translator('requests.types.card_load') };
    case 'expense':
      return {
        ...base,
        tooltip: translator('requests.types.expense'),
      };
    case 'invoice':
      return { ...base, tooltip: translator('requests.types.invoice') };
    case 'mileage_allowance':
      return {
        ...base,
        tooltip: translator('requests.types.mileage_allowance'),
      };
    case 'per_diem_allowance':
      return {
        ...base,
        tooltip: translator('requests.types.per_diem_allowance'),
      };
    case 'purchase_order':
      return { ...base, tooltip: translator('requests.types.purchase_order') };
    case 'credit_note':
      return { ...base, tooltip: translator('requests.types.credit_note') };
    // eslint-disable-next-line unicorn/no-useless-switch-case
    case 'plastic_card':
    default:
      if (!payment.card_id) {
        return { ...base, tooltip: translator('requests.types.invoice') };
      }

      return { ...base, tooltip: translator('requests.types.plastic_card') };
  }
};

export const getLocalizedMissingReceiptReason = (
  payment: Pick<RawPayment, 'missingReceipt'>,
  translator: TGlobalFunctionTyped,
) => {
  const { missingReceipt } = payment;

  if (!missingReceipt || missingReceipt.type === 'affidavit') {
    return null;
  }

  const fullName = missingReceipt.user?.fullname;
  if (!fullName) {
    return missingReceipt.type === 'refund'
      ? translator('payments.missingInvoiceIsRefundAutomatic')
      : null;
  }

  if (missingReceipt.message?.length > 0) {
    return translator('payments.missingInvoiceOther', {
      fullName,
      reason: missingReceipt.message,
    });
  }

  switch (missingReceipt.type) {
    case 'lost':
      return translator('payments.lostInvoiceSubTitle', { fullName });
    case 'noReceipt':
      return translator('payments.missingInvoiceNoReceiptGivenGeneric', {
        fullName,
      });
    case 'refund':
      return translator('payments.missingInvoiceIsRefund', { fullName });
    default:
      return translator('payments.missingInvoiceNoReason');
  }
};
