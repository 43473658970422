import { type User } from 'modules/app/hooks/useUser';

import { type Duplicate } from '../types';

interface CustomField {
  id: string;
  type: string; // FIXME: 'list' | 'boolean' ?
  perms_add_values: string[] | null;
  is_required: boolean;
}

export const getHasUserPermissionForCustomField = (
  customField: CustomField,
  userRoles: {
    is_admin?: boolean;
    is_controller?: boolean;
    is_requester?: boolean;
  },
): boolean => {
  return (
    customField.perms_add_values?.some((permCode) => {
      if (permCode === '03_006_2' && userRoles.is_admin) {
        return true;
      }
      if (permCode === '07_004_0' && userRoles.is_controller) {
        return true;
      }
      return permCode === '05_002_0' && userRoles.is_requester;
    }) ?? false
  );
};

export const userCanCreateValue = (
  user: User,
  company: { id: string },
  customField: CustomField,
): boolean => {
  const userRoles = user.data_by_company[company.id];
  return Boolean(
    customField.perms_add_values &&
      customField.perms_add_values.length &&
      getHasUserPermissionForCustomField(customField, userRoles),
  );
};

export const isCustomFieldValid = (
  user: User,
  company: { id: string },
  customFields: {
    [id: string]: { key?: string; name: string };
  },
  cf: CustomField,
): boolean => {
  const cfValue = customFields[cf.id];
  if (!cfValue) {
    return !cf.is_required;
  }
  if (cfValue.key) {
    return true;
  }
  if (cf.type === 'boolean') {
    return true;
  }
  if (!cfValue.key && !cfValue.name) {
    return !cf.is_required;
  }
  return userCanCreateValue(user, company, cf);
};

export const areCustomFieldsValid = (
  user: User,
  company: { id: string },
  eligibleCustomFields: CustomField[],
  customFields: {
    [id: string]: { key: string; name: string };
  },
): boolean => {
  return eligibleCustomFields?.every((cf) =>
    isCustomFieldValid(user, company, customFields, cf),
  );
};

export const getHasUserNoTeams = (user: User, companyId: string): boolean => {
  return user.data_by_company[companyId].groups_ids?.length < 2;
};

export const REQUEST_TYPES = {
  ExpenseClaim: 'expense',
  MileageAllowance: 'mileage_allowance',
  Invoice: 'invoice',
  SinglePurchase: 'single_purchase',
  Subscription: 'subscription',
  CardLoad: 'card_load',
  SubscriptionIncrease: 'subscription_increase',
  CreditNote: 'credit_note',
};

type RequestType = keyof typeof REQUEST_TYPES;

export const filterRequestsByType = <T extends { type: RequestType }>(
  requests: T[],
  type: string,
): T[] => {
  if (!requests) {
    return [];
  }
  return requests.filter((request) => request.type === type);
};

export const hasDuplicate = (
  request: { duplicates?: Duplicate[] } | null,
): boolean => {
  return Boolean(request?.duplicates?.length);
};

export const isDraftRequest = (request: { state?: string } | null): boolean => {
  return request?.state === 'draft';
};

export const getUserTeams = <T extends { id: string }>(
  user: {
    data_by_company: {
      [companyId: string]: {
        groups_ids: string[];
      };
    };
  },
  teams: T[],
  companyId: string,
): T[] => {
  // FIXME: this code is sometimes called without teams
  if (!teams) {
    return [];
  }

  const teamIds = user?.data_by_company[companyId]?.groups_ids ?? [];
  const teamsById = new Map(teams.map((team) => [team.id, team]));
  return teamIds
    .map((teamId) => teamsById.get(teamId))
    .filter((team): team is T => team !== undefined);
};

export const getUserVisibleTeams = <T extends { id: string }>(
  user: {
    is_admin: boolean;
    data_by_company: {
      [companyId: string]: {
        groups_ids: string[];
      };
    };
  },
  teams: T[] = [],
  companyId: string,
  isCostCentersFeatureEnabled: boolean,
): T[] => {
  if (user.is_admin && !isCostCentersFeatureEnabled) {
    return teams;
  }
  return getUserTeams(user, teams, companyId);
};

export const hasApprovalStarted = (
  approvalState:
    | {
        rules: { steps: { state: string }[] }[];
      }
    | undefined,
): boolean => {
  if (!approvalState) {
    return true;
  }

  return approvalState.rules.some((rule) => {
    return rule.steps.some((step) => step.state === 'approved');
  });
};
