import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import {
  type CreateSupplierAPIPayload,
  transformDraftSupplierToAPIPayload,
} from './createSupplierTransformer';
import { type DraftSupplier, type SupplierDetails } from '../../../models';
import {
  type RawSupplier,
  transformSupplier,
  useSetSupplierQueryData,
} from '../useFetchSupplier';

export type CreateSupplierError =
  | {
      reason: 'invalidParameters';
      error: string;
    }
  | {
      reason: 'mimeTypeNotSupported';
      allowedMimeTypes: string[];
    }
  | {
      reason: 'invalidBankInfos';
      errors?: string[];
      invalidFields: object;
    }
  | {
      reason: 'invalidFile';
      errorMessage: string;
    }
  | {
      reason: 'notUploadableSupplierBankDetail';
      errorMessage: string;
    };

type Props = {
  onSupplierCreationSuccess?(supplier: SupplierDetails): void;
};

export const useCreateSupplier = ({
  onSupplierCreationSuccess,
}: Props = {}): MutationState<
  DraftSupplier,
  SupplierDetails,
  CreateSupplierError
> => {
  const setSupplierQueryData = useSetSupplierQueryData();
  const [createSupplierMutation, ...rest] = useMutation<
    CreateSupplierAPIPayload,
    SupplierDetails,
    RawSupplier,
    CreateSupplierError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/invoice_suppliers`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data, rawData }) => {
        setSupplierQueryData(rawData);
        if (onSupplierCreationSuccess) {
          onSupplierCreationSuccess(data);
        }
      },
    },
    reshapeData(data) {
      return transformSupplier(data);
    },
  });

  return [
    (draftSupplier: DraftSupplier) =>
      createSupplierMutation(transformDraftSupplierToAPIPayload(draftSupplier)),
    ...rest,
  ];
};
