import React from 'react';

import { CenteredLayout } from 'src/core/common/components/layout';

import CustomFieldsContainer from '../CustomFieldsContainer';

export const LegacyCustomFieldsPage = () => {
  return (
    <div className="page__container">
      <CenteredLayout className="CustomFieldsContainer" width="medium">
        <CustomFieldsContainer />
      </CenteredLayout>
    </div>
  );
};
