import { type DefaultEmployeeAccount } from '../../employeeAccount';

type RawDefaultEmployeeAccount = {
  id: string;
  name: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  isDefault: true;
  isArchived: boolean;
};

export const reshapeDefaultEmployeeAccounts = (
  defaultEmployeeAccounts: RawDefaultEmployeeAccount[],
): DefaultEmployeeAccount[] =>
  defaultEmployeeAccounts.map((defaultEmployeeAccount) => ({
    id: defaultEmployeeAccount.id,
    generalAccountCode: defaultEmployeeAccount.generalAccountCode,
    auxiliaryAccountCode: defaultEmployeeAccount.auxiliaryAccountCode,
    isDefault: defaultEmployeeAccount.isDefault,
    isArchived: defaultEmployeeAccount.isDefault,
    name: defaultEmployeeAccount.name,
  }));

type RawUserEmployeeAccount = {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  isDefault: boolean;
};

type UserEmployeeAccount = {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  isDefault: boolean;
};

export const reshapeEmployeeAccount = (
  employeeAccounts: RawUserEmployeeAccount[],
): UserEmployeeAccount[] =>
  employeeAccounts.map((item) => ({
    id: item.id,
    generalAccountCode: item.generalAccountCode,
    auxiliaryAccountCode: item.auxiliaryAccountCode ?? undefined,
    isDefault: item.isDefault,
  }));
