import {
  SkeletonText,
  SkeletonTable,
  SkeletonCheckbox,
} from '@dev-spendesk/grapes';
import React from 'react';

export const MembersPaginatedTableLoader = () => {
  return (
    <SkeletonTable
      columns={[
        {
          cell: (
            <div className="flex w-full gap-s">
              <SkeletonCheckbox />
              <SkeletonText width="280px" />
            </div>
          ),
          header: (
            <div className="flex w-full gap-s">
              <SkeletonCheckbox />
              <SkeletonText width="280px" />
            </div>
          ),
        },
        {
          cell: <SkeletonText width="130px" />,
          header: <SkeletonText width="130px" />,
        },
        {
          cell: <SkeletonText width="130px" />,
          header: <SkeletonText width="130px" />,
        },
        {
          cell: <SkeletonText width="130px" />,
          header: <SkeletonText width="130px" />,
        },
        {
          cell: <SkeletonText width="130px" />,
          header: <SkeletonText width="130px" />,
        },
      ]}
      numberOfRows={5}
      withHeader
    />
  );
};
