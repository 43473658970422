import { useSelector } from 'react-redux';

import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { getSpendingPolicy } from 'modules/profile/utils';
import { getSelf as getUser } from "src/core/selectors/users";

import { type SpendingPolicy } from '../models/rules';

export const useSpendingPolicy = (): SpendingPolicy => {
  const user = useSelector(getUser);
  const companyCurrency = useCompanyCurrency();
  return getSpendingPolicy(user, companyCurrency);
};
