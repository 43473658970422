import * as R from '@dev-spendesk/general-type-helpers/Result';

import { type AccountPayable } from 'modules/bookkeep';
import { type CreateOrUpdateAccountPayableResponse } from 'src/core/modules/accounting-integration/apis';

export type AccountPayableCreationModalState =
  | {
      kind: 'form';
      createdAccount: AccountPayable;
      error:
        | 'codeAlreadyExists'
        | 'invalidPattern'
        | 'required'
        | 'integrationValidationFailed'
        | undefined;
    }
  | {
      kind: 'closed';
      editedAccount?: AccountPayable;
      error?: undefined;
    };

export const getCreateOrUpdateAccountPayableError = (
  createOrUpdateAccountPayableResult: CreateOrUpdateAccountPayableResponse,
): { error: AccountPayableCreationModalState['error'] } | object => {
  if (R.isFailure(createOrUpdateAccountPayableResult)) {
    switch (createOrUpdateAccountPayableResult.error.reason) {
      case 'codeAlreadyExists':
        return { error: createOrUpdateAccountPayableResult.error.reason };
      default:
      // Fall through
    }
  }

  return { error: undefined };
};
