import { Navigation } from '@dev-spendesk/grapes';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser, type User } from 'modules/app/hooks/useUser';
import FEATURES from 'src/core/constants/features';

import {
  type AccountingStats,
  getLeftLinks,
  getRightLinks,
} from '../../utils/navigation';

type Props = {
  accountingStats: AccountingStats | null;
};

const isAccountingWorkflowVisible = (user: User) => {
  return user.is_account_owner || user.is_controller;
};

export const PaymentsSubNavigation = ({ accountingStats }: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();
  const user = useUser();

  const isPayableFeatureEnabled = useFeature(FEATURES.BOOKKEEP_PAYABLES);

  if (!isPayableFeatureEnabled || !isAccountingWorkflowVisible(user)) {
    return null;
  }

  return (
    <Navigation
      className="shrink-0"
      variant="subNavigation"
      aria-label="Sub navigation"
      leftNavigationItems={getLeftLinks({
        accountingStats,
        company,
        translator: t,
      })}
      rightNavigationItems={getRightLinks({
        company,
        translator: t,
      })}
    />
  );
};
