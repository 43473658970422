import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
  };
  user: {
    is_account_owner: boolean;
    is_controller: boolean;
    is_group_admin: boolean;
    is_requester: boolean;
    has_plastic_card: boolean;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
};

export const PaymentsLink = ({
  company,
  user,
  className,
  isInDropdown,
  onClick,
}: Props) => {
  const { t } = useTranslation('global');
  const { pathname: currentPath } = useLocation();

  return (
    <NavigationLink
      data-marketing="top-menu_payments"
      key="nav_payments"
      className={className}
      onClick={onClick}
      path={routeFor(routes.PAYMENTS_ALL.path, {
        company: company.id,
      })}
      text={
        user.is_controller ? t('misc.bookkeepTitle') : t('misc.payment_plural')
      }
      isActive={Boolean(
        matchPath(currentPath, {
          path: [
            routes.PAYMENTS_ALL.path,
            routes.PAYABLES_ALL.path,
            routes.EXPENSE_INBOX.path,
          ],
        }),
      )}
      isInDropdown={isInDropdown}
    />
  );
};
