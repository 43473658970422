import { SideNavigation, NavigationItem } from '@dev-spendesk/grapes';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';

import { useShouldDisplayBankInformation } from '../../hooks/useShouldDisplayBankInformation';

type Props = {
  companyId: string;
};

type GeneralSettingsPageNavLink = {
  id: string;
  text: string;
  url: string;
  match: (loc: string, url: string) => boolean;
};

export const GeneralSettingsPageNav = ({ companyId }: Props) => {
  const { t } = useTranslation('global');
  const { isAccountOwner } = useUserRoles();
  const location = useLocation();
  const hasControlOnSpendingMethodsFeature = useFeature(
    FEATURES.CONTROL_ON_SPENDING_METHODS,
  );
  const isWireTransferForExpenseClaimsEnabled = useFeature(
    FEATURES.WIRE_TRANSFER_FOR_EXPENSE_CLAIMS,
  );
  const shouldDisplayBankInformation = useShouldDisplayBankInformation();

  const match = (loc: string, url: string): boolean => loc === url;

  const links: GeneralSettingsPageNavLink[] = [];

  if (shouldDisplayBankInformation) {
    links.push({
      id: 'bank-information',
      text: t('generalSettings.navLinks.bankInformation'),
      url: routeFor(routes.COMPANY_GENERAL_SETTINGS_BANK_INFORMATION.path, {
        company: companyId,
      }),
      match,
    });
  }

  links.push({
    id: 'company-information',
    text: t('generalSettings.navLinks.companyInformation'),
    url: routeFor(routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path, {
      company: companyId,
    }),
    match,
  });

  if (hasControlOnSpendingMethodsFeature) {
    links.push({
      id: 'payment-methods',
      text: t('generalSettings.navLinks.paymentMethods'),
      url: routeFor(routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path, {
        company: companyId,
      }),
      match,
    });
  }

  if (
    !hasControlOnSpendingMethodsFeature &&
    isWireTransferForExpenseClaimsEnabled &&
    isAccountOwner
  ) {
    links.push({
      id: 'wire-transfer',
      text: t('generalSettings.navLinks.wireTransfer'),
      url: routeFor(routes.COMPANY_GENERAL_SETTINGS_WIRE_TRANSFER.path, {
        company: companyId,
      }),
      match,
    });
  }

  links.push({
    id: 'notifications',
    text: t('generalSettings.navLinks.notifications'),
    url: routeFor(routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path, {
      company: companyId,
    }),
    match,
  });

  return (
    <SideNavigation>
      {links.map((link) => {
        return (
          <NavigationItem
            component={NavLink}
            text={link.text}
            isActive={link.match(location.pathname, link.url)}
            key={link.id}
            to={link.url}
          />
        );
      })}
    </SideNavigation>
  );
};
