import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  endpointParams: {
    groupId: string;
  };
};

export const useDeleteTeamMutation = (memberId: string) => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: ({ groupId }) => `/groups/${groupId}/users/${memberId}`,
      method: 'delete',
    },
    options: {
      throwOnError: true,
    },
  });
};
