import { gql } from 'graphql-tag';

export const GET_TAX_ACCOUNTS = gql`
  query GetTaxAccounts($companyId: ID!, $filter: TaxAccountFilter) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        taxAccounts(filter: $filter) {
          edges {
            node {
              id
              name
              isArchived
              __typename
              ... on VatAccount {
                code
                rate
                isDefault
              }
            }
          }
        }
      }
    }
  }
`;
