import { Button, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import ApiAccessStateTag from './ApiAccessStateTag';
import ConsentForm from './ConsentForm';
import ScopesForm from './ScopesForm';
import { ErrorState } from '../../../../../../common/components/ErrorState';
import { InputCopy } from '../../../../../../common/components/InputCopy';
import { useParams } from '../../../../../../common/hooks/useParams';
import { useTranslation } from '../../../../../../common/hooks/useTranslation';
import { routeFor, routes } from '../../../../../../constants/routes';
import { type PublicApiScopeListEntry } from '../../../../domain/public-api/scopes';
import { type ApiKeyListItem, isKeyActive } from '../domain';
import { useDisableApiKey, useGetApiKeys, useGetUsersById } from '../hooks';

type Props = {
  scopes: PublicApiScopeListEntry[];
  onGoToDuplicateForm: (sourceApiKey: ApiKeyListItem) => void;
};

const ApiAccessView = ({ scopes, onGoToDuplicateForm }: Props) => {
  const { id } = useParams(routes.COMPANY_INTEGRATIONS_API_ACCESS_VIEW.path);
  const { t, activeLanguage } = useTranslation('global');
  const dateFormat = new Intl.DateTimeFormat(activeLanguage, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const dateTimeFormat = new Intl.DateTimeFormat(activeLanguage, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  const companyId = useCompanyId();
  const history = useHistory();
  const keysResult = useGetApiKeys();
  const usersResult = useGetUsersById(
    keysResult.status === 'success'
      ? [
          ...new Set(
            keysResult.data.flatMap(({ creatorId, lastUpdaterId }) => [
              creatorId,
              lastUpdaterId,
            ]),
          ),
        ]
      : [],
  );
  const [disableKey, disableApiKeyMutationState] = useDisableApiKey();
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);

  if (keysResult.status === 'loading' || usersResult.status === 'loading') {
    // TODO skeleton
    return <></>;
  }

  const key =
    keysResult.status === 'success'
      ? keysResult.data.find(({ apiId }) => apiId === id)
      : undefined;

  if (keysResult.status === 'error' || usersResult.status === 'error' || !key) {
    return <ErrorState title={t('misc.errorState.title')} />;
  }

  // TODO opti: memoize if needed
  const usersMap = new Map(usersResult.data.map((user) => [user.id, user]));
  const creator = usersMap.get(key.creatorId);
  const lastUpdater = usersMap.get(key.lastUpdaterId);
  const keyScopes = new Set(key.scope.split(' '));

  return (
    <>
      <Button
        variant="ghost"
        text={t('publicApi.flowApiKey.main.back')}
        iconName="caret-left"
        iconPosition="left"
        fit="content"
        onClick={() => {
          history.push(
            routeFor(routes.COMPANY_INTEGRATIONS_API_ACCESS_MANAGEMENT.path, {
              company: companyId,
            }),
          );
        }}
      />

      <div className="flex justify-between">
        <div>
          <h2 className="mt-s title-xl">
            <span className="mr-xs">{key.name}</span>{' '}
            <ApiAccessStateTag apiAccess={key} />
          </h2>
          <div className="my-s flex flex-row items-center text-neutral-dark">
            <p className="mr-xs">{t('publicApi.flowApiKey.main.expires')}:</p>
            <p className="mr-s text-complementary">
              {dateFormat.format(key.expiredAt)}
            </p>
            <p className="mr-xs">{t('publicApi.flowApiKey.main.apiId')}:</p>
            <div className="w-[360px]">
              <InputCopy value={key.apiId} />
            </div>
          </div>
          <div className="my-s flex flex-row items-center text-neutral-dark">
            <p className="mr-xs">{t('publicApi.flowApiKey.main.createdBy')}:</p>
            <p className="mr-s text-complementary">
              {creator?.fullname ?? 'Unknown'}
            </p>
            <p className="mr-xs">{t('publicApi.flowApiKey.main.createdAt')}:</p>
            <p className="mr-s text-complementary">
              {dateTimeFormat.format(key.createdAt)}
            </p>
          </div>
          <div className="my-s flex flex-row items-center text-neutral-dark">
            <p className="mr-xs">
              {t('publicApi.flowApiKey.main.lastUpdatedBy')}:
            </p>
            <p className="mr-s text-complementary">
              {lastUpdater?.fullname ?? 'Unknown'}
            </p>
            <p className="mr-xs">
              {t('publicApi.flowApiKey.main.lastUpdatedAt')}:
            </p>
            <p className="mr-s text-complementary">
              {dateTimeFormat.format(key.updatedAt)}
            </p>
          </div>
          {key.description && (
            <p className="text-neutral-dark body-m">{key.description}</p>
          )}
        </div>
        <div>
          {isKeyActive(key) && (
            <Button
              text="Revoke"
              variant="alert"
              onClick={() => setIsRevokeModalOpen(true)}
              className="mr-s"
              data-testid="api-key-action-revoke"
            />
          )}
          <Button
            text="Duplicate"
            onClick={() => onGoToDuplicateForm(key)}
            data-testid="api-key-action-duplicate"
          />
        </div>
      </div>
      <div className="w-100 mt-s flex flex-col rounded-xs bg-white p-m elevation-10">
        <ScopesForm
          mode="view"
          scopes={scopes}
          selectedScopes={keyScopes}
          onScopeChange={() => {}}
        />
        <ConsentForm
          mode="view"
          hasExpressedConsent
          onConsentChange={() => {}}
        />
      </div>

      <Modal
        isOpen={isRevokeModalOpen}
        title={t('publicApi.flowApiKey.revoke.title')}
        subtitle={t('publicApi.flowApiKey.revoke.description')}
        iconName="warning"
        iconVariant="alert"
        onClose={() => setIsRevokeModalOpen(false)}
        actions={[
          <Button
            key="cancel"
            text={t('misc.cancel')}
            variant="contrasted"
            onClick={() => setIsRevokeModalOpen(false)}
            disabled={disableApiKeyMutationState.status === 'loading'}
          />,
          <Button
            key="disable"
            text={t('publicApi.flowApiKey.revoke.button')}
            variant="alert"
            disabled={disableApiKeyMutationState.status === 'loading'}
            onClick={async () => {
              await disableKey({
                endpointParams: { id: key.apiId },
              });
              setIsRevokeModalOpen(false);
            }}
          />,
        ]}
      />
    </>
  );
};

export default ApiAccessView;
