import * as R from '@dev-spendesk/general-type-helpers/Result';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';

import { useCostCenterData } from './useCostCenterData';
import { useCustomFieldsData } from './useCustomFieldsData';
import { useTeamData } from './useTeamData';
import {
  type BudgetTrackingTraitModel,
  BudgetTrackingValidationModel,
} from '../../models/budgetTracking';
import { type CustomFieldEligibleType } from '../../models/customFieldAssociation';

type Params = {
  disableErrorNotifications?: boolean;
};

/* eslint-disable unicorn/no-await-expression-member */
export const useBudgetTrackingValidation = <
  T extends BudgetTrackingTraitModel.Trait,
>({ disableErrorNotifications = false }: Params = {}) => {
  const costCenterDataGetter = useCostCenterData();
  const teamDataGetter = useTeamData();
  const customFieldDataWrapper = useCustomFieldsData();

  const { dangerNotif } = useNotifications();
  const { t } = useTranslation('global');

  return (customFieldEligibleType: CustomFieldEligibleType) =>
    async (
      values: T,
    ): Promise<
      R.Result<
        { reason: 'FetchError' },
        BudgetTrackingValidationModel.BudgetTrackingFormikErrors | undefined
      >
    > => {
      try {
        const isCostCenterRequired =
          costCenterDataGetter.isCostCenterEnabled &&
          (await costCenterDataGetter.costCenterQueryPromise())
            .isCostCenterRequired;
        const isTeamRequired =
          teamDataGetter.isTeamEnabled &&
          (await teamDataGetter.teamsQueryPromise()).isTeamRequired;
        const customFieldDataGetter =
          customFieldDataWrapper.isCustomFieldsEnabled &&
          (await customFieldDataWrapper.customFieldsQueryPromise());
        const requiredCustomFields = customFieldDataGetter
          ? customFieldDataGetter(
              customFieldEligibleType,
              values.costCenterId,
            ).getRequiredCustomFields(values.teamId)
          : [];

        const validationContext: BudgetTrackingValidationModel.ValidationContext =
          {
            isCostCenterRequired,
            isTeamRequired,
            requiredCustomFields,
          };

        return R.toSuccess(
          BudgetTrackingValidationModel.validate(values, validationContext),
        );
      } catch {
        if (!disableErrorNotifications) {
          dangerNotif(t('budget.formValidation.fetchError'));
        }
        return R.toFailure({ reason: 'FetchError' });
      }
    };
};
