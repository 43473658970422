import { HighlightIcon } from '@dev-spendesk/grapes';
import React from 'react';

import { DownloadAppIllustration } from 'common/components/DownloadAppIllustration';
import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { PendingKycEmptyStateWithCta } from 'modules/kyc/components/PendingKycEmptyState/PendingKycEmptyStateWithCta';

import styles from './PendingKycRequestsEmtpyState.module.css';

export const PendingKycRequestsEmptyState = () => {
  const { t } = useTranslation('global');
  const user = useUser();

  if (user.is_account_owner) {
    return <PendingKycEmptyStateWithCta />;
  }

  return (
    <div className="m-auto mt-m flex w-1/2 flex-col items-center gap-m">
      <HighlightIcon name="clock" variant="primary" />
      <div className="text-center">
        <h2 className="title-l">
          {t('requests.notAvailableForRequesterYet.title')}
        </h2>
        <p className="body-m">
          {t('requests.notAvailableForRequesterYet.message')}
        </p>
      </div>
      <DownloadAppIllustration className={styles.illustrationContainer} />
    </div>
  );
};
