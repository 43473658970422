import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { DownloadAppBadges } from 'common/components/DownloadAppBadges';
import { useTranslation } from 'common/hooks/useTranslation';
import CaroleCooper from 'modules/StrongCustomerAuthentication/images/carole-cooper.svg';
import QrCode from 'modules/StrongCustomerAuthentication/images/qr-code.svg';
import ScanToDownload from 'modules/StrongCustomerAuthentication/images/scan-to-download.svg';
import './MfaXeroActivationModal.css';

interface Props {
  isOpen: boolean;
  isUserEnrolledError: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const MfaXeroActivationModal = ({
  isOpen,
  isUserEnrolledError,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation('global');

  const renderCalloutIfError = () => {
    return (
      isUserEnrolledError && (
        <Callout
          className="MfaXeroActivationModal__content__error"
          title={t('bookkeep.integrations.xero.modal.mfa.activateMfa.error')}
          variant="alert"
        />
      )
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      iconName="lock"
      iconVariant="primary"
      title={t('bookkeep.integrations.xero.modal.mfa.activateMfa.title')}
      actions={[
        <Button
          key="haveSetUpApp"
          text={t(
            'bookkeep.integrations.xero.modal.mfa.activateMfa.haveSetUpApp',
          )}
          variant="primary"
          onClick={onConfirm}
        />,
      ]}
      onClose={onClose}
    >
      <div className="MfaXeroActivationModal__content">
        <p>
          {`1. ${t(
            'bookkeep.integrations.xero.modal.mfa.activateMfa.desc.step1',
          )}`}
        </p>

        <p>{`2. ${t(
          'bookkeep.integrations.xero.modal.mfa.activateMfa.desc.step2',
        )} `}</p>

        <p>
          {`3. ${t(
            'bookkeep.integrations.xero.modal.mfa.activateMfa.desc.step3',
          )}`}
        </p>

        <div className="MfaXeroActivationModal__content__imgs">
          <img
            src={ScanToDownload}
            className="MfaXeroActivationModal__content__imgs__scan-to-download"
            alt="Scan to download"
          />
          <img
            src={QrCode}
            className="MfaXeroActivationModal__content__imgs__qr-code"
            alt="QR code"
          />
          <DownloadAppBadges className="MfaXeroActivationModal__content__imgs__badges" />
          <img
            src={CaroleCooper}
            className="MfaXeroActivationModal__content__imgs__carole-cooper"
            alt="Working woman holding phone"
          />
        </div>

        {renderCalloutIfError()}
      </div>
    </Modal>
  );
};
