import omit from 'lodash/omit';

import { isNilOrEmpty } from 'common/utils/extended-lodash';
import { type DeepPartial } from 'src/core/common/utils/deep-partial';
import { type CurrenciesKey } from 'src/core/config/money';
import {
  BankFields,
  getBankInfoFieldsCombo,
} from 'src/core/utils/bankInfoFormats';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type BankDetailsSuggestion } from './bankDetailsSuggestion';

export type SupplierId = string;

export interface Supplier {
  id: SupplierId;
  name: string;
  thumbnailUrl?: string;
}

export interface SupplierDetails extends Supplier {
  address: SupplierAddress;
  isSupplierValidated: boolean;
  isArchived: boolean;
  isInvoicePayable: boolean;
  legalInfos: SupplierLegalInfos;
  bankInfos?: SupplierBankInfos;
  latestUpdater?: User;
  updatedAt: string | null;
}

export interface SupplierDetailsChange {
  updatedAt: string;
  updater?: User;
  field: SupplierDetailsField;
  oldValue: string;
  newValue: string;
}

export interface User {
  userId: string;
  name: string;
  lastName: string;
  email: string;
  avatar: string;
}

export interface SupplierBankInfos {
  accountHolderName?: string;
  accountCode?: string;
  accountNumber?: string;
  bankCountry?: string;
  bic?: string;
  iban?: string;
  routingNumber?: string;
  sortCode?: string;
}

export interface SupplierLegalInfos {
  legalName: string;
  registrationNumber?: string;
  vatNumber?: string;
}

export interface SupplierAddress {
  address?: string;
  city?: string;
  country?: string;
  zipcode?: string;
}

export type SupplierDetailsField =
  | keyof SupplierBankInfos
  | keyof SupplierLegalInfos
  | keyof SupplierAddress;

export interface DraftSupplier {
  id?: SupplierId;
  name: string;
  address: SupplierAddress;
  bankInfos?: SupplierBankInfos;
  legalInfos: SupplierLegalInfos;
  isArchived: boolean;
  isSupplierValidated: boolean;
}

export type DraftSupplierUpdate = DeepPartial<DraftSupplier>;

export type SupplierBankFields = Partial<
  Record<BankFields, { isOptional: boolean }>
>;

export const getSupplierBankFields =
  (company: { country: string; currency: CurrenciesKey }) =>
  (supplierBankInfos: SupplierBankInfos): SupplierBankFields => {
    const supplierBankCountry = supplierBankInfos.bankCountry ?? '';
    const bankFields = getBankInfoFieldsCombo(
      company.country,
      supplierBankCountry,
      company.currency,
    );

    const shouldFillBankInfoCompletely =
      getShouldFillBankInfoCompletely(supplierBankInfos);
    const alwaysOptionalFields = getAlwaysOptionalFields(
      company.country,
      supplierBankCountry,
    );

    return bankFields.reduce(
      (
        supplierBankFields: SupplierBankFields,
        bankField: BankFields,
      ): SupplierBankFields => {
        supplierBankFields[bankField] = {
          isOptional:
            !shouldFillBankInfoCompletely ||
            alwaysOptionalFields.includes(bankField),
        };

        return supplierBankFields;
      },
      {},
    );
  };

const getAlwaysOptionalFields = (
  companyCountry: string,
  supplierBankCountry: string,
): BankFields[] => {
  if (companyCountry === 'US' && supplierBankCountry === 'US') {
    return [BankFields.BicSwift];
  }
  return [];
};

export type BankDetailsSuggestionMatchValue =
  | 'match'
  | 'mismatch'
  | 'missingBankDetailsSuggestion'
  | 'missingSupplierBankInfos'
  | 'missingSupplierBankInfosAndBankDetailsSuggestion';

export type BankDetailsSuggestionMatch = Partial<
  Record<keyof SupplierBankInfos, BankDetailsSuggestionMatchValue>
>;

export const isUpdateMode = (
  supplier: DraftSupplier,
): supplier is WithRequired<DraftSupplier, 'id'> => Boolean(supplier.id);

export const buildDraftSupplier = ({
  supplierName,
}: {
  supplierName: string;
}): DraftSupplier => ({
  name: supplierName,
  address: {},
  bankInfos: {
    accountHolderName: supplierName,
  },
  legalInfos: {
    legalName: supplierName,
  },
  isArchived: false,
  isSupplierValidated: false,
});

export const getSupplierBankInfosFromSuggestion = (
  supplierBankInfos: SupplierBankInfos,
  bankDetailsSuggestion: BankDetailsSuggestion,
  company: { country: string; currency: CurrenciesKey },
): SupplierBankInfos => {
  if (!shouldUseBankInfosSuggestion(supplierBankInfos, bankDetailsSuggestion)) {
    return supplierBankInfos;
  }

  const bankCountry =
    supplierBankInfos.bankCountry ?? bankDetailsSuggestion.country ?? undefined;
  const bankFields = getBankInfoFieldsCombo(
    company.country,
    bankCountry ?? '',
    company.currency,
  );
  const ibanSuggestion = bankFields.includes(BankFields.Iban)
    ? bankDetailsSuggestion.iban?.text
    : undefined;
  const bicSuggestion = bankFields.includes(BankFields.BicSwift)
    ? bankDetailsSuggestion.bic?.text
    : undefined;

  return {
    ...supplierBankInfos,
    bankCountry,
    iban: supplierBankInfos.iban ?? ibanSuggestion,
    bic: supplierBankInfos.bic ?? bicSuggestion,
  };
};

const shouldUseBankInfosSuggestion = (
  supplierBankInfos: SupplierBankInfos,
  bankDetailsSuggestion: BankDetailsSuggestion,
) => {
  // the bank fields depend on the bank country, so we don't use the suggestion if the country is different
  const isSupplierBankCountryMatchingSuggestion =
    supplierBankInfos.bankCountry === bankDetailsSuggestion.country;
  const shouldOverrideSupplierBankCountry =
    !supplierBankInfos.bankCountry && bankDetailsSuggestion.country;

  return !bankDetailsSuggestion.country
    ? false
    : isSupplierBankCountryMatchingSuggestion ||
        shouldOverrideSupplierBankCountry;
};

export const changeSupplierBankInfoBankCountry = (
  supplierBankInfos: SupplierBankInfos,
  bankCountry: SupplierBankInfos['bankCountry'],
): SupplierBankInfos =>
  // when switching country, we reset all fields as they are dependant on the country
  supplierBankInfos.bankCountry !== bankCountry
    ? {
        accountHolderName: supplierBankInfos.accountHolderName,
        bankCountry,
      }
    : supplierBankInfos;

export const getShouldFillBankInfoCompletely = (
  supplierBankInfos: SupplierBankInfos,
): boolean => {
  return Object.values(
    omit(supplierBankInfos, ['accountHolderName', 'bankCountry']),
  ).some((bankField) => !isNilOrEmpty(bankField));
};

export const compareBankDetailsSuggestionToSupplierBankInfo = (
  bankDetailsSuggestion: BankDetailsSuggestion,
  supplierBankInfos: SupplierBankInfos,
  company: { country: string; currency: CurrenciesKey },
): BankDetailsSuggestionMatch => {
  if (!supplierBankInfos.bankCountry) {
    // we can't proceed without the bank country because we need it to determine the relevant bank fields
    return {
      bankCountry: compareBankDetailsSuggestionFieldToSupplierBankInfoField(
        bankDetailsSuggestion,
        supplierBankInfos,
        'bankCountry',
      ),
    };
  }

  const bankFields = getBankInfoFieldsCombo(
    company.country,
    supplierBankInfos.bankCountry,
    company.currency,
  );

  return bankFields.reduce(
    (
      bankFieldsMatch: BankDetailsSuggestionMatch,
      bankField: BankFields,
    ): BankDetailsSuggestionMatch => {
      const field = mapBankFieldToSupplierBankInfoField(bankField);
      bankFieldsMatch[field] =
        compareBankDetailsSuggestionFieldToSupplierBankInfoField(
          bankDetailsSuggestion,
          supplierBankInfos,
          field,
        );
      return bankFieldsMatch;
    },
    {},
  );
};

const compareBankDetailsSuggestionFieldToSupplierBankInfoField = (
  bankDetailsSuggestion: BankDetailsSuggestion,
  supplierBankInfos: SupplierBankInfos,
  field: keyof SupplierBankInfos,
): BankDetailsSuggestionMatchValue => {
  const supplierBankInfosFieldValue = supplierBankInfos[field];
  const bankDetailsSuggestionFieldValue = getBankDetailsSuggestionFieldValue(
    bankDetailsSuggestion,
    field,
  );

  if (!supplierBankInfosFieldValue && !bankDetailsSuggestionFieldValue) {
    return 'missingSupplierBankInfosAndBankDetailsSuggestion';
  }

  if (!supplierBankInfosFieldValue) {
    return 'missingSupplierBankInfos';
  }

  if (!bankDetailsSuggestionFieldValue) {
    return 'missingBankDetailsSuggestion';
  }

  if (
    sanitizeBankInfoValue(supplierBankInfosFieldValue) ===
    sanitizeBankInfoValue(bankDetailsSuggestionFieldValue)
  ) {
    return 'match';
  }

  return 'mismatch';
};

const mapBankFieldToSupplierBankInfoField = (
  bankField: BankFields,
): keyof SupplierBankInfos => {
  switch (bankField) {
    case BankFields.AccountCode:
      return 'accountCode';

    case BankFields.AccountNumber:
      return 'accountNumber';

    case BankFields.BicSwift:
      return 'bic';

    case BankFields.Iban:
      return 'iban';

    case BankFields.RoutingNumber:
      return 'routingNumber';

    case BankFields.SortCode:
      return 'sortCode';

    case BankFields.AccountHolderName:
      return 'accountHolderName';

    default:
      rejectUnexpectedValue('BankField type unexpected', bankField);
  }
};

const getBankDetailsSuggestionFieldValue = (
  bankDetailsSuggestion: BankDetailsSuggestion,
  bankField: keyof SupplierBankInfos,
): string | undefined => {
  switch (bankField) {
    case 'bankCountry':
      return bankDetailsSuggestion.country ?? undefined;

    case 'bic':
    case 'iban':
      return bankDetailsSuggestion[bankField]?.text;

    default:
      // we only have bank suggestion for Iban & Bic
      return undefined;
  }
};

const sanitizeBankInfoValue = (
  value: string | undefined,
): string | undefined => {
  // remove the spaces
  return value ? value.replaceAll(/\s/g, '') : value;
};
