import identity from 'lodash/identity';

import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type Payload = {
  scheduledPaymentIds: string[];
};

export type Error = unknown;

export const useSuppressWarningsForExpenseBeneficiaryQuery = (): MutationState<
  Payload,
  object,
  Error
> => {
  return useMutation<Payload, object, object, Error>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/scheduled_payments/suppress-warnings-for-beneficiaries`,
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
    reshapeData: identity,
  });
};
