import { useParams as useParamsBase, useRouteMatch } from 'react-router-dom';

import { type ParseUrlParams, type RoutesUrl } from 'src/core/constants/routes';

/**
 * overwrites react-router's useParams and correctly type the response
 *
 * @example
 * import { useParams } from 'common/hooks/useParams';
 *
 * const params = useParams(routes.REQUESTS.path)
 */
export function useParams<RouteUrl extends RoutesUrl>(
  route: RouteUrl,
): ParseUrlParams<RouteUrl> {
  if (!useRouteMatch(route) && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(
      'the path specified to useParams doesnt match the current route, meaning the return type may be wrong',
      route,
    );
  }
  return useParamsBase();
}
