import { FormField, TextInput, Input } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { type Dispatch, type SetStateAction } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from '../ModalState';
import styles from '../TaxAccountLocalOnlySection.module.css';

interface Props {
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
}

export const VatAddEditForm = ({ formState, setFormState }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {formState.kind === 'addVat' && (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.vatAccountsTable.accountName',
            )}
            className={styles.formField}
          >
            <TextInput
              value={formState.name}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.target.value,
                  inputChanged: true,
                })
              }
            />
          </FormField>
          <FormField
            label={t('bookkeep.integrations.settings.vatAccountsTable.vatRate')}
            className={styles.formField}
            alertMessage={
              !formState.inputChanged && formState.error
                ? t(
                    'bookkeep.integrations.settings.vatAccountsTable.vatRateError',
                  )
                : undefined
            }
          >
            <Input
              value={formState.rate}
              type="number"
              rightAddon={
                <div className={classNames(styles.rateRightAddon, 'body-m')}>
                  %
                </div>
              }
              min={0}
              max={100}
              onChange={(e) => {
                const rateAsNumber = e.target.valueAsNumber;
                setFormState({
                  ...formState,
                  error:
                    rateAsNumber < 0 || rateAsNumber > 100
                      ? 'invalidRate'
                      : undefined,
                  rate: e.target.value,
                  inputChanged: true,
                });
              }}
            />
          </FormField>
          <FormField
            label={t('bookkeep.integrations.settings.vatAccountsTable.vatCode')}
            className={styles.formField}
          >
            <TextInput
              value={formState.code}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  code: e.target.value,
                  inputChanged: true,
                })
              }
            />
          </FormField>
        </>
      )}
    </>
  );
};
