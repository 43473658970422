import { PanelSection, type PanelSectionProps } from '@dev-spendesk/grapes';
import React, { type ReactElement, type ReactNode } from 'react';

import styles from './PanelPreviewsSection.module.css';

type Props = DistributiveOmit<PanelSectionProps, 'children'> & {
  previews: ReactElement[];
  noPreviewContent: string;
  children?: ReactNode;
  beforePreviews?: ReactNode;
};

export function PanelPreviewsSection({
  previews,
  noPreviewContent,
  children,
  beforePreviews,
  ...panelSectionProps
}: Props) {
  return (
    <PanelSection {...panelSectionProps}>
      <>
        {beforePreviews ? <div className="mb-xs">{beforePreviews}</div> : null}

        {previews.length === 0 ? (
          <div className="rounded border border-dashed border-neutral-light p-xs text-center text-neutral-dark body-m">
            {noPreviewContent}
          </div>
        ) : (
          <div className={styles.panelPreviewsSectionContent}>{previews}</div>
        )}
        {children}
      </>
    </PanelSection>
  );
}
