import {
  addYears,
  differenceInYears,
  isAfter,
  isBefore,
  isValid,
} from 'date-fns';

import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';

import type { AllocationPeriod } from '../../containers/AllocationPeriodContainer/AllocationPeriodContainer';

const MIN_STARTING_YEAR = new Date(2000, 1, 1);
const MAX_YEARS_FROM_NOW = 30;
const MAX_YEARS_DIFFERENCE = 10;

export const validate = (
  values: AllocationPeriod,
  t: TGlobalFunctionTyped,
): { date?: string; startDate?: string; endDate?: string } => {
  if (values.type === 'date') {
    return getErrorsForDateType(values.date, t);
  }

  const errors: ReturnType<typeof validate> = {};
  if (!values.startDate || !isValid(values.startDate)) {
    errors.startDate = t('budget.allocationPeriod.validation.errorStartDate');
  }
  if (!values.endDate || !isValid(values.endDate)) {
    errors.endDate = t('budget.allocationPeriod.validation.errorEndDate');
  }

  if (isBefore(values.startDate, MIN_STARTING_YEAR)) {
    errors.startDate = t('budget.allocationPeriod.validation.tooFarAwayPast');
  }

  if (isBefore(values.endDate, MIN_STARTING_YEAR)) {
    errors.endDate = t('budget.allocationPeriod.validation.tooFarAwayPast');
  }

  if (isAfter(values.startDate, addYears(new Date(), MAX_YEARS_FROM_NOW))) {
    errors.startDate = t('budget.allocationPeriod.validation.tooFarAwayFuture');
  }

  if (isAfter(values.endDate, addYears(new Date(), MAX_YEARS_FROM_NOW))) {
    errors.endDate = t('budget.allocationPeriod.validation.tooFarAwayFuture');
  }

  if (
    differenceInYears(values.endDate, values.startDate) >= MAX_YEARS_DIFFERENCE
  ) {
    errors.startDate = t('budget.allocationPeriod.validation.moreThan10Years');
  }

  return errors;
};

const getErrorsForDateType = (
  dateValue: Date,
  t: TGlobalFunctionTyped,
): ReturnType<typeof validate> => {
  let errorMessage: string | undefined;

  if (!dateValue || !isValid(dateValue)) {
    errorMessage = t('budget.allocationPeriod.validation.errorStartDate');
  } else if (isBefore(dateValue, MIN_STARTING_YEAR)) {
    errorMessage = t('budget.allocationPeriod.validation.tooFarAwayPast');
  } else if (isAfter(dateValue, addYears(new Date(), MAX_YEARS_FROM_NOW))) {
    errorMessage = t('budget.allocationPeriod.validation.tooFarAwayFuture');
  }

  return errorMessage ? { date: errorMessage } : {};
};
