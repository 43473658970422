import Fuse from 'fuse.js';

import { type OptionRoute } from './useCreateRoutes';

const LIMIT = 8;

export const filterRoutes = (
  routesToSearch: OptionRoute[],
  searchTerm: string | undefined,
  companyId: string,
): OptionRoute[] => {
  if (!searchTerm) {
    return routesToSearch.slice(0, LIMIT);
  }

  const fuse = new Fuse(
    routesToSearch.map((routes) => ({
      ...routes,
      urlWithoutCompanyId: routes.url.replace(`app/${companyId}`, ''),
    })),
    {
      keys: ['label', 'category', 'urlWithoutCompanyId', 'hiddenTags'],
      threshold: 0.1,
      location: 0,
      distance: 2900,
      useExtendedSearch: true,
      ignoreLocation: true,
      minMatchCharLength: 1,
    },
  );
  return fuse.search(searchTerm, { limit: LIMIT }).map((result) => {
    const { urlWithoutCompanyId, ...route } = result.item;
    return route;
  });
};
