import type { CostCenter } from 'modules/budgets/models/costCenter';

import { type ExpenseCategory } from '../../expenseCategory';

export const listCostCentersWithoutExpenseCategories = (
  costCenters: CostCenter[],
  expenseCategories: ExpenseCategory[],
): CostCenter[] => {
  const costCentersWithECs: { [key: CostCenter['id']]: boolean } = {};
  for (const expenseCategory of expenseCategories) {
    // An expense category that has an empty costcenterIds is applied to all cost centers, so in this case there are no cost centers without expense categories
    if (expenseCategory.costCenterIds.length === 0) {
      return [];
    }

    for (const costCenterId of expenseCategory.costCenterIds) {
      costCentersWithECs[costCenterId] = true;
    }
  }

  // Remove the cost centers that have expense categories
  return costCenters.filter((costCenter) => !costCentersWithECs[costCenter.id]);
};
