import { type ReactElement } from 'react';

import { type QueryError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';

type QuerySuspenseProps<TResult, TError> = {
  queryState: QueryState<TResult, TError>;
  fallback: (error: QueryError<TError>) => ReactElement | null;
  loading: ReactElement | null;
  children: (data: TResult) => ReactElement | null;
};

export const QuerySuspense = <TResult, TError>({
  queryState,
  fallback,
  loading,
  children,
}: QuerySuspenseProps<TResult, TError>) => {
  if (queryState.status === 'loading') {
    return loading;
  }

  if (queryState.status === 'error') {
    return fallback(queryState.error);
  }

  return children(queryState.data);
};
