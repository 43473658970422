import React from 'react';

import { IntegrationBenefitsSkeleton } from '../IntegrationBenefits';

export const IntegrationBenefitsBoxSkeleton = () => {
  return (
    <div className="box flex flex-col gap-s border-0 bg-white">
      <IntegrationBenefitsSkeleton />
    </div>
  );
};
