import { Button, Callout, Modal, TextArea } from '@dev-spendesk/grapes';
import React, { type ReactNode, useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  title: string;
  description: string;
  placeholder?: string;
  actionText: string;
  isOpen: boolean;
  withRejectionMessage?: boolean;
  isLoading?: boolean;
  errorMessage?: ReactNode;
  onClose: () => void;
  onConfirm: (text: string) => void;
};

export const DangerConfirmationModal = ({
  title,
  description,
  placeholder,
  actionText,
  isOpen,
  errorMessage,
  withRejectionMessage = true,
  isLoading = false,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation('global');
  const [text, setText] = useState<string>('');
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const isRejectionMessageEmpty = !text;

  const handleTextChange = (newText: string) => {
    setText(newText);
    setIsErrorDisplayed(false);
  };

  const handleReject = () => {
    if (!withRejectionMessage || !isRejectionMessageEmpty) {
      onConfirm(text);
    } else {
      setIsErrorDisplayed(true);
    }
  };

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('misc.cancel')}
          variant="secondary"
          isDisabled={isLoading}
        />,
        <Button
          key="confirm"
          text={actionText}
          variant="alert"
          onClick={handleReject}
          isDisabled={isLoading}
        />,
      ]}
      iconName="cross"
      title={title}
      iconVariant="alert"
      isOpen={isOpen}
    >
      <p>{description}</p>
      {withRejectionMessage && (
        <TextArea
          placeholder={
            placeholder || t('paymentRejection.modal.defaultPlaceholder')
          }
          rows={4}
          value={text}
          isInvalid={isErrorDisplayed}
          onChange={(e) => handleTextChange(e.target.value)}
          className="mt-m"
        />
      )}

      {isErrorDisplayed && (
        <Callout
          variant="alert"
          title={t('paymentRejection.modal.errorText')}
        />
      )}

      {errorMessage}
    </Modal>
  );
};
