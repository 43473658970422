import { type WireTransferPayableEntity } from 'modules/company/general-settings/models/wireTransfer';

import { type WireTransferActivationStatus } from '../../general-settings/models';
import { type BankInformations } from '../companyBilling';

// Company billing info
const SAVE_COMPANY_BILLING_INFO_FAILURE = 'SAVE_COMPANY_BILLING_INFO_FAILURE';

export const SAVE_COMPANY_INFO_SUCCESS = 'SAVE_COMPANY_INFO_SUCCESS';
type SaveCompanyInfoSuccessAction = Readonly<{
  type: typeof SAVE_COMPANY_INFO_SUCCESS;
  payload: object;
}>;

// Fetch bank info settings for SMI
export const FETCH_BANK_INFO_LOADING = 'FETCH_BANK_INFO_LOADING';
type FetchBankInfoLoadingAction = Readonly<{
  type: typeof FETCH_BANK_INFO_LOADING;
}>;

export const FETCH_BANK_INFO_FAILURE = 'FETCH_BANK_INFO_FAILURE';
type FetchBankInfoFailureAction = Readonly<{
  type: typeof SAVE_COMPANY_BILLING_INFO_FAILURE;
}>;

export const FETCH_BANK_INFO_SUCCESS = 'FETCH_BANK_INFO_SUCCESS';
type FetchBankInfoSuccessAction = Readonly<{
  type: typeof FETCH_BANK_INFO_SUCCESS;
  payload: BankInformations;
}>;

export const FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_SUCCESS =
  'FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_SUCCESS';
type FetchWireTransferActivationStatusSuccessAction = Readonly<{
  type: typeof FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_SUCCESS;
  payload: {
    forExpenseClaims: WireTransferActivationStatus;
    forInvoices: WireTransferActivationStatus;
  };
}>;

export const UPDATE_WIRE_TRANSFER_ACTIVATION_STATUS =
  'UPDATE_WIRE_TRANSFER_ACTIVATION_STATUS';
type UpdateWireTransferActivationStatusAction = Readonly<{
  type: typeof UPDATE_WIRE_TRANSFER_ACTIVATION_STATUS;
  payload: {
    wireTransferPayableEntity: WireTransferPayableEntity;
    status: WireTransferActivationStatus;
  };
}>;

export const FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_LOADING =
  'FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_LOADING';
type FetchWireTransferActivationStatusLoadingAction = Readonly<{
  type: typeof FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_LOADING;
}>;

export const FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_ERROR =
  'FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_ERROR';
type FetchWireTransferActivationStatusErrorAction = Readonly<{
  type: typeof FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_ERROR;
}>;

export type BillingActions =
  | FetchBankInfoSuccessAction
  | FetchBankInfoLoadingAction
  | FetchBankInfoFailureAction
  | FetchWireTransferActivationStatusSuccessAction
  | FetchWireTransferActivationStatusLoadingAction
  | FetchWireTransferActivationStatusErrorAction
  | SaveCompanyInfoSuccessAction
  | UpdateWireTransferActivationStatusAction;
