import { DATE_FORMAT } from '@dev-spendesk/grapes';

import { useHasAccountingIntegrationCapability } from 'modules/accounting-integration/apis';
import { useIsEditablePayable } from 'modules/payable/hooks/api/useIsEditablePayable';
import { useIsLockedPayable } from 'modules/payable/hooks/api/useIsLockedPayable';
import { PanelItemsSection } from 'src/core/common/components/Panel';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { PayableDetailsEditSection } from './PayableDetailsEditSection/PayableDetailsEditSection';
import { usePayableDetailsEditForm } from './hooks/usePayableDetailsEditForm';
import { EditAfterExportEditCallout } from '../../components/PayablePanel/EditAfterExportEditCallout';
import {
  formatMemberName,
  formatSpendingAllocationPeriod,
} from '../../models/member';
import { isSpanishDPRPayable } from '../../models/payable';
import type { Payable } from '../PayablePanelContainer';

type Props = {
  payable: Payable;
};

export const PayableDetailsSectionContainer = ({ payable }: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const hasTeamsFeature = useFeature(FEATURES.TEAMS);
  const hasAllocationPeriodFeature = useFeature(FEATURES.ALLOCATION_PERIOD);
  const hasAccountingDateEnabled = useFeature(FEATURES.TMP_ACCOUNTING_DATE);
  const hasEditPayablesAfterExportFeature = useFeature(
    FEATURES.TMP_EDIT_PAYABLES_AFTER_EXPORT,
  );
  const hasSpanishDPR = isSpanishDPRPayable(payable);
  const hasInvoiceNumberFeature =
    useHasAccountingIntegrationCapability('invoiceNumber');

  const isLocked = useIsLockedPayable(payable);
  const isEditable = useIsEditablePayable(payable);

  const { formik, handleOnSectionCancelEdit, handleOnSectionSaveEdit } =
    usePayableDetailsEditForm({
      payable,
      context: {
        hasInvoiceNumberFeature,
        hasSpanishDPR,
        hasAccountingDateEnabled,
      },
    });

  const formatDate = (date: Date) => localeFormat(date, DATE_FORMAT.SHORT);
  const items = [
    {
      label: t('payables.panel.requester'),
      value: payable.member ? formatMemberName(payable.member, t) : '-',
      type: 'requester',
    },
    ...(payable.type === 'claimedBill' &&
    !hasSpanishDPR &&
    payable.supplier?.name
      ? [
          {
            label: t('misc.supplier'),
            value: payable.supplier?.name,
            type: 'supplier',
          },
        ]
      : []),
    ...getRenderItemsWhenInvoiceNumberForAllPayables(payable, t),
    ...(payable.creationDate
      ? [
          {
            label: computeInvoiceDateLabel(payable, t),
            value: formatDate(payable.creationDate),
            type: 'creationDate',
          },
        ]
      : []),
    ...(hasAccountingDateEnabled
      ? [
          {
            label: t('payables.panel.accountingDate'),
            value: payable.accountingDate
              ? formatDate(payable.accountingDate)
              : '-',
            type: 'accountingDate',
          },
        ]
      : []),

    ...(hasTeamsFeature
      ? [
          {
            label: t('payables.panel.team'),
            value: payable.team?.name ?? '-',
            type: 'team',
          },
        ]
      : []),
    ...(hasAllocationPeriodFeature &&
    payable.type === 'invoicePurchase' &&
    payable.subtype !== 'creditNote'
      ? [
          {
            label: t('payables.panel.allocationPeriod'),
            value: formatSpendingAllocationPeriod(
              payable.spendingAllocationPeriod,
              formatDate,
            ),
            type: 'allocationPeriod',
          },
        ]
      : []),
  ];

  const isEditableProps =
    hasEditPayablesAfterExportFeature && isEditable && !isLocked
      ? {
          isEditable: true as const,
          cancelTranslation: t('misc.cancel'),
          saveTranslation: t('misc.saveChanges'),
          editSection: (
            <>
              <EditAfterExportEditCallout payable={payable} />
              <PayableDetailsEditSection
                payable={payable}
                {...formik}
                items={[...items]}
                context={{
                  hasAccountingDateEnabled,
                  hasSpanishDPR,
                  hasInvoiceNumberFeature,
                }}
              />
            </>
          ),
          onCancel: handleOnSectionCancelEdit,
          onSave: handleOnSectionSaveEdit,
        }
      : { isEditable: false as const };
  return (
    <PanelItemsSection
      title={t('payables.panel.detailsSection.title')}
      items={items}
      {...isEditableProps}
    />
  );
};

function getRenderItemsWhenInvoiceNumberForAllPayables(
  payable: Payable,
  t: TGlobalFunctionTyped,
) {
  return [
    ...(payable.documentaryEvidence?.type === 'invoice'
      ? [
          {
            label: computeInvoiceNumberLabel(payable, t),
            value: payable.documentaryEvidence?.invoiceNumber || '-',
            type: 'invoiceNumber',
          },
        ]
      : []),
    ...(payable.documentaryEvidence?.type === 'creditNote' &&
    payable.documentaryEvidence?.creditNoteNumber
      ? [
          {
            label: computeInvoiceNumberLabel(payable, t),
            value: payable.documentaryEvidence?.creditNoteNumber || '-',
            type: 'invoiceNumber',
          },
        ]
      : []),
  ];
}

/**
 * we intend to display a label for the field based on the payable
 * type and the type of the documentary evidence, as follows:
 *
 * For invoices -> Invoice number
 * For credit notes -> Credit note number
 * For expense claims, cards (all types), card refunds -> Receipt number
 *
 * Since we always need to display something for the field, we default to
 * 'Invoice number' for any other case.
 */
function computeInvoiceNumberLabel(payable: Payable, t: TGlobalFunctionTyped) {
  if (payable.documentaryEvidence?.type === 'creditNote') {
    return t('payables.panel.creditNoteNumber');
  }

  switch (payable.type) {
    case 'claimedBill':
    case 'cardPurchase':
    case 'reversal':
      return t('payables.panel.receiptNumberLabel');
    default:
      return t('payables.panel.invoiceNumber');
  }
}

function computeInvoiceDateLabel(payable: Payable, t: TGlobalFunctionTyped) {
  if (payable.documentaryEvidence?.type === 'creditNote') {
    return t('payables.panel.creditNoteDate');
  }

  switch (payable.type) {
    case 'claimedBill':
    case 'cardPurchase':
    case 'reversal':
      return t('payables.panel.receiptDate');
    default:
      return t('payables.panel.invoiceDate');
  }
}
