import React from 'react';

import { ModalError } from 'common/components/ModalError';
import { ModalLoader } from 'common/components/ModalLoader';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { type FormOnSubmit } from 'common/utils/forms';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';

import {
  MembersBulkEditModal,
  type MembersBulkUpdateForm,
  NULL_VALUE,
} from '../../components/MembersBulkEditModal/MembersBulkEditModal';
import { useControlRulesQuery, usePoliciesQuery } from '../../hooks';
import { useBulkUnassignControlRuleMutation } from '../../hooks/useBulkUnassignControlRule';
import { useBulkUpdateApprovalPolicyMutation } from '../../hooks/useBulkUpdateApprovalPolicy';
import { useBulkUpdateControlRuleMutation } from '../../hooks/useBulkUpdateControlRule';
import { useBulkUpdateCostCenterMutation } from '../../hooks/useBulkUpdateCostCenterMutation';
import { type Member } from '../../models/member';

function isDifferent(currentMemberValue: unknown, newFormValue: unknown) {
  if (
    typeof currentMemberValue === 'undefined' &&
    newFormValue === NULL_VALUE
  ) {
    return false;
  }
  return currentMemberValue !== newFormValue;
}

type Props = {
  onClose(): void;
  members: Member[];
};
export function MembersBulkEditModalContainer({ onClose, members }: Props) {
  const { t } = useTranslation('global');

  const [updateCostCenter] = useBulkUpdateCostCenterMutation();
  const [updateApprovalPolicy] = useBulkUpdateApprovalPolicyMutation();
  const [updateControlRule] = useBulkUpdateControlRuleMutation();
  const [unassignControlRule] = useBulkUnassignControlRuleMutation();

  const queriesState = useQueryStates({
    states: {
      controlRules: useControlRulesQuery(),
      costCenters: useCostCentersQuery(),
      policies: usePoliciesQuery(),
    },
  });

  /* eslint-disable sonarjs/cognitive-complexity */
  const onSubmit: FormOnSubmit<MembersBulkUpdateForm> = async (
    _,
    { updatedValues: data },
  ) => {
    /* eslint-enable sonarjs/cognitive-complexity */

    // cost center
    if (data.costCenterId.changed) {
      const membersWithUpdatedCostCenter = members.filter((member) =>
        isDifferent(member.costCenter?.id, data.costCenterId.value),
      );

      if (membersWithUpdatedCostCenter.length) {
        await updateCostCenter({
          userIds: membersWithUpdatedCostCenter.map((member) => member.id),
          costCenterId:
            data.costCenterId.value === NULL_VALUE
              ? null
              : data.costCenterId.value,
        });
      }
    }

    // approval policy
    if (data.approvalPolicyId.changed) {
      const membersWithUpdatedApprovalPolicy = members
        .filter((member) => member.isRequester)
        .filter((member) =>
          isDifferent(member.policy?.id, data.approvalPolicyId.value),
        );

      if (membersWithUpdatedApprovalPolicy.length && data.approvalPolicyId) {
        await updateApprovalPolicy({
          user_ids: membersWithUpdatedApprovalPolicy.map((member) => member.id),
          endpointParams: {
            policyId: data.approvalPolicyId.value,
          },
        });
      }
    }

    // control rule
    if (data.controlRuleId.changed) {
      const membersWithUpdatedControlRule = members
        .filter((member) => member.isRequester)
        .filter((member) =>
          isDifferent(member.controlRule?.id, data.controlRuleId.value),
        );

      if (membersWithUpdatedControlRule.length) {
        if (data.controlRuleId.value === NULL_VALUE) {
          await unassignControlRule({
            userIds: membersWithUpdatedControlRule.map((member) => member.id),
          });
        } else {
          await updateControlRule({
            userIds: membersWithUpdatedControlRule.map((member) => member.id),
            endpointParams: {
              controlRuleId: data.controlRuleId.value,
            },
          });
        }
      }
    }
  };

  return (
    <QuerySuspense
      queryState={queriesState}
      loading={<ModalLoader />}
      fallback={() => (
        <ModalError
          message={t('members.editModal.fetchFailed')}
          onClose={onClose}
        />
      )}
    >
      {({ controlRules, policies, costCenters }) => (
        <MembersBulkEditModal
          costCenters={costCenters}
          controlRules={controlRules.filter(
            (controlRule) => !controlRule.isCustom,
          )}
          policies={policies}
          members={members}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
    </QuerySuspense>
  );
}
