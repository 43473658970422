import { type QueryResult } from '@apollo/react-common';
import {
  useQuery as useQueryHook,
  type QueryHookOptions,
} from '@apollo/react-hooks';
import { type ApolloError } from 'apollo-client';
import { type DocumentNode } from 'graphql';
import { useMemo } from 'react';

export const useQuery = <TResult, TResultError = ApolloError>(
  query: DocumentNode,
  config: QueryHookOptions,
  customReshapers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reshapeData: (data: any) => TResult;
    reshapeError?: (error?: ApolloError) => TResultError;
  },
): QueryResult<TResult> & {
  loadingMore: boolean;
  error: ApolloError | (ApolloError & TResultError) | undefined;
} => {
  const result = useQueryHook(query, {
    ...config,
  });

  const error =
    result.error && customReshapers.reshapeError
      ? { ...result.error, ...customReshapers.reshapeError(result.error) }
      : result.error;

  // Only re-run the reshaper if the data actually changed.
  const data = useMemo(() => {
    return result.data === undefined
      ? undefined
      : customReshapers.reshapeData(result.data);
  }, [result.data]);

  return {
    ...result,
    loadingMore: result.networkStatus === 3,
    data,
    error,
  };
};
