import { Icon, SkeletonText } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { EntityBreakdownBlock } from './EntityBreakdownBlock';
import { EntityGenericStatisticsBlock } from './EntityGenericStatisticsBlock';
import { type OrganisationReportingEntityStatusForCallout } from './OrganisationReportingEntityStatisticsCallout/OrganisationReportingEntityStatisticsCallout';
import { type useOrganisationEntityWalletSummaryQuery } from '../../../hooks/data/useOrganisationEntityWalletSummaryQuery';
import { type OrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import { type OrganisationReportingEntity } from '../../../types';

export const OrganisationReportingEntityStatisticsWalletSummary = ({
  entity,
  entityStatus,
  entityWalletSummaryQueryState,
  features,
}: {
  entity: OrganisationReportingEntity;
  entityStatus: OrganisationReportingEntityStatusForCallout | undefined;
  entityWalletSummaryQueryState: ReturnType<
    typeof useOrganisationEntityWalletSummaryQuery
  >;
  features: OrganisationFeatures;
}) => {
  const { t } = useTranslation('global');

  return (
    <QuerySuspense
      queryState={entityWalletSummaryQueryState}
      loading={
        <>
          {features.wallet && (
            <div className="flex-[3]">
              <EntityGenericStatisticsBlock
                stack
                title={t(
                  'organisation.reporting.page.entities.entity.walletBalance',
                )}
                contents={[
                  <div key="walletBalance" className="font-medium">
                    <SkeletonText />
                  </div>,
                  <div key="wallBalanceAddFunds">
                    <SkeletonText />
                  </div>,
                ]}
              />
            </div>
          )}
          {features.wallet && (
            <div className="flex-[10]">
              <SkeletonText size="l" />
              <div className="flex flex-row justify-between gap-xs pt-s">
                {[...Array(3).keys()].map((key) => (
                  <div key={`skeleton-${key}`} className="flex-1">
                    <SkeletonText />
                    <SkeletonText />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      }
      fallback={() => null}
    >
      {(entityWalletSummary) => {
        // TODO: Remove this when we want to display churned entities
        // See https://spendesk.atlassian.net/browse/ECO-395
        if (entity.hasChurned) {
          return null;
        }

        const isAvailableFundsLow =
          entityWalletSummary.breakdown.trend === 'warning';

        const statusesForInactiveState: OrganisationReportingEntityStatusForCallout[] =
          ['kycInProgress', 'awaitingKycApproval'] as const;
        const isInactive = Boolean(
          entityStatus && statusesForInactiveState.includes(entityStatus),
        );
        // const cannotAccessEntity =
        //   entityStatus === 'kycInProgress' ? false : isInactive;

        return (
          <>
            {features.wallet && (
              <div className="flex-[3]">
                <EntityGenericStatisticsBlock
                  stack
                  title={t(
                    'organisation.reporting.page.entities.entity.walletBalance',
                  )}
                  contents={[
                    <div key="walletBalance" className="font-medium">
                      {formatMonetaryValue({
                        amount: entityWalletSummary.walletBalance,
                        currency: entity.currency,
                        precision: 2,
                      })}
                    </div>,
                    <div key="wallBalanceAddFunds">
                      <Link
                        to={routeFor(routes.COMPANY_BANK_FUNDING.path, {
                          company: entity.id,
                        })}
                        target="_blank"
                        className={classnames(
                          'flex items-center gap-xxs leading-[16px] text-complementary title-s',
                          // eslint-disable-next-line @typescript-eslint/naming-convention
                          { 'text-warning': isAvailableFundsLow },
                        )}
                        onClick={() =>
                          track(
                            AnalyticEventName.ORGANISATION_REPORTING_ADD_FUNDS_BUTTON_CLICKED,
                          )
                        }
                      >
                        {t(
                          'organisation.reporting.page.entities.entity.walletBalanceAddFunds',
                        )}
                        <Icon name="caret-right" />
                      </Link>
                    </div>,
                  ].filter((component) => component)}
                  isInactive={isInactive}
                />
              </div>
            )}
            {features.wallet && (
              <div className="flex-[10]">
                <EntityBreakdownBlock
                  currency={entity.currency}
                  breakdown={entityWalletSummary.breakdown}
                  isInactive={isInactive && entityStatus !== 'churning'}
                />
              </div>
            )}
          </>
        );
      }}
    </QuerySuspense>
  );
};
