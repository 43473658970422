import { Button, Link } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import bg from './assets/bg.png';
import deliveryIcon from './assets/delivery.svg';
import { ReorderNotReceivedContinueModal } from './components/ReorderNotReceivedContinueModal';
import { ReorderNotReceivedFormModal } from './components/ReorderNotReceivedFormModal';
import type { ApiCard } from '../../../../card';

type Props = {
  card: ApiCard;
  onCardReceptionConfirmationClick(): void;
  onReorderNotReceivedFormSuccessfulSubmission(): void;
  className?: string;
};

export const MyCardOrderBanner = ({
  card,
  onCardReceptionConfirmationClick,
  onReorderNotReceivedFormSuccessfulSubmission,
  className,
}: Props) => {
  const { t } = useTranslation('global');

  const [isContinueModalOpen, setIsContinueModalOpen] = useState(false);
  const [isFormOrderModalOpen, setIsFormOrderModalOpen] = useState(false);

  const cardOrder = card.cardOrder;
  const address = `${cardOrder.address} ${cardOrder.address2 ? `${cardOrder.address2} ` : ''}${cardOrder.city} ${cardOrder.zipcode}`;

  return (
    <div>
      <div
        className={classNames(
          'relative flex items-center justify-between overflow-hidden rounded-xxs p-s',
          className,
        )}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="text-white">
          <div className="mb-xxs title-xl">
            {`${t('myCardOrderBanner.title')} `}
            <img src={deliveryIcon} alt="" />
          </div>
          <div>
            <div className="flex items-center gap-xxs">
              {t('myCardOrderBanner.address', { address })}
              {Boolean(cardOrder.tracking_link) && (
                <>
                  <span>•</span>
                  <Link
                    href={cardOrder.tracking_link ?? ''}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      track(AnalyticEventName.CARD_TRACKING_LINK_CLICKED);
                    }}
                  >
                    {t('myCardOrderBanner.buttonTrack')}
                  </Link>
                </>
              )}
            </div>
            {cardOrder.tracking_link
              ? t('myCardOrderBanner.deliveryEstimation')
              : t('myCardOrderBanner.deliveryEstimationWithoutTrackingLink')}
          </div>
        </div>
        <div className="flex gap-s">
          {cardOrder.state === 'shipped' ? (
            <Button
              className="text-white"
              variant="ghost"
              text={t('myCardOrderBanner.cardNotReceived')}
              onClick={() => setIsContinueModalOpen(true)}
            />
          ) : null}

          <Button
            variant="contrasted"
            text={t('myCardOrderBanner.buttonReceived')}
            onClick={onCardReceptionConfirmationClick}
          />
        </div>
      </div>
      <ReorderNotReceivedContinueModal
        isOpen={isContinueModalOpen}
        onClose={() => setIsContinueModalOpen(false)}
        onNext={() => {
          setIsFormOrderModalOpen(true);
          setIsContinueModalOpen(false);
        }}
        trackingLink={cardOrder.tracking_link ?? ''}
      />
      <ReorderNotReceivedFormModal
        isOpen={isFormOrderModalOpen}
        onClose={() => setIsFormOrderModalOpen(false)}
        onFormSuccessfulSubmission={
          onReorderNotReceivedFormSuccessfulSubmission
        }
        notReceivedCard={card}
      />
    </div>
  );
};
