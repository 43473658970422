import { InfoTip } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import type { CardAddress } from 'src/core/common/hooks/useCardAddressQuery';
import { COUNTRIES } from 'src/core/config/country';

type Props = {
  cardAddress: CardAddress;
};

export const PlasticCardNotice = ({ cardAddress }: Props) => {
  const { t } = useTranslation('global');
  const country = COUNTRIES[cardAddress?.country?.toUpperCase()];

  return (
    <div className="text-neutral-dark body-m">
      <span>{t('cards.addressCheckNoticeTitleLabel')}</span>
      <div className="leading-4 text-complementary">
        <div>{cardAddress.address ?? ''}</div>
        <div>{cardAddress.address2 ?? ''}</div>
        <div>{`${cardAddress.zipCode ?? ''} ${cardAddress.city ?? ''}`}</div>
        {country && <div>{t(country.translationKey)}</div>}
      </div>
      <div className="flex items-center gap-xxs">
        <span>{t('cards.addressCheckNoticeContent')}</span>
        <InfoTip content={t('cards.addressCheckNoticeTip')} />
      </div>
    </div>
  );
};
