import { Callout, FormField, SkeletonText } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';

import { useMembersQuery } from '../../hooks';
import { getMemberName, type Member } from '../../models/member';

type Props = {
  toDeleteMember: Member;
  error: string | undefined;
  onSelectMemberId: (id: string) => void;
};

export const TransferToMemberAutocompleteContainer = ({
  toDeleteMember,
  error,
  onSelectMemberId,
}: Props) => {
  const { t } = useTranslation('global');
  const [selectedMemberOption, setSelectedMemberOption] = useState<{
    key: string;
    label: string;
  }>();
  const membersQueryState = useMembersQuery();

  return (
    <QuerySuspense
      loading={<SkeletonText size="xxl" />}
      fallback={() => (
        <Callout
          variant="warning"
          title={t('members.deleteModal.loadMembersError')}
        />
      )}
      queryState={membersQueryState}
    >
      {(members) => (
        <FormField
          label={t('members.subscriptionsWillBeTransferedTo', {
            userName: getMemberName(toDeleteMember),
          })}
          alertMessage={error}
        >
          <AutocompleteSearch
            fit="parent"
            name="transferringMember"
            placeholder={t('members.deleteModal.transferringMember')}
            value={selectedMemberOption}
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions value={rawValue} />
            )}
            options={getMembersToTransfer(members, toDeleteMember.id).map(
              (member) => ({
                key: member.id,
                label: member.displayName,
              }),
            )}
            onSelect={(member) => {
              if (member) {
                setSelectedMemberOption(member);
                onSelectMemberId(member.key);
              }
            }}
          />
        </FormField>
      )}
    </QuerySuspense>
  );
};

const getMembersToTransfer = (
  members: Member[],
  toDeleteMemberId: string,
): Member[] => {
  return members.filter(
    (member) => !member.isPending && member.id !== toDeleteMemberId,
  );
};
