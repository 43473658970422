import { Button, Modal } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import React, { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import {
  type ApprovalPolicyFormValues,
  validateApprovalPolicyFormValues,
} from '../../formValues';
import { type Member } from '../../member';
import { ApprovalPolicyDetailsContent } from '../ApprovalPolicyDetailsContent';
import { ApprovalPolicyMembersContent } from '../ApprovalPolicyMembersContent';

type Props = {
  members: Member[];
  onSuccess(values: ApprovalPolicyFormValues): void;
  onCancel(): void;
};

type Step = 'define' | 'members';

export const CreateApprovalPolicyModal = ({
  members,
  onSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const [step, setStep] = useState<Step>('define');

  const formikProps = useFormik<ApprovalPolicyFormValues>({
    initialValues: {
      name: '',
      membersIds: [],
      approvalNeeded: 'always',
      spendingTypes: [],
      amountPerMonth: undefined,
      amountPerTransaction: undefined,
    },
    validateOnChange: false,
    validate: (values) => validateApprovalPolicyFormValues(values, step, t),
    onSubmit: onSuccess,
  });

  return (
    <Modal
      isOpen
      iconName="badge"
      iconVariant="primary"
      title={t('approvalPolicy.modal.title')}
      actions={getModalActions({
        step,
        onNextStep: async () => {
          const errors = await formikProps.validateForm();
          if (Object.keys(errors).length === 0) {
            setStep('members');
          }
        },
        onPreviousStep: () => setStep('define'),
        onSubmit: async () => {
          const errors = await formikProps.validateForm();
          if (Object.keys(errors).length === 0) {
            track(
              AnalyticEventName.APPROVAL_POLICIES_CREATE_POLICY_BUTTON_CLICKED,
            );
            formikProps.handleSubmit();
          }
        },
        onCancel,
        translator: t,
        isSubmitting: formikProps.isSubmitting,
      })}
    >
      {step === 'define' && <ApprovalPolicyDetailsContent {...formikProps} />}
      {step === 'members' && (
        <ApprovalPolicyMembersContent {...formikProps} members={members} />
      )}
    </Modal>
  );
};

const getModalActions = ({
  step,
  onNextStep,
  onPreviousStep,
  onSubmit,
  onCancel,
  translator,
  isSubmitting,
}: {
  step: Step;
  onNextStep(): void;
  onPreviousStep(): void;
  onSubmit(): void;
  onCancel(): void;
  translator(key: string): string;
  isSubmitting: boolean;
}) => {
  if (step === 'define') {
    return [
      <Button
        key="1"
        variant="secondary"
        text={translator('approvalPolicy.modal.cancel')}
        onClick={onCancel}
      />,
      <Button
        key="2"
        variant="primary"
        iconPosition="right"
        iconName="caret-right"
        text={translator('approvalPolicy.modal.assign')}
        onClick={onNextStep}
      />,
    ];
  }

  return [
    <Button
      key="1"
      variant="secondary"
      iconPosition="left"
      iconName="caret-left"
      text={translator('approvalPolicy.modal.back')}
      onClick={onPreviousStep}
    />,
    <Button
      key="2"
      variant="primary"
      text={translator('approvalPolicy.modal.save')}
      isDisabled={isSubmitting}
      onClick={onSubmit}
    />,
  ];
};
