import isEqual from 'lodash/isEqual';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { CenteredLayout } from 'common/components/layout';
import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';

import { useCreateOrUpdateExpenseCategoryExpenseAccountRule } from './hooks/useCreateOrUpdateExpenseCategoryExpenseAccountRule';
import { useEditEcQuery } from './hooks/useEditEcQuery';
import { EcFormModal } from '../../components/EcFormModal';
import { type FormValues } from '../../components/EcFormModal/formValues';
import { type ExpenseCategory } from '../../expenseCategory';
import { useGetDataForExpenseCategoryExpenseAccountRules } from '../../hooks/useGetDataForExpenseCategoryExpenseAccountRules';
import { type ExpenseCategoryExpenseAccountRule } from '../../hooks/useGetExpenseCategoryExpenseAccountRulesLazyQuery';
import { useIsExpenseCategoryExpenseAccountRuleEnabled } from '../../hooks/useIsExpenseCategoryExpenseAccountRuleEnabled';
import { useExpenseCategoriesQuery } from '../EcContainer/hooks/useExpenseCategoriesQuery';

type Props = {
  expenseCategory: ExpenseCategory;
  isOpen: boolean;
  onComplete(): void;
  onCancel(): void;
};

export const EcEditFormModalContainer = ({
  expenseCategory,
  isOpen,
  onComplete,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif, successNotif } = useNotifications();
  const isExpenseCategoryExpenseAccountRuleEnabled =
    useIsExpenseCategoryExpenseAccountRuleEnabled();

  const [editExpenseCategory] = useEditEcQuery(expenseCategory.id);
  const [editExpenseCategoryExpenseAccountRule] =
    useCreateOrUpdateExpenseCategoryExpenseAccountRule();

  const queryStates = useQueryStates({
    states: {
      costCenters: useCostCentersQuery(),
      expenseCategories: useExpenseCategoriesQuery(),
      data: useGetDataForExpenseCategoryExpenseAccountRules(),
    },
    reshapeData({ costCenters, expenseCategories, data }) {
      return {
        costCenters,
        expenseCategories,
        ...data,
      };
    },
  });

  const shouldUpdateExpenseCategory = (values: FormValues) => {
    return (
      values.name !== expenseCategory.name ||
      !isEqual(values.costCenterIds, expenseCategory.costCenterIds)
    );
  };

  const shouldUpdateExpenseCategoryExpenseAccountRules = (
    values: FormValues,
    expenseCategoryExpenseAccountRules: ExpenseCategoryExpenseAccountRule[],
  ) => {
    const existingRule = expenseCategoryExpenseAccountRules.find(
      ({ expenseCategoryId }) => expenseCategoryId === expenseCategory.id,
    );

    return (
      isExpenseCategoryExpenseAccountRuleEnabled &&
      !isEqual(values.linkedExpenseAccountIds, existingRule?.expenseAccountIds)
    );
  };

  const handleSubmit = async (
    values: FormValues,
    expenseCategoryExpenseAccountRules: ExpenseCategoryExpenseAccountRule[],
  ) => {
    if (shouldUpdateExpenseCategory(values)) {
      await editExpenseCategory({
        name: values.name,
        costCenterIds: values.costCenterIds,
      });
    }
    if (
      shouldUpdateExpenseCategoryExpenseAccountRules(
        values,
        expenseCategoryExpenseAccountRules,
      )
    ) {
      await editExpenseCategoryExpenseAccountRule({
        expenseAccountIds: values.linkedExpenseAccountIds,
        expenseCategoryId: expenseCategory.id,
      });
    }
  };

  return (
    <QuerySuspense
      queryState={queryStates}
      fallback={() => (
        <CenteredLayout className="text-center" verticalAlign="middle">
          {t('misc.errors.networkError')}
        </CenteredLayout>
      )}
      loading={null}
    >
      {({
        costCenters,
        expenseCategories,
        expenseAccounts,
        expenseCategoryExpenseAccountRules,
      }) => (
        <EcFormModal
          expenseCategory={expenseCategory}
          costCenters={costCenters}
          expenseAccounts={expenseAccounts}
          expenseCategoryExpenseAccountRules={
            expenseCategoryExpenseAccountRules
          }
          expenseCategoryNames={expenseCategories
            .map((ec) => ec.name)
            .filter((ecName) => ecName !== expenseCategory.name)}
          isOpen={isOpen}
          onSubmit={async (values: FormValues) => {
            try {
              await handleSubmit(values, expenseCategoryExpenseAccountRules);
              successNotif(t('expenseCategories.form.editSuccess'));
              onComplete();
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(e);
              dangerNotif(t('expenseCategories.form.editError'));
            }
          }}
          onCancel={onCancel}
        />
      )}
    </QuerySuspense>
  );
};
