import {
  type Template,
  type TemplateItem,
  type AvailableColumn,
} from '../template';

export const FETCH_TEMPLATE_REQUEST = 'export/FETCH_TEMPLATE_REQUEST';
export const FETCH_TEMPLATE_FAILURE = 'export/FETCH_TEMPLATE_FAILURE';
export const FETCH_TEMPLATE_SUCCESS = 'export/FETCH_TEMPLATE_SUCCESS';
export const FETCH_ALL_TEMPLATES_REQUEST = 'export/FETCH_ALL_TEMPLATES_REQUEST';
export const FETCH_ALL_TEMPLATES_FAILURE = 'export/FETCH_ALL_TEMPLATES_FAILURE';
export const FETCH_ALL_TEMPLATES_SUCCESS = 'export/FETCH_ALL_TEMPLATES_SUCCESS';
export const RESET_OPENED_TEMPLATE = 'export/RESET_OPENED_TEMPLATE';

export type FetchTemplateRequest = Readonly<{
  type: typeof FETCH_TEMPLATE_REQUEST;
}>;

export type FetchTemplateFailure = Readonly<{
  type: typeof FETCH_TEMPLATE_FAILURE;
}>;

export type FetchTemplateSuccess = Readonly<{
  type: typeof FETCH_TEMPLATE_SUCCESS;
  payload: {
    template: Template;
    availableTemplateColumns?: AvailableColumn[];
  };
}>;

export type FetchAllTemplatesRequest = Readonly<{
  type: typeof FETCH_ALL_TEMPLATES_REQUEST;
}>;

export type FetchAllTemplatesFailure = Readonly<{
  type: typeof FETCH_ALL_TEMPLATES_FAILURE;
}>;

export type FetchAllTemplatesSuccess = Readonly<{
  type: typeof FETCH_ALL_TEMPLATES_SUCCESS;
  payload: {
    templates: TemplateItem[];
  };
}>;

export type ResetOpenedTemplate = Readonly<{
  type: typeof RESET_OPENED_TEMPLATE;
}>;

export type ExportActions =
  | FetchTemplateRequest
  | FetchTemplateFailure
  | FetchTemplateSuccess
  | FetchAllTemplatesRequest
  | FetchAllTemplatesFailure
  | FetchAllTemplatesSuccess
  | ResetOpenedTemplate;
