import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type SelectableBankAccounts } from '../../accounting';

export const useBankAccountsQuery = (): QueryState<SelectableBankAccounts> => {
  const { company: companyId } = useParams();

  return useQuery<SelectableBankAccounts>({
    key: ['getBankAccounts', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/chart-of-accounts/bank-accounts',
    },
    reshapeData(data) {
      return data;
    },
  });
};
