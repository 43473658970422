import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCompany } from 'modules/app/hooks/useCompany';
import { BankFields } from 'src/core/utils/bankInfoFormats';
import { useBankInfoFieldsCombo } from 'src/core/utils/useBankInfo';

import { AccountCodePreview } from './AccountCodePreview';
import { AccountNumberPreview } from './AccountNumberPreview';
import { BicPreview } from './BicPreview';
import { IbanPreview } from './IbanPreview';
import { SortCodePreview } from './SortCodePreview';
import styles from './SupplierBankInfoPreview.module.css';
import { type SupplierBankInfos } from '../../models';

type Props = {
  className?: string;
  filter?: (keyof SupplierBankInfos)[];
  bankInfos: SupplierBankInfos;
};

export const SupplierBankInfoPreview = ({
  className,
  filter = ['accountCode', 'accountNumber', 'bic', 'iban', 'sortCode'],
  bankInfos,
}: Props) => {
  const fields = [];

  const { t } = useTranslation('global');
  const company = useCompany();

  const undefinedValue = t('submitMyInvoice.fields.undefined');

  const bankInfoFields = useBankInfoFieldsCombo()(
    bankInfos.bankCountry ?? company.country,
  );

  const mappingToBankInfosFields = {
    [BankFields.Iban]: 'iban',
    [BankFields.BicSwift]: 'bic',
    [BankFields.AccountNumber]: 'accountNumber',
    [BankFields.AccountCode]: 'accountCode',
    [BankFields.SortCode]: 'sortCode',
    [BankFields.RoutingNumber]: 'routingNumber',
    [BankFields.AccountHolderName]: 'accountHolderName',
  };

  const mappedBankInfoFields = bankInfoFields.map(
    (field) => mappingToBankInfosFields[field],
  );

  const getFieldValue = (value: string | undefined) => {
    return value && value !== '' ? value : undefinedValue;
  };

  if (filter.includes('iban') && mappedBankInfoFields.includes('iban')) {
    fields.push(
      <div key="iban">
        <IbanPreview iban={getFieldValue(bankInfos.iban)} />
      </div>,
    );
  }

  if (filter.includes('bic') && mappedBankInfoFields.includes('bic')) {
    fields.push(
      <div key="bic">
        <BicPreview bic={getFieldValue(bankInfos.bic)} />
      </div>,
    );
  }

  if (
    filter.includes('accountNumber') &&
    mappedBankInfoFields.includes('accountNumber')
  ) {
    fields.push(
      <div key="accountNumber">
        <AccountNumberPreview
          accountNumber={getFieldValue(bankInfos.accountNumber)}
        />
      </div>,
    );
  }

  if (
    filter.includes('accountCode') &&
    mappedBankInfoFields.includes('accountCode')
  ) {
    fields.push(
      <div key="accountCode">
        <AccountCodePreview
          accountCode={getFieldValue(bankInfos.accountCode)}
        />
      </div>,
    );
  }

  if (
    filter.includes('sortCode') &&
    mappedBankInfoFields.includes('sortCode')
  ) {
    fields.push(
      <div key="sortCode">
        <SortCodePreview sortCode={getFieldValue(bankInfos.sortCode)} />
      </div>,
    );
  }

  return fields.length ? (
    <section className={classNames(styles.SupplierBankInfoPreview, className)}>
      {fields}
    </section>
  ) : null;
};
