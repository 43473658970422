import { useSelector } from 'react-redux';

import type Features from '../../constants/features';
import { type AppState } from '../../reducers';
import { getIsFeatureEnabled } from '../../selectors/globalSelectors';

/**
 * @example
 * import { useFeature } from 'common/hooks/useFeature';
 *
 * const MyComponent = () => {
 *   const hasSomeFeature = useFeature(FEATURES.SOME);
 *   return hasSomeFeature ? 'YES' : 'NO';
 * };
 */
export const useFeature = (feature: Features): boolean => {
  return useSelector((state: AppState) => getIsFeatureEnabled(state, feature));
};
