export interface State {
  isEditing: boolean;
  upTo: number | null;
  originalUpToValue: number;
  fadeOut: boolean;
  blink: boolean;
  isMultiStep: boolean;
  isOpenDropDown: boolean;
}

type StartEditing = {
  readonly type: 'START_EDITING';
  readonly payload: number;
};

type StopEditing = {
  readonly type: 'STOP_EDITING';
};

type UpdateUpTo = {
  readonly type: 'UPDATE_UP_TO';
  readonly payload: number;
};

type UpdateSearchValue = {
  readonly type: 'UPDATE_SEARCH_VALUE';
  readonly payload: string;
};

type StartFadeOut = {
  readonly type: 'START_FADE_OUT';
};

type StopFadeOut = {
  readonly type: 'STOP_FADE_OUT';
};

type StartBlink = {
  readonly type: 'START_BLINK';
};

type StopBlink = {
  readonly type: 'STOP_BLINK';
};

type SetIsMultiStep = {
  readonly type: 'SET_IS_MULTISTEP';
  readonly payload: boolean;
};

type ToggleDropDown = {
  readonly type: 'TOGGLE_DROP_DOWN';
};

type CloseDropDown = {
  readonly type: 'CLOSE_DROP_DOWN';
};

type Action =
  | StartEditing
  | StopEditing
  | UpdateUpTo
  | UpdateSearchValue
  | StartFadeOut
  | StopFadeOut
  | StartBlink
  | StopBlink
  | SetIsMultiStep
  | ToggleDropDown
  | CloseDropDown;

const START_EDITING = 'START_EDITING';
const STOP_EDITING = 'STOP_EDITING';
const UPDATE_UP_TO = 'UPDATE_UP_TO';
const START_FADE_OUT = 'START_FADE_OUT';
const STOP_FADE_OUT = 'STOP_FADE_OUT';
const START_BLINK = 'START_BLINK';
const STOP_BLINK = 'STOP_BLINK';
const SET_IS_MULTISTEP = 'SET_IS_MULTISTEP';
const TOGGLE_DROP_DOWN = 'TOGGLE_DROP_DOWN';
const CLOSE_DROP_DOWN = 'CLOSE_DROP_DOWN';

export const startEditing = (upTo: number): StartEditing => ({
  type: START_EDITING,
  payload: upTo,
});

export const stopEditing = (): StopEditing => ({
  type: STOP_EDITING,
});

export const updateUpTo = (upTo: number): UpdateUpTo => ({
  type: UPDATE_UP_TO,
  payload: upTo,
});

export const startFadeOut = (): StartFadeOut => ({
  type: START_FADE_OUT,
});

export const stopFadeOut = (): StopFadeOut => ({
  type: STOP_FADE_OUT,
});

export const startBlink = (): StartBlink => ({
  type: START_BLINK,
});

export const stopBlink = (): StopBlink => ({
  type: STOP_BLINK,
});

export const setIsMultiStep = (isMultiStep: boolean): SetIsMultiStep => ({
  type: SET_IS_MULTISTEP,
  payload: isMultiStep,
});

export const toggleDropDown = (): ToggleDropDown => ({
  type: TOGGLE_DROP_DOWN,
});

export const closeDropDown = (): CloseDropDown => ({
  type: CLOSE_DROP_DOWN,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case START_EDITING:
      return { ...state, isEditing: true, originalUpToValue: action.payload };
    case STOP_EDITING:
      return { ...state, isEditing: false };
    case UPDATE_UP_TO:
      return { ...state, upTo: action.payload };
    case START_FADE_OUT:
      return { ...state, fadeOut: true };
    case STOP_FADE_OUT:
      return { ...state, fadeOut: false };
    case START_BLINK:
      return { ...state, blink: true };
    case STOP_BLINK:
      return { ...state, blink: false };
    case SET_IS_MULTISTEP:
      return { ...state, isMultiStep: action.payload };
    case TOGGLE_DROP_DOWN:
      return { ...state, isOpenDropDown: !state.isOpenDropDown };
    case CLOSE_DROP_DOWN:
      return { ...state, isOpenDropDown: false };
    default:
      return state;
  }
};

export default reducer;
