import { type DefaultSupplierAccount } from '../../supplierAccount';

type RawSupplierAccount = {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  isDefault: boolean;
  isArchived: boolean;
};

export const reshapeDefaultSupplierAccounts = (
  defaultSupplierAccounts: RawSupplierAccount[],
): DefaultSupplierAccount[] =>
  defaultSupplierAccounts.map((defaultSupplierAccount) => ({
    id: defaultSupplierAccount.id,
    generalAccountCode: defaultSupplierAccount.generalAccountCode,
    name: 'Miscellaneous',
    defaultFor: 'cardSupplier',
  }));
