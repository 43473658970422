import { useQueryClient } from 'react-query';

import type { DraftExpenses, RawExpense } from '@finance-review/models/expense';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { transformRawExpenses } from './transformer';

export const REQUESTS_REIMBURSEMENTS_KEY = ['requests', 'reimbursements'];

export const useExpensesQuery = (): QueryState<DraftExpenses> => {
  return useQuery<DraftExpenses, RawExpense[]>({
    key: REQUESTS_REIMBURSEMENTS_KEY,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/requests/reimbursements',
    },
    reshapeData: transformRawExpenses,
  });
};

export const useInvalidateExpenses = (): (() => Promise<void>) => {
  const queryClient = useQueryClient();

  return async (): Promise<void> => {
    await queryClient.invalidateQueries(REQUESTS_REIMBURSEMENTS_KEY);
  };
};
