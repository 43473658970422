import type * as Money from 'ezmoney';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { buildShortfallCalloutTitle } from 'modules/company/wallet/buildShortfallCalloutTitle';
import {
  type DetailedWalletSummary,
  getWalletTotalAllocated,
} from 'modules/company/wallet/walletSummary';
import { routeFor, routes } from 'src/core/constants/routes';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  HomepageBankAccountNotConnected,
  HomepageFirstFundingInProgress,
  HomepageWalletDetailKybNotValidated,
  HomepageWalletDetailNoFunds,
  HomepageWalletDetailPrimaryAmount,
  HomepageWalletDetailSecondaryAmount,
} from '../HomepageWalletDetail';

type Props = {
  company: {
    id: string;
    is_kyb_validated: boolean;
  };
  hasMissingFunds: boolean;
  hasPendingGpaOrders: boolean;
  isAccountLoaded: boolean;
  isMarqeta: boolean;
  mustConnectBankAccount: boolean;
  walletSummary: DetailedWalletSummary;
};

export const HomepageWallet = ({
  company,
  hasMissingFunds,
  hasPendingGpaOrders,
  isAccountLoaded,
  isMarqeta,
  mustConnectBankAccount,
  walletSummary,
}: Props) => {
  const { t } = useTranslation('global');

  const totalAllocated = formatMonetaryValue(
    getWalletTotalAllocated(walletSummary),
  );

  const totalAvailableForRequest = walletSummary.walletDetails
    ? formatMonetaryValue(walletSummary.walletDetails.available.totalAmount, {
        signDisplay: 'never' as Money.SignDisplay,
      })
    : '-';

  // TODO@financeOps: scheduledTransfers will always be present on detailed wallet when fully rolled out on the backend side
  const hasScheduledTransfersDetails =
    !!walletSummary.walletDetails?.scheduledTransfers;

  const totalWallet = walletSummary.totalAmount
    ? formatMonetaryValue(walletSummary.totalAmount)
    : '-';

  const formatedAmountDescription = hasMissingFunds
    ? t('homepage.wallet.expectedShortfall')
    : t('homepage.wallet.availableForRequest');

  const shortfallCalloutTitle = buildShortfallCalloutTitle({
    walletSummary,
    t,
  });

  const tooltipContent = hasMissingFunds ? shortfallCalloutTitle : '';

  const ctaText = hasMissingFunds
    ? t('homepage.wallet.topUpWallet')
    : t('homepage.wallet.link');

  const ctaLink = hasMissingFunds
    ? routeFor(routes.COMPANY_BANK_FUNDING.path, { company: company.id })
    : routeFor(routes.COMPANY_BANK.path, { company: company.id });

  const renderConnectBankAccount = (): JSX.Element => (
    <HomepageBankAccountNotConnected company={company} />
  );
  const renderFirstFundingInProgress = (): JSX.Element => (
    <HomepageFirstFundingInProgress company={company} />
  );
  const renderNoFunds = (isKybValidated: boolean): JSX.Element =>
    isKybValidated ? (
      <HomepageWalletDetailNoFunds />
    ) : (
      <HomepageWalletDetailKybNotValidated />
    );

  const renderPrimaryAmount = (): JSX.Element => (
    <HomepageWalletDetailPrimaryAmount
      ctaText={ctaText}
      ctaLink={ctaLink}
      formatedAmount={totalAvailableForRequest}
      formatedAmountDescription={formatedAmountDescription}
      hasWarning={hasMissingFunds}
      hideTooltip={!hasMissingFunds}
      tooltipContent={tooltipContent}
    />
  );

  const formatedAllocatedAmountDescription = hasScheduledTransfersDetails
    ? t('homepage.wallet.totalAllocated')
    : t('homepage.wallet.allocatedOnCards');

  const renderSecondaryAmounts = (): JSX.Element => (
    <div className="flex content-stretch items-stretch gap-xs">
      <HomepageWalletDetailSecondaryAmount
        formatedAmount={totalWallet}
        formatedAmountDescription={t('homepage.wallet.walletDescription')}
        isAccountLoaded={isAccountLoaded}
        iconName="wallet"
      />
      <div className="mx-xs inline-block w-[1px] bg-neutral-light" />
      <HomepageWalletDetailSecondaryAmount
        formatedAmount={totalAllocated}
        formatedAmountDescription={formatedAllocatedAmountDescription}
        isAccountLoaded={isAccountLoaded}
        iconName="recurring-card"
      />
    </div>
  );

  const renderHomepageWallet = (): JSX.Element => {
    if (isMarqeta) {
      if (!isAccountLoaded) {
        if (hasPendingGpaOrders) {
          return renderFirstFundingInProgress();
        }
        if (mustConnectBankAccount) {
          return renderConnectBankAccount();
        }
        return renderNoFunds(company.is_kyb_validated);
      }
      return (
        <div className="flex flex-col gap-m">
          {renderPrimaryAmount()}
          {renderSecondaryAmounts()}
        </div>
      );
    }
    if (!isAccountLoaded) {
      return (
        <div className="flex flex-col gap-m">
          {renderNoFunds(true)}
          {renderSecondaryAmounts()}
        </div>
      );
    }
    return (
      <div className="flex flex-col gap-m">
        {renderPrimaryAmount()}
        {renderSecondaryAmounts()}
      </div>
    );
  };

  return <div className="box p-xs pb-m">{renderHomepageWallet()}</div>;
};
