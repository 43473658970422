import React from 'react';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';

export const integrationsOnEMVPFlow = ['Sage100', 'ACD'];

export function switchFromXeroBookkeepImpact(
  accountingIntegration: AccountingSoftware | 'noIntegration',
  t: TGlobalFunctionTyped,
) {
  switch (accountingIntegration) {
    case 'Quickbooks':
    case 'Datev':
    case 'Netsuite':
      return '';
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
    case 'Sage100':
    case 'ACD':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.xeroToFileBasedImpact.two',
            )}
          </li>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.xeroToFileBasedImpact.three',
            )}
          </li>
        </>
      );
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
}

export function switchFromQuickbooksBookkeepImpact(
  accountingIntegration: AccountingSoftware | 'noIntegration',
  t: TGlobalFunctionTyped,
) {
  switch (accountingIntegration) {
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
    case 'Sage100':
    case 'ACD':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.quickbooksToFileBasedImpact.two',
            )}
          </li>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.quickbooksToFileBasedImpact.three',
            )}
          </li>
        </>
      );
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
}

export function switchFromDatevBookkeepImpact(
  accountingIntegration: AccountingSoftware | 'noIntegration',
  t: TGlobalFunctionTyped,
) {
  switch (accountingIntegration) {
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
    case 'Sage100':
    case 'ACD':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.datevToFileBasedImpact.two',
            )}
          </li>
        </>
      );
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
}

export function switchFromFileBasedBookkeepImpact(
  accountingIntegration: AccountingSoftware | 'noIntegration',
  t: TGlobalFunctionTyped,
) {
  switch (accountingIntegration) {
    case 'Datev':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToDatevImpact.two',
            )}
          </li>
        </>
      );
    case 'Xero':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToXeroImpact.two',
            )}
          </li>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToXeroImpact.three',
            )}
          </li>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToXeroImpact.four',
            )}
          </li>
        </>
      );
    case 'Quickbooks':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToQuickbooksImpact.two',
            )}
          </li>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToQuickbooksImpact.three',
            )}
          </li>
        </>
      );
    case 'Netsuite':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToNetsuiteImpact.two',
            )}
          </li>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToNetsuiteImpact.three',
            )}
          </li>
        </>
      );
    case 'Sage100':
    case 'ACD':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToChiftImpact.one',
              {
                integration: accountingIntegration,
              },
            )}
          </li>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToChiftImpact.two',
              {
                integration: accountingIntegration,
              },
            )}
          </li>
        </>
      );
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToFileBased.1',
            )}
          </li>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToFileBased.2',
            )}
          </li>
        </>
      );
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
}

export function switchFromXeroSettingsImpact(
  accountingIntegration: AccountingSoftware | 'noIntegration',
  t: TGlobalFunctionTyped,
) {
  switch (accountingIntegration) {
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
    case 'Sage100':
    case 'ACD':
      return (
        <li>
          {t(
            'bookkeep.integrations.settings.baseSwitch.xeroToFileBasedImpact.settings',
          )}
        </li>
      );
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
}

export function switchFromQuickbooksSettingsImpact(
  accountingIntegration: AccountingSoftware | 'noIntegration',
  t: TGlobalFunctionTyped,
) {
  switch (accountingIntegration) {
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
    case 'Sage100':
    case 'ACD':
      return (
        <li>
          {t(
            'bookkeep.integrations.settings.baseSwitch.quickbooksToFileBasedImpact.settings',
          )}
        </li>
      );
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
}

export function switchFromDatevSettingsImpact(
  accountingIntegration: AccountingSoftware | 'noIntegration',
  t: TGlobalFunctionTyped,
) {
  switch (accountingIntegration) {
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
    case 'Sage100':
    case 'ACD':
      return (
        <li>
          {t(
            'bookkeep.integrations.settings.baseSwitch.datevToFileBasedImpact.settings',
          )}
        </li>
      );
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
}

export function switchFromFileBasedSettingsImpact(
  accountingIntegration: AccountingSoftware | 'noIntegration',
  t: TGlobalFunctionTyped,
) {
  switch (accountingIntegration) {
    case 'Quickbooks':
      return (
        <li>
          {t(
            'bookkeep.integrations.settings.baseSwitch.fileBasedToQuickbooksImpact.settings',
          )}
        </li>
      );
    case 'Xero':
      return (
        <li>
          {t(
            'bookkeep.integrations.settings.baseSwitch.fileBasedToXeroImpact.settings',
          )}
        </li>
      );
    case 'Datev':
      return (
        <li>
          {t(
            'bookkeep.integrations.settings.baseSwitch.fileBasedToDatevImpact.settings',
          )}
        </li>
      );
    case 'Netsuite':
      return (
        <li>
          {t(
            'bookkeep.integrations.settings.baseSwitch.fileBasedToNetsuiteImpact.settings',
          )}
        </li>
      );
    case 'Sage100':
    case 'ACD':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToChiftImpact.settings',
              {
                integration: accountingIntegration,
              },
            )}
          </li>
        </>
      );
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return (
        <>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToFileBased.settings1',
            )}
          </li>
          <li>
            {t(
              'bookkeep.integrations.settings.baseSwitch.fileBasedToFileBased.settings2',
            )}
          </li>
        </>
      );
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
}
