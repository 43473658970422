import { createReducer } from '@reduxjs/toolkit';

import * as customFieldsTypes from 'src/core/actionTypes/customFields';

import { type CustomField } from '../modules/invoices/models';

type SharedState = {
  customFields: CustomField[] | null;
};

const initialState: SharedState = {
  customFields: null,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(
    customFieldsTypes.FETCH_CF_SUCCESS,
    (
      state,
      action: {
        type: typeof customFieldsTypes.FETCH_CF_SUCCESS;
        payload: {
          customFields: CustomField[];
        };
      },
    ) => {
      state.customFields = action.payload.customFields;
    },
  );
});
