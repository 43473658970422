import { useHistory } from 'react-router-dom';

import { routeBuilder } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';

import { type InvoiceStatus } from '../../models';

type Params = {
  invoiceStatus?: InvoiceStatus;
  entityId?: string;
};

export const useGoToInvoice = (params: Params) => {
  const route = useInvoiceRoute(params);
  const history = useHistory();

  return () => {
    if (route) {
      history.push(route);
    }
  };
};

export const useInvoiceRoute = (params: Params): string | undefined => {
  const companyId = useCompanyId();
  return getInvoiceRoute(companyId, params);
};

export const getInvoiceRoute = (
  companyId: string,
  { invoiceStatus, entityId }: Params,
): string | undefined => {
  switch (invoiceStatus) {
    case 'to-pay':
    case 'partially-paid':
      return routeBuilder('INVOICES_PAY', {
        company: companyId,
        ...(entityId ? { paymentToScheduleId: entityId } : {}),
      });
    case 'paid':
      return routeBuilder('INVOICES_HISTORY', {
        company: companyId,
        ...(entityId ? { paymentId: entityId } : {}),
      });
    case 'approved':
      return routeBuilder('INVOICES_REVIEW', {
        company: companyId,
        ...(entityId ? { id: entityId } : {}),
      });
    default:
      return undefined;
  }
};
