import { type PayableAmortisationScheme } from 'modules/accounting-integration/models';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { getAmortisationSchemesQueryKey } from './query-key-registry';

export type AmortisationSchemesResponse = PayableAmortisationScheme[];

export type AmortisationSchemesQueryState =
  QueryState<AmortisationSchemesResponse>;

export const useAmortisationSchemesQuery = ({
  isEnabled = true,
}: { isEnabled?: boolean } = {}): AmortisationSchemesQueryState => {
  const companyId = useCompanyId();

  return useQuery<AmortisationSchemesResponse, AmortisationSchemesResponse>({
    key: getAmortisationSchemesQueryKey(companyId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/amortisation-schemes',
    },
    isEnabled,
  });
};
