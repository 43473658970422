import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import type { CustomFieldDefinition } from 'modules/budgets/models/customFieldDefinition';
import {
  useDesignatedCustomFieldQuery,
  useDesignatedCustomFieldQueryPromise,
} from 'modules/custom-fields/hooks/useDesignatedCustomFieldQuery';
import { getFetcher } from 'src/core/api/fetcher';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { staleTime } from './constants';

const requestParams = {
  type: 'rest',
  target: 'companyAPI',
  endpoint: '/custom-fields',
  method: 'get',
} as const;
const queryKey = ['customFields'];

type Params = {
  isEnabled?: boolean;
};
export type CustomFieldsQueryResult = {
  customFields: CustomFieldDefinition[];
  expenseCategoryField: CustomFieldDefinition | undefined;
};

export const useCustomFieldsQuery = ({
  isEnabled = true,
}: Params = {}): QueryState<
  CustomFieldsQueryResult,
  CustomFieldDefinition[]
> => {
  const expenseCategoryCustomFieldId = unwrapQuery(
    useDesignatedCustomFieldQuery(),
  )?.customFieldId;
  const reshaper = reshapeData(expenseCategoryCustomFieldId);

  return useQuery<CustomFieldsQueryResult>({
    key: queryKey,
    request: requestParams,
    options: {
      staleTime,
    },
    isEnabled,
    reshapeData: reshaper,
  });
};

export const useCustomFieldsQueryPromise = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();
  const designatedCustomFieldQueryPromise =
    useDesignatedCustomFieldQueryPromise();
  const customFieldsFetcher = () =>
    getFetcher<CustomFieldDefinition[]>({
      companyId,
      getRequest: () => requestParams,
    });

  return async (): Promise<CustomFieldsQueryResult> => {
    const customFields = await queryClient.fetchQuery(
      queryKey,
      customFieldsFetcher(),
      {
        staleTime,
      },
    );
    try {
      const designatedCustomField = await designatedCustomFieldQueryPromise();
      return reshapeData(designatedCustomField.customFieldId)(customFields);
    } catch {
      return reshapeData(undefined)(customFields);
    }
  };
};

const reshapeData =
  (expenseCategoryCustomFieldId: string | undefined) =>
  (data: CustomFieldDefinition[]): CustomFieldsQueryResult => {
    let expenseCategoryField;

    return {
      customFields: data.filter(
        (customFieldDefinition: CustomFieldDefinition) => {
          if (
            expenseCategoryCustomFieldId &&
            customFieldDefinition.id === expenseCategoryCustomFieldId
          ) {
            expenseCategoryField = customFieldDefinition;
            return false;
          }
          return true;
        },
      ),
      expenseCategoryField,
    };
  };

export const useInvalidateCustomFieldsQuery = (): (() => Promise<void>) => {
  const queryClient = useQueryClient();

  return async (): Promise<void> => {
    await queryClient.invalidateQueries<CustomFieldDefinition[]>(queryKey);
  };
};
