import React from 'react';
import { connect } from 'react-redux';

import { type AppState } from "src/core/reducers";

import { ControlRuleSummary } from '../../components/ControlRuleSummary';
import { type ControlRule } from '../../controlRule';
import { type Member } from '../../member';
import { getMembers } from '../../redux/membersSelector';

type Props = {
  controlRule: ControlRule;
  members: Member[];
  onEditDetailsClick(): void;
  onDelete(): void;
  onClose(): void;
};

const Container = ({
  controlRule,
  members,
  onEditDetailsClick,
  onDelete,
  onClose,
}: Props) => {
  const membersById = toMembersById(members);
  const membersInRule = controlRule.userIds
    .map((memberId) => membersById[memberId])
    .filter(Boolean);
  return (
    <ControlRuleSummary
      controlRule={controlRule}
      members={membersInRule}
      onEditDetailsClick={onEditDetailsClick}
      onDelete={onDelete}
      onClose={onClose}
    />
  );
};

const toMembersById = (members: Member[]) => {
  const result: { [id: string]: Member } = {};
  for (const member of members) {
    result[member.id] = member;
  }
  return result;
};

const mapStateToProps = (state: AppState) => ({
  members: getMembers(state),
});

export const ControlRuleSummaryContainer = connect(mapStateToProps)(Container);
