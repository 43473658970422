import { Tag, Collapse, Icon } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import Card from './assets/card.svg';
import Lock from './assets/lock.svg';
import Receipt from './assets/receipt.svg';

type Props = {
  delay: number;
  limit: number;
};

export const Pbr2RuleDescription = ({ delay, limit }: Props) => {
  const { t } = useTranslation('global');

  return (
    <div>
      <Collapse
        renderHeader={(isCollapsed) => (
          <div className="mb-s flex cursor-pointer items-center gap-xs text-complementary title-m">
            {t('controlRules.mandatoryFields.create.ruleDescription.title')}
            <Icon name={isCollapsed ? 'caret-down' : 'caret-up'} />
          </div>
        )}
      >
        <div className="relative flex flex-col gap-[20px]">
          <div className="absolute left-[24px] z-0 h-full w-[1px] bg-neutral-light" />
          <div className="z-[1] flex items-center gap-s">
            <img
              width="48"
              height="48"
              alt=""
              src={Card}
              className="bg-white"
            />
            <div>
              <div className="mb-xxs text-complementary title-m">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.limit',
                  { from: 1, to: delay },
                )}
              </div>
              <div className="text-s text-neutral-dark">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.limitDescription',
                )}
              </div>
            </div>
          </div>
          <div className="z-[1] flex items-center gap-s">
            <div className="flex h-[48px] w-[48px] items-center justify-center">
              <div className="relative h-[19px] w-[19px] rounded-[50%] border border-[5px] border-solid border-white bg-neutral-light" />
            </div>
            <div>
              <div className="mb-xxs text-complementary title-m">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.dayTitle',
                  { day: 1 },
                )}{' '}
                <Tag className="ml-xxs" variant="warning">
                  {t(
                    'controlRules.mandatoryFields.create.ruleDescription.tagBlocked',
                  )}
                </Tag>
              </div>
              <div className="text-s text-neutral-dark">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.dayDescriptionUrgent',
                )}
              </div>
            </div>
          </div>
          <div className="z-[1] flex items-center gap-s">
            <div className="flex h-[48px] w-[48px] items-center justify-center">
              <div className="relative h-[19px] w-[19px] rounded-[50%] border border-[5px] border-solid border-white bg-neutral-light" />
            </div>
            <div>
              <div className="mb-xxs text-complementary title-m">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.dayTitle',
                  { day: delay },
                )}{' '}
                <Tag className="ml-xxs" variant="warning">
                  {t(
                    'controlRules.mandatoryFields.create.ruleDescription.tagLate',
                  )}
                </Tag>
              </div>
              <div className="text-s text-neutral-dark">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.dayDescriptionLate',
                )}
              </div>
            </div>
          </div>
          <div className="z-[1] flex items-center gap-s">
            <img
              width="48"
              height="48"
              alt=""
              src={Lock}
              className="bg-white"
            />
            <div>
              <div className="mb-xxs text-complementary title-m">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.titleLatePayments',
                  { payments: limit },
                )}{' '}
                <Tag className="ml-xxs" variant="alert">
                  {t(
                    'controlRules.mandatoryFields.create.ruleDescription.tagBlocked',
                  )}
                </Tag>
              </div>
              <div className="text-s text-neutral-dark">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.restrictedDescription',
                )}
              </div>
            </div>
          </div>
          <div className="z-[1] flex items-center gap-s">
            <img width="48" height="48" alt="" src={Receipt} />
            <div>
              <div className="mb-xxs text-complementary title-m">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.unblockingTitle',
                )}
              </div>
              <div className="text-s text-neutral-dark">
                {t(
                  'controlRules.mandatoryFields.create.ruleDescription.unblockingDescription',
                )}
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
