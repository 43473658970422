import * as Money from 'ezmoney';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export const useFetchCompanyLastBalanceQuery = (
  companyId: string,
  isEnabled = true,
): QueryState<Money.MonetaryValue | undefined> => {
  return useQuery<Money.MonetaryValue | undefined>({
    key: 'lastBankableBalance',
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/companies/${companyId}/last-balance`,
    },
    isEnabled,
    reshapeData: (data) => {
      return data?.amount
        ? Money.fromNumber(data.amount, data.currency)
        : undefined;
    },
  });
};
