import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { RequesterApprovalPolicy } from 'src/core/modules/profile/components/RequesterSpendingPolicy/BasicRequesterSpendingPolicy/RequesterApprovalPolicy';
import { useSpendingPolicy } from 'src/core/modules/profile/hooks';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

export const ApprovalPolicySpendingRule = ({
  hasFeatureEnabled,
}: {
  hasFeatureEnabled: boolean;
}) => {
  const { t } = useTranslation('global');
  const spendingPolicy = useSpendingPolicy();
  const { is_account_owner: isAccountOwner } = useUser();

  if (isAccountOwner) {
    return (
      <p className="text-neutral-dark body-m">
        {t('control.approvalPolicyWidget.spendingLimitAccountOwner')}
      </p>
    );
  }

  return (
    <div className="text-neutral-dark body-m">
      {hasFeatureEnabled ? (
        <RequesterApprovalPolicy
          spendingPolicy={spendingPolicy}
          hideLimitStatus
        />
      ) : (
        <Trans
          i18nKey="control.approvalPolicyWidget.spendingPolicyDetails"
          values={{
            maxTransaction: formatMonetaryValue(spendingPolicy.maxTransaction),
            spendingLimit: formatMonetaryValue(spendingPolicy.spendingLimit),
          }}
        />
      )}
    </div>
  );
};
