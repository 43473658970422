import { type MonetaryValue } from 'ezmoney';

import { type SupplierAccountDefaultFor } from '../settings/accounting';

export const AccountPayableSuppliersPageId = 'AccountPayableSuppliersPage';

export type Supplier = {
  supplierName: string | null;
  supplierId: string;
  logosUrls: string[];
  totalAmount?: MonetaryValue;
};

export type AccountPayableSuppliersRow = {
  totalCount: number;
  accountPayable: SupplierAccount;
} & Supplier;

export type SuppliersWithoutAccountPayableRow = {
  totalCount: number;
} & Supplier;

export type SupplierDetails = {
  id: string;
  name: string;
  thumbnailUrl: string;
  isArchived: boolean;
  accountHolderName: string | null;
  bankDetails: {
    bankCountry: string;
    iban: string | null;
    bicSwift: string | null;
    sortCode: string | null;
    routingNumber: string | null;
    accountNumber: string | null;
    accountCode: string | null;
    bankAccountDetailsFileUrl: string | null;
    bankAccountDetailsThumbnailUrl: string | null;
    bankAccountDetailsMediaType: string | null;
  } | null;
  legalDetails: {
    legalName: string;
    registrationNumber: string | null;
    vatNumber: string | null;
    addressCountry: string | null;
    postalCode: string | null;
    streetAddress: string | null;
    addressLocality: string | null;
  } | null;
  supplierAccount: {
    id: string;
    generalAccountCode: string;
    auxiliaryAccountCode: string | undefined;
  } | null;
  totalAmount: MonetaryValue;
  expenseAccountSupplierRule: {
    expenseAccount: {
      id: string;
      name: string;
      isArchived: boolean;
    };
  } | null;
};

export type BankAccountDetailsFile =
  | {
      file: File;
      fileUrl: string;
      fileMediaType: string;
      action: 'upload';
    }
  | {
      action: 'delete';
    }
  | null;

export type SupplierAccount = {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  isDefault: boolean;
  isArchived: boolean;
  defaultFor?: SupplierAccountDefaultFor;
};

export type ExpenseAccount = {
  id: string;
  name: string;
  isArchived: boolean;
  code?: string;
};

export type AccountingFormValues = {
  accountPayableId: string | undefined;
  expenseAccountId: string | undefined;
};

export type BankDetailsFormValues = {
  bankCountry: string;
  iban: string;
  bicSwift: string;
  sortCode: string;
  routingNumber: string;
  accountNumber: string;
  accountCode: string;
  accountHolderName: string;
};

export type LegalDetailsFormValues = {
  legalName: string;
  registrationNumber: string;
  vatNumber: string;
  addressCountry: string;
  postalCode: string;
  streetAddress: string;
  addressLocality: string;
};

export type CreateSupplierFormValues = {
  name: string;
  legalName: string;
  accountPayableId: string;
} & BankDetailsFormValues;
