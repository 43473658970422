import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useNotifications } from 'src/core/modules/app/notifications';
import { useUpdateCustomFieldMutation } from 'src/core/modules/custom-fields/hooks/useUpdateCustomFieldMutation';
import { type CustomField } from 'src/core/modules/custom-fields/models/customField';

import { ExpenseCategoriesEditSettingsModal } from '../../components/ExpenseCategoriesEditSettingsModal';

type Props = {
  isOpen: boolean;
  expenseCategoryCustomField: CustomField;
  onComplete(): void;
  onClose(): void;
};

export const ExpenseCategoriesEditSettingsContainer = ({
  isOpen,
  expenseCategoryCustomField,
  onComplete,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');

  const { dangerNotif, successNotif } = useNotifications();

  const [handleOnSubmit] = useUpdateCustomFieldMutation(
    expenseCategoryCustomField.id,
  );

  if (!isOpen) {
    return <></>;
  }

  return (
    <ExpenseCategoriesEditSettingsModal
      isOpen={isOpen}
      expenseCategoryCustomField={expenseCategoryCustomField}
      onSubmit={async (newExpenseCategoryCustomField) => {
        const payload = {
          isRequired: newExpenseCategoryCustomField.isRequired,
          isSplittable: newExpenseCategoryCustomField.isSplittable,
        };

        try {
          await handleOnSubmit(payload);
          successNotif(t('expenseCategories.edit.editSuccess'));
          onComplete();
        } catch {
          dangerNotif(t('expenseCategories.edit.editError'));
        }
      }}
      onCancel={onClose}
    />
  );
};
