import isEqual from 'lodash/isEqual';
import { useQueryClient } from 'react-query';

import { useUpdatePolicy } from 'modules/company/members/components/ApprovalPolicies/hooks/useUpdatePolicy';
import { useUpdateUsersPolicy } from 'modules/company/members/components/ApprovalPolicies/hooks/useUpdateUsersPolicy';
import { reshapeFromSpendingTypes } from 'modules/company/members/components/ApprovalPolicies/reshapers';

import { type ApprovalPolicyFormValues } from '../../../components/EditApprovalPolicyModal';
import { type Member } from '../../../models/member';
import { type Policy } from '../../../models/policy';

type Props = {
  policy: Policy;
  policyMembers: Member[];
  standardPolicyId: string | undefined;
};

export const useEditUsersPolicyMutation = ({
  policy,
  policyMembers,
  standardPolicyId,
}: Props) => {
  const [updatePolicy] = useUpdatePolicy(policy.id);
  const [updateUsersPolicy] = useUpdateUsersPolicy(policy.id);
  const [updateUsersStandardPolicy] = useUpdateUsersPolicy(
    standardPolicyId || '',
  );

  const queryClient = useQueryClient();

  const updateFunction = async (values: ApprovalPolicyFormValues) => {
    const initialMemberIds = policyMembers.map((member) => member.id);
    const unassignedMemberIds = initialMemberIds.filter((initialMemberId) => {
      return !values.membersIds.includes(initialMemberId);
    });

    await updatePolicy({
      name: values.name || '',
      is_default: policy.isDefault,
      is_user_custom: false,
      params: {
        approval_needed: values.approvalNeeded,
        spending_types: reshapeFromSpendingTypes(values.spendingTypes),
        spending_limit: values.amountPerMonth,
        transaction_max: values.amountPerTransaction,
      },
    });
    if (!isEqual(initialMemberIds.sort(), values.membersIds.sort())) {
      await updateUsersPolicy({ user_ids: values.membersIds });
      await updateUsersStandardPolicy({ user_ids: unassignedMemberIds });
    }
    queryClient.invalidateQueries(['users']);
    queryClient.invalidateQueries(['policies']);
  };

  return [updateFunction];
};
