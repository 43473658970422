import chunk from 'lodash/chunk';
import queryString from 'query-string';
import { type QueryKey, useQueryClient, type InfiniteData } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import type {
  InvoiceRequest,
  InvoiceRequestData,
} from '@finance-review/models/invoice';
import type { AppDispatch } from 'modules/app/redux/store';
import { type SupplierId } from 'modules/suppliers';
import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';
import { type AppState } from 'src/core/reducers';

import { fetchInvoicesCounts } from '../../../../invoices/redux/thunks';
import type { RawInvoiceData } from '../transformers';
import { transformInvoiceData } from '../transformers';
import { updateInvoiceRequestQueryData } from '../useFetchDraftInvoiceRequest';

export type InvoiceQueryFilters = {
  status?: InvoiceRequest.Status[] | InvoiceRequest.Status;
  supplier?: SupplierId;
  search?: string;
  with_applied_credit_notes?: boolean;
  pageSize?: number;
};

export const useInvoiceReviewListQuery = (
  filters: InvoiceQueryFilters,
): InfiniteQueryState<InvoiceRequestData[]> => {
  const qs = queryString.stringify(filters);
  const queryKey = getQueryKey(filters);

  return useInfiniteQuery<InvoiceRequestData, RawInvoiceData[]>({
    key: queryKey,
    getRequest(page = 0) {
      const params =
        filters.pageSize && filters.pageSize > 0
          ? {
              offset:
                filters.pageSize *
                (typeof page === 'number' ? page : Number.parseInt(page)),
              max_results: filters.pageSize,
            }
          : {};

      return {
        type: 'rest',
        target: 'companyAPI',
        endpoint: `/invoice_requests?${qs}`,
        params,
      };
    },
    getNextPageParam(lastPage, allPages) {
      if (
        filters?.pageSize === undefined ||
        lastPage === undefined ||
        lastPage.length < filters.pageSize
      ) {
        return undefined;
      }
      const nextPage = allPages.length;
      return nextPage.toString();
    },
    options: {
      staleTime: 60 * 1000 * 3,
      onSuccess: ({ rawData, client }): void => {
        rawData.pages
          .flat()
          .forEach((rawInvoice) =>
            updateInvoiceRequestQueryData(client, rawInvoice),
          );
      },
    },
    reshapeData: invoiceListReshaper,
  });
};

const invoiceListReshaper = (
  rawInvoices: RawInvoiceData[],
): InvoiceRequestData[] => {
  return rawInvoices.map(transformInvoiceData);
};

export const useInvalidateInvoiceReviewListQuery =
  (): (() => Promise<void>) => {
    const queryClient = useQueryClient();

    return async (): Promise<void> => {
      await queryClient.invalidateQueries<RawInvoiceData[]>([
        'invoice-requests',
      ]);
    };
  };

export const useUpdateInvoiceCacheEntry = () => {
  const filters = useSelector((state: AppState) => state.invoices.filters);
  const queryClient = useQueryClient();

  return (rawInvoice: RawInvoiceData): void => {
    const queryKey = getQueryKey(filters);

    const cache =
      queryClient.getQueryData<InfiniteData<RawInvoiceData[]>>(queryKey);

    if (cache) {
      const newInvoices = updateInvoiceInPages(cache.pages, rawInvoice);
      queryClient.setQueryData<InfiniteData<RawInvoiceData[]>>(queryKey, {
        ...cache,
        pages: newInvoices,
      });
    }
  };
};

export const useRemoveInvoicesCacheEntries = () => {
  const dispatch = useDispatch<AppDispatch>();
  const filters = useSelector((state: AppState) => state.invoices.filters);
  const queryClient = useQueryClient();

  return (invoiceIds: InvoiceRequest.EntityId[]): void => {
    const queryKey = getQueryKey(filters);

    const cache =
      queryClient.getQueryData<InfiniteData<RawInvoiceData[]>>(queryKey);

    if (cache) {
      const filteredInvoices = removeInvoicesInPages(
        cache.pages,
        invoiceIds,
        filters.pageSize,
      );
      queryClient.setQueryData<InfiniteData<RawInvoiceData[]>>(queryKey, {
        ...cache,
        pages: filteredInvoices,
      });
      // TODO@financeOps: manually update the invoice to schedule count if paid with wire transfer
      // + migrate count to useQuery
      // cf https://spendesk.atlassian.net/browse/PAY-4623
      // sometimes the back-end is not updated yet when we fetch the counts after a request validation
      // setting a timeout to improve chances of getting the last count data
      setTimeout(() => dispatch(fetchInvoicesCounts()), 500);
    }
  };
};

const getQueryKey = (filters: InvoiceQueryFilters): QueryKey => {
  const qs = queryString.stringify(filters, { arrayFormat: 'index' });
  return ['invoice-requests', qs];
};

const updateInvoiceInPages = (
  pages: RawInvoiceData[][],
  updatedInvoice: RawInvoiceData,
): RawInvoiceData[][] => {
  return pages.map((page) =>
    page.map((invoice) =>
      invoice.id === updatedInvoice.id ? updatedInvoice : invoice,
    ),
  );
};

const removeInvoicesInPages = (
  pages: RawInvoiceData[][],
  invoiceIds: InvoiceRequest.EntityId[],
  pageSize?: number,
): RawInvoiceData[][] => {
  const filteredInvoices = pages
    .flat()
    .filter((invoice: RawInvoiceData) => !invoiceIds.includes(invoice.id));
  return pageSize ? chunk(filteredInvoices, pageSize) : [filteredInvoices];
};
