import { Button, SkeletonButton } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import { type LocationDescriptor } from 'history';
import { type TFunction } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { type QueryState } from 'src/core/api/queryState';
import { CountryFlag } from 'src/core/common/components/CountryFlag/CountryFlag';
import { getCountryFromCode } from 'src/core/config/country';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { EntityGenericStatisticsBlock } from './EntityGenericStatisticsBlock';
import { OrganisationReportingEntityLayout } from './OrganisationReportingEntityLayout';
import { OrganisationReportingEntityPin } from './OrganisationReportingEntityPin/OrganisationReportingEntityPin';
import {
  OrganisationReportingEntityStatisticsCallout,
  type OrganisationReportingEntityStatusForCallout,
} from './OrganisationReportingEntityStatisticsCallout/OrganisationReportingEntityStatisticsCallout';
import { OrganisationReportingEntityStatisticsWalletSummary } from './OrganisationReportingEntityStatisticsWalletSummary';
import { useOrganisationEntityWalletSummaryQuery } from '../../../hooks/data/useOrganisationEntityWalletSummaryQuery';
import { useOrganisationReportingEntityStatistics } from '../../../hooks/data/useOrganisationReportingEntityStatistics';
import { type OrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import type {
  ExtendedOrganisationReportingEntity,
  OrganisationReportingStatistics,
  OrganisationReportingEntity,
  OrganisationReportingKycStatus,
  OrganisationReportingWalletDetails,
} from '../../../types';

type CountryCode = Parameters<typeof getCountryFromCode>[0];

type EntityStatisticDefinitions = Record<
  keyof OrganisationReportingStatistics,
  {
    isAvailable: boolean;
    title: string;
    link: LocationDescriptor;
    label: string | null;
    trackingEventName?: AnalyticEventName;
  }
>;

type Props = {
  features: OrganisationFeatures;
  entity: OrganisationReportingEntity;
  isPinned: boolean;
  togglePinnedEntity: (entityId: string) => void;
  onDataLoaded: (
    entityData:
      | OrganisationReportingEntity
      | ExtendedOrganisationReportingEntity,
    status: QueryState['status'],
  ) => void;
};

export const OrganisationReportingEntityStatistics = ({
  entity,
  isPinned,
  togglePinnedEntity,
  features,
  onDataLoaded,
}: Props) => {
  const { t } = useTranslation('global');

  const entityWalletSummaryQueryState = useOrganisationEntityWalletSummaryQuery(
    entity.id,
    { isEnabled: features.wallet },
  );
  const entityStatisticsQueryState = useOrganisationReportingEntityStatistics(
    entity,
    features,
  );

  useEffect(() => {
    if (
      ['success', 'error'].includes(entityStatisticsQueryState.status) &&
      ['success', 'error'].includes(entityWalletSummaryQueryState.status)
    ) {
      onDataLoaded(
        {
          ...entity,
          ...(entityWalletSummaryQueryState.status === 'success'
            ? entityWalletSummaryQueryState.data
            : {}),
        },
        entityStatisticsQueryState.status,
      );
      return () => {};
    }
  }, [entityStatisticsQueryState.status, entityWalletSummaryQueryState.status]);

  const entityStatisticsDefinitions: EntityStatisticDefinitions = {
    requestsToApprove: {
      isAvailable: features.requestsToApprove,
      title: t('organisation.reporting.page.entities.entity.requestsToApprove'),
      link: routeFor(routes.REQUESTS.path, {
        company: entity.id,
        type: 'to-approve',
      }),
      label: null,
      trackingEventName:
        AnalyticEventName.ORGANISATION_REPORTING_REQUESTS_TO_APPROVE_BUTTON_CLICKED,
    },
    invoicesToPay: {
      isAvailable: features.invoicesToPay,
      title: t('organisation.reporting.page.entities.entity.invoicesToPay'),
      link: routeFor(routes.INVOICES_PAY.path, {
        company: entity.id,
      }),
      label: null,
      trackingEventName:
        AnalyticEventName.ORGANISATION_REPORTING_INVOICES_TO_PAY_BUTTON_CLICKED,
    },
    lateReceipts: {
      isAvailable: features.lateReceipts,
      title: t('organisation.reporting.page.entities.entity.lateReceipts'),
      link: `${routeFor(routes.PAYMENTS_ALL.path, {
        company: entity.id,
      })}?completionDeadline=late`,
      label: null,
      trackingEventName:
        AnalyticEventName.ORGANISATION_REPORTING_LATE_RECEIPTS_BUTTON_CLICKED,
    },
    payablesToReview: {
      isAvailable: features.payablesToReview,
      title: t('organisation.reporting.page.entities.entity.payablesToReview'),
      link: routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
        company: entity.id,
      }),
      label: null,
      trackingEventName:
        AnalyticEventName.ORGANISATION_REPORTING_PAYABLES_TO_REVIEW_BUTTON_CLICKED,
    },
  };

  const placeholder = '-';
  const setStatisticsIntoDefinitions = (
    entityStatistics: OrganisationReportingStatistics,
  ) => {
    entityStatisticsDefinitions.requestsToApprove.label = String(
      entityStatistics.requestsToApprove ?? placeholder,
    );
    entityStatisticsDefinitions.invoicesToPay.label = String(
      entityStatistics.invoicesToPay ?? placeholder,
    );
    entityStatisticsDefinitions.lateReceipts.label = String(
      entityStatistics.lateReceipts ?? placeholder,
    );
    entityStatisticsDefinitions.payablesToReview.label = String(
      entityStatistics.payablesToReview ?? placeholder,
    );
  };

  const entityStatus = (() => {
    if (entityWalletSummaryQueryState.status !== 'success') {
      return undefined;
    }

    return getEntityStatus(entity, entityWalletSummaryQueryState.data);
  })();

  const entityStatistics = (() => {
    if (
      entityStatisticsQueryState.status !== 'success' ||
      entityWalletSummaryQueryState.status !== 'success' // Necessary for using entity status as it depends on wallet funds
    ) {
      return (
        <>
          {Object.entries(entityStatisticsDefinitions)
            // FIXME Perf issue: Filtering in DOM
            .filter(([, { isAvailable }]) => isAvailable)
            .map(([key, { title }]) => (
              <div key={key} className="flex-[2]">
                <EntityGenericStatisticsBlock
                  title={title}
                  contents={[
                    <SkeletonButton className="w-xxl" key="skeleton" />,
                  ]}
                />
              </div>
            ))}
        </>
      );
    }

    const entityStatisticsData = entityStatisticsQueryState.data;

    // TODO: Remove this when we want to display churned entities
    // See https://spendesk.atlassian.net/browse/ECO-395
    if (entity.hasChurned) {
      return null;
    }

    setStatisticsIntoDefinitions(entityStatisticsData);

    const statusesForInactiveState: OrganisationReportingEntityStatusForCallout[] =
      ['kycInProgress', 'awaitingKycApproval'] as const;
    const isInactive =
      entityStatus && statusesForInactiveState.includes(entityStatus);
    // const cannotAccessEntity =
    //   entityStatus === 'kycInProgress' ? false : isInactive;

    return (
      <>
        {Object.entries(entityStatisticsDefinitions)
          // FIXME Perf issue: Filtering in DOM
          .filter(([, { isAvailable }]) => isAvailable)
          .map(([key, { title, link, label, trackingEventName }]) => (
            <div key={key} className="flex-[2]">
              <EntityGenericStatisticsBlock
                title={title}
                contents={[
                  isInactive || entityStatus === 'churning' ? (
                    <p className="items-center justify-center text-center leading-[16px] text-neutral-dark title-m">
                      -
                    </p>
                  ) : (
                    label && (
                      <Button
                        component="a"
                        href={link.toString()}
                        target="_blank"
                        variant="ghost"
                        className={classnames(
                          label === '0' && 'text-neutral-dark',
                        )}
                        text={label}
                        onClick={
                          trackingEventName &&
                          (() =>
                            track(trackingEventName, {
                              value: label,
                            }))
                        }
                        iconName="caret-right"
                        iconPosition="right"
                      />
                    )
                  ),
                ]}
              />
            </div>
          ))}
      </>
    );
  })();

  const statusesForInactiveState: OrganisationReportingEntityStatusForCallout[] =
    ['kycInProgress', 'awaitingKycApproval'] as const;
  const isInactive =
    entityStatus && statusesForInactiveState.includes(entityStatus);
  const cannotAccessEntity =
    entityStatus === 'kycInProgress' ? false : isInactive;

  return (
    <div className={classnames('relative flex flex-col content-stretch')}>
      <OrganisationReportingEntityLayout entityStatus={entityStatus}>
        <div className="flex-0 w-fit self-center">
          <OrganisationReportingEntityPin
            isPinned={isPinned}
            togglePinnedEntity={() => {
              togglePinnedEntity(entity.id);
            }}
          />
        </div>
        <div className="gap-m flex flex-auto flex-wrap">
          <div className="flex-[3]">
            <EntityGenericStatisticsBlock
              title={getBranchTypeTitle(entity, t)}
              contents={[
                cannotAccessEntity ? (
                  <h3 className="text-complementary title-m">{entity.name}</h3>
                ) : (
                  <a
                    key="name"
                    href={routeFor(routes.HOMEPAGE.path, {
                      company: entity.id,
                    })}
                    target="_blank"
                    rel="noreferrer"
                    className="text-complementary underline title-m"
                    onClick={() =>
                      track(
                        AnalyticEventName.ORGANISATION_REPORTING_OPEN_ENTITY_BUTTON_CLICKED,
                      )
                    }
                  >
                    {entity.name}
                  </a>
                ),
                <div key="country" className="gap-xxs flex items-center">
                  {getPrettyCountryFromCode(entity.country, t)}
                  <CountryFlag
                    country={entity.country}
                    className="h-[18px] w-[18px]"
                  />
                </div>,
              ]}
              stack
            />
          </div>

          <OrganisationReportingEntityStatisticsWalletSummary
            entity={entity}
            entityStatus={entityStatus}
            entityWalletSummaryQueryState={entityWalletSummaryQueryState}
            features={features}
          />

          {entityStatistics}
        </div>
      </OrganisationReportingEntityLayout>

      {entityStatus && (
        <OrganisationReportingEntityStatisticsCallout
          state={entityStatus}
          entityId={entity.id}
        />
      )}
    </div>
  );
};

const getBranchTypeTitle = (
  { type }: OrganisationReportingEntity,
  t: TFunction<'global', undefined>,
): string => {
  switch (type) {
    case 'branch_currency':
      return t(
        'organisation.reporting.page.entities.entity.type.branchCurrency',
      );
    case 'branch_expense_entity':
      return t(
        'organisation.reporting.page.entities.entity.type.expenseEntity',
      );
    default:
      return t('organisation.reporting.page.entities.entity.type.legalEntity');
  }
};

const getPrettyCountryFromCode = (
  countryCode: CountryCode,
  t: TFunction<'global', undefined>,
) => {
  const country = getCountryFromCode(countryCode);

  if (country) {
    return t(country.translationKey);
  }

  return countryCode;
};

export const getEntityStatus = (
  entity: OrganisationReportingEntity,
  entityWalletSummary: OrganisationReportingKycStatus &
    OrganisationReportingWalletDetails,
): OrganisationReportingEntityStatusForCallout | undefined => {
  if (entityWalletSummary.isKycInProgress) {
    return 'kycInProgress';
  }
  if (entityWalletSummary.isKycAwaitingApproval) {
    return 'awaitingKycApproval';
  }
  if (entity.isChurning) {
    return 'churning';
  }
  if (entity.hasChurned) {
    return entityWalletSummary.walletBalance > 0
      ? 'churnedWithRemainingFunds'
      : 'churned';
  }

  return undefined;
};
