import { DATE_FORMAT } from '@dev-spendesk/grapes';
import React from 'react';

import { PanelItemsSection } from 'common/components/Panel';
import { useTranslation } from 'common/hooks/useTranslation';
import { formatMonetaryValue } from "src/core/utils/monetaryValue";

import { type TransferDetails } from '../../../models';

type Props = {
  transfer: TransferDetails;
};

export const DetailsSection = ({ transfer }: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const { bill } = transfer;

  return (
    <PanelItemsSection
      title={t('purchaseOrders.panel.details')}
      items={[
        {
          label: t('invoices.transfer.panel.globalAmount'),
          value: formatMonetaryValue(bill.amount),
        },
        {
          label: t('invoices.transfer.panel.invoiceNumber'),
          value: bill.documentaryEvidence.invoiceNumber,
        },
        {
          label: t('invoices.transfer.panel.issueDate'),
          value: localeFormat(
            new Date(bill.spendingCommitment.issueDate),
            DATE_FORMAT.SHORT,
          ),
        },
        {
          label: t('invoices.transfer.panel.dueDate'),
          value: localeFormat(new Date(bill.dueDate), DATE_FORMAT.SHORT),
        },
      ]}
    />
  );
};
