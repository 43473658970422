import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { ACCOUNT_TYPES } from '../models/accountTypes';

type Payload = {
  parentCompanyId: string;
  name: string;
  currency: string;
};

type Response = {
  id: string;
};

type RawResponse = {
  id: string;
};

export const useCreateSFSCurrencyBranchMutation = (): MutationState<
  Payload,
  Response
> => {
  const companyId = useCompanyId();

  return useMutation<Payload, Response, RawResponse>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'baseAPI',
      endpoint: `/${companyId}/branch/sfs?type=${ACCOUNT_TYPES.CURRENCY}`,
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['companies']);
        client.invalidateQueries(['companyWalletSummaries']);
      },
    },
  });
};
