import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type DefaultEmployeeAccount } from '../../accounting';

type RawData = {
  employeeAccount: DefaultEmployeeAccount | undefined;
};

export type Result = DefaultEmployeeAccount | undefined;

export const useGetDefaultEmployeeAccountQueryKey =
  'useGetDefaultEmployeeAccountQuery';

export const useGetDefaultEmployeeAccountQuery = (
  { isEnabled } = { isEnabled: true },
): QueryState<Result> => {
  const { company: companyId } = useParams();

  return useQuery<Result, RawData>({
    key: [useGetDefaultEmployeeAccountQueryKey, companyId],
    isEnabled,
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint:
        '/accounting-integration/chart-of-accounts/employee-accounts/default',
    },
    reshapeData(data) {
      // TODO remove the delete after deleting `code` from the REST API
      if (data.employeeAccount && data.employeeAccount.code) {
        delete data.employeeAccount.code;
      }
      return data.employeeAccount;
    },
  });
};
