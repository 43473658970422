import { Button, Label } from '@dev-spendesk/grapes';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import './HomepageBankAccountNotConnected.css';

type Props = {
  company: {
    id: string;
  };
};

export const HomepageBankAccountNotConnected = ({ company }: Props) => {
  const { t } = useTranslation('global');
  return (
    <div className="HomepageBankAccountNotConnected">
      <Label
        className="HomepageBankAccountNotConnected__title"
        label={t('homepage.bankAccountNotConnected.title')}
      />

      <p className="HomepageBankAccountNotConnected__description">
        {t('homepage.bankAccountNotConnected.description')}
      </p>
      <Link
        to={routeFor(routes.COMPANY_BANK_FUNDING_SOURCE.path, {
          company: company.id,
        })}
      >
        <Button
          className="HomepageBankAccountNotConnected__button"
          text={t('homepage.bankAccountNotConnected.cta')}
          variant="primary"
        />
      </Link>
    </div>
  );
};
