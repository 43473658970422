import * as actionTypes from './actionTypes';

export const exportExpensesRequest = (): actionTypes.ExportExpensesRequest => ({
  type: actionTypes.EXPORT_EXPENSES_REQUEST,
});

export const exportExpensesSuccess = (): actionTypes.ExportExpenseSuccess => ({
  type: actionTypes.EXPORT_EXPENSES_SUCCESS,
});

export const exportExpensesFailure = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
): actionTypes.ExportExpensesFailure => ({
  type: actionTypes.EXPORT_EXPENSES_FAILURE,
  error,
});

export const exportExpensesReset = (): actionTypes.ExportExpensesReset => ({
  type: actionTypes.EXPORT_EXPENSES_RESET,
});

export const fetchUnreadExportsSuccess = (
  payload: actionTypes.FetchUnreadExportsSuccess['payload'],
): actionTypes.FetchUnreadExportsSuccess => ({
  type: actionTypes.FETCH_UNREAD_EXPORTS_SUCCESS,
  payload,
});
