import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { type QueryState } from 'src/core/api/queryState';
import { getSelf as getUser } from 'src/core/selectors/users';

import { useFetchFinanceControllers } from './api';
import { type User } from '../models';

export const useIsFinanceController = (): QueryState<boolean> => {
  const user: User = useSelector(getUser);
  const fetchFinanceControllersQueryState = useFetchFinanceControllers();

  return useMemo(() => {
    if (fetchFinanceControllersQueryState.status === 'success') {
      const financeControllers = fetchFinanceControllersQueryState.data;
      const isFinanceController = financeControllers.some(
        (financeController) => financeController.id === user.id,
      );

      return {
        ...fetchFinanceControllersQueryState,
        data: isFinanceController,
      };
    }

    return fetchFinanceControllersQueryState;
  }, [
    fetchFinanceControllersQueryState.status,
    fetchFinanceControllersQueryState.status === 'success'
      ? fetchFinanceControllersQueryState.data
      : undefined,
  ]);
};
