import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type FieldName, type Form } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<TForm extends Form<any>> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  form: TForm extends Form<infer TData> ? TForm : never;
  key: FieldName<TForm>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useErrorMessage<TForm extends Form<any>>({
  form,
  key,
}: Props<TForm>) {
  const { t } = useTranslation('global');

  const error = form.errors[key];

  if (!error) {
    return;
  }

  if (typeof error === 'string') {
    return error;
  }

  return t('forms.errorMessages.invalid');
}
