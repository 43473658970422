import React from 'react';
import { useSelector } from 'react-redux';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import withErrorBoundary from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useCompany } from 'modules/app/hooks/useCompany';
import { LatePaymentsSection } from 'modules/control-rules/components/LatePaymentsSection';
import { PurchaseOrderDashboardSummary } from 'modules/purchase-orders/components/';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';
import { getUserPaymentCount } from 'src/core/selectors/users';

import { PaymentsListSection } from './components/PaymentsListSection';
import { PaymentsListSectionLoader } from './components/PaymentsListSectionLoader';
import { RestrictedAccessPanel } from './components/RestrictedAccessPanel';
import { SpendingRules } from './components/SpendingRules/SpendingRules';
import { usePaymentsToCompleteQuery, useUserControlRuleQuery } from './hooks';
import { useCardsAccessQuery } from '../Homepage/hooks';

const RequesterHomepage = () => {
  const company = useCompany();
  const userPaymentCount = useSelector((state: AppState) =>
    getUserPaymentCount(state, company.id),
  );
  const hasPurchaseOrderFeatureActivated = useFeature(
    FEATURES.PURCHASE_ORDERS_ACTIVATED,
  );
  const hasPlayByTheRulesReceiptAndFieldsFeature = useFeature(
    FEATURES.PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS,
  );

  const controlRuleQueryState = useUserControlRuleQuery();
  const paymentsListQueryState = useQueryStates({
    states: {
      payments: usePaymentsToCompleteQuery({
        companyCurrency: company.currency,
      }),
      controlRule: controlRuleQueryState,
    },
    reshapeData({ payments, controlRule }) {
      const paymentsWithLateCompletionDeadline = payments.filter(
        (payment) => payment.completionDeadline?.status === 'late',
      );
      return {
        payments,
        controlRule,
        overduePaymentsCount: paymentsWithLateCompletionDeadline.length,
      };
    },
  });
  const rulesPanelQueryState = useQueryStates({
    states: {
      cardsAccess: useCardsAccessQuery(),
      controlRule: controlRuleQueryState,
    },
  });

  return (
    <div className="flex flex-col flex-wrap items-start gap-xl lg:flex-row">
      <section className="flex grow flex-col gap-xl">
        {hasPlayByTheRulesReceiptAndFieldsFeature ? (
          <LatePaymentsSection />
        ) : (
          <div className="box pb-0">
            <QuerySuspense
              queryState={paymentsListQueryState}
              loading={<PaymentsListSectionLoader />}
              fallback={(error) => (
                <QueryError
                  queryError={error}
                  componentType="Text"
                  className="mb-m block text-alert"
                />
              )}
            >
              {({ payments, controlRule, overduePaymentsCount }) => (
                <PaymentsListSection
                  context={{
                    companyId: company.id,
                    userHasMadePayments: userPaymentCount > 0,
                  }}
                  payments={payments}
                  controlRule={controlRule}
                  overduePaymentsCount={overduePaymentsCount}
                />
              )}
            </QuerySuspense>
          </div>
        )}
        {hasPurchaseOrderFeatureActivated && <PurchaseOrderDashboardSummary />}
      </section>
      <section className="flex shrink flex-col content-stretch gap-xl">
        <QuerySuspense
          queryState={rulesPanelQueryState}
          loading={null}
          fallback={() => null}
        >
          {({ cardsAccess, controlRule }) => {
            if (!cardsAccess.hasAccess && cardsAccess.initiator === 'user') {
              return <RestrictedAccessPanel />;
            }

            return (
              <SpendingRules
                incompletePaymentsLimit={controlRule.incompletePaymentsLimit}
                completionDeadline={controlRule.completionDeadline}
              />
            );
          }}
        </QuerySuspense>
      </section>
    </div>
  );
};

const ConnectedRequesterHomepage = withErrorBoundary({
  team: 'capture',
  scope: 'requester-home-page',
})(RequesterHomepage);

export { ConnectedRequesterHomepage as RequesterHomepage };
