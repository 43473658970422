import { useTranslation } from 'src/core/common/hooks/useTranslation';

import accessBlockedSource from './assets/accessBlocked.svg';
import lateReceiptSource from './assets/lateReceipt.svg';
import missingReceiptSource from './assets/missingReceipt.svg';
import { PreviewCard } from './components/PreviewCard';

type Props = {
  completionDeadline: number;
  incompletePaymentsLimit: number;
};

export const ControlRulePreview = ({
  completionDeadline,
  incompletePaymentsLimit,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="flex flex-col items-center">
      <div className="mb-xl flex flex-col items-center">
        <h3 className="text-primary title-xl">
          {t('setupHub.controlRules.preview.title')}
        </h3>
        <a
          className="text-neutral-darker underline body-s"
          href="https://helpcenter.spendesk.com/articles/4240464"
          target="_blank"
          rel="noreferrer"
        >
          {t('setupHub.controlRules.preview.subtitle')}
        </a>
      </div>
      <div className="flex flex-col gap-l">
        <PreviewCard
          title={t('setupHub.controlRules.preview.missingReceipt.title', {
            count: completionDeadline - 1,
          })}
          description={t(
            'setupHub.controlRules.preview.missingReceipt.description',
          )}
          illustration={<img src={missingReceiptSource} alt="" />}
        />
        <PreviewCard
          title={t('setupHub.controlRules.preview.lateReceipt.title', {
            count: completionDeadline,
          })}
          description={t(
            'setupHub.controlRules.preview.lateReceipt.description',
          )}
          illustration={<img src={lateReceiptSource} alt="" />}
        />
        <PreviewCard
          title={t('setupHub.controlRules.preview.accessBlocked.title', {
            count: incompletePaymentsLimit || 1,
          })}
          description={t(
            'setupHub.controlRules.preview.accessBlocked.description',
          )}
          illustration={<img src={accessBlockedSource} alt="" />}
        />
      </div>
    </div>
  );
};
