import { gql } from 'graphql-tag';

export const GET_TEAMS = gql`
  query GetTeams($companyId: ID!, $filter: TeamFilter, $first: Int) {
    company(id: $companyId) {
      id
      teams(filter: $filter, first: $first) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
