import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export type DelegationConfirmationModalProps = {
  onApprove: () => void;
  onClose: () => void;
  isOpen: boolean;
  isApproving: boolean;
};

export const DelegationConfirmationModal = ({
  onApprove,
  onClose,
  isOpen,
  isApproving,
}: DelegationConfirmationModalProps) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('misc.cancel')}
          variant="secondary"
          isDisabled={isApproving}
        />,
        <Button
          key="confirm"
          text={t('members.editModal.additionalRights.confirmPermission')}
          variant="primary"
          onClick={onApprove}
          isDisabled={isApproving}
        />,
      ]}
      iconName="bell"
      iconVariant="primary"
      title={t('members.editModal.additionalRights.mfa.confirmTitle')}
      isOpen={isOpen}
    >
      <div>
        {t('members.editModal.additionalRights.mfa.confirmDescription')}
      </div>
    </Modal>
  );
};
