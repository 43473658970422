import { Button, Modal } from '@dev-spendesk/grapes';
import { t } from 'i18next';

import tomTalker from './tomtalker.svg';

type Props = {
  onClose: () => void;
  onShowPasswordModal: () => void;
};

export const SfsUkPasswordEducation = ({
  onClose,
  onShowPasswordModal,
}: Props) => {
  return (
    <Modal
      illustration={
        <div className="flex w-full items-center justify-center">
          <img
            src={tomTalker}
            alt=""
            className="max-h-[100%] min-h-0 w-[100%] min-w-0"
          />
        </div>
      }
      illustrationHeight="424px"
      // @ts-expect-error Grapes hacking time because Georges's arm is hanging out
      title={
        <div className="-mt-m">
          {t('requests.sfsUkPasswordEducationModal.title')}
        </div>
      }
      subtitle={t('requests.sfsUkPasswordEducationModal.description')}
      isOpen
      onClose={onClose}
      actions={[
        <div key="ctas" className="flex flex-col items-center">
          <Button
            onClick={onShowPasswordModal}
            text={t('requests.sfsUkPasswordEducationModal.closeButton')}
            variant="primary"
          />
        </div>,
      ]}
    ></Modal>
  );
};
