import cx from 'classnames';
import React from 'react';

import styles from './MapSkeleton.module.css';

export const MapSkeleton = () => {
  return (
    <div className={styles.MapSkeletonContainer}>
      <div className={cx(styles.MapSkeleton, 'flex-[1_0_24rem]')} />
    </div>
  );
};
