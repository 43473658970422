import {
  type I18nKey,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';
import {
  type BulkMemberInviteValidateFailure,
  type ParseBulkMemberInviteFailure,
  type ParseBulkMemberInviteFailureData,
  type ParseBulkMemberInviteFailureFileRowsLimit,
  type ParseBulkMemberInviteFailurePlanLimit,
} from 'modules/members/models/fileInviteResult';
import { type InvitationResultMessage } from 'modules/members/models/invite';

const getBulkImportErrorTextKey = (
  error: BulkMemberInviteValidateFailure,
): I18nKey => {
  switch (error.reason) {
    case 'missing_required_first_name':
      return 'members.fileUploadErrors.missingRequiredFirstName';
    case 'missing_required_last_name':
      return 'members.fileUploadErrors.missingRequiredLastName';
    case 'missing_required_email':
      return 'members.fileUploadErrors.missingRequiredEmail';
    case 'missing_required_iban':
      return 'members.fileUploadErrors.missingRequiredIban';
    case 'missing_required_bic':
      return 'members.fileUploadErrors.missingRequiredBic';
    case 'invalid_email':
      return 'members.fileUploadErrors.invalidEmail';
    case 'invalid_role':
      return 'members.fileUploadErrors.invalidRole';
    case 'invalid_cost_center':
      return 'members.fileUploadErrors.invalidCostCenter';
    case 'invalid_approval_policy':
      return 'members.fileUploadErrors.invalidApprovalPolicy';
    case 'already_invited_to_company':
      return 'members.fileUploadErrors.alreadyInvitedToCompany';
    case 'registered_in_another_organisation':
      return 'members.fileUploadErrors.registeredInAnotherOrganisation';
    case 'user_update_forbidden':
      return 'members.fileUploadErrors.userUpdateForbidden';
    default:
      return 'members.fileUploadErrors.userInviteImpossible';
  }
};

export const getBulkImportErrorReasons = (
  result: ParseBulkMemberInviteFailure,
  t: TGlobalFunctionTyped,
): InvitationResultMessage[] => {
  switch (result.reason) {
    case 'data_errors':
      return (result as ParseBulkMemberInviteFailureData).errors.map(
        (error: BulkMemberInviteValidateFailure) => {
          const rows = error.rows.join(', ');
          const key = getBulkImportErrorTextKey(error);
          return {
            text: t(key, {
              rows,
              count: error.rows.length,
            }),
            isError: true,
          };
        },
      );
    case 'no_member_data_found':
    case 'no_worksheet_found':
      return [{ text: t('members.fileUploadErrors.fileEmpty'), isError: true }];
    case 'too_many_members':
      if (
        (
          result as
            | ParseBulkMemberInviteFailurePlanLimit
            | ParseBulkMemberInviteFailureFileRowsLimit
        ).isPlanLimit
      ) {
        return [
          {
            text: t('members.fileUploadErrors.tooManyMembersPlanLimit', {
              maxAllowed: (result as ParseBulkMemberInviteFailurePlanLimit)
                .maxAllowed,
              numCurrent: (result as ParseBulkMemberInviteFailurePlanLimit)
                .numCurrent,
              numInviting: (result as ParseBulkMemberInviteFailurePlanLimit)
                .numInviting,
            }),
            isError: true,
          },
        ];
      }
      return [
        {
          text: t('members.fileUploadErrors.tooManyMembersFileRowsLimit', {
            inviteLimit: (result as ParseBulkMemberInviteFailureFileRowsLimit)
              .inviteLimit,
            numInviting: (result as ParseBulkMemberInviteFailureFileRowsLimit)
              .numInviting,
          }),
          isError: true,
        },
      ];
    case 'unauthorised':
      return [
        {
          text: t('members.fileUploadErrors.unauthorised'),
          isError: true,
        },
      ];
    case 'couldnt_read_file':
    case 'couldnt_parse_file':
    case 'couldnt_extract_member_data':
    case 'invalid_member_data_format':
      return [
        {
          text: t('members.fileUploadErrors.incorrectFileFormat'),
          isError: true,
        },
      ];
    default:
      return [
        {
          text: t('members.fileUploadErrors.unknownError'),
          isError: true,
        },
      ];
  }
};
