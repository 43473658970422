import { useSelector } from 'react-redux';

import { useWalletTransactionForecastQuery } from 'modules/company';

import { walletAmountAllocationToCheckForMissingFunds } from '../models';
import { selectors } from '../redux';

export const useWalletTransactionForecastOnSelectedTransfersQuery = () => {
  const transfersSelectedAmountSum = useSelector(
    selectors.selectTransfersSelectedAmountSum,
  );

  const getWalletTransactionForecastQueryState =
    useWalletTransactionForecastQuery();

  return (): ReturnType<typeof getWalletTransactionForecastQueryState> =>
    getWalletTransactionForecastQueryState(
      transfersSelectedAmountSum,
      walletAmountAllocationToCheckForMissingFunds,
    );
};
