import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { getHelpCenterBase } from 'common/utils/help-center/getHelpCenterBase';
import { useFeature } from "src/core/common/hooks/useFeature";
import { wwwBaseUrl } from "src/core/config";
import FEATURES from "src/core/constants/features";
import { AnalyticEventName, track } from "src/core/utils/analytics";
import {
  isSupportChatAvailable,
  openSupportChat,
} from "src/core/utils/supportChat";

const VIDEO_LIBRARY_URL = 'https://www.youtube.com/@Spendesk';

export const WelcomeWidgets = () => {
  const { activeLanguage, t } = useTranslation('global');

  const helpCenterUrl = getHelpCenterBase({
    lang: activeLanguage,
  });

  const isChatAvailable = isSupportChatAvailable();
  const hasUpsellCtaFeature = useFeature(FEATURES.UPSELL_CTA);

  return (
    <div>
      <div className="box flex flex-col gap-s">
        <h3 className="title-xl">
          {t('homepage.welcomeWidgets.needHelp.title')}
        </h3>
        <div className="flex flex-col items-start gap-xs">
          <span className="text-neutral-dark body-m">
            {t('homepage.welcomeWidgets.needHelp.subtitleHelp')}
          </span>
          <Button
            className="text-primary"
            href={helpCenterUrl}
            component="a"
            variant="ghost"
            iconName="external"
            iconPosition="right"
            text={t('homepage.welcomeWidgets.needHelp.cta.faq')}
            target="_blank"
            onClick={() =>
              track(AnalyticEventName.WELCOME_WIDGETS_NEED_HELP_CLICKED, {
                link: 'FAQ',
              })
            }
          />
          <Button
            className="text-primary"
            href={VIDEO_LIBRARY_URL}
            component="a"
            variant="ghost"
            iconName="external"
            iconPosition="right"
            text={t('homepage.welcomeWidgets.needHelp.cta.video')}
            target="_blank"
            onClick={() =>
              track(AnalyticEventName.WELCOME_WIDGETS_NEED_HELP_CLICKED, {
                link: 'VIDEO_LIBRARY',
              })
            }
          />
        </div>
        {isChatAvailable && (
          <div className="flex flex-col items-start border-0 border-t border-solid border-neutral-light pt-s">
            <span className="mb-s text-neutral-dark body-m">
              {t('homepage.welcomeWidgets.needHelp.subtitleChat')}
            </span>
            <Button
              href={helpCenterUrl}
              variant="secondary"
              text={t('homepage.welcomeWidgets.needHelp.cta.chat')}
              onClick={() => {
                track(AnalyticEventName.WELCOME_WIDGETS_NEED_HELP_CLICKED, {
                  link: 'INTERCOM_CHAT',
                });
                openSupportChat();
              }}
            />
          </div>
        )}
      </div>

      {hasUpsellCtaFeature && (
        <div className="box mt-l">
          <div className="mb-s">
            <h3 className="mb-xs title-xl">
              {t('homepage.welcomeWidgets.levelUp.title')}
            </h3>
            <span className="body-m">
              {t('homepage.welcomeWidgets.levelUp.subtitle')}
            </span>
          </div>
          <div className="flex flex-col items-start">
            <Button
              className="text-primary"
              href={`${wwwBaseUrl}/pricing`}
              component="a"
              variant="ghost"
              iconName="external"
              iconPosition="right"
              text={t('homepage.welcomeWidgets.levelUp.cta.explore')}
              target="_blank"
              onClick={() =>
                track(AnalyticEventName.WELCOME_WIDGETS_EXPLORE_PLANS_CLICKED)
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};
