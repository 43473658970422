import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export const useControlRulesActivelyUsedQuery = (): QueryState<{
  isActivelyUsed: boolean;
}> => {
  return useQuery<{ isActivelyUsed: boolean }>({
    key: 'controlRulesActivelyUsed',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/control-rules/actively-used',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reshapeData: (data: any) => {
      return {
        isActivelyUsed: data.isActivelyUsed,
      };
    },
  });
};
