import {
  Button,
  Callout,
  CheckboxField,
  FormField,
  Modal,
  TextInput,
} from '@dev-spendesk/grapes';
import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type IntegrationStatus } from 'modules/bookkeep/integration/status';
import { routeFor, routes } from 'src/core/constants/routes';

import { PayableState } from '../../graphql/mappers/payableState';
import { type ExpenseAccount } from '../../settings/accounting';
import { useGetPayablesCount } from '../../settings/accounting/graphql/hooks';
import { useSetExpenseAccountMutation } from '../../settings/integrations/hooks/useSetExpenseAccountMutation';
import { CodeInUseCallout } from '../../settings/integrations/pages/LegacyIntegrationsAccountingPage/components/CodeInUseCallout/CodeInUseCallout';
import styles from '../../settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/ExpenseAccountsSection/ExpenseAccountLocalOnlySection.module.css';
import { ExpenseAccountAdvice } from '../AccountAdvice';

type ModalState = {
  accountName: string;
  accountCode: string;
  inputChanged: boolean;
  accountCurrentlyUsingTheCode?: ExpenseAccount;
  shouldSendPayablesBackToPrepare: boolean;
  error?: string;
};

const defaultState: ModalState = {
  accountName: '',
  accountCode: '',
  inputChanged: false,
  shouldSendPayablesBackToPrepare: false,
  error: undefined,
};

export const UpdateExpenseAccountModal = ({
  integrationStatus,
  isOpen,
  onClose,
  account,
}: {
  integrationStatus: IntegrationStatus;
  isOpen: boolean;
  onClose: (newAccount?: ExpenseAccount) => void;
  account?: ExpenseAccount;
}) => {
  const { t } = useTranslation('global');

  const [modalState, setModalState] = useState<ModalState>(defaultState);

  const [setExpenseAccount, setExpenseAccountQueryState, reset] =
    useSetExpenseAccountMutation();

  useEffect(() => {
    if (isOpen && account) {
      setModalState({
        ...defaultState,
        accountName: account.name,
        accountCode: account.code,
      });
    }
  }, [isOpen, account]);

  const handleUpdateExpenseAccount = async () => {
    if (account) {
      setModalState({ ...modalState, inputChanged: false, error: undefined });

      try {
        const result = await setExpenseAccount({
          id: account.id,
          name: modalState.accountName,
          code: modalState.accountCode,
          isArchived: false,
          shouldSendPayablesBackToPrepare:
            modalState.shouldSendPayablesBackToPrepare,
        });

        if (
          result.outcome === 'notSet' &&
          result.reason === 'codeAlreadyExists'
        ) {
          setModalState({
            ...modalState,
            inputChanged: false,
            accountCurrentlyUsingTheCode: result.existingAccount,
          });
        }

        if (result.outcome === 'set') {
          handleCloseModal(result.accounts[0]);
        } else {
          setModalState((state) => ({
            ...state,
            error: t('misc.errors.unknownError'),
          }));
        }
      } catch {
        // Nothing to do on error
      }
    }
  };

  const handleCloseModal = (newAccount?: ExpenseAccount) => {
    setModalState(defaultState);
    reset();
    onClose(newAccount);
  };

  const wrongPatternError = useMemo(
    () =>
      setExpenseAccountQueryState.status === 'error' &&
      setExpenseAccountQueryState.error.type === 'RequestError' &&
      setExpenseAccountQueryState.error.data.reason ===
        'integrationValidationFailed',
    [],
  );

  const codeInUseError = useMemo(
    () => !!modalState.accountCurrentlyUsingTheCode && !modalState.inputChanged,
    [modalState.accountCurrentlyUsingTheCode, modalState.inputChanged],
  );

  const isSaveDisabled = useMemo(
    () => codeInUseError || wrongPatternError || !modalState.inputChanged,
    [modalState, codeInUseError, wrongPatternError],
  );

  const handleChange =
    (key: 'accountName' | 'accountCode') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setModalState({
        ...modalState,
        [key]: event.target.value,
        inputChanged: true,
      });
    };

  return (
    <Modal
      title={t(
        'bookkeep.integrations.settings.expenseAccountsTable.updateExpenseAccount',
      )}
      isOpen={isOpen}
      iconVariant="primary"
      iconName="pen"
      actions={[
        <Button
          key="cancel"
          onClick={() => handleCloseModal()}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="saveChanges"
          onClick={handleUpdateExpenseAccount}
          text={t('misc.saveChanges')}
          variant="primary"
          isDisabled={isSaveDisabled}
        />,
      ]}
    >
      {isOpen && (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.expenseAccountsTable.accountName',
            )}
            className={styles.formField}
          >
            <TextInput
              value={modalState.accountName}
              onChange={handleChange('accountName')}
            />
          </FormField>

          <FormField
            label={t(
              'bookkeep.integrations.settings.expenseAccountsTable.accountCode',
            )}
            className={styles.formField}
          >
            <TextInput
              isInvalid={
                (wrongPatternError || codeInUseError) &&
                !modalState.inputChanged
              }
              value={modalState.accountCode}
              onChange={handleChange('accountCode')}
            />
          </FormField>

          <SendPayablesBackToPrepareCallout
            account={account}
            value={modalState.shouldSendPayablesBackToPrepare}
            onChange={(shouldSendPayablesBackToPrepare) => {
              setModalState((state) => ({
                ...state,
                shouldSendPayablesBackToPrepare,
              }));
            }}
          />

          {!codeInUseError && (
            <ExpenseAccountAdvice
              integrationStatus={integrationStatus}
              showError={wrongPatternError}
              className={styles.formCallout}
            />
          )}

          {codeInUseError &&
            modalState.accountCurrentlyUsingTheCode &&
            !modalState.inputChanged && (
              <ExpenseAccountCodeAlreadyInUse
                account={modalState.accountCurrentlyUsingTheCode}
              />
            )}
        </>
      )}
    </Modal>
  );
};

const ExpenseAccountCodeAlreadyInUse = ({
  account,
}: {
  account: ExpenseAccount;
}) => {
  const companyId = useCompanyId();

  return (
    <CodeInUseCallout
      accountInfo={`${account && account.name} - ${account && account.code}`}
      linkTo={routeFor(routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path, {
        company: companyId,
      })}
      className={styles.formCallout}
    />
  );
};

const SendPayablesBackToPrepareCallout = ({
  account,
  value,
  onChange,
}: {
  account: ExpenseAccount | undefined;
  value: boolean;
  onChange: (shouldSendPayablesBackToPrepare: boolean) => void;
}) => {
  const { t } = useTranslation('global');

  const getPreparedPayablesCount = useGetPayablesCount(
    {
      expenseAccounts: account ? { ids: [account.id] } : undefined,
      state: [
        PayableState.Prepared,
        PayableState.ToAccountingPending,
        PayableState.ToAccountingFailed,
      ],
    },
    !!account?.id,
  );

  if (!account) {
    return null;
  }

  return !getPreparedPayablesCount.loading &&
    getPreparedPayablesCount.data !== 0 ? (
    <Callout
      variant="warning"
      title={t(
        'bookkeep.integrations.settings.expenseAccountsTable.shouldSendPayablesBackToPrepare',
        { count: getPreparedPayablesCount.data, code: account?.code },
      )}
      className="mb-m"
    >
      <CheckboxField
        label={t(
          'bookkeep.integrations.settings.expenseAccountsTable.shouldSendPayablesBackToPrepareConfirmation',
          { count: getPreparedPayablesCount.data },
        )}
        isChecked={value}
        onChange={(event) => onChange(event.target.checked)}
        fit="parent"
      />
    </Callout>
  ) : null;
};
