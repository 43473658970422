import { SkeletonText } from '@dev-spendesk/grapes';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

const DEFAULT_MIN_TEXT_SIZE_FACTOR = 0.6 as const; // Factor of text size
const DEFAULT_TEXT_SIZE_FACTOR_STEP = 0.15 as const; // 4 steps
const DEFAULT_MIN_LETTER_SPACING_SUB = -0.7 as const; // pixels, below `-0.7px` it gets unreadable
const DEFAULT_LETTER_SPACING_SUB_STEP = 0.175 as const; // 4 steps
const DEFAULT_PLACEHOLDER = false as const;

export const TextFitter = ({
  children,
  ...options
}: {
  minTextSizeFactor?: number;
  textSizeFactorStep?: number;
  minLetterSpacingSub?: number;
  letterSpacingSubStep?: number;
  maxLines: number;
  placeholder?: boolean;
  children: React.ReactNode;
}) => {
  const {
    minTextSizeFactor = DEFAULT_MIN_TEXT_SIZE_FACTOR,
    textSizeFactorStep = DEFAULT_TEXT_SIZE_FACTOR_STEP,
    minLetterSpacingSub = DEFAULT_MIN_LETTER_SPACING_SUB,
    letterSpacingSubStep = DEFAULT_LETTER_SPACING_SUB_STEP,
    maxLines,
    placeholder = DEFAULT_PLACEHOLDER,
  } = options;
  const [initialStyle, setInitialStyle] = useState({
    fontSize: 0,
    lineHeight: 0,
    letterSpacing: 0,
  });
  const [current, setCurrent] = useState({
    lineCount: 0,
    letterSpacingSub: 0,
    fontSizeFactor: 1,
    justTriggered: false,
  });
  const [done, setDone] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const element = ref.current;
    const computedStyle = window.getComputedStyle(element);
    const letterSpacing = computedStyle.getPropertyValue('letter-spacing');
    setInitialStyle({
      fontSize: Number.parseInt(computedStyle.getPropertyValue('font-size')),
      lineHeight: Number.parseInt(
        computedStyle.getPropertyValue('line-height'),
      ),
      letterSpacing: Number.parseInt(
        (() => {
          if (letterSpacing === 'normal') {
            return '0';
          }
          return letterSpacing;
        })(),
      ),
    });
  }, [ref.current]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const element = ref.current;
    const lineCount = Math.round(
      element.getBoundingClientRect().height / initialStyle.lineHeight,
    );
    if (
      lineCount <= maxLines ||
      (current.letterSpacingSub <= minLetterSpacingSub &&
        current.fontSizeFactor <= minTextSizeFactor)
    ) {
      setDone(true);
    } else {
      setCurrent({
        ...current,
        lineCount: lineCount,
        justTriggered: true,
      });
    }
  }, [
    JSON.stringify(initialStyle),
    current.letterSpacingSub,
    current.fontSizeFactor,
  ]);

  useEffect(() => {
    if (current.lineCount <= maxLines) {
      return;
    }
    if (current.justTriggered === false) {
      // Trigger line recount
      return;
    }

    if (current.letterSpacingSub > minLetterSpacingSub) {
      setCurrent({
        ...current,
        letterSpacingSub: Math.max(
          current.letterSpacingSub - letterSpacingSubStep,
          minLetterSpacingSub,
        ), // Clamp letter spacing to `minLetterSpacingSub`
        justTriggered: false,
      });
    } else if (current.fontSizeFactor > minTextSizeFactor) {
      setCurrent({
        ...current,
        letterSpacingSub: 0,
        fontSizeFactor: Math.max(
          current.fontSizeFactor - textSizeFactorStep,
          minTextSizeFactor,
        ), // Clamp text size to `minTextSizeFactor`
        justTriggered: false,
      });
    } else {
      setCurrent({
        ...current,
        justTriggered: false,
      });
    }
  }, [current.justTriggered]);

  const isPlaceholding = placeholder && !done;
  return (
    <>
      <div style={isPlaceholding ? { height: 0, overflow: 'hidden' } : {}}>
        <div
          style={{
            fontSize:
              initialStyle.lineHeight !== 0
                ? `${Math.round(initialStyle.fontSize * current.fontSizeFactor)}px`
                : undefined,
            letterSpacing:
              initialStyle.lineHeight !== 0
                ? `${initialStyle.letterSpacing + current.letterSpacingSub}px`
                : undefined,
          }}
          ref={ref}
        >
          {children}
        </div>
      </div>

      {isPlaceholding &&
        (() => {
          const placeholders = [];
          for (let index = 0; maxLines > index; ++index) {
            placeholders.push(<SkeletonText />);
          }

          return <>{placeholders}</>;
        })()}
    </>
  );
};
