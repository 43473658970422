import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { useGetManagedUsersByKomboHrIntegrationQuery } from '../../members/hooks/useGetManagedUsersByKomboHrIntegrationQuery';

export const useIsUserManagedByKombo = ({
  userId,
}: {
  userId: string;
}): boolean => {
  const useGetUsersManagedByKomboHrIntegration =
    useGetManagedUsersByKomboHrIntegrationQuery();
  const usersManagedByKombo = unwrapQuery(
    useGetUsersManagedByKomboHrIntegration,
  );

  if (!usersManagedByKombo) {
    return false;
  }

  return !!usersManagedByKombo.managedUsers.get(userId);
};
