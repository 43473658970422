import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export type ControlRule = {
  id: string;
  name: string;
  isCustom: boolean;
};

export const useControlRulesQuery = (): QueryState<ControlRule[]> => {
  return useQuery<ControlRule[]>({
    key: 'control-rules',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/control-rules',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reshapeData: (data: any[]) => {
      return data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          isCustom: item.isCustom,
        };
      });
    },
  });
};
