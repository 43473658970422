import { useState } from 'react';

// TODO Should be moved to common components folder
// FIXME Make the component more generic
export const OrganisationReportingEntityListSearch = <T extends object>({
  entities,
  sortFn: sortFunction = () => 0,
  children,
  searchableFields,
}: {
  entities: T[];
  searchableFields: (keyof T)[];
  sortFn?: (a: T, b: T) => number;
  children: ({
    search,
    setSearch,
    filteredEntities,
  }: {
    search: string;
    setSearch: (search: string) => void;
    filteredEntities: T[];
  }) => React.ReactNode;
}) => {
  const [search, setSearch] = useState<string>('');
  const filterBySearchTerm = (entity: T) => {
    const sanitizedTerm = search.trim().toLowerCase();

    return searchableFields
      .map((field) => String(entity[field]).toLowerCase())
      .some((entityField) => entityField.includes(sanitizedTerm));
  };

  return children({
    search,
    setSearch,
    filteredEntities: entities
      .filter((entity) => filterBySearchTerm(entity))
      .sort(sortFunction),
  });
};
