import React from 'react';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
  };
  className?: string;
  onClick?: () => void;
};

export const CategoriesLink = ({ company, className, onClick }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavigationLink
      data-marketing="top-menu_custom-fields"
      key="nav_custom_fields"
      className={className}
      onClick={onClick}
      path={routeFor(routes.COMPANY_CATEGORIES.path, {
        company: company.id,
      })}
      text={t('settings.links.customFields')}
    />
  );
};
