import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch } from 'react';

import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from './ModalState';
import { type AnalyticalFieldMappingUpdate } from '../../../../../hooks/useUpdateAnalyticalFieldMappingMutation';

interface Props {
  integration: AccountingSoftware;
  modalState: ModalState;
  setModalState: Dispatch<ModalState>;
  onUpdate: (
    editedAnalyticalField: AnalyticalFieldMappingUpdate & {
      externalName: string;
    },
  ) => void;
}

export const StaticMapAndPullAndMapDeleteModal = ({
  integration,
  modalState,
  setModalState,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      actions={[
        <Button
          key="no"
          onClick={() => setModalState({ modalKind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          text={t(
            'bookkeep.integrations.settings.analyticalFieldsMappingsTable.deleteAnalyticalFieldMapping.confirmDeletion',
          )}
          variant="primary"
          onClick={() => {
            if (modalState.modalKind === 'delete') {
              onUpdate({
                ...modalState,
                kind: 'notMapped',
              });
            }
            setModalState({ modalKind: 'closed' });
          }}
        />,
      ]}
      onClose={() => {
        setModalState({ modalKind: 'closed' });
      }}
      iconName="trash"
      title={t(
        'bookkeep.integrations.settings.analyticalFieldsMappingsTable.deleteAnalyticalFieldMapping.title',
        {
          externalName:
            modalState.modalKind === 'delete' && modalState.externalName,
        },
      )}
      subtitle={t(
        'bookkeep.integrations.settings.analyticalFieldsMappingsTable.deleteAnalyticalFieldMapping.subtitle',
        {
          integration,
        },
      )}
      iconVariant="alert"
      isOpen={modalState.modalKind === 'delete'}
    />
  );
};
