import React from 'react';

import {
  EmptyStateError,
  EmptyStateLoading,
  lazyLoad,
} from 'common/components/LazyLoad';

export const CardsContainer = lazyLoad({
  loader: async () => ({
    /* eslint-disable unicorn/no-await-expression-member */
    default: (
      await import(/* webpackChunkName: "CardsContainer" */ './CardsContainer')
    ).CardsContainer,
    /* eslint-enable unicorn/no-await-expression-member */
  }),
  loading: <EmptyStateLoading />,
  fallback: (props) => <EmptyStateError {...props} />,
});
