import { useTranslation } from 'common/hooks/useTranslation';

const KNOWN_ERRORS = ['invalidBankInfo'] as const;

type KnownError = (typeof KNOWN_ERRORS)[number];

export function useGetErrorTranslation() {
  const { t } = useTranslation('global');

  return function getErrorTranslation(error: string): string | undefined {
    if (isKnownError(error)) {
      return t(`wiretransfer.confirmation.errorModal.knownErrors.${error}`);
    }

    return undefined;
  };
}

function isKnownError(error: string): error is KnownError {
  return KNOWN_ERRORS.includes(error as KnownError);
}
