import { type SupplierAccountDefaultFor } from 'modules/bookkeep/settings/accounting';
import { getFetcher } from 'src/core/api/fetcher';

import { GET_SUPPLIER_ACCOUNTS } from './query';

type RawData = {
  company: {
    chartOfAccounts: {
      supplierAccounts: {
        totalCount: number;
        edges: {
          node: {
            id: string;
            generalAccountCode: string;
            auxiliaryAccountCode?: string | undefined;
            defaultFor?: SupplierAccountDefaultFor;
          };
        }[];
      };
    };
  };
};

export type SupplierAccountsVariables = {
  filter: {
    includeArchived?: boolean;
    search?: string;
    ids?: string[];
  };
};

export type SupplierAccountsResponse = RawData;

export const getSupplierAccountsQueryFetcher = ({
  companyId,
  filter = {
    includeArchived: false,
  },
}: {
  companyId: string;
  filter: SupplierAccountsVariables['filter'];
}) =>
  getFetcher<SupplierAccountsResponse>({
    companyId,
    getRequest: () => ({
      type: 'graphQL',
      target: 'v2',
      query: GET_SUPPLIER_ACCOUNTS,
      variables: {
        filter,
      },
      method: 'get',
    }),
  });
