export const getMonthsBetweenDates = (rawStart: Date, rawEnd: Date): Date[] => {
  if (rawStart > rawEnd) {
    return getMonthsBetweenDates(rawEnd, rawStart);
  }

  const allMonths = [];

  const cursor = new Date(rawStart.getFullYear(), rawStart.getMonth() + 1);
  const end = new Date(rawEnd.getFullYear(), rawEnd.getMonth() - 1);

  while (cursor <= end) {
    allMonths.push(new Date(cursor));

    cursor.setMonth(cursor.getMonth() + 1);
  }

  return allMonths.reverse();
};
