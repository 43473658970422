import React from 'react';

import {
  CocktailIllustration,
  FlamingoIllustration,
  NoResultsIllustration,
  PaperclipIllustration,
  PaymentIllustration,
  RequestIllustration,
} from './illustrations';

const getBaseProps = (): React.ImgHTMLAttributes<HTMLImageElement> => {
  // FIXME: add title and aria-labelledby=title?
  return {
    width: 136,
    height: 136,
  };
};

export const getComponentByIllustrationName = (
  illustrationName: IllustrationName,
) => {
  switch (illustrationName) {
    case 'cocktail':
      return <img src={CocktailIllustration} alt="" {...getBaseProps()} />;
    case 'flamingo':
      return <img src={FlamingoIllustration} alt="" {...getBaseProps()} />;
    case 'no-results':
      return <img src={NoResultsIllustration} alt="" {...getBaseProps()} />;
    case 'paperclip':
      return <img src={PaperclipIllustration} alt="" {...getBaseProps()} />;
    case 'payment':
      return <img src={PaymentIllustration} alt="" {...getBaseProps()} />;
    case 'request':
      return <img src={RequestIllustration} alt="" {...getBaseProps()} />;
    default:
      throw new Error(
        `Unsupported empty state with illustration name: ${illustrationName}`,
      );
  }
};

export type IllustrationName =
  | 'cocktail'
  | 'flamingo'
  | 'no-results'
  | 'paperclip'
  | 'payment'
  | 'request';
