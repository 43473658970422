import { createSlice, createSelector } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { AppState } from 'src/core/reducers';

import { type InvoicesPayFiltersValues, countActiveFilters } from '../models';

const initialState: InvoicesPayFiltersValues = {
  teams: [],
  suppliers: [],
  costCenters: [],
  customFields: {},
  period: undefined,
};

const schedulePaymentsFiltersSlice = createSlice({
  name: 'invoices/schedulePayments/filters',
  initialState,
  reducers: {
    setFilters: (
      _,
      { payload }: PayloadAction<{ filters: InvoicesPayFiltersValues }>,
    ) => {
      return payload.filters;
    },

    resetFilters: () => {
      return initialState;
    },
  },
});

const selectFilters = (state: AppState): InvoicesPayFiltersValues =>
  state.invoices.schedulePaymentsFilters;

const selectActiveFiltersCount = createSelector(
  selectFilters,
  countActiveFilters,
);

export const selectors = {
  selectActiveFiltersCount,
  selectFilters,
};

export const { actions, reducer } = schedulePaymentsFiltersSlice;
