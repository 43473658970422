import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';

export type Option = {
  key: string;
  label: string;
  thumbnailUrl?: string;
};

export const useSearchSuppliers = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  return async (search: string): Promise<Option[]> => {
    const data: { id: string; name: string; thumbnailUrl?: string }[] =
      await queryClient.fetchQuery(
        ['useSearchSuppliers', companyId, search],
        getFetcher({
          companyId,
          getRequest: () => ({
            type: 'rest',
            target: 'companyAPI',
            endpoint: `/suppliers`,
            params: {
              search,
            },
            method: 'get',
          }),
        }),
      );

    return data.map((supplier) => ({
      key: supplier.id,
      label: supplier.name,
      thumbnailUrl: supplier.thumbnailUrl,
    }));
  };
};
