import { useMutation } from 'src/core/api/hooks/useMutation';

type PhoneNumber = {
  mobile_no: string;
  mobile_ext: string;
};

type Payload = {
  cards: [
    {
      recipient: string;
      addressLine1: string;
      addressLine2: string;
      zipCode: string;
      city: string;
      country: string;
      phoneNumber: PhoneNumber;
      owner: {
        id: string;
        firstName: string;
        lastName: string;
      };
      recardingCardId: string;
      budget: number;
      setUpMonthlyReload: boolean;
      enableCashWithdrawal?: boolean;
    },
  ];
};

export const useReorderNotReceivedCardMutation = () => {
  return useMutation<Payload, Response, Response>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint: '/cards',
    },
    reshapeData: (data: Response) => data,
  });
};
