import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { type KomboFeatures } from '../../integrations/types/kombo';

export type ManagedCompany = {
  isManagedByKombo: boolean;
  featureSet: KomboFeatures[];
};

export const useGetCompanyManagedByKomboHrIntegrationQuery =
  (): QueryState<ManagedCompany> => {
    const isKomboEnabled = useFeature(FEATURES.HR_INTEGRATIONS);

    return useQuery({
      key: ['integrations', 'kombo', 'is-company-managed'],
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: `/integrations/kombo/is-company-managed`,
      },
      options: {
        cacheTime: 10 * 60 * 1000, // 10min,
        staleTime: 10 * 60 * 1000, // 10min,
      },
      isEnabled: isKomboEnabled,
      reshapeData: (data) => data,
    });
  };
