import {
  createAction,
  type ThunkDispatch,
  type AnyAction,
} from '@reduxjs/toolkit';

import * as types from 'src/core/actionTypes/files';

import type { AppState } from '../reducers';

// Upload file
const uploadFileLoading = createAction(types.UPLOAD_FILE_LOADING);
export const uploadFile =
  (
    path: string,
    formData: FormData,
    onSuccess: () => void,
    onError: (error: { status: number }) => void,
  ) =>
  async (dispatch: ThunkDispatch<AppState, null, AnyAction>) => {
    dispatch(uploadFileLoading());
    if (!path) {
      return;
    }

    try {
      const response = await fetch(path, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      if (response.ok) {
        onSuccess();
      } else {
        onError({ status: response.status });
      }
    } catch {
      onError({ status: -1 });
    }
  };
