export const FETCH_SLACK_PROVIDER_SETTINGS_LOADING =
  'FETCH_SLACK_PROVIDER_SETTINGS_LOADING';
export const FETCH_SLACK_PROVIDER_SETTINGS_SUCCESS =
  'FETCH_SLACK_PROVIDER_SETTINGS_SUCCESS';
export const FETCH_SLACK_PROVIDER_SETTINGS_FAILURE =
  'FETCH_SLACK_PROVIDER_SETTINGS_FAILURE';

export const FETCH_USER_APPROVAL_DATA_LOADING =
  'FETCH_USER_APPROVAL_DATA_LOADING';
export const FETCH_USER_APPROVAL_DATA_SUCCESS =
  'FETCH_USER_APPROVAL_DATA_SUCCESS';
export const FETCH_USER_APPROVAL_DATA_FAILURE =
  'FETCH_USER_APPROVAL_DATA_FAILURE';
