import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { type MemberTableRow } from 'src/core/modules/members/models/member';

import { useGetCompanyManagedByKomboHrIntegrationQuery } from './useGetCompanyManagedByKomboHrIntegrationQuery';

// TOOD: Replace with useMemberTableRowsQuery when https://spendesk.atlassian.net/browse/ON-931 is picked up
export const useArchivedMembersQuery = (): QueryState<MemberTableRow[]> => {
  // This check can be removed when archived is for everyone.
  const isCompanyManagedByIntegrationQueryState =
    useGetCompanyManagedByKomboHrIntegrationQuery();
  const isCompanyManagedByIntegration =
    unwrapQuery(isCompanyManagedByIntegrationQueryState)?.isManagedByKombo ??
    false;

  return useQuery<MemberTableRow[], MemberTableRow[]>({
    key: ['archived-members', { memberStatus: 'archived' }],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/members-page?pageSize=5000&offset=0&memberStatus=archived`,
    },
    isEnabled: isCompanyManagedByIntegration,
    reshapeData: (data) =>
      data.map((rawMember) => ({
        ...rawMember,
        status: 'archived' as const,
      })),
  });
};
