import { compareAsc, isAfter } from 'date-fns';
import { type MonetaryValue } from 'ezmoney';

import { type CreditNoteId } from '@finance-review/models/credit-note';
import {
  defaultPaymentMethod,
  type DraftInvoiceRequest,
  type DraftPaymentSchedule,
  DraftScheduledPayment,
  type InvoiceRequest,
  type PaymentMethod,
} from '@finance-review/models/invoice';
import {
  type CustomField,
  customFieldsToCustomFieldAssociation,
} from 'modules/budgets/models/customField';
import type { RawSupplier } from 'modules/suppliers';

export type RawDraftInvoice = {
  id: InvoiceRequest.EntityId;
  amount_declared: number;
  currency_declared: string;
  description: string | null;
  original_converted_amount: number;
  group_id: string | null;
  cost_center_id?: string | null;
  user_id: string;
  purchase_order_id: string | null;
  supplier_id: string;
  created_at: string;
  updated_at: string | null;
  invoice_request: RawDraftInvoiceRequest;
  custom_fields: CustomField[] | null;
  appliedCreditNoteIds?: CreditNoteId[];
  appliedCredit?: MonetaryValue;
};

export type RawDraftInvoiceRequest = {
  id: string;
  emission_date: string | null;
  due_date: string | null;
  paymentMethod: PaymentMethod | null;
  payment_reference?: string;
  invoice_number: string | null;
  status: 'in_review' | 'to_pay' | 'partially_paid' | 'paid';
  paymentDate?: string | null;
  scheduled_payments:
    | {
        id: string;
        date: string | null;
        amount: number | null;
      }[]
    | null;
};

// The data coming from the back-end has necessarily an amount and a supplier defined
// for this reason, we create a type that is more accurately describing that fact
type DraftInvoiceRequestTransformed = WithRequired<
  DraftInvoiceRequest.Entity,
  'amount' | 'supplierId' | 'supplierName'
>;

export const transformInvoiceRequest = (
  rawDraftInvoice: RawDraftInvoice,
  rawSupplier: RawSupplier,
  today: Date,
): DraftInvoiceRequestTransformed => {
  const { invoice_request: invoiceRequest } = rawDraftInvoice;

  return {
    id: rawDraftInvoice.id,
    invoiceNumber: invoiceRequest.invoice_number ?? undefined,
    invoiceDetailsId: invoiceRequest.id ?? undefined,
    amount: rawDraftInvoice.amount_declared,
    originalAmountInCompanyCurrency: rawDraftInvoice.original_converted_amount,
    currency: rawDraftInvoice.currency_declared,
    description: rawDraftInvoice.description ?? '',
    teamId: rawDraftInvoice.group_id ?? undefined,
    costCenterId: rawDraftInvoice.cost_center_id ?? undefined,
    requesterId: rawDraftInvoice.user_id,
    purchaseOrderId: rawDraftInvoice.purchase_order_id ?? undefined,
    supplierId: rawDraftInvoice.supplier_id,
    supplierName: rawSupplier.name,
    emissionDate: invoiceRequest.emission_date
      ? stringToDateConverter(invoiceRequest.emission_date)
      : undefined,
    dueDate: invoiceRequest.due_date
      ? stringToDateConverter(invoiceRequest.due_date)
      : undefined,
    paymentDate: invoiceRequest.paymentDate
      ? stringToDateConverter(invoiceRequest.paymentDate)
      : undefined,
    paymentReference: invoiceRequest.payment_reference ?? undefined,
    paymentSchedule: getPaymentSchedule(rawDraftInvoice, today),
    paymentMethod: invoiceRequest.paymentMethod ?? defaultPaymentMethod,
    customFieldsAssociations: rawDraftInvoice.custom_fields
      ? customFieldsToCustomFieldAssociation(rawDraftInvoice.custom_fields)
      : [],
    status: invoiceRequest?.status,
    appliedCredit: rawDraftInvoice.appliedCredit,
    appliedCreditNoteIds: rawDraftInvoice.appliedCreditNoteIds,
  };
};

const getPaymentSchedule = (
  rawDraftInvoice: RawDraftInvoice,
  today: Date,
): DraftPaymentSchedule.Entity => {
  const rawPaymentSchedule = rawDraftInvoice.invoice_request.scheduled_payments;
  const { due_date: dueDate } = rawDraftInvoice.invoice_request;

  const defaultScheduledPayment = DraftScheduledPayment.create();

  return rawPaymentSchedule && rawPaymentSchedule.length > 0
    ? rawPaymentSchedule
        .map((rawScheduledPayment) =>
          DraftScheduledPayment.create({
            id: rawScheduledPayment.id ?? undefined,
            amount: rawScheduledPayment.amount ?? undefined,
            date:
              getValidScheduledPaymentDate(rawScheduledPayment.date, today) ??
              getValidScheduledPaymentDate(dueDate, today) ??
              today,
          }),
        )
        .sort((scheduledPaymentA, scheduledPaymentB) =>
          scheduledPaymentA.date && scheduledPaymentB.date
            ? compareAsc(scheduledPaymentA.date, scheduledPaymentB.date)
            : 0,
        )
    : [defaultScheduledPayment];
};

const getValidScheduledPaymentDate = (
  rawScheduledPaymentDate: string | null | undefined,
  today: Date,
): Date | undefined =>
  rawScheduledPaymentDate && isAfter(new Date(rawScheduledPaymentDate), today)
    ? new Date(rawScheduledPaymentDate)
    : undefined;

// date param expressed as yyyy-mm-dd
// sets the date to midnight local time to prevent the browser from adding a UTC offset
const stringToDateConverter = (date: string): Date =>
  new Date(`${date}T00:00:00`);
