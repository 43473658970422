import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { useGetProcedureQuery } from './useGetProcedureQuery';
import { type Procedure } from '../models';

type Params = {
  isEnabled?: boolean;
};

export const useProcedure = ({ isEnabled = true }: Params = {}):
  | Procedure
  | undefined => {
  const procedureState = useGetProcedureQuery({ isEnabled });
  return procedureState ? unwrapQuery(procedureState) : undefined;
};
