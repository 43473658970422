import { Callout, Button, Icon, TextInput } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { fromNumber } from 'ezmoney';
import { type FormikProps } from 'formik';
import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import BankStatementIllustration from './bankStatement.svg';
import { type CheckBankStatementFormValues } from '../../types';

import './CheckBankStatementForm.css';

type Props = {
  currency: string;
  hasError: boolean;
  isPendingAchSourceLate: boolean;
  showLinkLater?: boolean;
  onLinkLater?: () => void;
  onUpdateBankInfo?: () => void;
} & FormikProps<CheckBankStatementFormValues>;

export const CheckBankStatementForm = ({
  showLinkLater,
  currency,
  hasError,
  isPendingAchSourceLate,
  onLinkLater,
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  isSubmitting,
  onUpdateBankInfo,
  errors,
}: Props) => {
  const { t } = useTranslation('global');

  const from = formatMonetaryValue(fromNumber(0, currency, 2));
  const to = formatMonetaryValue(fromNumber(1, currency, 2));

  const handleUpdateBankInfo = () => {
    if (onUpdateBankInfo) {
      onUpdateBankInfo();
    }
  };
  return (
    <div
      className={cx('CheckBankStatementForm', {
        'box mb-xxl py-s': !showLinkLater,
      })}
    >
      <div className="CheckBankStatementForm__form">
        <p className="mb-m text-neutral-darker title-m">
          {t('ach.validateTest')}
        </p>
        <p className="CheckBankStatementForm__form__description">
          {t('ach.checkBankStatementDesc1', {
            from,
            to,
          })}
        </p>
        <p className="CheckBankStatementForm__form__description">
          {t('ach.checkBankStatementDesc2')}
        </p>
        <form onSubmit={handleSubmit}>
          <div className="CheckBankStatementForm__form__inputs">
            <TextInput
              name="firstAmount"
              pattern="^(0?(\.\d{0,2})?|1)$"
              placeholder="0.65"
              value={values.firstAmount}
              onChange={(event) => {
                if (event.target.checkValidity()) {
                  handleChange(event);
                }
              }}
              onBlur={handleBlur}
              isInvalid={Boolean(errors.firstAmount) || hasError}
            />
            <TextInput
              name="secondAmount"
              pattern="^(0?(\.\d{0,2})?|1)$"
              placeholder="0.65"
              value={values.secondAmount}
              onChange={(event) => {
                if (event.target.checkValidity()) {
                  handleChange(event);
                }
              }}
              onBlur={handleBlur}
              isInvalid={Boolean(errors.firstAmount) || hasError}
            />
          </div>
          {isPendingAchSourceLate && (
            <Callout
              className="CheckBankStatementForm__form__error"
              title={
                <Trans i18nKey="ach.nothingAppear">
                  <button type="button" onClick={() => window.Intercom('show')}>
                    -
                  </button>
                </Trans>
              }
              variant="warning"
            />
          )}
          <div className="CheckBankStatementForm__form__actions">
            {showLinkLater && onLinkLater && (
              <Button
                isDisabled={isSubmitting}
                text={t('ach.linkLater')}
                variant="ghost"
                onClick={onLinkLater}
              />
            )}
            <Button
              type="submit"
              isDisabled={isSubmitting}
              text={t('ach.link')}
            />
          </div>
          {onUpdateBankInfo && (
            <div className="CheckBankStatementForm__form__actions_update">
              <Callout
                className="CheckBankStatementForm__form__info"
                title={t('ach.updateBankInfoMessage')}
                variant="info"
              >
                <Button
                  className="CheckBankStatementForm__form__actions__link_update"
                  type="button"
                  text={t('ach.updateBankInfo')}
                  onClick={handleUpdateBankInfo}
                  variant="ghost"
                />
              </Callout>
            </div>
          )}
        </form>
      </div>
      <div
        className={cx('CheckBankStatementForm__illustration', {
          'CheckBankStatementForm__illustration--in-wallet': !showLinkLater,
        })}
      >
        <div className="CheckBankStatementForm__illustration__bankIcon">
          <Icon name="bank" />
          <p className="CheckBankStatementForm__illustration__bankIcon__title">
            <Trans i18nKey="ach.bankStatement">
              <br />
            </Trans>
          </p>
        </div>
        <img width="267" height="302" alt="" src={BankStatementIllustration} />
      </div>
    </div>
  );
};
