import { Icon } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useNewRequestRoute } from 'src/core/modules/requests/hooks/useNewRequestRoute';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import styles from './NewRequestButton.module.css';

export const NewRequestButton = () => {
  const { t } = useTranslation('global');
  const requestRoute = useNewRequestRoute({});
  const history = useHistory();

  return (
    <button
      // Use id instead of class to have better specificity
      id={styles.button}
      type="button"
      className="navigation-item"
      onClick={() => {
        track(AnalyticEventName.REQUEST_NEW_BUTTON_CLICKED, {
          from: 'nav_bar',
        });
        history.push(requestRoute);
      }}
      aria-label={t('misc.newRequest_short')}
    >
      <Icon className="shrink-0" name="plus" />
      <span className="navigation-toggle-display">
        {t('misc.newRequest_short')}
      </span>
    </button>
  );
};
