export type IntegrationValidateSettingsResult = {
  spendeskBankAccount: SpendeskBankAccountValidationFailedResult | undefined;
  spendeskFsBankAccount:
    | SpendeskFsBankAccountValidationFailedResult
    | undefined;
  fundingBankAccount: FundingAccountValidationFailedResult | undefined;
  bankFeesAccount: BankFeesAccountValidationFailedResult | undefined;
  employeeAccounts: EmployeeAccountValidationFailedResult[];
  expenseAccounts: ExpenseAccountValidationFailedResult[];
  supplierAccounts: SupplierAccountValidationFailedResult[];
  taxAccounts: TaxAccountValidationFailedResult[];
  analyticalFields: AnalyticalFieldValidationFailedResult[];
  journalCard: JournalMappingValidationFailedResult | undefined;
  journalExpenseClaims: JournalMappingValidationFailedResult | undefined;
  journalInvoicePayables: JournalMappingValidationFailedResult | undefined;
  journalBank: JournalMappingValidationFailedResult | undefined;
};

export function haveSettingsAtLeastOneValidationError(
  validationResult: IntegrationValidateSettingsResult,
): boolean {
  return (
    Boolean(validationResult.bankFeesAccount) ||
    Boolean(validationResult.fundingBankAccount) ||
    Boolean(validationResult.spendeskBankAccount) ||
    Boolean(validationResult.journalInvoicePayables) ||
    Boolean(validationResult.journalCard) ||
    Boolean(validationResult.journalExpenseClaims) ||
    validationResult.analyticalFields.length > 0 ||
    validationResult.employeeAccounts.length > 0 ||
    validationResult.expenseAccounts.length > 0 ||
    validationResult.supplierAccounts.length > 0 ||
    validationResult.taxAccounts.length > 0
  );
}

type JournalMappingValidationFailedResult = {
  error: 'journalMappingNotSelected';
};

type SpendeskBankAccountValidationFailedResult =
  | {
      error: 'invalidSpendeskBankAccount';
      value: string;
      suggestions: undefined;
    }
  | {
      error: 'spendeskBankAccountNotSelected';
    };

type SpendeskFsBankAccountValidationFailedResult =
  | {
      error: 'invalidSpendeskFsBankAccount';
      value: string;
      suggestions: undefined;
    }
  | {
      error: 'spendeskFsBankAccountNotSelected';
    };

type FundingAccountValidationFailedResult = {
  error: 'invalidFundingBankAccount';
  value: string;
  suggestions: undefined;
};

type BankFeesAccountValidationFailedResult =
  | {
      error: 'invalidBankFeesAccount';
      value: string;
      suggestions: undefined;
    }
  | {
      error: 'bankFeesAccountNotSelected';
    };

type AnalyticalFieldValidationFailedResult = {
  error: 'invalidValue';
  value: string;
  suggestions: undefined;
};

export type EmployeeAccountValidationFailedResult = {
  error: 'invalidAccount' | 'noTenant';
  id: string;
};

type ExpenseAccountValidationFailedResult = {
  error: 'invalidAccount';
  id: string;
};

type SupplierAccountValidationFailedResult = {
  error: 'invalidAccount' | 'noTenant';
  id: string;
};

type TaxAccountValidationFailedResult = {
  error:
    | 'invalidBuCodeFormat'
    | 'unknownBuCode'
    | 'buCodeUnsupportedByDatevBelegeOnline'
    | 'invalidBuCodeForAccountsPayable'
    | 'invalidBuCodeTaxRateCombination';
  id: string;
};
