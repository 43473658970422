import { gql } from 'graphql-tag';

export const GET_EXPENSE_ACCOUNTS_WITH_ARCHIVED_FIELD = gql`
  query GetExpenseAccountsWithArchived(
    $companyId: ID!
    $filter: ExpenseAccountFilter!
  ) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        expenseAccounts(filter: $filter) {
          edges {
            node {
              id
              name
              isArchived
              code
            }
          }
        }
      }
    }
  }
`;
