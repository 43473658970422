import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  payableId: string;
  path: string;
};

type Response =
  | {
      outcome: 'deleted';
    }
  | {
      outcome: 'notDeleted';
      reason:
        | 'payableNotFound'
        | 'attachmentNotFound'
        | 'invalidAttachmentCount'
        | 'payableAlreadyExists'
        | 'invalidPayableSubtype';
    };

export const useDeleteDocumentaryEvidence = ({
  onSuccess,
}: { onSuccess?: () => void } = {}): MutationState<Payload, Response> => {
  return useMutation<Payload, Response, Response>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'delete',
      endpoint: '/bookkeeping/documentary-evidence',
    },
    reshapeData: (data) => data,
    options: {
      onSuccess,
    },
  });
};
