import { useUpdateGetPayableQueryCache } from 'modules/bookkeep/apis/prepare-payable/useGetPayableQuery';
import { type PayableId } from 'modules/payable/models';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { useInvalidateBookkeepingNavbarCounters } from '../../hooks/useInvalidateBookkeepNavbarCounters';
import { useUpdatePayableQueryCache } from '../../payables/containers/PayablePanelContainer/hooks';
import { useUpdatePayablesQueryCache } from '../../prepare-payables/components/PreparePayablesBuckets/hooks/usePayablesQuery/usePayablesQuery';

type UndoMarkPayableAsPreparedError = {
  outcome: 'notUndidMarkPayableAsPrepared';
} & (
  | {
      reason: 'expectationFailure';
      error: Error;
    }
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason:
        | 'unauthorized'
        | 'notFound'
        | 'invalidState'
        | 'wrongVersion'
        | 'updateInProgress'
        | 'nothingToUpdate';
    }
);

export type UndoMarkPayableAsPreparedResponse = {
  outcome: 'undidMarkPayableAsPrepared';
};

export type UndoMarkPayableAsPreparedPayload = {
  payableId: PayableId;
  payableVersion: number;
};

/**
 * Mutation
 **/

export const useUndoMarkPayableAsPrepared = () => {
  const companyId = useCompanyId();
  const updateGetPayableQueryCache = useUpdateGetPayableQueryCache();
  const updatePayablesQueryCache = useUpdatePayablesQueryCache();
  const updatePayableQueryCache = useUpdatePayableQueryCache();

  const invalidateBookkeepingNavbarCounters =
    useInvalidateBookkeepingNavbarCounters();

  return useMutation<
    UndoMarkPayableAsPreparedPayload,
    UndoMarkPayableAsPreparedResponse,
    UndoMarkPayableAsPreparedResponse,
    UndoMarkPayableAsPreparedError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/bookkeeping/payable/undo-mark-as-prepared`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data, payload }) => {
        if (data.outcome === 'undidMarkPayableAsPrepared') {
          const update = {
            version: payload.payableVersion + 1,
            state: 'unprepared' as const,
          };

          updateGetPayableQueryCache(companyId, payload.payableId, update);
          updatePayablesQueryCache(companyId, payload.payableId, update);
          updatePayableQueryCache(payload.payableId, update);

          invalidateBookkeepingNavbarCounters({
            action: 'INCREMENT_PAYABLES_TO_PREPARE_COUNT',
            value: 1,
          });
        }
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};
