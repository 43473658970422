import { type QueryClient } from 'src/core/api/client';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

interface CommitmentsCounts {
  subscriptionsCount: number;
  purchaseOrdersCount: number;
}

interface RawCommitmentsCounts {
  subscriptionsCount: number;
  purchaseOrdersCount: number;
}

export const useFetchCommitmentsCountsQuery = (): QueryState<
  CommitmentsCounts,
  RawCommitmentsCounts
> => {
  return useQuery<CommitmentsCounts, RawCommitmentsCounts>({
    key: 'fetchCommitmentsCounts',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/commitments/counts',
    },
    reshapeData: (data) => {
      return {
        subscriptionsCount: data.subscriptionsCount,
        purchaseOrdersCount: data.purchaseOrdersCount,
      };
    },
  });
};

export const invalidateCommitmentsCountsQueries = (
  queryClient: QueryClient,
): void => {
  queryClient.invalidateQueries('fetchCommitmentsCounts');
};
