import {
  type FormikConfig,
  type FormikErrors,
  type FormikValues,
  useFormik,
} from 'formik';
import {
  type DependencyList,
  type EffectCallback,
  useEffect,
  useState,
} from 'react';

import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

type ValidationStrategy<Values extends FormikValues = FormikValues> =
  | { type: 'ON_CHANGE' }
  | { type: 'ON_CHANGE_AND_BLUR' }
  | {
      type: 'ON_CHANGE_WHEN_INVALID';
      // Those fields will not trigger the `onChange` validation when they are invalid
      invalidFieldsToIgnore?: (keyof Values)[];
    };

export const useFormikWithValidationStrategy = <
  Values extends FormikValues = FormikValues,
>(
  formikConfig: FormikConfig<Values>,
  validationStrategy: ValidationStrategy<Values> = {
    type: 'ON_CHANGE_WHEN_INVALID',
  },
) => {
  const [validateOnChange, setValidateOnChange] = useState(false);

  const validationStrategyProps: {
    validateOnChange: boolean;
    validateOnBlur: boolean;
    getEffect?: () => { run: EffectCallback; dependencies: DependencyList };
  } = (() => {
    const validationStrategyType = validationStrategy.type;

    switch (validationStrategyType) {
      case 'ON_CHANGE':
        return {
          validateOnChange: true,
          validateOnBlur: false,
        };

      case 'ON_CHANGE_AND_BLUR':
        return {
          validateOnChange: true,
          validateOnBlur: true,
        };

      case 'ON_CHANGE_WHEN_INVALID':
        return {
          validateOnChange,
          validateOnBlur: false,
          getEffect: () => ({
            run: () => {
              const errorKeys = Object.keys(formikProps.errors ?? {});

              const hasOnlyErrorFromIgnoredFields =
                errorKeys.filter((error) =>
                  (validationStrategy.invalidFieldsToIgnore || []).includes(
                    error,
                  ),
                ).length === errorKeys.length;

              setValidateOnChange(
                !formikProps.isValid && !hasOnlyErrorFromIgnoredFields,
              );
            },
            dependencies: [formikProps.isValid],
          }),
        };

      default:
        rejectUnexpectedValue('validationStrategyType', validationStrategyType);
    }
  })();

  const formikProps = useFormik({
    ...formikConfig,
    ...validationStrategyProps,
  });

  const effect = validationStrategyProps.getEffect?.();
  useEffect(() => effect?.run(), effect?.dependencies ?? []);

  const validateField = async (
    fieldName: keyof Values | string,
    values?: Values,
  ) => {
    if (formikConfig.validate) {
      const { [fieldName]: fieldError } =
        (await formikConfig.validate(values ?? formikProps.values)) ?? {};

      // Merge existing errors with the possible error from the specified fieldName
      const errors = Object.fromEntries(
        Array.from(
          Object.entries({ ...formikProps.errors, [fieldName]: fieldError }),
        ).filter(([_, value]) => value),
      ) as unknown as FormikErrors<Values>;

      formikProps.setErrors(errors);
    }
  };

  return { ...formikProps, validateField };
};
