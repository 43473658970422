import { Button, Callout } from '@dev-spendesk/grapes';
import React, { useMemo } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import {
  useNotifyTransfersApprovers,
  useConfirmTransfersTrackingEvents,
} from '../../hooks';
import { type FinanceController } from '../../models';

type Props = {
  financeControllers: FinanceController[];
};

export const FinanceControllersInformation = ({
  financeControllers,
}: Props) => {
  const { t } = useTranslation('global');
  const confirmTransfersTrackingEvents = useConfirmTransfersTrackingEvents();
  const [notifyTransfersApprovers, notifyTransfersApproversQueryState] =
    useNotifyTransfersApprovers();
  const approverNames = useMemo(
    () => formatNames(financeControllers),
    [financeControllers],
  );

  return (
    <Callout
      title={t('invoices.transfer.approversInformations.title')}
      variant="info"
      className="mb-s"
    >
      <>
        <div className="my-s">
          {t('invoices.transfer.approversInformations.description', {
            names: approverNames,
            count: financeControllers.length,
          })}
        </div>

        <Button
          variant="contrasted"
          text={t('invoices.transfer.approversInformations.notifyCTA')}
          onClick={() => {
            confirmTransfersTrackingEvents.onSendReminderClicked();
            notifyTransfersApprovers();
          }}
          isDisabled={['loading', 'success'].includes(
            notifyTransfersApproversQueryState.status,
          )}
        />

        {notifyTransfersApproversQueryState.status === 'error' && (
          <Callout
            title={t(
              'invoices.transfer.approversInformations.notificationError',
            )}
            variant="alert"
            className="mt-s"
          />
        )}

        {notifyTransfersApproversQueryState.status === 'success' && (
          <Callout
            title={t(
              'invoices.transfer.approversInformations.notificationSuccess',
            )}
            variant="success"
            className="mt-s"
          />
        )}
      </>
    </Callout>
  );
};

const formatNames = (financeControllers: FinanceController[]): string => {
  return financeControllers
    .map((financeController) => financeController.fullName)
    .join(', ');
};
