import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routeBuilder } from 'src/core/common/hooks/useGoTo';

export const useGoToPaymentMethods = () => {
  const getRoute = usePaymentMethodsRoute();
  const history = useHistory();

  return () => {
    const route = getRoute();
    history.push(route);
  };
};

const usePaymentMethodsRoute = () => {
  const companyId = useCompanyId();
  return (): string => getPaymentMethodsRoute(companyId);
};

const getPaymentMethodsRoute = (companyId: string): string => {
  return routeBuilder('COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS', {
    company: companyId,
  });
};
