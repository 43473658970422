import { absolute } from 'ezmoney';
import React from 'react';

import { MissingFundsWarning } from './MissingFundsWarning';
import { useWalletTransactionForecastOnTransfersQueryData } from '../../hooks';

export const MissingFundsNotice = () => {
  const walletTransactionForecastOnTransfersQueryDataQueryState =
    useWalletTransactionForecastOnTransfersQueryData()();

  if (
    walletTransactionForecastOnTransfersQueryDataQueryState.status ===
      'success' &&
    !walletTransactionForecastOnTransfersQueryDataQueryState.data.hasEnoughFunds
  ) {
    return (
      <MissingFundsWarning
        missingFunds={absolute(
          walletTransactionForecastOnTransfersQueryDataQueryState.data
            .walletAmountAfterTransaction,
        )}
        walletAmount={
          walletTransactionForecastOnTransfersQueryDataQueryState.data
            .walletAmount
        }
      />
    );
  }

  return null;
};
