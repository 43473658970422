import { useCompany } from 'modules/app/hooks/useCompany';

export const useHasAccessToKycStateRelatedFeatures = (): boolean => {
  const company = useCompany();

  const isCompanyInitial =
    // @ts-expect-error: Not an helpful comment
    company.created_from === null && company.type === 'initial';
  const isKycAlreadyValidated = company.is_kyb_validated;

  if (company.type === 'branch_expense_entity') {
    return false;
  }
  if (company.banking_provider === 'sfs' || !company.banking_provider) {
    return isKycAlreadyValidated;
  }
  return isKycAlreadyValidated || !isCompanyInitial;
};
