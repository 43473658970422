import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export const isNilOrEmpty = (value?: unknown): boolean => {
  if (typeof value === 'string') {
    return Boolean(isNil(value) || isEmpty(value.trim()));
  }

  return Boolean(isNil(value) || isEmpty(value));
};
