import { FormField } from '@dev-spendesk/grapes';
import React from 'react';

import { CountryAutocomplete } from 'common/components/CountryAutocomplete';
import { useTranslation } from 'common/hooks/useTranslation';

import { type BankInfosErrors, type SupplierBankInfos } from '../../../models';
import { FormFieldHint } from '../FormFieldHint';

type Props = {
  className?: string;
  bankCountry: SupplierBankInfos['bankCountry'];
  bankCountrySuggestion?: SupplierBankInfos['bankCountry'];
  error?: BankInfosErrors['bankCountry'];
  onChange(bankCountry: SupplierBankInfos['bankCountry']): void;
};

export const BankCountryFormField = ({
  className,
  bankCountry,
  bankCountrySuggestion,
  error,
  onChange,
}: Props) => {
  const { t } = useTranslation('global');
  const isValueFromSuggestion =
    Boolean(bankCountrySuggestion) && bankCountry === bankCountrySuggestion;

  return (
    <FormField
      className={className}
      label={t('submitMyInvoice.fields.supplierBankCountry.label')}
      alertMessage={error ? t('misc.requiredField') : undefined}
      hint={
        <FormFieldHint optionalField={false} showOCR={isValueFromSuggestion} />
      }
    >
      <CountryAutocomplete
        name="bankCountry"
        fit="parent"
        placeholder={t(
          'submitMyInvoice.fields.supplierBankCountry.placeholder',
        )}
        countryCode={bankCountry ?? ''}
        onSelect={(selectedCountry: string | undefined) =>
          onChange(selectedCountry)
        }
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (e.target.value === '') {
            onChange(undefined);
          }
        }}
      />
    </FormField>
  );
};
