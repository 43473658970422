import { getDate } from 'date-fns';
import compact from 'lodash/compact';
import sum from 'lodash/sum';

import { type Company } from '../modules/app/hooks/useCompany';

export const HARD_RELOAD = 'hard-reload';
export const SOFT_RELOAD = 'soft-reload';
export const NO_RELOAD = 'no-reload';

export const getAmountNeeded = (company: Company, budget: number) => {
  const available = sum(
    compact([
      company.balance_available_all_accounts,
      company.balance_loaded_reloadable_cards,
    ]),
  );
  return budget - available;
};

export const getReloadType = (
  company: Company,
  budget: number | null | undefined,
) => {
  if (getDate(new Date()) < 20) {
    return NO_RELOAD;
  }
  if (!budget) {
    return NO_RELOAD;
  }
  const needed = getAmountNeeded(company, budget);
  if (needed >= 0) {
    return HARD_RELOAD;
  }
  if (needed + company.suggest_to_reload_amount >= 0) {
    return SOFT_RELOAD;
  }
  return NO_RELOAD;
};
