import { colors, Icon, IconButton } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { toggleForceToExpandNavigation } from '../NavigationContext';

import './Logo.css';

export const Logo = () => {
  const { t } = useTranslation('global');
  return (
    <div className="ml-xxs flex items-center justify-between">
      <Icon
        name="spendesk"
        color={colors.white}
        aria-hidden="true"
        size="l"
        className="ml-xxs shrink-0"
      />
      <IconButton
        className="navigation-toggle-display text-white"
        iconName="drawer"
        aria-label={t('navigation.expandedOrCollapseNavigation')}
        onClick={() => {
          toggleForceToExpandNavigation();
        }}
      />
    </div>
  );
};
