import { type MonetaryValue } from 'ezmoney';

import { type PayableType, type Group } from 'modules/bookkeep/types';
import { payableTypeToTranslationKey } from 'modules/bookkeep/utils';
import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { type GroupFilter } from '../../../../models';

export type RawGroup = {
  id: string;
  name: string;
  thumbnailUrl: string;
  payables: {
    totalCount: number;
    connectionId: string;
    aggregate: {
      sum: {
        functionalAmount: MonetaryValue;
      };
    };
  };
};

const payableGroupFilterToAPITypeMap = new Map([
  ['expenseType', 'type'],
  ['supplier', 'supplier'],
  ['expenseAccount', 'expenseAccount'],
]);

export const toAPIPayableGroupType = (
  groupOption: GroupFilter | undefined,
): string | undefined =>
  groupOption ? payableGroupFilterToAPITypeMap.get(groupOption) : undefined;

const reshapeGroupName = (
  groupId: string,
  groupName: string,
  groupOption: GroupFilter | undefined,
  translator: TGlobalFunctionTyped,
): string => {
  if (groupId === 'default') {
    if (groupOption === 'supplier') {
      return translator('expenseInbox.expensesBar.missingSupplier');
    }

    if (groupOption === 'expenseAccount') {
      return translator('expenseInbox.expensesBar.missingExpenseAccount');
    }

    if (groupOption === 'taxAccount') {
      return translator('expenseInbox.expensesBar.missingVatAccount');
    }

    return translator('expenseInbox.expensesBar.defaultGroup');
  }
  if (groupId === 'recommendations') {
    return translator('expenseInbox.expensesBar.recommendationsGroup');
  }

  if (groupOption === 'expenseType') {
    return translator(payableTypeToTranslationKey(groupId as PayableType));
  }

  if (groupOption === 'taxAccount' && groupId === 'multiple_vat') {
    return translator('expenseInbox.multipleVat');
  }
  if (groupOption === 'taxAccount' && groupId === 'reverse_charge') {
    return translator('expenseInbox.reverseCharge');
  }
  return groupName;
};

export const reshapeGroup = (
  rawGroup: RawGroup,
  groupOption: GroupFilter | undefined,
  translator: TGlobalFunctionTyped,
): Group => {
  const label = reshapeGroupName(
    rawGroup.id,
    rawGroup.name,
    groupOption,
    translator,
  );

  return {
    id: rawGroup.id,
    label,
    thumbnailUrl: rawGroup.thumbnailUrl,
    totalCount: rawGroup.payables.totalCount,
    totalAmount: rawGroup.payables.aggregate.sum.functionalAmount,
    connectionId: rawGroup.payables.connectionId,
  };
};
