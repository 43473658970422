import { useState } from 'react';

import { PreviewFileItem } from 'modules/requests/components/common/PreviewFileItem';

import { type MediaType } from './FileViewerModalWrapper';
import { ImageViewer, PDFViewer } from '../FileViewer';
import { usePageViewer } from '../layout/SiderPageLayout/viewerContext';

type FileReceiptProps = {
  previewName: string;
  fileUrl: string;
  fileMediaType: MediaType;
  onDelete?: () => void;
};

export const FileReceipt = ({
  previewName,
  fileUrl,
  fileMediaType,
  onDelete,
}: FileReceiptProps) => {
  const [isPreviewHighlighted, setIsPreviewHighlighted] = useState(false);

  const setViewerContent = usePageViewer({
    team: 'capture',
    scope: 'pageViewerContext::FileReceipt',
  });
  const showFileViewer = () => {
    const fileViewer =
      fileMediaType === 'application/pdf' ? (
        <PDFViewer
          url={fileUrl}
          onDownload={() => window.open(fileUrl, '_blank')}
          onClose={hideFileViewer}
          className="w-[900px] min-w-[500px] max-w-[80%]"
        />
      ) : (
        <ImageViewer
          url={fileUrl}
          withZoom
          withRotate
          onDownload={() => window.open(fileUrl, '_blank')}
          onClose={hideFileViewer}
          withoutCrossOrigin
        />
      );
    setIsPreviewHighlighted(true);
    setViewerContent(fileViewer);
  };

  const hideFileViewer = () => {
    setIsPreviewHighlighted(false);
    setViewerContent(undefined);
  };

  return (
    <div>
      <PreviewFileItem
        isHighlighted={isPreviewHighlighted}
        fileName={previewName}
        onPreview={showFileViewer}
        mimeType={fileMediaType}
        onDelete={onDelete}
      />
    </div>
  );
};
