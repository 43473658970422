import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

export interface User {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  avatar: string;
}

export interface SuspendedUser {
  id: string;
  givenName: string;
  familyName: string;
}

export interface Invitee {
  id: string;
  email: string;
}

export interface Supplier {
  id: string;
  name: string;
  thumbnailUrl: string;
}

export function isUser(input: object): input is User {
  return (input as User).givenName !== undefined;
}

export function isInvitee(input: object): input is Invitee {
  return (input as Invitee).email !== undefined;
}

export function isSupplier(input: object): input is Supplier {
  return (input as Supplier).name !== undefined;
}

export function isSuspendedUser(input: object): input is SuspendedUser {
  return (input as SuspendedUser).givenName !== undefined;
}

export const formatMemberName = (
  member: User | SuspendedUser | Invitee | undefined,
  translator: TGlobalFunctionTyped,
): string => {
  if (!member) {
    return translator('misc.unknownMember');
  }

  if (
    (isUser(member) || isSuspendedUser(member)) &&
    member.givenName &&
    member.familyName
  ) {
    return `${member.givenName} ${member.familyName}`;
  }

  if (isUser(member) || isInvitee(member)) {
    return member.email;
  }

  return translator('misc.unknownMember');
};
