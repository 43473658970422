import { useMemo } from 'react';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { AutocompleteMultipleSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useMembersQueryRaw } from 'src/core/modules/members/hooks/useMembersQueryRaw';

type Option = {
  label: string;
  key: string;
};

type Props = {
  onChange: (memberIds: string[]) => void;
  values: string[];
};

export const MultipleMemberAutocomplete = ({ onChange, values }: Props) => {
  const { t } = useTranslation('global');

  const membersQueryState = useMembersQueryRaw();
  const membersToDisplay: Option[] = useMemo(() => {
    const members = unwrapQuery(membersQueryState) || [];
    return members
      .map((member) => {
        const label = member.pending ? member.email : member.fullname;
        return {
          label,
          key: member.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [membersQueryState]);

  const selectedMembers = values
    .map((id) => membersToDisplay.find((member) => member.key === id))
    .filter(Boolean) as Option[];

  return (
    <AutocompleteMultipleSearch
      fit="parent"
      placeholder={t('controlRulesModal.members.search')}
      translations={{
        selected: t('controlRulesModal.members.counter', {
          count: values.length,
        }),
      }}
      onSelect={(members) => onChange(members.map((member) => member.key))}
      options={membersToDisplay}
      values={selectedMembers}
    />
  );
};
