import { createSelector } from '@reduxjs/toolkit';

import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';
import {
  isController as getIsUserController,
  isAdmin as getIsUserAdmin,
  isAccountOwner as getIsUserAccountOwner,
} from 'src/core/selectors/users';

export const getHasCommitmentsTab = createSelector(
  getIsUserController,
  getIsUserAdmin,
  getIsUserAccountOwner,
  (state: AppState) => getIsFeatureEnabled(state, FEATURES.PURCHASE_ORDERS),
  (state: AppState) =>
    getIsFeatureEnabled(state, FEATURES.CONTROL_ON_PURCHASE_ORDER),
  (state: AppState) =>
    getIsFeatureEnabled(state, FEATURES.TMP_VERTICAL_NAVIGATION),
  (
    isUserController,
    isUserAdmin,
    isUserAccountOwner,
    hasPurchaseOrdersFeature,
    hasControlOnPurchaseOrdersFeature,
    hasVerticalNavigation,
  ) => {
    if (hasVerticalNavigation) {
      return false;
    }
    if (!hasPurchaseOrdersFeature && hasControlOnPurchaseOrdersFeature) {
      return isUserAdmin || isUserAccountOwner;
    }

    return hasPurchaseOrdersFeature && isUserController;
  },
);
