import { useQueryClient } from 'react-query';

import { useFeature } from 'common/hooks/useFeature';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import FEATURES from 'src/core/constants/features';

const requestParams = {
  type: 'rest',
  target: 'companyAPI',
  endpoint: '/expense-categories/designated-custom-field',
  method: 'get',
} as const;
const queryKey = 'designatedCustomField';
const staleTime = 1000 * 60 * 5; // 5 minutes

type Response = { customFieldId: string };

export const useDesignatedCustomFieldQuery = (): QueryState<Response> => {
  const isExpenseCategoriesFeatureEnabled = useFeature(
    FEATURES.EXPENSE_CATEGORIES,
  );

  return useQuery<Response>({
    key: 'designatedCustomField',
    isEnabled: isExpenseCategoriesFeatureEnabled,
    request: requestParams,
    options: {
      staleTime,
    },
  });
};

export const useDesignatedCustomFieldQueryPromise = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const designatedCustomFieldFetcher = () =>
    getFetcher<Response>({
      companyId,
      getRequest: () => requestParams,
    });

  return async (): Promise<Response> => {
    return queryClient.fetchQuery(queryKey, designatedCustomFieldFetcher(), {
      staleTime,
    });
  };
};
