import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ApiApprovalPolicy } from '../../company/members/components/ApprovalPolicies';
import { type Policy, reshapeToApprovalPolicy } from '../models/policy';

export const usePoliciesQuery = (): QueryState<Policy[]> => {
  return useQuery<Policy[], ApiApprovalPolicy[]>({
    key: ['policies'],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/policies`,
    },
    reshapeData: (data) => (data ?? []).map(reshapeToApprovalPolicy),
  });
};
