import { Autocomplete, FormField } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { useGetSupplierAccountsQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSupplierAccountsQuery';
import { type SupplierAccount } from 'modules/bookkeep/accounts-payable/types';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import {
  type DefaultSupplierAccount,
  getDefaultSupplierAccountName,
  type SupplierAccountDefaultFor,
} from 'modules/bookkeep/settings/accounting';
import { AutocompleteNoOptions } from 'src/core/common/components/AutocompleteNoOptions';
import { useDebouncedState } from 'src/core/common/hooks/useDebounceState';
import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import { getCodeWithAuxiliaryAccounts } from 'src/core/utils/accountPayable';

import styles from './SupplierAccountsPullWithDefaultAccountsSection.module.css';
import { useSetDefaultSupplierAccountMutation } from '../../../../../hooks/useSetDefaultSupplierAccountMutation';
import { getDefaultSupplierAccountPlaceholderI18nKey } from '../translations';

type SectionProps = {
  defaultFor: SupplierAccountDefaultFor;
  savedDefaultSupplierFor: DefaultSupplierAccount | undefined;
  integrationStatus: IntegrationStatusWithIntegration;
  t: TGlobalFunctionTyped;
  onSelectOption?: (id: string) => void;
};

type AutocompleteOption = { key: string; label: string };

export const DefaultSupplierSelect = ({
  defaultFor,
  savedDefaultSupplierFor,
  integrationStatus,
  t,
  onSelectOption,
}: SectionProps) => {
  const [selectedOption, setSelectedOption] = useState<
    AutocompleteOption | undefined
  >(
    savedDefaultSupplierFor
      ? {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          key: savedDefaultSupplierFor.id!,
          label: getCodeWithAuxiliaryAccounts(savedDefaultSupplierFor),
        }
      : undefined,
  );
  const [search, setSearch] = useDebouncedState<string>('', 300);
  const getSupplierAccountsQueryState = useGetSupplierAccountsQuery({
    includeArchived: false,
    search,
  });

  const { pushNotif } = useNotifications();

  const options =
    getSupplierAccountsQueryState.status === 'success'
      ? getSupplierAccountsQueryState.data.map(
          (supplierAccount: SupplierAccount) => ({
            key: supplierAccount.id,
            label: getCodeWithAuxiliaryAccounts(supplierAccount),
          }),
        )
      : [];

  const [setDefaultSupplierAccountFor] = useSetDefaultSupplierAccountMutation();
  const onSelect = async (supplierAccount: AutocompleteOption) => {
    if (!supplierAccount || supplierAccount === selectedOption) {
      return;
    }
    try {
      if (getSupplierAccountsQueryState.status === 'success') {
        const existingAccount = getSupplierAccountsQueryState.data.find(
          (account) => account.id === supplierAccount.key,
        );
        if (existingAccount) {
          await setDefaultSupplierAccountFor({
            ...existingAccount,
            // TODO: We might need the name from the graphql Query.
            name: getDefaultSupplierAccountName(
              integrationStatus.accountingCountry,
            ),
            defaultFor,
          });
          setSelectedOption(supplierAccount);
          if (onSelectOption) {
            onSelectOption(supplierAccount.key);
          }
          pushNotif({
            type: NotificationType.Success,
            message: t(
              'bookkeep.integrations.settings.supplierAccountsTable.successMessage',
            ),
          });
        }
      }
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t(
          'bookkeep.integrations.settings.supplierAccountsTable.errorMessage',
        ),
      });
    }
  };

  return (
    <FormField
      label={t(
        'bookkeep.integrations.settings.defaultSupplierAccountForm.name',
      )}
      className={styles.defaultForm__select}
    >
      <Autocomplete
        fit="parent"
        placeholder={t(
          getDefaultSupplierAccountPlaceholderI18nKey(
            integrationStatus.integration,
          ),
        )}
        isLoading={getSupplierAccountsQueryState.status === 'loading'}
        value={selectedOption}
        options={options}
        onSearch={(keyword) => setSearch(keyword ?? '')}
        onSelect={onSelect}
        renderNoOptions={(rawValue) => (
          <AutocompleteNoOptions value={rawValue} />
        )}
      />
    </FormField>
  );
};
