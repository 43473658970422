import React from 'react';

import './MissingReceiptsLoader.css';

export const MissingReceiptsLoader = () => {
  return (
    <div className="box h-[400px]">
      <div className="MissingReceiptsLoader__skeleton" />
      <div className="MissingReceiptsLoader__skeleton MissingReceiptsLoader__skeleton--long" />
      <div className="MissingReceiptsLoader__skeleton MissingReceiptsLoader__skeleton--long" />
      <div className="MissingReceiptsLoader__skeleton MissingReceiptsLoader__skeleton--long" />
      <div className="MissingReceiptsLoader__skeleton MissingReceiptsLoader__skeleton--long" />
    </div>
  );
};
