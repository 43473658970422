import { Accordion, Icon, NavigationItem, Popover } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { useState } from 'react';

import { ModalMFAContainer } from 'common/components/ModalMFA';
import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useUser } from 'modules/app/hooks/useUser';
import { useGetOnboardingUserTasksQuery } from 'modules/onboarding/setup-hub/hooks/useGetOnboardingUserTasksQuery';
import { type UserTaskType } from 'modules/onboarding/setup-hub/types/userTask';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { ReadyToSpendTaskItem } from './components/ReadyToSpendTaskItem';
import { ReadyToSpendModalSlideshow } from '../ReadyToSpendModalSlideshow';
import { READY_TO_SPEND_TASKS } from '../SetupGuideNavigationItem/SetupGuideNavigationItem';

type MFAModalProperties =
  | {
      isOpen: true;
      onFactorActivated: () => void;
    }
  | {
      isOpen: false;
    };

export const ReadyToSpendPopover = () => {
  const { t } = useTranslation('global');
  const user = useUser();

  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);
  const tasksQueryState = useGetOnboardingUserTasksQuery();

  const [modalSlideshowType, setModalSlideshowType] =
    useState<UserTaskType | null>(null);

  const [mfaModalProperties, setMfaModalProperties] =
    useState<MFAModalProperties>({
      isOpen: false,
    });

  if (tasksQueryState.status !== 'success') {
    return null;
  }

  const tasks = tasksQueryState.data.filter((task) => {
    if (!READY_TO_SPEND_TASKS.includes(task.type)) {
      return false;
    }
    return task.type !== 'learn_to_approve' || user.is_approver;
  });

  if (tasks.length === 0) {
    return null;
  }

  const completedTasks = tasks.filter((task) => task.status === 'completed');
  if (completedTasks.length === tasks.length) {
    return null;
  }
  const progress = Math.floor((completedTasks.length / tasks.length) * 100);

  return (
    <ErrorBoundary context={{ team: 'growth', scope: 'ready-to-spend' }}>
      <Popover
        className={cx({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'popover-hack-align w-full': hasNewNavigation,
        })}
        placement={hasNewNavigation ? 'end-top' : 'bottom-start'}
        renderTrigger={(triggerProps) =>
          hasNewNavigation ? (
            <button
              {...triggerProps}
              className="navigation-item h-xl"
              type="button"
            >
              <Icon
                name="expense-claim"
                aria-hidden="true"
                className="shrink-0"
              />
              <span className="navigation-toggle-display grow">
                {t('readyToSpend.triggerText', { progress })}
              </span>
            </button>
          ) : (
            <NavigationItem
              {...triggerProps}
              text={t('readyToSpend.triggerText', { progress })}
              variant="mainNavigation"
              leftAddon={<Icon name="expense-claim" />}
              rightAddon={<Icon name="caret-down" />}
            />
          )
        }
      >
        {() => (
          <div className="max-h-[80vh] w-[500px] overflow-y-auto p-m">
            <div className="mb-s">
              <p className="mb-xs text-complementary title-xl">
                {t('readyToSpend.title')}
              </p>
              <p className="text-neutral-dark body-m">
                {t('readyToSpend.subtitle')}
              </p>
            </div>
            <div className="mb-m flex items-center gap-xs">
              <label
                className="text-neutral-darker title-m"
                htmlFor="ready-to-spend-progress"
              >
                {`${progress}%`}
              </label>
              <progress
                className="h-[10px] flex-1 accent-primary"
                id="ready-to-spend-progress"
                max="100"
                value={progress}
              />
            </div>
            <Accordion>
              {READY_TO_SPEND_TASKS.map((taskType) => {
                const task = tasks.find(({ type }) => type === taskType);
                if (!task) {
                  return null;
                }
                return (
                  <ReadyToSpendTaskItem
                    key={task.id}
                    setModalType={setModalSlideshowType}
                    task={task}
                    openMFAModal={(callback) =>
                      setMfaModalProperties({
                        isOpen: true,
                        onFactorActivated: callback,
                      })
                    }
                  />
                );
              })}
            </Accordion>
          </div>
        )}
      </Popover>
      <ReadyToSpendModalSlideshow
        onCloseClicked={() => setModalSlideshowType(null)}
        type={modalSlideshowType}
      />
      <ModalMFAContainer
        isOpen={mfaModalProperties.isOpen}
        onModalClose={() => setMfaModalProperties({ isOpen: false })}
        onFactorActivated={() => {
          if (
            mfaModalProperties.isOpen &&
            mfaModalProperties.onFactorActivated
          ) {
            setMfaModalProperties({ isOpen: false });
            mfaModalProperties.onFactorActivated();
          }
        }}
      />
    </ErrorBoundary>
  );
};
