import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

export const useRefreshAccountingSettingsMutation = (): MutationState => {
  return useMutation({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: '/accounting-integration/refresh-settings',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        [
          'useIntegrationStatusQuery',
          'getBankAccounts',
          'getExpenseAccounts',
          'getTaxAccounts',
          'getAnalyticalFieldMappings',
          'useExpenseAccountsQuery', // expense account list in Prepare
          'useExpenseAccountQuery', // expense account detail in Prepare
          'useTaxAccountsQuery', // tax account list in Prepare
          'useReverseChargeTaxAccountRatesQuery', // reverse tax account list in Prepare
          'useGetPayableQuery', // payable details in Prepare
          'payable', // cache payable details in Prepare
          'useTaxAndExpenseAccountsCountsQuery', // account tips in Prepare
          'useFieldValuesQuery', // teams/customFields/costCenters/supplierAccounts fields values in prepare
          'useSuppliersSyncStatus',
          'useMemberSyncQuery',
          'useEmployeeAccountCodesQuery',
          // TODO: invalidate supplier related queries
        ].forEach((key) => client.invalidateQueries(key));
      },
    },
  });
};
