import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import styles from './InvalidProofBanner.module.css';

type Props = {
  reason?: string;
};

export const InvalidProofBanner = ({ reason }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Callout
      variant="warning"
      className={styles.InvalidProofBanner}
      title={t('misc.receiptDeclaredAsInvalid')}
    >
      {reason && <div>« {reason} »</div>}
    </Callout>
  );
};
