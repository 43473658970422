import { useMemo } from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { useKycStatusCode } from 'src/core/modules/kyc/hooks/useKycStatusCode';

import { HeaderLoadNotif } from './HeaderLoadNotif';

export const HeaderLoadNotifContainer = () => {
  const company = useCompany();
  const user = useUser();

  const kycStatusCode = useKycStatusCode();
  const isKycSubmitted = useMemo(
    () =>
      kycStatusCode && !['not-started', 'initiated'].includes(kycStatusCode),
    [kycStatusCode],
  );

  const isAoOrAdmin = user.is_account_owner || user.is_admin;

  const shouldDisplayLoadNotif =
    isKycSubmitted === true && isAoOrAdmin && !company.is_kyb_validated;

  if (!shouldDisplayLoadNotif) {
    return null;
  }

  return <HeaderLoadNotif />;
};
