import identity from 'lodash/identity';

import { type Translations, useQueryError } from 'common/components/QueryError';
import { useNotifications } from 'modules/app/notifications';
import { useQuery } from 'src/core/api/hooks/useQuery';
import type { QueryError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';

import type { WireTransferActivationStatus } from '../models';

type WireTransferActivationStatusPerPayableEntity = Record<
  'forInvoices' | 'forExpenseClaims',
  WireTransferActivationStatus
>;

export const useWireTransferActivationStatusQuery = (): QueryState<
  WireTransferActivationStatusPerPayableEntity,
  unknown
> => {
  const { dangerNotif } = useNotifications();
  const getErrorMessage = useErrorMessage();

  return useQuery<
    WireTransferActivationStatusPerPayableEntity,
    WireTransferActivationStatusPerPayableEntity,
    unknown
  >({
    key: ['features', 'wiretransfer'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/features/wiretransfer`,
    },
    reshapeData: identity,
    options: {
      onError: ({ error }) => {
        dangerNotif(getErrorMessage(error));
      },
    },
  });
};

export const errorTranslations: Translations<unknown> = {
  requestError: () => {
    return 'wiretransfer.queryError.default';
  },
  serverError: 'wiretransfer.queryError.default',
};

export const useErrorMessage = () => {
  const getErrorMessage = useQueryError<unknown, object>(errorTranslations);

  return (queryError: QueryError<unknown>): string =>
    getErrorMessage(queryError);
};
