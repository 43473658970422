export const capitalize = (stringToCapitalize: string | null | undefined) => {
  if (typeof stringToCapitalize !== 'string' || !stringToCapitalize) {
    return '';
  }
  return (
    stringToCapitalize.charAt(0).toUpperCase() +
    stringToCapitalize.slice(1).toLowerCase()
  );
};

export const upperFirst = (stringToUpperFirst: string | null | undefined) => {
  if (typeof stringToUpperFirst !== 'string' || !stringToUpperFirst) {
    return '';
  }
  return (
    stringToUpperFirst.charAt(0).toUpperCase() + stringToUpperFirst.slice(1)
  );
};

export function fromCamelToSnakeCase(string_: string) {
  return string_.replaceAll(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function fromSnakeToCamelCase(string_: string) {
  return string_.replaceAll(/_([a-z])/g, (g) => g[1].toUpperCase());
}

export function truncate(stringToTruncate: string, stringMaxLength: number) {
  if (stringToTruncate.length <= stringMaxLength) {
    return stringToTruncate;
  }
  if (stringMaxLength < 3) {
    return '...';
  }
  return `${stringToTruncate.slice(0, stringMaxLength - 3)}...`;
}
