import { v4 as uuid } from 'uuid';

import { useQuery } from 'src/core/api/hooks';
import { type QueryState } from 'src/core/api/queryState';

import { type ApprovalRule } from '../../structure/approval-flows';
import {
  normalizeRules,
  removeAoRule,
  shouldRemoveAoRule,
} from '../../structure/approval-flows/models';

export type ApprovalScheme = {
  id: string;
  owner: {
    id: string;
  };
  rules: ApprovalRule[];
};

export const useApprovalSchemeByCostCenterIdQuery = (
  costCenterId: string,
): QueryState<ApprovalScheme> => {
  return useQuery<ApprovalScheme>({
    key: ['approval-scheme', 'by-owner', costCenterId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/approval-scheme/by-owner?type=costCenter&id=${costCenterId}`,
    },
    reshapeData: (data) => {
      const apiApprovalRules = data.rules ?? [];
      const approvalRules = shouldRemoveAoRule({ rules: apiApprovalRules })
        ? removeAoRule({ rules: apiApprovalRules }).rules
        : apiApprovalRules;
      const normalizedRules = normalizeRules(approvalRules);
      return {
        ...data,
        rules: normalizedRules.map((normalizedRule) => ({
          ...normalizedRule,
          id: uuid(),
        })),
      };
    },
  });
};
