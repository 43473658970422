import { gql } from 'graphql-tag';

export const GET_SUPPLIERS_WITHOUT_ACCOUNT_PAYABLE = gql`
  query GetSuppliersWithoutAccountPayable(
    $companyId: ID!
    $filter: SupplierFilter
    $after: ID
  ) {
    company(id: $companyId) {
      suppliers(first: 20, filter: $filter, after: $after) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            name
            thumbnailUrl
          }
        }
      }
    }
  }
`;
