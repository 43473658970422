import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type SupplierBankInfos } from '../../../models';
import { BankInfoPreview } from '../BankInfoPreview';

type Props = {
  accountNumber: SupplierBankInfos['accountNumber'];
};

export const AccountNumberPreview = ({ accountNumber }: Props) => {
  const { t } = useTranslation('global');

  return (
    <BankInfoPreview
      primaryText={t('submitMyInvoice.fields.accountNumber.label')}
      secondaryText={accountNumber}
    />
  );
};
