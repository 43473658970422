import { useCompany } from 'modules/app/hooks/useCompany';

import {
  getSupplierBankFields,
  type SupplierBankFields,
  type SupplierBankInfos,
} from '../../models';

export const useGetSupplierBankFields = () => {
  const company = useCompany();
  const getBankFields = getSupplierBankFields(company);

  return (supplierBankInfos: SupplierBankInfos): SupplierBankFields => {
    return getBankFields(supplierBankInfos);
  };
};
