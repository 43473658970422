import { ListItem, ListView } from '@dev-spendesk/grapes';
import React, { useMemo } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { NotificationSetting } from './components/NotificationSetting';
import { NotificationSlackSettingContainer } from './components/NotificationSlackSetting';
import { type Notification } from '../../models';

type Props = {
  notifications: Notification[];
};

export const NotificationSettings = ({ notifications }: Props) => {
  const approvalRightsNotification = notifications.find(
    (n) => n.notifCode === 'notifyApprovalRightsApprover',
  );
  const walletRefilledNotification = notifications.find(
    (n) => n.notifCode === 'walletRefilled',
  );
  const receiptRemindersNotification = notifications.find(
    (n) => n.notifCode === 'autoReminders',
  );

  const hasWalletAccessFeature = useFeature(FEATURES.WALLET_ACCESS);

  const notificationSettings = useMemo(
    () => [
      {
        notifCode: 'notifyApprovalRightsApprover',
        checked: approvalRightsNotification?.checked ?? false,
      },
      ...(hasWalletAccessFeature
        ? [
            {
              notifCode: 'walletRefilled',
              checked: walletRefilledNotification?.checked ?? true,
            },
          ]
        : []),
      {
        notifCode: 'autoReminders',
        checked: receiptRemindersNotification?.checked ?? true,
        value: receiptRemindersNotification?.value,
      },
    ],
    [notifications],
  );

  return (
    <ListView className="mt-m">
      {notificationSettings.map((notification) => (
        <ListItem key={notification.notifCode}>
          <NotificationSetting notification={notification} />
        </ListItem>
      ))}

      <NotificationSlackSettingContainer />
    </ListView>
  );
};
