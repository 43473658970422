import { useContext } from 'react';
import { useQueryClient, type QueryKey } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { makeGetPayablesBucketsStatsQuery } from './query';
import {
  reshapePayablesBucketsStatsResultData,
  type RawPayablesBucketsStats,
} from './reshaper';
import { PreparePayablesFiltersContext } from '../../../prepare-payables/contexts';
import {
  type BucketsStats,
  defaultSortFilter,
  type Filters,
  toAPIPayableFilters,
  type PayableFilters,
} from '../../../prepare-payables/models';

/**
 * Query and cache config
 */

export type PayablesBucketsQueryRawResult = RawPayablesBucketsStats;

export type PayablesBucketsQueryResult =
  | { outcome: 'noPayables' }
  | { outcome: 'noUnpreparedPayables' }
  | { outcome: 'noMatchingUnpreparedPayables' }
  | { outcome: 'ok'; bucketsStats: BucketsStats };

export type PayablesBucketsQueryVariables = {
  filters: PayableFilters;
  withProofFilters: PayableFilters;
  missingProofFilters: PayableFilters;
  withoutProofFilters: PayableFilters;
};

export const getQueryKey = (
  companyId: string,
  variables?: Partial<PayablesBucketsQueryVariables>,
): QueryKey => {
  return ['usePayablesBucketsStatsQuery', companyId, variables].filter(Boolean);
};

export const useInvalidatePayablesBucketsStatsQueryCache = () => {
  const queryClient = useQueryClient();

  return async (
    companyId: string,
    variables?: PayablesBucketsQueryVariables,
  ): Promise<void> => {
    await queryClient.invalidateQueries(getQueryKey(companyId, variables));
  };
};

type Bucket = 'withProof' | 'missingProof' | 'withoutProof';

const decrementTotalForBucket = (
  bucket: Bucket | undefined,
  data: PayablesBucketsQueryRawResult,
  value: number,
) => {
  switch (bucket) {
    case 'withProof':
      if (data.company.payablesWithProof) {
        data.company.payablesWithProof.totalCount -= value;
      }
      break;
    case 'missingProof':
      if (data.company.payablesMissingProof) {
        data.company.payablesMissingProof.totalCount -= value;
      }
      break;
    case 'withoutProof':
      if (data.company.payablesWithoutProof) {
        data.company.payablesWithoutProof.totalCount -= value;
      }
      break;
    default:
  }

  if (data.company.payablesFiltered) {
    data.company.payablesFiltered.totalCount -= value;
  }
};

const incrementTotalForBucket = (
  bucket: Bucket | undefined,
  data: PayablesBucketsQueryRawResult,
  value: number,
) => {
  switch (bucket) {
    case 'withProof':
      if (data.company.payablesWithProof) {
        data.company.payablesWithProof.totalCount += value;
      }
      break;
    case 'missingProof':
      if (data.company.payablesMissingProof) {
        data.company.payablesMissingProof.totalCount += value;
      }
      break;
    case 'withoutProof':
      if (data.company.payablesWithoutProof) {
        data.company.payablesWithoutProof.totalCount += value;
      }
      break;
    default:
  }
  if (data.company.payablesFiltered) {
    data.company.payablesFiltered.totalCount += value;
  }
};

export const useUpdatePayablesBucketsStatsQueryCache = () => {
  const queryClient = useQueryClient();

  return (
    companyId: string,
    update?: {
      action:
        | 'DECREMENT_PAYABLES_TO_PREPARE_COUNT'
        | 'INCREMENT_PAYABLES_TO_PREPARE_COUNT';
      value: number;
      bucket?: Bucket;
    },
  ) => {
    if (!update) {
      return;
    }

    const statsQueries = queryClient
      .getQueryCache()
      .findAll({ queryKey: getQueryKey(companyId) });

    for (const query of statsQueries) {
      query.setData(
        (
          data: PayablesBucketsQueryRawResult | undefined,
        ): PayablesBucketsQueryRawResult | undefined => {
          if (!data) {
            return;
          }

          if (update.action === 'DECREMENT_PAYABLES_TO_PREPARE_COUNT') {
            decrementTotalForBucket(
              update.bucket,
              data,
              Math.max(0, update.value),
            );
          }

          if (update.action === 'INCREMENT_PAYABLES_TO_PREPARE_COUNT') {
            incrementTotalForBucket(update.bucket, data, update.value);
          }

          return data;
        },
      );
    }
  };
};

/**
 * GraphQL query hook
 */

export const usePayablesBucketsStatsQuery = (
  useDefaultFilters = false,
): QueryState<PayablesBucketsQueryResult> => {
  const { state: contextFilters } = useContext(PreparePayablesFiltersContext);
  const companyId = useCompanyId();

  const filters = useDefaultFilters
    ? { sort: defaultSortFilter, additional: [] }
    : contextFilters;

  const variables = {
    filters: toAPIPayableFilters({
      filters,
      withBookkeepingStartDate: true,
    }),
    withProofFilters: toAPIPayableFilters({
      filters,
      withBookkeepingStartDate: true,
      bucketType: 'withProof',
    }),
    missingProofFilters: toAPIPayableFilters({
      filters,
      withBookkeepingStartDate: true,
      bucketType: 'missingProof',
    }),
    withoutProofFilters: toAPIPayableFilters({
      filters,
      withBookkeepingStartDate: true,
      bucketType: 'withoutProof',
    }),
  };

  return useQuery<PayablesBucketsQueryResult, PayablesBucketsQueryRawResult>({
    key: getQueryKey(companyId, variables),
    request: {
      type: 'graphQL',
      target: 'v2',
      query: makeGetPayablesBucketsStatsQuery(variables),
      variables,
    },
    reshapeData: (data) => {
      if (!data.company) {
        return { outcome: 'noPayables' };
      }
      const bucketsStats = reshapePayablesBucketsStatsResultData(data);
      if (hasNoPayables(bucketsStats)) {
        return { outcome: 'noPayables' };
      }
      if (hasNoUnpreparedPayables(bucketsStats, filters)) {
        return { outcome: 'noUnpreparedPayables' };
      }
      if (hasNoMatchingUnpreparedPayables(bucketsStats)) {
        return { outcome: 'noMatchingUnpreparedPayables' };
      }
      return { outcome: 'ok', bucketsStats };
    },
  });
};

/**
 * Helpers
 */

const hasNoPayables = (bucketsStats: BucketsStats): boolean =>
  bucketsStats.expenses.totalCount === 0;

const hasNoUnpreparedPayables = (
  bucketsStats: BucketsStats,
  filters: Filters,
): boolean =>
  hasNoPayables(bucketsStats) ||
  (bucketsStats.expensesFiltered.totalCount === 0 &&
    ![
      filters.group !== null,
      filters.period?.startDate !== null,
      filters.period?.endDate !== null,
      filters.search !== null,
      filters.additional.length > 0,
    ].some(Boolean));

const hasNoMatchingUnpreparedPayables = (bucketsStats: BucketsStats): boolean =>
  bucketsStats.expensesFiltered.totalCount === 0 ||
  (bucketsStats.expensesWithProof.totalCount === 0 &&
    bucketsStats.expensesWithoutProof.totalCount === 0 &&
    bucketsStats.expensesMissingProof.totalCount === 0);
