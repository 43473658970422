import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { getExportPayablesInconsistencyMonitorResultQueryKey } from './query-key-registry';

export type PayableInconsistentResult = {
  reason: 'payableAmountNotMatchingValidatedRequest';
  details: {
    expected: {
      total: {
        count: number;
        billedAmount: number;
      };
    };
    received: {
      total: {
        count: number;
        billedAmount: number;
      };
    };
  };
};

export type SettlementInconsistentResult = {
  reason: 'settlementAmountNotMatchingPayment';
  details: {
    expected: {
      total: {
        billedAmount: number;
      };
    };
    received: {
      total: {
        billedAmount: number;
      };
    };
  };
};

export type InconsistencyResult =
  | PayableInconsistentResult
  | SettlementInconsistentResult;

export type ExportPayablesInconsistencyMonitorResult =
  | { status: 'valid' }
  | {
      status: 'invalid';
      result: {
        result: InconsistencyResult[];
      };
    };

export function useExportPayablesInconsistencyMonitorResult(): QueryState<ExportPayablesInconsistencyMonitorResult> {
  const companyId = useCompanyId();

  return useQuery<ExportPayablesInconsistencyMonitorResult>({
    key: getExportPayablesInconsistencyMonitorResultQueryKey(companyId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/bk-inconsistency-monitor-result',
    },
    reshapeData(data) {
      return data;
    },
  });
}
