import { type ParsedQuery } from 'query-string';

const isValueNotEmpty = (
  value:
    | string
    | string[]
    | {
        [key: string]: string | string[];
      }
    | null
    | undefined,
) => {
  if (!value) {
    return false;
  }
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length > 0;
  }

  return true;
};

/**
 * Formats raw filters from the URL to API-understandable filters
 *
 * @param  {Object} filters={}  The raw filters to clean / format
 * @return {Object}             API-ready request search params
 */
export const convertFiltersFromUrl = (filters: ParsedQuery<string> = {}) => {
  const formattedfilters = Object.fromEntries(
    Object.entries({ ...filters, group: filters.team || null }).filter(
      ([key]) => !['team'].includes(key),
    ),
  );
  return Object.fromEntries(
    Object.entries(formattedfilters).filter(([_, value]) =>
      isValueNotEmpty(value),
    ),
  );
};
