import { isAfter, isBefore, isToday } from 'date-fns';
import { type MonetaryValue, add } from 'ezmoney';

import {
  type Budget,
  type RawBudget,
  reshapeRawBudget,
  getBudgetAmount,
} from './budget';

export type BudgetaryExercise = {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  periodicity: 'monthly' | 'quarterly' | 'yearly';
  companyId: string;
  budgets: Budget[];
};

export type RawBudgetaryExercise = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  periodicity: 'monthly' | 'quarterly' | 'yearly';
  companyId: string;
  budgets: RawBudget[];
};

export const reshapeRawBudgetaryExercise = (
  rawBudgetaryExercise: RawBudgetaryExercise,
): BudgetaryExercise => {
  return {
    id: rawBudgetaryExercise.id,
    name: rawBudgetaryExercise.name,
    startDate: new Date(rawBudgetaryExercise.startDate),
    endDate: new Date(rawBudgetaryExercise.endDate),
    periodicity: rawBudgetaryExercise.periodicity,
    companyId: rawBudgetaryExercise.companyId,
    budgets: rawBudgetaryExercise.budgets.map(reshapeRawBudget),
  };
};

export const getBudgetaryExerciseAmount = (
  budgetaryExercise: BudgetaryExercise,
): MonetaryValue | undefined => {
  return budgetaryExercise.budgets.reduce<MonetaryValue | undefined>(
    (sum, budget) => {
      const budgetAmount = getBudgetAmount(budget);
      return sum && budgetAmount ? add(sum, budgetAmount) : budgetAmount;
    },
    undefined,
  );
};

export const getCurrentOrDefaultBudgetaryExercise = (
  budgetaryExercises: BudgetaryExercise[],
): BudgetaryExercise | undefined => {
  const activeBudgetaryExercise = budgetaryExercises.find(
    ({ startDate, endDate }) =>
      isTodayOrAfter(startDate) && isTodayOrBefore(endDate),
  );

  return activeBudgetaryExercise ?? budgetaryExercises[0];
};

export const isActiveBudgetaryExercise = ({
  startDate,
  endDate,
}: BudgetaryExercise): boolean =>
  isTodayOrAfter(startDate) && isTodayOrBefore(endDate);

export const isFutureBudgetaryExercise = ({
  startDate,
}: BudgetaryExercise): boolean => isAfterToday(startDate);

export const isPastBudgetaryExercise = ({
  endDate,
}: BudgetaryExercise): boolean => isBeforeToday(endDate);

const isTodayOrBefore = (date: Date) =>
  isToday(date) || isBefore(new Date(), date);

const isTodayOrAfter = (date: Date) =>
  isToday(date) || isAfter(new Date(), date);

const isAfterToday = (date: Date) => isAfter(date, new Date());
const isBeforeToday = (date: Date) => isBefore(date, new Date());
