import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type SupplierAccountDefaultFor } from '../../settings/accounting';
import { GET_INITIAL_SUPPLIER_ACCOUNTS } from '../queries/getSupplierAccounts';
import { type SupplierAccount } from '../types';

type RawData = {
  company: {
    chartOfAccounts: {
      supplierAccounts: {
        edges: {
          node: {
            id: string;
            generalAccountCode: string;
            auxiliaryAccountCode: string | undefined;
            isDefault: boolean;
            isArchived: boolean;
            defaultFor: SupplierAccountDefaultFor | undefined;
          };
        }[];
      };
    };
  };
};

export const useGetSupplierAccountsQuery = (options?: {
  includeArchived: boolean;
  first?: number;
  search?: string;
}): QueryState<SupplierAccount[]> => {
  const { company: companyId } = useParams();

  return useQuery<SupplierAccount[], RawData>({
    key: ['accountsPayable', 'useGetSupplierAccountsQuery', companyId, options],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_INITIAL_SUPPLIER_ACCOUNTS,
      variables: {
        first: options?.first,
        filter: {
          ...(options?.search && {
            search: options.search,
          }),
          includeArchived:
            options?.includeArchived !== undefined
              ? options?.includeArchived
              : true,
        },
      },
    },
    reshapeData: (rawData) => {
      const { edges } = rawData.company.chartOfAccounts.supplierAccounts;
      return edges.map(({ node }) => ({
        id: node.id,
        generalAccountCode: node.generalAccountCode,
        auxiliaryAccountCode: node.auxiliaryAccountCode ?? undefined,
        isDefault: node.isDefault,
        isArchived: node.isArchived,
        defaultFor: node.defaultFor,
      }));
    },
  });
};
