import { SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { IntegrationBoxSkeleton } from '../IntegrationBox';

type Props = {
  integrations: {
    error?: unknown | undefined;
  }[];
  extra?: JSX.Element[];
  activeIntegrations: boolean;
};
export const IntegrationsSectionSkeleton = (props: Props) => {
  const { integrations, extra, activeIntegrations } = props;
  return integrations.length > 0 ? (
    <div className="grid grid-cols-2 gap-m">
      <SkeletonText size="xl" width="350px" className="col-span-2" />
      {integrations.map((_, index) => (
        <IntegrationBoxSkeleton
          key={`integration_${index.toString()}`}
          active={activeIntegrations}
        />
      ))}
      {extra ? extra.map((child) => child) : null}
    </div>
  ) : null;
};
