import { type ExportPayablesCountResponse } from '../../apis';
import { type PayableExportRequest } from '../../models';

export const getTotalPayablesToBeExported = (
  payableExportRequest: Pick<
    PayableExportRequest,
    'includeExportedPayables' | 'includePayablesFromPreviousPeriod'
  >,
  {
    unexportedPayablesCount,
    exportedPayablesCount,
    previousPeriodUnexportedPayablesCount,
  }: ExportPayablesCountResponse['payablesCounts'],
) => {
  return (
    unexportedPayablesCount +
    +(payableExportRequest.includeExportedPayables
      ? exportedPayablesCount
      : 0) +
    (payableExportRequest.includePayablesFromPreviousPeriod
      ? previousPeriodUnexportedPayablesCount
      : 0)
  );
};

export const getIsExportingOnlyAlreadyExportedPayables = (
  payableExportRequest: PayableExportRequest,
  {
    unexportedPayablesCount,
    exportedPayablesCount,
  }: ExportPayablesCountResponse['payablesCounts'],
) =>
  unexportedPayablesCount === 0 &&
  exportedPayablesCount > 0 &&
  payableExportRequest.includeExportedPayables &&
  !payableExportRequest.includePayablesFromPreviousPeriod;
