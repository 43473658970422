import { Autocomplete, type AutocompleteProps } from '@dev-spendesk/grapes';
import React, { useEffect, type ReactChild } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type Option } from './option';
import { useCustomFieldValuesSearch } from './useCustomFieldValuesSearch';

type Props = Omit<
  AutocompleteProps<Option>,
  'options' | 'onSearch' | 'renderNoOptions' | 'onAddOption' | 'renderAddOption'
> & {
  disableSelectUndefined?: boolean;
  customField: {
    id: string;
    type: string;
    total_values?: number;
  };
} & (
    | {
        renderAddOption(rawValue: string): ReactChild; // should always return a DropdownItem component
        onAddOption(newOptionLabel: string): Option | Promise<Option>;
        renderNoOptions?(rawValue: string): ReactChild;
      }
    | {
        renderNoOptions(rawValue: string): ReactChild;
        renderAddOption?: never;
        onAddOption?: never;
      }
  );

export const CustomFieldAsyncAutocomplete = ({
  customField,
  placeholder,
  disableSelectUndefined = true,
  ...rest
}: Props) => {
  const { t } = useTranslation('global');

  const [customFieldValues, searchCustomFieldValues] =
    useCustomFieldValuesSearch(customField.id);

  useEffect(() => {
    if (customFieldValues.length === 0) {
      searchCustomFieldValues('');
    }
  }, [customFieldValues]);

  return (
    <Autocomplete
      options={
        disableSelectUndefined
          ? customFieldValues
          : [
              ...customFieldValues,
              { key: '', label: t('customFields.notProvided') },
            ]
      }
      placeholder={placeholder}
      onSearch={async (query) => {
        await searchCustomFieldValues(query ?? '');
      }}
      {...rest}
    />
  );
};
