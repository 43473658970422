import type { FormikErrors } from 'formik';

import { PayableErrors } from '@finance-review/models/payable';

import type { Errors } from './draftInvoiceRequestValidation';
import type * as DraftPaymentSchedule from './draftPaymentSchedule';

export * from '@finance-review/models/payable/payableErrors';

export const supplierNotValidatedError = 'notValidated' as const;
export type SupplierNotValidatedError = typeof supplierNotValidatedError;

export const nonPositiveAmountError = 'nonPositiveAmount' as const;
export type NonPositiveAmountError = typeof nonPositiveAmountError;

export const paymentReferenceMoreThanMaxLengthError =
  'moreThanMaxLength' as const;
export type PaymentReferenceMoreThanMaxLengthError =
  typeof paymentReferenceMoreThanMaxLengthError;

export const paymentReferenceInvalidFormatError = 'invalidFormat' as const;
export type PaymentReferenceInvalidFormatError =
  typeof paymentReferenceInvalidFormatError;

export const paymentScheduleNotMatchingAmountError =
  'notMatchingAmount' as const;
export type PaymentScheduleNotMatchingAmountError =
  typeof paymentScheduleNotMatchingAmountError;

export const dueDateBeforeEmissionDateError =
  'dueDateBeforeEmissionDateError' as const;
export type DueDateBeforeEmissionDateError =
  typeof dueDateBeforeEmissionDateError;

export const emissionDateInTheFuture = 'emissionDateInTheFutureError' as const;
export type EmissionDateInTheFuture = typeof emissionDateInTheFuture;

export const parseSupplierError = (
  error?: string,
): PayableErrors.RequiredError | SupplierNotValidatedError | undefined =>
  error === supplierNotValidatedError
    ? supplierNotValidatedError
    : PayableErrors.parseToRequiredError(error);

export const parseAmountError = (
  error?: string,
): PayableErrors.RequiredError | NonPositiveAmountError | undefined => {
  if (error) {
    return error === nonPositiveAmountError
      ? nonPositiveAmountError
      : PayableErrors.requiredError;
  }
  return undefined;
};

export const parsePaymentReferenceError = (
  error?: string,
): Errors['paymentReference'] =>
  PayableErrors.parseFromFormikError<Errors['paymentReference']>(
    [
      paymentReferenceMoreThanMaxLengthError,
      paymentReferenceInvalidFormatError,
    ],
    error,
    paymentReferenceMoreThanMaxLengthError,
  );

export const parsePaymentScheduleError = (
  error?: FormikErrors<DraftPaymentSchedule.Entity>,
): Errors['paymentSchedule'] => {
  if (!error) {
    return undefined;
  }

  if (Array.isArray(error)) {
    return error.map((scheduledPaymentError) => ({
      date: PayableErrors.parseToRequiredError(scheduledPaymentError?.date),
      amount: parseAmountError(scheduledPaymentError?.amount),
    }));
  }

  return paymentScheduleNotMatchingAmountError;
};
