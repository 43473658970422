import React from 'react';

import { EmptyStateError, lazyLoad } from 'src/core/common/components/LazyLoad';

import { MapSkeleton } from './MapSkeleton';

export const LazyLoadedMap = lazyLoad({
  loader: async () => ({
    // eslint-disable-next-line unicorn/no-await-expression-member
    default: (await import(/* webpackChunkName: "Map" */ './Map')).Map,
  }),
  loading: <MapSkeleton />,
  fallback: (props) => <EmptyStateError {...props} />,
});
