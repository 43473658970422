import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';

type StateType<TValue> = {
  value: TValue;
  status: 'idle' | 'debouncing';
};
/**
 *
 * @param initialValue The type of initialValue defines the type of the returned value
 * @param timeout delays changing the state until after `timeout` milliseconds
 * @returns [state, setState, { isDebouncing }]
 */
export const useDebouncedState = <TValue>(
  initialValue: TValue,
  timeout: number,
): [TValue, (value: TValue) => void, { isDebouncing: boolean }] => {
  const [state, setState] = useState<StateType<TValue>>({
    value: initialValue,
    status: 'idle',
  });

  const setStateDebounced = useMemo(() => {
    const debouncedFunction = debounce((value) => {
      setState({ value, status: 'idle' });
    }, timeout);

    return (value: TValue) => {
      setState((currentState) => ({ ...currentState, status: 'debouncing' }));
      debouncedFunction(value);
    };
  }, []);

  return [
    state.value,
    setStateDebounced,
    { isDebouncing: state.status === 'debouncing' },
  ];
};
