import {
  Button,
  Callout,
  Modal,
  OptionGroup,
  RadioGroup,
  RadioField,
  DATE_FORMAT,
  TextInput,
  FormField,
} from '@dev-spendesk/grapes';
import { differenceInMonths } from 'date-fns';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type BudgetaryExerciseInput } from '../../containers/EditBudgetaryExerciseModalContainer/budgetaryExerciseInput';
import { type BudgetaryExercise } from '../../models/budgetaryExercise';

type Props = {
  budgetaryExercise: BudgetaryExercise;
  onHide: () => void;
};

export const EditBudgetaryExerciseModal = ({
  budgetaryExercise,
  onHide,
  // formikProps
  values,
  errors,
  setFieldValue,
  handleSubmit,
}: Props & FormikProps<BudgetaryExerciseInput>) => {
  const { localeFormat, t } = useTranslation('global');

  return (
    <Modal
      isOpen
      title={t('budget.editBEModal.title')}
      actions={[
        <Button
          key="1"
          variant="secondary"
          text={t('misc.cancel')}
          onClick={onHide}
        />,
        <Button
          key="2"
          variant="primary"
          text={t('budget.editBEModal.createButton')}
          onClick={() => {
            handleSubmit();
          }}
        />,
      ]}
      iconName="money-bag"
      iconVariant="primary"
    >
      <Callout
        className="mb-s text-left"
        variant="warning"
        title={t('budget.editBEModal.calloutTitle')}
      >
        {t('budget.editBEModal.calloutContent')}
      </Callout>
      <div className="flex flex-col items-stretch gap-m text-left">
        <FormField
          label={t('budget.editBEModal.nameLabel')}
          alertMessage={errors.name}
        >
          <TextInput
            onChange={(event) => {
              setFieldValue('name', event.target.value);
            }}
            value={values.name}
            placeholder={t('budget.editBEModal.namePlaceholder')}
          />
        </FormField>
        <FormField label={t('budget.editBEModal.dateLabel')}>
          <RadioGroup
            name="date"
            className="mb-xs"
            onChange={() => {
              // readonly
            }}
            value={
              differenceInMonths(
                budgetaryExercise.startDate,
                budgetaryExercise.endDate,
              ) < 12
                ? 'custom'
                : 'fullYear'
            }
          >
            <RadioField
              isDisabled
              value="fullYear"
              label={t('budget.editBEModal.dateFullYear')}
            />
            <RadioField
              isDisabled
              value="custom"
              label={t('budget.editBEModal.dateCustom')}
            />
          </RadioGroup>
          <div className="h-[36px] rounded bg-neutral-lightest px-s py-xs text-neutral-darker body-m">
            {t('budget.editBEModal.fullYearFromTo', {
              from: localeFormat(
                budgetaryExercise.startDate,
                DATE_FORMAT.SHORT,
              ),
              to: localeFormat(budgetaryExercise.endDate, DATE_FORMAT.SHORT),
            })}
          </div>
        </FormField>
        <FormField label={t('budget.editBEModal.periodicityLabel')}>
          <OptionGroup
            isDisabled
            name="periodicityOptionGroup"
            options={[
              { value: 'monthly', label: t('budget.editBEModal.monthly') },
              { value: 'yearly', label: t('budget.editBEModal.global') },
            ]}
            value={budgetaryExercise.periodicity}
          />
        </FormField>
      </div>
    </Modal>
  );
};
