/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DatePicker,
  type DatePickerProps,
  FormField,
} from '@dev-spendesk/grapes';
import { formatISO, isValid } from 'date-fns';
import React from 'react';

import { removeTimezone } from 'src/core/modules/requests/utils/removeTimezone';

import type { BasicInputProps, Form } from './types';
import { useErrorMessage } from './utils/useErrorMessage';

type Props<TForm extends Form<any>> = BasicInputProps<TForm, string> &
  Omit<DatePickerProps, 'onChange' | 'value'> & {
    shouldRemoveTimezone?: boolean;
    hint?: React.ReactNode;
  };

export function FormDatePicker<TForm>({
  name,
  form,
  label,
  hint,
  shouldRemoveTimezone,
  ...props
}: Props<TForm extends Form<any> ? TForm : never>) {
  const error = useErrorMessage({
    form,
    key: name,
  });

  const getValueAsDate = (date?: string) => {
    if (!date) {
      return;
    }

    const jsDate = new Date(date);
    if (!isValid(jsDate)) {
      return;
    }

    return shouldRemoveTimezone ? removeTimezone(jsDate) : jsDate;
  };

  return (
    <FormField label={label} hint={hint} alertMessage={error}>
      <DatePicker
        onChange={(date) => {
          if (date) {
            form.setFieldValue(name, formatISO(date).split('T')[0]);
          }
        }}
        value={getValueAsDate(form.values[name])}
        fit="parent"
        {...props}
      />
    </FormField>
  );
}
