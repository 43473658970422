import { useContext } from 'react';

import { useCachedSinglePayablesStatus } from './useCachedSinglePayablesStatus';
import { usePayablesBucketResolver } from './usePayablesBucketResolver';
import { usePayablesGroupResolver } from './usePayablesGroupResolver';
import { PreparePayablesSelectionContext } from '../contexts';
import {
  type ConnectionSelector,
  type PayableConnectionSelector,
  type ConnectionId,
  toAPIPayableConnectionSelectors,
  bulkUpdateProcessesStore,
  type BucketType,
  type GroupId,
} from '../models';

export const usePayablesConnectionSelectors = ():
  | ConnectionSelector[]
  | PayableConnectionSelector[] => {
  const { state: selection } = useContext(PreparePayablesSelectionContext);
  const getPayablesBucket = usePayablesBucketResolver();
  const getPayablesGroup = usePayablesGroupResolver();
  const getCachedSinglePayablesStatus = useCachedSinglePayablesStatus();

  return toAPIPayableConnectionSelectors({
    selection,
    resolvers: {
      getPayablesBucketConnectionId: (bucketId: BucketType): ConnectionId => {
        const bucket = getPayablesBucket(bucketId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (bucket as any).connectionId;
      },
      getPayablesGroupConnectionId: (
        bucketId: BucketType,
        groupId: GroupId,
      ): ConnectionId | undefined => {
        const group = getPayablesGroup(bucketId, groupId);
        return group?.connectionId;
      },
    },
    cachedSinglePayablesStatus: getCachedSinglePayablesStatus(),
    bulkProcessesIds: bulkUpdateProcessesStore.read(),
  });
};
