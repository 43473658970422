import { useParams } from 'react-router-dom';

import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { type ExpenseAccount } from '../../accounting';

interface PaginatedExpenseAccounts {
  pageInfo: { endCursor?: string };
  expenseAccounts: [{ account: ExpenseAccount; cursor: string }];
}

export const useExpenseAccountsQuery = (
  search: string | undefined,
): InfiniteQueryState<ExpenseAccount[]> => {
  const { company: companyId } = useParams();
  return useInfiniteQuery<ExpenseAccount, PaginatedExpenseAccounts>({
    key: ['getExpenseAccounts', companyId, search],
    getRequest(cursor) {
      return {
        type: 'rest',
        target: 'companyAPI',
        endpoint: '/accounting-integration/chart-of-accounts/expense-accounts',
        params: {
          first: !cursor ? 30 : 500,
          after: cursor,
          search: search ?? '',
          includeDefault: false,
        },
      };
    },
    getNextPageParam(data) {
      return data.pageInfo.endCursor;
    },
    reshapeData(paginatedExpenseAccounts) {
      return paginatedExpenseAccounts.expenseAccounts.map(({ account }) => {
        return {
          ...account,
        };
      });
    },
  });
};
