import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type TeamRaw, type TeamUserRaw } from 'modules/members/models/teams';

type Props = {
  group: TeamRaw;
  users: TeamUserRaw[];
  onDelete: (groupId: string) => void;
  onCancel: () => void;
};

export const TeamDeleteModal = ({
  group,
  users,
  onDelete,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  const hasMembersOnlyBelongingToThisTeam = () => {
    const groupUsers = group.users.map((groupUser) =>
      users.find((user) => user.id === groupUser.id),
    );

    return groupUsers.some((groupUser) => {
      const companyData = groupUser?.data_by_company?.[companyId];
      const userGroups = companyData?.groups_ids;
      return userGroups?.length === 1;
    });
  };

  return (
    <Modal
      isOpen
      title={t('members.deleteTeamConfirm', {
        teamName: group.name,
      })}
      subtitle={t('members.aboutToDeleteTheTeam', { teamName: group.name })}
      iconName="warning"
      iconVariant="alert"
      onClose={onCancel}
      actions={[
        <Button
          key="cancel"
          variant="secondary"
          text={t('misc.cancel')}
          onClick={onCancel}
        />,
        <Button
          key="delete"
          variant="alert"
          text={t('members.deleteTeam')}
          onClick={async () => {
            await onDelete(group.id);
          }}
        />,
      ]}
    >
      {hasMembersOnlyBelongingToThisTeam() && (
        <Callout variant="warning" title={t('members.someMembersReassigned')} />
      )}
    </Modal>
  );
};
