import { Callout, PanelSection } from '@dev-spendesk/grapes';
import React from 'react';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';

import { TransferTimeline } from './TransferTimeline';
import { type TransferDetails } from '../../../models';

type Props = {
  transfer: TransferDetails;
};

// the typing of the timeline is not 100% reliable, so we add an ErrorBoundary
export const TimelineSection = ({ transfer }: Props) => {
  const { t } = useTranslation('global');

  return (
    <PanelSection
      isCollapsible
      title={<span>{t('invoices.transfer.panel.timeline.title')}</span>}
    >
      <ErrorBoundary
        context={{
          team: 'finance-operations',
          scope: 'invoices-timeline',
        }}
        fallbackComponent={
          <Callout
            title={t('invoices.transfer.panel.timeline.error')}
            variant="warning"
          />
        }
      >
        <TransferTimeline transfer={transfer} />
      </ErrorBoundary>
    </PanelSection>
  );
};
