import get from 'lodash/get';

import { type MarqetaAchSources } from './types';

export const MOBILE_PIN_LENGTH = 6;
export const emptyPin = Array(MOBILE_PIN_LENGTH).fill('');

export const isFundingAmountValid = (amount: string): boolean => {
  const parsedNumber = Math.floor(Number(amount) * 100);

  return Boolean(parsedNumber > 0 && Number.isSafeInteger(parsedNumber));
};

export const getAchSourceInformations = (achSources: MarqetaAchSources) => {
  const activeAch = achSources.data.find((achSource) => achSource.active);

  // TODO: in the future we should consider multi-token app
  if (activeAch) {
    return {
      achSourceToken: activeAch.token,
      achSourceAccountSuffix: activeAch.accountSuffix,
      hasPendingAch: false,
      hasValidatedAch: true,
      pendingAchCreatedAt: null,
    };
  }

  const pendingAch = achSources.data.find(
    (achSource) =>
      !achSource.active &&
      achSource.verificationStatus === 'VERIFICATION_PENDING',
  );

  // TODO: in the future we should consider multi-token app
  if (pendingAch) {
    return {
      achSourceToken: pendingAch.token,
      achSourceAccountSuffix: pendingAch.accountSuffix || null,
      hasPendingAch: true,
      hasValidatedAch: false,
      pendingAchCreatedAt: new Date(pendingAch.createdTime),
    };
  }

  // it shouldn't be possible at this point to not have an achSource
  return {
    achSourceToken: '',
    achSourceAccountSuffix: null,
    hasPendingAch: false,
    hasValidatedAch: false,
    pendingAchCreatedAt: null,
  };
};

export const transformError = (error: Error): string =>
  get(error, 'response.data.reason', 'unknown');
