import {
  Button,
  DropdownMenu,
  DropdownItem,
  Modal,
  FormField,
  Tag,
  Callout,
  Avatar,
  CheckboxField,
} from '@dev-spendesk/grapes';
import { stringify } from 'qs';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
  hasExternalConnection,
  isIntegrationsPlanEnabled,
} from 'modules/bookkeep/integration/status';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import appConfig from 'src/core/config';
import FEATURES from 'src/core/constants/features';

import { AccountingBaseToggle } from './AccountingBaseInput';
import { getAccountingIntegrationDetails } from '../../../../integrations/helper';

import './AccountingBaseSelect.css';

// TODO: we should be able to fetch all available integrations with
// their capabilities, which would allow us to only select the
// integrations with the switch capability for the dropdown.
const getAccountingIntegrationOptions = (
  optionList: AccountingSoftware[],
  currentOption: AccountingSoftware | 'noIntegration',
  featureFlags: {
    netsuite: boolean;
  },
): AccountingSoftware[] => {
  const filteredOptionList = optionList.filter(
    (option: AccountingSoftware) =>
      option !== currentOption &&
      isIntegrationsPlanEnabled({
        integrationName: option,
        featureFlags,
      }),
  );

  // If the current integration is native, we only allow switching to a filebased one
  if (hasExternalConnection(currentOption)) {
    return filteredOptionList.filter((option: AccountingSoftware) =>
      hasIntegrationFileBasedExport(option),
    );
  }

  return filteredOptionList;
};

export function AccountingBaseSelectModal({
  accountingIntegration,
  initialAccountingIntegration,
  availableAccountingIntegrations,
  isOpen,
  onClose,
  onConfirm,
  onSelect,
}: {
  accountingIntegration: AccountingSoftware | 'noIntegration';
  initialAccountingIntegration: AccountingSoftware | 'noIntegration';
  availableAccountingIntegrations: AccountingSoftware[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onSelect: (accountingSoftware: AccountingSoftware) => void;
}) {
  const { t } = useTranslation();
  const companyId = useCompanyId();

  const isNetsuiteEnterpriseEnabled = useFeature(
    FEATURES.NETSUITE_ACCOUNTING_INTEGRATION,
  );
  const isNetsuiteBetaEnabled = useFeature(FEATURES.TMP_NETSUITE_BETA);

  const featureFlags = {
    netsuite: isNetsuiteEnterpriseEnabled || isNetsuiteBetaEnabled,
  };

  const hadNoIntegration = initialAccountingIntegration === 'noIntegration';
  const options = getAccountingIntegrationOptions(
    availableAccountingIntegrations,
    initialAccountingIntegration,
    featureFlags,
  );

  const integrationRequiresAcknowledgement = ['Sage100'].includes(
    accountingIntegration,
  );

  const [nextButtonEnabled, setNextButtonEnabled] = useState(
    !integrationRequiresAcknowledgement,
  );

  useEffect(() => {
    setNextButtonEnabled(integrationRequiresAcknowledgement);
  }, [integrationRequiresAcknowledgement]);

  const modalActions = [
    <Button
      key="close"
      text={t('misc.cancel')}
      variant="secondary"
      onClick={() => {
        onClose();
      }}
    />,
    <Button
      key="next"
      text={t('bookkeep.integrations.settings.baseSelect.continue')}
      variant="primary"
      onClick={() => {
        return onConfirm();
      }}
      iconName={hadNoIntegration ? 'caret-right' : undefined}
      iconPosition={hadNoIntegration ? 'right' : undefined}
      isDisabled={nextButtonEnabled}
    />,
  ];

  const renderOption = (
    {
      key: availableIntegration,
      label,
    }: { key: AccountingSoftware; label: string },
    index: number,
  ) => (
    <DropdownItem
      key={index}
      isSelected={availableIntegration === accountingIntegration}
      prefix={
        <Avatar
          text={availableIntegration}
          src={getAccountingIntegrationDetails(availableIntegration).logoPath}
          fallbackSrc={fallbackSupplierLogoSrc}
          size="xs"
          variant="square"
        />
      }
      label={
        <>
          <span>{label}</span>{' '}
          <span className="AccountingBaseSelect__integrationType body-m">
            {t(
              getAccountingIntegrationDetails(availableIntegration)
                .i18nSelectType,
            )}
          </span>
          {getAccountingIntegrationDetails(availableIntegration).isBeta && (
            <span>
              {' '}
              <Tag variant="primary">{t('bookkeep.integrations.openBeta')}</Tag>
            </span>
          )}
        </>
      }
      helpText={t(
        getAccountingIntegrationDetails(availableIntegration)
          .i18nSelectDescription,
        { integration: availableIntegration },
      )}
    />
  );

  const fileBasedPreview = (
    <div className="AccountingBaseSelect__preview">
      <Button
        component="a"
        href={`${
          appConfig.apiUrls.api
        }/${companyId}/accounting-export-templates/file-preview?${stringify({
          accountingIntegration,
        })}`}
        target="_blank"
        variant="ghost"
        iconName="download"
        iconPosition="left"
        text={t('bookkeep.integrations.settings.baseSelect.preview')}
      />
    </div>
  );

  const sage100InfoCallout = (
    <Callout
      title={t('bookkeep.integrations.settings.chiftInfoCallout.title')}
      iconName="socket"
      variant="neutral"
    >
      <div className="IntegrationInfoCallout__content">
        <Trans
          i18nKey="bookkeep.integrations.settings.chiftInfoCallout.content"
          values={{ integration: accountingIntegration }}
        >
          <a
            // TODO: this link should be updated to the correct one
            href="https://helpcenter.spendesk.com/collections/9677966-sage100-sur-compta-2-0"
            target="_blank"
            rel="noopener noreferrer"
          >
            this article
          </a>
        </Trans>
      </div>
    </Callout>
  );

  const chiftWarningSection = (
    <>
      <Callout
        title={t('bookkeep.integrations.settings.chiftWarningCallout.title')}
        iconName="warning"
        variant="warning"
      >
        <div className="IntegrationInfoCallout__warning">
          {t('bookkeep.integrations.settings.chiftWarningCallout.content', {
            integration: accountingIntegration,
          })}
        </div>
      </Callout>

      <CheckboxField
        className="mt-s hover:bg-[transparent]"
        isChecked={!nextButtonEnabled}
        label={
          <Trans
            i18nKey="bookkeep.integrations.settings.chiftAcknowledgeTechnicalSupportNeeded"
            values={{ integration: accountingIntegration }}
          />
        }
        onChange={() => setNextButtonEnabled(!nextButtonEnabled)}
      />
    </>
  );

  const netsuiteInfoCallout = (
    <Callout
      title={t('bookkeep.integrations.settings.netsuiteInfoCallout.title')}
      iconName="socket"
      variant="neutral"
    >
      <div className="IntegrationInfoCallout__content">
        <Trans i18nKey="bookkeep.integrations.settings.netsuiteInfoCallout.content">
          <a
            href="https://helpcenter.spendesk.com/articles/6899573-netsuite-native-integration-contents"
            target="_blank"
            rel="noopener noreferrer"
          >
            this article
          </a>
        </Trans>
      </div>
    </Callout>
  );

  return (
    <Modal
      isOpen={isOpen}
      iconName="settings"
      iconVariant="primary"
      title={t(
        hadNoIntegration
          ? 'bookkeep.integrations.settings.baseSelect.headerNoIntegration'
          : 'bookkeep.integrations.settings.baseSelect.header',
      )}
      subtitle={t('bookkeep.integrations.settings.baseSelect.description')}
      actions={modalActions}
    >
      <div
        className="AccountingBaseSelect__form"
        data-testid="accounting-base-select-form"
      >
        <DropdownMenu
          options={options.map((key) => ({
            key,
            label: t(getAccountingIntegrationDetails(key).i18nSelectTitle),
          }))}
          className="AccountingBaseSelect__dropdown"
          placement="bottom-start"
          dropdownContentMaxHeight="350px"
          renderButton={(getToggleButtonProps) => (
            <FormField
              label={t('bookkeep.integrations.settings.baseSelect.selectLabel')}
            >
              {/* @ts-expect-error: No helpful comment */}
              <div {...getToggleButtonProps()}>
                <AccountingBaseToggle value={accountingIntegration} />
              </div>
            </FormField>
          )}
          renderOption={renderOption}
          onSelect={(option) => {
            onSelect(option.key);
          }}
        />
        {hasIntegrationFileBasedExport(accountingIntegration) &&
          fileBasedPreview}
        {accountingIntegration === 'Netsuite' && netsuiteInfoCallout}
        {integrationRequiresAcknowledgement && (
          <>
            <div className="mb-s">{sage100InfoCallout}</div>
            <div>{chiftWarningSection}</div>
          </>
        )}
      </div>
    </Modal>
  );
}
