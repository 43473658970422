import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type DownloadAccountStatementResponse = {
  outcome: string;
};

export const useDownloadAccountStatementQuery = (
  query: string | undefined,
): QueryState<DownloadAccountStatementResponse> => {
  return useQuery<DownloadAccountStatementResponse>({
    key: `downloadAccountStatement::${query}`,
    isEnabled: Boolean(query),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/accounts/statements/${query}`,
    },
    reshapeData: (data) => {
      return {
        outcome: data.outcome,
      };
    },
  });
};
