import {
  type RawExpense,
  type Requester,
} from '@finance-review/models/expense';

export const transformRawUserToRequester = (
  rawExpense: RawExpense,
): Requester => {
  const { user, employeeAccountCode } = rawExpense;
  // TODO@youldali: remove the try / catch once issue is resolved
  try {
    return {
      id: user?.id ?? rawExpense.user_id,
      fullname: user?.fullname ?? '',
      avatar: user?.avatar ?? undefined,
      firstName: user?.first_name ?? '',
      lastName: user?.last_name ?? '',
      bankInfo: user?.bankInfo,
      employeeAccountCode: employeeAccountCode ?? undefined,
      lang: user?.lang ?? 'en',
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'Error when transforming the user, most likely the id is missing',
    );
    // eslint-disable-next-line no-console
    console.log('rawExpense', rawExpense);
    // eslint-disable-next-line no-console
    console.trace();
    throw error;
  }
};
