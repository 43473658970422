import { Icon } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  link: string;
  title?: string;
  numberOfItems?: number;
  translationKey?: string;
  onClick?: () => void;
};

export const WelcomePanelButton = ({
  link,
  title,
  translationKey,
  numberOfItems,
  onClick = () => {},
}: Props) => {
  return (
    <Link
      to={link}
      onClick={onClick}
      className="min-w-[296px] rounded-xxs border border-solid border-neutral-light bg-white p-xs text-complementary body-m hover:bg-neutral-light"
    >
      <div className="flex items-center gap-s capitalize">
        <span>
          {title || (
            <Trans
              i18nKey={translationKey}
              count={numberOfItems}
              values={{ count: numberOfItems }}
              components={[<span className="title-m" key="link-highlight" />]}
            />
          )}
        </span>
        <Icon name="caret-right" size="m" className="ml-auto" />
      </div>
    </Link>
  );
};
