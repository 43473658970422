import { apiUrl } from 'src/core/utils/api';

type CreationPayload = {
  name: string;
  ownerId: string;
  viewerIds: string[];
};

export const createCostCenter = async (
  payload: CreationPayload,
  companyId: string,
): Promise<{ id: string }> => {
  const response = await fetch(apiUrl('/cost-centers', companyId), {
    method: 'POST',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error('Impossible to create a cost center');
  }
  return response.json();
};
