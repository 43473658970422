import { useState } from 'react';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { companyAPI } from 'src/core/api/axios';

import { type Option } from './option';

export const useCustomFieldValuesSearch = (
  customFieldId: string,
): [Option[], (query: string) => Promise<void>] => {
  const companyId = useCompanyId();
  const [customFieldValues, setCustomFieldValues] = useState<Option[]>([]);

  const searchCustomFieldValues = async (query: string): Promise<void> => {
    let results: { id: string; value: string }[] = [];
    try {
      const { data } = await companyAPI.get<{
        custom_fields_values: { id: string; value: string }[];
      }>(
        `/custom-fields/${customFieldId}/values?search=${encodeURIComponent(
          query,
        )}`,
        {
          companyId,
        },
      );
      results = data.custom_fields_values;
    } catch {
      return;
    }

    const reshapedValues = results.map((result) => ({
      key: result.id,
      label: result.value,
    }));
    setCustomFieldValues(reshapedValues);
  };

  return [customFieldValues, searchCustomFieldValues];
};
