export const FETCH_LAST_TERMS_AND_CONDITIONS_SUCCESS =
  'FETCH_LAST_TERMS_AND_CONDITIONS_SUCCESS';

export const SIGN_TERMS_AND_CONDITIONS_LOADING =
  'SIGN_TERMS_AND_CONDITIONS_LOADING';
export const SIGN_TERMS_AND_CONDITIONS_FAILURE =
  'SIGN_TERMS_AND_CONDITIONS_FAILURE';
export const SIGN_TERMS_AND_CONDITIONS_SUCCESS =
  'SIGN_TERMS_AND_CONDITIONS_SUCCESS';

export const FETCH_TIPS_FILTERS_FAILURE = 'FETCH_TIPS_FILTERS_FAILURE';
export const FETCH_TIPS_FILTERS_SUCCESS = 'FETCH_TIPS_FILTERS_SUCCESS';
