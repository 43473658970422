import { gql } from 'graphql-tag';

export const getGetAccountPayableSuppliersListQuery = ({
  hasDefaultFor,
}: {
  hasDefaultFor: boolean;
}) => {
  return gql`
    query GetAccountPayableSuppliersList(
      $companyId: ID!
      $after: ID
      $filter: AccountPayableFilter!
      $supplierFilter: SupplierFilter
    ) {
      company(id: $companyId) {
        id
        chartOfAccounts {
          supplierAccounts(first: 20, after: $after, filter: $filter) {
            totalCount
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                id
                generalAccountCode
                auxiliaryAccountCode
                isDefault
                ${hasDefaultFor ? 'defaultFor' : ''}
                isArchived
                payablesAggregate {
                  sum {
                    functionalAmount
                  }
                }
                suppliers(filter: $supplierFilter) {
                  totalCount
                  edges {
                    cursor
                    node {
                      id
                      name
                      thumbnailUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};
