import {
  SkeletonAvatar,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

type InvoiceReviewListSkeletonProps = {
  numberOfRows?: number;
};

export const InvoiceReviewListSkeleton = ({
  numberOfRows = 5,
}: InvoiceReviewListSkeletonProps) => (
  <div className="overflow-auto">
    <SkeletonText size="xl" className="mb-s" width="33%" />
    <SkeletonTable
      numberOfRows={numberOfRows}
      columns={[
        {
          cell: (
            <div
              className="grid w-full items-center gap-s"
              style={{
                gridTemplateColumns: 'min-content 2fr 64px',
              }}
            >
              <SkeletonAvatar variant="square" />
              <div
                className="grid items-center gap-xs"
                style={{ gridTemplateRows: 'repeat(2, min-content)' }}
              >
                <SkeletonText width="50%" size="l" />
                <SkeletonText width="40%" size="s" />
              </div>
              <div className="grid items-center gap-xs">
                <SkeletonText size="s" />
                <SkeletonText size="s" />
              </div>
            </div>
          ),
          header: <SkeletonText width="30%" size="m" />,
        },
      ]}
      withHeader
    />
  </div>
);
