import { useQueryClient } from 'react-query';

import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useDeletePolicy = (id: string): MutationState => {
  const queryClient = useQueryClient();

  return useMutation({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/policies/${id}`,
      method: 'delete',
    },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        queryClient.invalidateQueries(['policies']);
      },
      throwOnError: true,
    },
  });
};
