import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { getBookkeepingSettingsQueryKey } from './query-key-registry';

import { type SetBookkeepingSettingsApi } from '.';

/**
 * Query and cache config
 */

export type BookkeepingLockAfterUnit = 'day' | 'week' | 'month' | 'quarter';

export type BookkeepingSettingsResponse = {
  bookkeepingStartDate: Date | null;
  editPayablesAfterExport: {
    hasOptedIn: boolean;
    wasOptedIn: boolean;
    lockAfter?: number | null;
    lockAfterUnit?: BookkeepingLockAfterUnit | null;
  };
};

export const useUpdateBookkeepingSettingsQueryCache = () => {
  const companyId = useCompanyId();

  const queryClient = useQueryClient();

  return async (payload: SetBookkeepingSettingsApi.Payload): Promise<void> => {
    const queryKey = getBookkeepingSettingsQueryKey(companyId);

    const previousData =
      queryClient.getQueryData<BookkeepingSettingsResponse>(queryKey);

    if (previousData) {
      queryClient.setQueryData<BookkeepingSettingsResponse>(queryKey, {
        ...previousData,
        ...payload,
        editPayablesAfterExport: {
          ...previousData.editPayablesAfterExport,
          ...payload.editPayablesAfterExport,
          wasOptedIn: !!(
            previousData.editPayablesAfterExport.hasOptedIn === true &&
            payload.editPayablesAfterExport?.hasOptedIn === false
          ),
        },
      });
    }
  };
};

export const useInvalidateBookkeepingSettingsQueryCache = () => {
  const queryClient = useQueryClient();

  return async (companyId: string): Promise<void> => {
    await queryClient.invalidateQueries(
      getBookkeepingSettingsQueryKey(companyId),
    );
  };
};

/**
 * REST query hook
 */

export const useBookkeepingSettings = ({
  isEnabled = true,
}: { isEnabled?: boolean } = {}): QueryState<BookkeepingSettingsResponse> => {
  const companyId = useCompanyId();

  return useQuery<BookkeepingSettingsResponse, BookkeepingSettingsResponse>({
    isEnabled,
    key: getBookkeepingSettingsQueryKey(companyId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/bookkeeping/settings',
    },
  });
};
