import { Icon, type IconName } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import styles from './TopNotif.module.css';

type Props = {
  type: 'warning' | 'danger' | 'success' | 'pending';
  children: React.ReactNode;
};
export const TopNotif = ({ children, type }: Props) => {
  let iconName: IconName;

  switch (type) {
    case 'success': {
      iconName = 'success';
      break;
    }
    case 'danger':
    case 'warning': {
      iconName = 'warning';
      break;
    }
    case 'pending': {
      iconName = 'clock-full';
      break;
    }
    default: {
      rejectUnexpectedValue('TopNotif type', type);
    }
  }

  return (
    <div className={cx(styles.topNotif, styles[type])}>
      <div className="mr-xs">
        <Icon className={styles.icon} name={iconName} size="m" />
      </div>
      <div>{children}</div>
    </div>
  );
};
