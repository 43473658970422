/* eslint-disable unicorn/no-useless-switch-case */
import { Button, EmptyState } from '@dev-spendesk/grapes';
import React, { type MouseEventHandler } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import type { Language } from 'src/core/config/i18n';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import './CostCentersActivationPage.css';

type Props = {
  isDisabled: boolean;
  onCreateClick: MouseEventHandler<HTMLElement>;
};

export const CostCentersActivationPage = ({
  isDisabled,
  onCreateClick,
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');

  return (
    <div className="page__container">
      <div className="flex-1 overflow-y-auto bg-page-background">
        <EmptyState
          illustration={
            <video
              muted
              loop
              autoPlay
              className="CostCentersActivationPage__video"
            >
              <source
                src={getVideoFromLanguage(activeLanguage)}
                type="video/mp4"
              />
            </video>
          }
          title={t('costCenters.activation.title')}
          subtitle={t('costCenters.activation.description')}
          actions={[
            <Button
              key="1"
              variant="primary"
              text={t('costCenters.activation.button')}
              isDisabled={isDisabled}
              onClick={(event) => {
                track(
                  AnalyticEventName.COST_CENTERS_MIGRATION_DISCOVER_BUTTON_CLICKED,
                );
                onCreateClick(event);
              }}
            />,
          ]}
        />
      </div>
    </div>
  );
};

const getVideoFromLanguage = (language: Language) => {
  switch (language) {
    case 'fr':
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/cost-centers/onboarding-placeholder-fr.mp4';
    case 'en':
    case 'de':
    default:
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/cost-centers/onboarding-placeholder-en.mp4';
  }
};
