import { type MonetaryValue } from 'ezmoney';

import { type Duplicate } from '@finance-review/models/invoice';

type MatchingData =
  | 'checksum'
  | 'supplierId'
  | 'invoiceNumber'
  | 'date'
  | 'amount';

export type RawDuplicate = {
  matchingData: MatchingData[];
  matchingEntity: {
    id: string;
    owner: {
      id: string;
      name: string;
    };
    supplier: {
      id: string;
      name: string;
      logoUrl: string;
    };
    amount: MonetaryValue;
    description: string;
    date: string | Date;
    reimbursementPaymentId?: string | null;
  } & (
    | {
        type:
          | 'expenseClaimRequest'
          | 'singlePurchaseRequest'
          | 'subscriptionRequest'
          | 'payment';
      }
    | {
        type: 'invoiceRequest';
        invoiceNumber: string;
        status:
          | 'to-approve'
          | 'to-review'
          | 'to-pay'
          | 'partially-paid'
          | 'paid';
      }
  );
};

export const transformDuplicates = (
  rawDuplicates: RawDuplicate[],
): Duplicate[] => rawDuplicates.map(transformDuplicate).filter(isDuplicate);

export const transformDuplicate = (
  rawDuplicate: RawDuplicate,
): Duplicate | undefined => {
  const { matchingData: rawMatchingDate, matchingEntity: rawMatchingEntity } =
    rawDuplicate;

  return rawMatchingEntity.type === 'invoiceRequest'
    ? {
        matchingData: rawMatchingDate,
        matchingEntity: {
          ...rawMatchingEntity,
          type: 'invoiceRequest',
          supplier: {
            id: rawMatchingEntity.supplier.id,
            name: rawMatchingEntity.supplier.name,
            thumbnailUrl: rawMatchingEntity.supplier.logoUrl,
          },
          date: new Date(rawMatchingEntity.date),
          reimbursementPaymentId:
            rawMatchingEntity.reimbursementPaymentId ?? undefined,
        },
      }
    : undefined;
};

const isDuplicate = (
  duplicate: Duplicate | undefined,
): duplicate is Duplicate => Boolean(duplicate);
