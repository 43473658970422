import { createReducer } from '@reduxjs/toolkit';

import * as TYPES from './actionTypes';

const initialState = {
  isVisible: false,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(TYPES.SHOW_SWITCHED_SUPERVISED_USER_MODAL, (state) => {
    state.isVisible = true;
  });
  builder.addCase(TYPES.HIDE_SWITCHED_SUPERVISED_USER_MODAL, (state) => {
    state.isVisible = false;
  });
});
