import { gql } from 'graphql-tag';

export const GET_INITIAL_SUPPLIER_ACCOUNTS = gql`
  query GetInitialSupplierAccounts(
    $companyId: ID!
    $filter: AccountPayableFilter!
    $first: Int
  ) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        supplierAccounts(first: $first, filter: $filter) {
          edges {
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
              isArchived
              defaultFor
            }
          }
        }
      }
    }
  }
`;
