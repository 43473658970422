import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  hasAccess: boolean;
};

export const useUpdateCardsAccessMutation = (
  memberId: string,
): MutationState<Payload> => {
  const params = { userId: memberId };

  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/cards/access',
      method: 'patch',
      params,
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }): void => {
        client.invalidateQueries(['cards', 'access']);
      },
    },
  });
};
