import { Button, DatePicker, FormField } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

type Props = {
  date: Date;
  errorDate?: string;
  onDateChange(date: Date): void;
  onChangeMode(): void;
};

export const AllocationPeriodFieldDate = ({
  date,
  errorDate,
  onDateChange,
  onChangeMode,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <>
      <div>
        <FormField
          label={t('budget.allocationPeriod.fieldLabel')}
          alertMessage={errorDate}
        >
          <DatePicker
            value={date}
            onChange={(newDate) => newDate && onDateChange(newDate)}
            fit="parent"
          />
        </FormField>
      </div>
      <Button
        type="button"
        variant="ghost"
        onClick={onChangeMode}
        text={t('budget.allocationPeriod.defineExtended')}
        className="mt-xs"
      />
    </>
  );
};
