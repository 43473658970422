import { IconButton } from '@dev-spendesk/grapes';
import React from 'react';

import { useModal } from 'common/hooks/useModalGrapes';
import { useTranslation } from 'common/hooks/useTranslation';
import { useHasAccessToKycStateRelatedFeatures } from 'modules/kyc/hooks/useHasAccessToKycStateRelatedFeatures';

import { ConfirmModal } from './ConfirmModal';
import { type OperationalOnboardingData } from '../../../../../models';
import { getNumberOfRemainingTasks } from '../../progress';

type Props = {
  operationalOnboarding?: OperationalOnboardingData;
};

export const OperationalOnboardingHeader = ({
  operationalOnboarding,
}: Props) => {
  const { t } = useTranslation('global');

  const [confirmModal, showConfirmModal] = useModal(({ isOpen, onClose }) => {
    return <ConfirmModal isOpen={isOpen} onClose={onClose} />;
  });
  const hasAccessToKycStateRelatedFeatures =
    useHasAccessToKycStateRelatedFeatures();

  const tasks = operationalOnboarding?.tasks || [];

  const nbRemainingTasks = getNumberOfRemainingTasks(tasks);
  const nbTotalTasks = tasks.length;

  const taskCount = t('homepage.welcomeDashboard.taskCount', {
    remainingCount: nbTotalTasks - nbRemainingTasks,
    totalCount: nbTotalTasks,
  });

  return (
    <>
      <div className="flex justify-between p-m">
        <div>
          <h2 className="text-complementary title-xl">
            {t('homepage.welcomeDashboard.title')}
          </h2>

          <div className="mt-xs whitespace-nowrap">
            <p className="text-neutral-dark body-l">{taskCount}</p>
          </div>
        </div>

        {hasAccessToKycStateRelatedFeatures && (
          <>
            <IconButton
              aria-label="close"
              iconName="cross"
              variant="borderless"
              onClick={() => showConfirmModal()}
            />
            {confirmModal}
          </>
        )}
      </div>
    </>
  );
};
