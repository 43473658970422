import {
  Button,
  DropdownMenu,
  IconButton,
  DropdownItem,
  Tag,
  GridContainer,
  GridItem,
  Banner,
  DATE_FORMAT,
} from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { GrapesSectionHeader } from 'common/components/GrapesSectionHeader';
import { CenteredLayout } from 'common/components/layout';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { routes, routeFor } from 'src/core/constants/routes';

import Illustration from './binoclar.svg';
import { BudgetExpenseCategoriesBreakdownContainer } from '../../containers/BudgetExpenseCategoriesBreakdownContainer';
import { BudgetOverviewContainer } from '../../containers/BudgetOverviewContainer';
import { dateRangeToPeriodRange } from '../../models/breakdown';
import type { Budget } from '../../models/budget';
import {
  type BudgetaryExercise,
  isFutureBudgetaryExercise,
} from '../../models/budgetaryExercise';
import { type PeriodRange } from '../../models/period';
import { BudgetPeriodSelector } from '../BudgetPeriodSelector';
import { ExportBudgetButton } from '../ExportBudgetButton';

type Props = {
  hasExpenseCategories: boolean;
  budget: Budget;
  budgetaryExercise: BudgetaryExercise;
  onDeleteBudget(): void;
};

export const BudgetOverviewPage = ({
  hasExpenseCategories,
  budget,
  budgetaryExercise,
  onDeleteBudget,
}: Props) => {
  const { localeFormat, t } = useTranslation('global');
  const history = useHistory();
  const location = useLocation();
  const companyId = useCompanyId();

  const hasBackButton = location.state?.hasBackButton;
  const { isAccountOwner, isController } = useUserRoles();

  const [selectedPeriodRange, setSelectedPeriodRange] = useState<PeriodRange>(
    location.state?.selectedPeriodRange ?? {
      key: 'currentMonth',
    },
  );

  const readableStartDate = localeFormat(
    budgetaryExercise.startDate,
    DATE_FORMAT.SHORT,
  );
  const readableEndDate = localeFormat(
    budgetaryExercise.endDate,
    DATE_FORMAT.SHORT,
  );
  const isControllerOrAccountOwner = isController || isAccountOwner;
  const canDeleteBudget =
    budget.isUnavailable || isFutureBudgetaryExercise(budgetaryExercise);

  return (
    <div className="page__container">
      <CenteredLayout>
        <GridContainer className="pb-[40px]">
          <GridItem columnSpan={12} columnStartLine={0}>
            {hasBackButton && (
              <Button
                type="button"
                variant="ghost"
                iconName="caret-left"
                hasNegativeMargins
                text={t('budget.overviewPage.back')}
                className="left-xs mt-xl"
                onClick={() => {
                  const pageRoute = routeFor(routes.BUDGETARY_EXERCISES.path, {
                    id: budgetaryExercise.id,
                    company: companyId,
                  });
                  history.push({
                    pathname: pageRoute,
                    state: { selectedPeriodRange },
                  });
                }}
              />
            )}
            <GrapesSectionHeader
              className="mt-xl"
              title={t('budget.overviewPage.title', { name: budget.name })}
              subtitle={
                <Tag variant="neutral">{`${readableStartDate} - ${readableEndDate}`}</Tag>
              }
              leftContent={
                <div className="flex items-center gap-s">
                  {budgetaryExercise.periodicity !== 'yearly' && (
                    <BudgetPeriodSelector
                      range={selectedPeriodRange}
                      maxRange={{
                        from: budgetaryExercise.startDate,
                        to: budgetaryExercise.endDate,
                      }}
                      onChange={(range: PeriodRange) => {
                        setSelectedPeriodRange(range);
                      }}
                    />
                  )}
                  <ExportBudgetButton budgetIds={[budget.id]} />
                  {isControllerOrAccountOwner && canDeleteBudget && (
                    <DropdownMenu
                      options={[
                        ...(canDeleteBudget
                          ? [
                              {
                                key: 'deleteBudget',
                                label: t('budget.overviewPage.delete'),
                              },
                            ]
                          : []),
                      ]}
                      placement="bottom-end"
                      className="BudgetOverviewPage__actions-dropdown"
                      renderOption={(option) => {
                        return <DropdownItem label={option.label} />;
                      }}
                      renderButton={(getToggleButtonProps) => {
                        return (
                          <IconButton
                            {...getToggleButtonProps()}
                            iconName="dots"
                            variant="border"
                            aria-label={t('misc.openMenu')}
                          />
                        );
                      }}
                      onSelect={(option) => {
                        if (option.key === 'deleteBudget') {
                          onDeleteBudget();
                        }
                      }}
                    />
                  )}
                </div>
              }
            />
            <section className="mb-m">
              <BudgetOverviewContainer
                budget={budget}
                periodRange={dateRangeToPeriodRange(
                  budgetaryExercise.periodicity === 'yearly'
                    ? { key: 'overall' }
                    : selectedPeriodRange,
                )}
              />
            </section>
            {hasExpenseCategories ? null : (
              <Banner
                actionText={t(
                  'budget.budgetaryExercise.budgetExpenseCategoriesOverviewCallout.button',
                )}
                title={t(
                  'budget.budgetaryExercise.budgetExpenseCategoriesOverviewCallout.title',
                )}
                onClick={() => {
                  history.push(
                    routeFor(routes.COMPANY_CATEGORIES.path, {
                      company: companyId,
                    }),
                  );
                }}
                illustration={
                  <img
                    width="67"
                    height="67"
                    alt=""
                    src={Illustration}
                    className="flex-shrink-0"
                  />
                }
                className="mb-m"
              >
                {t(
                  'budget.budgetaryExercise.budgetExpenseCategoriesOverviewCallout.text',
                )}
              </Banner>
            )}
            <BudgetExpenseCategoriesBreakdownContainer
              budgetId={budget.id}
              periodRange={dateRangeToPeriodRange(
                budgetaryExercise.periodicity === 'yearly'
                  ? { key: 'overall' }
                  : selectedPeriodRange,
              )}
            />
          </GridItem>
        </GridContainer>
      </CenteredLayout>
    </div>
  );
};
