import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped as TFunctionTyped } from "src/core/common/hooks/useTranslation";

import type { SpendingType } from './spendingTypes';

export type ApprovalPolicyParamsFormValues = {
  approvalNeeded: 'always' | 'sometimes' | 'never';
  spendingTypes: SpendingType[];
  amountPerTransaction: number | undefined;
  amountPerMonth: number | undefined;
};

export type ApprovalPolicyFormValues = {
  name: string | null;
  membersIds: string[];
} & ApprovalPolicyParamsFormValues;

export const validateApprovalPolicyParamsFormValues = (
  values: ApprovalPolicyParamsFormValues,
  translator: TFunctionTyped,
): FormikErrors<ApprovalPolicyParamsFormValues> => {
  const errors: FormikErrors<ApprovalPolicyParamsFormValues> = {};

  if (values.approvalNeeded === 'sometimes') {
    if (!values.amountPerMonth) {
      errors.amountPerMonth = translator(
        'approvalPolicy.errors.amountPerMonth',
      );
    }
    if (!values.amountPerTransaction) {
      errors.amountPerTransaction = translator(
        'approvalPolicy.errors.amountPerTransaction',
      );
    }
    if (
      values.amountPerMonth &&
      values.amountPerTransaction &&
      values.amountPerMonth < values.amountPerTransaction
    ) {
      // this error is common to "amountPerMonth" and "amountPerTransaction"
      errors.amountPerMonth = translator(
        'approvalPolicy.errors.inconsistentAmounts',
      );
    }
    if (values.spendingTypes.length === 0) {
      errors.spendingTypes = translator('approvalPolicy.errors.spendingTypes');
    }
  }

  return errors;
};

export const validateApprovalPolicyFormValues = (
  values: ApprovalPolicyFormValues,
  step: 'define' | 'members',
  translator: TFunctionTyped,
): FormikErrors<ApprovalPolicyFormValues> => {
  const errors: FormikErrors<ApprovalPolicyFormValues> =
    validateApprovalPolicyParamsFormValues(values, translator);

  if (step === 'define' && (!values.name || values.name.trim() === '')) {
    errors.name = translator('approvalPolicy.errors.emptyName');
  }
  if (step === 'members' && values.membersIds.length === 0) {
    errors.membersIds = translator('approvalPolicy.errors.membersIds');
  }

  return errors;
};
