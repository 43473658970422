import { SwitchField } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { type WireTransferActivationStatus } from '../../../../models';
import { WireTransferFeatureToggleError } from '../WireTransferFeatureToggleError';

type WireTransferProps = {
  className?: string;
  wireTransferActivationStatus: WireTransferActivationStatus;
  onWireTransferActivation(): void;
};
export const ForExpenses = ({
  className,
  wireTransferActivationStatus,
  onWireTransferActivation,
}: WireTransferProps) => {
  const { t } = useTranslation('global');

  const { isActivated, toggle } = wireTransferActivationStatus;

  return (
    <div className={classNames('text-left', className)}>
      <SwitchField
        id="wireTransferForExpenses"
        fit="parent"
        isChecked={isActivated}
        label={t('payments.settings.wireTransfer.title')}
        isDisabled={!toggle.canToggle}
        helpText={t('payments.settings.wireTransfer.description')}
        onChange={onWireTransferActivation}
      />
      {!toggle.canToggle && (
        <WireTransferFeatureToggleError
          reason={toggle.reason}
          wireTransferPayableEntity="expenses"
        />
      )}
    </div>
  );
};
