import isEqual from 'lodash/isEqual';

import { useCreateTeamMutation } from '../../../hooks/useCreateTeamMutation';
import { useDeleteTeamMutation } from '../../../hooks/useDeleteTeamMutation';

export const useEditTeamMutation = (memberId: string) => {
  const [createTeam] = useCreateTeamMutation();
  const [deleteTeam] = useDeleteTeamMutation(memberId);
  return [
    (oldTeamIds: string[], newTeamIds: string[]) => {
      if (isEqual(oldTeamIds, newTeamIds)) {
        return;
      }

      // Add new teams
      const newTeamsPromises = newTeamIds.map((teamId) => {
        if (oldTeamIds.includes(teamId)) {
          return;
        }
        return createTeam({
          user_id: memberId,
          endpointParams: {
            groupId: teamId,
          },
        });
      });

      // Remove old teams
      const oldTeamsPromises = oldTeamIds.map((teamId) => {
        if (newTeamIds.includes(teamId)) {
          return;
        }
        return deleteTeam({
          endpointParams: {
            groupId: teamId,
          },
        });
      });
      return Promise.all([...newTeamsPromises, ...oldTeamsPromises]);
    },
  ];
};
