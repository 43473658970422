import { Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { ContactUs } from "src/core/common/components/ContactUs";
import { useTranslation } from "src/core/common/hooks/useTranslation";

type Props = {
  handleClose: () => void;
  countryName: string;
};

export const CannotCreateBranchModal = ({
  handleClose,
  countryName,
}: Props) => {
  const { t } = useTranslation('global');
  return (
    <Modal
      isOpen
      onClose={handleClose}
      title={t('branchCreationUnavailable.title')}
      subtitle={t('branchCreationUnavailable.description', {
        countryName,
      })}
      iconName="warning"
      iconVariant="warning"
      actions={[
        <ContactUs
          key="contactUs"
          buttonVariant="secondary"
          text={t('helpCenter.contactUs')}
        />,
      ]}
    >
      {t('branchCreationUnavailable.contactUs')}
    </Modal>
  );
};
