import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { NotificationType, type PushNotif } from 'modules/app/notifications';

import { expenseFieldsToText } from '../components/PreparePayableView/helpers';

export class HasBeenModifiedError extends Error {
  constructor(message = 'This expense has been modified.') {
    super(message);
  }
}

export class AlreadyPreparedError extends Error {
  constructor(message = 'This expense has already been marked as prepared.') {
    super(message);
  }
}

export class NotFoundError extends Error {
  constructor(message = 'This expense could not be found.') {
    super(message);
  }
}

class NotAllowedError extends Error {
  constructor(message = 'The user is not allowed to perform this action.') {
    super(message);
  }
}

export class MissingFieldsError extends Error {
  fields: string[];

  constructor(fields: string[]) {
    super('Some mandatory fields do not have a value.');
    this.fields = fields;
  }
}

export class UpdateInProgressError extends Error {
  constructor(
    message = 'The payable is already being updated, please reload the page and retry in a minute',
  ) {
    super(message);
  }
}

export class ItemLineWithZeroVatAndNetAmountError extends Error {
  constructor(
    message = 'This payable can’t be marked as ready because expense account’s line has 0 as amount. Please edit the amount or remove the line.',
  ) {
    super(message);
  }
}

export class InvalidItemLinesError extends Error {
  constructor(
    message = 'This payable can’t be marked as ready because a technical error occurred. Please contact the support.',
  ) {
    super(message);
  }
}

export class NothingToUpdateError extends Error {
  constructor(
    message = 'This payable can’t be marked as ready because a technical error occurred. Please contact the support.',
  ) {
    super(message);
  }
}

export class NonUniqueItemLinesError extends Error {
  constructor(
    message = 'This payable can’t be marked as ready because two or more lines use both the same expense and vat account. Please edit it.',
  ) {
    super(message);
  }
}

export class UnmatchingVatRateError extends Error {
  constructor(
    message = 'This payable can’t be marked as ready because the amount of the exempt account line is not equal at 0. Please edit the amount.',
  ) {
    super(message);
  }
}

export class PayableNotReconciledCorrectlyError extends Error {
  constructor(
    message = 'This payable can’t be marked as ready because a technical error occurred. Please contact the support.',
  ) {
    super(message);
  }
}

export class IncorrectVatError extends Error {
  constructor(
    message = 'This payable has an invalid VAT account. Please select another VAT account.',
  ) {
    super(message);
  }
}

export class InvalidExpenseAccountsError extends Error {
  constructor(message = 'Please select a valid expense account.') {
    super(message);
  }
}

export class InvalidTaxAccountsError extends Error {
  constructor(message = 'Please select a valid VAT account.') {
    super(message);
  }
}

export class MissingExpenseAccountError extends Error {
  constructor(message = 'Please select an expense account.') {
    super(message);
  }
}

export class MissingAccountingDate extends Error {
  constructor(message = 'Please select an accounting date.') {
    super(message);
  }
}

export class MissingInvoiceNumber extends Error {
  constructor(message = 'Please select an invoice number.') {
    super(message);
  }
}

export class MissingSupplierCountry extends Error {
  constructor(message = 'Please select a supplier country.') {
    super(message);
  }
}

export class MissingSupplierVatNumber extends Error {
  constructor(message = 'Please select a supplier VAT number.') {
    super(message);
  }
}

export class ExpenseAccountDeletedError extends Error {
  constructor(message = 'Please select an expense account not deleted.') {
    super(message);
  }
}

export class TaxAccountDeletedError extends Error {
  constructor(message = 'Please select a tax account not deleted.') {
    super(message);
  }
}

export class InvalidAccountPayableError extends Error {
  constructor(
    message = 'The account payable has been deleted. Please assign another account.',
  ) {
    super(message);
  }
}

export class MissingAccountPayableError extends Error {
  constructor(message = 'Please select an account payable.') {
    super(message);
  }
}

export class MissingRequiredCustomFieldError extends Error {
  constructor(message = 'Some required custom fields are missing.') {
    super(message);
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function displayNotificationForFormErrors(
  error: Error,
  pushNotif: PushNotif,
  t: TGlobalFunctionTyped,
): void {
  let message;
  if (error instanceof MissingFieldsError) {
    message = t('expenseInbox.errors.missingFieldsError', {
      count: error.fields.length,
      fields: expenseFieldsToText(error.fields, t),
    });
  } else if (error instanceof HasBeenModifiedError) {
    message = t('expenseInbox.errors.hasBeenModifiedError');
  } else if (error instanceof AlreadyPreparedError) {
    message = t('expenseInbox.errors.alreadyPreparedError');
  } else if (error instanceof NotFoundError) {
    message = t('expenseInbox.errors.notFoundError');
  } else if (error instanceof NotAllowedError) {
    message = t('expenseInbox.errors.notAllowedError');
  } else if (error instanceof ItemLineWithZeroVatAndNetAmountError) {
    message = t(
      'bookkeep.prepare.bulkValidationErrors.itemLineWithZeroVatAndNetAmount',
    );
  } else if (error instanceof InvalidItemLinesError) {
    message = t('bookkeep.prepare.bulkValidationErrors.invalidItemLines');
  } else if (error instanceof NothingToUpdateError) {
    message = t('bookkeep.prepare.bulkValidationErrors.nothingToUpdate');
  } else if (error instanceof NonUniqueItemLinesError) {
    message = t('bookkeep.prepare.bulkValidationErrors.nonUniqueItemLines');
  } else if (error instanceof UnmatchingVatRateError) {
    message = t('bookkeep.prepare.bulkValidationErrors.unmatchingVatRate');
  } else if (error instanceof PayableNotReconciledCorrectlyError) {
    message = t(
      'bookkeep.prepare.bulkValidationErrors.payableNotReconciledCorrectly',
    );
  } else if (error instanceof IncorrectVatError) {
    message = t('bookkeep.prepare.bulkValidationErrors.incorrectVat');
  } else if (error instanceof InvalidExpenseAccountsError) {
    message = t('bookkeep.prepare.bulkValidationErrors.invalidExpenseAccounts');
  } else if (error instanceof InvalidTaxAccountsError) {
    message = t('bookkeep.prepare.bulkValidationErrors.invalidTaxAccounts');
  } else if (error instanceof MissingExpenseAccountError) {
    message = t('bookkeep.prepare.bulkValidationErrors.missingExpenseAccount');
  } else if (error instanceof ExpenseAccountDeletedError) {
    message = t('bookkeep.prepare.bulkValidationErrors.expenseAccountDeleted');
  } else if (error instanceof TaxAccountDeletedError) {
    message = t('bookkeep.prepare.bulkValidationErrors.taxAccountDeleted');
  } else if (error instanceof InvalidAccountPayableError) {
    message = t('bookkeep.prepare.bulkValidationErrors.invalidAccountPayable');
  } else if (error instanceof MissingAccountPayableError) {
    message = t('bookkeep.prepare.bulkValidationErrors.missingAccountPayable');
  } else if (error instanceof MissingRequiredCustomFieldError) {
    message = t(
      'bookkeep.prepare.bulkValidationErrors.missingRequiredCustomField',
    );
  } else if (error instanceof MissingAccountingDate) {
    message = t('bookkeep.prepare.bulkValidationErrors.missingAccountingDate');
  } else if (error instanceof MissingInvoiceNumber) {
    message = t('bookkeep.prepare.bulkValidationErrors.missingInvoiceNumber');
  } else if (error instanceof MissingSupplierVatNumber) {
    message = t(
      'bookkeep.prepare.bulkValidationErrors.missingSupplierVatNumber',
    );
  } else if (error instanceof MissingSupplierCountry) {
    message = t('bookkeep.prepare.bulkValidationErrors.missingSupplierCountry');
  } else {
    message = t('misc.somethingWrong');
  }
  pushNotif({
    type: NotificationType.Danger,
    message,
  });
}
