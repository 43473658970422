import styles from './VideoBlock.module.css';
import placeholderSource from './assets/placeholder.png';

type Props = {
  title: string;
  description: string;
};

export const VideoBlock = ({ title, description }: Props) => {
  return (
    <div className="flex max-w-[428px] flex-col items-center">
      <img className={styles.placeholder} src={placeholderSource} alt={title} />
      <p className="mb-m text-center title-xl">{title}</p>
      <p className="text-left text-neutral-dark body-m">{description}</p>
    </div>
  );
};
