import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  restrict_domain?: string;
};

export const useUpdateInviteLinkMutation = (): MutationState<Payload> => {
  const companyId = useCompanyId();

  return useMutation<Payload>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: '/invite-links',
    },
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['inviteLink', companyId]);
      },
    },
  });
};
