import { useHistory } from 'react-router-dom';

import { routeBuilder } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';

type Params = {
  paymentId?: string;
};

export const useGoToSchedulePayment = () => {
  const getRoute = useSchedulePaymentRoute();
  const history = useHistory();

  return (params: Params) => {
    const route = getRoute(params);
    history.push(route);
  };
};

export const useSchedulePaymentRoute = () => {
  const companyId = useCompanyId();
  return (params: Params): string => getSchedulePaymentRoute(companyId, params);
};

const getSchedulePaymentRoute = (
  companyId: string,
  { paymentId }: Params,
): string => {
  if (paymentId) {
    return routeBuilder('INVOICES_PAY', {
      company: companyId,
      paymentToScheduleId: paymentId,
    });
  }

  return routeBuilder('INVOICES_PAY', {
    company: companyId,
  });
};
