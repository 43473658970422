import { ValidatePayableApi } from '@finance-review/apis/payable';
import { type Expense } from '@finance-review/models/expense';
import { type MutationState } from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';

import { useInvalidateExpenses } from './useExpensesQuery';
import { useFetchLegacyExpenseClaimCounts } from './useFetchLegacyExpenseClaimCounts';

export type Response = ValidatePayableApi.Response;
export type QueryState = ValidatePayableApi.QueryState;
export type Payload = Expense;

export const useMutation = (
  options: {
    onError?(queryError?: QueryError<unknown>): void;
    onSuccess?(requestId: string): void;
  } = {},
): MutationState<Payload, Response, unknown> => {
  const legacyFetchCount = useFetchLegacyExpenseClaimCounts();
  const invalidateExpenses = useInvalidateExpenses();
  const [validatePayableRequestMutation, ...rest] =
    ValidatePayableApi.useMutation({
      onSuccess: (requestId) => {
        legacyFetchCount();
        invalidateExpenses();
        options.onSuccess?.(requestId);
      },
      onError: options.onError,
    });

  return [
    async (expense) =>
      validatePayableRequestMutation({
        id: expense.id,
        amount: expense.amountToReimburse,
        currency: expense.currency,
      }),
    ...rest,
  ];
};
