import { Button, FormField, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { type ControlRule } from '../../controlRule';
import { type Member } from '../../member';
import { ListMembers } from '../ListMembers/ListMembers';

import './ControlRuleSummary.css';

type Props = {
  controlRule: ControlRule;
  members: Member[];
  onEditDetailsClick(): void;
  onDelete(): void;
  onClose(): void;
};

export const ControlRuleSummary = ({
  controlRule,
  members,
  onEditDetailsClick,
  onDelete,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      iconName="card-lock"
      iconVariant="primary"
      isOpen
      title={
        controlRule.isInitial
          ? t('controlRules.initialRuleName')
          : controlRule.name
      }
      actions={[]}
      onClose={onClose}
    >
      <div className="ControlRuleSummary">
        <FormField label={t('controlRulesModal.details.labelForRuleDetails')}>
          <div className="ControlRuleSummary__details">
            <SettingDisplay
              label={t('controlRulesModal.details.limit')}
              current={t('controlRulesModal.details.limitNumber', {
                count: controlRule.incompletePaymentsLimit,
              })}
            />
            <SettingDisplay
              label={t('controlRulesModal.details.deadline')}
              current={t('controlRulesModal.details.deadlineNumber', {
                count: controlRule.completionDeadline,
              })}
            />
            {controlRule.isInitial ? (
              <SettingDisplay
                label={t('controlRulesModal.details.autoAssign')}
                current={controlRule.isDefault ? t('misc.yes') : t('misc.no')}
              />
            ) : null}
          </div>
        </FormField>
        <FormField
          label={t('controlRulesModal.members.withThisControlRule', {
            count: controlRule.userIds.length,
          })}
        >
          <ListMembers members={members} />
        </FormField>
        <div className="ControlRuleSummary__actions">
          <Button
            className="ControlRuleSummary__actions_button-edit"
            variant="primary"
            text={t('controlRulesModal.details.editThePolicy')}
            onClick={onEditDetailsClick}
          />
          {controlRule.isInitial ? (
            <span>{t('controlRulesModal.details.cannotRemove')}</span>
          ) : (
            <Button
              text={t('controlRulesModal.details.delete')}
              variant="ghost"
              onClick={onDelete}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

const SettingDisplay = ({
  label,
  current,
}: {
  label: string;
  current: string;
}) => {
  return (
    <div className="ControlRuleSummary__details_row">
      <span className="text-neutral-darker body-m ">{label}</span>
      <span className="ControlRuleSummary__details_row_current body-m ">
        {current}
      </span>
    </div>
  );
};
