import { reducer as cardsReducer } from './list/redux/reducer';
import { reducer as cardReducer } from './my-card/redux/reducer';
import { reducer as cardsOrderReducer } from './order/redux/reducer';

// FIXME: we should export only one reducer and use Redux combine reducers
const reducers = {
  cardsOrder: cardsOrderReducer,
  cards: cardsReducer,
  card: cardReducer,
};

export { reducers };

export {
  CardActivationContainer,
  MyCardContainer,
  MyCardSetPinContainer,
  RecardContainer,
} from './my-card/components';
export { CardsContainer } from './list/components';
export { CardsOrderContainer } from './order';
export { RestrictedAccessWarning } from './components/RestrictedAccessWarning';
