import { SkeletonText, SkeletonButton } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import './CompanySectionLoader.css';

type Props = {
  className?: string;
};

export const CompanySectionLoader = ({ className }: Props) => {
  return (
    <div className={classNames('box mt-m', className)}>
      {Array.from({ length: 3 }, (_, key) => (
        <SkeletonText
          className="CompanySectionLoader__text"
          size="l"
          key={key}
        />
      ))}
      <SkeletonButton className="mt-s" />
    </div>
  );
};
