import { Icon, type IconName } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { describeArc } from './helpers';

import './PieChart.css';

type Props = {
  size: number;
  sectionsCount: number;
  activeSectionsCount: number;
  strokeWidth?: number;
  color: string;
  icon: IconName;
  className?: string;
};

export const PieChart = ({
  size,
  sectionsCount,
  activeSectionsCount,
  color,
  icon,
  strokeWidth = 18,
  className,
}: Props) => {
  const renderArcs = () => {
    const angleDiff = 360 / sectionsCount;
    let angleStart = 0;
    let angleEnd = angleDiff;

    const paths = [];
    for (let index = 0; index <= sectionsCount; index++) {
      paths.push(
        <path
          key={index}
          fill="none"
          stroke={color}
          opacity={index < activeSectionsCount ? 1 : 0.1}
          strokeWidth={strokeWidth}
          d={describeArc(
            size / 2,
            size / 2,
            size / 2 - strokeWidth,
            angleStart,
            angleEnd - 2,
          )}
        />,
      );
      angleStart = angleEnd;
      angleEnd += angleDiff;
    }

    return <g>{paths}</g>;
  };

  return (
    <div
      className={cx('PieChart', className)}
      style={{ width: size, height: size }}
    >
      {icon && (
        <Icon className="PieChart__icon" size="l" name={icon} color={color} />
      )}
      <svg width={size} height={size}>
        {renderArcs()}
      </svg>
    </div>
  );
};
