import { useQueryClient } from 'react-query';

import { getFetcher } from 'src/core/api/fetcher';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryRequest } from 'src/core/api/query';
import { type QueryState } from 'src/core/api/queryState';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

const getUserMfaStatusRequestConfig = (): QueryRequest => ({
  type: 'rest',
  target: 'companyAPI',
  endpoint: '/accounting-integration/check-mfa-enabled',
});

export const useGetUserMfaStatusQuery = (
  hasMfaCheckEnabled: boolean,
): QueryState<boolean> => {
  return useQuery({
    key: ['useGetUserMfaStatusQuery'],
    request: getUserMfaStatusRequestConfig(),
    isEnabled: hasMfaCheckEnabled,
    reshapeData: (data) => {
      return data;
    },
  });
};

export const useGetUserMfaStatusFetcher = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const userMfaStatusFetcher = () =>
    getFetcher<boolean>({
      companyId,
      getRequest: () => ({
        ...getUserMfaStatusRequestConfig(),
        method: 'get',
      }),
    });

  return async (): Promise<boolean> => {
    return queryClient.fetchQuery(
      ['useGetUserMfaStatusQuery'],
      userMfaStatusFetcher(),
    );
  };
};
