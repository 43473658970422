/* eslint-disable sonarjs/no-nested-switch */
import {
  Button,
  EmptyState,
  DeprecatedModalSlideshow,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { SettingsSubNavigation } from 'src/core/common/components/layout/SettingsLayout/SettingsSubNavigation/SettingsSubNavigation';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type Language } from 'src/core/config/i18n';
import FEATURES from 'src/core/constants/features';

import placeholderDePath from './assets/placeholder-de.png';
import placeholderEnPath from './assets/placeholder-en.png';
import placeholderFrPath from './assets/placeholder-fr.png';
import placeholderUsPath from './assets/placeholder-us.png';
import slide1DePath from './assets/slide-1-de.png';
import slide1EnPath from './assets/slide-1-en.png';
import slide1FrPath from './assets/slide-1-fr.png';
import slide1UsPath from './assets/slide-1-us.png';
import slide2DePath from './assets/slide-2-de.png';
import slide2EnPath from './assets/slide-2-en.png';
import slide2FrPath from './assets/slide-2-fr.png';
import slide2UsPath from './assets/slide-2-us.png';
import slide3DePath from './assets/slide-3-de.png';
import slide3EnPath from './assets/slide-3-en.png';
import slide3FrPath from './assets/slide-3-fr.png';
import slide3UsPath from './assets/slide-3-us.png';
import slide4DePath from './assets/slide-4-de.png';
import slide4EnPath from './assets/slide-4-en.png';
import slide4FrPath from './assets/slide-4-fr.png';
import slide4UsPath from './assets/slide-4-us.png';

function getSlidePath(lang: Language, index: number, currency: string): string {
  if (currency === 'USD') {
    switch (index) {
      case 0:
        return slide1UsPath;
      case 1:
        return slide2UsPath;
      case 2:
        return slide3UsPath;
      case 3:
        return slide4UsPath;
      default:
        return slide1UsPath;
    }
  }
  switch (index) {
    case 0:
      switch (lang) {
        case 'de':
          return slide1DePath;
        case 'fr':
          return slide1FrPath;
        default:
          return slide1EnPath;
      }
    case 1:
      switch (lang) {
        case 'de':
          return slide2DePath;
        case 'fr':
          return slide2FrPath;
        default:
          return slide2EnPath;
      }
    case 2:
      switch (lang) {
        case 'de':
          return slide3DePath;
        case 'fr':
          return slide3FrPath;
        default:
          return slide3EnPath;
      }
    case 3:
      switch (lang) {
        case 'de':
          return slide4DePath;
        case 'fr':
          return slide4FrPath;
        default:
          return slide4EnPath;
      }
    default:
      return slide1EnPath;
  }
}

function getPlaceholderPath(lang: Language, currency: string): string {
  if (currency === 'USD') {
    return placeholderUsPath;
  }
  switch (lang) {
    case 'de':
      return placeholderDePath;
    case 'fr':
      return placeholderFrPath;
    default:
      return placeholderEnPath;
  }
}

export type Props = {
  onDone: () => void;
};

export function NoIntegrationAccountingPage({ onDone }: Props) {
  const company = useCompany();
  const { t, activeLanguage } = useTranslation('global');
  const [isOpen, setIsOpen] = useState(false);
  const slides = [
    {
      title: t('accounting.onboarding.slide1Title'),
      content: t('accounting.onboarding.slide1Content'),
    },
    {
      title: t('accounting.onboarding.slide2Title'),
      content: t('accounting.onboarding.slide2Content'),
    },
    {
      title: t('accounting.onboarding.slide3Title'),
      content: t('accounting.onboarding.slide3Content'),
    },
    {
      title: t('accounting.onboarding.slide4Title'),
      content: t('accounting.onboarding.slide4Content'),
    },
  ].map((slide, index) => ({
    ...slide,
    illustration: (
      <div className="flex h-full w-full items-center justify-center bg-primary-lightest">
        <img
          className="max-h-[340px] max-w-[80%] object-contain"
          src={getSlidePath(activeLanguage, index, company.currency)}
          alt=""
        />
      </div>
    ),
  }));

  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  if (hasNewNavigation) {
    return (
      <>
        <EmptyState
          illustration={
            <img
              src={getPlaceholderPath(activeLanguage, company.currency)}
              alt="accounting integration setup"
              className="w-[430px]"
            />
          }
          title={t('accounting.onboarding.promoteTitle')}
          subtitle={t('accounting.onboarding.promoteContent')}
          actions={
            <Button
              variant="primary"
              text={t('accounting.onboarding.promoteCta')}
              onClick={() => setIsOpen(true)}
            />
          }
        />

        <DeprecatedModalSlideshow
          isOpen={isOpen}
          slides={slides}
          translations={{
            cancel: t('accounting.onboarding.close'),
            previous: t('accounting.onboarding.back'),
            next: t('accounting.onboarding.next'),
            done: t('accounting.onboarding.done'),
          }}
          onDone={() => onDone()}
          onClose={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}
        />
      </>
    );
  }

  return (
    <div className="page__container">
      {hasNewNavigation && <SettingsSubNavigation />}
      <EmptyState
        illustration={
          <img
            src={getPlaceholderPath(activeLanguage, company.currency)}
            alt="accounting integration setup"
            className="w-[430px]"
          />
        }
        title={t('accounting.onboarding.promoteTitle')}
        subtitle={t('accounting.onboarding.promoteContent')}
        actions={
          <Button
            variant="primary"
            text={t('accounting.onboarding.promoteCta')}
            onClick={() => setIsOpen(true)}
          />
        }
      />

      <DeprecatedModalSlideshow
        isOpen={isOpen}
        slides={slides}
        translations={{
          cancel: t('accounting.onboarding.close'),
          previous: t('accounting.onboarding.back'),
          next: t('accounting.onboarding.next'),
          done: t('accounting.onboarding.done'),
        }}
        onDone={() => onDone()}
        onClose={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
      />
    </div>
  );
}
