import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUpdatePayableFieldValuesQueryCache } from 'modules/payable/hooks';
import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { CREATE_CUSTOM_FIELD_VALUE } from './query';

export type Payload = {
  customFieldId: string;
  value: string;
};

type RawResponse = {
  createCustomFieldValue:
    | {
        outcome: 'created';
        customFieldValue: {
          id: string;
          value: string;
          archiveDate?: string | null;
        };
      }
    | {
        outcome: 'failed' | 'custom_field_not_found';
      };
};

export type Response = {
  createCustomFieldValue:
    | {
        outcome: 'created';
        customFieldValue: {
          id: string;
          value: string;
          archiveDate?: string | null;
        };
      }
    | {
        outcome: 'failed' | 'custom_field_not_found';
      };
};

export const useCreateCustomFieldMutation = (): MutationState<
  Payload,
  Response
> => {
  const companyId = useCompanyId();
  const updateFieldValuesQueryCache = useUpdatePayableFieldValuesQueryCache();

  return useMutation<Payload, Response, RawResponse>({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: CREATE_CUSTOM_FIELD_VALUE,
    },
    reshapeData: (data) => data,
    options: {
      onSuccess: ({ data, payload }): void => {
        if (data.createCustomFieldValue.outcome === 'created') {
          // TODO@financeAccountant@CORE-4887: update `useValuesOfCustomFieldsQuery` query cache

          updateFieldValuesQueryCache(companyId, {
            action: 'ADD_CUSTOM_FIELD_VALUE',
            customFieldId: payload.customFieldId,
            value: {
              id: data.createCustomFieldValue.customFieldValue.id,
              name: data.createCustomFieldValue.customFieldValue.value,
              archiveDate:
                data.createCustomFieldValue.customFieldValue.archiveDate,
            },
          });
        }
      },
    },
  });
};
