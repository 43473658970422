import React from 'react';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_admin: boolean;
    has_plastic_card: boolean;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
};

export const CardLink = ({
  company,
  className,
  isInDropdown,
  onClick,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavigationLink
      data-marketing="top-menu_card"
      key="nav_card"
      className={className}
      onClick={onClick}
      path={routeFor(routes.CARD.path, { company: company.id })}
      text={t('misc.card')}
      isInDropdown={isInDropdown}
    />
  );
};
