import * as R from '@dev-spendesk/general-type-helpers/Result';
import { CheckboxBox } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUserRoles } from 'src/core/modules/app/hooks/useUserRoles';

import { AddionalRightsNotEditableCallout } from './AddionalRightsNotEditableCallout';
import * as MemberModel from '../../models/member';
import { type Role } from '../../models/roles';
import { type MemberEditFormikValues } from '../MemberEditModal/validate';

type Props = {
  member: MemberModel.MemberDetails;
  formikProps: FormikProps<MemberEditFormikValues>;
  roles: Role[];
};

export const MemberEditTabAdditionalRights = ({
  member,
  roles,
  formikProps,
}: Props) => {
  const { t } = useTranslation('global');
  const currentUserRoles = useUserRoles();

  const { values, setFieldValue } = formikProps;
  const draftMemberRoles = MemberModel.getDraftMemberRoles(
    formikProps.values,
    roles,
  );

  const isRightsEditable =
    MemberModel.canEditAdditionalRights(currentUserRoles) &&
    (values.canConfirmPayments ||
      R.isSuccess(
        MemberModel.getEligibilityToWireTransferDelegation(
          member,
          draftMemberRoles.isController,
        ),
      ));

  const updateConfirmPaymentsRight = () => {
    if (values.canConfirmPayments) {
      return setFieldValue('canConfirmPayments', false);
    }

    return setFieldValue('canConfirmPayments', true);
  };

  return (
    <div>
      {!isRightsEditable && (
        <AddionalRightsNotEditableCallout
          member={member}
          isControllerValue={draftMemberRoles.isController}
        />
      )}

      <CheckboxBox
        className="my-m"
        isDisabled={!isRightsEditable}
        onChange={updateConfirmPaymentsRight}
        isChecked={Boolean(values.canConfirmPayments)}
        label={t('members.editModal.additionalRights.confirmPaymentsTitle')}
        description={t(
          'members.editModal.additionalRights.confirmPaymentsDescription',
        )}
      />
    </div>
  );
};
