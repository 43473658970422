import type { MonthStats } from './stats';

export type QueryResult = {
  company: {
    payments: {
      pageInfo: {
        hasNextPage: boolean;
        endCursor: string;
      };
      stats_by_month: MonthStats[];
      edges: object[];
    };
  };
};

export const allPaymentsQuery = `
  query FetchPaymentsAndStats($companyId: String!, $first: Int, $after: String, $filtersV1: [PaymentsFilters], $filtersV2: [JSON]) {
    company(id: $companyId) {
      payments(orderBy: PAID_DATE_NULL_THEN_DESC, first: $first, after: $after, filters: $filtersV1, filters_v2: $filtersV2) {
        pageInfo {
          hasNextPage
          endCursor
        }
        stats_by_month {
          month
          year
          total_count
          total_amount_billed
          total_fx_fee_amount
        }
        edges {
          node {
            databaseId
            user {
              _id,
              full_name
              gender
              avatar
            }
            card_id
            completionDeadline {
              status
              daysLeft
            }
            completionState
            team {
              name
            }
            costCenter {
              name
            }
            supplier {
              name
              url
            }
            description
            method
            invoices {
              total
            }
            invoice_lost
            invoice_invalid
            request {
              type
              mileage_allowance_request {
                distance
              }
            }
            amount_declared
            currency_declared
            amount_billed
            fx_fee_amount
            paid_at
            created_at
            validated_at
            state
            is_atm_withdrawal
            expense_account_id
            transaction {
              clean_description
            }
            payment_description
          }
        }
      }
    }
  }
`;
