import {
  SkeletonButton,
  SkeletonText,
  SkeletonTable,
} from '@dev-spendesk/grapes';
import React from 'react';

export const MembersTableLoader = () => {
  return (
    <div>
      <div className="mb-s flex gap-m">
        <div className="flex grow flex-col gap-xxs">
          <SkeletonText size="xl" width="150px" />
          <SkeletonText width="150px" />
        </div>

        <SkeletonButton />
        <SkeletonButton />
      </div>
      <SkeletonTable
        columns={[
          {
            cell: <SkeletonText width="80%" />,
            header: <SkeletonText width="50%" />,
          },
        ]}
        numberOfRows={5}
        withHeader
      />
    </div>
  );
};
