import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';

import { type DeclarationData } from './declarationData';

export const validateAffidavitForm = (
  values: DeclarationData,
  isGermanCompany: boolean,
  t: TGlobalFunctionTyped,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): FormikErrors<DeclarationData> => {
  const errors: FormikErrors<DeclarationData> = {};

  if (!values.purchaseDescription || values.purchaseDescription.trim() === '') {
    errors.purchaseDescription = t(
      'payments.affidavitModal.purchaseInfo.errors.descriptionEmptyError',
    );
  }

  if (isGermanCompany) {
    if (!values.userRole || values.userRole.trim() === '') {
      errors.userRole = t(
        'payments.affidavitModal.purchaseInfo.errors.descriptionEmptyError',
      );
    }
    if (
      !values.supplierAddressThoroughfare ||
      values.supplierAddressThoroughfare.trim() === ''
    ) {
      errors.supplierAddressThoroughfare = t(
        'payments.affidavitModal.purchaseInfo.errors.thoroughfareEmptyError',
      );
    }
    if (
      !values.supplierAddressCity ||
      values.supplierAddressCity.trim() === ''
    ) {
      errors.supplierAddressCity = t(
        'payments.affidavitModal.purchaseInfo.errors.cityEmptyError',
      );
    }
    if (
      !values.supplierAddressCountry ||
      values.supplierAddressCountry.trim() === ''
    ) {
      errors.supplierAddressCountry = t(
        'payments.affidavitModal.purchaseInfo.errors.countryEmptyError',
      );
    }
    if (
      !values.supplierAddressZipCode ||
      values.supplierAddressZipCode.trim() === ''
    ) {
      errors.supplierAddressZipCode = t(
        'payments.affidavitModal.purchaseInfo.errors.zipEmptysError',
      );
    }

    if (!values.supplierName || values.supplierName.trim() === '') {
      errors.supplierName = t(
        'payments.affidavitModal.purchaseInfo.errors.supplierNameEmptyError',
      );
    }
  }

  if (!values.reason || values.reason.trim() === '') {
    errors.reason = t(
      'payments.affidavitModal.purchaseInfo.errors.reasonEmptyError',
    );
  }

  return errors;
};
