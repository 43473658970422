import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type { ApiCard } from '../card';

export const useCardQuery = (cardId: string): QueryState<ApiCard> => {
  return useQuery<ApiCard, ApiCard>({
    key: ['cards', cardId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/cards/${cardId}`,
    },
    reshapeData: (data) => data,
  });
};
