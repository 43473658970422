export type TeamId = string;

export interface Team {
  id: TeamId;
  name: string;
}

// if user has one team, that's the default. Otherwise we let them choose
export const getDefaultTeam = (teams: Team[]): Team | null =>
  teams.length === 1 ? { id: teams[0].id, name: teams[0].name } : null;

export const getIsTeamRequired = (teams: Team[]): boolean => teams.length > 0;
