import { Avatar, Button, Icon, Link, Tag, Tooltip } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { routes, routeFor } from 'src/core/constants/routes';

import {
  useDisconnectKomboIntegrationMutation,
  useDisconnectTravelperkMutation,
} from '../../../hooks';
import { useKomboForceSyncMutation } from '../../../hooks/useKomboForceSyncMutation';
import { type Integration } from '../../../types';
import {
  canRefreshSettings,
  getIntegrationDetailsDescription,
  getIntegrationLogo,
  getIntegrationName,
  getIntegrationUrl,
  isKomboIntegration,
} from '../../../utils';
import { ConnectionModal } from '../ConnectionModal';
import { IntegrationBenefitsBox } from '../IntegrationBenefitsBox';
import { IntegrationErrorCallout } from '../IntegrationErrorCallout';
import { KomboIntegrationDetailsContainer } from '../IntegrationPage/custom-page-content/kombo/KomboIntegrationDetailsContainer';
import OAuth2TokenContent from '../IntegrationPage/custom-page-content/oauth2-token/OAuth2TokenContent';

type Props = {
  integration: Integration;
};

type ModalState =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      integration: Integration;
    };

const IntegrationDetails = (props: Props) => {
  const { integration } = props;
  const { error } = integration;
  const company = useCompany();
  const history = useHistory();
  const { t } = useTranslation('global');
  const name = getIntegrationName(t, integration.id);
  const logo = getIntegrationLogo(integration.id);
  const url = getIntegrationUrl(t, integration.id);

  const navigateToList = () => {
    history.push(
      routeFor(routes.COMPANY_INTEGRATIONS_ALL.path, {
        company: company.id,
      }),
    );
  };

  const komboIntegration = isKomboIntegration(integration.id);

  const canRefresh = canRefreshSettings(integration.id);

  const [komboForceSync] = useKomboForceSyncMutation();

  const [disconnectTravelperk] = useDisconnectTravelperkMutation(
    integration.id,
  );
  const [disconnectKombo] = useDisconnectKomboIntegrationMutation(
    integration.id,
  );
  const disconnect = async ({ silent } = { silent: false }) => {
    if (integration.id === 'travelperk') {
      await disconnectTravelperk({ silent });
    }
    if (isKomboIntegration(integration.id)) {
      await disconnectKombo({ silent });
    }
  };

  const renderStatusTag = () => {
    if (!error) {
      return (
        <Tag variant="success" className="flex self-center">
          {t('integration.tags.connected')}
        </Tag>
      );
    }

    switch (error) {
      case 'pendingAction':
        return (
          <Tag variant="warning" className="flex self-center">
            {t(`integration.tags.pendingAction`)}
          </Tag>
        );
      case 'pendingSync':
        return (
          <Tag variant="info" className="flex self-center">
            {t(`integration.tags.pendingSync`)}
          </Tag>
        );
      default:
        return (
          <Tag variant="alert" className="flex self-center">
            {t('integration.tags.notConnected')}
          </Tag>
        );
    }
  };

  return (
    <div className="page__container bg-page-background">
      <div className="relative flex flex-row items-start justify-center gap-m pb-l pt-3xl">
        <div className="w-[1200px]">
          <div className="flex flex-col gap-m">
            <Button
              className="self-start"
              fit="content"
              iconName="caret-left"
              iconPosition="left"
              variant="ghost"
              text={t('integration.backToList')}
              onClick={navigateToList}
            />
            <div className="flex flex-row items-center gap-s pb-s">
              <div className="flex h-[72px] w-[72px]">
                <Avatar
                  src={logo}
                  text={name}
                  variant="square"
                  className="h-[72px] w-[72px]"
                />
              </div>
              <div className="mr-auto flex flex-col gap-xs text-left">
                <div className="flex gap-s">
                  <span className="flex text-complementary title-xl">
                    {name}
                  </span>
                  {renderStatusTag()}
                </div>
                <div>
                  {url ? (
                    <Link
                      className="flex items-center text-neutral-dark no-underline hover:text-neutral"
                      href={url}
                      isExternal
                    >
                      {url}&nbsp;
                      <Icon name="external" size="s" />
                    </Link>
                  ) : null}
                </div>
              </div>

              {canRefresh && (
                <Tooltip
                  content={t('integration.kombo.refresh.tooltipMessage')}
                  placement="top"
                  maxWidth={430}
                >
                  <Button
                    variant="secondary"
                    text={t('integration.refresh')}
                    onClick={async () => {
                      await komboForceSync({ full: false });
                    }}
                    isDisabled={!!error}
                  />
                </Tooltip>
              )}

              {komboIntegration ? (
                <div>
                  <Button
                    variant="alert"
                    className="self-start"
                    text={t('integration.disconnect')}
                    onClick={async () => {
                      await disconnect();
                      navigateToList();
                    }}
                  />
                </div>
              ) : null}
            </div>
            <IntegrationDetailsContent
              {...props}
              navigateToList={navigateToList}
              disconnect={disconnect}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary({
  scope: 'integration-details',
  team: 'api-integration',
})(IntegrationDetails);

type IntegrationDetailsContentProps = Props & {
  navigateToList: () => void;
  disconnect: (options?: { silent: boolean }) => Promise<void>;
};

const IntegrationDetailsContent = (props: IntegrationDetailsContentProps) => {
  if (isKomboIntegration(props.integration.id)) {
    return <KomboIntegrationDetailsContainer {...props} />;
  }

  switch (props.integration.id) {
    case 'spendesk-oauth2':
      return <OAuth2TokenContent />;
    default:
      return <DefaultIntegrationDetails {...props} />;
  }
};

const DefaultIntegrationDetails = ({
  integration,
  navigateToList,
  disconnect,
}: IntegrationDetailsContentProps) => {
  const { t } = useTranslation('global');

  const [modalState, setModalState] = useState<ModalState>({ isOpen: false });
  const fullDescription = getIntegrationDetailsDescription(t, integration.id);
  const { error } = integration;

  return (
    <>
      <div className="flex flex-col gap-xs">
        <span className="text-l text-neutral-dark">{fullDescription}</span>
      </div>
      {error ? (
        <IntegrationErrorCallout
          error={error}
          onAction={async () => {
            await disconnect({ silent: true });
            setModalState({
              isOpen: true,
              integration,
            });
          }}
        />
      ) : null}
      <IntegrationBenefitsBox integration={integration} />
      <Button
        variant="alert"
        className="self-start"
        text={t('integration.disconnect')}
        onClick={async () => {
          await disconnect();
          navigateToList();
        }}
      />

      {modalState.isOpen ? (
        <ConnectionModal
          integration={modalState.integration}
          closeModal={() => setModalState({ isOpen: false })}
        />
      ) : null}
    </>
  );
};
