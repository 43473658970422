import navLinks from '../header-items';

type MainNavigationLink = (props: {
  user: {
    is_account_owner: boolean;
    is_organisation_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
    is_requester: boolean;
    is_approver: boolean;
    has_plastic_card: boolean;
    is_group_admin: boolean;
  };
  company: {
    id: string;
    churning_at: string | null;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
}) => JSX.Element | null;

type MainNavigationItem = { name: string; component: MainNavigationLink };

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getLeftNavigationItems({
  user,
  company,
  features,
}: {
  user: {
    is_account_owner: boolean;
    is_organisation_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
    is_requester: boolean;
    is_approver: boolean;
    has_plastic_card: boolean;
    is_group_admin: boolean;
  };
  company: {
    id: string;
    type: 'branch_entity' | 'branch_currency' | 'branch_expense_entity';
    churning_at: string | null;
  };
  features: {
    hasCommitmentsTab: boolean;
    isPurchaseOrdersFeatureActivated: boolean;
    hasVirtualCards: boolean;
    hasPlasticCardsFeature: boolean;
    isBudgetsFeatureEnabled: boolean;
    canAccessInvoicesPage: boolean;
    canAccessExpenseClaimsPage: boolean;
    isBookkeepEnabled: boolean;
    isProcurementEnabled: boolean;
  };
}): MainNavigationItem[] {
  const leftNavigationItems: MainNavigationItem[] = [];
  const {
    hasCommitmentsTab,
    isPurchaseOrdersFeatureActivated,
    hasVirtualCards,
    hasPlasticCardsFeature,
    isBudgetsFeatureEnabled,
    canAccessInvoicesPage,
    canAccessExpenseClaimsPage,
    isBookkeepEnabled,
  } = features;

  // Requests
  if (
    !company.churning_at &&
    (user.is_requester || user.is_approver || user.is_controller)
  ) {
    leftNavigationItems.push({
      name: 'requests',
      component: navLinks.RequestsLink,
    });
  }

  // Payments
  if (
    isBookkeepEnabled &&
    (user.has_plastic_card ||
      user.is_account_owner ||
      user.is_controller ||
      user.is_requester ||
      user.is_group_admin)
  ) {
    leftNavigationItems.push({
      name: 'payments',
      component: navLinks.PaymentsLink,
    });
  }

  // Commitments
  if (hasCommitmentsTab) {
    leftNavigationItems.push({
      name: 'commitments',
      component: navLinks.CommitmentsLink,
    });
  }

  // Purchase orders
  if (isPurchaseOrdersFeatureActivated && !hasCommitmentsTab) {
    leftNavigationItems.push({
      name: 'purchase',
      component: navLinks.PurchaseOrdersLink,
    });
  }

  // Subscriptions
  if (
    // Hide the 'Subscriptions' tab when 'Commitments' tab is visible (because it included in 'Commitments')
    !hasCommitmentsTab &&
    company.type !== 'branch_expense_entity' &&
    hasVirtualCards &&
    !company.churning_at &&
    (user.is_account_owner ||
      user.is_controller ||
      user.is_requester ||
      user.is_group_admin)
  ) {
    leftNavigationItems.push({
      name: 'subscription',
      component: navLinks.SubscriptionsLink,
    });
  }

  // Cards
  if (
    !company.churning_at &&
    (user.is_account_owner || user.is_admin) &&
    hasPlasticCardsFeature
  ) {
    leftNavigationItems.push({ name: 'cards', component: navLinks.CardsLink });
  }

  // Invoices
  if (canAccessInvoicesPage) {
    leftNavigationItems.push({
      name: 'invoices',
      component: navLinks.InvoicesLink,
    });
  }

  // Expense claims
  if (canAccessExpenseClaimsPage) {
    leftNavigationItems.push({
      name: 'expense',
      component: navLinks.ExpenseClaimsLink,
    });
  }

  // Card
  if (
    !company.churning_at &&
    user.has_plastic_card &&
    !user.is_admin &&
    hasPlasticCardsFeature
  ) {
    leftNavigationItems.push({ name: 'card', component: navLinks.CardLink });
  }

  // Procurement
  if (features.isProcurementEnabled) {
    leftNavigationItems.push({
      name: 'procurement',
      component: navLinks.ProcurementLink,
    });
  }
  // Settings
  if (
    user.is_controller ||
    user.is_account_owner ||
    (!company.churning_at && user.is_admin)
  ) {
    leftNavigationItems.push({
      name: 'settings',
      component: navLinks.SettingsLink,
    });
  }

  // Viewers budgets
  if (
    isBudgetsFeatureEnabled &&
    !(user.is_account_owner || user.is_controller)
  ) {
    leftNavigationItems.push({
      name: 'budgets',
      component: navLinks.ViewersBudgetsLink,
    });
  }

  return leftNavigationItems;
}

export function getResponsiveLeftNavigationItems({
  width,
  leftNavigationItems,
}: {
  width: number;
  leftNavigationItems: MainNavigationItem[];
}): MainNavigationItem[] {
  if (width >= 1600) {
    return leftNavigationItems;
  }

  if (width >= 1280) {
    return leftNavigationItems.slice(0, 7);
  }

  if (width >= 1000) {
    return leftNavigationItems.slice(0, 4);
  }

  return leftNavigationItems.slice(0, 2);
}

export function getPlusDropdownItems({
  width,
  leftNavigationItems,
}: {
  width: number;
  leftNavigationItems: MainNavigationItem[];
}): MainNavigationItem[] {
  if (width >= 1280) {
    return leftNavigationItems.slice(7);
  }

  if (width >= 1000) {
    return leftNavigationItems.slice(4);
  }

  return leftNavigationItems.slice(2);
}
