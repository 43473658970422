import React from 'react';

import {
  useCreateCustomFieldValueMutation,
  useUpdateCustomFieldValueMutation,
  useDeleteCustomFieldValueMutation,
} from './hooks';
import { useTeamsQuery } from './hooks/useTeamsQuery';
import { CustomFieldValuesList } from '../../components/CustomFieldValuesList';
import { type CustomField } from '../../models/customField';

type Props = {
  customField: CustomField;
};

export const CustomFieldValuesListContainer = ({ customField }: Props) => {
  const [createCustomFieldValue] = useCreateCustomFieldValueMutation(
    customField.id,
  );
  const [updateCustomFieldValue] = useUpdateCustomFieldValueMutation(
    customField.id,
  );
  const [deleteCustomFieldValue] = useDeleteCustomFieldValueMutation(
    customField.id,
  );

  const teamsQueryState = useTeamsQuery();

  if (teamsQueryState.status !== 'success') {
    return null;
  }

  return (
    <CustomFieldValuesList
      customField={customField}
      teams={teamsQueryState.data}
      onUpdateCustomFieldValue={updateCustomFieldValue}
      onCreateCustomFieldValue={createCustomFieldValue}
      onDeleteCustomFieldValue={deleteCustomFieldValue}
    />
  );
};
