import React from 'react';

import { CalloutError, lazyLoad } from 'common/components/LazyLoad';

import { PurchaseOrdersPageSkeleton } from './PurchaseOrdersPageSkeleton';

export const PurchaseOrdersPage = lazyLoad({
  loader: async () => ({
    /* eslint-disable unicorn/no-await-expression-member */
    default: (
      await import(
        /* webpackChunkName: "PurchaseOrdersPage" */ './PurchaseOrdersPage'
      )
    ).PurchaseOrdersPage,
    /* eslint-enable unicorn/no-await-expression-member */
  }),
  loading: <PurchaseOrdersPageSkeleton />,
  fallback: (props) => <CalloutError {...props} />,
});
