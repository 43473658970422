import * as R from '@dev-spendesk/general-type-helpers/Result';
import {
  Callout,
  FormField,
  Input,
  Skeleton,
  SkeletonCheckbox,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { type ReactElement, useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  type CreateOrUpdateAccountPayableResponse,
  useCreateOrUpdateAccountPayable,
  useHasAccountingIntegrationCapability,
} from 'modules/accounting-integration/apis';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useIntegrationStatusQuery } from 'modules/bookkeep';
import { useGetSupplierAccountsQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSupplierAccountsQuery';
import { AccountsPayableAdvice } from 'modules/bookkeep/components/AccountAdvice';
import {
  AccountPayableCreationModal,
  type AccountPayableCreationModalState,
} from 'modules/bookkeep/components/AccountPayableCreationModal';
import { getCreateOrUpdateAccountPayableError } from 'modules/bookkeep/components/AccountPayableCreationModal/AccountPayableCreationModalState';
import { useHasAuxiliaryAccountsEnabled } from 'modules/bookkeep/hooks';
import { isIntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { InvalidAccountCallout } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/components/InvalidAccountCallout';
import { isAccountPayableEditable } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/components/PreparePayableView/helpers';
import {
  checkIfDefaultSupplierAccount,
  checkIfSupplierAccountDeleted,
  checkIfSupplierAccountInvalid,
  findCurrentSupplier,
} from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/components/PreparePayablesFormFields/helpers';
import { getSupplierAccountsQueryFetcher } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/hooks/getSupplierAccountsQueryFetcher';
import { useGetDefaultSupplierAccountsQuery } from 'modules/bookkeep/settings/accounting/graphql/hooks';
import { useGetEmployeeAccountCodesQuery } from 'modules/bookkeep/settings/integrations/hooks/useGetEmployeeAccountCodesQuery';
import { PayableInputComponent } from 'modules/payable/components';
import {
  buildInputConfig,
  useGetPayableFieldValues,
} from 'modules/payable/hooks';
import { reshapeSupplierAccountsResultData } from 'modules/payable/hooks/api/useGetPayableFieldValues/reshaper';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { type Payable } from 'src/core/modules/bookkeep/prepare-payables/models';
import {
  getCodeWithAuxiliaryAccounts,
  getGeneralAndAuxiliaryAccountCodes,
} from 'src/core/utils/accountPayable';

type Account = {
  id: string;
  generalAccountCode: string | null | undefined;
  auxiliaryAccountCode?: string | null | undefined;
};

type Props = {
  value: Account | undefined;
  payable: Pick<Payable, 'type' | 'subType'>;
  onChange: (value: Account | null | undefined) => void;
  onInputChange?: (value?: string) => void;
  error?: string | undefined;
  isReadOnly?: boolean;
  className?: string;
};

export const PayableAccountPayableField = ({
  value: accountPayable,
  payable,
  onChange,
  onInputChange,
  error,
  isReadOnly: isParentReadOnly,
  className = '',
}: Props) => {
  const { t } = useTranslation('global');
  const client = useQueryClient();
  const companyId = useCompanyId();

  const supplierInput = usePayableAccountPayableFieldConfig({
    isReadOnly: isParentReadOnly,
  });

  const integrationStatusQuery = useIntegrationStatusQuery();

  const [
    accountPayableCreationModalState,
    setAccountPayableCreationModalState,
  ] = useState<AccountPayableCreationModalState>({ kind: 'closed' });

  const [accountCreationFailure, setAccountCreationFailure] =
    useState<AccountCreationFailure>();

  const areSupplierAccountsLocalOnly = useHasAccountingIntegrationCapability(
    'supplierAccounts',
    ['localOnly', 'localOnlyWithDefaultAccounts'],
  );

  const getEmployeeAccountCodesQuery = useGetEmployeeAccountCodesQuery();

  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();

  const getSupplierAccountsQueryState = useGetSupplierAccountsQuery({
    includeArchived: false,
  });

  const [createSupplierAccount] =
    useCreateOrUpdateAccountPayable('supplierAccount');

  const showAccountPayableCreationModal = (value: string) => {
    const { generalAccountCode, auxiliaryAccountCode } =
      getGeneralAndAuxiliaryAccountCodes(value);

    setAccountPayableCreationModalState({
      kind: 'form',
      error: undefined,
      createdAccount: {
        generalAccountCode,
        auxiliaryAccountCode,
        kind: 'supplierAccount',
        id: '',
        isDefault: false,
        isArchived: false,
      },
    });

    return accountPayable?.id;
  };

  const cancelCreateAccountPayable = async () => {
    onChange(accountPayable);
  };

  const createAccountPayable = async (value: string) => {
    if (
      auxiliaryAccountsEnabled &&
      accountPayableCreationModalState.kind === 'closed'
    ) {
      return showAccountPayableCreationModal(value);
    }

    const { generalAccountCode, auxiliaryAccountCode } =
      getGeneralAndAuxiliaryAccountCodes(value);

    const accountCode = getCodeWithAuxiliaryAccounts({
      generalAccountCode,
      auxiliaryAccountCode,
    });

    const createSupplierAccountResult = await createSupplierAccount({
      generalAccountCode: auxiliaryAccountsEnabled
        ? generalAccountCode
        : accountCode,
      ...(auxiliaryAccountsEnabled ? { auxiliaryAccountCode } : {}),
      isArchived: false,
    });

    if (R.isFailure(createSupplierAccountResult)) {
      if (accountPayableCreationModalState.kind === 'form') {
        setAccountPayableCreationModalState({
          ...accountPayableCreationModalState,
          ...getCreateOrUpdateAccountPayableError(createSupplierAccountResult),
        });
      }

      setAccountCreationFailure(createSupplierAccountResult.error);
      return;
    }

    // We need to invalidate this query synchronously so that...
    await client.invalidateQueries(['useFieldValuesQuery', companyId], {
      exact: true,
    });
    // ... the changes we apply to form data are reflected on the UI
    onChange({
      id: createSupplierAccountResult.value.accountPayableId,
      generalAccountCode,
      auxiliaryAccountCode,
    });

    return createSupplierAccountResult.value.accountPayableId;
  };

  const feedback = useAccountUpdateFeedback({
    accountPayable,
    payable,
    supplierInput,
    error,
    accountCreationFailure,
  });

  if (feedback?.isReadOnly) {
    return (
      <div className={classNames('flex flex-col gap-xs', className)}>
        <FormField label={t('payables.panel.accountPayable.label')}>
          <Input
            isDisabled
            value={
              getCodeWithAuxiliaryAccounts({
                generalAccountCode: accountPayable?.generalAccountCode ?? '',
                auxiliaryAccountCode:
                  accountPayable?.auxiliaryAccountCode ?? undefined,
              }) || '-'
            }
          />
        </FormField>
      </div>
    );
  }

  if (!supplierInput) {
    return (
      <div className={classNames('flex flex-col gap-xs', className)}>
        <FormField label={t('payables.panel.accountPayable.label')}>
          {accountPayable ? (
            <Input
              isDisabled
              value={getCodeWithAuxiliaryAccounts({
                generalAccountCode: accountPayable.generalAccountCode ?? '',
                auxiliaryAccountCode:
                  accountPayable.auxiliaryAccountCode ?? undefined,
              })}
              rightAddon={<SkeletonCheckbox className="mr-xs" />}
            />
          ) : (
            <Skeleton width="100%" height="30px" />
          )}
        </FormField>
      </div>
    );
  }

  return (
    <div className={classNames('flex flex-col gap-xs', className)}>
      <PayableInputComponent
        input={{
          ...supplierInput,
          'data-testid': `field-${supplierInput.id}`,
        }}
        allowNewValue={areSupplierAccountsLocalOnly}
        error={error || feedback?.isError ? 'true' : undefined}
        showErrorMessage={false}
        value={accountPayable?.id}
        onChange={(accountPayableId) => {
          onChange(
            typeof accountPayableId === 'string' && accountPayableId
              ? {
                  id: accountPayableId,
                  generalAccountCode: '',
                  auxiliaryAccountCode: '',
                }
              : undefined,
          );
          if (accountPayableId) {
            setAccountCreationFailure(undefined);
          }
        }}
        onInputChanged={onInputChange}
        onCreateNewValue={createAccountPayable}
      />

      {integrationStatusQuery.status === 'success' &&
      isIntegrationStatusWithIntegration(integrationStatusQuery.data) &&
      accountPayableCreationModalState.kind === 'form' ? (
        <AccountPayableCreationModal
          integrationStatus={integrationStatusQuery.data}
          accountPayableCreationModalState={accountPayableCreationModalState}
          setAccountPayableCreationModalState={
            setAccountPayableCreationModalState
          }
          getSupplierAccountsQueryState={getSupplierAccountsQueryState}
          getEmployeeAccountCodesQuery={getEmployeeAccountCodesQuery}
          onClose={cancelCreateAccountPayable}
          createAccountPayable={(generalAccountCode, auxiliaryAccountCode) =>
            createAccountPayable(
              getCodeWithAuxiliaryAccounts({
                generalAccountCode,
                auxiliaryAccountCode,
              }),
            )
          }
        />
      ) : null}

      {feedback?.component}
    </div>
  );
};

/**
 * Helpers
 */

const usePayableAccountPayableFieldConfig = ({
  isReadOnly,
}: {
  isReadOnly?: boolean;
}) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const queryClient = useQueryClient();
  const fieldValuesQuery = useGetPayableFieldValues();
  const fieldsValues = unwrapQuery(fieldValuesQuery);

  return fieldsValues?.supplierAccounts
    ? buildInputConfig({
        id: 'supplierAccount',
        name: t('payables.panel.accountPayable.label'),
        fieldValues: fieldsValues.supplierAccounts,
        isReadOnly: isReadOnly ?? false,

        onLoadValues: async (idsToLoad) => {
          const data = await queryClient.fetchQuery(
            ['useGetSupplierAccountsQuery', companyId, idsToLoad],
            getSupplierAccountsQueryFetcher({
              companyId,
              filter: {
                ids: idsToLoad,
              },
            }),
            {
              cacheTime: 10 * 60 * 1000, // 10min,
              staleTime: 10 * 60 * 1000, // 10min,
            },
          );
          return reshapeSupplierAccountsResultData(
            data.company.chartOfAccounts.supplierAccounts,
          ).values;
        },

        onSearchValues: async (search) => {
          const data = await queryClient.fetchQuery(
            ['useGetSupplierAccountsQuery', companyId, search],
            getSupplierAccountsQueryFetcher({
              companyId,
              filter: {
                search,
              },
            }),
            {
              cacheTime: 10 * 60 * 1000, // 10min,
              staleTime: 10 * 60 * 1000, // 10min,
            },
          );
          return reshapeSupplierAccountsResultData(
            data.company.chartOfAccounts.supplierAccounts,
          ).values;
        },
      })
    : undefined;
};

type AccountCreationFailure = R.FailureOf<CreateOrUpdateAccountPayableResponse>;

const useAccountUpdateFeedback = ({
  accountPayable,
  payable,
  supplierInput,
  error,
  accountCreationFailure,
}: Pick<Props, 'payable' | 'isReadOnly'> & {
  error: string | undefined;
  supplierInput: ReturnType<typeof usePayableAccountPayableFieldConfig>;
  accountCreationFailure: AccountCreationFailure | undefined;
  accountPayable: Props['value'];
}) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const integrationStatusQuery = useIntegrationStatusQuery();
  const accountPayableId = accountPayable?.id;

  const isSupplierAccountDeleted = checkIfSupplierAccountDeleted(
    accountPayable?.id,
    supplierInput,
  );

  const isSupplierAccountInvalid = checkIfSupplierAccountInvalid(
    integrationStatusQuery,
    accountPayableId,
  );

  const isReadOnly =
    supplierInput?.isReadOnly ||
    !isAccountPayableEditable(
      { ...payable, accountPayableId: accountPayable?.id },
      isSupplierAccountDeleted || isSupplierAccountInvalid,
    );

  const { data: defaultSupplierAccounts } =
    useGetDefaultSupplierAccountsQuery();

  const currentSelectedSupplierAccount = findCurrentSupplier(
    supplierInput,
    accountPayableId,
  );

  const isDefaultAccount = checkIfDefaultSupplierAccount(
    currentSelectedSupplierAccount,
    defaultSupplierAccounts,
  );

  const duplicateAccount =
    accountCreationFailure?.reason === 'codeAlreadyExists'
      ? accountCreationFailure.existingAccount
      : undefined;

  const hasUnknownError = accountCreationFailure?.reason
    ? accountCreationFailure.reason !== 'codeAlreadyExists'
    : false;

  let feedback: {
    isError: boolean;
    isReadOnly: boolean;
    component: ReactElement | null;
  } = {
    isError: false,
    isReadOnly,
    component: null,
  };

  if (duplicateAccount) {
    feedback = {
      isError: true,
      isReadOnly,
      component: (
        <>
          {feedback.component}
          <InvalidAccountCallout
            title={t('payables.panel.accountPayable.duplicatedCode.title')}
            message={t('payables.panel.accountPayable.duplicatedCode.text', {
              code: getCodeWithAuxiliaryAccounts(duplicateAccount),
            })}
            button={t('payables.panel.accountPayable.duplicatedCode.link')}
            className="!mb-0"
            route={(() => {
              if (duplicateAccount.kind === 'employeeAccount') {
                return routeFor(
                  routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path,
                  {
                    company: companyId,
                  },
                );
              }

              if (isDefaultAccount || !accountPayableId) {
                return routeFor(
                  routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path,
                  {
                    company: companyId,
                  },
                );
              }

              return routeFor(routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path, {
                company: companyId,
                accountPayableId: duplicateAccount.id,
              });
            })()}
          />
        </>
      ),
    };
  }

  if (
    integrationStatusQuery.status === 'success' &&
    error === 'integrationValidationFailed'
  ) {
    feedback = {
      isError: false,
      isReadOnly,
      component: (
        <>
          {feedback.component}
          <AccountsPayableAdvice
            className=""
            showError
            integrationStatus={integrationStatusQuery.data}
          />
        </>
      ),
    };
  }

  if (!isReadOnly && isSupplierAccountDeleted) {
    feedback = {
      isError: false,
      isReadOnly,
      component: (
        <>
          {feedback.component}
          <Callout
            variant="warning"
            title={t('payables.panel.accountPayable.deletedCode', {
              code: accountPayable?.generalAccountCode,
            })}
          />
        </>
      ),
    };
  }

  if (!isSupplierAccountDeleted && isSupplierAccountInvalid) {
    return {
      isError: true,
      isReadOnly,
      component: (
        <>
          {feedback.component}
          <InvalidAccountCallout
            title={t('payables.panel.accountPayable.invalidAccount.title')}
            message={t('payables.panel.accountPayable.invalidAccount.message', {
              code: currentSelectedSupplierAccount?.label,
            })}
            button={t('payables.panel.accountPayable.invalidAccount.button')}
            className="!mb-0"
            route={
              isDefaultAccount || !accountPayableId
                ? routeFor(routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path, {
                    company: companyId,
                  })
                : routeFor(routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path, {
                    company: companyId,
                    accountPayableId,
                  })
            }
          />
        </>
      ),
    };
  }

  if (hasUnknownError) {
    return {
      isError: true,
      isReadOnly,
      component: (
        <>
          {feedback.component}
          <Callout
            variant="alert"
            title={t('payables.panel.accountPayable.unknownCreationError')}
          />
        </>
      ),
    };
  }

  return feedback;
};
