import { type TaxAccount } from 'modules/payable/components';

type TaxAccountApi = {
  id: string;
  name: string;
  isArchived: boolean;
};

export type VatAccountApi = TaxAccountApi & {
  rate: string;
  code: string;
  isDefault?: boolean;
  __typename: 'VatAccount';
};

export type ReverseChargeAccountApi = TaxAccountApi & {
  __typename: 'ReverseChargeAccount';
};

export const reshapeTaxAccount = (
  rawTaxAccount: VatAccountApi | ReverseChargeAccountApi,
): TaxAccount => {
  if (rawTaxAccount.__typename === 'VatAccount') {
    return {
      ...rawTaxAccount,
      rate: Number(rawTaxAccount.rate),
      isDefault: Boolean(rawTaxAccount.isDefault),
    };
  }

  return {
    ...rawTaxAccount,
  };
};
