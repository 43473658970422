import { colors, Icon, type IconName } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { type ReactNode } from 'react';

import styles from './PanelCard.module.css';

type Props = {
  content: ReactNode;
  items?: { label: string; icon?: IconName }[];
  className?: string;
};

export function PanelCard({ content, items, className }: Props) {
  return (
    <div className={cx(styles.panelCard, className)}>
      <div>{content}</div>
      {items && (
        <ul>
          {items.map((item) => (
            <li
              key={item.label}
              className="flex whitespace-nowrap py-xs px-s text-complementary body-s"
            >
              {item.icon && (
                <span>
                  <Icon
                    name={item.icon}
                    color={colors.neutral}
                    className="mr-xs"
                  />
                </span>
              )}
              <span className="overflow-hidden text-ellipsis">
                {item.label}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
