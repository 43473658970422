import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import type { AppDispatch } from 'modules/app/redux/store';
import { useCostCenterMembersQuery } from 'modules/budgets/apis';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import { useCardsAccessQuery } from './useCardsAccessQuery';
import { useControlRulesQuery } from './useControlRulesQuery';
import { useInvitesQuery } from './useInvitesQuery';
import { useMemberSubscriptionCount } from './useMemberSubscriptionCount';
import { usePoliciesQuery } from './usePoliciesQuery';
import { useTeamsQuery } from './useTeamsQuery';
import { useCompany } from '../../app/hooks/useCompany';
import { useUser } from '../../app/hooks/useUser';
import { fetchMe } from '../../app/layout/redux/actions';
import { useUserHasDelegationQuery } from '../../delegation/hooks';
import type { MemberDetails, RawMember } from '../models/member';
import { reshapeMemberDetails } from '../utils/reshapeMember';

export const useMemberQuery = (memberId: string): QueryState<MemberDetails> => {
  const company = useCompany();

  return useQueryStates({
    states: {
      member: useMemberQueryRaw(memberId),
      teams: useTeamsQuery(),
      controlRules: useControlRulesQuery(),
      policies: usePoliciesQuery(),
      subscriptionCount: useMemberSubscriptionCount(memberId),
      invites: useInvitesQuery(),
      membersCostCenter: useCostCenterMembersQuery(),
      cardsAccess: useCardsAccessQuery(memberId),
      canConfirmPayments: useUserHasDelegationQuery(
        memberId,
        'wt_confirmation',
      ),
    },
    reshapeData: ({
      member,
      teams,
      controlRules,
      policies,
      subscriptionCount,
      invites,
      membersCostCenter,
      cardsAccess,
      canConfirmPayments,
    }) => {
      return reshapeMemberDetails(member, {
        company,
        teams,
        controlRules,
        policies,
        subscriptionCount,
        invites,
        membersCostCenter,
        cardsAccess,
        canConfirmPayments,
      });
    },
  });
};

const useMemberQueryRaw = (memberId: string): QueryState<RawMember> => {
  return useQuery<RawMember, RawMember>({
    key: ['users', memberId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users/${memberId}`,
    },
  });
};

export const useInvalidateMemberQuery = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const me = useUser();

  return (memberIds: string | string[] = []) => {
    queryClient.invalidateQueries(['users'], { exact: true });

    if (typeof memberIds === 'string') {
      memberIds = [memberIds];
    }

    for (const memberId of memberIds) {
      if (memberId === me.id) {
        dispatch(fetchMe());
      }
      queryClient.invalidateQueries(['users', memberId]);
    }
  };
};
