import { useInvalidateCostCentersQuery } from 'modules/budgets/apis';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useDeleteCostCenterQuery = (
  costCenterId: string,
): MutationState => {
  const invalidateCostCentersQuery = useInvalidateCostCentersQuery();

  return useMutation({
    request: {
      type: 'rest',
      method: 'delete',
      target: 'companyAPI',
      endpoint: `/cost-centers/${costCenterId}`,
    },
    options: {
      throwOnError: true,
      onSuccess(): void {
        invalidateCostCentersQuery();
      },
    },
  });
};
