import { type FormikErrors } from 'formik';
import uniq from 'lodash/uniq';

import { type TGlobalFunctionTyped as TFunctionTyped } from 'common/hooks/useTranslation';

export type FormValues = {
  value: string;
};

export const validateCustomFieldsModalForm = (
  values: FormValues,
  customFieldValues: string[],
  translator: TFunctionTyped,
) => {
  const errors: FormikErrors<FormValues> = {};
  if (!values.value.trim()) {
    errors.value = translator(
      'customFields.settings.modals.creation.validation',
    );
    return errors;
  }
  if (
    customFieldValues.length > 0 &&
    hasDuplicates([values.value, ...uniq(customFieldValues)])
  ) {
    errors.value = translator('customFields.creation.duplicateValues');
  }
  return errors;
};

const hasDuplicates = (values: string[]): boolean => {
  const seen = new Set<string>();
  for (const value of values) {
    const valueLowCase = value.toLowerCase();

    if (seen.has(valueLowCase)) {
      return true;
    }

    seen.add(valueLowCase);
  }
  return false;
};
