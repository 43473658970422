import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type FiltersState } from 'modules/bookkeep/payables/containers/PayablesFiltersContainer/hooks/useFiltersContext';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type RawResult, query } from './query';
import { getPayableCountQueryKey } from '../query-key-registry';
import { reshapeFilters } from '../useGetPayableList/reshaper';

export const useInvalidateGetPayableCountQueryCache = () => {
  const queryClient = useQueryClient();

  return async (companyId: string, filters?: FiltersState): Promise<void> => {
    await queryClient.invalidateQueries(
      getPayableCountQueryKey(companyId, filters),
    );
  };
};

export type PayableCountResponse = { totalCount: number };

export type PayableCountRawResponse = RawResult;

export type PayableCountVariables = { companyId: string } & FiltersState;

export const useGetPayableCount = (
  filters: FiltersState,
): QueryState<PayableCountResponse> => {
  const companyId = useCompanyId();

  const reshapedFilters = reshapeFilters(filters);

  const variables: PayableCountVariables = {
    companyId,
    ...reshapedFilters,
  };

  return useQuery<PayableCountResponse, RawResult>({
    key: getPayableCountQueryKey(companyId, reshapedFilters),
    request: {
      type: 'graphQL',
      target: 'v2',
      query,
      variables,
    },
    reshapeData: (data) => {
      return {
        totalCount: data.company.payables.totalCount,
      };
    },
  });
};
