import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export interface DebitCardMigrationInfo {
  subscriptionCardsExistToMigrate: boolean;
  plasticCardsExistToMigrate: boolean;
  isAdminAndCompanyhasPhysicalCardsToRecard: boolean;
}

export const useDebitCardMigrationInfoQuery =
  (): QueryState<DebitCardMigrationInfo> =>
    useQuery<DebitCardMigrationInfo>({
      key: 'debitCardMigrationInfo',
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: '/debit-card-migration-info',
      },
      reshapeData: (data) => {
        return {
          subscriptionCardsExistToMigrate: data.subscriptionCardsExistToMigrate,
          plasticCardsExistToMigrate: data.plasticCardsExistToMigrate,
          isAdminAndCompanyhasPhysicalCardsToRecard:
            data.isAdminAndCompanyhasPhysicalCardsToRecard,
        };
      },
    });
