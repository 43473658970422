import { connect } from 'react-redux';

import { getCompanyCurrency } from 'modules/company';
import { type AppState } from 'src/core/reducers';

import { withCheckBankStatementForm } from './withCheckBankStatementForm';
import { CheckBankStatementForm } from '../../components/CheckBankStatementForm';
import * as achSelectors from '../../redux/selectors';
import * as achThunks from '../../redux/thunks';

const mapStateToProps = (state: AppState) => ({
  currency: getCompanyCurrency(state),
  hasError: Boolean(achSelectors.getAchError(state)),
  isPendingAchSourceLate: achSelectors.isPendingAchSourceLate(state),
});

const mapDispatchToProps = {
  checkBankStatement: achThunks.checkBankStatement,
  getAchFundingSources: achThunks.getAchFundingSources,
};

export const CheckBankStatementFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCheckBankStatementForm()(CheckBankStatementForm));
