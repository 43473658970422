import { useBookkeepingSettings } from 'modules/bookkeep/apis/useBookkeepingSettings';
import {
  getIsPayableExported,
  type PayableState,
} from 'modules/bookkeep/payables/models/payable';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

export const useWasUnlockablePayable = (payable: {
  state: PayableState;
}): boolean => {
  const hasEditPayablesAfterExportFeature = useFeature(
    FEATURES.TMP_EDIT_PAYABLES_AFTER_EXPORT,
  );

  const bookkeepingSettingsQueryResult = useBookkeepingSettings({
    isEnabled: hasEditPayablesAfterExportFeature,
  });

  const bookkeepingSettings = unwrapQuery(bookkeepingSettingsQueryResult);

  const isPayableExported = getIsPayableExported(payable);

  if (
    !hasEditPayablesAfterExportFeature ||
    !bookkeepingSettings ||
    !isPayableExported
  ) {
    return false;
  }

  return (
    bookkeepingSettings.editPayablesAfterExport?.hasOptedIn === false &&
    bookkeepingSettings.editPayablesAfterExport?.wasOptedIn === true
  );
};
