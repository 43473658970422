import { Tag } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const VerificationErrorTag = ({ error }: { error: string }) => {
  const { t } = useTranslation('global');

  switch (error) {
    case 'noMatch':
      return (
        <Tag variant="alert">
          {t(
            'wiretransfer.confirmation.verificationOfBeneficiariesModal.errors.noMatch',
          )}
        </Tag>
      );
    case 'partialMatch':
      return (
        <Tag variant="warning">
          {t(
            'wiretransfer.confirmation.verificationOfBeneficiariesModal.errors.partialMatch',
          )}
        </Tag>
      );
    case 'unavailable':
      return (
        <Tag variant="warning">
          {t(
            'wiretransfer.confirmation.verificationOfBeneficiariesModal.errors.unavailable',
          )}
        </Tag>
      );
    case 'fullMatch':
      return (
        <Tag variant="success">
          {t(
            'wiretransfer.confirmation.verificationOfBeneficiariesModal.errors.fullMatch',
          )}
        </Tag>
      );
    default:
      return (
        <Tag variant="alert">
          {t('wiretransfer.confirmation.errorModal.unknownError')}
        </Tag>
      );
  }
};
