import React from 'react';

import { ModalError } from 'common/components/ModalError';
import { ModalLoader } from 'common/components/ModalLoader';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { routeFor, routes } from 'src/core/constants/routes';
import history from 'src/core/history';

import { MemberDeleteModal } from '../../components/MemberDeleteModal';
import { useDeleteMemberMutation, useMemberQuery } from '../../hooks';

type Props = {
  memberId: string;
  onClose: () => void;
};

export const MemberDeleteModalContainer = ({ memberId, onClose }: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const memberQueryState = useMemberQuery(memberId);
  const [deleteMember] = useDeleteMemberMutation(memberId);
  const company = useCompany();

  const getErrorMessage = (reason: string) => {
    switch (reason) {
      case 'has_pending_transfers':
        return t('members.deleteModal.userHasPendingTransfers');
      default:
        return t('members.deleteModal.deleteRequestFailed');
    }
  };

  const handleSubmit = async (transferringUserId: string | undefined) => {
    try {
      await deleteMember({ endpointParams: { transferringUserId } });
      pushNotif({
        type: NotificationType.Success,
        message: t('members.deleteModal.deleteRequestSuccess'),
      });
      history.push(
        routeFor(routes.COMPANY_MEMBERS.path, {
          company: company.id,
        }),
      );
    } catch (e) {
      pushNotif({
        type: NotificationType.Danger,
        message: getErrorMessage(e.data.reason),
      });
    }
  };

  return (
    <QuerySuspense
      queryState={memberQueryState}
      loading={<ModalLoader />}
      fallback={() => (
        <ModalError
          message={t('members.errorModal.fetchFailedMessage')}
          onClose={onClose}
          onRetry={() => history.go(0)}
        />
      )}
    >
      {(member) => (
        <MemberDeleteModal
          onCancel={onClose}
          onSubmit={handleSubmit}
          member={member}
          companyName={company.name}
        />
      )}
    </QuerySuspense>
  );
};
