export const ROLE_NAMES_TO_ASSIGN = [
  'Administrator',
  'Controller',
  'Requester',
];

export const ROLE_NAMES_TO_DISPLAY = [
  'Account owner',
  'Administrator',
  'Controller',
  'Requester',
];
