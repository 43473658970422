import { Button, Modal } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split';
import { useCreateCustomFieldMutation } from 'modules/custom-fields/containers/CustomFieldCreationModalContainer/useCreateCustomFieldMutation';
import { useDefaultCustomFieldPayload } from 'modules/custom-fields/containers/customField';
import { useUpdateCustomFieldMutation } from 'modules/custom-fields/hooks/useUpdateCustomFieldMutation';
import { type CustomField } from 'modules/custom-fields/models/customField';
import { convertValues } from 'modules/custom-fields/utils/convertValues';

import { validate } from './form';
import { type FormValues } from './types';
import { CustomFieldIsRequiredFormField } from '../../../ControlSettingsCustomFieldName/components/CustomFieldIsRequiredFormField';
import { CustomFieldNameFormField } from '../../../ControlSettingsCustomFieldName/components/CustomFieldNameFormField';
import { CustomFieldValuesFormField } from '../../../ControlSettingsCustomFieldValues/components/CustomFieldValuesFormField';

type Props = {
  customField?: CustomField;
  isOpen: boolean;
  mode: 'create' | 'edit' | undefined;
  onClose: () => void;
};

export const CustomFieldFormModal = ({
  customField,
  isOpen,
  mode,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif, successNotif } = useNotifications();

  const isEditing = mode === 'edit';

  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();
  const defaultCustomFieldPayload = useDefaultCustomFieldPayload();

  const [createCustomField] = useCreateCustomFieldMutation();
  const [updateCustomField] = useUpdateCustomFieldMutation(
    customField?.id || '',
  );

  const [hasSubmittedOnce, setHasSubmittedOnce] = useState<boolean>(false);

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik<FormValues>({
    initialValues: {
      name: customField ? customField.name : '',
      isRequired: customField ? customField.isRequired : true,
      customFieldValues: customField
        ? customField.values.map(({ name }) => name).join(', ')
        : '',
    },
    enableReinitialize: true,
    validate: (valuesToValidate) =>
      validate({
        values: valuesToValidate,
        t,
      }),
    validateOnChange: hasSubmittedOnce,
    onSubmit: async (newValues) => {
      try {
        if (mode === 'create') {
          await createCustomField({
            ...defaultCustomFieldPayload,
            ...newValues,
            values: convertValues(newValues.customFieldValues),
            isSplittable: isAnalyticalSplitActivated,
          });
          successNotif(t('customFields.creation.successRequest'));
        }

        if (mode === 'edit') {
          await updateCustomField({
            name: newValues.name,
            isRequired: newValues.isRequired,
            values: convertValues(newValues.customFieldValues),
          });
          successNotif(t('customFields.edition.successRequest'));
        }

        closeModal();
      } catch {
        dangerNotif(t('misc.errors.unknownError'));
      }
    },
  });

  const closeModal = () => {
    onClose();
    if (hasSubmittedOnce) {
      setHasSubmittedOnce(false);
    }
    resetForm();
  };

  const onSubmit = async () => {
    if (!hasSubmittedOnce) {
      setHasSubmittedOnce(true);
    }
    handleSubmit();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title={
        isEditing
          ? t('setupHub.customField.modal.editTitle')
          : t('setupHub.customField.modal.createTitle')
      }
      iconName="plus"
      actions={[
        <Button
          key="cancel"
          onClick={closeModal}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="submit"
          iconName="caret-right"
          iconPosition="right"
          onClick={onSubmit}
          text={t('misc.continue')}
        />,
      ]}
    >
      <form className="flex flex-col gap-m text-left" onSubmit={onSubmit}>
        <CustomFieldNameFormField
          error={errors.name}
          name={values.name}
          onChange={handleChange}
        />
        <CustomFieldIsRequiredFormField
          isRequired={values.isRequired}
          onChange={(isRequired) => {
            setFieldValue('isRequired', isRequired);
          }}
        />
        <CustomFieldValuesFormField
          onChange={handleChange}
          showErrors={hasSubmittedOnce}
          values={values.customFieldValues}
        />
      </form>
    </Modal>
  );
};
