import {
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeaderWithIcon,
  ModalOverlay,
} from '@dev-spendesk/grapes';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useDisconnectKomboIntegrationMutation } from 'src/core/modules/integrations/hooks';
import { type IntegrationId } from 'src/core/modules/integrations/types';

type Props = {
  integrationId: IntegrationId;
  isOpen: boolean;
};

export const SyncInProgressModal = ({ integrationId, isOpen }: Props) => {
  const { t } = useTranslation('global');
  const [disconnectIntegration] =
    useDisconnectKomboIntegrationMutation(integrationId);
  const [shouldDisplayWarning, setShouldDisplayWarning] =
    useState<boolean>(false);

  return (
    <>
      <ModalOverlay isOpen={isOpen}>
        <ModalContent>
          {shouldDisplayWarning ? (
            <ModalHeaderWithIcon
              title={t(
                'integration.kombo.settings.syncInProgressModal.warning.title',
              )}
              iconName="warning"
              iconVariant="warning"
            />
          ) : (
            <ModalHeaderWithIcon
              title={t(
                'integration.kombo.settings.syncInProgressModal.initial.title',
              )}
              iconName="sync"
              iconVariant="primary"
            />
          )}
          <ModalBody className="m-xs px-s">
            {shouldDisplayWarning ? (
              <Trans i18nKey="integration.kombo.settings.syncInProgressModal.warning.description" />
            ) : (
              <Trans i18nKey="integration.kombo.settings.syncInProgressModal.initial.description" />
            )}
          </ModalBody>
          <ModalFooter>
            {shouldDisplayWarning ? (
              <>
                <Button
                  text={t(
                    'integration.kombo.settings.syncInProgressModal.warning.continue',
                  )}
                  variant="secondary"
                  onClick={() => setShouldDisplayWarning(false)}
                />
                <Button
                  text={t(
                    'integration.kombo.settings.syncInProgressModal.warning.disconnect',
                  )}
                  variant="warning"
                  onClick={() => disconnectIntegration({ silent: false })}
                />
              </>
            ) : (
              <Button
                text={t(
                  'integration.kombo.settings.syncInProgressModal.initial.cancel',
                )}
                variant="secondary"
                onClick={() => setShouldDisplayWarning(true)}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </>
  );
};
