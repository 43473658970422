import React, { useState } from 'react';

import {
  type ExpenseAccount,
  type ExpenseAccountUpdate,
} from 'modules/bookkeep/settings/accounting';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import {
  ExpenseAccountLocalOnlySection,
  ExpenseAccountLocalOnlyWithDefaultAccountsSection,
} from './ExpenseAccountLocalOnlySection';
import { ExpenseAccountPullSection } from './ExpenseAccountPullSection';
import {
  type ExpenseAccountsCapability,
  type IntegrationStatusWithIntegration,
} from '../../../../../../integration/status';
import { useExpenseAccountsQuery } from '../../../../hooks/useExpenseAccountsQuery';
import { useGetDefaultExpenseAccountQuery } from '../../../../hooks/useGetDefaultExpenseAccountQuery';
import { useSetDefaultExpenseAccountMutation } from '../../../../hooks/useSetDefaultExpenseAccountMutation';
import { useSetExpenseAccountMutation } from '../../../../hooks/useSetExpenseAccountMutation';

interface Props {
  integrationStatus: IntegrationStatusWithIntegration;
  expenseAccountsCapability: ExpenseAccountsCapability;
}

export const ExpenseAccountsSection = ({
  integrationStatus,
  expenseAccountsCapability,
}: Props) => {
  const [search, setSearch] = useState('');
  const expenseAccountQueryState = useExpenseAccountsQuery(search);
  const getDefaultExpenseAccountQueryState = useGetDefaultExpenseAccountQuery({
    isEnabled: expenseAccountsCapability === 'localOnlyWithDefaultAccounts',
  });
  const [setDefaultExpenseAccount, setDefaultExpenseAccountQueryState] =
    useSetDefaultExpenseAccountMutation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [setExpenseAccount, __, reset] = useSetExpenseAccountMutation();

  const handleChangeDefaultExpenseAccount = async (
    expenseAccount: ExpenseAccountUpdate,
  ) => {
    await setDefaultExpenseAccount(expenseAccount);
  };

  const handleDeleteExpenseAccount = async ({
    id,
    code,
    name,
  }: ExpenseAccount) => {
    await setExpenseAccount({
      id,
      code,
      name,
      isArchived: true,
    });
  };

  const handleToggleAvailabilityExpenseAccount = async ({
    id,
    code,
    name,
    isArchived,
    isAvailable,
  }: ExpenseAccount) => {
    await setExpenseAccount({
      id,
      code,
      name,
      isArchived,
      isAvailable: !isAvailable,
    });
  };

  switch (expenseAccountsCapability) {
    case 'pull': {
      return (
        <ExpenseAccountPullSection
          expenseAccountQueryState={expenseAccountQueryState}
          integration={integrationStatus.integration}
          setSearch={setSearch}
          search={search}
          handleToggleAvailabilityExpenseAccount={
            handleToggleAvailabilityExpenseAccount
          }
        />
      );
    }

    case 'localOnlyWithDefaultAccounts':
      return (
        <ExpenseAccountLocalOnlyWithDefaultAccountsSection
          getDefaultExpenseAccountQueryState={
            getDefaultExpenseAccountQueryState
          }
          onDefaultChange={handleChangeDefaultExpenseAccount}
          setDefaultExpenseAccountQueryState={
            setDefaultExpenseAccountQueryState
          }
          expenseAccounts={
            expenseAccountQueryState.status === 'success'
              ? expenseAccountQueryState.data
              : []
          }
          hasNextPage={expenseAccountQueryState.hasNextPage}
          fetchNextPage={expenseAccountQueryState.fetchNextPage}
          clearErrors={reset}
          onDelete={handleDeleteExpenseAccount}
          integrationStatus={integrationStatus}
          isLoading={expenseAccountQueryState.status === 'loading'}
        />
      );

    case 'localOnly':
      return (
        <ExpenseAccountLocalOnlySection
          expenseAccounts={
            expenseAccountQueryState.status === 'success'
              ? expenseAccountQueryState.data
              : []
          }
          hasNextPage={expenseAccountQueryState.hasNextPage}
          fetchNextPage={expenseAccountQueryState.fetchNextPage}
          clearErrors={reset}
          onDelete={handleDeleteExpenseAccount}
          integrationStatus={integrationStatus}
          isLoading={expenseAccountQueryState.status === 'loading'}
        />
      );
    case 'push':
      return <>TO DO: push</>;
    default:
      rejectUnexpectedValue(
        'expenseAccountsCapability',
        expenseAccountsCapability,
      );
  }
};
