import {
  Button,
  DropdownItem,
  FormField,
  Modal,
  Tag,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React, { useMemo } from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { CountryAutocomplete } from 'src/core/common/components/CountryAutocomplete';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { isSupportedCountry } from 'src/core/config/country';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';

import styles from './CountryAndCurrencyStep.module.css';
import { getCurrencyOptions } from './helpers';
import { type NewBranchEntityModalForm } from '../../form';

type Props = {
  onCancel: () => void;
  onClose: () => void;
} & Pick<
  FormikProps<NewBranchEntityModalForm>,
  'values' | 'setFieldValue' | 'isSubmitting' | 'handleSubmit'
>;

export const CountryAndCurrencyStep = ({
  onCancel,
  onClose,
  values,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();
  const canCreateExpenseEntity =
    company.banking_provider !== 'bankable' &&
    company.type !== 'branch_currency';

  const currencyOptions = useMemo(
    () =>
      getCurrencyOptions({
        bankingProvider: company.banking_provider,
        forceAllCurrencies:
          canCreateExpenseEntity && !isSupportedCountry(values.country),
      }),
    [company.banking_provider, values.country],
  );

  return (
    <Modal
      isOpen
      iconName={canCreateExpenseEntity ? 'info' : 'location-pin'}
      iconVariant={canCreateExpenseEntity ? 'info' : 'primary'}
      title={
        canCreateExpenseEntity
          ? t('branch.countryAndCurrencyStep.title')
          : t('branch.preKycModalTitle')
      }
      subtitle={
        canCreateExpenseEntity
          ? t('branch.countryAndCurrencyStep.subtitle')
          : ''
      }
      onClose={onClose}
      actions={[
        <Button
          key="cancel"
          onClick={onCancel}
          text={t('misc.back')}
          variant="secondary"
        />,
        <Button
          key="confirm"
          type="submit"
          form="currency-and-country"
          isLoading={isSubmitting}
          isDisabled={!values.country || !values.currency}
          text={t('misc.continue')}
          iconName="caret-right"
          iconPosition="right"
        />,
      ]}
    >
      <form id="currency-and-country" onSubmit={handleSubmit}>
        <div className={styles.container}>
          <FormField label={t('branch.companyCountry')}>
            <CountryAutocomplete
              name="country"
              fit="parent"
              countryCode={values.country}
              placeholder={t('generalSettings.address.countryPlaceholder')}
              onSelect={(selectedKey: string | undefined) => {
                setFieldValue('country', selectedKey || '');
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setFieldValue('country', '');
                }
              }}
            />
          </FormField>

          <FormField label={t('branch.companyCurrency')}>
            <AutocompleteSearch
              name="currency"
              placeholder={t('branch.companyCurrencyPlaceholder')}
              fit="parent"
              value={currencyOptions.find(
                (option) => option.key === values.currency,
              )}
              options={currencyOptions}
              renderOption={(option, state) => (
                <DropdownItem
                  {...state}
                  label={option.label}
                  suffix={
                    option.disabled ? (
                      <Tag variant="info">{t('branch.comingSoon')}</Tag>
                    ) : undefined
                  }
                  isDisabled={option.disabled}
                />
              )}
              renderNoOptions={(rawValue) => (
                <AutocompleteNoOptions value={rawValue} />
              )}
              onSelect={(value) => setFieldValue('currency', value?.key)}
            />
          </FormField>
        </div>
      </form>
    </Modal>
  );
};
