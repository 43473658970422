import { add, type MonetaryValue } from 'ezmoney';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { LegacySubscriptionItem } from './LegacySubscriptionItem';
import SubscriptionUsageItem from './LegacySubscriptionUsageItem';
import {
  formatAddonName,
  formatAddonPrice,
  type ADDON_NAMES,
} from '../helpers';

import './LegacySubscriptionAddonItem.css';

// Map addon product name to usage unit name
const addonUsageUnitMapper = new Map([
  ['supplementary_wallets', 'branches'],
  ['ereceipts', 'ereceipts'],
  ['ereceipt_users', 'active_users'],
  ['card_insurance', 'active_users'],
]);

const getPriceFromUsagePriceItem = (usagePriceItem: {
  base: MonetaryValue;
  factor: MonetaryValue;
}) => {
  return add(usagePriceItem.base, usagePriceItem.factor);
};

type Props = {
  name: keyof typeof ADDON_NAMES;
  price: {
    constant: MonetaryValue;
    usage: Record<string, MonetaryValue[]>;
  };
};

const LegacySubscriptionAddonItem = ({ name, price }: Props) => {
  const { t } = useTranslation('global');
  const addonName = formatAddonName({ name, t });
  const usageUnit = addonUsageUnitMapper.get(name);
  // @ts-expect-error code works,usageUnit is always defined and part of the map
  const firstUsagePrice = price.usage[usageUnit]?.[0];
  // If constant price is free, use the first usage price item as addon price
  const addonPrice =
    price.constant.amount === 0 && firstUsagePrice
      ? getPriceFromUsagePriceItem(firstUsagePrice)
      : price.constant;
  // If constant price is free, remove the first usage price item from usage pricing (already as for addon price)
  const addonUsagePrice =
    price.constant.amount === 0 && firstUsagePrice
      ? // @ts-expect-error code works,usageUnit is always defined and part of the map
        (price.usage[usageUnit] ?? []).slice(
          1,
          // @ts-expect-error code works,usageUnit is always defined and part of the map
          (price.usage[usageUnit] ?? []).length,
        )
      : // @ts-expect-error code works,usageUnit is always defined and part of the map
        price.usage[usageUnit];
  const formattedAddonPrice = formatAddonPrice({
    unit: name,
    price: addonPrice,
    t,
  });

  return (
    <div className="LegacySubscriptionAddonItem">
      <LegacySubscriptionItem
        base={addonName}
        extra={[formattedAddonPrice]}
        isAddon
      />
      {addonUsagePrice && (
        <SubscriptionUsageItem key={name} unit={name} usage={addonUsagePrice} />
      )}
    </div>
  );
};

export default LegacySubscriptionAddonItem;
