import { gql } from 'graphql-tag';

export const MARK_PAYABLE_AS_PREPARED = gql`
  mutation MarkPayableAsPrepared($payableId: ID!, $payableVersion: Int!) {
    markPayableAsPrepared(
      payableId: $payableId
      payableVersion: $payableVersion
    ) {
      __typename
      ... on MarkPayablesAsPreparedResultNotPrepared {
        reason
      }
    }
  }
`;
