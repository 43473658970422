import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

interface DefaultAccountBase {
  code: string;
  generalAccountCode?: string;
  isArchived?: boolean;
}

interface Props<TAccount extends DefaultAccountBase> {
  integration: AccountingSoftware;
  isOpen: boolean;
  setModalState: Dispatch<
    SetStateAction<{ kind: 'closed' | 'confirmEdit' | 'confirmDelete' }>
  >;
  onSetDefault: (defaultAccount: TAccount) => Promise<void>;
  defaultAccount: TAccount;
  actionOrigin:
    | 'defaultEmployeeAccountForm'
    | 'defaultSupplierAccountForm'
    | 'defaultExpenseAccountForm'
    | 'defaultTaxAccountForm';
}

export const DefaultAccountDeleteConfirmationModal = <
  TAccount extends DefaultAccountBase,
>({
  integration,
  isOpen,
  setModalState,
  onSetDefault,
  defaultAccount,
  actionOrigin,
}: Props<TAccount>) => {
  const { t } = useTranslation('global');

  const getModalTitleTranslationKey = () => {
    switch (actionOrigin) {
      case 'defaultEmployeeAccountForm':
        return 'bookkeep.integrations.settings.defaultEmployeeAccountForm.confirmDeleteDefaultAccountTitle';
      case 'defaultSupplierAccountForm':
        return 'bookkeep.integrations.settings.defaultSupplierAccountForm.confirmDeleteDefaultAccountTitle';
      case 'defaultExpenseAccountForm':
        return 'bookkeep.integrations.settings.defaultExpenseAccountForm.confirmDeleteDefaultAccountTitle';
      case 'defaultTaxAccountForm':
        return 'bookkeep.integrations.settings.defaultTaxAccountForm.confirmDeleteDefaultAccountTitle';
      default:
        throw new Error(`Unexpected action origin ${actionOrigin}`);
    }
  };
  const modalTitle = t(getModalTitleTranslationKey(), {
    code: defaultAccount ? defaultAccount.code : '',
  });

  const getModalSubtitleTranslationKey = () => {
    switch (actionOrigin) {
      case 'defaultEmployeeAccountForm':
        return 'bookkeep.integrations.settings.defaultEmployeeAccountForm.confirmDeleteDefaultAccountSubtitle';
      case 'defaultSupplierAccountForm':
        return 'bookkeep.integrations.settings.defaultSupplierAccountForm.confirmDeleteDefaultAccountSubtitle';
      case 'defaultExpenseAccountForm':
        return 'bookkeep.integrations.settings.defaultExpenseAccountForm.confirmDeleteDefaultAccountSubtitle';
      case 'defaultTaxAccountForm':
        return 'bookkeep.integrations.settings.defaultTaxAccountForm.confirmDeleteDefaultAccountSubtitle';
      default:
        throw new Error(`Unexpected action origin ${actionOrigin}`);
    }
  };
  const modalSubtitle = hasIntegrationFileBasedExport(integration)
    ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
    : t(getModalSubtitleTranslationKey());

  return (
    <Modal
      title={modalTitle}
      subtitle={modalSubtitle}
      isOpen={isOpen}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          autoFocus
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          onClick={() => {
            onSetDefault({
              ...defaultAccount,
              isArchived: true,
            });
            setModalState({ kind: 'closed' });
          }}
          text={t('misc.delete')}
          variant="alert"
        />,
      ]}
    />
  );
};
