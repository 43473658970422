import { connect } from 'react-redux';

import {
  fetchAllTemplates,
  getBases,
  getCustomExportsWithDefault,
  getHasLoaded,
  deleteTemplate,
} from 'modules/bookkeep/settings/export';
import { type AppState } from 'src/core/reducers';

import { CustomExports } from './CustomExports';

const mapStateToProps = (state: AppState) => ({
  bases: getBases(state),
  customExports: getCustomExportsWithDefault(state),
  hasLoaded: getHasLoaded(state),
});

const mapDispatchToProps = {
  fetchAllTemplates,
  deleteTemplate,
};

export const CustomExportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomExports);
