import { type PaymentMethod } from 'modules/company';

import {
  type UserScheduledPayments,
  type UserRequests,
  type ScheduledPaymentRequest,
  type ScheduledPayment,
  PaymentInfoStatus,
  ScheduledPaymentsBatchStatus,
  type ScheduledPaymentsBatch,
  type ScheduledPaymentsBatchWithScheduledPayments,
} from './types';
import { formatExecutionDate } from './utils';

export const toUserRequests = (
  userScheduledPayments: UserScheduledPayments,
): UserRequests => {
  return {
    user: userScheduledPayments.user,
    amount: userScheduledPayments.amount,
    requests: userScheduledPayments.scheduledPayments.reduce(
      (
        accumulator: ScheduledPaymentRequest[],
        scheduledPayment: ScheduledPayment,
      ) => accumulator.concat(scheduledPayment.requests),
      [],
    ),
  };
};

export const isScheduledPaymentCancellableByStatus = (
  status: PaymentInfoStatus,
) => [PaymentInfoStatus.Approved, PaymentInfoStatus.Pending].includes(status);

export const isScheduledPaymentsBatchCancellableByStatus = (
  status: ScheduledPaymentsBatchStatus,
) => status === ScheduledPaymentsBatchStatus.Pending;

export const scheduledPaymentsBatchWithScheduledPaymentsToScheduledPaymentsBatch =
  (
    batch: ScheduledPaymentsBatchWithScheduledPayments,
  ): ScheduledPaymentsBatch => {
    return {
      id: batch.id,
      amount: batch.amount,
      paymentMethod: batch.paymentMethod,
      batchedAt: batch.batchedAt,
      batchedBy: batch.batchedBy,
      scheduledPaymentsCount: batch.scheduledPayments.length,
      status: batch.status,
      exportMethods: batch.exportMethods,
    };
  };

export const parseBatchDate = (dateString: string) => {
  const [year, month] = dateString.split('-');
  return new Date(Date.UTC(Number.parseInt(year), Number.parseInt(month) - 1));
};

export const formatBatchDate = (date: Date, locale: string = 'en') =>
  date.toLocaleDateString(locale, { month: 'long', year: 'numeric' });

export const buildSchedulePaymentsPayload = (payload: {
  scheduledPayments: ScheduledPayment[];
  executionDate: Date;
  paymentMethod: PaymentMethod;
}) => {
  return {
    pay: payload.scheduledPayments.map((scheduledPayment) => ({
      billId: scheduledPayment.bill?.id,
      amount: scheduledPayment.amount,
      executeOn: formatExecutionDate(payload.executionDate),
    })),
    paymentMethod: payload.paymentMethod,
  };
};
