import { type I18nKey } from 'src/core/common/hooks/useTranslation';

import { PayableType } from '../types';

export const payableTypeToTranslationKey = (
  payableType: PayableType,
): I18nKey => {
  switch (payableType) {
    case PayableType.cardPurchase:
      return 'bookkeep.payableType.cardPurchase';
    case PayableType.cardReversal:
      return 'bookkeep.payableType.cardReversal';
    case PayableType.invoicePurchase:
      return 'bookkeep.payableType.invoicePurchase';
    case PayableType.creditNote:
      return 'bookkeep.payableType.creditNote';
    case PayableType.claimedBill:
      return 'bookkeep.payableType.claimedBill';
    case PayableType.reverseBill:
      return 'bookkeep.payableType.reverseBill';
    case PayableType.mileageExpense:
      return 'bookkeep.payableType.mileageExpense';
    case PayableType.perDiemExpense:
      return 'bookkeep.payableType.perDiemExpense';
    default:
      return payableType;
  }
};
