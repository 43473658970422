import { SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { CompanyBankInformation } from 'common/components/CompanyBankInformation';
import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { useAccountsQuery } from 'modules/app/hooks/useAccountQuery';
import { useCompany } from 'modules/app/hooks/useCompany';
import { HubTaskWrapper } from 'modules/onboarding/components/HubTaskWrapper';
import { MarkAsDoneButton } from 'modules/onboarding/components/MarkAsDoneButton';
import { type HubTask } from 'modules/onboarding/types/hubTask';

type Props = {
  task: HubTask;
};

export const WalletFundingTask = ({ task }: Props) => {
  const { t } = useTranslation('global');
  const accountsQuery = useAccountsQuery(task.status !== 'disabled');
  const { country } = useCompany();

  return (
    <HubTaskWrapper
      title={t('onboardingHub.walletFundingTask.subtitle')}
      description={t('onboardingHub.walletFundingTask.description')}
      disabledMessage={t('onboardingHub.misc.disabledMessage')}
      task={task}
      duration="5"
      actions={[
        <MarkAsDoneButton
          task={task}
          key="mark-as-done"
          text={t('onboardingHub.misc.markAsDone')}
        />,
      ]}
    >
      {task.status !== 'disabled' && (
        <>
          <QuerySuspense
            queryState={accountsQuery}
            loading={<SkeletonText width="80%" className="mt-s" />}
            fallback={(error) => (
              <QueryError
                componentType="ErrorState"
                queryError={error}
                translations={{
                  networkError: 'misc.errors.networkError',
                  serverError: 'misc.errors.serverError',
                  requestError: () => 'misc.errors.networkError',
                }}
              />
            )}
          >
            {(account) => (
              <div className="mt-xs">
                <CompanyBankInformation
                  account={account}
                  country={country}
                  displayTitleAndFooter={false}
                />
                <p className="mt-s text-neutral-dark">
                  {t('onboardingHub.walletFundingTask.sepaAvailability')}
                </p>
              </div>
            )}
          </QuerySuspense>
        </>
      )}
    </HubTaskWrapper>
  );
};
