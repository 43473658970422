import type {
  Allocation,
  SpendingAllocationPeriod,
  CustomField,
  DocumentaryEvidence,
  ItemLine,
  Member,
  MileageDetails,
  PerDiem,
  Supplier,
  Team,
} from './payable';
import type {
  RawAllocation,
  RawSpendingAllocationPeriod,
  RawCounterparty,
  RawCustomField,
  RawDocumentaryEvidence,
  RawDocumentaryEvidenceCannotBeProvided,
  RawItemLine,
  RawMember,
  RawMileageDetails,
  RawPayableState,
  RawPayableType,
  RawPerDiem,
  RawSupplier,
  RawTeam,
  RawCreditNoteDetails,
} from './query';
import type { PayableState, PayableType } from '../../../../models/payable';

export const reshapeMember = (rawMember: RawMember): Member => {
  if (rawMember.__typename === 'User') {
    return {
      type: 'user',
      id: rawMember.id,
      givenName: rawMember.givenName,
      familyName: rawMember.familyName,
      email: rawMember.email,
      avatar: rawMember.avatar,
    };
  }
  if (rawMember.__typename === 'SuspendedUser') {
    return {
      type: 'suspendedUser',
      id: rawMember.id,
      givenName: rawMember.givenName,
      familyName: rawMember.familyName,
      avatar: undefined,
    };
  }
  return {
    type: 'invitee',
    id: rawMember.id,
    email: rawMember.email,
  };
};

export const reshapeSupplier = (rawSupplier: RawSupplier): Supplier => {
  return {
    id: rawSupplier.id,
    name: rawSupplier.name,
    thumbnailUrl: rawSupplier.thumbnailUrl,
  };
};

export const reshapeTeam = (rawTeam: RawTeam): Team => {
  return {
    id: rawTeam.id,
    name: rawTeam.name,
  };
};

export const reshapeCounterparty = (
  rawCounterparty: RawCounterparty,
): Member | (Supplier & { type: 'supplier' }) => {
  if (rawCounterparty.__typename === 'Supplier') {
    return { type: 'supplier', ...reshapeSupplier(rawCounterparty) };
  }
  return reshapeMember(rawCounterparty);
};

export const reshapeDocumentaryEvidence = (
  rawDocumentaryEvidence: RawDocumentaryEvidence,
): DocumentaryEvidence => {
  const commonProperties = {
    validity: {
      isValid: rawDocumentaryEvidence.validity.valid,
      reason: rawDocumentaryEvidence.validity.reason,
    },
    compliances: rawDocumentaryEvidence.compliances,
    attachments: rawDocumentaryEvidence.attachments.map((attachment) => ({
      mediaType: attachment.mediaType,
      url: attachment.url,
      path: attachment.path,
      downloadUrl: attachment.downloadUrl,
      thumbnailUrl: attachment.thumbnailUrl,
      attachedAt: attachment.attachedAt
        ? new Date(attachment.attachedAt)
        : undefined,
      compliances: attachment.compliances,
    })),
  };

  if (rawDocumentaryEvidence.type === 'invoice') {
    return {
      ...commonProperties,
      type: 'invoice',
      invoiceNumber: rawDocumentaryEvidence.invoiceNumber,
    };
  }

  if (rawDocumentaryEvidence.type === 'creditNote') {
    return {
      ...commonProperties,
      type: 'creditNote',
      creditNoteNumber: rawDocumentaryEvidence.creditNoteNumber,
    };
  }

  return {
    ...commonProperties,
    type: rawDocumentaryEvidence.type,
  };
};

export const reshapeDocumentaryEvidenceCannotBeProvided = (
  documentaryEvidenceCannotBeProvided: RawDocumentaryEvidenceCannotBeProvided,
): {
  reportMethod: 'manual' | 'automatic';
  reason: string;
  reporterGivenName: string | undefined;
} => {
  return {
    reportMethod: documentaryEvidenceCannotBeProvided.reportMethod ?? 'manual',
    reason: documentaryEvidenceCannotBeProvided.reason,
    reporterGivenName: documentaryEvidenceCannotBeProvided.reporter?.givenName,
  };
};

export const reshapeItemLine = (rawItemLine: RawItemLine): ItemLine => {
  return {
    grossAmount: rawItemLine.grossAmount,
    netAmount: rawItemLine.netAmount,
    taxAmount: rawItemLine.taxAmount,
    analyticalFieldAssociations: rawItemLine.analyticalFieldAssociations || [],
    taxAccount: rawItemLine.taxAccount
      ? {
          id: rawItemLine.taxAccount.id,
          name: rawItemLine.taxAccount.name,
          isArchived: rawItemLine.taxAccount.isArchived,
          rate:
            typeof rawItemLine.taxAccount.rate === 'string'
              ? Number(rawItemLine.taxAccount.rate)
              : rawItemLine.taxAccount.rate,
        }
      : undefined,
    expenseAccount: rawItemLine.expenseAccount ?? undefined,
  };
};

export const reshapeCustomField = (
  rawCustomField: RawCustomField,
): CustomField => {
  if (rawCustomField.__typename === 'CustomFieldListAssociation') {
    return {
      type: 'list',
      name: rawCustomField.customField.name,
      id: rawCustomField.customField.id,
      values: rawCustomField.values.map(({ value, id }) => ({
        id,
        name: value,
      })),
    };
  }
  return {
    type: 'boolean',
    id: rawCustomField.customField.id,
    name: rawCustomField.customField.name,
    value: rawCustomField.value,
  };
};

export const reshapeAllocation = (rawAllocation: RawAllocation): Allocation => {
  return {
    ...rawAllocation,
    settlement: rawAllocation.settlement
      ? {
          ...rawAllocation.settlement,
          transaction: {
            ...rawAllocation.settlement.transaction,
            clearingDate: new Date(
              rawAllocation.settlement.transaction.clearingDate,
            ),
          },
        }
      : undefined,
  };
};

export const reshapePayableType = (rawType: RawPayableType): PayableType => {
  switch (rawType) {
    case 'card_purchase':
      return 'cardPurchase';
    case 'invoice_purchase':
      return 'invoicePurchase';
    case 'expense_claim':
      return 'claimedBill';
    case 'mileage_allowance':
      return 'mileageAllowance';
    case 'per_diem_allowance':
      return 'perDiemAllowance';
    case 'reversal':
      return 'reversal';
    default:
      throw new Error(`Unknown payable type ${rawType}`);
  }
};

export const reshapePayableState = (rawType: RawPayableState): PayableState => {
  switch (rawType) {
    case 'created':
      return 'created';
    case 'prepared':
      return 'prepared';
    case 'notBookkept':
      return 'notBookkept';
    case 'unprepared':
      return 'unprepared';
    case 'discarded':
      return 'discarded';
    case 'to_accounting_pending':
      return 'toAccountingPending';
    case 'to_accounting_failed':
      return 'toAccountingFailed';
    case 'in_accounting':
      return 'inAccounting';
    case 'in_accounting_manually':
      return 'inAccountingManually';
    default:
      throw new Error(`Unknown payable type ${rawType}`);
  }
};

export const reshapeMileageDetails = (
  rawType: RawMileageDetails,
): MileageDetails => {
  return {
    ...rawType,
    departureAddress: rawType.departure.address,
    arrivalAddress: rawType.arrival.address,
    distance: `${rawType.distance.value}${rawType.distance.unit}`,
    travelDate: new Date(rawType.travelDate),
    vehicle: {
      ...rawType.vehicle,
      totalDistanceOverYear: `${rawType.vehicle.totalDistanceOverYear.value}${rawType.vehicle.totalDistanceOverYear.unit}`,
    },
    journey: rawType.journey.reduce((path: [number, number][], point) => {
      const { lng, lat } = point;
      path.push([lat, lng]);
      return path;
    }, []),
  };
};

export const reshapeCreditNoteDetails = (
  rawCreditNoteDetails: RawCreditNoteDetails,
) => {
  return {
    creditNoteNumber: rawCreditNoteDetails.creditNoteNumber,
    referenceInvoiceRequestNumber:
      rawCreditNoteDetails.referenceInvoiceRequestNumber || undefined,
    referenceInvoiceRequestId:
      rawCreditNoteDetails.referenceInvoiceRequestId || undefined,
    validationAction: rawCreditNoteDetails.validationAction,
    state: rawCreditNoteDetails.state,
  };
};

export const reshapePerDiem = (rawType: RawPerDiem): PerDiem => {
  return {
    duration: rawType.tripDays.length,
  };
};

export const reshapeAllocationPeriod = (
  rawAllocationPeriod: RawSpendingAllocationPeriod,
): SpendingAllocationPeriod => {
  return {
    period: {
      from: new Date(rawAllocationPeriod.period.from),
      to: new Date(rawAllocationPeriod.period.to),
    },
    date: rawAllocationPeriod.date
      ? new Date(rawAllocationPeriod.date)
      : undefined,
  };
};
