import { fromNumber, type MonetaryValue } from 'ezmoney';

import { type QueryState } from 'src/core/api/queryState';

import { usePurchaseOrderQuery } from '../detail/hooks';
import {
  type OverbillForecast,
  getPurchaseOrderOverbillForecast,
} from '../models';

type ResponseError = {
  errorCode: string;
};

export const usePurchaseOrderOverbillForecast = (purchaseOrderId: string) => {
  const purchaseOrderQueryState = usePurchaseOrderQuery(purchaseOrderId);

  return (
    newInvoiceAmount: MonetaryValue,
    oldInvoiceAmount?: MonetaryValue,
  ): QueryState<OverbillForecast, ResponseError> => {
    if (purchaseOrderQueryState.status !== 'success') {
      return purchaseOrderQueryState;
    }

    try {
      const overbillForecast = getPurchaseOrderOverbillForecast(
        purchaseOrderQueryState.data,
        newInvoiceAmount,
        oldInvoiceAmount ?? fromNumber(0, newInvoiceAmount.currency, 2),
      );
      return {
        ...purchaseOrderQueryState,
        data: overbillForecast,
      };
    } catch (e) {
      return {
        status: 'error',
        error: e,
      };
    }
  };
};
