import * as types from './actionTypes';

const initialState = {
  card: undefined,
  behavior: {
    hasError: false,
    isLoading: false,
  },
};

type ActionType = {
  type: keyof typeof types;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
};

export const reducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case types.FETCH_PLASTIC_CARD_LOADING:
      return {
        ...state,
        behavior: { isLoading: true, hasError: false },
      };
    case types.FETCH_PLASTIC_CARD_FAILURE:
      return {
        ...state,
        behavior: { isLoading: false, hasError: true },
      };
    case types.FETCH_PLASTIC_CARD_SUCCESS:
      return {
        ...state,
        card: action.payload.card,
        isLoading: false,
        hasError: false,
      };
    default:
      return state;
  }
};
