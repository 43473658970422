/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { combineReducers } from '@reduxjs/toolkit';
import { type CancelTokenSource } from 'axios';

import { createPaginatedReducer, type Pagination } from 'common/redux/paginate';
import {
  createPaginatedReducer as createCursorPaginatedReducer,
  type Pagination as CursorPagination,
} from 'common/redux/paginateCursor';
import { RequestState } from 'common/redux/requestState';
import { createSelectionReducer } from 'common/redux/selection';

import {
  type ExpenseClaimsActions,
  FETCH_EXPENSE_CLAIMS_COUNTS_REQUEST,
  FETCH_EXPENSE_CLAIMS_COUNTS_SUCCESS,
  FETCH_EXPENSE_CLAIMS_COUNTS_FAILURE,
  UPDATE_EXPENSE_CLAIMS_COUNTS,
  FETCH_USERS_SCHEDULED_PAYMENTS_REQUEST,
  FETCH_USERS_SCHEDULED_PAYMENTS_SUCCESS,
  FETCH_USERS_SCHEDULED_PAYMENTS_FAILURE,
  RESET_USERS_SCHEDULED_PAYMENTS,
  FETCH_USER_SCHEDULED_PAYMENTS_SUCCESS,
  SEND_TO_PAYMENT_REQUEST,
  SEND_TO_PAYMENT_SUCCESS,
  SEND_TO_PAYMENT_FAILURE,
  DOWNLOAD_CSV_REQUEST,
  DOWNLOAD_CSV_SUCCESS,
  DOWNLOAD_CSV_FAILURE,
  SEND_MISSING_BANK_INFO_REMINDERS_REQUEST,
  SEND_MISSING_BANK_INFO_REMINDERS_SUCCESS,
  SEND_MISSING_BANK_INFO_REMINDERS_FAILURE,
  RESET_OPENED_USER_SCHEDULED_PAYMENTS,
  TOGGLE_USER_SCHEDULED_PAYMENTS_SELECTION,
  FETCH_SCHEDULED_PAYMENTS_BATCHES_REQUEST,
  FETCH_SCHEDULED_PAYMENTS_BATCHES_SUCCESS,
  FETCH_SCHEDULED_PAYMENTS_BATCHES_FAILURE,
  CLEAR_SCHEDULED_PAYMENTS_BATCHES,
  REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REMOVE,
  REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_UPDATE,
  FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_REQUEST,
  FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_SUCCESS,
  FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_FAILURE,
  FETCH_SCHEDULED_PAYMENT_SUCCESS,
  RESET_OPENED_SCHEDULED_PAYMENT,
  CONFIRM_SCHEDULED_PAYMENTS_BATCH_REQUEST,
  CONFIRM_SCHEDULED_PAYMENTS_BATCH_SUCCESS,
  CONFIRM_SCHEDULED_PAYMENTS_BATCH_FAILURE,
  RESET_SCHEDULED_PAYMENTS_CONFIRMATION_AUTH_TYPE,
  CONFIRM_SCHEDULED_PAYMENT_REQUEST,
  CONFIRM_SCHEDULED_PAYMENT_SUCCESS,
  CONFIRM_SCHEDULED_PAYMENT_FAILURE,
  CANCEL_SCHEDULED_PAYMENT_REQUEST,
  CANCEL_SCHEDULED_PAYMENT_SUCCESS,
  CANCEL_SCHEDULED_PAYMENT_FAILURE,
} from './actionTypes';
import {
  type UserScheduledPayments,
  type ScheduledPaymentsBatch,
  type ScheduledPayment,
  USERS_SCHEDULED_PAYMENTS_LIST_ROOT_KEY,
  type ExpenseClaimsCounts,
} from '../types';

export type ExpenseClaimsCountsState = {
  requestState: RequestState;
  data: ExpenseClaimsCounts;
};

export type UsersScheduledPaymentsState = Pagination<UserScheduledPayments>;

export type OpenedUserScheduledPaymentsState = UserScheduledPayments | null;

export type SendToPaymentState = {
  isSendingToPayment: boolean;
  isDownloadingCsv: boolean;
  isSendingMissingBankInfoReminders: boolean;
};

export interface ScheduledPaymentsBatchesState
  extends CursorPagination<ScheduledPaymentsBatch> {
  cancelToken: CancelTokenSource | undefined;
}

export type ScheduledPaymentsByBatchIdState = {
  [key: string]: {
    hasFetched: boolean;
    isFetching: boolean;
    count: number;
    items: ScheduledPayment[];
  };
};

export type OpenedScheduledPaymentState = ScheduledPayment | null;

export type ConfirmScheduledPaymentsState = {
  isLoading: boolean;
  authType: 'mfa' | 'sca' | null;
};

export type CancelScheduledPaymentsState = {
  isLoading: boolean;
};

export const expenseClaimsCountsInitialState: ExpenseClaimsCountsState = {
  requestState: RequestState.NotAsked,
  data: {
    expensesToValidate: undefined,
    paymentsToConfirm: undefined,
    usersToPay: undefined,
  },
};

export const expenseClaimsCountsReducer = (
  state: ExpenseClaimsCountsState = expenseClaimsCountsInitialState,
  action: ExpenseClaimsActions,
): ExpenseClaimsCountsState => {
  switch (action.type) {
    case FETCH_EXPENSE_CLAIMS_COUNTS_REQUEST:
      return {
        ...state,
        requestState: RequestState.Loading,
      };
    case FETCH_EXPENSE_CLAIMS_COUNTS_FAILURE:
      return {
        requestState: RequestState.Failure,
        data: {
          expensesToValidate: undefined,
          paymentsToConfirm: undefined,
          usersToPay: undefined,
        },
      };
    case FETCH_EXPENSE_CLAIMS_COUNTS_SUCCESS:
      return {
        requestState: RequestState.Success,
        data: action.payload,
      };
    case UPDATE_EXPENSE_CLAIMS_COUNTS: {
      if (state.requestState !== RequestState.Success) {
        return state;
      }

      const { expensesToValidate, paymentsToConfirm, usersToPay } =
        action.payload;
      return {
        ...state,
        data: {
          expensesToValidate:
            state.data.expensesToValidate! + expensesToValidate!,
          paymentsToConfirm: state.data.paymentsToConfirm! + paymentsToConfirm!,
          usersToPay: state.data.usersToPay! + usersToPay!,
        },
      };
    }
    default:
      return state;
  }
};

const usersScheduledPaymentsInitialState: UsersScheduledPaymentsState = {
  offset: 0,
  limit: 20,
  totalCount: Number.POSITIVE_INFINITY,
  isFetching: false,
  items: [],
};

const usersScheduledPaymentsReducer = createPaginatedReducer<
  UserScheduledPayments,
  UsersScheduledPaymentsState['offset']
>(
  {
    request: FETCH_USERS_SCHEDULED_PAYMENTS_REQUEST,
    success: FETCH_USERS_SCHEDULED_PAYMENTS_SUCCESS,
    failure: FETCH_USERS_SCHEDULED_PAYMENTS_FAILURE,
    reset: RESET_USERS_SCHEDULED_PAYMENTS,
  },
  usersScheduledPaymentsInitialState,
);

export const openedUserScheduledPaymentsInitialState: OpenedUserScheduledPaymentsState =
  null;

export const openedUserScheduledPaymentsReducer = (
  state: OpenedUserScheduledPaymentsState = openedUserScheduledPaymentsInitialState,
  action: ExpenseClaimsActions,
): OpenedUserScheduledPaymentsState => {
  switch (action.type) {
    case FETCH_USER_SCHEDULED_PAYMENTS_SUCCESS:
      return action.payload;
    case RESET_OPENED_USER_SCHEDULED_PAYMENTS:
      return openedUserScheduledPaymentsInitialState;
    default:
      return state;
  }
};

const usersScheduledPaymentsSelectionReducer =
  createSelectionReducer<UserScheduledPayments>({
    rootRef: USERS_SCHEDULED_PAYMENTS_LIST_ROOT_KEY,
    actionTypes: {
      populate: FETCH_USERS_SCHEDULED_PAYMENTS_SUCCESS,
      toggle: TOGGLE_USER_SCHEDULED_PAYMENTS_SELECTION,
      reset: RESET_USERS_SCHEDULED_PAYMENTS,
    },
    accessors: {
      populate: (action): { items: UserScheduledPayments[] } => ({
        items: action.payload.items,
      }),
      toggle: (action): string => action.payload,
    },
    getItemRef: (item) => item.user.id,
  });

const sendToPaymentInitialState: SendToPaymentState = {
  isSendingToPayment: false,
  isDownloadingCsv: false,
  isSendingMissingBankInfoReminders: false,
};

const sendToPaymentReducer = (
  state: SendToPaymentState = sendToPaymentInitialState,
  action: ExpenseClaimsActions,
): SendToPaymentState => {
  switch (action.type) {
    case SEND_TO_PAYMENT_REQUEST:
      return {
        ...state,
        isSendingToPayment: true,
      };
    case SEND_TO_PAYMENT_SUCCESS:
    case SEND_TO_PAYMENT_FAILURE:
      return {
        ...state,
        isSendingToPayment: false,
      };
    case DOWNLOAD_CSV_REQUEST:
      return {
        ...state,
        isDownloadingCsv: true,
      };
    case DOWNLOAD_CSV_SUCCESS:
    case DOWNLOAD_CSV_FAILURE:
      return {
        ...state,
        isDownloadingCsv: false,
      };
    case SEND_MISSING_BANK_INFO_REMINDERS_REQUEST:
      return { ...state, isSendingMissingBankInfoReminders: true };
    case SEND_MISSING_BANK_INFO_REMINDERS_SUCCESS:
    case SEND_MISSING_BANK_INFO_REMINDERS_FAILURE:
      return { ...state, isSendingMissingBankInfoReminders: false };
    default:
      return state;
  }
};

const getScheduledPaymentsBatchesInitialState =
  (): ScheduledPaymentsBatchesState => ({
    limit: 20,
    nextCursor: null,
    requestState: RequestState.NotAsked,
    items: [],
    cancelToken: undefined,
  });

// exporting this for testing purpose
export const scheduledPaymentsBatchesCustomReducer = (
  state: ScheduledPaymentsBatchesState = getScheduledPaymentsBatchesInitialState(),
  action: ExpenseClaimsActions,
): ScheduledPaymentsBatchesState => {
  switch (action.type) {
    case FETCH_SCHEDULED_PAYMENTS_BATCHES_REQUEST:
      return {
        ...state,
        cancelToken: action.payload.cancelToken,
      };
    case FETCH_SCHEDULED_PAYMENTS_BATCHES_SUCCESS:
      return {
        ...state,
        cancelToken: undefined,
      };
    case FETCH_SCHEDULED_PAYMENTS_BATCHES_FAILURE:
      return {
        ...state,
        cancelToken: undefined,
      };
    case CLEAR_SCHEDULED_PAYMENTS_BATCHES:
      return {
        ...state,
        cancelToken: undefined,
      };
    case REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REMOVE:
      return {
        ...state,
        items: state.items.filter(
          (batch) => batch.id !== action.payload.batchId,
        ),
      };
    case REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_UPDATE:
      return {
        ...state,
        items: state.items.map((batch) => {
          return batch.id === action.payload.id ? action.payload : batch;
        }),
      };
    default:
      return state;
  }
};

const scheduledPaymentsBatchesReducer = createCursorPaginatedReducer<
  ScheduledPaymentsBatch,
  ScheduledPaymentsBatchesState,
  ExpenseClaimsActions
>(
  {
    request: FETCH_SCHEDULED_PAYMENTS_BATCHES_REQUEST,
    success: FETCH_SCHEDULED_PAYMENTS_BATCHES_SUCCESS,
    failure: FETCH_SCHEDULED_PAYMENTS_BATCHES_FAILURE,
    reset: CLEAR_SCHEDULED_PAYMENTS_BATCHES,
  },
  getScheduledPaymentsBatchesInitialState(),
  scheduledPaymentsBatchesCustomReducer,
);

const scheduledPaymentsByBatchIdInitialState: ScheduledPaymentsByBatchIdState =
  {};

export const scheduledPaymentsByBatchIdReducer = (
  state: ScheduledPaymentsByBatchIdState = scheduledPaymentsByBatchIdInitialState,
  action: ExpenseClaimsActions,
): ScheduledPaymentsByBatchIdState => {
  switch (action.type) {
    case FETCH_SCHEDULED_PAYMENTS_BATCHES_SUCCESS: {
      return action.payload.items.reduce(
        (nextState, scheduledPaymentsBatch) => ({
          ...nextState,
          [scheduledPaymentsBatch.id]: {
            hasFetched: false,
            isFetching: false,
            count: scheduledPaymentsBatch.scheduledPaymentsCount,
            items: [],
          },
        }),
        state,
      );
    }
    case FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_REQUEST: {
      const { batchId } = action.payload;

      return {
        ...state,
        [batchId]: {
          ...state[batchId],
          isFetching: true,
        },
      };
    }
    case FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_SUCCESS: {
      const { batchId, scheduledPayments } = action.payload;

      return {
        ...state,
        [batchId]: {
          ...state[batchId],
          hasFetched: true,
          isFetching: false,
          items: scheduledPayments,
        },
      };
    }
    case FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_FAILURE: {
      const { batchId } = action.payload;

      return {
        ...state,
        [batchId]: {
          ...state[batchId],
          isFetching: false,
        },
      };
    }
    default:
      return state;
  }
};

export const openedScheduledPaymentInitialState: OpenedScheduledPaymentState =
  null;

export const openedScheduledPaymentReducer = (
  state: OpenedScheduledPaymentState = openedScheduledPaymentInitialState,
  action: ExpenseClaimsActions,
): OpenedScheduledPaymentState => {
  switch (action.type) {
    case FETCH_SCHEDULED_PAYMENT_SUCCESS:
      return action.payload;
    case RESET_OPENED_SCHEDULED_PAYMENT:
      return openedScheduledPaymentInitialState;
    default:
      return state;
  }
};

const confirmScheduledPaymentsInitialState: ConfirmScheduledPaymentsState = {
  authType: null,
  isLoading: false,
};

export const confirmScheduledPaymentsReducer = (
  state: ConfirmScheduledPaymentsState = confirmScheduledPaymentsInitialState,
  action: ExpenseClaimsActions,
): ConfirmScheduledPaymentsState => {
  switch (action.type) {
    case CONFIRM_SCHEDULED_PAYMENTS_BATCH_REQUEST:
    case CONFIRM_SCHEDULED_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CONFIRM_SCHEDULED_PAYMENTS_BATCH_SUCCESS:
    case CONFIRM_SCHEDULED_PAYMENT_SUCCESS:
      return {
        ...state,
        authType: action.payload.authType,
        isLoading: false,
      };
    case CONFIRM_SCHEDULED_PAYMENTS_BATCH_FAILURE:
    case CONFIRM_SCHEDULED_PAYMENT_FAILURE:
    case RESET_SCHEDULED_PAYMENTS_CONFIRMATION_AUTH_TYPE:
      return {
        ...state,
        authType: null,
        isLoading: false,
      };
    default:
      return state;
  }
};

const cancelScheduledPaymentsInitialState: CancelScheduledPaymentsState = {
  isLoading: false,
};

export const cancelScheduledPaymentsReducer = (
  state: CancelScheduledPaymentsState = cancelScheduledPaymentsInitialState,
  action: ExpenseClaimsActions,
): CancelScheduledPaymentsState => {
  switch (action.type) {
    case CANCEL_SCHEDULED_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CANCEL_SCHEDULED_PAYMENT_SUCCESS:
    case CANCEL_SCHEDULED_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const cancelScheduledPaymentsBatchReducer = (
  state: CancelScheduledPaymentsState = cancelScheduledPaymentsInitialState,
  action: ExpenseClaimsActions,
): CancelScheduledPaymentsState => {
  switch (action.type) {
    case CANCEL_SCHEDULED_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CANCEL_SCHEDULED_PAYMENT_SUCCESS:
    case CANCEL_SCHEDULED_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  counts: expenseClaimsCountsReducer,
  usersScheduledPayments: usersScheduledPaymentsReducer,
  openedUserScheduledPayments: openedUserScheduledPaymentsReducer,
  usersScheduledPaymentsSelection: usersScheduledPaymentsSelectionReducer,
  sendToPayment: sendToPaymentReducer,
  scheduledPaymentsBatches: scheduledPaymentsBatchesReducer,
  scheduledPaymentsByBatchId: scheduledPaymentsByBatchIdReducer,
  openedScheduledPayment: openedScheduledPaymentReducer,
  confirmScheduledPayments: confirmScheduledPaymentsReducer,
  cancelScheduledPayments: cancelScheduledPaymentsReducer,
  cancelScheduledPaymentsBatch: cancelScheduledPaymentsBatchReducer,
});
