import {
  createAction,
  type ThunkDispatch,
  type AnyAction,
} from '@reduxjs/toolkit';

import { SET_FIRST_USER_PAYMENT_ID } from 'modules/payments/redux/actionTypes';
import { companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { graphql } from 'src/core/utils/api';

export const saveWhatsAValidReceiptPopupSeenState =
  () =>
  async (
    _dispatch: ThunkDispatch<AppState, null, AnyAction>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    await companyAPI.post(
      '/user/popup-seen',
      { code: 'WHATS_A_VALID_RECEIPT' },
      { companyId },
    );
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setFirstUserPaymentId = createAction<any>(SET_FIRST_USER_PAYMENT_ID);
export const fetchFirstUserPayment =
  (userId: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, AnyAction>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);
    const variables = {
      companyId,
      filters: [
        { type: 'status', value: 'settled' },
        {
          type: 'type',
          value: ['physical', 'single_purchase', 'subscription'],
        },
        { type: 'payer', value: userId },
      ],
    };

    const request = `
    query FetchFirstPayment($companyId: String!, $filters: [JSON]) {
      userFirstSettledPayment: company(id: $companyId) {
        payments(first: 1, orderBy: CREATED_DATE_ASC, filters_v2: $filters) {
          total,
          edges {
            node {
              databaseId
            }
          }
        }
      }
    }
  `;

    const { userFirstSettledPayment } = await graphql(request, variables);

    if (userFirstSettledPayment.payments.edges.length) {
      dispatch(
        setFirstUserPaymentId(
          userFirstSettledPayment.payments.edges[0].node.databaseId,
        ),
      );
    }
  };
