import { useCallback } from 'react';

import { useGoTo } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';

import type { PayableId } from '../../models';

export const useGoToPayableDetails = () => {
  const goTo = useGoTo();
  const companyId = useCompanyId();

  return useCallback(
    (payableId: PayableId) =>
      goTo('PAYABLES_ALL', { company: companyId, id: payableId }),
    [companyId],
  );
};
