import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { GET_EXPENSE_ACCOUNTS_WITH_ARCHIVED_FIELD } from '../queries/getExpenseAccounts';
import { type ExpenseAccount } from '../types';

export type ExpenseAccountRawData = {
  company: {
    chartOfAccounts: {
      expenseAccounts: {
        edges: {
          node: {
            id: string;
            name: string;
            isArchived: boolean;
            code: string;
          };
        }[];
      };
    } | null;
  };
};

export const reshapeExpenseAccountData = (
  rawData: ExpenseAccountRawData,
): ExpenseAccount[] => {
  // This can be null when no expense accounts have been setup.
  if (!rawData.company.chartOfAccounts?.expenseAccounts) {
    return [];
  }
  const { edges } = rawData.company.chartOfAccounts.expenseAccounts;

  return edges.map(({ node }) => ({
    id: node.id,
    name: node.name,
    isArchived: node.isArchived,
    code: node.code,
  }));
};

export const useGetExpenseAccountsQuery = (
  {
    includeArchived = true,
  }: {
    includeArchived?: boolean;
  } = { includeArchived: true },
): QueryState<ExpenseAccount[]> => {
  const { company: companyId } = useParams();

  return useQuery<ExpenseAccount[], ExpenseAccountRawData>({
    key: ['useGetExpenseAccountsQuery', companyId, includeArchived],
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_EXPENSE_ACCOUNTS_WITH_ARCHIVED_FIELD,
      variables: {
        filter: { includeArchived },
      },
    },
    reshapeData: reshapeExpenseAccountData,
  });
};
