// for now, we handle only 'attachment', but it can be updated to handle more formats
const contentDispositionFilenameExtractionRgx =
  /^attachment; filename="([^*]*)"$/;

export const getFilenameFromContentDispositionHeader = (
  contentDispositionHeader = '',
) => {
  const matches = contentDispositionFilenameExtractionRgx.exec(
    contentDispositionHeader,
  );
  return matches ? matches[1] : undefined;
};
