import {
  isApprovedCountry,
  isEeaMember,
  isGbCountry,
} from 'src/core/config/country';
import { type BankingProvider } from 'src/core/reducers/global';

export type CreatedEntity = {
  id: string;
  banking_provider: BankingProvider;
};

export const canCreateSfsBranchEntityWith = (
  country: string,
  currency: string,
) =>
  (isEeaMember(country) && currency === 'EUR') ||
  (currency === 'GBP' && isGbCountry(country));

export const canSfsCompanyCreateBranchEntity = (
  country: string,
  currency: string,
) => {
  const isMarqeta = country === 'US' && currency === 'USD';
  return isMarqeta || canCreateSfsBranchEntityWith(country, currency);
};

export const canCreateBranchEntityWith = ({
  bankingProvider,
  country,
  currency,
}: {
  bankingProvider: BankingProvider;
  country: string;
  currency: string;
}) => {
  if (bankingProvider === 'sfs') {
    return canSfsCompanyCreateBranchEntity(country, currency);
  }

  return isApprovedCountry(country);
};
