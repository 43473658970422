import {
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
} from 'date-fns';
import { type MonetaryValue, add, toNumber } from 'ezmoney';

import { type PeriodRange } from './period';

export type BudgetBreakdown = {
  used: MonetaryValue;
  usedExceeded: MonetaryValue;
  committed: MonetaryValue;
  committedExceeded: MonetaryValue;
  available: MonetaryValue;
};

export type Breakdown = BudgetBreakdown & {
  breakdownComputedAt: Date;
};

export type BreakdownPart = keyof Pick<BudgetBreakdown, 'used' | 'committed'>;

/**
 * Calculates the sum of used and committed amounts.
 * @param breakdown The budget breakdown.
 * @returns the sum of committed + used amounts.
 */
const getSpentInBudgetTotalAmount = ({
  committed,
  used,
}: BudgetBreakdown): MonetaryValue => add(committed, used);

/**
 * Calculates the budget limit.
 * @param breakdown The budget breakdown.
 * @returns the sum of `used` + `committed` + `available` amounts.
 */
export const getBudgetLimit = (breakdown: BudgetBreakdown): MonetaryValue =>
  add(getSpentInBudgetTotalAmount(breakdown), breakdown.available);

/**
 * Calculates the used amount.
 * @param breakdown The budget breakdown.
 * @param withExceeded Includes `usedExceeded` ?
 * @returns the sum of `used` + `usedExceeded` if `withExceeded` is truthy, `used` only otherwise
 */
export const getUsedAmount = (
  { used, usedExceeded }: BudgetBreakdown,
  withExceeded = true,
): MonetaryValue => (withExceeded ? add(used, usedExceeded) : used);

/**
 * Calculates the committed amount.
 * @param breakdown The budget breakdown.
 * @param withExceeded Includes `committedExceeded` ?
 * @returns the sum of `committed` + `committedExceeded` if `withExceeded` is truthy, `committed` only otherwise
 */
export const getCommittedAmount = (
  { committed, committedExceeded }: BudgetBreakdown,
  withExceeded = true,
): MonetaryValue =>
  withExceeded ? add(committed, committedExceeded) : committed;

export const hasCommittedAmounts = (breakdown: BudgetBreakdown) =>
  toNumber(getCommittedAmount(breakdown)) !== 0;

/**
 * Calculates the sum of exceeded amounts.
 * @param breakdown The budget breakdown.
 * @returns the sum of `committedExceeded` + `usedExceeded` amounts.
 */
export const getExceededAmount = ({
  committedExceeded,
  usedExceeded,
}: BudgetBreakdown): MonetaryValue => add(committedExceeded, usedExceeded);

export const hasExceededAmounts = (breakdown: BudgetBreakdown) =>
  toNumber(getExceededAmount(breakdown)) !== 0;

/**
 * Calculates the sum of all spent amounts.
 * @param breakdown The budget breakdown.
 * @param withExceeded Includes `committedExceeded` and `usedExceeded` ?
 * @returns the sum of `used` + `usedExceeded` + `committed` + `committedExceeded` amounts.
 */
export const getSpentAmount = (
  breakdown: BudgetBreakdown,
  withExceeded = true,
): MonetaryValue =>
  add(
    getUsedAmount(breakdown, withExceeded),
    getCommittedAmount(breakdown, withExceeded),
  );

/**
 * Calculates the progress of the spent amounts
 * @param breakdown The budget breakdown.
 * @returns The percentage of spent amounts / budget limit
 */
export const getSpentBreakdownPercentage = (
  breakdown: BudgetBreakdown,
): number => {
  return Math.ceil(
    (toNumber(getSpentAmount(breakdown)) /
      toNumber(getBudgetLimit(breakdown))) *
      100,
  );
};

/**
 * Check if the budget breakdown is empty (all its amounts are equal to 0).
 * @param breakdown The budget breakdown.
 * @returns whether committed, used, committedExceeded, usedExceeded and available amounts are all empty.
 */
export const isBudgetBreakdownEmpty = ({
  committed,
  used,
  committedExceeded,
  usedExceeded,
  available,
}: BudgetBreakdown): boolean => {
  return [committed, used, committedExceeded, usedExceeded, available].every(
    (amount) => toNumber(amount) === 0,
  );
};

/**
 * Converts the budget period key to the date range
 * @param key The budget period key.
 * @returns returns the range of two dates: from and to
 */
export const dateRangeToPeriodRange = (range: PeriodRange) => {
  const currentDate = new Date();
  if (range.key === 'custom') {
    return {
      from: startOfMonth(range.from),
      to: endOfMonth(range.to),
    };
  }
  switch (range.key) {
    case 'currentMonth':
      return {
        from: startOfMonth(currentDate),
        to: endOfMonth(currentDate),
      };
    case 'previousMonth': {
      const previousMonth = subMonths(currentDate, 1);
      return {
        from: startOfMonth(previousMonth),
        to: endOfMonth(previousMonth),
      };
    }
    case 'currentQuarter':
      return {
        from: startOfQuarter(currentDate),
        to: endOfQuarter(currentDate),
      };
    case 'previousQuarter': {
      const previousQuarter = subQuarters(currentDate, 1);
      return {
        from: startOfQuarter(previousQuarter),
        to: endOfQuarter(previousQuarter),
      };
    }
    case 'overall':
      return {
        from: undefined,
        to: undefined,
      };
    default:
      // fallback to currentMonth
      return {
        from: startOfMonth(currentDate),
        to: endOfMonth(currentDate),
      };
  }
};
