import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type Member = {
  id: string;
  email: string;
  avatar: string | undefined;
  isPending: boolean;
  firstName: string | undefined;
  lastName: string | undefined;
};

const PAGE_SIZE = 5;
const OFFSET = 0;
const MEMBER_STATUS = 'active';

export const useRequestersQuery = (
  searchTerm: string,
): QueryState<Member[]> => {
  return useQuery<Member[], Member[]>({
    key: ['users', { searchTerm }],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/members-page?pageSize=${PAGE_SIZE}&offset=${OFFSET}&searchTerm=${encodeURIComponent(
        searchTerm,
      )}&memberStatus=${MEMBER_STATUS}`,
    },
  });
};
