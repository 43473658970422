import { Modal, Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  isOpen: boolean;
  value: string;
  onClose: () => void;
  onSubmit: () => void;
};

export const DeleteCustomFieldValueModal = ({
  isOpen,
  value,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      iconName="cross"
      iconVariant="alert"
      isOpen={isOpen}
      actions={[
        <Button
          variant="secondary"
          text={t('customFields.settings.modals.deletion.cancel')}
          onClick={() => onClose()}
          key="secondary"
        />,
        <Button
          variant="alert"
          text={t('customFields.settings.modals.deletion.submit')}
          onClick={() => onSubmit()}
          key="primary"
        />,
      ]}
      title={t('customFields.settings.modals.deletion.title')}
    >
      {t('customFields.settings.modals.deletion.description', { name: value })}
    </Modal>
  );
};
