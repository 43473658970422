const CURRENCY_KEYS = [
  'EUR',
  'GBP',
  'USD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BYR',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'FJD',
  'FKP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LTL',
  'LVL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLE',
  'SLL',
  'SOS',
  'SRD',
  'STD',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VEF',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL',
] as const;

export type CurrenciesKey = (typeof CURRENCY_KEYS)[number];
interface Currency {
  value: CurrenciesKey;
  label: string;
  symbol: string;
  available?: boolean;
  defaultForCountries?: string[];
}

export const CURRENCIES: Record<CurrenciesKey, Currency> = {
  EUR: { value: 'EUR', label: '€ - Euro', symbol: '€', available: true },
  GBP: {
    value: 'GBP',
    label: '£ - British Pound',
    symbol: '£',
    available: true,
    defaultForCountries: ['UK'],
  },
  USD: {
    value: 'USD',
    label: '$ - US Dollar',
    symbol: '$',
    available: true,
    defaultForCountries: ['US'],
  },
  AED: { value: 'AED', label: 'AED - Emirati dirham', symbol: 'د.إ' },
  AFN: { value: 'AFN', label: 'AFN - Afghan Afghani', symbol: 'AFN' },
  ALL: { value: 'ALL', label: 'ALL - Albanian Lek', symbol: 'ALL' },
  AMD: { value: 'AMD', label: 'AMD - Armenian Dram', symbol: 'AMD' },
  ANG: {
    value: 'ANG',
    label: 'ANG - Netherlands Antillean Guilder',
    symbol: 'ANG',
  },
  AOA: { value: 'AOA', label: 'AOA - Angolan Kwanza', symbol: 'AOA' },
  ARS: { value: 'ARS', label: 'ARS - Argentine Peso', symbol: 'ARS' },
  AUD: { value: 'AUD', label: 'AUD - Australian Dollar', symbol: 'A$' },
  AWG: { value: 'AWG', label: 'AWG - Aruban Florin', symbol: 'AWG' },
  AZN: { value: 'AZN', label: 'AZN - Azerbaijani Manat', symbol: 'AZN' },
  BAM: {
    value: 'BAM',
    label: 'BAM - Bosnia-Herzegovina Convertible Mark',
    symbol: 'BAM',
  },
  BBD: { value: 'BBD', label: 'BBD - Barbadian Dollar', symbol: 'BBD' },
  BDT: { value: 'BDT', label: 'BDT - Bangladeshi Taka', symbol: 'BDT' },
  BGN: { value: 'BGN', label: 'BGN - Bulgarian Lev', symbol: 'лв' },
  BHD: { value: 'BHD', label: 'BHD - Bahraini Dinar', symbol: 'BHD' },
  BIF: { value: 'BIF', label: 'BIF - Burundian Franc', symbol: 'BIF' },
  BMD: { value: 'BMD', label: 'BMD - Bermudan Dollar', symbol: 'BMD' },
  BND: { value: 'BND', label: 'BND - Brunei Dollar', symbol: 'BND' },
  BOB: { value: 'BOB', label: 'BOB - Bolivian Boliviano', symbol: 'BOB' },
  BRL: { value: 'BRL', label: 'BRL - Brazilian real', symbol: 'R$' },
  BSD: { value: 'BSD', label: 'BSD - Bahamian Dollar', symbol: 'BSD' },
  BTN: { value: 'BTN', label: 'BTN - Bhutanese Ngultrum', symbol: 'BTN' },
  BWP: { value: 'BWP', label: 'BWP - Botswanan Pula', symbol: 'BWP' },
  BYN: { value: 'BYN', label: 'BYN - New Belarusian Ruble', symbol: 'BYN' },
  BYR: {
    value: 'BYR',
    label: 'BYR - Belarusian Ruble',
    symbol: 'BYR',
    available: false,
  },
  BZD: { value: 'BZD', label: 'BZD - Belize Dollar', symbol: 'BZD' },
  CAD: { value: 'CAD', label: 'CAD - Canadian dollar', symbol: 'C$' },
  CDF: { value: 'CDF', label: 'CDF - Congolese Franc', symbol: 'CDF' },
  CHF: { value: 'CHF', label: 'CHF - Swiss Franc', symbol: 'CHF' },
  CLF: {
    value: 'CLF',
    label: 'CLF - Chilean Unit of Account (UF)',
    symbol: 'CLF',
  },
  CLP: { value: 'CLP', label: 'CLP - Chilean Peso', symbol: 'CLP' },
  CNY: { value: 'CNY', label: 'CNY - Chinese Yuan', symbol: 'CN¥' },
  COP: { value: 'COP', label: 'COP - Colombian Peso', symbol: 'COP' },
  CRC: { value: 'CRC', label: 'CRC - Costa Rican Colón', symbol: 'CRC' },
  CUC: { value: 'CUC', label: 'CUC - Cuban Convertible Peso', symbol: 'CUC' },
  CUP: { value: 'CUP', label: 'CUP - Cuban Peso', symbol: 'CUP' },
  CVE: { value: 'CVE', label: 'CVE - Cape Verdean Escudo', symbol: 'CVE' },
  CZK: { value: 'CZK', label: 'CZK - Czech koruna', symbol: 'Kč' },
  DJF: { value: 'DJF', label: 'DJF - Djiboutian Franc', symbol: 'DJF' },
  DKK: {
    value: 'DKK',
    label: 'DKK - Danish krone',
    symbol: 'dkk',
    defaultForCountries: ['DK'],
    available: false,
  },
  DOP: { value: 'DOP', label: 'DOP - Dominican Peso', symbol: 'DOP' },
  DZD: { value: 'DZD', label: 'DZD - Algerian Dinar', symbol: 'DZD' },
  EGP: { value: 'EGP', label: 'EGP - Egyptian Pound', symbol: 'EGP' },
  ERN: { value: 'ERN', label: 'ERN - Eritrean Nakfa', symbol: 'ERN' },
  ETB: { value: 'ETB', label: 'ETB - Ethiopian Birr', symbol: 'ETB' },
  FJD: { value: 'FJD', label: 'FJD - Fijian Dollar', symbol: 'FJD' },
  FKP: { value: 'FKP', label: 'FKP - Falkland Islands Pound', symbol: 'FKP' },
  GEL: { value: 'GEL', label: 'GEL - Georgian Lari', symbol: 'GEL' },
  GGP: { value: 'GGP', label: 'GGP - Guernsey Pound', symbol: 'GGP' },
  GHS: { value: 'GHS', label: 'GHS - Ghanaian Cedi', symbol: 'GHS' },
  GIP: { value: 'GIP', label: 'GIP - Gibraltar pound', symbol: '£GI' },
  GMD: { value: 'GMD', label: 'GMD - Gambian Dalasi', symbol: 'GMD' },
  GNF: { value: 'GNF', label: 'GNF - Guinean Franc', symbol: 'GNF' },
  GTQ: { value: 'GTQ', label: 'GTQ - Guatemalan Quetzal', symbol: 'GTQ' },
  GYD: { value: 'GYD', label: 'GYD - Guyanese dollar', symbol: 'G$' },
  HKD: { value: 'HKD', label: 'HKD - Hong Kong Dollar', symbol: 'HK$' },
  HNL: { value: 'HNL', label: 'HNL - Honduran Lempira', symbol: 'HNL' },
  HRK: {
    value: 'HRK',
    label: 'HRK - (obsolete) Croatian Kuna',
    symbol: 'kn',
    available: false,
  },
  HTG: { value: 'HTG', label: 'HTG - Haitian Gourde', symbol: 'HTG' },
  HUF: { value: 'HUF', label: 'HUF - Hungarian forint', symbol: 'Ft' },
  IDR: { value: 'IDR', label: 'IDR - Indonesian Rupiah', symbol: 'Rp' },
  ILS: { value: 'ILS', label: 'ILS - Israeli shekel', symbol: '₪' },
  IMP: { value: 'IMP', label: 'IMP - Manx pound', symbol: 'IMP' },
  INR: { value: 'INR', label: 'INR - Indian Rupee', symbol: '₹' },
  IQD: { value: 'IQD', label: 'IQD - Iraqi Dinar', symbol: 'IQD' },
  IRR: { value: 'IRR', label: 'IRR - Iranian Rial', symbol: 'IRR' },
  ISK: { value: 'ISK', label: 'ISK - Icelandic Króna', symbol: 'ISK' },
  JEP: { value: 'JEP', label: 'JEP - Jersey Pound', symbol: 'JEP' },
  JMD: { value: 'JMD', label: 'JMD - Jamaican Dollar', symbol: 'JMD' },
  JOD: { value: 'JOD', label: 'JOD - Jordanian Dinar', symbol: 'JOD' },
  JPY: { value: 'JPY', label: 'JPY - Japanese Yen', symbol: '¥' },
  KES: { value: 'KES', label: 'KES - Kenyan Shilling', symbol: 'KES' },
  KGS: { value: 'KGS', label: 'KGS - Kyrgystani Som', symbol: 'KGS' },
  KHR: { value: 'KHR', label: 'KHR - Cambodian Riel', symbol: 'KHR' },
  KMF: { value: 'KMF', label: 'KMF - Comorian Franc', symbol: 'KMF' },
  KPW: { value: 'KPW', label: 'KPW - North Korean Won', symbol: 'KPW' },
  KRW: { value: 'KRW', label: 'KRW - Korean KRW', symbol: '₩' },
  KWD: { value: 'KWD', label: 'KWD - Kuwaiti Dinar', symbol: 'KWD' },
  KYD: { value: 'KYD', label: 'KYD - Cayman Islands Dollar', symbol: 'KYD' },
  KZT: { value: 'KZT', label: 'KZT - Kazakhstani Tenge', symbol: 'KZT' },
  LAK: { value: 'LAK', label: 'LAK - Laotian Kip', symbol: 'LAK' },
  LBP: { value: 'LBP', label: 'LBP - Lebanese Pound', symbol: 'LBP' },
  LKR: { value: 'LKR', label: 'LKR - Sri Lankan Rupee', symbol: 'LKR' },
  LRD: { value: 'LRD', label: 'LRD - Liberian Dollar', symbol: 'LRD' },
  LSL: { value: 'LSL', label: 'LSL - Lesotho Loti', symbol: 'LSL' },
  LTL: { value: 'LTL', label: 'LTL - Lithuanian Litas', symbol: 'LTL' },
  LVL: { value: 'LVL', label: 'LVL - Latvian Lats', symbol: 'LVL' },
  LYD: { value: 'LYD', label: 'LYD - Libyan Dinar', symbol: 'LYD' },
  MAD: { value: 'MAD', label: 'MAD - Moroccan Dirham', symbol: 'MAD' },
  MDL: { value: 'MDL', label: 'MDL - Moldovan Leu', symbol: 'MDL' },
  MGA: { value: 'MGA', label: 'MGA - Malagasy Ariary', symbol: 'MGA' },
  MKD: { value: 'MKD', label: 'MKD - Macedonian Denar', symbol: 'MKD' },
  MMK: { value: 'MMK', label: 'MMK - Myanma Kyat', symbol: 'MMK' },
  MNT: { value: 'MNT', label: 'MNT - Mongolian Tugrik', symbol: 'MNT' },
  MOP: { value: 'MOP', label: 'MOP - Macanese Pataca', symbol: 'MOP' },
  MRO: {
    value: 'MRO',
    label: 'MRO - (obsolete) Mauritanian Ouguiya',
    symbol: 'MRO',
    available: false,
  },
  MRU: {
    value: 'MRU',
    label: 'MRU -  Ouguiya mauritanien',
    symbol: 'UM',
  },
  MUR: { value: 'MUR', label: 'MUR - Mauritian Rupee', symbol: 'MUR' },
  MVR: { value: 'MVR', label: 'MVR - Maldivian Rufiyaa', symbol: 'MVR' },
  MWK: { value: 'MWK', label: 'MWK - Malawian Kwacha', symbol: 'MWK' },
  MXN: { value: 'MXN', label: 'MXN - Mexican peso', symbol: '$' },
  MYR: { value: 'MYR', label: 'MYR - Malaysian ringgit', symbol: 'RM' },
  MZN: { value: 'MZN', label: 'MZN - Mozambican Metical', symbol: 'MZN' },
  NAD: { value: 'NAD', label: 'NAD - Namibian Dollar', symbol: 'NAD' },
  NGN: { value: 'NGN', label: 'NGN - Nigerian Naira', symbol: 'NGN' },
  NIO: { value: 'NIO', label: 'NIO - Nicaraguan Córdoba', symbol: 'NIO' },
  NOK: {
    value: 'NOK',
    label: 'NOK - Norwegian krone',
    symbol: 'nok',
    defaultForCountries: ['NO'],
    available: false,
  },
  NPR: { value: 'NPR', label: 'NPR - Nepalese Rupee', symbol: 'NPR' },
  NZD: { value: 'NZD', label: 'NZD - New Zealand Dollar', symbol: 'NZD' },
  OMR: { value: 'OMR', label: 'OMR - Omani Rial', symbol: 'OMR' },
  PAB: { value: 'PAB', label: 'PAB - Panamanian Balboa', symbol: 'PAB' },
  PEN: { value: 'PEN', label: 'PEN - Peruvian Nuevo Sol', symbol: 'PEN' },
  PGK: { value: 'PGK', label: 'PGK - Papua New Guinean Kina', symbol: 'PGK' },
  PHP: { value: 'PHP', label: 'PHP - Philippine Piso', symbol: '₱' },
  PKR: { value: 'PKR', label: 'PKR - Pakistani Rupee', symbol: 'PKR' },
  PLN: { value: 'PLN', label: 'PLN - Polish Złoty', symbol: 'zł' },
  PYG: { value: 'PYG', label: 'PYG - Paraguayan Guarani', symbol: 'PYG' },
  QAR: { value: 'QAR', label: 'QAR - Qatari Rial', symbol: 'QAR' },
  RON: { value: 'RON', label: 'RON - Romanian Leu', symbol: 'RON' },
  RSD: { value: 'RSD', label: 'RSD - Serbian Dinar', symbol: 'РСД' },
  RUB: { value: 'RUB', label: 'RUB - Russian Rouble', symbol: '₽' },
  RWF: { value: 'RWF', label: 'RWF - Rwandan Franc', symbol: 'RWF' },
  SAR: { value: 'SAR', label: 'SAR - Saudi Riyal', symbol: 'SAR' },
  SBD: { value: 'SBD', label: 'SBD - Solomon Islands Dollar', symbol: 'SBD' },
  SCR: { value: 'SCR', label: 'SCR - Seychellois Rupee', symbol: 'SCR' },
  SDG: { value: 'SDG', label: 'SDG - Sudanese Pound', symbol: 'SDG' },
  SEK: {
    value: 'SEK',
    label: 'SEK - Swedish Krona',
    symbol: 'sek',
    defaultForCountries: ['SE'],
    available: false,
  },
  SGD: { value: 'SGD', label: 'SGD - Singapore dollar', symbol: 'S$' },
  SHP: { value: 'SHP', label: 'SHP - Saint Helena Pound', symbol: 'SHP' },
  SLE: { value: 'SLE', label: 'SLE - New Sierra Leonean Leone', symbol: 'SLE' },
  SLL: { value: 'SLL', label: 'SLL - Sierra Leonean Leone', symbol: 'SLL' },
  SOS: { value: 'SOS', label: 'SOS - Somali Shilling', symbol: 'SOS' },
  SRD: { value: 'SRD', label: 'SRD - Surinamese Dollar', symbol: 'SRD' },
  STD: {
    value: 'STD',
    label: 'STD - São Tomé and Príncipe Dobra',
    symbol: 'STD',
  },
  SVC: { value: 'SVC', label: 'SVC - Salvadoran Colón', symbol: 'SVC' },
  SYP: { value: 'SYP', label: 'SYP - Syrian Pound', symbol: 'SYP' },
  SZL: { value: 'SZL', label: 'SZL - Swazi Lilangeni', symbol: 'SZL' },
  THB: { value: 'THB', label: 'THB - Thai Baht', symbol: '฿' },
  TJS: { value: 'TJS', label: 'TJS - Tajikistani Somoni', symbol: 'TJS' },
  TMT: { value: 'TMT', label: 'TMT - Turkmenistani Manat', symbol: 'TMT' },
  TND: { value: 'TND', label: 'TND - Tunisian dinar', symbol: 'د.ت' },
  TOP: { value: 'TOP', label: 'TOP - Tongan Paʻanga', symbol: 'TOP' },
  TRY: { value: 'TRY', label: 'TRY - Turkish lira', symbol: '₺' },
  TTD: {
    value: 'TTD',
    label: 'TTD - Trinidad and Tobago Dollar',
    symbol: 'TTD',
  },
  TWD: { value: 'TWD', label: 'TWD - New Taiwan Dollar', symbol: 'TWD' },
  TZS: { value: 'TZS', label: 'TZS - Tanzanian Shilling', symbol: 'TZS' },
  UAH: { value: 'UAH', label: 'UAH - Hryvnia', symbol: '₴' },
  UGX: { value: 'UGX', label: 'UGX - Ugandan Shilling', symbol: 'UGX' },
  UYU: { value: 'UYU', label: 'UYU - Uruguayan Peso', symbol: 'UYU' },
  UZS: { value: 'UZS', label: 'UZS - Uzbekistan Som', symbol: 'UZS' },
  VEF: {
    value: 'VEF',
    label: 'VEF - (obsolete) Venezuelan Bolívar Fuerte',
    symbol: 'VEF',
    available: false,
  },
  VES: {
    value: 'VES',
    label: 'VES - Venezuela Bolívar Soberano',
    symbol: 'Bs.S',
  },
  VND: { value: 'VND', label: 'VND - Vietnamese Dong', symbol: 'VND' },
  VUV: { value: 'VUV', label: 'VUV - Vanuatu Vatu', symbol: 'VUV' },
  WST: { value: 'WST', label: 'WST - Samoan Tala', symbol: 'WST' },
  XAF: { value: 'XAF', label: 'XAF - CFA Franc BEAC', symbol: 'XAF' },
  XCD: { value: 'XCD', label: 'XCD - East Caribbean Dollar', symbol: 'XCD' },
  XOF: { value: 'XOF', label: 'XOF - CFA Franc BCEAO', symbol: 'XOF' },
  XPF: { value: 'XPF', label: 'XPF - CFP Franc', symbol: 'XPF' },
  YER: { value: 'YER', label: 'YER - Yemeni Rial', symbol: 'YER' },
  ZAR: { value: 'ZAR', label: 'ZAR - South African Rand', symbol: 'ZAR' },
  ZMW: { value: 'ZMW', label: 'ZMW - Zambian Kwacha', symbol: 'ZMW' },
  ZWL: { value: 'ZWL', label: 'ZWL - Zimbabwean Dollar', symbol: 'ZWL' },
};

export const AVAILABLE_CURRENCIES = Object.values(CURRENCIES).filter(
  ({ available }) => available,
);

export const SORTED_CURRENCIES = Object.fromEntries(
  [
    ...AVAILABLE_CURRENCIES,
    ...Object.values(CURRENCIES)
      .filter(({ available }) => !available)
      .sort((left, right) => left.label.localeCompare(right.label)),
  ].map((currency) => [currency.value, currency]),
) as {
  [k in CurrenciesKey]: Currency;
};

export interface CurrencyOption {
  key: string;
  label: string;
}

export const currencyOptions: CurrencyOption[] = Object.values(CURRENCIES).map(
  (currency) => ({
    key: currency.value,
    label: currency.label,
  }),
);

export const SORTED_CURRENCY_OPTIONS: CurrencyOption[] = Object.values(
  SORTED_CURRENCIES,
).map((currency) => ({
  key: currency.value,
  label: currency.label,
}));

export type CurrencyRates = Record<string, number>;

export const isValidCurrency = (currency?: CurrenciesKey) => {
  return currency && CURRENCY_KEYS.includes(currency);
};
