import { isStringEmpty } from './isStringEmpty';

export const getProductBoardContent = (
  content: string,
  rating: string,
  title: string,
) => {
  const shortContent = `${rating}/10 ${title}`;
  const longContent = `${shortContent}
-----
${content}`;

  return isStringEmpty(content) ? shortContent : longContent;
};
