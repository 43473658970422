import React, { useState } from 'react';

import { MfaXeroActivationModal } from 'modules/StrongCustomerAuthentication/components/MfaXeroActivationModal/MfaXeroActivationModal';
import { MfaXeroWarningModal } from 'modules/StrongCustomerAuthentication/components/MfaXeroWarningModal/MfaXeroWarningModal';

interface Props {
  isOpen: boolean;
  hasMfaUserError: boolean;
  onClose: () => void;
  onSetup: () => Promise<void>;
}

export const MfaXeroModalStepsManager = ({
  isOpen,
  hasMfaUserError,
  onClose,
  onSetup,
}: Props) => {
  const [xeroMfaStep, setXeroMfaStep] = useState<
    'warning' | 'activationWithSca'
  >('warning');

  const resetSteps = () => {
    setXeroMfaStep('warning');
    onClose();
  };

  return (
    <>
      <MfaXeroWarningModal
        isOpen={xeroMfaStep === 'warning' && isOpen}
        onClose={resetSteps}
        next={() => setXeroMfaStep('activationWithSca')}
      />

      <MfaXeroActivationModal
        isOpen={xeroMfaStep === 'activationWithSca' && isOpen}
        isUserEnrolledError={hasMfaUserError}
        onClose={resetSteps}
        onConfirm={onSetup}
      />
    </>
  );
};
