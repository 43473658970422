import React, { useEffect } from 'react';

import { type AllowanceLocation } from '../../allowanceLocation';
import { type PerDiem } from '../../perDiem';
import { PerDiemDaysBreakdown } from '../PerDiemDaysBreakdown';

type Props = Readonly<{
  className?: string;
  perDiem: PerDiem;
  userLang: string;
  allowanceLocations: ReadonlyArray<AllowanceLocation>;
  companyCountry: string;
  onFetchAllowanceLocations: () => void;
}>;

export const PerDiemDetails = ({
  className,
  perDiem,
  userLang,
  allowanceLocations,
  companyCountry,
  onFetchAllowanceLocations,
}: Props) => {
  useEffect(() => {
    onFetchAllowanceLocations();
  }, []);

  return (
    <div className={className}>
      <PerDiemDaysBreakdown
        perDiem={perDiem}
        userLang={userLang}
        allowanceLocations={allowanceLocations}
        companyCountry={companyCountry}
      />
    </div>
  );
};
