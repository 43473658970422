import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { type AccountStatement } from '../wallet';

const ACCOUNT_STATEMENTS_LIMIT = 5;

export type RawAccountStatementsResponse = {
  data: {
    month: number;
    year: number;
    total_credit: number;
    total_debit: number;
    date_start: string;
    date_end: string;
    balance_before: number;
    balance_after: number;
  }[];
  hasNextPage: boolean;
  pageNumber: number;
};

export const useGetAccountStatementsQuery = ({
  isEnabled = true,
}: { isEnabled?: boolean } = {}): InfiniteQueryState<AccountStatement[]> => {
  const companyId = useCompanyId();

  return useInfiniteQuery<AccountStatement, RawAccountStatementsResponse>({
    key: ['accountStatements', companyId],
    isEnabled,
    getRequest: (cursor) => ({
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounts/statements',
      params: {
        pageNumber: Number(cursor) || 1,
        pageSize: ACCOUNT_STATEMENTS_LIMIT,
      },
    }),
    getNextPageParam(data) {
      return data.hasNextPage ? data.pageNumber + 1 : undefined;
    },
    reshapeData: (rawAccountStatements) => {
      return rawAccountStatements.data.map((rawAccountStatement) => ({
        month: rawAccountStatement.month,
        year: rawAccountStatement.year,
        totalCredit: rawAccountStatement.total_credit,
        totalDebit: rawAccountStatement.total_debit,
        dateStart: new Date(rawAccountStatement.date_start),
        dateEnd: new Date(rawAccountStatement.date_end),
        balanceBefore: rawAccountStatement.balance_before,
        balanceAfter: rawAccountStatement.balance_after,
      }));
    },
  });
};
