import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type PayableId } from '../../payable';

type GetTravelperkPayableDataResponse = {
  url: string;
};
export const useGetTravelperkPayableDataQuery = (
  payableId: PayableId,
): QueryState<GetTravelperkPayableDataResponse> => {
  return useQuery<GetTravelperkPayableDataResponse>({
    key: ['travelperkPayable', payableId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/integrations/travelperk/payable/${payableId}`,
    },
    options: {
      staleTime: 60_000,
    },
    reshapeData(data) {
      return data;
    },
  });
};
