import { Button, type ButtonProps } from '@dev-spendesk/grapes';
import React, { type Ref } from 'react';

import type { Form } from './types';

type ButtonType = Exclude<ButtonProps, { component: 'a' }>;

type Props<TForm> = {
  form: TForm;
  ref?: Ref<HTMLButtonElement>;
} & Omit<ButtonType, 'form'>;

export function FormSubmitButton<TForm>({
  form,
  ref,
  ...props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}: Props<TForm extends Form<infer TData> ? TForm : never>) {
  return (
    <Button
      ref={ref}
      variant="primary"
      isLoading={form.isSubmitting}
      isDisabled={form.isSubmitting || props.isDisabled}
      onClick={() => form.submitForm()}
      {...props}
    />
  );
}
