import { type CurrenciesKey } from 'src/core/config/money';

export enum AnnouncementCode {
  SlackAnnouncement = 'SLACK_ANNOUNCEMENT',
  ExpenseInboxFirstRecord = 'EXPENSE_INBOX_FIRST_RECORD',
}

export interface CompanyAnnouncement {
  code: string;
  lastSeenAt: string | null;
}

export interface Company {
  id: string;
  name: string;
  currency: CurrenciesKey;
  announcements: CompanyAnnouncement[];
  automaticReceiptReminders: boolean;
}

export enum PaymentMethod {
  Csv = 'csv',
  XmlSepa = 'xml-sepa',
  WireTransfer = 'wiretransfer',
}

export const EXPORT_METHODS = ['csv', 'xml-sepa'] as const;

export type ExportMethod = (typeof EXPORT_METHODS)[number];
export type ExportMethodsStatus = Record<ExportMethod, { active: boolean }>;

export type PaymentMethodsByIssuer = {
  fromSpendesk: PaymentMethod.WireTransfer[];
  fromBank: (PaymentMethod.Csv | PaymentMethod.XmlSepa)[];
};
