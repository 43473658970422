import { add, fromNumber, type MonetaryValue } from 'ezmoney';

import { PayableRequest } from '@finance-review/models/payable';

import * as DraftScheduledPayment from './draftScheduledPayment';

export type Entity = DraftScheduledPayment.Entity[]; // sorted by dates

export const append = (draftPaymentSchedule: Entity): Entity =>
  draftPaymentSchedule.concat(DraftScheduledPayment.create());

export const canDeleteElement = (
  draftPaymentSchedule: Entity,
  draftScheduledPaymentToDelete: DraftScheduledPayment.Entity,
): boolean => draftPaymentSchedule[0] !== draftScheduledPaymentToDelete;

export const computeTotal = (
  draftPaymentSchedule: Entity,
  currency: string,
): MonetaryValue =>
  draftPaymentSchedule.reduce((sum, draftScheduledPayment) => {
    const amount = draftScheduledPayment.amount
      ? fromNumber(draftScheduledPayment.amount, currency, 2)
      : fromNumber(0, currency, 2);
    return add(sum, amount);
  }, fromNumber(0, currency, 2));

/**
 * the payment schedule is a list of payments sorted by date
 * each payment has a date which is bounded by the previous and next payment on list
 * this function returns the min and max date possible for each payment, relative to the previous and next ones
 */
export const getDateRangeOfEachElement = (
  draftPaymentSchedule: Entity,
  today: Date,
): Map<
  DraftScheduledPayment.Entity,
  { minDate: Date; maxDate: Date | undefined }
> => {
  const computedMinDates = draftPaymentSchedule.reduce((minDates, _, index) => {
    const previousDraftScheduledPayment = draftPaymentSchedule[index - 1];
    const minDate =
      previousDraftScheduledPayment?.date ?? minDates.at(-1) ?? today;

    minDates.push(minDate);
    return minDates;
  }, [] as Date[]);

  const computedMaxDates = draftPaymentSchedule
    .reduceRight((maxDates, _, index) => {
      const succDraftScheduledPayment = draftPaymentSchedule[index + 1];
      const maxDate =
        succDraftScheduledPayment?.date ??
        maxDates.at(-1) ??
        PayableRequest.latestAllowedDate;

      maxDates.push(maxDate);
      return maxDates;
    }, [] as (Date | undefined)[])
    .reverse();

  return new Map(
    draftPaymentSchedule.map((draftScheduledPayment, index) => [
      draftScheduledPayment,
      { minDate: computedMinDates[index], maxDate: computedMaxDates[index] },
    ]),
  );
};

export const removeElement = (
  draftPaymentSchedule: Entity,
  draftScheduledPayment: DraftScheduledPayment.Entity,
): Entity => {
  if (!canDeleteElement(draftPaymentSchedule, draftScheduledPayment)) {
    return draftPaymentSchedule;
  }

  const indexToRemove = draftPaymentSchedule.indexOf(draftScheduledPayment);
  return indexToRemove <= 0
    ? draftPaymentSchedule
    : [
        ...draftPaymentSchedule.slice(0, indexToRemove),
        ...draftPaymentSchedule.slice(indexToRemove + 1),
      ];
};

export const replaceElement = (
  draftPaymentSchedule: Entity,
  oldDraftScheduledPayment: DraftScheduledPayment.Entity,
  newDraftScheduledPayment: DraftScheduledPayment.Entity,
): Entity => {
  const indexToUpdate = draftPaymentSchedule.indexOf(oldDraftScheduledPayment);

  return indexToUpdate < 0
    ? draftPaymentSchedule
    : [
        ...draftPaymentSchedule.slice(0, indexToUpdate),
        newDraftScheduledPayment,
        ...draftPaymentSchedule.slice(indexToUpdate + 1),
      ];
};
