import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { useGoToMembersPage } from 'modules/members/hooks';

import { useShowDelegationFeatureCallout } from './useShowDelegationFeatureCallout';

type Props = {
  className?: string;
};

export const DelegationFeatureAvailableCallout = ({ className }: Props) => {
  const { t } = useTranslation('global');

  const goToMembersPage = useGoToMembersPage();

  return (
    <QuerySuspense
      queryState={useShowDelegationFeatureCallout()}
      loading={null}
      fallback={() => null}
    >
      {(showCallout) =>
        showCallout ? (
          <Callout
            iconName="sparkles"
            title={t('delegation.featureAvailableCallout.title')}
            variant="primary"
            className={className}
          >
            <p className="mb-s">
              {t('delegation.featureAvailableCallout.description')}
            </p>
            <Button
              variant="contrasted"
              text={t('delegation.featureAvailableCallout.editMembersButton')}
              onClick={() => {
                goToMembersPage();
              }}
            />
          </Callout>
        ) : null
      }
    </QuerySuspense>
  );
};
