import { Button, Callout, Link } from '@dev-spendesk/grapes';
import React from 'react';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { openSupportChat } from 'src/core/utils/supportChat';

import { type ModalState } from './ModalState';
import styles from '../AnalyticalFieldsSection.module.css';

interface Props {
  hasCostCenterFeature: boolean;
  hasCostCenterActive: boolean;
  integration: AccountingSoftware;
  modalState: ModalState;
}

export const CostCenterInfo = ({
  hasCostCenterFeature,
  hasCostCenterActive,
  integration,
  modalState,
}: Props) => {
  const { t } = useTranslation();
  const companyId = useCompanyId();

  if (
    !modalState ||
    modalState.modalKind !== 'map' ||
    modalState.kind === 'notMapped' ||
    modalState.codeType !== 'costCenter'
  ) {
    return <></>;
  }

  if (!hasCostCenterFeature) {
    return (
      <Callout
        className={styles.analyticalField}
        title={t(
          'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenterWarning.header',
        )}
        variant="warning"
      >
        <div className={styles.analyticalField__content}>
          <p className={styles.analyticalField__text}>
            {t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenterWarning.text',
            )}
            &nbsp;
            <span className={styles.uppercase}>
              {integration} {modalState.externalName}
            </span>
          </p>
          {/* TODO when approval rating tab will become available for everyone, do redirect there instead of opening the intercom  */}
          <Button
            variant="contrasted"
            onClick={() => {
              openSupportChat();
            }}
            text={t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsWarning.button',
            )}
          />
        </div>
      </Callout>
    );
  }

  if (!hasCostCenterActive) {
    return (
      <Callout
        className={styles.analyticalField}
        title={t(
          'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenterWarning.header',
        )}
        variant="warning"
      >
        <div className={styles.analyticalField__content}>
          <p className={styles.analyticalField__text}>
            {t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenterWarning.text',
            )}
            &nbsp;
            <span className={styles.uppercase}>
              {integration} {modalState.externalName}
            </span>
          </p>
          {/* TODO when approval rating tab will become available for everyone, do redirect there instead of opening the intercom  */}
          <Button
            component="a"
            href={routeFor(routes.COST_CENTERS.path, { company: companyId })}
            variant="contrasted"
            text={t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenterInfo.link',
            )}
          />
        </div>
      </Callout>
    );
  }

  return (
    <Callout
      className={styles.analyticalField}
      title={
        <div className={styles.analyticalField__callout}>
          <p>
            {t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenterInfo.pullAndMapWithValues.text',
              {
                externalName: modalState.externalName,
              },
            )}
          </p>
          <Link
            isExternal
            href={routeFor(routes.COST_CENTERS.path, { company: companyId })}
            className={styles.analyticalField__link}
          >
            {t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenterInfo.link',
            )}
          </Link>
        </div>
      }
      variant="info"
    />
  );
};
