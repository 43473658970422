import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { type MediaType } from 'common/components/FileReceipt';
import { useCompany } from 'modules/app/hooks/useCompany';
import type { AppDispatch } from 'modules/app/redux/store';
import {
  createSupplier,
  updateSupplier,
} from 'modules/invoices/components/Invoices/Suppliers/thunks';
import { SupplierEditor } from 'modules/invoices/components/Invoices/_shared/SupplierEditor';
import { type ApiSupplier } from 'modules/invoices/components/Invoices/_shared/SupplierEditor/supplier';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { useInvalidateSupplierQuery } from '../../hooks';

type BankProofFile = {
  downloadUrl: string | null;
  extension?: string | null;
  filename: string | null;
  mimetype: MediaType | null;
  thumbnailUrl: string | null;
  viewUrl: string | null;
};

type Props = {
  supplier: ApiSupplier;
  onUpdateSupplierSuccess?(supplier: ApiSupplier): void;
  onCreateSupplierSuccess?(supplier: ApiSupplier): void;
};

export const ToReviewPanelSupplier = ({
  supplier,
  onUpdateSupplierSuccess,
  onCreateSupplierSuccess,
}: Props) => {
  const company = useCompany();
  const dispatch = useDispatch<AppDispatch>();
  const invalidateSupplierQuery = useInvalidateSupplierQuery();

  const [bankProofFile, setBankProofFile] = useState<BankProofFile | undefined>(
    undefined,
  );

  const onUpdate = async (
    supplierId: string,
    payload: Partial<ApiSupplier>,
  ) => {
    const onUpdateResult = await dispatch(updateSupplier(supplierId, payload));

    if (onUpdateSupplierSuccess && onUpdateResult.outcome === 'success') {
      if (onUpdateResult.supplier.id) {
        invalidateSupplierQuery(onUpdateResult.supplier.id);
      }
      onUpdateSupplierSuccess(onUpdateResult.supplier);
    }
    return onUpdateResult;
  };

  const onCreate = async (payload: Partial<ApiSupplier>) => {
    const onCreateResult = await dispatch(createSupplier(payload));

    if (onCreateSupplierSuccess && onCreateResult.outcome === 'success') {
      onCreateSupplierSuccess(onCreateResult.supplier);
    }
    return onCreateResult;
  };

  const onSave = async (
    supplierId: string | undefined,
    payload: Partial<ApiSupplier>,
  ) => {
    return supplierId ? onUpdate(supplierId, payload) : onCreate(payload);
  };

  return (
    <SupplierEditor
      warnForUnsavedChanges
      company={company}
      supplier={supplier}
      onSave={onSave}
      isBankProofFileDisplayed={!!bankProofFile}
      onShowBankProofFile={(newBankProofFile) => {
        if (newBankProofFile) {
          setBankProofFile(newBankProofFile as BankProofFile);
        }
      }}
      onHideBankProofFile={() => setBankProofFile(undefined)}
      onTrackSupplierSave={(suggestionContext) => {
        track(AnalyticEventName.SUPPLIER_SUGGESTION_DISPLAYED, {
          supplierId: supplier.id,
          ...suggestionContext,
        });
      }}
      showBankProofFileInline={!!bankProofFile}
    />
  );
};
