import { BucketType } from '../../prepare/utils/buckets';

type GraphQLPayableObject = {
  documentaryEvidence: {
    type?: string;
    validity?: {
      valid: boolean;
    };
  };
  __typename: string;
};

export const payableToBucketId = (
  payable: GraphQLPayableObject,
): BucketType => {
  if (
    (payable.documentaryEvidence?.type &&
      ['invoice', 'creditNote'].includes(payable.documentaryEvidence?.type)) ||
    [
      'MileageAllowanceExpensePayable',
      'PerDiemAllowanceExpensePayable',
    ].includes(payable.__typename)
  ) {
    return BucketType.WithProof;
  }
  if (payable.documentaryEvidence?.type === 'declarationOfMissingInvoice') {
    return BucketType.MissingProof;
  }
  return BucketType.WithoutProof;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const payableGroupToGraphQLDataId = (graphQLObject: any): string => {
  // Workaround because we can have the same PayableGroup in two different
  // buckets but without the same counters.
  // For `natural` bucket we don't fetch expenses proofs stuff,
  // otherwise we compute the related bucket from proofs data.
  const bucketId =
    graphQLObject.payables &&
    graphQLObject.payables.edges &&
    graphQLObject.payables?.edges?.length
      ? payableToBucketId(graphQLObject.payables.edges[0].node)
      : BucketType.Natural;

  return `PayableGroup:${graphQLObject.id}:${bucketId}`;
};
