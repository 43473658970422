import * as Money from 'ezmoney';

import { sortByDateAsc } from 'common/utils/sortByDateAsc';
import type { PaymentMethod } from 'modules/company';

import { type WireTransferError } from './wireTransferError';

export type Beneficiary = {
  accountHolderName: string | undefined;
  iban?: string;
  bic?: string;
  sortCode?: string;
  accountNumber?: string;
  supplierId?: string;
  supplierName?: string;
  accountCode?: string;
  routingNumber?: string;
};

export type SchedulingProcess = {
  paymentSchedule: { date: string; amount: Money.MonetaryValue }[];
  paymentsToSchedule: {
    id: string;
    date: string;
    amount: Money.MonetaryValue;
  }[];
  executedPayments: {
    id: string;
    date: string;
    amount: Money.MonetaryValue;
    paymentId: string;
    method?: PaymentMethod;
    beneficiary?: Beneficiary;
  }[];
  executingPayments: {
    id: string;
    attemptingPaymentId: string;
    date: string;
    amount: Money.MonetaryValue;
    method?: PaymentMethod;
    beneficiary?: Beneficiary;
  }[];
  failedPayments: {
    id: string;
    date: string;
    amount: Money.MonetaryValue;
    method: PaymentMethod;
    failureReason?: WireTransferError;
    beneficiary?: Beneficiary;
  }[];
  scheduledPayments: {
    id: string;
    date: string;
    amount: Money.MonetaryValue;
    method: PaymentMethod;
  }[];
  appliedCreditNotes: {
    id: string;
    amount: Money.MonetaryValue;
    requestId: string;
    issueDate: string;
    creditNoteNumber: string;
  }[];
};

interface SchedulingProcessEntryWithStatus {
  id: string;
  date: string;
  amount: Money.MonetaryValue;
  status: 'toSchedule' | 'scheduled' | 'executing' | 'success' | 'failed';
}

export interface SchedulingProcessEntryToSchedule
  extends SchedulingProcessEntryWithStatus {
  status: 'toSchedule';
}
export interface SchedulingProcessEntryScheduled
  extends SchedulingProcessEntryWithStatus {
  status: 'scheduled';
  method: PaymentMethod;
}

export interface SchedulingProcessEntryExecuting
  extends SchedulingProcessEntryWithStatus {
  status: 'executing';
  attemptingPaymentId: string;
  method?: PaymentMethod;
  beneficiary?: Beneficiary;
}
export interface SchedulingProcessEntryPaid
  extends SchedulingProcessEntryWithStatus {
  status: 'success';
  method?: PaymentMethod;
  paymentId: string;
  beneficiary?: Beneficiary;
}

export interface SchedulingProcessEntryFailed
  extends SchedulingProcessEntryWithStatus {
  status: 'failed';
  method: PaymentMethod;
  failureReason?: WireTransferError;
  beneficiary?: Beneficiary;
}

export type SchedulingProcessEntry =
  | SchedulingProcessEntryToSchedule
  | SchedulingProcessEntryScheduled
  | SchedulingProcessEntryPaid
  | SchedulingProcessEntryExecuting
  | SchedulingProcessEntryFailed;

export const getSchedulingProcessSortedByDateWithStatus = (
  schedulingProcess: SchedulingProcess,
): SchedulingProcessEntry[] => {
  const paymentsToScheduleWithStatus: SchedulingProcessEntryToSchedule[] =
    schedulingProcess.paymentsToSchedule.map((entry) => ({
      ...entry,
      status: 'toSchedule',
    }));
  const scheduledPaymentsWithStatus: SchedulingProcessEntryScheduled[] =
    schedulingProcess.scheduledPayments.map((entry) => ({
      ...entry,
      status: 'scheduled',
    }));
  const executedPaymentsWithStatus: SchedulingProcessEntryPaid[] =
    schedulingProcess.executedPayments.map((entry) => ({
      ...entry,
      status: 'success',
    }));
  const executingPaymentsWithStatus: SchedulingProcessEntryExecuting[] =
    schedulingProcess.executingPayments.map((entry) => ({
      ...entry,
      status: 'executing',
    }));
  const failedPaymentsWithStatus: SchedulingProcessEntryFailed[] =
    schedulingProcess.failedPayments.map((entry) => ({
      ...entry,
      status: 'failed',
    }));
  const entries: SchedulingProcessEntry[] = [
    ...paymentsToScheduleWithStatus,
    ...scheduledPaymentsWithStatus,
    ...executedPaymentsWithStatus,
    ...executingPaymentsWithStatus,
    ...failedPaymentsWithStatus,
  ];

  return sortByDateAsc(entries);
};

export const computeAmountToSchedule = (
  schedulingProcess: SchedulingProcess,
  billAmount: Money.MonetaryValue,
): Money.MonetaryValue =>
  Money.subtract(
    billAmount,
    [
      ...schedulingProcess.scheduledPayments,
      ...schedulingProcess.executedPayments,
      ...schedulingProcess.executingPayments,
      ...schedulingProcess.appliedCreditNotes,
    ].reduce(
      (total, item) => Money.add(total, item.amount),
      Money.fromNumber(0, billAmount.currency, 2),
    ),
  );

export const computeSchedulingProcessAmount = (
  schedulingProcess: SchedulingProcess,
  billAmount: Money.MonetaryValue,
): Money.MonetaryValue =>
  Money.subtract(
    billAmount,
    [...schedulingProcess.appliedCreditNotes].reduce(
      (total, item) => Money.add(total, item.amount),
      Money.fromNumber(0, billAmount.currency, 2),
    ),
  );

export const lastFailedSchedulingProcessEntry = (
  schedulingProcess: SchedulingProcess,
): SchedulingProcessEntryFailed | undefined => {
  const lastFailedPayment = schedulingProcess.failedPayments[0];
  const schedulingProcessEntry: SchedulingProcessEntryFailed | undefined =
    lastFailedPayment ? { ...lastFailedPayment, status: 'failed' } : undefined;

  return schedulingProcessEntry;
};
