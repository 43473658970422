import * as R from '@dev-spendesk/general-type-helpers/Result';
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { ScaWireTransferConfirmationModalContainer } from 'modules/StrongCustomerAuthentication';
import { type ScaState } from 'modules/StrongCustomerAuthentication/types';
import { useNotifications } from 'modules/app/notifications';
import { VerificationOfBeneficiariesModal } from 'modules/invoices/transfer/components/VerificationOfBeneficiariesModal';
import {
  useVerifyTransfersBeneficiaries,
  VerifyBeneficiariesQueryFactory,
} from 'modules/invoices/transfer/hooks';
import { type Beneficiary } from 'modules/invoices/transfer/models';
import { ModalMFAToken } from 'src/core/common/components/ModalMFA';
import { ScaWireTransferConfirmationEnrollmentModal } from 'src/core/modules/StrongCustomerAuthentication/components/ScaWireTransferConfirmationEnrollmentModal';

import { type Props as WireTransferApproverModalContainerProps } from './WireTransferApproverModalContainer';
import { WireTransfersApprovedModal } from './WireTransfersApprovedModal';
import { WireTransfersDeclinedModal } from './WireTransfersDeclinedModal';
import { WireTransfersNotEnoughFundsModal } from './WireTransfersNotEnoughFundsModal';
import { WireTransfersSummaryModal } from './WireTransfersSummaryModal';

enum ApproverModalStep {
  NotEnoughFunds,
  Summary,
  MfaToken,
  Confirmation,
  Sca,
  Declined,
  ScaEnrollment,
  BeneficiariesVerification,
}

export type Props = WireTransferApproverModalContainerProps & {
  showNotEnoughFundsWarning: boolean;
};

const missingSCAErrors = [
  'accountOwnerIsMissingMfaPhoneFactor',
  'unableToCreateScaProcedure',
];

const WireTransferApproverModal = ({
  amount,
  count,
  isApproving,
  showNotEnoughFundsWarning,
  isOpen,
  approvalAuthType,
  billType,
  selectedBatchId,
  selectedPaymentIds,
  onClose,
  onApprove,
  onComplete,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();
  const getVerifyBeneficiariesErrorMessage =
    VerifyBeneficiariesQueryFactory.useVerifyTransfersBeneficiariesErrorMessage();
  const [currentStep, setCurrentStep] = useState<ApproverModalStep>(
    ApproverModalStep.Summary,
  );
  const [unverifiedBeneficiaries, setUnverifiedBeneficiaries] = useState<
    Beneficiary.VerificationResult[]
  >([]);

  const verifyTransfersBeneficiaries = useVerifyTransfersBeneficiaries();

  useEffect(() => {
    if (showNotEnoughFundsWarning) {
      setCurrentStep(ApproverModalStep.NotEnoughFunds);
    }
    if (
      !showNotEnoughFundsWarning &&
      currentStep === ApproverModalStep.NotEnoughFunds
    ) {
      setCurrentStep(ApproverModalStep.Summary);
    }
  }, [showNotEnoughFundsWarning, isOpen]);

  const handleContinue = () => {
    setCurrentStep(ApproverModalStep.Summary);
  };

  useEffect(() => {
    if (approvalAuthType === 'sca') {
      setCurrentStep(ApproverModalStep.Sca);
    } else if (approvalAuthType === 'mfa') {
      setCurrentStep(ApproverModalStep.MfaToken);
    }
  }, [approvalAuthType]);

  const handleApprove = async () => {
    try {
      await onApprove();
    } catch (error) {
      if (
        missingSCAErrors.includes(error.response?.data?.reason) ||
        missingSCAErrors.includes(error.data?.reason)
      ) {
        setCurrentStep(ApproverModalStep.ScaEnrollment);
      } else {
        dangerNotif(t('wiretransfer.confirmation.confirmApi.error'));
      }
    }
  };

  const handleMfaTokenValidated = () => {
    setCurrentStep(ApproverModalStep.Confirmation);
  };

  const handleScaProceed = (state: ScaState): void => {
    if (state === 'approved') {
      setCurrentStep(ApproverModalStep.Confirmation);
    } else if (['rejected', 'timeout'].includes(state)) {
      setCurrentStep(ApproverModalStep.Declined);
    }
  };

  const handleComplete = () => {
    handleClose();
    onComplete();
  };

  const handleClose = () => {
    setCurrentStep(ApproverModalStep.Summary);
    onClose();
  };

  const renderScaModal = () => {
    const isScaModalOpen = isOpen && currentStep === ApproverModalStep.Sca;
    return (
      <ScaWireTransferConfirmationModalContainer
        isOpen={isScaModalOpen}
        onUseAlternateMethod={handleApprove}
        onClose={handleClose}
        onScaProceed={handleScaProceed}
      />
    );
  };

  const renderWireTransfersDeclinedModal = () => {
    const isWireTransfersDeclinedModalOpen =
      isOpen && currentStep === ApproverModalStep.Declined;

    return (
      <WireTransfersDeclinedModal
        isOpen={isWireTransfersDeclinedModalOpen}
        onClose={handleClose}
      />
    );
  };

  const onSummaryConfirm = async () => {
    if (!billType || (!selectedPaymentIds && !selectedBatchId)) {
      return;
    }

    const params = selectedBatchId
      ? {
          batchId: selectedBatchId,
          type: 'expense' as const,
        }
      : {
          paymentIds: selectedPaymentIds,
          type: billType,
        };
    try {
      const beneficiariesValidationResult =
        await verifyTransfersBeneficiaries(params);

      if (R.isFailure(beneficiariesValidationResult)) {
        setUnverifiedBeneficiaries(
          beneficiariesValidationResult.error.failedVerifications,
        );
        setCurrentStep(ApproverModalStep.BeneficiariesVerification);
      } else {
        handleApprove();
      }
    } catch (error) {
      dangerNotif(getVerifyBeneficiariesErrorMessage(error));
    }
  };

  return (
    <>
      <WireTransfersNotEnoughFundsModal
        count={count}
        onContinue={handleContinue}
        isOpen={isOpen && currentStep === ApproverModalStep.NotEnoughFunds}
      />
      <WireTransfersSummaryModal
        amount={amount}
        count={count}
        isApproving={isApproving}
        canApprove
        showNotEnoughFundsWarning={showNotEnoughFundsWarning}
        onApprove={onSummaryConfirm}
        onClose={handleClose}
        isOpen={isOpen && currentStep === ApproverModalStep.Summary}
      />
      <ModalMFAToken
        onMfaTokenValidated={handleMfaTokenValidated}
        onClose={handleClose}
        isOpen={isOpen && currentStep === ApproverModalStep.MfaToken}
        flow="standard"
      />
      {renderScaModal()}
      {renderWireTransfersDeclinedModal()}
      <WireTransfersApprovedModal
        count={count}
        onComplete={handleComplete}
        isOpen={isOpen && currentStep === ApproverModalStep.Confirmation}
      />
      <ScaWireTransferConfirmationEnrollmentModal
        isOpen={isOpen && currentStep === ApproverModalStep.ScaEnrollment}
        onClose={() => {
          handleClose();
        }}
        onEnroll={onApprove}
      />

      <VerificationOfBeneficiariesModal
        isOpen={
          isOpen && currentStep === ApproverModalStep.BeneficiariesVerification
        }
        unverifiedBeneficiaries={unverifiedBeneficiaries}
        billType={billType}
        onCancel={handleClose}
        onConfirm={handleApprove}
      />
    </>
  );
};

export default WireTransferApproverModal;
