import {
  Callout,
  DatePicker,
  FormField,
  IconButton,
} from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './AllocationPeriodFieldPeriod.module.css';

type Props = {
  startDate: Date;
  endDate: Date;
  errorStartDate?: string;
  errorEndDate?: string;
  onDatesChange(dates: { startDate: Date; endDate: Date }): void;
  onChangeToDate(date: Date): void;
};

export const AllocationPeriodFieldPeriod = ({
  startDate,
  endDate,
  errorStartDate,
  errorEndDate,
  onDatesChange,
  onChangeToDate,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <FormField
      label={t('budget.allocationPeriod.fieldLabel')}
      alertMessage={errorStartDate}
    >
      <div>
        <div className={styles.fields}>
          <div>
            <DatePicker
              value={startDate}
              onChange={(date) => {
                if (date) {
                  onDatesChange({ startDate: date, endDate });
                }
              }}
              className="w-full"
              fit="content"
              maxDate={endDate}
            />
          </div>
          <div>
            <DatePicker
              value={endDate}
              onChange={(date) => {
                if (date) {
                  onDatesChange({ startDate, endDate: date });
                }
              }}
              placeholder={t('budget.allocationPeriod.endPlaceholder')}
              className="w-full"
              fit="content"
              minDate={startDate}
              placement="bottom-end"
            />
          </div>
          <IconButton
            iconName="minus-circle"
            variant="borderless"
            onClick={(event) => {
              event.preventDefault();
              onChangeToDate(startDate);
            }}
            aria-label={t('misc.delete')}
          />
        </div>
        {errorEndDate && (
          <Callout className="mt-xs" variant="alert" title={errorEndDate} />
        )}
      </div>
    </FormField>
  );
};
