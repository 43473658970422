import { QueryClient, type DefaultOptions } from 'react-query';

import { createQueryCache } from './cache';

export const createQueryClient = (
  options: DefaultOptions = {},
): QueryClient => {
  // Needed by `persistQueryClient()`
  if (process.env.NODE_ENV === 'development') {
    options.queries = {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    };
  }

  return new QueryClient({
    defaultOptions: options,
    queryCache: createQueryCache(),
  });
};

export const appQueryClient = createQueryClient();

export { QueryClient } from 'react-query';
