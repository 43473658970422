import { type ApprovalRuleApi } from 'modules/company/structure/approval-flows';
import { apiUrl } from 'src/core/utils/api';

type EditionPayload = {
  rules: ApprovalRuleApi[];
};

export const editApprovalScheme = async ({
  approvalSchemeId,
  payload,
  companyId,
}: {
  approvalSchemeId: string;
  payload: EditionPayload;
  companyId: string;
}): Promise<void> => {
  const response = await fetch(
    apiUrl(`/approval-scheme/${approvalSchemeId}`, companyId),
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    },
  );
  if (!response.ok) {
    throw new Error('Impossible to edit an approval scheme');
  }
};
