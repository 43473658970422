import { Panel, Skeleton, SkeletonText } from '@dev-spendesk/grapes';

export const IntegrationConfigurationSkeleton = () => {
  return (
    <Panel className="w-full">
      <div className="flex flex-col gap-m">
        <SkeletonText className="w-[300px]" size="xxl" />
        <div className="flex gap-m">
          <Skeleton width="50%" height="90px" />
          <Skeleton width="50%" height="90px" />
        </div>
        <SkeletonText className="w-[300px]" size="xxl" />
        <Skeleton width="100%" height="150px" />
      </div>
    </Panel>
  );
};
