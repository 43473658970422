import { type I18nKey } from 'common/hooks/useTranslation';
import type { User } from 'modules/app/hooks/useUser';
import { type RequestType } from 'modules/requests/models';
import {
  SubnavigationItem,
  hasApproverSubNav,
  isDraftsTab,
} from 'modules/requests/utils';

import { type RequestStats, type Sections } from '../requestsSectionsProps';

type SectionType = Record<
  string,
  {
    translationKey: I18nKey;
    sectionStatsKey: keyof Sections<RequestStats>;
  }
>;

export const sectionGroupByRequestType: Record<RequestType, string> = {
  single_purchase: 'purchase',
  subscription: 'purchase',
  subscription_increase: 'purchase',
  card_load: 'purchase',
  expense: 'expense',
  invoice: 'invoice',
  purchase_order: 'purchase_order',
  mileage_allowance: 'mileage_allowance',
  per_diem_allowance: 'per_diem_allowance',
  credit_note: 'credit_note',
};

export const sectionsByCategory: {
  team: SectionType;
  teamToApprove: SectionType;
  personal: SectionType;
  draft: SectionType;
} = {
  team: {
    purchase: {
      translationKey: 'requests.teamPurchases',
      sectionStatsKey: 'purchaseRequests',
    },
    expense: {
      translationKey: 'requests.teamExpenseClaims',
      sectionStatsKey: 'expenseClaimRequests',
    },
    invoice: {
      translationKey: 'requests.teamInvoices',
      sectionStatsKey: 'invoiceRequests',
    },
    purchase_order: {
      translationKey: 'requests.teamPurchaseOrders',
      sectionStatsKey: 'purchaseOrderRequests',
    },
    mileage_allowance: {
      translationKey: 'requests.teamMileageAllowances',
      sectionStatsKey: 'mileageAllowanceRequests',
    },
    per_diem_allowance: {
      translationKey: 'requests.teamPerDiemAllowances',
      sectionStatsKey: 'perDiemAllowanceRequests',
    },
    credit_note: {
      translationKey: 'requests.teamCreditNotes',
      sectionStatsKey: 'creditNoteRequests',
    },
  },
  teamToApprove: {
    purchase: {
      translationKey: 'requests.teamPurchases',
      sectionStatsKey: 'teamPurchaseRequests',
    },
    expense: {
      translationKey: 'requests.teamExpenseClaims',
      sectionStatsKey: 'teamExpenseClaimRequests',
    },
    invoice: {
      translationKey: 'requests.teamInvoices',
      sectionStatsKey: 'teamInvoiceRequests',
    },
    purchase_order: {
      translationKey: 'requests.teamPurchaseOrders',
      sectionStatsKey: 'teamPurchaseOrderRequests',
    },
    mileage_allowance: {
      translationKey: 'requests.teamMileageAllowances',
      sectionStatsKey: 'teamMileageAllowanceRequests',
    },
    per_diem_allowance: {
      translationKey: 'requests.teamPerDiemAllowances',
      sectionStatsKey: 'teamPerDiemAllowanceRequests',
    },
    credit_note: {
      translationKey: 'requests.teamCreditNotes',
      sectionStatsKey: 'teamCreditNoteRequests',
    },
  },
  personal: {
    purchase: {
      translationKey: 'requests.myPurchases',
      sectionStatsKey: 'myPurchaseRequests',
    },
    expense: {
      translationKey: 'requests.myExpenseClaims',
      sectionStatsKey: 'myExpenseClaimRequests',
    },
    invoice: {
      translationKey: 'requests.myInvoices',
      sectionStatsKey: 'myInvoiceRequests',
    },
    purchase_order: {
      translationKey: 'requests.myPurchaseOrders',
      sectionStatsKey: 'myPurchaseOrderRequests',
    },
    mileage_allowance: {
      translationKey: 'requests.myMileageAllowances',
      sectionStatsKey: 'myMileageAllowanceRequests',
    },
    per_diem_allowance: {
      translationKey: 'requests.myPerDiemAllowances',
      sectionStatsKey: 'myPerDiemAllowanceRequests',
    },
    credit_note: {
      translationKey: 'requests.myCreditNotes',
      sectionStatsKey: 'myCreditNoteRequests',
    },
  },
  draft: {
    expense: {
      translationKey: 'requests.myDraftExpenseClaims',
      sectionStatsKey: 'myDraftExpenseClaimRequests',
    },
    invoice: {
      translationKey: 'requests.myDraftInvoices',
      sectionStatsKey: 'myDraftInvoiceRequests',
    },
    credit_note: {
      translationKey: 'requests.myDraftCreditNotes',
      sectionStatsKey: 'myDraftCreditNoteRequests',
    },
    // no drafts for those types, should not happen
    purchase: {
      translationKey: 'requests.myPurchases',
      sectionStatsKey: 'myPurchaseRequests',
    },
    purchase_order: {
      translationKey: 'requests.myPurchaseOrders',
      sectionStatsKey: 'myPurchaseOrderRequests',
    },
    mileage_allowance: {
      translationKey: 'requests.myMileageAllowances',
      sectionStatsKey: 'myMileageAllowanceRequests',
    },
    per_diem_allowance: {
      translationKey: 'requests.myPerDiemAllowances',
      sectionStatsKey: 'myPerDiemAllowanceRequests',
    },
  },
};

export const getSection = (type: SubnavigationItem | 'request', user: User) => {
  if (isDraftsTab(type)) {
    return sectionsByCategory.draft;
  }

  if (type === SubnavigationItem.All && hasApproverSubNav(user)) {
    return sectionsByCategory.team;
  }

  // Controller who are not approver can see the "All tab" and need to have the whole counters
  if (type === SubnavigationItem.All && user.is_controller) {
    return sectionsByCategory.teamToApprove;
  }

  if (type === SubnavigationItem.ToApprove && hasApproverSubNav(user)) {
    return sectionsByCategory.teamToApprove;
  }

  return sectionsByCategory.personal;
};
