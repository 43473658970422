import { useMutation } from 'src/core/api/hooks/useMutation';

import { useInvalidateControlRulesQuery } from './useControlRulesQuery';
import { useInvalidateMemberQuery } from './useMemberQuery';

type Payload = {
  user_ids: string[];
  endpointParams: {
    policyId: string;
  };
};

export const useBulkUpdateApprovalPolicyMutation = () => {
  const invalidateMembers = useInvalidateMemberQuery();
  const invalidateControlRules = useInvalidateControlRulesQuery();
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: ({ policyId }) => `userpolicies/${policyId}`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ payload }): void => {
        invalidateMembers(payload.user_ids);
        invalidateControlRules();
      },
    },
  });
};
