import { SwitchField } from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import {
  type DefaultSupplierAccount,
  type SupplierAccountDefaultFor,
} from 'modules/bookkeep/settings/accounting';
import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { ConfirmDisableDefaultSupplierAccountModal } from './ConfirmDisableDefaultSupplierAccountModal';
import { DefaultSupplierSelect } from './DefaultSupplierSelect';
import { useSetDefaultSupplierAccountMutation } from '../../../../../hooks/useSetDefaultSupplierAccountMutation';
import {
  getDescriptionTranslation,
  getTitleTranslation,
} from '../translations';

type SectionProps = {
  defaultFor: SupplierAccountDefaultFor;
  savedDefaultSupplierFor: DefaultSupplierAccount | undefined;
  integrationStatus: IntegrationStatusWithIntegration;
  t: TGlobalFunctionTyped;
  onSelectOption?: (id: string) => void;
};

export const DefaultSupplierToggle = ({
  defaultFor,
  savedDefaultSupplierFor,
  integrationStatus,
  t,
  onSelectOption,
}: SectionProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(
    !!savedDefaultSupplierFor,
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const { pushNotif } = useNotifications();
  const [setDefaultSupplierAccountFor] = useSetDefaultSupplierAccountMutation();

  useEffect(() => {
    if (savedDefaultSupplierFor && !isChecked) {
      disableSupplierAccount();
    }
  }, [isChecked, savedDefaultSupplierFor]);

  const disableSupplierAccount = async () => {
    if (savedDefaultSupplierFor && !isChecked) {
      await setDefaultSupplierAccountFor({
        ...savedDefaultSupplierFor,
        defaultFor,
        isArchived: true,
      });

      pushNotif({
        type: NotificationType.Success,
        message: t(
          'bookkeep.integrations.settings.supplierAccountsTable.successMessage',
        ),
      });
    }
  };

  return (
    <div className="box mt-m">
      <SwitchField
        id={`useDefaultAccount_${defaultFor}`}
        name={`useDefaultAccount_${defaultFor}`}
        label={t(getTitleTranslation(defaultFor))}
        helpText={t(getDescriptionTranslation(defaultFor))}
        fit="parent"
        isChecked={isChecked}
        onChange={() => {
          if (!isChecked) {
            setIsChecked(!isChecked);
          } else {
            setShowModal(true);
          }
        }}
      />
      {isChecked && (
        <DefaultSupplierSelect
          defaultFor={defaultFor}
          savedDefaultSupplierFor={savedDefaultSupplierFor}
          integrationStatus={integrationStatus}
          t={t}
          onSelectOption={onSelectOption}
        />
      )}

      <ConfirmDisableDefaultSupplierAccountModal
        defaultSupplierAccount={
          savedDefaultSupplierFor ? [savedDefaultSupplierFor] : []
        }
        showModal={showModal}
        setShowModal={setShowModal}
        handleDisableDefaultSupplierAccount={() => {
          setShowModal(false);
          setIsChecked(!isChecked);
        }}
      />
    </div>
  );
};
