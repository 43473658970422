import React from 'react';

import { EmptyStateError, lazyLoad } from 'common/components/LazyLoad';

import { MembersSettingsPageSkeleton } from './MembersSettingsPageSkeleton';

export const MembersSettingsPage = lazyLoad({
  loader: async () => ({
    /* eslint-disable unicorn/no-await-expression-member */
    default: (
      await import(
        /* webpackChunkName: "MembersSettingsPage" */ './MembersSettingsPage'
      )
    ).MembersSettingsPage,
    /* eslint-enable */
  }),
  loading: <MembersSettingsPageSkeleton />,
  fallback: (props) => <EmptyStateError {...props} />,
});
