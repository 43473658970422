import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type {
  KomboFiltersResponse,
  KomboAvailableFilterByDimension,
  SupportedKomboFilterDimension,
} from '../types';
import { supportedKomboFilterDimensionSchema } from '../types';

type AvailableFilter = {
  dimension: SupportedKomboFilterDimension;
  values: {
    komboName: string;
    komboId: string;
  }[];
};

export const useGetKomboAvailableFiltersQuery = (
  isEnabled: boolean,
): QueryState<KomboAvailableFilterByDimension> => {
  return useQuery<KomboAvailableFilterByDimension, KomboFiltersResponse>({
    key: ['komboFilters'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/integrations/kombo/filters',
    },
    isEnabled,
    reshapeData: (data) => {
      const knownAvailableFilters = data.filters.map((dimensionValues) => {
        const knownDimension = supportedKomboFilterDimensionSchema.safeParse(
          dimensionValues.dimension,
        );
        if (knownDimension.success) {
          return {
            dimension: knownDimension.data,
            values: dimensionValues.values.sort((filterValueA, filterValueB) =>
              compareStrings(filterValueA.komboName, filterValueB.komboName),
            ),
          };
        }

        return undefined;
      });

      const knownAvailableFiltersWithoutHoles = knownAvailableFilters.filter(
        (filter): filter is AvailableFilter => filter !== undefined,
      );

      return new Map(
        knownAvailableFiltersWithoutHoles.map((availableFilter) => [
          availableFilter.dimension,
          availableFilter.values,
        ]),
      );
    },
  });
};

const compareStrings = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
