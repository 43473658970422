import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  useCancelTransferErrorMessage,
  useCancelTransfers,
  useConfirmTransfersTrackingEvents,
} from '../../hooks';
import { sumTransfersAmount, type Transfer } from '../../models';

type Props = {
  isOpen: boolean;
  transfers: Transfer[];
  onClose: () => void;
  onConfirm?: () => void;
};

export const TransferCancelModal = ({
  isOpen,
  transfers,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation('global');
  const confirmTransfersTrackingEvents = useConfirmTransfersTrackingEvents();
  const { pushNotif } = useNotifications();
  const companyCurrency = useCompanyCurrency();
  const [
    cancelTransfers,
    cancelTransfersQueryState,
    resetCancelTransfersQueryState,
  ] = useCancelTransfers();

  const getCancelTransferErrorMessage = useCancelTransferErrorMessage();

  const transferIds = useMemo(
    () => transfers.map((transfer) => transfer.id),
    [transfers],
  );
  const transfersCount = transfers.length;
  const transfersAmount = sumTransfersAmount(transfers, companyCurrency);

  useEffect(() => {
    if (isOpen) {
      resetCancelTransfersQueryState();
    }
  }, [isOpen]);

  const handleCancelTransfers = async () => {
    confirmTransfersTrackingEvents.onConfirmTransfersRejectionModalConfirmed({
      transfers,
    });
    await cancelTransfers({ transferIds });
    pushNotif({
      type: NotificationType.Success,
      message: t('invoices.transfer.cancel.success', {
        count: transfersCount,
      }),
    });
    onClose();

    if (onConfirm) {
      onConfirm();
    }
  };
  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('invoices.transfer.modal.reject.cancel')}
          variant="secondary"
          isDisabled={cancelTransfersQueryState.status === 'loading'}
        />,
        <Button
          key="reject-transfers"
          text={t('invoices.transfer.modal.reject.confirm', {
            count: transfersCount,
          })}
          variant="warning"
          onClick={handleCancelTransfers}
        />,
      ]}
      iconName="warning"
      iconVariant="warning"
      title={t('invoices.transfer.modal.reject.title', {
        count: transfersCount,
        amount: formatMonetaryValue(transfersAmount),
      })}
      isOpen={isOpen}
    >
      <p>
        {t('invoices.transfer.modal.reject.subtitle', {
          count: transfersCount,
        })}
      </p>
      {cancelTransfersQueryState.status === 'error' && (
        <Callout
          title={getCancelTransferErrorMessage(
            cancelTransfersQueryState.error,
            { count: transfersCount },
          )}
          variant="alert"
          className="mt-xs"
        />
      )}
    </Modal>
  );
};
