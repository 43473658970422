import React from 'react';

import { MembersTableLoader } from '../MembersTableLoader';

import './MembersSettingsPage.css';

export const MembersSettingsPageSkeleton = () => {
  return (
    <div className="MembersSettingsPage">
      <div className="MembersSettingsPage__content">
        <MembersTableLoader />
      </div>
    </div>
  );
};
