import { type CompanyWalletSummary } from '../../companyWalletSummary';
import { type DetailedWalletSummary } from '../../wallet/walletSummary';

export const hasCompanyNegativeAvailableAmount = (
  company: CompanyWalletSummary | DetailedWalletSummary,
): boolean => {
  return (
    'walletDetails' in company &&
    company.walletDetails.available.totalAmount.amount < 0
  );
};
