type Mergeable = {
  [key: string]: unknown;
};

export const isArrayOfString = <T extends string>(
  array: unknown,
): array is T[] => {
  return (
    !!array &&
    Array.isArray(array) &&
    array.length > 0 &&
    array.every((item) => typeof item === 'string')
  );
};

export const intersection = <T>(...arrays: T[][]): T[] => {
  const firstArray = arrays[0];
  if (!firstArray) {
    return [];
  }
  return firstArray.filter((item: T) =>
    arrays.every((array: T[]) => array.includes(item)),
  );
};

export const smartMerge = <T extends Mergeable>(
  a: T[] | undefined,
  b: T[] | undefined,
  property: keyof T,
): T[] => {
  if (!a?.length) {
    return b?.length ? b : [];
  }

  const newA = [...a];

  b?.forEach((itemB: T) => {
    const itemA = newA.find((item: T) => item[property] === itemB[property]);

    if (itemA) {
      Object.assign(itemA, itemB);
    } else {
      newA.push(itemB);
    }
  });

  return newA;
};
