import { Callout } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { usePurchaseOrdersRoute } from '../../hooks';

export type Props = {
  className?: string;
};

export const NoAvailablePurchaseOrderMessage = ({ className }: Props) => {
  const purchaseOrdersRoute = usePurchaseOrdersRoute()();

  const title = (
    <Trans
      i18nKey="purchaseOrders.noneAvailableMessage"
      components={{
        url: <Link to={purchaseOrdersRoute} />,
      }}
    />
  );

  return <Callout className={className} title={title} variant="info" />;
};
