import { type QueryState } from 'src/core/api/queryState';

import { useFetchUserDelegationsQuery } from './useFetchUserDelegationsQuery';
import { useHasWireTransferDelegationFeature } from './useHasWireTransferDelegationFeature';
import { type Delegation } from '../models';

export const useUserHasDelegationQuery = (
  userId: string,
  delegation: Delegation,
  options: {
    isEnabled: boolean;
  } = { isEnabled: true },
): QueryState<boolean> => {
  const hasWireTransferConfirmationFeature =
    useHasWireTransferDelegationFeature();

  const userDelegationsQueryState = useFetchUserDelegationsQuery(userId, {
    isEnabled: hasWireTransferConfirmationFeature && options.isEnabled,
  });

  if (!hasWireTransferConfirmationFeature) {
    return {
      status: 'success',
      data: false,
    };
  }

  if (userDelegationsQueryState.status === 'success') {
    return {
      ...userDelegationsQueryState,
      data: userDelegationsQueryState.data.delegationList.includes(delegation),
    };
  }

  return userDelegationsQueryState;
};
