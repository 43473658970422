import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { GET_CUSTOM_FIELD_VALUES } from './query';

export type CustomFieldValue = {
  id: string;
  name: string;
};

type RawData = {
  company: {
    customFields: {
      values: {
        pageInfo: {
          hasNextPage: boolean;
          cursor?: string;
        };
        totalCount: number;
        edges: {
          cursor: string;
          node: {
            id: string;
            name: string;
          };
        }[];
      };
    }[];
  };
};

export const useCustomFieldValuesQuery = (
  customFieldId: string,
  { search }: { search?: string } = {},
): InfiniteQueryState<CustomFieldValue[]> => {
  return useInfiniteQuery<CustomFieldValue, RawData>({
    key: ['useCustomFieldValuesQuery', customFieldId, { search }],
    getRequest: (cursor) => {
      return {
        type: 'graphQL',
        target: 'v2',
        query: GET_CUSTOM_FIELD_VALUES,
        variables: {
          customFieldId,
          includeArchivedValues: false,
          after: cursor,
          search,
        },
      };
    },
    getNextPageParam: (data) => {
      const { edges, pageInfo } = data.company.customFields[0].values;
      const lastEdge = edges.at(-1);

      if (pageInfo.hasNextPage && lastEdge) {
        return lastEdge.cursor;
      }
      return undefined;
    },
    reshapeData: (rawData) => {
      const { edges } = rawData.company.customFields[0].values;
      return edges.map((edge) => ({
        id: edge.node.id,
        name: edge.node.name,
      }));
    },
  });
};
