import type { PayableRequest } from '@finance-review/models/payable';
import {
  type MutationState as MutationStateType,
  useMutation as useQueryMutation,
} from 'src/core/api/hooks/useMutation';
import { type MutationQueryState } from 'src/core/api/queryState';

export type Response = {
  successes: { id: PayableRequest.EntityId }[];
  errors: {
    requestId: PayableRequest.EntityId;
    errorCode: string;
    errorMessage: string;
  }[];
};
export type Payload = {
  rejectReason: string;
  requestIds: PayableRequest.EntityId[];
};
export type QueryState = MutationQueryState<Response, unknown>;
export type MutationState = MutationStateType<Payload, Response, unknown>;

export const useMutation = (
  options: {
    onError?: () => void;
    onSuccess?: (requestId: PayableRequest.EntityId) => void;
    onComplete?: () => void;
  } = {},
): MutationStateType<Payload, Response, unknown> => {
  return useQueryMutation<Payload, Response, Response, unknown>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/reject-by-controller`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess({ data }) {
        if (data.errors.length > 0) {
          options.onError?.();
        }
        if (data.successes.length > 0 && options.onSuccess) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          data.successes.forEach(({ id }) => options.onSuccess!(id));
        }
        options.onComplete?.();
      },
      onError: () => {
        options.onError?.();
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};

export const hasSomeFailed = (
  rejectInvoiceRequestQueryState: QueryState,
): boolean =>
  rejectInvoiceRequestQueryState.status === 'success' &&
  rejectInvoiceRequestQueryState.data.errors.length > 0;
