import {
  type DetailedWalletSummary,
  type WalletSummary,
} from './wallet/walletSummary';

type CreatingCompany = {
  id: string;
  name: string;
  bankingProvider: 'bankable' | 'marqeta' | 'sfs';
  status: 'creating';
};

export type InReviewCompany = WalletSummary & {
  status: 'in-review';
  bankingProvider: 'bankable' | 'marqeta' | 'sfs';
};

export type ActiveCompany = {
  status: 'active';
} & (WalletSummary | DetailedWalletSummary);

export type CompanyWalletSummary =
  | CreatingCompany
  | InReviewCompany
  | ActiveCompany;

export const isCreatingCompany = (
  company: CompanyWalletSummary,
): company is CreatingCompany => {
  return company.status === 'creating';
};

export const isInReviewCompany = (
  company: CompanyWalletSummary,
): company is InReviewCompany => {
  return company.status === 'in-review';
};
