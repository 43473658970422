import {
  Skeleton,
  SkeletonButton,
  SkeletonTag,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import { CenteredLayout } from 'common/components/layout';

type Props = {
  withBoxes?: boolean;
};

export const BudgetPageLoader = ({ withBoxes = true }: Props) => {
  return (
    <CenteredLayout>
      <div className="mt-xxl">
        <SkeletonText size="xxl" width="200px" />
        <div className="flex items-center justify-between">
          <SkeletonTag width="250px" />
          <div className="flex items-center gap-s">
            <SkeletonButton />
            <Skeleton height="36px" width="36px" />
          </div>
        </div>
        {withBoxes && (
          <div className="mt-m flex items-center justify-between gap-l">
            <div className="box w-full">
              <SkeletonText size="l" width="100px" className="mb-3xl" />
              <Skeleton height="16px" width="100%" />
            </div>
            <div className="box w-full">
              <SkeletonText size="l" width="100px" className="mb-3xl" />
              <Skeleton height="16px" width="100%" />
            </div>
          </div>
        )}
      </div>
    </CenteredLayout>
  );
};
