import { Toast } from '@dev-spendesk/grapes';
import React from 'react';

import { type Notification as TypedNotification } from '../../notification';

type Props = Readonly<{
  className?: string;
  notification: TypedNotification;
  removeNotification: (notificationId: number) => void;
}>;

export const Notification = ({
  className,
  notification,
  removeNotification,
}: Props) => {
  const handleClose = () => {
    if (notification.onClose) {
      notification.onClose();
    }
    removeNotification(notification.id);
  };

  const handleActionClicked = () => {
    if (notification.action) {
      notification.action.onClick(notification);
    }
    removeNotification(notification.id);
  };

  const variant = notification.type === 'success' ? 'success' : 'alert';
  const hasAction = notification.action !== undefined;

  return (
    <Toast
      variant={variant}
      onClose={handleClose}
      title={notification.message}
      className={className}
      canExpire={notification.canExpire}
      action={
        hasAction
          ? {
              text: notification.action?.text || ' ',
              onClick: handleActionClicked,
            }
          : undefined
      }
    >
      {notification.description}
    </Toast>
  );
};
