import { type FormikErrors, yupToFormErrors, validateYupSchema } from 'formik';
import * as yup from 'yup';

import { filterOutUndefinedProperties } from 'common/utils/filterOutUndefinedProperties';
import * as Schemas from 'common/utils/forms/schemas';

import type * as BudgetTrackingTrait from './budgetTrackingTrait';
import * as CustomFieldAssociation from '../customFieldAssociation';

export type ValidationContext = {
  isCostCenterRequired: boolean;
  isTeamRequired: boolean;
  requiredCustomFields?: string[];
};

export type CustomFieldAssociationError = {
  [customFieldId: string]: Schemas.RequiredError;
};

export type Errors = {
  costCenterId?: Schemas.RequiredError;
  customFieldsAssociations?: CustomFieldAssociationError;
  teamId?: Schemas.RequiredError;
};

export type ToFormikErrors<T extends BudgetTrackingTrait.Trait> = Omit<
  FormikErrors<T>,
  'customFieldsAssociations'
> & { customFieldsAssociations?: CustomFieldAssociationError };

// custom type because the customField error shape is not compliant with formik
export type BudgetTrackingFormikErrors =
  ToFormikErrors<BudgetTrackingTrait.Trait>;

export const validate = (
  budgetTracking: BudgetTrackingTrait.Trait,
  validationContext: ValidationContext,
): BudgetTrackingFormikErrors | undefined => {
  const customFieldError = validateCustomFields(
    budgetTracking,
    validationContext,
  );

  try {
    validateYupSchema(budgetTracking, budgetTrackingValidationSchema, true, {
      ...validationContext,
      budgetTracking,
    });
    return customFieldError
      ? { customFieldsAssociations: customFieldError }
      : undefined;
  } catch (e) {
    return {
      ...(yupToFormErrors<BudgetTrackingTrait.Trait>(
        e,
      ) as BudgetTrackingFormikErrors),
      ...(customFieldError
        ? { customFieldsAssociations: customFieldError }
        : {}),
    };
  }
};

export const fromFormikErrors = (
  formikErrors: BudgetTrackingFormikErrors,
): Errors =>
  filterOutUndefinedProperties({
    costCenterId: Schemas.parseToRequiredError(formikErrors.costCenterId),
    customFieldsAssociations:
      formikErrors.customFieldsAssociations as unknown as CustomFieldAssociationError,
    teamId: Schemas.parseToRequiredError(formikErrors.teamId),
  });

const validateCustomFields = (
  budgetTracking: BudgetTrackingTrait.Trait,
  { requiredCustomFields }: ValidationContext,
): Errors['customFieldsAssociations'] => {
  if (!requiredCustomFields || requiredCustomFields.length === 0) {
    return undefined;
  }

  const { customFieldsAssociations } = budgetTracking;
  const error = requiredCustomFields.reduce(
    (
      errorAccumulator: { [customFieldId: string]: Schemas.RequiredError },
      requiredCustomFieldId: string,
    ) => {
      const isRequiredCustomFieldDefined = customFieldsAssociations.some(
        (customFieldsAssociation) =>
          customFieldsAssociation.customFieldId === requiredCustomFieldId &&
          CustomFieldAssociation.isDefinedCustomFieldAssociation(
            customFieldsAssociation,
          ),
      );

      if (!isRequiredCustomFieldDefined) {
        errorAccumulator[requiredCustomFieldId] = Schemas.requiredError;
      }
      return errorAccumulator;
    },
    {},
  );

  return Object.keys(error).length === 0 ? undefined : error;
};

const budgetTrackingValidationSchema = yup.object().shape({
  costCenterId: yup.string().when('$isCostCenterRequired', {
    is: true,
    then: () => Schemas.text,
    otherwise: () => Schemas.optionalText,
  }),
  teamId: yup.string().when('$isTeamRequired', {
    is: true,
    then: () => Schemas.text,
    otherwise: () => Schemas.optionalText,
  }),
});
