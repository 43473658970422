import { useQuery } from 'src/core/api/hooks/useQuery';

import {
  type RawFinanceController,
  transformRawFinanceController,
} from './transformers';
import { type FinanceController } from '../../models';

type RawResponse = {
  financeControllers: RawFinanceController[];
};

export const useFetchFinanceControllers = () => {
  return useQuery<FinanceController[], RawResponse>({
    key: ['financeControllers'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/finance-controllers`,
    },
    reshapeData: (data) =>
      data.financeControllers.map(transformRawFinanceController),
  });
};
