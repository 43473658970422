import { SEPACountries } from 'src/core/config/country';

export const getShouldFillBankInfoCompletely = ({
  companyCurrency,
  bankCountry,
  iban,
  bic,
}: {
  companyCurrency: string;
  bankCountry: string | null;
  iban: string | null;
  bic: string | null;
}): boolean => {
  const isBankInfoRecommended = getIsBankInfoRecommended({
    companyCurrency,
    bankCountry,
  });

  const hasStartedFillingBankInfo = Boolean(iban) || Boolean(bic);
  return isBankInfoRecommended && hasStartedFillingBankInfo;
};

export const getIsBankInfoRecommended = ({
  companyCurrency,
  bankCountry,
}: {
  companyCurrency: string;
  bankCountry: string | null;
}): boolean => {
  // supplier bank info are recommended for SEPA supplier bank country and
  // if the company wallet currency is in euro, otherwise XML SEPA won't be
  // available
  return (
    companyCurrency === 'EUR' &&
    bankCountry !== null &&
    SEPACountries.includes(bankCountry)
  );
};
