import { FormField, OptionGroup } from '@dev-spendesk/grapes';
import { useTranslation } from 'react-i18next';

type Props = {
  isRequired: boolean;
  onChange: (isRequired: boolean) => void;
};

export const CustomFieldIsRequiredFormField = ({
  isRequired,
  onChange,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <FormField
      label={t('customFields.creation.typeLabel')}
      description={t('customFields.creation.typeDescription')}
    >
      <OptionGroup
        name="isRequired"
        options={[
          {
            value: 'mandatory',
            label: t('customFields.creation.typeOptionMandatory'),
          },
          {
            value: 'optional',
            label: t('customFields.creation.typeOptionOptional'),
          },
        ]}
        value={isRequired ? 'mandatory' : 'optional'}
        onChange={(event) => {
          onChange(event.target.value === 'mandatory');
        }}
      />
    </FormField>
  );
};
