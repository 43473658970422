import { formatISO } from 'date-fns';

import { type ExpenseCategory } from 'modules/budgets/models/expenseCategory';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import { useBreakdownQuery } from '../containers/hooks/useBreakdownQuery';
import { useExpenseCategoriesQuery } from '../containers/hooks/useExpenseCategoriesQuery';
import { type Breakdown, type BudgetBreakdown } from '../models/breakdown';
import { type ExpenseCategoryBreakdown } from '../models/expenseCategoryBreakdown';
import { type PeriodRangePayload } from '../models/period';

type RawBreakdown = {
  [ecId: string]: BudgetBreakdown;
};

export const useExpenseCategoriesBreakdownQuery = (
  budgetId: string,
  periodRange: PeriodRangePayload,
): QueryState<{
  expenseCategoriesBreakdown: ExpenseCategoryBreakdown[];
  expenseCategories: ExpenseCategory[];
  breakdown: Breakdown;
}> => {
  const params = periodRange && {
    startDate:
      periodRange.from &&
      formatISO(periodRange.from, { representation: 'date' }),
    endDate:
      periodRange.to && formatISO(periodRange.to, { representation: 'date' }),
  };

  const expenseCategoriesBreakdownQueryState = useQuery<
    RawBreakdown,
    RawBreakdown
  >({
    key: ['budgets', budgetId, 'expenseCategories', 'breakdown', params],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/budgets/budgets/${budgetId}/expense-categories/breakdown`,
      params,
    },
    reshapeData(data) {
      return data;
    },
  });

  return useQueryStates({
    states: {
      expenseCategoriesBreakdown: expenseCategoriesBreakdownQueryState,
      expenseCategories: useExpenseCategoriesQuery({ withArchived: true }),
      breakdown: useBreakdownQuery(budgetId, periodRange),
    },
    reshapeData({ expenseCategoriesBreakdown, expenseCategories, breakdown }) {
      return {
        expenseCategoriesBreakdown: Object.entries(
          expenseCategoriesBreakdown,
        ).map(([id, item]) => {
          if (id === 'other') {
            return {
              expenseCategory: { id, isOther: true },
              used: item.used,
              usedExceeded: item.usedExceeded,
              available: item.available,
              committed: item.committed,
              committedExceeded: item.committedExceeded,
            };
          }
          const expenseCategory = expenseCategories.find((ec) => ec.id === id);
          return {
            expenseCategory: {
              id,
              name: expenseCategory?.name ?? '',
              isOther: false,
            },
            used: item.used,
            usedExceeded: item.usedExceeded,
            available: item.available,
            committed: item.committed,
            committedExceeded: item.committedExceeded,
          };
        }),
        expenseCategories,
        breakdown,
      };
    },
  });
};
