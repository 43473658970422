import { type RawInvoiceData } from '@finance-review/apis/invoice/transformers';
import { type CreditNoteId } from '@finance-review/models/credit-note';
import { type InvoiceRequest } from '@finance-review/models/invoice';
import { type Translations } from 'common/components/QueryError';
import { useUpdateCacheWhenCreditChange } from 'modules/finance-review/hooks/invoice';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type RecordAttachedCreditToSupplierError = {
  outcome: 'notDetached';
} & (
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason:
        | 'invalidValidationAction'
        | 'inconsistentCurrency'
        | 'noInvoiceRequests'
        | 'nothingToDetach'
        | 'invalidTransactionCommit'
        | 'invalidTransactionRollback';
    }
  | {
      reason: 'invoiceRequestNotFound';
      invoiceRequestId: string;
    }
  | {
      reason:
        | 'requestNotFound'
        | 'invalidRequestStatus'
        | 'cannotReplaceScheduledPayments';
      requestId: string;
    }
  | {
      reason:
        | 'invalidValidationAction'
        | 'inconsistentCurrency'
        | 'nonReimbursableCredit';
      creditNoteId: string;
    }
);

type Payload = {
  invoiceRequestId: InvoiceRequest.EntityId;
};

export type RecordAttachedCreditToSupplierResponse = {
  outcome: 'detached';
  detachedCreditNoteIds: CreditNoteId[];
  invoiceRequest: RawInvoiceData;
};

export const useRecordAttachedCreditToSupplier = (): MutationState<
  Payload,
  RecordAttachedCreditToSupplierResponse,
  RecordAttachedCreditToSupplierError
> => {
  const updateCacheWhenCreditChange = useUpdateCacheWhenCreditChange();

  return useMutation<
    Payload,
    RecordAttachedCreditToSupplierResponse,
    RecordAttachedCreditToSupplierResponse,
    RecordAttachedCreditToSupplierError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/credit_note_requests/record_attached_credit_to_supplier`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data }) => {
        if (data.outcome === 'detached') {
          updateCacheWhenCreditChange(data.invoiceRequest);
        }
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};

export const recordAttachedCreditToSupplierErrorTranslations: Translations<RecordAttachedCreditToSupplierError> =
  {
    requestError: () => {
      return 'creditNote.query.recordAttachedCreditToSupplier.error' as const;
    },
    serverError:
      'creditNote.query.recordAttachedCreditToSupplier.error' as const,
  };
