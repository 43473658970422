import {
  type DeclarationData,
  type DeclarationDataApi,
} from './declarationData';

export const reshapeDeclarationData = (
  declarationData: DeclarationData,
): DeclarationDataApi => {
  return {
    type: declarationData.type,
    format: declarationData.format,
    reason: declarationData.reason,
    userRole: declarationData.userRole,
    supplierAddress: {
      thoroughfare: declarationData.supplierAddressThoroughfare,
      city: declarationData.supplierAddressCity,
      country: declarationData.supplierAddressCountry,
      zipCode: declarationData.supplierAddressZipCode,
    },
    purchaseDescription: declarationData.purchaseDescription,
    supplierName: declarationData.supplierName,
  };
};
