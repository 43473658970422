import { useQueryClient } from 'react-query';
import { Redirect, useHistory } from 'react-router-dom';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useParams } from 'src/core/common/hooks/useParams';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';

import { CardActivation } from './CardActivation';
import { useCardQuery } from '../../../hooks/useCardQuery';

export const CardActivationContainer = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const user = useUser();
  const company = useCompany();
  const { cardId } = useParams(routes.CARD_ACTIVATION.path);
  const cardQuery = useCardQuery(cardId);
  const hasPlasticCardsFeature = useFeature(FEATURES.PLASTIC_CARDS);

  if (
    !hasPlasticCardsFeature ||
    !user.has_plastic_card ||
    company.churning_at
  ) {
    return (
      <Redirect to={routeFor(routes.CARD.path, { company: company.id })} />
    );
  }

  return (
    <QuerySuspense
      queryState={cardQuery}
      fallback={(error) => (
        <QueryError componentType="Callout" queryError={error} />
      )}
      loading={null}
    >
      {(card) => (
        <CardActivation
          card={card}
          onFinish={() => {
            queryClient.invalidateQueries(['cards', cardId], {
              exact: true,
            });
            history.push(routeFor(routes.CARD.path, { company: company.id }));
          }}
          isRecard // TODO: use a query params for this one (right now only the recard flow uses the URL)
        />
      )}
    </QuerySuspense>
  );
};
