import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type RawCustomField } from '../models/customField';

function reshapeData(data: RawCustomField[]) {
  return data.filter((customField) => !customField.deleted_at);
}

export const useRawCustomFieldsQuery = (): QueryState<RawCustomField[]> => {
  return useQuery<RawCustomField[], RawCustomField[]>({
    key: ['customFields'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/custom-fields',
    },
    reshapeData,
  });
};
