import { type DateRange } from '@dev-spendesk/grapes';
import { startOfMonth, endOfMonth, subMonths, format } from 'date-fns';

import { type Language } from '../config/i18n';

export const lastMonthRange = (): DateRange => {
  const today = new Date();
  const startMonth = startOfMonth(subMonths(today, 1));
  const endMonth = endOfMonth(subMonths(today, 1));
  return [startMonth, endMonth];
};

/**
 * Get a short localized hour from a number.
 * Ex. with 3 as input, will return "03 AM" for EN locale and "03 h" for other locales
 * @param {number} hour Hour to get short localized value from (should be 0 to 23)
 * @param {string} language The user's active language
 * @return {string} Hour in short localized format
 */
export const getShortHourLocalized = (hour: number, language: Language) => {
  const date = new Date().setHours(hour);
  const hourFormat = language === 'en' ? 'hh a' : "HH 'h'";
  return format(date, hourFormat);
};

export const reverseChronologicalDateComparator = (dateA: Date, dateB: Date) =>
  dateA.getTime() > dateB.getTime() ? -1 : 1;
