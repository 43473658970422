import { gql } from 'graphql-tag';

import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { type BankAccount } from '../../accounting';

const SET_BANK_ACCOUNTS = gql`
  mutation SetBankAccounts(
    $companyId: ID!
    $accountsUpdates: [BankAccountUpdate!]!
  ) {
    setBankAccounts(companyId: $companyId, accountsUpdates: $accountsUpdates) {
      ... on SetBankAccountsResultNotSet {
        reason
      }
    }
  }
`;

type Payload = {
  accountsUpdates: BankAccount[];
};

type RawResponse = {
  setBankAccounts: {
    reason?: string;
  };
};

export const useSetBankAccountsMutation = (): MutationState<Payload> => {
  return useMutation<Payload, RawResponse>({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: SET_BANK_ACCOUNTS,
    },
    reshapeData: (data) => {
      if (data.setBankAccounts.reason) {
        throw new Error(data.setBankAccounts.reason);
      }
    },
    options: {
      onSuccess: (data): void => {
        data.client.invalidateQueries('getBankAccounts');
        data.client.invalidateQueries('useIntegrationStatusQuery');
      },
    },
  });
};
