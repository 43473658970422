import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import appConfig from 'src/core/config';

export const useBuildConsentUrlMutation = (): [() => Promise<void>] => {
  const companyId = useCompanyId();
  return [
    (): Promise<void> => {
      return promisifyPostRedirect(
        `${appConfig.apiUrls.api}/${companyId}/accounting-integration/oauth2/redirect-to-consent-url`,
      );
    },
  ];
};

const promisifyPostRedirect = (url: string): Promise<void> =>
  new Promise(() => {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = url;

    document.body.append(form);

    // When the form submits, it'll redirect the user, dropping the promise
    form.submit();
  });
