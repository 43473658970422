import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export interface CustomField {
  id: string;
  name: string;
  deleted_at: string | null;
}

export const useCustomFieldsQuery = (): QueryState<CustomField[]> => {
  const { company: companyId } = useParams();

  return useQuery<CustomField[], CustomField[]>({
    key: ['getCustomFields', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/custom-fields',
    },
    reshapeData(customFields) {
      return customFields;
    },
  });
};
