/* eslint-disable @typescript-eslint/naming-convention */

import { getRoutes } from 'src/core/constants/routes';

export const routes = getRoutes({
  COMPANY_SETUP: {
    path: '/app/:company/onboarding-hub/company-setup/*',
    analytics: {
      category: 'Onboarding Hub',
      name: 'company_setup',
    },
  },
  COMPANY_SETUP_APPROVAL_POLICY: {
    path: '/app/:company/onboarding-hub/company-setup/approval-policy',
    analytics: {
      category: 'Onboarding Hub',
      name: 'company_setup_approval_policy',
    },
  },
  COMPANY_SETUP_COST_CENTER: {
    path: '/app/:company/onboarding-hub/company-setup/cost-center',
    analytics: {
      category: 'Onboarding Hub',
      name: 'company_setup_cost_center',
    },
  },
  COMPANY_SETUP_APPROVAL_RULES: {
    path: '/app/:company/onboarding-hub/company-setup/approval-rules',
    analytics: {
      category: 'Onboarding Hub',
      name: 'company_setup_approval_rules',
    },
  },
  MEMBER_INVITE: {
    path: '/app/:company/onboarding-hub/invite-members/*',
    analytics: {
      category: 'Onboarding Hub',
      name: 'invite_members',
    },
  },
  MEMBER_INVITE_EMAIL: {
    path: '/app/:company/onboarding-hub/invite-members/email',
    analytics: {
      category: 'Onboarding Hub',
      name: 'invite_members_email',
    },
  },
  MEMBER_INVITE_ACTIONS: {
    path: '/app/:company/onboarding-hub/invite-members/actions',
    analytics: {
      category: 'Onboarding Hub',
      name: 'invite_members_actions',
    },
  },
  MEMBER_INVITE_ROLES: {
    path: '/app/:company/onboarding-hub/invite-members/roles',
    analytics: {
      category: 'Onboarding Hub',
      name: 'invite_members_roles',
    },
  },
  MEMBER_INVITE_LIST: {
    path: '/app/:company/onboarding-hub/invite-members/list',
    analytics: {
      category: 'Onboarding Hub',
      name: 'invite_members_list',
    },
  },
  ONBOARDING_HUB_TASK_LIST: {
    path: '/app/:company/onboarding-hub',
    analytics: {
      category: 'Onboarding Hub',
      name: 'task_list',
    },
  },
});
