import cx from 'classnames';
import React from 'react';

import styles from './IllustratedBanner.module.css';
import illustrationPath from './assets/illustration.svg';

type Props = {
  title: string;
  description: string;
  children: React.ReactNode;
};

export const IllustratedBanner = ({ title, description, children }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h3 className={cx(styles.title, 'mb-xs text-white title-xl')}>
          {title}
        </h3>
        <p className={cx(styles.description, 'mb-xs text-white body-m')}>
          {description}
        </p>
        {children}
      </div>
      <img className={styles.illustration} src={illustrationPath} alt="" />
    </div>
  );
};
