import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type ExternalUrls = {
  controlDashboard: string;
};

export const useExternalUrlsQuery = (): QueryState<ExternalUrls> => {
  return useQuery<ExternalUrls>({
    key: 'homepageExternalUrls',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/dashboard/external-urls',
    },
    reshapeData: (data) => {
      return {
        controlDashboard: data.controlDashboard,
      };
    },
  });
};
