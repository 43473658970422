import { useMutation } from 'src/core/api/hooks/useMutation';

import { useInvalidateUserDelegationQuery } from '../../delegation';

type Payload = {
  endpointParams: {
    role: 'wt_confirmation';
    userId: string;
  };
};

type Response = {
  deletedDelegation: {
    companyId: string;
    userId: string;
  };
};

export const useDeleteDelegationMutation = () => {
  const invalidateUserDelegations = useInvalidateUserDelegationQuery();
  return useMutation<Payload, Response, Response>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: ({ role, userId }) => `/delegations/${role}/${userId}`,
      method: 'delete',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
      onSuccess: ({ rawData }) => {
        invalidateUserDelegations(rawData.deletedDelegation.userId);
      },
    },
  });
};
