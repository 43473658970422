export const FETCH_PLASTIC_CARDS_LOADING = 'FETCH_PLASTIC_CARDS_LOADING';
export const FETCH_PLASTIC_CARDS_FAILURE = 'FETCH_PLASTIC_CARDS_FAILURE';
export const FETCH_PLASTIC_CARDS_SUCCESS = 'FETCH_PLASTIC_CARDS_SUCCESS';

export const UPDATE_PLASTIC_CARD_SUCCESS = 'UPDATE_PLASTIC_CARD_SUCCESS';

export const CHECK_CARD = 'CHECK_CARD';
export const UNCHECK_CARD = 'UNCHECK_CARD';
export const CHECK_ALL_CARDS = 'CHECK_ALL_CARDS';
export const UNCHECK_ALL_CARDS = 'UNCHECK_ALL_CARDS';

export const REMOVE_CARD_LOCALLY = 'REMOVE_CARD_LOCALLY';
export const UPDATE_CARDS_LOCALLY = 'UPDATE_CARDS_LOCALLY';
export const NUKE_CARDS_STATE = 'NUKE_CARDS_STATE';

export const PAUSE_PLASTIC_CARD_LOADING = 'PAUSE_PLASTIC_CARD_LOADING';
export const PAUSE_PLASTIC_CARD_FAILURE = 'PAUSE_PLASTIC_CARD_FAILURE';
export const PAUSE_PLASTIC_CARD_SUCCESS = 'PAUSE_PLASTIC_CARD_SUCCESS';

export const UNPAUSE_PLASTIC_CARD_LOADING = 'UNPAUSE_PLASTIC_CARD_LOADING';
export const UNPAUSE_PLASTIC_CARD_FAILURE = 'UNPAUSE_PLASTIC_CARD_FAILURE';
export const UNPAUSE_PLASTIC_CARD_SUCCESS = 'UNPAUSE_PLASTIC_CARD_SUCCESS';

export const RELOAD_PLASTIC_CARD_LOADING = 'RELOAD_PLASTIC_CARD_LOADING';
export const RELOAD_PLASTIC_CARD_FAILURE = 'RELOAD_PLASTIC_CARD_FAILURE';
export const RELOAD_PLASTIC_CARD_SUCCESS = 'RELOAD_PLASTIC_CARD_SUCCESS';

export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';

export const FETCH_HAS_WALLET_INSUFFICIENT_FUNDS_SUCCESS =
  'FETCH_HAS_WALLET_INSUFFICIENT_FUNDS_SUCCESS';
