import history from "src/core/history";

import { useTransferRoute } from './useTransferRoute';
import { type TransferId } from '../../models';

export const useGoToTransfer = () => {
  const getTransferRoute = useTransferRoute();

  return (transferId?: TransferId) => {
    const route = getTransferRoute(transferId);
    history.push(route);
  };
};
