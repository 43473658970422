import {
  type Group,
  type FetchAllRequest,
  type FetchAllSuccess,
  type FetchAllFailure,
  type FetchOneRequest,
  type FetchOneSuccess,
  type FetchOneFailure,
  type CreateRequest,
  type CreateSuccess,
  type CreateFailure,
  type UpdateRequest,
  type UpdateSuccess,
  type UpdateFailure,
  type DestroyRequest,
  type DestroySuccess,
  type DestroyFailure,
  type PatchRequest,
  type PatchSuccess,
  type PatchFailure,
} from '../types';

export const FETCH_ALL_REQUEST = 'group/FETCH_ALL_REQUEST';
export const FETCH_ALL_SUCCESS = 'group/FETCH_ALL_SUCCESS';
export const FETCH_ALL_FAILURE = 'group/FETCH_ALL_FAILURE';
export const FETCH_ONE_REQUEST = 'group/FETCH_ONE_REQUEST';
export const FETCH_ONE_SUCCESS = 'group/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'group/FETCH_ONE_FAILURE';
export const CREATE_REQUEST = 'group/CREATE_REQUEST';
export const CREATE_SUCCESS = 'group/CREATE_SUCCESS';
export const CREATE_FAILURE = 'group/CREATE_FAILURE';
export const UPDATE_REQUEST = 'group/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'group/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'group/UPDATE_FAILURE';
export const DESTROY_REQUEST = 'group/DESTROY_REQUEST';
export const DESTROY_SUCCESS = 'group/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'group/DESTROY_FAILURE';
export const PATCH_REQUEST = 'group/PATCH_REQUEST';
export const PATCH_SUCCESS = 'group/PATCH_SUCCESS';
export const PATCH_FAILURE = 'group/PATCH_FAILURE';

export const fetchAllRequest = (): FetchAllRequest => ({
  type: FETCH_ALL_REQUEST,
});

export const fetchAllSuccess = (groups: Group[]): FetchAllSuccess => ({
  type: FETCH_ALL_SUCCESS,
  payload: groups,
});

export const fetchAllFailure = (error: string): FetchAllFailure => ({
  type: FETCH_ALL_FAILURE,
  payload: error,
});

export const fetchOneRequest = (): FetchOneRequest => ({
  type: FETCH_ONE_REQUEST,
});

export const fetchOneSuccess = (group: Group): FetchOneSuccess => ({
  type: FETCH_ONE_SUCCESS,
  payload: group,
});

export const fetchOneFailure = (error: string): FetchOneFailure => ({
  type: FETCH_ONE_FAILURE,
  payload: error,
});

export const createRequest = (): CreateRequest => ({
  type: CREATE_REQUEST,
});

export const createSuccess = (group: Group): CreateSuccess => ({
  type: CREATE_SUCCESS,
  payload: group,
});

export const createFailure = (errorMessage: string): CreateFailure => ({
  type: CREATE_FAILURE,
  payload: errorMessage,
});

export const updateRequest = (group: Group): UpdateRequest => ({
  type: UPDATE_REQUEST,
  payload: group,
});

export const updateSuccess = (group: Group): UpdateSuccess => ({
  type: UPDATE_SUCCESS,
  payload: group,
});

export const updateFailure = (errorMessage: string): UpdateFailure => ({
  type: UPDATE_FAILURE,
  payload: errorMessage,
});

export const destroyRequest = (): DestroyRequest => ({
  type: DESTROY_REQUEST,
});

export const destroySuccess = (groupId: string): DestroySuccess => ({
  type: DESTROY_SUCCESS,
  payload: groupId,
});

export const destroyFailure = (errorMessage: string): DestroyFailure => ({
  type: DESTROY_FAILURE,
  payload: errorMessage,
});

export const patchRequest = (): PatchRequest => ({
  type: PATCH_REQUEST,
});

export const patchSuccess = (group: Group): PatchSuccess => ({
  type: PATCH_SUCCESS,
  payload: group,
});

export const patchFailure = (errorMessage: string): PatchFailure => ({
  type: PATCH_FAILURE,
  payload: errorMessage,
});
