import { Avatar, type AvatarBadgeProps } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';

import './Counterparty.css';

type Props = {
  type: 'employee' | 'supplier';
  name: string;
  vatNumber?: string;
  countryIsoCode2?: string;
  imageUrl: string | undefined;
  badge?: AvatarBadgeProps | undefined;
  accountPayable?: string;
  isAccountPayableInvalid?: boolean;
};

export const Counterparty = ({
  type,
  name,
  vatNumber,
  countryIsoCode2,
  imageUrl,
  badge,
  accountPayable,
  isAccountPayableInvalid,
}: Props) => {
  const subtitleItems = [accountPayable, vatNumber, countryIsoCode2].filter(
    Boolean,
  );

  return (
    <div className="Counterparty">
      <Avatar
        variant={getAvatarType(type)}
        src={imageUrl}
        text={name}
        badgeProps={badge}
        fallbackSrc={type === 'supplier' ? fallbackSupplierLogoSrc : undefined}
      />
      <div className="Counterparty__information">
        <div
          className="Counterparty__label title-l"
          data-testid="counterparty-name"
        >
          {name}
        </div>
        {subtitleItems.length > 0 && (
          <div
            data-testid="counterparty-subtitle"
            className={classNames('Counterparty__sublabel body-s', {
              'Counterparty__sublabel--invalid': isAccountPayableInvalid,
            })}
          >
            {subtitleItems.join(' • ')}
          </div>
        )}
      </div>
    </div>
  );
};

const getAvatarType = (type: Props['type']) => {
  return type === 'employee' ? 'circle' : 'square';
};
