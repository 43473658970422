import { FormField } from '@dev-spendesk/grapes';
import { type Dispatch } from 'react';

import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import {
  type MappedJournalModalState,
  type JournalModalState,
} from './journalModalState';
import { type Journal } from '../../../../../accounting';

interface Props {
  journals: Journal[];
  modalState: JournalModalState;
  setModalState: Dispatch<JournalModalState>;
}

export const JournalSelectAndInfo = ({
  journals,
  modalState,
  setModalState,
}: Props) => {
  const { t } = useTranslation();
  let initialJournal: Journal | undefined;

  if (!modalState || !['map', 'mapped'].includes(modalState.modalKind)) {
    return <></>;
  }

  if (modalState.modalKind === 'mapped') {
    initialJournal = modalState.mappedJournal;
  }

  const autoCompleteOptions = journals.map((journal) => {
    return {
      key: journal.id,
      label: `${journal.externalCode} - ${journal.externalName}`,
    };
  });

  return (
    <div>
      <FormField
        label={t('bookkeep.integrations.settings.chift.selectJournal')}
        htmlFor="set-mapping-autocomplete-input"
      >
        <AutocompleteSearch
          fit="parent"
          value={
            initialJournal
              ? {
                  key: initialJournal.id,
                  label: `${initialJournal.externalCode} - ${initialJournal.externalName}`,
                }
              : undefined
          }
          onSelect={(journal) => {
            if (journal) {
              const mappedJournal = journals.find(
                (availableJournal) => availableJournal.id === journal.key,
              );
              if (mappedJournal) {
                setModalState({
                  modalKind: 'mapped',
                  mappingType: (modalState as MappedJournalModalState)
                    .mappingType,
                  mappedJournal,
                });
              }
            }
          }}
          options={autoCompleteOptions}
          renderNoOptions={() => <></>}
          showClearSelectionButton
        />
      </FormField>
    </div>
  );
};
