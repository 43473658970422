import { useMutation } from 'src/core/api/hooks/useMutation';

import { type HubTask, type HubTaskCategory } from '../../../types/hubTask';

export const useMarkHubTaskAsDoneMutation = (
  taskId: string,
  category: HubTaskCategory,
) => {
  return useMutation({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/onboarding-hub/tasks/${taskId}/complete`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }) => {
        const hubTasks = client.getQueryData<HubTask[]>([
          'onboarding-hub',
          'tasks',
          category,
        ]);

        // Should not happen. But if it does, we invalidate the cache to refetch the data
        if (!hubTasks) {
          client.invalidateQueries(['onboarding-hub', 'tasks', category]);
          return;
        }

        const updatedHubTask = hubTasks.find((task) => task.id === taskId);

        // Should not happen either. But if it does, we invalidate the cache to refetch the data
        if (!updatedHubTask) {
          client.invalidateQueries(['onboarding-hub', 'tasks', category]);
          return;
        }

        client.setQueryData<HubTask[]>(
          ['onboarding-hub', 'tasks', category],
          (tasks) => {
            if (!tasks) {
              return [];
            }
            return tasks.map((task) => {
              if (task.id === taskId) {
                return { ...task, status: 'completed' };
              }
              if (
                updatedHubTask.type === 'wallet_funding' &&
                task.type === 'company_setup'
              ) {
                return { ...task, status: 'ongoing' };
              }
              return task;
            });
          },
        );
      },
    },
  });
};
