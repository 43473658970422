import {
  type UpdateCompany,
  type FetchAvailablePaymentMethodsRequest,
  type FetchAvailablePaymentMethodsSuccess,
  type FetchAvailablePaymentMethodsFailure,
  UPDATE_COMPANY,
  FETCH_AVAILABLE_PAYMENT_METHODS_REQUEST,
  FETCH_AVAILABLE_PAYMENT_METHODS_SUCCESS,
  FETCH_AVAILABLE_PAYMENT_METHODS_FAILURE,
} from './actionTypes';
import { type PaymentMethodsByIssuer } from '../types';

export const fetchAvailablePaymentMethodsRequest =
  (): FetchAvailablePaymentMethodsRequest => ({
    type: FETCH_AVAILABLE_PAYMENT_METHODS_REQUEST,
  });

export const fetchAvailablePaymentMethodsSuccess = (
  payload: PaymentMethodsByIssuer,
): FetchAvailablePaymentMethodsSuccess => ({
  type: FETCH_AVAILABLE_PAYMENT_METHODS_SUCCESS,
  payload,
});

export const fetchAvailablePaymentMethodsFailure =
  (): FetchAvailablePaymentMethodsFailure => ({
    type: FETCH_AVAILABLE_PAYMENT_METHODS_FAILURE,
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateCompany = (payload: any): UpdateCompany => {
  return {
    type: UPDATE_COMPANY,
    payload: {
      company: payload,
    },
  };
};
