import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { EmptyStateLoading } from 'common/components/LazyLoad';
import { routes } from 'src/core/constants/routes';

import { ExpenseClaimsConfirmPageSkeleton } from './ExpenseClaimsConfirmPage/ExpenseClaimsConfirmPageSkeleton';
import { ExpenseClaimsHistoryPageSkeleton } from './ExpenseClaimsHistoryPage/ExpenseClaimsHistoryPageSkeleton';
import { ExpenseClaimsPayPageSkeleton } from './ExpenseClaimsPayPage/ExpenseClaimsPayPageSkeleton';
import { ExpenseReviewPageSkeleton } from './ExpenseReviewPage/ExpenseReviewPageSkeleton';

export const ExpenseClaimsPageSkeleton = () => {
  return (
    <div className="max-h-full grow overflow-hidden bg-page-background">
      <Switch>
        <Route path={routes.EXPENSE_CLAIMS_REVIEW.path}>
          <ExpenseReviewPageSkeleton />
        </Route>
        <Route path={routes.EXPENSE_CLAIMS_PAY.path}>
          <ExpenseClaimsPayPageSkeleton />
        </Route>
        <Route path={routes.EXPENSE_CLAIMS_CONFIRM.path}>
          <ExpenseClaimsConfirmPageSkeleton />
        </Route>
        <Route path={routes.EXPENSE_CLAIMS_HISTORY.path}>
          <ExpenseClaimsHistoryPageSkeleton />
        </Route>
        <Route>
          <EmptyStateLoading />
        </Route>
      </Switch>
    </div>
  );
};
