import { PanelSection, type PanelSectionProps } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

type Props = PanelSectionProps & {
  isEmphasized?: boolean;
};

export function PanelTextSection({
  children,
  isEmphasized = false,
  ...panelSectionProps
}: Props) {
  return (
    <PanelSection {...panelSectionProps}>
      <div
        className={cx('text-complementary body-m', {
          'rounded bg-neutral-lightest p-xs text-neutral-dark': isEmphasized,
        })}
      >
        {children}
      </div>
    </PanelSection>
  );
}
