import { type DeepPartial } from 'src/core/common/utils/deep-partial';

import { type DraftSupplierUpdate } from '../../../models';
import { type CreateSupplierAPIPayload } from '../useCreateSupplier/createSupplierTransformer';

export type UpdateSupplierAPIPayload = DeepPartial<CreateSupplierAPIPayload>;

export const transformDraftSupplierUpdateToAPIPayload = ({
  name,
  address,
  bankInfos,
  legalInfos,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: DraftSupplierUpdate): UpdateSupplierAPIPayload => ({
  name: name ?? undefined,
  supplier_details: {
    account_holder_name: bankInfos?.accountHolderName ?? undefined,
    account_code: bankInfos?.accountCode ?? undefined,
    account_number: bankInfos?.accountNumber ?? undefined,
    address: address?.address ?? undefined,
    bank_country: bankInfos?.bankCountry ?? undefined,
    bic: bankInfos?.bic ?? undefined,
    city: address?.city ?? undefined,
    country: address?.country ?? undefined,
    iban: bankInfos?.iban ?? undefined,
    legal_name: legalInfos?.legalName ?? undefined,
    registration_number: legalInfos?.registrationNumber ?? undefined,
    routing_number: bankInfos?.routingNumber ?? undefined,
    sort_code: bankInfos?.sortCode ?? undefined,
    vat_number: legalInfos?.vatNumber ?? undefined,
    zipcode: address?.zipcode ?? undefined,
  },
});
