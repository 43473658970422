import { type QueryLoadingState } from 'src/core/api/queryState';
import { useCurrencyRatesQuery } from 'src/core/common/hooks/money';
import { type CurrencyRates } from 'src/core/config/money';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  type ExtendedOrganisationReportingEntity,
  type OrganisationReportingCurrency,
  type OrganisationReportingGroupBreakdown,
} from '../types';

export const useOrganisationReportingGroupStats = (
  entityData: ExtendedOrganisationReportingEntity[],
  isDataComplete: boolean,
) => {
  const mainCurrency: OrganisationReportingCurrency =
    entityData.find(({ type }) => type === 'initial')?.currency ?? 'EUR';

  const currencyTimestamp = getCurrencyRateDate();

  const currencyRateQuery = useCurrencyRatesQuery({
    currency: mainCurrency,
    date: currencyTimestamp,
  });

  if (currencyRateQuery.status === 'success') {
    if (!isDataComplete) {
      return { status: 'loading' } as QueryLoadingState;
    }

    const currencyRatesData = currencyRateQuery.data;

    const currencyRates = getRelevantCurrencyRates(
      entityData,
      mainCurrency,
      currencyRatesData,
    );
    const totalWalletBalance = getTotalWalletBalance(
      entityData,
      mainCurrency,
      currencyRatesData,
    );
    const breakdownTotals = getBreakdownTotals(entityData, currencyRatesData);

    return {
      ...currencyRateQuery,
      data: {
        totalWalletBalance,
        breakdownTotals,
        currencyRates,
        currencyTimestamp,
        mainCurrency,
      },
    };
  }

  return currencyRateQuery;
};

// Get the currency at the hour of the current date
const getCurrencyRateDate = (): Date => {
  const currentDate = new Date();
  currentDate.setMinutes(0, 0, 0);
  return currentDate;
};

const getTotalWalletBalance = (
  entityData: ExtendedOrganisationReportingEntity[],
  mainCurrency: OrganisationReportingCurrency,
  currencyRates: CurrencyRates,
) => {
  const total = entityData.reduce(
    (accumulator, { currency, walletBalance }) => {
      return (
        accumulator +
        convertFromCurrency(walletBalance, currency, currencyRates)
      );
    },
    0,
  );

  return formatMonetaryValue({
    amount: total,
    currency: mainCurrency,
    precision: 2,
  });
};

const getBreakdownTotals = (
  entityData: ExtendedOrganisationReportingEntity[],
  currencyRates: CurrencyRates,
): OrganisationReportingGroupBreakdown => {
  const breakdowns = entityData.reduce(
    (accumulator, { currency, breakdown }) => {
      accumulator.available += convertFromCurrency(
        breakdown.available,
        currency,
        currencyRates,
      );
      accumulator.availableCount += breakdown.available > 0 ? 1 : 0;
      accumulator.cardsLimits += convertFromCurrency(
        breakdown.cardsLimits,
        currency,
        currencyRates,
      );
      accumulator.scheduledTransfers += convertFromCurrency(
        breakdown.scheduledTransfers,
        currency,
        currencyRates,
      );
      accumulator.shortfall += convertFromCurrency(
        breakdown.shortfall,
        currency,
        currencyRates,
      );
      accumulator.shortfallCount += breakdown.shortfall > 0 ? 1 : 0;

      return accumulator;
    },
    {
      scheduledTransfers: 0,
      cardsLimits: 0,
      available: 0,
      shortfall: 0,
      shortfallCount: 0,
      availableCount: 0,
    },
  );

  return {
    ...breakdowns,
    trend: 'group',
  };
};

const getRelevantCurrencyRates = (
  entityData: ExtendedOrganisationReportingEntity[],
  mainCurrency: OrganisationReportingCurrency,
  currencyRates: Record<string, number>,
) => {
  const currencies = new Set<string>();

  entityData.forEach(({ currency }) => {
    if (currency !== mainCurrency) {
      currencies.add(currency);
    }
  });

  const relevantCurrencyRates = Array.from(currencies);
  return relevantCurrencyRates.map((currency) => ({
    sourceCurrency: currency,
    rate: currencyRates[currency],
  }));
};

const convertFromCurrency = (
  amount: number,
  currency: OrganisationReportingCurrency,
  currencyRates: CurrencyRates,
): number => {
  return Math.round(amount / currencyRates[currency]);
};
