import { useParams } from 'react-router-dom';
import { object, string, type infer as Infer } from 'zod';

import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type CreateApiKeyPayload = {
  name: string;
  description: string;
  scope: string;
  expiredAt: Date;
};

const createResponseSchema = object({
  apiId: string(),
  apiSecret: string(),
});

export type CreateApiKeyResponse = Infer<typeof createResponseSchema>;

export const useCreateApiKey = (): MutationState<
  CreateApiKeyPayload,
  CreateApiKeyResponse
> => {
  const { company: companyId } = useParams();

  return useMutation<CreateApiKeyPayload, CreateApiKeyResponse, object>({
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/public-api-configuration/api-keys/create`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }) => {
        return client.invalidateQueries(['apiKeysList', companyId]);
      },
    },
    reshapeData: createResponseSchema.parse,
  });
};
