import {
  useRawBudgetQuery,
  USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE,
} from 'modules/budgets/containers/BudgetOverviewPageContainer/hooks/useBudgetQuery';
import { isRequestError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

export const useIsUserAllowedToAccessBudgets = (
  budgetId: string,
): QueryState<boolean> => {
  const budgetQueryState = useRawBudgetQuery(budgetId);

  switch (budgetQueryState.status) {
    case 'loading':
      return { status: 'loading' };

    case 'success':
      return { status: 'success', data: true };

    case 'error': {
      if (
        isRequestError(budgetQueryState.error) &&
        budgetQueryState.error.data.errorCode ===
          USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE
      ) {
        return { status: 'success', data: false };
      }

      return budgetQueryState;
    }

    default:
      rejectUnexpectedValue('budgetQueryStateStatus', budgetQueryState);
  }
};
