import type * as NEA from '@dev-spendesk/general-type-helpers/NonEmptyArray';

import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { type ExpenseAccount } from '../../accounting';

export type ExpenseAccountAddError = {
  outcome: 'noSet';
  reason: 'integrationValidationFailed';
};

export type ExpenseAccountAddSuccess =
  | {
      outcome: 'set';
      accounts: NEA.NonEmptyArray<ExpenseAccount>;
    }
  | {
      outcome: 'notSet';
      reason: 'nothingToSet';
    }
  | {
      outcome: 'notSet';
      reason: 'codeAlreadyExists';
      existingAccount: ExpenseAccount;
    };

export const useSetExpenseAccountMutation = (): MutationState<
  {
    id?: string;
    code: string;
    name: string;
    isArchived: boolean;
    isAvailable?: boolean;
    shouldSendPayablesBackToPrepare?: boolean;
  },
  ExpenseAccountAddSuccess,
  ExpenseAccountAddError
> => {
  return useMutation<
    {
      id?: string;
      code: string;
      name: string;
      isArchived: boolean;
      isAvailable?: boolean;
    },
    ExpenseAccountAddSuccess,
    ExpenseAccountAddSuccess,
    ExpenseAccountAddError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/chart-of-accounts/expense-accounts',
      method: 'post',
    },
    reshapeData(data) {
      return data;
    },
    options: {
      async onSuccess({ data, client }) {
        if (data.outcome === 'set') {
          await client.invalidateQueries('getExpenseAccounts');
          await client.invalidateQueries('useGetExpenseAccountsQuery'); // expense category -> expense account automation
          await client.invalidateQueries('useGetExpenseAccountLazyQuery', {
            // this allows to refetch lazy queries that have been fetched at least once
            refetchInactive: true,
          }); // expense category -> expense account automation
          await client.invalidateQueries('useIntegrationStatusQuery'); // expense account async validation for native
          await client.invalidateQueries('useExpenseAccountsQuery'); // expense account list in Prepare
          client.invalidateQueries('useExpenseAccountQuery'); // expense account detail in Prepare
          client.invalidateQueries('useTaxAndExpenseAccountsCountsQuery'); // account tips in Prepare
        }
      },
    },
  });
};
