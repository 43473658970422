import includes from 'lodash/includes';
import some from 'lodash/some';

// TODO: rely less on Lodash and refine typings

export const MAX_SIZE = 10;

const getFileName = (filePath: string): string | undefined => {
  const fileName = filePath.split('/').at(-1); // Remove file path
  return fileName
    ? fileName.split('?').at(0) // Remove query parameters
    : undefined;
};

const getFileExtension = (filePath: string): string | undefined => {
  const fileName = getFileName(filePath);
  return fileName ? fileName.split('.').at(-1)?.toLowerCase() : undefined;
};

export const getFileType = (fileName: string): string | null => {
  const extension = getFileExtension(fileName);
  if (includes(['jpg', 'jpeg', 'png', 'svg', 'gif', 'webp'], extension)) {
    return 'Image';
  }
  if (includes(['pdf'], extension)) {
    return 'PDF';
  }
  return null;
};

export const getFileTypeFromMime = (mime: string): string | null => {
  const imageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'image/svg+xml',
    'image/bmp',
  ];
  if (includes(imageMimeTypes, mime)) {
    return 'Image';
  }
  const pdfMimeTypes = [
    'application/pdf',
    // Some systems are using this mime type alias https://spendesk.atlassian.net/browse/SSD-8237
    'image/pdf',
  ];
  if (pdfMimeTypes.includes(mime)) {
    return 'PDF';
  }
  return null;
};

export const srcFromFile = (file: File): string | null => {
  if (!window.URL || !file) {
    return null;
  }
  return URL.createObjectURL(file);
};

export const isFileTooLarge = (
  file: File,
  maxSize: number = MAX_SIZE,
): boolean => Math.round((file.size / 1_000_000) * 100) / 100 > maxSize;

export const someFilesAreTooLarge = (
  files: File[] | FileList = [],
  maxSize: number = MAX_SIZE,
): boolean => some(files, (f) => isFileTooLarge(f, maxSize));

const allowedMimeTypes = [
  'application/pdf',
  // Some systems are using this mime type alias https://spendesk.atlassian.net/browse/SSD-8237
  'image/pdf',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
  'image/bmp',
];

export const isFileTypesNotSupported = (file: File): boolean =>
  !includes(allowedMimeTypes, file.type);

export const someFilesTypesNotSupported = (
  files: File[] | FileList = [],
): boolean => {
  return some(files, isFileTypesNotSupported);
};

export const isValidPDF = async (pdf: File): Promise<boolean> => {
  try {
    const text = await pdf.text();
    return text.includes('%PDF-') && text.includes('%%EOF');
  } catch {
    return false;
  }
};

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result as string);
      },
      false,
    );

    reader.addEventListener('error', () => {
      reject(reader.error);
    });
  });
