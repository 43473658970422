import { AnalyticEventName, track } from 'src/core/utils/analytics';

export function trackSupplierRuleCreated({
  supplierId,
  expenseAccountId,
  createdFrom,
}: {
  supplierId: string;
  expenseAccountId: string | undefined;
  createdFrom: 'prepare' | 'settings';
}): void {
  track(AnalyticEventName.BOOKKEEP_SUPPLIER_RULE_CREATED, {
    supplierId,
    expenseAccountId,
    createdFrom,
  });
}

export function trackSupplierRuleUpdated({
  supplierId,
  expenseAccountId,
  createdFrom,
}: {
  supplierId: string;
  expenseAccountId: string | undefined;
  createdFrom: 'prepare' | 'settings';
}): void {
  track(AnalyticEventName.BOOKKEEP_SUPPLIER_RULE_UPDATED, {
    supplierId,
    expenseAccountId,
    createdFrom,
  });
}
