import { useQuery } from 'src/core/api/hooks/useQuery';
import { isRequestError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';

import {
  type BudgetaryExercise,
  type RawBudgetaryExercise,
  reshapeRawBudgetaryExercise,
} from '../../models/budgetaryExercise';

export const USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE =
  'userNotAllowedToAccessBudget';

type BudgetaryExercisesQueryError = {
  errorCode: typeof USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE;
};

export const useBudgetaryExercisesQuery = (
  isEnabled = true,
): QueryState<BudgetaryExercise[], BudgetaryExercisesQueryError> => {
  return useQuery<BudgetaryExercise[], RawBudgetaryExercise[]>({
    key: 'budgetaryExercises',
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/budgets/budgetary-exercises',
    },
    reshapeError: (queryError) => {
      if (
        isRequestError(queryError) &&
        queryError.status === 403 &&
        queryError.data.errorCode ===
          USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE
      ) {
        return {
          ...queryError,
          data: {
            errorCode: USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE,
          },
        };
      }
      return queryError;
    },
    reshapeData: (rawBudgetaryExercises) =>
      rawBudgetaryExercises.map(reshapeRawBudgetaryExercise),
  });
};
