// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';

import type FEATURES from '../../../constants/features';

/**
 * The FeatureSwitch component connects to the Redux store to check if a given feature is enabled or not and then
 * conditionnaly renders the according component, depending on the status of the feature and the provided props.
 *
 * FeatureSwitch can be used in different ways:
 * 1. By providing children to be rendered when the feature is active
 * ```
 * <FeatureSwitch feature="some-feature">
 *   <p>The feature is enabled! 🎉</p>
 * </FeatureSwitch>
 * ```
 * 2. By providing a render prop that will be called with the status of the feature
 * ```
 * <FeatureSwitch
 *   feature="some-feature"
 *   render={(isSomeFeatureEnabled) => {
 *      if (isSomeFeatureEnabled) {
 *        return (<p>The feature is enabled! 🎉</p>);
 *      } else {
 *        return (<p>Too bad you didn't pay for this... <a href="">Get it!</a></p>);
 *      }
 *   }}
 * />
 * ```
 * 3. By providing onComponent and offComponent props
 * ```
 * <FeatureSwitch
 *   feature="some-feature"
 *   onComponent={<p>The feature is enabled! 🎉</p>}
 *   offComponent={<p>Too bad you didn't pay for this... <a href="">Get it!</a></p>}
 * />
 * ```
 * 4. By providing both children and offComponent prop (children always act as onComponent prop)
 * 5. ...and more! For example, you could even only render something when the feature is enabled and nothing when it's
 * not by using only the offComponent prop.
 *
 * But keep in mind that there is an order of precedence. If a render prop is provided, children and on/off-Component
 * props are ignored and if children is provided, onComponent prop is ignored.
 */

type Props = {
  feature: FEATURES;
  onComponent?: JSX.Element | null;
  offComponent?: JSX.Element | null;
  children?: JSX.Element | null; // takes precendence over `onComponent`
  render?: (isFeatureEnabled: boolean) => JSX.Element; // takes precedence over `on/off-Component` props
};

export const FeatureSwitch = ({
  feature,
  onComponent = null,
  offComponent = null,
  children = null,
  render,
}: Props) => {
  const isFeatureEnabled = useFeature(feature);

  if (render) {
    // The render prop takes precedence if it's defined. We call it with the status of the feature.
    return render(isFeatureEnabled);
  }

  if (isFeatureEnabled) {
    // When feature enabled, children take precedence over onComponent.
    if (children) {
      return children;
    }
    return onComponent;
  }

  return offComponent;
};
