import { createSlice, createSelector } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { AppState } from "src/core/reducers";
import { getCompanyCurrency } from "src/core/selectors/globalSelectors";

import { type Transfer, sumTransfersAmount } from '../models';

interface TransferState {
  transfersSelected: Transfer[];
}

const initialState: TransferState = {
  transfersSelected: [],
};

const transfersSlice = createSlice({
  name: 'invoices/transfers',
  initialState,
  reducers: {
    setTransfersSelected: (
      state,
      { payload }: PayloadAction<{ transfersSelected: Transfer[] }>,
    ) => {
      state.transfersSelected = payload.transfersSelected;
    },

    resetTransfersSelection: (state) => {
      state.transfersSelected = [];
    },
    unselectTransfers: (
      state,
      { payload }: PayloadAction<{ transfersToUnselect: Transfer[] }>,
    ) => {
      state.transfersSelected = state.transfersSelected.filter(
        (selectedTransfer) => {
          return !payload.transfersToUnselect.some(
            (transferToRemove) => transferToRemove.id === selectedTransfer.id,
          );
        },
      );
    },
  },
});

const selectTransfersSlice = (state: AppState): TransferState =>
  state.invoices.transfers;

const selectTransfersSelected = createSelector(
  selectTransfersSlice,
  (transfersSliceState) => transfersSliceState.transfersSelected,
);

const selectTransfersSelectedCount = createSelector(
  selectTransfersSelected,
  (transfersSelected) => transfersSelected.length,
);

const selectTransfersSelectedAmountSum = createSelector(
  selectTransfersSelected,
  getCompanyCurrency,
  sumTransfersAmount,
);

export const selectors = {
  selectTransfersSelected,
  selectTransfersSelectedCount,
  selectTransfersSelectedAmountSum,
};

export const { actions, reducer } = transfersSlice;
