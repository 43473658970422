import * as actions from './redux/actions';
import reducer, { initialState } from './redux/reducer';
import * as selectors from './redux/selectors';
import * as thunks from './redux/thunks';

export * from './allowanceLocation';
export * from './perDiem';
export * from './perDiemLocation';

export { PerDiemDetailsContainer } from './containers/PerDiemDetailsContainer';
export { PerDiemDetailsHeader } from './components/PerDiemDetails';

export default {
  reducer,
  initialState,
  selectors,
  actions: {
    ...actions,
    ...thunks,
  },
};

export { useGetAllowanceLocationsQuery } from './hooks/useGetAllowanceLocationsQuery';
