import { type TGlobalFunctionTyped as TFunctionTyped } from '../../hooks/useTranslation';

export type QuestionsList = ReturnType<typeof getQuestionsList>;

type GetQuestionsListParams = {
  t: TFunctionTyped;
};

export const getQuestionsList = ({ t }: GetQuestionsListParams) => {
  return {
    // Help center index pages
    HC_INDEX: { title: '', link: '' },
    HC_INDEX_1: {
      title: t('helpCenter.links.index1'),
      link: 'collections/2341163',
    },
    HC_INDEX_2: {
      title: t('helpCenter.links.index2'),
      link: 'collections/2341188',
    },
    HC_INDEX_3: {
      title: t('helpCenter.links.index3'),
      link: 'collections/2341182',
    },
    HC_INDEX_4: {
      title: t('helpCenter.links.index4'),
      link: 'collections/2341495',
    },
    HC_INDEX_5: {
      title: t('helpCenter.links.index5'),
      link: 'collections/132336',
    },
    HC_INDEX_6: {
      title: t('helpCenter.links.index6'),
      link: 'collections/132336',
    },
    HC_INDEX_7: {
      title: t('helpCenter.links.index7'),
      link: 'collections/2341490',
    },
    HC_INDEX_8: {
      title: t('helpCenter.links.index8'),
      link: 'collections/2383252',
    },

    // Requests
    HC_LINK_1: {
      title: t('helpCenter.links.link1'),
      link: 'articles/4047397',
    },
    HC_LINK_2: {
      title: t('helpCenter.links.link2'),
      link: 'articles/4200510',
    },
    HC_LINK_3: {
      title: t('helpCenter.links.link3'),
      link: 'articles/4035447',
    },
    HC_LINK_4: {
      title: t('helpCenter.links.link4'),
      link: 'articles/4233622',
    },
    HC_LINK_5: {
      title: t('helpCenter.links.link5'),
      link: 'articles/4035447',
    },
    HC_LINK_6: {
      title: t('helpCenter.links.link6'),
      link: 'articles/4031958',
    },
    HC_LINK_7: {
      title: t('helpCenter.links.link7'),
      link: 'articles/4067421',
    },

    // Payments
    HC_LINK_8: {
      title: t('helpCenter.links.link8'),
      link: 'articles/4047617',
    },
    HC_LINK_9: {
      title: t('helpCenter.links.link9'),
      link: 'articles/4107867',
    },
    HC_LINK_10: {
      title: t('helpCenter.links.link10'),
      link: 'articles/4070416',
    },
    HC_LINK_11: {
      title: t('helpCenter.links.link11'),
      link: 'articles/4097608',
    },
    HC_LINK_12: {
      title: t('helpCenter.links.link12'),
      link: 'articles/4097608',
    },
    HC_LINK_13: {
      title: t('helpCenter.links.link13'),
      link: 'articles/4153508',
    },
    HC_LINK_14: {
      title: t('helpCenter.links.link14'),
      link: 'articles/4136508',
    },
    HC_LINK_16: {
      title: t('helpCenter.links.link16'),
      link: 'articles/4136508',
    },

    // Subscriptions
    HC_LINK_17: {
      title: t('helpCenter.links.link17'),
      link: 'articles/4070173',
    },
    HC_LINK_18: {
      title: t('helpCenter.links.link18'),
      link: 'articles/4035451',
    },
    HC_LINK_19: {
      title: t('helpCenter.links.link19'),
      link: 'articles/4056961',
    },
    HC_LINK_20: {
      title: t('helpCenter.links.link20'),
      link: 'articles/4070173',
    },
    HC_LINK_21: {
      title: t('helpCenter.links.link21'),
      link: 'articles/4056966',
    },

    // Card / cards
    HC_LINK_22: {
      title: t('helpCenter.links.link22'),
      link: 'articles/4156399',
    },
    HC_LINK_23: {
      title: t('helpCenter.links.link23'),
      link: 'articles/4156407',
    },
    HC_LINK_24: {
      title: t('helpCenter.links.link24'),
      link: 'articles/4156599',
    },
    HC_LINK_25: {
      title: t('helpCenter.links.link25'),
      link: 'articles/4156068',
    },
    HC_LINK_26: {
      title: t('helpCenter.links.link26'),
      link: 'articles/4157025',
    },
    HC_LINK_27: {
      title: t('helpCenter.links.link27'),
      link: 'articles/4156029',
    },

    // Company profile
    HC_LINK_28: {
      title: t('helpCenter.links.link28'),
      link: 'articles/4057422',
    },

    // Members
    HC_LINK_29: {
      title: t('helpCenter.links.link29'),
      link: 'articles/4057643',
    },
    HC_LINK_30: {
      title: t('helpCenter.links.link30'),
      link: 'articles/4062441',
    },
    HC_LINK_31: {
      title: t('helpCenter.links.link31'),
      link: 'articles/4057745',
    },
    HC_LINK_32: {
      title: t('helpCenter.links.link32'),
      link: 'articles/4057745',
    },

    // Invitations
    HC_LINK_33: {
      title: t('helpCenter.links.link33'),
      link: 'articles/4057542',
    },

    // Teams
    HC_LINK_34: {
      title: t('helpCenter.links.link34'),
      link: 'articles/4062399',
    },
    HC_LINK_35: {
      title: t('helpCenter.links.link35'),
      link: 'articles/4062441',
    },
    HC_LINK_36: {
      title: t('helpCenter.links.link36'),
      link: 'articles/4062441',
    },

    // Policies
    HC_LINK_37: {
      title: t('helpCenter.links.link37'),
      link: 'articles/4067237',
    },
    HC_LINK_38: {
      title: t('helpCenter.links.link38'),
      link: 'articles/4062441',
    },

    // Bank
    HC_LINK_39: {
      title: t('helpCenter.links.link39'),
      link: 'articles/4031867',
    },
    HC_LINK_40: {
      title: t('helpCenter.links.link40'),
      link: 'articles/4136508',
    },
    HC_LINK_41: {
      title: t('helpCenter.links.link41'),
      link: 'articles/4152597',
    },

    // Billing
    HC_LINK_42: {
      title: t('helpCenter.links.link42'),
      link: 'articles/4057515',
    },

    // Accounting
    HC_LINK_44: {
      title: t('helpCenter.links.link44'),
      link: 'articles/4136555',
    },
    HC_LINK_45: {
      title: t('helpCenter.links.link45'),
      link: 'articles/4153924',
    },
    HC_LINK_46: {
      title: t('helpCenter.links.link46'),
      link: 'articles/4154153',
    },
    HC_LINK_461: {
      title: t('helpCenter.links.link461'),
      link: 'articles/4154264',
    },
    HC_LINK_462: {
      title: t('helpCenter.links.link462'),
      link: 'articles/4153872',
    },

    // Categories
    HC_LINK_47: {
      title: t('helpCenter.links.link47'),
      link: 'articles/4028519',
    },

    // Account page
    HC_LINK_49: {
      title: t('helpCenter.links.link49'),
      link: 'articles/4067237',
    },
    HC_LINK_50: {
      title: t('helpCenter.links.link50'),
      link: 'articles/4065666',
    },

    // Account notifications
    HC_LINK_51: {
      title: t('helpCenter.links.link51'),
      link: 'articles/4026123',
    },

    // Accounting
    HC_LINK_52: {
      title: t('helpCenter.links.link52'),
      link: 'articles/4152880',
    },

    HC_LINK_53: {
      title: t('helpCenter.links.link53'),
      link: 'articles/4152880',
    },

    HC_LINK_54: {
      title: t('helpCenter.links.link54'),
      link: 'articles/4152989',
    },

    // Invoices
    HC_LINK_60: {
      title: t('helpCenter.links.link60'),
      link: 'articles/4198389',
    },

    HC_LINK_61: {
      title: t('helpCenter.links.link61'),
      link: 'articles/4198466',
    },

    // Expense Claims
    HC_LINK_62: {
      title: t('helpCenter.links.link62'),
      link: 'articles/4124224',
    },

    HC_LINK_63: {
      title: t('helpCenter.links.link63'),
      link: 'articles/4119697',
    },

    // Purchase orders
    HC_LINK_64: {
      title: t('helpCenter.links.link64'),
      link: 'articles/5140208',
    },
  };
};
