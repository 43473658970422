/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QueryHookOptions } from '@apollo/react-hooks';
import { type ApolloError } from 'apollo-client';
import { type DocumentNode } from 'graphql';
import get from 'lodash/get';
import identity from 'lodash/identity';
import set from 'lodash/set';

import { useQuery } from './useQuery';

const getConnectionFromQuery = (data: any, pathToConnection: string): any =>
  get(data, pathToConnection);

const getHasNextPageFromQuery = (
  data: any,
  pathToConnection: string,
): boolean => {
  const connection = getConnectionFromQuery(data, pathToConnection);
  return connection.pageInfo?.hasNextPage;
};

const getEdgesFromQuery = (data: any, pathToConnection: string) => {
  const connection = getConnectionFromQuery(data, pathToConnection);
  return connection.edges;
};

const getTotalCountFromQuery = (
  data: any,
  pathToConnection: string,
): number => {
  const connection = getConnectionFromQuery(data, pathToConnection);
  return connection.totalCount;
};

const getItemsFromEdges = (edges: any): any => {
  return edges.map((edge: any) => {
    return edge.node;
  });
};

export const usePaginatedQuery = <TReshaped, TItemError>(
  query: DocumentNode,
  config: QueryHookOptions,
  customReshapers: {
    reshapeData: (data?: any) => TReshaped;
    reshapeError?: (error?: ApolloError) => TItemError;
  },
  pathToConnection: string,
) => {
  const reshapePaginatedData = (data: any) => {
    const hasNextPage = getHasNextPageFromQuery(data, pathToConnection);
    const totalCount = getTotalCountFromQuery(data, pathToConnection);
    const edges = getEdgesFromQuery(data, pathToConnection);
    const lastEdge = Array.isArray(edges) ? edges.at(-1) : null;
    const lastCursor: string | null =
      hasNextPage && lastEdge ? lastEdge.cursor : null;

    let reshaped: TReshaped = getItemsFromEdges(edges);
    if (customReshapers.reshapeData) {
      reshaped = customReshapers.reshapeData(reshaped);
    }

    return { totalCount, items: reshaped, hasNextPage, lastCursor };
  };

  const result = useQuery(
    query,
    {
      ...config,
      notifyOnNetworkStatusChange: true,
    },
    {
      reshapeData: reshapePaginatedData,
      reshapeError: customReshapers.reshapeError ?? identity,
    },
  );

  const fetchMoreHandler = (): void => {
    result.fetchMore({
      variables: {
        afterCursor: result.data && result.data.lastCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousEdges = getEdgesFromQuery(
          previousResult,
          pathToConnection,
        );
        const fetchMoreEdges = getEdgesFromQuery(
          fetchMoreResult,
          pathToConnection,
        );
        const newEdges = [...previousEdges, ...fetchMoreEdges];

        const fetchMoreConnection = getConnectionFromQuery(
          fetchMoreResult,
          pathToConnection,
        );
        const newConnection = { ...fetchMoreConnection, edges: newEdges };

        if (fetchMoreResult) {
          return set(fetchMoreResult, pathToConnection, newConnection);
        }

        return previousResult;
      },
    });
  };

  return {
    ...result,
    fetchMore: fetchMoreHandler,
  };
};
