import { type ApprovalPolicy } from 'modules/company/members/components/ApprovalPolicies';
import { reshapeFromSpendingTypes } from 'modules/company/members/components/ApprovalPolicies/reshapers';

import { type MemberEditFormikValues } from '../../../components/MemberEditModal/validate';
import { useCreateUserPolicyMutation } from '../../../hooks/useCreateUserPolicyMutation';
import { useUpdateUserPolicyMutation } from '../../../hooks/useUpdateUserPolicyMutation';

export const useEditApprovalPolicyMutation = (memberId: string) => {
  const [updateUserPolicy] = useUpdateUserPolicyMutation();
  const [createUserPolicy] = useCreateUserPolicyMutation();
  return [
    async (
      memberApprovalPolicy: ApprovalPolicy | undefined,
      values: MemberEditFormikValues,
    ) => {
      // update user approval policy
      if (
        values.approvalPolicy &&
        memberApprovalPolicy?.id !== values.approvalPolicy?.id
      ) {
        if (values.approvalPolicy?.isCustom) {
          await createUserPolicy({
            user_id: memberId,
            params: {
              approval_needed: values.approvalPolicy.params.approvalNeeded,
              spending_types: reshapeFromSpendingTypes(
                values.approvalPolicy.params.spendingTypes,
              ),
              spending_limit: values.approvalPolicy.params.amountPerMonth,
              transaction_max:
                values.approvalPolicy.params.amountPerTransaction,
            },
          });
        } else {
          await updateUserPolicy({
            user_id: memberId,
            endpointParams: {
              policyId: values.approvalPolicy.id,
            },
          });
        }
      }
    },
  ];
};
