import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type EmployeeAccountCode } from 'modules/bookkeep';
import { type SupplierAccount } from 'modules/bookkeep/accounts-payable/types';
import { SupplierAccountAdvice } from 'modules/bookkeep/components/AccountAdvice';
import {
  useAccountLength,
  useHasAuxiliaryAccountsEnabled,
} from 'modules/bookkeep/hooks';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { CodeInUseCallout } from '../../../../components/CodeInUseCallout/CodeInUseCallout';
import {
  getCodeInUseLinkForAccountsPayable,
  validateAccountPayable,
} from '../../../../utils';
import { EditDefaultAccountForm } from '../EditDefaultAccountForm';
import { type ModalState } from '../ModalState';
import styles from '../SupplierAccountsLocalOnlySection.module.css';

type EditDefaultSupplierAccountModalProps = {
  setModalState: Dispatch<SetStateAction<ModalState>>;
  modalState: ModalState;
  integrationStatus: IntegrationStatusWithIntegration;
  getSupplierAccountsQueryState: QueryState<SupplierAccount[], unknown>;
  getEmployeeAccountCodesQuery: QueryState<EmployeeAccountCode[], unknown>;
  handleUpdateSpendeskAccount: () => void;
};

export const EditDefaultSupplierAccountModal = ({
  setModalState,
  modalState,
  integrationStatus,
  getSupplierAccountsQueryState,
  getEmployeeAccountCodesQuery,
  handleUpdateSpendeskAccount,
}: EditDefaultSupplierAccountModalProps) => {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();
  const accountLength = useAccountLength();

  return (
    <Modal
      actions={[
        <Button
          key="no"
          onClick={() =>
            setModalState({
              ...modalState,
              kind: 'closed',
            })
          }
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          id="confirm-delete-supplier-account"
          text={t('misc.saveChanges')}
          isDisabled={
            modalState.kind === 'form' &&
            modalState.error !== 'none' &&
            !modalState.inputChanged
          }
          variant="primary"
          onClick={() => {
            if (modalState.kind !== 'form') {
              // eslint-disable-next-line no-console
              console.warn(
                "This shouldn't happen: modal will always be open when we see modal buttons",
              );
              return;
            }
            const result = validateAccountPayable(
              modalState.editedAccount.generalAccountCode,
              modalState.editedAccount.auxiliaryAccountCode,
              accountLength,
              getSupplierAccountsQueryState,
              getEmployeeAccountCodesQuery,
              integrationStatus.integration,
              auxiliaryAccountsEnabled,
            );

            if (result.outcome === 'valid') {
              if (modalState.editedAccount.id) {
                setModalState({
                  ...modalState,
                  kind: 'confirmation',
                });
                return;
              }

              return handleUpdateSpendeskAccount();
            }

            switch (result.reason) {
              case 'required':
              case 'invalidPattern': {
                setModalState({
                  ...modalState,
                  error: result.reason,
                  inputChanged: false,
                });
                return;
              }
              case 'codeAlreadyExists': {
                setModalState({
                  ...modalState,
                  error: result.reason,
                  inputChanged: false,
                  existingAccount: result.existingAccount,
                });
                return;
              }
              default:
                rejectUnexpectedValue(
                  'defaultSupplierAccountValidation',
                  result,
                );
            }
          }}
        />,
      ]}
      iconName="info"
      iconVariant="info"
      title={t(
        'bookkeep.integrations.settings.supplierAccountsTable.editDefaultAccount',
      )}
      isOpen={modalState.kind === 'form'}
      onClose={() => {
        // This should be called after the fading out transition
        // We clean up the previously edited account
        setModalState({
          kind: 'closed',
        });
      }}
    >
      <>
        {modalState.kind === 'form' && modalState.editedAccount && (
          <EditDefaultAccountForm
            editedAccount={modalState.editedAccount}
            error={
              modalState.error !== 'none' && !modalState.inputChanged
                ? modalState.error
                : undefined
            }
            setEditedAccount={(account) =>
              setModalState({
                ...modalState,
                editedAccount: account,
                inputChanged: true,
              })
            }
          />
        )}
        {modalState.kind === 'form' &&
          modalState.error === 'codeAlreadyExists' &&
          modalState.existingAccount &&
          !modalState.inputChanged && (
            <CodeInUseCallout
              className={styles.formCallout}
              accountInfo={`${t(
                `bookkeep.integrations.settings.supplierAccountsTable.${modalState.existingAccount.kind}`,
              )} - ${modalState.existingAccount.generalAccountCode}`}
              linkTo={getCodeInUseLinkForAccountsPayable(modalState, companyId)}
            />
          )}

        {modalState.kind === 'form' &&
          (modalState.error !== 'codeAlreadyExists' ||
            modalState.inputChanged) && (
            <SupplierAccountAdvice
              className={styles.formCallout}
              integrationStatus={integrationStatus}
              showError={
                modalState.error === 'invalidPattern' &&
                !modalState.inputChanged
              }
            />
          )}
      </>
    </Modal>
  );
};
