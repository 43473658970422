import { useFeature } from 'common/hooks/useFeature';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import FEATURES from 'src/core/constants/features';

export const useCanAccessExpenseClaimsPage = (): boolean => {
  const { isAccountOwner, isController } = useUserRoles();
  const isExpenseClaimsFeatureEnabled = useFeature(FEATURES.EXPENSE_CLAIMS);
  const isControlOnSpendingMethodEnabled = useFeature(
    FEATURES.CONTROL_ON_SPENDING_METHODS,
  );

  if (isControlOnSpendingMethodEnabled || isExpenseClaimsFeatureEnabled) {
    return isAccountOwner || isController;
  }

  return false;
};
