import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useInvalidateFailedExportPayablesQueryCache } from 'modules/payable-export/apis';

import {
  useInvalidatePayablesBucketsStatsQueryCache,
  useUpdatePayablesBucketsStatsQueryCache,
} from '../apis/prepare-payable/usePayablesBucketsStatsQuery/usePayablesBucketsStatsQuery';

export const useInvalidateBookkeepingNavbarCounters = () => {
  const companyId = useCompanyId();

  const updatePayablesBucketsStatsQueryCache =
    useUpdatePayablesBucketsStatsQueryCache();

  const invalidatePayablesBucketsStatsQueryCache =
    useInvalidatePayablesBucketsStatsQueryCache();

  const invalidateFailedExportPayablesQueryCache =
    useInvalidateFailedExportPayablesQueryCache();

  return (
    action?: Parameters<typeof updatePayablesBucketsStatsQueryCache>[1],
  ) =>
    Promise.all([
      updatePayablesBucketsStatsQueryCache(companyId, action),
      invalidatePayablesBucketsStatsQueryCache(companyId),
      invalidateFailedExportPayablesQueryCache(companyId),
    ]);
};
