import { useEffect, useState } from 'react';
import { type QueryClient, useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useInvalidateGetPayableQueryCache as useInvalidateGetPayableInPrepareQueryCache } from 'modules/bookkeep/apis/prepare-payable/useGetPayableQuery';
import { useRefreshPayableBuckets } from 'modules/bookkeep/apis/prepare-payable/useRefreshPayableBuckets';
import { reshapePayableProofs } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/hooks/useGetPayableProofsQuery/reshaper';
import { getPayableProofsQueryKey } from 'modules/payable';
import {
  getPayableQueryKey,
  useInvalidateGetPayableQueryCache,
  type PayableGraphQLRawResponse,
} from 'src/core/modules/bookkeep/payables/containers/PayablePanelContainer/hooks';

const POLL_INTERVAL = 1000;
const POLL_MAX_ATTEMPTS = 15;

export function useUploadDocumentaryEvidencePolling(
  payableId: string,
): [boolean, (shouldPoll: boolean) => void] {
  const companyId = useCompanyId();
  const [isPolling, setIsPolling] = useState(false);
  const queryClient = useQueryClient();
  const refreshPayableBuckets = useRefreshPayableBuckets();

  const invalidatePayableInPrepareQueryCache =
    useInvalidateGetPayableInPrepareQueryCache();

  const invalidatePayableQueryCache = useInvalidateGetPayableQueryCache();

  const previousPayableDocumentaryEvidenceHash =
    getPayableDocumentaryEvidenceHash(companyId, payableId, queryClient);

  // As uploads are asynchronous on the backend,
  // we poll the server until the number of documentaryEvidence changes.
  useEffect(() => {
    if (!isPolling) {
      return;
    }

    let counter = 0;

    let newPayableDocumentaryEvidenceHash: string | undefined;
    const refetchTimer = setInterval(async () => {
      counter++;
      if (counter > POLL_MAX_ATTEMPTS) {
        clearInterval(refetchTimer);
        setIsPolling(false);
        return;
      }

      await Promise.all([
        // Data from queries in Bookkeep > Prepare
        queryClient.invalidateQueries(
          getPayableProofsQueryKey(companyId, payableId),
        ),

        // Data from queries in Bookkeep > All payables
        queryClient.invalidateQueries(getPayableQueryKey(payableId)),
      ]);

      newPayableDocumentaryEvidenceHash = getPayableDocumentaryEvidenceHash(
        companyId,
        payableId,
        queryClient,
      );

      if (
        newPayableDocumentaryEvidenceHash !==
        previousPayableDocumentaryEvidenceHash
      ) {
        clearInterval(refetchTimer);
        refreshPayableBuckets();
        invalidatePayableInPrepareQueryCache(companyId, payableId);
        invalidatePayableQueryCache(payableId);
        setIsPolling(false);
      }
    }, POLL_INTERVAL);

    return (): void => {
      clearInterval(refetchTimer);
      setIsPolling(false);
    };
  }, [isPolling, payableId]);

  return [isPolling, setIsPolling];
}

/**
 * Helpers
 */

const getPayableDocumentaryEvidenceHash = (
  companyId: string,
  payableId: string,
  queryClient: QueryClient,
) => {
  // Data from queries in Bookkeep > Prepare
  let payableDocumentaryEvidenceHash = reshapePayableProofs(
    queryClient.getQueryData(getPayableProofsQueryKey(companyId, payableId)),
  )
    ?.proofs?.map(({ path }) => path)
    .join('');

  // Data from queries in Bookkeep > All payables
  if (!payableDocumentaryEvidenceHash) {
    const data = queryClient.getQueryData<PayableGraphQLRawResponse>(
      getPayableQueryKey(payableId),
    );

    payableDocumentaryEvidenceHash =
      data?.payable?.documentaryEvidence?.attachments
        .map(({ path }) => path)
        .join('');
  }

  return payableDocumentaryEvidenceHash;
};
