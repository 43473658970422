import { AnalyticEventName, track } from "src/core/utils/analytics";

type Params = {
  featureName: string;
};

export const useBetaFeatureNoticeTracking = ({ featureName }: Params) => {
  const onGiveFeedbackButtonClick = () => {
    track(AnalyticEventName.BETA_FEATURE_GIVE_FEEDBACK_BUTTON_CLICKED, {
      feature: featureName,
    });
  };

  const onSubmitFeedbackAndSwitchToOldVersionButtonClicked = () => {
    track(
      AnalyticEventName.BETA_FEATURE_SUBMIT_FEEDBACK_AND_SWITCH_TO_OLD_VERSION_BUTTON_CLICKED,
      {
        feature: featureName,
      },
    );
  };

  const onSubmitFeedbackButtonClicked = () => {
    track(AnalyticEventName.BETA_FEATURE_SUBMIT_FEEDBACK_BUTTON_CLICKED, {
      feature: featureName,
    });
  };

  return {
    onGiveFeedbackButtonClick,
    onSubmitFeedbackAndSwitchToOldVersionButtonClicked,
    onSubmitFeedbackButtonClicked,
  };
};
