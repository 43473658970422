import {
  AutocompleteNoOptions,
  AutocompletePlace,
  Button,
  FormField,
  TextInput,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import { type FormikProps, type FormikErrors } from 'formik';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PhoneNumberInput } from 'common/components/PhoneNumberInput';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { getPlace } from 'common/utils/mapbox';

import { ShippingCountryAutocomplete } from './ShippingCountryAutocomplete';
import { type Values } from './types';

type Props = {
  isRecipientDisabled?: boolean;
  backButtonLink: string;
} & Pick<
  FormikProps<Values>,
  | 'values'
  | 'isSubmitting'
  | 'isValid'
  | 'handleChange'
  | 'handleSubmit'
  | 'setFieldValue'
  | 'setValues'
  | 'errors'
>;

const getPhoneNumberError = (
  values: Values,
  errors: FormikErrors<Values>,
  t: TGlobalFunctionTyped,
) => {
  if (errors.phoneNumber) {
    return values.phoneNumber
      ? t('recard.order.invalidPhoneNumber')
      : t('recard.order.noPhoneNumber');
  }

  return '';
};

export const ShippingForm = ({
  isRecipientDisabled = true,
  backButtonLink,
  // Formik props
  values,
  isSubmitting,
  isValid,
  handleChange,
  handleSubmit,
  setFieldValue,
  setValues,
  errors,
}: Props) => {
  const { t } = useTranslation('global');
  const urlParams = new URLSearchParams(window.location.search);
  const isWebviewActive = urlParams.has('webview');

  return (
    <div
      className={cx('mx-auto my-l w-[420px]', {
        'w-full': isWebviewActive,
      })}
    >
      <h1 className="mb-xs text-complementary title-xl">
        {t('recard.order.shippingInfoTitle')}
      </h1>
      <p className="mb-m text-neutral-dark body-m">
        {t('recard.order.shippingInfoSubtitle')}
      </p>
      <div className="box">
        <form onSubmit={handleSubmit}>
          <FormField
            className="mb-m"
            label={t('cardorder.shippingRecipientLabel')}
          >
            <TextInput
              name="recipientName"
              value={values.recipientName}
              onChange={handleChange}
              isDisabled={isRecipientDisabled}
            />
          </FormField>
          <FormField
            className="mb-m"
            label={t('cardorder.shippingCompanyRecipientLabel')}
          >
            <TextInput
              placeholder={t('cardorder.shippingCompanyRecipientPlaceholder')}
              name="recipient"
              value={values.recipient}
              onChange={handleChange}
              maxLength={35}
            />
          </FormField>
          <FormField
            className="mb-m"
            label={t('cardorder.addressLine1Label')}
            alertMessage={
              errors.addressLine1 ? t('recard.order.noAddressLine1') : ''
            }
          >
            <AutocompletePlace
              fit="parent"
              name="addressLine1"
              placeholder={t('cardorder.addressLine1Placeholder')}
              value={values.addressLine1}
              proximityCountry={values.country}
              handleSelect={(result) => {
                const place = getPlace(result);
                if (place) {
                  setValues((previous) => ({
                    ...previous,
                    addressLine1: place.shortAddress,
                    addressLine2: '',
                    zipCode: place.postcode ?? '',
                    city: place.city ?? '',
                    country: place.country ?? '',
                  }));
                }
              }}
              onBlur={(event) => {
                setFieldValue('addressLine1', event.target.value.trim());
              }}
              renderNoOptions={(value, debouncedValue) => {
                if (value.length < 3 || !debouncedValue) {
                  return (
                    <AutocompleteNoOptions>
                      <div>{t('misc.asyncAutocompleteEmptyState')}</div>
                    </AutocompleteNoOptions>
                  );
                }
                return (
                  <AutocompleteNoOptions>
                    <Trans
                      i18nKey="misc.noOptions"
                      values={{ account: value }}
                      components={[
                        <span className="text-complementary" key="no-option" />,
                      ]}
                    />
                  </AutocompleteNoOptions>
                );
              }}
            />
          </FormField>
          <FormField className="mb-m" label={t('cardorder.addressLine2Label')}>
            <TextInput
              placeholder={t('cardorder.addressLine2Placeholder')}
              value={values.addressLine2}
              onChange={handleChange}
              maxLength={35}
              name="addressLine2"
            />
          </FormField>
          <div className="mb-m flex w-full gap-s">
            <FormField
              className="flex-[50%]"
              label={t('cardorder.zipCodeLabel')}
              alertMessage={errors.zipCode ? t('recard.order.noZipCode') : ''}
            >
              <TextInput
                placeholder={t('cardorder.zipCodeLabel')}
                value={values.zipCode}
                onChange={handleChange}
                maxLength={9}
                name="zipCode"
              />
            </FormField>
            <FormField
              className="flex-[50%]"
              label={t('cardorder.cityLabel')}
              alertMessage={errors.city ? t('recard.order.noCity') : ''}
            >
              <TextInput
                placeholder={t('cardorder.cityLabel')}
                value={values.city}
                onChange={handleChange}
                maxLength={40}
                name="city"
              />
            </FormField>
          </div>
          <FormField
            className="mb-m"
            label={t('cardorder.countryLabel')}
            alertMessage={errors.country ? t('recard.order.noCountry') : ''}
          >
            <ShippingCountryAutocomplete
              countryCode={values.country}
              onSelect={(countryCode) => setFieldValue('country', countryCode)}
            />
          </FormField>

          <FormField
            className="mb-m"
            label={t('cards.activation.step3.phoneInputLabel')}
            infoTipContent={t('recard.order.tooltip')}
            alertMessage={getPhoneNumberError(values, errors, t)}
          >
            <PhoneNumberInput
              fit="parent"
              value={values.phoneNumber}
              onChange={(phoneNumber) => {
                setFieldValue('phoneNumber', phoneNumber);
              }}
              isInvalid={Boolean(errors.phoneNumber)}
              name="phoneNumber"
            />
          </FormField>
          <div className="flex justify-between">
            {!isWebviewActive && (
              <Link to={backButtonLink}>
                <Button
                  text={t('recard.order.goBackButton')}
                  isDisabled={isSubmitting}
                  variant="secondary"
                />
              </Link>
            )}
            <Button
              type="submit"
              fit={isWebviewActive ? 'parent' : 'content'}
              text={t('recard.order.orderButton')}
              isDisabled={!isValid || isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
