import { Button, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useNotifications } from 'modules/app/notifications';
import { trackPayableAllRequested } from 'modules/bookkeep/export/analytics';
import {
  PayableList,
  PayableListSkeleton,
  PayableListTitle,
} from 'modules/payable/components';
import { useGetPayableCount, useGetPayableList } from 'modules/payable/hooks';
import {
  type ExportPayableReportPayload,
  useExportPayableReport,
  useExportPayableReportErrorMessage,
} from 'modules/payable-report/hooks/api';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useFiltersContext } from './PayablesFiltersContainer/hooks';
import { type FiltersState } from './PayablesFiltersContainer/hooks/useFiltersContext';

type Props = {
  payableId: string;
  onClick(payableId: string): void;
};

export const PayableListContainer = ({ payableId, onClick }: Props) => {
  const { t } = useTranslation('global');
  const { state, updateUrlParams } = useFiltersContext();

  const payablesQueryState = useGetPayableList({
    filters: state,
    options: { onSuccess: () => updateUrlParams(state) },
  });

  const countPayablesQueryState = useGetPayableCount(state);

  const queryStates = useQueryStates({
    states: {
      payables: payablesQueryState,
      totalPayables: countPayablesQueryState,
    },
    reshapeData: ({ payables, totalPayables }) => ({
      payables,
      totalPayables: totalPayables.totalCount,
    }),
  });

  return (
    <QuerySuspense
      queryState={queryStates}
      loading={<PayableListSkeleton />}
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="ErrorState"
          translations={{}}
        />
      )}
    >
      {({ payables, totalPayables }) => {
        return (
          <>
            <PayableListTitle
              title={
                <>
                  {t('payables.export.title', {
                    count: totalPayables,
                  })}
                  <span className="ml-xxs inline-block font-normal text-neutral-dark">
                    ({totalPayables})
                  </span>
                </>
              }
            />
            <PayableList
              activePayableId={payableId}
              payables={payables}
              totalPayables={totalPayables}
              hasNextPage={payablesQueryState.hasNextPage}
              onFetchNextPage={payablesQueryState.fetchNextPage}
              onClick={onClick}
              renderActions={(props) => <PayableListActions {...props} />}
            />
          </>
        );
      }}
    </QuerySuspense>
  );
};

/**
 * List actions
 */

const PayableListActions = ({
  numberOfSelectedRows,
  areAllRowsSelected,
  unselectedRows,
  selectedRows,
}: {
  numberOfSelectedRows: number;
  areAllRowsSelected: boolean;
  unselectedRows: string[];
  selectedRows: string[];
}) => {
  const { t } = useTranslation('global');

  const { state } = useFiltersContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [exportPayableReport] = useExportPayableReport();

  const exportPayableReportErrorMessage = useExportPayableReportErrorMessage();

  const { dangerNotif } = useNotifications();

  const exportPayables = async () => {
    trackPayableAllRequested();
    setIsModalOpen(true);
    try {
      await exportPayableReport({
        ...(areAllRowsSelected ? reshapeFilters(state) : {}),
        ids: !areAllRowsSelected ? selectedRows : undefined,
        excludeIds:
          areAllRowsSelected && unselectedRows.length > 0
            ? unselectedRows
            : undefined,
      });
    } catch (error) {
      const errorMessage = exportPayableReportErrorMessage(error);
      dangerNotif(errorMessage);
    }
  };

  return (
    <>
      <Button
        text={t('payables.export.downloadSelection')}
        variant="secondary"
        onClick={exportPayables}
        isDisabled={numberOfSelectedRows === 0}
      />

      <Modal
        isOpen={isModalOpen}
        iconName="info"
        iconVariant="info"
        title={t('wallet.statements.downloadModal.title')}
        actions={[
          <Button
            key="no"
            variant="primary"
            text={t('wallet.statements.downloadModal.hide')}
            onClick={() => setIsModalOpen(false)}
          />,
        ]}
      >
        <div>{t('wallet.statements.downloadModal.subtitle')}</div>
      </Modal>
    </>
  );
};

const reshapeFilters = (state: FiltersState): ExportPayableReportPayload => {
  const reshapedPayableStates: ExportPayableReportPayload['payableStates'] = [];
  if (state.payableStates) {
    if (state.payableStates.includes('toPrepare')) {
      reshapedPayableStates.push('created', 'unprepared');
    }
    if (state.payableStates.includes('toExport')) {
      reshapedPayableStates.push(
        'prepared',
        'to_accounting_pending',
        'to_accounting_failed',
      );
    }
    if (state.payableStates.includes('exported')) {
      reshapedPayableStates.push('in_accounting', 'in_accounting_manually');
    }
  } else {
    // If we don't have state filters, we fetch every state except "discarded"
    reshapedPayableStates.push(
      'created',
      'prepared',
      'unprepared',
      'notBookkept',
      'to_accounting_pending',
      'to_accounting_failed',
      'in_accounting',
      'in_accounting_manually',
    );
  }

  return {
    payableStates:
      reshapedPayableStates.length > 0 ? reshapedPayableStates : undefined,
    payableTypes:
      state.payableTypes as ExportPayableReportPayload['payableTypes'],
    settlementState:
      state.settlementState as ExportPayableReportPayload['settlementState'],
    documentaryEvidenceStatus:
      state.documentaryEvidenceStatus as ExportPayableReportPayload['documentaryEvidenceStatus'],
    costCenters: state.costCenter ? [state.costCenter] : undefined,
    search: state.search,
    creationDate: state.creationDate,
  };
};
