import {
  Avatar,
  colors,
  Icon,
  IconButton,
  Tag,
  Tooltip,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { type SelectedApprover, type Approver } from '../../../types';
import { SelectApproverDropdown } from '../../SelectApproverDropdown';

type Props = {
  approver: Approver;
  onDelete: () => void;
  isAllFollowingMode: boolean;
  index: number;
  onUpdateRule: (selectedApprover: SelectedApprover) => void;
  costCenterOwnerId: string;
};

export const ApproversListItem = ({
  approver,
  onDelete,
  isAllFollowingMode,
  index,
  onUpdateRule,
  costCenterOwnerId,
}: Props) => {
  const { t } = useTranslation('global');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleApproverSelect = (selectedApprover: SelectedApprover) => {
    onUpdateRule(selectedApprover);
    setIsDropdownOpen(false);
  };

  const handleButtonClick = useCallback(() => {
    setIsDropdownOpen((previous) => !previous);
  }, []);

  return (
    <>
      {isDropdownOpen ? (
        <SelectApproverDropdown
          costCenterOwnerId={costCenterOwnerId}
          onSelect={handleApproverSelect}
          currentApproverId={
            approver.approverType === 'user' ||
              approver.approverType === 'costCenterOwner'
              ? approver.id
              : 'reportingManager'
          }
          onBlur={() => setIsDropdownOpen(false)}
          className="mx-xs my-[5px]"
        />
      ) : (
        <div
          key={
            approver.approverType === 'reportingManager'
              ? 'reportingManager'
              : approver.id
          }
          className={classNames(
            'ApproversList__item',
            isAllFollowingMode && 'ApproversList__item--all-following',
          )}
        >
          {isAllFollowingMode && (
            <div className="ApproversList__item__details__index">
              {index + 1}
            </div>
          )}
          <ApproverDisplay approver={approver} />
          <div className="ApproversList__item__icons">
            <IconButton
              iconName="pen"
              variant="borderless"
              onClick={handleButtonClick}
              data-testid="ApproversList__edit-approver"
              aria-label={t('misc.openMenu')}
            />
            <IconButton
              iconName="trash"
              variant="borderless"
              onClick={() => {
                track(
                  AnalyticEventName.APPROVALS_WORKFLOW_SETUP_DELETE_APPROVER,
                  {
                    approverId:
                      approver.approverType === 'user'
                        ? approver.id
                        : undefined,
                    approverType: approver.approverType,
                  },
                );
                onDelete();
              }}
              data-testid="ApproversList__delete-approver"
              aria-label={t('misc.delete')}
            />
          </div>
        </div>
      )}
    </>
  );
};

const ApproverDisplay = ({ approver }: { approver: Approver }) => {
  const { t } = useTranslation('global');

  if (approver.approverType === 'reportingManager') {
    return (
      <div className="flex w-full cursor-default gap-xs">
        <div className="flex h-l w-l shrink-0 items-center justify-center self-center rounded-[50%] bg-primary-lighter">
          <Icon name="person" color={colors.white} />
        </div>
        <div>
          <div className="flex items-center gap-xs">
            <div className="ApproversList__item__details__name text-complementary title-m">
              {t('teams.approvalFlows.reportingManager')}
            </div>
            <Tag variant="primary">{t('misc.new')}</Tag>
          </div>
          <div className="mt-xxs max-w-[290px] text-left text-neutral-dark body-s">
            {t('teams.approvalFlows.reportingManagerDisclaimer')}
          </div>
        </div>
      </div>
    );
  }

  const label = approver.isPending ? approver.email : approver.fullname;
  const avatar = approver.avatar ?? undefined;
  const shouldDisplayTooltip = !getIsCostCenterOwner(approver);

  return (
    <Tooltip isDisabled={shouldDisplayTooltip} content={label}>
      <div className="flex w-full cursor-default gap-xs">
        <Avatar src={avatar} text={label} className="shrink-0" />
        <div className="flex items-center gap-xs">
          {getIsCostCenterOwner(approver) ? (
            <div className="ApproversList__item__details__name text-complementary title-m">
              {t('teams.approvalFlows.costCenterOwner')}
            </div>
          ) : (
            <div className="ApproversList__item__details__name">{label}</div>
          )}

          {approver.isAccountOwner && (
            <div className="text-neutral-dark">
              ({t('profile.roleTitleAccountOwner')})
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

const getIsCostCenterOwner = (approver: Approver) => {
  return approver.approverType === 'costCenterOwner';
};
