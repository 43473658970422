import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

type Props = {
  className?: string;
  title?: string;
  actionText?: string;
  retry(): void;
};

export const CalloutError = ({
  className,
  title,
  actionText,
  retry,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Callout
      className={className}
      variant="warning"
      title={title ?? t('lazyLoading.error.component.title')}
    >
      <Button
        text={actionText ?? t('lazyLoading.error.retry')}
        onClick={retry}
      />
    </Callout>
  );
};
