import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { usePrevious, useAsync } from 'react-use';

import { getIsMarqeta } from 'modules/company/redux/selectors';
import { type AppState } from 'src/core/reducers';

import * as achSelectors from '../../redux/selectors';
import * as achThunks from '../../redux/thunks';

export type RenderPropertyParams = {
  isLoading: boolean;
  isWalletPage: boolean;
  hasPendingFundingSource: boolean;
  hasValidatedFundingSource: boolean;
  shouldShowModal: boolean;
  hasSuccessfullyCreatedFundingSource: boolean;
  clearHasSuccessfullyCreatedFundingSource: () => void;
  accountSuffix: string | null;
};

type Props = {
  getAchFundingSources: () => void;
  isMarqeta: boolean;
  hasPendingAchSource: boolean;
  hasValidatedAchSource: boolean;
  shouldShowModal: boolean;
  accountSuffix: string | null;
  render: (renderPropertyParams: RenderPropertyParams) => JSX.Element | null;
};

const CheckBankStatementContainer = ({
  getAchFundingSources,
  hasPendingAchSource,
  hasValidatedAchSource,
  shouldShowModal,
  isMarqeta,
  render,
  accountSuffix,
}: Props) => {
  const [
    hasSuccessfullyCreatedFundingSource,
    setHasSuccessfullyCreatedFundingSource,
  ] = useState(false);

  const [hasPendingFundingSource, setHasPendingFundingSource] = useState(
    isMarqeta && hasPendingAchSource,
  );

  const previousHasPendingAchSource = usePrevious(hasPendingAchSource);

  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  useAsync(async () => {
    if (isMarqeta) {
      await getAchFundingSources();
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setHasPendingFundingSource(isMarqeta && hasPendingAchSource);

    if (
      isMarqeta &&
      !isLoading &&
      !hasPendingAchSource &&
      previousHasPendingAchSource
    ) {
      setHasSuccessfullyCreatedFundingSource(true);
    }
  }, [isMarqeta, isLoading, hasPendingAchSource, previousHasPendingAchSource]);

  return render({
    hasPendingFundingSource,
    hasValidatedFundingSource: hasValidatedAchSource,
    shouldShowModal,
    hasSuccessfullyCreatedFundingSource,
    clearHasSuccessfullyCreatedFundingSource: () =>
      setHasSuccessfullyCreatedFundingSource(false),
    isWalletPage: location.pathname.endsWith('/company/bank'),
    isLoading,
    accountSuffix,
  });
};

const mapStateToProps = (state: AppState) => ({
  hasPendingAchSource: achSelectors.hasPendingAchSource(state),
  hasValidatedAchSource: achSelectors.hasValidatedAchSource(state),
  shouldShowModal: achSelectors.shouldShowCheckBankStatementModal(state),
  isMarqeta: getIsMarqeta(state),
  accountSuffix: achSelectors.getAchSourceAccountSuffix(state),
});

const mapDispatchToProps = {
  getAchFundingSources: achThunks.getAchFundingSources,
};

const ConnectedCheckBankStatementContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckBankStatementContainer);

export { ConnectedCheckBankStatementContainer as CheckBankStatementContainer };
