import {
  Panel,
  SkeletonAvatar,
  SkeletonButton,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import './ExpenseReviewFormSkeleton.css';

const skeletonButtonCount = 5;

export const ExpenseReviewFormSkeleton = () => {
  return (
    <Panel
      className="ExpenseReviewFormSkeleton ml-s"
      header={
        <div className="flex h-3xl items-center gap-xs pt-s">
          <SkeletonAvatar variant="circle" />
          <SkeletonText width="96px" size="l" />
        </div>
      }
      footer={
        <div className="flex justify-between gap-s">
          <SkeletonButton className="flex-1" />
          <SkeletonButton className="flex-1" />
        </div>
      }
    >
      <div>
        {Array.from({ length: skeletonButtonCount }, (_, key) => (
          <div className="mb-s" key={key}>
            <SkeletonText width="96px" size="l" />
            <SkeletonButton width="100%" />
          </div>
        ))}
      </div>
    </Panel>
  );
};
