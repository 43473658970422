import { type AnyAction } from '@reduxjs/toolkit';
import filter from 'lodash/filter';

import * as customFieldsTypes from 'src/core/actionTypes/customFields';
import * as groupsTypes from 'src/core/actionTypes/groups';

type CustomFieldsStateType = {
  groups: null | unknown[];
  customFields: null | unknown[];
  behavior: {
    errors: null | unknown[];
    isLoading: boolean;
  };
};
const initialState: CustomFieldsStateType = {
  groups: null,
  customFields: null,
  behavior: {
    errors: null,
    isLoading: false,
  },
};

const accountingReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case groupsTypes.FETCH_GROUPS_LOADING:
    case customFieldsTypes.FETCH_CF_LOADING: {
      return {
        ...state,
        behavior: {
          errors: null,
          isLoading: true,
        },
      };
    }
    case groupsTypes.FETCH_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: action.payload.groups,
        behavior: {
          errors: null,
          isLoading: false,
        },
      };
    }
    case customFieldsTypes.FETCH_CF_SUCCESS: {
      return {
        ...state,
        // eslint-disable-next-line lodash/matches-shorthand
        customFields: filter(action.payload.customFields, { deleted_at: null }),
        behavior: {
          errors: null,
          isLoading: false,
        },
      };
    }
    case groupsTypes.FETCH_GROUPS_FAILURE:
    case customFieldsTypes.FETCH_CF_FAILURE: {
      return {
        ...state,
        behavior: {
          errors: true,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default accountingReducer;
