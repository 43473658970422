import { HighlightIcon, Tooltip } from '@dev-spendesk/grapes';
import React, { Fragment } from 'react';

import { type MemberIntegrationMetadata } from '../../utils';

export const HrStatusIcon = (memberMetadata: MemberIntegrationMetadata) => {
  if (memberMetadata.existsInKombo) {
    return (
      <Tooltip
        key="HrStatusIcon"
        maxWidth={361}
        content={memberMetadata.tooltipText}
        placement="top"
      >
        <HighlightIcon
          name={memberMetadata.iconName}
          variant={memberMetadata.iconVariant}
          size="s"
        />
      </Tooltip>
    );
  }
  return <></>;
};
