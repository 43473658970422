import { type MonetaryValue } from 'ezmoney';

import type { Supplier } from 'modules/suppliers';

import { type InvoiceStatus } from '../../../invoices/models';

type MatchingData =
  | 'checksum'
  | 'supplierId'
  | 'invoiceNumber'
  | 'date'
  | 'amount';

export type Duplicate = {
  matchingData: MatchingData[];
  matchingEntity: {
    id: string;
    owner: {
      id: string;
      name: string;
    };
    supplier: Supplier;
    amount: MonetaryValue;
    description: string;
    date: Date;
    type: 'invoiceRequest';
    invoiceNumber: string;
    status: InvoiceStatus;
    reimbursementPaymentId: string | undefined;
  };
};

export const findDuplicateAlreadyReviewed = (
  duplicates: Duplicate[],
): Duplicate | undefined =>
  duplicates.find((duplicate) =>
    ['to-pay', 'partially-paid', 'paid'].includes(
      duplicate.matchingEntity.status,
    ),
  );
