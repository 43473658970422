import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type EmployeeAccountCode } from '../../accounting';

const GET_EMPLOYEE_ACCOUNTS_CODES = gql`
  query GetEmployeeAccountsCodes(
    $companyId: ID!
    $filter: AccountPayableFilter!
    $first: Int
  ) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        employeeAccounts(first: $first, filter: $filter) {
          totalCount
          edges {
            cursor
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
              isArchived
            }
          }
        }
      }
    }
  }
`;

type RawData = {
  company: {
    chartOfAccounts: {
      employeeAccounts: {
        edges: {
          cursor: string;
          node: {
            generalAccountCode: string;
            auxiliaryAccountCode: string | undefined;
            isDefault: boolean;
            id: string;
            isArchived: boolean;
          };
        }[];
      };
    };
  };
};

export const useGetEmployeeAccountCodesQuery = (options?: {
  includeArchived: boolean;
  first?: number;
  search?: string;
}): QueryState<EmployeeAccountCode[]> => {
  const { company: companyId } = useParams();

  return useQuery<EmployeeAccountCode[], RawData>({
    key: [
      'accountsPayable',
      'useEmployeeAccountCodesQuery',
      companyId,
      options,
    ],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_EMPLOYEE_ACCOUNTS_CODES,
      variables: {
        first: options?.first,
        filter: {
          ...(options?.search && {
            search: options.search,
          }),
          includeArchived:
            options?.includeArchived !== undefined
              ? options?.includeArchived
              : false,
        },
      },
    },
    reshapeData(data) {
      return data.company.chartOfAccounts.employeeAccounts.edges.map(
        ({ node }) => {
          return {
            id: node.id,
            generalAccountCode: node.generalAccountCode,
            auxiliaryAccountCode: node.auxiliaryAccountCode ?? '',
            isArchived: node.isArchived,
          };
        },
      );
    },
  });
};
