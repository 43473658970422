import React from 'react';

import { ModalError } from 'common/components/ModalError';
import { ModalLoader } from 'common/components/ModalLoader';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { onEditMemberDetailsModalClosed as trackEditMemberDetailsModalClosed } from 'modules/members/tracking';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import history from 'src/core/history';
import { type Delegation } from 'src/core/modules/delegation';

import { MemberEditModal } from '../../components/MemberEditModal';
import { useMemberQuery, useRolesQuery } from '../../hooks';

type Props = {
  memberId: string;
  onClose: () => void;
  onEditDelegation: (role: Delegation, onComplete: () => void) => void;
};

export const MemberEditModalContainer = ({
  memberId,
  onClose,
  onEditDelegation,
}: Props) => {
  const { t } = useTranslation('global');

  const queriesState = useQueryStates({
    states: {
      member: useMemberQuery(memberId),
      roles: useRolesQuery(),
    },
  });

  return (
    <QuerySuspense
      queryState={queriesState}
      loading={<ModalLoader />}
      fallback={() => (
        <ModalError
          message={t('members.errorModal.fetchFailedMessage')}
          onClose={onClose}
          onRetry={() => history.go(0)}
        />
      )}
    >
      {({ member, roles }) => {
        return (
          <MemberEditModal
            member={member}
            roles={roles}
            onClose={() => {
              trackEditMemberDetailsModalClosed();
              onClose();
            }}
            onEditDelegation={onEditDelegation}
          />
        );
      }}
    </QuerySuspense>
  );
};
