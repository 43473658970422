import { type AppState } from "src/core/reducers";

import { type WireTransferActivationStatus } from '../../general-settings/models';

export const getWireTransferActivationStatusForExpenses = (
  state: AppState,
): WireTransferActivationStatus | null =>
  state.billing.wireTransferActivationStatusForExpenses;

export const getWireTransferActivationStatusForInvoices = (
  state: AppState,
): WireTransferActivationStatus | null =>
  state.billing.wireTransferActivationStatusForInvoices;
