import { type getCountryFromCode } from 'src/core/config/country';
import { type CurrenciesKey } from 'src/core/config/money';
import { type CompanyType } from 'src/core/reducers/global';

export type OrganisationReportingCurrency = CurrenciesKey;

type OrganisationReportingEntityCountryCode = Parameters<
  typeof getCountryFromCode
>[0];

export type OrganisationReportingEntityBreakdown = {
  scheduledTransfers: number;
  cardsLimits: number;
  available: number;
  shortfall: number;
  trend: 'positive' | 'neutral' | 'warning';
};

export type OrganisationReportingGroupBreakdown = {
  scheduledTransfers: number;
  cardsLimits: number;
  available: number;
  availableCount: number;
  shortfall: number;
  shortfallCount: number;
  trend: 'group';
};

export type OrganisationReportingEntity = {
  id: string;
  name: string;
  country: OrganisationReportingEntityCountryCode;
  currency: OrganisationReportingCurrency;
  type: CompanyType;
  hasChurned: boolean;
  isChurning: boolean;
};

export type OrganisationReportingStatistics = {
  requestsToApprove: number | undefined;
  invoicesToPay: number | undefined;
  lateReceipts: number | undefined;
  payablesToReview: number | undefined;
};

export type OrganisationReportingWalletDetails = {
  walletBalance: number;
  walletBalanceByCurrency:
    | Record<OrganisationReportingCurrency, number>
    | undefined;
  breakdown: OrganisationReportingEntityBreakdown;
};

export type OrganisationReportingKycStatus = {
  isKycInProgress: boolean;
  isKycAwaitingApproval: boolean;
};

export type OrganisationReportingEntityDetails =
  OrganisationReportingWalletDetails & OrganisationReportingKycStatus;

export type ExtendedOrganisationReportingEntity = OrganisationReportingEntity &
  OrganisationReportingEntityDetails;

export const isExtendedOrganisationReportingEntity = (
  entityData: OrganisationReportingEntity,
): entityData is ExtendedOrganisationReportingEntity => {
  return 'walletBalance' in entityData && 'isKycInProgress' in entityData;
};
