import {
  Button,
  Callout,
  Modal,
  RadioBox,
  RadioGroup,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type NewBranchEntityModalForm } from '../../form';

type Props = Partial<FormikProps<NewBranchEntityModalForm>> & {
  onCancel: () => void;
  onClose: () => void;
} & Pick<
    FormikProps<NewBranchEntityModalForm>,
    'isSubmitting' | 'handleSubmit'
  >;

export const AccountTypeStep = ({
  onCancel,
  onClose,
  handleSubmit,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      iconName="info"
      iconVariant="info"
      title={t('branch.accountTypeStep.title')}
      subtitle={t('branch.accountTypeStep.subtitle')}
      onClose={onClose}
      actions={[
        <Button
          key="cancel"
          onClick={onCancel}
          text={t('misc.back')}
          variant="secondary"
        />,
        <Button
          key="confirm"
          type="submit"
          form="account-type"
          isLoading={isSubmitting}
          text={t('misc.continue')}
          iconName="caret-right"
          iconPosition="right"
        />,
      ]}
    >
      <form id="account-type" onSubmit={handleSubmit}>
        <Callout
          className="mb-m"
          variant="info"
          title={t('branch.accountTypeStep.noBranchEntityCallout')}
        />
        <RadioGroup
          direction="column"
          name="accountType"
          onChange={() => {}}
          value="branch_expense_entity"
        >
          <RadioBox
            className="text-left"
            isDisabled
            label={t('branch.accountTypeStep.branchEntity.label')}
            description={t('branch.accountTypeStep.branchEntity.description')}
            value="branch_entity"
          />
          <RadioBox
            className="text-left"
            label={t('branch.accountTypeStep.expenseEntity.label')}
            description={t('branch.accountTypeStep.expenseEntity.description')}
            value="branch_expense_entity"
          />
        </RadioGroup>
      </form>
    </Modal>
  );
};
