import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  accountPayableId: string;
  memberId: string;
};

type RawData =
  | {
      outcome: 'notSet';
      reason: 'accountNotFound';
    }
  | {
      outcome: 'set';
    };

export type Result = RawData;

export const useSetAccountPayableToEmployeeMutation = (): MutationState<
  Payload,
  Result
> => {
  return useMutation<Payload, Result, RawData>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint:
        '/accounting-integration/chart-of-accounts/employee-accounts/assign-account',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ data, client }): void => {
        if (data.outcome === 'set') {
          client.invalidateQueries('accountsPayable');
          client.invalidateQueries('useGetPayableQuery'); // payable details in Prepare
          client.invalidateQueries('payable'); // cache payable details in Prepare
        }
      },
    },
  });
};
