export const euMembers = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Republic of Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
] as const;

export const eeaMembers = [
  ...euMembers,
  'IS', // Iceland
  'LI', // Liechtenstein
  'NO', // Norway
] as const;

export const allowedCountriesForBankableCardOrders = [
  ...eeaMembers,
  'GB', // Great Britain
  'GF', // French Guiana
  'GI', // Gibraltar
  'GP', // Guadeloupe
  'MQ', // Martinique
  'RE', // Réunion
  'YT', // Mayotte
  'PF', // French Polynesia
] as const;

/**
 * List of countries where we can ship a card to for SFS EEA as per legal and
 * compliance guidance. See Notion doc here: https://www.notion.so/spendesk/Approved-and-Prohibited-Countries-ae9505c96c6945f5ae214374265d7008?pvs=4#a1360ce5c0484f21b99fd562e8cedc7e
 */
export const allowedShippingCountriesForSfsEeaCardOrders = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IS', // Iceland
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LI', // Liechtenstein
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal (This includes Azores and Madeira)
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain (This includes Canary Island, Ceuta and Melilla)
  'SE', // Sweden
  'GP', // Guadeloupe
  'GF', // Guyane (French Guiana)
  'RE', // La Réunion
  'MQ', // Martinique
  'YT', // Mayotte
  'MF', // Saint-Martin
] as const;

/**
 * List of countries where we can ship a card to for SFS UK as per legal and
 * compliance guidance. See Notion doc here: https://www.notion.so/spendesk/Adyen-SAS-UK-Card-Delivery-f7ca4f1f1fab43b385b70e1b7e04ce39
 */
export const allowedShippingCountriesForSfsUkCardOrders = [
  ...allowedShippingCountriesForSfsEeaCardOrders,
  'GB', // United Kingdom
  'GI', // Gibraltar
  'GG', // Guernsey (including Alderney and Sark)
  'IM', // Isle of Man
  'JE', // Jersey
] as const;

export const allowedShippingCountriesForAllSfsCardOrders = [
  ...allowedShippingCountriesForSfsEeaCardOrders,
  ...allowedShippingCountriesForSfsUkCardOrders,
] as const;
