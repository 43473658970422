import { SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';

import { useBudgetBreakdownSpendingsQuery } from './hooks/useBudgetBreakdownSpendingsQuery';
import { type SpendingType } from '../../breakdownSpendings';
import { BreakdownSpendingsSection } from '../../components/BreakdownSpendingsSection';
import { type BreakdownPart } from '../../models/breakdown';
import { type ExpenseCategoryBreakdown } from '../../models/expenseCategoryBreakdown';
import { type PeriodRangePayload } from '../../models/period';

type Props = {
  spendingType: SpendingType;
  budgetId: string;
  breakdownComputedAt: Date;
  periodRange: PeriodRangePayload;
  breakdownPart: BreakdownPart;
  expenseCategory?: ExpenseCategoryBreakdown['expenseCategory'];
};

export const BreakdownSpendingsSectionContainer = ({
  spendingType,
  breakdownPart,
  breakdownComputedAt,
  budgetId,
  periodRange,
  expenseCategory,
}: Props) => {
  const budgetBreakdownSpendingsQueryState = useBudgetBreakdownSpendingsQuery({
    budgetId,
    breakdownComputedAt,
    breakdownPart,
    spendingType,
    periodRange,
    expenseCategory,
  });

  return (
    <QuerySuspense
      queryState={budgetBreakdownSpendingsQueryState}
      loading={
        <>
          <SkeletonText width="130px" className="mb-s mt-m" />
          <SkeletonTable
            numberOfRows={5}
            columns={[
              {
                cell: <SkeletonText width="80%" />,
                header: <SkeletonText width="50%" />,
                width: '420px',
              },
              ...Array.from({ length: 4 }, () => ({
                cell: <SkeletonText width="80%" />,
                header: <SkeletonText width="50%" />,
              })),
            ]}
            withColumnSeparator
            withHeader
          />
        </>
      }
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {(budgetBreakdownSpendings) => (
        <BreakdownSpendingsSection
          breakdownSpendings={budgetBreakdownSpendings}
          spendingType={spendingType}
          breakdownPart={breakdownPart}
          hasNextPage={budgetBreakdownSpendingsQueryState.hasNextPage}
          onFetchNextPage={budgetBreakdownSpendingsQueryState.fetchNextPage}
        />
      )}
    </QuerySuspense>
  );
};
