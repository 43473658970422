import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type BookkeepingStartDate } from '../../accounting/bookkeepingStartDate';

const GET_BOOKKEEPING_START_DATE = gql`
  query GetBookkeepingStartDate($companyId: ID!) {
    company(id: $companyId) {
      id
      bookkeepingSettings {
        payablesBookkeepingStartDate {
          startDate
        }
      }
    }
  }
`;

type RawData = {
  company: {
    bookkeepingSettings: {
      payablesBookkeepingStartDate: {
        startDate: string;
      };
    };
  };
};

export const useGetBookkeepingStartDateQuery =
  (): QueryState<BookkeepingStartDate> => {
    const { company: companyId } = useParams();

    return useQuery<BookkeepingStartDate, RawData>({
      key: ['useGetBookkeepingStartDateQuery', companyId],
      options: {
        cacheTime: 10 * 60 * 1000, // 10min,
        staleTime: 10 * 60 * 1000, // 10min,
      },
      request: {
        type: 'graphQL',
        target: 'v2',
        query: GET_BOOKKEEPING_START_DATE,
      },
      reshapeData(data) {
        const { startDate } =
          data.company.bookkeepingSettings.payablesBookkeepingStartDate;

        return {
          bookkeepingStartDate: startDate ? new Date(startDate) : undefined,
        };
      },
    });
  };
