import { differenceInDays } from 'date-fns';

import {
  MINIMUM_DAYS_TO_SHOW_BANK_STATEMENT_MODAL,
  MAXIMUM_DAYS_BEFORE_SHOWING_WARNING,
} from '../constants';
import {
  type AchState,
  type AutomatedFundingRuleCreateSteps,
  type AutomatedFundingRuleDeleteSteps,
  type AutomatedFundingRuleLoadingSteps,
  type GpaOrder,
  type OneOffFundingRequestCreateSteps,
} from '../types';

export interface AppState {
  ach: AchState;
}

export const getAchError = (state: AppState): string | null => state.ach.error;

export const getAchSourceToken = (state: AppState): string | null =>
  state.ach.achSourceToken;

export const hasPendingAchSource = (state: AppState): boolean =>
  state.ach.hasPendingAch;

export const hasValidatedAchSource = (state: AppState): boolean =>
  state.ach.hasValidatedAch;

export const hasActiveAchSource = (state: AppState): boolean =>
  Boolean(
    state.ach.achSourceToken &&
      !hasPendingAchSource(state) &&
      hasValidatedAchSource(state),
  );

export const getWalletOneOffFundingCreateStep = (
  state: AppState,
): OneOffFundingRequestCreateSteps => state.ach.oneOffFundingRequestCreateStep;

export const getWalletAutomatedFundingRuleLoadingStep = (
  state: AppState,
): AutomatedFundingRuleLoadingSteps =>
  state.ach.automatedFundingRuleLoadingStep;
export const getWalletAutomatedFundingRuleDeleteStep = (
  state: AppState,
): AutomatedFundingRuleDeleteSteps => state.ach.automatedFundingRuleDeleteStep;
export const getWalletAutomatedFundingRuleCreateStep = (
  state: AppState,
): AutomatedFundingRuleCreateSteps => state.ach.automatedFundingRuleCreateStep;

export const getHasJustCreatedAutomatedFundingRule = (
  state: AppState,
): boolean => state.ach.hasJustCreatedAutomatedFundingRule;

export const getHasJustCreatedOneOffFundingRequest = (
  state: AppState,
): boolean => state.ach.hasJustCreatedOneOffFundingRequest;

export const getAutomatedFundingRuleAmount = (state: AppState): number | null =>
  state.ach.automatedFundingRuleAmount;
export const getAutomatedFundingRuleThreshold = (
  state: AppState,
): number | null => state.ach.automatedFundingRuleThreshold;

export const getMfaProcedureId = (state: AppState): string | null =>
  state.ach.mfaProcedureId;

const getMfaType = (state: AppState): string | null => state.ach.mfaType;

const getMfaParams = (state: AppState): string | null => state.ach.mfaParams;

export const getMfaContact = (state: AppState): string | null => {
  const mfaType = getMfaType(state);
  const mfaParams = getMfaParams(state);
  if (mfaType === 'phone' && mfaParams) {
    const formattedPhoneNumber = mfaParams.split(' ').join('');
    const startIndex: number = formattedPhoneNumber.startsWith('+') ? 3 : 2;
    const endIndex: number = formattedPhoneNumber.length - 2;
    let unknowLength: number = formattedPhoneNumber.length - 2 - startIndex;
    let middleNumber: string = '';
    while (unknowLength) {
      middleNumber += '*';
      unknowLength--;
    }
    return (
      formattedPhoneNumber.slice(0, Math.max(0, startIndex)) +
      middleNumber +
      formattedPhoneNumber.slice(Math.max(0, endIndex))
    );
  }
  return null;
};

export const getAchSourceAccountSuffix = (state: AppState): string | null =>
  state.ach.achSourceAccountSuffix;

export const shouldShowCheckBankStatementModal = (state: AppState): boolean => {
  if (!hasPendingAchSource(state) || !state.ach.pendingAchCreatedAt) {
    return false;
  }

  const daysSinceAchSourceWasCreated = differenceInDays(
    new Date(),
    state.ach.pendingAchCreatedAt,
  );

  return (
    daysSinceAchSourceWasCreated >= MINIMUM_DAYS_TO_SHOW_BANK_STATEMENT_MODAL
  );
};

export const isPendingAchSourceLate = (state: AppState): boolean => {
  if (!hasPendingAchSource(state) || !state.ach.pendingAchCreatedAt) {
    return false;
  }

  const daysSinceAchSourceWasCreated = differenceInDays(
    new Date(),
    state.ach.pendingAchCreatedAt,
  );

  return daysSinceAchSourceWasCreated >= MAXIMUM_DAYS_BEFORE_SHOWING_WARNING;
};

export const getFundingActionsCount = (state: AppState): number =>
  state.ach.fundingActionsCount;

export const getPendingGpaOrders = (state: AppState): GpaOrder[] => {
  return state.ach.gpaOrders.filter(
    (gpaOrder) => gpaOrder.status === 'PENDING',
  );
};
