import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { useUser } from '../../app/hooks/useUser';

/**
 * Multi-entity hub is acessible if
 * - FT is enabled
 * - the current user is AO or Controller of more than 1 company
 * @returns {boolean} Whether the user has access to the multi-entity hub.
 */
export const useHasAccessToMultiEntityHub = () => {
  const isMultiEntityHubEnabled = useFeature(
    FEATURES.ORGANISATION_REPORTING_PAGE,
  );

  const user = useUser();
  const managedCompanies = Object.entries(user.data_by_company).filter(
    ([_, data]) => data.is_account_owner || data.is_controller,
  );

  return isMultiEntityHubEnabled && managedCompanies.length > 1;
};
