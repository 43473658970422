import { Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import './Gauge.css';

export type Segment = {
  fill: string;
  width: string | number;
  tooltipContent?: React.ReactChild;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hasAnimation?: boolean;
};

type Props = {
  className?: string;
  segments: Segment[];
  bypassDefaultHoverBehaviour?: boolean;
  hasAnimation?: boolean;
  withRoundedSegments?: boolean;
  height?: string;
};

export const Gauge = ({
  className = '',
  segments,
  bypassDefaultHoverBehaviour = false,
  hasAnimation = false,
  withRoundedSegments = false,
  height = 'var(--sizing-s)',
}: Props) => {
  return (
    <div
      className={cx(`GaugeBar ${className}`, {
        'GaugeBar--clickable': Boolean(
          segments.some((segment) => Boolean(segment.onClick)),
        ),
        'GaugeBar--has-animation': hasAnimation,
        'GaugeBar--withRoundedSegments': withRoundedSegments,
      })}
      style={{ height }}
    >
      {segments.map((segment) => {
        if (segment.width === 0) {
          return null;
        }
        return (
          <div
            key={`segment--${segment.fill}`}
            className={cx('GaugeBar__segment', {
              'GaugeBar__segment--clickable': Boolean(segment.onClick),
              'GaugeBar__segment--bypass-hover': bypassDefaultHoverBehaviour,
            })}
            style={{
              background: `${segment.fill}`,
              // As `background` property is not rendered in generated DOM
              // `fill` has been added for test purpose
              fill: `${segment.fill}`,
              width: segment.width,
            }}
            onClick={segment.onClick}
            onMouseEnter={segment.onMouseEnter}
            onMouseLeave={segment.onMouseLeave}
            aria-hidden="true"
          >
            <Tooltip
              isDisabled={!segment.tooltipContent}
              content={segment.tooltipContent ?? ''}
              triggerAsChild
            >
              <div
                className="GaugeBar__segment__inner"
                style={{ width: segment.width }}
              />
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};
