import { useCallback } from 'react';

import { useGoTo } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type PayableType } from 'src/core/modules/bookkeep/types';

type Filters = {
  payableTypes?: PayableType;
};

export const useGoToPayableList = (filters: Filters = {}) => {
  const goTo = useGoTo();
  const companyId = useCompanyId();

  const urlSearchParams = new URLSearchParams(filters);

  return useCallback(
    () => goTo('PAYABLES_ALL', { company: companyId }, urlSearchParams),
    [companyId],
  );
};
