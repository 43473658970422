import { SkeletonText, SkeletonButton } from '@dev-spendesk/grapes';
import React from 'react';

import styles from './SchedulePaymentsBlockLoader.module.css';

export const SchedulePaymentsBlockLoader = () => {
  return (
    <div className={styles.SchedulePaymentsBlockLoader}>
      <SkeletonText size="xl" width="60%" className="mb-m" />
      <SkeletonText size="xxl" width="40%" className="mb-l" />
      <SkeletonText size="l" width="70%" className="mb-l" />
      <SkeletonButton width="100%" />
    </div>
  );
};
