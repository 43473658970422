import { useQueryClient } from 'react-query';

import { getFetcher } from 'src/core/api/fetcher';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { type RawSupplier, type SupplierDetails } from '../../../models';
import { transformSupplier } from '../useFetchSupplier/supplierTransformer';

export const useFetchSupplierByName = (supplierName: string) => {
  return useQuery({
    key: [
      'fetchSupplierByName',
      { search: supplierName, includeArchived: true },
    ],
    isEnabled: supplierName.length > 0,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/suppliers?search=${supplierName}&includeArchived=true`,
    },
    reshapeData(rawData: RawSupplier[]) {
      const rawSupplier = rawData.find(
        ({ name }) => name.toLowerCase() === supplierName.toLowerCase(),
      );

      if (!rawSupplier) {
        return undefined;
      }
      return transformSupplier(rawSupplier);
    },
  });
};

export const useFetchSupplierByNameLazy = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const supplierFetcher = (supplierName: string) =>
    getFetcher<RawSupplier[]>({
      companyId,
      getRequest: () => ({
        type: 'rest',
        target: 'companyAPI',
        endpoint: `/suppliers?search=${supplierName}&includeArchived=true`,
        method: 'get',
      }),
    });
  return async (supplierName: string): Promise<SupplierDetails | undefined> => {
    if (supplierName.length === 0) {
      return undefined;
    }

    const rawData = await queryClient.fetchQuery(
      ['suppliers', { name: supplierName, includeArchived: true }],
      supplierFetcher(supplierName),
    );

    const rawSupplier = rawData.find(
      ({ name }) => name.toLowerCase() === supplierName.toLowerCase(),
    );

    if (!rawSupplier) {
      return undefined;
    }
    return transformSupplier(rawSupplier);
  };
};
