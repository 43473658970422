import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { isAccountOwner } from 'src/core/utils/entities/userTyped';

export type Member = {
  id: string;
  email: string;
  avatar: string | null;
  fullname: string;
  isPending: boolean;
  isAccountOwner: boolean;
};

type Response = {
  id: string;
  email: string;
  avatar: string | null;
  fullname: string;
  pending: boolean;
  data_by_company?: {
    [key: string]: {
      is_account_owner?: boolean;
    };
  };
};

export const useMembersQuery = (): QueryState<Member[]> => {
  const companyId = useCompanyId();

  return useQuery<Member[]>({
    key: ['members'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/users?includePending=true',
    },
    reshapeData(data: Response[]) {
      return data.map((member) => {
        return {
          id: member.id,
          fullname: member.fullname,
          email: member.email,
          avatar: member.avatar,
          isPending: member.pending,
          isAccountOwner: isAccountOwner(member, { id: companyId }),
        };
      });
    },
  });
};
