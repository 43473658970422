import { type PayableProof } from '../../../../models';

export type PayableProofs = {
  id: string;
  proofs: PayableProof[];
  missingProof:
    | {
        reportMethod: 'manual' | 'automatic';
        reason: string;
        reporter: string | undefined;
      }
    | undefined;
  invalidProof: { reason: string | undefined } | undefined;
  lastMissingReceiptReminderSentAt?: string;
};

export const reshapePayableProofs = (
  data: PayableProofs | undefined,
): PayableProofs | undefined => {
  if (data === undefined) {
    return undefined;
  }

  return {
    ...data,
    proofs: data.proofs.map((proof) => ({
      ...proof,
      id: proof.url, // TODO: the API is not returning an id now
    })),
  };
};
