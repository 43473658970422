import { type MonetaryValue, toNumber } from 'ezmoney';

import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type ExpenseCategory } from 'modules/budgets/models/expenseCategory';

import {
  type BudgetBreakdown,
  getBudgetLimit,
  getSpentAmount,
  hasExceededAmounts,
} from './breakdown';

export type ExpenseCategoryBreakdown = BudgetBreakdown & {
  expenseCategory:
    | (ExpenseCategory & { isOther: false })
    | { id: 'other'; isOther: true };
};

export const getExpenseCategorySpentPercentage = (
  breakdown: ExpenseCategoryBreakdown,
): number => {
  if (toNumber(getBudgetLimit(breakdown)) === 0) {
    return 0;
  }
  return toPercentage(getSpentAmount(breakdown), getBudgetLimit(breakdown));
};

export const isExpenseCategoryNotInBudget = (
  breakdown: ExpenseCategoryBreakdown,
): boolean => {
  const { used, committed, available } = breakdown;
  return (
    [used, committed, available].every((amount) => toNumber(amount) === 0) &&
    hasExceededAmounts(breakdown)
  );
};

const sortFunction = (
  breakdowns: ExpenseCategoryBreakdown[],
  t: TGlobalFunctionTyped,
) => {
  return breakdowns.sort((a, b) => {
    if (
      getExpenseCategoryName(a.expenseCategory, t).toLowerCase() >
        getExpenseCategoryName(b.expenseCategory, t).toLowerCase() ||
      a.expenseCategory.isOther
    ) {
      return 1;
    }
    return -1;
  });
};

/**
 * Sort a list of expense category breakdowns.
 * Assigned breakdowns (i.e with a defined Expense Category) come first,
 * Then breakdowns are sorted according to the most spent amounts.
 * Unassigned breakdown appear in last position.
 * @param breakdowns List of expense category breakdowns.
 * @returns The sorted list of expense category breakdowns.
 */
export const sortExpenseCategoryBreakdownsByExpenseCategory = (
  breakdowns: ExpenseCategoryBreakdown[],
  t: TGlobalFunctionTyped,
) => {
  const breakdownsWithEC = breakdowns.filter(
    (breakdown) => !breakdown.expenseCategory.isOther,
  );

  const sorted = sortFunction(breakdownsWithEC, t);

  const breakdownWithoutEC = breakdowns.find(
    (breakdown) => breakdown.expenseCategory.isOther,
  );
  if (breakdownWithoutEC) {
    sorted.push(breakdownWithoutEC);
  }

  return sorted;
};

export const isArchivedExpenseCategory = (
  expenseCateogryId: string,
  expenseCategories: ExpenseCategory[],
): boolean =>
  expenseCategories.some(
    ({ id, isArchived }) => isArchived && id === expenseCateogryId,
  );

const toPercentage = (
  numerator: MonetaryValue,
  denominator: MonetaryValue,
): number => {
  return Math.round((toNumber(numerator) * 100) / toNumber(denominator));
};

export const getExpenseCategoryName = (
  expenseCategory: ExpenseCategoryBreakdown['expenseCategory'],
  translator: TGlobalFunctionTyped,
): string => {
  if (expenseCategory.isOther) {
    return translator('budget.overviewPage.unassigned');
  }
  if (expenseCategory.id === 'default') {
    return translator('budget.overviewPage.default');
  }
  return expenseCategory.name;
};
