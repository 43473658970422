import { Button, Modal } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { ApprovalPolicyDetailsContent } from 'modules/company/members/components/ApprovalPolicies/components/ApprovalPolicyDetailsContent';
import { ApprovalPolicyMembersContent } from 'modules/company/members/components/ApprovalPolicies/components/ApprovalPolicyMembersContent';
import { validateApprovalPolicyFormValues } from 'modules/company/members/components/ApprovalPolicies/formValues';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { type Member } from '../../models/member';
import { type Policy, type PolicyParams } from '../../models/policy';

export type ApprovalPolicyFormValues = {
  name: string | null;
  membersIds: string[];
} & PolicyParams;

type Props = {
  policy: Policy;
  policyMembers: Member[];
  members: Member[];
  onSuccess(values: ApprovalPolicyFormValues): void;
  onCancel(): void;
};

type Step = 'define' | 'members';

export const EditApprovalPolicyModal = ({
  policy,
  policyMembers,
  members,
  onSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const [step, setStep] = useState<Step>('define');

  const formikProps = useFormik<ApprovalPolicyFormValues>({
    initialValues: {
      name: policy.name || '',
      membersIds: policyMembers.map((member) => member.id),
      approvalNeeded: policy.params.approvalNeeded,
      spendingTypes: policy.params.spendingTypes,
      amountPerMonth: policy.params.amountPerMonth,
      amountPerTransaction: policy.params.amountPerTransaction,
    },
    validateOnChange: false,
    validate: (values) => validateApprovalPolicyFormValues(values, step, t),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const errors = await formikProps.validateForm();

      if (Object.keys(errors).length === 0) {
        track(AnalyticEventName.APPROVAL_POLICIES_UPDATE_POLICY_BUTTON_CLICKED);
        await onSuccess(values);
      }
      setSubmitting(false);
    },
  });

  return (
    <Modal
      isOpen
      iconName="badge"
      iconVariant="primary"
      title={t('approvalPolicy.modal.title')}
      actions={
        step === 'define' ? (
          <>
            <Button
              variant="secondary"
              text={t('approvalPolicy.modal.cancel')}
              onClick={onCancel}
            />
            <Button
              variant="primary"
              iconPosition="right"
              iconName="caret-right"
              text={t('approvalPolicy.modal.assign')}
              onClick={async () => {
                const errors = await formikProps.validateForm();
                if (Object.keys(errors).length === 0) {
                  setStep('members');
                }
              }}
            />
          </>
        ) : (
          <>
            <Button
              variant="secondary"
              iconPosition="left"
              iconName="caret-left"
              text={t('approvalPolicy.modal.back')}
              onClick={() => setStep('define')}
            />
            <Button
              variant="primary"
              text={t('approvalPolicy.modal.save')}
              onClick={formikProps.submitForm}
            />
          </>
        )
      }
    >
      {step === 'define' && (
        <ApprovalPolicyDetailsContent
          isPolicyDefault={policy.isDefault}
          {...formikProps}
        />
      )}
      {step === 'members' && (
        <ApprovalPolicyMembersContent
          isPolicyDefault={policy.isDefault}
          members={members}
          {...formikProps}
        />
      )}
    </Modal>
  );
};
