export type CostCenterReplacePreview = {
  pendingRequestsCount: number;
  approvedRequestsCount: number;
  readyOrExportedPaymentsCount: number;
  notReadyPaymentsCount: number;
  subscriptionsCount: number;
  usersCount: number;
  purchaseOrdersCount: number;
};

// check if a cost center requires a replacement for deletion (cannot be deleted
// straight away)
export const isCostCenterReplacementRequired = (
  replacePreview: CostCenterReplacePreview,
): boolean => {
  return (
    replacePreview.pendingRequestsCount !== 0 ||
    replacePreview.approvedRequestsCount !== 0 ||
    replacePreview.notReadyPaymentsCount !== 0 ||
    replacePreview.subscriptionsCount !== 0 ||
    replacePreview.purchaseOrdersCount !== 0 ||
    replacePreview.usersCount !== 0
  );
};
