import { Handle, Position, type NodeProps } from '@xyflow/react';

export type LabelNodeData = {
  nodeType: 'labelNode';
  text: string;
  onAddNodeClick: () => void;
};

type Props = NodeProps & {
  data: LabelNodeData;
};

const handleStyle = {
  background: 'transparent',
  border: 'none',
  width: 0,
  height: 0,
};

export const LabelNode = ({ data }: Props) => {
  return (
    <div className="flex items-center">
      <div className="rounded bg-white px-xs py-xxs text-complementary title-m">
        {data.text}
      </div>
      {/* 
        TODO: Uncomment when adding the ability to add nodes
        <IconButton
          variant="borderless"
          onClick={data.onAddNodeClick}
          iconName="plus-circle"
          aria-label="plus"
        /> */}
      <Handle
        style={handleStyle}
        type="source"
        id="rightHandle"
        position={Position.Right}
      />
      <Handle
        style={handleStyle}
        type="source"
        id="bottomHandle"
        position={Position.Bottom}
      />
    </div>
  );
};
