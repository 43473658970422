import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  notif_code: string;
  checked: boolean;
  value: string | null;
};

export const useUpdateNotificationMutation = (): MutationState<Payload> => {
  const companyId = useCompanyId();

  return useMutation<Payload>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/notifications',
    },
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['notifications', companyId]);
      },
    },
  });
};
