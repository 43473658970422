import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type BudgetaryExerciseInput } from '../budgetaryExerciseInput';

export const useUpdateBudgetaryExerciseMutation = (
  budgetaryExerciseId: string,
): MutationState<BudgetaryExerciseInput> => {
  return useMutation<BudgetaryExerciseInput>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/budgets/budgetary-exercises/${budgetaryExerciseId}`,
      method: 'patch',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['budgetaryExercises']);
      },
    },
  });
};
