import { SEPACountries } from 'src/core/config/country';
import { type CurrenciesKey } from 'src/core/config/money';
import {
  BankFields,
  getBankInfoFieldsCombo,
} from 'src/core/utils/bankInfoFormats';

import { type BankInfoOcrResult } from '../supplier';

export const getIsBankInfoRecommended = ({
  companyCurrency,
  bankCountry,
}: {
  companyCurrency: string;
  bankCountry: string | null;
}): boolean => {
  // supplier bank info are recommended for SEPA supplier bank country and
  // if the company wallet currency is in euro, otherwise XML SEPA won't be
  // available
  return (
    companyCurrency === 'EUR' &&
    !!bankCountry &&
    SEPACountries.includes(bankCountry)
  );
};

export const getShouldUseSuggestion = ({
  companyCountry,
  bankCountry,
  companyCurrency,
}: {
  companyCountry: string;
  bankCountry: string | null;
  companyCurrency: CurrenciesKey;
}) => {
  const bankInfoFields = getBankInfoFieldsCombo(
    companyCountry,
    bankCountry,
    companyCurrency,
  );
  return bankInfoFields.includes(BankFields.Iban);
};

export const getCountryValue = ({
  initialCountryValue,
  countrySuggestion,
}: {
  initialCountryValue: string | null;
  countrySuggestion: string | null;
}) =>
  countrySuggestion && !initialCountryValue
    ? countrySuggestion
    : initialCountryValue;

const shouldUseIbanSuggestion = ({
  initialIbanValue,
  ibanSuggestion,
}: {
  initialIbanValue: string | null;
  ibanSuggestion: BankInfoOcrResult | null;
}) => {
  return (
    ibanSuggestion &&
    ibanSuggestion.text &&
    (!initialIbanValue || initialIbanValue === ibanSuggestion.text)
  );
};

export const getIbanSuggestion = (values: {
  initialIbanValue: string | null;
  ibanSuggestion: BankInfoOcrResult | null;
}) =>
  shouldUseIbanSuggestion(values) && values.ibanSuggestion
    ? values.ibanSuggestion
    : null;

const shouldUseBicSuggestion = ({
  initialBicValue,
  bicSuggestion,
}: {
  initialBicValue: string | null;
  bicSuggestion: BankInfoOcrResult | null;
}) => {
  return (
    bicSuggestion &&
    bicSuggestion.text &&
    (!initialBicValue || initialBicValue === bicSuggestion.text)
  );
};

export const getBicSuggestion = (values: {
  initialBicValue: string | null;
  bicSuggestion: BankInfoOcrResult | null;
}) =>
  shouldUseBicSuggestion(values) && values.bicSuggestion
    ? values.bicSuggestion
    : null;
