import { type FormikErrors } from 'formik';

import { type CreateSupplierFormValues } from 'modules/bookkeep/accounts-payable/types';
import { type CurrenciesKey } from 'src/core/config/money';

import { validateBankDetailsForm } from '../../../components/AccountPayableSuppliersBankFields';

export const validateForm = (
  company: { currency: CurrenciesKey; country: string },
  values: CreateSupplierFormValues,
  supplierNames: Set<string>,
): FormikErrors<CreateSupplierFormValues> => {
  const errors: FormikErrors<CreateSupplierFormValues> =
    validateBankDetailsForm(company, values);

  if (values.name.trim() === '') {
    errors.name = 'bookkeep.accountsPayable.createSupplier.nameError';
  }

  const existingSupplier = supplierNames.has(
    values.name.trim().toLocaleLowerCase(),
  );
  if (existingSupplier) {
    errors.name = 'bookkeep.accountsPayable.createSupplier.nameExistsError';
  }

  if (values.legalName.trim() === '') {
    errors.legalName = 'bookkeep.accountsPayable.createSupplier.legalNameError';
  }

  if (
    (values.iban || values.bicSwift || values.accountNumber) &&
    !values.accountHolderName
  ) {
    errors.accountHolderName =
      'bookkeep.accountsPayable.createSupplier.accountHolderNameError';
  }

  return errors;
};
