import { z } from 'zod';

import { integrationSchema } from './integration';

export const integrationStatusSchema = z.object({
  active: z.array(integrationSchema),
  available: z.array(integrationSchema),
  unavailable: z.array(integrationSchema),
});

export type IntegrationStatus = z.infer<typeof integrationStatusSchema>;
