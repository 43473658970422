import {
  FETCH_SUPPLIERS_LOADING,
  FETCH_SUPPLIERS_SUCCESS,
  FETCH_SUPPLIERS_FAILURE,
  CREATE_SUPPLIER_LOADING,
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_FAILURE,
  UPDATE_SUPPLIER_LOADING,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAILURE,
} from './actionTypes';
import { type Supplier } from './supplier';

export type FetchSuppliersLoading = Readonly<{
  type: typeof FETCH_SUPPLIERS_LOADING;
}>;

export type FetchSuppliersSuccess = Readonly<{
  type: typeof FETCH_SUPPLIERS_SUCCESS;
  payload: {
    suppliers: Supplier[];
    hasSelectedFilters: boolean;
  };
}>;

export type FetchSuppliersFailure = Readonly<{
  type: typeof FETCH_SUPPLIERS_FAILURE;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
  };
}>;

export type CreateSupplierLoading = Readonly<{
  type: typeof CREATE_SUPPLIER_LOADING;
}>;

export type CreateSupplierSuccess = Readonly<{
  type: typeof CREATE_SUPPLIER_SUCCESS;
  payload: {
    newSupplier: Supplier;
  };
}>;

export type CreateSupplierFailure = Readonly<{
  type: typeof CREATE_SUPPLIER_FAILURE;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
  };
}>;

export type UpdateSupplierLoading = Readonly<{
  type: typeof UPDATE_SUPPLIER_LOADING;
}>;

export type UpdateSupplierSuccess = Readonly<{
  type: typeof UPDATE_SUPPLIER_SUCCESS;
  payload: {
    updatedSupplier: Supplier;
  };
}>;

export type UpdateSupplierFailure = Readonly<{
  type: typeof UPDATE_SUPPLIER_FAILURE;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
  };
}>;

export type SuppliersAction =
  | FetchSuppliersLoading
  | FetchSuppliersSuccess
  | FetchSuppliersFailure
  | CreateSupplierLoading
  | CreateSupplierSuccess
  | CreateSupplierFailure
  | UpdateSupplierLoading
  | UpdateSupplierSuccess
  | UpdateSupplierFailure;

export const fetchSuppliersLoading = (): FetchSuppliersLoading => ({
  type: FETCH_SUPPLIERS_LOADING,
});

export const fetchSuppliersSuccess = (
  suppliers: Supplier[],
  hasSelectedFilters: boolean,
): FetchSuppliersSuccess => ({
  type: FETCH_SUPPLIERS_SUCCESS,
  payload: { suppliers, hasSelectedFilters },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchSuppliersFailure = (error: any): FetchSuppliersFailure => ({
  type: FETCH_SUPPLIERS_FAILURE,
  payload: { error },
});

export const createSupplierLoading = (): CreateSupplierLoading => ({
  type: CREATE_SUPPLIER_LOADING,
});

export const createSupplierSuccess = (
  newSupplier: Supplier,
): CreateSupplierSuccess => ({
  type: CREATE_SUPPLIER_SUCCESS,
  payload: { newSupplier },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSupplierFailure = (error: any): CreateSupplierFailure => ({
  type: CREATE_SUPPLIER_FAILURE,
  payload: { error },
});

export const updateSupplierLoading = (): UpdateSupplierLoading => ({
  type: UPDATE_SUPPLIER_LOADING,
});

export const updateSupplierSuccess = (
  updatedSupplier: Supplier,
): UpdateSupplierSuccess => ({
  type: UPDATE_SUPPLIER_SUCCESS,
  payload: { updatedSupplier },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateSupplierFailure = (error: any): UpdateSupplierFailure => ({
  type: UPDATE_SUPPLIER_FAILURE,
  payload: { error },
});
