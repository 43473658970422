import { Navigation } from '@dev-spendesk/grapes';
import React from 'react';
import { useParams } from 'react-router-dom';

import { usePayablesBucketsStatsQuery } from 'modules/bookkeep/apis/prepare-payable/usePayablesBucketsStatsQuery';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { NavigationLink } from 'src/core/common/components/NavigationLink';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';
import { useExportPayablesCount } from 'src/core/modules/payable-export/apis';
import { getTotalPayablesToBeExported } from 'src/core/modules/payable-export/components/utils/payablesCounts';
import { usePayableExportFilters } from 'src/core/modules/payable-export/hooks';

export const BookkeepPayablesNav = () => {
  const { t } = useTranslation('global');
  const { company } = useParams();
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  const bucketsStatsQueryResult = usePayablesBucketsStatsQuery(true);
  const countPayablesToPrepare = getPayablesToBookkeepCountFromBucketsStats(
    bucketsStatsQueryResult,
  );

  const payablesToBeExported = useCountPayablesToBeExported();

  const leftNavigationItems = [
    {
      key: 'nav_prepare',
      text: t('expenseInbox.navbar.prepare'),
      path: routeFor(routes.EXPENSE_INBOX_PREPARE.path, { company }),
      count: countPayablesToPrepare,
    },
    {
      key: 'nav_export',
      text: t('expenseInbox.navbar.export'),
      path: routeFor(routes.EXPENSE_INBOX_EXPORT.path, { company }),
      count: payablesToBeExported,
    },
  ];

  const rightNavigationItems = [
    <NavigationLink
      key="nav_all-payables"
      text={t('payables.allPayablesTitle')}
      path={routeFor(routes.PAYABLES_ALL.path, { company })}
    />,
    <NavigationLink
      key="nav_all-payments"
      text={t('payments.allPaymentsTitle')}
      path={routeFor(routes.PAYMENTS_ALL.path, { company })}
    />,
  ];

  if (hasNewNavigation) {
    return (
      <HeaderWithNavigation links={leftNavigationItems}>
        {t('misc.bookkeepTitle')}
      </HeaderWithNavigation>
    );
  }

  return (
    <Navigation
      variant="subNavigation"
      aria-label="Sub navigation"
      leftNavigationItems={leftNavigationItems.map((link) => (
        <NavigationLink {...link} key={link.key} />
      ))}
      rightNavigationItems={rightNavigationItems}
    />
  );
};

function getPayablesToBookkeepCountFromBucketsStats(
  statsQuery: ReturnType<typeof usePayablesBucketsStatsQuery>,
): number | undefined {
  if (statsQuery.status !== 'success') {
    return undefined;
  }
  if (statsQuery.data.outcome !== 'ok') {
    return 0;
  }
  return statsQuery.data.bucketsStats.expensesFiltered.totalCount;
}

const useCountPayablesToBeExported = () => {
  const payableExportFilters = usePayableExportFilters();

  const exportPayablesCountQuery = useExportPayablesCount(
    payableExportFilters.period,
  );

  const exportPayablesCountResult = unwrapQuery(exportPayablesCountQuery);

  return exportPayablesCountResult
    ? getTotalPayablesToBeExported(
        payableExportFilters,
        exportPayablesCountResult.payablesCounts,
      )
    : undefined;
};
