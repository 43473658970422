import { type AppState } from "src/core/reducers";

import { type TermsAndConditions } from './types';
import { type AccountLoad, type WalletInfos } from '../wallet';

export const getAccountLoads = (state: AppState): AccountLoad[] => {
  return state.wallet.loads;
};

export const getIsLoadingAccountLoads = (state: AppState): boolean => {
  return state.wallet.behavior.isAccountLoadsLoading;
};

export const getIsLoadingWallet = (state: AppState): boolean => {
  return (
    state.wallet.behavior.isWalletInfosLoading ||
    state.wallet.behavior.isAccountLoadsLoading
  );
};

export const getWalletInfos = (state: AppState): WalletInfos => {
  return state.wallet.walletInfos;
};

export const getLastTermsAndConditions = (
  state: AppState,
): TermsAndConditions | null => {
  return state.controllerHomepage.lastTermsAndConditions;
};
