import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { vendorSupplierMapQueriesToInvalidate } from './useAssignVendorToSupplierMutation';

type Payload = {
  supplierId: string;
};

export const useDeleteVendorSupplierMapMutation =
  (): MutationState<Payload> => {
    return useMutation<Payload>({
      request: {
        type: 'rest',
        target: 'companyAPI',
        method: 'delete',
        endpoint: '/accounting-integration/supplier-sync/vendor-supplier-map',
      },
      options: {
        onSuccess({ client }): void {
          vendorSupplierMapQueriesToInvalidate.forEach((q) =>
            client.invalidateQueries(q),
          );
        },
      },
    });
  };
