import { Route } from 'react-router';

import { routes } from 'src/core/constants/routes';
import RequestsContainer from 'src/core/modules/requests/list/components/RequestsContainer';

import { RequestHeader } from './RequestHeader';

export const RequestPage = () => {
  return (
    <div className="page__container">
      <RequestHeader />
      <Route path={routes.REQUESTS.path}>
        <RequestsContainer />
      </Route>
    </div>
  );
};
