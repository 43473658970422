import { useTranslation } from 'common/hooks/useTranslation';

export type Props = {
  value: string | null;
  strike?: boolean;
};

export const SupplierChangeFieldValue = ({ value, strike = false }: Props) => {
  const { t } = useTranslation('global');

  const fieldValue =
    value && value !== ''
      ? value
      : t('suppliers.latestUpdater.historyModal.emptyValue');

  if (strike) {
    return <s>{fieldValue}</s>;
  }

  return fieldValue;
};
