export type SupplierAccountDefaultFor = 'cardSupplier' | 'invoiceSupplier';

export type DefaultSupplierAccount = {
  id?: string;
  generalAccountCode: string;
  auxiliaryAccountCode?: string;
  name: string;
  isArchived?: boolean;
  defaultFor: SupplierAccountDefaultFor;

  // the `code` should not be here, and not be used, but currently the REST API is still returning it
  // TODO: delete this after deleting it from the REST API
  code?: string;
};

export const getDefaultSupplierAccountName = (
  accountingCountry: string,
): string => {
  switch (accountingCountry) {
    case 'FR':
      return 'Divers';
    case 'DE':
      return 'Sammelkonto (Kreditoren)';
    default:
      return 'Miscellaneous';
  }
};
