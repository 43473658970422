import { useAccountingIntegrationStatusQuery } from 'modules/accounting-integration/apis';
import {
  type IntegrationStatusWithIntegration,
  isIntegrationStatusWithIntegration,
} from 'modules/bookkeep/integration/status';

export function useHasAccountingIntegrationCapability<
  K extends keyof IntegrationStatusWithIntegration['capabilities'],
>(
  capability: K,
  capabilityValue?:
    | IntegrationStatusWithIntegration['capabilities'][K]
    | IntegrationStatusWithIntegration['capabilities'][K][],
): boolean {
  const integrationStatusQuery = useAccountingIntegrationStatusQuery();

  if (
    integrationStatusQuery.status !== 'success' ||
    !isIntegrationStatusWithIntegration(integrationStatusQuery.data)
  ) {
    return false;
  }

  const { capabilities } = integrationStatusQuery.data;

  const hasCapabiltity = Boolean(capabilities[capability]);

  if (hasCapabiltity && capabilityValue) {
    if (Array.isArray(capabilityValue)) {
      return capabilityValue.includes(capabilities[capability]);
    }
    return capabilityValue === capabilities[capability];
  }

  return hasCapabiltity;
}
