import { Button, EmptyState } from '@dev-spendesk/grapes';
import React from 'react';

import { getComponentByIllustrationName } from 'common/components/Illustration/Illustration';
import { PendingKycEmptyState } from 'modules/kyc/components/PendingKycEmptyState';
import { InvoiceDraftNoResultPromoInbox } from 'modules/requests/components/InvoiceDraftNoResultPromoInbox/InvoiceDraftNoResultPromoInbox';
import { PendingKycRequestsEmptyState } from 'modules/requests/components/PendingKycRequestsEmptyState';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import history from 'src/core/history';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import {
  type Mode,
  modeToIllustration,
  modeToSubtitle,
  modeToTitle,
} from './mode';
import { type TGlobalFunctionTyped } from '../../hooks/useTranslation';

type Props = {
  mode: Mode;
  isUserRequester: boolean;
  isUserOnlyRequester: boolean;
  companyId: string;
  hasFiltersApplied?: boolean;
  impersonator?: { id: string } | null;
  hasFeature(code: FEATURES): boolean;
  t: TGlobalFunctionTyped;
  shouldCompleteKyc?: boolean;
};

const modeToAction = (
  mode: Mode,
  companyId: string,
  translator: TGlobalFunctionTyped,
) => {
  switch (mode) {
    case 'request':
    case 'mineRequest': {
      return (
        <Button
          variant="primary"
          text={translator('pageEmptyState.requests.button')}
          onClick={() => {
            track(AnalyticEventName.REQUEST_NEW_BUTTON_CLICKED, {
              from: 'request_empty_list',
            });
            history.push(
              routeFor(routes.REQUESTS.path, {
                company: companyId,
                type: 'all',
                id: 'new',
              }),
            );
          }}
        />
      );
    }
    case 'draftRequest':
      return (
        <Button
          variant="primary"
          text={translator('pageEmptyState.requests.button')}
          onClick={() => {
            track(AnalyticEventName.REQUEST_NEW_BUTTON_CLICKED, {
              from: 'request_empty_list',
            });
            history.push(
              routeFor(routes.REQUESTS.path, {
                company: companyId,
                type: 'all',
                id: 'new',
              }),
            );
          }}
        />
      );
    default:
      return undefined;
  }
};

export function PageNoResult({
  mode,
  isUserRequester,
  isUserOnlyRequester,
  companyId,
  hasFiltersApplied,
  impersonator,
  hasFeature,
  t,
  shouldCompleteKyc,
}: Props) {
  if (mode === 'draftRequest' && hasFeature(FEATURES.BILL_INBOX)) {
    return <InvoiceDraftNoResultPromoInbox />;
  }

  if (isUserOnlyRequester && mode === 'request' && shouldCompleteKyc) {
    return <PendingKycRequestsEmptyState />;
  }

  if (mode === 'payment' && shouldCompleteKyc) {
    return <PendingKycEmptyState />;
  }

  const actionContent =
    isUserRequester && !impersonator
      ? modeToAction(mode, companyId, t)
      : undefined;

  return (
    <EmptyState
      title={modeToTitle(mode, hasFiltersApplied, t)}
      subtitle={modeToSubtitle(mode, isUserOnlyRequester, t)}
      illustration={getComponentByIllustrationName(modeToIllustration(mode))}
      actions={actionContent}
    />
  );
}
