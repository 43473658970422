import React, { useState } from 'react';

import { QuerySuspense } from "src/core/common/components/QuerySuspense";
import { useTranslation } from "src/core/common/hooks/useTranslation";

import { BreakdownSpendingsPageDrawer } from '../../components/BreakdownSpendingsPageDrawer';
import {
  BudgetExpenseCategoriesBreakdownList,
  BudgetExpenseCategoriesBreakdownListLoader,
  BudgetExpenseCategoriesBreakdownListFallback,
} from '../../components/BudgetExpenseCategoriesBreakdownList';
import { useExpenseCategoriesBreakdownQuery } from '../../hooks/useExpenseCategoriesBreakdownQuery';
import {
  type BreakdownPart,
  getCommittedAmount,
  getUsedAmount,
} from '../../models/breakdown';
import { type ExpenseCategoryBreakdown } from '../../models/expenseCategoryBreakdown';
import type { PeriodRangePayload } from '../../models/period';

type Props = {
  budgetId: string;
  periodRange: PeriodRangePayload;
};

export const BudgetExpenseCategoriesBreakdownContainer = ({
  budgetId,
  periodRange,
}: Props) => {
  const { t } = useTranslation('global');

  const [selectedBreakdownSegment, setSelectedBreakdownSegment] = useState<{
    breakdownPart: BreakdownPart;
    expenseCategoryBreakdown: ExpenseCategoryBreakdown;
  }>();
  const expenseCategoriesBreakdownQueryState =
    useExpenseCategoriesBreakdownQuery(budgetId, periodRange);

  return (
    <QuerySuspense
      queryState={expenseCategoriesBreakdownQueryState}
      loading={<BudgetExpenseCategoriesBreakdownListLoader />}
      fallback={() => <BudgetExpenseCategoriesBreakdownListFallback />}
    >
      {(data) => (
        <>
          <BudgetExpenseCategoriesBreakdownList
            expenseCategoryBreakdown={data.expenseCategoriesBreakdown}
            expenseCategories={data.expenseCategories}
            onGaugeSegmentClick={(breakdownPart, expenseCategoryBreakdown) => {
              setSelectedBreakdownSegment({
                breakdownPart,
                expenseCategoryBreakdown,
              });
            }}
          />
          {selectedBreakdownSegment && (
            <BreakdownSpendingsPageDrawer
              isOpen={Boolean(selectedBreakdownSegment)}
              budgetId={budgetId}
              periodRange={periodRange}
              title={
                selectedBreakdownSegment.expenseCategoryBreakdown
                  .expenseCategory.isOther
                  ? t('budget.breakdownSpendings.unassignedExpenseCategory')
                  : selectedBreakdownSegment.expenseCategoryBreakdown
                      .expenseCategory.name
              }
              budgetTotalAmount={
                selectedBreakdownSegment.breakdownPart === 'committed'
                  ? getCommittedAmount(
                      selectedBreakdownSegment.expenseCategoryBreakdown,
                    )
                  : getUsedAmount(
                      selectedBreakdownSegment.expenseCategoryBreakdown,
                    )
              }
              breakdownPart={selectedBreakdownSegment.breakdownPart}
              expenseCategory={
                selectedBreakdownSegment.expenseCategoryBreakdown
                  .expenseCategory
              }
              breakdownComputedAt={data.breakdown.breakdownComputedAt}
              onCloseDrawer={() => setSelectedBreakdownSegment(undefined)}
            />
          )}
        </>
      )}
    </QuerySuspense>
  );
};
