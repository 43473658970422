import {
  type QueryKey,
  type InfiniteData,
  useInfiniteQuery as useReactInfiniteQuery,
  useQueryClient,
} from 'react-query';
import { useParams } from 'react-router-dom';

import { type QueryClient } from '../client';
import { getFetcher } from '../fetcher';
import { type QueryRequest } from '../query';
import { type QueryError } from '../queryError';
import { type InfiniteQueryState } from '../queryState';

export function useInfiniteQuery<
  TResult extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRawResult extends object = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
>({
  key,
  isEnabled = true,
  getRequest,
  getNextPageParam: getNextPageParameter = (): undefined => undefined,
  reshapeData,
  options,
}: {
  key: QueryKey;
  isEnabled?: boolean;
  getRequest(pageParameter: string | number | undefined): QueryRequest;
  getNextPageParam?(
    lastPage: TRawResult,
    allPages: TRawResult[],
  ): string | number | undefined;
  reshapeData(data: TRawResult): TResult[];
  options?: {
    onSuccess?(successBag: {
      data: TResult[];
      rawData: InfiniteData<TRawResult>;
      client: QueryClient;
      rawResult: TRawResult;
    }): void;
    onError?(errorBag: {
      error: QueryError<TError>;
      client: QueryClient;
    }): void;
    /** In milliseconds */
    cacheTime?: number;
    /** In milliseconds – default: 0 */
    staleTime?: number;
  };
}): InfiniteQueryState<TResult[]> {
  const queryClient = useQueryClient();
  const { company: companyId }: { company: string } = useParams();
  const fetcher = ({ pageParam }: { pageParam?: string }) => {
    return getFetcher<TRawResult>({
      companyId,
      getRequest: () => ({
        ...getRequest(pageParam),
        method: 'get',
      }),
    })();
  };
  const queryState = useReactInfiniteQuery<
    TRawResult,
    QueryError<TError>,
    TRawResult
  >(key, fetcher, {
    ...options,
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageParameter(lastPage, allPages) ?? undefined,
    onSuccess: (data) =>
      options?.onSuccess?.({
        data: data.pages.flatMap(reshapeData),
        rawData: data,
        client: queryClient,
        rawResult: data.pages?.[0],
      }),
    onError: (error) =>
      options?.onError?.({
        error,
        client: queryClient,
      }),
  });
  const {
    status,
    data,
    error,
    isFetchingNextPage,
    hasNextPage = false, // FIXME: why this could be undefined?
    fetchNextPage,
    refetch,
  } = queryState;

  const infiniteQueryBag = {
    isFetchingNextPage: isFetchingNextPage || (false as const),
    hasNextPage,
    fetchNextPage: async (): Promise<void> => {
      await fetchNextPage();
    },
    refetch: async () => {
      const result = await refetch();
      if (result.data) {
        return result.data.pages.flatMap(reshapeData);
      }
    },
  };

  if (status === 'loading' || !isEnabled) {
    return {
      status: 'loading',
      ...infiniteQueryBag,
    };
  }
  if (status === 'error') {
    return {
      status: 'error',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      error: error!,
      ...infiniteQueryBag,
    };
  }
  return {
    status: 'success',
    data: data ? data.pages.flatMap(reshapeData) : [],
    ...infiniteQueryBag,
  };
}
