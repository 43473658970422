import React from 'react';

import { NavigationLink } from 'common/components/NavigationLink';
import { type TGlobalFunctionTyped as TFunctionTyped } from 'common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

export type AccountingStats = {
  totalToReview: number;
};

export const getLeftLinks = ({
  accountingStats,
  company,
  translator,
}: {
  accountingStats: AccountingStats | null;
  company: { id: string };
  translator: TFunctionTyped;
}) => {
  const companyFilter = { company: company.id };

  return [
    <NavigationLink
      key="nav_payments_to_prepare"
      text={translator('expenseInbox.navbar.prepare')}
      path={routeFor(routes.EXPENSE_INBOX_PREPARE.path, companyFilter)}
      count={accountingStats?.totalToReview || 0}
    />,
    <NavigationLink
      key="nav_payments_to_export"
      text={translator('expenseInbox.navbar.export')}
      path={routeFor(routes.EXPENSE_INBOX_EXPORT.path, companyFilter)}
    />,
  ];
};

export const getRightLinks = ({
  company,
  translator,
}: {
  company: { id: string };
  translator: TFunctionTyped;
}) => {
  const companyFilter = { company: company.id };

  return [
    <NavigationLink
      key="nav_all-payables"
      text={translator('payables.allPayablesTitle')}
      path={routeFor(routes.PAYABLES_ALL.path, companyFilter)}
    />,
    <NavigationLink
      key="all"
      text={translator('payments.allPaymentsTitle')}
      path={routeFor(routes.PAYMENTS_ALL.path, companyFilter)}
    />,
  ];
};
