import { Callout, FormField, Input, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';
import { Trans } from 'react-i18next';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { DayDropdown } from '../DayDropdown/DayDropdown';
import { Pbr2RuleDescription } from '../Pbr2RuleDescription';
import { PbrRuleTimeline } from '../PbrRuleTimeline';

import './CreateControlRule.css';

type FormValues = {
  name: string;
  limit: number;
  delay: number;
  isDefault: boolean;
  memberIds: string[];
};

export const CreateControlRule = ({
  // Formik props
  values,
  errors,
  setFieldValue,
  handleSubmit,
}: FormikProps<FormValues>) => {
  const { t } = useTranslation('global');
  const hasPlayByTheRulesReceiptAndFieldsFeature = useFeature(
    FEATURES.PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS,
  );

  return (
    <form className="CreateControlRule" onSubmit={handleSubmit}>
      <div className="CreateControlRule__content">
        <div className="CreateControlRule__content__form">
          <div className="CreateControlRule__content__form__row">
            <FormField
              label={t('controlRulesModal.nameLabel')}
              alertMessage={errors.name}
            >
              <TextInput
                value={values.name}
                placeholder={t('controlRulesModal.namePlaceholder')}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />
            </FormField>
          </div>
          <div className="CreateControlRule__content__form__row CreateControlRule__content__form__row--double">
            <FormField
              label={
                hasPlayByTheRulesReceiptAndFieldsFeature
                  ? t('controlRules.mandatoryFields.create.maxLate')
                  : t('controlRulesModal.limitLabel')
              }
              alertMessage={errors.limit}
            >
              <Input
                type="number"
                min={1}
                value={values.limit}
                onChange={(event) => {
                  const value = event.target.valueAsNumber;
                  if (!Number.isNaN(value)) {
                    setFieldValue('limit', value);
                  } else {
                    setFieldValue('limit', undefined);
                  }
                }}
              />
            </FormField>
            <FormField
              className="CreateControlRule__content__form__row__day-dropdown"
              label={
                hasPlayByTheRulesReceiptAndFieldsFeature
                  ? t('controlRules.mandatoryFields.create.delay')
                  : t('controlRulesModal.delayLabel')
              }
            >
              <DayDropdown
                value={values.delay}
                onSelect={(days) => setFieldValue('delay', days)}
              />
            </FormField>
          </div>
        </div>
        {hasPlayByTheRulesReceiptAndFieldsFeature && (
          <Callout
            className="mt-s"
            title={t('controlRules.mandatoryFields.create.callout.title')}
          >
            {t('controlRules.mandatoryFields.create.callout.description')}
          </Callout>
        )}
      </div>
      <div className="CreateControlRule__pbr-description">
        {hasPlayByTheRulesReceiptAndFieldsFeature ? (
          <Pbr2RuleDescription delay={values.delay} limit={values.limit} />
        ) : (
          <PbrRuleTimeline
            content={{
              mainTitle: t('pbrTimeline.admin.mainTitle'),
              title1: t('pbrTimeline.admin.sectionTitle1'),
              desc1: t('pbrTimeline.admin.sectionDesc1', {
                count: values.delay,
              }),
              title2: (
                <Trans
                  i18nKey="pbrTimeline.admin.sectionTitle2"
                  count={values.delay - 1}
                  values={{ count: values.delay - 1 }}
                  components={[
                    <span key="span" />,
                    <span
                      key="urgent"
                      className="PbrRuleTimeline__desc__row__text__urgent"
                    />,
                  ]}
                />
              ),
              desc2: t('pbrTimeline.admin.sectionDesc2'),
              title3: (
                <Trans
                  i18nKey="pbrTimeline.admin.sectionTitle3"
                  count={values.delay}
                  values={{ count: values.delay }}
                  components={[
                    <span key="span" />,
                    <span
                      key="overdue"
                      className="PbrRuleTimeline__desc__row__text__overdue"
                    />,
                  ]}
                />
              ),
              desc3: t('pbrTimeline.admin.sectionDesc3'),
              title4: (
                <Trans
                  i18nKey="pbrTimeline.admin.sectionTitle4new"
                  count={values.limit ?? 1}
                  values={{ count: values.limit ?? 1 }}
                  components={[
                    <span
                      key="overdue"
                      className="PbrRuleTimeline__desc__row__text__overdue"
                    />,
                  ]}
                />
              ),
              desc4: t('pbrTimeline.admin.sectionDesc4'),
              title5: t('pbrTimeline.admin.sectionTitle5'),
              desc5: t('pbrTimeline.admin.sectionDesc5'),
            }}
          />
        )}
      </div>
    </form>
  );
};
