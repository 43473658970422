import { useInvalidateCostCentersQuery } from 'modules/budgets/apis';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type ReplacePayload = {
  replacementId: string;
};

export const useReplaceCostCenterQuery = (
  costCenterId: string,
): MutationState<ReplacePayload> => {
  const invalidateCostCentersQuery = useInvalidateCostCentersQuery();

  return useMutation<ReplacePayload>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: `/cost-centers/${costCenterId}/replace`,
    },
    options: {
      throwOnError: true,
      onSuccess(): void {
        invalidateCostCentersQuery();
      },
    },
  });
};
