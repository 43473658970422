import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { CustomFieldEditValuesModal } from '../../components/CustomFieldEditValuesModal/CustomFieldEditValuesModal';
import { useUpdateCustomFieldValuesMutation } from '../../hooks/useUpdateCustomFieldValuesMutation';
import { type CustomField } from '../../models/customField';
import { convertValues } from '../../utils/convertValues';
import { removeDuplicates } from '../../utils/removeDuplicates';

type Props = {
  isOpen: boolean;
  customField: CustomField;
  onClose(): void;
};

export const CustomFieldEditValuesModalContainer = ({
  isOpen,
  customField,
  onClose,
}: Props) => {
  const [updateCustomFieldValues] = useUpdateCustomFieldValuesMutation(
    customField.id,
  );
  const { t } = useTranslation('global');
  const { successNotif, dangerNotif } = useNotifications();

  const handleSubmit = async (values: string) => {
    try {
      await updateCustomFieldValues({
        values: removeDuplicates(convertValues(values)),
      });
      successNotif(t('customFields.edition.successRequest'));
      onClose();
      track(AnalyticEventName.CUSTOM_FIELDS_FORM_POST_VALUES, {
        method: 'edition',
        numberOfCharacters: values.length,
        numberOfValues: values.split('\n').length,
      });
    } catch {
      dangerNotif(t('customFields.edition.errorRequest'));
    }
  };

  return (
    <CustomFieldEditValuesModal
      isOpen={isOpen}
      name={customField.name}
      onClose={onClose}
      customField={customField}
      onSubmit={handleSubmit}
    />
  );
};
