import { Button, CheckboxField, Modal } from '@dev-spendesk/grapes';
import * as Ezmoney from 'ezmoney';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

type Props = {
  paymentAmount: number;
  currency: string;
  isLoading: boolean;
  onCancel: () => void;
  onBack: () => void;
  onContinue: () => void;
};

export const AffidavitCertifyModal = ({
  paymentAmount,
  currency,
  isLoading,
  onCancel,
  onBack,
  onContinue,
}: Props) => {
  const { t } = useTranslation('global');

  const [isTotalChecked, setIsTotalChecked] = useState(false);
  const [isProviderAskedChecked, setIsProviderAskedChecked] = useState(false);
  const [isTruthfulChecked, setIsTruthfulChecked] = useState(false);

  const areAllCheckboxesChecked =
    isTotalChecked && isProviderAskedChecked && isTruthfulChecked;

  const monetaryValue = Ezmoney.fromNumber(paymentAmount, currency, 2);
  const formattedAmount = formatMonetaryValue(monetaryValue, {
    usePlusSignForNegative: true,
  });

  return (
    <Modal
      isOpen
      onClose={onCancel}
      iconName="receipt-question-mark"
      title={t('payments.affidavitModal.certifyModal.title')}
      actions={[
        <Button
          key="back"
          variant="secondary"
          text={t('payments.affidavitModal.certifyModal.cancelButton')}
          onClick={onBack}
        />,
        <Button
          key="continue"
          text={t('payments.affidavitModal.certifyModal.continueButton')}
          isDisabled={!areAllCheckboxesChecked || isLoading}
          isLoading={isLoading}
          onClick={() => {
            onContinue();
          }}
        />,
      ]}
    >
      <div className="mb-m px-m text-left">
        <CheckboxField
          className="mb-xs"
          id="certifyAmountTotal"
          fit="parent"
          isChecked={isTotalChecked}
          label={
            <>
              {t('payments.affidavitModal.certifyModal.total')}{' '}
              <b>{formattedAmount}</b>
            </>
          }
          onChange={() => setIsTotalChecked(!isTotalChecked)}
        />
        <CheckboxField
          className="mb-xs"
          id="certifyProviderAsked"
          fit="parent"
          isChecked={isProviderAskedChecked}
          label={t('payments.affidavitModal.certifyModal.providerAsked')}
          onChange={() => setIsProviderAskedChecked(!isProviderAskedChecked)}
        />
        <CheckboxField
          className="mb-xs"
          id="certifyTruthful"
          fit="parent"
          isChecked={isTruthfulChecked}
          label={t('payments.affidavitModal.certifyModal.truthful')}
          onChange={() => setIsTruthfulChecked(!isTruthfulChecked)}
        />
      </div>
    </Modal>
  );
};
