import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { type IntegrationStatus } from '../../../integration/status';

export function getCounterpartyMembersTitle(
  t: TGlobalFunctionTyped,
  integration?: IntegrationStatus['integration'],
) {
  switch (integration) {
    case 'Netsuite':
      return {
        synced: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.members.title',
        ),
        notSynced: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.members.notSyncedTitle',
        ),
      };
    case 'Sage100':
      return {
        synced: t(
          'bookkeep.integrations.settings.syncStatus.sage100.members.title',
        ),
        notSynced: t(
          'bookkeep.integrations.settings.syncStatus.sage100.members.notSyncedTitle',
        ),
      };
    default:
      return {
        synced: t(
          'bookkeep.integrations.settings.syncStatus.default.members.title',
        ),
        notSynced: t(
          'bookkeep.integrations.settings.syncStatus.default.members.notSyncedTitle',
        ),
      };
  }
}

export function getCounterpartyMembersCalloutTitle(
  t: TGlobalFunctionTyped,
  integration?: IntegrationStatus['integration'],
) {
  switch (integration) {
    case 'Netsuite':
      return t(
        'bookkeep.integrations.settings.syncStatus.netsuite.members.calloutTitle',
      );
    case 'Sage100':
      return t(
        'bookkeep.integrations.settings.syncStatus.sage100.members.calloutTitle',
      );
    default:
      return t(
        'bookkeep.integrations.settings.syncStatus.default.members.calloutTitle',
      );
  }
}

export function getCounterpartyMembersCalloutText(
  t: TGlobalFunctionTyped,
  integration?: IntegrationStatus['integration'],
) {
  switch (integration) {
    case 'Netsuite':
      return t(
        'bookkeep.integrations.settings.syncStatus.netsuite.members.calloutText',
      );
    case 'Sage100':
      return t(
        'bookkeep.integrations.settings.syncStatus.sage100.members.calloutText',
      );
    default:
      return t(
        'bookkeep.integrations.settings.syncStatus.default.members.calloutText',
      );
  }
}

export function getCounterpartySuppliersTitle(
  t: TGlobalFunctionTyped,
  integration?: IntegrationStatus['integration'],
) {
  switch (integration) {
    case 'Netsuite':
      return {
        synced: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.title',
        ),
        notSynced: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.notSyncedTitle',
        ),
      };
    case 'Sage100':
      return {
        synced: t(
          'bookkeep.integrations.settings.syncStatus.sage100.suppliers.title',
        ),
        notSynced: t(
          'bookkeep.integrations.settings.syncStatus.sage100.suppliers.notSyncedTitle',
        ),
      };
    default:
      return {
        synced: t(
          'bookkeep.integrations.settings.syncStatus.default.suppliers.title',
        ),
        notSynced: t(
          'bookkeep.integrations.settings.syncStatus.default.suppliers.notSyncedTitle',
        ),
      };
  }
}

export function getCounterpartySuppliersTooltipText(
  t: TGlobalFunctionTyped,
  integration?: IntegrationStatus['integration'],
) {
  switch (integration) {
    case 'Netsuite':
      return t(
        'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.tooltipText',
      );
    case 'Sage100':
      return t(
        'bookkeep.integrations.settings.syncStatus.sage100.suppliers.tooltipText',
      );
    default:
      return t(
        'bookkeep.integrations.settings.syncStatus.default.suppliers.tooltipText',
      );
  }
}
