import { PayableApiHooks } from '@finance-review/apis/payable';
import type {
  CreditNoteId,
  CreditNote,
} from '@finance-review/models/credit-note';
import { type I18nKey } from 'common/hooks/useTranslation';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import {
  transformRawCreditNote,
  transformCreditNoteToUpdateApiPayload,
} from './transformers';
import type {
  RawCreditNote,
  UpdateCreditNoteRequestApiPayload,
} from './transformers';
import { useUpdateCreditNoteCacheEntry } from './useCreditNoteListQuery';
import { updateCreditNoteRequestQueryData } from './useCreditNoteRequestQuery';

export type UpdateCreditNoteRequestError = {
  reason:
    | 'cannotUpdateAlreadyValidatedCreditNote'
    | 'requestNotFound'
    | 'userNotAuthorizedToUpdateThisRequest'
    | 'requestIsNotEditable';
  error?: string;
};

type RawResult = { outcome: 'updated'; creditNote: RawCreditNote };

type Result = { outcome: 'updated'; creditNote: CreditNote };

const reshapeData = (data: RawResult): Result => ({
  outcome: data.outcome,
  creditNote: transformRawCreditNote(data.creditNote),
});

export const useUpdateCreditNoteRequest = (
  creditNoteId: CreditNoteId,
): MutationState<CreditNote, Result, UpdateCreditNoteRequestError> => {
  const onCreditNoteUpdate = useUpdateCreditNoteCacheEntry();
  const onPayableUpdate = PayableApiHooks.useOnUpdate();
  const [updateCreditNoteRequestMutation, ...rest] = useMutation<
    UpdateCreditNoteRequestApiPayload,
    Result,
    RawResult,
    UpdateCreditNoteRequestError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/credit_note_requests/${creditNoteId}`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client, rawData: { creditNote } }): void => {
        updateCreditNoteRequestQueryData(client, creditNote);
        onCreditNoteUpdate(creditNote);
      },
    },
    reshapeData,
  });

  return [
    async (creditNote: CreditNote) => {
      const result = await updateCreditNoteRequestMutation(
        transformCreditNoteToUpdateApiPayload(creditNote),
      );
      onPayableUpdate(creditNote);
      return result;
    },
    ...rest,
  ];
};

export const updateCreditNoteRequestErrorToTranslation = (
  updateCreditNoteRequestError: UpdateCreditNoteRequestError,
  httpStatusCode: number,
): I18nKey | undefined => {
  if (httpStatusCode === 404) {
    return 'creditNote.review.update.errors.requestNotFound';
  }

  switch (updateCreditNoteRequestError.reason) {
    case 'cannotUpdateAlreadyValidatedCreditNote':
      return 'creditNote.review.update.errors.cannotUpdateAlreadyValidatedCreditNote';
    case 'requestIsNotEditable':
      return 'creditNote.review.update.errors.requestIsNotEditable';
    case 'requestNotFound':
      return 'creditNote.review.update.errors.requestNotFound';
    case 'userNotAuthorizedToUpdateThisRequest':
      return 'creditNote.review.update.errors.userNotAuthorizedToUpdateThisRequest';
    default:
      return undefined;
  }
};
