import React from 'react';

import { KomboConnectionModal } from './KomboConnectionModal';
import { PreRedirectionModal } from './PreRedirectionModal';
import { type Integration } from '../../../types';
import { isKomboIntegration } from '../../../utils';

type Props = {
  integration: Integration;
  closeModal: () => void;
};

export const ConnectionModal = ({ integration, closeModal }: Props) => {
  if (isKomboIntegration(integration.id)) {
    return (
      <KomboConnectionModal integration={integration} closeModal={closeModal} />
    );
  }
  if (integration.id === 'travelperk' || integration.id === 'spendesk-oauth2') {
    return (
      <PreRedirectionModal integration={integration} closeModal={closeModal} />
    );
  }

  return null;
};
