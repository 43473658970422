import { RadioBox, RadioGroup, type IconName } from '@dev-spendesk/grapes';
import React, { type ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type PaymentMethod } from 'modules/company';
import { getI18nContext } from 'src/core/selectors/i18n';

import type {
  PaymentDescriptorMap,
  PaymentMethodDescriptor,
} from '../PaymentMethodDropdown';

interface FieldOption {
  key: PaymentMethod;
  label: string;
  description: React.ReactNode;
  icon: IconName;
  isDisabled: boolean;
}

const getPaymentFieldsOptions = ({
  paymentMethodDescriptors,
  t,
  translatorParams,
  getIsPaymentMethodDisabled,
  getPaymentMethodHintMessage,
}: {
  paymentMethodDescriptors: PaymentDescriptorMap;
  t: TGlobalFunctionTyped;
  translatorParams: { countryContext: { context: string } | undefined };
  getIsPaymentMethodDisabled?: (paymentMethod: PaymentMethod) => boolean;
  getPaymentMethodHintMessage?: (paymentMethod: PaymentMethod) => ReactNode;
}) => {
  const getFieldOption = ({
    paymentMethod,
    paymentMethodDescriptor,
  }: {
    paymentMethod: PaymentMethod;
    paymentMethodDescriptor: PaymentMethodDescriptor;
  }) => {
    const hint = getPaymentMethodHintMessage?.(paymentMethod);

    const description = (
      <>
        <p>
          {t(
            paymentMethodDescriptor.description,
            translatorParams.countryContext,
          )}
        </p>
        {hint && <p className="text-warning-light">{hint}</p>}
      </>
    );

    const isDisabled = Boolean(getIsPaymentMethodDisabled?.(paymentMethod));

    return {
      key: paymentMethod,
      icon: paymentMethodDescriptor.icon,
      label: t(paymentMethodDescriptor.title, translatorParams.countryContext),
      description,
      isDisabled,
    };
  };

  const fieldsOptions: FieldOption[] = [];
  for (const [paymentMethod, paymentDescriptor] of paymentMethodDescriptors) {
    const fieldOption = getFieldOption({
      paymentMethod,
      paymentMethodDescriptor: paymentDescriptor,
    });

    fieldsOptions.push(fieldOption);
  }

  return fieldsOptions;
};

export const PaymentMethodOptions = ({
  paymentMethodDescriptors,
  paymentMethodSelected,
  onSelectPaymentMethod,
  getIsPaymentMethodDisabled,
  getPaymentMethodHintMessage,
}: {
  paymentMethodDescriptors: PaymentDescriptorMap;
  paymentMethodSelected: PaymentMethod;
  onSelectPaymentMethod: (paymentMethod: PaymentMethod) => void;
  getIsPaymentMethodDisabled?: (paymentMethod: PaymentMethod) => boolean;
  getPaymentMethodHintMessage?: (paymentMethod: PaymentMethod) => ReactNode;
}) => {
  const { t } = useTranslation('global');
  const countryContext = useSelector(getI18nContext);

  const paymentFieldsOptions = getPaymentFieldsOptions({
    paymentMethodDescriptors,
    t,
    translatorParams: {
      countryContext,
    },
    getIsPaymentMethodDisabled,
    getPaymentMethodHintMessage,
  });

  const handleSelectPaymentMethod = (paymentMethod: PaymentMethod): void =>
    onSelectPaymentMethod(paymentMethod);

  return (
    <RadioGroup
      direction="column"
      name="payment-method"
      value={paymentMethodSelected}
      onChange={(event) =>
        handleSelectPaymentMethod(event.target.value as PaymentMethod)
      }
    >
      {paymentFieldsOptions.map((paymentFieldOptions) => (
        <RadioBox
          key={paymentFieldOptions.key}
          label={paymentFieldOptions.label}
          value={paymentFieldOptions.key}
          iconName={paymentFieldOptions.icon}
          description={paymentFieldOptions.description}
          isDisabled={paymentFieldOptions.isDisabled}
        />
      ))}
    </RadioGroup>
  );
};
