import { type PaymentMethodsByIssuer } from 'modules/company';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export const useFetchPaymentMethods = (): QueryState<
  PaymentMethodsByIssuer,
  null
> => {
  return useQuery<PaymentMethodsByIssuer, PaymentMethodsByIssuer, null>({
    key: ['paymentMethods', 'invoice'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/payment-methods/invoice`,
    },
    reshapeData: (data) => data,
  });
};
