import { gql } from 'graphql-tag';

export const GET_SUPPLIERS_WITHOUT_ACCOUNT_PAYABLE_LIST = gql`
  query getSuppliersWithoutAccountPayableList(
    $companyId: ID!
    $filter: SupplierFilter
  ) {
    company(id: $companyId) {
      suppliers(first: 2, filter: $filter) {
        totalCount
        edges {
          node {
            id
            name
            thumbnailUrl
          }
        }
      }
    }
  }
`;
