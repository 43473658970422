import React from 'react';

import { ScheduledPaymentsBatchesLoader } from 'modules/reimbursements/components/ScheduledPaymentsBatchesList/ScheduledPaymentsBatchesLoader';

export const ExpenseClaimsHistoryPageSkeleton = () => {
  return (
    <div className="mt-xxl flex justify-center">
      <ScheduledPaymentsBatchesLoader count={2} />
    </div>
  );
};
