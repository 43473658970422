import { IconButton, Avatar } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type Member } from '../../member';

import './MemberDisplay.css';

export const MemberDisplay = ({
  member,
  onClick,
}: {
  member: Member;
  onClick?: (member: Member) => void;
}) => {
  const { t } = useTranslation('global');
  return (
    <div
      className={cx('MemberDisplay', {
        'MemberDisplay--hoverable': Boolean(onClick),
      })}
    >
      <Avatar
        src={member.avatar}
        text={member.pending ? member.email : member.fullname}
      />
      {member.pending ? (
        <span className="MemberDisplay_pending">{member.email}</span>
      ) : (
        <>
          <span className="MemberDisplay_name" title={member.fullname}>
            {member.fullname}
          </span>
          {member.teamNames && member.teamNames.length > 0 ? (
            <span
              className="MemberDisplay_teams"
              title={member.teamNames.join(', ')}
            >
              ({member.teamNames.join(', ')})
            </span>
          ) : null}
        </>
      )}
      {onClick ? (
        <IconButton
          className="MemberDisplay_action"
          iconName="trash"
          variant="borderless"
          onClick={() => {
            onClick(member);
          }}
          aria-label={t('controlRulesModal.members.removeThisMember', {
            member: member.fullname,
          })}
        />
      ) : null}
    </div>
  );
};
