import { isInUnion } from 'src/core/utils/validators';

import { type PurchaseOrder, type PurchaseOrderStatus } from './purchaseOrder';
import { type SupplierId } from './supplier';
import { type CostCenterId } from '../../budgets/models/costCenter';

export interface Filter {
  status: StatusFilter;
  costCenterIds?: CostCenterId[];
  supplierIds?: SupplierId[];
  search?: string;
}
export type StatusFilter = Extract<PurchaseOrderStatus, 'open' | 'closed'>;

export const defaultStatusFilter = 'open';

export const getFilterFromSearchParams = (
  urlSearchParams: URLSearchParams,
): Filter => ({
  status: getStatusFilterFromSearchParams(urlSearchParams),
  costCenterIds: urlSearchParams.has('costCenterIds')
    ? urlSearchParams.getAll('costCenterIds')
    : undefined,
  supplierIds: urlSearchParams.has('supplierIds')
    ? urlSearchParams.getAll('supplierIds')
    : undefined,
  search: urlSearchParams.get('search') ?? undefined,
});

export const getStatusFilterFromSearchParams = (
  urlSearchParams: URLSearchParams,
): StatusFilter => {
  const statusParameter = urlSearchParams.get('status');
  return isInUnion<StatusFilter>(['closed', 'open'], statusParameter)
    ? statusParameter
    : defaultStatusFilter;
};

export const getSearchParamsFromFilter = (
  filter: Partial<Filter>,
): URLSearchParams => {
  const urlSearchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(filter)) {
    if (Array.isArray(value)) {
      value.forEach((arrayValue) => {
        urlSearchParams.append(key, arrayValue);
      });
    } else if (value !== undefined) {
      urlSearchParams.append(key, value);
    }
  }

  return urlSearchParams;
};

export const applyFilter = (
  filter: Filter,
  newFilter: Partial<Filter>,
): Filter => ({ ...filter, ...newFilter });

export const getFilterFromPurchaseOrder = (
  purchaseOrder: PurchaseOrder,
): Filter => ({
  status: purchaseOrder.status === 'closed' ? 'closed' : 'open',
});
