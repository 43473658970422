import { IconButton, Tag } from '@dev-spendesk/grapes';
import { Handle, Position, type NodeProps } from '@xyflow/react';
import classNames from 'classnames';

import { ApprovalSchemeNodePreview } from '../ApprovalSchemeNodePreview';

export type StandardApprovalFlowNodeData = {
  nodeType: 'standardApprovalFlowNode';
  isSelected: boolean;
  onEditClick: () => void;
};

type Props = NodeProps & {
  data: StandardApprovalFlowNodeData;
};

const handleStyle = {
  background: 'transparent',
  border: 'none',
  width: 0,
  height: 0,
};

export const StandardApprovalFlowNode = ({ data }: Props) => {
  return (
    <div className="flex w-[340px] items-center">
      <div
        className={classNames(
          'rounded p-s',
          data.isSelected
            ? 'border border-solid border-primary bg-primary-lightest'
            : 'bg-white',
        )}
      >
        <div className="flex items-center justify-between">
          <Tag variant="primary">Standard approval workflow</Tag>
          <div className="flex items-center">
            <IconButton
              aria-label="edit"
              onClick={data.onEditClick}
              iconName="pen"
            />
          </div>
        </div>
        <div className="mt-xs text-neutral-darker body-s">
          This workflow will be executed if no other workflow applies. This
          ensures that any request has a reviewer.
        </div>
        <ApprovalSchemeNodePreview />
      </div>
      <Handle
        style={handleStyle}
        type="target"
        id="topHandle"
        position={Position.Top}
      />
    </div>
  );
};
