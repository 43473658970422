import { gql } from 'graphql-tag';

export const SET_SUPPLIER_ACCOUNT_TO_SUPPLIER = gql`
  mutation SetSupplierAccountToSupplier(
    $companyId: ID!
    $supplierId: ID!
    $supplierAccountId: String!
  ) {
    setSupplierAccountToSupplier(
      companyId: $companyId
      supplierId: $supplierId
      supplierAccountId: $supplierAccountId
    ) {
      ... on SetSupplierAccountToSupplierResultNotSet {
        reason
      }
    }
  }
`;
