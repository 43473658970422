/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { isSameDay } from 'date-fns';
import { type MonetaryValue } from 'ezmoney';

import { replaceAt } from 'common/utils/array';

import type { Location } from './perDiemLocation';

export interface PerDiemTripDay {
  mealsIncluded: MealsIncluded;
  stays: PerDiemDayStay[];
  isHotelIncluded: boolean;
}

export interface PerDiemDayStay {
  arrivalTime: Date | null;
  departureTime: Date | null;
  location: Location;
}

export type IdentifiableDayStay = PerDiemDayStay & { id: string };

export interface MealsIncluded {
  isBreakfastIncluded: boolean;
  isLunchIncluded: boolean;
  isDinnerIncluded: boolean;
}

export interface PerDiem {
  tripDays: PerDiemTripDay[];
  allowance: PerDiemAllowance;
}

export interface PerDiemAllowance {
  totalAmount: MonetaryValue;
  dailyAllowances: DailyAllowance[];
}

export interface DailyAllowance {
  dayType: 'half' | 'full';
  mealLegalAmount: MonetaryValue;
  reductions: {
    breakfast: AllowanceReduction | null;
    lunch: AllowanceReduction | null;
    dinner: AllowanceReduction | null;
  };
  finalAmount: MonetaryValue;
}

export interface AllowanceReduction {
  fullDayAmount: MonetaryValue;
  reductionAmount: MonetaryValue;
  reductionRate: number;
}

export interface Meal extends MealsIncluded {
  readonly date: Date;
}

export const extractDate = (dateTime: Date) =>
  `${dateTime.getFullYear()}-${dateTime.getMonth() + 1}-${dateTime.getDate()}`;

export const sortByDateTime = (stays: PerDiemDayStay[]) => {
  return [...stays].sort((stay, nextStay) => {
    const dateTime = stay.arrivalTime || stay.departureTime;
    const nextDateTime = nextStay.arrivalTime || nextStay.departureTime;
    if (dateTime && nextDateTime) {
      return dateTime.getTime() - nextDateTime.getTime();
    }
    if (dateTime && !nextDateTime) {
      return -1;
    }
    if (!dateTime && nextDateTime) {
      return 1;
    }
    return 0;
  });
};

export const flattenDayStays = (stays: PerDiemDayStay[]) => {
  const flattenedDayStays = stays.flatMap((stay) => {
    if (stay.arrivalTime && stay.departureTime) {
      const arrivalDate = extractDate(stay.arrivalTime);
      const departureDate = extractDate(stay.departureTime);
      if (arrivalDate === departureDate) {
        return [stay];
      }

      return [
        {
          arrivalTime: null,
          departureTime: stay.departureTime,
          location: stay.location,
        },
        {
          arrivalTime: stay.arrivalTime,
          departureTime: null,
          location: stay.location,
        },
      ];
    }
    return [stay];
  });
  return sortByDateTime(flattenedDayStays);
};

export const computeStaysForOneDay = (
  dayDateTime: Date,
  stays: PerDiemDayStay[],
  defaultLocation: Location,
) => {
  const dayStays = flattenDayStays(stays)
    .filter((s) => {
      const departure = s.departureTime && extractDate(s.departureTime);
      const arrival = s.arrivalTime && extractDate(s.arrivalTime);
      const day = extractDate(dayDateTime);
      return departure === day || arrival === day;
    })
    .map((ds) => ({
      arrivalTime: ds.arrivalTime,
      departureTime: ds.departureTime,
      location: ds.location.country ? ds.location : defaultLocation,
    }));

  return sortByDateTime(dayStays);
};

export const getDates = (mealsIncluded: Meal[]) =>
  mealsIncluded.map((meal) => meal.date);

export const addReturnLocation = (
  dayStays: PerDiemDayStay[],
  tripOrigin: Location,
): PerDiemDayStay[] => {
  const lastStay = dayStays.at(-1);
  if (lastStay && lastStay.departureTime) {
    const arrival = new Date(lastStay.departureTime);
    arrival.setSeconds(lastStay.departureTime.getSeconds() + 1);
    return dayStays.concat({
      location: tripOrigin,
      arrivalTime: arrival,
      departureTime: null,
    });
  }
  return dayStays;
};

export const toTripDays = (
  mealsIncluded: Meal[],
  stays: PerDiemDayStay[],
  isHotelIncluded: boolean,
  country: string,
): PerDiemTripDay[] => {
  const defaultLocation = { country, city: 'default' };
  let previousLocation = defaultLocation;
  return mealsIncluded.map((meal) => {
    const dayStays = computeStaysForOneDay(meal.date, stays, defaultLocation);
    if (dayStays.length) {
      const previousDayStay = dayStays.at(-1);
      if (previousDayStay) {
        previousLocation = previousDayStay.location;
      }
    }
    const buildStays = () => {
      if (dayStays.length) {
        return dayStays;
      }
      return [
        {
          arrivalTime: null,
          departureTime: null,
          location: previousLocation,
        },
      ];
    };
    return {
      mealsIncluded: {
        isBreakfastIncluded: meal.isBreakfastIncluded,
        isLunchIncluded: meal.isLunchIncluded,
        isDinnerIncluded: meal.isDinnerIncluded,
      },
      stays: buildStays(),
      isHotelIncluded,
    };
  });
};

enum Ordering {
  GT = 'GT',
  LT = 'LT',
  EQ = 'EQ',
}
const compareDeparture = (
  ds1: PerDiemDayStay,
  ds2: PerDiemDayStay,
): Ordering => {
  if (ds1.departureTime! > ds2.departureTime!) {
    return Ordering.GT;
  }
  if (ds1.departureTime! < ds2.departureTime!) {
    return Ordering.LT;
  }
  return Ordering.EQ;
};

export const fixDepartures = (
  perDiemDayStays: IdentifiableDayStay[],
): IdentifiableDayStay[] => {
  const loop = (
    dayStays: IdentifiableDayStay[],
    index: number,
  ): IdentifiableDayStay[] => {
    if (index === perDiemDayStays.length) {
      return dayStays;
    }
    if (index === 0) {
      return loop(dayStays, index + 1);
    }
    const previousDayStay = dayStays[index - 1];
    const dayStay = dayStays[index];
    if (compareDeparture(previousDayStay, dayStay) === Ordering.GT) {
      const departureTimeToUpdate = previousDayStay.departureTime
        ? new Date(previousDayStay.departureTime)
        : null;
      const updatedDayStay = {
        ...dayStay,
        departureTime: dayStay!.departureTime ? departureTimeToUpdate : null,
      };
      return loop(replaceAt(dayStays, index, updatedDayStay), index + 1);
    }
    return loop(dayStays, index + 1);
  };
  return loop(perDiemDayStays, 0);
};

export const hasChangedLocation = (dayStays: PerDiemDayStay[]): boolean => {
  const loop = (days: PerDiemDayStay[], index: number): boolean => {
    if (index === 0) {
      return false;
    }
    const previousDayStay = dayStays[index - 1];
    const dayStay = dayStays[index];
    if (
      previousDayStay.location.country !== dayStay.location.country ||
      previousDayStay.location.city !== dayStay.location.city
    ) {
      return true;
    }
    return loop(days, index - 1);
  };
  return loop(dayStays, dayStays.length - 1);
};

export const isForeignAllowanceCanTakePrecedence = (
  dayStays: PerDiemDayStay[],
  companyCountry: string,
): boolean => {
  const loop = (days: PerDiemDayStay[], index: number): boolean => {
    if (index === 0) {
      return false;
    }
    const previousDayStay = days[index - 1];
    const dayStay = days[index];
    if (
      previousDayStay.location.country !== companyCountry &&
      dayStay.location.country === companyCountry
    ) {
      return true;
    }
    return loop(days, index - 1);
  };
  return loop(dayStays, dayStays.length - 1);
};

export const isTripSpanningMultipleDates = (
  stays: PerDiemDayStay[],
): boolean => {
  for (const { arrivalTime, departureTime } of stays) {
    if (
      arrivalTime &&
      departureTime &&
      !isSameDay(arrivalTime, departureTime)
    ) {
      return true;
    }
  }
  return false;
};

export const findNextArrival = (
  perDiemTripDays: PerDiemTripDay[],
  startIndex: number,
): PerDiemDayStay | null => {
  for (const tripDay of perDiemTripDays.slice(startIndex)) {
    const dayStayWithArrivalTime = tripDay.stays.find(
      (stay) => stay.arrivalTime,
    );
    if (dayStayWithArrivalTime) {
      return dayStayWithArrivalTime;
    }
  }
  return null;
};

export const getLastArrival = (
  dayStays: PerDiemDayStay[],
): PerDiemDayStay | undefined => {
  return dayStays.reduce<PerDiemDayStay | undefined>((lastArrival, dayStay) => {
    if (dayStay.arrivalTime) {
      return dayStay;
    }
    return lastArrival;
  }, undefined);
};
