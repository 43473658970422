import { colors, Tag, type TagVariant } from '@dev-spendesk/grapes';
import {
  BaseEdge,
  EdgeLabelRenderer,
  type EdgeProps,
  getBezierPath,
  Position,
} from '@xyflow/react';

export type EdgeData = {
  labelText: string;
  labelVariant: TagVariant | 'blank';
  direction?: 'horizontal' | 'vertical';
};

type Props = EdgeProps & {
  data: EdgeData;
};

export const LabelEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data: { labelText, labelVariant, direction = 'horizontal' },
  markerEnd,
}: Props) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    ...(direction === 'horizontal'
      ? {
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        }
      : {
          sourcePosition: Position.Bottom,
          targetPosition: Position.Top,
        }),
  });

  return (
    <>
      <BaseEdge
        style={{ stroke: colors.complementary }}
        id={id}
        markerEnd={markerEnd}
        path={edgePath}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            pointerEvents: 'all',
          }}
        >
          {labelVariant === 'blank' ? (
            <div className="rounded bg-white px-xs py-xxs text-neutral-darker title-s">
              {labelText}
            </div>
          ) : (
            <Tag variant={labelVariant}>{labelText}</Tag>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};
