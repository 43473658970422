import { type Language } from 'src/core/config/i18n';

import learnToApprove1DeSource from './assets/learn_to_approve_1_de.webp';
import learnToApprove1EnSource from './assets/learn_to_approve_1_en.webp';
import learnToApprove1FrSource from './assets/learn_to_approve_1_fr.webp';
import learnToApprove2DeSource from './assets/learn_to_approve_2_de.webp';
import learnToApprove2EnSource from './assets/learn_to_approve_2_en.webp';
import learnToApprove2FrSource from './assets/learn_to_approve_2_fr.webp';
import uploadReceipt1DeSource from './assets/upload_receipt_1_de.webp';
import uploadReceipt1EnSource from './assets/upload_receipt_1_en.webp';
import uploadReceipt1FrSource from './assets/upload_receipt_1_fr.webp';

export const getLearnToApproveAssets = (lang: Language) => {
  switch (lang) {
    case 'de':
      return [learnToApprove1DeSource, learnToApprove2DeSource];
    case 'fr':
      return [learnToApprove1FrSource, learnToApprove2FrSource];
    default:
      return [learnToApprove1EnSource, learnToApprove2EnSource];
  }
};

export const getUploadReceiptFirstAsset = (lang: Language) => {
  switch (lang) {
    case 'de':
      return uploadReceipt1DeSource;
    case 'fr':
      return uploadReceipt1FrSource;
    default:
      return uploadReceipt1EnSource;
  }
};

export const getUploadReceiptThirdAsset = (lang: Language) => {
  switch (lang) {
    case 'de':
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/slideshows/upload_receipt-3-de.mp4';
    case 'fr':
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/slideshows/upload_receipt-3-fr.mp4';
    default:
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/slideshows/upload_receipt-3-en.mp4';
  }
};
