import {
  FormField,
  DatePicker,
  Button,
  IconButton,
  SwitchField,
  Callout,
  Skeleton,
} from '@dev-spendesk/grapes';
import { addMinutes, subMinutes, isSameDay } from 'date-fns';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { usePayableCounts } from 'modules/bookkeep/graphql/hooks';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { BookkeepingStartDateConfirmationModal } from './BookkeepingStartDateConfirmationModal';
import { useGetBookkeepingStartDateQuery } from '../../../../hooks/useGetBookkeepingStartDateQuery';
import { useSetBookkeepingStartDateMutation } from '../../../../hooks/useSetBookkeepingStartDateMutation';

export function BookkeepingStartDateSection() {
  const { t } = useTranslation('global');
  const history = useHistory();
  const companyId = useCompanyId();

  const bookkeepingStartDateQueryResult = useGetBookkeepingStartDateQuery();

  const bookkeepingStartDate = unwrapQuery(
    bookkeepingStartDateQueryResult,
  )?.bookkeepingStartDate;

  const isComingFromPreparePage =
    history.location.state?.isNewBookkeepingPrepareAfresh;

  const redirectTo = isComingFromPreparePage
    ? routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
        company: companyId,
      })
    : undefined;

  const [date, setDate] = useState<Date | null | undefined>(
    bookkeepingStartDate,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [setBookkeepingStartDate] = useSetBookkeepingStartDateMutation();

  const onConfirm = async () => {
    const newDate = localeDateToUTC(date);
    await setBookkeepingStartDate({
      payablesBookkeepingStartDate: newDate,
    });
    setDate(newDate);
    setIsModalOpen(false);
    if (redirectTo) {
      history.push(redirectTo);
    }
  };

  const payableCountsQueryResult = usePayableCounts({ to: date || undefined });

  if (bookkeepingStartDateQueryResult.status === 'loading') {
    return (
      <div className="box">
        <Skeleton width="75%" height="20px" className="my-[4px]" />
      </div>
    );
  }

  const isEnabled = bookkeepingStartDate !== undefined;

  return (
    <>
      <div className="box">
        <SwitchField
          label={t('accounting.bookkeepingStartDate.title')}
          fit="parent"
          isChecked={isEnabled}
          onChange={async () => {
            setDate(date !== undefined ? undefined : null);
            if (bookkeepingStartDate && date !== undefined) {
              await setBookkeepingStartDate({
                payablesBookkeepingStartDate: undefined,
              });
            }
          }}
        />

        {bookkeepingStartDateQueryResult.status === 'error' && (
          <Callout
            title={t('accounting.bookkeepingStartDate.error')}
            variant="alert"
            className="mt-m"
          />
        )}

        {date !== undefined && (
          <div className="mt-m flex flex-col gap-s">
            <FormField label={t('accounting.bookkeepingStartDate.field')}>
              <div className="flex flex-col gap-s">
                <p className="leading-5 text-neutral-dark">
                  {t('accounting.bookkeepingStartDate.content')}
                </p>

                <div className="flex flex-row items-center gap-xs">
                  <DatePicker
                    placement="bottom-start"
                    value={date || undefined}
                    onChange={(newDate) => {
                      setDate(newDate || undefined);
                    }}
                    placeholder={t(
                      'accounting.bookkeepingStartDate.placeholder',
                    )}
                    className="w-[288px]"
                  />
                  {date && (
                    <IconButton
                      iconName="cross"
                      variant="borderless"
                      aria-label={t('misc.delete')}
                      onClick={() => {
                        setDate(null);
                      }}
                    />
                  )}
                </div>
              </div>
            </FormField>

            <Button
              isDisabled={isSameDay(
                bookkeepingStartDate ?? new Date(),
                date ?? new Date(),
              )}
              text={t('accounting.bookkeepingStartDate.save')}
              variant="primary"
              className="w-[fit-content]"
              onClick={async () => {
                if (!date) {
                  await onConfirm();
                  return;
                }
                setIsModalOpen(true);
              }}
            />
          </div>
        )}
      </div>

      <BookkeepingStartDateConfirmationModal
        bookkeepingStartDate={date}
        hiddenPayablesCount={payableCountsQueryResult.data?.toPrepareCount}
        isOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={onConfirm}
      />
    </>
  );
}

// Will transform locale "2021-03-30T22:00:00.000Z"
// into UTC "2021-03-31T00:00:00.000Z"
// We need this transformation because we receive the locale date
// from the user input, but we need to send it to the server
// in a standardize way (UTC)
function localeDateToUTC(date: Date | null | undefined): Date | undefined {
  if (!date) {
    return;
  }
  const offset = date.getTimezoneOffset();
  return Math.sign(offset) !== -1
    ? subMinutes(date, Math.abs(offset))
    : addMinutes(date, Math.abs(offset));
}
