import {
  type Budget,
  type RawBudget,
  reshapeRawBudget,
} from 'modules/budgets/models/budget';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import { useCostCentersQuery } from '../../../apis';

export const USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE =
  'userNotAllowedToAccessBudget';

type RawBudgetQueryError = {
  errorCode: typeof USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE;
};

export const useRawBudgetQuery = (
  budgetId: string,
): QueryState<RawBudget, RawBudgetQueryError> => {
  return useQuery<RawBudget, RawBudgetQueryError>({
    key: ['budgets', budgetId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/budgets/budgets/${budgetId}`,
    },
  });
};

export const useBudgetQuery = (id: string): QueryState<Budget> => {
  return useQueryStates({
    states: {
      budget: useQuery<Budget, RawBudget, RawBudgetQueryError>({
        key: ['budgets', id],
        request: {
          type: 'rest',
          target: 'companyAPI',
          endpoint: `/budgets/budgets/${id}`,
        },
        reshapeData: reshapeRawBudget,
      }),
      costCenters: useCostCentersQuery({ withDeleted: true }),
    },
    reshapeData: ({ budget, costCenters }) => {
      const { costCenterId } = budget;
      const costCenter = costCenters.find((cc) => cc.id === costCenterId);
      return {
        ...budget,
        name: costCenter?.name ?? '',
        periodAmounts: budget.periodAmounts,
        isUnavailable: costCenter?.isDeleted,
      };
    },
  });
};
