import { createReducer } from '@reduxjs/toolkit';
import find from 'lodash/find';
import merge from 'lodash/merge';

import * as types from 'src/core/actionTypes/users';
import { swap } from 'src/core/utils/toolbelt';

type UsersState = {
  isLoading: boolean;
  isLoadingUsers: boolean;
  error: null | unknown;
  users: null | { id: string }[];
};

const initialState: UsersState = {
  isLoading: false,
  isLoadingUsers: false,
  error: null,
  users: null,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(types.FETCH_USERS_LOADING, (state: UsersState) => {
    state.isLoadingUsers = true;
  });
  builder.addCase(
    types.FETCH_USERS_SUCCESS,
    (
      state: UsersState,
      action: {
        type: types.FETCH_USERS_SUCCESS;
        payload: { id: string }[];
      },
    ) => {
      state.users = action.payload;
      state.isLoadingUsers = false;
    },
  );
  builder.addCase(
    types.FETCH_USERS_FAILURE,
    (
      state: UsersState,
      action: {
        type: types.FETCH_USERS_FAILURE;
        payload: unknown;
      },
    ) => {
      state.isLoadingUsers = false;
      state.error = action.payload;
    },
  );
  builder.addCase(types.FETCH_USER_LOADING, (state: UsersState) => {
    state.isLoading = true;
    state.error = null;
  });
  builder.addCase(
    types.FETCH_USER_FAILURE,
    (
      state,
      action: {
        type: types.FETCH_USER_FAILURE;
        error: unknown;
      },
    ) => {
      state.isLoading = false;
      state.error = action.error;
    },
  );
  builder.addCase(
    types.FETCH_USER_SUCCESS,
    (
      state: UsersState,
      action: {
        type: types.FETCH_USER_SUCCESS;
        payload: { id: string };
      },
    ) => {
      // eslint-disable-next-line lodash/matches-shorthand
      const user = find(state.users, { id: action.payload.id });
      const updatedUser = merge(user, action.payload);

      state.isLoading = false;
      state.users = swap(
        // @ts-expect-error types are not correct in swap fn
        state.users,
        (item) => item.id === action.payload.id,
        updatedUser,
      );
    },
  );
});
