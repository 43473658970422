import { Panel, PanelSection, SkeletonText } from '@dev-spendesk/grapes';

export const PanelPaymentLoader = () => {
  return (
    <Panel className="h-full">
      <PanelSection>
        <SkeletonText size="l" width="33%" className="block" />
        <SkeletonText size="xxl" width="50%" className="my-xxl block" />
      </PanelSection>
    </Panel>
  );
};
