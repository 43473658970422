import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import {
  type RawCustomField,
  reshapeCustomField,
  type CustomField,
} from '../models/customField';

export const useCustomFieldsQuery = ({
  isEnabled,
}: {
  isEnabled?: boolean;
}): QueryState<CustomField[]> => {
  return useQuery<CustomField[], RawCustomField[]>({
    key: ['customFields'],
    isEnabled: isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/custom-fields',
    },
    reshapeData: (data) => {
      return data
        .filter((customField) => !customField.deleted_at)
        .map(reshapeCustomField);
    },
  });
};
