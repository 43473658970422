import cx from 'classnames';
import React, {
  type ReactNode,
  type MouseEvent,
  useState,
  useRef,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';

import { PageViewerContext } from './viewerContext';
import './SiderPageLayout.css';

type Props = {
  className?: string;
  panel?: ReactNode;
  children: ReactNode;
};

export const SiderPageLayout = ({ className, panel, children }: Props) => {
  const viewerReference = useRef(null);
  const viewerContentReference = useRef(null);
  const [viewerContent, setViewerContent] = useState<ReactNode>();

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setViewerContent(undefined);
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const isPanelDisplayed = !!panel;
  useEffect(() => {
    setViewerContent(undefined);
  }, [isPanelDisplayed]);

  const onViewerClickOutside = (event: MouseEvent) => {
    if (
      viewerReference?.current === event.target ||
      viewerContentReference?.current === event.target
    ) {
      setViewerContent(undefined);
    }
  };

  return (
    <PageViewerContext.Provider value={setViewerContent}>
      <div className={cx('SiderPageLayout', className)}>
        <div className="SiderPageLayout__content">{children}</div>
        {panel && (
          <div
            className={cx('SiderPageLayout__panel', {
              'SiderPageLayout__panel--emphasized': Boolean(viewerContent),
            })}
          >
            {panel}
          </div>
        )}
        {viewerContent &&
          createPortal(
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <div
              ref={viewerReference}
              className="SiderPageLayout__viewer"
              onClick={onViewerClickOutside}
            >
              <div
                ref={viewerContentReference}
                className="SiderPageLayout__viewer-content"
              >
                {viewerContent}
              </div>
            </div>,
            document.body,
          )}
      </div>
    </PageViewerContext.Provider>
  );
};
