import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

import { NavLink } from '../NavLink/NavLink';

type Props = Readonly<{
  company: {
    id: string;
  };
}>;

export const BudgetsLink = ({ company }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavLink
      href={routeFor(routes.BUDGETARY_EXERCISES.path, {
        company: company.id,
      })}
      activePaths={[
        routes.BUDGET_OVERVIEW.path,
        routes.BUDGETARY_EXERCISES.path,
      ]}
      iconName="chart-pie"
    >
      {t('budget.mainMenuItem')}
    </NavLink>
  );
};
