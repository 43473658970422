import { useParams } from 'react-router-dom';

import { routes, routeFor } from 'src/core/constants/routes';
import history from 'src/core/history';

type Params = {
  supplierId?: string;
};

export const useAccountPayableRoute = () => {
  const { company: companyId }: { company: string } = useParams();

  return ({ supplierId }: Params = {}) =>
    supplierId
      ? getAccountPayableSupplierDetailRoute(companyId, supplierId)
      : getBaseAccountPayableRoute(companyId);
};

export const useGoToAccountPayable = () => {
  const getAccountPayableRoute = useAccountPayableRoute();

  return (params: Params = {}) => {
    const route = getAccountPayableRoute(params);
    history.push(route);
  };
};

const getBaseAccountPayableRoute = (companyId: string) =>
  routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
    company: companyId,
  });

const getAccountPayableSupplierDetailRoute = (
  companyId: string,
  supplierId: string,
) =>
  routeFor(routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path, {
    company: companyId,
    supplierId,
  });
