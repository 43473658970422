import {
  differenceInMonths,
  differenceInYears,
  endOfDay,
  startOfDay,
  areIntervalsOverlapping,
} from 'date-fns';
import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from "src/core/common/hooks/useTranslation";

import { type BudgetaryExerciseInput } from './budgetaryExerciseInput';
import { type BudgetaryExercise } from '../../models/budgetaryExercise';

export const validate = (
  values: BudgetaryExerciseInput,
  existingBudgetaryExercises: BudgetaryExercise[],
  translator: TGlobalFunctionTyped,
): FormikErrors<BudgetaryExerciseInput> => {
  const errors: FormikErrors<BudgetaryExerciseInput> = {};

  if (!values.name || values.name.trim() === '') {
    errors.name = translator('budget.createBEModal.validation.errorName');
  }

  if (!values.startDate) {
    errors.startDate = translator(
      'budget.createBEModal.validation.errorStartDate',
    );
  }

  if (!values.endDate) {
    errors.endDate = translator('budget.createBEModal.validation.errorEndDate');
  }

  if (!values.periodicity) {
    errors.periodicity = translator(
      'budget.createBEModal.validation.errorPeriodicity',
    );
  }

  if (values.endDate && values.startDate) {
    const startDate = new Date(values.startDate);
    const endDate = new Date(values.endDate);

    if (
      values.periodicity === 'quarterly' &&
      differenceInMonths(endDate, startDate) < 3
    ) {
      errors.periodicity = translator(
        'budget.createBEModal.validation.calloutQuarter',
      );
    }

    const overlappingBudgetaryExercise = getOverlappingBudgetaryExercise(
      existingBudgetaryExercises,
      { startDate, endDate },
    );
    if (overlappingBudgetaryExercise) {
      errors.startDate = translator(
        'budget.createBEModal.validation.errorOverlap',
        { name: overlappingBudgetaryExercise.name },
      );
    }

    if (differenceInYears(endDate, startDate) >= 1) {
      errors.startDate = translator(
        'budget.createBEModal.validation.moreThanYear',
      );
    }
  }

  return errors;
};

const getOverlappingBudgetaryExercise = (
  budgetaryExercises: BudgetaryExercise[],
  {
    startDate = new Date(),
    endDate = new Date(),
  }: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  },
): BudgetaryExercise | undefined => {
  const referenceInterval = buildInterval(startDate, endDate);

  return budgetaryExercises.find((budgetaryExercise) => {
    const interval = buildInterval(
      budgetaryExercise.startDate,
      budgetaryExercise.endDate,
    );

    return areIntervalsOverlapping(referenceInterval, interval);
  });
};

const buildInterval = (
  startDate: Date,
  endDate: Date,
): { start: Date; end: Date } => ({
  start: startOfDay(startDate),
  end: endOfDay(endDate),
});
