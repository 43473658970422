import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';

import * as types from './actionTypes';
import { STANDARD, AVAILABLE_DELIVERY_METHODS } from '../constants';

type PhysicalCardOrderState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardsToOrder: any[];
  deliveryMethod: string;
  deliveryAddress: {
    recipient: string | null;
    recipientName: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    city: string | null;
    zipCode: string | null;
    country: string | null;
  };
  availableDeliveryMethods: {
    key: string;
    title: string;
    estimateDeliveryDay: string;
    pricing: string;
    deliveryDelayRange: number[];
  }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  standardDeliveryPricing: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressDeliveryPricing: any;
};

const initialState: PhysicalCardOrderState = {
  cardsToOrder: [],
  deliveryMethod: STANDARD,
  deliveryAddress: {
    recipient: null,
    recipientName: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    zipCode: null,
    country: null,
  },
  availableDeliveryMethods: AVAILABLE_DELIVERY_METHODS,
  standardDeliveryPricing: {},
  expressDeliveryPricing: {},
};

export const addCardToOrder = (
  state: PhysicalCardOrderState,
  action: {
    type: typeof types.ADD_CARD_TO_ORDER;
    payload: { cardHolder: { _id: string } };
  },
) => {
  // If the card already exists just replace it. Else add it.
  const newCardsToOrder = state.cardsToOrder.filter(
    (card) =>
      get(card, 'cardHolder._id') !== get(action, 'payload.cardHolder._id'),
  );

  return {
    ...state,
    cardsToOrder: [...newCardsToOrder, action.payload],
  };
};

const removeCardToOrder = (
  state: PhysicalCardOrderState,
  action: {
    type: typeof types.REMOVE_CARD_TO_ORDER;
    payload: { cardUserId: string };
  },
) => ({
  ...state,
  cardsToOrder: state.cardsToOrder.filter(
    (item) => item.cardHolder._id !== action.payload.cardUserId,
  ),
});

const changeDeliveryAddressForOrder = (
  state: PhysicalCardOrderState,
  action: {
    type: typeof types.CHANGE_DELIVERY_ADDRESS_FOR_ORDER;
    payload: PhysicalCardOrderState['deliveryAddress'];
  },
) => ({
  ...state,
  deliveryAddress: action.payload,
});

const changeDeliveryMethodForOrder = (
  state: PhysicalCardOrderState,
  action: {
    type: typeof types.CHANGE_DELIVERY_METHOD_FOR_ORDER;
    payload: PhysicalCardOrderState['deliveryMethod'];
  },
) => ({
  ...state,
  deliveryMethod: action.payload,
});

const setExpressDeliveryPricing = (
  state: PhysicalCardOrderState,
  action: {
    type: typeof types.FETCH_EXPRESS_DELIVERY_PRICINGS_SUCCESS;
    payload: {
      standardDeliveryPricing: PhysicalCardOrderState['standardDeliveryPricing'];
      expressDeliveryPricing: PhysicalCardOrderState['expressDeliveryPricing'];
    };
  },
) => ({
  ...state,
  standardDeliveryPricing: action.payload.standardDeliveryPricing,
  expressDeliveryPricing: action.payload.expressDeliveryPricing,
});

const clearCurrentOrder = () => initialState;

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(types.ADD_CARD_TO_ORDER, addCardToOrder);
  builder.addCase(types.REMOVE_CARD_TO_ORDER, removeCardToOrder);
  builder.addCase(
    types.CHANGE_DELIVERY_ADDRESS_FOR_ORDER,
    changeDeliveryAddressForOrder,
  );
  builder.addCase(
    types.CHANGE_DELIVERY_METHOD_FOR_ORDER,
    changeDeliveryMethodForOrder,
  );
  builder.addCase(types.CLEAR_CURRENT_ORDER, clearCurrentOrder);
  builder.addCase(
    types.FETCH_EXPRESS_DELIVERY_PRICINGS_SUCCESS,
    setExpressDeliveryPricing,
  );
});
