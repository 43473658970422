import { type RawInvoiceData } from '@finance-review/apis/invoice/transformers';
import { useUpdateCacheWhenCreditChange } from '@finance-review/hooks/invoice';
import { type CreditNoteId } from '@finance-review/models/credit-note';
import { type InvoiceRequest } from '@finance-review/models/invoice';
import { type Translations } from 'common/components/QueryError';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type MarkAttachedCreditAsRefundedOutsideSpendeskError = {
  outcome: 'notDetached';
} & (
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason:
        | 'invalidValidationAction'
        | 'inconsistentCurrency'
        | 'noInvoiceRequests'
        | 'nothingToDetach'
        | 'invalidTransactionCommit'
        | 'invalidTransactionRollback';
    }
  | {
      reason: 'invoiceRequestNotFound';
      invoiceRequestId: string;
    }
  | {
      reason:
        | 'requestNotFound'
        | 'invalidRequestStatus'
        | 'cannotReplaceScheduledPayments';
      requestId: string;
    }
  | {
      reason:
        | 'invalidValidationAction'
        | 'inconsistentCurrency'
        | 'nonReimbursableCredit';
      creditNoteId: string;
    }
);

type Payload = {
  invoiceRequestId: InvoiceRequest.EntityId;
};

export type MarkAttachedCreditAsRefundedOutsideSpendeskResponse = {
  outcome: 'detached';
  detachedCreditNoteIds: CreditNoteId[];
  invoiceRequest: RawInvoiceData;
};

export const useMarkAttachedCreditAsRefundedOutsideSpendesk = (): MutationState<
  Payload,
  MarkAttachedCreditAsRefundedOutsideSpendeskResponse,
  MarkAttachedCreditAsRefundedOutsideSpendeskError
> => {
  const updateCacheWhenCreditChange = useUpdateCacheWhenCreditChange();

  return useMutation<
    Payload,
    MarkAttachedCreditAsRefundedOutsideSpendeskResponse,
    MarkAttachedCreditAsRefundedOutsideSpendeskResponse,
    MarkAttachedCreditAsRefundedOutsideSpendeskError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/credit_note_requests/refund_attached_credit_outside_spendesk`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data }) => {
        if (data.outcome === 'detached') {
          updateCacheWhenCreditChange(data.invoiceRequest);
        }
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};

export const markAttachedCreditAsRefundedOutsideSpendeskErrorTranslations: Translations<MarkAttachedCreditAsRefundedOutsideSpendeskError> =
  {
    requestError: () => {
      return 'creditNote.query.markAttachedCreditAsRefundedOutsideSpendesk.error';
    },
    serverError:
      'creditNote.query.markAttachedCreditAsRefundedOutsideSpendesk.error',
  };
