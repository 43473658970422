import { type MonetaryValue } from 'ezmoney';

import {
  type PayableAutomation,
  type PayableAmortisation,
  type MarvinConfidence,
} from 'modules/payable/models';
import { type PerDiem } from 'modules/per-diem';

import { type User } from './counterparty';
import { type AnalyticalFieldAssociation } from './itemLine';
import { type PayableSubType, type PayableType } from './payableType';
import { type PayableState } from '../../payables/models/payable';
import { type MileageAllowanceDetails } from '../../types';

export type Payable = {
  id: string;
  version: number;
  accountingDate: string | null;
  creationDate: string;
  amount: MonetaryValue;
  originalAmount: MonetaryValue;
  exchangeRate: { amount: number; precision: number };
  description: string;
  supplier?: {
    kind?: string;
    id?: string;
    name: string;
    thumbnailUrl?: string | null;
    isArchived?: boolean | null;
  } | null;
  supplierAccount?: {
    id?: string;
    generalAccountCode?: string | null | undefined;
    auxiliaryAccountCode?: string | null | undefined;
  };
  employeeAccount?: {
    id?: string;
    generalAccountCode?: string | null | undefined;
    auxiliaryAccountCode?: string | null | undefined;
  } | null;
  team?: {
    id: string;
    name: string;
  };
  accountPayableId: string | undefined;
  vatConfidence: MarvinConfidence | null;
  itemLines: {
    id: number;
    grossAmount: MonetaryValue;
    vat?: {
      vatAccount: {
        id: string;
        name: string;
        code?: string;
        rate?: number;
      } | null;
      amount: MonetaryValue | null;
    } | null;
    expense: {
      expenseAccount: {
        id: string;
        name: string;
        code?: string;
      } | null;
      amount: MonetaryValue;
    };
    analyticalFieldAssociations: AnalyticalFieldAssociation[];
  }[];
  type: PayableType;
  subType: PayableSubType | undefined;
  state: PayableState;
  costCenter:
    | {
        id?: string;
        name?: string;
      }
    | undefined;
  customFields: { [key: string]: string | boolean };
  mileageDetails?: MileageAllowanceDetails;
  perDiem?: PerDiem;
  automation?: PayableAutomation;
  createdAt: string;
  user: User;
  counterparty:
    | {
        id: string;
        name: string;
        thumbnailUrl: string;
      }
    | {
        id: string;
        givenName: string;
        familyName: string;
        email: string;
        avatar: string;
      }
    | {
        id: string;
        givenName: string;
        familyName: string;
        avatar: undefined;
      }
    | {
        id: string;
        email: string;
      };
  prepared: boolean;
  invoiceNumber?: string;
  documentaryEvidence?: {
    compliances?: Compliances;
  } & (
    | {
        type: 'invoice';
        invoiceNumber: string | undefined;
      }
    | {
        type: 'creditNote';
        creditNoteNumber: string | undefined;
      }
    | {
        type:
          | 'declarationOfMissingInvoice'
          | 'declarationOfMissingCreditNote'
          | 'mileageEntry'
          | 'perDiemEntry';
      }
  );
  amortisation?: PayableAmortisation;
};

type SpainEreceiptCompliance = {
  receipt?: {
    path: string;
    uniqueId: string;
  };
  closure?: {
    closedAt: string;
    closingPeriod: string;
  };
};

export type Compliances = {
  spainEreceipt?: SpainEreceiptCompliance | null;
};

export const payableVersionWhenPartiallyLoaded = 0;

export const isMileageAllowance = (payable: Payable): boolean =>
  Boolean(payable.mileageDetails && payable.mileageDetails.vehicle);
