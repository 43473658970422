import {
  EmptyStateError,
  EmptyStateLoading,
  lazyLoad,
} from 'common/components/LazyLoad';

export const SubscriptionsPage = lazyLoad({
  loader: async () => {
    const { SubscriptionsPage: SubscriptionsPageContainer } = await import(
      /* webpackChunkName: "SubscriptionsPageContainer" */ './SubscriptionsPage'
    );
    return {
      default: SubscriptionsPageContainer,
    };
  },
  loading: <EmptyStateLoading />,
  fallback: (props) => <EmptyStateError {...props} />,
});
