import { Skeleton, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import styles from './IntegrationAccountingPageSkeleton.module.css';

export function IntegrationAccountingPageSkeleton() {
  const { t } = useTranslation('global');

  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  if (hasNewNavigation) {
    return (
      <div className={styles.page}>
        <div className={styles.section}>
          <div className={styles.sectionDescriptionLine} />
          <div className={styles.wrapper}>
            <div className={styles.title}>
              <Skeleton
                className="mr-s"
                width="var(--sizing-l)"
                height="var(--sizing-l)"
              />
              <Skeleton className="mr-xs" width="150px" height="27px" />
              <Skeleton width="51px" height="var(--sizing-m)" />
            </div>
            <SkeletonText size="xl" className="mb-xxs" />
            <SkeletonText size="xl" className="mb-m" />
            <div className={styles.actions}>
              <SkeletonText width="175px" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <div className={styles.sidebar}>
        <Skeleton className="m-xs mb-s" width="170px" height="20px" />
        <Skeleton className="mx-xs mb-s" width="170px" height="20px" />
      </div>
      <div className={styles.section}>
        <div className="IntegrationAccountingPage__section-title title-xl">
          {t('bookkeep.integrations.settings.sectionAccountingSoftware')}
        </div>
        <div className={styles.sectionDescriptionLine} />
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <Skeleton
              className="mr-s"
              width="var(--sizing-l)"
              height="var(--sizing-l)"
            />
            <Skeleton className="mr-xs" width="150px" height="27px" />
            <Skeleton width="51px" height="var(--sizing-m)" />
          </div>
          <SkeletonText size="xl" className="mb-xxs" />
          <SkeletonText size="xl" className="mb-m" />
          <div className={styles.actions}>
            <SkeletonText width="175px" />
          </div>
        </div>
      </div>
    </div>
  );
}
