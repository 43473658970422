import { type Language } from "src/core/config/i18n";

export const getEmailPreviewLink = ({
  language,
  isRuleCreationMode,
}: {
  language: Language;
  isRuleCreationMode: boolean;
}): string => {
  if (language === 'fr') {
    return isRuleCreationMode
      ? 'https://www.notion.so/spendesk/Play-by-the-rules-95ba4dd21479493982c60a24fba5d4d6'
      : 'https://www.notion.so/spendesk/Play-by-the-rules-c0924191e371445bb886591389449349';
  }
  // TODO: i18n German
  return isRuleCreationMode
    ? 'https://www.notion.so/spendesk/Play-by-the-rules-4091cfe813a54e17aaa7ae55affb3dfb'
    : 'https://www.notion.so/spendesk/Play-by-the-rules-ba314c292f6a4318b7905bd11fdace9b';
};
