export const FETCH_SUPPLIERS_LOADING =
  'invoicesSuppliers/FETCH_SUPPLIERS_LOADING';
export const FETCH_SUPPLIERS_SUCCESS =
  'invoicesSuppliers/FETCH_SUPPLIERS_SUCCESS';
export const FETCH_SUPPLIERS_FAILURE =
  'invoicesSuppliers/FETCH_SUPPLIERS_FAILURE';

export const CREATE_SUPPLIER_LOADING =
  'invoicesSuppliers/CREATE_SUPPLIER_LOADING';
export const CREATE_SUPPLIER_SUCCESS =
  'invoicesSuppliers/CREATE_SUPPLIER_SUCCESS';
export const CREATE_SUPPLIER_FAILURE =
  'invoicesSuppliers/CREATE_SUPPLIER_FAILURE';

export const UPDATE_SUPPLIER_LOADING =
  'invoicesSuppliers/UPDATE_SUPPLIER_LOADING';
export const UPDATE_SUPPLIER_SUCCESS =
  'invoicesSuppliers/UPDATE_SUPPLIER_SUCCESS';
export const UPDATE_SUPPLIER_FAILURE =
  'invoicesSuppliers/UPDATE_SUPPLIER_FAILURE';
