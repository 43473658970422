import { FormField, OptionGroup } from '@dev-spendesk/grapes';
import React from 'react';

import { CustomFieldFormField } from 'common/components/CustomFieldFormField';
import { useTranslation } from 'common/hooks/useTranslation';
import { type RawCustomField } from 'modules/custom-fields/models/customField';

import { type CustomFieldFiltersType } from './types';

type Props = {
  filters: CustomFieldFiltersType;
  customField: RawCustomField;
  setFilters: (filters: CustomFieldFiltersType) => void;
  disableSelectUndefined?: boolean;
};

export const CustomFieldFilter = ({
  customField,
  filters,
  setFilters,
  disableSelectUndefined,
}: Props) => {
  const { t } = useTranslation('global');

  const selectedValue = filters[customField.id];

  if (customField.type === 'boolean') {
    return (
      <FormField
        key={customField.id}
        className="mb-s"
        label={customField.name}
        htmlFor="none"
      >
        <OptionGroup
          name="invoiceBooleanFilter"
          value={selectedValue?.key ?? null}
          onChange={(event) => {
            setFilters({
              ...filters,
              [customField.id]: {
                key: event.target.value,
                label: event.target.value,
              },
            });
          }}
          options={[
            {
              value:
                customField.custom_fields_values.find(
                  ({ value }) => value === '1',
                )?.id || '',
              label: t('misc.yes'),
            },
            {
              value:
                customField.custom_fields_values.find(
                  ({ value }) => value === '0',
                )?.id || '',
              label: t('misc.no'),
            },
          ]}
        />
      </FormField>
    );
  }

  return (
    <CustomFieldFormField
      key={customField.id}
      className="mb-s"
      fit="parent"
      customField={customField}
      label={customField.name}
      placeholder={customField.name}
      customFieldAssociation={
        selectedValue
          ? {
              customFieldId: customField.id,
              customFieldValueId: selectedValue?.key ?? '',
              value: selectedValue?.label ?? '',
            }
          : undefined
      }
      disableCreateNewValue
      disableSelectUndefined={disableSelectUndefined}
      onSelectCustomFieldValue={(customFieldValue) => {
        setFilters({
          ...filters,
          [customField.id]: customFieldValue
            ? { key: customFieldValue.key, label: customFieldValue.name }
            : null,
        });
      }}
      onSelectNewCustomFieldValue={() => {}}
    />
  );
};
