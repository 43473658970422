import { useState } from 'react';
import { useLocalStorage } from 'react-use';

import type FEATURES from "src/core/constants/features";

import { type EXTRA_FEATURES } from '../BetaFeatureNotice';

const sessionStoragePrefix = 'beta-feature-notice-';
const giveFeedbackButtonSessionStoragePrefix = 'beta-give-feedback-btn-';

type Params = {
  featureName: FEATURES | EXTRA_FEATURES;
  isMandatoryFeedbackSet: boolean;
};

export const useInitialiseBetaFeatureNotice = ({
  featureName,
  isMandatoryFeedbackSet,
}: Params) => {
  // This is used in the e2e tests to prevent the beta feature notice from blocking scenarios: if the local storage is set to false, beta feature notices won't open
  const [showAllBetaFeatureNotices] = useLocalStorage(
    `${sessionStoragePrefix}show-all`,
    true,
  );

  const [isBetaFeaturePopoverOpen, setShowBetaFeaturePopover] = useLocalStorage(
    sessionStoragePrefix + featureName,
    showAllBetaFeatureNotices,
  );

  const [hasGivenFeedback, setHasGivenFeedback] = useLocalStorage(
    giveFeedbackButtonSessionStoragePrefix + featureName,
    false,
  );

  const [isBetaFeatureFeedbackPopoverOpen, setShowBetaFeatureFeedbackPopover] =
    useState(
      () =>
        !hasGivenFeedback &&
        isMandatoryFeedbackSet &&
        !isBetaFeaturePopoverOpen,
    );

  const isFeedbackMandatory = isMandatoryFeedbackSet && !hasGivenFeedback;

  return {
    isBetaFeaturePopoverOpen,
    showBetaFeaturePopover: () => setShowBetaFeaturePopover(true),
    hideBetaFeaturePopover: () => {
      if (isFeedbackMandatory) {
        setShowBetaFeatureFeedbackPopover(true);
      }

      setShowBetaFeaturePopover(false);
    },
    isBetaFeatureFeedbackPopoverOpen,
    showBetaFeatureFeedbackPopover: () =>
      setShowBetaFeatureFeedbackPopover(true),
    hideBetaFeatureFeedbackPopover: () =>
      setShowBetaFeatureFeedbackPopover(false),
    onFeedbackSent: () => setHasGivenFeedback(true),
    isFeedbackMandatory,
    hasGivenFeedback,
  };
};
