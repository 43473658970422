import { type I18nKey } from 'common/hooks/useTranslation';

export type MerchantCategoryIdentifier =
  | 'airlines'
  | 'car_rental'
  | 'gas'
  | 'hotels'
  | 'meals_and_groceries'
  | 'parking_and_tolls'
  | 'postal_services'
  | 'taxi'
  | 'transportation'
  | 'other';

export type MerchantCategory = {
  id: string;
  identifier: MerchantCategoryIdentifier;
  allowed: boolean;
};

export const merchantCategoryToTranslationKey: {
  [identifier in MerchantCategoryIdentifier]: I18nKey;
} = {
  airlines: 'cards.controlSettings.sections.categories.airlines',
  car_rental: 'cards.controlSettings.sections.categories.carRental',
  gas: 'cards.controlSettings.sections.categories.gas',
  hotels: 'cards.controlSettings.sections.categories.hotels',
  meals_and_groceries: 'cards.controlSettings.sections.categories.meals',
  parking_and_tolls: 'cards.controlSettings.sections.categories.parking',
  postal_services: 'cards.controlSettings.sections.categories.postalServices',
  taxi: 'cards.controlSettings.sections.categories.taxi',
  transportation: 'cards.controlSettings.sections.categories.transportation',
  other: 'cards.controlSettings.sections.categories.other',
};

export const merchantCategoryOrder: MerchantCategoryIdentifier[] = [
  'airlines',
  'car_rental',
  'transportation',
  'hotels',
  'gas',
  'postal_services',
  'parking_and_tolls',
  'meals_and_groceries',
  'taxi',
  'other',
];
