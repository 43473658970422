import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import {
  transformDraftSupplierUpdateToAPIPayload,
  type UpdateSupplierAPIPayload,
} from './updateSupplierTransformer';
import {
  type DraftSupplierUpdate,
  type SupplierDetails,
  type SupplierId,
} from '../../../models';
import {
  type RawSupplier,
  transformSupplier,
  useSetSupplierQueryData,
} from '../useFetchSupplier';

export type UpdateSupplierError =
  | {
      reason: undefined;
      error: string;
    }
  | {
      reason: 'mimeTypeNotSupported';
      allowedMimeTypes: string[];
    }
  | {
      reason: 'invalidBankInfos';
      errors?: string[];
      invalidFields: object;
    }
  | {
      reason: 'bankDetailsAttachmentFail';
    }
  | {
      reason: 'invalidFile';
      errorMessage: string;
    }
  | {
      reason: 'notUploadableSupplierBankDetail';
      errorMessage: string;
    };

type Props = {
  onSupplierUpdateSuccess?(supplier: SupplierDetails): void;
};

export const useUpdateSupplier = (
  supplierId: SupplierId,
  { onSupplierUpdateSuccess }: Props = {},
): MutationState<DraftSupplierUpdate, SupplierDetails, UpdateSupplierError> => {
  const setSupplierQueryData = useSetSupplierQueryData();
  const [updateSupplierMutation, ...rest] = useMutation<
    UpdateSupplierAPIPayload,
    SupplierDetails,
    RawSupplier,
    UpdateSupplierError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/invoice_suppliers/${supplierId}`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data, rawData }) => {
        setSupplierQueryData(rawData);
        if (onSupplierUpdateSuccess) {
          onSupplierUpdateSuccess(data);
        }
      },
    },
    reshapeData(data) {
      return transformSupplier(data);
    },
  });

  return [
    (update: DraftSupplierUpdate) =>
      updateSupplierMutation(transformDraftSupplierUpdateToAPIPayload(update)),
    ...rest,
  ];
};
