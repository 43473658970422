import { useDispatch } from 'react-redux';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { saveCompanyInfoSuccess } from 'modules/company/billing-legacy';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

// TODO: type company
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Payload = any;

type Response = {
  id: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type RawResponse = {};

export const useUpdateCompanyInformationMutation = (): MutationState<
  Payload,
  Response
> => {
  const companyId = useCompanyId();
  const dispatch = useDispatch();

  // @ts-expect-error: No helpful comment
  return useMutation<Payload, Response, RawResponse>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'baseAPI',
      endpoint: `/companies/${companyId}`,
    },
    // @ts-expect-error: No helpful comment
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ data }) => {
        // @ts-expect-error: No helpful comment
        dispatch(saveCompanyInfoSuccess({ company: data }));
      },
    },
  });
};
