import { Button, IconButton } from '@dev-spendesk/grapes';
import { type Dispatch } from 'react';

import { type AnalyticalFieldMapping } from 'modules/bookkeep/settings/accounting';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from './ModalState';

interface Props {
  mapping: AnalyticalFieldMapping;
  setModalState: Dispatch<ModalState>;
}

export const TableButton = ({ mapping, setModalState }: Props) => {
  const { t } = useTranslation();

  return mapping.kind === 'notMapped' ? (
    <Button
      variant="secondary"
      onClick={() => {
        setModalState({
          modalKind: 'map',
          ...mapping,
        });
      }}
      className="CellWithButton__button"
      text={t('bookkeep.integrations.settings.selectValue')}
    />
  ) : (
    <span className="CellWithButton__button">
      <IconButton
        iconName="pen"
        variant="borderless"
        onClick={() => {
          setModalState({
            modalKind: 'map',
            ...mapping,
          });
        }}
        aria-label={t('misc.edit')}
      />
      <IconButton
        iconName="trash"
        variant="borderless"
        onClick={() => {
          setModalState({
            ...mapping,
            modalKind: 'delete',
            kind: 'notMapped',
          });
        }}
        aria-label={t('misc.delete')}
      />
    </span>
  );
};
