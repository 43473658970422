import { type BankJournalFileExportId } from 'modules/payable-export/models';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';
import {
  type Translations,
  useQueryError,
} from 'src/core/common/components/QueryError';

export type ExportBankJournalError =
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason: 'ongoingExport' | 'unavailableCapability' | 'databaseError';
    };

export type ExportBankJournalResponse = {
  exportId: BankJournalFileExportId;
};

export type ExportBankJournalPayload = {
  period: { from: string; to: string };
  excludeNonPreparedPayables?: boolean;
  includeAlreadyExported?: boolean;
  markAsAccountedThroughSupervision?: boolean;
};

/**
 * Mutation
 **/

export const useExportBankJournal = () => {
  return useMutation<
    ExportBankJournalPayload,
    ExportBankJournalResponse,
    ExportBankJournalResponse,
    ExportBankJournalError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/export/bank-journal',
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
    reshapeData(data) {
      return data;
    },
  });
};

/**
 * Error messages
 **/

export const exportBankJournalErrorTranslations: Translations<ExportBankJournalError> =
  {
    requestError: ({ reason }) => {
      switch (reason) {
        case 'ongoingExport':
          return 'bankJournal.api.exportBankJournal.ongoingExportError';
        default:
          return 'bankJournal.api.exportBankJournal.error';
      }
    },
    serverError: 'bankJournal.api.exportBankJournal.error',
  };

export const useExportBankJournalErrorMessage = () => {
  const getErrorMessage = useQueryError<ExportBankJournalError>(
    exportBankJournalErrorTranslations,
  );

  return (
    queryError: QueryError<ExportBankJournalError>,
    translationParams?: object,
  ): string => getErrorMessage(queryError, translationParams);
};
