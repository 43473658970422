import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  selectedTransfersCount: number;
  totalTransfersCount: number;
};

export const TransferListHeader = ({
  selectedTransfersCount,
  totalTransfersCount,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="TransferListHeader__header">
      <span className="TransferListHeader__header__content">
        {t('invoices.transfer.transfersSelected', {
          totalTransfersCount,
          count: selectedTransfersCount,
        })}
      </span>
    </div>
  );
};
