export type ActionsCounts = {
  requestsToReview: number;
  invoicesToPay: number;
  invoiceTransfersToConfirm?: number;
  expenseTransfersToConfirm?: number;
  paymentsWithoutReceipts: number;
  paymentsToPrepare: number;
};

export const sumActionCounts = (counts: ActionsCounts): number => {
  return Object.values(counts)
    .filter(Boolean)
    .reduce((sum, count) => sum + (count ?? 0), 0);
};

export type ActionsCountsState =
  | {
      loading: true;
    }
  | {
      loading: false;
      counts: ActionsCounts;
    };
