export function rejectUnexpectedValue(name: string, value: never): never {
  throw new Error(`Unexpected ${name}: ${value}`);
}

export function processUnexpectedValue(
  value: never,
  F: (value: never) => void,
): void {
  F(value);
}
export class TypeGuardError extends Error {
  constructor(_switchCase: never, message: string) {
    super(message);
  }
}
