import {
  SkeletonTable,
  Skeleton,
  SkeletonCheckbox,
} from '@dev-spendesk/grapes';
import React from 'react';

import { PayableListItemSkeleton } from './PayableListItemSkeleton';

export const PayableListSkeleton = ({
  className = '',
}: {
  className?: string;
}) => {
  return (
    <div className={className}>
      <Skeleton className="mb-xs block" height="27px" width="33%" />
      <SkeletonTable
        withHeader
        columns={[
          {
            header: (
              <div className="flex h-[52px] w-full items-center justify-between">
                <SkeletonCheckbox className="mr-s" />
              </div>
            ),
            cell: <PayableListItemSkeleton />,
          },
        ]}
        numberOfRows={10}
      />
    </div>
  );
};
