// These functions exist in the back-end too, with the same name

type GeneralAndAuxiliaryAccounts = {
  generalAccountCode: string;
  auxiliaryAccountCode?: string | undefined;
};

export const getCodeWithAuxiliaryAccounts = (
  input: GeneralAndAuxiliaryAccounts,
): string =>
  input.generalAccountCode
    ? `${input.generalAccountCode}${
        input?.auxiliaryAccountCode ? `/${input.auxiliaryAccountCode}` : ``
      }`
    : '';

export const getGeneralAndAuxiliaryAccountCodes = (
  code: string,
): GeneralAndAuxiliaryAccounts => {
  const splitted = code ? code.split('/') : [];
  const generalAccountCode = splitted.shift() ?? '';
  const auxiliaryAccountCode = splitted.length ? splitted.join('_') : undefined;
  return { generalAccountCode, auxiliaryAccountCode };
};
