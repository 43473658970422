import cx from 'classnames';
import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type Discount } from 'modules/company/billing/models';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { formatDiscount, computePriceWithDiscount } from '../helpers';

import './Subscription.css';

type Props = {
  name: string;
  price: MonetaryValue;
  discounts: Discount[];
};

const Subscription = ({ name, price, discounts }: Props) => {
  const { t } = useTranslation('global');
  // For now, we manage only one discount on the subscription
  const discount = discounts[0];
  const discountedPrice = computePriceWithDiscount(price, discount);

  return (
    <div className="Subscription">
      <span className="Subscription__name">{name}</span>
      <div className="Subscription__pricing">
        <div className="Subscription__pricing-amounts">
          <span
            className={cx('Subscription__pricing-amount', {
              'Subscription__pricing-amount--crossed': !!discount,
            })}
          >
            {formatMonetaryValue(price)}
          </span>
          {discountedPrice && (
            <span className="Subscription__plan-amount">
              {formatMonetaryValue(discountedPrice)}
            </span>
          )}{' '}
          / {t(`billing.month`)}
        </div>
        {discount && (
          <div className="Subscription__pricing-discounts">
            {t('billing.discount', {
              discount: formatDiscount(discount),
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Subscription;
