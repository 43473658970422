import React from 'react';

import { Spinner } from 'common/components/Spinner/Spinner';
import { useTranslation } from 'common/hooks/useTranslation';

import './AffidavitSignatureLoader.css';

export const AffidavitSignatureLoader = () => {
  const { t } = useTranslation('global');

  return (
    <div className="AffidavitSignatureLoader">
      <div className="AffidavitSignatureLoader__content">
        <div className="AffidavitSignatureLoader__content__spinner">
          <Spinner />
        </div>
        <div className="AffidavitSignatureLoader__content__message">
          <div>
            {t('payments.affidavitModal.signatureModal.loader.message1')}
          </div>
          <div>
            {t('payments.affidavitModal.signatureModal.loader.message2')}
          </div>
        </div>
      </div>
    </div>
  );
};
