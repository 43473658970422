import { useQuery } from 'src/core/api/hooks/useQuery';

import { GET_ACCOUNTING_INTEGRATION_HISTORY } from '../graphql/queries';
import {
  type AccountingSoftware,
  type AccountingSoftwareGraphQl,
  toAccountingSoftware,
} from '../integration/status';

export type RawData = {
  company: {
    accountingIntegrationHistory: {
      accountingIntegrationId: AccountingSoftwareGraphQl;
      accountingCountry: string;
      from: string;
      to: string | null;
    }[];
  };
};

export type Result = {
  accountingIntegrationId: AccountingSoftware;
  accountingCountry: string;
  from: Date;
  to: Date | null;
}[];

function reshapeData(rawData: RawData): Result {
  return rawData.company.accountingIntegrationHistory.map((data) => {
    const accountingIntegrationId = toAccountingSoftware(
      data.accountingIntegrationId,
    );

    if (accountingIntegrationId === 'noIntegration') {
      throw new Error('Invalid accounting integration');
    }

    return {
      accountingIntegrationId,
      accountingCountry: data.accountingCountry,
      from: new Date(data.from),
      to: data.to ? new Date(data.to) : null,
    };
  });
}

export const useAccountingIntegrationHistory = () => {
  return useQuery<Result, RawData>({
    key: 'useAccountingIntegrationHistory',
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_ACCOUNTING_INTEGRATION_HISTORY,
    },
    reshapeData,
  });
};
