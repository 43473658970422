import { type CancelTokenSource } from 'axios';

import {
  type UserScheduledPayments,
  type ScheduledPayment,
  type ScheduledPaymentUser,
  type ScheduledPaymentsBatch,
  type USERS_SCHEDULED_PAYMENTS_LIST_ROOT_KEY,
  type ExpenseClaimsCounts,
  type AuthType,
} from '../types';

export const FETCH_EXPENSE_CLAIMS_COUNTS_REQUEST =
  'expenseClaims/FETCH_EXPENSE_CLAIMS_COUNTS_REQUEST';
export const FETCH_EXPENSE_CLAIMS_COUNTS_SUCCESS =
  'expenseClaims/FETCH_EXPENSE_CLAIMS_COUNTS_SUCCESS';
export const FETCH_EXPENSE_CLAIMS_COUNTS_FAILURE =
  'expenseClaims/FETCH_EXPENSE_CLAIMS_COUNTS_FAILURE';
export const UPDATE_EXPENSE_CLAIMS_COUNTS =
  'expenseClaims/UPDATE_EXPENSE_CLAIMS_COUNTS';
export const FETCH_USERS_SCHEDULED_PAYMENTS_REQUEST =
  'expenseClaims/FETCH_USERS_SCHEDULED_PAYMENTS_REQUEST';
export const FETCH_USERS_SCHEDULED_PAYMENTS_SUCCESS =
  'expenseClaims/FETCH_USERS_SCHEDULED_PAYMENTS_SUCCESS';
export const FETCH_USERS_SCHEDULED_PAYMENTS_FAILURE =
  'expenseClaims/FETCH_USERS_SCHEDULED_PAYMENTS_FAILURE';
export const RESET_USERS_SCHEDULED_PAYMENTS =
  'expenseClaims/RESET_USERS_SCHEDULED_PAYMENTS';
export const FETCH_USER_SCHEDULED_PAYMENTS_REQUEST =
  'expenseClaims/FETCH_USER_SCHEDULED_PAYMENTS_REQUEST';
export const FETCH_USER_SCHEDULED_PAYMENTS_SUCCESS =
  'expenseClaims/FETCH_USER_SCHEDULED_PAYMENTS_SUCCESS';
export const RESET_OPENED_USER_SCHEDULED_PAYMENTS =
  'expenseClaims/RESET_OPENED_USER_SCHEDULED_PAYMENTS';
export const TOGGLE_USER_SCHEDULED_PAYMENTS_SELECTION =
  'expenseClaims/TOGGLE_USER_SCHEDULED_PAYMENTS_SELECTION';
export const SEND_TO_PAYMENT_REQUEST = 'expenseClaims/SEND_TO_PAYMENT_REQUEST';
export const SEND_TO_PAYMENT_SUCCESS = 'expenseClaims/SEND_TO_PAYMENT_SUCCESS';
export const SEND_TO_PAYMENT_FAILURE = 'expenseClaims/SEND_TO_PAYMENT_FAILURE';
export const DOWNLOAD_CSV_REQUEST = 'expenseClaims/DOWNLOAD_CSV_REQUEST';
export const DOWNLOAD_CSV_SUCCESS = 'expenseClaims/DOWNLOAD_CSV_SUCCESS';
export const DOWNLOAD_CSV_FAILURE = 'expenseClaims/DOWNLOAD_CSV_FAILURE';
export const SEND_MISSING_BANK_INFO_REMINDERS_REQUEST =
  'expenseClaims/SEND_MISSING_BANK_INFO_REMINDERS_REQUEST';
export const SEND_MISSING_BANK_INFO_REMINDERS_SUCCESS =
  'expenseClaims/SEND_MISSING_BANK_INFO_REMINDERS_SUCCESS';
export const SEND_MISSING_BANK_INFO_REMINDERS_FAILURE =
  'expenseClaims/SEND_MISSING_BANK_INFO_REMINDERS_FAILURE';
export const FETCH_SCHEDULED_PAYMENTS_BATCHES_REQUEST =
  'expenseClaims/FETCH_SCHEDULED_PAYMENTS_BATCHES_REQUEST';
export const FETCH_SCHEDULED_PAYMENTS_BATCHES_SUCCESS =
  'expenseClaims/FETCH_SCHEDULED_PAYMENTS_BATCHES_SUCCESS';
export const FETCH_SCHEDULED_PAYMENTS_BATCHES_FAILURE =
  'expenseClaims/FETCH_SCHEDULED_PAYMENTS_BATCHES_FAILURE';
export const CLEAR_SCHEDULED_PAYMENTS_BATCHES =
  'expenseClaims/CLEAR_SCHEDULED_PAYMENTS_BATCHES';
export const REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REQUEST =
  'expenseClaims/REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REQUEST';
export const REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REMOVE =
  'expenseClaims/REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REMOVE';
export const REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_UPDATE =
  'expenseClaims/REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_UPDATE';
export const REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_FAILURE =
  'expenseClaims/REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_FAILURE';
export const FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_REQUEST =
  'expenseClaims/FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_REQUEST';
export const FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_SUCCESS =
  'expenseClaims/FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_SUCCESS';
export const FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_FAILURE =
  'expenseClaims/FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_FAILURE';
export const FETCH_SCHEDULED_PAYMENT_REQUEST =
  'expenseClaims/FETCH_SCHEDULED_PAYMENT_REQUEST';
export const FETCH_SCHEDULED_PAYMENT_SUCCESS =
  'expenseClaims/FETCH_SCHEDULED_PAYMENT_SUCCESS';
export const FETCH_SCHEDULED_PAYMENT_FAILURE =
  'expenseClaims/FETCH_SCHEDULED_PAYMENT_FAILURE';
export const RESET_OPENED_SCHEDULED_PAYMENT =
  'expenseClaims/RESET_OPENED_SCHEDULED_PAYMENT';
export const CONFIRM_SCHEDULED_PAYMENTS_BATCH_REQUEST =
  'expenseClaims/CONFIRM_SCHEDULED_PAYMENTS_BATCH_REQUEST';
export const CONFIRM_SCHEDULED_PAYMENTS_BATCH_SUCCESS =
  'expenseClaims/CONFIRM_SCHEDULED_PAYMENTS_BATCH_SUCCESS';
export const CONFIRM_SCHEDULED_PAYMENTS_BATCH_FAILURE =
  'expenseClaims/CONFIRM_SCHEDULED_PAYMENTS_BATCH_FAILURE';
export const CONFIRM_SCHEDULED_PAYMENT_REQUEST =
  'expenseClaims/CONFIRM_SCHEDULED_PAYMENT_REQUEST';
export const CONFIRM_SCHEDULED_PAYMENT_SUCCESS =
  'expenseClaims/CONFIRM_SCHEDULED_PAYMENT_SUCCESS';
export const CONFIRM_SCHEDULED_PAYMENT_FAILURE =
  'expenseClaims/CONFIRM_SCHEDULED_PAYMENT_FAILURE';
export const CANCEL_SCHEDULED_PAYMENT_REQUEST =
  'expenseClaims/CANCEL_SCHEDULED_PAYMENT_REQUEST';
export const CANCEL_SCHEDULED_PAYMENT_SUCCESS =
  'expenseClaims/CANCEL_SCHEDULED_PAYMENT_SUCCESS';
export const CANCEL_SCHEDULED_PAYMENT_FAILURE =
  'expenseClaims/CANCEL_SCHEDULED_PAYMENT_FAILURE';
export const CANCEL_SCHEDULED_PAYMENTS_BATCH_REQUEST =
  'expenseClaims/CANCEL_SCHEDULED_PAYMENTS_BATCH_REQUEST';
export const CANCEL_SCHEDULED_PAYMENTS_BATCH_SUCCESS =
  'expenseClaims/CANCEL_SCHEDULED_PAYMENTS_BATCH_SUCCESS';
export const CANCEL_SCHEDULED_PAYMENTS_BATCH_FAILURE =
  'expenseClaims/CANCEL_SCHEDULED_PAYMENTS_BATCH_FAILURE';
export const DOWNLOAD_CSV_BATCH_DETAILS_REQUEST =
  'expenseClaims/DOWNLOAD_CSV_BATCH_DETAILS_REQUEST';
export const DOWNLOAD_CSV_BATCH_DETAILS_SUCCESS =
  'expenseClaims/DOWNLOAD_CSV_BATCH_DETAILS_SUCCESS';
export const DOWNLOAD_CSV_BATCH_DETAILS_FAILURE =
  'expenseClaims/DOWNLOAD_CSV_BATCH_DETAILS_FAILURE';
export const RESET_SCHEDULED_PAYMENTS_CONFIRMATION_AUTH_TYPE =
  'expenseClaims/RESET_SCHEDULED_PAYMENTS_CONFIRMATION_AUTH_TYPE';

export type FetchExpenseClaimsCountsRequest = {
  readonly type: typeof FETCH_EXPENSE_CLAIMS_COUNTS_REQUEST;
};

export type FetchExpenseClaimsCountsSuccess = {
  readonly type: typeof FETCH_EXPENSE_CLAIMS_COUNTS_SUCCESS;
  readonly payload: ExpenseClaimsCounts;
};

export type FetchExpenseClaimsCountsFailure = {
  readonly type: typeof FETCH_EXPENSE_CLAIMS_COUNTS_FAILURE;
};

export type UpdateExpenseClaimsCounts = {
  readonly type: typeof UPDATE_EXPENSE_CLAIMS_COUNTS;
  readonly payload: ExpenseClaimsCounts;
};

export type FetchUsersScheduledPaymentsRequest = {
  readonly type: typeof FETCH_USERS_SCHEDULED_PAYMENTS_REQUEST;
};

export type FetchUsersScheduledPaymentsSuccess = {
  readonly type: typeof FETCH_USERS_SCHEDULED_PAYMENTS_SUCCESS;
  readonly payload: {
    readonly totalCount: number;
    readonly items: UserScheduledPayments[];
  };
};

export type FetchUsersScheduledPaymentsFailure = {
  readonly type: typeof FETCH_USERS_SCHEDULED_PAYMENTS_FAILURE;
};

export type ResetUsersScheduledPayments = {
  type: typeof RESET_USERS_SCHEDULED_PAYMENTS;
};

export type ResetScheduledPaymentsConfirmationAuthType = {
  readonly type: typeof RESET_SCHEDULED_PAYMENTS_CONFIRMATION_AUTH_TYPE;
};

export type FetchUserScheduledPaymentsRequest = {
  readonly type: typeof FETCH_USER_SCHEDULED_PAYMENTS_REQUEST;
};

export type FetchUserScheduledPaymentsSuccess = {
  readonly type: typeof FETCH_USER_SCHEDULED_PAYMENTS_SUCCESS;
  readonly payload: UserScheduledPayments;
};

export type ResetOpenedUserScheduledPayments = {
  readonly type: typeof RESET_OPENED_USER_SCHEDULED_PAYMENTS;
};

export type ToggleUserScheduledPaymentsSelection = {
  readonly type: typeof TOGGLE_USER_SCHEDULED_PAYMENTS_SELECTION;
  readonly payload:
    | ScheduledPaymentUser['id']
    | typeof USERS_SCHEDULED_PAYMENTS_LIST_ROOT_KEY;
};

export type SendToPaymentRequest = {
  readonly type: typeof SEND_TO_PAYMENT_REQUEST;
};

export type SendToPaymentSuccess = {
  readonly type: typeof SEND_TO_PAYMENT_SUCCESS;
};

export type SendToPaymentFailure = {
  readonly type: typeof SEND_TO_PAYMENT_FAILURE;
};

export type DownloadCsvRequest = {
  readonly type: typeof DOWNLOAD_CSV_REQUEST;
};

export type DownloadCsvSuccess = {
  readonly type: typeof DOWNLOAD_CSV_SUCCESS;
};

export type DownloadCsvFailure = {
  readonly type: typeof DOWNLOAD_CSV_FAILURE;
};

export type SendMissingBankInfoRemindersRequest = {
  readonly type: typeof SEND_MISSING_BANK_INFO_REMINDERS_REQUEST;
};

export type SendMissingBankInfoRemindersSuccess = {
  readonly type: typeof SEND_MISSING_BANK_INFO_REMINDERS_SUCCESS;
};

export type SendMissingBankInfoRemindersFailure = {
  readonly type: typeof SEND_MISSING_BANK_INFO_REMINDERS_FAILURE;
};

export type FetchScheduledPaymentsBatchesRequest = Readonly<{
  type: typeof FETCH_SCHEDULED_PAYMENTS_BATCHES_REQUEST;
  payload: {
    cancelToken: CancelTokenSource;
  };
}>;

export type FetchScheduledPaymentsBatchesSuccess = {
  readonly type: typeof FETCH_SCHEDULED_PAYMENTS_BATCHES_SUCCESS;
  readonly payload: {
    readonly items: ScheduledPaymentsBatch[];
    readonly nextCursor: number | string | null;
  };
};

export type FetchScheduledPaymentsBatchesFailure = {
  readonly type: typeof FETCH_SCHEDULED_PAYMENTS_BATCHES_FAILURE;
};

export type ClearScheduledPaymentsBatches = Readonly<{
  type: typeof CLEAR_SCHEDULED_PAYMENTS_BATCHES;
}>;

export type RefreshOneScheduledPaymentsBatchRequest = {
  readonly type: typeof REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REQUEST;
};

export type RefreshOneScheduledPaymentsBatchRemove = {
  readonly type: typeof REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REMOVE;
  readonly payload: { batchId: string };
};

export type RefreshOneScheduledPaymentsBatchUpdate = {
  readonly type: typeof REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_UPDATE;
  readonly payload: ScheduledPaymentsBatch;
};

export type RefreshOneScheduledPaymentsBatchFailure = {
  readonly type: typeof REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_FAILURE;
};

export type FetchScheduledPaymentsByBatchIdRequest = {
  readonly type: typeof FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_REQUEST;
  readonly payload: {
    readonly batchId: ScheduledPaymentsBatch['id'];
  };
};

export type FetchScheduledPaymentsByBatchIdSuccess = {
  readonly type: typeof FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_SUCCESS;
  readonly payload: {
    readonly batchId: ScheduledPaymentsBatch['id'];
    readonly scheduledPayments: ScheduledPayment[];
  };
};

export type FetchScheduledPaymentsByBatchIdFailure = {
  readonly type: typeof FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_FAILURE;
  readonly payload: {
    readonly batchId: ScheduledPaymentsBatch['id'];
  };
};

export type FetchScheduledPaymentRequest = {
  readonly type: typeof FETCH_SCHEDULED_PAYMENT_REQUEST;
  readonly payload: {
    readonly scheduledPaymentId: ScheduledPayment['id'];
  };
};

export type FetchScheduledPaymentSuccess = {
  readonly type: typeof FETCH_SCHEDULED_PAYMENT_SUCCESS;
  readonly payload: ScheduledPayment;
};

export type FetchScheduledPaymentFailure = {
  readonly type: typeof FETCH_SCHEDULED_PAYMENT_FAILURE;
  readonly payload: {
    readonly scheduledPaymentId: ScheduledPayment['id'];
  };
};

export type ResetOpenedScheduledPayment = {
  readonly type: typeof RESET_OPENED_SCHEDULED_PAYMENT;
};

export type ConfirmScheduledPaymentsBatchRequest = {
  readonly type: typeof CONFIRM_SCHEDULED_PAYMENTS_BATCH_REQUEST;
};

export type ConfirmScheduledPaymentsBatchSuccess = {
  readonly type: typeof CONFIRM_SCHEDULED_PAYMENTS_BATCH_SUCCESS;
  readonly payload: {
    readonly authType: AuthType;
  };
};

export type ConfirmScheduledPaymentsBatchFailure = {
  readonly type: typeof CONFIRM_SCHEDULED_PAYMENTS_BATCH_FAILURE;
};

export type ConfirmScheduledPaymentRequest = {
  readonly type: typeof CONFIRM_SCHEDULED_PAYMENT_REQUEST;
};

export type ConfirmScheduledPaymentSuccess = {
  readonly type: typeof CONFIRM_SCHEDULED_PAYMENT_SUCCESS;
  readonly payload: {
    readonly authType: AuthType;
  };
};

export type ConfirmScheduledPaymentFailure = {
  readonly type: typeof CONFIRM_SCHEDULED_PAYMENT_FAILURE;
};

export type CancelScheduledPaymentRequest = {
  readonly type: typeof CANCEL_SCHEDULED_PAYMENT_REQUEST;
};

export type CancelScheduledPaymentSuccess = {
  readonly type: typeof CANCEL_SCHEDULED_PAYMENT_SUCCESS;
};

export type CancelScheduledPaymentFailure = {
  readonly type: typeof CANCEL_SCHEDULED_PAYMENT_FAILURE;
};

export type CancelScheduledPaymentsBatchRequest = {
  readonly type: typeof CANCEL_SCHEDULED_PAYMENTS_BATCH_REQUEST;
};

export type CancelScheduledPaymentsBatchSuccess = {
  readonly type: typeof CANCEL_SCHEDULED_PAYMENTS_BATCH_SUCCESS;
};

export type CancelScheduledPaymentsBatchFailure = {
  readonly type: typeof CANCEL_SCHEDULED_PAYMENTS_BATCH_FAILURE;
};

export type DownloadCsvBatchDetailsRequest = {
  readonly type: typeof DOWNLOAD_CSV_BATCH_DETAILS_REQUEST;
};

export type DownloadCsvBatchDetailsSuccess = {
  readonly type: typeof DOWNLOAD_CSV_BATCH_DETAILS_SUCCESS;
};

export type DownloadCsvBatchDetailsFailure = {
  readonly type: typeof DOWNLOAD_CSV_BATCH_DETAILS_FAILURE;
};

export type ExpenseClaimsActions =
  | FetchExpenseClaimsCountsRequest
  | FetchExpenseClaimsCountsSuccess
  | FetchExpenseClaimsCountsFailure
  | UpdateExpenseClaimsCounts
  | FetchUsersScheduledPaymentsRequest
  | FetchUsersScheduledPaymentsSuccess
  | FetchUsersScheduledPaymentsFailure
  | ResetUsersScheduledPayments
  | FetchUserScheduledPaymentsRequest
  | FetchUserScheduledPaymentsSuccess
  | ResetOpenedUserScheduledPayments
  | ToggleUserScheduledPaymentsSelection
  | SendToPaymentRequest
  | SendToPaymentSuccess
  | SendToPaymentFailure
  | DownloadCsvRequest
  | DownloadCsvSuccess
  | DownloadCsvFailure
  | SendMissingBankInfoRemindersRequest
  | SendMissingBankInfoRemindersSuccess
  | SendMissingBankInfoRemindersFailure
  | FetchScheduledPaymentsBatchesRequest
  | FetchScheduledPaymentsBatchesSuccess
  | FetchScheduledPaymentsBatchesFailure
  | ClearScheduledPaymentsBatches
  | RefreshOneScheduledPaymentsBatchRequest
  | RefreshOneScheduledPaymentsBatchRemove
  | RefreshOneScheduledPaymentsBatchUpdate
  | RefreshOneScheduledPaymentsBatchFailure
  | FetchScheduledPaymentsByBatchIdRequest
  | FetchScheduledPaymentsByBatchIdSuccess
  | FetchScheduledPaymentsByBatchIdFailure
  | FetchScheduledPaymentRequest
  | FetchScheduledPaymentSuccess
  | FetchScheduledPaymentFailure
  | ResetOpenedScheduledPayment
  | ConfirmScheduledPaymentsBatchRequest
  | ConfirmScheduledPaymentsBatchSuccess
  | ConfirmScheduledPaymentsBatchFailure
  | ConfirmScheduledPaymentRequest
  | ConfirmScheduledPaymentSuccess
  | ConfirmScheduledPaymentFailure
  | CancelScheduledPaymentRequest
  | CancelScheduledPaymentSuccess
  | CancelScheduledPaymentFailure
  | CancelScheduledPaymentsBatchRequest
  | CancelScheduledPaymentsBatchSuccess
  | CancelScheduledPaymentsBatchFailure
  | DownloadCsvBatchDetailsRequest
  | DownloadCsvBatchDetailsSuccess
  | DownloadCsvBatchDetailsFailure
  | ResetScheduledPaymentsConfirmationAuthType;
