import React from 'react';

import { type IntegrationId } from "src/core/modules/integrations/types";
import { isIntegrationWithAutomaticallyCollectedReceipt } from "src/core/modules/integrations/utils";
import { type PayableId } from "src/core/modules/payable";

import { TravelperkReceipt } from './integrations';

type Props = {
  integrationId: IntegrationId;
  payableId: PayableId;
};

export const AutomaticallyCollectedReceipt = (props: Props) => {
  const { integrationId, payableId } = props;

  if (!isIntegrationWithAutomaticallyCollectedReceipt(integrationId)) {
    return null;
  }

  switch (integrationId) {
    case 'travelperk':
      return <TravelperkReceipt payableId={payableId} />;
    // eslint-disable-next-line unicorn/no-useless-switch-case
    case 'spendesk-oauth2':
    default:
      return null;
  }
};
