const MARQETA_CLIENT_SCRIPT_ID = 'spx-marqeta-client-script-v2';

export const loadMarqetaScriptV2 = () => {
  return new Promise<void>((resolve, reject) => {
    if (document.getElementById(MARQETA_CLIENT_SCRIPT_ID)) {
      return resolve();
    }

    const script = document.createElement('script');
    script.id = MARQETA_CLIENT_SCRIPT_ID;

    let source = 'https://widgets.marqeta.com/marqetajs/2.0.0/marqeta.min.js';

    const developmentEnvironmentRegex =
      /.*(?:\.linc-preview\.sh|\.spendesk\.dev)/;

    // direct all development environments towards the sandbox
    if (
      developmentEnvironmentRegex.test(window.location.host) ||
      window.location.host === 'demo.spendesk.com'
    ) {
      source =
        'https://widgets-sandbox.marqeta.com/marqetajs/2.0.0/marqeta.min.js';
    }

    script.src = source;
    script.async = true;
    document.body.append(script);
    script.addEventListener('load', () => resolve());
    script.addEventListener('error', (error) => reject(error));
  });
};
