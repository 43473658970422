import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import { useCardsAccessQuery } from './useCardsAccessQuery';
import { useCheckOverduePaymentsQuery } from './useCheckOverduePaymentsQuery';
import { useWalletDetailsQuery } from './useWalletDetailsQuery';
import { getHasWalletWarning } from '../../utils';
import type { SwitchButtonIcon } from '../components/WelcomePanel/components/SwitchButton';

export const useSwitchButtonIcon = ({
  displayMode,
}: {
  displayMode: 'controller' | 'requester' | undefined;
}): SwitchButtonIcon | null => {
  const company = useCompany();
  const user = useUser();
  const cardsAccessQueryState = useCardsAccessQuery();
  const walletDetailsQueryState = useWalletDetailsQuery(user);
  const checkOverduePaymentsQueryState = useCheckOverduePaymentsQuery();

  if (
    displayMode === 'controller' &&
    cardsAccessQueryState.status === 'success' &&
    checkOverduePaymentsQueryState.status === 'success'
  ) {
    if (!cardsAccessQueryState.data.hasAccess) {
      return cardsAccessQueryState.data.initiator === 'controlRuleProcess'
        ? 'warningRed'
        : 'cardBlocked';
    }
    if (checkOverduePaymentsQueryState.data.hasOverduePayments) {
      return 'warningRed';
    }
  }

  if (
    displayMode === 'requester' &&
    walletDetailsQueryState.status === 'success' &&
    getHasWalletWarning(company, walletDetailsQueryState.data)
  ) {
    return 'warningOrange';
  }

  return null;
};
