import {
  GridContainer,
  GridItem,
  SkeletonText,
  SkeletonTable,
} from '@dev-spendesk/grapes';
import React from 'react';

export const ProfilePageSkeleton = () => {
  return (
    <GridContainer className="mt-xl h-full">
      <GridItem columnStartLine={0} columnSpan={3} className="pr-s">
        <SkeletonText size="l" className="m-s mt-0" />
        <SkeletonText size="l" className="m-s" />
        <SkeletonText size="l" className="m-s" />
        <SkeletonText size="l" className="m-s" />
      </GridItem>
      <GridItem columnSpan={9}>
        <div className="mb-s flex flex-col">
          <SkeletonText size="xl" width="30%" />
          <SkeletonText width="80%" className="mt-s" />
        </div>
        <SkeletonTable
          columns={[
            {
              cell: <SkeletonText width="80%" />,
              header: <SkeletonText width="50%" />,
            },
          ]}
          numberOfRows={5}
          withHeader
        />
      </GridItem>
    </GridContainer>
  );
};
