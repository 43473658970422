import {
  Callout,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Badge,
} from '@dev-spendesk/grapes';
import React, { useCallback, useMemo, useState } from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { useMembersTableDataQuery } from './hooks/useMembersTableDataQuery';
import { MembersPaginatedTable } from '../../components/MembersPaginatedTable';
import { MembersTable } from '../../components/MembersTable';
import { MembersTableHeader } from '../../components/MembersTableHeader/MembersTableHeader';
import { MembersTableLoader } from '../../components/MembersTableLoader';
import { MembersTableManagersInformation } from '../../components/MembersTableManagersInformation/MembersTableManagersInformation';
import { useArchivedMembersQuery } from '../../hooks/useArchivedMembersQuery';
import { useGetCompanyManagedByKomboHrIntegrationQuery } from '../../hooks/useGetCompanyManagedByKomboHrIntegrationQuery';
import { useGetManagedUsersByKomboHrIntegrationQuery } from '../../hooks/useGetManagedUsersByKomboHrIntegrationQuery';
import { useMembersCardsAccessQuery } from '../../hooks/useMembersCardsAccessQuery';
import { useRemainingSeats } from '../../hooks/useRemainingSeats';
import { useUsersCountLimitFeatures } from '../../hooks/useUsersCountLimitFeatures';
import { type CardsAccess } from '../../models/cardsAccess';
import { type MemberTableRow, type Member } from '../../models/member';
import './MembersTableContainer.css';

type Props = {
  onInviteClick(): void;
  onMemberClick(member: { id: string }): void;
  onMemberEditClick(member: { id: string }): void;
  onBulkEditClick(members: Member[]): void;
};

export const MembersTableContainer = ({
  onInviteClick,
  onMemberClick,
  onMemberEditClick,
  onBulkEditClick,
}: Props) => {
  const membersTableDataQueryState = useMembersTableDataQuery();
  const { t } = useTranslation('global');

  // This hook can not go inside useMembersTableDataQuery because it is conditional,
  // and it would cause QuerySuspense to be on loading state :(
  const archivedMembersQueryState = useArchivedMembersQuery();

  const archivedMembers = unwrapQuery(archivedMembersQueryState) ?? [];

  return (
    <QuerySuspense
      queryState={membersTableDataQueryState}
      loading={<MembersTableLoader />}
      fallback={() => (
        <Callout variant="alert" title={t('members.table.error')} />
      )}
    >
      {({ members }) => (
        <MembersTableContainerWithMembers
          members={members}
          onInviteClick={onInviteClick}
          onMemberClick={onMemberClick}
          onMemberEditClick={onMemberEditClick}
          onBulkEditClick={onBulkEditClick}
          archivedMembers={archivedMembers}
        />
      )}
    </QuerySuspense>
  );
};

type TabState = 'active' | 'pending' | 'archived';
const MembersTableContainerWithMembers = ({
  onInviteClick,
  members,
  onMemberClick,
  onMemberEditClick,
  onBulkEditClick,
  archivedMembers,
}: {
  onInviteClick(): void;
  onMemberClick(member: { id: string }): void;
  onMemberEditClick(member: { id: string }): void;
  members: Member[];
  onBulkEditClick(members: Member[]): void;
  archivedMembers: MemberTableRow[];
}) => {
  const { t } = useTranslation('global');
  const membersCardsAccessQueryState = useMembersCardsAccessQuery(
    members.map((member) => member.id),
  );

  const membersWithCardAccess =
    membersCardsAccessQueryState.status === 'success' &&
    membersCardsAccessQueryState.data.length > 0
      ? enrichMembersWithCardAccess(members, membersCardsAccessQueryState.data)
      : undefined;

  const isCompanyManagedByIntegrationQueryState =
    useGetCompanyManagedByKomboHrIntegrationQuery();
  const isCompanyManagedByIntegration =
    unwrapQuery(isCompanyManagedByIntegrationQueryState)?.isManagedByKombo ??
    false;

  // TODO: Change naming from users to member in separate PR.
  const usersManagedByKomboHrIntegrationQuery =
    useGetManagedUsersByKomboHrIntegrationQuery();
  const usersManagedByIntegration = unwrapQuery(
    usersManagedByKomboHrIntegrationQuery,
  );
  const hasUsersCountLimitFeature = useUsersCountLimitFeatures();

  const [searchPattern, setSearchPattern] = useState('');
  const [selectedTab, setSelectedTab] = useState<TabState>('active');
  const [selectedIds, setSelectedIds] = useState<{
    active: Record<string, boolean>;
    pending: Record<string, boolean>;
  }>({
    active: {},
    pending: {},
  });

  const membersCount = members.length;
  const remainSeats = useRemainingSeats(membersCount);

  // filter by status and name
  const membersByState = useMemo<{
    active: Member[];
    pending: Member[];
    archived: MemberTableRow[];
  }>(() => {
    const active = [] as Member[];
    const pending = [] as Member[];
    const archived = [] as MemberTableRow[];

    const membersList = membersWithCardAccess ?? members;

    pending.push(
      ...membersList.filter(
        (m) => m.isPending && filterMember(m, searchPattern),
      ),
    );
    active.push(
      ...membersList.filter(
        (m) => !m.isPending && filterMember(m, searchPattern),
      ),
    );

    archived.push(
      ...archivedMembers.filter((m) => filterArchivedMember(m, searchPattern)),
    );

    return { active, pending, archived };
  }, [members, membersWithCardAccess, searchPattern, archivedMembers]);

  const selectedMembers =
    selectedTab !== 'archived'
      ? membersByState[selectedTab].filter(
          (member) => selectedIds[selectedTab][member.id],
        )
      : [];
  const onRowSelectionChangePending = useCallback(
    (_: Member, id: string, checked: boolean) => {
      setSelectedIds((options) => ({
        ...options,
        pending: {
          ...options.pending,
          [id]: checked,
        },
      }));
    },
    [],
  );

  const onAllRowsSelectionChangePending = useCallback(
    (_: Member[], ids: string[], checked: boolean) => {
      setSelectedIds((options) => ({
        ...options,
        pending: checked
          ? ids.reduce(
              (accumulator, id) => ({ ...accumulator, [id]: true }),
              {},
            )
          : {},
      }));
    },
    [],
  );

  const onRowSelectionChangeActive = useCallback(
    (_: Member, id: string, checked: boolean) => {
      setSelectedIds((options) => ({
        ...options,
        active: {
          ...options.active,
          [id]: checked,
        },
      }));
    },
    [],
  );

  const onAllRowsSelectionChangeActive = useCallback(
    (_: Member[], ids: string[], checked: boolean) => {
      setSelectedIds((options) => ({
        ...options,
        active: checked
          ? ids.reduce(
              (accumulator, id) => ({ ...accumulator, [id]: true }),
              {},
            )
          : {},
      }));
    },
    [],
  );

  return (
    <>
      <MembersTableHeader
        membersCount={membersCount}
        canInviteMembers={
          !hasUsersCountLimitFeature ||
          (remainSeats !== undefined && remainSeats > 0)
        }
        searchPattern={searchPattern}
        setSearchPattern={setSearchPattern}
        selectedMembersCount={selectedMembers.length}
        onInviteClick={onInviteClick}
        onEditMembersClick={() => {
          if (selectedMembers.length === 1) {
            return onMemberEditClick({ id: selectedMembers[0].id });
          }

          onBulkEditClick(selectedMembers);
        }}
      />
      <MembersTableManagersInformation activeMembers={membersByState.active} />
      <Tabs
        onChange={(id) => {
          switch (id) {
            case 0: {
              setSelectedTab('active');
              break;
            }
            case 1: {
              setSelectedTab('pending');
              break;
            }
            case 2: {
              setSelectedTab('archived');
              break;
            }
            default: {
              setSelectedTab('active');
            }
          }
        }}
      >
        <TabList className="MembersTabList">
          <Tab className="MembersTab">
            {t('members.table.active')}
            <Counter
              count={membersByState.active.length}
              active={selectedTab === 'active'}
            />
          </Tab>
          <Tab className="MembersTab">
            {t('members.table.pending')}
            <Counter
              count={membersByState.pending.length}
              active={selectedTab === 'pending'}
            />
          </Tab>
          {isCompanyManagedByIntegration && (
            <Tab className="MembersTab">
              {t('members.table.archived')}
              <Counter
                count={membersByState.archived.length}
                active={selectedTab === 'archived'}
              />
            </Tab>
          )}
        </TabList>
        <TabPanels>
          <TabPanel>
            <MembersTable
              isPending={false}
              members={membersByState.active}
              onMemberClick={onMemberClick}
              selectedIds={Object.entries(selectedIds.active)
                .filter(([_, isSelected]) => isSelected)
                .map(([id]) => id)}
              onRowSelectionChange={onRowSelectionChangeActive}
              onAllRowsSelectionChange={onAllRowsSelectionChangeActive}
              usersManagedByIntegration={usersManagedByIntegration}
            />
          </TabPanel>
          <TabPanel>
            <MembersTable
              isPending
              members={membersByState.pending}
              onMemberClick={onMemberClick}
              selectedIds={Object.entries(selectedIds.pending)
                .filter(([_, isSelected]) => isSelected)
                .map(([id]) => id)}
              onRowSelectionChange={onRowSelectionChangePending}
              onAllRowsSelectionChange={onAllRowsSelectionChangePending}
              usersManagedByIntegration={usersManagedByIntegration}
            />
          </TabPanel>
          {isCompanyManagedByIntegration && ( // remove check when archive is for everyone
            <TabPanel>
              <MembersPaginatedTable
                members={membersByState.archived}
                isCompanyManagedByIntegration={isCompanyManagedByIntegration}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};

const Counter = ({ count, active }: { count: number; active: boolean }) => {
  return <Badge variant={active ? 'primary' : 'secondary'}>{count}</Badge>;
};

const filterArchivedMember = (
  member: MemberTableRow,
  searchPattern: string,
) => {
  if (member.id === searchPattern) {
    return true;
  }

  return `${member.firstName} ${member.lastName} ${member.deletedEmail}`
    .toLowerCase()
    .includes(searchPattern.toLowerCase());
};

const filterMember = (member: Member, searchPattern: string) => {
  if (member.id === searchPattern) {
    return true;
  }

  return `${member.displayName} ${member.email}`
    .toLowerCase()
    .includes(searchPattern.toLowerCase());
};

const enrichMembersWithCardAccess = (
  members: Member[],
  cardAccesses: CardsAccess[],
): Member[] => {
  return members.map((member) => ({
    ...member,
    cardsAccess: cardAccesses.find(
      (cardAccess) => cardAccess.userId === member.id,
    ),
  }));
};
