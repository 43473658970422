import { isBefore } from 'date-fns';
import i18next from 'i18next';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { intersection } from './array';
import { type CustomFieldDefinition } from '../modules/budgets/models/customFieldDefinition';

const TOO_MANY_VALUES_THRESHOLD = 200;

export const userCanCreateCfValue = (
  user: {
    is_requester: boolean;
    is_controller: boolean;
    is_admin: boolean;
  },
  cf: Pick<CustomFieldDefinition, 'perms_add_values'>,
) => {
  if (!isObject(user) || !isObject(cf)) {
    return false;
  }
  const { perms_add_values } = cf;
  const { is_requester, is_controller, is_admin } = user;
  return (
    (is_requester && includes(perms_add_values, '05_002_0')) || // requesters can create values
    (is_controller && includes(perms_add_values, '07_004_0')) || // controllers can create values
    (is_admin && includes(perms_add_values, '03_006_2'))
  ); // admins can create values
};

export const userCanSeeCf = (
  user?: {
    is_requester?: boolean;
    is_controller?: boolean;
    is_admin?: boolean;
  },
  cf?: {
    perms_visibility?: string[];
  },
) => {
  if (!isObject(user) || !isObject(cf)) {
    return false;
  }
  const { perms_visibility } = cf;
  const { is_requester, is_controller, is_admin } = user;
  return (
    (is_requester && includes(perms_visibility, '05_002_0')) || // requesters can create values
    (is_controller && includes(perms_visibility, '07_004_0')) || // controllers can create values
    (is_admin && includes(perms_visibility, '03_006_2'))
  ); // admins can create values
};

// Get eligible custom fields depending on their type, teams and the current user
// Also skip deleted custom fields if the payment or request occurs after this deletion
export const getEligibleCustomFields = <
  T extends {
    deleted_at?: string | Date | null;
    eligible_types?: { type: string }[];
    total_values?: number;
    is_all_scopes?: boolean;
    scopes?: { entity_type: string; entity_id: string }[];
    name?: string;
    perms_visibility?: string[];
  },
>(
  customFields?: T[],
  options?: {
    types?: string[];
    date?: Date;
    teamIds?: (string | undefined)[];
    all?: boolean;
    user?: {
      is_requester?: boolean;
      is_controller?: boolean;
      is_admin?: boolean;
    };
  },
): T[] => {
  const { types, date = new Date(), teamIds = [], all, user } = options ?? {};

  const cfs = compact(
    filter(customFields, (cf) => {
      // Custom field is deleted before
      if (cf.deleted_at && isBefore(new Date(cf.deleted_at), date)) {
        return false;
      }
      // Custom field is not eligible for type
      if (
        intersection(
          map(cf.eligible_types, ({ type }) => type),
          types ?? [],
        ).length === 0
      ) {
        return false;
      }
      // Not eligible if no values
      if (cf.total_values === 0) {
        return false;
      }
      // User can't see the custom field
      if (!userCanSeeCf(user, cf)) {
        return false;
      }
      // Wanna see all custom fields eligible for entity type
      if (all) {
        return true;
      }
      // Custom field applies to all team or team id not provided
      if (cf.is_all_scopes) {
        return true;
      }
      // Only all scope custom fields
      if (isNil(teamIds)) {
        return false;
      }
      // Custom field applies to the payment team
      const cfTeamsScopes = map(
        filter(cf.scopes, ({ entity_type }) => entity_type === 'team'),
        ({ entity_id }) => entity_id,
      );

      return !isEmpty(intersection(cfTeamsScopes, compact(teamIds)));
    }),
  );

  return sortBy(cfs, (cf) => !cf.deleted_at && cf.name);
};

export const getCfLabel = (customField: {
  name: string;
  deleted_at: string | Date | null;
}) =>
  `${customField.name}${
    customField.deleted_at
      ? ` (${i18next.t('global:customFields.noLongerUsed')})`
      : ''
  }`;

export const isCfBoolean = (customField: { type: 'list' | 'boolean' }) =>
  customField.type === 'boolean';

export const prettyCfValue = (
  customField: { type: 'list' | 'boolean' },
  cfv: { value: string },
) => {
  if (!cfv) {
    return '';
  }
  return isCfBoolean(customField)
    ? prettyBooleanCustomFieldValue(cfv.value)
    : cfv.value;
};

const formatCustomFieldValue = (
  customFieldValue: { id: string; value: string },
  customField: { type: 'list' | 'boolean' },
) => ({
  key: customFieldValue.id,
  name: prettyCfValue(customField, customFieldValue),
});

export const getValuesFromCustomField = (customField: {
  type: 'list' | 'boolean';
  custom_fields_values: { id: string; value: string }[];
}) =>
  map(customField.custom_fields_values, (cfv) =>
    formatCustomFieldValue(cfv, customField),
  );

export const getCurrentCustomFieldValue = (
  customField: { type: 'list' | 'boolean' },
  entityCf:
    | {
        id: string;
        value: {
          id: string;
          value: string;
        };
      }
    | undefined,
) => {
  if (!entityCf) {
    return null;
  }
  if (!get(entityCf, 'value.value')) {
    return null;
  }
  return {
    id: entityCf.value.id,
    name: prettyCfValue(customField, entityCf.value),
  };
};

const hasTooManyValues = (
  customField:
    | {
        totalValues?: number;
      }
    | {
        total_values?: number;
      },
) => {
  return (
    get(customField, 'totalValues', 0) > TOO_MANY_VALUES_THRESHOLD ||
    get(customField, 'total_values', 0) > TOO_MANY_VALUES_THRESHOLD
  );
};

/**
 * @param entity Can be a request or a payment
 */
export const attachValuesToCustomFields = (
  customFields: {
    id?: string;
    deleted_at?: null | string;
  }[],
  customFieldsInEntity: {
    id?: string;
    field: {
      id: string;
      deleted_at?: null | string;
    };
    value: {
      id: string;
      value: string;
    };
  }[],
): {
  field: { id?: string };
  value: { id: string; value: string };
}[] => {
  const eligibleCustomFieldsWithValue = map(customFields, (field) => ({
    field,
    value: {} as { id: string; value: string },
  }));
  forEach(customFieldsInEntity, ({ field, value }) => {
    const matchingFieldWithValue = find(
      eligibleCustomFieldsWithValue,
      (object) => object.field.id === field.id,
    );
    if (matchingFieldWithValue) {
      matchingFieldWithValue.value = value;
    } else {
      eligibleCustomFieldsWithValue.push({
        field,
        value,
      });
    }
  });

  return filter(
    eligibleCustomFieldsWithValue,
    // Remove delete custom fields except those that are set for the entity
    ({ field, value }) => !field.deleted_at || !isEmpty(value),
  );
};

export const prettyBooleanCustomFieldValue = (cfValue: string) => {
  const value = Number(cfValue);

  if (isNaN(value)) {
    if (includes(['yes', 'no'], cfValue?.toLowerCase())) {
      return cfValue?.toLowerCase() === 'yes'
        ? i18next.t('global:misc.yes')
        : i18next.t('global:misc.no');
    }
    return cfValue;
  }

  return value === 1
    ? i18next.t('global:misc.yes')
    : i18next.t('global:misc.no');
};

/**
 * Returns a list of entity CFs to display,
 * for instance in a `ReadOnlyFieldsValues` component
 * The return values is a simple list of CF name / CF value objects
 *
 * @param  {Array} eligibleCustomFields The entity's eligible CFs
 * @param  {Array} entityCustomFields   The entity's CFs mappings
 * @return {Array}                      List of CFs associations to show
 */
export const getDisplayCustomFieldsValues = (
  eligibleCustomFields: CustomFieldDefinition[],
  entityCustomFields: {
    field: { id: string };
  }[],
) => {
  return compact(
    map(eligibleCustomFields, (cf) => {
      const matchingCf = find(
        entityCustomFields,
        ({ field: { id } }) => cf.id === id,
      );
      if (!cf.deleted_at || !isNil(get(matchingCf, 'value.value'))) {
        return {
          key: cf.name,
          value: matchingCf
            ? prettyCfValue(cf, {
                value: String(get(matchingCf, 'value.value')),
              })
            : '-',
        };
      }
      return null;
    }),
  );
};

/*
 * Transform nested entity CF data structure to flat, server-understandable format:
 *   input:  [{ field: { id: 12 }, value: { id: 45, value: 'foo' } }]
 *   output: [{ customFieldId: 12, customFieldValueId: 45, value: 'foo' }]
 */
export const harmonizeCustomFieldsAssociations = (
  customFieldsAssociations:
    | {
        field: {
          id: string;
        };
        value: {
          id: string | null;
          value: string;
        };
      }[]
    | undefined,
) => {
  if (!customFieldsAssociations) {
    return null;
  }
  return map(customFieldsAssociations, (association) => ({
    customFieldId: get(association, 'field.id'),
    customFieldValueId: get(association, 'value.id'),
    value: get(association, 'value.value'),
    hasMultipleMatchingValues: get(association, 'isMultipleValue', false),
  }));
};

export const shouldUseAsyncCustomFieldAutocomplete = (
  customField:
    | {
        type: 'list' | 'boolean';
        totalValues?: number;
      }
    | {
        type: 'list' | 'boolean';
        total_values?: number;
      },
) => {
  return !isCfBoolean(customField) && hasTooManyValues(customField);
};
