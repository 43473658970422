import classNames from 'classnames';
import React from 'react';

export function MemberDetailsRow({
  label,
  children,
  containerClassNames = '',
}: {
  label: string;
  children: React.ReactNode;
  containerClassNames?: string;
}) {
  return (
    <div className="MemberDetailsModal__row">
      <div className="MemberDetailsModal__row__label body-m">{label}</div>
      <div
        className={classNames(
          'MemberDetailsModal__row__valueContainer',
          containerClassNames,
        )}
      >
        {children}
      </div>
    </div>
  );
}
