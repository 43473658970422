import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type DefaultSupplierAccount } from '../../accounting';

export type RawData = {
  supplierAccountForCard: DefaultSupplierAccount | undefined;
  supplierAccountForInvoice: DefaultSupplierAccount | undefined;
};

export type Result = RawData;

export const useGetDefaultSupplierAccountQueryKey =
  'useGetDefaultSupplierAccountQuery';

export const useGetDefaultSupplierAccountQuery = (
  { isEnabled } = { isEnabled: true },
): QueryState<Result> => {
  const { company: companyId } = useParams();

  return useQuery<Result, RawData>({
    key: [useGetDefaultSupplierAccountQueryKey, companyId],
    isEnabled,
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint:
        '/accounting-integration/chart-of-accounts/supplier-accounts/default',
    },
    reshapeData: (data) => {
      // TODO remove the deletes after deleting `code` from the REST API
      if (data.supplierAccountForCard && data.supplierAccountForCard.code) {
        delete data.supplierAccountForCard.code;
      }
      if (
        data.supplierAccountForInvoice &&
        data.supplierAccountForInvoice.code
      ) {
        delete data.supplierAccountForInvoice.code;
      }

      return data;
    },
  });
};
