import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { TransferToMemberAutocompleteContainer } from '../../containers/TransferToMemberAutocompleteContainer';
import { getMemberName, type Member } from '../../models/member';

type Props = {
  onCancel: () => void;
  onSubmit: (transferringMemberId: string | undefined) => void;
  member: Member;
  companyName: string;
};

export const MemberDeleteModal = ({
  onCancel,
  onSubmit,
  member,
  companyName,
}: Props) => {
  const { t } = useTranslation('global');
  const [transferringMemberId, setTransferringMemberId] = useState<
    string | undefined
  >();
  const [
    noTransferringMemberIdSelectedError,
    setNoTransferringMemberIdSelectedError,
  ] = useState<string | undefined>();

  return (
    <Modal
      title={t('members.deleteModal.title', {
        name: getMemberName(member),
        companyName,
      })}
      actions={[
        <Button
          text={t('members.deleteModal.buttonCancel')}
          variant="secondary"
          onClick={onCancel}
          key="cancel"
        />,
        <Button
          text={t('members.deleteModal.buttonSubmit')}
          variant="alert"
          onClick={() => {
            if (!transferringMemberId && member.subscriptionsCount > 0) {
              setNoTransferringMemberIdSelectedError(
                t('members.deleteModal.transferringMemberError'),
              );
            } else {
              onSubmit(transferringMemberId);
              onCancel();
            }
          }}
          key="submit"
        />,
      ]}
      isOpen
      iconName="warning"
      iconVariant="alert"
    >
      <div className="text-left">
        <Callout
          variant="alert"
          iconName="warning"
          title={t('members.deleteModal.warning', {
            name: getMemberName(member),
            companyName,
          })}
        />
        <div className="mt-m text-neutral-darker">
          <div className="title-m">{t('members.deleteModal.noteTitle')}</div>
          <ul className="mt-s ml-s list-disc">
            <li className="mb-xs">
              {t('members.deleteModal.note1', { companyName })}
            </li>
            <li>{t('members.deleteModal.note2')}</li>
          </ul>
        </div>
        {member.subscriptionsCount > 0 && (
          <div className="mt-l">
            <TransferToMemberAutocompleteContainer
              onSelectMemberId={setTransferringMemberId}
              toDeleteMember={member}
              error={noTransferringMemberIdSelectedError}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
