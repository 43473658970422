import { Icon, colors } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { type KybStatusCode } from 'modules/kyc/models/kybStatus';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './KycTimeline.module.css';
import { KybStatusContainer } from '../KybStatus';

type Props = {
  kybStatusCode: KybStatusCode;
};

export const KycTimeline = ({ kybStatusCode }: Props) => {
  const { t } = useTranslation('global');

  const isActionRequired = [
    'information-needed',
    'signature-requested',
  ].includes(kybStatusCode);

  return (
    <ErrorBoundary context={{ scope: 'kyc-timeline', team: 'kyc' }}>
      <div className="box p-0">
        <h2 className={cx('p-m text-complementary title-xl', styles.header)}>
          {t('homepage.kycStatusProgression.titles.inReview')}
        </h2>

        <div className="gap-m p-m flex flex-col">
          <div className="gap-xs flex flex-col">
            <div className="flex align-bottom">
              <p className="flex-1 text-left text-complementary body-s">
                {t('homepage.kycStatusProgression.steps.submitted')}
              </p>
              <p className="flex-1 text-center text-complementary body-s">
                {t('homepage.kycStatusProgression.steps.inReview')}
              </p>
              <p className="flex-1 text-right text-neutral-dark body-s">
                {t('homepage.kycStatusProgression.steps.completed')}
              </p>
            </div>
            <div className="gap-xxs flex items-center">
              <Icon
                name="success"
                size="m"
                color={isActionRequired ? colors.warning : colors.success}
              />
              <div
                className={cx(styles.line, {
                  [styles.lineWarning]: isActionRequired,
                })}
              />
              <Icon
                name={isActionRequired ? 'warning' : 'success'}
                size="m"
                color={isActionRequired ? colors.warning : colors.success}
              />
              <div className={cx(styles.line, styles.linePending)} />
              <Icon name="success" size="m" color={colors.neutral} />
            </div>
          </div>
          <KybStatusContainer />
        </div>
      </div>
    </ErrorBoundary>
  );
};
