import { ListItem } from '@dev-spendesk/grapes';
import React from 'react';

import { type UserNotification } from '../../models';
import { UserNotificationSetting } from '../UserNotificationSetting';

type Props = {
  userNotifications: UserNotification[];
};

export const UserNotifications = ({ userNotifications }: Props) => {
  return (
    <>
      {userNotifications.map((notification) => (
        <ListItem key={notification.notifCode}>
          <UserNotificationSetting notification={notification} />
        </ListItem>
      ))}
    </>
  );
};
