import { Button, EmptyState } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type ExpenseAccountEmptyStateProps = {
  manualAction: () => void;
  importAction: () => void;
};

export function ExpenseAccountEmptyState({
  manualAction,
  importAction,
}: ExpenseAccountEmptyStateProps) {
  const { t } = useTranslation('global');

  return (
    <EmptyState
      illustration={
        <img
          src="/static/img/icons/calculator.svg"
          alt={t('accounting.expenseAccounts.emptyState.emptyImage')}
        />
      }
      title={t('accounting.expenseAccounts.emptyState.title')}
      subtitle={t('accounting.expenseAccounts.emptyState.body')}
      actions={[
        <Button
          key="importButton"
          variant="primary"
          onClick={importAction}
          text={t('accounting.expenseAccounts.emptyState.importAction')}
        />,
        <Button
          key="addButton"
          variant="ghost"
          onClick={manualAction}
          text={t('accounting.expenseAccounts.emptyState.manualAction')}
        />,
      ]}
    />
  );
}
