import { type FormikErrors, validateYupSchema, yupToFormErrors } from 'formik';
import * as yup from 'yup';

export type AddressFormValues = {
  address: string;
  address2: string;
  city: string;
  country: string;
  name: string;
  zipcode: string;
};

const addressSchema = yup.object().shape({
  address: yup.string().required().trim(),
  address2: yup.string().optional().trim(),
  city: yup.string().required().trim(),
  country: yup.string().required().trim(),
  name: yup.string().required().trim(),
  zipcode: yup.string().required().trim(),
});

export const validateAddress = async (
  values: AddressFormValues,
): Promise<FormikErrors<AddressFormValues>> => {
  try {
    await validateYupSchema(values, addressSchema);
    return {};
  } catch (error) {
    return yupToFormErrors<AddressFormValues>(error);
  }
};
