import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export type CardLoad = {
  id: string;
  amount: number;
  created_at: string;
  by_user: string;
  by_user_id: string;
  request: {
    appraisals: {
      approver_id: string;
      approver: {
        fullname: string;
      };
    }[];
    auto_approved: boolean;
  };
};

export const useCardLoadsQuery = (cardId: string): QueryState<CardLoad[]> => {
  return useQuery<CardLoad[], CardLoad[]>({
    key: ['cards', cardId, 'loads'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/cards/${cardId}/loads`,
    },
    reshapeData: (data) => data,
  });
};
