import { useQueryClient } from 'react-query';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export type UseScreeningInformationStatusQueryReponse = {
  status: 'pending' | 'collected' | 'disabled';
};

export const useScreeningInformationStatusQuery = ({
  userId,
}: {
  userId: string;
}): QueryState<UseScreeningInformationStatusQueryReponse> => {
  return useQuery({
    key: ['users', userId, 'screening-information-status'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users/${userId}/screening-information-status`,
    },
    options: {
      staleTime: 60_000,
      cacheTime: 60_000,
    },
  });
};

export const useInvalidateScreeningInformationStatusQuery = (
  userId: string,
) => {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.refetchQueries([
      'users',
      userId,
      'screening-information-status',
    ]);
  };
};
