import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';

import { useMemberTableRowsQuery } from './hooks/useMemberTableRowsQuery';
import { MembersPaginatedTable } from '../../components/MembersPaginatedTable';
import { MembersPaginatedTableLoader } from '../../components/MembersTableLoader/MembersPaginatedTableLoader';

type Props = {
  selectedTab: TabState;
  searchPattern: string;
  onMemberClick(member: { id: string }): void;
};

export type TabState = 'active' | 'pending';

export const MembersPaginatedTableContainer = ({
  onMemberClick,
  selectedTab,
  searchPattern,
}: Props) => {
  const memberTableRowsQueryState = useMemberTableRowsQuery(
    searchPattern.toLowerCase(),
    selectedTab === 'active' ? 'active' : 'pending',
  );
  const { t } = useTranslation('global');

  return (
    <QuerySuspense
      queryState={memberTableRowsQueryState}
      loading={<MembersPaginatedTableLoader />}
      fallback={() => (
        <Callout variant="alert" title={t('members.table.error')} />
      )}
    >
      {(memberRows) => (
        <MembersPaginatedTable
          members={memberRows}
          onMemberClick={onMemberClick}
        />
      )}
    </QuerySuspense>
  );
};
