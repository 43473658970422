import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

import { useDeleteBankInfoMutation } from '../../../hooks/useDeleteBankInfoMutation';
import { useUpdateBankInfoMutation } from '../../../hooks/useUpdateBankInfoMutation';
import { type Member } from '../../../models/member';

export const useEditBankInfoMutation = (memberId: string) => {
  const [updateBankInfo] = useUpdateBankInfoMutation(memberId);
  const [deleteBankInfo] = useDeleteBankInfoMutation(memberId);
  return [
    async (
      oldBankInfo: Member['bankInfo'],
      newBankInfo: NonNullable<Member['bankInfo']>,
    ) => {
      if (
        isEqual(oldBankInfo, newBankInfo) ||
        (isNil(oldBankInfo) && isEmpty(newBankInfo))
      ) {
        return;
      }
      if (Object.values(newBankInfo ?? {}).every(isEmpty)) {
        await deleteBankInfo();
      } else {
        await updateBankInfo(newBankInfo);
      }
    },
  ];
};
