export const UPDATE_USER_POLICY_REQUEST = 'UPDATE_USER_POLICY_REQUEST';
export const UPDATE_USER_POLICY_SUCCESS = 'UPDATE_USER_POLICY_SUCCESS';
export const UPDATE_USER_POLICY_FAILURE = 'UPDATE_USER_POLICY_FAILURE';

export const FETCH_APPROVAL_SCHEMES_REQUEST = 'FETCH_APPROVAL_SCHEMES_REQUEST';
export const FETCH_APPROVAL_SCHEMES_SUCCESS = 'FETCH_APPROVAL_SCHEMES_SUCCESS';
export const FETCH_APPROVAL_SCHEMES_FAILURE = 'FETCH_APPROVAL_SCHEMES_FAILURE';

export const FETCH_COST_CENTERS_SUCCESS = 'FETCH_COST_CENTERS_SUCCESS';
export const FETCH_COST_CENTERS_FAILURE = 'FETCH_COST_CENTERS_FAILURE';
