import React from 'react';

import { useFiltersContext } from './hooks';
import { PayablesFilters } from '../../components/PayablesFilters';

export const PayablesFiltersContainer = () => {
  const { state, actions } = useFiltersContext();

  return <PayablesFilters state={state} actions={actions} />;
};
