import { type DateRange } from '@dev-spendesk/grapes';
import { type QueryKey } from 'react-query';

import { type ExportSettlementsCountVariables } from './useExportSettlementsCount';

export const getExportPayablesCountQueryKey = (
  companyId: string,
  period: DateRange,
): QueryKey => {
  return ['export-payables-count', companyId, ...period];
};

export const getExportPayablesInconsistencyMonitorResultQueryKey = (
  companyId: string,
) => {
  return ['export-payables-inconsistency-monitor-result', companyId];
};

export const getFailedExportPayablesQueryKey = (companyId: string) => {
  return ['export-failed-payables', companyId];
};

export const getExportPayablesCurrentStateQueryKey = (companyId: string) => {
  return ['export-payables-current-state', companyId];
};

export const getExportSettlementsCountQueryKey = (
  companyId: string,
  variables: ExportSettlementsCountVariables,
): QueryKey => {
  return ['export-settlement-count', companyId, variables];
};

export const getExportBankJournalCurrentStateQueryKey = (companyId: string) => {
  return ['export-bank-journal-current-state', companyId];
};

export const getFailedExportSettlementsQueryKey = (companyId: string) => {
  return ['export-failed-settlements', companyId];
};

export const getExportPayablesDownloadQueryKey = (companyId: string) => {
  return ['export-payables-download', companyId];
};
