import { useQueryClient, type InfiniteData } from 'react-query';

import { type QueryClient } from 'src/core/api/client';
import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { type Transfer } from '../../models';

interface RawTransfersResponse {
  transfers: Transfer[];
  nextCursor: null | number;
}

const transfersPerPage = 20;
const queryKey = [
  'transfer-scheduling',
  'transfers',
  { status: 'to_confirm', type: 'invoice' },
];

export const useFetchTransfers = (): InfiniteQueryState<Transfer[]> => {
  return useInfiniteQuery<Transfer, RawTransfersResponse>({
    key: queryKey,
    getRequest: (cursor) => ({
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/transfer-scheduling/transfers?status=to_confirm&type=invoice',
      params: {
        cursor,
        limit: transfersPerPage,
      },
    }),
    getNextPageParam(data) {
      return data.nextCursor?.toString();
    },
    reshapeData: (data) => {
      return data.transfers;
    },
  });
};

export const invalidateTransferQueries = (queryClient: QueryClient): void => {
  queryClient.invalidateQueries(['transfer-scheduling', 'transfers']);
};

export const useGetTransfersQueryData = () => {
  const queryClient = useQueryClient();

  return (): Transfer[] | undefined => {
    const data: InfiniteData<{ transfers: Transfer[] }> | undefined =
      queryClient.getQueryData(queryKey);
    return data?.pages.flatMap((page) => page.transfers);
  };
};
