import { useMemberSyncQuery } from 'modules/members/hooks/useMemberSyncStatusQuery';

import {
  useAccountingIntegrationName,
  useHasAccountingIntegrationCapability,
} from '../../accounting-integration/apis';
import { type AccountingSoftware } from '../integration/status';

type SyncStatus =
  | {
      synced: boolean;
      url: string | null;
      integrationName?: AccountingSoftware;
    }
  | undefined;

export const useGetMemberSyncStatus = (memberId: string): SyncStatus => {
  const integrationName = useAccountingIntegrationName();
  const hasMemberSyncCapability =
    useHasAccountingIntegrationCapability('memberSync');

  const memberSyncStatus = useMemberSyncQuery(memberId);

  if (!hasMemberSyncCapability) {
    return;
  }

  if (memberSyncStatus.status !== 'success') {
    return;
  }

  return {
    ...memberSyncStatus.data,
    integrationName,
  };
};
