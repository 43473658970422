import { EmptyState, Link } from '@dev-spendesk/grapes';
import { type ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';

import './UnexpectedError.css';

type Props = {
  actions?: ReactElement;
};

export const UnexpectedError = ({ actions }: Props) => {
  const { t } = useTranslation('errors');

  return (
    <EmptyState
      className="UnexpectedError"
      iconName="warning"
      iconVariant="warning"
      title={t('unexpectedError.title')}
      subtitle={
        <Trans i18nKey="errors:unexpectedError.weHaveBeenNotified">
          <Link
            as="a"
            href="#"
            id="intercom-message"
            onClick={() => window.Intercom('show')}
          >
            -
          </Link>
        </Trans>
      }
      actions={actions}
    />
  );
};
