import {
  AutocompleteNoOptions,
  type AutocompleteProps,
  DropdownItem,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { COUNTRIES, type Country } from 'src/core/config/country';

import { AutocompleteSearch } from '../AutocompleteSearch';
import { type Option } from '../AutocompleteSearch/option';

import './CountryAutocomplete.css';

export type CountryCode = string;

type Props<T extends Option> = {
  countryCode: CountryCode;
  onSelect(countryCode: CountryCode | undefined): void;
  countryFilter?(countries: Country[]): Country[];
} & Omit<
  AutocompleteProps<T>,
  | 'onSearch'
  | 'options'
  | 'onAddOption'
  | 'renderAddOption'
  | 'value'
  | 'onSelect'
>;

export const CountryAutocomplete = <T extends Option>({
  countryFilter,
  countryCode,
  onSelect,
  ...rest
}: Props<T>) => {
  const { t } = useTranslation('countries');

  const getCountries = useCallback(() => {
    const countries = countryFilter
      ? countryFilter(Object.values(COUNTRIES))
      : Object.values(COUNTRIES);

    return countries.map((country) => ({
      key: country.alpha2,
      label: t(country.translationKey),
    }));
  }, [countryFilter]);

  const options = useMemo(getCountries, [countryFilter]);
  const selectedCountry = options.find(
    (option) => option.key?.toLowerCase() === countryCode?.toLowerCase(),
  );

  const renderPrefix = (option: Option | null) => {
    if (!option) {
      return null;
    }
    return (
      <div className="iti-flag-container">
        <div className={cx('iti-flag', option.key?.toLowerCase() || '')} />
      </div>
    );
  };

  return (
    <AutocompleteSearch
      {...rest}
      value={selectedCountry}
      options={options}
      onSelect={(country) => onSelect(country?.key)}
      renderOption={(option, optionState) => (
        <DropdownItem
          label={option.label}
          prefix={renderPrefix(option)}
          {...optionState}
        />
      )}
      renderPrefix={renderPrefix}
      renderNoOptions={(rawValue) => (
        <AutocompleteNoOptions className="text-center body-m">
          <div>
            <Trans
              i18nKey="misc.noOptions"
              values={{ account: rawValue }}
              components={[
                <span key="noOptions" className="text-complementary" />,
              ]}
            />
          </div>
        </AutocompleteNoOptions>
      )}
    />
  );
};
