import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { CompanyInvoices } from './components/CompanyInvoices';
import { CompanyPlanSubscriptionContainer } from './components/CompanyPlanSubscription';
import { LegacyCompanyPlanSubscription } from './components/LegacyCompanyPlanSubscription';
import {
  type CompanySubscription,
  type Invoice,
  isChargebeeSubscription,
} from '../../models';

import './CompanyPlan.css';

type Props = {
  companySubscription: CompanySubscription | undefined;
  invoices: Invoice[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
};

export const CompanyPlan = ({
  companySubscription,
  invoices,
  hasNextPage,
  fetchNextPage,
}: Props) => {
  const { t } = useTranslation();

  if (!companySubscription) {
    return (
      <div className="CompanyPlan__not-activated title-m ">
        {t('billing.companyPlan.notActivated')}
      </div>
    );
  }

  return (
    <>
      {isChargebeeSubscription(companySubscription) ? (
        <CompanyPlanSubscriptionContainer
          companySubscription={companySubscription}
        />
      ) : (
        <LegacyCompanyPlanSubscription
          companySubscription={companySubscription}
        />
      )}
      {invoices.length > 0 && (
        <CompanyInvoices
          invoices={invoices}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      )}
    </>
  );
};
