import { useHasAccessToKycStateRelatedFeatures } from 'modules/kyc/hooks/useHasAccessToKycStateRelatedFeatures';

import { isChargebeeSubscription } from '../../../../models';
import { useGetCompanySubscriptionQuery } from '../useGetCompanySubscriptionQuery';

/**
 * activeEntities and availableEntities will only be defined in case of an organisation based billing
 * isAllowedToOpenEntity will default to true on every other cases
 */
type EntitiesCount = {
  activeEntities?: number;
  availableEntities?: number;
  isAllowedToOpenEntity: boolean;
};

export const useGetOrganisationEntities = (): EntitiesCount => {
  const getCompanySubscriptionQueryState = useGetCompanySubscriptionQuery();
  const hasAccessToKycStateRelatedFeatures =
    useHasAccessToKycStateRelatedFeatures();

  // Default is true for every cases even errors because non-Chargebee subscriptions return a 500 error instead of empty body
  // Even with a true error the back end will block the request
  if (
    getCompanySubscriptionQueryState.status === 'success' &&
    isChargebeeSubscription(getCompanySubscriptionQueryState.data) &&
    getCompanySubscriptionQueryState.data?.entitiesCount
  ) {
    return getCompanySubscriptionQueryState.data?.entitiesCount;
  }
  return {
    isAllowedToOpenEntity: hasAccessToKycStateRelatedFeatures,
  };
};
