import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useDeleteBudgetaryExerciseMutation = (
  budgetaryExerciseId: string,
): MutationState => {
  return useMutation({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/budgets/budgetary-exercises/${budgetaryExerciseId}`,
      method: 'delete',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['budgetaryExercises']);
      },
    },
  });
};
