import classNames from 'classnames';
import React from 'react';

export const CardLabel = ({
  label,
  className = 'text-[#ae90e8]',
}: {
  label: string;
  className?: string;
}) => (
  <div
    className={classNames('mb-xxs', className)}
    style={{
      lineHeight: '11px',
      fontSize: 10,
    }}
  >
    {label}
  </div>
);
