import { add, subtract, multiply, toNumber, type MonetaryValue } from 'ezmoney';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type Discount } from 'modules/company/billing/models';
import i18n from 'src/core/config/i18n';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { formatMoney } from 'src/core/utils/money';

export const USAGE_UNITS = {
  SPEND: 'spent',
  USERS: 'active_users',
  BILLS: 'bills',
  EXPENSES: 'expenses',
};

export const ADDON_NAMES = {
  ERECEIPTS: 'ereceipts',
  ERECEIPT_USERS: 'ereceipt_users',
  SUPPLEMENTARY_WALLETS: 'supplementary_wallets',
  SAML_SSO: 'saml_sso',
  CARD_INSURANCE: 'card_insurance',
};

export const DISCOUNT_TYPES = {
  AMOUNT: 'amount',
  PER_UNIT: 'per_unit',
  PERCENTAGE: 'percentage',
};

// FIXME: we shouldn't use monetary value to represent percentage
const formatPercentMonetaryValue = (monetaryValue: MonetaryValue): string =>
  toNumber(monetaryValue).toLocaleString(i18n.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'percent',
  });

type FormatOptions = {
  monetaryValue: MonetaryValue;
  unit: string;
  currency: string;
  t: TGlobalFunctionTyped;
};

export const formatBaseItem = (
  value: number,
  { monetaryValue, unit, currency, t }: FormatOptions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): string => {
  switch (unit) {
    case USAGE_UNITS.SPEND: {
      if (value === Number.POSITIVE_INFINITY) {
        if (monetaryValue.amount === 0) {
          return t('billing.spendUnlimited');
        }
        const percentage = formatPercentMonetaryValue(monetaryValue);
        return t('billing.spendUnlimitedWithPercentage', { percentage });
      }
      return t('billing.spendIncludedWithCount', {
        amount: formatMoney(value, currency),
      });
    }

    case USAGE_UNITS.EXPENSES: {
      return t('billing.expenses', { quantity: value });
    }

    case USAGE_UNITS.USERS: {
      if (value === Number.POSITIVE_INFINITY) {
        if (monetaryValue.amount === 0) {
          return t('billing.usersUnlimited');
        }
        return t('billing.usersUnlimitedWithAmount', {
          amount: formatMonetaryValue(monetaryValue),
        });
      }
      if (monetaryValue.amount === 0) {
        return t('billing.usersIncludedWithCount', { count: value });
      }
      return t('billing.usersWithCount', {
        count: value,
        amount: formatMonetaryValue(monetaryValue),
      });
    }

    case USAGE_UNITS.BILLS: {
      if (value === Number.POSITIVE_INFINITY) {
        if (monetaryValue.amount === 0) {
          return t('billing.billsUnlimited');
        }
        return t('billing.billsUnlimitedWithAmount', {
          amount: formatMonetaryValue(monetaryValue),
        });
      }
      if (monetaryValue.amount === 0) {
        return t('billing.billsIncludedWithCount', { count: value });
      }
      return t('billing.billsWithCount', {
        count: value,
        amount: formatMonetaryValue(monetaryValue),
      });
    }

    default: {
      if (value === Number.POSITIVE_INFINITY) {
        if (monetaryValue.amount === 0) {
          return t('billing.genericUnlimited');
        }
        return t('billing.genericUnlimitedWithAmount', {
          amount: formatMonetaryValue(monetaryValue),
        });
      }
      if (monetaryValue.amount === 0) {
        return t('billing.genericIncludedWithCount', { count: value });
      }

      return t('billing.genericWithCount', {
        count: value,
        amount: formatMonetaryValue(monetaryValue),
      });
    }
  }
};

// FIXME: If from === to, we need a new wording; otherwise it looks like:
// "X€ par active users from Y to Y", which is weird
// It doesn't really affect any customer for now

type FormatMiddleTierItemOptions = {
  unit: string;
  currency: string;
  t: TGlobalFunctionTyped;
};

export const formatMiddleTierItem = (
  from: string | number,
  to: string | number,
  value: MonetaryValue,
  { unit, currency, t }: FormatMiddleTierItemOptions,
): string | undefined => {
  switch (unit) {
    case USAGE_UNITS.SPEND: {
      return t('billing.spendTier', {
        from: formatMoney(from, currency),
        to: formatMoney(to, currency),
        percentage: formatPercentMonetaryValue(value),
      });
    }
    case USAGE_UNITS.USERS: {
      const amount = formatMonetaryValue(value);
      return t('billing.usersTier', { from, to, amount });
    }
    case USAGE_UNITS.BILLS: {
      const amount = formatMonetaryValue(value);
      return t('billing.billsTier', { from, to, amount });
    }
    case USAGE_UNITS.EXPENSES: {
      return;
    }
    default: {
      const amount = formatMonetaryValue(value);
      return t('billing.genericTier', { from, to, amount });
    }
  }
};

type FormatExtraItemOptions = {
  unit: string;
  t: TGlobalFunctionTyped;
};

export const formatExtraItem = (
  value: MonetaryValue,
  { unit, t }: FormatExtraItemOptions,
): string | undefined => {
  switch (unit) {
    case USAGE_UNITS.SPEND: {
      return t('billing.spendExtra', {
        percentage: formatPercentMonetaryValue(value),
      });
    }
    case USAGE_UNITS.USERS: {
      const amount = formatMonetaryValue(value);
      return t('billing.usersExtra', { amount });
    }
    case USAGE_UNITS.BILLS: {
      const amount = formatMonetaryValue(value);
      return t('billing.billsExtra', { amount });
    }
    case USAGE_UNITS.EXPENSES: {
      const amount = formatMonetaryValue(value);
      return t('billing.expensesExtra', { amount });
    }
    default: {
      const amount = formatMonetaryValue(value);
      return t('billing.genericExtra', { amount });
    }
  }
};

export const formatAddonName = ({
  name,
  t,
}: {
  name: string;
  t: TGlobalFunctionTyped;
}): string => {
  switch (name) {
    case ADDON_NAMES.ERECEIPTS:
    case ADDON_NAMES.ERECEIPT_USERS: {
      return t('billing.ereceipts');
    }
    case ADDON_NAMES.SUPPLEMENTARY_WALLETS: {
      return t('billing.supplementaryWallets');
    }
    case ADDON_NAMES.SAML_SSO: {
      return t('billing.samlSso');
    }
    case ADDON_NAMES.CARD_INSURANCE: {
      return t('billing.cardInsurance');
    }
    default: {
      return '';
    }
  }
};

type FormatAddonPriceParams = {
  unit: string;
  price: MonetaryValue;
  t: TGlobalFunctionTyped;
};

export const formatAddonPrice = ({
  unit,
  price,
  t,
}: FormatAddonPriceParams): string => {
  switch (unit) {
    case ADDON_NAMES.ERECEIPTS:
      return `${formatMonetaryValue(price)} / ${t('billing.month')}`;

    case ADDON_NAMES.ERECEIPT_USERS:
    case ADDON_NAMES.CARD_INSURANCE:
      return `${formatMonetaryValue(price)} / ${t('billing.month')}`;

    case ADDON_NAMES.SUPPLEMENTARY_WALLETS: {
      const periodicity = `${t('billing.month')} / ${t('billing.wallet')}`;
      return `${formatMonetaryValue(price)} / ${periodicity}`;
    }
    case ADDON_NAMES.SAML_SSO:
      return `${formatMonetaryValue(price)} / ${t('billing.month')}`;
    default: {
      return '';
    }
  }
};

export const formatDiscount = (discount: Discount): string | null => {
  switch (discount.type) {
    case DISCOUNT_TYPES.AMOUNT:
    case DISCOUNT_TYPES.PER_UNIT: {
      // @ts-expect-error code is good, typing not perfect
      return formatMonetaryValue(discount.value);
    }
    case DISCOUNT_TYPES.PERCENTAGE: {
      // @ts-expect-error code is good, typing not perfect
      return `${discount.ratio * 100}%`;
    }
    default:
      return null;
  }
};

export const computePriceWithDiscount = (
  price: MonetaryValue,
  discount: Discount,
): MonetaryValue | null => {
  if (!discount) {
    return null;
  }

  switch (discount.type) {
    case DISCOUNT_TYPES.AMOUNT:
    case DISCOUNT_TYPES.PER_UNIT: {
      // @ts-expect-error code is good, typing not perfect
      return subtract(price, discount.value);
    }
    case DISCOUNT_TYPES.PERCENTAGE: {
      // @ts-expect-error code is good, typing not perfect
      return multiply(price, Math.floor((1 - discount.ratio) * 100), 2);
    }
    default:
      return null;
  }
};

export const addIncludedExpensesToPrice = (
  price: MonetaryValue,
  includedExpensesAmount: MonetaryValue,
): MonetaryValue => {
  return add(price, includedExpensesAmount);
};
