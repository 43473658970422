/* eslint-disable unicorn/no-await-expression-member */

import { EmptyStateError, lazyLoad } from 'src/core/common/components/LazyLoad';

import { LoadingState } from './LoadingState';

export const LazyRequestsPanel = lazyLoad({
  loader: async () => ({
    default: (
      await import(
        /* webpackChunkName: "RequestsPanel", webpackPrefetch: true */ '../../../list/components/RequestPanelContainer'
      )
    ).RequestPanelContainer,
  }),
  loading: <LoadingState />,
  fallback: (props) => <EmptyStateError {...props} />,
});
