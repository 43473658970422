import { InfoTip, Tag } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { daysToString, formatHour } from './formatter';
import { type ApiCardSettings } from '../../../card';
import {
  merchantCategoryToTranslationKey,
  merchantCategoryOrder,
} from '../../../merchantCategory';

import './PlasticCardControlSettings.css';

type Props = {
  cardSettings: ApiCardSettings;
};

export const PlasticCardControlSettings = ({ cardSettings }: Props) => {
  const { t } = useTranslation('global');
  const hasAdvancedCardControlFeature = useFeature(
    FEATURES.PHYSICAL_CARD_CONTROL_ADVANCED,
  );
  const hasCardAuthorisations =
    Object.keys(cardSettings.authorisations).length > 0;

  if (!hasCardAuthorisations && !hasAdvancedCardControlFeature) {
    return null;
  }

  const { isCashWithdrawalEnabled, isOnlineEnabled, isContactlessEnabled } =
    cardSettings.authorisations;

  return (
    <>
      <h2 className="mb-m mt-l text-complementary title-l">
        {t('cards.controlSettings.yourCardSetup')}
      </h2>
      <div className="box flex">
        {(hasCardAuthorisations ||
          (hasAdvancedCardControlFeature && cardSettings.schedule)) && (
          <div className="PlasticCardControlSettings__column">
            <span className="PlasticCardControlSettings__title title-m">
              {t('cards.controlSettings.sections.authorisations.title')}
              <InfoTip
                content={t(
                  'cards.controlSettings.sections.authorisations.tipMessage',
                )}
              />
            </span>
            {hasAdvancedCardControlFeature && cardSettings.schedule && (
              <>
                <div className="PlasticCardControlSettings__row body-m">
                  {t('cards.controlSettings.sections.schedule.dayOfUse')}
                  <Tag variant="neutral">
                    {daysToString(cardSettings.schedule.days, t)}
                  </Tag>
                </div>
                {!cardSettings.schedule.fullDay && (
                  <div className="PlasticCardControlSettings__row body-m">
                    {t('cards.controlSettings.sections.schedule.hoursOfUse')}
                    <Tag variant="neutral">
                      {formatHour(cardSettings.schedule.hours.from)}{' '}
                      {t('cards.controlSettings.sections.schedule.to')}{' '}
                      {formatHour(cardSettings.schedule.hours.to)}
                    </Tag>
                  </div>
                )}
              </>
            )}
            {hasCardAuthorisations && (
              <>
                {isCashWithdrawalEnabled !== undefined && (
                  <div className="PlasticCardControlSettings__row body-m">
                    {t(
                      'cards.controlSettings.sections.authorisations.cashWithdrawal',
                    )}
                    <Tag variant="neutral">
                      {isCashWithdrawalEnabled
                        ? t(
                            'cards.controlSettings.sections.authorisations.allowed',
                          )
                        : t(
                            'cards.controlSettings.sections.authorisations.notAllowed',
                          )}
                    </Tag>
                  </div>
                )}
                {isOnlineEnabled !== undefined && (
                  <div className="PlasticCardControlSettings__row body-m">
                    {t(
                      'cards.controlSettings.sections.authorisations.onlinePayment',
                    )}
                    <Tag variant="neutral">
                      {isOnlineEnabled
                        ? t(
                            'cards.controlSettings.sections.authorisations.allowed',
                          )
                        : t(
                            'cards.controlSettings.sections.authorisations.notAllowed',
                          )}
                    </Tag>
                  </div>
                )}
                {isContactlessEnabled !== undefined && (
                  <div className="PlasticCardControlSettings__row body-m">
                    {t(
                      'cards.controlSettings.sections.authorisations.contactlessPayment',
                    )}
                    <Tag variant="neutral">
                      {isContactlessEnabled
                        ? t(
                            'cards.controlSettings.sections.authorisations.allowed',
                          )
                        : t(
                            'cards.controlSettings.sections.authorisations.notAllowed',
                          )}
                    </Tag>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {hasAdvancedCardControlFeature && (
          <div
            className={cx('PlasticCardControlSettings__column', {
              'ml-m border-0 border-l border-solid border-l-neutral-lighter pl-m':
                hasCardAuthorisations || cardSettings.schedule,
            })}
          >
            <h5 className="PlasticCardControlSettings__title title-m">
              {t('cards.controlSettings.sections.categories.title')}
            </h5>
            {merchantCategoryOrder.map((merchantCategory) => (
              <div
                key={merchantCategory}
                className="PlasticCardControlSettings__row body-m"
              >
                {t(merchantCategoryToTranslationKey[merchantCategory])}
                <Tag variant="neutral">
                  {cardSettings.categories.find(
                    (category) => category.identifier === merchantCategory,
                  )?.allowed
                    ? t('cards.controlSettings.sections.authorisations.allowed')
                    : t(
                        'cards.controlSettings.sections.authorisations.notAllowed',
                      )}
                </Tag>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
