import { type Account } from 'modules/company/wallet/wallet';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export const useAccountsQuery = (isEnabled = true): QueryState<Account> => {
  return useQuery<Account>({
    key: 'accounts',
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounts',
    },
  });
};
