import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type AppState } from "src/core/reducers";

import { ControlRuleSummaryContainer } from './ControlRuleSummaryContainer';
import { EditControlRuleDetailsContainer } from './EditControlRuleDetailsContainer';
import { EditControlRuleMembersContainer } from './EditControlRuleMembersContainer';
import { DeleteControlRule } from '../../components/DeleteControlRule/DeleteControlRule';
import { type ControlRule } from '../../controlRule';
import * as actions from '../../redux/actions';
import {
  getIsRemovingControlRule,
  getSelectedControlRule,
} from '../../redux/selectors';
import * as thunks from '../../redux/thunks';

type Props = {
  isRemoving: boolean;
  controlRule?: ControlRule;
  resetSelectedControlRule(): void;
  removeControlRule(id: string): Promise<void>;
};

type UiState =
  | 'ruleSummary'
  | 'editRuleDetails'
  | 'editRuleMembers'
  | 'deleteRule';

export const Container = ({
  isRemoving,
  controlRule,
  resetSelectedControlRule,
  removeControlRule,
}: Props) => {
  const [uiState, setUiState] = useState<UiState>('ruleSummary');

  useEffect(() => {
    setUiState('ruleSummary');
  }, [controlRule?.id]);

  if (!controlRule) {
    return null;
  }

  switch (uiState) {
    case 'ruleSummary':
      return (
        <ControlRuleSummaryContainer
          controlRule={controlRule}
          onEditDetailsClick={() => setUiState('editRuleDetails')}
          onDelete={() => setUiState('deleteRule')}
          onClose={resetSelectedControlRule}
        />
      );
    case 'deleteRule':
      return (
        <DeleteControlRule
          isRemoving={isRemoving}
          controlRuleName={controlRule.name}
          onCancel={() => setUiState('ruleSummary')}
          onDelete={async () => {
            await removeControlRule(controlRule.id);
            resetSelectedControlRule();
          }}
        />
      );
    case 'editRuleDetails':
      return (
        <EditControlRuleDetailsContainer
          controlRule={controlRule}
          onClose={() => setUiState('ruleSummary')}
          onNext={() => setUiState('editRuleMembers')}
        />
      );
    case 'editRuleMembers':
      return (
        <EditControlRuleMembersContainer
          controlRule={controlRule}
          onClose={() => setUiState('editRuleDetails')}
        />
      );
    default:
      return null;
  }
};

const mapStateToProps = (state: AppState) => ({
  controlRule: getSelectedControlRule(state),
  isRemoving: getIsRemovingControlRule(state),
});

const mapDispatchToProps = {
  resetSelectedControlRule: actions.resetSelectedControlRule,
  removeControlRule: thunks.removeControlRule,
};

export const EditControlRuleModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
