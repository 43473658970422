import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';

import { type RawSupplier, transformSupplier } from './api';
import { type SupplierId, type SupplierDetails } from '../models';

export const useCheckIsSupplierValidated = ({
  staleTime = 30_000, // we don't want to fetch it on every form change, so we put 30s stale time
}: { staleTime?: number } = {}) => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const supplierFetcher = (supplierId: SupplierId) =>
    getFetcher<RawSupplier>({
      companyId,
      getRequest: () => ({
        type: 'rest',
        target: 'companyAPI',
        endpoint: `/suppliers/${supplierId}`,
        method: 'get',
      }),
    });

  return async (
    supplierId: SupplierId | undefined,
  ): Promise<boolean | undefined> => {
    if (supplierId) {
      try {
        const rawSupplier = await queryClient.fetchQuery(
          ['suppliers', supplierId],
          supplierFetcher(supplierId),
          { staleTime },
        );
        const supplier = reshaper(rawSupplier);
        return supplier.isSupplierValidated;
      } catch {
        // Do nothing
      }
    }
  };
};

const reshaper = (rawSupplier: RawSupplier): SupplierDetails =>
  transformSupplier(rawSupplier);
