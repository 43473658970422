import { type AppState } from 'src/core/reducers';

import { type ControlRule } from '../controlRule';

export const getControlRules = (state: AppState): ControlRule[] =>
  state.control.controlRules;

export const getIsFetchingControlRules = (state: AppState): boolean =>
  state.control.behavior.isFetching;

export const getIsRemovingControlRule = (state: AppState): boolean =>
  state.control.behavior.isRemoving;

export const getIsCreatingControlRule = (state: AppState): boolean =>
  state.control.behavior.isCreating;

export const getIsUpdatingControlRule = (state: AppState): boolean =>
  state.control.behavior.isUpdating;

export const getSelectedControlRule = (
  state: AppState,
): ControlRule | undefined => state.control.selectedControlRule;
