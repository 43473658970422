import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { SettingsLayout } from 'common/components/layout';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routes } from 'src/core/constants/routes';

import { BankInformationPage } from './components/BankInformationPage';
import { CompanyInformationPage } from './components/CompanyInformationPage';
import { GeneralSettingsPageNav } from './components/GeneralSettingsPageNav';
import { NotificationSettingsContainer } from './components/NotificationSettings';
import { SpendingMethods } from './components/SpendingMethods';
import { WireTransferPage } from './components/WireTransferPage';

export const GeneralSettingsPage = () => {
  const companyId = useCompanyId();

  return (
    <div className="page__container">
      <SettingsLayout>
        <GeneralSettingsPageNav companyId={companyId} />
        <Switch>
          <Route path={routes.COMPANY_GENERAL_SETTINGS_BANK_INFORMATION.path}>
            <BankInformationPage />
          </Route>
          <Route
            path={routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path}
          >
            <CompanyInformationPage />
          </Route>
          <Route path={routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path}>
            <SpendingMethods />
          </Route>
          <Route path={routes.COMPANY_GENERAL_SETTINGS_WIRE_TRANSFER.path}>
            <WireTransferPage />
          </Route>
          <Route path={routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path}>
            <NotificationSettingsContainer />
          </Route>
        </Switch>
      </SettingsLayout>
    </div>
  );
};
