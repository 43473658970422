import { useDispatch } from 'react-redux';

import { updateFeatureSet } from 'modules/app/layout/redux/actions';
import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import type FEATURES from 'src/core/constants/features';

export const useEnableFeature = (): MutationState<{
  featureCode: FEATURES;
}> => {
  const dispatch = useDispatch();
  return useMutation<{ featureCode: FEATURES }, { featureSet: FEATURES }>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/feature',
    },
    reshapeData: (data) => {
      return data.featureSet;
    },
    options: {
      throwOnError: true,
      onSuccess({ data }): void {
        dispatch(updateFeatureSet(data));
      },
    },
  });
};
