import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export type BuCodes = {
  buCodes: {
    code: string;
    rates?: number[];
  }[];
};

export const useGetValidTaxCodesQuery = (
  reverseCharge: boolean | undefined = undefined,
): QueryState<BuCodes> => {
  const { company: companyId } = useParams();

  return useQuery<BuCodes>({
    key: ['useGetValidTaxCodesQuery', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint:
        '/accounting-integration/chart-of-accounts/tax-accounts/valid-bu-codes',
      params: { reverseCharge },
    },
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
  });
};
