import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type CustomFieldValue } from '../../../models/customField';

type CustomFieldCreatePayload = {
  customFieldId: string;
  value: string;
};

export const useCreateCustomFieldValueMutation = (
  customFieldId: string,
): MutationState<CustomFieldCreatePayload, CustomFieldValue> => {
  return useMutation<
    CustomFieldCreatePayload,
    CustomFieldValue,
    CustomFieldValue,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/custom-fields/values',
      method: 'post',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['useCustomFieldValuesQuery', customFieldId]);
        client.invalidateQueries(['customFields']);
      },
    },
  });
};
