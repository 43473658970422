/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createAction,
  type ThunkDispatch,
  type AnyAction,
} from '@reduxjs/toolkit';
import i18next from 'i18next';
import noop from 'lodash/noop';

import { setMeCompanyPlasticCard } from 'modules/app/layout/redux/actions';
import { addNotification, NotificationType } from 'modules/app/notifications';
import { companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import * as types from './actionTypes';
import { type Recipient } from '../recipient';

// Fetch single plastic card
export const fetchPlasticCardLoading = createAction(
  types.FETCH_PLASTIC_CARD_LOADING,
);
export const fetchPlasticCardFailure = createAction<any>(
  types.FETCH_PLASTIC_CARD_FAILURE,
);
export const fetchPlasticCardSuccess = createAction<any>(
  types.FETCH_PLASTIC_CARD_SUCCESS,
);
export const fetchPlasticCard =
  (cardId: string, callback = noop) =>
  async (
    dispatch: ThunkDispatch<AppState, null, AnyAction>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();

    dispatch(fetchPlasticCardLoading());

    let card;
    try {
      const companyId = getCompanyId(state);
      const { data: cardData } = await companyAPI.get(`/cards/${cardId}`, {
        companyId,
      });
      card = cardData;
    } catch (error) {
      dispatch(fetchPlasticCardFailure(error));
      return;
    }

    dispatch(fetchPlasticCardSuccess({ card }));
    return callback(card);
  };

export const orderRecard =
  (cardId: string, recipient: Recipient) =>
  async (
    _: ThunkDispatch<AppState, null, AnyAction>,
    getState: () => AppState,
  ) => {
    const companyId = getCompanyId(getState());
    await companyAPI.post(
      `/cards`,
      {
        cards: [{ ...recipient, recardingCardId: cardId }],
      },
      { companyId },
    );
  };

export const activateCard =
  (cardId: string, lastNumbers: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, AnyAction>,
    getState: () => AppState,
  ) => {
    const companyId = getCompanyId(getState());
    try {
      const result = await companyAPI.put(
        `/cards/${cardId}/activate`,
        {
          lastNumbers,
        },
        { companyId },
      );
      dispatch(
        setMeCompanyPlasticCard({ companyId, plasticCard: result.data }),
      );
      return { outcome: 'success', card: result.data };
    } catch (e) {
      const res = e.response;
      let reason = '';
      if (res.status === 400) {
        // #FIXME
        // we should not show backend data in the frontend
        // but the existing card activation API sends the
        // error message directly to the front
        reason = res.data.error;
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: res.data.error,
          }),
        );
      } else {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18next.t('errors:somethingWrong_short'),
          }),
        );
      }
      return { outcome: 'error', reason };
    }
  };
