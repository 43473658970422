import {
  FETCH_LAST_TERMS_AND_CONDITIONS_SUCCESS,
  SIGN_TERMS_AND_CONDITIONS_FAILURE,
  SIGN_TERMS_AND_CONDITIONS_SUCCESS,
  SIGN_TERMS_AND_CONDITIONS_LOADING,
  FETCH_TIPS_FILTERS_SUCCESS,
  FETCH_TIPS_FILTERS_FAILURE,
} from './actionTypes';
import { type ControllerHomepageAction } from './actions';
import { type TermsAndConditions } from './types';
import { type TipsFilters } from '../components/TipBox/types';

type TipsStatusStatus = 'success' | 'failure';

export type State = {
  lastTermsAndConditions: TermsAndConditions | null;
  tips: {
    status: {
      loading: boolean;
      status?: TipsStatusStatus;
    };
    filters: TipsFilters;
  };
  signTaC: { loading: boolean };
};

const initialState: State = {
  lastTermsAndConditions: null,
  tips: {
    status: {
      loading: true,
      status: undefined,
    },
    filters: {
      hasPaymentsWithoutReceipts: false,
      isAutocatEnabled: false,
      hasCustomExports: false,
      hasPaymentsWithVirtualCreditCards: false,
      isExpenseClaimsEnabled: false,
    },
  },
  signTaC: { loading: false },
};

export const reducer = (
  state: State = initialState,
  action: ControllerHomepageAction,
): State => {
  switch (action.type) {
    case FETCH_LAST_TERMS_AND_CONDITIONS_SUCCESS:
      return { ...state, lastTermsAndConditions: action.payload };
    case SIGN_TERMS_AND_CONDITIONS_FAILURE:
      return {
        ...state,
        signTaC: { loading: false },
      };
    case SIGN_TERMS_AND_CONDITIONS_LOADING:
      return {
        ...state,
        signTaC: { loading: true },
      };
    case SIGN_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        signTaC: { loading: false },
      };
    case FETCH_TIPS_FILTERS_SUCCESS:
      return {
        ...state,
        tips: {
          ...state.tips,
          status: { loading: false, status: 'success' as TipsStatusStatus }, // If not forcing the status with "as", "success" is recognized like a simple string by Typescript
          filters: { ...state.tips.filters, ...action.payload.tipsFilters },
        },
      };
    case FETCH_TIPS_FILTERS_FAILURE:
      return {
        ...state,
        tips: {
          ...state.tips,
          status: { loading: false, status: 'failure' as TipsStatusStatus }, // If not forcing the status with "as", "failure" is recognized like a simple string by Typescript
        },
      };
    default:
      return state;
  }
};
