import { type PaymentMethodsByIssuer } from '../types';

export const UPDATE_COMPANY = 'company/UPDATE_COMPANY';
export const FETCH_AVAILABLE_PAYMENT_METHODS_REQUEST =
  'company/FETCH_AVAILABLE_PAYMENT_METHODS_REQUEST';
export const FETCH_AVAILABLE_PAYMENT_METHODS_SUCCESS =
  'company/FETCH_AVAILABLE_PAYMENT_METHODS_SUCCESS';
export const FETCH_AVAILABLE_PAYMENT_METHODS_FAILURE =
  'company/FETCH_AVAILABLE_PAYMENT_METHODS_FAILURE';

export type UpdateCompany = Readonly<{
  type: typeof UPDATE_COMPANY;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    company: any;
  };
}>;

export type FetchAvailablePaymentMethodsRequest = Readonly<{
  type: typeof FETCH_AVAILABLE_PAYMENT_METHODS_REQUEST;
}>;

export type FetchAvailablePaymentMethodsSuccess = Readonly<{
  type: typeof FETCH_AVAILABLE_PAYMENT_METHODS_SUCCESS;
  payload: PaymentMethodsByIssuer;
}>;

export type FetchAvailablePaymentMethodsFailure = Readonly<{
  type: typeof FETCH_AVAILABLE_PAYMENT_METHODS_FAILURE;
}>;

export type CompanyActions =
  | UpdateCompany
  | FetchAvailablePaymentMethodsRequest
  | FetchAvailablePaymentMethodsSuccess
  | FetchAvailablePaymentMethodsFailure;
