import {
  AutocompleteMultiple,
  type AutocompleteMultipleProps,
} from '@dev-spendesk/grapes';
import React from 'react';

import type { Option } from './option';
import { useSearch } from './useSearch';

export type Props<T extends Option> = Omit<
  AutocompleteMultipleProps<T>,
  'onSearch'
>;

export const AutocompleteMultipleSearch = <T extends Option>(
  props: Props<T>,
) => {
  const { filteredOptions, handleSearch } = useSearch(props.options);
  const filteredOptionKeys = filteredOptions.map(({ key }) => key);

  const handleSelect = (visibleSelectedOptions: T[]) => {
    // keep selected keys that are out of current filtering
    const hiddenSelectedOptions = props.values.filter((value) => {
      return !filteredOptionKeys.includes(value.key);
    });
    const selectedOptions = new Set([
      ...visibleSelectedOptions,
      ...hiddenSelectedOptions,
    ]);
    props.onSelect(Array.from(selectedOptions));
  };

  return (
    <AutocompleteMultiple
      {...props}
      options={filteredOptions}
      onSearch={handleSearch}
      onSelect={handleSelect}
    />
  );
};
