import {
  type DraftExpense,
  type RawExpense,
} from '@finance-review/models/expense';
import { type QueryClient } from 'src/core/api/client';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { transformRawExpense } from './transformer';
import { REQUESTS_REIMBURSEMENTS_KEY } from './useExpensesQuery';

export const useExpenseQuery = (id: string): QueryState<DraftExpense> => {
  return useQuery<DraftExpense, RawExpense>({
    // we put the id at the end, so that invalidating ['requests', 'reimbursements'] also invalidates individual requests
    key: [...REQUESTS_REIMBURSEMENTS_KEY, id],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/${id}/reimbursement`,
    },
    reshapeData: (data: RawExpense): DraftExpense => {
      return transformRawExpense(data);
    },
  });
};

export const updateExpenseQueryData = (
  queryClient: QueryClient,
  queryData: RawExpense,
): void => {
  queryClient.setQueryData(
    [...REQUESTS_REIMBURSEMENTS_KEY, queryData.id],
    queryData,
  );
};
