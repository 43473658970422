import { useDispatch } from 'react-redux';

import type { AppDispatch } from 'src/core/modules/app/redux/store';
import {
  fetchRequestsStats,
  removeDraftRequestLocally,
} from 'src/core/modules/requests/redux/legacy/actions';

export const useSynchronizeDraftRequestDelete = () => {
  const dispatch = useDispatch<AppDispatch>();

  return (draftRequestId: string) => {
    dispatch(removeDraftRequestLocally({ id: draftRequestId }));
    dispatch(fetchRequestsStats());
  };
};
