import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { useCreateCustomFieldMutation } from './useCreateCustomFieldMutation';
import { CustomFieldCreationModal } from '../../components/CustomFieldCreationModal';
import { convertValues } from '../../utils/convertValues';
import { removeDuplicates } from '../../utils/removeDuplicates';
import { useDefaultCustomFieldPayload } from '../customField';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export type CustomFieldCreationForm = {
  name: string;
  type: 'optional' | 'mandatory';
  isSplittable?: boolean;
  values?: string;
};

export const CustomFieldCreationModalContainer = ({
  isOpen,
  onClose,
}: Props) => {
  const [createCustomField] = useCreateCustomFieldMutation();
  const { t } = useTranslation('global');
  const { successNotif, dangerNotif } = useNotifications();

  const defaultCustomFieldPayload = useDefaultCustomFieldPayload();
  const handleSubmit = async (values: CustomFieldCreationForm) => {
    try {
      await createCustomField({
        ...defaultCustomFieldPayload,
        name: values.name,
        isRequired: values.type === 'mandatory',
        values: removeDuplicates(convertValues(values.values)),
        isSplittable: values.isSplittable,
      });
      successNotif(t('customFields.creation.successRequest'));
      onClose();
      track(AnalyticEventName.CUSTOM_FIELDS_FORM_POST_VALUES, {
        method: 'creation',
        numberOfCharacters: (values.values ?? '').length,
        numberOfValues: (values.values ?? '').split('\n').length,
      });
    } catch {
      dangerNotif(t('customFields.creation.errorRequest'));
    }
  };

  return (
    <CustomFieldCreationModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
