import { gql } from 'graphql-tag';

export const GET_CUSTOM_FIELD_VALUES = gql`
  query GetCustomFieldValues(
    $companyId: ID!
    $customFieldIds: [ID!]
    $customFieldValuesIds: [ID!]
  ) {
    company(id: $companyId) {
      id
      customFields(filter: { ids: $customFieldIds, includeArchived: true }) {
        __typename
        id
        archiveDate
        ... on CustomFieldList {
          values(
            filter: { ids: $customFieldValuesIds, includeArchived: true }
          ) {
            edges {
              node {
                id
                name: value
                archiveDate
              }
            }
          }
        }
      }
    }
  }
`;
