import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type PreviewResponse = {
  pendingRequestsCount: number;
  approvedRequestsCount: number;
  readyOrExportedPaymentsCount: number;
  notReadyPaymentsCount: number;
  subscriptionsCount: number;
  purchaseOrdersCount: number;
  usersCount: number;
};

export const useCostCenterReplacePreviewQuery = (
  costCenterId: string,
): QueryState<PreviewResponse> => {
  return useQuery<PreviewResponse>({
    key: `${costCenterId}-replacePreview`,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/cost-centers/${costCenterId}/replace/preview`,
    },
    reshapeData: (data) => {
      return {
        pendingRequestsCount: data.pendingRequestsCount,
        approvedRequestsCount: data.approvedRequestsCount,
        readyOrExportedPaymentsCount: data.readyOrExportedPaymentsCount,
        notReadyPaymentsCount: data.notReadyPaymentsCount,
        subscriptionsCount: data.subscriptionsCount,
        purchaseOrdersCount: data.purchaseOrdersCount,
        usersCount: data.usersCount,
      };
    },
  });
};
