import { FormField, TextInput } from '@dev-spendesk/grapes';
import { useTranslation } from 'react-i18next';

import { isSpanishDPRPayable } from 'modules/bookkeep/payables/models/payable';
import { useHasAccountingIntegrationCapability } from 'src/core/modules/accounting-integration/apis';

import { type Payable } from '../../models';

export type Props = {
  payable: Payable;
  value: string | undefined;
  label: string;
  setFieldValue: (
    key: string,
    value: null | string | boolean,
    mustValidate?: boolean,
  ) => void;
  error: string | undefined;
};

/**
 * Component responsible for rendering an invoice number field,
 *
 * @param {Props} Props   Component properties, including
 *                        - value (string): the date value
 *                        - setFieldValue (function): the callback function
 *                        - label (string): the label to display
 *                        - error (string): the error message to display
 * @returns {JSX.Element} FormField component
 */
export const PayableInvoiceNumberFormField = ({
  label,
  payable,
  value,
  setFieldValue,
  error,
}: Props) => {
  const { t } = useTranslation('global');

  const integrationHasInvoiceNumberCapability =
    useHasAccountingIntegrationCapability('invoiceNumber');

  const hasSpanishDPR = isSpanishDPRPayable(payable);

  const isFieldRequired =
    integrationHasInvoiceNumberCapability || hasSpanishDPR;

  return (
    <>
      <FormField
        infoTipContent={
          hasSpanishDPR
            ? t('expenseInbox.expenseEditor.receiptNumberSpanishDPRTooltip')
            : undefined
        }
        className="AllPayablesPanelEditorForm__accounting-date"
        label={label}
        hint={!isFieldRequired ? t('misc.optional') : undefined}
        alertMessage={error}
      >
        <TextInput
          value={value ?? ''}
          onChange={(event) =>
            setFieldValue('invoiceNumber', event.target.value)
          }
          name="invoice-number"
        />
      </FormField>
    </>
  );
};
