import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export type RawExpenseCategory = {
  id: string;
  name: string;
  isArchived: boolean;
};

export const useExpenseCategoriesQuery = (): QueryState<
  RawExpenseCategory[]
> => {
  return useQuery<RawExpenseCategory[]>({
    key: 'expenseCategories',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/expense-categories',
    },
    reshapeData: (data) => data,
  });
};
