import React from 'react';

import { PaymentsBatchesLoader } from 'modules/invoices/components/PaymentsBatchesList/PaymentsBatchesLoader';

export const InvoicesHistoryPageSkeleton = () => {
  return (
    <div className="mt-xxl flex justify-center">
      <PaymentsBatchesLoader count={2} />
    </div>
  );
};
