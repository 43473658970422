import { combineReducers } from '@reduxjs/toolkit';

import * as actionTypes from './actionTypes';
import { type BookkeepingExport } from '../types';

type ExpensesExportState = {
  isPending: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  data: object | null; // No data payload expected for now, just used to track the success scenario
};

const expensesExportInitialState = {
  isPending: false,
  error: null,
  data: null,
};

export const expensesExportReducer = (
  state: ExpensesExportState = expensesExportInitialState,
  action: actionTypes.ExportActions,
): ExpensesExportState => {
  switch (action.type) {
    case actionTypes.EXPORT_EXPENSES_REQUEST:
      return {
        ...state,
        isPending: true,
        error: null,
      };
    case actionTypes.EXPORT_EXPENSES_SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
        data: {},
      };
    case actionTypes.EXPORT_EXPENSES_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.error,
      };
    case actionTypes.EXPORT_EXPENSES_RESET:
      return expensesExportInitialState;
    default:
      return state;
  }
};

type UnreadExportsState = BookkeepingExport[];

const unreadExportsInitialState: UnreadExportsState = [];

const unreadExportsReducer = (
  state: UnreadExportsState = unreadExportsInitialState,
  action: actionTypes.UnreadExportsActions,
): UnreadExportsState => {
  switch (action.type) {
    case actionTypes.FETCH_UNREAD_EXPORTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  export: expensesExportReducer,
  unreadExports: unreadExportsReducer,
});
