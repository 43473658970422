import { Avatar } from '@dev-spendesk/grapes';
import React from 'react';

import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { getAccountingIntegrationName } from '../accountingIntegration';

import './AccountingBaseSwitchOptions.css';

type Props = {
  firstIcon: string;
  secondIcon: string;
  firstOption: AccountingSoftware;
  secondOption: AccountingSoftware;
  thirdOption: AccountingSoftware;
};

export function AccountingBaseSwitchOptions({
  firstIcon,
  secondIcon,
  firstOption,
  secondOption,
  thirdOption,
}: Props) {
  const { t } = useTranslation('global');

  return (
    <>
      <span className="AccountingBaseSwitchOptions__icons">
        <Avatar
          variant="square"
          size="s"
          className="AccountingBaseSwitchOptions__first-icon"
          src={firstIcon}
          fallbackSrc={fallbackSupplierLogoSrc}
          text=""
        />
        <div className="AccountingBaseSwitchOptions__icon-background" />
        <Avatar
          variant="square"
          size="s"
          className="AccountingBaseSwitchOptions__second-icon"
          src={secondIcon}
          fallbackSrc={fallbackSupplierLogoSrc}
          text=""
        />
      </span>
      <p>
        {t('bookkeep.integrations.settings.baseSwitch.description', {
          firstOption: getAccountingIntegrationName(firstOption, t),
          secondOption: getAccountingIntegrationName(secondOption, t),
          thirdOption: getAccountingIntegrationName(thirdOption, t),
        })}
      </p>
    </>
  );
}
