import { SkeletonButton, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const KycWidgetSkeleton = () => {
  return (
    <div className="box flex justify-between gap-m p-m">
      <SkeletonText size="l" />
      <SkeletonButton width="m" />
    </div>
  );
};
