import { useTaxAccountsQuery } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/hooks/useTaxAccountsQuery';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { isReverseChargeAccount } from '../../payable/components';

export const useCheckReverseChargeTaxAccount = () => {
  const taxAccountsQueryResult = useTaxAccountsQuery({
    includeArchived: false,
  });

  const taxAccounts = unwrapQuery(taxAccountsQueryResult) ?? [];

  const reverseChargeVatAccountIds = taxAccounts
    .filter((taxAccount) => isReverseChargeAccount(taxAccount))
    .map((taxAccount) => taxAccount.id);

  return (accountId: string) => reverseChargeVatAccountIds.includes(accountId);
};
