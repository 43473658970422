import { type i18n, type TFunction } from 'i18next';
import compact from 'lodash/compact';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import without from 'lodash/without';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Tag from 'src/core/common/components/legacy/Tag/Tag';
import { type Company } from 'src/core/modules/app/hooks/useCompany';
import {
  NotificationType,
  type PushNotif,
} from 'src/core/modules/app/notifications';

import { type CustomField } from '../../export/customField';

type Props = {
  company: Company;
  customFields: CustomField[] | null;
  pushNotif: PushNotif;
  updateInvoicePattern: (invoicePattern: string[]) => void;
  t: TFunction<'global', undefined>;
  i18n: i18n;
  tReady: boolean;
};

type State = {
  selected: string[];
  choices: {
    date: string;
    supplier: string;
    amount: string;
    payer: string;
    team: string;
    payableId: string;
    receiptId: string;
  };
  displayError: boolean;
};

class InvoicesPattern extends Component<Props, State> {
  static defaultProps = {
    company: undefined,
    customFields: [],
  };

  constructor(props: Props) {
    super(props);

    const { company } = props;
    const selected = get(company, 'invoices', []);
    const { t } = this.props;

    this.state = {
      selected,
      choices: {
        date: t('accounting.filenameChoices.payableDate'),
        supplier: t('accounting.filenameChoices.supplier'),
        amount: t('accounting.filenameChoices.amount'),
        payer: t('accounting.filenameChoices.payer'),
        team: t('accounting.filenameChoices.team'),
        payableId: t('accounting.filenameChoices.payableReference'),
        receiptId: t('accounting.filenameChoices.receiptId'),
      },
      displayError: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.customFields !== nextProps.customFields) {
      const cfChoices = reduce(
        nextProps.customFields,
        (all, cf) => ({
          ...all,
          [`cf:${cf.id}`]: cf.name,
        }),
        {},
      );

      // Append custom fields to available invoice patterns
      this.setState(merge({}, this.state, { choices: cfChoices }));
    }
  }

  getPreview = () => {
    const { customFields, t } = this.props;
    const { selected } = this.state;
    const staticSuffix = '1'; // invoice ID
    const samples = {
      date: '2017_02_14',
      supplier: 'apple',
      amount: '123',
      payer: 'laura_ford',
      team: 'marketing',
      payableId: '2c794c7b',
      receiptId: '000001',
    };

    // Append custom fields preview values
    forEach(customFields, (cf) => {
      let value;
      if (cf.type === 'boolean') {
        value = t('misc.yes');
      } else {
        const first = head(cf.custom_fields_values);
        value = first ? first.value : 'foo';
      }
      // @ts-expect-error wont fix during ts migration
      samples[`cf:${cf.id}`] = value;
    });

    // @ts-expect-error wont fix during ts migration
    return `eg.: ${map(selected, (item) => samples[item]).join('-')}${
      isEmpty(selected) ? '' : '-'
    }${staticSuffix}.pdf`;
  };

  displayAvailableChoices = () => {
    const { choices, selected } = this.state;
    const available = Object.keys(choices).filter(
      (key) => !selected.includes(key),
    );

    if (!size(available)) {
      return null;
    }

    return map(available, (item: keyof typeof choices) => (
      <Tag
        key={item}
        text={choices[item]}
        onClick={() => this.changePatternItem(item, true)}
        style={{ display: 'inline-block', marginRight: 10 }}
      />
    ));
  };

  displayChosenPattern = () => {
    const { t } = this.props;

    const { choices, selected } = this.state;
    const readOnlyDefaults = {
      invoiceId: t('accounting.filenameChoices.invoiceId'),
    };
    const readOnlyChoices = keys(readOnlyDefaults);
    const allChoices = (selected ?? []).concat(readOnlyChoices);

    return map(allChoices, (key: string, index: number) => {
      const isReadOnly = index >= allChoices.length - readOnlyChoices.length;
      const clickHandler = isReadOnly
        ? noop
        : () => this.changePatternItem(key, false);
      return (
        <Tag
          key={key}
          theme="info"
          // @ts-expect-error wont fix during ts migration
          text={isReadOnly ? readOnlyDefaults[key] : choices[key]}
          onClick={clickHandler}
          hasCloseIcon={!isReadOnly}
          isDisabled={isReadOnly}
          style={{ display: 'inline-block', marginRight: 10 }}
        />
      );
    });
  };

  changePatternItem = (item: string, chosen = true) => {
    const { t } = this.props;
    const selected = chosen
      ? (this.state.selected ?? []).concat(item) // add item to pattern
      : without(this.state.selected, item); // remove item to pattern

    if (!this.isPatternValid(selected)) {
      this.setState({ displayError: true });
      return;
    }

    this.setState({ selected, displayError: false }, async () => {
      const invoicePattern = compact(this.state.selected);
      // Save pattern remotely & locally
      try {
        await this.props.updateInvoicePattern(invoicePattern);
        this.props.pushNotif({
          message: t('accounting.successUpdateInvoicePattern'),
        });
      } catch {
        this.props.pushNotif({
          type: NotificationType.Danger,
          message: t('misc.somethingWrong'),
        });
      }
    });
  };

  isPatternValid = (pattern: string[]) => {
    return pattern.some((item) => item === 'payableId' || item === 'receiptId');
  };

  render() {
    const { t } = this.props;
    const { displayError } = this.state;

    return (
      <div>
        <h1 className="text-complementary title-xl">
          {t('exports.receiptsFileNamesTitle')}
        </h1>
        <p className="mb-s text-neutral-dark body-m">
          {t('exports.receiptsFileNamesSubTitle')}
        </p>
        <div className="box">
          <div className="mb-s">{this.displayAvailableChoices()}</div>
          <div className="min-h-xxl rounded-xxs border border-solid border-neutral-light px-s pb-xs pt-s">
            {this.displayChosenPattern()}
          </div>
          <div className="mt-xxs text-neutral-dark">{this.getPreview()}</div>
          {displayError && (
            <div className="text-alert-light">
              {t('exports.receiptsFileNamesError')}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(InvoicesPattern);
