import {
  type Dispatch,
  type AnyAction,
  type ThunkDispatch,
} from '@reduxjs/toolkit';

import {
  NotificationType,
  addNotification,
  removeNotification,
  type Notification,
} from 'modules/app/notifications';
import { companyAPI } from 'src/core/api/axios';
import i18n from 'src/core/config/i18n';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { apiUrl } from 'src/core/utils/api';
import { poll, POLL_STRATEGIES } from 'src/core/utils/async';
import { downloadFromUrl } from 'src/core/utils/fileDownloader';

import { type UnreadExportsActions } from './actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import { type BookkeepingExport } from '../types';
import { isExportProcessing, isExportSuccess, isExportFailure } from '../utils';

export const fetchUnreadExports = () => {
  return async (
    dispatch: ThunkDispatch<AppState, null, UnreadExportsActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    let unreadExports;
    try {
      const res = await companyAPI.get('/accounts/statements/export/unread', {
        companyId,
      });
      unreadExports = res.data;
    } catch {
      return;
    }

    dispatch(actions.fetchUnreadExportsSuccess(unreadExports));
    dispatch(refreshProcessingExports());
    dispatch(displayProcessedExports());
  };
};

const refreshProcessingExports = () => {
  return (
    dispatch: ThunkDispatch<AppState, null, UnreadExportsActions>,
    getState: () => AppState,
  ): void => {
    const state = getState();
    const processingExports = selectors.getProcessingExports(state);

    processingExports.forEach((processingExport: BookkeepingExport) =>
      dispatch(refreshProcessingExport(processingExport)),
    );
  };
};

const refreshProcessingExport = (processingExport: BookkeepingExport) => {
  return async (
    dispatch: ThunkDispatch<AppState, null, UnreadExportsActions>,
    getState: () => AppState,
  ): Promise<void> => {
    if (!isExportProcessing(processingExport)) {
      return;
    }

    const state = getState();
    const companyId = getCompanyId(state);
    let processedExport;
    try {
      const result = await poll({
        fn: companyAPI.get,
        fnParams: [
          `/accounts/statements/export/${processingExport.id}`,
          { companyId },
        ],
        predicate: (res: { data: BookkeepingExport }) => {
          const updatedExport = res.data;
          return !isExportProcessing(updatedExport);
        },
        initialWaitDuration: 5000,
        timeStrategy: POLL_STRATEGIES.LINEAR,
        maxIterations: 120, // Until 10 minutes
      });

      processedExport = result.data;
    } catch {
      return;
    }

    dispatch(displayProcessedExport(processedExport));
  };
};

const displayProcessedExports = () => {
  return (
    dispatch: ThunkDispatch<AppState, null, UnreadExportsActions>,
    getState: () => AppState,
  ): void => {
    const state = getState();
    const processedExports = selectors.getProcessedExports(state);

    processedExports.forEach((processedExport: BookkeepingExport) => {
      dispatch(displayProcessedExport(processedExport));
    });
  };
};

const displayedNotifCache = new Map();

const displayProcessedExport = (processedExport: BookkeepingExport) => {
  return (dispatch: ThunkDispatch<AppState, null, AnyAction>): void => {
    if (
      isExportSuccess(processedExport) &&
      displayedNotifCache.get(processedExport.id) !== 'success'
    ) {
      dispatch(
        addNotification({
          type: NotificationType.Success,
          message: i18n.t('bookkeep.export.asyncExportNotifications.success'),
          canExpire: false,
          onClose: () => {
            dispatch(markExportAsRead(processedExport.id));
          },
          action: {
            text: i18n.t(
              'bookkeep.export.asyncExportNotifications.successDownloadCta',
            ),
            onClick: async (notification: Notification): Promise<void> => {
              try {
                await dispatch(downloadExportFile(processedExport.id));
              } catch {
                dispatch(
                  addNotification({
                    type: NotificationType.Danger,
                    message: i18n.t(
                      'bookkeep.export.asyncExportNotifications.downloadExportFileFailure',
                    ),
                  }),
                );
              } finally {
                dispatch(removeNotification(notification.id));
              }
            },
          },
        }),
      );
      displayedNotifCache.set(processedExport.id, 'success');
    } else if (
      isExportFailure(processedExport) &&
      displayedNotifCache.get(processedExport.id) !== 'failure'
    ) {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('bookkeep.export.asyncExportNotifications.failure', {
            error: processedExport.error,
          }),
          canExpire: false,
          onClose: () => {
            dispatch(markExportAsRead(processedExport.id));
          },
        }),
      );
      displayedNotifCache.set(processedExport.id, 'failure');
    }
  };
};

const downloadExportFile = (exportId: string) => {
  return async (
    _: Dispatch<AnyAction>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    const downloadUrl = apiUrl(
      `/accounts/statements/download/${exportId}`,
      companyId,
    );
    downloadFromUrl(downloadUrl);
  };
};

const markExportAsRead = (exportId: string) => {
  return async (
    _: Dispatch<AnyAction>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    await companyAPI.put(
      `/accounts/statements/export/${exportId}`,
      { isRead: true },
      { companyId },
    );
  };
};
