import { type ApprovalRule } from 'modules/company/structure/approval-flows';
import { type UserDetails } from 'src/core/common/components/form/models/user';

export type CostCenterId = string;

export type CostCenter = {
  id: CostCenterId;
  name: string;
  isDeleted?: boolean;
  approvalSchemeId: string;
  ownerId: string;
  viewerIds: string[];
  approvalRules: ApprovalRule[];
};

export type CostCenterWithApprovalRules = CostCenter & {
  ownerId: string;
  viewerIds: string[];
  approvalRules: ApprovalRule[];
};

export const getUserDefaultCostCenter = (
  costCenters: CostCenter[],
  user: UserDetails,
): CostCenter | null => {
  const defaultCostCenter = user.costCenter
    ? {
        ...user.costCenter,
        ownerId: '',
        viewerIds: [],
        approvalRules: [],
        approvalSchemeId: '',
      }
    : costCenters.find((costCenter) => costCenter.id === user.cost_center_id);
  return defaultCostCenter ?? null;
};

export const getIsCostCenterRequired = (costCenters: CostCenter[]): boolean =>
  costCenters.length > 0;
