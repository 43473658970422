import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { UPDATE_SUPPLIER_DETAILS } from '../queries/updateSupplierDetails';

type Payload = {
  supplierId: string;
  accountHolderName?: string;
  bankDetails?: {
    bankCountry?: string | null;
    iban?: string | null;
    bicSwift?: string | null;
    sortCode?: string | null;
    routingNumber?: string | null;
    accountNumber?: string | null;
    accountCode?: string | null;
    bankAccountDetailsFile?: string | null;
  } | null;
  legalDetails?: {
    legalName?: string | null;
    registrationNumber?: string | null;
    vatNumber?: string | null;
    addressCountry?: string | null;
    postalCode?: string | null;
    streetAddress?: string | null;
    addressLocality?: string | null;
  } | null;
};

type RawResponse = {
  updateSupplierDetails: {
    reason?: string;
  };
};

type Response = {
  updateSupplierDetails: {
    reason?: string;
  };
};

export const useUpdateSupplierDetailsMutation = (
  supplierId: string,
): MutationState<Payload, Response> => {
  return useMutation<Payload, Response, RawResponse>({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: UPDATE_SUPPLIER_DETAILS,
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: (data): void => {
        data.client.invalidateQueries([
          'suppliers',
          'suppliersDetails',
          supplierId,
        ]);
      },
    },
  });
};
