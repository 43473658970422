import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type GraphDataItem } from '../graphDataItem';

export const useGraphDataQuery = (): QueryState<GraphDataItem[]> => {
  return useQuery<GraphDataItem[]>({
    key: 'dashboard-graph',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/dashboard/graph',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reshapeData: (data: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.graph.map((item: any) => {
        return {
          invoiceState: item.invoice_state,
          invoiceDate: new Date(item.invoice_date),
          date: item.date,
          sum: item.sum,
          count: item.count,
        };
      });
    },
  });
};
