import React from 'react';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
  };
  user: {
    is_account_owner: boolean;
    is_controller: boolean;
  };
  className?: string;
  onClick?: () => void;
};

export const BankLink = ({ company, className, onClick }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavigationLink
      data-marketing="top-menu_wallet"
      key="nav_your_wallet"
      className={className}
      onClick={onClick}
      path={routeFor(routes.COMPANY_BANK.path, {
        company: company.id,
      })}
      text={t('settings.links.yourWallet')}
    />
  );
};
