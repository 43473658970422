import {
  type CustomFieldAssociation,
  buildCustomFieldAssociations,
} from './customFieldAssociation';

export interface CustomField {
  id: string;
  field: {
    id: string;
    perms_add_values: string[]; // ['05_002_0', '07_004_0', '03_006_2']
    perms_visibility: string[];
    name: string;
    type: 'list' | 'boolean';
    is_all_scopes: boolean;
    is_required: boolean;
    deleted_at: null | string;
    eligible_types: { type: string }[];
    scopes: { entity_type: string; entity_id: string }[];
  };
  value: { id: string; value: string; is_default: boolean };
}

export const customFieldToCustomFieldAssociation = (
  customField: CustomField,
): CustomFieldAssociation => ({
  customFieldId: customField.field.id,
  customFieldValueId: customField.value.id,
  value: customField.value.value,
});

export const customFieldsToCustomFieldAssociation = (
  customFields: CustomField[],
): CustomFieldAssociation[] =>
  buildCustomFieldAssociations(
    customFields.map(customFieldToCustomFieldAssociation),
  );
