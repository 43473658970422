import { useEffect, useState } from 'react';

import { normalizeString } from "src/core/common/utils/normalizeString";

import type { Option } from './option';

export const useSearch = <T extends Option>(
  options: T[],
): { filteredOptions: T[]; handleSearch(value: string | undefined): void } => {
  const [searchTerms, setSearchTerms] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option: T) => {
        const normalizedItemName = normalizeString(option.label);
        const normalizedQuery = normalizeString(searchTerms);
        return normalizedItemName.includes(normalizedQuery);
      }),
    );
  }, [searchTerms, options]);

  const handleSearch = (value: string | undefined): void => {
    setSearchTerms(value || '');
  };

  return { filteredOptions, handleSearch };
};
