import React from 'react';

import { SettingsSubNavigation } from 'src/core/common/components/layout/SettingsLayout/SettingsSubNavigation/SettingsSubNavigation';

import { MainNavigation } from './MainNavigation';
import { ProfileSubNavigation } from './ProfileSubNavigation';

export const Header = () => (
  <header>
    <MainNavigation />
    <SettingsSubNavigation />
    <ProfileSubNavigation />
  </header>
);
