import { useQuery } from 'src/core/api/hooks/useQuery';

import { GET_ACTIVE_ACCOUNTING_INTEGRATION } from '../graphql/queries';
import {
  type AccountingSoftware,
  type AccountingSoftwareGraphQl,
  toAccountingSoftware,
} from '../integration/status';

export type RawData = {
  company: {
    activeAccountingIntegration: AccountingSoftwareGraphQl;
  };
};

export type Result = {
  activeAccountingIntegration: AccountingSoftware | 'noIntegration';
};

function reshapeData(rawData: RawData): Result {
  return {
    activeAccountingIntegration: toAccountingSoftware(
      rawData.company.activeAccountingIntegration,
    ),
  };
}

export const useActiveAccountingIntegration = () => {
  return useQuery<Result, RawData>({
    key: 'useActiveAccountingIntegration',
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_ACTIVE_ACCOUNTING_INTEGRATION,
    },
    reshapeData,
  });
};
