import { useAccountingIntegrationStatusQuery } from 'modules/accounting-integration/apis';

import { shouldHideAccountsPayable } from '../integration/status';

/**
 * Sadly this might result in text pop-in
 */
export function useMustDisplayAccountsPayable(): boolean {
  const accountingIntegrationStatusQueryResult =
    useAccountingIntegrationStatusQuery();

  return (
    accountingIntegrationStatusQueryResult.status === 'error' ||
    (accountingIntegrationStatusQueryResult.status === 'success' &&
      !shouldHideAccountsPayable(accountingIntegrationStatusQueryResult.data))
  );
}
