import { type Reducer } from '@reduxjs/toolkit';

import * as types from './actionTypes';
import { type WireTransferActivationStatus } from '../../general-settings/models';
import {
  type Address,
  type BankInformations,
  type Invoice,
  type Subscription,
} from '../companyBilling';

interface BillingReducerState {
  address: Address | null;
  invoices: Invoice[] | null;
  subscription: Subscription | null;
  isSubscriptionLoading: boolean;
  bankInfo: BankInformations | null;
  wireTransferActivationStatusForExpenses: WireTransferActivationStatus | null;
  wireTransferActivationStatusForInvoices: WireTransferActivationStatus | null;
  isWireTransferActivationStatusLoading: boolean;
}

const initialState: BillingReducerState = {
  address: null,
  invoices: null,
  subscription: null,
  isSubscriptionLoading: true,
  bankInfo: null,
  wireTransferActivationStatusForExpenses: null,
  wireTransferActivationStatusForInvoices: null,
  isWireTransferActivationStatusLoading: false,
};

export const billingReducer: Reducer<
  BillingReducerState,
  types.BillingActions
> = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BANK_INFO_SUCCESS:
      return { ...state, bankInfo: action.payload };

    case types.FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_SUCCESS: {
      return {
        ...state,
        wireTransferActivationStatusForExpenses:
          action.payload.forExpenseClaims,
        wireTransferActivationStatusForInvoices: action.payload.forInvoices,
        isWireTransferActivationStatusLoading: false,
      };
    }

    case types.UPDATE_WIRE_TRANSFER_ACTIVATION_STATUS: {
      const { payload } = action;

      return {
        ...state,
        ...(payload.wireTransferPayableEntity === 'expenses'
          ? { wireTransferActivationStatusForExpenses: payload.status }
          : { wireTransferActivationStatusForInvoices: payload.status }),
      };
    }

    case types.FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_LOADING: {
      return { ...state, isWireTransferActivationStatusLoading: true };
    }

    case types.FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_ERROR: {
      return { ...state, isWireTransferActivationStatusLoading: false };
    }

    default:
      return state;
  }
};
