import { type MonetaryValue, add } from 'ezmoney';

export type Budget = {
  id: string;
  name: string;
  budgetaryExerciseId: string;
  ownerId: string;
  costCenterId: string;
  periodAmounts: MonetaryValue[];
  isUnavailable?: boolean;
};

export type RawBudget = {
  id: string;
  budgetaryExerciseId: string;
  ownerId: string;
  costCenterId: string;
  periodAmountsByExpenseCategory: { [ec: string]: MonetaryValue[] };
};

export const reshapeRawBudget = (rawBudget: RawBudget): Budget => {
  return {
    id: rawBudget.id,
    // FIXME: should be directly returned by the API
    name: '', // enriched by the caller
    budgetaryExerciseId: rawBudget.budgetaryExerciseId,
    ownerId: rawBudget.ownerId,
    costCenterId: rawBudget.costCenterId,
    periodAmounts: getBudgetPeriodAmounts(
      rawBudget.periodAmountsByExpenseCategory,
    ),
  };
};

export const getBudgetAmount = (budget: Budget): MonetaryValue | undefined => {
  return budget.periodAmounts.reduce<MonetaryValue | undefined>(
    (sum, current) => {
      return !sum ? current : add(sum, current);
    },
    undefined,
  );
};

const getBudgetPeriodAmounts = (
  periodAmounts: RawBudget['periodAmountsByExpenseCategory'],
): MonetaryValue[] => {
  return Object.values(periodAmounts).reduce<MonetaryValue[]>(
    (monetaryValues, amounts) => {
      const sumAmounts = amounts.reduce<MonetaryValue | undefined>(
        (sum, amount) => (!sum ? amount : add(sum, amount)),
        undefined,
      );

      return sumAmounts ? monetaryValues.concat(sumAmounts) : monetaryValues;
    },
    [],
  );
};
