import { Banner, IconButton } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { LocalStorageKey } from 'src/core/constants/storage';
import { getLocalItem, setLocalItem } from 'src/core/utils/storage';

import qrMobile from './assets/qr-mobile.svg';

export const MobileBanner = () => {
  const { t } = useTranslation('global');
  const [hideMobileBanner, setHideMobileBanner] = useState(false);

  if (
    hideMobileBanner ||
    getLocalItem(LocalStorageKey.MyCardMobileBannerDismissed)
  ) {
    return null;
  }

  return (
    <div className="relative">
      <Banner
        illustration={
          <img src={qrMobile} alt="" className="w-[130px] object-contain" />
        }
        actionText={t('cards.mobileCallout.cta')}
        title={t('cards.mobileCallout.title')}
        onClick={() => {
          window.open('https://www.spendesk.com/mobile/', '_blank');
        }}
        className="mb-m"
      >
        <p>{t('cards.mobileCallout.description')}</p>
      </Banner>
      <IconButton
        aria-label="close-banner"
        className="absolute right-[4px] top-[4px]"
        iconName="cross"
        variant="borderless"
        onClick={() => {
          setLocalItem(LocalStorageKey.MyCardMobileBannerDismissed, true);
          setHideMobileBanner(true);
        }}
      />
    </div>
  );
};
