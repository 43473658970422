/* eslint-disable unicorn/no-await-expression-member */
import React from 'react';

import { EmptyStateError, lazyLoad } from 'src/core/common/components/LazyLoad';

import { IntegrationAccountingPageSkeleton } from './components/IntegrationAccountingPageSkeleton';

export const LegacyIntegrationAccountingPageContainer = lazyLoad({
  loader: async () => ({
    default: (
      await import(
        /* webpackChunkName: "IntegrationAccountingPageContainer" */ './LegacyIntegrationAccountingPageContainer'
      )
    ).default,
  }),
  loading: <IntegrationAccountingPageSkeleton />,
  fallback: (props) => <EmptyStateError {...props} />,
});
