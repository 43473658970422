import React from 'react';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { UnexpectedError } from 'src/core/common/components/UnexpectedError/UnexpectedError';

import { useGetIntegrations } from '../../../hooks';
import { IntegrationsList, IntegrationsListSkeleton } from '../../components';

const IntegrationsListContainer = () => {
  const integrationsQuery = useGetIntegrations();
  if (integrationsQuery.status === 'loading') {
    return <IntegrationsListSkeleton />;
  }
  if (integrationsQuery.status === 'error') {
    return <UnexpectedError />;
  }
  return <IntegrationsList integrationStatus={integrationsQuery.data} />;
};

export default withErrorBoundary({
  scope: 'integrations-list-container',
  team: 'api-integration',
})(IntegrationsListContainer);
