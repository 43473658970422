import React from 'react';

import { PDFViewer, ImageViewer } from 'src/core/common/components/FileViewer';

export type Document = {
  url: string;
  mediaType: string;
  validity: {
    isValid: boolean;
    reason: string | undefined;
  };
  path: string;
};

export const getDocumentViewer = (
  document: Document,
  onClose: () => void,
  onDelete?: () => void,
) => {
  if (document.mediaType === 'application/pdf') {
    return (
      <PDFViewer
        className="w-full"
        url={document.url}
        isInvalid={!document.validity.isValid}
        invalidProofReason={document.validity.reason}
        onDownload={() => window.open(document.url, '_blank')}
        onClose={onClose}
        onDelete={onDelete}
      />
    );
  }
  return (
    <ImageViewer
      withoutCrossOrigin
      withZoom
      withRotate
      url={document.url}
      isInvalid={!document.validity.isValid}
      invalidProofReason={document.validity.reason}
      onDownload={() => window.open(document.url, '_blank')}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};
