import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from "src/core/common/hooks/useTranslation";

import { type BudgetaryExerciseInput } from './budgetaryExerciseInput';

export const validate = (
  values: BudgetaryExerciseInput,
  translator: TGlobalFunctionTyped,
): FormikErrors<BudgetaryExerciseInput> => {
  const errors: FormikErrors<BudgetaryExerciseInput> = {};

  if (!values.name || values.name.trim() === '') {
    errors.name = translator('budget.createBEModal.validation.errorName');
  }

  return errors;
};
