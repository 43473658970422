import { type MonetaryValue } from 'ezmoney';

import { type SupplierDetails } from 'modules/bookkeep/accounts-payable/types';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { GET_SUPPLIER_DETAILS } from '../queries/getSupplierDetails';

type RawData = {
  supplier: {
    id: string;
    name: string;
    thumbnailUrl: string;
    isArchived: boolean;
    accountHolderName: string | null;
    bankDetails: {
      bankCountry: string;
      iban: string | null;
      bicSwift: string | null;
      sortCode: string | null;
      routingNumber: string | null;
      accountNumber: string | null;
      accountCode: string | null;
      bankAccountDetailsFileUrl: string | null;
      bankAccountDetailsThumbnailUrl: string | null;
      bankAccountDetailsMediaType: string | null;
    } | null;
    legalDetails: {
      legalName: string;
      registrationNumber: string | null;
      vatNumber: string | null;
      addressCountry: string | null;
      postalCode: string | null;
      streetAddress: string | null;
      addressLocality: string | null;
    } | null;
    supplierAccount: {
      id: string;
      generalAccountCode: string;
      auxiliaryAccountCode: string | undefined;
    };
    payablesAggregate: {
      sum: {
        functionalAmount: MonetaryValue;
      };
    };
    expenseAccountSupplierRule: {
      expenseAccount: {
        id: string;
        name: string;
        isArchived: boolean;
      };
    } | null;
  };
};

export const useSupplierDetailsQuery = (
  supplierId: string,
): QueryState<SupplierDetails> => {
  return useQuery<SupplierDetails, RawData>({
    key: ['suppliers', 'suppliersDetails', supplierId],
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_SUPPLIER_DETAILS,
      variables: {
        id: supplierId,
      },
    },
    reshapeData: (rawData: RawData) => {
      return {
        id: rawData.supplier.id,
        name: rawData.supplier.name,
        thumbnailUrl: rawData.supplier.thumbnailUrl,
        isArchived: rawData.supplier.isArchived,
        accountHolderName: rawData.supplier.accountHolderName,
        bankDetails: rawData.supplier.bankDetails
          ? {
              bankCountry: rawData.supplier.bankDetails.bankCountry,
              iban: rawData.supplier.bankDetails.iban,
              bicSwift: rawData.supplier.bankDetails.bicSwift,
              sortCode: rawData.supplier.bankDetails.sortCode,
              routingNumber: rawData.supplier.bankDetails.routingNumber,
              accountNumber: rawData.supplier.bankDetails.accountNumber,
              accountCode: rawData.supplier.bankDetails.accountCode,
              bankAccountDetailsFileUrl:
                rawData.supplier.bankDetails.bankAccountDetailsFileUrl,
              bankAccountDetailsThumbnailUrl:
                rawData.supplier.bankDetails.bankAccountDetailsThumbnailUrl,
              bankAccountDetailsMediaType:
                rawData.supplier.bankDetails.bankAccountDetailsMediaType,
            }
          : null,
        legalDetails: rawData.supplier.legalDetails
          ? {
              legalName: rawData.supplier.legalDetails.legalName,
              registrationNumber:
                rawData.supplier.legalDetails.registrationNumber,
              vatNumber: rawData.supplier.legalDetails.vatNumber,
              addressCountry: rawData.supplier.legalDetails.addressCountry,
              postalCode: rawData.supplier.legalDetails.postalCode,
              streetAddress: rawData.supplier.legalDetails.streetAddress,
              addressLocality: rawData.supplier.legalDetails.addressLocality,
            }
          : null,
        supplierAccount: rawData.supplier.supplierAccount,
        totalAmount: rawData.supplier.payablesAggregate?.sum.functionalAmount,
        expenseAccountSupplierRule: rawData.supplier.expenseAccountSupplierRule,
      };
    },
  });
};
