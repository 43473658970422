import React, { useEffect } from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { getReloadType } from 'src/core/utils/wallet';

import { CompanySwitcherMenu } from './CompanySwitcherMenu';

type Props = {
  budget?: number | null;
  hasHomepage: boolean;
  fetchMonthlyBudget: () => void;
};

export const CompanySwitcher = ({
  budget,
  hasHomepage,
  fetchMonthlyBudget,
}: Props) => {
  const user = useUser();
  const company = useCompany();
  const reloadType = getReloadType(company, budget);
  const showWallet = user.is_controller;

  useEffect(() => {
    fetchMonthlyBudget();
  }, []);

  // If the user belongs to only one company and doesn't have the permission
  // to see wallet don't show the CompanySwitcher
  if ((!user.company_ids || user.company_ids.length === 1) && !showWallet) {
    return null;
  }

  return (
    <CompanySwitcherMenu
      user={user}
      company={company}
      reloadType={reloadType}
      showWallet={showWallet}
      hasHomepage={hasHomepage}
    />
  );
};
