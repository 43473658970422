import {
  Button,
  Callout,
  DropdownItem,
  Icon,
  Label,
  Select,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import styles from './BudgetPeriodSelectorForm.module.css';
import {
  convertToDate,
  getBudgetaryExerciseYears,
  getMonthIndex,
  getTranslatedMonthOptions,
} from './dates';
import { validate, type FormState } from './form';

type Props = {
  onChange: ({ from, to }: { from: Date; to: Date }) => void;
  selectedDateRange: undefined | { from: Date; to: Date };
  maxRange: { from: Date; to: Date };
};

export const BudgetPeriodSelectorForm = ({
  onChange,
  selectedDateRange,
  maxRange,
}: Props) => {
  const { t } = useTranslation('global');
  const [isFormOpened, setIsFormOpened] = useState(false);
  const formikProps = useFormik<FormState>({
    initialValues: {
      fromMonthIndex: selectedDateRange
        ? getMonthIndex(selectedDateRange.from)
        : undefined,
      fromYear: getBudgetaryExerciseYears(maxRange)[0],
      toMonthIndex: selectedDateRange
        ? getMonthIndex(selectedDateRange.to)
        : undefined,
      toYear:
        getBudgetaryExerciseYears(maxRange)[
          getBudgetaryExerciseYears(maxRange).length - 1
        ],
    },
    validateOnChange: false,
    validate: (values) => validate(values, t, maxRange),
    onSubmit: async (values) => {
      let { fromMonthIndex, toMonthIndex } = values;
      if (
        values.fromMonthIndex === undefined ||
        values.toMonthIndex === undefined
      ) {
        // if only one of the values selected, consider it as one month
        fromMonthIndex = values.fromMonthIndex ?? values.toMonthIndex;
        toMonthIndex = values.toMonthIndex ?? values.fromMonthIndex;
      }
      if (values.fromYear && values.toYear && fromMonthIndex && toMonthIndex) {
        onChange({
          from: convertToDate(fromMonthIndex, values.fromYear),
          to: convertToDate(toMonthIndex, values.toYear),
        });
      }
    },
  });

  const monthOptions = useMemo(
    () =>
      [...Array(12).keys()].map((_, index) =>
        getTranslatedMonthOptions(index, t),
      ),
    [selectedDateRange],
  );

  const yearOptions = useMemo(() => {
    return getBudgetaryExerciseYears(maxRange).map((year) => ({
      key: year,
      label: year,
    }));
  }, [selectedDateRange]);

  return (
    <div>
      <div className="separator mt-xs" />
      <div>
        <button
          className={classNames(
            'w-full rounded-xs border-none bg-white text-neutral-dark body-m',
            styles.dropdown,
          )}
          type="button"
          onClick={() => setIsFormOpened(!isFormOpened)}
        >
          <DropdownItem
            label={
              <div className="flex w-full items-center justify-between">
                <div>{t('budget.breakdownSpendings.periods.customForm')}</div>
                <Icon name={isFormOpened ? 'caret-down' : 'caret-right'} />
              </div>
            }
          />
        </button>
        {isFormOpened && (
          <form className="max-h-[500px] text-left">
            <div className="m-xs">
              <div className="mb-s mt-xs">
                <Label className="mb-xs" label={t('misc.from')} />
                <div className="flex gap-xs">
                  <Select
                    className={styles.select}
                    onSelect={(option) => {
                      formikProps.setFieldValue('fromMonthIndex', option.key);
                    }}
                    value={
                      formikProps.values.fromMonthIndex !== undefined
                        ? getTranslatedMonthOptions(
                            formikProps.values.fromMonthIndex,
                            t,
                          )
                        : undefined
                    }
                    options={monthOptions}
                    placeholder={t(
                      'budget.breakdownSpendings.monthPlaceholder',
                    )}
                  />
                  <Select
                    placeholder={t('budget.breakdownSpendings.yearPlaceholder')}
                    onSelect={(option) => {
                      formikProps.setFieldValue('fromYear', option.key);
                    }}
                    options={yearOptions}
                    isDisabled={
                      getBudgetaryExerciseYears(maxRange).length === 1
                    }
                    value={
                      formikProps.values.fromYear
                        ? {
                            key: formikProps.values.fromYear,
                            label: formikProps.values.fromYear,
                          }
                        : undefined
                    }
                    className={styles.select}
                  />
                </div>
                {formikProps.errors.fromMonthIndex && (
                  <div className={styles.callout}>
                    <Callout
                      className="mt-xs"
                      title={formikProps.errors.fromMonthIndex}
                      variant="alert"
                    />
                  </div>
                )}
              </div>
              <div className="mb-s">
                <Label className="mb-xs" label={t('misc.to')} />
                <div className="flex gap-xs">
                  <Select
                    className={styles.select}
                    onSelect={(option) => {
                      formikProps.setFieldValue('toMonthIndex', option.key);
                    }}
                    value={
                      formikProps.values.toMonthIndex !== undefined
                        ? getTranslatedMonthOptions(
                            formikProps.values.toMonthIndex,
                            t,
                          )
                        : undefined
                    }
                    options={monthOptions}
                    placeholder={t(
                      'budget.breakdownSpendings.monthPlaceholder',
                    )}
                  />
                  <Select
                    placeholder={t('budget.breakdownSpendings.yearPlaceholder')}
                    onSelect={(option) => {
                      formikProps.setFieldValue('toYear', option.key);
                    }}
                    className={styles.select}
                    options={yearOptions}
                    isDisabled={
                      getBudgetaryExerciseYears(maxRange).length === 1
                    }
                    value={
                      formikProps.values.toYear
                        ? {
                            key: formikProps.values.toYear,
                            label: formikProps.values.toYear,
                          }
                        : undefined
                    }
                  />
                </div>
                {formikProps.errors.toMonthIndex && (
                  <div className={styles.callout}>
                    <Callout
                      className="mt-xs"
                      title={formikProps.errors.toMonthIndex}
                      variant="alert"
                    />
                  </div>
                )}
              </div>
              <Button
                text={t('budget.breakdownSpendings.apply')}
                onClick={() => formikProps.handleSubmit()}
                fit="parent"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
