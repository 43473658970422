import { type SupplierAccountDefaultFor } from '../../../settings/accounting';

export type InputValueId = string;

export type InputValueEntry = {
  key: InputValueId;
  label: string;
  archiveDate?: string | null;
};

export type SelectInputConfig = {
  id: string;
  name: string;
  type: 'select';
  values: InputValueEntry[];
  selectedValue?: InputValueId;
  onSearchValues?: (searchQuery: string) => InputValueEntry[];
} & AdditionalConfig;

export type SupplierInputValueEntry = {
  key: InputValueId;
  label: string;
  archiveDate?: string | null;
  defaultFor?: SupplierAccountDefaultFor;
  isDefault?: boolean;
};

type SelectSupplierInputConfig = {
  id: string;
  name: string;
  type: 'select';
  values: SupplierInputValueEntry[];
  selectedValue?: InputValueId;
  onSearchValues?: (searchQuery: string) => SupplierInputValueEntry[];
} & AdditionalConfig;

type BooleanInputConfig = {
  id: string;
  name: string;
  type: 'boolean';
  selectedValue?: boolean | InputValueId;
  values?: { key: InputValueId; label: string | boolean }[];
} & AdditionalConfig;

type SearchInputConfig = {
  id: string;
  name: string;
  type: 'search';
  selectedValue?: InputValueId;
  onLoadValues: (ids: InputValueId[]) => Promise<InputValueEntry[]>;
  onSearchValues: (searchQuery: string) => Promise<InputValueEntry[]>;
} & AdditionalConfig;

type AdditionalConfig = {
  isOptional?: boolean;
  isCustom?: boolean;
  isReadOnly?: boolean;
  isSplittable?: boolean;
  placeholder?: string;
  allowNewValue?: boolean;
  totalCount?: number;
  'data-testid'?: string;
  onCreateNewValue?: (newValue: string) => Promise<string> /* TODO: remove */;
};

export type PreparePayablesInputConfig =
  | SelectInputConfig
  | SelectSupplierInputConfig
  | BooleanInputConfig
  | SearchInputConfig;

export const isSelectInputConfig = (
  inputConfig: PreparePayablesInputConfig,
): inputConfig is SelectInputConfig => {
  return inputConfig.type === 'select';
};

export const isSearchInputConfig = (
  inputConfig: PreparePayablesInputConfig,
): inputConfig is SearchInputConfig => {
  return inputConfig.type === 'search';
};

export const isBooleanInputConfig = (
  inputConfig: PreparePayablesInputConfig,
): inputConfig is BooleanInputConfig => {
  return inputConfig.type === 'boolean';
};
