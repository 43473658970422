import { useInvalidateCostCentersQuery } from 'modules/budgets/apis';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type FileInvalidError = {
  reason: 'fileInvalid';
  error: 'unreadableFile' | 'inconsistentValues' | 'invalidValues';
};

type FileDataInvalid = {
  reason: 'fileDataInvalid';
  errors: {
    type:
      | 'costCenterAlreadyExisting'
      | 'costCenterAlreadyDefined'
      | 'userNotFound';
    value: string;
  }[];
};

// eslint-disable-next-line @typescript-eslint/ban-types
type ImportCostCenterRequestSuccess = {};

export type ImportCostCenterRequestError =
  | FileInvalidError
  | FileDataInvalid
  | {
      reason:
        | 'fileNotFound'
        | 'companyNotFound'
        | 'costCenterFeatureDisabled'
        | 'costCenterCreationFailed';
    };

export const useImportCostCentersMutation = (): MutationState<
  FormData,
  ImportCostCenterRequestSuccess,
  ImportCostCenterRequestError
> => {
  const invalidateCostCentersQuery = useInvalidateCostCentersQuery();

  return useMutation<
    FormData,
    ImportCostCenterRequestSuccess,
    ImportCostCenterRequestSuccess,
    ImportCostCenterRequestError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/cost-centers/import`,
      method: 'post',
    },
    reshapeData(data) {
      return data;
    },
    options: {
      throwOnError: true,
      onSuccess(): void {
        invalidateCostCentersQuery();
      },
    },
  });
};

export const reshapeToFormData = (completedCostCenters: File): FormData => {
  const formData = new FormData();
  formData.append(
    'completedCostCenters',
    completedCostCenters,
    completedCostCenters.name,
  );
  return formData;
};
