import React from 'react';
import { connect } from 'react-redux';

import { useUser } from 'modules/app/hooks/useUser';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';
import { getImpersonator } from 'src/core/selectors/globalSelectors';

type Props = {
  impersonator?: {
    id: string;
  };
  children: React.ReactNode;
};

const NewBranchButtonComponent = ({ impersonator, children }: Props) => {
  const user = useUser();
  const hasBranchCreationFeature = useFeature(FEATURES.ORGANISATION_BRANCHES);

  if (
    !hasBranchCreationFeature ||
    !user.is_organisation_owner ||
    Boolean(impersonator)
  ) {
    return null;
  }

  return <>{children}</>;
};

const mapStateToProps = (state: AppState) => ({
  impersonator: getImpersonator(state),
});
export const NewBranchButton = connect(mapStateToProps)(
  NewBranchButtonComponent,
);
