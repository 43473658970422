import { Tag } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { isKeyActive } from '../domain';

type Props = {
  apiAccess: {
    expiredAt: Date;
    active: boolean;
  };
};

const ApiAccessStateTag = ({ apiAccess }: Props) => {
  const { t } = useTranslation('global');

  return isKeyActive(apiAccess) ? (
    <Tag variant="success">{t('publicApi.flowApiKey.main.active')}</Tag>
  ) : (
    <Tag variant="neutral">{t('publicApi.flowApiKey.main.revoked')}</Tag>
  );
};

export default ApiAccessStateTag;
