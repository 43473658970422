import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type DefaultEmployeeAccount } from '../../accounting/employeeAccount';
import { GET_DEFAULT_EMPLOYEE_ACCOUNTS } from '../../accounting/graphql/queries';

type RawData = {
  company: {
    chartOfAccounts: {
      employeeAccounts: {
        edges: {
          cursor: string;
          node: {
            generalAccountCode: string;
            auxiliaryAccountCode: string | undefined;
            isDefault: boolean;
            isArchived: boolean;
            id: string;
          };
        }[];
      };
    };
  };
};

export const useGetDefaultEmployeeAccountQuery__deprecated = (
  isEnabled = false,
): QueryState<DefaultEmployeeAccount[]> => {
  const { company: companyId } = useParams();

  return useQuery<DefaultEmployeeAccount[], RawData>({
    key: ['accountsPayable', 'getDefaultEmployeeAccountQuery', companyId],
    isEnabled,
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_DEFAULT_EMPLOYEE_ACCOUNTS,
    },
    reshapeData(data) {
      return data.company.chartOfAccounts.employeeAccounts.edges.map(
        ({ node }) => {
          return {
            id: node.id,
            generalAccountCode: node.generalAccountCode,
            auxiliaryAccountCode: node.auxiliaryAccountCode,
            name: '',
            isArchived: node.isArchived,
          };
        },
      );
    },
  });
};
