import { Avatar, ListBox } from '@dev-spendesk/grapes';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import {
  models as approvalModels,
  selectors as approvalSelectors,
  type ApprovalRule,
} from 'modules/company/structure/approval-flows';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from "src/core/common/hooks/useTranslation";
import { type AppState } from "src/core/reducers";
import { getUsers } from "src/core/selectors/users";
import { formatMoney } from "src/core/utils/money";

import './TeamApprovalPreview.css';

type Props = {
  selectedGroupId: string;
  rules?: ApprovalRule[];
  members?: {
    id: string;
    email: string;
    fullname: string;
    avatar: string;
    pending: boolean;
  }[];
};

const renderFirstRule = (
  rule: ApprovalRule,
  t: TGlobalFunctionTyped,
  titleId: string,
) => (
  <div data-testid="ApprovalPreview_firstRule" id={titleId}>
    <span className="ApprovalPreview__rule__upTo__text">
      {t('teams.approvalFlows.upTo')}&nbsp;
    </span>
    <span className="ApprovalPreview__rule__upTo__value">
      {formatMoney(rule.upTo.value || 0, rule.upTo.currency)}
    </span>
  </div>
);

const renderFromToRule = (
  rule: ApprovalRule,
  t: TGlobalFunctionTyped,
  titleId: string,
) => (
  <div data-testid="ApprovalPreview_fromToRule" id={titleId}>
    <span className="ApprovalPreview__rule__from__text">
      {t('teams.approvalFlows.from')}&nbsp;
    </span>
    <span className="ApprovalPreview__rule__from__value">
      {formatMoney(rule.from.value || 0, rule.from.currency)}&nbsp;
    </span>
    <span className="ApprovalPreview__rule__upTo__text">
      {t('teams.approvalFlows.to')}&nbsp;
    </span>
    <span className="ApprovalPreview__rule__upTo__value">
      {formatMoney(rule.upTo.value || 0, rule.upTo.currency)}
    </span>
  </div>
);

const renderLastRule = (
  rule: ApprovalRule,
  t: TGlobalFunctionTyped,
  titleId: string,
) => (
  <div data-testid="ApprovalPreview_lastRule" id={titleId}>
    <span className="ApprovalPreview__rule__from__text">
      {t('teams.approvalFlows.above')}&nbsp;
    </span>
    <span className="ApprovalPreview__rule__from__value">
      {formatMoney(rule.from.value || 0, rule.from.currency)}
    </span>
  </div>
);

const matchRule = (rules: ApprovalRule[], ruleIndex: number) => {
  if (ruleIndex === 0) {
    return renderFirstRule;
  }
  if (approvalModels.isTheLastRule(rules, ruleIndex)) {
    return renderLastRule;
  }
  return renderFromToRule;
};

const ApprovalPreview = ({ rules = [], members = [] }: Props) => {
  const { t } = useTranslation('global');

  return (
    <ListBox
      options={rules.map((rule, index) => ({
        ...rule,
        index,
      }))}
      getOptionId={(rule) => rule.id}
    >
      {(rule, titleId) => (
        <div className="ApprovalPreview__rule relative py-xs" key={rule.id}>
          <div
            className={
              rules.length === 1
                ? 'ApprovalPreview__rule__canApproveAll'
                : undefined
            }
          >
            {rules.length === 1
              ? t('teams.approvalFlows.canApproveAllRequests')
              : matchRule(rules, rule.index)(rule, t, titleId)}
          </div>
          {approvalModels.collectApproverIds(rule).map((approverId, index) => {
            const approver = members.find((member) => member.id === approverId);
            if (approver) {
              return (
                <Fragment key={approverId}>
                  {approvalModels.isMultiStep(rule.steps) && (
                    <i className="ApprovalPreview__rule__approver__line" />
                  )}
                  <div className="ApprovalPreview__rule__approver">
                    {approvalModels.isMultiStep(rule.steps) && (
                      <span className="ApprovalPreview__rule__approver__index">
                        {index + 1}
                      </span>
                    )}
                    <Avatar
                      className="mr-xs"
                      size="s"
                      src={approver.avatar}
                      text={
                        approver.pending ? approver.email : approver.fullname
                      }
                    />
                    <span className="ApprovalPreview__rule__approver__name">
                      {approver.pending ? approver.email : approver.fullname}
                    </span>
                  </div>
                </Fragment>
              );
            }
            return null;
          })}
        </div>
      )}
    </ListBox>
  );
};

const mapStateToProps = (state: AppState, { selectedGroupId }: Props) => ({
  rules: approvalSelectors.getRulesByTeamId(state, selectedGroupId),
  members: getUsers(state),
});

export const TeamApprovalPreview = connect(mapStateToProps)(ApprovalPreview);
