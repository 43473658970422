import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
};

export const CommitmentsLink = ({
  company,
  className,
  isInDropdown,
  onClick,
}: Props) => {
  const { t } = useTranslation('global');
  const { pathname: currentPath } = useLocation();

  return (
    <NavigationLink
      data-marketing="top-menu_commitments"
      key="nav_commitments"
      className={className}
      onClick={onClick}
      path={routeFor(routes.PURCHASE_ORDERS.path, {
        company: company.id,
      })}
      text={t('misc.commitments')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [routes.PURCHASE_ORDERS.path, routes.SUBSCRIPTIONS.path],
        }),
      )}
      isInDropdown={isInDropdown}
    />
  );
};
