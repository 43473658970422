import { Button, Icon, Modal, ModalFooter } from '@dev-spendesk/grapes';
import cx from 'classnames';
import queryString from 'query-string';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { ForwardInfos } from 'modules/payments/components/all/ForwardInfos';
import { routeFor, routes } from 'src/core/constants/routes';

import './ModalReminder.css';

type ModalReminderProps = {
  count: number;
  user: {
    id: string;
    fullname: string;
    is_controller: boolean;
  };
  onClose: () => void;
};

export const ModalReminder = ({ user, count, onClose }: ModalReminderProps) => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const companyId = useCompanyId();
  const [forwardReceiptsOpened, setForwardReceiptsOpened] = useState(false);
  const [whyUploadReceiptsOpened, setWhyUploadReceiptsOpened] = useState(false);

  const toggleForwardReceipts = () => {
    setForwardReceiptsOpened(!forwardReceiptsOpened);
    setWhyUploadReceiptsOpened(false);
  };

  const toggleWhyUpload = () => {
    setWhyUploadReceiptsOpened(!whyUploadReceiptsOpened);
    setForwardReceiptsOpened(false);
  };

  return (
    <Modal
      isOpen
      iconName="warning"
      iconVariant="warning"
      onClose={onClose}
      title={t('reminderModal.title', {
        name: user.fullname,
        count,
      })}
      subtitle={t('reminderModal.desc')}
    >
      <ModalFooter>
        <Button
          variant="secondary"
          key="notNow"
          onClick={onClose}
          text={t('reminderModal.notnow')}
        />
        <Button
          key="showPayments"
          onClick={() => {
            history.push({
              pathname: routeFor(routes.PAYMENTS_ALL.path, {
                company: companyId,
              }),
              search: queryString.stringify({
                invoice: 'missing',
                payer: user.id,
              }),
            });
            onClose();
          }}
          text={t('reminderModal.button')}
        />
      </ModalFooter>
      <div className="ModalReminder_expander">
        <button
          type="button"
          className={cx('opener resetButtonStyle w-full title-m', {
            opened: forwardReceiptsOpened,
          })}
          onClick={toggleForwardReceipts}
        >
          {t('reminderModal.forwardTitle')}
          <div style={{ flex: 1 }} />
          <Icon name="caret-right" className="caret" />
        </button>
        <AnimateHeight
          height={forwardReceiptsOpened ? 'auto' : 0}
          duration={220}
        >
          <div className="expander-content forward-receipts">
            <ForwardInfos inModal={false} />
          </div>
        </AnimateHeight>
      </div>
      <div className="ModalReminder_expander">
        <button
          type="button"
          className={cx('opener resetButtonStyle w-full title-m', {
            opened: whyUploadReceiptsOpened,
          })}
          onClick={toggleWhyUpload}
        >
          {t('reminderModal.whyUploadTitle')}
          <div style={{ flex: 1 }} />
          <Icon name="caret-right" className="caret" />
        </button>
        <AnimateHeight
          height={whyUploadReceiptsOpened ? 'auto' : 0}
          duration={220}
        >
          <div className="expander-content text-left">
            <p>{t('reminderModal.desc')}</p>

            <section className="mt-s">
              <h4>
                <strong>{t('reminderModal.whyUploadRulesTitle')}</strong>
              </h4>
              <p>{t('reminderModal.whyUploadRulesSubtitle')}</p>
              <ul className="ModalReminder__upload-rule-list">
                <li>
                  <Trans i18nKey="reminderModal.whyUploadDateRule" />
                </li>
                <li>
                  <Trans i18nKey="reminderModal.whyUploadSupplierRule" />
                </li>
                <li>
                  <Trans i18nKey="reminderModal.whyUploadItemRule" />
                </li>
                <li>
                  <Trans i18nKey="reminderModal.whyUploadVATRule" />
                </li>
                <li>
                  <Trans i18nKey="reminderModal.whyUploadPriceRule" />
                </li>
              </ul>
            </section>
          </div>
        </AnimateHeight>
      </div>
    </Modal>
  );
};
