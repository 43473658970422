import { type AnyAction, type Reducer } from '@reduxjs/toolkit';

type ActionTypes = {
  request: string;
  success: string;
  failure: string;
  reset?: string;
};

export type Pagination<TItem, TOffset = number | string> = {
  offset: TOffset;
  limit: number;
  totalCount: number;
  isFetching: boolean;
  items: TItem[];
};

/**
 * This helper creates a standardized Redux reducer for paginated entities.
 * The pagination system it uses is designed for a "Load more" experience,
 * meaning it doesn't actually use "pages" but relies on offset and limit
 * parameters to load data in batches.
 * It only focuses on managing the state of the pagination itself and doesn't
 * handle anything related to the actual data fetching of the paginated entities
 * itself.
 */
// FIXME: Please use createPaginatedReducer from paginateCursor which is better
// suited for an cursor instead of an offset
export const createPaginatedReducer = <TItem, TOffset>(
  actionTypes: ActionTypes,
  initialState: Pagination<TItem, TOffset>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  computeNextOffset = (state: typeof initialState, payload: any): TOffset =>
    state.offset + payload.items.length,
  // Enable extending the behavior of the paginated reducer
  customReducer: Reducer<typeof initialState> = (state) =>
    state || initialState,
) => {
  const { request, success, failure, reset } = actionTypes;

  return (
    state = initialState,
    action: AnyAction,
  ): Pagination<TItem, TOffset> => {
    switch (action.type) {
      case request:
        return {
          ...state,
          isFetching: true,
        };
      case success:
        return {
          ...state,
          offset: computeNextOffset(state, action.payload),
          totalCount: action.payload.totalCount,
          isFetching: false,
          items: [...state.items, ...action.payload.items],
        };
      case failure:
        return {
          ...state,
          isFetching: false,
        };
      case reset:
        return initialState;
      default:
        // call the customReducer when no default action matching
        return customReducer(state, action);
    }
  };
};

/**
 * Expose a few utils to be used as a selectors to read values from the
 * paginated state.
 */
export const getHasMoreItems = <TItem, TOffset>(
  p: Pagination<TItem, TOffset>,
) => p.totalCount > p.items.length;
export const getOffset = <TItem, TOffset>(p: Pagination<TItem, TOffset>) =>
  p.offset;
export const getLimit = <TItem, TOffset>(p: Pagination<TItem, TOffset>) =>
  p.limit;
export const getIsFetching = <TItem, TOffset>(p: Pagination<TItem, TOffset>) =>
  p.isFetching;
export const getItems = <TItem, TOffset>(p: Pagination<TItem, TOffset>) =>
  p.items;
export const getTotalCount = <TItem, TOffset>(p: Pagination<TItem, TOffset>) =>
  p.totalCount;
