import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import {
  type NewIntegrationReverseChargeAccount,
  type IntegrationReverseChargeAccount,
  type IntegrationVatAccount,
} from 'modules/bookkeep/settings/accounting';
import { type TaxAccountAddSuccess } from 'modules/bookkeep/settings/integrations/hooks/useSetTaxAccountMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { PullAndSetReverseChargeAddEditForm } from './PullAndSetReverseChargeAddEditForm';
import styles from './TaxAccountPullAndSetSection.module.css';
import { type ModalState } from './chift/ChiftReverseChargeSetSection';
import { CodeInUseCallout } from '../../../components/CodeInUseCallout/CodeInUseCallout';
import { getReverseChargeAddEditDescription } from '../translations';

interface Props {
  onAdd: (
    taxAccount: NewIntegrationReverseChargeAccount,
  ) => Promise<TaxAccountAddSuccess>;
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
  integration: AccountingSoftware;
  duplicatedError: boolean;
  duplicatedCurrentAccount?: IntegrationReverseChargeAccount | false;
  clearErrors: () => void;
  availableTaxAccounts: {
    debit: IntegrationVatAccount[];
    credit: IntegrationVatAccount[];
  };
}

export const ReverseChargeAddModal = ({
  onAdd,
  formState,
  setFormState,
  integration,
  duplicatedError,
  duplicatedCurrentAccount,
  clearErrors,
  availableTaxAccounts,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  return (
    <Modal
      title={t(
        'bookkeep.integrations.settings.reverseChargeAccountsTable.addReverseChargeAccount',
      )}
      isOpen={formState.kind === 'addReverseCharge'}
      iconName="plus"
      iconVariant="primary"
      actions={[
        <Button
          key="cancel"
          onClick={() => {
            setFormState({ kind: 'closed' });
            clearErrors();
          }}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="confirmSection"
          onClick={async () => {
            if (formState.kind === 'addReverseCharge') {
              setFormState({ ...formState, inputChanged: false });

              try {
                const result = await onAdd({
                  name: formState.name,
                  codeDebit: formState.codeDebit,
                  codeCredit: formState.codeCredit,
                  type: 'reverseCharge',
                });

                if (result.outcome === 'set') {
                  setFormState({ kind: 'closed' });
                }
              } catch (e) {
                // There is nothing that we need to do with this error.
                // Previously it worked without try catch even, now it throws errors. Not sure what changed.
                // eslint-disable-next-line no-console
                console.log(e);
              }
            }
          }}
          text={t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.confirmSelection',
          )}
          variant="primary"
          isDisabled={
            formState.kind === 'addReverseCharge' &&
            (!formState.name ||
              !formState.codeCredit ||
              !formState.codeDebit ||
              (duplicatedError && !formState.inputChanged) ||
              formState.codeCredit === formState.codeDebit)
          }
        />,
      ]}
    >
      <>
        <p className={styles.subtitle}>
          {getReverseChargeAddEditDescription(t, integration)}
        </p>

        {renderReverseChargeAddEditForm(
          integration,
          formState,
          setFormState,
          availableTaxAccounts,
        )}

        {duplicatedCurrentAccount && (
          <CodeInUseCallout
            accountInfo={`${duplicatedCurrentAccount.name} - ${
              formState.kind === 'addReverseCharge' &&
              duplicatedCurrentAccount.codeDebit === formState.codeDebit
                ? duplicatedCurrentAccount.codeDebit
                : duplicatedCurrentAccount.codeCredit
            }`}
            linkTo={routeFor(routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path, {
              company: companyId,
            })}
          />
        )}
      </>
    </Modal>
  );
};

function renderReverseChargeAddEditForm(
  integration: AccountingSoftware,
  formState: ModalState,
  setFormState: Dispatch<SetStateAction<ModalState>>,
  availableTaxAccounts: {
    debit: IntegrationVatAccount[];
    credit: IntegrationVatAccount[];
  },
) {
  switch (integration) {
    case 'Xero':
    case 'Datev':
    case 'SpendeskAccounting':
    case 'Sage':
    case 'Cegid':
    case 'SpendeskAccountingSingleEntry':
    case 'Netsuite':
    case 'Quickbooks':
      return <></>;
    case 'Sage100':
    case 'ACD':
      return (
        <PullAndSetReverseChargeAddEditForm
          formState={formState}
          setFormState={setFormState}
          availableTaxAccounts={availableTaxAccounts}
        />
      );
    default:
      rejectUnexpectedValue('render reverse charge table', integration);
  }
}
