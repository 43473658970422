import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type Procedure } from '../models';

type Params = {
  isEnabled?: boolean;
  accountType?: AccountType;
};

type AccountType = 'kyb' | 'migration' | 'sfs-migration';

export const useGetProcedureQuery = ({
  isEnabled = true,
  accountType,
}: Params = {}): QueryState<Procedure | undefined> => {
  const companyId = useCompanyId();

  return useQuery<Procedure>({
    key: ['procedure', companyId],
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/procedures',
      params: { accountType },
    },
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
  });
};
