import { testIfLocationIsInSingapore } from 'src/core/utils/location';
import { logger } from 'src/utils/datadog-log-wrapper';

const geocodingPath = 'geocoding/v5';
const mapboxRootUrl = 'https://api.mapbox.com/';

const countryCodeToCoordinates: { [key in string]: string } = {
  FR: '2.349014,48.864716',
  GB: '-0.127758,51.507351',
  DE: '13.404954,52.520008',
  US: '-100.5633433,38.477381',
  BE: '4.3053504,50.8549541',
  ES: '-3.8196204,40.4378698',
  IT: '12.3102844,43.094997',
  IE: '-6.385786,53.3242381',
};

type PlaceType = (
  | 'country'
  | 'region'
  | 'postcode'
  | 'district'
  | 'place'
  | 'locality'
  | 'neighborhood'
  | 'address'
  | 'poi'
)[];

export interface MapboxPlace {
  id: string;
  place_type: PlaceType;
  address?: string;
  text: string;
  place_name: string;
  geometry: {
    type: 'Point';
    coordinates: [number, number];
    interpolated?: boolean;
    omitted?: boolean;
  };
  context?: {
    id: string;
    text: string;
    short_code?: string;
  }[];
  center?: number[];
}

export const getMatchingPlaces = async (
  mapboxAccessToken: string,
  locale: string,
  searchText: string,
  userCountry?: string,
): Promise<MapboxPlace[] | undefined> => {
  const adjustedSearchText = sanitizeSearchText(searchText);
  const urlEncodedSearchText = encodeURIComponent(adjustedSearchText);
  const url = new URL(
    `${mapboxRootUrl}${geocodingPath}/mapbox.places/${urlEncodedSearchText}.json`,
  );
  const proximity = userCountry
    ? countryCodeToCoordinates[userCountry]
    : undefined;
  const params: { [key in string]: string } = {
    limit: '5',
    ...(proximity ? { proximity } : {}),
    language: locale,
    geometries: 'geojson',
    access_token: mapboxAccessToken,
  };
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );

  let response;
  try {
    response = await fetch(url.toString());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('ERROR', error);
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((await response.json()) as any).features;
};

const mapboxLimits = {
  MAX_NUMBER_OF_TOKENS: 20,
  MAX_NUMBER_OF_CHAR: 256,
};

const spacesAndDashesRegex = /[\s-]+/;

const sanitizeSearchText = (searchText: string): string => {
  const shortenSearchText = shortenLengthOfSearchText(searchText);
  return shortenSearchText.replaceAll(';', '');
};

const shortenLengthOfSearchText = (searchText: string): string => {
  const shortenedSearchText = searchText.slice(
    0,
    Math.max(0, mapboxLimits.MAX_NUMBER_OF_CHAR),
  );

  const searchArray = shortenedSearchText
    ? shortenedSearchText.split(spacesAndDashesRegex)
    : [];

  let adjustedSearchText = shortenedSearchText;
  if (searchArray.length > mapboxLimits.MAX_NUMBER_OF_TOKENS) {
    const shortenedSearchArray = searchArray.slice(
      0,
      mapboxLimits.MAX_NUMBER_OF_TOKENS,
    );
    adjustedSearchText = shortenedSearchArray.join(' ');
  }
  return adjustedSearchText;
};

export const getCountryCode = (place: MapboxPlace) => {
  const country = place.context?.find((address) =>
    address.id.includes('country'),
  )?.short_code;
  if (country) {
    return country;
  }

  if (place.context) {
    const contextById = new Map(
      place.context.map((c) => [c.id?.split('.')[0], c]),
    );
    const code =
      contextById.get('country')?.short_code ??
      contextById.get('place')?.short_code ??
      contextById.get('region')?.short_code;
    if (code) {
      return code;
    }
  }

  // Singapore Mapbox fallback fix.
  if (
    place.id === 'place.8905' ||
    testIfLocationIsInSingapore([
      (place.center ?? [])[1] ?? 0,
      (place.center ?? [])[0] ?? 0,
    ])
  ) {
    return 'sg';
  }

  logger.warn('getCountryCode empty country', {
    team: 'capture',
    scope: 'request',
    mapBoxResult: place,
  });

  return undefined;
};

export const getPlace = (mapboxPlace: MapboxPlace | undefined) => {
  if (mapboxPlace === undefined) {
    return undefined;
  }
  const shortAddress = mapboxPlace.address
    ? `${mapboxPlace.address} ${mapboxPlace.text}`
    : mapboxPlace.text;
  return {
    id: mapboxPlace.id,
    fullAddress: mapboxPlace.place_name,
    shortAddress,
    houseNumber: mapboxPlace.address,
    road: mapboxPlace.text,
    city: mapboxPlace.context?.find((address) => address.id.includes('place'))
      ?.text,
    postcode: mapboxPlace.context?.find((address) =>
      address.id.includes('postcode'),
    )?.text,
    region: mapboxPlace.context?.find((address) =>
      address.id.includes('region'),
    )?.text,
    country: getCountryCode(mapboxPlace),
    longitude: (mapboxPlace.center ?? [])[0],
    latitude: (mapboxPlace.center ?? [])[1],
  };
};
