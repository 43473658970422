import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useGetIntegrations } from 'src/core/modules/integrations/hooks/useGetIntegrations';
import { isKomboIntegration } from 'src/core/modules/integrations/utils/index';

export const EncourageIntegrationAdoptionCallout = () => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const history = useHistory();
  const navigateToList = () => {
    history.push(
      routeFor(routes.COMPANY_INTEGRATIONS_ALL.path, {
        company: company.id,
      }),
    );
  };
  const getIntegrationsQuery = useGetIntegrations();
  const integrationsStatus = unwrapQuery(getIntegrationsQuery);

  const hasAvailableHRIntegration =
    integrationsStatus?.available.some((integration) =>
      isKomboIntegration(integration.id),
    ) || false;

  if (!hasAvailableHRIntegration) {
    return null;
  }

  return (
    <Callout
      className="mb-s"
      iconName="socket"
      title={t('integration.callout.encourageIntegrationAdoption.title')}
      variant="primary"
    >
      {t('integration.callout.encourageIntegrationAdoption.description')}
      <Button
        className="mt-xs"
        variant="contrasted"
        text={t('integration.callout.encourageIntegrationAdoption.button.text')}
        onClick={() => {
          navigateToList();
        }}
      />
    </Callout>
  );
};
