import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { type IntegrationStatus } from '../../../integration/status';

export const useExternalAuthDisconnectMutation = (): MutationState => {
  return useMutation({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/accounting-integration/external-auth/logout',
    },
    options: {
      throwOnError: true,
      async onMutate({ client }): Promise<void> {
        client.cancelQueries('useIntegrationStatusQuery');

        const currentStatus = client.getQueryData<IntegrationStatus>(
          'useIntegrationStatusQuery',
        );

        if (
          !currentStatus ||
          currentStatus.integration === 'noIntegration' ||
          currentStatus.integration === 'switchInProgress'
        ) {
          return;
        }

        if (currentStatus.authorization.kind === 'noAuthorization') {
          return;
        }

        client.setQueryData<IntegrationStatus>('useIntegrationStatusQuery', {
          ...currentStatus,
          descriptor: {
            title: currentStatus.descriptor.title,
          },
          authorization: {
            ...currentStatus.authorization,
            status: {
              status: 'notConnected',
            },
          },
        });
      },
      onSuccess({ client }): void {
        client.invalidateQueries(['useIntegrationStatusQuery']);
      },
    },
  });
};
