import {
  Button,
  Callout,
  IconButton,
  SkeletonTable,
  SkeletonText,
  Table,
  type TableVariant,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import partition from 'lodash/partition';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { useGetSupplierAccountsQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSupplierAccountsQuery';
import { SupplierAccountAdvice } from 'modules/bookkeep/components/AccountAdvice';
import { useAccountLength } from 'modules/bookkeep/hooks';
import {
  hasIntegrationFileBasedExport,
  type IntegrationStatusWithIntegration,
} from 'modules/bookkeep/integration/status';
import { routeFor, routes } from 'src/core/constants/routes';

import { DefaultSupplierAccountForm } from './DefaultSupplierAccountForm';
import { type ModalState } from './ModalState';
import styles from './SupplierAccountsLocalOnlySection.module.css';
import { ConfirmDeleteSupplierAccountModal } from './modals/ConfirmDeleteSupplierAccountModal';
import { ConfirmEditSupplierAccountModal } from './modals/ConfirmEditSupplierAccountModal';
import { EditDefaultSupplierAccountModal } from './modals/EditDefaultSupplierAccountModal';
import {
  type DefaultSupplierAccount,
  getDefaultSupplierAccountName,
} from '../../../../../../accounting/supplierAccount';
import { useGetDefaultSupplierAccountQuery } from '../../../../../hooks/useGetDefaultSupplierAccountQuery';
import { useGetDefaultSupplierAccountQuery__deprecated } from '../../../../../hooks/useGetDefaultSupplierAccountQuery__deprecated';
import { useGetEmployeeAccountCodesQuery } from '../../../../../hooks/useGetEmployeeAccountCodesQuery';
import { useSetDefaultSupplierAccountMutation } from '../../../../../hooks/useSetDefaultSupplierAccountMutation';
import { useSetDefaultSupplierAccountMutation__deprecated } from '../../../../../hooks/useSetDefaultSupplierAccountMutation__deprecated';
import { CellWithButton } from '../../../components/CellWithIconButton/CellWithIconButton';
import { getSupplierHelpCenterLink } from '../../../utils';

interface Props {
  companyId: string;
  integrationStatus: IntegrationStatusWithIntegration;
}

export function SupplierAccountsLocalOnlyWithDefaultAccountsSection({
  companyId,
  integrationStatus,
}: Props) {
  const { t } = useTranslation('global');
  const getSupplierAccountsQueryState = useGetSupplierAccountsQuery({
    includeArchived: false,
    first: 1,
  });
  const getDefaultSupplierAccountQueryState =
    useGetDefaultSupplierAccountQuery();
  const [
    setDefaultSupplierAccountForCard,
    setDefaultSupplierAccountForCardQueryState,
  ] = useSetDefaultSupplierAccountMutation();
  const [
    setDefaultSupplierAccountForInvoice,
    setDefaultSupplierAccountForInvoiceQueryState,
  ] = useSetDefaultSupplierAccountMutation();
  const accountLength = useAccountLength();

  const defaultSupplierAccountForCard =
    getDefaultSupplierAccountQueryState.status === 'success'
      ? getDefaultSupplierAccountQueryState.data.supplierAccountForCard
      : undefined;

  const isSupplierAccountForCardToggleChecked = Boolean(
    defaultSupplierAccountForCard?.id &&
      !defaultSupplierAccountForCard.isArchived,
  );

  const defaultSupplierAccountForInvoice =
    getDefaultSupplierAccountQueryState.status === 'success'
      ? getDefaultSupplierAccountQueryState.data.supplierAccountForInvoice
      : undefined;

  const isSupplierAccountForInvoiceToggleChecked = Boolean(
    defaultSupplierAccountForInvoice?.id &&
      !defaultSupplierAccountForInvoice.isArchived,
  );

  const hasNoAccountSetup =
    getSupplierAccountsQueryState.status === 'success' &&
    getSupplierAccountsQueryState.data.length === 0;

  return (
    <div>
      <Callout
        className={cx(
          styles.noSuppliersCallout,
          'transition-all',
          hasNoAccountSetup ? 'visible' : 'hidden',
        )}
        title={t(
          'bookkeep.integrations.settings.supplierAccountsTable.noSuppliersCallout',
        )}
        variant="warning"
      />

      <DefaultSupplierAccountForm
        defaultFor="cardSupplier"
        integrationStatus={integrationStatus}
        getDefaultSupplierAccountQueryState={
          getDefaultSupplierAccountQueryState
        }
        setDefaultSupplierAccountQueryState={
          setDefaultSupplierAccountForCardQueryState
        }
        onDefaultChange={async (account) => {
          await setDefaultSupplierAccountForCard(account);
        }}
        defaultSupplierAccount={defaultSupplierAccountForCard}
        isInitialToggleChecked={isSupplierAccountForCardToggleChecked}
        accountLength={accountLength}
      />

      <DefaultSupplierAccountForm
        defaultFor="invoiceSupplier"
        integrationStatus={integrationStatus}
        getDefaultSupplierAccountQueryState={
          getDefaultSupplierAccountQueryState
        }
        setDefaultSupplierAccountQueryState={
          setDefaultSupplierAccountForInvoiceQueryState
        }
        onDefaultChange={async (account) => {
          await setDefaultSupplierAccountForInvoice(account);
        }}
        defaultSupplierAccount={defaultSupplierAccountForInvoice}
        isInitialToggleChecked={isSupplierAccountForInvoiceToggleChecked}
        accountLength={accountLength}
      />

      <Callout
        className="mt-m"
        iconName="info"
        variant="info"
        title={t(
          'bookkeep.integrations.settings.sectionSupplierAccountsManageTitle',
        )}
      >
        <p className="mb-s">
          {t(
            'bookkeep.integrations.settings.sectionSupplierAccountsManageDescription',
          )}
        </p>
        <Link
          to={routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
            company: companyId,
          })}
          component={(props: { navigate: () => void }) => {
            return (
              <Button
                data-testid="go-to-supplier-page"
                variant="contrasted"
                text={t(
                  'bookkeep.integrations.settings.sectionSupplierAccountsManageAction',
                )}
                onClick={props.navigate}
              />
            );
          }}
        />
      </Callout>
    </div>
  );
}

export function SupplierAccountsLocalOnlySection({
  companyId,
  integrationStatus,
}: Props) {
  const { t } = useTranslation('global');
  const queryClient = useQueryClient();
  const getSupplierAccountsQueryState = useGetSupplierAccountsQuery({
    includeArchived: false,
  });
  const getEmployeeAccountCodesQuery = useGetEmployeeAccountCodesQuery();
  const getDefaultSupplierAccountQueryState =
    useGetDefaultSupplierAccountQuery__deprecated();
  const setDefaultSupplierAccount =
    useSetDefaultSupplierAccountMutation__deprecated();

  const [modalState, setModalState] = useState<ModalState>({ kind: 'closed' });

  const supplierAccountsErrorsList =
    integrationStatus.settingsValidation.supplierAccounts.filter(
      (error) => error.error === 'invalidAccount',
    );

  const getCellVariant = (
    row: DefaultSupplierAccount,
  ): TableVariant | undefined => {
    const errors = supplierAccountsErrorsList.filter(
      (error) =>
        error.error === 'invalidAccount' &&
        error.id &&
        row.id &&
        error.id === row.id,
    );
    return errors.length === 1 ? 'alert' : undefined;
  };

  async function handleUpdateSpendeskAccount() {
    if (modalState.kind !== 'form' && modalState.kind !== 'confirmation') {
      // eslint-disable-next-line no-console
      console.warn("This shouldn't happen: can't save without confirmation");
      return;
    }

    const { editedAccount } = modalState;

    await setDefaultSupplierAccount(editedAccount);

    await queryClient.invalidateQueries(['useIntegrationStatusQuery']);

    setModalState({
      ...modalState,
      kind: 'closed',
    });
  }

  async function handleDeleteSpendeskAccount() {
    if (modalState.kind !== 'confirmDelete') {
      // eslint-disable-next-line no-console
      console.warn("This shouldn't happen: can't delete without confirmation");
      return;
    }

    const { editedAccount } = modalState;

    await setDefaultSupplierAccount(
      {
        ...editedAccount,
        isArchived: true,
      },
      true,
    );

    await queryClient.invalidateQueries(['useIntegrationStatusQuery']);

    setModalState({
      ...modalState,
      kind: 'closed',
    });
  }

  return (
    <div>
      {getSupplierAccountsQueryState.status === 'success' &&
        !getSupplierAccountsQueryState.data.length && (
          <Callout
            className={styles.noSuppliersCallout}
            title={t(
              'bookkeep.integrations.settings.supplierAccountsTable.noSuppliersCallout',
            )}
            variant="warning"
          />
        )}

      <QuerySuspense
        queryState={getDefaultSupplierAccountQueryState}
        loading={<DefaultSupplierAccountSkeletonTable />}
        fallback={() => <DefaultSupplierAccountErrorWarning />}
      >
        {(supplierAccounts) => {
          const defaultSupplierAccount =
            supplierAccounts.length > 0 ? supplierAccounts[0] : null;
          const hasDefaultSupplierAccountError =
            !!defaultSupplierAccount &&
            !!supplierAccounts[0].id &&
            supplierAccountsErrorsList.filter(
              (supplierAccount) =>
                supplierAccount.id &&
                supplierAccount.id === defaultSupplierAccount?.id,
            ).length === 1;

          const [
            defaultSupplierAccountErrors,
            individualSupplierAccountErrors,
          ] = partition(supplierAccountsErrorsList, (error) => {
            return (
              defaultSupplierAccount &&
              defaultSupplierAccount.id &&
              error.id === defaultSupplierAccount.id
            );
          });
          const data = [
            defaultSupplierAccount ?? {
              generalAccountCode: '',
              name: getDefaultSupplierAccountName(
                integrationStatus.accountingCountry,
              ),
              defaultFor: 'cardSupplier',
            },
          ];
          return (
            <>
              {(!defaultSupplierAccount ||
                defaultSupplierAccountErrors.length > 0) && (
                <SupplierAccountAdvice
                  className={styles.formCallout}
                  integrationStatus={integrationStatus}
                  showError={hasDefaultSupplierAccountError}
                  title={t(
                    defaultSupplierAccount
                      ? 'bookkeep.integrations.datev.accountCodeAdvice.defaultSupplierAccount.title.warning'
                      : 'bookkeep.integrations.datev.accountCodeAdvice.defaultSupplierAccount.title.info',
                  )}
                />
              )}
              <Table
                hasColumnSeparator
                rowHeight="compact"
                columns={[
                  {
                    id: 'name',
                    header: t(
                      'bookkeep.integrations.settings.supplierAccountsTable.name',
                    ),
                    renderCell() {
                      return t(
                        'bookkeep.integrations.settings.supplierAccountsTable.miscellaneousAccountName',
                      );
                    },
                  },
                  {
                    id: 'generalAccountCode',
                    header: t(
                      'bookkeep.integrations.settings.supplierAccountsTable.code',
                    ),
                    getCellVariant,
                    renderCell(row, { isRowHovered }) {
                      return (
                        <CellWithButton
                          isButtonVisible={isRowHovered}
                          button={
                            <span className="CellWithButton__button">
                              <IconButton
                                iconName="pen"
                                variant="borderless"
                                onClick={() =>
                                  setModalState({
                                    kind: 'form',
                                    editedAccount: row,
                                    error: 'none',
                                    inputChanged: false,
                                  })
                                }
                                aria-label={t('misc.edit')}
                              />
                              {!hasIntegrationFileBasedExport(
                                integrationStatus.integration,
                              ) &&
                                row.id && (
                                  <IconButton
                                    iconName="trash"
                                    variant="borderless"
                                    onClick={() =>
                                      setModalState({
                                        kind: 'confirmDelete',
                                        editedAccount: row,
                                      })
                                    }
                                    aria-label={t('misc.delete')}
                                  />
                                )}
                            </span>
                          }
                        >
                          {row.generalAccountCode || (
                            <em className={cx(styles.placeholder, 'body-m')}>
                              {t(
                                'bookkeep.integrations.settings.supplierAccountsTable.placeholder',
                              )}
                            </em>
                          )}
                        </CellWithButton>
                      );
                    },
                  },
                ]}
                data={data}
              />

              <ConfirmEditSupplierAccountModal
                integration={integrationStatus.integration}
                defaultSupplierAccount={data}
                setModalState={setModalState}
                modalState={modalState}
                handleUpdateSpendeskAccount={handleUpdateSpendeskAccount}
              />

              <ConfirmDeleteSupplierAccountModal
                defaultSupplierAccount={data}
                setModalState={setModalState}
                modalState={modalState}
                handleDeleteSpendeskAccount={handleDeleteSpendeskAccount}
              />
              <h3
                className={cx(
                  'IntegrationAccountingPage__section-title title-xl',
                  styles.midPageTitle,
                )}
              >
                {t(
                  'bookkeep.integrations.settings.miscIndividualSupplierAccountTitle',
                )}
              </h3>

              <p className="IntegrationAccountingPage__section-description body-m">
                {t(
                  'bookkeep.integrations.settings.miscIndividualSupplierAccountDescription',
                )}{' '}
                {integrationStatus.integration === 'Datev' && (
                  <>
                    &nbsp;|&nbsp;
                    <a
                      href={getSupplierHelpCenterLink(
                        integrationStatus.integration,
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('misc.helpCenterArticle')}
                    </a>
                  </>
                )}
              </p>

              {individualSupplierAccountErrors.length > 0 && (
                <SupplierAccountAdvice
                  className={styles.formCallout}
                  integrationStatus={integrationStatus}
                  showError
                  title={t(
                    'bookkeep.integrations.datev.accountCodeAdvice.individualSupplierAccounts.title',
                    {
                      count: individualSupplierAccountErrors.length,
                    },
                  )}
                />
              )}

              <Link
                to={routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
                  company: companyId,
                })}
                component={(props: { navigate: () => void }) => {
                  return (
                    <Button
                      variant="contrasted"
                      text={t(
                        'bookkeep.integrations.settings.goToAccountsPayable',
                      )}
                      onClick={props.navigate}
                    />
                  );
                }}
              />

              <EditDefaultSupplierAccountModal
                modalState={modalState}
                setModalState={setModalState}
                integrationStatus={integrationStatus}
                handleUpdateSpendeskAccount={handleUpdateSpendeskAccount}
                getSupplierAccountsQueryState={getSupplierAccountsQueryState}
                getEmployeeAccountCodesQuery={getEmployeeAccountCodesQuery}
              />
            </>
          );
        }}
      </QuerySuspense>
    </div>
  );
}

const DefaultSupplierAccountErrorWarning = () => {
  const { t } = useTranslation('global');

  return (
    <Callout
      title={t('bookkeep.integrations.settings.errorLoading')}
      variant="alert"
    />
  );
};

const DefaultSupplierAccountSkeletonTable = () => (
  <SkeletonTable
    columns={[
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '50%',
      },
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '50%',
      },
    ]}
    numberOfRows={2}
    withColumnSeparator
  />
);
