import queryString from 'query-string';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getHasControllerHomepage,
  getHasRequesterHomepage,
} from 'src/core/selectors/globalSelectors';

type HomepageMode = 'requester' | 'controller';

const getDisplayMode = ({
  hasRequesterHomepage,
  hasControllerHomepage,
  locationSearch,
}: {
  hasRequesterHomepage: boolean;
  hasControllerHomepage: boolean;
  locationSearch: string;
}): HomepageMode | undefined => {
  const { mode } = queryString.parse(locationSearch);
  if (hasControllerHomepage && (!mode || mode === 'controller')) {
    return 'controller';
  }
  if (hasRequesterHomepage && (!mode || mode === 'requester')) {
    return 'requester';
  }
};

export const useDisplayMode = (): HomepageMode | undefined => {
  const location = useLocation();

  const hasRequesterHomepage = useSelector(getHasRequesterHomepage);
  const hasControllerHomepage = useSelector(getHasControllerHomepage);

  return useMemo(() => {
    return getDisplayMode({
      hasRequesterHomepage,
      hasControllerHomepage,
      locationSearch: location.search,
    });
  }, [location.search]);
};
