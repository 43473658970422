import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type ReturnType = {
  isActive: boolean;
};
export const useIsExpenseCategoryExpenseAccountRuleEnabledQuery =
  (): QueryState<ReturnType> =>
    useQuery<ReturnType>({
      key: 'expense-accounts-expense-categories-status',
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint:
          '/bookkeeping/automation/expense-accounts-expense-categories-status',
      },
    });

export const useIsExpenseCategoryExpenseAccountRuleEnabled = (): boolean => {
  const isExpenseCategoryExpenseAccountRuleEnabledQuery =
    useIsExpenseCategoryExpenseAccountRuleEnabledQuery();

  if (
    isExpenseCategoryExpenseAccountRuleEnabledQuery.status === 'loading' ||
    isExpenseCategoryExpenseAccountRuleEnabledQuery.status === 'error'
  ) {
    return false;
  }

  return isExpenseCategoryExpenseAccountRuleEnabledQuery.data.isActive;
};
