import {
  type Selection,
  type BucketId,
  type GroupId,
  type PayableId,
  type SelectionBucket,
} from './selectionUpdater';

// TODO: change to union type
export enum SelectionStatus {
  All = 'ALL',
  Partial = 'PARTIAL',
  None = 'NONE',
}

/**
 * Returns selection status of a given bucket
 * @param {Selection} selection
 * @param {BucketId} bucketId
 */
export const getBucketSelectionStatus = (
  selection: Selection,
  bucketId: BucketId,
) => {
  const bucketSelection =
    selection?.buckets?.[bucketId] ?? ({} as SelectionBucket);

  // Check bucket selection
  if (bucketSelection.isPartial) {
    return SelectionStatus.Partial;
  }
  if (bucketSelection.shouldAutoSelectChildren) {
    return SelectionStatus.All;
  }

  return SelectionStatus.None;
};

/**
 * Checks if a specific payable is selected
 * @param {Selection} selection
 * @param {BucketId} bucketId
 * @param {GroupId} groupId
 * @param {PayableId} payableId
 */
export const getPayableSelectionStatus = (
  selection: Selection,
  bucketId: BucketId,
  groupId: GroupId = 'COMMON',
  payableId: PayableId,
) => {
  const groupSelection = selection.buckets?.[bucketId]?.groups?.[groupId];
  const payableSelection = groupSelection?.payables?.[payableId];

  // Check payable selection
  if (payableSelection !== undefined) {
    if (payableSelection) {
      return SelectionStatus.All;
    }

    return SelectionStatus.None;
  }

  // Check group selection
  if (groupSelection) {
    if (groupSelection.shouldAutoSelectChildren) {
      return SelectionStatus.All;
    }

    return SelectionStatus.None;
  }

  return SelectionStatus.None;
};
