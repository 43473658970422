export type ReverseChargeTaxAccountRateApi = {
  rate: string;
  validFrom: string;
};

export type ReverseChargeTaxAccountRate = {
  rate: number;
  validFrom: Date;
};

export const reshapeReverseChargeTaxAccountRate = (
  reverseChargeTaxAccountRate: ReverseChargeTaxAccountRateApi,
): ReverseChargeTaxAccountRate => {
  return {
    rate: Number(reverseChargeTaxAccountRate.rate) / 100,
    validFrom: new Date(reverseChargeTaxAccountRate.validFrom),
  };
};
