import { Button, Callout } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useState } from 'react';

import {
  type MutationQueryState,
  type QueryState,
} from 'src/core/api/queryState';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type AccountingSoftware } from 'src/core/modules/bookkeep/integration/status';
import {
  type NewTaxAccount,
  filterIntegrationReverseChargeAccounts,
  type TaxAccount,
  filterIntegrationVatAccounts,
  type IntegrationVatAccount,
} from 'src/core/modules/bookkeep/settings/accounting';
import { type TaxAccountAddSuccess } from 'src/core/modules/bookkeep/settings/integrations/hooks/useSetTaxAccountMutation';

import { PullAndSetReverseChargeTable } from './PullAndSetReverseChargeTable';
import { TaxAccountErrorWarning } from '../../TaxAccountErrorWarning';
import { TaxAccountSkeletonTable } from '../../TaxAccountSkeletonTable';
import { ReverseChargeAddModal } from '../ReverseChargeAddModal';
import { ReverseChargeDeleteModal } from '../ReverseChargeDeleteModal';
import styles from '../TaxAccountPullAndSetSection.module.css';

interface Props {
  taxAccountQueryState: QueryState<TaxAccount[]>;
  setTaxAccountQueryState: MutationQueryState<TaxAccountAddSuccess, unknown>;
  integration: AccountingSoftware;
  onAdd: (taxAccount: NewTaxAccount) => Promise<TaxAccountAddSuccess>;
  onDelete: (taxAccount: TaxAccount) => Promise<void>;
  clearErrors: () => void;
}

export type ModalState =
  | { kind: 'closed' }
  | {
      kind: 'addReverseCharge' | 'confirmDeleteReverseCharge';
      id: string;
      name: string;
      codeDebit: string;
      codeCredit: string;
      inputChanged?: boolean;
    };

export const ChiftReverseChargeSetSection = ({
  taxAccountQueryState,
  setTaxAccountQueryState,
  integration,
  onAdd,
  onDelete,
  clearErrors,
}: Props) => {
  const { t } = useTranslation('global');
  const [modalState, setModalState] = useState<ModalState>({
    kind: 'closed',
  });

  const isAddModalWithCode =
    modalState.kind === 'addReverseCharge' && !!modalState.codeCredit;
  const duplicatedError =
    isAddModalWithCode &&
    setTaxAccountQueryState.status === 'success' &&
    setTaxAccountQueryState.data.outcome === 'notSet' &&
    setTaxAccountQueryState.data.reason === 'codeAlreadyExists';

  return (
    <div id="reverse-charge-accounts" className={styles.reverseChargeSection}>
      <div className={styles.header}>
        <div className={styles.headerText}>
          <h3 className="IntegrationAccountingPage__section-title title-xl">
            {t('bookkeep.integrations.settings.sectionReverseChargeAccounts')}
          </h3>
          <p className={classNames(styles.headerTextDescription, 'body-m')}>
            {t('bookkeep.integrations.chift.reverseChargeAccountsDescription', {
              integration,
            })}
          </p>
        </div>
        <Button
          className={styles.headerBtn}
          text={t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.addReverseChargeAccount',
          )}
          variant="primary"
          onClick={() =>
            setModalState({
              kind: 'addReverseCharge',
              id: '',
              name: '',
              codeDebit: '',
              codeCredit: '',
              inputChanged: false,
            })
          }
        />
      </div>

      <QuerySuspense
        queryState={taxAccountQueryState}
        loading={<TaxAccountSkeletonTable numberOfRows={2} />}
        fallback={() => <TaxAccountErrorWarning />}
      >
        {(taxAccounts) => {
          const reverseChargeAccounts =
            filterIntegrationReverseChargeAccounts(taxAccounts);
          const emptyCallout = reverseChargeAccounts.length === 0 && (
            <Callout
              className={styles.noVatAccountWarning}
              variant="warning"
              title={t(
                'bookkeep.integrations.settings.noReverseChargeAccountWarning',
              )}
            />
          );

          // TODO: Update when working on settings validation

          const duplicatedCurrentAccount =
            duplicatedError &&
            !modalState.inputChanged &&
            modalState.kind === 'addReverseCharge' &&
            reverseChargeAccounts.find(
              (account) =>
                account.codeDebit === modalState.codeDebit ||
                account.codeCredit === modalState.codeCredit,
            );

          const availableTaxAccounts = filterIntegrationVatAccounts(
            taxAccounts,
          ).reduce(
            (
              memo: {
                debit: IntegrationVatAccount[];
                credit: IntegrationVatAccount[];
              },
              account,
            ) => {
              const alreadyUsedDebitCodes = reverseChargeAccounts.map(
                (reverseChargeAccount) => reverseChargeAccount.codeDebit,
              );
              const alreadyUsedCreditCodes = reverseChargeAccounts.map(
                (reverseChargeAccount) => reverseChargeAccount.codeCredit,
              );

              if (!alreadyUsedCreditCodes.includes(account.code)) {
                memo.credit.push(account);
              }
              if (!alreadyUsedDebitCodes.includes(account.code)) {
                memo.debit.push(account);
              }

              return memo;
            },
            { debit: [], credit: [] },
          );

          return (
            <>
              {emptyCallout}
              {/* {errorCallout} */}
              <PullAndSetReverseChargeTable
                // integrationStatus={integrationStatus}
                reverseChargeAccounts={reverseChargeAccounts}
                setModalState={setModalState}
              />

              <ReverseChargeAddModal
                onAdd={onAdd}
                clearErrors={clearErrors}
                setFormState={setModalState}
                duplicatedError={duplicatedError}
                duplicatedCurrentAccount={duplicatedCurrentAccount}
                formState={modalState}
                integration="Sage100"
                availableTaxAccounts={availableTaxAccounts}
              />
              <ReverseChargeDeleteModal
                onDelete={onDelete}
                setFormState={setModalState}
                formState={modalState}
              />
            </>
          );
        }}
      </QuerySuspense>
    </div>
  );
};
