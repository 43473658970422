/* eslint-disable @typescript-eslint/naming-convention */

import React, { type ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { NoAccountingIntegrationFeedback } from 'modules/accounting-integration/components';
import { PendingKycEmptyState } from 'modules/kyc/components/PendingKycEmptyState';
import { useShouldCompleteKyc } from 'modules/kyc/hooks/useShouldCompleteKyc';
import { fetchCustomFields as fetchCustomFieldsAction } from 'src/core/actions/customFields';
import { type AppState } from 'src/core/reducers';

import { Exports } from './Exports';
import { type CustomField } from './customField';
import { getCustomFields } from './redux/selectors';
import { GraphQLProvider } from '../../components/GraphQLProvider';
import { useIntegrationStatusQuery } from '../../hooks';
import { updateInvoicePattern as updateInvoicePatternAction } from '../export-legacy/redux/actions';

type Props = {
  customFields: CustomField[];
  fetchCustomFields: () => void;
  updateInvoicePattern: (pattern: string[]) => Promise<void>;
};

const withNewOnboarding = <P extends object>(Subcomponent: ComponentType<P>) =>
  function ExportsContainerWithNewOnboarding(props: P) {
    const shouldCompleteKyc = useShouldCompleteKyc();
    const accountingIntegrationQueryResult = useIntegrationStatusQuery();

    if (shouldCompleteKyc) {
      return <PendingKycEmptyState />;
    }

    if (accountingIntegrationQueryResult.status === 'error') {
      // TODO: handle error
      return null;
    }

    if (accountingIntegrationQueryResult.status === 'loading') {
      // TODO: handle loading
      return null;
    }

    if (
      accountingIntegrationQueryResult.status === 'success' &&
      accountingIntegrationQueryResult.data.integration === 'noIntegration'
    ) {
      return <NoAccountingIntegrationFeedback />;
    }

    return <Subcomponent {...props} />;
  };

const withGraphQLProvider = <P extends object>(
  Subcomponent: ComponentType<P>,
) =>
  function ExportsContainerWithGraphQL(props: P) {
    return (
      <GraphQLProvider>
        <Subcomponent {...props} />
      </GraphQLProvider>
    );
  };

const ExportsContainer = ({
  customFields,
  fetchCustomFields,
  updateInvoicePattern,
}: Props) => {
  useEffect(() => {
    fetchCustomFields();
  }, []);

  return (
    <div className="page__container">
      <Exports
        customFields={customFields.filter(
          (customField) => !customField.deleted_at,
        )}
        updateInvoicePattern={updateInvoicePattern}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  customFields: getCustomFields(state),
});

const mapDispatchToProps = {
  fetchCustomFields: fetchCustomFieldsAction,
  updateInvoicePattern: updateInvoicePatternAction,
};

export default withErrorBoundary({
  scope: 'bookkeep-exports',
  team: 'finance-accountant',
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withGraphQLProvider(withNewOnboarding(ExportsContainer))),
);
