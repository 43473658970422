import { type PayableType } from '../prepare-payables/models';

const supplierCounterpartyExpenseTypes: PayableType[] = [
  'card_purchase',
  'invoice_purchase',
  // FIXME: not technically correct but we only handle refunds on cards for now
  'reversal',
];

export const isSupplierCounterpartyExpense = (expense: {
  type: PayableType;
}) => {
  return supplierCounterpartyExpenseTypes.includes(expense.type);
};
