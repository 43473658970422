import { SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { type ControlRule } from 'modules/company/members/components/ControlRules';
import * as actions from 'modules/company/members/components/ControlRules/redux/actions';
import {
  getControlRules,
  getIsFetchingControlRules,
} from 'modules/company/members/components/ControlRules/redux/selectors';
import * as thunks from 'modules/company/members/components/ControlRules/redux/thunks';
import { type AppState } from 'src/core/reducers';

import { TableControlRules } from './TableControlRules';

type Props = {
  controlRules: ControlRule[];
  searchPattern?: string;
  isFetching: boolean;
  fetchControlRules(): void;
  setSelectedControlRule(id: string): void;
};

const Container = ({
  controlRules,
  searchPattern = '',
  isFetching,
  fetchControlRules,
  setSelectedControlRule,
}: Props) => {
  useEffect(() => {
    fetchControlRules();
  }, []);

  const nonCustomRules = searchControlRulesWithPattern(
    controlRules,
    searchPattern,
  );
  if (isFetching) {
    return (
      <SkeletonTable
        columns={[{ cell: <SkeletonText width="100%" /> }]}
        numberOfRows={3}
      />
    );
  }
  return (
    <TableControlRules
      controlRules={nonCustomRules}
      setSelectedControlRule={setSelectedControlRule}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  controlRules: getControlRules(state),
  isFetching: getIsFetchingControlRules(state),
});

const mapDispatchToProps = {
  fetchControlRules: thunks.fetchControlRules,
  setSelectedControlRule: actions.setSelectedControlRule,
};

export const TableControlRulesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);

export const searchControlRulesWithPattern = (
  controlRules: ControlRule[],
  searchPattern: string,
) =>
  controlRules.filter(
    (rule) => !rule.isCustom && rule.name.includes(searchPattern),
  );
