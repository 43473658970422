import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  userId: string;
  endpointParams: {
    controlRuleId: string;
  };
};

export const useUpdateControlRuleMutation = () => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: ({ controlRuleId }) => `control-rules/${controlRuleId}/assign`,
      method: 'put',
    },
    options: {
      throwOnError: true,
    },
  });
};
