import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { NotificationType, useNotifications } from '../../app/notifications';

export const useKomboForceSyncMutation = (): MutationState<{
  full: boolean;
}> => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  return useMutation<{
    full: boolean;
  }>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/integrations/kombo/sync',
    },
    options: {
      onSuccess(): void {
        pushNotif({
          type: NotificationType.Success,
          message: t('integration.kombo.refresh.toast.title'),
          description: t('integration.kombo.refresh.toast.description'),
        });
      },
    },
  });
};
