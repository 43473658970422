import { Icon, Button, colors, DeprecatedPreview } from '@dev-spendesk/grapes';
import React from 'react';

import { ContactUs } from 'common/components/ContactUs';
import { useTranslation } from 'common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  title?: string;
  questions: {
    title: string;
    link: string;
  }[];
  helpCenterIndex: {
    link: string;
    title: string;
  };
  openFixIntercomCookiesModal?: () => void;
};

const HelpTooltip = ({
  title,
  questions,
  helpCenterIndex,
  openFixIntercomCookiesModal,
}: Props) => {
  const { t } = useTranslation('global');

  const handleChatClicked = () => {
    track(AnalyticEventName.HELP_CENTER_CLICKED_CHAT);
  };

  const handleLinkClicked = (link: string, linkTitle: string) => {
    track(AnalyticEventName.HELP_CENTER_CLICKED_LINK, {
      link,
      title: linkTitle,
    });
  };

  return (
    <div className="w-[482px]">
      <div className="flex items-center gap-s p-s">
        <Icon name="question-mark" size="l" color={colors.alertLight} />
        <div className="text-neutral-darker title-m">
          {title || t('misc.needHelp')}
        </div>
      </div>
      <div className="bg-page-background p-s pb-m">
        <div className="flex flex-col gap-xxs">
          {questions.map((question) => (
            <DeprecatedPreview
              key={question.title}
              fit="parent"
              primaryText={question.title}
              rightAddon={<Icon aria-hidden="true" name="caret-right" />}
              onClick={() => {
                handleLinkClicked(question.link, question.title);
                window.open(question.link, '_blank');
              }}
            />
          ))}
        </div>
        <div className="mt-xs flex items-center justify-between">
          <a
            href={helpCenterIndex.link}
            className="flex items-center justify-center gap-xxs text-neutral-dark title-m hover:text-neutral-darker"
            onClick={() =>
              handleLinkClicked(helpCenterIndex.link, helpCenterIndex.title)
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="arrow-right" size="s" />
            <span>{t('helpCenter.findOutMore')}</span>
          </a>

          <ContactUs openFixIntercomCookiesModal={openFixIntercomCookiesModal}>
            {({ handleContactUs }) => (
              <Button
                variant="ghost"
                text={t('helpCenter.contactUs')}
                onClick={() => {
                  handleContactUs();
                  handleChatClicked();
                }}
              />
            )}
          </ContactUs>
        </div>
      </div>
    </div>
  );
};

export default HelpTooltip;
