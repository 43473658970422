import { useParams } from 'react-router-dom';

import { type ExpenseAccount } from 'modules/bookkeep/settings/accounting';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { GET_EXPENSE_ACCOUNTS } from './query';

type RawData = {
  company: {
    chartOfAccounts: {
      expenseAccounts: {
        edges: {
          node: {
            id: string;
            name: string;
            code: string;
            isDefault: boolean;
            isArchived: boolean;
            isAvailable: boolean;
          };
        }[];
      };
    };
  };
};

export const useExpenseAccountsQuery = (
  filter: { includeArchived: boolean; isAvailable?: boolean } = {
    includeArchived: false,
    isAvailable: true,
  },
): QueryState<ExpenseAccount[]> => {
  const { company: companyId } = useParams();

  // TODO: use infinite query
  return useQuery<ExpenseAccount[], RawData>({
    key: ['useExpenseAccountsQuery', companyId, filter],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_EXPENSE_ACCOUNTS,
      variables: {
        filter,
      },
    },
    reshapeData: (rawData) => {
      const { edges } = rawData.company.chartOfAccounts.expenseAccounts;
      return edges.map(({ node }) => node);
    },
  });
};
