import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { type IntegrationVatAccount } from 'modules/bookkeep/settings/accounting/taxAccount';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from '../ModalState';

interface Props {
  integration: AccountingSoftware;
  onDelete: (taxAccount: IntegrationVatAccount) => Promise<void>;
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
}

export const VatDeleteModal = ({
  integration,
  onDelete,
  formState,
  setFormState,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        formState.kind === 'confirmDeleteVat'
          ? t(
              'bookkeep.integrations.settings.vatAccountsTable.confirmDeleteTaxAccountTitle',
              { accountName: formState.name },
            )
          : ''
      }
      subtitle={
        hasIntegrationFileBasedExport(integration)
          ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
          : t(
              'bookkeep.integrations.settings.vatAccountsTable.confirmSendBackToPrepareSubtitle',
            )
      }
      isOpen={formState.kind === 'confirmDeleteVat'}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={() => setFormState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          onClick={() => {
            if (formState.kind === 'confirmDeleteVat') {
              onDelete({
                id: formState.id,
                name: formState.name,
                code: formState.code,
                rate: formState.rate,
                type: 'vat',
              });
            }

            setFormState({ kind: 'closed' });
          }}
          text={t(
            'bookkeep.integrations.settings.vatAccountsTable.confirmDeletion',
          )}
          variant="alert"
        />,
      ]}
    />
  );
};
