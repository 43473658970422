import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';

import { type ControlRule } from '../models/controlRule';

export const translateControlRuleName = (
  controlRule: ControlRule | undefined,
  t: TGlobalFunctionTyped,
): string => {
  if (controlRule?.isCustom) {
    return t('members.customControlRule');
  }
  if (controlRule?.isInitial) {
    return t('controlRules.initialRuleName');
  }
  return controlRule?.name ?? t('members.table.noControlRule');
};
