import cx from 'classnames';
import React, { useState, useEffect } from 'react';

type Props = {
  src: string;
  fallbackSrc?: string;
  className?: string;
  alt: string;
};

const cache = new Set();

/**
 * Renders an image, falling back to fallbackSrc url if the provided src fails to load.
 */
export const Img = ({ src, fallbackSrc, className, alt }: Props) => {
  const [source, setSource] = useState<string>(
    cache.has(src) && fallbackSrc ? fallbackSrc : src,
  );

  const onError = () => {
    if (fallbackSrc) {
      setSource(fallbackSrc);
      cache.add(src);
    }
  };

  useEffect(() => {
    setSource(cache.has(src) && fallbackSrc ? fallbackSrc : src);
  }, [src, fallbackSrc]);

  return (
    <img
      className={cx('Img', className)}
      src={source}
      onError={onError}
      alt={alt}
    />
  );
};
