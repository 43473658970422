import { Callout, CheckboxBox } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import * as MemberModel from '../../models/member';
import { type Role, translateRoleName } from '../../models/roles';
import { type MemberEditFormikValues } from '../MemberEditModal/validate';

type EnrichedRole = Role & { isEditable: boolean; checked: boolean };

type Props = {
  member: MemberModel.MemberDetails;
  roles: EnrichedRole[];
  formikProps: FormikProps<MemberEditFormikValues>;
};

export const MemberEditTabRoles = ({ member, roles, formikProps }: Props) => {
  const { values, setFieldValue, errors } = formikProps;

  const { t } = useTranslation('global');
  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);

  const getRoleDescription = (role: Role) => {
    switch (role.name) {
      case 'Administrator':
        return isTeamsFeatureEnabled
          ? t('members.roleDescriptionAdminWithTeams')
          : t('members.roleDescriptionAdmin');
      case 'Controller':
        return t('members.roleDescriptionController');
      case 'Requester':
        return t('members.roleDescriptionRequester');
      default:
        throw new Error('Unknown role');
    }
  };

  const updateRole = (role: EnrichedRole) => {
    const newRoles = role.checked
      ? values.roleIds.filter((selectedRole) => selectedRole !== role.id)
      : [...values.roleIds, role.id];
    const draftMemberRoles = MemberModel.getDraftMemberRoles(
      { ...formikProps.values, roleIds: newRoles },
      roles,
    );

    if (!draftMemberRoles.isController) {
      setFieldValue('canConfirmPayments', false);
    } else if (member.canConfirmPayments) {
      setFieldValue('canConfirmPayments', true);
    }

    return setFieldValue('roleIds', newRoles);
  };

  return (
    <div className="flex flex-col gap-m">
      {errors.roleIds ? (
        <Callout variant="alert" title={t('members.editModal.roleIdsError')} />
      ) : null}
      {roles.map((role) => (
        <CheckboxBox
          key={`role-block-${role.id}`}
          isDisabled={!role.isEditable}
          isChecked={role.checked}
          label={translateRoleName(role.name, t)}
          description={getRoleDescription(role)}
          onChange={() => {
            updateRole(role);
          }}
        />
      ))}
    </div>
  );
};
