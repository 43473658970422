import { Button } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { useMemo } from 'react';

import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './SetupHubLayout.module.css';

type Props = {
  children: React.ReactNode;
  onBack: () => void;
  onNext: () => void;
  onNextLabel?: string;
  rightContent: React.ReactNode;
  scope: string;
  stepIndex?: number;
  stepTotal?: number;
  title: string;
};

export const SetupHubLayout = ({
  children,
  onBack,
  onNext,
  onNextLabel,
  rightContent,
  scope,
  stepIndex = 0,
  stepTotal = 1,
  title,
}: Props) => {
  const { t } = useTranslation('global');

  const hasMultipleSteps = stepTotal > 1;
  const isLastStep = stepIndex === stepTotal - 1;

  const nextLabel = useMemo(() => {
    if (onNextLabel) {
      return onNextLabel;
    }
    return isLastStep ? t('misc.done') : t('misc.continue');
  }, [isLastStep, onNextLabel]);

  return (
    <ErrorBoundary context={{ scope: `setup-hub::${scope}`, team: 'growth' }}>
      <div className="page__container">
        <div className="flex h-full flex-row">
          <div className={cx(styles.block, 'flex-[6]')}>
            <div className={styles.leftContent}>
              {hasMultipleSteps && (
                <div className="mb-xl flex flex-row gap-s">
                  {Array.from({ length: stepTotal }).map((_, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`stepper-${index}`}
                      className={cx('h-xs w-xs rounded-xs', {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        'bg-primary': index === stepIndex,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        'bg-neutral-light': index !== stepIndex,
                      })}
                    />
                  ))}
                </div>
              )}
              <h1 className="mb-xl leading-normal title-xxl">{title}</h1>
              <div className="mb-m">{children}</div>
              <div className="flex flex-row gap-s">
                <Button
                  onClick={onBack}
                  text={t('misc.back')}
                  variant="secondary"
                />
                <Button
                  iconName={isLastStep ? undefined : 'caret-right'}
                  iconPosition="right"
                  onClick={onNext}
                  text={nextLabel}
                />
              </div>
            </div>
          </div>
          <div
            className={cx(
              styles.block,
              'flex-[4] justify-center bg-[#F1F3FF]', // Grapes hacking time: to be replaced by Secondary / Blue 10
            )}
          >
            {rightContent}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
