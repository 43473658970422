const diactriticsUnicodeRangeRegex = /[\u0300-\u036f]/g;

const specialChars: { [key: string]: string } = {
  ß: 'ss',
  æ: 'ae',
  œ: 'oe',
  ł: 'l',
};

export const normalizeString = (string: string): string =>
  string
    .replaceAll(/[ßæœł]/g, (match) => specialChars[match])
    .toLowerCase()
    .normalize('NFD')
    .replaceAll(diactriticsUnicodeRangeRegex, '');
