import * as yup from 'yup';

import { isInUnion } from "src/core/utils/validators";

/**
 * Formik errors are strings, but we want to use a more specific type, ie 'required' instead of 'string'
 * These utils are used to from formik errors to our specific errors unions
 */

export const requiredError = 'required' as const;
export type RequiredError = typeof requiredError;

export const parseToRequiredError = (
  error?: string,
): RequiredError | undefined => (error ? requiredError : undefined);

export const parseFromFormikError = <TError>(
  errors: TError[],
  formikError: string | undefined,
  defaultError: TError,
): TError | undefined => {
  if (formikError === undefined) {
    return undefined;
  }

  return isInUnion<TError>(errors, formikError) ? formikError : defaultError;
};

/**
 * Yup schema utils
 */

export const amount = yup.number().required(requiredError);
export const optionalAmount = yup.number().optional();

export const text = yup.string().required(requiredError);
export const optionalText = yup.string().optional();

export const optionalDate = yup.date().optional();
