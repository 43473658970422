// TODO clarify and merge types if possible
export type IntegrationVatAccount = {
  type: 'vat';
  id: string;
  name: string;
  description?: string;
  code: string;
  rate: string;
  isArchived?: boolean;
};

export type IntegrationReverseChargeAccount = {
  type: 'reverseCharge';
  id: string;
  name: string;
  description?: string;
  codeCredit: string;
  codeDebit: string;
  isArchived?: boolean;
};

export type TaxAccount =
  | IntegrationVatAccount
  | IntegrationReverseChargeAccount;

export type NewIntegrationVatAccount = {
  name: string;
  code: string;
  rate: string;
  type: 'vat';
};

export type NewIntegrationReverseChargeAccount = {
  name: string;
  codeDebit: string;
  codeCredit: string;
  type: 'reverseCharge';
};

export type TaxAccountUpdate = {
  id?: string;
  code: string;
  rate: string;
  name: string;
  isArchived?: boolean;
};

export const getDefaultTaxAccountName = (accountingCountry: string): string => {
  switch (accountingCountry) {
    case 'FR':
      return 'Compte par défaut';
    case 'DE':
      return 'Sammelkonto (MwSt. - Konten)';
    default:
      return 'Default account';
  }
};

export type NewTaxAccount =
  | NewIntegrationVatAccount
  | NewIntegrationReverseChargeAccount;

const isIntegrationVatAccount = (
  taxAccount: TaxAccount,
): taxAccount is IntegrationVatAccount => taxAccount.type === 'vat';

export const filterIntegrationVatAccounts = (
  taxAccounts: TaxAccount[],
): IntegrationVatAccount[] => {
  return taxAccounts.filter(isIntegrationVatAccount);
};

const isIntegrationReverseChargeAccount = (
  taxAccount: TaxAccount,
): taxAccount is IntegrationReverseChargeAccount =>
  taxAccount.type === 'reverseCharge';

export const filterIntegrationReverseChargeAccounts = (
  taxAccounts: TaxAccount[],
): IntegrationReverseChargeAccount[] => {
  return taxAccounts.filter(isIntegrationReverseChargeAccount);
};
