import { useHistory } from 'react-router-dom';

import { routeBuilder } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';

type Params = {
  paymentId?: string;
};

export const useGoToPaymentHistory = () => {
  const getRoute = usePaymentHistoryRoute();
  const history = useHistory();

  return (params: Params) => {
    const route = getRoute(params);
    history.push(route);
  };
};

export const usePaymentHistoryRoute = () => {
  const companyId = useCompanyId();
  return (params: Params): string => getPaymentHistoryRoute(companyId, params);
};

const getPaymentHistoryRoute = (
  companyId: string,
  { paymentId }: Params,
): string => {
  if (paymentId) {
    return routeBuilder('INVOICES_HISTORY', {
      company: companyId,
      paymentId,
    });
  }

  return routeBuilder('INVOICES_HISTORY', {
    company: companyId,
  });
};
