import uniq from 'lodash/uniq';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export type UserDetail = {
  id: string;
  avatar: string;
  fullname: string;
  email: string;
};

export const useGetUsersById = (
  userIds: string[],
): QueryState<UserDetail[]> => {
  return useQuery<UserDetail[], UserDetail[]>({
    key: ['users', { userIds }],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users?ids=${uniq(userIds ?? []).join(',')}`,
    },
  });
};
