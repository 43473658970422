import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useClickAway } from 'react-use';

import { type Company } from 'modules/app/hooks/useCompany';
import CONFIG from 'src/core/config';

import './GringottsIframe.css';

interface Props {
  companyId: string;
  bankingProvider: Company['banking_provider'];
  onClose(): void;
}

export const GringottsIframe = ({
  companyId,
  bankingProvider,
  onClose,
}: Props) => {
  const iframeReference = useRef(null);
  useClickAway(iframeReference, () => onClose());

  const apiBaseUrl = CONFIG.apiUrls.api;
  const { gringottsBaseUrl } = CONFIG;
  const gringottsApi = `${gringottsBaseUrl}/?companyId=${companyId}&bankingProvider=${bankingProvider}&apiBaseUrl=${encodeURIComponent(
    apiBaseUrl,
  )}`;

  return ReactDOM.createPortal(
    <iframe
      ref={iframeReference}
      title="gringotts"
      src={gringottsApi}
      className="GringottsIframe"
    />,
    document.body,
  );
};
