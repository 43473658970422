import { BucketType } from '../../prepare/utils/buckets';

type GraphQLExpenseObject = {
  __typename: string;
  proofs: {
    id: string;
    type: 'receipt' | 'affidavit';
  }[];
  missingProof: {
    id: string;
  } | null;
};

export const expenseToBucketId = (
  expense: GraphQLExpenseObject,
): BucketType => {
  if (expense.__typename === 'MileageAllowanceExpense') {
    return BucketType.WithProof;
  }

  if (expense.missingProof || expense.proofs[0]?.type === 'affidavit') {
    return BucketType.MissingProof;
  }

  if (expense.proofs.length === 0) {
    return BucketType.WithoutProof;
  }

  return BucketType.WithProof;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const expenseGroupToGraphQLDataId = (graphQLObject: any): string => {
  // Workaround because we can have the same ExpenseGroup in two different
  // buckets but without the same counters.
  // For `natural` bucket we don't fetch expenses proofs stuff,
  // otherwise we compute the related bucket from proofs data.
  const bucketId =
    graphQLObject.expenses &&
    graphQLObject.expenses.edges &&
    graphQLObject.expenses.edges.length
      ? expenseToBucketId(graphQLObject.expenses.edges[0].node)
      : BucketType.Natural;

  return `ExpenseGroup:${graphQLObject.id}:${bucketId}`;
};
