import { Button } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import {
  useProcedure,
  useStartOrContinueProcedure,
} from 'src/core/modules/procedures';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

export const StartOrContinueButton = () => {
  const { t } = useTranslation('global');

  const procedure = useProcedure();
  const startOrContinueProcedure = useStartOrContinueProcedure();

  const hasStartedProcedure = !!procedure;

  return (
    <Button
      className="mt-s h-fit self-center"
      text={
        hasStartedProcedure
          ? t('misc.continue')
          : t('onboardingHub.kycTask.startCta')
      }
      onClick={() => {
        if (hasStartedProcedure) {
          track(AnalyticEventName.KYC_RESUME);
        }
        startOrContinueProcedure();
      }}
    />
  );
};
