import { validateYupSchema, yupToFormErrors } from 'formik';
import * as yup from 'yup';

import {
  BankFields,
  isAccountCodeValid,
  isAccountNumberValid,
  isBicSwiftValid,
  isIbanValid,
  isRoutingNumberValid,
  isSortCodeValid,
  isAccountHolderNameValid,
} from 'src/core/utils/bankInfoFormats';

import { type SupplierBankFields, type SupplierBankInfos } from './supplier';

export type BankInfoRequiredError = 'required';
export type BankInfoInvalidError = 'invalid';

export type BankInfosErrors = {
  accountHolderName?: BankInfoRequiredError | BankInfoInvalidError;
  accountCode?: BankInfoRequiredError | BankInfoInvalidError;
  accountNumber?: BankInfoRequiredError | BankInfoInvalidError;
  bankCountry?: BankInfoRequiredError;
  bic?: BankInfoRequiredError | BankInfoInvalidError;
  iban?: BankInfoRequiredError | BankInfoInvalidError;
  routingNumber?: BankInfoRequiredError | BankInfoInvalidError;
  sortCode?: BankInfoRequiredError | BankInfoInvalidError;
};

export type BankInfosValidationContext = {
  supplierBankFields: SupplierBankFields;
};

export const validateSupplierBankInfos = (
  bankInfos: SupplierBankInfos,
  validationContext: BankInfosValidationContext,
): BankInfosErrors => {
  try {
    validateYupSchema(
      bankInfos,
      supplierBankInfosSchema,
      true,
      validationContext,
    );
    return {};
  } catch (error) {
    return yupToFormErrors<SupplierBankInfos>(error) as BankInfosErrors;
  }
};

const stringSchema = yup.string().required('required');

const accountCodeSchema = stringSchema.test(
  'accountCodeValidation',
  'invalid',
  (value) => (value ? isAccountCodeValid(value) : true),
);
const accountNumberSchema = stringSchema.test(
  'accountNumberValidation',
  'invalid',
  (value) => (value ? isAccountNumberValid(value) : true),
);
const bicSchema = stringSchema.test('bicValidation', 'invalid', (value) =>
  value ? isBicSwiftValid(value) : true,
);
const ibanSchema = stringSchema.test('ibanValidation', 'invalid', (value) =>
  value ? isIbanValid(value) : true,
);
const routingNumberSchema = stringSchema.test(
  'routingNumberValidation',
  'invalid',
  (value) => (value ? isRoutingNumberValid(value) : true),
);
const sortCodeSchema = stringSchema.test(
  'sortCodeValidation',
  'invalid',
  (value) => (value ? isSortCodeValid(value) : true),
);

const accountHolderNameSchema = stringSchema.test(
  'accountNumberValidation',
  'invalid',
  (value) => (value ? isAccountHolderNameValid(value) : true),
);

const generateBankFieldSchema = (
  bankField: BankFields,
  fieldSchema: yup.AnySchema,
  supplierBankFields: SupplierBankFields,
) => {
  if (supplierBankFields[bankField]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return supplierBankFields[bankField]!.isOptional
      ? fieldSchema.optional()
      : fieldSchema.required('required');
  }
  return yup.mixed();
};

const supplierBankInfosSchema = yup.object().shape({
  accountHolderName: accountHolderNameSchema.when(
    '$supplierBankFields',
    ([supplierBankFields], schema) =>
      generateBankFieldSchema(
        BankFields.AccountHolderName,
        schema,
        supplierBankFields,
      ),
  ),
  accountCode: accountCodeSchema.when(
    '$supplierBankFields',
    ([supplierBankFields], schema) =>
      generateBankFieldSchema(
        BankFields.AccountCode,
        schema,
        supplierBankFields,
      ),
  ),
  accountNumber: accountNumberSchema.when(
    '$supplierBankFields',
    ([supplierBankFields], schema) =>
      generateBankFieldSchema(
        BankFields.AccountNumber,
        schema,
        supplierBankFields,
      ),
  ),
  bankCountry: stringSchema.required('required'),
  bic: bicSchema.when('$supplierBankFields', ([supplierBankFields], schema) =>
    generateBankFieldSchema(BankFields.BicSwift, schema, supplierBankFields),
  ),
  iban: ibanSchema.when('$supplierBankFields', ([supplierBankFields], schema) =>
    generateBankFieldSchema(BankFields.Iban, schema, supplierBankFields),
  ),
  routingNumber: routingNumberSchema.when(
    '$supplierBankFields',
    ([supplierBankFields], schema) =>
      generateBankFieldSchema(
        BankFields.RoutingNumber,
        schema,
        supplierBankFields,
      ),
  ),
  sortCode: sortCodeSchema.when(
    '$supplierBankFields',
    ([supplierBankFields], schema) =>
      generateBankFieldSchema(BankFields.SortCode, schema, supplierBankFields),
  ),
});
