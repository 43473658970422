/**
 * Given a Date object as input, test whether a date is valid or not.
 *
 * @param {Date} d    The Date object
 * @return {boolean}  true / false
 */
export function isValidDate(d: Date): boolean {
  return (
    d !== undefined &&
    d !== null &&
    d instanceof Date &&
    !Number.isNaN(d.getTime())
  );
}

/**
 * Given a string value, tries to parse it as
 * a date. If successful, returns the Date object represented
 * by the string value; otherwise returns today's Date object.
 *
 * @param {string} value  A string representation of the date
 * @return {Date}         The Date object
 */
export function getValueDate(value: string): Date {
  const date = new Date(value);
  return isValidDate(date) ? date : new Date();
}

/**
 * Given a string value, tries to parse it as a date.
 * If successful returns true, otherwise false.
 * @param {string} s  A string representation of the date
 * @returns {boolean} true / false
 */
export function isValidDateString(s: string): boolean {
  const date = new Date(s);
  return isValidDate(date);
}
