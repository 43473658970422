import { startOfDay, isAfter, isSameDay, isWithinInterval } from 'date-fns';
import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from "src/core/common/hooks/useTranslation";

import { convertToDate } from './dates';

export type FormState = {
  fromMonthIndex: number | undefined;
  fromYear: string;
  toMonthIndex: number | undefined;
  toYear: string;
};

export const validate = (
  values: FormState,
  t: TGlobalFunctionTyped,
  maxRange: { from: Date; to: Date },
): FormikErrors<{
  fromMonthIndex: string;
  toMonthIndex: string;
}> => {
  const errors: FormikErrors<{
    fromMonthIndex: string;
    toMonthIndex: string;
    rangeError: string;
  }> = {};
  if (
    values.fromMonthIndex === undefined &&
    values.toMonthIndex === undefined
  ) {
    errors.toMonthIndex = t('budget.breakdownSpendings.error');
  }
  if (
    values.fromMonthIndex &&
    values.toMonthIndex &&
    !isEndDateAfterStartDate({
      from: convertToDate(values.fromMonthIndex, values.fromYear),
      to: convertToDate(values.toMonthIndex, values.toYear),
    })
  ) {
    errors.toMonthIndex = t('budget.breakdownSpendings.errorWrongDates');
  }
  if (
    values.fromMonthIndex &&
    values.toMonthIndex &&
    !isInRange(maxRange, {
      from: convertToDate(values.fromMonthIndex, values.fromYear),
      to: convertToDate(values.toMonthIndex, values.toYear),
    })
  ) {
    errors.toMonthIndex = t('budget.breakdownSpendings.errorInRange');
  }
  return errors;
};

const isEndDateAfterStartDate = (date: { from: Date; to: Date }) => {
  return isAfter(date.to, date.from) || isSameDay(date.to, date.from);
};

const isInRange = (
  maxRange: { from: Date; to: Date },
  valuesRange: { from: Date; to: Date },
) => {
  const budgetaryInterval = {
    start: startOfDay(maxRange.from),
    end: startOfDay(maxRange.to),
  };

  const valueInterval = {
    start: startOfDay(valuesRange.from),
    end: startOfDay(valuesRange.to),
  };

  return (
    isWithinInterval(valueInterval.start, budgetaryInterval) &&
    isWithinInterval(valueInterval.end, budgetaryInterval)
  );
};
