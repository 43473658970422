import {
  Button,
  FormField,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeaderWithIcon,
  ModalOverlay,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { useTranslation } from 'common/hooks/useTranslation';

import { AssignControlRuleLoader } from './AssignControlRuleLoader';
import type { FormValues } from '../../containers/AssignControlRuleModalContainer/AssignControlRuleModalContainer';
import { type ControlRule } from '../../hooks/useControlRulesQuery';

type Props = {
  userName?: string;
  controlRules?: ControlRule[];
  isLoading: boolean;
  onExit(): void;
} & FormikProps<FormValues>;

export const AssignControlRuleModal = ({
  userName,
  controlRules,
  isLoading,
  onExit,
  // formik props
  values,
  setFieldValue,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation('global');

  const availableValues = controlRules?.filter((cr) => !cr.isCustom) ?? []; // filter out custom control rules

  const autoCompleteValue =
    availableValues?.length === 1
      ? availableValues[0]
      : values.selectedControlRule;

  return (
    <ModalOverlay isOpen>
      <ModalContent onClose={onExit}>
        {isLoading ? (
          <ModalBody>
            <AssignControlRuleLoader />
          </ModalBody>
        ) : (
          <>
            <ModalHeaderWithIcon
              iconName="card-lock"
              title={t(
                'homepage.missingReceipts.assignControlRuleModal.title',
                {
                  name: userName,
                },
              )}
              subtitle={t(
                'homepage.missingReceipts.assignControlRuleModal.subtitle',
              )}
            />
            <ModalBody>
              <FormField
                label={t(
                  'homepage.missingReceipts.assignControlRuleModal.label',
                )}
              >
                <AutocompleteSearch
                  fit="parent"
                  placeholder={t('members.selectRule')}
                  options={availableValues.map((controlRule) => ({
                    key: controlRule.id,
                    label: controlRule.name,
                  }))}
                  value={
                    autoCompleteValue
                      ? {
                          key: autoCompleteValue.id,
                          label: autoCompleteValue.name,
                        }
                      : undefined
                  }
                  onSelect={(selectedControlRule) => {
                    setFieldValue(
                      'selectedControlRule',
                      selectedControlRule
                        ? availableValues.find(
                            (controlRule) =>
                              controlRule.id === selectedControlRule.key,
                          )
                        : null,
                    );
                  }}
                  renderNoOptions={(rawValue) => (
                    <AutocompleteNoOptions value={rawValue} />
                  )}
                />
              </FormField>
            </ModalBody>
            <ModalFooter>
              <Button
                text={t('misc.cancel')}
                onClick={onExit}
                variant="secondary"
              />
              <Button
                variant="primary"
                text={t(
                  'homepage.missingReceipts.assignControlRuleModal.buttonSubmit',
                )}
                onClick={async () => {
                  await handleSubmit();
                }}
              />
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};
