import { useDispatch } from 'react-redux';

import { fetchMe } from 'modules/app/layout/redux/actions';
import { type AppDispatch } from 'modules/app/redux/store';
import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

type Result =
  | { outcome: 'activated' }
  | { outcome: 'notActivated'; reason: 'notAuthorized' };

export const useOptInToAnalyticalSplitMutation = (): MutationState<
  void,
  Result
> => {
  const dispatch = useDispatch<AppDispatch>();

  return useMutation<void, Result, Result>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'put',
      endpoint: '/bookkeeping/enable-analytical-split',
    },
    options: {
      onSuccess: async ({ data }) => {
        if (data.outcome !== 'activated') {
          throw new Error(data.reason);
        }
        await dispatch(fetchMe());
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};
