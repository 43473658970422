import { Link } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type SchemeType } from '../../models/mileageScheme';

type Props = {
  className?: string;
  schemeType: SchemeType | undefined;
};

export const MileageRequestComputationContentSchemeExplanation = ({
  className,
  schemeType,
}: Props) => {
  const { t } = useTranslation('global');
  let explanation = null;
  switch (schemeType) {
    case 'FR':
      explanation = (
        <Trans
          i18nKey="requests.panel.mileage.computationFooterExplanation"
          components={{
            a: (
              <Link
                href="https://helpcenter.spendesk.com/articles/4729427"
                target="_blank"
                rel="noreferrer"
              >
                -
              </Link>
            ),
          }}
        />
      );
      break;
    case 'UK':
      explanation = (
        <Trans
          i18nKey="requests.panel.mileage.computationFooterExplanation"
          components={{
            a: (
              <Link
                href="https://helpcenter.spendesk.com/articles/9206924"
                target="_blank"
                rel="noreferrer"
              >
                -
              </Link>
            ),
          }}
        />
      );
      break;
    case 'DE':
      explanation = (
        <Trans
          i18nKey="requests.panel.mileage.computationFooterExplanation"
          components={{
            a: (
              <Link
                href="https://helpcenter.spendesk.com/articles/9207025"
                target="_blank"
                rel="noreferrer"
              >
                -
              </Link>
            ),
          }}
        />
      );
      break;
    case 'FX':
      explanation = (
        <>{t('requests.panel.mileage.computationFooterExplanationCustom')}</>
      );
      break;
    default:
      explanation = (
        <>{t('requests.panel.mileage.computationFooterExplanationNoLink')}</>
      );
  }
  return (
    <div className={classNames(className, 'text-neutral-dark body-s')}>
      {explanation}
    </div>
  );
};
