import { gql } from 'graphql-tag';

export const GET_DEFAULT_EMPLOYEE_ACCOUNTS = gql`
  query GetDefaultEmployeeAccounts($companyId: ID!) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        employeeAccounts(filter: { isDefault: true, includeArchived: false }) {
          totalCount
          # FIXME: pagination isn't implemented yet
          # pageInfo {
          #   hasNextPage
          # }
          edges {
            cursor
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
              isArchived
            }
          }
        }
      }
    }
  }
`;

export const GET_DEFAULT_SUPPLIER_ACCOUNTS = gql`
  query GetDefaultSupplierAccounts($companyId: ID!) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        supplierAccounts(filter: { isDefault: true, includeArchived: false }) {
          totalCount
          # FIXME: pagination isn't implemented yet
          # pageInfo {
          #   hasNextPage
          # }
          edges {
            cursor
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
              isArchived
            }
          }
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_ACCOUNT = gql`
  query GetEmployeeAccount($companyId: ID!, $userId: String!) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        employeeAccounts(filter: { creditorId: $userId }) {
          totalCount
          # FIXME: pagination isn't implemented yet
          # pageInfo {
          #   hasNextPage
          # }
          edges {
            cursor
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
            }
          }
        }
      }
    }
  }
`;

export const GET_PAYABLES_COUNT = gql`
  query GetPayablesCount($companyId: ID!, $after: ID, $filter: PayableFilter!) {
    company(id: $companyId) {
      id
      payables(first: 15, after: $after, filter: $filter) {
        totalCount
      }
    }
  }
`;
