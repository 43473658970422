import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { NotificationType, useNotifications } from '../../app/notifications';
import { type CompanyKomboConfiguration } from '../types/kombo';

export const useUpdateKomboSettings = (): MutationState<{
  companySettings: CompanyKomboConfiguration;
}> => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  return useMutation<{ companySettings: CompanyKomboConfiguration }>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/integrations/kombo/company-settings',
    },
    options: {
      onSuccess({ client }): void {
        pushNotif({
          type: NotificationType.Success,
          message: t('integration.kombo.settings.notifications.updated'),
        });
        client.invalidateQueries('komboSettings');
        client.invalidateQueries('integrationsStatus');
        client.invalidateQueries([
          'integrations',
          'kombo',
          'is-company-managed',
        ]);
        client.invalidateQueries([
          'integrations',
          'kombo',
          'managed-employees',
        ]);
        client.invalidateQueries(['integrations', 'kombo', 'employee']);
        client.invalidateQueries(['integrations', 'kombo', 'preview']);
      },
    },
  });
};
