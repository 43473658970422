import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import type { AppDispatch } from 'modules/app/redux/store';

import { invalidateTransferQueries } from './useFetchTransfers';
import { invalidateInvoicesCounts } from '../../../hooks';
import { fetchInvoicesCounts } from '../../../redux/thunks';

export const useInvalidateTransferQueries = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  return (): void => {
    invalidateTransferQueries(queryClient);
    invalidateInvoicesCounts(queryClient);
    dispatch(fetchInvoicesCounts());
  };
};
