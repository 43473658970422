import { FormField } from '@dev-spendesk/grapes';
import React, { useEffect } from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { type CostCenter } from 'modules/company/cost-centers/costCenter';
import FEATURES from 'src/core/constants/features';

import { ROLE_NAMES_TO_ASSIGN } from '../../constants/roles';
import {
  type DataSources,
  type DisplayablePolicy,
  type Settings,
  type SettingsKeys,
} from '../../models/invite';
import { translateRoleName, type Role } from '../../models/roles';
import type { Team } from '../../models/teams';

type AutocompleteOption = { key: string; label: string };

const renderAutoComplete = (
  label: string,
  values: AutocompleteOption[],
  value: AutocompleteOption | undefined,
  onSelect: (selection: AutocompleteOption | undefined) => void,
) => (
  <FormField label={label}>
    <AutocompleteSearch
      fit="parent"
      options={values}
      value={value}
      onSelect={onSelect}
      renderNoOptions={(rawValue) => <AutocompleteNoOptions value={rawValue} />}
    />
  </FormField>
);

const RoleSelect = ({
  roles,
  role,
  onSelect,
}: {
  roles: Role[];
  role: Role;
  onSelect: (selectedRole: Role) => void;
}) => {
  const { t } = useTranslation('global');

  const filteredRoles = roles.filter((roleToFilter) =>
    ROLE_NAMES_TO_ASSIGN.includes(roleToFilter.name),
  );

  return renderAutoComplete(
    t('members.inviteSettings.roleLabel'),
    filteredRoles.map((roleToDisplay) => ({
      key: roleToDisplay.id,
      label: translateRoleName(roleToDisplay.name, t),
    })),
    { key: role.id, label: translateRoleName(role.name, t) },
    (selectedRole) => {
      const roleToSelect = filteredRoles.find(
        (filteredRole) => filteredRole.id === selectedRole?.key,
      );
      if (roleToSelect) {
        onSelect(roleToSelect);
      }
    },
  );
};

const PolicySelect = ({
  policies,
  policy,
  onSelect,
}: {
  policies: DisplayablePolicy[];
  policy?: DisplayablePolicy;
  onSelect: (selectedPolicy: DisplayablePolicy) => void;
}) => {
  const { t } = useTranslation('global');

  return renderAutoComplete(
    t('members.inviteSettings.policyLabel'),
    policies.map((policyToDisplay) => ({
      key: policyToDisplay.id,
      label: policyToDisplay.name,
    })),
    policy ? { key: policy.id, label: policy.name } : undefined,
    (selectedPolicy) => {
      const policyToSelect = policies.find(
        (filteredPolicy) => filteredPolicy.id === selectedPolicy?.key,
      );
      if (policyToSelect) {
        onSelect(policyToSelect);
      }
    },
  );
};

const TeamSelect = ({
  teams,
  team,
  onSelect,
}: {
  teams: Team[];
  team: Team | undefined;
  onSelect: (selectedTeam: Team | undefined) => void;
}) => {
  const { t } = useTranslation('global');

  const noTeam = { id: 'none', name: t('teams.none') };
  const displayedTeam = team ?? noTeam;
  const allTeams = [noTeam, ...teams];

  return renderAutoComplete(
    t('members.inviteSettings.teamLabel'),
    allTeams.map((teamToDisplay) => ({
      key: teamToDisplay.id,
      label: teamToDisplay.name,
    })),
    { key: displayedTeam.id, label: displayedTeam.name },
    (selectedTeam) => {
      const teamToSelect = teams.find(
        (filteredTeam) => filteredTeam.id === selectedTeam?.key,
      );
      if (teamToSelect) {
        onSelect(teamToSelect.id !== 'none' ? teamToSelect : undefined);
      }
    },
  );
};

const CostCenterSelect = ({
  costCenters,
  costCenter,
  onSelect,
}: {
  costCenters: CostCenter[];
  costCenter: CostCenter | undefined;
  onSelect: (selectedCostCenter: CostCenter | undefined) => void;
}) => {
  const { t } = useTranslation('global');

  const noCostCenter = {
    id: 'none',
    name: t('members.inviteNoCostCenter'),
  };
  const displayedCostCenter = costCenter ?? noCostCenter;
  const allCostCenters = [noCostCenter, ...costCenters];

  return renderAutoComplete(
    t('members.inviteSettings.costCenterLabel'),
    allCostCenters.map((costCenterToDisplay) => ({
      key: costCenterToDisplay.id,
      label: costCenterToDisplay.name,
    })),
    { key: displayedCostCenter.id, label: displayedCostCenter.name },
    (selectedCostCenter) => {
      const costCenterToSelect = costCenters.find(
        (filteredCostCenter) =>
          filteredCostCenter.id === selectedCostCenter?.key,
      );
      if (costCenterToSelect) {
        onSelect(costCenterToSelect);
      } else {
        onSelect(undefined);
      }
    },
  );
};

type Props = {
  dataSources: DataSources;
  onSelect: (settings: Settings) => void;
  setOneSetting: (key: SettingsKeys, value: Settings[SettingsKeys]) => void;
  settings: Settings;
};

export const MemberInviteSettings = ({
  dataSources,
  settings,
  setOneSetting,
  onSelect,
}: Props) => {
  const { t } = useTranslation('global');
  const isSpendingPoliciesFeatureEnabled = useFeature(
    FEATURES.SPENDING_POLICIES,
  );
  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);
  const isCostCenterActivatedFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );

  const { role, policy, team, costCenter } = settings;

  useEffect(
    () =>
      onSelect({
        role,
        policy,
        team,
        costCenter,
      }),
    [role, policy, team, costCenter],
  );

  return (
    <div className="bg-neutral-lightest p-s">
      <p>{t('members.inviteSettings.membersWillBeAssigned')}</p>
      <div className="mt-s grid grid-cols-2 gap-s">
        <RoleSelect
          roles={dataSources.roles}
          role={role}
          onSelect={(selectedRole) => setOneSetting('role', selectedRole)}
        />
        {isSpendingPoliciesFeatureEnabled && (
          <PolicySelect
            policies={dataSources.policies}
            policy={policy}
            onSelect={(selectedPolicy) =>
              setOneSetting('policy', selectedPolicy)
            }
          />
        )}
        {!isCostCenterActivatedFeatureEnabled && isTeamsFeatureEnabled && (
          <TeamSelect
            teams={dataSources.teams}
            team={team}
            onSelect={(selectedTeam) => setOneSetting('team', selectedTeam)}
          />
        )}
        {isCostCenterActivatedFeatureEnabled && (
          <CostCenterSelect
            costCenters={dataSources.costCenters}
            costCenter={costCenter}
            onSelect={(selectedCostCenter) =>
              setOneSetting('costCenter', selectedCostCenter)
            }
          />
        )}
      </div>
    </div>
  );
};
