import { Autocomplete, type AutocompleteProps } from '@dev-spendesk/grapes';
import React, { type ReactChild } from 'react';

import type { Option } from './option';
import { useSearch } from './useSearch';

export type Props<T extends Option> = Omit<
  AutocompleteProps<T>,
  'onSearch' | 'renderNoOptions' | 'onAddOption' | 'renderAddOption'
> &
  (
    | {
      renderAddOption(rawValue: string): ReactChild; // should always return a DropdownItem component
      onAddOption(newOptionLabel: string): T | Promise<T>;
      renderNoOptions?(rawValue: string): ReactChild;
      onInputChanged?: (value?: string) => void;
    }
    | {
      renderNoOptions(rawValue: string): ReactChild;
      renderAddOption?: never;
      onAddOption?: never;
      onInputChanged?: (value?: string) => void;
    }
  );

export const AutocompleteSearch = <T extends Option>({
  options,
  onInputChanged,
  ...props
}: Props<T>) => {
  const { filteredOptions, handleSearch } = useSearch(options);
  return (
    <Autocomplete
      {...props}
      options={filteredOptions}
      onSearch={(value) => {
        if (onInputChanged) {
          onInputChanged(value);
        }
        return handleSearch(value);
      }}
    />
  );
};
