import { type PreparePayablesInputConfig } from '../../panelInputConfig';

export const removeCustomFieldsSelectsWithNoValues = (
  customFieldsInputsConfig: PreparePayablesInputConfig[],
): PreparePayablesInputConfig[] =>
  customFieldsInputsConfig.filter(
    (inputConfig) =>
      inputConfig.type !== 'select' || inputConfig.values.length > 0,
  );

export const isEditableCustomField = (
  inputConfig: Pick<PreparePayablesInputConfig, 'isSplittable' | 'type'>,
  editSplitValues: boolean,
): boolean => {
  if (editSplitValues) {
    return true;
  }

  return (
    !inputConfig.isSplittable ||
    // TODO@finance-accountant for now we don't support boolean splittable custom fields
    !['search', 'select'].includes(inputConfig.type)
  );
};
