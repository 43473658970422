import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useResendInvitationMutation = (
  inviteId: string | undefined,
): MutationState => {
  return useMutation({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/invites/${inviteId}/resend`,
      method: 'put',
    },
    options: {
      throwOnError: true,
    },
  });
};
