import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type Invite, type RawInvite } from '../models/invite';
import { reshapeInvite } from '../utils/reshapeInvite';

export const useInvitesQuery = (): QueryState<Invite[]> => {
  return useQuery<Invite[], RawInvite[]>({
    key: ['invites'],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/invites`,
    },
    reshapeData: (data) => data.map(reshapeInvite),
  });
};
