import { type Role } from 'modules/members/models/roles';

import { type DataSources, type Settings } from '../../models/invite';

export const getDefaultSettings = (dataSources: DataSources): Settings => {
  const { roles, policies } = dataSources;

  return {
    // In practice, the Requester role is always there.
    role: roles.find((role) => role.name === 'Requester') as Role,
    // In practice, there should be always at least the standard company policy.
    policy: policies.find((policy) => policy.isDefault),
    costCenter: undefined,
  };
};
