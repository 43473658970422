import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useIsUserManagedByKombo } from 'src/core/modules/integrations/hooks';

import { useGetCompanyManagedByKomboHrIntegrationQuery } from './useGetCompanyManagedByKomboHrIntegrationQuery';
import { useHasCompanyUserBankInfos } from './useHasCompanyUserBankInfos';
import { type Member } from '../models/member';

export const useCanEditUserBankInfo = (user: Member): boolean => {
  const isManagedByKombo = useIsUserManagedByKombo({ userId: user.id });
  const getCompanyManagedByKombo =
    useGetCompanyManagedByKomboHrIntegrationQuery();
  const hasCompanyUserBankInfos = useHasCompanyUserBankInfos();

  const hasBankAccountInfoInHrTool =
    unwrapQuery(getCompanyManagedByKombo)?.featureSet.includes(
      'includesBankDetails',
    ) ?? false;

  return (
    hasCompanyUserBankInfos && !(isManagedByKombo && hasBankAccountInfoInHrTool)
  );
};
