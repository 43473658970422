import { type FormikErrors, type FormikProps, useFormik } from 'formik';

import type { CostCenter } from 'modules/budgets/models/costCenter';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type FormValues } from './formValues';
import { type ExpenseCategory } from '../../expenseCategory';
import { type ExpenseCategoryExpenseAccountRule } from '../../hooks/useGetExpenseCategoryExpenseAccountRulesLazyQuery';

const removeUnavailableCostCenters = (
  costCenters: CostCenter[],
  costCenterIds: string[] = [],
): string[] => {
  return costCenterIds.filter((costCenterId) =>
    costCenters.find((costCenter) => costCenter.id === costCenterId),
  );
};

export const useEcFormikProps = ({
  expenseCategory,
  costCenters,
  expenseCategoryNames,
  expenseCategoryExpenseAccountRules,
  onSubmit,
}: {
  expenseCategory?: ExpenseCategory;
  costCenters: CostCenter[];
  expenseCategoryNames: string[];
  expenseCategoryExpenseAccountRules: ExpenseCategoryExpenseAccountRule[];
  onSubmit(values: FormValues): Promise<void>;
}): FormikProps<FormValues> => {
  const { t } = useTranslation('global');
  return useFormik<FormValues>({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      name: expenseCategory?.name ?? '',
      costCenterIds: removeUnavailableCostCenters(
        costCenters,
        expenseCategory?.costCenterIds,
      ),
      costCenterAssignationType:
        (expenseCategory?.costCenterIds ?? []).length > 0 ? 'some' : 'all',
      linkedExpenseAccountIds:
        expenseCategoryExpenseAccountRules.find(
          ({ expenseCategoryId }) => expenseCategoryId === expenseCategory?.id,
        )?.expenseAccountIds ?? [],
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (values.name?.trim() === '') {
        errors.name = t('expenseCategories.form.nameEmptyError');
      }
      if (
        expenseCategoryNames.some(
          (name) => values.name.toLowerCase() === name.toLowerCase(),
        )
      ) {
        errors.name = t('customFields.creation.duplicateValues');
      }
      if (
        values.costCenterAssignationType === 'some' &&
        values.costCenterIds.length === 0
      ) {
        errors.costCenterIds = t('expenseCategories.form.selectOneCostCenter');
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit({
        ...values,
        costCenterIds:
          values.costCenterAssignationType === 'all'
            ? []
            : values.costCenterIds,
      });
      setSubmitting(false);
    },
  });
};
