import { FormField, Input } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type BankInfosErrors, type SupplierBankInfos } from '../../../models';

type Props = {
  className?: string;
  isOptional?: boolean;
  accountHolderName: SupplierBankInfos['accountHolderName'];
  error?: BankInfosErrors['accountHolderName'];
  onChange(accountHolderName: SupplierBankInfos['accountHolderName']): void;
};

export const AccountHolderNameFormField = ({
  className,
  isOptional,
  accountHolderName,
  error,
  onChange,
}: Props) => {
  const { t } = useTranslation('global');

  const getMessageFromError = (
    errorMessage: BankInfosErrors['accountHolderName'],
  ): string => {
    switch (errorMessage) {
      case 'invalid': {
        return t('submitMyInvoice.fields.accountHolderName.invalid');
      }
      default:
        return t('misc.requiredField');
    }
  };

  return (
    <FormField
      className={className}
      label={t('submitMyInvoice.fields.accountHolderName.label')}
      alertMessage={error ? getMessageFromError(error) : undefined}
      infoTipContent={t('submitMyInvoice.fields.accountHolderName.hint')}
      hint={isOptional ? t('misc.optional') : undefined}
    >
      <Input
        name="accountHolderName"
        value={accountHolderName ?? ''}
        placeholder={t('submitMyInvoice.fields.accountHolderName.placeholder')}
        onChange={(event) => onChange(event.target.value)}
      />
    </FormField>
  );
};
