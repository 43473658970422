import { Callout } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routeFor, routes } from 'src/core/constants/routes';

type Props = {
  isLockedPayable?: boolean;
  wasUnlockablePayable?: boolean;
  className?: string;
};

export const PayablePanelLockFeedback = ({
  isLockedPayable,
  wasUnlockablePayable,
  className = '',
}: Props) => {
  const companyId = useCompanyId();

  if (wasUnlockablePayable) {
    return (
      <Callout
        variant="neutral"
        className={classNames(
          className,
          '[&_a]:text-neutral-darker [&_a]:underline',
        )}
        iconName="lock"
        title={
          <Trans
            i18nKey="payables.panel.editionAfterExportDisabled"
            components={{
              a: (
                <Link
                  to={routeFor(
                    routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path,
                    { company: companyId },
                  )}
                >
                  -
                </Link>
              ),
            }}
          />
        }
      />
    );
  }

  if (isLockedPayable) {
    return (
      <Callout
        variant="neutral"
        className={classNames(
          className,
          '[&_a]:text-neutral-darker [&_a]:underline',
        )}
        iconName="lock"
        title={
          <Trans
            i18nKey="payables.panel.isInLockedPeriod"
            components={{
              a: (
                <Link
                  to={routeFor(
                    routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path,
                    { company: companyId },
                  )}
                >
                  -
                </Link>
              ),
            }}
          />
        }
      />
    );
  }
};
