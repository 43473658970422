import {
  BaseNode,
  LabelNode,
  StandardApprovalFlowNode,
} from './components/node-components';

export const nodeTypes = {
  labelNode: LabelNode,
  baseNode: BaseNode,
  standardApprovalFlowNode: StandardApprovalFlowNode,
};
