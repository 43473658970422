import { Button, Modal } from '@dev-spendesk/grapes';
import { type FormikProps, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { CostCenterFormApprovalFlowContent } from './CostCenterFormApprovalFlowContent';
import { CostCenterFormDetailsContent } from './CostCenterFormDetailsContent';
import type { FormValues } from './formValues';
import { validateCostCenterForm } from './validator';
import type { CostCenter } from '../../costCenter';
import { type ApprovalScheme } from '../../hooks/useApprovalSchemeByCostCenterIdQuery';
import type { Member } from '../../member';
import { ReportingManagerMarketingBanner } from '../ReportingManagerMarketingBanner';

type Props = {
  costCenter?: CostCenter;
  approvalScheme?: ApprovalScheme;
  members: Member[];
  costCenters: CostCenter[];
  isOpen: boolean;
  showBackButton?: boolean;
  onSubmit(rc: Omit<CostCenter, 'id' | 'approvalSchemeId'>): Promise<void>;
  onCancel(): void;
};

type Step = 'details' | 'approvalFlow';

export const CostCenterFormModal = ({
  costCenter,
  approvalScheme,
  members,
  costCenters,
  isOpen,
  onSubmit,
  onCancel,
  showBackButton = false,
}: Props) => {
  const { t } = useTranslation('global');
  const [step, setStep] = useState<Step>('details');
  const isReportingManagerFeatureEnabled = useFeature(
    FEATURES.REPORTING_MANAGERS,
  );
  const type = costCenter ? 'edition' : 'creation';
  const isMultidimensionalApprovalWorkflowEnabled = useFeature(
    FEATURES.MULTIDIMENSIONAL_APPROVAL_WORKFLOW,
  );

  useEffect(() => {
    if (type === 'creation') {
      formikProps.resetForm();
    }
    setStep('details');
  }, [isOpen]);

  const formikProps = useFormik<FormValues>({
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      name: costCenter?.name ?? '',
      ownerId: costCenter?.ownerId,
      viewerIds: costCenter?.viewerIds ?? [],
      approvalRules: approvalScheme?.rules ?? [],
    },
    validate: (values) =>
      validateCostCenterForm(
        values,
        t,
        costCenter?.id,
        costCenters,
        values.ownerId ?? '',
      ),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit({
        name: values.name,
        ownerId: values.ownerId ?? '',
        viewerIds: values.viewerIds,
        approvalRules: values.approvalRules,
      });
      setSubmitting(false);
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      iconName="info"
      iconVariant="info"
      title={getModalTitle(type, step, t)}
      actions={getModalActions({
        type,
        step,
        formikProps,
        setStep,
        onCancel,
        translator: t,
        showBackButton,
        isMultidimensionalApprovalWorkflowEnabled,
      })}
    >
      {step === 'details' && (
        <CostCenterFormDetailsContent {...formikProps} members={members} />
      )}
      {step === 'approvalFlow' && (
        <>
          {t('costCenters.modalSubtitle')}
          {isReportingManagerFeatureEnabled && (
            <ReportingManagerMarketingBanner
              className="-mb-xs mt-s"
              variant="modal"
              title={t('costCenters.banner.title')}
              description={t('costCenters.banner.descriptionShort')}
            />
          )}
          <CostCenterFormApprovalFlowContent
            {...formikProps}
            costCenterOwnerId={formikProps.values.ownerId}
            members={members}
          />
        </>
      )}
    </Modal>
  );
};

const getModalTitle = (
  type: 'creation' | 'edition',
  step: Step,
  translator: TGlobalFunctionTyped,
) => {
  if (type === 'creation') {
    if (step === 'details') {
      return translator('costCenters.creation.titleDetails');
    }
    return translator('costCenters.creation.titleApprovalFlow');
  }
  if (step === 'details') {
    return translator('costCenters.edition.titleDetails');
  }
  return translator('costCenters.edition.titleApprovalFlow');
};

const getModalActions = ({
  type,
  step,
  formikProps,
  setStep,
  onCancel,
  translator,
  showBackButton,
  isMultidimensionalApprovalWorkflowEnabled,
}: {
  type: 'creation' | 'edition';
  step: Step;
  formikProps: FormikProps<FormValues>;
  setStep: (step: Step) => void;
  onCancel: () => void;
  translator: TGlobalFunctionTyped;
  showBackButton?: boolean;
  isMultidimensionalApprovalWorkflowEnabled: boolean;
}) => {
  // if multidimensional approval workflow is enabled, we submit the cost center creation/edition directly
  const submitButton = isMultidimensionalApprovalWorkflowEnabled ? (
    <Button
      key="configureApproval"
      variant="primary"
      text={
        type === 'creation'
          ? translator('costCenters.creation.submit')
          : translator('costCenters.edition.submit')
      }
      isDisabled={
        Boolean(formikProps.errors.name) ||
        Boolean(formikProps.errors.ownerId) ||
        !formikProps.values.name ||
        !formikProps.values.ownerId
      }
      onClick={formikProps.submitForm}
    />
  ) : (
    <Button
      key="configureApproval"
      variant="primary"
      text={translator('costCenters.configureApprovalFlow')}
      iconPosition="right"
      iconName="caret-right"
      isDisabled={
        Boolean(formikProps.errors.name) ||
        Boolean(formikProps.errors.ownerId) ||
        !formikProps.values.name ||
        !formikProps.values.ownerId
      }
      onClick={() => setStep('approvalFlow')}
    />
  );

  if (step === 'details') {
    return [
      <Button
        key="cancel"
        variant="secondary"
        iconName={showBackButton ? 'caret-left' : undefined}
        text={
          showBackButton ? translator('misc.back') : translator('misc.cancel')
        }
        onClick={onCancel}
      />,
      submitButton,
    ];
  }

  return [
    <Button
      key="back"
      variant="secondary"
      text={translator('costCenters.back')}
      onClick={() => setStep('details')}
    />,
    <Button
      key="submit"
      variant="primary"
      text={
        type === 'creation'
          ? translator('costCenters.creation.submit')
          : translator('costCenters.edition.submit')
      }
      onClick={formikProps.submitForm}
    />,
  ];
};
