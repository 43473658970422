import { Callout, FormField, Input } from '@dev-spendesk/grapes';
import { type CleaveOptions } from 'cleave.js/options';
import { type FormikErrors } from 'formik';
import React from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { type BankDetailsFormValues } from 'modules/bookkeep/accounts-payable/types';
import { CountryAutocomplete } from 'src/core/common/components/CountryAutocomplete/CountryAutocomplete';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { IBANCountries } from 'src/core/config/country';
import { BankFields } from 'src/core/utils/bankInfoFormats';
import { useBankInfoFieldsCombo } from 'src/core/utils/useBankInfo';

import {
  getIsBankInfoRecommended,
  getShouldFillBankInfoCompletely,
} from './helpers';

const IBANCountriesMasks = Object.fromEntries(
  Object.entries(IBANCountries).map(([key, { blocks }]) => [
    key,
    {
      blocks,
    },
  ]),
);

type Props = {
  errors: FormikErrors<BankDetailsFormValues>;
  values: BankDetailsFormValues;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined,
  ) => void;
};

const unmaskValue = (value: string, mask: CleaveOptions) => {
  let delimiter = mask.delimiter || '\\s';
  if (delimiter === '.') {
    delimiter = '\\.';
  }

  return value.replaceAll(new RegExp(delimiter, 'gi'), '');
};

const MASKS: { [key: string]: CleaveOptions } = {
  ACCOUNT_NUMBER: {
    blocks: [18],
    numericOnly: true,
  },
  ACCOUNT_CODE: {
    blocks: [26],
    numericOnly: true,
  },
  BIC_SWIFT: {
    blocks: [11],
  },
  SORT_CODE: {
    blocks: [2, 2, 2],
    delimiter: '-',
    numericOnly: true,
  },
  ROUTING_NUMBER: {
    blocks: [9],
    numericOnly: true,
  },
};

export const AccountPayableSuppliersBankFields = ({
  // Formik props
  errors,
  values,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation();
  const company = useCompany();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    mask: CleaveOptions,
  ) => {
    // If mask has a delimiter, unmask value first
    const value = unmaskValue(event.target.value, mask);
    setFieldValue(fieldName, value);
  };

  const bankInfoFields = useBankInfoFieldsCombo()(values.bankCountry);

  const isBankInfoOptional = !getShouldFillBankInfoCompletely({
    companyCurrency: company.currency,
    bankCountry: values.bankCountry,
    iban: values.iban,
    bic: values.bicSwift,
  });

  return (
    <>
      <FormField
        className="my-s"
        label={t(
          'bookkeep.accountsPayable.panel.bankDetailsSection.bankCountryLabel',
        )}
        alertMessage={errors.bankCountry ? t(errors.bankCountry) : undefined}
      >
        <CountryAutocomplete
          name="bankCountry"
          fit="parent"
          data-testid="iban-country-select"
          countryCode={values.bankCountry}
          placeholder={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.bankCountryPlaceholder',
          )}
          onSelect={(selectedKey: string | undefined) =>
            setFieldValue('bankCountry', selectedKey || '')
          }
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (e.target.value === '') {
              setFieldValue('bankCountry', '');
            }
          }}
        />
      </FormField>
      {getIsBankInfoRecommended({
        companyCurrency: company.currency,
        bankCountry: values.bankCountry,
      }) && (
        <Callout
          className="-mt-xs text-left"
          variant="info"
          title={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.xmlSepaInformation',
          )}
        />
      )}
      {bankInfoFields.includes(BankFields.AccountHolderName) && (
        <FormField
          className="my-s"
          label={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.accountHolderNameLabel',
          )}
          hint={t('bookkeep.accountsPayable.panel.optionalField')}
          alertMessage={
            errors.accountHolderName ? t(errors.accountHolderName) : undefined
          }
        >
          <Input
            name="accountHolderName"
            value={values.accountHolderName}
            placeholder={t(
              'bookkeep.accountsPayable.panel.bankDetailsSection.accountHolderNamePlaceholder',
            )}
            onChange={(e) =>
              setFieldValue('accountHolderName', e.target.value ?? '')
            }
          />
        </FormField>
      )}
      {bankInfoFields.includes(BankFields.AccountNumber) && (
        <FormField
          className="my-s"
          label={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.accountNumberLabel',
          )}
          hint={t('bookkeep.accountsPayable.panel.optionalField')}
          alertMessage={
            errors.accountNumber ? t(errors.accountNumber) : undefined
          }
        >
          <Input
            name="accountNumber"
            value={values.accountNumber}
            placeholder={t(
              'bookkeep.accountsPayable.panel.bankDetailsSection.accountNumberPlaceholder',
            )}
            maskOptions={MASKS.ACCOUNT_NUMBER}
            onChange={(e) =>
              handleChange(e, 'accountNumber', MASKS.ACCOUNT_NUMBER)
            }
          />
        </FormField>
      )}
      {bankInfoFields.includes(BankFields.AccountCode) && (
        <FormField
          className="my-s"
          label={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.accountCodeLabel',
          )}
          hint={t('bookkeep.accountsPayable.panel.optionalField')}
          alertMessage={errors.accountCode ? t(errors.accountCode) : undefined}
        >
          <Input
            name="accountCode"
            value={values.accountCode}
            placeholder={t(
              'bookkeep.accountsPayable.panel.bankDetailsSection.accountCodePlaceholder',
            )}
            maskOptions={MASKS.ACCOUNT_CODE}
            onChange={(e) => handleChange(e, 'accountCode', MASKS.ACCOUNT_CODE)}
          />
        </FormField>
      )}
      {bankInfoFields.includes(BankFields.Iban) && (
        <FormField
          className="my-s"
          label={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.ibanLabel',
          )}
          hint={
            isBankInfoOptional
              ? t('bookkeep.accountsPayable.panel.optionalField')
              : undefined
          }
          alertMessage={errors.iban ? t(errors.iban) : undefined}
        >
          <Input
            name="iban"
            value={values.iban}
            placeholder={t(
              'bookkeep.accountsPayable.panel.bankDetailsSection.ibanPlaceholder',
            )}
            maskOptions={
              IBANCountriesMasks[values.bankCountry]
                ? { ...IBANCountriesMasks[values.bankCountry] }
                : undefined
            }
            onChange={(e) =>
              handleChange(e, 'iban', IBANCountriesMasks[values.bankCountry])
            }
          />
        </FormField>
      )}
      {bankInfoFields.includes(BankFields.BicSwift) && (
        <FormField
          className="my-s"
          label={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.bicSwiftLabel',
          )}
          hint={
            isBankInfoOptional
              ? t('bookkeep.accountsPayable.panel.optionalField')
              : undefined
          }
          alertMessage={errors.bicSwift ? t(errors.bicSwift) : undefined}
        >
          <Input
            name="bicSwift"
            value={values.bicSwift}
            placeholder={t(
              'bookkeep.accountsPayable.panel.bankDetailsSection.bicSwiftPlaceholder',
            )}
            maskOptions={MASKS.BIC_SWIFT}
            onChange={(e) => handleChange(e, 'bicSwift', MASKS.BIC_SWIFT)}
          />
        </FormField>
      )}
      {bankInfoFields.includes(BankFields.SortCode) && (
        <FormField
          className="my-s"
          label={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.sortCodeLabel',
          )}
          hint={t('bookkeep.accountsPayable.panel.optionalField')}
          alertMessage={errors.sortCode ? t(errors.sortCode) : undefined}
        >
          <Input
            name="sortCode"
            value={values.sortCode}
            placeholder={t(
              'bookkeep.accountsPayable.panel.bankDetailsSection.sortCodePlaceholder',
            )}
            maskOptions={MASKS.SORT_CODE}
            onChange={(e) => handleChange(e, 'sortCode', MASKS.SORT_CODE)}
          />
        </FormField>
      )}
      {bankInfoFields.includes(BankFields.RoutingNumber) && (
        <FormField
          className="my-s"
          label={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.routingNumberLabel',
          )}
          hint={t('bookkeep.accountsPayable.panel.optionalField')}
          alertMessage={
            errors.routingNumber ? t(errors.routingNumber) : undefined
          }
        >
          <Input
            name="routingNumber"
            value={values.routingNumber}
            placeholder={t(
              'bookkeep.accountsPayable.panel.bankDetailsSection.routingNumberPlaceholder',
            )}
            maskOptions={MASKS.ROUTING_NUMBER}
            onChange={(e) =>
              handleChange(e, 'routingNumber', MASKS.ROUTING_NUMBER)
            }
          />
        </FormField>
      )}
    </>
  );
};
