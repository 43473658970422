/* eslint-disable @typescript-eslint/no-redeclare */
export const FETCH_CF_LOADING = 'FETCH_CF_LOADING';
export type FETCH_CF_LOADING = typeof FETCH_CF_LOADING;
export const FETCH_CF_SUCCESS = 'FETCH_CF_SUCCESS';
export type FETCH_CF_SUCCESS = typeof FETCH_CF_SUCCESS;
export const FETCH_CF_FAILURE = 'FETCH_CF_FAILURE';
export type FETCH_CF_FAILURE = typeof FETCH_CF_FAILURE;

export const SET_EXPENSE_CATEGORY_VALUES = 'SET_EXPENSE_CATEGORY_VALUES';
export type SET_EXPENSE_CATEGORY_VALUES = typeof SET_EXPENSE_CATEGORY_VALUES;

export const SET_EXPENSE_CATEGORY_CUSTOM_FIELD =
  'SET_EXPENSE_CATEGORY_CUSTOM_FIELD';
export type SET_EXPENSE_CATEGORY_CUSTOM_FIELD =
  typeof SET_EXPENSE_CATEGORY_CUSTOM_FIELD;

export const SET_EXPENSE_CATEGORY_CUSTOM_FIELD_ID =
  'SET_EXPENSE_CATEGORY_CUSTOM_FIELD_ID';
export type SET_EXPENSE_CATEGORY_CUSTOM_FIELD_ID =
  typeof SET_EXPENSE_CATEGORY_CUSTOM_FIELD_ID;

export const REFRESH_REQUEST_WITH_EXPENSE_CATEGORY =
  'REFRESH_REQUEST_WITH_EXPENSE_CATEGORY';
export type REFRESH_REQUEST_WITH_EXPENSE_CATEGORY =
  typeof REFRESH_REQUEST_WITH_EXPENSE_CATEGORY;
