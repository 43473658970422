import { Button, Callout } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './CodeInUseCallout.module.css';

export const CodeInUseCallout = ({
  linkTo,
  accountInfo,
  className,
}: {
  linkTo: string;
  accountInfo: string;
  className?: string;
}) => {
  const { t } = useTranslation();

  // TODO@integrations understand why we need DATEV-specific keys here
  return (
    <Callout
      variant="alert"
      className={classNames(styles.codeInUseCallout, className)}
      title={
        <p className={styles.codeInUseCallout__text}>
          {t('bookkeep.integrations.datev.duplicatedCode.text')} <br />
          {accountInfo}
        </p>
      }
    >
      <Button
        variant="ghost"
        text={t('bookkeep.integrations.datev.duplicatedCode.link')}
        href={linkTo}
        rel="noopener noreferrer"
        target="_blank"
        component="a"
      />
    </Callout>
  );
};
