import classNames from 'classnames';
import {
  type ReactNode,
  useEffect,
  useRef,
  useState,
  type MouseEvent,
} from 'react';
import { createPortal } from 'react-dom';

import { PageViewerContext } from 'src/core/common/components/layout/SiderPageLayout/viewerContext';

import styles from './AccountPayableSuppliers.module.css';
import { AccountPayableSuppliersCreateSupplier } from './pages/AccountPayableSuppliersCreateSupplier';
import { AccountPayableSuppliersList } from './pages/AccountPayableSuppliersList';
import { type IntegrationStatusWithIntegration } from '../integration/status';

type Props = {
  integrationStatus: IntegrationStatusWithIntegration;
};

export const AccountPayableSuppliers = ({ integrationStatus }: Props) => {
  const [isSupplierCreationModalOpen, setIsSupplierCreationModalOpen] =
    useState(false);

  const viewerReference = useRef(null);
  const viewerContentReference = useRef(null);
  const [viewerContent, setViewerContent] = useState<ReactNode>();

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setViewerContent(undefined);
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const onViewerClickOutside = (event: MouseEvent) => {
    if (
      viewerReference?.current === event.target ||
      viewerContentReference?.current === event.target
    ) {
      setViewerContent(undefined);
    }
  };

  return (
    <PageViewerContext.Provider value={setViewerContent}>
      <AccountPayableSuppliersList
        integrationStatus={integrationStatus}
        isFileViewerOpen={
          !isSupplierCreationModalOpen && Boolean(viewerContent)
        }
        setIsSupplierCreationModalOpen={setIsSupplierCreationModalOpen}
      />
      <AccountPayableSuppliersCreateSupplier
        isSupplierCreationModalOpen={isSupplierCreationModalOpen}
        setIsSupplierCreationModalOpen={setIsSupplierCreationModalOpen}
      />
      {viewerContent &&
        createPortal(
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
          <div
            ref={viewerReference}
            className={classNames(styles.viewer, {
              [styles.viewerInCreationPanel]: isSupplierCreationModalOpen,
            })}
            onClick={onViewerClickOutside}
          >
            <div ref={viewerContentReference} className={styles.viewerContent}>
              {viewerContent}
            </div>
          </div>,
          document.body,
        )}
    </PageViewerContext.Provider>
  );
};
