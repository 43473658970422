import { useQueryClient } from 'react-query';

import { useInvalidateCreditAvailableQuery } from '@finance-review/apis/credit-note';
import { updateInvoiceRequestQueryData } from '@finance-review/apis/invoice';
import { type RawInvoiceData } from '@finance-review/apis/invoice/transformers';

export const useUpdateCacheWhenCreditChange = () => {
  const queryClient = useQueryClient();
  const invalidateCreditAvailableQuery = useInvalidateCreditAvailableQuery();

  return (rawInvoiceRequest: RawInvoiceData) => {
    updateInvoiceRequestQueryData(queryClient, rawInvoiceRequest);
    invalidateCreditAvailableQuery({
      supplierId: rawInvoiceRequest.supplier_id,
    });
  };
};
