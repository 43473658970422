import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload =
  | {
      type: 'period';
      period: {
        from: string;
        to: string;
      };
    }
  | {
      type: 'date';
      date: string;
    };

export const useAllocationPeriodMutation = (
  spendingType: 'request' | 'payable',
  spendingId: string,
): MutationState<Payload> => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/spending-allocation/spending-allocation-period/${spendingType}/${spendingId}`,
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
  });
};
