import { type IntegrationStatus } from 'modules/bookkeep/integration/status';

export const checkIfExpenseAccountIsInvalid = (
  integrationStatus: IntegrationStatus,
  accountId?: string | null,
): boolean => {
  return !!(
    integrationStatus.integration !== 'noIntegration' &&
    integrationStatus.integration !== 'switchInProgress' &&
    integrationStatus.settingsValidation.expenseAccounts.some(
      (expenseAccount) =>
        expenseAccount.error === 'invalidAccount' &&
        expenseAccount.id === accountId,
    )
  );
};
