import { useEffect } from 'react';

import {
  type QueryLoadingState,
  type QueryState,
} from 'src/core/api/queryState';
import { type ExpenseAccount } from 'src/core/modules/bookkeep/accounts-payable/types';

import { useGetExpenseAccountLazyQuery } from './useGetExpenseAccountLazyQuery';
import {
  type ExpenseCategoryExpenseAccountRule,
  useGetExpenseCategoryExpenseAccountRulesLazyQuery,
} from './useGetExpenseCategoryExpenseAccountRulesLazyQuery';
import { useIsExpenseCategoryExpenseAccountRuleEnabledQuery } from './useIsExpenseCategoryExpenseAccountRuleEnabled';

type DataForExpenseCategoryExpenseAccountRules = {
  expenseAccounts: ExpenseAccount[];
  expenseCategoryExpenseAccountRules: ExpenseCategoryExpenseAccountRule[];
};

export const useGetDataForExpenseCategoryExpenseAccountRules = (
  {
    shouldGetExpenseAccounts,
    shouldGetExpenseCategoryExpenseAccountRules,
  }: {
    shouldGetExpenseAccounts: boolean;
    shouldGetExpenseCategoryExpenseAccountRules: boolean;
  } = {
    shouldGetExpenseAccounts: true,
    shouldGetExpenseCategoryExpenseAccountRules: true,
  },
): QueryState<DataForExpenseCategoryExpenseAccountRules> => {
  const isExpenseCategoryExpenseAccountRuleEnabledQuery =
    useIsExpenseCategoryExpenseAccountRuleEnabledQuery();

  const [getExpenseAccountsLazyQuery, getExpenseAccountsLazyQueryState] =
    useGetExpenseAccountLazyQuery({ includeArchived: false });
  const [
    getExpenseCategoryExpenseAccountRulesLazyQuery,
    getExpenseCategoryExpenseAccountRulesLazyQueryState,
  ] = useGetExpenseCategoryExpenseAccountRulesLazyQuery();

  const queryStates = [
    getExpenseAccountsLazyQueryState,
    getExpenseCategoryExpenseAccountRulesLazyQueryState,
  ];

  const isIdle = queryStates.some(({ status }) => status === 'idle');

  useEffect(() => {
    if (
      isExpenseCategoryExpenseAccountRuleEnabledQuery.status === 'success' &&
      isExpenseCategoryExpenseAccountRuleEnabledQuery.data.isActive === true &&
      isIdle
    ) {
      if (shouldGetExpenseAccounts) {
        getExpenseAccountsLazyQuery();
      }
      if (shouldGetExpenseCategoryExpenseAccountRules) {
        getExpenseCategoryExpenseAccountRulesLazyQuery();
      }
    }
  }, [isExpenseCategoryExpenseAccountRuleEnabledQuery.status, isIdle]);

  const isLoading =
    queryStates.some(({ status }) => status === 'loading') ||
    isExpenseCategoryExpenseAccountRuleEnabledQuery.status === 'loading';
  if (isLoading) {
    return { status: 'loading' } as QueryLoadingState;
  }

  if (getExpenseAccountsLazyQueryState.status === 'error') {
    return getExpenseAccountsLazyQueryState;
  }

  if (getExpenseCategoryExpenseAccountRulesLazyQueryState.status === 'error') {
    return getExpenseCategoryExpenseAccountRulesLazyQueryState;
  }

  if (
    getExpenseAccountsLazyQueryState.status === 'success' &&
    getExpenseCategoryExpenseAccountRulesLazyQueryState.status === 'success'
  ) {
    return {
      status: 'success',
      data: {
        expenseAccounts: getExpenseAccountsLazyQueryState.data,
        expenseCategoryExpenseAccountRules:
          getExpenseCategoryExpenseAccountRulesLazyQueryState.data,
      },
    };
  }

  return {
    status: 'success',
    data: { expenseAccounts: [], expenseCategoryExpenseAccountRules: [] },
  };
};
