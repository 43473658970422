import { Button, Callout, FormField, Link, Select } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { type Dispatch } from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { openSupportChat } from 'src/core/utils/supportChat';

import { type ModalState } from './ModalState';
import { type AccountingSoftware } from '../../../../../../../integration/status';
import { type CustomField } from '../../../../../hooks/useCustomFieldsQuery';
import styles from '../AnalyticalFieldsSection.module.css';

interface Props {
  customFields: CustomField[];
  integration: AccountingSoftware;
  hasCustomFieldsActive: boolean;
  modalState: ModalState;
  setModalState: Dispatch<ModalState>;
}

export const CustomFieldSelectAndInfo = ({
  integration,
  customFields,
  hasCustomFieldsActive,
  modalState,
  setModalState,
}: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const companyId = useCompanyId();

  if (
    !modalState ||
    modalState.modalKind !== 'map' ||
    modalState.kind === 'notMapped' ||
    modalState.codeType !== 'customField'
  ) {
    return <></>;
  }

  if (!hasCustomFieldsActive) {
    return (
      <Callout
        className={styles.analyticalField}
        title={t(
          'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsWarning.header',
        )}
        variant="warning"
      >
        <div className={styles.analyticalField__content}>
          <p className={styles.analyticalField__text}>
            {t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsWarning.text',
            )}
            &nbsp;
            <span className={styles.uppercase}>
              {integration} {modalState.externalName}.
            </span>
            &nbsp;
            {t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsWarning.callout',
            )}
          </p>
          <Button
            variant="contrasted"
            onClick={() => {
              openSupportChat();
            }}
            text={t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsWarning.button',
            )}
          />
        </div>
      </Callout>
    );
  }

  if (!customFields.length) {
    return (
      <Callout
        className={styles.analyticalField}
        title={t(
          'bookkeep.integrations.settings.analyticalFieldsMappingsTable.noCustomFieldsWarning.header',
        )}
        variant="warning"
      >
        <div className={styles.analyticalField__content}>
          <p className={styles.analyticalField__text}>
            {t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.noCustomFieldsWarning.text',
            )}
            &nbsp;
            <span className={styles.uppercase}>
              {integration} {modalState.externalName}.
            </span>
          </p>
          <Button
            variant="contrasted"
            onClick={() => {
              setTimeout(() => {
                history.push(
                  routeFor(routes.COMPANY_CATEGORIES.path, {
                    company: companyId,
                  }),
                );
              });
            }}
            text={t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.noCustomFieldsWarning.button',
            )}
          />
        </div>
      </Callout>
    );
  }

  const customFieldsOptions = customFields.map((mapping) => ({
    key: mapping.id,
    label: mapping.name,
  }));

  const selectedValue = customFields.find(
    (customField) => customField.id === modalState.internalId,
  );

  return (
    <div className={styles.analyticalField}>
      <FormField
        label={t('bookkeep.integrations.settings.selectCustomField')}
        htmlFor="set-mapping-autocomplete-input"
      >
        <Select
          fit="parent"
          options={customFieldsOptions}
          value={
            selectedValue
              ? { key: selectedValue.id, label: selectedValue.name }
              : undefined
          }
          onSelect={(option) => {
            setModalState({
              ...modalState,
              kind: 'mapped',
              codeType: 'customField',
              internalId: option.key,
            });
          }}
        />
      </FormField>
      {selectedValue && (
        <Callout
          className={classNames(
            styles.analyticalField,
            styles.analyticalField__noIcon,
          )}
          title={
            <div className={styles.analyticalField__callout}>
              <p className={styles.analyticalField__text}>
                <span className={styles.uppercase}>
                  {integration} {modalState.externalName}
                </span>
                &nbsp;
                {t(
                  'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsInfo.text',
                )}
                &nbsp;{selectedValue.name}
              </p>
              <Link
                isExternal
                href={routeFor(routes.COMPANY_CATEGORIES.path, {
                  company: companyId,
                })}
                className={styles.analyticalField__link}
              >
                {t(
                  'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsInfo.link',
                )}
              </Link>
            </div>
          }
          variant="neutral"
        />
      )}
    </div>
  );
};
