import { Navigation } from '@dev-spendesk/grapes';

import { useCompany } from 'modules/app/hooks/useCompany';
import { NavigationLink } from 'src/core/common/components/NavigationLink';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { useFetchCommitmentsCountsQuery } from '../../hooks/useFetchCommitmentsCountsQuery';

export const CommitmentsNav = () => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const fetchCommitmentsCountsQueryStatus = useFetchCommitmentsCountsQuery();

  const leftNavigationItems = [
    <NavigationLink
      key="purchase-orders"
      path={routeFor(routes.PURCHASE_ORDERS.path, {
        company: company.id,
      })}
      text={t('misc.purchaseOrders')}
      count={
        fetchCommitmentsCountsQueryStatus.status === 'success'
          ? fetchCommitmentsCountsQueryStatus.data.purchaseOrdersCount
          : '-'
      }
    />,
    !company.churning_at && company.type !== 'branch_expense_entity' && (
      <NavigationLink
        key="subscriptions"
        path={routeFor(routes.SUBSCRIPTIONS.path, {
          company: company.id,
        })}
        text={t('misc.subscription_plural')}
        count={
          fetchCommitmentsCountsQueryStatus.status === 'success'
            ? fetchCommitmentsCountsQueryStatus.data.subscriptionsCount
            : '-'
        }
      />
    ),
  ];

  return (
    <Navigation
      variant="subNavigation"
      aria-label="Sub navigation"
      leftNavigationItems={leftNavigationItems}
    />
  );
};
