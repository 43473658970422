import queryString from 'query-string';

import { type Company } from 'modules/app/hooks/useCompany';
import * as meTypes from 'modules/app/layout/redux/actionTypes';
import {
  UPDATE_COMPANY,
  FETCH_AVAILABLE_PAYMENT_METHODS_REQUEST,
  FETCH_AVAILABLE_PAYMENT_METHODS_SUCCESS,
  FETCH_AVAILABLE_PAYMENT_METHODS_FAILURE,
} from 'modules/company';
import {
  SAVE_COMPANY_INFO_SUCCESS,
  FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_SUCCESS,
} from 'modules/company/billing-legacy';

import * as globalTypes from '../actionTypes/global';
import * as usersTypes from '../actionTypes/users';
import { type Supervisor } from '../modules/app/hooks/useSupervisor';
import { type User } from '../modules/app/hooks/useUser';

export type CompanyType =
  | 'initial'
  | 'branch_entity'
  | 'branch_currency'
  | 'branch_expense_entity';

export type BillingCompany = {
  id: string;
  name: string;
  type: CompanyType;
};

export type BankingProvider = 'bankable' | 'marqeta' | 'sfs' | null;

// TODO: complete the type definition
export type State = {
  company: Company | null;
  config: {
    ui: {
      targetApp?: 'app-desktop' | 'onboarding' | 'onboarding-hub';
      showRequesterHomepage?: boolean;
      showControllerHomepage?: boolean;
      showKybProcedureValidatedPopup: boolean;
      isOperationalTasksDismissed: boolean;
      showKybStatus: boolean;
      showRequesterOnboardingPopup?: boolean;
      isReimburseViaSepaEnabled?: boolean;
      isExpenseMigratedToTransferScheduling?: boolean;
    };
    features: Record<string, boolean>;
    user: {
      lang: string | undefined;
    };
  } | null;
  user: User | null;
  supervisor: Supervisor;
  impersonator?: { id: string };
  hasImpersonationTargets: boolean;
  referer: string | string[] | undefined; // FIXME: remove string[] type
  bankInfoBehavior: {
    isLoading: boolean;
    error: string | null;
  };
  billingStatus: {
    hasMigrated: boolean;
    selfMigrationAllowed: boolean;
    billingType: 'centralized' | 'decentralized';
    isParent: boolean;
    parent: BillingCompany;
    children: BillingCompany[];
  } | null;
  paymentMethodsBehavior: {
    isLoading: boolean;
    hasError: boolean;
  };
};

const locationReferer = queryString.parse(window.location.search).referer;
const initialState: State = {
  company: null,
  config: {
    features: {},
    ui: {
      showKybProcedureValidatedPopup: false,
      isOperationalTasksDismissed: true,
      showKybStatus: false,
      showRequesterOnboardingPopup: false,
      isReimburseViaSepaEnabled: false,
      isExpenseMigratedToTransferScheduling: false,
    },
    user: {
      lang: undefined,
    },
  },
  user: null,
  billingStatus: null,
  supervisor: undefined,
  impersonator: undefined,
  hasImpersonationTargets: false,
  referer: locationReferer ?? undefined,
  bankInfoBehavior: {
    isLoading: false,
    error: null,
  },
  paymentMethodsBehavior: {
    isLoading: false,
    hasError: false,
  },
};

// TODO: extract company to the company module
export default function reducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: { type: string; payload: any },
): State {
  switch (action.type) {
    case SAVE_COMPANY_INFO_SUCCESS:
    case UPDATE_COMPANY: {
      return {
        ...state,
        company: action.payload.company,
      };
    }

    case meTypes.FETCH_ME_SUCCESS: {
      const {
        supervisor,
        impersonator,
        hasImpersonationTargets,
        user,
        company,
        config,
        billingStatus,
      } = action.payload;

      return {
        ...state,
        company,
        user,
        billingStatus,
        config: {
          ...config,
          ui: {
            ...state.config?.ui,
            ...config?.ui,
          },
        },
        supervisor,
        impersonator,
        hasImpersonationTargets,
      };
    }

    case meTypes.UPDATE_FEATURE_SET: {
      const newFeatureSet = action.payload;

      if (!state.config) {
        return state;
      }

      return {
        ...state,
        config: {
          ...state.config,
          features: {
            ...state.config.features,
            ...newFeatureSet,
          },
        },
      };
    }

    case meTypes.SET_ME_COMPANY_PLASTIC_CARD: {
      const { companyId, plasticCard } = action.payload;
      if (!state.user) {
        return state;
      }
      return {
        ...state,
        user: {
          ...state.user,
          data_by_company: {
            ...state.user.data_by_company,
            [companyId]: {
              ...state.user.data_by_company[companyId],
              plastic_card: plasticCard,
            },
          },
        },
      };
    }

    case globalTypes.MARK_ONBOARDING_POPUP_AS_SEEN: {
      const { key } = action.payload;
      if (!state.config) {
        return state;
      }
      return {
        ...state,
        config: {
          ...state.config,
          ui: {
            ...state.config.ui,
            [key]: false,
          },
        },
      };
    }

    case globalTypes.DISMISS_WELCOME_DASHBOARD: {
      if (!state.config) {
        return state;
      }
      return {
        ...state,
        config: {
          ...state.config,
          ui: {
            ...state.config.ui,
            isOperationalTasksDismissed: true,
          },
        },
      };
    }

    case usersTypes.UPDATE_ME_LOCALLY: {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_SUCCESS: {
      if (!state.company) {
        return state;
      }
      return {
        ...state,
        company: {
          ...state.company,
          isWireTransferFeatureActivated: action.payload.isActivated,
        },
      };
    }

    // Bank infos
    case usersTypes.FETCH_ME_BANK_INFO_LOADING:
    case usersTypes.SAVE_ME_BANK_INFO_LOADING:
      return { ...state, bankInfoBehavior: { isLoading: true, error: null } };
    case usersTypes.FETCH_ME_BANK_INFO_SUCCESS:
    case usersTypes.SAVE_ME_BANK_INFO_SUCCESS:
      return { ...state, bankInfoBehavior: { isLoading: false, error: null } };
    case usersTypes.FETCH_ME_BANK_INFO_FAILURE:
    case usersTypes.SAVE_ME_BANK_INFO_FAILURE:
      return {
        ...state,
        bankInfoBehavior: {
          isLoading: false,
          error: action.payload.code,
        },
      };

    case FETCH_AVAILABLE_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        paymentMethodsBehavior: {
          isLoading: true,
          hasError: false,
        },
      };
    case FETCH_AVAILABLE_PAYMENT_METHODS_SUCCESS: {
      if (!state.company) {
        return state;
      }
      return {
        ...state,
        company: {
          ...state.company,
          paymentMethods: action.payload,
        },
        paymentMethodsBehavior: {
          isLoading: false,
          hasError: false,
        },
      };
    }
    case FETCH_AVAILABLE_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        paymentMethodsBehavior: {
          isLoading: false,
          hasError: true,
        },
      };

    default:
      return state;
  }
}
