import {
  SkeletonAvatar,
  SkeletonCheckbox,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import './TransferListSkeleton.css';

export const TransferListSkeleton = () => (
  <SkeletonTable
    columns={[
      {
        cell: (
          <div className="TransferListSkeleton__table-cell">
            <SkeletonCheckbox />
            <SkeletonAvatar variant="circle" />
            <div className="TransferListSkeleton__table-cell__description">
              <SkeletonText width="40%" size="l" />
              <SkeletonText width="30%" size="s" />
            </div>
            <div>
              <SkeletonText size="s" />
              <SkeletonText size="s" />
            </div>
          </div>
        ),
        header: (
          <>
            <SkeletonCheckbox />
            <SkeletonText
              width="50%"
              size="m"
              className="TransferListSkeleton__table-header__title"
            />
          </>
        ),
      },
    ]}
    withHeader
  />
);
