import { Suspense } from 'react';
// FIXME: Wrap entry component in dev so that we don't have to import react-hot-loader
import { hot } from 'react-hot-loader/root'; // eslint-disable-line import/no-extraneous-dependencies
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { appQueryClient } from 'src/core/api/client';
import i18n, { initializeI18next } from 'src/core/config/i18n';
import history from 'src/core/history';
import { LayoutCompanyIdUrl } from 'src/core/modules/app/layout/LayoutCompanyIdUrl';

import store from '../../redux/store';

initializeI18next(['global', 'card', 'errors', 'countries', 'notifications']);

const App = hot(() => (
  <ErrorBoundary context={{ scope: 'app', team: 'none' }}>
    <Router history={history}>
      <LayoutCompanyIdUrl />
    </Router>
  </ErrorBoundary>
));

// eslint-disable-next-line react/display-name, unicorn/no-anonymous-default-export
export default () => (
  <Provider store={store}>
    <Suspense fallback={null}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={appQueryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </I18nextProvider>
    </Suspense>
  </Provider>
);
