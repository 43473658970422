import { type MonetaryValue } from 'ezmoney';

import { type CreditNoteId } from '@finance-review/models/credit-note';
import { type PayableRequest } from '@finance-review/models/payable';
import type { CustomFieldEligibleType } from 'modules/budgets/models/customFieldAssociation';

import { type PaymentMethod } from './paymentMethod';
import type * as PaymentSchedule from './paymentSchedule';

export type Status = 'in_review' | 'to_pay' | 'partially_paid' | 'paid';

export const customFieldEligibleType: CustomFieldEligibleType = 'request';

export type EntityId = string;
export interface Entity extends PayableRequest.Entity {
  appliedCredit?: MonetaryValue;
  appliedCreditNoteIds?: CreditNoteId[];
  dueDate: Date | undefined;
  emissionDate: Date;
  invoiceNumber: string;
  // internal id of the invoice request table. Needs to be removed - for that the back-end needs to be updated
  // the field validation_action of the credit_note_request uses it
  invoiceDetailsId: string;
  originalAmountInCompanyCurrency: number;
  paymentDate: Date | undefined;
  paymentMethod: PaymentMethod;
  paymentReference?: string;
  paymentSchedule: PaymentSchedule.Entity;
  purchaseOrderId: string | undefined;
  status: Status;
  supplierId: string;
  supplierName: string;
}
