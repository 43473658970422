import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { NotificationType, useNotifications } from '../../app/notifications';
import { type IntegrationId } from '../types';
import { getIntegrationName } from '../utils';

export const useDisconnectKomboIntegrationMutation = (
  integrationId: IntegrationId,
): MutationState<{
  silent: boolean;
}> => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const integrationName = getIntegrationName(t, integrationId);

  return useMutation<{
    silent: boolean;
  }>({
    request: {
      type: 'rest',
      method: 'delete',
      target: 'companyAPI',
      endpoint: '/integrations/kombo/',
    },
    options: {
      onSuccess({ payload, client }): void {
        if (!payload.silent) {
          pushNotif({
            type: NotificationType.Success,
            message: t('integration.notifications.disconnect.success', {
              integrationName,
            }),
          });
          client.invalidateQueries('integrationsStatus');
          client.invalidateQueries([
            'integrations',
            'kombo',
            'is-company-managed',
          ]);
          client.invalidateQueries([
            'integrations',
            'kombo',
            'managed-employees',
          ]);
          client.invalidateQueries(['integrations', 'kombo', 'employee']);
          client.invalidateQueries(['integrations', 'kombo', 'preview']);
        }
      },
      onError() {
        pushNotif({
          type: NotificationType.Danger,
          message: t('integration.notifications.disconnect.error', {
            integrationName,
          }),
        });
      },
    },
  });
};
