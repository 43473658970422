import {
  FormField,
  TextInput,
  type TextInputProps,
} from '@dev-spendesk/grapes';
import React, { type Ref } from 'react';

import type { BasicInputProps, Form } from './types';
import { useErrorMessage } from './utils/useErrorMessage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<TForm extends Form<any>> = BasicInputProps<TForm, string> &
  TextInputProps & {
    innerRef?: Ref<HTMLInputElement>;
    hint?: React.ReactNode;
  };

export function FormTextInput<TForm>({
  name,
  form,
  label,
  hint,
  innerRef,
  ...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Props<TForm extends Form<any> ? TForm : never>) {
  const error = useErrorMessage({
    form,
    key: name,
  });
  return (
    <FormField label={label} alertMessage={error} hint={hint}>
      <TextInput
        ref={innerRef}
        name={name}
        value={form.values[name]}
        onChange={(e) => {
          form.setFieldValue(name, e.target.value);
        }}
        {...props}
      />
    </FormField>
  );
}
