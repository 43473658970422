import { type MonetaryValue } from 'ezmoney';

import { type AppState } from 'src/core/reducers';

import { getRequestsSections } from '../../redux/legacy/selectors';
import { REQUEST_TYPES, filterRequestsByType } from '../../utils/requestUtils';

type Request = object;
export type RequestStats = {
  count: number;
  totalAmount: MonetaryValue | null;
};

export type Sections<T> = {
  invoiceRequests: T;
  creditNoteRequests: T;
  purchaseRequests: T;
  expenseClaimRequests: T;
  purchaseOrderRequests: T;
  mileageAllowanceRequests: T;
  perDiemAllowanceRequests: T;
  myInvoiceRequests: T;
  myCreditNoteRequests: T;
  myPurchaseRequests: T;
  myExpenseClaimRequests: T;
  myPurchaseOrderRequests: T;
  myMileageAllowanceRequests: T;
  myPerDiemAllowanceRequests: T;
  myDraftInvoiceRequests: T;
  myDraftExpenseClaimRequests: T;
  myDraftCreditNoteRequests: T;
  teamInvoiceRequests: T;
  teamCreditNoteRequests: T;
  teamPurchaseRequests: T;
  teamExpenseClaimRequests: T;
  teamPurchaseOrderRequests: T;
  teamMileageAllowanceRequests: T;
  teamPerDiemAllowanceRequests: T;
  otherDraftInvoiceRequests: T;
};

// This function behaves kind as the same as a selector.
// His goal is to do the glue between Redux states and the "requests sections"
// related props.
export const stateToSectionsProps = (
  state: AppState,
): {
  sectionsStats: Sections<RequestStats>;
  requestsSections: Sections<Request[]>;
} => {
  const {
    global: { user },
    requests: { draftRequests },
  } = state;
  const requestsSections = getRequestsSections(state);
  const { requests, stats } = requestsSections;

  if (user === null) {
    throw new Error('Missing user on stateToSectionsProps');
  }

  const enrichRequests = (items: unknown): Request[] => {
    if (!Array.isArray(items)) {
      return [];
    }
    return items;
  };

  const draftInvoiceRequests = filterRequestsByType(
    draftRequests,
    REQUEST_TYPES.Invoice,
  );

  const otherDraftInvoiceRequests = draftInvoiceRequests.filter(
    // @ts-expect-error filterRequestsByType is poorly typed
    (draft) => draft.initiatingEmail && !draft.user,
  );
  const myDraftInvoiceRequests = draftInvoiceRequests.filter(
    // @ts-expect-error filterRequestsByType is poorly typed
    (draft) => !draft.initiatingEmail || draft.user,
  );

  const myDraftExpenseClaimRequests = filterRequestsByType(
    draftRequests,
    REQUEST_TYPES.ExpenseClaim,
  );
  const myDraftCreditNoteRequests = filterRequestsByType(
    draftRequests,
    REQUEST_TYPES.CreditNote,
  );

  return {
    sectionsStats: {
      // all requests (mine + team)
      invoiceRequests: stats.invoices,
      creditNoteRequests: stats.credit_notes,
      purchaseRequests: stats.purchases,
      expenseClaimRequests: stats.expense_claims,
      purchaseOrderRequests: stats.purchase_orders,
      mileageAllowanceRequests: stats.mileage_allowances,
      perDiemAllowanceRequests: stats.per_diem_allowances,
      // my requests
      myInvoiceRequests: stats.mine_invoices,
      myCreditNoteRequests: stats.mine_credit_notes,
      myPurchaseRequests: stats.mine_purchases,
      myExpenseClaimRequests: stats.mine_expenses,
      myPurchaseOrderRequests: stats.mine_purchase_orders,
      myMileageAllowanceRequests: stats.mine_mileage_allowances,
      myPerDiemAllowanceRequests: stats.mine_per_diem_allowances,
      // my draft requests
      myDraftInvoiceRequests: {
        count: myDraftInvoiceRequests.length,
        totalAmount: null, // not handled for now
      },
      myDraftExpenseClaimRequests: {
        count: myDraftExpenseClaimRequests.length,
        totalAmount: null, // not handled for now
      },
      myDraftCreditNoteRequests: {
        count: myDraftCreditNoteRequests.length,
        totalAmount: null, // not handled for now
      },
      // team requests
      teamInvoiceRequests: stats.team_invoices,
      teamCreditNoteRequests: stats.team_credit_notes,
      teamPurchaseRequests: stats.team_purchases,
      teamExpenseClaimRequests: stats.team_expenses,
      teamPurchaseOrderRequests: stats.team_purchase_orders,
      teamMileageAllowanceRequests: stats.team_mileage_allowances,
      teamPerDiemAllowanceRequests: stats.team_per_diem_allowances,
      otherDraftInvoiceRequests: {
        count: otherDraftInvoiceRequests.length,
        totalAmount: null, // not handled for now
      },
    },
    requestsSections: {
      // all requests (mine + team)
      invoiceRequests: enrichRequests(requests.invoices),
      creditNoteRequests: enrichRequests(requests.credit_notes),
      purchaseRequests: enrichRequests(requests.purchases),
      expenseClaimRequests: enrichRequests(requests.expense_claims),
      purchaseOrderRequests: enrichRequests(requests.purchase_orders),
      mileageAllowanceRequests: enrichRequests(requests.mileage_allowances),
      perDiemAllowanceRequests: enrichRequests(requests.per_diem_allowances),
      // my requests
      myInvoiceRequests: enrichRequests(requests.mine_invoices),
      myCreditNoteRequests: enrichRequests(requests.mine_credit_notes),
      myPurchaseRequests: enrichRequests(requests.mine_purchases),
      myExpenseClaimRequests: enrichRequests(requests.mine_expenses),
      myPurchaseOrderRequests: enrichRequests(requests.mine_purchase_orders),
      myMileageAllowanceRequests: enrichRequests(
        requests.mine_mileage_allowances,
      ),
      myPerDiemAllowanceRequests: enrichRequests(
        requests.mine_per_diem_allowances,
      ),
      // my draft requests
      myDraftInvoiceRequests,
      myDraftExpenseClaimRequests,
      myDraftCreditNoteRequests,
      // team requests
      teamInvoiceRequests: enrichRequests(requests.team_invoices),
      teamCreditNoteRequests: enrichRequests(requests.team_credit_notes),
      teamPurchaseRequests: enrichRequests(requests.team_purchases),
      teamExpenseClaimRequests: enrichRequests(requests.team_expenses),
      teamPurchaseOrderRequests: enrichRequests(requests.team_purchase_orders),
      teamMileageAllowanceRequests: enrichRequests(
        requests.team_mileage_allowances,
      ),
      teamPerDiemAllowanceRequests: enrichRequests(
        requests.team_per_diem_allowances,
      ),
      // other requests
      otherDraftInvoiceRequests,
    },
  };
};
