import { useCallback } from 'react';

import { useGoTo } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';

export const useGoToMembersPage = (userId?: string) => {
  const goTo = useGoTo();
  const companyId = useCompanyId();

  return useCallback(
    () => goTo('COMPANY_MEMBERS', { company: companyId, id: userId }),
    [companyId],
  );
};
