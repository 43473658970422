import {
  Button,
  DATE_FORMAT,
  EmptyState,
  Skeleton,
  SkeletonButton,
  SkeletonText,
} from '@dev-spendesk/grapes';
import { differenceInDays } from 'date-fns';
import { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { SfsUkPhoneNumberModal } from 'modules/cards/components/SfsUkPhoneNumberModal/SfsUkPhoneNumberModal';
import { getUserFactorsAndCheckIfPhoneActive } from 'modules/physical-cards/apis/getUserFactorsAndCheckIfPhoneActive';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { MemberScreeningStepModal } from 'src/core/modules/members/components/MemberScreeningStepModal/MemberScreeningStepModal';
import {
  type UseScreeningInformationStatusQueryReponse,
  useScreeningInformationStatusQuery,
} from 'src/core/modules/members/hooks/useScreeningInformationStatusQuery';
import { openSupportChat } from 'src/core/utils/supportChat';

import blueEnveloppePNG from './blue-enveloppe.png';
import { type ApiCard } from '../../../../card';

type Props = {
  card: ApiCard;
  goToNextStep: () => void;
  isRecard: boolean;
  screeningInformationStatus: UseScreeningInformationStatusQueryReponse['status'];
};

const Step1Body = ({
  card,
  goToNextStep,
  isRecard,
  screeningInformationStatus,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');

  const [isOpen, setIsOpen] = useState(false);
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getEstimatedReceptionText = (estimatedReceptionDate: Date | null) => {
    if (!estimatedReceptionDate) {
      return t('cards.activation.step1.cardOnItsWay');
    }
    const receptionDate = new Date(estimatedReceptionDate);
    const remainingDays = differenceInDays(receptionDate, new Date());
    if (remainingDays >= 0) {
      return t('cards.activation.step1.shouldReceiveBy', {
        date: localeFormat(receptionDate, DATE_FORMAT.SHORT),
      });
    }
    return t('cards.activation.step1.cardShouldBeReceived');
  };

  if (!card.company || card.status !== 'PRE') {
    return (
      <EmptyState
        actions={
          <Button
            text={t('cards.activation.error.contactUs')}
            variant="contrasted"
            onClick={() => openSupportChat()}
          />
        }
        iconName="warning"
        iconVariant="alert"
        subtitle={t('cards.activation.error.subtitle')}
        title={t('cards.activation.error.title')}
      />
    );
  }

  const cardOrder = card.cardOrder || {
    ordered_by: t('cards.activation.step1.yourManager'),
    estimated_received_at: null,
  };

  const estimatedText = getEstimatedReceptionText(
    cardOrder.estimated_received_at,
  );

  return (
    <>
      <EmptyState
        illustration={<img alt="" src={blueEnveloppePNG} />}
        title={
          isRecard
            ? t('recard.activation.step1.title')
            : t('cards.activation.step1.cardOnTheWayTitle')
        }
        subtitle={
          isRecard ? (
            <div className="flex flex-col gap-xs text-neutral-dark body-l">
              <span>{t('recard.activation.step1.subtitle')}</span>
              <span>{t('recard.activation.step1.notice.pendingPayments')}</span>
              <span>{t('recard.activation.step1.notice.updateCard')}</span>
              <p className="mb-xs text-neutral-dark body-s">
                {t('recard.activation.step1.notice.footnote')}
              </p>
            </div>
          ) : (
            <div className="explanation">
              <p>
                {t('cards.activation.step1.description1', {
                  admin: cardOrder.ordered_by,
                  company: card.company.name,
                })}
              </p>
              <p>{estimatedText}</p>
              <br />
              <p>{t('cards.activation.step1.description2')}</p>
            </div>
          )
        }
        actions={[
          <Button
            key="activate"
            text={
              isRecard
                ? t('recard.activation.step1.continue')
                : t('cards.activation.step1.activateCta')
            }
            onClick={async () => {
              if (card.banking_provider === 'sfs') {
                if (screeningInformationStatus === 'pending') {
                  setIsOpen(true);
                  return;
                }

                if (card.currency === 'GBP') {
                  setIsLoading(true);
                  const isMfaActive =
                    await getUserFactorsAndCheckIfPhoneActive();
                  if (!isMfaActive) {
                    setIsPhoneNumberModalOpen(true);
                    return;
                  }
                }
              } else if (
                card.banking_provider === 'marqeta' &&
                card.currency === 'USD'
              ) {
                setIsLoading(true);
                const isMfaActive = await getUserFactorsAndCheckIfPhoneActive();
                if (!isMfaActive) {
                  setIsPhoneNumberModalOpen(true);
                  return;
                }
              }
              goToNextStep();
            }}
            isLoading={isLoading}
          />,
        ]}
      />
      <MemberScreeningStepModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onContinue={() => {
          setIsOpen(false);
          goToNextStep();
        }}
      />
      {isPhoneNumberModalOpen && (
        <SfsUkPhoneNumberModal
          onClose={() => setIsPhoneNumberModalOpen(false)}
          isOpen={isPhoneNumberModalOpen}
        />
      )}
    </>
  );
};

export const Step1 = (props: Omit<Props, 'screeningInformationStatus'>) => {
  const user = useUser();

  const screeningInformationStatusQueryState =
    useScreeningInformationStatusQuery({
      userId: user.id,
    });

  return (
    <QuerySuspense
      queryState={screeningInformationStatusQueryState}
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="Callout"
          logContext={{
            team: 'capture',
          }}
        />
      )}
      loading={
        <div className="flex flex-col items-center justify-center gap-s">
          <Skeleton height="174px" width="210px" />
          <SkeletonText size="l" width="300px" className="mt-l" />
          <Skeleton height="40px" width="400px" />
          <SkeletonText width="500px" />
          <SkeletonButton className="mt-l" />
        </div>
      }
    >
      {({ status }) => (
        <Step1Body {...props} screeningInformationStatus={status} />
      )}
    </QuerySuspense>
  );
};
