import { gql } from 'graphql-tag';

export const CREATE_CUSTOM_FIELD_VALUE = gql`
  mutation CreateCustomFieldValue($customFieldId: ID!, $value: String!) {
    createCustomFieldValue(customFieldId: $customFieldId, value: $value) {
      outcome
      ... on CreateCustomFieldValueSuccess {
        customFieldValue {
          id
          value
          archiveDate
        }
      }
    }
  }
`;
