import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';

import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { type EmployeeAccount } from '../../accounting';

const GET_PAGINATED_EMPLOYEE_ACCOUNTS = gql`
  query GetPaginatedEmployeeAccounts(
    $companyId: ID!
    $after: ID
    $isMapped: Boolean
  ) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        employeeAccounts(
          filter: { isDefault: false, isMapped: $isMapped }
          first: 30
          after: $after
        ) {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
              members {
                totalCount
                pageInfo {
                  hasNextPage
                }
                edges {
                  cursor
                  node {
                    id
                    ... on User {
                      givenName
                      familyName
                      avatar
                    }
                    ... on SuspendedUser {
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type RawData = {
  company: {
    chartOfAccounts: {
      employeeAccounts: {
        pageInfo: {
          hasNextPage: boolean;
          cursor?: string;
        };
        edges: {
          cursor: string;
          node: {
            generalAccountCode: string;
            auxiliaryAccountCode: string | undefined;
            isDefault: boolean;
            id: string;
            members: {
              edges: {
                node: {
                  avatar?: string;
                  familyName: string;
                  givenName: string;
                  id: string;
                };
              }[];
            };
          };
        }[];
      };
    };
  };
};

export const usePaginatedEmployeeAccountsQuery = ({
  isMapped,
}: {
  isMapped?: boolean;
}): InfiniteQueryState<EmployeeAccount[]> => {
  const { company: companyId } = useParams();

  return useInfiniteQuery<EmployeeAccount, RawData>({
    key: ['accountsPayable', 'getPaginatedEmployeeAccounts', companyId],
    getRequest: (cursor) => ({
      type: 'graphQL',
      target: 'v2',
      query: GET_PAGINATED_EMPLOYEE_ACCOUNTS,
      variables: {
        after: cursor,
        isMapped,
      },
    }),
    getNextPageParam: (data) => {
      const { edges, pageInfo } = data.company.chartOfAccounts.employeeAccounts;
      const lastEdge = edges.at(-1);

      if (pageInfo.hasNextPage && lastEdge) {
        return lastEdge.cursor;
      }
      return undefined;
    },
    reshapeData(paginatedEmployeeAccounts) {
      return paginatedEmployeeAccounts.company.chartOfAccounts.employeeAccounts.edges.map(
        ({ node }) => {
          return {
            id: node.id,
            generalAccountCode: node.generalAccountCode,
            auxiliaryAccountCode: node.auxiliaryAccountCode,
            user: node.members.edges[0]
              ? {
                  id: node.members.edges[0].node.id,
                  firstName: node.members.edges[0].node.givenName,
                  lastName: node.members.edges[0].node.familyName,
                  avatar: node.members.edges[0].node.avatar || null,
                }
              : undefined,
          };
        },
      );
    },
  });
};
