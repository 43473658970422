import { FormField, Label, TextInput } from '@dev-spendesk/grapes';
import { type FormikErrors } from 'formik';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { type ApprovalPolicyFormValues } from '../../formValues';
import { ApprovalPolicyParamsContent } from '../ApprovalPolicyParamsContent';

import './ApprovalPolicyDetailsContent.css';

type Props = {
  isPolicyDefault?: boolean;
  values: ApprovalPolicyFormValues;
  errors: FormikErrors<ApprovalPolicyFormValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue(field: string, value: any): void;
};

export const ApprovalPolicyDetailsContent = ({
  isPolicyDefault,
  values,
  errors,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="ApprovalPolicyDetailsContent">
      <p className="ApprovalPolicyDetailsContent__title">
        {t('approvalPolicy.details.title')}
      </p>
      <div className="ApprovalPolicyDetailsContent__form">
        <div className="ApprovalPolicyDetailsContent__field">
          <FormField
            className="ApprovalPolicyDetailsContent__field__form"
            label={t('approvalPolicy.details.nameLabel')}
            alertMessage={errors.name}
          >
            <TextInput
              fit="parent"
              isDisabled={isPolicyDefault}
              value={values.name ?? ''}
              placeholder={t('approvalPolicy.details.namePlaceholder')}
              onChange={(event) => {
                setFieldValue('name', event.target.value);
              }}
            />
          </FormField>
        </div>

        <div className="ApprovalPolicyDetailsContent__field">
          <Label
            className="ApprovalPolicyDetailsContent__field__label"
            label={t('approvalPolicy.details.radioLabel')}
          />

          <ApprovalPolicyParamsContent
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
    </div>
  );
};
