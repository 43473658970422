import isEqual from 'lodash/isEqual';

import { useUpdateRolesMutation } from '../../../hooks/useUpdateRolesMutation';

export const useEditRolesMutation = (memberId: string) => {
  const [updateRole] = useUpdateRolesMutation();

  return [
    (oldRoleIds: string[], newRoleIds: string[]) => {
      if (isEqual(oldRoleIds, newRoleIds)) {
        return;
      }

      // Add missing roles
      const missingPromises = newRoleIds.map((roleId) => {
        if (oldRoleIds.includes(roleId)) {
          return;
        }
        return updateRole({
          user_id: memberId,
          id: roleId,
          enabled: true,
          endpointParams: {
            roleId,
          },
        });
      });

      // Remove old roles
      const oldPromises = oldRoleIds.map((roleId) => {
        if (newRoleIds.includes(roleId)) {
          return;
        }
        return updateRole({
          user_id: memberId,
          id: roleId,
          enabled: false,
          endpointParams: {
            roleId,
          },
        });
      });
      return Promise.all([...missingPromises, ...oldPromises]);
    },
  ];
};
