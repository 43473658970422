import { type AppState } from 'src/core/reducers';

import { type BookkeepingExport } from '../types';
import { isExportProcessing } from '../utils';

export const getIsExportPending = (state: AppState): boolean =>
  state.bookkeepingExport.export.isPending;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getExportError = (state: AppState): any =>
  getIsExportPending(state) ? null : state.bookkeepingExport.export.error;

export const getHasExportSucceeded = (state: AppState) =>
  !getIsExportPending(state) && !!state.bookkeepingExport.export.data;

export const getProcessingExports = (state: AppState) => {
  return state.bookkeepingExport.unreadExports.filter(isExportProcessing);
};

export const getProcessedExports = (state: AppState) => {
  return state.bookkeepingExport.unreadExports.filter(
    (bookkeepingExport: BookkeepingExport) =>
      !isExportProcessing(bookkeepingExport),
  );
};
