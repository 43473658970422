import { Tooltip } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

type Props = {
  text: string | React.ReactNode;
  tooltipMaxWidth?: number;
  className?: string;
  placement?: 'left' | 'right' | 'top' | 'bottom';
};

export const EllipsisTooltip = ({
  text,
  className,
  tooltipMaxWidth,
  placement,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasTooltip, setHasTooltip] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setHasTooltip(ref.current.scrollWidth > ref.current.offsetWidth);
    }
  }, [text]);

  if (hasTooltip) {
    return (
      <Tooltip
        content={text}
        maxWidth={tooltipMaxWidth}
        placement={placement}
        triggerAsChild
      >
        <div ref={ref} className={classNames('truncate', className)}>
          {text}
        </div>
      </Tooltip>
    );
  }

  return (
    <div ref={ref} className={classNames('truncate', className)}>
      {text}
    </div>
  );
};
