import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

export const useHasReportingManagerAsApprover = () => {
  const reportingManagerCountQuery = useGetReportingManagerCount();
  const reportingManagerCount = unwrapQuery(reportingManagerCountQuery);

  return (reportingManagerCount ?? 0) > 0;
};

const useGetReportingManagerCount = (): QueryState<number> => {
  return useQuery({
    key: ['members', 'reporting-manager-count'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `approval-schemes/count-approver-type?approverType=reportingManager`,
    },
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    reshapeData: (data) => data,
  });
};
