import { useFeature } from 'common/hooks/useFeature';
import FEATURES from "src/core/constants/features";

export type CustomFieldPayload = {
  name?: string;
  isRequired?: boolean;
  isSplittable?: boolean;
  isScopable?: boolean;
  values?: {
    id?: string;
    value: string;
    isDefault: boolean;
    costCenterIds: [];
  }[];
  type?: 'list' | 'boolean';
  availableForEntities?: ('request' | 'expense' | 'payment' | 'subscription')[];
  valuesCanBeCreatedBy?: ('requester' | 'controller' | 'admin')[];
  valuesCanBeSeenBy?: ('requester' | 'controller' | 'admin')[];
};

export const useDefaultCustomFieldPayload = (): CustomFieldPayload => {
  const hasTeamsFeature = useFeature(FEATURES.TEAMS);
  return {
    name: '',
    isRequired: true,
    isScopable: hasTeamsFeature,
    type: 'list',
    valuesCanBeCreatedBy: ['requester', 'controller', 'admin'],
    valuesCanBeSeenBy: ['requester', 'controller', 'admin'],
    availableForEntities: ['request', 'expense', 'payment', 'subscription'],
  };
};
