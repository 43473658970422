import { FormField, Input } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { IBANCountries } from 'src/core/config/country';

import { type BankInfosErrors, type SupplierBankInfos } from '../../../models';
import { FormFieldHint } from '../FormFieldHint';
import { mask, unmask } from '../helper';

const IBANCountriesMasks = Object.fromEntries(
  Object.entries(IBANCountries).map(([key, { blocks }]) => [
    key,
    {
      blocks,
    },
  ]),
);

type Props = {
  className?: string;
  isOptional?: boolean;
  iban: SupplierBankInfos['iban'];
  ibanSuggestion?: SupplierBankInfos['iban'];
  bankCountry: SupplierBankInfos['bankCountry'];
  error?: BankInfosErrors['iban'];
  onChange(iban: SupplierBankInfos['iban']): void;
};

export const IbanFormField = ({
  className,
  isOptional,
  iban,
  ibanSuggestion,
  bankCountry,
  error,
  onChange,
}: Props) => {
  const { t } = useTranslation('global');
  const isValueFromSuggestion =
    Boolean(ibanSuggestion) && iban === ibanSuggestion;

  const getMessageFromError = (fieldError: BankInfosErrors['iban']): string => {
    switch (fieldError) {
      case 'invalid': {
        return t('submitMyInvoice.fields.iban.error');
      }
      default:
        return t('misc.requiredField');
    }
  };

  const IbanMask = bankCountry ? IBANCountriesMasks[bankCountry] : undefined;

  return (
    <FormField
      className={className}
      label={t('submitMyInvoice.fields.iban.label')}
      alertMessage={error ? getMessageFromError(error) : undefined}
      hint={
        <FormFieldHint
          optionalField={isOptional}
          showOCR={isValueFromSuggestion}
        />
      }
    >
      <Input
        name="iban"
        value={IbanMask ? mask(iban ?? '', IbanMask) : (iban ?? '')}
        placeholder={t('submitMyInvoice.fields.iban.placeholder')}
        onChange={(event) =>
          onChange(
            IbanMask
              ? unmask(event.target.value, IbanMask)
              : event.target.value,
          )
        }
      />
    </FormField>
  );
};
