import { SwitchField } from '@dev-spendesk/grapes';
import React from 'react';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from "src/core/common/hooks/useTranslation";

import {
  AUTO_REMINDERS_DEFAULT_VALUE,
  type Notification,
} from '../../../../models';
import { useUpdateNotificationMutation } from '../../hooks/useUpdateNotificationMutation';
import { NotificationReceiptRemindersContainer } from '../NotificationReceiptReminders';

type Props = {
  notification: Notification;
};

export const NotificationSetting = ({ notification }: Props) => {
  const { t } = useTranslation();
  const { pushNotif } = useNotifications();

  const [updateNotification] = useUpdateNotificationMutation();

  return (
    <>
      <SwitchField
        id={notification.notifCode}
        fit="parent"
        isChecked={notification.checked}
        label={t(
          `generalSettings.notifications.${notification.notifCode}.title`,
        )}
        helpText={t(
          `generalSettings.notifications.${notification.notifCode}.company`,
        )}
        onChange={async (e) => {
          try {
            await updateNotification({
              notif_code: notification.notifCode,
              checked: e.target.checked,
              value:
                notification.notifCode === 'autoReminders'
                  ? AUTO_REMINDERS_DEFAULT_VALUE
                  : null,
            });
          } catch {
            pushNotif({
              type: NotificationType.Danger,
              message: t(
                'generalSettings.notifications.editFailureNotification',
              ),
            });
            return;
          }

          pushNotif({
            type: NotificationType.Success,
            message: t('generalSettings.notifications.editSuccessNotification'),
          });
        }}
      />
      {notification.notifCode === 'autoReminders' && notification.checked && (
        <NotificationReceiptRemindersContainer notification={notification} />
      )}
    </>
  );
};
