import { useMutation } from 'src/core/api/hooks/useMutation';

import { type CustomFieldPayload } from '../containers/customField';

export const useUpdateCustomFieldMutation = (customFieldId: string) => {
  return useMutation<CustomFieldPayload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/custom-fields-package/custom-fields/${customFieldId}`,
      method: 'patch',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['customFields']);
        client.invalidateQueries(['useCustomFieldValuesQuery', customFieldId]);
        client.invalidateQueries(['useFieldValuesQuery']);
      },
    },
  });
};
