import { createAction } from '@reduxjs/toolkit';
import isObject from 'lodash/isObject';
import noop from 'lodash/noop';

import { companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import * as types from './actionTypes';

type GetState = () => AppState;

export const saveCompanyInfoSuccess = createAction(
  types.SAVE_COMPANY_INFO_SUCCESS,
);

export const fetchBankInfoLoading = createAction(types.FETCH_BANK_INFO_LOADING);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchBankInfoFailure = createAction<any>(
  types.FETCH_BANK_INFO_FAILURE,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchBankInfoSuccess = createAction<any>(
  types.FETCH_BANK_INFO_SUCCESS,
);
export const fetchBankInfo =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => async (dispatch: any, getState: GetState) => {
    dispatch(fetchBankInfoLoading());
    try {
      const companyId = getCompanyId(getState());
      const { status, data: bankInfo } = await companyAPI.get('/bank-info', {
        companyId,
      });
      if (status !== 200 || !isObject(bankInfo)) {
        return dispatch(fetchBankInfoFailure({}));
      }
      dispatch(fetchBankInfoSuccess(bankInfo));
    } catch {
      dispatch(fetchBankInfoFailure({}));
    }
  };

export const saveBankInfo =
  (data = {}, callback = noop) =>
  async (_: unknown, getState: GetState) => {
    if (!data) {
      return;
    }
    try {
      const companyId = getCompanyId(getState());
      const res = await companyAPI.put('/bank-info', data, {
        companyId,
      });
      if (!isObject(res.data)) {
        callback({});
        return;
      }
      callback();
    } catch (error) {
      callback(error);
    }
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateWireTransferActivationStatus = createAction<any>(
  types.UPDATE_WIRE_TRANSFER_ACTIVATION_STATUS,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchWireTransferActivationStatusSuccess = createAction<any>(
  types.FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_SUCCESS,
);
export const fetchWireTransferActivationStatusLoading = createAction(
  types.FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_LOADING,
);
export const fetchWireTransferActivationStatusError = createAction(
  types.FETCH_WIRE_TRANSFER_ACTIVATION_STATUS_ERROR,
);
export const fetchWireTransferActivationStatus =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => async (dispatch: any, getState: GetState) => {
    fetchWireTransferActivationStatusLoading();
    const state = getState();
    const companyId = getCompanyId(state);

    try {
      const { data } = await companyAPI.get('/features/wiretransfer', {
        companyId,
      });
      dispatch(fetchWireTransferActivationStatusSuccess(data));
    } catch {
      fetchWireTransferActivationStatusError();
    }
  };
