import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { type WireTransferPayableEntity } from '../../../../models';

type ModalWireTransferActivatedProps = {
  isOpen: boolean;
  wireTransferPayableEntity: WireTransferPayableEntity;
  onClose: () => void;
};

export const ModalWireTransferActivated = ({
  isOpen,
  wireTransferPayableEntity,
  onClose,
}: ModalWireTransferActivatedProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      actions={[
        <Button
          key="continue"
          text={t('wiretransfer.modal.activated.button')}
          variant="primary"
          onClick={onClose}
        />,
      ]}
      iconName="checked"
      iconVariant="primary"
      title={t('wiretransfer.modal.activated.title')}
      subtitle={
        wireTransferPayableEntity === 'expenses'
          ? t('wiretransfer.modal.activated.desc')
          : t('wiretransfer.modal.activated.invoices.desc')
      }
      isOpen={isOpen}
    />
  );
};
