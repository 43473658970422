import { useContext } from 'react';

import { useUpdateGetPayableQueryCache } from 'modules/bookkeep/apis/prepare-payable/useGetPayableQuery';
import { useInvalidateBookkeepingNavbarCounters } from 'modules/bookkeep/hooks';
import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { MARK_PAYABLE_AS_PREPARED } from './query';
import { useInvalidatePayableGroupsQueryCache } from '../../../prepare-payables/components/PreparePayablesBuckets/hooks/usePayableGroupsQuery/usePayableGroupsQuery';
import { useUpdatePayablesQueryCache } from '../../../prepare-payables/components/PreparePayablesBuckets/hooks/usePayablesQuery/usePayablesQuery';
import {
  MissingFieldsError,
  HasBeenModifiedError,
  AlreadyPreparedError,
  NotFoundError,
  ItemLineWithZeroVatAndNetAmountError,
  InvalidItemLinesError,
  NothingToUpdateError,
  NonUniqueItemLinesError,
  UnmatchingVatRateError,
  UpdateInProgressError,
  PayableNotReconciledCorrectlyError,
  IncorrectVatError,
  InvalidExpenseAccountsError,
  InvalidTaxAccountsError,
  MissingExpenseAccountError,
  InvalidAccountPayableError,
  MissingAccountPayableError,
  MissingRequiredCustomFieldError,
  ExpenseAccountDeletedError,
  TaxAccountDeletedError,
  MissingAccountingDate,
  MissingInvoiceNumber,
  MissingSupplierCountry,
  MissingSupplierVatNumber,
} from '../../../prepare-payables/components/PreparePayablesInbox/utils/errors';
import { PreparePayablesFiltersContext } from '../../../prepare-payables/contexts';

export type MarkPayableAsPreparedPayload = {
  payableId: string;
  payableVersion: number;
};

export type MarkPayableAsPreparedResponse = {
  data: RawResponse;
  error: Error | null;
};

type RawResponse = {
  markPayableAsPrepared:
    | {
        __typename: 'MarkPayablesAsPreparedResultNotPrepared';
        reason: string;
      }
    | {
        __typename: 'MarkPayablesAsPreparedResultPrepared';
      };
};

export type MarkPayableAsPreparedRawResponse = RawResponse;

const useMarkPayableAsPrepared = (
  payableId: string,
): MutationState<
  MarkPayableAsPreparedPayload,
  MarkPayableAsPreparedResponse
> => {
  const companyId = useCompanyId();
  const updateGetPayableQueryCache = useUpdateGetPayableQueryCache();
  const updatePayablesQueryCache = useUpdatePayablesQueryCache();
  const invalidatePayableGroupsQueryCache =
    useInvalidatePayableGroupsQueryCache();
  const invalidateBookkeepingNavbarCounters =
    useInvalidateBookkeepingNavbarCounters();

  const filtersContext = useContext(PreparePayablesFiltersContext);

  return useMutation<
    MarkPayableAsPreparedPayload,
    MarkPayableAsPreparedResponse,
    MarkPayableAsPreparedRawResponse
  >({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: MARK_PAYABLE_AS_PREPARED,
    },
    reshapeData: (data) => {
      let error = null;

      if (
        data.markPayableAsPrepared.__typename ===
        'MarkPayablesAsPreparedResultNotPrepared'
      ) {
        switch (data.markPayableAsPrepared.reason) {
          case 'itemLineWithZeroVatAndNetAmount':
            error = new ItemLineWithZeroVatAndNetAmountError();
            break;
          case 'invalidItemLines':
          case 'itemLineHasInvalidCurrency':
          case 'itemLineAmountsNotEqualPayableAmount':
          case 'grossAmountNotEqualNetAndVAT':
          case 'vatAdjustementInconsistent':
            error = new InvalidItemLinesError();
            break;
          case 'NothingToUpdate':
            error = new NothingToUpdateError();
            break;
          case 'nonUniqueItemLines':
            error = new NonUniqueItemLinesError();
            break;
          case 'unmatchingVatRate':
            error = new UnmatchingVatRateError();
            break;
          case 'payableNotReconciledCorrectly':
            error = new PayableNotReconciledCorrectlyError();
            break;
          case 'incorrectVat':
            error = new IncorrectVatError();
            break;
          case 'invalidExpenseAccounts':
            error = new InvalidExpenseAccountsError();
            break;
          case 'invalidTaxAccounts':
            error = new InvalidTaxAccountsError();
            break;
          case 'missingExpenseAccount':
            error = new MissingExpenseAccountError();
            break;
          case 'missingAccountingDate':
            error = new MissingAccountingDate();
            break;
          case 'missingInvoiceNumber':
            error = new MissingInvoiceNumber();
            break;
          case 'missingSupplierCountry':
            error = new MissingSupplierCountry();
            break;
          case 'missingSupplierVatNumber':
            error = new MissingSupplierVatNumber();
            break;
          case 'expenseAccountDeleted':
            error = new ExpenseAccountDeletedError();
            break;
          case 'taxAccountDeleted':
            error = new TaxAccountDeletedError();
            break;
          case 'invalidAccountPayable':
            error = new InvalidAccountPayableError();
            break;
          case 'missingAccountPayable':
            error = new MissingAccountPayableError();
            break;
          case 'missingRequiredCustomField':
            error = new MissingRequiredCustomFieldError();
            break;
          case 'wrongVersion':
            error = new HasBeenModifiedError();
            break;
          case 'updateInProgress':
            error = new UpdateInProgressError();
            break;
          case 'invalidState':
            error = new AlreadyPreparedError();
            break;
          case 'notFound':
            error = new NotFoundError();
            break;
          case 'missingTaxAccount':
            error = new MissingFieldsError(['taxAccount']);
            break;
          default:
            error = new Error(
              `Failed to mark payable as prepared (reason: ${data.markPayableAsPrepared.reason}`,
            );
            break;
        }
      }

      return {
        data,
        error,
      };
    },
    options: {
      onSuccess: ({ payload, data: { data } }) => {
        if (
          data.markPayableAsPrepared.__typename ===
          'MarkPayablesAsPreparedResultPrepared'
        ) {
          const update = {
            version: payload.payableVersion + 1,
            state: 'prepared' as const,
          };

          updateGetPayableQueryCache(companyId, payableId, update);
          updatePayablesQueryCache(companyId, payableId, update);

          if (filtersContext.state.group !== undefined) {
            invalidatePayableGroupsQueryCache(companyId);
          }

          invalidateBookkeepingNavbarCounters({
            action: 'DECREMENT_PAYABLES_TO_PREPARE_COUNT',
            value: 1,
          });
        }
      },
    },
  });
};

export const useMarkPayableAsPreparedMutation = (payableId: string) => {
  const [markAsPreparedMutation] = useMarkPayableAsPrepared(payableId);

  return async ({ payableVersion }: { payableVersion: number | undefined }) => {
    if (!payableVersion) {
      return {
        error: new Error('no payable version found'),
      } as MarkPayableAsPreparedResponse;
    }

    return markAsPreparedMutation({
      payableId,
      payableVersion,
    });
  };
};
