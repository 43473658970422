import * as NEA from '@dev-spendesk/general-type-helpers/NonEmptyArray';
import React from 'react';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useSupplierDetailsQuery } from 'modules/bookkeep/accounts-payable/hooks/useSupplierDetailsQuery';

import { AccountPayableSuppliersPanel } from './AccountPayableSuppliersPanel';
import { AccountPayableSuppliersPanelLoader } from './AccountPayableSuppliersPanelLoader';
import { useMustDisplayAccountsPayable } from '../../../hooks';
import { type IntegrationStatusWithIntegration } from '../../../integration/status';
import { useBulkArchiveSupplierMutation } from '../../hooks/useBulkArchiveSupplierMutation';
import { useBulkUnarchiveSupplierMutation } from '../../hooks/useBulkUnarchiveSupplierMutation';

type Props = {
  integrationStatus: IntegrationStatusWithIntegration;
  supplierId: string;
};

export const AccountPayableSuppliersPanelContainer = ({
  integrationStatus,
  supplierId,
}: Props) => {
  const supplierDetailsQuery = useSupplierDetailsQuery(supplierId);
  const [bulkArchiveSupplier] = useBulkArchiveSupplierMutation();
  const [bulkUnarchiveSupplier] = useBulkUnarchiveSupplierMutation();
  const mustDisplayAccountsPayable = useMustDisplayAccountsPayable();

  return (
    <ErrorBoundary
      context={{
        team: 'finance-accountant',
        scope: 'account-payables-suppliers',
      }}
    >
      {supplierDetailsQuery.status === 'success' ? (
        <AccountPayableSuppliersPanel
          integrationStatus={integrationStatus}
          supplier={supplierDetailsQuery.data}
          mustDisplayAccountsPayable={mustDisplayAccountsPayable}
          onArchiveSupplier={() =>
            bulkArchiveSupplier({ supplierIds: NEA.fromSingleItem(supplierId) })
          }
          onUnarchiveSupplier={() =>
            bulkUnarchiveSupplier({
              supplierIds: NEA.fromSingleItem(supplierId),
            })
          }
        />
      ) : (
        <AccountPayableSuppliersPanelLoader />
      )}
    </ErrorBoundary>
  );
};
