import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { rejectUnexpectedValue } from '../../../../../utils/switchGuard';
import {
  type BankAccount,
  type BankAccountPurpose,
  type SelectableBankAccount,
} from '../../accounting';

type BankAccountUpdatePayload =
  | {
      capabilityKind: 'pullAndSelect';
      purpose: BankAccountPurpose;
      newId: string | undefined;
    }
  | {
      capabilityKind: 'localOnly';
      purpose: BankAccountPurpose;
      newCode: string;
    };

export type BankAccountUpdateError = {
  outcome: 'notSet';
  reason: 'emptyCode' | 'integrationValidationFailed';
};

export type BankAccountUpdateSuccess = {
  outcome: 'set';
  accounts: BankAccount[];
};

export const useUpdateBankAccountsMutation = (): MutationState<
  BankAccountUpdatePayload,
  BankAccountUpdateSuccess,
  BankAccountUpdateError
> => {
  return useMutation<
    BankAccountUpdatePayload,
    BankAccountUpdateSuccess,
    BankAccountUpdateSuccess,
    BankAccountUpdateError
  >({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: '/accounting-integration/chart-of-accounts/bank-accounts',
    },
    reshapeData(data) {
      return data;
    },
    options: {
      throwOnError: true,
      onMutate: async ({ payload, client }): Promise<void> => {
        client.cancelQueries('getBankAccounts');
        client.setQueryData<SelectableBankAccount[]>(
          'getBankAccounts',
          getOptimisticallyUpdatedBankAccounts(payload),
        );
        client.invalidateQueries(['useIntegrationStatusQuery']);
      },
      onSuccess({ client }): void {
        client.invalidateQueries(['getBankAccounts']);
        client.invalidateQueries(['useIntegrationStatusQuery']);
      },
    },
  });
};

const getOptimisticallyUpdatedBankAccounts =
  (payload: BankAccountUpdatePayload) =>
  (
    rawCachedBankAccounts: SelectableBankAccount[] | undefined,
  ): SelectableBankAccount[] => {
    const cachedBankAccounts = rawCachedBankAccounts ?? [];

    const updateBankAccount = (
      bankAccount: SelectableBankAccount,
    ): SelectableBankAccount => {
      if (bankAccount.accountPurpose !== payload.purpose) {
        return bankAccount;
      }

      switch (payload.capabilityKind) {
        case 'localOnly':
          return { ...bankAccount, code: payload.newCode };
        case 'pullAndSelect':
          return {
            ...bankAccount,
            isSelected: payload.newId === bankAccount.id,
          };
        default:
          rejectUnexpectedValue('payload', payload);
      }
    };

    return cachedBankAccounts.map(updateBankAccount);
  };
