import { type UserDetails } from './user';

export interface CostCenter {
  id: string;
  name: string;
}

export const getUserDefaultCostCenter = (
  costCenters: CostCenter[],
  user: UserDetails,
): CostCenter | null => {
  const defaultCostCenter =
    user.costCenter ??
    costCenters.find((costCenter) => costCenter.id === user.cost_center_id);
  return defaultCostCenter ?? null;
};
