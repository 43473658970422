/* eslint-disable @typescript-eslint/naming-convention */
import { colors, Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { toNumber } from 'ezmoney';
import React, { useRef } from 'react';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { ReactComponent as CommittedIcon } from '../../assets/committed.svg';
import { ReactComponent as CommittedSmallIcon } from '../../assets/committedSmall.svg';
import { ReactComponent as ExceededIcon } from '../../assets/exceeded.svg';
import { ReactComponent as ExceededSmallIcon } from '../../assets/exceededSmall.svg';
import {
  type Breakdown,
  type BudgetBreakdown,
  getCommittedAmount,
  getUsedAmount,
  hasCommittedAmounts,
  hasExceededAmounts,
} from '../../models/breakdown';
import { type BreakdownLegendState } from '../../models/breakdownLegendState';
import './BudgetGaugeLegend.css';
import { BudgetGaugeBreakdownKey } from '../BudgetGauge/budget-gauge';

type Mode = 'compact' | 'medium' | 'full';
type Props = {
  breakdown: Breakdown;
  currentSectionHovered?: BreakdownLegendState;
  mode: Mode;
  setCurrentSectionHovered?: (
    section: BreakdownLegendState | undefined,
  ) => void;
  onBreakdownPartClick?(breakdownPart: 'committed' | 'used'): void;
};

export const BudgetGaugeLegend = ({
  breakdown,
  currentSectionHovered,
  mode,
  setCurrentSectionHovered,
  onBreakdownPartClick,
}: Props) => {
  const { t } = useTranslation('global');
  const container = useRef<HTMLDivElement>(null);

  const isBudgetExceeded = hasExceededAmounts(breakdown);
  const isOverspentBudgetLegend = isBudgetExceeded;
  const shouldDisplayCommittedLegend = hasCommittedAmounts(breakdown);

  const isFullMode = mode === 'full';
  const isMediumMode = mode === 'medium';

  return (
    <div
      className={cx('BudgetGaugeLegend', {
        'BudgetGaugeLegend--small':
          container.current && container.current.offsetWidth < 400,
        'BudgetGaugeLegend--full': isFullMode,
        'BudgetGaugeLegend--medium': isMediumMode,
      })}
      ref={container}
    >
      <div
        className={cx('BudgetGaugeLegend__content', {
          'BudgetGaugeLegend__content--hovered': currentSectionHovered,
          'BudgetGaugeLegend__content--full': isOverspentBudgetLegend,
        })}
      >
        {toNumber(breakdown.used) !== 0 && (
          <button
            type="button"
            className={cx('BudgetGaugeLegend__item', {
              'BudgetGaugeLegend__item--hovered':
                currentSectionHovered === 'used',
              'BudgetGaugeLegend__item--button':
                isFullMode && onBreakdownPartClick,
            })}
            onClick={() => onBreakdownPartClick && onBreakdownPartClick('used')}
            onMouseOver={() =>
              setCurrentSectionHovered &&
              setCurrentSectionHovered(BudgetGaugeBreakdownKey.USED)
            }
            onMouseLeave={() =>
              setCurrentSectionHovered && setCurrentSectionHovered(undefined)
            }
            onFocus={() =>
              setCurrentSectionHovered &&
              setCurrentSectionHovered(BudgetGaugeBreakdownKey.USED)
            }
          >
            <div
              className={cx(
                'BudgetGaugeLegend__item__circle',
                isOverspentBudgetLegend ? 'bg-warning-light' : 'bg-primary',
              )}
            />
            <label className="text-left body-s">
              <div className={isMediumMode ? 'body-m' : 'body-l'}>
                {t('budget.gauge.legend.used')}
              </div>
              <div className="flex">
                {getUsedDisplayAmountString({
                  mode,
                  breakdown,
                  isOverspentBudgetLegend,
                  translator: t,
                })}
                <Icon
                  name="caret-right"
                  className="BudgetGaugeLegend__item__caret"
                  color={colors.neutralDark}
                />
              </div>
            </label>
          </button>
        )}
        {shouldDisplayCommittedLegend && (
          <button
            type="button"
            className={cx('BudgetGaugeLegend__item', {
              'BudgetGaugeLegend__item--hovered':
                currentSectionHovered === 'committed',
              'BudgetGaugeLegend__item--button':
                isFullMode && onBreakdownPartClick,
            })}
            onClick={() =>
              onBreakdownPartClick &&
              onBreakdownPartClick(BudgetGaugeBreakdownKey.COMMITTED)
            }
            onMouseOver={() =>
              setCurrentSectionHovered &&
              setCurrentSectionHovered(BudgetGaugeBreakdownKey.COMMITTED)
            }
            onMouseLeave={() =>
              setCurrentSectionHovered && setCurrentSectionHovered(undefined)
            }
            onFocus={() =>
              setCurrentSectionHovered &&
              setCurrentSectionHovered(BudgetGaugeBreakdownKey.COMMITTED)
            }
          >
            {isOverspentBudgetLegend
              ? getExceededIcon(mode)
              : getCommittedIcon(mode)}
            <label className="BudgetGaugeLegend__item__label text-left body-s">
              <div className={isMediumMode ? 'body-m' : 'body-l'}>
                {t('budget.gauge.legend.committed')}
              </div>
              <div className="flex">
                {getCommittedDisplayAmountString({
                  mode,
                  breakdown,
                  isOverspentBudgetLegend,
                  translator: t,
                })}
                <Icon
                  name="caret-right"
                  className="BudgetGaugeLegend__item__caret"
                  color={colors.neutralDark}
                />
              </div>
            </label>
          </button>
        )}
        {!isBudgetExceeded && (
          <button
            type="button"
            className={cx('BudgetGaugeLegend__item', {
              'BudgetGaugeLegend__item--hovered':
                currentSectionHovered === 'available',
            })}
            onMouseOver={() =>
              setCurrentSectionHovered &&
              setCurrentSectionHovered(BudgetGaugeBreakdownKey.AVAILABLE)
            }
            onMouseLeave={() =>
              setCurrentSectionHovered && setCurrentSectionHovered(undefined)
            }
            onFocus={() =>
              setCurrentSectionHovered &&
              setCurrentSectionHovered(BudgetGaugeBreakdownKey.AVAILABLE)
            }
          >
            <div className="BudgetGaugeLegend__item__circle bg-neutral-light" />
            <label className="BudgetGaugeLegend__item__label text-left body-s">
              <div className={isMediumMode ? 'body-m' : 'body-l'}>
                {t('budget.gauge.legend.available')}
              </div>
              <div className={isMediumMode ? '-ml-s body-m' : 'body-l'}>
                {formatMonetaryValue(breakdown.available)}
              </div>
            </label>
          </button>
        )}
        {toNumber(breakdown.usedExceeded) !== 0 && !isOverspentBudgetLegend && (
          <button
            type="button"
            className={cx('BudgetGaugeLegend__item', {
              'BudgetGaugeLegend__item--hovered':
                currentSectionHovered === BudgetGaugeBreakdownKey.USED,
            })}
            onMouseOver={() =>
              setCurrentSectionHovered &&
              setCurrentSectionHovered(BudgetGaugeBreakdownKey.USED)
            }
            onMouseLeave={() =>
              setCurrentSectionHovered && setCurrentSectionHovered(undefined)
            }
            onFocus={() =>
              setCurrentSectionHovered &&
              setCurrentSectionHovered(BudgetGaugeBreakdownKey.USED)
            }
          >
            <div className="BudgetGaugeLegend__item__circle bg-warning" />
            <label className="BudgetGaugeLegend__item__label text-left body-s">
              <div className={isMediumMode ? 'body-m' : 'body-l'}>
                {t('budget.gauge.legend.usedExceeded')}
              </div>
              <div className={isMediumMode ? '-ml-s body-m' : 'body-l'}>
                {formatMonetaryValue(breakdown.usedExceeded)}
              </div>
            </label>
          </button>
        )}
        {toNumber(breakdown.committedExceeded) !== 0 &&
          !isOverspentBudgetLegend && (
            <button
              type="button"
              className={cx('BudgetGaugeLegend__item', {
                'BudgetGaugeLegend__item--hovered':
                  currentSectionHovered === BudgetGaugeBreakdownKey.COMMITTED,
                'BudgetGaugeLegend__item--button':
                  isFullMode && onBreakdownPartClick,
              })}
              onMouseOver={() =>
                setCurrentSectionHovered &&
                setCurrentSectionHovered(BudgetGaugeBreakdownKey.COMMITTED)
              }
              onMouseLeave={() =>
                setCurrentSectionHovered && setCurrentSectionHovered(undefined)
              }
              onFocus={() =>
                setCurrentSectionHovered &&
                setCurrentSectionHovered(BudgetGaugeBreakdownKey.COMMITTED)
              }
              onClick={() =>
                onBreakdownPartClick && onBreakdownPartClick('committed')
              }
            >
              {getExceededIcon(mode)}
              <label className="BudgetGaugeLegend__item__label text-left body-s">
                <div className={isMediumMode ? 'body-m' : 'body-l'}>
                  {t('budget.gauge.legend.committedExceeded')}
                </div>
                <div className={isMediumMode ? '-ml-s body-m' : 'body-l'}>
                  {formatMonetaryValue(breakdown.committedExceeded)}
                </div>
              </label>
            </button>
          )}
      </div>
    </div>
  );
};

const getExceededIcon = (mode: 'compact' | 'medium' | 'full') => {
  if (mode === 'compact' || mode === 'medium') {
    return <ExceededSmallIcon className="BudgetGaugeLegend__item__icon" />;
  }
  return <ExceededIcon className="BudgetGaugeLegend__item__icon" />;
};

const getCommittedIcon = (mode: 'compact' | 'medium' | 'full') => {
  if (mode === 'compact' || mode === 'medium') {
    return <CommittedSmallIcon className="BudgetGaugeLegend__item__icon" />;
  }
  return <CommittedIcon className="BudgetGaugeLegend__item__icon" />;
};

export const getUsedDisplayAmountString = ({
  mode,
  breakdown,
  isOverspentBudgetLegend,
  translator,
}: {
  mode: Mode;
  breakdown: BudgetBreakdown;
  isOverspentBudgetLegend: boolean;
  translator: TGlobalFunctionTyped;
}): React.ReactElement => {
  let amountString;
  const { used, usedExceeded } = breakdown;
  if (isOverspentBudgetLegend) {
    amountString = (
      <>
        {formatMonetaryValue(getUsedAmount(breakdown))}
        {toNumber(usedExceeded) !== 0 && (
          <span className="text-warning body-s">
            {' '}
            <>
              ({formatMonetaryValue(usedExceeded)}{' '}
              {translator('budget.gauge.legend.exceeded')})
            </>
          </span>
        )}
      </>
    );
  } else {
    amountString = formatMonetaryValue(used);
  }

  return (
    <div className={mode === 'medium' ? '-ml-s body-m' : 'body-l'}>
      {amountString}
    </div>
  );
};

export const getCommittedDisplayAmountString = ({
  mode,
  breakdown,
  isOverspentBudgetLegend,
  translator,
}: {
  mode: Mode;
  breakdown: BudgetBreakdown;
  isOverspentBudgetLegend: boolean;
  translator: TGlobalFunctionTyped;
}): React.ReactElement => {
  let amountString;
  const { committed, committedExceeded } = breakdown;
  if (isOverspentBudgetLegend) {
    amountString = (
      <>
        {formatMonetaryValue(getCommittedAmount(breakdown))}
        {toNumber(committedExceeded) !== 0 && (
          <span className="text-warning body-s">
            {' '}
            <>
              ({formatMonetaryValue(committedExceeded)}{' '}
              {translator('budget.gauge.legend.exceeded')})
            </>
          </span>
        )}
      </>
    );
  } else {
    amountString = formatMonetaryValue(committed);
  }

  return (
    <div className={mode === 'medium' ? '-ml-s body-m' : 'body-l'}>
      {amountString}
    </div>
  );
};
