import { type Translations, useQueryError } from 'common/components/QueryError';
import { useSuppressWarningsForExpenseBeneficiaryQuery } from 'modules/reimbursements/hooks/useSuppressWarningsForExpenseBeneficiaryQuery';
import { type QueryError } from 'src/core/api/queryError';

import * as SuppressWarningsForInvoiceBeneficiariesQuery from './useSuppressWarningsForInvoiceBeneficiariesQuery';

export type Payload = {
  paymentIds: string[];
  type: 'invoice' | 'expense';
};

export type Response = object;

export const useVerifyBeneficiariesQueryFactory = () => {
  const [suppressWarningsForInvoiceBeneficiariesQuery] =
    SuppressWarningsForInvoiceBeneficiariesQuery.useSuppressWarningsForInvoiceBeneficiariesQuery();
  const [suppressWarningsForExpenseBeneficiaryQuery] =
    useSuppressWarningsForExpenseBeneficiaryQuery();

  return (payload: Payload): Promise<Response> => {
    return payload.type === 'invoice'
      ? suppressWarningsForInvoiceBeneficiariesQuery({
          paymentIds: payload.paymentIds,
        })
      : suppressWarningsForExpenseBeneficiaryQuery({
          scheduledPaymentIds: payload.paymentIds,
        });
  };
};

const errorTranslations: Translations<SuppressWarningsForInvoiceBeneficiariesQuery.Error> =
  {
    requestError: ({ error }) => {
      switch (error) {
        case 'payments-not-found':
          return 'wiretransfer.confirmation.suppressBeneficiariesWarningApi.errors.payments-not-found';
        default:
          return undefined;
      }
    },
    serverError:
      'wiretransfer.confirmation.suppressBeneficiariesWarningApi.error',
  };

export const useVerifyTransfersBeneficiariesErrorMessage = () => {
  const getErrorMessage =
    useQueryError<SuppressWarningsForInvoiceBeneficiariesQuery.Error>(
      errorTranslations,
    );

  return (
    queryError: QueryError<SuppressWarningsForInvoiceBeneficiariesQuery.Error>,
    translationParams?: Record<string, unknown>,
  ): string => getErrorMessage(queryError, translationParams);
};
