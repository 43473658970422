import { type SupplierBankInfos } from 'modules/suppliers';
import { processUnexpectedValue } from 'src/core/utils/switchGuard';
import { logger } from 'src/utils/datadog-log-wrapper';

// https://www.europeanpaymentscouncil.eu/sites/default/files/kb/file/2018-09/EPC135-18%20v1.0%20Guidance%20on%20Reason%20Codes%20for%20SCT%20R-transactions.pdf
export const sepaErrorsCodes = [
  'AC01',
  'AC03',
  'AC04',
  'AC06',
  'AG01',
  'AG02',
  'AM04',
  'AM05',
  'AM09',
  'ARDT',
  'BE04',
  'CNOR',
  'CUST',
  'DNOR',
  'DUPL',
  'FF01',
  'FOCR',
  'FRAD',
  'LEGL',
  'RC01',
  'TECH',
] as const;

export const otherErrors = [
  'PaymentComplianceFailure',
  'MissingScreeningData',
  'OUTBOUND_PAYMENT_REJECTED',
  'INSUFFICIENT_BALANCE',
  'WALLET_NOT_ACTIVE',
  'unknown_error',
  'DAILY_LIMIT_REACHED',
  'invalid_beneficiary_bank_info',
  'closed_beneficiary_account',
  'InvalidAmountGreaterThanLimit',
  'INVALID_PAYMENT_REFERENCE_FORMAT',
  'INVALID_ACCOUNT_HOLDER_NAME_FORMAT',
  'INVALID_BENEFICIARY_ADDRESS_FORMAT',
  'adyenMigrationCancellation',
  'notEnoughBalance',
] as const;

export const errors = [...sepaErrorsCodes, ...otherErrors] as const;

export type WireTransferError = (typeof errors)[number];

export const beneficiaryBankInfoErrorsCodes = [
  'AC01',
  'AC03',
  'RC01',
  'PaymentComplianceFailure',
  'invalid_beneficiary_bank_info',
  'closed_beneficiary_account',
  'INVALID_ACCOUNT_HOLDER_NAME_FORMAT',
] as const;
export type BeneficiaryBankInfoError =
  (typeof beneficiaryBankInfoErrorsCodes)[number];

export const isBeneficiaryBankInfoError = (
  error: WireTransferError,
): error is BeneficiaryBankInfoError =>
  beneficiaryBankInfoErrorsCodes.includes(error as BeneficiaryBankInfoError);

export const getBankFieldsRelevantToBeneficiaryBankInfoError = (
  error: BeneficiaryBankInfoError,
): (keyof Omit<SupplierBankInfos, 'bankCountry'>)[] => {
  switch (error) {
    case 'AC01':
    case 'AC03': {
      return ['iban', 'accountNumber'];
    }

    case 'RC01': {
      return ['bic'];
    }

    case 'INVALID_ACCOUNT_HOLDER_NAME_FORMAT':
    case 'PaymentComplianceFailure': {
      return ['accountHolderName'];
    }

    case 'invalid_beneficiary_bank_info':
    case 'closed_beneficiary_account': {
      return [
        'iban',
        'accountNumber',
        'accountCode',
        'routingNumber',
        'bic',
        'sortCode',
      ];
    }

    default: {
      processUnexpectedValue(error, () => {
        logger.warn(`@financeOps: Unhandled beneficiary error "${error}"`, {
          team: 'finance-operations',
          scope: 'invoices',
          error,
        });
      });
      return [];
    }
  }
};
