import { type QueryState } from 'src/core/api/queryState';

import { useWireTransferActivationStatusQuery } from './useWireTransferActivationStatusQuery';
import type { WireTransferActivationStatus } from '../models';

export const useInvoiceWireTransferActivationStatusQuery = (): QueryState<
  WireTransferActivationStatus,
  unknown
> => {
  const wireTransferActivationStatusQueryState =
    useWireTransferActivationStatusQuery();

  if (wireTransferActivationStatusQueryState.status === 'success') {
    return {
      ...wireTransferActivationStatusQueryState,
      data: wireTransferActivationStatusQueryState.data.forInvoices,
    };
  }

  return wireTransferActivationStatusQueryState;
};
