import {
  SkeletonTable,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  showArchivedTab: boolean;
};

export const PreviewSkeleton = ({ showArchivedTab }: Props) => {
  const { t } = useTranslation('global');

  return (
    <>
      <div>
        <div className="h-m text-left font-medium text-neutral-darker">
          {t('integration.kombo.settings.settingsModal.syncPreview.title')}
        </div>
        <div className="rounded-xxs bg-neutral-lightest p-s text-left">
          <SkeletonText size="l" width="100%" />
        </div>
      </div>

      <div data-testid="preview-skeleton">
        <div className="mb-xxs text-left font-medium text-neutral-darker">
          {t(
            'integration.kombo.settings.settingsModal.syncPreview.sample.title',
          )}
        </div>
        <div className="mb-xs text-left font-normal text-neutral-dark">
          {t(
            'integration.kombo.settings.settingsModal.syncPreview.sample.description',
          )}
        </div>

        <div className="rounded-xs border border-solid border-neutral">
          <Tabs>
            <TabList isFitted>
              <Tab>
                {t(
                  'integration.kombo.settings.settingsModal.syncPreview.sample.tabs.invites',
                )}
              </Tab>
              {showArchivedTab && (
                <Tab>
                  {t(
                    'integration.kombo.settings.settingsModal.syncPreview.sample.tabs.archives',
                  )}
                </Tab>
              )}
              <Tab>
                {t(
                  'integration.kombo.settings.settingsModal.syncPreview.sample.tabs.outOfSyncs',
                )}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <SkeletonTable
                  className="border-0"
                  numberOfRows={5}
                  columns={[
                    {
                      cell: <SkeletonText size="l" width="100%" />,
                      header: t(
                        'integration.kombo.settings.settingsModal.syncPreview.sample.columns.employee',
                      ),
                      width: '50%',
                    },
                    {
                      cell: <SkeletonText size="l" width="100%" />,
                      header: 'Email',
                      width: '50%',
                    },
                  ]}
                  withHeader
                  rowHeight="compact"
                />
              </TabPanel>
              {showArchivedTab && (
                <TabPanel>
                  <SkeletonTable
                    className="border-0"
                    numberOfRows={5}
                    columns={[
                      {
                        cell: <SkeletonText size="l" width="100%" />,
                        header: t(
                          'integration.kombo.settings.settingsModal.syncPreview.sample.columns.employee',
                        ),
                        width: '50%',
                      },
                      {
                        cell: <SkeletonText size="l" width="100%" />,
                        header: 'Email',
                        width: '50%',
                      },
                    ]}
                    withHeader
                    rowHeight="compact"
                  />
                </TabPanel>
              )}
              <TabPanel>
                <SkeletonTable
                  className="border-0"
                  numberOfRows={5}
                  columns={[
                    {
                      cell: <SkeletonText size="l" width="100%" />,
                      header: t(
                        'integration.kombo.settings.settingsModal.syncPreview.sample.columns.employee',
                      ),
                      width: '50%',
                    },
                    {
                      cell: <SkeletonText size="l" width="100%" />,
                      header: 'Email',
                      width: '50%',
                    },
                  ]}
                  withHeader
                  rowHeight="compact"
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
    </>
  );
};
