import { CheckboxField, RadioField, RadioGroup } from '@dev-spendesk/grapes';
import React from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import {
  type AnalyticalLineOption,
  type RowDisplayOption,
  type ColumnDisplayOptions,
  analyticalLineOptions,
} from '../../../export';

type Props = {
  onDisplayOptionChange: (displayOptions: ColumnDisplayOptions) => void;
  isAnalyticalColumn: boolean;
  displayOptions?: ColumnDisplayOptions;
  rowDisplayOptions?: RowDisplayOption[];
};

export const ColumnDisplayOptionsContainer = ({
  onDisplayOptionChange,
  isAnalyticalColumn,
  displayOptions,
  rowDisplayOptions,
}: Props) => {
  const { t } = useTranslation('global');

  const rowColSelectable = useFeature(FEATURES.TMP_TEMPLATE_ROW_COL_SELECT);

  if (rowColSelectable && rowDisplayOptions && !displayOptions) {
    displayOptions = {
      expense: { active: true },
      analytical: { active: !isAnalyticalColumn },
      vat: { active: true },
      supplier: { active: true },
    };
    onDisplayOptionChange(displayOptions);
  }

  return (
    <div className="flex flex-col">
      {rowDisplayOptions
        ?.filter((r) => r.active)
        .map(({ group, analyticalCommonLine }) => {
          const isChecked = !!(
            displayOptions &&
            displayOptions[group] &&
            displayOptions[group].active
          );

          const isAnalyticalColumnActiveForAnalyticalColumn =
            isAnalyticalColumn &&
            group === 'analytical' &&
            displayOptions?.analytical.active;

          // If analytical column is active and no selection, set first item as selected
          if (
            isAnalyticalColumnActiveForAnalyticalColumn &&
            !displayOptions?.analytical.analyticalOption &&
            displayOptions
          ) {
            displayOptions.analytical.analyticalOption =
              analyticalLineOptions[0];
            onDisplayOptionChange(displayOptions);
          }

          return (
            <>
              <CheckboxField
                isChecked={isChecked}
                key={group}
                onChange={(e) => {
                  if (!displayOptions) {
                    return;
                  }
                  const displayOption = displayOptions[group];
                  if (displayOption) {
                    displayOption.active = e.target.checked;
                  }
                  onDisplayOptionChange(displayOptions);
                }}
                label={t(
                  `bookkeep.settings.export.columnDisplayOptions.${group}`,
                )}
                className="AutoCompleteMulti__list__option__checkbox w-[436px]"
              />
              {isAnalyticalColumnActiveForAnalyticalColumn && (
                <RadioGroup
                  name="analyticalColumnDisplayOptions"
                  value={
                    displayOptions?.analytical.analyticalOption ||
                    analyticalLineOptions[0]
                  }
                  onChange={(e) => {
                    if (!displayOptions) {
                      return;
                    }
                    displayOptions.analytical.analyticalOption = e.target
                      .value as AnalyticalLineOption;

                    onDisplayOptionChange(displayOptions);
                  }}
                  direction="column"
                  className="pl-s"
                >
                  {analyticalLineOptions
                    .filter((option) => {
                      if (!analyticalCommonLine?.active) {
                        return option !== 'common';
                      }
                      return true;
                    })
                    .map((option) => (
                      <RadioField
                        value={option}
                        key={option}
                        label={t(
                          `bookkeep.settings.export.columnDisplayOptions.analyticalOptions.${option}`,
                        )}
                      />
                    ))}
                </RadioGroup>
              )}
            </>
          );
        })}
    </div>
  );
};
