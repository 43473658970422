/* eslint-disable unicorn/no-useless-switch-case */
import {
  type I18nKey,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';

const availableSpendingTypes = <const>[
  'singlePurchase',
  'subscription',
  'expenseClaim',
  'invoice',
  'cardLoad',
  'purchaseOrder',
];

export type SpendingType = (typeof availableSpendingTypes)[number];

const spendingTypeTranslationKeyMapping: {
  [spendingType in SpendingType]: I18nKey;
} = {
  singlePurchase: 'approvalPolicy.spendingTypes.singlePurchase',
  subscription: 'approvalPolicy.spendingTypes.subscription',
  expenseClaim: 'approvalPolicy.spendingTypes.expense',
  invoice: 'approvalPolicy.spendingTypes.invoice',
  cardLoad: 'approvalPolicy.spendingTypes.cardLoad',
  purchaseOrder: 'approvalPolicy.spendingTypes.purchaseOrder',
};

export const getValidSpendingTypes = ({
  isPlasticCardEnabled,
  isExpenseClaimEnabled,
  isInvoiceEnabled,
  isPurchaseOrderEnabled,
}: {
  isPlasticCardEnabled: boolean;
  isExpenseClaimEnabled: boolean;
  isInvoiceEnabled: boolean;
  isPurchaseOrderEnabled: boolean;
}): SpendingType[] => {
  return availableSpendingTypes.filter((availableSpendingType) => {
    switch (availableSpendingType) {
      case 'cardLoad':
        return isPlasticCardEnabled;
      case 'expenseClaim':
        return isExpenseClaimEnabled;
      case 'invoice':
        return isInvoiceEnabled;
      case 'purchaseOrder':
        return isPurchaseOrderEnabled;
      case 'singlePurchase':
      case 'subscription':
      default:
        return true;
    }
  });
};

export const getReadableSpendingType = (
  spendingType: SpendingType,
  translator: TGlobalFunctionTyped,
): string => {
  return translator(spendingTypeTranslationKeyMapping[spendingType]);
};

export const getReadableSpendingTypes = (
  spendingTypes: SpendingType[],
  translator: TGlobalFunctionTyped,
): string => {
  const translatedTypes = spendingTypes.map((spendingType) => {
    return getReadableSpendingType(spendingType, translator);
  });
  if (translatedTypes.length === availableSpendingTypes.length) {
    return translator('approvalPolicy.spendingTypes.all');
  }
  if (translatedTypes.length > 1) {
    const readableLastType = translatedTypes.slice(-1);
    const readableTypes = translatedTypes.slice(0, -1).join(', ');
    return `${readableTypes} ${translator('misc.and')} ${readableLastType}`;
  }
  return translatedTypes[0];
};
