import { getFetcher } from 'src/core/api/fetcher';

import { GET_TEAMS } from './query';

type RawData = {
  company: {
    teams: {
      totalCount: number;
      edges: {
        node: {
          id: string;
          name: string;
        };
      }[];
    };
  };
};

export const getTeamsQueryFetcher = ({
  companyId,
  filter,
}: {
  companyId: string;
  filter: {
    ids?: string[];
    search?: string;
  };
}) =>
  getFetcher<RawData>({
    companyId,
    getRequest: () => ({
      type: 'graphQL',
      target: 'v2',
      query: GET_TEAMS,
      variables: {
        first: 50,
        filter,
      },
      method: 'get',
    }),
  });
