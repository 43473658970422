import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { SettingsLayout } from 'common/components/layout';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routes } from 'src/core/constants/routes';

import { BillingInformationSection } from './components/BillingInformationSection';
import { BillingPageNav } from './components/BillingPageNav';
import { CompanyPlanContainer } from './components/CompanyPlan';

export const BillingPage = () => {
  const companyId = useCompanyId();
  return (
    <div className="page__container">
      <SettingsLayout>
        <BillingPageNav companyId={companyId} />
        <div>
          <Switch>
            <Route path={routes.COMPANY_BILLING_PLAN.path}>
              <CompanyPlanContainer />
            </Route>
            <Route path={routes.COMPANY_BILLING_INFORMATION.path}>
              <BillingInformationSection />
            </Route>
          </Switch>
        </div>
      </SettingsLayout>
    </div>
  );
};
