import React, { type ReactNode } from 'react';
import { NavLink as RRNavLink, matchPath } from 'react-router-dom';

type Props = {
  href: string;
  children: ReactNode;
  activePaths?: string[];
};

export const SubNavLink = ({ href, children, activePaths }: Props) => {
  return (
    <RRNavLink
      to={href}
      className="navigation-item navigation-toggle-display h-[36px]"
      isActive={(_, location) => {
        const match = matchPath(location.pathname, {
          path: activePaths ?? href,
          exact: false,
          strict: false,
        });
        return match !== null;
      }}
    >
      <span className="ml-l">{children}</span>
    </RRNavLink>
  );
};
