import { Autocomplete, type AutocompleteProps } from '@dev-spendesk/grapes';
import React, { type ReactChild, useState } from 'react';
import { useAsync } from 'react-use';

import type { Option } from './option';

export type Props<T extends Option> = Omit<
  AutocompleteProps<T>,
  | 'options'
  | 'onSelect'
  | 'onSearch'
  | 'renderNoOptions'
  | 'onAddOption'
  | 'renderAddOption'
> &
  (
    | {
        renderAddOption(rawValue: string): ReactChild; // should always return a DropdownItem component
        onAddOption(newOptionLabel: string): T | Promise<T>;
        renderNoOptions?(rawValue: string): ReactChild;
      }
    | {
        renderNoOptions(rawValue: string): ReactChild;
        renderAddOption?: never;
        onAddOption?: never;
      }
  ) & {
    handleSearch: (value: string | undefined) => Promise<T[]>;
    handleSelect: (value: T | undefined) => void;
    loadValues: (ids: string[]) => Promise<T[]>;
  };

export const AutocompleteAsync = <T extends Option>(props: Props<T>) => {
  const { handleSearch, handleSelect, loadValues, ...restProps } = props;
  const [selectedOption, setSelectedOption] = useState<T | undefined>();
  const [options, setOptions] = useState<T[] | undefined>();

  useAsync(async () => {
    if (props.value) {
      const selectedKey = props.value.key;
      const loadedOptions = await loadValues([selectedKey]);
      setOptions(loadedOptions);
      setSelectedOption({
        key: selectedKey,
        label:
          loadedOptions.find((option) => option.key === selectedKey)?.label ||
          '',
      } as T);
    } else {
      setSelectedOption(undefined);
    }
  }, [props.value]);

  return (
    <Autocomplete
      {...restProps}
      value={selectedOption}
      options={options || []}
      onSearch={async (value) => {
        if (value === undefined || value.length === 0) {
          setOptions(undefined);
          return;
        }
        const filteredOptions = await handleSearch(value);
        setOptions(filteredOptions);
      }}
      onSelect={(option) => {
        setSelectedOption(option);
        handleSelect(option);
      }}
    />
  );
};
