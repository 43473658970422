import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type MemberStatus, type MemberTableRow } from '../../../models/member';

const PAGE_SIZE = 50;
const OFFSET = 0;

export const useMemberTableRowsQuery = (
  searchTerm: string,
  memberStatus: MemberStatus,
): QueryState<MemberTableRow[]> => {
  return useQuery<MemberTableRow[], MemberTableRow[]>({
    key: ['users', { searchTerm, memberStatus }],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/members-page?pageSize=${PAGE_SIZE}&offset=${OFFSET}&searchTerm=${searchTerm}&memberStatus=${memberStatus}`,
    },
    reshapeData: (data) =>
      data.map((rawMember) => ({
        ...rawMember,
        status: memberStatus,
      })),
  });
};
