import { Button } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { wwwBaseUrl } from 'src/core/config';

import phoneSMSSentSVG from './phone-sms-sent.svg';

type Props = { goToNextStep: () => void };

export const Step4 = ({ goToNextStep }: Props) => {
  const { t } = useTranslation('global');

  const [actionLinksShown, setActionLinksShow] = useState(false);

  const toggleActionLinks = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setActionLinksShow(!actionLinksShown);
  };

  return (
    <div className="w-[560px]">
      <h1 className="text-complementary title-xxl">
        {t('cards.activation.step4.pinCodeSentTitle')}
      </h1>
      <p className="mb-m">{t('cards.activation.step4.checkYourMessages')}</p>

      <div className="box flex flex-col items-center">
        <img alt="" className="w-[540px]" src={phoneSMSSentSVG} />
        <Button
          className="mb-s mt-m"
          text={t('cards.activation.step4.showCardInfos')}
          onClick={goToNextStep}
        />
        <Button
          variant="ghost"
          text={t('cards.activation.step4.didntReceivePin')}
          onClick={toggleActionLinks}
        />
        {actionLinksShown && (
          <p className="mt-s text-neutral-darker body-m">
            <Trans i18nKey="cards.activation.step4.mobileCta">
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                className="text-neutral-darker underline"
                href={`${wwwBaseUrl}/mobile`}
                target="_blank"
              >
                -
              </a>
              -
              <a
                className="text-neutral-darker underline"
                href="#"
                id="intercom-message"
              >
                -
              </a>
            </Trans>
          </p>
        )}
      </div>
    </div>
  );
};
