import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  user_id: string;
  params: {
    approval_needed: 'always' | 'sometimes' | 'never';
    spending_types: string;
    spending_limit: number;
    transaction_max: number;
  };
};

export const useCreateUserPolicyMutation = () => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/userpolicies`,
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
  });
};
