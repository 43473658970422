import { PageModal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import type { BankDetailsSuggestion, RawSupplier } from '../../models';
import { ToReviewPanelSupplier } from '../ToReviewPanelSupplier';

type LegacySupplier = RawSupplier & {
  supplier_details_suggestion?: BankDetailsSuggestion | null;
};

export type Props = {
  documentaryEvidence?: React.ReactNode;
  supplier: LegacySupplier;
  onClose(): void;
  onCreateSupplierSuccess?(supplier: RawSupplier): void;
  onUpdateSupplierSuccess?(supplier: RawSupplier): void;
};

export const SupplierDetailsModal = ({
  documentaryEvidence,
  supplier,
  onClose,
  onCreateSupplierSuccess,
  onUpdateSupplierSuccess,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <PageModal isOpen onClose={onClose}>
      <h2 className="mb-l text-complementary title-xl">
        {t('submitMyInvoice.supplier.supplierInfo')}
      </h2>
      <div className="flex h-[calc(100%-70px)] gap-s">
        <section>
          <ToReviewPanelSupplier
            // @ts-expect-error FIXME: mismatch between the types RawInvoiceData['supplier'] and ApiSupplier
            supplier={supplier}
            // @ts-expect-error FIXME: mismatch between the types RawInvoiceData['supplier'] and ApiSupplier
            onCreateSupplierSuccess={onCreateSupplierSuccess}
            // @ts-expect-error FIXME: mismatch between the types RawInvoiceData['supplier'] and ApiSupplier
            onUpdateSupplierSuccess={onUpdateSupplierSuccess}
          />
        </section>
        {documentaryEvidence && (
          <section className="h-full w-full">{documentaryEvidence}</section>
        )}
      </div>
    </PageModal>
  );
};
