import { useSetAccountsPayableMutation } from '../../../accounts-payable/hooks/useSetAccountsPayableMutation';
import { type DefaultEmployeeAccount } from '../../accounting/employeeAccount';

export const useSetDefaultEmployeeAccountMutation__deprecated = () => {
  const [setAccountsPayableMutation] = useSetAccountsPayableMutation();

  return async (employeeAccount: DefaultEmployeeAccount, isDelete = false) => {
    return setAccountsPayableMutation({
      accountsUpdates: [
        {
          // in case of update the ID will be undefined becouse update is under the hood delete and create, and this behaviour is triggered when there is no id in the request
          id: isDelete ? employeeAccount.id : undefined,
          kind: 'employeeAccount',
          isDefault: true,
          isArchived: employeeAccount.isArchived ?? false,
          generalAccountCode: employeeAccount.generalAccountCode,
          // TODO: make this field editable from the platform
          // TODO: make this translatable
          name: 'Miscellaneous',
        },
      ],
    });
  };
};
