import { Icon } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const ProcurementLink = () => {
  const { t } = useTranslation('global');

  return (
    <a href={appConfig.procurementBaseUrl} className="navigation-item h-xl">
      <Icon name="cart" size="m" className="shrink-0" />
      <span className="navigation-toggle-display">
        {t('misc.procurementTitle')}
      </span>
    </a>
  );
};
