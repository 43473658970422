import { useUser, type User } from 'src/core/modules/app/hooks/useUser';

export const useCurrentMember = (): PartialReduxMember => {
  const currentReduxUser = useUser();

  return reshapeReduxToMember(currentReduxUser);
};

// You can add the properties you need
type PartialReduxMember = {
  id: string;
  isAccountOwner: boolean;
  isAdmin: boolean;
  isController: boolean;
  isRequester: boolean;
};

const reshapeReduxToMember = (user: User): PartialReduxMember => {
  return {
    id: user.id,
    isAccountOwner: user.is_account_owner,
    isAdmin: user.is_admin,
    isController: user.is_controller,
    isRequester: user.is_requester,
  };
};
