import {
  FormField,
  RadioGroup,
  RadioField,
  TextInput,
  Callout,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';

import { type CustomFieldCreationForm } from '../../containers/CustomFieldCreationModalContainer';

import './CustomFieldModalInfo.css';

export const CustomFieldInfoStep = ({
  formikProps,
}: {
  formikProps: FormikProps<
    Pick<CustomFieldCreationForm, 'name' | 'type' | 'isSplittable'>
  >;
}) => {
  const { t } = useTranslation('global');
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();

  return (
    <div className="CustomFieldModalInfo__content">
      <FormField
        className="CustomFieldModalInfo__content__field"
        label={t('customFields.creation.nameLabel')}
        alertMessage={formikProps.errors.name}
      >
        <TextInput
          value={formikProps.values.name}
          onChange={(e) => {
            formikProps.setFieldValue('name', e.target.value);
          }}
          placeholder={t('customFields.creation.namePlaceholder')}
        />
      </FormField>
      <FormField
        className="CustomFieldModalInfo__content__field"
        label={t('customFields.creation.typeLabel')}
        description={t('customFields.creation.typeDescription')}
      >
        <RadioGroup
          name="type"
          value={formikProps.values.type}
          onChange={(e) => {
            formikProps.setFieldValue('type', e.target.value);
          }}
        >
          <RadioField
            value="mandatory"
            label={t('customFields.creation.typeOptionMandatory')}
          />
          <RadioField
            value="optional"
            label={t('customFields.creation.typeOptionOptional')}
          />
        </RadioGroup>
      </FormField>
      <Callout
        className="CustomFieldCreationModal__content__callouts"
        title={t('customFields.creation.callout')}
      />
      {isAnalyticalSplitActivated ? (
        <FormField
          className="CustomFieldModalInfo__content__field"
          label={t('customFields.creation.splittableLabel')}
        >
          <RadioGroup
            name="isSplittable"
            value={formikProps.values.isSplittable ? 'true' : 'false'}
            onChange={(e) => {
              formikProps.setFieldValue(
                'isSplittable',
                e.target.value === 'true',
              );
            }}
          >
            <RadioField
              value="true"
              label={t('customFields.creation.splittableOptionYes')}
            />
            <RadioField
              value="false"
              label={t('customFields.creation.splittableOptionNo')}
            />
          </RadioGroup>
        </FormField>
      ) : null}
    </div>
  );
};
