import { dateFormatter, DATE_FORMAT, Avatar } from '@dev-spendesk/grapes';
import { type MonetaryValue } from 'ezmoney';
import React from 'react';
import { Trans } from 'react-i18next';

import {
  SupplierLogo,
  fallbackSupplierLogoSrc,
} from 'common/components/SupplierLogo';
import { formatMemberName } from 'modules/bookkeep/payables/models/member';
import type { Payable } from 'modules/payable/models';
import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

export const formatRow = (
  payable: Payable,
  translator: TGlobalFunctionTyped,
  activeLanguage: string,
) => {
  if (!payable.counterparty) {
    return formatMissingCounterpartyRow(payable, translator, activeLanguage);
  }
  if (payable.counterparty.type === 'supplier') {
    return formatSupplierCounterpartyRow(payable, translator, activeLanguage);
  }
  return formatMemberCounterpartyRow(payable, translator, activeLanguage);
};

const formatMissingCounterpartyRow = (
  payable: Payable,
  translator: TGlobalFunctionTyped,
  locale: string,
) => {
  const memberName = formatMemberName(payable.member, translator);

  return {
    id: payable.id,
    leftImage: <SupplierLogo name="" />,
    firstLineLeft: '', // FIXME: add a placeholder name
    firstLineRight: formatMonetaryValue(payable.functionalAmount),
    firstLineRightSecondary: formatGrossAmount(
      payable.grossAmount,
      payable.functionalAmount,
    ),
    secondLineLeftItems: [payable.description, memberName],
    secondLineRight: formatDate(locale, payable.creationDate),
  };
};

const formatSupplierCounterpartyRow = (
  payable: Payable,
  translator: TGlobalFunctionTyped,
  locale: string,
) => {
  const { counterparty } = payable;

  if (!counterparty || counterparty?.type !== 'supplier') {
    return;
  }

  const memberName = formatMemberName(payable.member, translator);

  let maybeInvoiceNumber;
  if (payable.documentaryEvidence?.type === 'invoice') {
    maybeInvoiceNumber = payable.documentaryEvidence.invoiceNumber;
  }

  if (payable.documentaryEvidence?.type === 'creditNote') {
    maybeInvoiceNumber = payable.documentaryEvidence.creditNoteNumber;
  }
  const receiptOrCreditNoteNumber = maybeInvoiceNumber
    ? [
        <Trans
          key="receiptNumber"
          i18nKey="payables.panel.receiptNumber"
          values={{ receiptNumber: maybeInvoiceNumber }}
          components={[<span className="text-complementary" key="text" />]}
        />,
      ]
    : [];

  return {
    id: payable.id,
    leftImage: (
      <SupplierLogo
        name={counterparty.name !== 'Unknown' ? counterparty.name : undefined}
        src={counterparty.thumbnailUrl}
      />
    ),
    firstLineLeft: counterparty.name,
    firstLineRight: formatMonetaryValue(payable.functionalAmount),
    firstLineRightSecondary: formatGrossAmount(
      payable.grossAmount,
      payable.functionalAmount,
    ),
    secondLineLeftItems: [
      ...receiptOrCreditNoteNumber,
      payable.description,
      memberName,
    ],
    secondLineRight: formatDate(locale, payable.creationDate),
  };
};

const formatMemberCounterpartyRow = (
  payable: Payable,
  translator: TGlobalFunctionTyped,
  locale: string,
) => {
  const { counterparty } = payable;

  if (!counterparty || counterparty?.type === 'supplier') {
    return;
  }

  const memberName = formatMemberName(payable.member, translator);
  const supplierName = payable.supplier?.name;
  const distance = payable.mileageDetails?.distance;
  const tripDuration = payable.perDiem?.duration;

  return {
    id: payable.id,
    leftImage: (
      <Avatar
        variant="circle"
        text={counterparty.type === 'invitee' ? counterparty.email : memberName}
        src={counterparty.type !== 'invitee' ? counterparty.avatar : undefined}
        badgeProps={
          payable.supplier
            ? {
                variant: 'square',
                fallbackSrc: fallbackSupplierLogoSrc,
                src: payable.supplier.thumbnailUrl,
                text: payable.supplier.name ?? '',
              }
            : undefined
        }
      />
    ),
    firstLineLeft: formatMemberName(counterparty, translator),
    firstLineRight: formatMonetaryValue(payable.functionalAmount),
    firstLineRightSecondary: formatGrossAmount(
      payable.grossAmount,
      payable.functionalAmount,
    ),
    secondLineLeftItems: getSecondLineLeft({
      distance,
      payableDescription: payable.description,
      supplierName,
      translator,
      tripDuration,
    }),
    secondLineRight: formatDate(locale, payable.creationDate),
  };
};

const formatGrossAmount = (
  grossAmount: MonetaryValue,
  functionalAmount: MonetaryValue,
): string | undefined => {
  return grossAmount.currency !== functionalAmount.currency
    ? formatMonetaryValue(grossAmount)
    : undefined;
};

const formatDate = (locale: string, date: Date): string => {
  return dateFormatter(locale, date, DATE_FORMAT.MEDIUM);
};

const getSecondLineLeft = ({
  distance,
  payableDescription,
  supplierName,
  translator,
  tripDuration,
}: {
  distance?: string;
  payableDescription: string;
  supplierName?: string;
  translator: TGlobalFunctionTyped;
  tripDuration?: number;
}): [string, string] | [string] => {
  if (supplierName) {
    return [supplierName, payableDescription];
  }
  if (distance) {
    return [distance, payableDescription];
  }
  if (tripDuration) {
    return [
      translator('payables.panel.perDiemDuration', {
        count: tripDuration,
      }),
      payableDescription,
    ];
  }

  return [payableDescription];
};
