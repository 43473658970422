import { useUser } from 'modules/app/hooks/useUser';
import { type HubTask } from 'modules/onboarding/types/hubTask';
import { useQuery } from 'src/core/api/hooks/useQuery';

export const useSetupHubTasksQuery = () => {
  const user = useUser();

  return useQuery<HubTask[], HubTask[]>({
    key: ['onboarding-hub', 'tasks', 'control_settings'],
    isEnabled: user.is_admin || user.is_account_owner,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/onboarding-hub/tasks`,
      params: {
        category: 'control_settings',
      },
    },
    options: {
      cacheTime: 10 * 60 * 1000 /* 10min */,
      staleTime: 10 * 60 * 1000 /* 10min */,
    },
  });
};
