import { createAction, type ThunkDispatch } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import noop from 'lodash/noop';
import queryString from 'query-string';

import { companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import * as types from './actionTypes';
import { type Schedule, type ApiCard, type ApiCardSettings } from '../../card';

// Check & uncheck card(s)
export const checkCard = createAction(types.CHECK_CARD);
export const uncheckCard = createAction(types.UNCHECK_CARD);
export const checkAllCards = createAction(types.CHECK_ALL_CARDS);
export const uncheckAllCards = createAction(types.UNCHECK_ALL_CARDS);

// Local changes
export const removeCardLocally = createAction(types.REMOVE_CARD_LOCALLY);

// Apply changes to a batch of cards
export const updateCardsLocally = createAction(types.UPDATE_CARDS_LOCALLY);

// Nuke local state
export const nukeState = createAction(types.NUKE_CARDS_STATE);

export const fetchCardsLoading = createAction(
  types.FETCH_PLASTIC_CARDS_LOADING,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchCardsFailure = createAction<any>(
  types.FETCH_PLASTIC_CARDS_FAILURE,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchCardsSuccess = createAction<any>(
  types.FETCH_PLASTIC_CARDS_SUCCESS,
);
export const fetchCards =
  (filters: { status?: string[] } = {}) =>
  async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<AppState, void, any>,
    getState: () => AppState,
  ) => {
    dispatch(fetchCardsLoading());
    try {
      const companyId: string = getCompanyId(getState());
      const qs = queryString.stringify(filters, { arrayFormat: 'index' });
      const { data: cards } = await companyAPI.get(`/cards?${qs}`, {
        companyId,
      });
      if (!Array.isArray(cards)) {
        return dispatch(fetchCardsFailure({}));
      }
      dispatch(fetchCardsSuccess(cards));
      if (filters.status?.includes('REC')) {
        dispatch(checkAllCards());
      }
    } catch (error) {
      dispatch(fetchCardsFailure(error));
    }
  };

type UpdateCardSettingsPayload = Partial<{
  schedule: Schedule;
  deniedMerchantCategories: string[];
  authorisations: Partial<{
    isOnlineEnabled: boolean;
    isCashWithdrawalEnabled: boolean;
    isContactlessEnabled: boolean;
  }>;
}>;

export type UpdateCardPayload = {
  cardPayload: ApiCard;
  cardSettingsPayload?: UpdateCardSettingsPayload;
};

export type UpdateCardResult =
  | {
      outcome: 'success';
      updatedCard: ApiCard;
      updatedCardSettings: ApiCardSettings;
    }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { outcome: 'failure'; error: any };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateActiveCardSuccess = createAction<any>(
  types.UPDATE_PLASTIC_CARD_SUCCESS,
);

const updateCard = async ({
  cardId,
  cardPayload,
  companyId,
  previousCard,
}: {
  cardId: string;
  cardPayload: ApiCard;
  companyId: string;
  previousCard: ApiCard;
}): Promise<{ hasBeenUpdated: boolean; card: ApiCard }> => {
  let hasBeenUpdated = false;
  let card = previousCard;

  // update the card if needed
  // Note: for now we only update the card if the monthly budget has changed
  // FIXME: update this logic for others changes than the budget
  if (cardPayload.monthly_budget !== previousCard.monthly_budget) {
    const { data } = await companyAPI.put(`/cards/${cardId}`, cardPayload, {
      companyId,
    });
    hasBeenUpdated = true;
    card = data;
  }

  return { hasBeenUpdated, card };
};

const updateCardSettings = async ({
  cardId,
  companyId,
  cardSettingsPayload,
  previousCardSettings,
}: {
  cardId: string;
  companyId: string;
  cardSettingsPayload: UpdateCardSettingsPayload | undefined;
  previousCardSettings: ApiCardSettings;
}): Promise<{
  hasBeenUpdated: boolean;
  cardSettings: ApiCardSettings;
}> => {
  let hasBeenUpdated = false;
  let updatedCardSettings = previousCardSettings;

  const hasScheduleChanged = !isEqual(
    cardSettingsPayload && cardSettingsPayload.schedule,
    previousCardSettings && previousCardSettings.schedule,
  );

  const deniedMerchantCategories = cardSettingsPayload
    ? cardSettingsPayload.deniedMerchantCategories
    : [];
  const previousDeniedMerchantCategories =
    previousCardSettings && previousCardSettings.categories
      ? previousCardSettings.categories
          .filter((category) => !category.allowed)
          .map((category) => category.id)
      : [];
  const hasCategoriesChanged = !isEqual(
    deniedMerchantCategories,
    previousDeniedMerchantCategories,
  );

  const hasAuthorisationsChanged =
    previousCardSettings && cardSettingsPayload
      ? !isEqual(
          previousCardSettings.authorisations,
          cardSettingsPayload.authorisations,
        )
      : false;

  // update the card settings if needed
  if (hasScheduleChanged || hasCategoriesChanged || hasAuthorisationsChanged) {
    const { data } = await companyAPI.put(
      `/cards/${cardId}/control-settings`,
      cardSettingsPayload,
      {
        companyId,
      },
    );
    hasBeenUpdated = true;
    updatedCardSettings = data;
  }

  return { hasBeenUpdated, cardSettings: updatedCardSettings };
};

// This action allows to update all the details (control settings) of the active
// card (open in the right panel)
// TODO: we need to have a single API endpoint to update all the details at the same time
export const updateActiveCard =
  (
    {
      previousCard,
      previousCardSettings,
    }: { previousCard: ApiCard; previousCardSettings: ApiCardSettings },
    { cardPayload, cardSettingsPayload }: UpdateCardPayload,
  ) =>
  async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<AppState, void, any>,
    getState: () => AppState,
  ): Promise<UpdateCardResult> => {
    const state = getState();
    const companyId = getCompanyId(state);
    const cardId = previousCard.id;

    let updatedCard: ApiCard;
    let updatedCardSettings: ApiCardSettings;
    try {
      // we don't do these calls in parallel because the update on card settings
      // can have an impact on the result data of the card
      const updateCardSettingsResult = await updateCardSettings({
        cardId,
        companyId,
        cardSettingsPayload,
        previousCardSettings,
      });
      const updateCardResult = await updateCard({
        cardId,
        cardPayload,
        companyId,
        previousCard,
      });

      updatedCardSettings = updateCardSettingsResult.cardSettings;

      // if the card hasn't been updated but the card settings has been,
      // we refetch the card because its status may has been changed
      if (
        !updateCardResult.hasBeenUpdated &&
        updateCardSettingsResult.hasBeenUpdated
      ) {
        const { data: card } = await companyAPI.get(`/cards/${cardId}`, {
          companyId,
        });
        updatedCard = card;
      } else {
        updatedCard = updateCardResult.card;
      }
    } catch (error) {
      return { outcome: 'failure', error };
    }

    dispatch(updateActiveCardSuccess({ updatedCard, updatedCardSettings }));
    return { outcome: 'success', updatedCard, updatedCardSettings };
  };

export const pauseCardLoading = createAction(types.PAUSE_PLASTIC_CARD_LOADING);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pauseCardFailure = createAction<any>(
  types.PAUSE_PLASTIC_CARD_FAILURE,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pauseCardSuccess = createAction<any>(
  types.PAUSE_PLASTIC_CARD_SUCCESS,
);
export const pauseCard =
  (cardId: string, callback = noop) =>
  async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<AppState, void, any>,
    getState: () => AppState,
  ) => {
    if (!cardId) {
      return;
    }
    dispatch(pauseCardLoading());
    try {
      const companyId = getCompanyId(getState());
      const { data: card } = await companyAPI.put(
        `/cards/${cardId}/pause`,
        {},
        {
          companyId,
        },
      );
      if (!isObject(card)) {
        callback({});
        return dispatch(pauseCardFailure({}));
      }
      dispatch(pauseCardSuccess(card));
      callback(null, card);
    } catch (error) {
      dispatch(pauseCardFailure(error));
      callback(error);
    }
  };

export const unPauseCardLoading = createAction(
  types.UNPAUSE_PLASTIC_CARD_LOADING,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unPauseCardFailure = createAction<any>(
  types.UNPAUSE_PLASTIC_CARD_FAILURE,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unPauseCardSuccess = createAction<any>(
  types.UNPAUSE_PLASTIC_CARD_SUCCESS,
);
export const unPauseCard =
  (cardId: string, callback = noop) =>
  async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<AppState, void, any>,
    getState: () => AppState,
  ) => {
    if (!cardId) {
      return;
    }
    dispatch(unPauseCardLoading());
    try {
      const companyId = getCompanyId(getState());
      const { data: card } = await companyAPI.put(
        `/cards/${cardId}/unpause`,
        {},
        {
          companyId,
        },
      );
      if (!isObject(card)) {
        callback({});
        return dispatch(unPauseCardFailure({}));
      }
      dispatch(unPauseCardSuccess(card));
      callback(null, card);
    } catch (error) {
      dispatch(unPauseCardFailure(error));
      callback(error);
    }
  };

export const reloadCardLoading = createAction(
  types.RELOAD_PLASTIC_CARD_LOADING,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reloadCardFailure = createAction<any>(
  types.RELOAD_PLASTIC_CARD_FAILURE,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reloadCardSuccess = createAction<any>(
  types.RELOAD_PLASTIC_CARD_SUCCESS,
);
export const reloadCard =
  (cardId: string, callback = noop) =>
  async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<AppState, void, any>,
    getState: () => AppState,
  ) => {
    if (!cardId) {
      return;
    }
    dispatch(reloadCardLoading());
    try {
      const companyId = getCompanyId(getState());
      const { data: card } = await companyAPI.post(
        `/cards/${cardId}/reload`,
        {},
        {
          companyId,
        },
      );
      if (!isObject(card)) {
        callback({});
        return dispatch(reloadCardFailure({}));
      }
      dispatch(reloadCardSuccess(card));
      callback(null, card);
    } catch (error) {
      callback(error);
      dispatch(reloadCardFailure(error));
    }
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportAndDeleteCardSuccess = createAction<any>(
  types.DELETE_CARD_SUCCESS,
);
export const reportAndDeleteCard =
  (cardId: string, actionType: string, callback = noop) =>
  async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<AppState, void, any>,
    getState: () => AppState,
  ) => {
    if (!cardId) {
      return;
    }
    try {
      const companyId = getCompanyId(getState());
      const { data: card } = await companyAPI.put(
        `/cards/${cardId}/${actionType}`,
        {},
        {
          companyId,
        },
      );
      if (!isObject(card)) {
        callback({});
      }
      dispatch(reportAndDeleteCardSuccess(card));
      callback(null, card);
    } catch (error) {
      callback(error);
    }
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchHasWalletInsufficientFundsSuccess = createAction<any>(
  types.FETCH_HAS_WALLET_INSUFFICIENT_FUNDS_SUCCESS,
);
export const fetchHasWalletInsufficientFunds =
  () =>
  async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<AppState, void, any>,
    getState: () => AppState,
  ) => {
    const companyId: string = getCompanyId(getState());

    const { data } = await companyAPI.get('/wallet/warnings', {
      companyId,
    });
    dispatch(
      fetchHasWalletInsufficientFundsSuccess(
        data.walletHasInsuffientFundToCoverAllocatedAmount,
      ),
    );
  };
