/* eslint-disable camelcase */

import isEmpty from 'lodash/isEmpty';

import { type MemberEditFormikValues } from '../../../components/MemberEditModal/validate';
import { useUpdateMemberProfileMutation } from '../../../hooks/useUpdateMemberProfileMutation';
import { type Member } from '../../../models/member';

export const useEditUserMutation = (memberId: string) => {
  const [updateMemberProfile] = useUpdateMemberProfileMutation(memberId, false);
  return [
    async (member: Member, values: MemberEditFormikValues) => {
      const newValues: Parameters<typeof updateMemberProfile>[0] = {};

      // User update
      if (member.firstName !== values.firstName) {
        newValues.first_name = values.firstName;
      }
      if (member.lastName !== values.lastName) {
        newValues.last_name = values.lastName;
      }

      // Update cost center when
      // - we select a new one (`member.costCenter?.id !== values.costCenter?.id`)
      // - we select the suggested one (`member.costCenter?.selectionMode === 'suggested'`)
      // - we reset to "Automatic detection" (`values.costCenter === null`)
      if (
        values.costCenter?.id &&
        (member.costCenter?.id !== values.costCenter?.id ||
          member.costCenter?.selectionMode === 'suggested')
      ) {
        newValues.cost_center_id = values.costCenter.id;
      } else if (values.costCenter === null) {
        newValues.cost_center_id = null;
      }

      if (!isEmpty(newValues)) {
        await updateMemberProfile(newValues);
      }
    },
  ];
};
