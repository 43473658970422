import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type LeaderboardItem } from '../leaderboardItem';

export const useMissingReceiptsLeaderboardQuery = (): QueryState<
  LeaderboardItem[]
> => {
  return useQuery<LeaderboardItem[]>({
    key: 'dashboard-leaderboard',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/dashboard/leaderboard',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reshapeData: (data: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.leaderboard.map((item: any) => {
        return {
          nbPayments: item.nb_payments,
          sumPayments: item.sum_payments,
          user: {
            id: item.user.id,
            fullname: item.user.fullname,
            avatar: item.user.avatar,
            hasControlRule: item.user.has_control_rule,
          },
          nbPaymentsOverdue: item.nb_payments_overdue,
          sumPaymentsOverdue: item.sum_payments_overdue,
        };
      });
    },
  });
};
