import cx from 'classnames';
import React from 'react';

import styles from './ReceiptImage.module.css';

type Props = {
  isAnimating: boolean;
};

export const ReceiptImage = ({ isAnimating }: Props) => (
  <svg
    className={cx('receipt-svg', { [styles.animatedReceipt]: isAnimating })}
    width="234"
    height="263"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        x="-3.6%"
        y="-2.9%"
        width="107.2%"
        height="105.7%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="1.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g transform="rotate(-5 181.711 36.885)" fill="none">
      <rect
        fill="#CCCCD9"
        opacity=".4"
        transform="rotate(-7 109 125.417)"
        x="14"
        y="11.417"
        width="190"
        height="228"
        rx="2"
      />
      <rect
        fill="#D6EFFF"
        transform="rotate(7 109 125.417)"
        x="14"
        y="11.417"
        width="190"
        height="228"
        rx="2"
      />
      <use fill="#000" filter="url(#a)" />
      <rect
        stroke="#54BEFF"
        fill="#FFF"
        x="18"
        y="10.417"
        width="182"
        height="230"
        rx="2"
      />
      <rect
        className={styles.invoiceNumber}
        fill="#30186D"
        x="30.066"
        y="35.266"
        width="29"
        height="7"
        rx="2"
      />
      <path
        className={styles.supplierNameAndAddress}
        d="M30.295 68.805h25.963c.95 0 1.719.77 1.719 1.719v.162c0 .95-.77 1.719-1.719 1.719H30.295c-.949 0-1.718-.77-1.718-1.719v-.162c0-.95.77-1.72 1.718-1.72zm0 7.2h45.563c.95 0 1.719.77 1.719 1.719v.162c0 .95-.77 1.719-1.719 1.719H30.295c-.949 0-1.718-.77-1.718-1.719v-.162c0-.95.77-1.72 1.718-1.72zm0 7.2h30.863c.95 0 1.719.77 1.719 1.719v.162c0 .95-.77 1.719-1.719 1.719H30.295c-.949 0-1.718-.77-1.718-1.719v-.162c0-.95.77-1.72 1.718-1.72z"
        fill="#FECBBE"
      />
      <g className={styles.table} transform="translate(29.697 107.387)">
        <rect
          fill="#fff"
          x="4.35651515e-13"
          y="4.97379915e-14"
          width="159.955257"
          height="51.0714286"
          rx="1"
        />
        <rect
          fill="#4F4F66"
          opacity=".4"
          x="4.922"
          y="3.648"
          width="29.53"
          height="3.184"
          rx="1.592"
        />
        <rect
          fill="#4F4F66"
          opacity=".4"
          x="4.922"
          y="15.808"
          width="19.687"
          height="3.648"
          rx="1.719"
        />
        <rect
          fill="#4F4F66"
          opacity=".4"
          x="4.922"
          y="27.968"
          width="29.53"
          height="3.648"
          rx="1.719"
        />
        <rect
          fill="#4F4F66"
          opacity=".4"
          x="72.595"
          y="3.648"
          width="29.53"
          height="3.184"
          rx="1.592"
        />
        <rect
          fill="#4F4F66"
          opacity=".4"
          x="94.743"
          y="15.808"
          width="7.383"
          height="3.648"
          rx="1.719"
        />
        <rect
          fill="#4F4F66"
          opacity=".4"
          x="93.512"
          y="27.968"
          width="8.613"
          height="3.648"
          rx="1.719"
        />
        <rect
          fill="#4F4F66"
          opacity=".4"
          x="141.499"
          y="3.648"
          width="13.535"
          height="3.184"
          rx="1.592"
        />
        <rect
          fill="#4F4F66"
          opacity=".4"
          x="150.112"
          y="15.808"
          width="4.922"
          height="3.648"
          rx="1.719"
        />
        <rect
          fill="#4F4F66"
          opacity=".4"
          x="147.651"
          y="27.968"
          width="7.383"
          height="3.648"
          rx="1.719"
        />
        <rect
          stroke="#30186D"
          opacity=".4"
          x="-.5"
          y="-.5"
          width="160.955"
          height="52.071"
          rx="1"
        />
        <path d="M.615 11.552H159.34" stroke="#30186D" opacity=".4" />
        <path fill="#30186D" opacity=".1" d="M0 0v10.944h159.955V0z" />
        <path
          d="M.615 23.712H159.34M.615 35.872H159.34"
          stroke="#30186D"
          opacity=".4"
        />
      </g>
      <path
        className={styles.myNameAndAddress}
        fill="#92F5EC"
        d="M161.081449,38.6689597 L187.043949,38.6689597 C187.993188,38.6689597 188.762699,39.4384702 188.762699,40.3877097 L188.762699,40.5502097 C188.762699,41.4994491 187.993188,42.2689597 187.043949,42.2689597 L161.081449,42.2689597 C160.132209,42.2689597 159.362699,41.4994491 159.362699,40.5502097 L159.362699,40.3877097 C159.362699,39.4384702 160.132209,38.6689597 161.081449,38.6689597 Z M141.481449,45.8689597 L187.043949,45.8689597 C187.993188,45.8689597 188.762699,46.6384702 188.762699,47.5877097 L188.762699,47.7502097 C188.762699,48.6994491 187.993188,49.4689597 187.043949,49.4689597 L141.481449,49.4689597 C140.532209,49.4689597 139.762699,48.6994491 139.762699,47.7502097 L139.762699,47.5877097 C139.762699,46.6384702 140.532209,45.8689597 141.481449,45.8689597 Z M156.181449,53.0689597 L187.043949,53.0689597 C187.993188,53.0689597 188.762699,53.8384702 188.762699,54.7877097 L188.762699,54.9502097 C188.762699,55.8994491 187.993188,56.6689597 187.043949,56.6689597 L156.181449,56.6689597 C155.232209,56.6689597 154.462699,55.8994491 154.462699,54.9502097 L154.462699,54.7877097 C154.462699,53.8384702 155.232209,53.0689597 156.181449,53.0689597 Z"
      />
      <path
        className={styles.vat}
        fill="#9292A1"
        d="M180.853791,184.083074 L185.130576,184.083074 C186.079816,184.083074 186.849326,184.852584 186.849326,185.801824 L186.849326,185.964324 C186.849326,186.913563 186.079816,187.683074 185.130576,187.683074 L180.853791,187.683074 C179.904551,187.683074 179.135041,186.913563 179.135041,185.964324 L179.135041,185.801824 C179.135041,184.852584 179.904551,184.083074 180.853791,184.083074 Z M182.139505,191.283074 L185.130576,191.283074 C186.079816,191.283074 186.849326,192.052584 186.849326,193.001824 L186.849326,193.164324 C186.849326,194.113563 186.079816,194.883074 185.130576,194.883074 L182.139505,194.883074 C181.190265,194.883074 180.420755,194.113563 180.420755,193.164324 L180.420755,193.001824 C180.420755,192.052584 181.190265,191.283074 182.139505,191.283074 Z M160.568076,184.083074 L168.948758,184.083074 C169.897997,184.083074 170.667508,184.852584 170.667508,185.801824 L170.667508,185.964324 C170.667508,186.913563 169.897997,187.683074 168.948758,187.683074 L160.568076,187.683074 C159.618837,187.683074 158.849326,186.913563 158.849326,185.964324 L158.849326,185.801824 C158.849326,184.852584 159.618837,184.083074 160.568076,184.083074 Z M160.568076,191.283074 L165.403304,191.283074 C166.352543,191.283074 167.122054,192.052584 167.122054,193.001824 L167.122054,193.164324 C167.122054,194.113563 166.352543,194.883074 165.403304,194.883074 L160.568076,194.883074 C159.618837,194.883074 158.849326,194.113563 158.849326,193.164324 L158.849326,193.001824 C158.849326,192.052584 159.618837,191.283074 160.568076,191.283074 Z"
      />
      <path
        className={styles.total}
        fill="#595971"
        d="M180.384327,199.684236 L185.946827,199.684236 C186.896066,199.684236 187.665577,200.453747 187.665577,201.402986 L187.665577,201.565486 C187.665577,202.514726 186.896066,203.284236 185.946827,203.284236 L180.384327,203.284236 C179.435088,203.284236 178.665577,202.514726 178.665577,201.565486 L178.665577,201.402986 C178.665577,200.453747 179.435088,199.684236 180.384327,199.684236 Z M161.384327,199.684236 L170.946827,199.684236 C171.896066,199.684236 172.665577,200.453747 172.665577,201.402986 L172.665577,201.565486 C172.665577,202.514726 171.896066,203.284236 170.946827,203.284236 L161.384327,203.284236 C160.435088,203.284236 159.665577,202.514726 159.665577,201.565486 L159.665577,201.402986 C159.665577,200.453747 160.435088,199.684236 161.384327,199.684236 Z"
      />
    </g>
  </svg>
);
