import { useHistory } from 'react-router-dom';

import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';

import { type Integration } from '../types/integration';

export const useRedirectToHRIntegrationSettings = (): ((
  integration: Integration['id'],
) => void) => {
  const history = useHistory();
  const { id: companyId } = useCompany();

  return (integration: Integration['id']): void => {
    history.push(
      routeFor(routes.COMPANY_INTEGRATION.path, {
        company: companyId,
        integration,
      }),
    );
  };
};
