import { Icon, Popover } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { FixIntercomCookiesModal } from 'src/core/common/components/ContactUs/FixIntercomCookiesModal/FixIntercomCookiesModal';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import HelpTooltip from '../../HelpButton/HelpTooltip';
import { useQuestions } from '../../HelpButton/useQuestions';

export const HelpButton = () => {
  const { t } = useTranslation('global');
  const { questions, helpCenterIndex, title } = useQuestions();
  const [isFixIntercomCookiesModalOpen, setIsFixIntercomCookiesModalOpen] =
    useState(false);

  return (
    <li>
      <Popover
        placement="end-top"
        className="popover-hack-align w-full"
        renderTrigger={({ isDropdown: _, ...rest }) => {
          return (
            <button {...rest} className="navigation-item h-xl" type="button">
              <Icon
                name="question-mark"
                aria-hidden="true"
                className="shrink-0"
              />
              <span className="navigation-toggle-display grow">
                {t('misc.needHelp')}
              </span>
            </button>
          );
        }}
      >
        {(closeDropdown) => (
          <HelpTooltip
            title={title}
            questions={questions}
            helpCenterIndex={helpCenterIndex}
            openFixIntercomCookiesModal={() => {
              closeDropdown();
              setIsFixIntercomCookiesModalOpen(true);
            }}
          />
        )}
      </Popover>
      <FixIntercomCookiesModal
        isOpen={isFixIntercomCookiesModalOpen}
        onClose={() => setIsFixIntercomCookiesModalOpen(false)}
      />
    </li>
  );
};
