import { type TagVariant } from '@dev-spendesk/grapes';

import { type I18nKey } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type ConnectionStatus } from '../../integration/status';

export function getTagVariantFromConnection(
  status: ConnectionStatus,
): TagVariant {
  switch (status) {
    case 'connected':
    case 'noAuthorization':
      return 'success';
    case 'notConnected':
    case 'noIntegration':
    case 'switchInProgress':
    case 'fetchingTenants':
    case 'selectingTenant':
    case 'fetchingSubsidiaries':
    case 'selectingSubsidiaries':
      return 'neutral';
    case 'needServerUp':
      return 'warning';
    case 'connectionError':
    case 'subsidiariesError':
      return 'alert';
    default:
      return rejectUnexpectedValue('status', status);
  }
}

export function getTextFromConnection(status: ConnectionStatus): I18nKey {
  switch (status) {
    case 'connected':
      return 'bookkeep.integrations.settings.statusConnected';
    case 'notConnected':
      return 'bookkeep.integrations.settings.statusNotConnected';
    case 'fetchingTenants':
    case 'selectingTenant':
    case 'fetchingSubsidiaries':
    case 'selectingSubsidiaries':
      return 'bookkeep.integrations.settings.statusSelectingTenant';
    case 'subsidiariesError':
    case 'connectionError':
      return 'bookkeep.integrations.settings.statusConnectionError';
    case 'needServerUp':
      return 'bookkeep.integrations.settings.needServerUp';
    case 'noIntegration':
    case 'switchInProgress':
    case 'noAuthorization':
      return 'bookkeep.integrations.settings.statusConnectionError';
    default:
      return rejectUnexpectedValue('status', status);
  }
}
