import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type AssignControlRuleInput = {
  userId: string;
};

export const useAssignControlRuleMutation = (
  conrolRuleId?: string,
): MutationState<AssignControlRuleInput> => {
  return useMutation<AssignControlRuleInput>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `control-rules/${conrolRuleId}/assign`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['dashboard-leaderboard']);
      },
    },
  });
};
