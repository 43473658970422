import { useQueryClient } from 'react-query';

import type { CreditNoteId } from '@finance-review/models/credit-note';
import type { InvoiceRequest } from '@finance-review/models/invoice';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import { type QueryRequest } from 'src/core/api/query';

export type CheckReferenceInvoiceAvailabilityResponse = {
  isReferenceInvoiceAlreadyUsed: boolean;
};

type CheckReferenceInvoiceAvailabilityParams = {
  creditNoteId: CreditNoteId;
  invoiceRequestId: InvoiceRequest.EntityId;
};

const getCheckReferenceInvoiceAvailabilityRequestConfig = ({
  creditNoteId,
  invoiceRequestId,
}: CheckReferenceInvoiceAvailabilityParams): QueryRequest => ({
  type: 'rest',
  target: 'companyAPI',
  endpoint: `/credit_note_requests/${creditNoteId}/check_reference_invoice_availability`,
  params: { invoiceRequestId },
});

export const useCheckReferenceInvoiceAvailability = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const fetcher = (params: CheckReferenceInvoiceAvailabilityParams) =>
    getFetcher<{ isValid: true }>({
      companyId,
      getRequest: () => ({
        ...getCheckReferenceInvoiceAvailabilityRequestConfig(params),
        method: 'get',
      }),
    });

  return async (
    params: CheckReferenceInvoiceAvailabilityParams,
  ): Promise<CheckReferenceInvoiceAvailabilityResponse> => {
    const queryKey = [
      'credit_notes_requests',
      params.creditNoteId,
      'check_reference_invoice_availability',
      params.invoiceRequestId,
    ];

    try {
      await queryClient.fetchQuery(queryKey, fetcher(params), {
        cacheTime: 10 * 60 * 1000, // 10min
        staleTime: 10 * 60 * 1000, // 10min
      });
    } catch (error) {
      if (
        error.originalError?.response?.data?.error?.reason ===
        'referenceInvoiceAlreadyUsed'
      ) {
        return { isReferenceInvoiceAlreadyUsed: true };
      }
    }

    return { isReferenceInvoiceAlreadyUsed: false };
  };
};
