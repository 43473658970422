import {
  ModalBody,
  ModalContent,
  ModalHeaderWithIcon,
  ModalOverlay,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { useInvalidateCostCentersQuery } from 'modules/budgets/apis';
import { MemberInviteModal } from 'modules/members/components/MemberInviteModal';
import { routeFor, routes } from 'src/core/constants/routes';
import { EncourageIntegrationAdoptionCallout } from 'src/core/modules/members/components/EncourageIntegrationAdoptionCallout/EncourageIntegrationAdoptionCallout';

import {
  type SummaryContent,
  type InvitationResult,
} from '../../models/invite';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export const MemberInviteModalContainer = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const companyId = useCompanyId();

  const queryClient = useQueryClient();
  const [summaryContent, setSummaryContent] = useState<SummaryContent>({
    isDisplayed: false,
  });

  const invalidateCostCentersQuery = useInvalidateCostCentersQuery();

  const handleInviteCreationSuccess = (count?: number, email?: string) => {
    handleClose();
    let i18nKey = 'members.welcomeDashboardModal.notificationSuccess';
    if (!email) {
      i18nKey = 'members.welcomeDashboardModal.notificationSuccess_noEmail';
    }
    pushNotif({
      type: NotificationType.Success,
      message: (
        <Trans
          i18nKey={i18nKey}
          values={{
            email,
            count,
          }}
          components={[
            <Link
              key={companyId}
              className="text-white underline"
              to={routeFor(routes.COMPANY_MEMBERS.path, {
                company: companyId,
              })}
            />,
          ]}
        />
      ),
    });
  };

  const handleInviteCreationResult = (result: InvitationResult) => {
    if (result.isSuccessful) {
      handleInviteCreationSuccess(result.numInvited, result.email);
      setSummaryContent({ isDisplayed: false });
    } else {
      setSummaryContent({
        isDisplayed: true,
        type: result.type,
        messages: result.messages,
      });
    }
    invalidateQueries();
  };

  const invalidateQueries = () => {
    invalidateCostCentersQuery();
    queryClient.invalidateQueries(['users'], { exact: true });
    queryClient.invalidateQueries(['welcomeDashboard']);
  };

  const getModalTitle = (): string => {
    if (summaryContent.isDisplayed && summaryContent.type === 'email') {
      return t('members.invitationsSentCount', {
        count: summaryContent.messages.filter((message) => !message.isError)
          .length,
      });
    }
    if (summaryContent.isDisplayed && summaryContent.type === 'file_import') {
      return t('members.fileUploadErrors.title');
    }
    return t('members.inviteNewMembers');
  };

  const handleClose = () => {
    setSummaryContent({ isDisplayed: false });
    onClose();
  };

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContent onClose={handleClose}>
        <ModalHeaderWithIcon
          iconName={
            summaryContent.isDisplayed && summaryContent.type === 'file_import'
              ? 'cross'
              : 'team'
          }
          iconVariant={
            summaryContent.isDisplayed && summaryContent.type === 'file_import'
              ? 'alert'
              : 'primary'
          }
          title={getModalTitle()}
        />
        <ModalBody>
          <EncourageIntegrationAdoptionCallout />
          <MemberInviteModal
            summaryContent={summaryContent}
            hideSummary={() => setSummaryContent({ isDisplayed: false })}
            onClose={handleClose}
            handleInviteCreationResult={handleInviteCreationResult}
          />
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};
