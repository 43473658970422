import { useParams } from 'react-router-dom';

import { routes, routeFor } from 'src/core/constants/routes';

export const usePurchaseOrderRequestRoute = () => {
  const { company: companyId }: { company: string } = useParams();

  return (purchaseOrderRequestId: string) =>
    routeFor(routes.REQUESTS.path, {
      id: purchaseOrderRequestId,
      company: companyId,
      type: 'all',
    });
};
