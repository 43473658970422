// Invoice pattern update
export const UPDATE_INVOICE_PATTERN_LOADING = 'UPDATE_INVOICE_PATTERN_LOADING';
export const UPDATE_INVOICE_PATTERN_SUCCESS = 'UPDATE_INVOICE_PATTERN_SUCCESS';
export const UPDATE_INVOICE_PATTERN_FAILURE = 'UPDATE_INVOICE_PATTERN_FAILURE';

export const CREATE_CUSTOM_EXPORT_LOADING = 'CREATE_CUSTOM_EXPORT_LOADING';
export const CREATE_CUSTOM_EXPORT_SUCCESS = 'CREATE_CUSTOM_EXPORT_SUCCESS';
export const CREATE_CUSTOM_EXPORT_FAILURE = 'CREATE_CUSTOM_EXPORT_FAILURE';

export const DELETE_CUSTOM_EXPORT_LOADING = 'DELETE_CUSTOM_EXPORT_LOADING';
export const DELETE_CUSTOM_EXPORT_SUCCESS = 'DELETE_CUSTOM_EXPORT_SUCCESS';
export const DELETE_CUSTOM_EXPORT_FAILURE = 'DELETE_CUSTOM_EXPORT_FAILURE';

export const UPDATE_CUSTOM_EXPORT_LOADING = 'UPDATE_CUSTOM_EXPORT_LOADING';
export const UPDATE_CUSTOM_EXPORT_FAILURE = 'UPDATE_CUSTOM_EXPORT_FAILURE';
export const UPDATE_CUSTOM_EXPORT_SUCCESS = 'UPDATE_CUSTOM_EXPORT_SUCCESS';

// Bases
export const FETCH_BASES_LOADING = 'FETCH_BASES_LOADING';
export const FETCH_BASES_SUCCESS = 'FETCH_BASES_SUCCESS';
export const FETCH_BASES_FAILURE = 'FETCH_BASES_FAILURE';

// Toggle active export
export const TOGGLE_ACTIVE_EXPORT_LOADING = 'TOGGLE_ACTIVE_EXPORT_LOADING';
export const TOGGLE_ACTIVE_EXPORT_FAILURE = 'TOGGLE_ACTIVE_EXPORT_FAILURE';
export const TOGGLE_ACTIVE_EXPORT_SUCCESS = 'TOGGLE_ACTIVE_EXPORT_SUCCESS';
