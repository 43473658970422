import { EmptyState } from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';
import { useQueryClient } from 'react-query';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import { useDeleteDocumentaryEvidence } from 'modules/documentary-evidence/hooks/api';
import {
  MAX_NUMBER_OF_FILES,
  useUploadDocumentaryEvidence,
} from 'modules/documentary-evidence/hooks/api/useUploadDocumentaryEvidence';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { usePageViewer } from 'src/core/common/components/layout/SiderPageLayout/viewerContext';
import { isValidPDF, someFilesTypesNotSupported } from 'src/core/utils/files';
import { logger } from 'src/utils/datadog-log-wrapper';

import { getDocumentViewer } from './documentViewer';
import { getPayableQueryKey, usePayableQuery } from './hooks/usePayableQuery';
import {
  PayablePanel,
  PayablePanelLoader,
} from '../../components/PayablePanel';
import { useCanDeleteDocumentaryEvidence } from '../../components/PayablePanel/ReceiptSection';

import { type DocumentViewable } from '.';

type Props = {
  payableId: string;
  exportError?: ReactNode;
  panelActions?: ReactNode;
  onClose(): void;
  className?: string;
};

export const PayablePanelContainer = ({
  payableId,
  exportError,
  panelActions,
  onClose,
  className,
}: Props) => {
  const { t } = useTranslation('global');

  const queryClient = useQueryClient();

  const { dangerNotif } = useNotifications();

  const setViewerContent = usePageViewer({
    team: 'finance-accountant',
    scope: 'pageViewerContext::PayablePanelContainer',
  });

  const payableQueryState = usePayableQuery(payableId);
  const payable = unwrapQuery(payableQueryState);

  const [deleteDocumentaryEvidence] = useDeleteDocumentaryEvidence();

  const [uploadDocumentaryEvidence, { isUploading: isUploadingDocument }] =
    useUploadDocumentaryEvidence(payable?.id ?? '');

  const canDeleteDocumentaryEvidence = useCanDeleteDocumentaryEvidence(payable);

  if (payableQueryState.status !== 'success') {
    return <PayablePanelLoader className={className} />;
  }

  if (!payable) {
    return (
      <EmptyState
        title={t('misc.errors.requestErrors.notFound')}
        subtitle=""
        iconName="warning"
        iconVariant="warning"
        actions={[]}
      />
    );
  }

  const onDeletedDocumentaryEvidence = (document: DocumentViewable) =>
    canDeleteDocumentaryEvidence
      ? async () => {
          const result = await deleteDocumentaryEvidence({
            payableId,
            path: document.path,
          });

          if (result.outcome === 'notDeleted') {
            dangerNotif(t('misc.errors.unknownError'));
          }

          if (result.outcome === 'deleted') {
            setViewerContent(undefined);
            await queryClient.invalidateQueries(getPayableQueryKey(payableId));
          }
        }
      : undefined;

  const onUploadDocumentaryEvidence = async (files: File[] | null) => {
    if (!files) {
      return;
    }

    if (someFilesTypesNotSupported(files)) {
      dangerNotif(t('requests.panel.fileTypeNotSupported'));
      return;
    }

    for (const file of files) {
      if (file.type === 'application/pdf' && !(await isValidPDF(file))) {
        dangerNotif(t('misc.errors.requestErrors.invalidPDF'));
        return;
      }
    }

    if (files.length > MAX_NUMBER_OF_FILES) {
      dangerNotif(
        t('requests.panel.tooManyFiles', { max: MAX_NUMBER_OF_FILES }),
      );
      return;
    }

    try {
      await uploadDocumentaryEvidence({ files });
    } catch (error) {
      logger.error(error, {
        team: 'finance-accountant',
        scope: 'payables::panel::documentary-evidence::upload',
        error,
      });
      dangerNotif(t('misc.errors.unknownError'));
    }
  };

  return (
    <PayablePanel
      className={className}
      payable={payable}
      exportError={exportError}
      panelActions={panelActions}
      onDocumentClick={(document) => {
        setViewerContent(
          getDocumentViewer(
            document,
            () => setViewerContent(undefined),
            onDeletedDocumentaryEvidence(document),
          ),
        );
      }}
      onDocumentUpload={onUploadDocumentaryEvidence}
      isUploadingDocument={isUploadingDocument}
      onClose={onClose}
    />
  );
};
