import {
  Autocomplete,
  FormField,
  SkeletonText,
  SwitchField,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useDebouncedState } from 'common/hooks/useDebounceState';
import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import {
  type DefaultEmployeeAccount,
  type EmployeeAccount,
  type EmployeeAccountCode,
  getDefaultEmployeeAccountName,
} from 'modules/bookkeep/settings/accounting';
import { type InfiniteQueryState } from 'src/core/api/queryState';
import { getCodeWithAuxiliaryAccounts } from 'src/core/utils/accountPayable';

import { IndividualEmployees } from './IndividualEmployees';
import { useGetDefaultEmployeeAccountQuery } from '../../../../../hooks/useGetDefaultEmployeeAccountQuery';
import { useGetEmployeeAccountCodesQuery } from '../../../../../hooks/useGetEmployeeAccountCodesQuery';
import { useSetDefaultEmployeeAccountMutation } from '../../../../../hooks/useSetDefaultEmployeeAccountMutation';
import styles from '../EmployeeAccountsSection.module.css';
import { getDefaultEmployeeAccountPlaceholderI18nKey } from '../translations';

interface Props {
  integrationStatus: IntegrationStatusWithIntegration;
  employeeAccountsQueryState: InfiniteQueryState<EmployeeAccount[]>;
}

type AutocompleteOption = { key: string; label: string };

type SectionProps = {
  savedDefaultEmployee: DefaultEmployeeAccount | undefined;
  integrationStatus: IntegrationStatusWithIntegration;
  t: TGlobalFunctionTyped;
};

export function EmployeeAccountsPullWithDefaultAccountsSection({
  integrationStatus,
  employeeAccountsQueryState,
}: Props) {
  const { t } = useTranslation('global');

  const getDefaultEmployeeAccountQueryState =
    useGetDefaultEmployeeAccountQuery();

  return (
    <div data-testid="employee-accounts-section">
      <div className={styles.header}>
        <div className={styles.headerText}>
          <h3 className="IntegrationAccountingPage__section-title title-xl">
            {t('bookkeep.integrations.settings.sectionDefaultEmployeeAccount')}
          </h3>
          <p className={classNames(styles.headerTextDescription, 'body-m')}>
            {t(
              'bookkeep.integrations.settings.defaultEmployeeAccountsDescription',
            )}
            &nbsp;|&nbsp;
            <a
              href="https://helpcenter.spendesk.com/en/articles/6202577-default-accounting-values"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('misc.helpCenterArticle')}
            </a>
          </p>
        </div>
      </div>
      <QuerySuspense
        queryState={getDefaultEmployeeAccountQueryState}
        loading={<SkeletonText size="xxl" key="default_employee_loading" />}
        fallback={(error) => (
          <QueryError queryError={error} componentType="Callout" />
        )}
      >
        {(defaultAccount: DefaultEmployeeAccount | undefined) => (
          <DefaultEmployeeToggle
            savedDefaultEmployee={defaultAccount}
            integrationStatus={integrationStatus}
            t={t}
          />
        )}
      </QuerySuspense>

      <IndividualEmployees
        integrationStatus={integrationStatus}
        employeeAccountsQueryState={employeeAccountsQueryState}
      />
    </div>
  );
}

const DefaultEmployeeToggle = ({
  savedDefaultEmployee,
  integrationStatus,
  t,
}: SectionProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!savedDefaultEmployee);
  const [setDefaultEmployeeAccount] = useSetDefaultEmployeeAccountMutation();

  useEffect(() => {
    if (savedDefaultEmployee && !isChecked) {
      setDefaultEmployeeAccount({
        ...savedDefaultEmployee,
        isArchived: true,
      });
    }
  }, [isChecked, savedDefaultEmployee]);

  return (
    <div className="box mt-m">
      <SwitchField
        id="useDefaultAccount_employee"
        name="useDefaultAccount_employee"
        label={t(
          'bookkeep.integrations.settings.defaultEmployeeAccountForm.title',
        )}
        helpText={t(
          'bookkeep.integrations.settings.defaultEmployeeAccountForm.description',
        )}
        fit="parent"
        isChecked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
      {isChecked && (
        <DefaultEmployeeSelect
          savedDefaultEmployee={savedDefaultEmployee}
          integrationStatus={integrationStatus}
          t={t}
        />
      )}
    </div>
  );
};

const DefaultEmployeeSelect = ({
  savedDefaultEmployee,
  integrationStatus,
  t,
}: SectionProps) => {
  const [selectedOption, setSelectedOption] = useState<
    AutocompleteOption | undefined
  >(
    savedDefaultEmployee
      ? {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          key: savedDefaultEmployee.id!,
          label: getCodeWithAuxiliaryAccounts(savedDefaultEmployee),
        }
      : undefined,
  );
  const [search, setSearch] = useDebouncedState<string>('', 300);
  const getEmployeeAccountsQueryState = useGetEmployeeAccountCodesQuery({
    includeArchived: false,
    search,
  });

  const { pushNotif } = useNotifications();

  const options =
    getEmployeeAccountsQueryState.status === 'success'
      ? getEmployeeAccountsQueryState.data.map(
          (employeeAccount: EmployeeAccountCode) => ({
            key: employeeAccount.id,
            label: getCodeWithAuxiliaryAccounts(employeeAccount),
          }),
        )
      : [];

  const [setDefaultEmployeeAccount] = useSetDefaultEmployeeAccountMutation();
  const onSelect = async (employeeAccount: AutocompleteOption) => {
    if (!employeeAccount || employeeAccount === selectedOption) {
      return;
    }
    try {
      if (getEmployeeAccountsQueryState.status === 'success') {
        const existingAccount = getEmployeeAccountsQueryState.data.find(
          (account) => account.id === employeeAccount.key,
        );
        if (existingAccount) {
          await setDefaultEmployeeAccount({
            ...existingAccount,
            // TODO: We might need the name from the graphql Query.
            name: getDefaultEmployeeAccountName(
              integrationStatus.accountingCountry,
            ),
            isArchived: false,
          });
          setSelectedOption(employeeAccount);
          pushNotif({
            type: NotificationType.Success,
            message: t(
              'bookkeep.integrations.settings.employeeAccountsTable.editDefaultAccountSuccess',
            ),
          });
        }
      }
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t(
          'bookkeep.integrations.settings.employeeAccountsTable.editDefaultAccountFailure',
        ),
      });
    }
  };

  return (
    <FormField
      label={t(
        'bookkeep.integrations.settings.defaultEmployeeAccountForm.name',
      )}
      className={styles.defaultForm__select}
    >
      <Autocomplete
        fit="parent"
        placeholder={t(
          getDefaultEmployeeAccountPlaceholderI18nKey(
            integrationStatus.integration,
          ),
        )}
        isLoading={getEmployeeAccountsQueryState.status === 'loading'}
        value={selectedOption}
        options={options}
        onSearch={(keyword) => setSearch(keyword ?? '')}
        onSelect={onSelect}
        renderNoOptions={(rawValue) => (
          <AutocompleteNoOptions value={rawValue} />
        )}
      />
    </FormField>
  );
};
