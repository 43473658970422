import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';
import {
  getIsFeatureEnabled,
  getUserCostCenter,
} from 'src/core/selectors/globalSelectors';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

export const sendCostCenterRequestSubmissionEvent = (
  state: AppState,
  requestId?: string | null,
  selectedCostCenterId?: string | null,
): void => {
  const hasCostCenterFeature = getIsFeatureEnabled(
    state,
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  if (hasCostCenterFeature && requestId && selectedCostCenterId) {
    const userCostCenter = getUserCostCenter(state);
    const payload = {
      requestId,
      selectedCostCenterId,
      suggestedCostCenterId: userCostCenter?.id,
      selectionMode: userCostCenter?.selectionMode,
    };
    track(AnalyticEventName.REQUESTS_CREATED_COST_CENTER_SUGGESTED, payload);
  }
};
