import { gql } from 'graphql-tag';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type PaymentToComplete } from '../models/paymentToComplete';

const reshapeType = (type: string): PaymentToComplete['type'] => {
  switch (type) {
    case 'single_purchase_card':
      return 'singlePurchaseCard';
    case 'subscription_card':
      return 'subscriptionCard';
    case 'plastic_card':
      return 'physicalCard';
    default:
      return 'physicalCard'; // otherwise we fallback on physical card payment
  }
};

type PaymentToCompleteRaw = {
  databaseId: string;
  type: string;
  description: string;
  amount_declared_float: number;
  currency_declared: string;
  amount_billed_float: number;
  fx_fee_amount_float: number;
  supplier: {
    name: string;
    logo_url: string;
  };
  transaction: {
    description: string;
  };
  completionDeadline: {
    status: string;
    daysLeft: number;
  };
  paid_at: string;
  created_at: string;
};

export const usePaymentsToCompleteQuery = ({
  companyCurrency,
}: {
  companyCurrency: string;
}): QueryState<PaymentToComplete[]> => {
  return useQuery<PaymentToComplete[]>({
    key: 'paymentsToComplete',
    request: {
      type: 'graphQL',
      target: 'v1',
      query: gql`
        query PaymentsToComplete($companyId: String!) {
          viewer {
            payments(
              company_id: $companyId
              filters: [ToComplete]
              first: 100
            ) {
              pageInfo {
                hasNextPage
                endCursor
              }
              edges {
                node {
                  databaseId
                  type
                  description
                  amount_declared_float
                  currency_declared
                  amount_billed_float
                  fx_fee_amount_float
                  supplier {
                    name
                    logo_url
                  }
                  transaction {
                    description
                  }
                  completionDeadline {
                    status
                    daysLeft
                  }
                  paid_at
                  created_at
                }
              }
            }
          }
        }
      `,
    },
    reshapeData: (data) => {
      const edges = data?.viewer?.payments?.edges ?? [];
      return edges.map(({ node }: { node: PaymentToCompleteRaw }) => {
        return {
          id: node.databaseId,
          type: reshapeType(node.type),
          description: node.description,
          transactionDescription: node.transaction?.description,
          amountDeclared: node.amount_declared_float,
          currencyDeclared: node.currency_declared,
          amountBilled: node.amount_billed_float,
          currencyBilled: companyCurrency,
          fxFeeAmount: node.fx_fee_amount_float,
          supplier: node.supplier
            ? {
                name: node.supplier.name,
                logoUrl: node.supplier.logo_url,
              }
            : null,
          completionDeadline: node.completionDeadline
            ? {
                status: node.completionDeadline.status,
                daysLeft: node.completionDeadline.daysLeft,
              }
            : null,
          paidAt: node.paid_at,
          createdAt: node.created_at,
        };
      });
    },
  });
};
