import {
  Avatar,
  DATE_FORMAT,
  Icon,
  Tooltip,
  colors,
} from '@dev-spendesk/grapes';

import {
  fallbackSupplierLogoSrc,
  getLogoUrlFromName,
} from 'common/components/SupplierLogo';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import { useParams } from 'src/core/common/hooks/useParams';
import { routes } from 'src/core/constants/routes';
import {
  getAmountToDisplay,
  paymentTypeIconFromRequest,
} from 'src/core/utils/entities/request';
import { formatMoney } from 'src/core/utils/money';

import { type Request } from './RequestsListBox';
import { getRequestDistanceFormatted } from '../../../models/mileageRequest';
import { RequestStateTag } from '../RequestStateTag';

type Props = {
  request: Request;
  titleId: string;
};

const renderRequestIcon = (request: Request, t: TGlobalFunctionTyped) => {
  if (
    ![
      'single_purchase',
      'subscription',
      'subscription_increase',
      'card_load',
      'plastic_card',
    ].includes(request.type)
  ) {
    return null;
  }
  const icon = paymentTypeIconFromRequest({
    request,
  });
  if (!icon) {
    return null;
  }

  return (
    <Tooltip triggerAsChild content={t(`requests.types.${request.type}`)}>
      <Icon
        className="ml-xs mt-xxs shrink-0"
        name={icon}
        color={colors.neutralLight}
      />
    </Tooltip>
  );
};

export const RequestListRow = ({ request, titleId }: Props) => {
  const { localeFormat, t } = useTranslation('global');
  const { type } = useParams(routes.REQUESTS.path);
  return (
    <div className="flex min-w-0 items-center gap-s py-xxs">
      {request.supplier ? (
        <Avatar
          variant="square"
          text={request.supplier.name}
          src={getLogoUrlFromName(request.supplier.name)}
          fallbackSrc={fallbackSupplierLogoSrc}
          badgeProps={
            type !== 'mine'
              ? {
                  text: request.user?.fullname ?? request.user?.email ?? '',
                  src: request.user?.avatar ?? '',
                }
              : undefined
          }
          className="shrink-0"
          lazyLoad
        />
      ) : (
        <Avatar
          text={request.user?.fullname ?? request.user?.email ?? ''}
          src={request.user?.avatar ?? ''}
          className="shrink-0"
          lazyLoad
        />
      )}
      <div className="min-w-0 flex-grow">
        <div
          className="flex items-start gap-xs text-complementary body-m"
          id={titleId}
        >
          <EllipsisTooltip
            className="max-w-[50%] shrink-0"
            text={
              request.supplier?.name ??
              request.user?.fullname ??
              request.user?.email ??
              ''
            }
          />
          {request.cost_center && (
            <>
              <span>•</span>
              <EllipsisTooltip text={request.cost_center?.name} />
            </>
          )}
          <RequestStateTag request={request} />
        </div>
        <div className="flex items-center gap-xs text-neutral-dark body-s">
          {request.type !== 'mileage_allowance' &&
            request.type !== 'per_diem_allowance' &&
            type !== 'mine' && (
              <EllipsisTooltip
                className="max-w-[70%] shrink-0 title-s"
                text={request.user?.fullname ?? request.user?.email ?? ''}
              />
            )}
          {request.type === 'per_diem_allowance' && (
            <EllipsisTooltip
              className="max-w-[70%] shrink-0 title-s"
              text={t('requests.types.per_diem_allowance')}
            />
          )}
          {request.type === 'mileage_allowance' && (
            <EllipsisTooltip
              className="max-w-[70%] shrink-0 title-s"
              text={getRequestDistanceFormatted({ mileageRequest: request })}
            />
          )}
          {type !== 'mine' && <span>•</span>}
          <EllipsisTooltip text={request.description} />
          {renderRequestIcon(request, t)}
        </div>
      </div>
      <div className="shrink-0 text-right">
        <div className="text-complementary title-m">
          {formatMoney(getAmountToDisplay(request), request.currency_declared)}
        </div>
        <div className="text-neutral-dark body-s">
          {localeFormat(new Date(request.created_at), DATE_FORMAT.MEDIUM)}
        </div>
      </div>
    </div>
  );
};
