import React from 'react';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useModal as useModalGrapes } from 'common/hooks/useModalGrapes';
import { useTranslation } from 'common/hooks/useTranslation';
import { type Language } from 'src/core/config/i18n';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { useEnrichedCostCentersQuery } from './hooks/useEnrichedCostCentersQuery';
import { useMembersQuery } from './hooks/useMembersQuery';
import { CostCenterCreationMethodSelectionModal } from '../../components/CostCenterCreationMethodSelectionModal/CostCenterCreationMethodSelectionModal';
import {
  CostCenterImportModalContainer,
  type ImportType,
} from '../../components/CostCenterImportModal/CostCenterImportModalContainer';
import { CostCenterPageLoader } from '../../components/CostCenterPageLoader';
import { CostCenterSetupGuideModal } from '../../components/CostCenterSetupGuideModal';
import { CostCentersPage } from '../../components/CostCentersPage';
import { type CostCenter, type CostCenterWithBudgets } from '../../costCenter';
import { type Member } from '../../member';
import {
  CostCenterCreationFormModalContainer,
  CostCenterEditionFormModalContainer,
  CostCenterDeletionModalContainer,
} from '../CostCenterFormModalContainer';

export const CostCentersPageContainer = () => {
  const membersQueryState = useMembersQuery();
  const { activeLanguage } = useTranslation('global');
  const enrichedCostCenterState = useEnrichedCostCentersQuery();
  const costCentersWithBudgets =
    enrichedCostCenterState.status === 'success'
      ? enrichedCostCenterState.data
      : [];

  const members =
    membersQueryState.status === 'success'
      ? sortMembers(membersQueryState.data, activeLanguage)
      : [];

  const [creationModal, showCreationModal] = useModalGrapes<{
    openedFromCreationSelectionModal?: boolean;
  }>(({ isOpen, onClose, openedFromCreationSelectionModal }) => (
    <CostCenterCreationFormModalContainer
      isOpen={isOpen}
      members={members}
      onComplete={() => onClose()}
      showBackButton={openedFromCreationSelectionModal}
      onCancel={() => {
        onClose();
        track(AnalyticEventName.APPROVALS_APPROVAL_FLOW_SETUP_CANCELLED);
        if (openedFromCreationSelectionModal) {
          showCreationMethodSelectionModal();
        }
      }}
    />
  ));

  const [editionModal, showEditionModal] = useModalGrapes<{
    costCenter?: CostCenter;
  }>(({ isOpen, onClose, costCenter }) => {
    if (!costCenter) {
      return <div />;
    } // TODO: find the way to show animation when passing the parameter
    return (
      <CostCenterEditionFormModalContainer
        isOpen={isOpen}
        costCenter={costCenter}
        members={members}
        onComplete={() => onClose()}
        onCancel={() => {
          onClose();
          track(AnalyticEventName.APPROVALS_APPROVAL_FLOW_SETUP_CANCELLED);
        }}
      />
    );
  });

  const [deletionModal, showDeletionModal] = useModalGrapes<{
    costCenter?: CostCenterWithBudgets;
  }>(({ isOpen, onClose, costCenter }) => {
    if (!costCenter) {
      return <div />;
    } // TODO: find the way to show animation when passing the parameter
    return (
      <CostCenterDeletionModalContainer
        isOpen={isOpen}
        costCenter={costCenter}
        onCloseModal={() => onClose()}
        onCancel={() => onClose()}
      />
    );
  });

  const hasCostCenters = costCentersWithBudgets.length > 0;

  const [importModal, showImportModal] = useModalGrapes<{
    openedFromCreationSelectionModal?: boolean;
    type: ImportType;
  }>(
    ({
      isOpen,
      onClose,
      type = 'create',
      openedFromCreationSelectionModal,
    }) => {
      return (
        <CostCenterImportModalContainer
          type={type}
          onExit={() => {
            onClose();
            if (openedFromCreationSelectionModal) {
              showCreationMethodSelectionModal();
            }
          }}
          onCancel={onClose}
          onSuccess={() => {
            onClose();
          }}
          isOpen={isOpen}
          showBackButton={openedFromCreationSelectionModal}
        />
      );
    },
  );

  const [
    creationMethodSelectionModal,
    showCreationMethodSelectionModal,
    hideCreationMethodSelectionModal,
  ] = useModalGrapes(({ isOpen, onClose }) => {
    return (
      <CostCenterCreationMethodSelectionModal
        isOpen={isOpen}
        onCancel={() => onClose()}
        onSelect={(method) => {
          hideCreationMethodSelectionModal();

          if (method === 'import') {
            showImportModal({
              type: hasCostCenters ? 'edit' : 'create',
              openedFromCreationSelectionModal: true,
            });
          } else {
            showCreationModal({ openedFromCreationSelectionModal: true });
          }
        }}
      />
    );
  });

  const [setupGuideModal, showSetupGuideModal, hideSetupGuideModal] =
    useModalGrapes<{ onDone?(): void }>(({ isOpen, onDone = () => null }) => (
      <CostCenterSetupGuideModal
        isOpen={isOpen}
        onCloseModal={() => hideSetupGuideModal()}
        onDone={() => {
          hideSetupGuideModal();
          onDone();
        }}
      />
    ));

  if (
    enrichedCostCenterState.status !== 'success' ||
    membersQueryState.status !== 'success'
  ) {
    return <CostCenterPageLoader />;
  }

  return (
    <ErrorBoundary
      context={{ team: 'finance-controller', scope: 'cost-centers' }}
    >
      <div className="page__container">
        <CostCentersPage
          members={members}
          costCenters={costCentersWithBudgets}
          onClickFirstCreate={() =>
            showSetupGuideModal({
              onDone: showCreationMethodSelectionModal,
            })
          }
          onClickCreate={() => {
            if (hasCostCenters) {
              return showCreationModal();
            }
            showSetupGuideModal({ onDone: showCreationModal });
          }}
          onClickMoreInfo={showSetupGuideModal}
          onClickEdit={(costCenter) => {
            showEditionModal({ costCenter });
          }}
          onClickDelete={(costCenter) => {
            showDeletionModal({ costCenter });
          }}
          onClickImport={() =>
            hasCostCenters
              ? showImportModal({ type: 'edit' })
              : showSetupGuideModal({
                  onDone: () => showImportModal({ type: 'create' }),
                })
          }
        />
        {creationModal}
        {editionModal}
        {deletionModal}
        {setupGuideModal}
        {creationMethodSelectionModal}
        {importModal}
      </div>
    </ErrorBoundary>
  );
};

const sortMembers = (members: Member[], activeLanguage: Language) => {
  return members.sort((m1, m2) => {
    return (
      m1.fullname.localeCompare(m2.fullname, activeLanguage, {
        sensitivity: 'base',
      }) ||
      m1.email.localeCompare(m2.email, activeLanguage, {
        sensitivity: 'base',
      })
    );
  });
};
