import { type Requester } from '@finance-review/models/invoice';

export type RawUser = {
  id: string;
  avatar: string;
  first_name: string;
  fullname: string;
  last_name: string;
  gender: string;
};

export const transformUser = (rawUser: RawUser): Requester.Entity => ({
  id: rawUser.id,
  avatar: rawUser.avatar,
  firstName: rawUser.first_name,
  fullName: rawUser.fullname,
  lastName: rawUser.last_name,
  gender: rawUser.gender,
});
