import { SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const IntegrationBenefitsSkeleton = () => {
  return (
    <div className="flex w-full flex-col gap-s">
      <SkeletonText size="m" className="flex" width="var(--sizing-s)" />
      <div className="flex flex-col gap-xs">
        <div className="flex flex-row gap-xs">
          <SkeletonText size="s" width="12px" className="self-center" />
          <SkeletonText size="m" width="50%" />
        </div>
        <div className="flex flex-row gap-xs">
          <SkeletonText size="s" width="12px" className="self-center" />
          <SkeletonText size="m" width="66%" />
        </div>
        <div className="flex flex-row gap-xs">
          <SkeletonText size="s" width="12px" className="self-center" />
          <SkeletonText size="m" width="25%" />
        </div>
        <div className="flex flex-row gap-xs">
          <SkeletonText size="s" width="12px" className="self-center" />
          <SkeletonText size="m" width="33%" />
        </div>
      </div>
    </div>
  );
};
