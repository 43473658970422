import { type CustomFieldPayload } from '../containers/customField';

export const convertValues = (
  values?: string,
): NonNullable<CustomFieldPayload['values']> => {
  if (!values) {
    return [];
  }
  return (values ?? '')
    .split('\n')
    .join(',')
    .split(',')
    .filter((value) => value.trim())
    .map((value) => ({
      value: value.trim(),
      isDefault: false,
      costCenterIds: [],
    }));
};
