import React from 'react';

import {
  EmptyStateError,
  EmptyStateLoading,
  lazyLoad,
} from 'src/core/common/components/LazyLoad';

export const Bookkeep = lazyLoad({
  loader: async () => ({
    default:
      // eslint-disable-next-line unicorn/no-await-expression-member
      (await import(/* webpackChunkName: "Bookkeep" */ './Bookkeep')).default,
  }),
  loading: <EmptyStateLoading />,
  fallback: (props) => <EmptyStateError {...props} />,
});
