export const MARK_ONBOARDING_POPUP_AS_SEEN =
  '@global/MARK_ONBOARDING_POPUP_AS_SEEN';

export type MarkOnboardingPopupAsSeen = Readonly<{
  type: typeof MARK_ONBOARDING_POPUP_AS_SEEN;
  payload: {
    key: 'showKybProcedureValidatedPopup' | 'showRequesterOnboardingPopup';
  };
}>;

export const DISMISS_WELCOME_DASHBOARD = '@global/DISMISS_WELCOME_DASHBOARD';

export type DismissWelcomeDashboard = Readonly<{
  type: typeof DISMISS_WELCOME_DASHBOARD;
}>;

export type GlobalActions = MarkOnboardingPopupAsSeen | DismissWelcomeDashboard;
