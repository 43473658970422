import {
  DropdownItem,
  DropdownMenu,
  IconButton,
  Tag,
  Banner,
  DATE_FORMAT,
} from '@dev-spendesk/grapes';
import { type ReactChild, useMemo } from 'react';

import { GrapesSectionHeader } from 'common/components/GrapesSectionHeader';
import { CenteredLayout } from 'common/components/layout';
import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { track, AnalyticEventName } from 'src/core/utils/analytics';

import Illustration from './hand-gear.svg';
import { BudgetOverviewButtonContainer } from '../../containers/BudgetOverviewButtonContainer';
import { type BudgetaryExercise } from '../../models/budgetaryExercise';
import type { CostCenter } from '../../models/costCenter';
import { type PeriodRange } from '../../models/period';
import { sortNamedItems } from '../../utils/sortNamedItems';
import { BudgetOverviewPlaceholder } from '../BudgetOverview';
import { BudgetPeriodSelector } from '../BudgetPeriodSelector';
import { ExportBudgetButton } from '../ExportBudgetButton';
import { NoCostCenter } from '../NoCostCenter';

type Props = {
  title: ReactChild;
  budgetaryExercise: BudgetaryExercise;
  costCenters: CostCenter[];
  selectedPeriodRange: PeriodRange;
  setSelectedPeriodRange: (range: PeriodRange) => void;
  onEditBudgetaryExerciseClick(): void;
  onDeleteBudgetaryExerciseClick(): void;
  onCreateBudgetExpenseCategoriesClick(): void;
  onEditBudgetExpenseCategoriesClick(): void;
};

export const BudgetaryExercisePage = ({
  title,
  budgetaryExercise,
  costCenters,
  selectedPeriodRange,
  setSelectedPeriodRange,
  onDeleteBudgetaryExerciseClick,
  onEditBudgetaryExerciseClick,
  onCreateBudgetExpenseCategoriesClick,
  onEditBudgetExpenseCategoriesClick,
}: Props) => {
  const { t, activeLanguage, localeFormat } = useTranslation('global');

  const { isAccountOwner, isController } = useUserRoles();
  const isAccountOwnerOrController = isController || isAccountOwner;

  const { budgets, startDate, endDate, periodicity } = budgetaryExercise;
  const isNotYearlyBudgetaryExercise = periodicity !== 'yearly';

  const hasBudgets = budgets.length !== 0;
  const hasCostCenters = costCenters.length !== 0;

  // Header

  const readableStartDate = localeFormat(startDate, DATE_FORMAT.SHORT);
  const readableEndDate = localeFormat(endDate, DATE_FORMAT.SHORT);

  // Rights

  const canCreateBudgets = isAccountOwnerOrController;
  const canEditBudgets = isAccountOwnerOrController;

  // Menu options

  const menuOptions = useMemo(() => {
    const options: {
      key: 'edit' | 'delete' | 'updateBudgets';
      hasSeparator: boolean;
      action: () => void;
    }[] = [
      {
        key: 'edit',
        hasSeparator: false,
        action: () => {
          track(
            AnalyticEventName.BUDGETS_BUDGETARY_EXERCISE_EDIT_BUTTON_CLICKED,
          );
          onEditBudgetaryExerciseClick();
        },
      },
      {
        key: 'delete',
        hasSeparator: false,
        action: () => {
          track(
            AnalyticEventName.BUDGETS_BUDGETARY_EXERCISE_DELETE_BUTTON_CLICKED,
          );
          onDeleteBudgetaryExerciseClick();
        },
      },
    ];

    if (hasBudgets && hasCostCenters) {
      options.unshift({
        key: 'updateBudgets',
        hasSeparator: true,
        action: () => onEditBudgetExpenseCategoriesClick(),
      });
    }

    return options;
  }, [hasBudgets, hasCostCenters]);

  // Budgets

  const configuredBudgets = sortNamedItems(
    budgets.filter((index) => !index.isUnavailable),
    activeLanguage,
  ).map((b) => (
    <div key={b.id} className="mb-m">
      <BudgetOverviewButtonContainer
        budget={b}
        periodRange={selectedPeriodRange}
        budgetaryExercisePeriodicity={budgetaryExercise.periodicity}
      />
    </div>
  ));

  const suggestedBudgets = sortNamedItems(
    costCenters.filter((cc) => !budgets.some((b) => b.costCenterId === cc.id)),
    activeLanguage,
  ).map((cc) => (
    <div key={cc.id} className="mb-m">
      <BudgetOverviewPlaceholder budgetName={cc.name} />
    </div>
  ));

  return (
    <CenteredLayout>
      <GrapesSectionHeader
        className="mt-xl"
        title={title}
        subtitle={
          <Tag
            variant="neutral"
            className="mt-xs"
          >{`${readableStartDate} - ${readableEndDate}`}</Tag>
        }
        leftContent={
          <div className="grid grid-flow-col gap-s">
            {isNotYearlyBudgetaryExercise && (
              <BudgetPeriodSelector
                maxRange={{
                  from: budgetaryExercise.startDate,
                  to: budgetaryExercise.endDate,
                }}
                range={selectedPeriodRange}
                onChange={(range: PeriodRange) => {
                  setSelectedPeriodRange(range);
                }}
              />
            )}
            <ExportBudgetButton budgetIds={budgets.map((b) => b.id)} />
            {canEditBudgets && (
              <DropdownMenu
                options={menuOptions.map((option) => ({
                  label: t(
                    `budget.budgetaryExercise.dropdown.${option.key}` as I18nKey,
                  ),
                  ...option,
                }))}
                placement="bottom-end"
                renderButton={(getToggleButtonProps) => {
                  return (
                    <IconButton
                      {...getToggleButtonProps()}
                      onClick={(event) => {
                        track(
                          AnalyticEventName.BUDGETS_BUDGETARY_EXERCISE_DROPDOWN_MENU_BUTTON_CLICKED,
                        );
                        getToggleButtonProps().onClick?.(event);
                      }}
                      iconName="dots"
                      variant="border"
                      aria-label={t('misc.openMenu')}
                    />
                  );
                }}
                renderOption={(option) => (
                  <>
                    <div className="w-[296px]">
                      <DropdownItem label={option.label} />
                    </div>
                    {option.hasSeparator && (
                      <div className="separator my-xxs" />
                    )}
                  </>
                )}
                onSelect={(option) => option.action()}
              />
            )}
          </div>
        }
      />
      {canCreateBudgets && hasCostCenters && !hasBudgets ? (
        <Banner
          actionText={t(
            'budget.budgetaryExercise.budgetExpenseCategoriesCallout.button',
          )}
          title={t(
            'budget.budgetaryExercise.budgetExpenseCategoriesCallout.title',
          )}
          onClick={onCreateBudgetExpenseCategoriesClick}
          illustration={
            <img
              width="107"
              height="87"
              alt=""
              src={Illustration}
              className="-ml-l"
            />
          }
          className="mb-m"
        >
          {t('budget.budgetaryExercise.budgetExpenseCategoriesCallout.text')}
        </Banner>
      ) : null}

      {canCreateBudgets && !hasCostCenters && !hasBudgets && <NoCostCenter />}

      {!canCreateBudgets &&
        !hasBudgets &&
        t('budget.budgetaryExercise.noDefinedBudgets')}

      <CenteredLayout columnCount={2}>
        {configuredBudgets.concat(canCreateBudgets ? suggestedBudgets : [])}
      </CenteredLayout>
    </CenteredLayout>
  );
};
