import { Table, type TableColumn } from '@dev-spendesk/grapes';
import React, { useMemo } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { getReadableApprovalPolicy } from 'modules/company/members/components/ApprovalPolicies';

import { type Member } from '../../models/member';
import { type Policy } from '../../models/policy';

import './MembersApprovalPolicies.css';

export function MembersApprovalPolicies({
  policies,
  members,
  onPolicyClick,
}: {
  policies: Policy[];
  members: Member[];
  onPolicyClick: ({ id }: { id: string }) => void;
}) {
  const { t } = useTranslation('global');
  const company = useCompany();

  const membersCountByPolicyId = useMemo(() => {
    const countByPolicyId: Record<string, number> = {};
    for (const member of members) {
      const { policy } = member;
      if (policy) {
        if (!countByPolicyId[policy.id]) {
          countByPolicyId[policy.id] = 0;
        }
        countByPolicyId[policy.id]++;
      }
    }
    return countByPolicyId;
  }, [policies, members]);

  const columns: TableColumn<Policy>[] = [
    {
      header: t('members.approvalPolicies.name'),
      id: 'name',
      renderCell: (policy) => <>{policy.name}</>,
      getSortValue: (policy) => policy.name,
      width: '20%',
    },
    {
      header: t('members.approvalPolicies.membersCount'),
      id: 'membersCount',
      renderCell: (policy) => membersCountByPolicyId[policy.id] || 0,
      getSortValue: (policy) => policy.usersCount,
      width: '10%',
    },
    {
      header: t('members.approvalPolicies.details'),
      id: 'policyDetails',
      renderCell: (policy) => (
        <>
          {policy.params
            ? getReadableApprovalPolicy({
                translator: t,
                approvalNeeded: policy.params?.approvalNeeded,
                companyCurrency: company.currency,
                spendingLimit: policy.params?.amountPerMonth,
                transactionMax: policy.params?.amountPerTransaction,
              })
            : '-'}
        </>
      ),
      width: '70%',
    },
  ];

  return (
    <Table
      className="MembersApprovalPolicies"
      columns={columns}
      data={policies}
      onRowClick={(row) => onPolicyClick({ id: row.id })}
      emptyState={{
        title: t('members.approvalPolicies.noResults.title'),
        subtitle: t('members.approvalPolicies.noResults.subtitle'),
      }}
      defaultSortState={{
        columnId: 'name',
        direction: 'ascending',
      }}
      getRowId={(row) => row.id}
    />
  );
}
