/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useParams } from 'react-router-dom';

import { routes, routeFor } from "src/core/constants/routes";
import history from "src/core/history";

export const useGoToCompanyWalletFunding = () => {
  const companyWalletFundingRoute = useCompanyWalletFundingRoute();

  return () => {
    history.push(companyWalletFundingRoute);
  };
};

const useCompanyWalletFundingRoute = () => {
  const { company: companyId }: { company: string } = useParams();

  return routeFor(routes.COMPANY_BANK_FUNDING.path, {
    company: companyId!,
  });
};
