import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type SupplierBankInfos } from '../../../models';
import { BankInfoPreview } from '../BankInfoPreview';

type Props = {
  accountCode: SupplierBankInfos['accountCode'];
};

export const AccountCodePreview = ({ accountCode }: Props) => {
  const { t } = useTranslation('global');

  return (
    <BankInfoPreview
      primaryText={t('submitMyInvoice.fields.accountCode.label')}
      secondaryText={accountCode}
    />
  );
};
