import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type UIStateStep = {
  id: string;
  completed: boolean;
};

type Response = {
  data: {
    steps?: Record<string, UIStateStep>;
  };
};

type Payload = {
  domain: 'kyb' | 'migration';
};

export const useOpenUIStateMutation = (): MutationState<Payload, Response> => {
  return useMutation<Payload, Response, Response>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/ui-states',
      method: 'post',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
    },
  });
};
