import * as R from '@dev-spendesk/general-type-helpers/Result';
import { Button, Modal } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { useIntegrationStatusQuery } from 'modules/bookkeep';
import { useGetExpenseAccountsQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetExpenseAccountsQuery';
import { useGetSupplierAccountsQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSupplierAccountsQuery';
import { useSetExpenseAccountSupplierRuleMutation } from 'modules/bookkeep/accounts-payable/hooks/useSetExpenseAccountSupplierRuleMutation';
import { useSetSupplierAccountToSupplierMutation } from 'modules/bookkeep/accounts-payable/hooks/useSetSupplierAccountToSupplierMutation';
import { useUnsetExpenseAccountSupplierRuleMutation } from 'modules/bookkeep/accounts-payable/hooks/useUnsetExpenseAccountSupplierRuleMutation';
import {
  trackSupplierRuleCreated,
  trackSupplierRuleUpdated,
} from 'modules/bookkeep/analytics';
import { SupplierAccountAdvice } from 'modules/bookkeep/components/AccountAdvice';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { PanelItemsSection } from 'src/core/common/components/Panel';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';
import { useCreateOrUpdateAccountPayable } from 'src/core/modules/accounting-integration/apis/useCreateOrUpdateAccountPayable';

import {
  getCodeWithAuxiliaryAccounts,
  getGeneralAndAuxiliaryAccountCodes,
} from '../../../../../../utils/accountPayable';
import {
  AccountPayableCreationModal,
  type AccountPayableCreationModalState,
} from '../../../../components/AccountPayableCreationModal';
import { getCreateOrUpdateAccountPayableError } from '../../../../components/AccountPayableCreationModal/AccountPayableCreationModalState';
import { useHasAuxiliaryAccountsEnabled } from '../../../../hooks/useHasAuxiliaryAccountsEnabled';
import { useGetEmployeeAccountCodesQuery } from '../../../../settings/integrations/hooks/useGetEmployeeAccountCodesQuery';
import { type AccountingFormValues } from '../../../types';
import { AccountPayableSuppliersPanelAccountingForm } from '../AccountPayableSuppliersPanelAccountingForm';

type Props = {
  integrationStatus: IntegrationStatusWithIntegration;
  supplier: {
    id: string;
    supplierAccount: {
      id: string;
      generalAccountCode: string;
      auxiliaryAccountCode: string | undefined;
    } | null;
    expenseAccountSupplierRule: {
      expenseAccount: {
        name: string;
        id: string;
        isArchived: boolean;
      };
    } | null;
  };
  mustDisplayAccountsPayable: boolean;
};

const getErrorNotificationText = (errors: {
  accountPayable: boolean;
  expenseAccount: boolean;
}): string => {
  if (errors.accountPayable && errors.expenseAccount) {
    return 'bookkeep.accountsPayable.panel.accountingSection.errorNotificationBoth';
  }
  if (errors.accountPayable) {
    return 'bookkeep.accountsPayable.panel.accountingSection.errorNotificationAccountPayable';
  }
  return 'bookkeep.accountsPayable.panel.accountingSection.errorNotificationExpenseAccount';
};

export const AccountPayableSuppliersPanelAccountingSection = ({
  integrationStatus,
  supplier,
  mustDisplayAccountsPayable,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const { t } = useTranslation();
  const getExpenseAccountsQuery = useGetExpenseAccountsQuery();
  const getSupplierAccountsQuery = useGetSupplierAccountsQuery();
  const hasAutocatFeature = useFeature(FEATURES.AUTO_CAT);
  const accountingIntegrationQueryResult = useIntegrationStatusQuery();
  const getSupplierAccountsQueryState = useGetSupplierAccountsQuery({
    includeArchived: false,
  });
  const getEmployeeAccountCodesQuery = useGetEmployeeAccountCodesQuery();

  const { company } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const { pushNotif } = useNotifications();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [
    accountPayableCreationModalState,
    setAccountPayableCreationModalState,
  ] = useState<AccountPayableCreationModalState>({ kind: 'closed' });

  const [editingSupplier, setEditingSupplier] = useState(false);
  const [failedAccount, setFailedAccount] = useState<string | undefined>();

  const [setSupplierAccountToSupplier] =
    useSetSupplierAccountToSupplierMutation();
  const [setExpenseAccountSupplierRuleMutation] =
    useSetExpenseAccountSupplierRuleMutation();
  const [unsetExpenseAccountSupplierRuleMutation] =
    useUnsetExpenseAccountSupplierRuleMutation();

  const [createSupplierAccount] =
    useCreateOrUpdateAccountPayable('supplierAccount');

  const invalidSupplierAccounts =
    integrationStatus.settingsValidation.supplierAccounts
      .filter((error) => error.id && error.error === 'invalidAccount')
      .map((error) => error.id);

  const panelItemSectionError =
    supplier.supplierAccount?.id &&
    invalidSupplierAccounts.includes(supplier.supplierAccount?.id) ? (
      <SupplierAccountAdvice
        className="abc"
        integrationStatus={integrationStatus}
        showError
      />
    ) : null;

  const queryClient = useQueryClient();
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();

  const initialValues = {
    accountPayableId: supplier.supplierAccount?.id,
    expenseAccountId: supplier.expenseAccountSupplierRule?.expenseAccount.id,
  };

  const formikProps = useFormik<AccountingFormValues>({
    enableReinitialize: true,
    initialValues,
    validateOnChange: false,
    // eslint-disable-next-line sonarjs/cognitive-complexity
    onSubmit: async (values) => {
      const errors = {
        accountPayable: false,
        expenseAccount: false,
      };
      if (
        values.accountPayableId &&
        values.accountPayableId !== initialValues.accountPayableId
      ) {
        const setSupplierAccountMutationResult =
          await setSupplierAccountToSupplier({
            supplierId: supplier.id,
            supplierAccountId: values.accountPayableId,
          });
        if (
          setSupplierAccountMutationResult.setSupplierAccountToSupplier.reason
        ) {
          errors.accountPayable = true;
        }
      }

      if (values.expenseAccountId !== initialValues.expenseAccountId) {
        if (values.expenseAccountId === undefined) {
          const unsetExpenseAccountMutationResult =
            await unsetExpenseAccountSupplierRuleMutation({
              supplierId: supplier.id,
            });
          if (
            unsetExpenseAccountMutationResult.unsetExpenseAccountSupplierRule
              .reason
          ) {
            errors.expenseAccount = true;
          }
        } else {
          const setExpenseAccountMutationResult =
            await setExpenseAccountSupplierRuleMutation({
              supplierId: supplier.id,
              expenseAccountId: values.expenseAccountId,
            });
          if (
            setExpenseAccountMutationResult.setExpenseAccountSupplierRule.reason
          ) {
            errors.expenseAccount = true;
          }
        }

        if (!errors.expenseAccount) {
          if (!initialValues.expenseAccountId) {
            trackSupplierRuleCreated({
              supplierId: supplier.id,
              expenseAccountId: values.expenseAccountId,
              createdFrom: 'settings',
            });
          } else {
            trackSupplierRuleUpdated({
              supplierId: supplier.id,
              expenseAccountId: values.expenseAccountId,
              createdFrom: 'settings',
            });
          }
        }
      }

      if (Object.values(errors).includes(true)) {
        pushNotif({
          type: NotificationType.Danger,
          message: t(getErrorNotificationText(errors)),
        });
      }
      await Promise.all([
        queryClient.invalidateQueries(['useAccountPayableSuppliersListQuery']),
        queryClient.invalidateQueries([
          'useGetSuppliersWithoutAccountPayableListQuery',
        ]),
        queryClient.invalidateQueries([
          'suppliers',
          'suppliersDetails',
          supplier.id,
        ]),
      ]);

      if (values.accountPayableId !== initialValues.accountPayableId) {
        history.push({
          pathname: routeFor(
            routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path,
            {
              company,
              accountPayableId:
                values.accountPayableId ||
                initialValues.accountPayableId ||
                '-',
              supplierId: supplier.id,
            },
          ),
          search,
        });
      }
    },
  });

  const onCancelCreateAccountPayable = () => {
    formikProps.setFieldValue(
      'accountPayableId',
      initialValues.accountPayableId,
    );
  };

  const createAccountPayable = async (
    generalAccountCode: string,
    auxiliaryAccountCode: string | undefined,
  ) => {
    const accountCode = getCodeWithAuxiliaryAccounts({
      generalAccountCode,
      auxiliaryAccountCode,
    });

    const createSupplierAccountResult = await createSupplierAccount({
      generalAccountCode: auxiliaryAccountsEnabled
        ? generalAccountCode
        : accountCode,
      isArchived: false,
      ...(auxiliaryAccountsEnabled
        ? { auxiliaryAccountCode: auxiliaryAccountCode ?? undefined }
        : {}),
    });

    if (R.isFailure(createSupplierAccountResult)) {
      if (accountPayableCreationModalState.kind === 'form') {
        setAccountPayableCreationModalState({
          ...accountPayableCreationModalState,
          ...getCreateOrUpdateAccountPayableError(createSupplierAccountResult),
        });
      }

      if (
        accountingIntegrationQueryResult.status === 'success' &&
        // TODO@integrations understand why we need DATEV-specific behaviour here
        accountingIntegrationQueryResult.data.integration === 'Datev'
      ) {
        formikProps.setFieldError(
          'accountPayableId',
          createSupplierAccountResult.error.reason,
        );
        setFailedAccount(accountCode);
      }

      pushNotif({
        type: NotificationType.Danger,
        message:
          createSupplierAccountResult.error.reason === 'codeAlreadyExists'
            ? t(
                'bookkeep.accountsPayable.panel.accountingSection.addOptionErrorCodeAlreadyExistsNotification',
              )
            : t(
                'bookkeep.accountsPayable.panel.accountingSection.addOptionErrorNotification',
              ),
      });
      throw new Error(`Couldn't create new account payable`);
    }

    formikProps.setFieldTouched('accountPayableId');
    formikProps.setFieldValue(
      'accountPayableId',
      createSupplierAccountResult.value.accountPayableId,
      true,
    );

    return createSupplierAccountResult.value.accountPayableId;
  };

  const onAddOption = async (
    newOptionLabel: string,
  ): Promise<{ key: string; label: string }> => {
    const { generalAccountCode, auxiliaryAccountCode } =
      getGeneralAndAuxiliaryAccountCodes(newOptionLabel);
    if (auxiliaryAccountsEnabled) {
      setAccountPayableCreationModalState({
        kind: 'form',
        error: undefined,
        createdAccount: {
          generalAccountCode,
          auxiliaryAccountCode,
          kind: 'supplierAccount',
          id: '',
          isDefault: false,
          isArchived: false,
        },
      });

      return {
        key: '',
        label: newOptionLabel,
      };
    }
    const accountPayableId = await createAccountPayable(
      generalAccountCode,
      auxiliaryAccountCode,
    );

    return {
      key: accountPayableId ?? '',
      label: newOptionLabel,
    };
  };

  const handleSubmit = async () => {
    // this will trigger 2 form validations
    // it is due to a formik bug that doesn't reject the promise when calling submitForm and there are validation errors
    // https://github.com/formium/formik/issues/1580
    const { submitForm, validateForm } = formikProps;
    const errors = await validateForm();
    const isValid = Object.keys(errors).length === 0;
    if (!isValid) {
      setEditingSupplier(false);
      throw new Error('Validation error');
    }
    await submitForm();
    const result = await queryClient.invalidateQueries([
      'useAccountPayableSuppliersListQuery',
      'useIntegrationStatusQuery',
    ]);
    setEditingSupplier(false);
    return result;
  };

  return (
    <>
      <PanelItemsSection
        title={t('bookkeep.accountsPayable.panel.accountingSection.title')}
        data-testid="account-payable-section"
        items={[
          ...(mustDisplayAccountsPayable
            ? [
                {
                  label: t(
                    'bookkeep.accountsPayable.panel.accountingSection.accountPayableLabel',
                  ),
                  value:
                    (auxiliaryAccountsEnabled
                      ? getCodeWithAuxiliaryAccounts({
                          generalAccountCode:
                            supplier?.supplierAccount?.generalAccountCode ?? '',
                          auxiliaryAccountCode:
                            supplier?.supplierAccount?.auxiliaryAccountCode,
                        })
                      : supplier?.supplierAccount?.generalAccountCode) ?? '',
                  ...(panelItemSectionError &&
                  !(showConfirmationModal || editingSupplier)
                    ? { error: panelItemSectionError }
                    : {}),
                },
              ]
            : []),
          ...(hasAutocatFeature
            ? [
                {
                  label: t(
                    'bookkeep.accountsPayable.panel.accountingSection.expenseAccountLabel',
                  ),
                  value:
                    supplier.expenseAccountSupplierRule?.expenseAccount.name ||
                    '-',
                },
              ]
            : []),
        ]}
        isEditable
        cancelTranslation={t('misc.cancel')}
        saveTranslation={t('misc.saveChanges')}
        editSection={
          getExpenseAccountsQuery.status === 'success' &&
          getSupplierAccountsQuery.status === 'success' &&
          (accountingIntegrationQueryResult.status === 'success' &&
          accountingIntegrationQueryResult.data.integration !==
            'switchInProgress' &&
          accountingIntegrationQueryResult.data.integration !==
            'noIntegration' ? (
            <AccountPayableSuppliersPanelAccountingForm
              expenseAccounts={getExpenseAccountsQuery.data}
              accountsPayable={getSupplierAccountsQuery.data}
              mustDisplayAccountsPayable={mustDisplayAccountsPayable}
              onAddOption={onAddOption}
              integrationStatus={accountingIntegrationQueryResult.data}
              failedAccount={failedAccount}
              {...formikProps}
            />
          ) : null)
        }
        onCancel={() => {
          formikProps.resetForm();
        }}
        disableSave={!!formikProps.errors.accountPayableId}
        onSave={async () => {
          // since send back to prepare is available only on datev and BK2.0 for now, show this there
          // TODO@integrations understand why we need DATEV-specific behaviour here
          if (
            accountingIntegrationQueryResult.status === 'success' &&
            accountingIntegrationQueryResult.data.integration === 'Datev' &&
            initialValues.accountPayableId !==
              formikProps.values.accountPayableId
          ) {
            return setShowConfirmationModal(true);
          }
          setEditingSupplier(true);
          return handleSubmit();
        }}
      />
      <Modal
        title={t(
          'bookkeep.accountsPayable.panel.accountingSection.confirmChangeSupplierAccountTitle',
          // {
          //   codeFrom: defaultEmployeeAccount?.code,
          //   codeTo: newDefaultEmployeeAccount?.code,
          // },
        )}
        subtitle={t(
          'bookkeep.accountsPayable.panel.accountingSection.confirmChangeSupplierAccountSubTitle',
          // {
          //   code: defaultEmployeeAccount?.code,
          // },
        )}
        isOpen={showConfirmationModal}
        iconVariant="warning"
        iconName="pen"
        actions={[
          <Button
            key="cancel"
            onClick={() => setShowConfirmationModal(false)}
            text={t('misc.cancel')}
            variant="secondary"
          />,
          <Button
            key="yes"
            onClick={async () => {
              setEditingSupplier(true);
              handleSubmit();
              setShowConfirmationModal(false);
            }}
            text={t(
              'bookkeep.integrations.settings.expenseAccountsTable.confirmEdition',
            )}
            variant="warning"
          />,
        ]}
      />

      <AccountPayableCreationModal
        integrationStatus={integrationStatus}
        accountPayableCreationModalState={accountPayableCreationModalState}
        setAccountPayableCreationModalState={
          setAccountPayableCreationModalState
        }
        getSupplierAccountsQueryState={getSupplierAccountsQueryState}
        getEmployeeAccountCodesQuery={getEmployeeAccountCodesQuery}
        onClose={onCancelCreateAccountPayable}
        createAccountPayable={createAccountPayable}
      />
    </>
  );
};
