import { allPaymentsQuery, type QueryResult } from './query';
import { mergeStatsByMonth } from './stats';

type Variables = {
  first: number;
  after?: string;
};

// This is a workaround solution to fix a complex issue on the legacy GraphQL
// payments API. The related GraphQL resolver applies some filters after the
// query of the results. This leads, in some cases, to have fewer results
// ("edges") returned than the number requested ("first" variable).
// Fixing this issue in the backend is super complex and risky + this resolver
// should disappear in the short term with the usage of the GraphQL v2 API and
// the Payables All page.
// As a tradeoff, this function fetches again the API and the next pages until
// there are the expected number of results or no page anymore.
// Note that this situation only happens with the `LateReceipt` filter.
export function fetchAllPayments(
  graphqlFetcher: (query: string, variables: Variables) => Promise<QueryResult>,
  variables: Variables,
): Promise<QueryResult> {
  // eslint-disable-next-line promise/prefer-await-to-then
  return graphqlFetcher(allPaymentsQuery, variables).then(async (result) => {
    // if there is less results than expected and a next page, then trigger
    // the fetching of the next page and merge the results
    if (
      result.company.payments.edges.length < variables.first &&
      result.company.payments.pageInfo.hasNextPage
    ) {
      const nextResult = await fetchAllPayments(graphqlFetcher, {
        ...variables,
        after: result.company.payments.pageInfo.endCursor,
      });

      return {
        ...result,
        company: {
          ...result.company,
          payments: {
            ...result.company.payments,
            edges: [
              ...result.company.payments.edges,
              ...nextResult.company.payments.edges,
            ],
            stats_by_month: mergeStatsByMonth(
              result.company.payments.stats_by_month,
              nextResult.company.payments.stats_by_month,
            ),
            pageInfo: nextResult.company.payments.pageInfo,
          },
        },
      };
    }

    return result;
  });
}
