import {
  DATE_FORMAT,
  colors,
  Icon,
  type IconName,
  Tag,
  type TagVariant,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { SupplierLogo } from 'common/components/SupplierLogo';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { formatMoney } from 'src/core/utils/money';

import { type PaymentToComplete } from '../../../paymentToComplete';

import './PaymentsListRow.css';

type Props = {
  payment: PaymentToComplete & { routeLink: string };
};

const getIconFromPaymentType = (type: PaymentToComplete['type']): IconName => {
  switch (type) {
    case 'singlePurchaseCard':
      return 'single-purchase-card';
    case 'physicalCard':
      return 'plastic-card';
    case 'subscriptionCard':
      return 'recurring-card';
    default:
      throw new Error(`Payment type doesn't exist`);
  }
};

const getTagVariantFromCompletion = (status: string): TagVariant => {
  switch (status) {
    case 'late': {
      return 'alert';
    }
    case 'urgent': {
      return 'warning';
    }
    default:
      return 'info';
  }
};

const getLabelTextFromCompletion = (
  status: string,
  daysLeft: number,
  translator: TGlobalFunctionTyped,
) => {
  if (status === 'late') {
    return translator('homepage.paymentsList.row.overdue');
  }
  return translator('homepage.paymentsList.row.dueIn', { count: daysLeft });
};

export const PaymentsListRow = ({ payment }: Props) => {
  const { t, localeFormat } = useTranslation('global');

  return (
    <Link className="PaymentsListRow" to={payment.routeLink}>
      <div className="PaymentsListRow__hover">
        <div className="PaymentsListRow__hover__action title-m">
          {t('homepage.paymentsList.row.justify')}
        </div>
      </div>
      <div className="PaymentsListRow__details">
        <SupplierLogo
          name={payment.supplier?.name}
          className="PaymentsListRow__supplier-logo"
        />
        <div className="PaymentsListRow__details-content">
          <div
            className={cx('PaymentsListRow__supplier-name title-m', {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'PaymentsListRow__supplier-name--missing': !payment.supplier,
            })}
          >
            <span>
              {payment.supplier?.name ??
                t('homepage.paymentsList.row.missingSupplier')}
            </span>
            <Icon
              className="PaymentsListRow__type-icon"
              name={getIconFromPaymentType(payment.type)}
              color={colors.neutral}
            />
          </div>
          <div className="PaymentsListRow__description body-s">
            {payment.description ?? payment.transactionDescription}
          </div>
        </div>
      </div>

      <div className="PaymentsListRow__date body-m">
        {localeFormat(payment.createdAt, DATE_FORMAT.SHORT)}
      </div>
      <div className="PaymentsListRow__price body-m">
        <div className="PaymentsListRow__price__main">
          <span>
            {formatMoney(payment.amountBilled, payment.currencyBilled)}
          </span>
          {payment.amountDeclared !== payment.amountBilled && (
            <span className="PaymentsListRow__price__main__add">
              {' '}
              • {formatMoney(payment.amountDeclared, payment.currencyDeclared)}
            </span>
          )}
        </div>
        {payment.fxFeeAmount !== 0 && (
          <div className="PaymentsListRow__price__desc body-s">
            {t('homepage.paymentsList.row.feeAmount', {
              amount: formatMoney(payment.fxFeeAmount, payment.currencyBilled),
            })}
          </div>
        )}
      </div>
      <div className="PaymentsListRow__due">
        {payment.completionDeadline && (
          <Tag
            variant={getTagVariantFromCompletion(
              payment.completionDeadline.status,
            )}
          >
            {getLabelTextFromCompletion(
              payment.completionDeadline.status,
              payment.completionDeadline.daysLeft,
              t,
            )}
          </Tag>
        )}
      </div>
    </Link>
  );
};
