/* eslint-disable unicorn/no-await-expression-member */
import React from 'react';

import { EmptyStateError, lazyLoad } from 'src/core/common/components/LazyLoad';

import { AccountPayableSuppliersListLoader } from '../AccountPayableSuppliersList/AccountPayableSuppliersListLoader';

export const AccountPayableSuppliersPage = lazyLoad({
  loader: async () => ({
    default: (
      await import(
        /* webpackChunkName: "AccountPayableSuppliersPage" */ './AccountPayableSuppliersPage'
      )
    ).AccountPayableSuppliersPage,
  }),
  loading: (
    <div className="flex min-h-full flex-col bg-page-background px-m py-s">
      <AccountPayableSuppliersListLoader />
    </div>
  ),
  fallback: (props) => <EmptyStateError {...props} />,
});
