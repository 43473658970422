import {
  Button,
  Avatar,
  Icon,
  colors,
  Table,
  Tooltip,
  DATE_FORMAT,
} from '@dev-spendesk/grapes';
import { equal, type MonetaryValue } from 'ezmoney';
import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import {
  getLogoUrlFromName,
  fallbackSupplierLogoSrc,
} from 'common/components/SupplierLogo';
import {
  type LocaleFormat,
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { getSpendingType, type SpendingType } from '../../breakdownSpendings';
import {
  type BreakdownSpending,
  type NetAmountStatus,
} from '../../containers/BreakdownSpendingsSectionContainer/hooks/useBudgetBreakdownSpendingsQuery';
import type { BreakdownPart } from '../../models/breakdown';

type Props = {
  breakdownSpendings: BreakdownSpending[];
  spendingType: SpendingType;
  breakdownPart: BreakdownPart;
  hasNextPage: boolean;
  onFetchNextPage(): void;
};

export const BreakdownSpendingsSection = ({
  breakdownSpendings,
  spendingType,
  breakdownPart,
  hasNextPage,
  onFetchNextPage,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');

  if (!hasNextPage && breakdownSpendings.length === 0) {
    return null;
  }

  const breakdownSpendingsTableRows = breakdownSpendings.map(
    (breakdownSpending) =>
      formatBreakdownSpendingRow(
        breakdownSpending,
        breakdownPart,
        spendingType,
        t,
        localeFormat,
      ),
  );

  return (
    <>
      <h2 className="mb-s mt-m text-neutral-darker title-l">
        {getSpendingType(spendingType, t)}
      </h2>
      <Table
        data={breakdownSpendingsTableRows}
        columns={[
          {
            header: t('budget.breakdownSpendings.netAmounts.table.supplier'),
            id: 'supplierCell',
            width: 400,
            renderCell: (row) => row.supplierCell,
          },
          {
            header: t('budget.breakdownSpendings.netAmounts.table.date'),
            id: 'date',
            width: 144,
            renderCell: (row) => row.date,
          },
          {
            header: t('budget.breakdownSpendings.netAmounts.table.inclTaxes'),
            id: 'inclTaxes',
            width: 144,
            renderCell: (row) => row.inclTaxes,
          },
          {
            header: t('budget.breakdownSpendings.netAmounts.table.exclTaxes'),
            id: 'netAmount',
            align: 'right',
            width: 256,
            renderCell: (row) => row.exclTaxesCell,
          },
        ]}
        footer={
          hasNextPage ? (
            <Button
              variant="secondary"
              text={t('budget.breakdownSpendings.loadMore')}
              onClick={onFetchNextPage}
            />
          ) : undefined
        }
      />
    </>
  );
};

const formatBreakdownSpendingRow = (
  breakdownSpending: BreakdownSpending,
  breakdownPart: BreakdownPart,
  spendingType: SpendingType,
  t: TGlobalFunctionTyped,
  localeFormat: LocaleFormat,
): {
  supplierCell: React.ReactChild;
  date: string;
  inclTaxes: React.ReactChild;
  exclTaxesCell: React.ReactChild;
} => {
  const {
    amount,
    date,
    description,
    globalAmount,
    initialGlobalAmount,
    payer,
    supplier,
    isSplit,
    grossAmount,
    netAmountStatus,
  } = breakdownSpending;
  const isExpenseClaim = spendingType === 'expenseClaim';
  const payerFullname = `${payer.firstName} ${payer.lastName}`;
  const supplierName =
    supplier ?? t('budget.breakdownSpendings.missingSupplier');

  const isExpenseInDifferentCurrency = isExpenseInADifferentCurrency(
    globalAmount,
    initialGlobalAmount,
  );

  const netAmount = (
    <div className="flex max-w-[230px] items-center gap-xxs overflow-hidden">
      <EllipsisTooltip
        tooltipMaxWidth={500}
        text={
          <>
            {getSpendingAmount(amount, globalAmount, spendingType)}
            {isExpenseInDifferentCurrency && (
              <>
                <span className="body-m">
                  {' '}
                  (
                  {getSpendingAmount(
                    amount,
                    globalAmount,
                    spendingType,
                    initialGlobalAmount,
                  )}
                  )
                </span>
              </>
            )}
          </>
        }
      />
    </div>
  );

  return {
    supplierCell: (
      <div className="flex items-center gap-s">
        {isExpenseClaim ? (
          <Avatar
            variant="square"
            text={payerFullname}
            src={payer.avatar}
            fallbackSrc={fallbackSupplierLogoSrc}
          />
        ) : (
          <Avatar
            variant="square"
            text={supplierName}
            src={supplier ? getLogoUrlFromName(supplier) : undefined}
            fallbackSrc={fallbackSupplierLogoSrc}
          />
        )}
        <div>
          <div className="text-complementary body-m">
            {isExpenseClaim ? payerFullname : supplierName}
          </div>
          {description && (
            <div className="flex gap-xxs text-neutral-dark body-s">
              <div className="max-w-[200px]">
                <EllipsisTooltip text={description} />
              </div>
              {!isExpenseClaim && (
                <>
                  <div>•</div>
                  <div>{payerFullname}</div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    ),
    inclTaxes: formatMonetaryValue(grossAmount),
    exclTaxesCell: (
      <div className="flex items-center justify-end gap-xs">
        <div>{netAmount}</div>
        {isSplit && (
          <Tooltip
            triggerAsChild
            content={t(
              'budget.breakdownSpendings.netAmounts.table.splitTooltip',
              { amount: formatMonetaryValue(globalAmount) },
            )}
          >
            <Icon name="arrow-split" />
          </Tooltip>
        )}
        {getSpendingStatusIcon(netAmountStatus, breakdownPart, t, spendingType)}
      </div>
    ),
    date: localeFormat(new Date(date), DATE_FORMAT.MEDIUM),
  };
};

const getSpendingStatusIcon = (
  status: NetAmountStatus,
  breakdownPart: BreakdownPart,
  t: TGlobalFunctionTyped,
  spendingType: SpendingType,
): React.ReactChild => {
  switch (status) {
    case 'estimated':
      return (
        <Tooltip
          triggerAsChild
          content={
            spendingType === 'subscription' && breakdownPart === 'committed'
              ? t(
                  'budget.breakdownSpendings.netAmounts.table.estimatedSubscriptionTooltipText',
                )
              : t(
                  'budget.breakdownSpendings.netAmounts.table.estimatedTooltipText',
                )
          }
        >
          <Icon name="robot" color={colors.primary} />
        </Tooltip>
      );
    case 'unknown':
      return (
        <Tooltip
          triggerAsChild
          content={t(
            'budget.breakdownSpendings.netAmounts.table.unknownTooltipText',
          )}
        >
          <Icon name="help-circle" color={colors.neutral} />
        </Tooltip>
      );
    case 'verified':
      return (
        <Tooltip
          triggerAsChild
          content={t(
            'budget.breakdownSpendings.netAmounts.table.verifiedTooltipText',
          )}
        >
          <Icon name="success" color={colors.success} />
        </Tooltip>
      );
    case 'editedByController':
      return (
        <Tooltip
          triggerAsChild
          content={t(
            'budget.breakdownSpendings.netAmounts.table.editedByControllerTooltipText',
          )}
        >
          <Icon name="person" color={colors.primary} />
        </Tooltip>
      );
    case 'editedByRequester':
      return (
        <Tooltip
          triggerAsChild
          content={t(
            'budget.breakdownSpendings.netAmounts.table.editedByRequesterTooltipText',
          )}
        >
          <Icon name="person" color={colors.primary} />
        </Tooltip>
      );
    case 'editedManually':
      return (
        <Tooltip
          triggerAsChild
          content={t(
            'budget.breakdownSpendings.netAmounts.table.editedManuallyTooltipText',
          )}
        >
          <Icon name="success" color={colors.success} />
        </Tooltip>
      );
    default:
      rejectUnexpectedValue('Unknown spending status icon status', status);
  }
};

const getSpendingAmount = (
  amount: MonetaryValue,
  globalAmount: MonetaryValue,
  spendingType: SpendingType,
  initialGlobalAmount?: MonetaryValue,
): React.ReactNode => {
  const isExpenseInDifferentCurrency = isExpenseInADifferentCurrency(
    globalAmount,
    initialGlobalAmount,
  );

  if (
    (spendingType === 'invoice' || spendingType === 'purchaseOrder') &&
    !equal(amount, globalAmount) &&
    !isExpenseInDifferentCurrency // For design purpose, we don't want to display the splitted amount for expenses in another currency
  ) {
    return (
      <>
        <span>{formatMonetaryValue(amount)}</span>
        <span className="body-m ">
          {' / '}
          {formatMonetaryValue(globalAmount)}
        </span>
      </>
    );
  }

  return formatMonetaryValue(initialGlobalAmount || amount);
};

const isExpenseInADifferentCurrency = (
  globalAmount: MonetaryValue,
  initialGlobalAmount?: MonetaryValue,
): boolean => {
  return Boolean(
    initialGlobalAmount &&
      initialGlobalAmount.currency !== globalAmount.currency,
  );
};
