import { Button, ListBox, ModalFooter } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUsersCountLimitFeatures } from 'modules/members/hooks/useUsersCountLimitFeatures';

import styles from './MemberInviteSummary.module.css';
import {
  type InviteType,
  type InvitationResultMessage,
} from '../../models/invite';

type Props = {
  type: InviteType;
  messages: InvitationResultMessage[];
  remainingSeatsForUsersCount?: number;
  onBackToImport: () => void;
  onClose: () => void;
};

export const MemberInviteSummary = ({
  type,
  messages,
  remainingSeatsForUsersCount,
  onBackToImport,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');

  const hasUsersCountLimitFeature = useUsersCountLimitFeatures();

  const renderInvitationsList = () => {
    return (
      <>
        {messages.length > 0 && (
          <ListBox
            className={styles.invitationResults}
            rowHeight="compact"
            options={messages}
            getOptionVariant={(option) =>
              option.isError ? 'warning' : undefined
            }
            getOptionId={(option) => option.text}
          >
            {(option) => <div className={styles.messageRow}>{option.text}</div>}
          </ListBox>
        )}
      </>
    );
  };
  const canInviteMoreMembers =
    !hasUsersCountLimitFeature ||
    (remainingSeatsForUsersCount !== undefined &&
      remainingSeatsForUsersCount > 0);

  return (
    <>
      {renderInvitationsList()}
      <ModalFooter>
        <Button variant="secondary" text={t('misc.cancel')} onClick={onClose} />
        {type === 'file_import' && (
          <Button text={t('misc.tryAgain')} onClick={onBackToImport} />
        )}
        {type === 'email' && canInviteMoreMembers && (
          <Button
            text={t('members.inviteMoreMembers')}
            onClick={onBackToImport}
          />
        )}
      </ModalFooter>
    </>
  );
};
