import { Callout, CheckboxInput } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  mode: 'edit' | 'view';
  hasExpressedConsent: boolean;
  onConsentChange: () => void;
  formError?: string;
};

const ConsentForm = ({
  mode,
  hasExpressedConsent,
  onConsentChange,
  formError,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <>
      <div
        className="mt-m flex flex-row items-center bg-neutral-lightest p-s"
        data-testid="api-key-create-consent"
      >
        <CheckboxInput
          isChecked={hasExpressedConsent}
          onChange={onConsentChange}
          isDisabled={mode === 'view'}
        />{' '}
        <p className="ml-xs font-medium">{t('publicApi.scopes.consense')}</p>
      </div>
      {formError && (
        <Callout title={formError} variant="alert" className="mt-xs" />
      )}
    </>
  );
};

export default ConsentForm;
