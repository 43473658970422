import { DeprecatedPreview } from '@dev-spendesk/grapes';
import React from 'react';

type Props = {
  primaryText: string;
  secondaryText?: string;
};

export const BankInfoPreview = ({ primaryText, secondaryText }: Props) => {
  return (
    <DeprecatedPreview
      className="border-0 bg-neutral-lightest text-neutral-darker"
      primaryText={primaryText}
      secondaryText={secondaryText}
      fit="parent"
    />
  );
};
