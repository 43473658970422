import { type QueryClient } from 'src/core/api/client';
import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { type Filter, type PurchaseOrder } from '../models';

export interface RawPurchaseOrdersResponse {
  purchaseOrders: PurchaseOrder[];
  nextCursor: string;
}

type ApiParams = {
  status: string;
  cost_center_ids?: string[];
  supplier_ids?: string[];
  search?: string;
};

const purchaseOrdersPerPage = 20;

export const useFetchPurchaseOrdersQuery = (
  filter: Filter,
  perPage: number = purchaseOrdersPerPage,
): InfiniteQueryState<PurchaseOrder[]> => {
  return useInfiniteQuery<PurchaseOrder, RawPurchaseOrdersResponse>({
    key: ['purchaseOrders', { ...filter, perPage }],
    getRequest: (cursor) => ({
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/purchase-orders',
      params: {
        ...getParamsFromFilter(filter),
        cursor,
        limit: perPage,
      },
    }),
    getNextPageParam(data) {
      return data.nextCursor;
    },
    reshapeData: (data) => {
      return data.purchaseOrders;
    },
  });
};

export const invalidatePurchaseOrdersQueries = (
  queryClient: QueryClient,
): void => {
  queryClient.invalidateQueries('purchaseOrders');
};

export const getParamsFromFilter = (filter: Filter): ApiParams => ({
  status: filter.status,
  cost_center_ids: filter.costCenterIds,
  supplier_ids: filter.supplierIds,
  search: filter.search,
});
