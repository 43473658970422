import React from 'react';

import { useUser } from 'modules/app/hooks/useUser';

import { MemberInviteLinkContent } from '../../components/MemberInviteLinkContent/MemberInviteLinkContent';
import { useCreateInviteLinkMutation } from '../../hooks/useCreateInviteLinkMutation';
import { useGetInviteLinkQuery } from '../../hooks/useGetInviteLinkQuery';
import { useUpdateInviteLinkMutation } from '../../hooks/useUpdateInviteLinkMutation';

type Props = {
  onDelete(): void;
  onClose(): void;
};

export const MemberInviteLinkContentContainer = ({
  onDelete,
  onClose,
}: Props) => {
  const getInviteLinkQuery = useGetInviteLinkQuery();
  const [createInviteLink] = useCreateInviteLinkMutation();
  const [updateInviteLink] = useUpdateInviteLinkMutation();

  const companyDomain = useUser().email.split('@')[1];

  if (getInviteLinkQuery.status !== 'success') {
    // TODO: manage loading and error state
    return null;
  }

  return (
    <MemberInviteLinkContent
      companyDomain={companyDomain}
      inviteLink={getInviteLinkQuery.data}
      createInviteLink={async (domain?: string) =>
        createInviteLink({ restrict_domain: domain })
      }
      updateInviteLink={async (domain?: string) =>
        updateInviteLink({ restrict_domain: domain })
      }
      deleteInviteLink={onDelete}
      onClose={onClose}
    />
  );
};
