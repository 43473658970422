import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export const useDesignatedCustomFieldQuery = (): QueryState<{
  customFieldId: string;
}> => {
  return useQuery<{ customFieldId: string }>({
    key: 'designatedCustomField',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/expense-categories/designated-custom-field',
    },
    reshapeData: (data) => data,
  });
};
