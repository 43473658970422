import { type DateRange } from '@dev-spendesk/grapes';
import { subDays } from 'date-fns';
import gql from 'graphql-tag';
import { useQueryClient } from 'react-query';
import { v4 as uuid4 } from 'uuid';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getExportPeriods } from 'modules/bookkeep/export/graphql/utils/payableHelpers';
import { AvailableAccountingIntegrationsApi } from 'modules/bookkeep/hooks/';
import {
  useAccountingIntegrationHistory,
  type Result as AccountingIntegrationHistoryResult,
} from 'modules/bookkeep/hooks/useAccountingIntegrationHistory';
import { getFetcher } from 'src/core/api/fetcher';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { formatDateToApiString } from 'src/core/common/utils/formatDateToApiString';

import { getExportPayablesCountQueryKey } from './query-key-registry';
import {
  exportedPayableStates,
  unexportedPayableStates,
  unpreparedPayableStates,
  type PayableState,
} from '../models';

/**
 * Query and cache config
 */
const GET_PAYABLES_TO_EXPORT_COUNT = gql`
  query GetPayablesToExportCount(
    $companyId: ID!
    $unexportedPayablesFilter: PayableFilter!
    $unpreparedPayablesFilter: PayableFilter!
    $exportedPayablesFilter: PayableFilter!
    $previousPeriodPayablesFilter: PayableFilter!
  ) {
    company(id: $companyId) {
      id
      payablesVersion: payables {
        inboxVersion
      }
      unexportedPayables: payables(filter: $unexportedPayablesFilter) {
        totalCount
      }
      unpreparedPayables: payables(filter: $unpreparedPayablesFilter) {
        totalCount
      }
      exportedPayables: payables(filter: $exportedPayablesFilter) {
        totalCount
      }
      previousPeriodPayables: payables(filter: $previousPeriodPayablesFilter) {
        totalCount
      }
    }
  }
`;

export type ExportPayablesCountQueryState = QueryState<
  ExportPayablesCountResponse,
  unknown
>;

export type ExportPayablesCountResponse = {
  inboxVersion?: number;
  payablesCounts: {
    unexportedPayablesCount: number;
    unpreparedPayablesCount: number;
    exportedPayablesCount: number;
    previousPeriodUnexportedPayablesCount: number;
  };
};

export type ExportPayablesCountVariables = {
  companyId: string;
} & PayablesCountsFilters;

export const useInvalidateExportPayablesCountQueryCache = () => {
  const queryClient = useQueryClient();

  return async (companyId: string, period: DateRange): Promise<void> => {
    await queryClient.invalidateQueries(
      getExportPayablesCountQueryKey(companyId, period),
    );
  };
};

const getQueryConfig = (options?: {
  companyId: string;
  period: DateRange;
  availableAccountingIntegrations: AvailableAccountingIntegrationsApi.AvailableAccountingIntegrationsResponse;
  accountingIntegrationHistory: AccountingIntegrationHistoryResult;
}) => {
  const variables = options
    ? {
        companyId: options.companyId,
        ...getPayablesCountsFilters(
          options.period,
          options.availableAccountingIntegrations,
          options.accountingIntegrationHistory,
        ),
      }
    : undefined;

  return {
    type: 'graphQL',
    target: 'v2',
    query: GET_PAYABLES_TO_EXPORT_COUNT,
    variables,
  } as const;
};

/**
 * Query hook
 */
export const useExportPayablesCount = (period: DateRange) => {
  const companyId = useCompanyId();
  const idempotencyKey = uuid4();

  const availableAccountingIntegrationsQueryResult =
    AvailableAccountingIntegrationsApi.useAvailableAccountingIntegrations(
      idempotencyKey,
    );

  const accountingIntegrationHistoryQueryResult =
    useAccountingIntegrationHistory();

  if (
    availableAccountingIntegrationsQueryResult.status === 'error' ||
    accountingIntegrationHistoryQueryResult.status === 'error'
  ) {
    throw new Error('Can not load dependencies');
  }

  const availableAccountingIntegrations =
    availableAccountingIntegrationsQueryResult.status === 'success'
      ? availableAccountingIntegrationsQueryResult.data
      : undefined;

  const accountingIntegrationHistory =
    accountingIntegrationHistoryQueryResult.status === 'success'
      ? accountingIntegrationHistoryQueryResult.data
      : undefined;

  const isNewBookkeepingDependenciesLoaded = !!(
    availableAccountingIntegrations && accountingIntegrationHistory
  );

  const isEnabled =
    isNewBookkeepingDependenciesLoaded &&
    period.every((date) => date !== undefined);

  const reshapeData = reshapePayablesCounts;

  return useQuery({
    key: getExportPayablesCountQueryKey(companyId, period),
    request: getQueryConfig(
      isEnabled
        ? {
            companyId,
            period,
            availableAccountingIntegrations,
            accountingIntegrationHistory,
          }
        : undefined,
    ),
    isEnabled,
    reshapeData,
  });
};

/**
 * Standalone REST fetcher
 */

export const useExportPayablesCountFetcher = (): ((
  period: DateRange,
) => Promise<ExportPayablesCountResponse | undefined>) => {
  const companyId = useCompanyId();
  const idempotencyKey = uuid4();

  const availableAccountingIntegrationsQueryResult =
    AvailableAccountingIntegrationsApi.useAvailableAccountingIntegrations(
      idempotencyKey,
    );

  const accountingIntegrationHistoryQueryResult =
    useAccountingIntegrationHistory();

  if (
    availableAccountingIntegrationsQueryResult.status === 'error' ||
    accountingIntegrationHistoryQueryResult.status === 'error'
  ) {
    throw new Error('Can not load dependencies');
  }

  return async (period: DateRange) => {
    const availableAccountingIntegrations =
      availableAccountingIntegrationsQueryResult.status === 'success'
        ? availableAccountingIntegrationsQueryResult.data
        : undefined;

    const accountingIntegrationHistory =
      accountingIntegrationHistoryQueryResult.status === 'success'
        ? accountingIntegrationHistoryQueryResult.data
        : undefined;

    if (!availableAccountingIntegrations || !accountingIntegrationHistory) {
      return;
    }

    const data = await getFetcher<ExportPayablesCountRawResponse>({
      companyId,
      getRequest: () =>
        getQueryConfig({
          companyId,
          period,
          availableAccountingIntegrations,
          accountingIntegrationHistory,
        }),
    })();

    return reshapePayablesCounts(data);
  };
};

/**
 * Query reshapers
 */

export type ExportPayablesCountRawResponse = {
  company: {
    payablesVersion: { inboxVersion: number };
    unexportedPayables: { totalCount: number };
    unpreparedPayables: { totalCount: number };
    exportedPayables: { totalCount: number };
    previousPeriodPayables: { totalCount: number };
  };
};

const reshapePayablesCounts = ({
  company: {
    payablesVersion: { inboxVersion },
    unexportedPayables: { totalCount: unexportedPayablesCount },
    unpreparedPayables: { totalCount: unpreparedPayablesCount },
    exportedPayables: { totalCount: exportedPayablesCount },
    previousPeriodPayables: {
      totalCount: previousPeriodUnexportedPayablesCount,
    },
  },
}: ExportPayablesCountRawResponse): ExportPayablesCountResponse => ({
  inboxVersion,
  payablesCounts: {
    unexportedPayablesCount,
    unpreparedPayablesCount,
    exportedPayablesCount,
    previousPeriodUnexportedPayablesCount,
  },
});

type PayablesCountsFilter = {
  state: PayableState[];
  creationDate: { from?: string; to?: string };
  firstExportedAt?: { from?: string; to?: string }[];
  withBookkeepingStartDate?: boolean;
};

type PayablesCountsFilters = {
  unexportedPayablesFilter: PayablesCountsFilter;
  unpreparedPayablesFilter: PayablesCountsFilter;
  exportedPayablesFilter: PayablesCountsFilter;
  previousPeriodPayablesFilter: PayablesCountsFilter;
  creationDate: { from: string; to: string };
};

const getPayablesCountsFilters = (
  period: DateRange,
  availableAccountingIntegrations: AvailableAccountingIntegrationsApi.AvailableAccountingIntegrationsResponse,
  accountingIntegrationHistory: AccountingIntegrationHistoryResult,
): PayablesCountsFilters => {
  const [start, end] = period as Date[];

  const lastDayOfPreviousPeriod = subDays(start, 1);

  const creationDate = {
    from: formatDateToApiString(start),
    to: formatDateToApiString(end),
  };

  const exportedPayablesFilter = {
    state: exportedPayableStates,
    creationDate,
    firstExportedAt: getExportPeriods({
      selectedPeriod: period,
      availableAccountingIntegrations,
      accountingIntegrationHistory,
    }),
  };

  return {
    unexportedPayablesFilter: {
      state: unexportedPayableStates,
      creationDate,
    },
    unpreparedPayablesFilter: {
      state: unpreparedPayableStates,
      creationDate,
      withBookkeepingStartDate: true,
    },
    exportedPayablesFilter,
    previousPeriodPayablesFilter: {
      state: unexportedPayableStates,
      creationDate: {
        to: formatDateToApiString(lastDayOfPreviousPeriod),
      },
    },
    creationDate,
  };
};
