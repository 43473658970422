import { Button } from '@dev-spendesk/grapes';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { type AppDispatch } from 'modules/app/redux/store';

import styles from './DelegationHeader.module.css';
import { revokeApprovalRights } from '../../redux/actions';

export const DelegationHeader = () => {
  const user = useUser();
  const { t } = useTranslation('global');
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => {
    dispatch(revokeApprovalRights());
  };

  if (!user.is_delegating) {
    return null;
  }

  return (
    <header className={styles.delegationHeader}>
      <p className="text-white body-m">{t('delegationHeader.title')}</p>
      <Button
        variant="contrasted"
        text={t('delegationHeader.cta')}
        onClick={handleClick}
      />
    </header>
  );
};
