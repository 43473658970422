import { Supervision } from 'src/core/common/components/Supervision/Supervision';

import styles from './TopBanners.module.css';
import { DebitCardMigrationNotification } from '../../../components/Notifications/DebitCardMigrationNotification';
import { FundsInEscrowNotification } from '../../../components/Notifications/FundInEscrowNotification';
import { DelegationHeader } from '../DelegationHeader/DelegationHeader';
import { HeaderLoadNotifContainer } from '../HeaderLoadNotif/HeaderLoadNotifContainer';

export const TopBanners = () => {
  return (
    <div className={styles.container}>
      <Supervision />
      <HeaderLoadNotifContainer />
      <DelegationHeader />
      <FundsInEscrowNotification />
      <DebitCardMigrationNotification />
    </div>
  );
};
