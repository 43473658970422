import type React from 'react';
import { createContext, useReducer } from 'react';

import {
  type BucketId,
  type GroupId,
  type PayableId,
  updateOnBucketSelectChange,
  updateOnGroupSelectChange,
  updateOnPayableSelectChange,
  clear,
  type Selection as State,
} from '../../models';

type SelectionReducerAction =
  | {
      type: 'SELECT_BUCKET';
      bucketId: BucketId;
    }
  | { type: 'SELECT_GROUP'; bucketId: BucketId; groupId: GroupId }
  | {
      type: 'SELECT_PAYABLE';
      bucketId: BucketId;
      groupId: GroupId | undefined;
      payableId: PayableId;
    }
  | { type: 'CLEAR_SELECTION' };

const initialState = {};
export const usePreparePayablesSelectionContextReducer = () => {
  const reducer = (state: State, action: SelectionReducerAction) => {
    switch (action.type) {
      case 'SELECT_BUCKET':
        return updateOnBucketSelectChange(state, action.bucketId);
      case 'SELECT_GROUP':
        return updateOnGroupSelectChange(
          state,
          action.bucketId,
          action.groupId,
        );
      case 'SELECT_PAYABLE':
        return updateOnPayableSelectChange(
          state,
          action.bucketId,
          action.groupId,
          action.payableId,
        );
      case 'CLEAR_SELECTION':
        return clear();
      default:
        throw new Error(`Unexpected action ${action}`);
    }
  };

  return useReducer(reducer, initialState);
};

export const actionsFactory = (
  dispatch: (action: SelectionReducerAction) => void,
) => ({
  selectBucket: (bucketId: BucketId) => {
    dispatch({ type: 'SELECT_BUCKET', bucketId });
  },
  selectGroup: (bucketId: BucketId, groupId: GroupId) => {
    dispatch({ type: 'SELECT_GROUP', bucketId, groupId });
  },
  selectPayable: (
    bucketId: BucketId,
    groupId: GroupId | undefined,
    payableId: PayableId,
  ) => {
    dispatch({ type: 'SELECT_PAYABLE', bucketId, groupId, payableId });
  },
  clear: () => {
    dispatch({ type: 'CLEAR_SELECTION' });
  },
});

export const PreparePayablesSelectionContext: React.Context<{
  state: State;
  actions: ReturnType<typeof actionsFactory>;
}> = createContext({
  state: {},
  actions: actionsFactory(() => {}),
});
