import { FormField } from '@dev-spendesk/grapes';
import React from 'react';

import { AutocompleteNoOptions } from 'src/core/common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

type CostCenter = {
  id: string;
  name: string;
};

type Props = {
  costCenters: CostCenter[];
  costCenter: CostCenter | undefined;
  error: string | undefined;
  onChange: (costCenter: CostCenter) => void;
};

export const PayableAccountingSectionCostCenter = ({
  costCenters,
  costCenter: currentCostCenter,
  error,
  onChange,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <FormField
      className="PayableAccountingSectionCostCenter"
      label={t('payables.panel.costCenter')}
      alertMessage={error}
    >
      <AutocompleteSearch
        fit="parent"
        options={costCenters.map((costCenter) => ({
          key: costCenter.id,
          label: costCenter.name,
        }))}
        value={
          currentCostCenter
            ? { key: currentCostCenter.id, label: currentCostCenter.name }
            : undefined
        }
        renderNoOptions={(rawValue) => (
          <AutocompleteNoOptions value={rawValue} />
        )}
        onSelect={(costCenterOption) => {
          onChange(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            costCenters.find(
              (costCenter) => costCenter.id === costCenterOption?.key,
            )!,
          );
        }}
      />
    </FormField>
  );
};
