import { gql } from 'graphql-tag';

export const GET_FIELD_VALUES = gql`
  query FieldValues($companyId: ID!) {
    company(id: $companyId) {
      id
      teams(first: 200) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
      chartOfAccounts {
        supplierAccounts(first: 200, filter: { includeArchived: false }) {
          totalCount
          edges {
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              defaultFor
            }
          }
        }
      }
      customFields(filter: { includeArchived: true }) {
        __typename
        id
        name
        optional
        isSplittable
        scopes {
          team {
            id
          }
        }
        eligibleEntities
        archiveDate
        ... on CustomFieldBoolean {
          booleanValues: values {
            id
            name: value
          }
        }
        ... on CustomFieldList {
          canCreateValue
          values(first: 200, filter: { includeArchived: true }) {
            totalCount
            edges {
              node {
                id
                name: value
                archiveDate
              }
            }
          }
        }
      }
    }
  }
`;
