import { Button, Input } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useState } from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import styles from './InputCopy.module.css';

type CopyMode = 'button' | 'manual';

type Props = {
  value: string;
  className?: string;
  withPurpleShadow?: boolean;
  onCopied?(mode: CopyMode): void;
};

export const InputCopy = ({
  value,
  className,
  withPurpleShadow = false,
  onCopied,
}: Props) => {
  const { t } = useTranslation('global');
  const [isCopied, setIsCopied] = useState(false);

  const buildHandleOnCopy = (mode: CopyMode) => async () => {
    try {
      await navigator.clipboard.writeText(value);
      onCopied?.(mode);
      setIsCopied(true);
    } catch {
      // ignore error thrown
    }
  };

  return (
    <div className={classNames(className, 'relative w-full')}>
      {/*
        The Grapes Input has some state dependant style (hover, focus-within)
        The purple shadow needs to be static for this design, so having an extra
        div works better than hacking CSS specificity
      */}
      {withPurpleShadow && (
        <div
          className={classNames('absolute inset-0 rounded-xxs', {
            [styles.shadow]: withPurpleShadow,
          })}
        />
      )}
      <Input
        isReadOnly
        fit="parent"
        value={value}
        rightAddon={
          <Button
            variant="ghost"
            text={isCopied ? t('misc.copied') : t('misc.copy')}
            onClick={buildHandleOnCopy('button')}
          />
        }
        onCopy={buildHandleOnCopy('manual')}
      />
    </div>
  );
};
