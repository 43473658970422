import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type DeletionPayload = {
  id: string;
};

export const useDeleteEcQuery = (
  id: string,
): MutationState<DeletionPayload> => {
  return useMutation<DeletionPayload>({
    request: {
      type: 'rest',
      method: 'delete',
      target: 'companyAPI',
      endpoint: `/expense-categories/${id}`,
    },
    options: {
      throwOnError: true,
      async onSuccess({ client }): Promise<void> {
        await Promise.all([
          client.invalidateQueries('expenseCategories'),

          // refresh which custom field values are archived
          client.invalidateQueries('useFieldValuesQuery'),
          client.removeQueries('useFieldValuesQuery'),
          // remove queries when they have both staleTime & cacheTime
          client.invalidateQueries('useValuesOfCustomFieldsQuery'),
          client.removeQueries('useValuesOfCustomFieldsQuery'),
        ]);
      },
    },
  });
};
