import React, { useState } from 'react';

import { ModalMFAToken } from 'common/components/ModalMFA';

import { DelegationConfirmationModal } from './DelegationConfirmationModal';
import { useInitiateDelegation } from '../../hooks/useInitiateDelegation';
import { type Delegation } from '../../models';

export type DelegationConfirmationModalContainerProps = {
  onClose: () => void;
  onComplete: () => void;
  delegation: { userId: string; role: Delegation };
};

export const DelegationConfirmationModalContainer = ({
  onClose,
  delegation,
  onComplete,
}: DelegationConfirmationModalContainerProps) => {
  const [initiateDelegation, initiateDelegationQueryState] =
    useInitiateDelegation();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(true);

  const onApprove = async () => {
    try {
      await initiateDelegation(delegation);
    } catch {
      onClose();
    }
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <DelegationConfirmationModal
        onClose={onClose}
        isOpen={isConfirmationModalOpen}
        isApproving={initiateDelegationQueryState.status === 'loading'}
        onApprove={onApprove}
      />
      <ModalMFAToken
        onMfaTokenValidated={onComplete}
        onClose={onClose}
        isOpen={!isConfirmationModalOpen}
        flow="standard"
      />
    </>
  );
};
