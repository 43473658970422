import { type Dispatch, type ThunkDispatch } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';

import { addNotification, NotificationType } from 'modules/app/notifications';
import { getSelectedCompanyId } from 'modules/company';
import { companyAPI } from 'src/core/api/axios';
import i18n from 'src/core/config/i18n';
import { type AppState } from 'src/core/reducers';

import * as controlRulesActions from './actions';
import {
  getIsFetchingControlRules,
  getIsCreatingControlRule,
  getIsUpdatingControlRule,
  getSelectedControlRule,
} from './selectors';
import {
  type ControlRuleRequest,
  type CustomControlRuleRequest,
  type ControlRule,
} from '../controlRule';

export const fetchControlRules = () => {
  return async (
    dispatch: Dispatch<controlRulesActions.ControlRuleAction>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getSelectedCompanyId(state);

    if (getIsFetchingControlRules(state)) {
      return;
    }

    dispatch(controlRulesActions.fetchControlRulesRequest());

    let controlRules;
    try {
      const { data } = await companyAPI.get('/control-rules', {
        companyId,
      });
      controlRules = data;
    } catch (error) {
      dispatch(controlRulesActions.fetchControlRulesFailure(error));
      throw error;
    }

    dispatch(controlRulesActions.fetchControlRulesSuccess(controlRules));
  };
};

export const createControlRule = (request: ControlRuleRequest) => {
  return async (
    dispatch: Dispatch<controlRulesActions.ControlRuleAction>,
    getState: () => AppState,
  ): Promise<void> => {
    if (getIsCreatingControlRule(getState())) {
      return;
    }

    let controlRule;
    const companyId = getSelectedCompanyId(getState());
    dispatch(controlRulesActions.createControlRuleRequest());

    try {
      const { data } = await companyAPI.post('/control-rules', request, {
        companyId,
      });
      controlRule = data;
    } catch (error) {
      dispatch(controlRulesActions.createControlRuleFailure(error));
      throw error;
    }
    dispatch(controlRulesActions.createControlRuleSuccess(controlRule));
  };
};

export const createCustomControlRule = (
  request: CustomControlRuleRequest,
  previousControlRule?: ControlRule,
) => {
  return async (
    dispatch: ThunkDispatch<
      AppState,
      null,
      controlRulesActions.ControlRuleAction
    >,
    getState: () => AppState,
  ): Promise<void> => {
    let controlRule;
    const companyId = getSelectedCompanyId(getState());

    try {
      const { data } = await companyAPI.post('/control-rules/custom', request, {
        companyId,
      });
      controlRule = data;
    } catch (error) {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('members.customControlRuleError'),
        }),
      );
      throw error;
    }

    dispatch(
      controlRulesActions.createControlRuleSuccess(
        controlRule,
        previousControlRule,
        request.userId,
      ),
    );
  };
};

export const removeControlRule = (id: string) => {
  return async (
    dispatch: ThunkDispatch<
      AppState,
      null,
      controlRulesActions.ControlRuleAction
    >,
    getState: () => AppState,
  ): Promise<void> => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(controlRulesActions.removeControlRuleRequest());

    try {
      await companyAPI.delete(`/control-rules/${id}`, {
        companyId,
      });
    } catch (error) {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('controlRulesModal.errors.deleteRule'),
        }),
      );

      throw error;
    }
    dispatch(controlRulesActions.removeControlRuleSuccess(id));
  };
};

export const updateControlRule = (request: Partial<ControlRuleRequest>) => {
  return async (
    dispatch: ThunkDispatch<
      AppState,
      null,
      controlRulesActions.ControlRuleAction
    >,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getSelectedCompanyId(state);
    const currentControlRule = getSelectedControlRule(state);

    if (!currentControlRule || getIsUpdatingControlRule(state)) {
      return;
    }

    dispatch(controlRulesActions.updateControlRuleRequest());

    // only update the data that has been changed
    const {
      name,
      completionDeadline,
      incompletePaymentsLimit,
      userIds,
      isDefault,
    } = request;
    const updatePayload: Partial<ControlRuleRequest> = {
      ...(name !== currentControlRule.name ? { name } : {}),
      ...(completionDeadline !== currentControlRule.completionDeadline
        ? { completionDeadline }
        : {}),
      ...(incompletePaymentsLimit !== currentControlRule.incompletePaymentsLimit
        ? { incompletePaymentsLimit }
        : {}),
      ...(!isEqual(userIds, currentControlRule.userIds) ? { userIds } : {}),
      ...(currentControlRule.isInitial && isDefault !== undefined
        ? { isDefault }
        : {}),
    };

    let controlRules;
    try {
      const { data } = await companyAPI.put(
        `/control-rules/${currentControlRule.id}`,
        updatePayload,
        {
          companyId,
        },
      );
      controlRules = data;
    } catch (error) {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('controlRulesModal.errors.updateRule'),
        }),
      );
      throw error;
    }

    dispatch(controlRulesActions.updateControlRuleSuccess(controlRules));
  };
};
