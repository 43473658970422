import { Icon, type IconName } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { type ReactNode, useEffect, useRef, useId } from 'react';

import styles from './DisclosureItem.module.css';
import { useNavigationContext } from '../NavigationContext';

type Props = {
  children: ReactNode;
  iconName: IconName;
  summary: string;
};

export const DisclosureItem = ({ iconName, children, summary }: Props) => {
  const detailsReference = useRef<HTMLDetailsElement>(null);
  const isExpanded = useNavigationContext();
  const summaryId = useId();

  useEffect(() => {
    if (!isExpanded && detailsReference.current) {
      detailsReference.current.open = false;
    }
  }, [isExpanded, detailsReference]);

  return (
    <details
      className={styles.details}
      name="navigation"
      ref={detailsReference}
    >
      <summary
        className={classNames(
          'navigation-item h-xl list-none',
          styles.safariFix,
        )}
        aria-labelledby={summaryId}
      >
        <Icon
          name={iconName}
          size="m"
          aria-hidden="true"
          className="shrink-0"
        />
        <span id={summaryId} className="navigation-toggle-display grow">
          {summary}
        </span>
        <Icon
          name="caret-right"
          aria-hidden="true"
          size="m"
          className={classNames(styles.caret, 'navigation-toggle-display')}
        />
      </summary>
      <ul className={styles.list}>{children}</ul>
    </details>
  );
};
