import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type { TransferDetails, TransferId } from '../../models';

export const useTransferQuery = (
  transferId: TransferId,
): QueryState<TransferDetails, unknown> => {
  return useQuery<TransferDetails, TransferDetails, unknown>({
    key: ['transfer-scheduling', 'transfers', transferId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/transfer-scheduling/transfers/${transferId}`,
    },
    reshapeData: (data) => data,
  });
};
