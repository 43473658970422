import { Table } from '@dev-spendesk/grapes';
import React, { type Dispatch } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { type AnalyticalFieldMapping } from 'modules/bookkeep/settings/accounting';

import { type ModalState } from './ModalState';
import { TableButton } from './TableButton';
import { analyticalFieldsMappingsTableExternalNameI18nKey } from './translations';
import { type CustomField } from '../../../../../hooks/useCustomFieldsQuery';
import { CellWithButton } from '../../../components/CellWithIconButton/CellWithIconButton';

interface Props {
  integration: AccountingSoftware;
  setModalState: Dispatch<ModalState>;
  customFields: CustomField[];
  analyticalFieldMapping: AnalyticalFieldMapping[];
}

function getInternalNameCellContent(
  mapping: AnalyticalFieldMapping,
  customFields: CustomField[],
  t: TGlobalFunctionTyped,
) {
  if (mapping.kind === 'notMapped') {
    return <em>—</em>;
  }

  if (mapping.codeType === 'costCenter') {
    return t(
      'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenters',
    );
  }

  const customField = customFields.find((cF) => cF.id === mapping.internalId);

  return customField?.name ?? '';
}

function showHideTableButton(
  mapping: AnalyticalFieldMapping,
  isRowHovered: boolean,
): boolean {
  if (mapping.kind === 'notMapped') {
    return true;
  }

  return isRowHovered;
}

export const StaticMapAndPullAndMapTable = ({
  integration,
  setModalState,
  customFields,
  analyticalFieldMapping,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Table<AnalyticalFieldMapping>
      rowHeight="compact"
      hasColumnSeparator
      columns={[
        {
          id: 'analytical-field-external-name',
          header: t(
            analyticalFieldsMappingsTableExternalNameI18nKey(integration),
          ),
          width: '50%',
          renderCell(row) {
            return row.externalName;
          },
        },
        {
          id: 'analytical-field-internal-name',
          header: t(
            'bookkeep.integrations.settings.analyticalFieldsMappingsTable.internalName',
          ),
          width: '50%',
          renderCell(row, { isRowHovered }) {
            return (
              <CellWithButton
                isButtonVisible={showHideTableButton(row, isRowHovered)}
                button={
                  <TableButton mapping={row} setModalState={setModalState} />
                }
              >
                {getInternalNameCellContent(row, customFields, t)}
              </CellWithButton>
            );
          },
        },
      ]}
      data={analyticalFieldMapping}
      footer={
        analyticalFieldMapping.length === 0 ? (
          <TableFooter analyticalFieldMapping={analyticalFieldMapping} />
        ) : undefined
      }
    />
  );
};

const TableFooter = ({
  analyticalFieldMapping,
}: {
  analyticalFieldMapping: AnalyticalFieldMapping[];
}) => {
  const { t } = useTranslation();

  if (analyticalFieldMapping.length === 0) {
    return (
      <em>
        {t(
          'bookkeep.integrations.settings.analyticalFieldsMappingsTable.noAnalyticalFields',
        )}
      </em>
    );
  }

  return null;
};
