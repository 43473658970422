import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Timeline } from 'common/components/Timeline';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { getI18nContext } from "src/core/selectors/i18n";
import { formatTimelineEvents } from "src/core/utils/timelines";

import { type TransferDetails } from '../../../../models';

export type Props = {
  transfer: TransferDetails;
};

export const TransferTimeline = ({ transfer }: Props) => {
  const { t } = useTranslation('global');
  const companyCurrency = useCompanyCurrency();
  const i18nContext = useSelector(getI18nContext);
  const { timeline, request } = transfer;

  const timelineEvents = useMemo(() => {
    const formattedEvents = formatTimelineEvents(
      t,
      timeline,
      request,
      companyCurrency,
      i18nContext,
    );

    return formattedEvents.map((event) => ({
      key: event.id,
      date: new Date(event.occuredAt),
      content: (
        <>
          <div>{event.firstLine}</div>
          <div>{event.secondLine}</div>
        </>
      ),
    }));
  }, [transfer]);

  return <Timeline className="mt-m" events={timelineEvents} />;
};
