import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type SupplierBankInfos } from '../../../models';
import { BankInfoPreview } from '../BankInfoPreview';

type Props = {
  sortCode: SupplierBankInfos['sortCode'];
};

export const SortCodePreview = ({ sortCode }: Props) => {
  const { t } = useTranslation('global');

  return (
    <BankInfoPreview
      primaryText={t('submitMyInvoice.fields.sortCode.label')}
      secondaryText={sortCode}
    />
  );
};
