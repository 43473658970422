import { gql } from 'graphql-tag';

export const GET_SUPPLIER_DETAILS = gql`
  query GetSupplierDetails($id: ID!) {
    supplier(id: $id) {
      id
      name
      thumbnailUrl
      isArchived
      accountHolderName
      supplierAccount {
        id
        generalAccountCode
        auxiliaryAccountCode
      }
      bankDetails {
        bankCountry
        iban
        bicSwift
        sortCode
        routingNumber
        accountNumber
        accountCode
        bankAccountDetailsFileUrl
        bankAccountDetailsThumbnailUrl
        bankAccountDetailsMediaType
      }
      legalDetails {
        legalName
        registrationNumber
        vatNumber
        addressCountry
        postalCode
        streetAddress
        addressLocality
      }
      payablesAggregate {
        sum {
          functionalAmount
        }
      }
      expenseAccountSupplierRule {
        expenseAccount {
          id
          name
          isArchived
        }
      }
    }
  }
`;
