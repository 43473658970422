import {
  Button,
  Link,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { getHelpCenterBase } from 'common/utils/help-center/getHelpCenterBase';
import { useUser } from 'modules/app/hooks/useUser';
import { TemplateEditorFormModal } from 'modules/bookkeep/settings/export';

import { CustomExportsList } from './CustomExportsList';
import { type CustomField } from '../../customField';
import { type TemplateItem } from '../../template';

type Props = {
  customExports?: TemplateItem[];
  customFields: CustomField[] | null;
  hasLoaded: boolean;
  fetchAllTemplates: () => void;
  deleteTemplate: (templateId: string) => Promise<void>;
};

export const CustomExports = ({
  customExports = [],
  customFields,
  hasLoaded,
  fetchAllTemplates,
  deleteTemplate,
}: Props) => {
  const { t } = useTranslation('global');
  const user = useUser();

  const [isCreateExportModalActive, setIsCreateExportModalActive] =
    useState(false);
  const [editingCustomExport, setEditingCustomExport] =
    useState<TemplateItem | null>(null);

  useEffect(() => {
    fetchAllTemplates();
  }, []);

  const helpCenterLink = `${getHelpCenterBase({
    lang: user.lang,
  })}articles/4152880`;

  return (
    <>
      <div className="mb-s mt-l">
        <h1 className="text-complementary title-xl">
          {t('exports.customExportsTitle')}
        </h1>
        <div className="flex items-center justify-between">
          <p className="w-full flex-1 text-neutral-dark body-m">
            <Trans
              i18nKey="exports.customExportsSubTitle"
              components={[
                <Link
                  key="help"
                  isExternal
                  href={helpCenterLink}
                  className="text-complementary"
                >
                  {t('exports.customExportsSubTitleLink')}
                </Link>,
              ]}
            />
          </p>
          {hasLoaded && (
            <Button
              className="ml-m"
              text={
                customExports && customExports.length > 0
                  ? t('exports.btnNewCustomExport')
                  : t('exports.btnFirstCustomExport')
              }
              onClick={() => setIsCreateExportModalActive(true)}
            />
          )}
        </div>
      </div>
      {hasLoaded ? (
        <CustomExportsList
          customExports={customExports}
          deleteTemplate={deleteTemplate}
          setEditingCustomExport={setEditingCustomExport}
          setIsCreateExportModalActive={setIsCreateExportModalActive}
        />
      ) : (
        <SkeletonTable
          columns={[
            {
              header: <SkeletonText width="30%" />,
              cell: <SkeletonText width="50%" />,
            },
          ]}
          withHeader
          rowHeight="compact"
        />
      )}
      <TemplateEditorFormModal
        isOpen={isCreateExportModalActive}
        templateId={editingCustomExport ? editingCustomExport.id : undefined}
        onExit={() => {
          fetchAllTemplates();
          setIsCreateExportModalActive(false);
          setEditingCustomExport(null);
        }}
        customFields={customFields ?? []}
      />
    </>
  );
};
