import { combineReducers } from '@reduxjs/toolkit';

import { RequestState } from 'src/core/common/redux/requestState';

import {
  type ExportActions,
  FETCH_TEMPLATE_REQUEST,
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_ALL_TEMPLATES_REQUEST,
  FETCH_ALL_TEMPLATES_FAILURE,
  FETCH_ALL_TEMPLATES_SUCCESS,
  RESET_OPENED_TEMPLATE,
} from './actionTypes';
import {
  type Template,
  type TemplateItem,
  type AvailableColumn,
} from '../template';

type OpenedTemplateState = {
  requestState: RequestState;
  template: Template | undefined;
};

const openedTemplateInitialState: OpenedTemplateState = {
  requestState: RequestState.NotAsked,
  template: undefined,
};

const openedTemplateReducer = (
  state: OpenedTemplateState = openedTemplateInitialState,
  action: ExportActions,
): OpenedTemplateState => {
  switch (action.type) {
    case FETCH_TEMPLATE_REQUEST:
      return {
        requestState: RequestState.Loading,
        template: undefined,
      };
    case FETCH_TEMPLATE_FAILURE:
      return {
        requestState: RequestState.Failure,
        template: undefined,
      };
    case FETCH_TEMPLATE_SUCCESS:
      return {
        requestState: RequestState.Success,
        template: action.payload.template,
      };
    case RESET_OPENED_TEMPLATE:
      return openedTemplateInitialState;
    default:
      return state;
  }
};

type TemplatesState = {
  requestState: RequestState;
  templates: TemplateItem[];
};

const templatesInitialState: TemplatesState = {
  requestState: RequestState.NotAsked,
  templates: [],
};

const templatesReducer = (
  state: TemplatesState = templatesInitialState,
  action: ExportActions,
): TemplatesState => {
  switch (action.type) {
    case FETCH_ALL_TEMPLATES_REQUEST:
      return {
        ...state,
        requestState: RequestState.Loading,
      };
    case FETCH_ALL_TEMPLATES_FAILURE:
      return {
        requestState: RequestState.Failure,
        templates: [],
      };
    case FETCH_ALL_TEMPLATES_SUCCESS:
      return {
        requestState: RequestState.Success,
        templates: action.payload.templates,
      };
    default:
      return state;
  }
};

type AvailableTemplateColumnsState = {
  hasLoaded: boolean;
  availableColumns: AvailableColumn[];
};

const availableTemplateColumnsInitialState: AvailableTemplateColumnsState = {
  hasLoaded: false,
  availableColumns: [],
};

const availableTemplateColumnsReducer = (
  state: AvailableTemplateColumnsState = availableTemplateColumnsInitialState,
  action: ExportActions,
): AvailableTemplateColumnsState => {
  switch (action.type) {
    case FETCH_TEMPLATE_SUCCESS:
      if (!action.payload.availableTemplateColumns) {
        return state;
      }

      return {
        hasLoaded: true,
        availableColumns: action.payload.availableTemplateColumns,
      };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  templates: templatesReducer,
  openedTemplate: openedTemplateReducer,
  availableTemplateColumns: availableTemplateColumnsReducer,
});
