import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type BillingInformation } from '../../../../models';

type RawBillingInformation = {
  address1: string | null;
  address2: string | null;
  city: string | null;
  country: string | null;
  name: string | null;
  zipcode: string | null;
  is_billing_info_same_as_company: boolean;
  vat: string | null;
  email: string | null;
  company_id: string;
  organisation_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  id: string;
};

type GetBillingAddressResult =
  | {
      outcome: 'success';
      billingAddress: RawBillingInformation;
    }
  | {
      outcome: 'error';
      errorCode: string;
    };

export const useGetBillingInformationQuery = (): QueryState<
  BillingInformation | undefined
> => {
  const companyId = useCompanyId();

  return useQuery<BillingInformation | undefined, GetBillingAddressResult>({
    key: ['billingInformation', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/billing/address',
    },
    reshapeData: (result) => {
      if (result.outcome === 'error') {
        return undefined;
      }

      return {
        address: result.billingAddress.address1,
        address2: result.billingAddress.address2,
        city: result.billingAddress.city,
        country: result.billingAddress.country,
        name: result.billingAddress.name,
        zipcode: result.billingAddress.zipcode,
        isBillingInfoSameAsCompany:
          result.billingAddress.is_billing_info_same_as_company,
        email: result.billingAddress.email,
        vat: result.billingAddress.vat,
        companyId: result.billingAddress.company_id,
        organisationId: result.billingAddress.organisation_id,
        createdAt: result.billingAddress.created_at,
        updatedAt: result.billingAddress.updated_at,
        deletedAt: result.billingAddress.deleted_at,
        id: result.billingAddress.id,
      };
    },
  });
};
