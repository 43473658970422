import { Button, Tag } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { HubTaskWrapper } from 'modules/onboarding/components/HubTaskWrapper';
import { type HubTask } from 'modules/onboarding/types/hubTask';
import { routeFor } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { routes } from '../../../routes';

type Props = {
  task: HubTask & {
    type: 'member_invite';
  };
};

export const MemberInviteTask = ({ task }: Props) => {
  const history = useHistory();
  const { t } = useTranslation('global');
  const user = useUser();

  const companyId = useCompanyId();
  const isDisabled =
    task.status === 'disabled' ||
    (!!task.assignedUserId && task.assignedUserId !== user.id);
  const isCompleted = task.status === 'completed';

  const startTask = () => {
    const firstMember = task.metadata?.members[0];
    const routeForPath =
      firstMember?.email && firstMember.roles.length
        ? routes.MEMBER_INVITE_LIST.path
        : routes.MEMBER_INVITE_EMAIL.path;
    const path = routeFor(routeForPath, {
      company: companyId,
    });
    history.push(path);
  };

  return (
    <HubTaskWrapper
      title={t('onboardingHub.accountSetupTask.memberInviteSubTask.title')}
      description={t(
        'onboardingHub.accountSetupTask.memberInviteSubTask.description',
      )}
      disabledMessage={t(
        'onboardingHub.accountSetupTask.memberInviteSubTask.disabledMessage',
      )}
      task={task}
      duration="5"
      actions={
        isCompleted
          ? [
              <Tag key="done" variant="success" iconName="success" isRounded>
                {t('misc.done')}
              </Tag>,
            ]
          : [
              <Button
                variant="primary"
                key="company-setup"
                isDisabled={isDisabled}
                onClick={startTask}
                text={t(
                  'onboardingHub.accountSetupTask.memberInviteSubTask.cta',
                )}
              />,
            ]
      }
    />
  );
};
