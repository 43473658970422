import { Icon, Link, Tooltip } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import './SupplierOrMemberSyncStatus.css';

export type SupplierOrMemberSyncStatusProps = {
  synced: boolean;
  label: string;
  anchor?: 'none' | 'simple' | 'defaultVendor';
  url?: string | null;
  defaultVendorName?: string;
  tooltipText?: string;
};

export const SupplierOrMemberSyncStatus = ({
  synced,
  anchor = 'simple',
  ...otherProps
}: SupplierOrMemberSyncStatusProps) => {
  const syncStatusRowProps: SyncStatusRowProps = synced
    ? { synced, label: otherProps.label, url: otherProps.url || null }
    : { synced, label: otherProps.label, tooltipText: otherProps.tooltipText };

  if (anchor === 'defaultVendor') {
    return (
      <div
        className={classNames(
          'VendorSyncStatus',
          'hasDefaultVendorAnchor',
          synced && 'hasCurvyAnchor',
        )}
      >
        <div className="defaultVendorAnchor" />
        <DefaultVendorRow
          synced={synced}
          defaultVendorName={otherProps.defaultVendorName}
        />
        {synced && (
          <>
            <div className="curvyAnchor" />
            <SyncStatusRow {...syncStatusRowProps} />
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'VendorSyncStatus',
        anchor !== 'none' && 'hasCurvyAnchor',
      )}
    >
      {anchor !== 'none' && <div className="curvyAnchor" />}
      <SyncStatusRow {...syncStatusRowProps} />
    </div>
  );
};

type DefaultVendorRowProps =
  | {
      synced: false;
    }
  | {
      synced: true;
      defaultVendorName?: string;
    };

const DefaultVendorRow = (props: DefaultVendorRowProps) => {
  const { t } = useTranslation('global');

  const icon = <Icon className="defaultVendorIcon" name="supplier" />;

  const text =
    props.synced && props.defaultVendorName ? (
      <>
        {props.defaultVendorName.length > 27 ? (
          <Tooltip content={props.defaultVendorName} triggerAsChild>
            <div className="max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap leading-6 text-complementary title-l">
              {props.defaultVendorName}
            </div>
          </Tooltip>
        ) : (
          <div className="min-w-[250px] leading-6 text-complementary title-l">
            {props.defaultVendorName}
          </div>
        )}
        <span>
          ({t('bookkeep.integrations.settings.defaultVendors.default')})
        </span>
      </>
    ) : (
      <span className="missingDefaultVendor">
        {t('bookkeep.integrations.settings.defaultVendors.defaultMissing')}
      </span>
    );

  return (
    <div className="DefaultVendorRow">
      {icon}
      {text}
    </div>
  );
};

type SyncStatusRowProps =
  | {
      synced: false;
      label: string;
      tooltipText?: string;
    }
  | {
      synced: true;
      label: string;
      url: string | null;
    };

const SyncStatusRow = (props: SyncStatusRowProps) => {
  const tooltipText = !props.synced && props.tooltipText;
  const url = props.synced && props.url;

  const icon = (
    <Icon
      className={classNames('statusSyncIcon', props.synced && 'synced')}
      name={props.synced ? 'success' : 'clock'}
    />
  );

  return (
    <div className="SyncStatusRow">
      {tooltipText ? (
        <Tooltip content={tooltipText} triggerAsChild>
          {icon}
        </Tooltip>
      ) : (
        icon
      )}
      <span className="label">
        {url ? (
          <>
            <Link href={url} isExternal>
              {props.label}
            </Link>
            <Icon className="externalLinkIcon" name="external" />
          </>
        ) : (
          props.label
        )}
      </span>
    </div>
  );
};
