import { useMutation } from 'src/core/api/hooks/useMutation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

type Payload = {
  user_id: string;
  id: string;
  enabled: boolean;
  endpointParams: { roleId: string };
};

export const useUpdateRolesMutation = () => {
  const companyId = useCompanyId();
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: ({ roleId }) => `/roles/${roleId}`,
      method: 'put',
    },
    options: {
      onSuccess: ({ client }) => {
        client.refetchQueries(['companies', companyId, 'roles']);
      },
    },
  });
};
