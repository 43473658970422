import { formatISO } from 'date-fns';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type { Breakdown } from '../../models/breakdown';
import type { PeriodRangePayload } from '../../models/period';

export const useBreakdownQuery = (
  budgetId: string | undefined,
  periodRange?: PeriodRangePayload | undefined,
): QueryState<Breakdown> => {
  const params = periodRange && {
    startDate:
      periodRange.from &&
      formatISO(periodRange.from, { representation: 'date' }),
    endDate:
      periodRange.to && formatISO(periodRange.to, { representation: 'date' }),
  };

  return useQuery<Breakdown>({
    key: ['budgets', budgetId, 'breakdown', params],
    isEnabled: Boolean(budgetId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/budgets/budgets/${budgetId}/breakdown`,
      params,
    },
    reshapeData: (data) => {
      return {
        available: data.available,
        used: data.used,
        committed: data.committed,
        usedExceeded: data.usedExceeded,
        committedExceeded: data.committedExceeded,
        breakdownComputedAt: new Date(data.breakdownComputedAt),
      } satisfies Breakdown;
    },
  });
};
