import * as F from 'formik';
import * as yup from 'yup';

import { filterOutUndefinedProperties } from 'common/utils/filterOutUndefinedProperties';
import {
  type BudgetTrackingTraitModel,
  BudgetTrackingValidationModel,
} from 'modules/budgets/models/budgetTracking';

import type * as DraftPayableRequest from './draftPayableRequest';
import * as PayableErrors from './payableErrors';
import * as Schemas from './schemas';

export const maxDescriptionLength = 255;
export interface ValidationContext {}

export interface Errors extends BudgetTrackingValidationModel.Errors {
  amount?: PayableErrors.RequiredError | unknown;
  description?: PayableErrors.RequiredError;
}

export type ToCorePayableErrors<T extends DraftPayableRequest.Entity> =
  F.FormikErrors<Omit<T, keyof BudgetTrackingTraitModel.Trait>>;
export type CorePayableFormikErrors =
  ToCorePayableErrors<DraftPayableRequest.Entity>;

export type ToPayableErrors<T extends DraftPayableRequest.Entity> =
  BudgetTrackingValidationModel.ToFormikErrors<T>;
export type FormikErrors = ToPayableErrors<DraftPayableRequest.Entity>;

export const validate = (
  payableRequest: DraftPayableRequest.Entity,
  validationContext: ValidationContext,
): CorePayableFormikErrors => {
  try {
    F.validateYupSchema(payableRequest, payableRequestValidationSchema, true, {
      ...validationContext,
      payableRequest,
    });
    return {};
  } catch (e) {
    return {
      ...F.yupToFormErrors<DraftPayableRequest.Entity>(e),
    } as CorePayableFormikErrors;
  }
};

export const fromFormikErrors = (formikErrors: FormikErrors): Errors =>
  filterOutUndefinedProperties({
    ...BudgetTrackingValidationModel.fromFormikErrors(formikErrors),
    amount: PayableErrors.parseToRequiredError(formikErrors.amount),
    description: PayableErrors.parseToRequiredError(formikErrors.description),
  });

const payableRequestValidationSchema = yup.object().shape({
  amount: Schemas.amount,
  description: Schemas.text.max(maxDescriptionLength),
});
