import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export type Props = {
  numberOfPayablesInExportTab: number;
  displayMissingAnalyticalSplitWarning: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function AccountingBaseSwitchConfirmationModal({
  numberOfPayablesInExportTab,
  displayMissingAnalyticalSplitWarning,
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen={isOpen}
      title={t('bookkeep.integrations.settings.baseSwitch.confirmationTitle')}
      iconName="warning"
      iconVariant="warning"
      actions={[
        <Button
          key="back"
          variant="secondary"
          onClick={() => onClose()}
          text={t('misc.cancel')}
        />,
        <Button
          key="confirm"
          text={t(
            'bookkeep.integrations.settings.baseSwitch.confirmationValidation',
          )}
          variant="warning"
          onClick={() => onConfirm()}
          iconName="caret-right"
          iconPosition="right"
        />,
      ]}
    >
      <div data-testid="accounting-base-switch-confirmation-content">
        {numberOfPayablesInExportTab > 0 && (
          <p>
            {t(
              'bookkeep.integrations.settings.baseSwitch.confirmationContent',
              { count: numberOfPayablesInExportTab },
            )}
          </p>
        )}

        {displayMissingAnalyticalSplitWarning && (
          <p>
            {t(
              'bookkeep.integrations.settings.baseSwitch.missingAnalyticalSplitWarning',
            )}
          </p>
        )}
      </div>
    </Modal>
  );
}
