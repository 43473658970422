import { Callout, FormField, TextInput } from '@dev-spendesk/grapes';
import React from 'react';

import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';

import styles from './AccountPayableCreationModal.module.css';
import { type AccountPayableCreationModalState } from './AccountPayableCreationModalState';
import { type AccountPayable } from '../../settings/accounting';

type AccountPayableCreationFormProps = {
  createdAccount: AccountPayable;
  setCreatedAccount: (account: AccountPayable) => void;
  error: AccountPayableCreationModalState['error'];
};

function getErrorTranslation(
  error:
    | 'codeAlreadyExists'
    | 'invalidPattern'
    | 'required'
    | 'integrationValidationFailed',
  t: TGlobalFunctionTyped,
  createdAccount: AccountPayable,
): string | undefined {
  switch (error) {
    case 'required':
      return t('bookkeep.accountsPayable.createAccountPayable.errors.required');
    case 'invalidPattern':
    case 'integrationValidationFailed':
      return t(
        'bookkeep.accountsPayable.createAccountPayable.errors.invalidPattern',
      );
    case 'codeAlreadyExists':
      if (!createdAccount.auxiliaryAccountCode) {
        return t(
          'bookkeep.accountsPayable.createAccountPayable.errors.generalAccountCodeAlreadyExists',
        );
      }
      return t(
        'bookkeep.accountsPayable.createAccountPayable.errors.auxiliaryAccountCodeAlreadyExists',
      );
    default:
      return undefined;
  }
}

export const AccountPayableCreationForm = (
  props: AccountPayableCreationFormProps,
) => {
  const { createdAccount, setCreatedAccount, error } = props;
  const { t } = useTranslation('global');

  const setFormValue = (
    key: 'generalAccountCode' | 'auxiliaryAccountCode',
    value: string,
  ) => {
    return setCreatedAccount({
      ...createdAccount,
      [key]:
        key === 'generalAccountCode' ? value ?? undefined : value || undefined,
    });
  };

  return (
    <>
      <div className={styles.CreateAccountPayable}>
        <FormField
          label={t('bookkeep.accountsPayable.list.headers.generalAccount')}
          htmlFor="set-account-general-account-code"
          className={styles.CreateAccountPayable__col}
        >
          <TextInput
            value={createdAccount.generalAccountCode.trim()}
            isInvalid={Boolean(
              error &&
                ['codeAlreadyExists', 'invalidPattern', 'required'].includes(
                  error,
                ),
            )}
            placeholder={t(
              'bookkeep.accountsPayable.createAccountPayable.enterCode',
            )}
            onChange={(e) =>
              setFormValue('generalAccountCode', e.target.value.trim())
            }
            id="set-account-general-account-code"
          />
        </FormField>
        <FormField
          label={t('bookkeep.accountsPayable.list.headers.auxiliaryAccount')}
          htmlFor="set-account-auxiliary-account-code"
          hint={t('misc.optional')}
          className={styles.CreateAccountPayable__col}
        >
          <TextInput
            value={
              createdAccount?.auxiliaryAccountCode
                ? createdAccount?.auxiliaryAccountCode.trim()
                : ''
            }
            isInvalid={Boolean(
              error &&
                ['codeAlreadyExists'].includes(error) &&
                createdAccount?.auxiliaryAccountCode,
            )}
            placeholder={t(
              'bookkeep.accountsPayable.createAccountPayable.enterCode',
            )}
            onChange={(e) =>
              setFormValue('auxiliaryAccountCode', e.target.value.trim())
            }
            id="set-account-auxiliary-account-code"
          />
        </FormField>
      </div>
      {error && getErrorTranslation(error, t, createdAccount) ? (
        <Callout
          title={getErrorTranslation(error, t, createdAccount)}
          variant="alert"
          className={styles.CreateAccountPayableError}
        />
      ) : null}
    </>
  );
};
