export enum NotificationType {
  Success = 'success',
  Danger = 'danger',
}

export type Notification = {
  id: number;
  type: NotificationType;
  message: React.ReactNode;
  description?: React.ReactNode;
  onClose?: () => void;
  action?: {
    text: string;
    isLoading?: boolean;
    onClick: (notification: Notification) => void;
  };
  canExpire?: boolean;
};

export type NotificationPayload = {
  type?: NotificationType;
  canExpire?: boolean;
  message: React.ReactNode;
  description?: React.ReactNode;
  isClosable?: boolean;
  onClose?: () => void;
  action?: {
    text: string;
    isLoading?: boolean;
    onClick: (notification: Notification) => void;
  };
};

export type PushNotif = (input: {
  type?: NotificationType;
  message: React.ReactNode;
  description?: React.ReactNode;
  canExpire?: boolean;
  isClosable?: boolean;
  onClose?: () => void;
  action?: {
    text: string;
    isLoading?: boolean;
    onClick: (notification: Notification) => void;
  };
}) => void;

export type DangerNotif = (
  message: React.ReactNode,
  description?: React.ReactNode,
) => void;

export type SuccessNotif = (
  message: React.ReactNode,
  description?: React.ReactNode,
) => void;
