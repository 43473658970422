import { ListBox } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { type Member } from '../../member';
import { MemberDisplay } from '../MemberDisplay/MemberDisplay';

import './ListMembers.css';

type Props = {
  members: Member[];
  onClick?(clickedMember: Member): void;
};

export const ListMembers = ({ members, onClick }: Props) => {
  const { t } = useTranslation('global');

  return (
    <ListBox
      key="listwithusers"
      className={classNames('ListMembers__list', {
        'ListMembers__list--empty': members.length === 0,
      })}
      rowHeight="compact"
      options={members}
      emptyState={{
        title: t('controlRulesModal.members.noMembersSelected'),
      }}
      getOptionId={(option) => option.id}
    >
      {(option) => (
        <MemberDisplay key={option.id} member={option} onClick={onClick} />
      )}
    </ListBox>
  );
};
