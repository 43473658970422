import classNames from 'classnames';
import React from 'react';

import { TextFitter } from '../../TextFitter';

export const EntityGenericStatisticsBlock = ({
  title,
  contents,
  isInactive = false,
  stack = false,
}: {
  title: string;
  contents: React.ReactNode[];
  stack?: boolean;
  isInactive?: boolean;
}) => (
  <div
    className={classNames(
      'box-border flex h-full w-full flex-col',
      stack ? 'flex-start' : 'justify-between',
      'gap-xxs',
    )}
  >
    <div className="text-pretty uppercase text-neutral-dark body-s">
      <TextFitter maxLines={2}>{title}</TextFitter>
    </div>
    {React.Children.map(contents, (content) => (
      <div
        key={content?.toString()}
        className={isInactive ? 'text-neutral' : ''}
      >
        {content}
      </div>
    ))}
  </div>
);
