import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { SubnavigationItem } from 'src/core/modules/requests/utils/navigation';

import { NavLink } from '../NavLink/NavLink';

export function useRequestLink(companyId: string) {
  const user = useUser();

  return routeFor(routes.REQUESTS.path, {
    company: companyId,
    type: user.is_approver
      ? SubnavigationItem.ToApprove
      : SubnavigationItem.All,
  });
}

type Props = Readonly<{
  company: {
    id: string;
  };
}>;

/**
 * Request entry
 * Used only by Requester
 */
export const RequestLink = ({ company }: Props) => {
  const { t } = useTranslation('global');
  const requestLink = useRequestLink(company.id);

  return (
    <NavLink
      href={requestLink}
      activePaths={[
        // Cannot use REQUESTS.path because we need to exclude `requests/inbox`
        '/app/:company/requests/all',
        '/app/:company/requests/to-approve',
        '/app/:company/requests/mine',
        '/app/:company/requests/drafts',
      ]}
      iconName="cube"
    >
      {t('misc.request_plural')}
    </NavLink>
  );
};
