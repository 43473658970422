import { InfoTip } from '@dev-spendesk/grapes';
import React from 'react';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { formatMoney } from "src/core/utils/money";

import { type Member } from '../../models/member';

type getPolicyProps = {
  member: Member;
  company: {
    currency: string;
  };
  t: TGlobalFunctionTyped;
};
export function getPolicy({ member, company, t }: getPolicyProps) {
  const { policy } = member;

  if (member.isAccountOwner) {
    return (
      <div className="MembersTable__textSecondary MembersTable__ellipsis">
        {t('members.table.noApprovalPolicy')}
      </div>
    );
  }

  if (!policy) {
    return (
      <div className="MembersTable__textSecondary MembersTable__ellipsis">
        {t('members.table.noApprovalPolicy')}
      </div>
    );
  }

  let tooltipContent: React.ReactChild | React.ReactChild[] = '';

  switch (policy.params?.approvalNeeded) {
    case 'always':
      tooltipContent = t('approvalPolicy.table.always') as string;
      break;
    case 'never':
      tooltipContent = t('approvalPolicy.table.never') as string;
      break;
    // eslint-disable-next-line unicorn/no-useless-switch-case
    case 'sometimes':
    default:
      tooltipContent = (
        <ul>
          <li>
            {t('members.countAmountPerTransaction', {
              amount: formatMoney(
                policy.params?.amountPerTransaction ?? 0,
                company.currency,
              ),
            })}
          </li>
          <li>
            {t('members.countSpendingLimit', {
              amount: formatMoney(
                policy.params?.amountPerMonth ?? 0,
                company.currency,
              ),
            })}
          </li>
        </ul>
      );
      break;
  }

  if (member.isRequester) {
    return (
      <div className="MembersTable__textPrimary MembersTable__iconContainer">
        <div className="MembersTable__ellipsis">
          {policy.isCustom
            ? t('members.table.customApprovalRule')
            : policy.name}
        </div>
        {policy.isCustom && <InfoTip content={tooltipContent} />}
      </div>
    );
  }

  return <></>;
}

export function getPolicyName({ member, t }: getPolicyProps) {
  const { policy } = member;

  if (!policy || member.isAccountOwner) {
    return '';
  }

  if (member.isRequester) {
    if (policy.isCustom) {
      return t('members.table.customApprovalRule');
    }
    return policy.name;
  }

  return '';
}
