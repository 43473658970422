import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { type RequiredInfoTypeForEmployeeBankAddress } from '../modules/members/models/member';
import { type AppState } from '../reducers';

export const getIsFeatureEnabled = (
  { global }: Pick<AppState, 'global'>,
  feature: string,
) => {
  const { config } = global;
  const features: Record<string, boolean> = get(config, 'features', {});

  return (
    Object.prototype.hasOwnProperty.call(features, feature) &&
    Boolean(features[feature])
  );
};

export const getConfig = (state: AppState) => state.global.config;

export const getBankInfoIsLoading = ({ global }: AppState) =>
  global.bankInfoBehavior.isLoading;

export const getBankInfoError = ({ global }: AppState) =>
  global.bankInfoBehavior.error;

export const getBankInfo = ({ global }: AppState) =>
  global.user?.bankInfo || {};

export const getSupervisor = ({ global }: AppState) => global.supervisor;

export const getIsSupervisionActive = (state: AppState) =>
  Boolean(getSupervisor(state));

export const getIsSupervisedUserSwitchedModalVisible = ({
  switchedSupervisedUserModal,
}: AppState) => Boolean(switchedSupervisedUserModal.isVisible);

export const getCompanyCurrency = ({ global }: AppState) =>
  global.company?.currency as string;

export const getCompanyCountry = ({ global }: AppState) =>
  global.company?.country;

export const getRequiredInfoTypesForEmployeeBankAddress = ({
  global,
}: AppState): RequiredInfoTypeForEmployeeBankAddress[] => {
  const companyId = global.company?.id as string;
  return companyId
    ? (global.user?.data_by_company[companyId]
        ?.requiredInfoTypesForEmployeeBankAddress ?? [])
    : [];
};

export const getCompany = (state: AppState) => state.global.company;

export const getUserDataByCompany = (state: AppState) => {
  const companyId = state.global.company?.id;
  return companyId ? state.global.user?.data_by_company[companyId] : undefined;
};

export const getIsLimitedAccessActive = (state: AppState) =>
  Boolean(
    // company is null by default and this selector is used in LayoutContainer
    state.global.company &&
      // there is no limited access with bankable
      state.global.company.banking_provider === 'marqeta' &&
      // if the company has been validated in forest, it's not limited access anymore
      !state.global.company.validated_at,
  );

export const getIsLoadingPaymentMethods = (state: AppState) =>
  state.global.paymentMethodsBehavior.isLoading;

export const getCompanyPaymentMethods = (state: AppState) =>
  state.global.company?.paymentMethods;

export const getHasRequesterHomepage = (state: AppState) =>
  !!state.global.config?.ui.showRequesterHomepage;

export const getHasControllerHomepage = (state: AppState) =>
  !!state.global.config?.ui.showControllerHomepage;

export const getIsWelcomeDashboardDismissed = (state: AppState) =>
  !!state.global.config?.ui.isOperationalTasksDismissed;

export const getShowRequesterOnboardingPopup = (state: AppState) =>
  !!state.global.config?.ui.showRequesterOnboardingPopup;

export const getIsReimburseViaSepaEnabled = (state: AppState) =>
  !!state.global.config?.ui.isReimburseViaSepaEnabled;

// TODO@expense-migration@finance-ops: remove this selector when the migration is done
export const getIsExpenseMigratedToTransferScheduling = (state: AppState) =>
  !!state.global.config?.ui.isExpenseMigratedToTransferScheduling;

export const getHasHomepage = (state: AppState) =>
  getHasRequesterHomepage(state) || getHasControllerHomepage(state);

export const getShowKybProcedureValidatedPopup = (state: AppState) =>
  !!state.global.config?.ui?.showKybProcedureValidatedPopup;

export const getTargetApp = (state: AppState) =>
  state.global.config?.ui?.targetApp;

export const getUser = (state: AppState) => state.global.user;
export const getUserId = (state: AppState) => state.global.user?.id;

export const getIsController = (state: AppState) =>
  !!state.global.user?.is_controller;
export const getIsAccountOwner = (state: AppState) =>
  !!state.global.user?.is_account_owner;

export const getUserRoles = createSelector(getUser, (user) => {
  return {
    isRequester: !!user?.is_requester,
    isAdmin: !!user?.is_admin,
    isController: !!user?.is_controller,
    isAccountOwner: !!user?.is_account_owner,
  };
});

export const getUserCostCenter = (state: AppState) =>
  state.global.user?.costCenter;

export const getHasImpersonationTargets = (state: AppState) =>
  state.global.hasImpersonationTargets;

export const getImpersonator = (state: AppState) => state.global.impersonator;
