import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import { invalidateCommitmentsCountsQueries } from 'src/core/modules/app/hooks/useFetchCommitmentsCountsQuery';

import { invalidatePurchaseOrdersQueries } from '../../hooks/useFetchPurchaseOrdersQuery';
import { type PurchaseOrderDetails } from '../models';

export type ClosePurchaseOrderError = {
  outcome: 'notClosed';
  reason:
    | 'alreadyExists'
    | 'forbidden'
    | 'noAttachedInvoices'
    | 'purchaseOrderNotFound'
    | 'statusNotOpened'
    | 'unpaidInvoices';
};

export const useClosePurchaseOrderMutation = (
  purchaseOrderId: string,
): MutationState<object, PurchaseOrderDetails, ClosePurchaseOrderError> => {
  return useMutation<
    object,
    PurchaseOrderDetails,
    PurchaseOrderDetails,
    ClosePurchaseOrderError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/purchase-orders/${purchaseOrderId}/close`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }): void => {
        invalidateCommitmentsCountsQueries(client);
        invalidatePurchaseOrdersQueries(client);
      },
    },
    reshapeData: (data) => data,
  });
};
