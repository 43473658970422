import {
  useBudgetaryExerciseQuery,
  USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE,
} from 'modules/budgets/containers/hooks/useBudgetaryExerciseQuery';
import { isRequestError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

export const useIsUserAllowedToAccessBudgetaryExercise = (
  budgetaryExerciseId: string,
): QueryState<boolean> => {
  const budgetaryExerciseQuery = useBudgetaryExerciseQuery(budgetaryExerciseId);

  switch (budgetaryExerciseQuery.status) {
    case 'loading':
      return { status: 'loading' };

    case 'success':
      return { status: 'success', data: true };

    case 'error': {
      if (
        isRequestError(budgetaryExerciseQuery.error) &&
        budgetaryExerciseQuery.error.data.errorCode ===
          USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE
      ) {
        return { status: 'success', data: false };
      }

      return budgetaryExerciseQuery;
    }

    default:
      rejectUnexpectedValue(
        'budgetaryExerciseQueryStatus',
        budgetaryExerciseQuery,
      );
  }
};
