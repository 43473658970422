import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type ImportExpenseAccountRequestSuccess = Record<string, never>;

type ExpenseAccountWorksheetErrorPosition = {
  column: number;
  row: number;
};

export type ExpenseAccountImportError = {
  position?: ExpenseAccountWorksheetErrorPosition;
  errorCode: string;
  message: string;
};

export type ImportExpenseAccountRequestError = {
  errors: ExpenseAccountImportError[];
};

export const useExpenseAccountsMutation = (): MutationState<
  FormData,
  ImportExpenseAccountRequestSuccess,
  ImportExpenseAccountRequestError
> => {
  return useMutation<
    FormData,
    ImportExpenseAccountRequestSuccess,
    ImportExpenseAccountRequestSuccess,
    ImportExpenseAccountRequestError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/accounting-integration/chart-of-accounts/expense-accounts/import`,
      method: 'post',
    },
    reshapeData(data) {
      return data;
    },
    options: {
      throwOnError: false,
      async onSuccess({ client }) {
        await client.invalidateQueries('getExpenseAccounts');
        await client.invalidateQueries('useGetExpenseAccountsQuery'); // expense category -> expense account automation
        await client.invalidateQueries('useGetExpenseAccountLazyQuery', {
          // this allows to refetch lazy queries that have been fetched at least once
          refetchInactive: true,
        }); // expense category -> expense account automation
        await client.invalidateQueries('useIntegrationStatusQuery'); // expense account async validation for native
        client.invalidateQueries('useExpenseAccountsQuery'); // expense account list in Prepare
        client.invalidateQueries('useExpenseAccountQuery'); // expense account detail in Prepare
        client.invalidateQueries('useTaxAndExpenseAccountsCountsQuery'); // account tips in Prepare
      },
    },
  });
};

export const reshapeToFormData = (
  expenseAccounts: File,
  prefixWithCode: boolean,
): FormData => {
  const formData = new FormData();
  formData.append('expenseAccounts', expenseAccounts, expenseAccounts.name);
  formData.append('prefixWithCode', prefixWithCode ? 'true' : 'false');
  return formData;
};
