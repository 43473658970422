import { Callout } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useIsUserManagedByKombo } from '../../../integrations/hooks/useIsUserManagedByKombo';

type Props = {
  userId: string;
  hasBankAccountInfoInHrTool: boolean;
};

export const UserManagedByKomboCallout = ({
  userId,
  hasBankAccountInfoInHrTool,
}: Props) => {
  const { t } = useTranslation('global');

  const isUserManagedByKombo = useIsUserManagedByKombo({ userId });

  if (!isUserManagedByKombo) {
    return null;
  }

  return (
    <Callout
      className="mb-s"
      title={t('integration.members.userActiveIntegrationTitle')}
    >
      {hasBankAccountInfoInHrTool
        ? t('integration.members.userActiveIntegrationContent')
        : t('integration.members.userActiveIntegrationNamesOnlyContent')}
    </Callout>
  );
};
