import includes from 'lodash/includes';
import map from 'lodash/map';
import trim from 'lodash/trim';

import appConfig from 'src/core/config';

import {
  type ApiCard,
  type CardActivationStatus,
  type CardStatus,
} from '../card';

type User = {
  is_admin: boolean;
  is_account_owner: boolean;
  id: string;
};

const getNextAllowedStatus = (card: { status: CardStatus }): CardStatus[] => {
  switch (card.status) {
    case 'ACT':
      return ['BLO', 'LOS', 'STO', 'DAM'];
    case 'BLO':
      return ['ACT', 'LOS', 'STO', 'DAM'];
    case 'PRE':
      return ['LOS', 'STO', 'DAM'];
    case 'CBL':
      return ['ACT', 'LOS', 'STO'];
    default:
      return [];
  }
};

export const getVisibleNumbers = (
  cardVisibleNumber: string,
  cardStatus: CardStatus,
) => {
  const numbersParts = map(cardVisibleNumber?.split('*'), trim);
  const areNumbersVisible = !!cardStatus && cardStatus !== 'PRE';
  const hasFirstNumbers = numbersParts.length > 1;
  const firstNumbers =
    areNumbersVisible && hasFirstNumbers && numbersParts
      ? (numbersParts ?? []).at(0)
      : '....';
  const lastNumbers =
    areNumbersVisible && numbersParts ? (numbersParts ?? []).at(-1) : '....';

  return numbersParts
    ? `${firstNumbers} .... .... ${lastNumbers}`
    : '.... .... .... ....';
};

export const amountToReload = (card: ApiCard) =>
  Math.max(0, card.monthly_budget - card.available);

const currentUserCanReload = (card: ApiCard, user: User) =>
  user.is_admin || user.is_account_owner || user.id === card.user.id;

export const canBeReloaded = (
  card: ApiCard,
  company: { balance_available_all_accounts: number },
  user: User,
) => {
  const toReload = amountToReload(card);
  return Boolean(
    currentUserCanReload(card, user) &&
      (company.balance_available_all_accounts === undefined ||
        company.balance_available_all_accounts > toReload) &&
      card.automatic_reload_fail,
  );
};

const isFirstActivationRequired = (card: ApiCard) => card.status === 'PRE';

export const isStatusChangeAllowed = (
  card: ApiCard,
  user: User,
  nextStatus: CardStatus,
) => {
  // Car holder only can activate its card
  if (nextStatus === 'ACT') {
    if (isFirstActivationRequired(card) && card.user_id !== user.id) {
      return false;
    }
    if (
      card.status === 'BLO' &&
      user.id !== card.blocker_id &&
      !user.is_admin
    ) {
      return false;
    }
  }
  return includes(getNextAllowedStatus(card), nextStatus);
};

export const getIsCardOrdered = (cardStatus: CardActivationStatus) =>
  cardStatus === 'ordered';
export const getIsCardBlocked = (cardStatus: CardActivationStatus) =>
  cardStatus === 'blocked';
export const getIsCardBlockedBySchedule = (cardStatus: CardActivationStatus) =>
  cardStatus === 'blockedBySchedule';
export const getIsCardBlockedByCompletionRule = (
  cardStatus: CardActivationStatus,
) => cardStatus === 'blockedByCompletionRule';
export const cashWithdrawalEnabled = (card: ApiCard) =>
  card.cash_withdrawal_allowed;

export const getCardBlockingAction = (
  card: Pick<ApiCard, 'activation_status' | 'blocker_id'>,
) => {
  const activationStatus = card.activation_status;
  const isCardBlocked = getIsCardBlocked(activationStatus);
  const hasCardBeenBlockedByUser = card.blocker_id;
  const isCardBlockedBySchedule = getIsCardBlockedBySchedule(activationStatus);
  const isCardBlockedByCompletionRule =
    getIsCardBlockedByCompletionRule(activationStatus);
  const isCardBlockedByAProcess =
    isCardBlockedBySchedule || isCardBlockedByCompletionRule;

  if (!isCardBlocked) {
    return 'pause';
  }

  if (hasCardBeenBlockedByUser) {
    return 'unpause';
  }

  // When a blocked card is not blocked by a user and not blocked by a process,
  // cardStatus = "blocked" and card.blocker_id = null
  //
  // This is the case when a card was blocked by schedule or completion rule
  // but lifting restriction failed - card remains blocked.
  // This allows for users to unblock the card.
  // TODO: https://spendesk.atlassian.net/browse/BANKGATE-741
  if (!isCardBlockedByAProcess) {
    return 'unpause';
  }

  return 'pause';
};

// DON'T USE THIS UTILS FUNCTION ANYMORE, USE REDUX ACTION
// TODO: remove these functions
export const doCardAction = async (
  companyId: string,
  cardId: string,
  actionType:
    | 'pause'
    | 'unpause'
    | 'lost'
    | 'damaged'
    | 'stolen'
    | 'cancelled'
    | 'pinsms'
    | 'topup'
    | 'unload'
    | 'denyWithdrawal',
  body: object | null,
  callback: (
    error: { registered?: boolean; name: string } | null,
    payload?: ApiCard,
  ) => void,
) => {
  const allowedActions = [
    'pause',
    'unpause',
    'lost',
    'damaged',
    'stolen',
    'cancelled',
    'pinsms',
    'topup',
    'unload',
    'denyWithdrawal',
  ];

  if (!includes(allowedActions, actionType)) {
    return;
  }

  try {
    const res = await fetch(
      `${appConfig.apiUrls.api}/${companyId}/cards/${cardId}/${actionType}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        credentials: 'include',
        body: body && JSON.stringify(body),
      },
    );
    const payload = await res.json();
    if (res.status >= 300) {
      throw payload;
    }
    return callback(null, payload);
  } catch (error) {
    if (error && error.error) {
      return callback(error.error);
    }
    return callback(error);
  }
};
