import {
  type CountryCode,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  isSupportedCountry,
  parsePhoneNumber,
} from 'libphonenumber-js';

export const getCountryFromNumber = (value: string | null) => {
  if (value) {
    try {
      const parsedPhoneNumber = parsePhoneNumber(value);

      if (parsedPhoneNumber.country) {
        return parsedPhoneNumber.country;
      }

      const possibleCountries = parsedPhoneNumber.getPossibleCountries();

      if (possibleCountries.length > 0) {
        return possibleCountries[0];
      }
    } catch {
      return undefined;
    }
  }
  return undefined;
};

export const getNationalNumberFromNumber = (value: string | null) => {
  if (value) {
    try {
      const { nationalNumber } = parsePhoneNumber(value);
      if (nationalNumber) {
        return nationalNumber;
      }
    } catch {
      return undefined;
    }
  }
  return undefined;
};

export function formatPhoneNumber(value: string | null, country: string) {
  if (!value || !isSupportedCountry(country)) {
    return '';
  }

  const callingCode = `+${getCountryCallingCode(country as CountryCode)}`;
  const formattedPhoneNumber = formatIncompletePhoneNumber(
    value.replaceAll(/\s/g, ''),
    country as CountryCode,
  );

  // If a character other than a number was typed
  if (formattedPhoneNumber === callingCode) {
    return '';
  }

  // Remove calling code from the number as Grapes already displays it
  return formattedPhoneNumber.replace(`${callingCode} `, '');
}
