/* eslint-disable @typescript-eslint/naming-convention */
import { useQueryClient } from 'react-query';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

export type KomboEmployeePreview = {
  fullName: string;
  workEmail: string;
};

type KomboSettingsPreviewCategory = {
  total: number;
  employees: KomboEmployeePreview[];
};

export type KomboSettingsPreview = {
  total: number;
  invites: KomboSettingsPreviewCategory;
  outOfSyncs: KomboSettingsPreviewCategory;
  // this is only sent if automatic archiving is enabled
  archives?: KomboSettingsPreviewCategory;
  hasNoEffect: boolean;
  hasUnmatchedFilters: boolean;
};

export type SettingsPreviewParams = {
  work_location?: string[];
  legal_entity?: string[];
  cost_center?: string[];
  department?: string[];
  team?: string[];
  automatic_archiving: boolean;
  sync_reporting_managers: boolean;
};

export const useGetKomboSettingsPreviewQuery = (
  params: SettingsPreviewParams,
  onSuccessCallback: (data: KomboSettingsPreview) => void,
): QueryState<KomboSettingsPreview> => {
  const isKomboEnabled = useFeature(FEATURES.HR_INTEGRATIONS);

  return useQuery<KomboSettingsPreview, KomboSettingsPreview>({
    key: ['integrations', 'kombo', 'preview', params],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/integrations/kombo/settings-preview`,
      params: {
        'automatic-archiving': params.automatic_archiving ? 'true' : 'false',
        'sync-reporting-managers': params.sync_reporting_managers
          ? 'true'
          : 'false',
        ...(params.work_location && {
          'work-location-ids': params.work_location,
        }),
        ...(params.legal_entity && { 'legal-entity-ids': params.legal_entity }),
        ...(params.cost_center && { 'cost-center-ids': params.cost_center }),
        ...(params.department && { 'department-ids': params.department }),
        ...(params.team && { 'team-ids': params.team }),
      },
    },
    options: {
      onSuccess: (data) => {
        onSuccessCallback(data.data);
      },
      cacheTime: 5 * 60 * 1000, // 5min,
      staleTime: 5 * 60 * 1000, // 5min,
    },
    isEnabled: isKomboEnabled,
  });
};

export const useInvalidateGetKomboSettingsPreviewQuery = () => {
  const queryClient = useQueryClient();
  return (params: SettingsPreviewParams) =>
    queryClient.invalidateQueries(['integrations', 'kombo', 'preview', params]);
};
