import { type ReactNode } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { ApprovalPolicySpendingRule } from './ApprovalPolicySpendingRule';
import { PlayByTheRulesSpendingRule } from './PlayByTheRulesSpendingRule';
import approvalPolicyImage from './assets/approval-policy.svg';
import receiptsPhysicalCardsImage from './assets/receipts-physical-cards.svg';

type Props = {
  incompletePaymentsLimit: number;
  completionDeadline: number;
};

export const SpendingRules = ({
  incompletePaymentsLimit,
  completionDeadline,
}: Props) => {
  const { t } = useTranslation('global');
  const hasApprovalFlowFeature = useFeature(FEATURES.APPROVAL_FLOWS);

  return (
    <section className="box flex flex-col content-stretch gap-s">
      <SpendingRuleBlock
        title={
          hasApprovalFlowFeature
            ? t('control.approvalPolicyWidget.approvalPolicyTitle')
            : t('control.approvalPolicyWidget.spendingPolicyTitle')
        }
        image={approvalPolicyImage}
        content={
          <ApprovalPolicySpendingRule
            hasFeatureEnabled={hasApprovalFlowFeature}
          />
        }
      />
      <hr className="separator" style={{ borderTop: 'none' }} />
      <SpendingRuleBlock
        title={t('homepage.rules.panel.title')}
        image={receiptsPhysicalCardsImage}
        content={
          <PlayByTheRulesSpendingRule
            incompletePaymentsLimit={incompletePaymentsLimit}
            completionDeadline={completionDeadline}
          />
        }
      />
    </section>
  );
};

type SpendingRuleBlockProps = {
  title: string;
  image: string;
  content: ReactNode;
};

const SpendingRuleBlock = ({
  title,
  image,
  content,
}: SpendingRuleBlockProps) => {
  return (
    <div className="flex items-center gap-xs">
      <div className="flex h-[136px] w-[136px] items-center justify-center">
        <img src={image} alt={title} />
      </div>
      <div className="flex flex-col content-stretch gap-s">
        <h3 className="text-complementary title-l">{title}</h3>
        <div className="text-neutral-dark body-m">{content}</div>
      </div>
    </div>
  );
};
