import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import handHoldingPhone from 'modules/StrongCustomerAuthentication/images/hand-holding-phone.png';

import './ScaWireTransferConfirmationModal.css';

interface Props {
  isOpen: boolean;
  onUseAlternateMethod: () => void;
  onClose: () => void;
}

export const ScaWireTransferConfirmationModal = ({
  isOpen,
  onUseAlternateMethod,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen={isOpen}
      iconName="lock"
      iconVariant="primary"
      title={t('wiretransfer.modal.sca.confirmWireTransfer.title')}
      subtitle={t('wiretransfer.modal.sca.confirmWireTransfer.desc')}
      actions={[
        <Button
          key="cancel"
          text={t('wiretransfer.modal.sca.confirmWireTransfer.cancel')}
          variant="secondary"
          onClick={onClose}
        />,
      ]}
    >
      <div className="ScaWireTransferConfirmationModal__content">
        <div className="ScaWireTransferConfirmationModal__img__background">
          <img
            className="ScaWireTransferConfirmationModal__img"
            src={handHoldingPhone}
            alt="Hand holding a phone"
          />
        </div>

        <Button
          className="ScaWireTransferConfirmationModal__btn-alternative"
          key="alternative"
          text={t(
            'wiretransfer.modal.sca.confirmWireTransfer.useAlternateMethod',
          )}
          variant="ghost"
          onClick={onUseAlternateMethod}
        />
      </div>
    </Modal>
  );
};
