import cx from 'classnames';
import React, { type ReactNode } from 'react';

import './GrapesSectionHeader.css';

type Props = {
  title: ReactNode;
  subtitle?: ReactNode;
  leftContent?: ReactNode;
  className?: string;
};

export const GrapesSectionHeader = ({
  title,
  subtitle,
  leftContent,
  className,
}: Props) => {
  return (
    <div className={cx('GrapesSectionHeader', className)}>
      <div>
        <h1 className="title-xl">{title}</h1>
        <div className="mr-s text-neutral-dark body-m">{subtitle}</div>
      </div>
      <div className="whitespace-nowrap">{leftContent}</div>
    </div>
  );
};
