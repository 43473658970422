import {
  type CustomField,
  type CustomFieldType,
  type CustomFieldScope,
  type CustomFieldEligibleEntity,
} from 'modules/bookkeep/prepare-payables/models';
import { type SupplierAccountDefaultFor } from 'modules/bookkeep/settings/accounting';
import { getCodeWithAuxiliaryAccounts } from 'src/core/utils/accountPayable';

export type FieldValues = {
  teams: {
    values: FieldValue[];
    valuesTotalCount: number;
  };
  customFields: CustomField[];
  costCenters?: {
    values: FieldValue[];
    valuesTotalCount: number;
  };
  supplierAccounts: {
    values: FieldValue<SupplierAccountDefaultFor>[];
    valuesTotalCount: number;
  };
};

export type FieldValue<T = string> = {
  key: string;
  label: string;
  archiveDate?: string | null;
  defaultFor?: T;
};

export type RawData = {
  company: {
    teams: {
      totalCount: number;
      edges: { node: RawFieldValue }[];
    };
    chartOfAccounts: {
      supplierAccounts: {
        totalCount: number;
        edges: {
          node: RawSupplierAccount;
        }[];
      };
    };
    customFields: RawCustomField[];
  };
};

type RawFieldValue = {
  id: string;
  name: string | boolean;
  archiveDate?: string | null;
};

export type RawSupplierAccount = {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode?: string | undefined;
  isArchived?: boolean;
  defaultFor?: SupplierAccountDefaultFor;
};

export type RawCustomField = {
  id: string;
  name: string;
  optional: boolean;
  isSplittable: boolean;
  canCreateValue: boolean;
  scopes: CustomFieldScope[];
  eligibleEntities: CustomFieldEligibleEntity[];
  archiveDate: string | null;
  values: {
    totalCount: number;
    edges: {
      node: RawFieldValue;
    }[];
  };
  booleanValues: RawFieldValue[];
  __typename: 'CustomFieldList' | 'CustomFieldBoolean';
};

const reshapeFieldValues = (edges: { node: RawFieldValue }[]): FieldValue[] =>
  edges.map(({ node }) => ({
    key: node.id,
    label: `${node.name}`,
    archiveDate: node.archiveDate,
  }));

// TODO: merge CustomFieldType with the typename
const reshapeCustomFieldsType = (
  typename: 'CustomFieldList' | 'CustomFieldBoolean',
): CustomFieldType => {
  if (typename === 'CustomFieldBoolean') {
    return 'Boolean';
  }
  return 'List';
};

export const reshapeTeamsResultData = (teams: {
  totalCount: number;
  edges: { node: RawFieldValue }[];
}): {
  values: FieldValue[];
  valuesTotalCount: number;
} => ({
  values: reshapeFieldValues(teams.edges),
  valuesTotalCount: teams.totalCount,
});

const reshapeSupplierAccount = (
  supplierAccount: RawSupplierAccount,
): FieldValue<SupplierAccountDefaultFor> => ({
  key: supplierAccount.id,
  label: getCodeWithAuxiliaryAccounts(supplierAccount),
  defaultFor: supplierAccount.defaultFor,
});

export const reshapeSupplierAccountsResultData = (supplierAccounts: {
  totalCount: number;
  edges: {
    node: RawSupplierAccount;
  }[];
}): {
  values: FieldValue<SupplierAccountDefaultFor>[];
  valuesTotalCount: number;
} => ({
  values: supplierAccounts.edges.map(({ node }) =>
    reshapeSupplierAccount(node),
  ),
  valuesTotalCount: supplierAccounts.totalCount,
});

const reshapeCustomFieldsScopes = (scopes: { team: { id: string } }[]) =>
  scopes.map((scope) => ({
    team: {
      id: scope.team?.id,
    },
  }));

export const reshapeCustomFieldsResultData = (
  customFields: RawCustomField[],
): CustomField[] =>
  customFields.map(
    ({
      id,
      name,
      optional,
      isSplittable,
      canCreateValue,
      scopes,
      eligibleEntities,
      archiveDate,
      values,
      booleanValues,
      __typename,
    }) => {
      const type = reshapeCustomFieldsType(__typename);
      let customFieldValues = null;
      if (values && type === 'List') {
        customFieldValues = values.edges
          .map(({ node }) => ({
            id: node.id,
            name: node.name,
            archiveDate: node.archiveDate,
          }))
          .filter((customFieldValue) => !customFieldValue.archiveDate);
      }
      if (booleanValues && type === 'Boolean') {
        customFieldValues = booleanValues;
      }

      return {
        id,
        name,
        type,
        isOptional: optional,
        isSplittable,
        allowNewValue: canCreateValue,
        scopes: reshapeCustomFieldsScopes(scopes),
        eligibleEntities,
        archiveDate,
        values: customFieldValues || [],
        valuesTotalCount: values ? values.totalCount : 0,
      };
    },
  );

export const reshapeCustomFieldsValuesResultData = (
  customFields: RawCustomField[],
): FieldValue[] => {
  return customFields.length > 0
    ? customFields[0].values.edges
        .map(({ node }) => {
          return {
            key: node.id,
            label: `${node.name}`,
            archiveDate: node.archiveDate,
          };
        })
        .filter((node) => node.label !== '')
    : [];
};

export const reshapeFieldValuesResultData = (data: RawData): FieldValues => ({
  teams: reshapeTeamsResultData(data.company.teams),
  supplierAccounts: reshapeSupplierAccountsResultData(
    data.company.chartOfAccounts.supplierAccounts,
  ),
  customFields: reshapeCustomFieldsResultData(data.company.customFields),
});
