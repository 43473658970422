import {
  type MonetaryValue,
  fromNumber,
  add,
  subtract,
  maximum,
  greaterThanOrEqual,
  minimum,
} from 'ezmoney';
import uniqueId from 'lodash/uniqueId';

import { type ItemLine, type ItemLineErrors } from '../../../models';

// TODO: refine types so we don't need optional chaining
// TODO: add unit tests

export const getTotalNetAmount = (
  itemLines: ItemLine[],
  currency: string,
): MonetaryValue => {
  return (
    itemLines.reduce(
      (total, current) =>
        add(total, current.expense?.amount ?? fromNumber(0, currency, 2)),
      fromNumber(0, currency, 2),
    ) ?? fromNumber(0, currency, 2)
  );
};

export const getTotalVatAmount = (
  itemLines: ItemLine[],
  currency: string,
): MonetaryValue => {
  return itemLines.reduce(
    (total, current) =>
      add(total, current.vat?.amount ?? fromNumber(0, currency, 2)),
    fromNumber(0, currency, 2),
  );
};

export const getTotalGrossAmount = (
  totalNetAmount: MonetaryValue,
  totalVatAmount: MonetaryValue,
): MonetaryValue => add(totalNetAmount, totalVatAmount);

export const computeNetAmountFromVat = (
  vatAmount: MonetaryValue | null,
  grossAmount: MonetaryValue,
  currency: string,
): MonetaryValue => {
  if (greaterThanOrEqual(grossAmount, fromNumber(0, currency, 2))) {
    return maximum(
      fromNumber(0, currency, 2),
      subtract(grossAmount, vatAmount ?? fromNumber(0, currency, 2)),
    );
  }
  return minimum(
    fromNumber(0, currency, 2),
    subtract(grossAmount, vatAmount ?? fromNumber(0, currency, 2)),
  );
};

export const unwrapUniqErrors = (errors: ItemLineErrors[]): string[] => {
  const output: string[] = [];

  const insertIfNotExists = (
    value: string | undefined,
    array: string[],
  ): void => {
    if (value && !array.includes(value)) {
      output.push(value);
    }
  };

  errors.forEach((error) => {
    insertIfNotExists(error?.expense?.accountId, output);
    insertIfNotExists(error?.expense?.amount, output);
    insertIfNotExists(error?.uniqueAssociation, output);
    insertIfNotExists(error?.vat?.accountId, output);
    insertIfNotExists(error?.vat?.amount, output);
  });

  return output;
};

export const getHasMixedSigns = (
  itemLines: ItemLine[] | undefined,
): boolean => {
  if (!itemLines) {
    return false;
  }
  return itemLines.some((itemLine) => {
    const amountsAreNumbers =
      Number.isInteger(itemLine.expense?.amount?.amount) &&
      Number.isInteger(itemLine.vat?.amount?.amount);

    if (!amountsAreNumbers) {
      return false;
    }

    // Note: asserting non-null as the number check above already ensures that
    // values exist.
    const amountsArePositive =
      (itemLine.expense?.amount?.amount ?? 0) >= 0 &&
      (itemLine.vat?.amount?.amount ?? 0) >= 0;
    const amountsAreNegative =
      (itemLine.expense?.amount?.amount ?? 0) <= 0 &&
      (itemLine.vat?.amount?.amount ?? 0) <= 0;

    return !(amountsArePositive || amountsAreNegative);
  });
};

export const getEmptyItemLine = (currency: string): ItemLine => {
  const id = Number(uniqueId());

  return {
    id,
    expense: {
      name: '',
      accountId: null,
      amount: fromNumber(0, currency, 2),
    },
    vat: {
      name: '',
      accountId: null,
      amount: fromNumber(0, currency, 2),
      itemLineId: id,
      rate: null,
    },
    analyticalFieldAssociations: [],
  };
};
