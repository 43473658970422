import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import type { RemoveNotification } from '../../redux/actionTypes';
import { removeNotification } from '../../redux/actions';
import { getNotificationsStack } from '../../redux/selectors';
import { Notification } from '../Notification/Notification';

import './NotificationsStack.css';

export const NotificationsStack = () => {
  const notificationsStack = useSelector(getNotificationsStack);
  const dispatch = useDispatch();

  const handleRemoveNotification = useCallback(
    (payload: RemoveNotification['payload']) => {
      dispatch(removeNotification(payload));
    },
    [dispatch],
  );

  if (notificationsStack.length === 0) {
    return null;
  }

  const domNode = document && document.getElementById('react__notifications');

  if (domNode) {
    return ReactDOM.createPortal(
      <div className="NotificationsStack">
        {notificationsStack.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
            removeNotification={handleRemoveNotification}
          />
        ))}
      </div>,
      domNode,
    );
  }

  return null;
};
