import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  emails: string[];
};

type Result = (
  | {
      email: string;
      isValid: true;
    }
  | {
      email: string;
      isValid: false;
      reason: 'invalid_email' | 'registered_in_another_organisation';
    }
)[];

export const useCheckEmailsCanBeInvited = () => {
  return useMutation<Payload, Result, Result>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: 'invites/check-emails',
      method: 'post',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
    },
  });
};
