import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type DefaultEmployeeAccount } from '../../../../../../../accounting/employeeAccount';

interface Props {
  integration: AccountingSoftware;
  isOpen: boolean;
  setModalState: Dispatch<
    SetStateAction<{ kind: 'closed' | 'confirmEdit' | 'confirmDelete' }>
  >;
  onSetDefault: (
    defaultEmployeeAccount: DefaultEmployeeAccount,
  ) => Promise<void>;
  defaultEmployeeAccount: DefaultEmployeeAccount | undefined;
}

export const EmployeeAccountDefaultDeleteConfirmationModal = ({
  integration,
  isOpen,
  setModalState,
  onSetDefault,
  defaultEmployeeAccount,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t(
        'bookkeep.integrations.settings.employeeAccountsTable.confirmDefaultDeleteTitle',
        {
          code: defaultEmployeeAccount
            ? defaultEmployeeAccount.generalAccountCode
            : '',
        },
      )}
      subtitle={
        hasIntegrationFileBasedExport(integration)
          ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
          : t(
              'bookkeep.integrations.settings.employeeAccountsTable.confirmDefaultDeleteSubtitle',
            )
      }
      isOpen={isOpen}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          onClick={() => {
            onSetDefault({
              ...(defaultEmployeeAccount || {
                generalAccountCode: '',
                auxiliaryAccountCode: undefined,
                name: '',
              }),
              isArchived: true,
            });
            setModalState({ kind: 'closed' });
          }}
          text={t(
            'bookkeep.integrations.settings.employeeAccountsTable.confirmDeletion',
          )}
          variant="alert"
        />,
      ]}
    />
  );
};
