export const getInvoiceForwardEmailAddress = (
  companyId: string,
  environmentName: string,
) => {
  switch (environmentName) {
    case 'production':
      return `bills+${companyId}@spendesk.com`;
    case 'demo':
      return `bills-demo+${companyId}@spendesk.com`;
    default:
      // other environmentName found in settings.json5 will be sent to the test gmail account
      return `bills-test+${companyId}@spendesk.com`;
  }
};
