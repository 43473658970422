import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { LegacySubscriptionItem } from './LegacySubscriptionItem';
import {
  formatBaseItem,
  formatMiddleTierItem,
  formatExtraItem,
  type USAGE_UNITS,
  type ADDON_NAMES,
} from '../helpers';

type Props = {
  unit: keyof typeof USAGE_UNITS | keyof typeof ADDON_NAMES;
  usage: {
    base: MonetaryValue;
    factor: MonetaryValue;
    floor: number;
  }[];
};
const LegacySubscriptionUsageItem = ({ unit, usage }: Props) => {
  const { t } = useTranslation('global');
  // For now, we don't handle empty usage for constant price
  if (usage.length === 0) {
    return null;
  }

  const firstUsage = usage[0];
  const { currency } = firstUsage.base;
  let base = '';
  const extra = [];

  // Format base
  if (firstUsage.base.amount === 0) {
    const value = usage[1] ? usage[1].floor : Number.POSITIVE_INFINITY;
    const monetaryValue = firstUsage.factor;
    base = formatBaseItem(value, { monetaryValue, unit, currency, t });
  }

  // Format middle tiers item
  if (usage.length > 2) {
    usage.slice(1, -1).forEach((currentUsage, index) => {
      const from = currentUsage.floor + 1;
      const to = usage[index + 2].floor; // +2 because we want the next item (+1) and we iterate in a `usage` subarray starting at 1
      const value = currentUsage.factor;
      extra.push(
        formatMiddleTierItem(from, to, value, {
          unit,
          currency,
          t,
        }),
      );
    });
  }

  // Format extra tier item
  if (usage.length > 1) {
    // @ts-expect-error usage.at(-1) can't be undefined
    const value = usage.at(-1).factor;
    extra.push(formatExtraItem(value, { unit, t }));
  }

  return (
    <LegacySubscriptionItem
      base={base}
      extra={extra.filter((value) => value !== undefined)}
    />
  );
};

export default LegacySubscriptionUsageItem;
