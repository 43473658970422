import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { NavigationLink } from 'src/core/common/components/NavigationLink';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
  };
  className?: string;
  onClick?: () => void;
};

export const MembersLink = ({ company, className, onClick }: Props) => {
  const { t } = useTranslation('global');
  const { pathname: currentPath } = useLocation();
  const isNewNavigationEnabled = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  if (isNewNavigationEnabled) {
    return (
      <NavigationLink
        data-marketing="top-menu_members"
        key="nav_members"
        className={className}
        onClick={onClick}
        path={routeFor(routes.COMPANY_POLICIES.path, {
          company: company.id,
        })}
        text={t('members.navLinks.policies')}
        isActive={Boolean(
          matchPath(currentPath, {
            path: [
              routes.COMPANY_POLICIES.path,
              routes.COMPANY_CONTROL_RULES.path,
            ],
          }),
        )}
      />
    );
  }

  return (
    <NavigationLink
      data-marketing="top-menu_members"
      key="nav_members"
      className={className}
      onClick={onClick}
      path={routeFor(routes.COMPANY_MEMBERS.path, {
        company: company.id,
        id: '',
      })}
      text={t('settings.links.members')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [
            routes.COMPANY_MEMBERS.path,
            routes.COMPANY_TEAMS.path,
            routes.COMPANY_POLICIES.path,
            routes.COMPANY_CONTROL_RULES.path,
          ],
        }),
      )}
    />
  );
};
