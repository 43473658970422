import {
  Button,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Tag,
} from '@dev-spendesk/grapes';
import React, { type ReactNode, useState, useId } from 'react';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useIsCurrencyBranchAllowed } from 'modules/app/hooks/useIsCurrencyBranchAllowed';

import { AccountTypeButton } from './AccountTypeButton';
import newCompany from './img/newCompany.svg';
import sameCompany from './img/sameCompany.svg';
import { ACCOUNT_TYPES, type AccountTypes } from '../../models/accountTypes';

export type InitialSelectableAccountType = Exclude<
  AccountTypes,
  'EXPENSE_ENTITY'
>;

type AccountChooserModalProps = {
  onSelect: (accountType: InitialSelectableAccountType) => void;
  onCancel: () => void;
};

export const AccountChooserModal = ({
  onSelect,
  onCancel,
}: AccountChooserModalProps) => {
  const company = useCompany();
  const { t } = useTranslation('global');
  const allowCurrencyBranch = useIsCurrencyBranchAllowed();
  const titleId = useId();
  const formId = useId();

  const [newAccountType, setNewAccountType] = useState<
    InitialSelectableAccountType | undefined
  >();

  let currencyBranchSubtitle: ReactNode = t('branch.newCurrencySubtitle');

  if (company.banking_provider === 'sfs') {
    currencyBranchSubtitle = t('branch.newSFSCurrencySubtitle');
  }

  if (!allowCurrencyBranch.isAllowed) {
    if (allowCurrencyBranch.reasonKey === 'branch.comingSoon') {
      currencyBranchSubtitle = (
        <>
          <p>{currencyBranchSubtitle}</p>
          <Tag className="mt-xs" variant="info">
            {t(allowCurrencyBranch.reasonKey)}
          </Tag>
        </>
      );
    } else {
      currencyBranchSubtitle = t(allowCurrencyBranch.reasonKey);
    }
  }

  return (
    <ErrorBoundary context={{ scope: 'modal-new-account', team: 'kyc' }}>
      <ModalOverlay isOpen>
        <ModalContent onClose={onCancel} aria-labelledby={titleId}>
          <h1
            id={titleId}
            className="mt-l text-center text-complementary title-xl"
          >
            {t('branch.accountChooserModalTitle')}
          </h1>
          <ModalBody>
            <form
              id={formId}
              aria-labelledby={titleId}
              className="grid grid-cols-2 gap-xxl"
              onSubmit={(event) => {
                event.preventDefault();
                if (newAccountType) {
                  onSelect(newAccountType);
                }
              }}
            >
              <AccountTypeButton
                image={newCompany}
                name="accountType"
                title={t('branch.newCompanyTitle')}
                subtitle={t('branch.newCompanySubtitle')}
                isChecked={newAccountType === ACCOUNT_TYPES.ENTITY}
                onClick={() => setNewAccountType(ACCOUNT_TYPES.ENTITY)}
                value={ACCOUNT_TYPES.ENTITY}
              />

              <AccountTypeButton
                name="accountType"
                image={sameCompany}
                title={t('branch.newCurrencyTitle')}
                subtitle={currencyBranchSubtitle}
                isChecked={newAccountType === ACCOUNT_TYPES.CURRENCY}
                onClick={() => setNewAccountType(ACCOUNT_TYPES.CURRENCY)}
                value={ACCOUNT_TYPES.CURRENCY}
                isDisabled={!allowCurrencyBranch.isAllowed}
              />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              key="cancel"
              text={t('misc.cancel')}
              variant="secondary"
              onClick={onCancel}
            />
            <Button
              key="continue"
              text={t('misc.continue')}
              variant="primary"
              iconName="caret-right"
              iconPosition="right"
              type="submit"
              form={formId}
              isDisabled={!newAccountType}
            />
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </ErrorBoundary>
  );
};
