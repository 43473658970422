import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  completionDeadline: number;
  incompletePaymentsLimit: number;
  userId: string;
};

export const useCreateCustomControlRuleMutation = () => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/control-rules/custom',
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
  });
};
