import { type MonetaryValue } from 'ezmoney';
import React from 'react';
import { useSelector } from 'react-redux';

import { useWalletTransactionForecastQuery } from 'modules/company';
import { getShowWarningWhenNotEnoughFundsForWireTransfer } from 'modules/reimbursements/redux/selectors';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';

import WireTransferModal from './WireTransferModal';

type Props = {
  amount: MonetaryValue;
  count: number;
  isSendingToPayment: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSendToPayment: (options?: { onSuccess: () => void }) => void;
  onComplete: () => void;
};

export const WireTransferModalContainer = ({
  amount,
  count,
  isSendingToPayment,
  isOpen,
  onClose,
  onSendToPayment,
  onComplete,
}: Props) => {
  const showWarningWhenNotEnoughFundsForWireTransfer = useSelector(
    getShowWarningWhenNotEnoughFundsForWireTransfer,
  );
  const walletTransactionForecastQuery =
    useWalletTransactionForecastQuery()(amount);

  return (
    <QuerySuspense
      queryState={walletTransactionForecastQuery}
      loading={null}
      fallback={() => (
        // In case of error in the query, we fallback to the 'normal' flow (without not enough funds warning)
        // Since it shouldn't be blocking for the wire transfer flow
        <WireTransferModal
          amount={amount}
          count={count}
          isSendingToPayment={isSendingToPayment}
          isOpen={isOpen}
          showNotEnoughFundsWarning={false}
          onClose={onClose}
          onSendToPayment={onSendToPayment}
          onComplete={onComplete}
        />
      )}
    >
      {({ hasEnoughFunds }) => {
        const showNotEnoughFundsWarning =
          showWarningWhenNotEnoughFundsForWireTransfer && !hasEnoughFunds;

        return (
          <WireTransferModal
            amount={amount}
            count={count}
            isSendingToPayment={isSendingToPayment}
            isOpen={isOpen}
            showNotEnoughFundsWarning={showNotEnoughFundsWarning}
            onClose={onClose}
            onSendToPayment={onSendToPayment}
            onComplete={onComplete}
          />
        );
      }}
    </QuerySuspense>
  );
};
