import cx from 'classnames';
import React, { type ReactNode } from 'react';

import styles from './ViewerLoader.module.css';
import { FileViewerToolbar } from '../FileViewerToolbar/FileViewerToolbar';

type Props = {
  className?: string;
  leftActions?: ReactNode[];
  rightActions?: ReactNode[];
};

export const ViewerLoader = ({
  className = '',
  leftActions = [],
  rightActions = [],
}: Props) => {
  return (
    <div
      className={cx(
        styles.ViewerLoader,
        'flex-[1_0_24rem] shadow-10',
        className,
      )}
    >
      <FileViewerToolbar
        leftActions={leftActions}
        rightActions={rightActions}
      />
    </div>
  );
};
