import {
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tag,
} from '@dev-spendesk/grapes';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { SessionStorageKey } from 'src/core/constants/storage';
import { type AppState } from 'src/core/reducers';
import {
  getSessionItem as getItem,
  setSessionItem as setItem,
} from 'src/core/utils/storage';

import { WalletSectionSubHeader } from '../../../components/WalletSectionSubHeader';
import { CheckBankStatementFormContainer } from '../../containers/CheckBankStatementFormContainer';
import * as achSelectors from '../../redux/selectors';

type Props = {
  accountSuffix: string | null;
  onUpdateBankInfo?: () => void;
};

const CheckBankStatementModalContainer = (props: Props) => {
  const { accountSuffix, onUpdateBankInfo } = props;
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('global');

  useEffect(() => {
    const hasSessionKey = Boolean(
      getItem(SessionStorageKey.CheckYourBankStatement),
    );

    if (!hasSessionKey) {
      setIsVisible(true);
      setItem(SessionStorageKey.CheckYourBankStatement, '1');
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  const hide = () => setIsVisible(false);

  return (
    <ModalOverlay isOpen>
      <ModalContent className="w-[720px]">
        <ModalBody className="text-left">
          <h1 className="mb-m text-complementary title-xl">
            {t('ach.checkBankStatementTitle')}
          </h1>
          <WalletSectionSubHeader>
            {t('ach.checkBankStatementAccount')}
            <Tag className="ml-xs" variant="neutral">
              {t('ach.accountSuffixTag', { suffix: accountSuffix })}
            </Tag>
          </WalletSectionSubHeader>

          <CheckBankStatementFormContainer
            showLinkLater
            onLinkLater={hide}
            onUpdateBankInfo={onUpdateBankInfo}
          />
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

const mapStateToProps = (state: AppState) => ({
  accountSuffix: achSelectors.getAchSourceAccountSuffix(state),
});

const ConnectedCheckBankStatementModalContainer = connect(mapStateToProps)(
  CheckBankStatementModalContainer,
);

export { ConnectedCheckBankStatementModalContainer as CheckBankStatementModal };
