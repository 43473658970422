import { type ThunkDispatch } from '@reduxjs/toolkit';

import { insertId } from 'common/utils/object';
import { NotificationType, addNotification } from 'modules/app/notifications';
import { getSelectedCompanyId } from 'modules/company';
import { baseAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';

import * as approvalActions from './actions';
import type { ApprovalAction } from './actions';
import { toApiFormat, shouldRemoveAoRule, removeAoRule } from '../models';
import { type ApprovalRule } from '../types';

export const fetchSchema = (teamId: string) => {
  return async (
    dispatch: ThunkDispatch<AppState, null, ApprovalAction>,
    getState: () => AppState,
  ) => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(approvalActions.fetchSchemaRequest());
    try {
      const { data } = await baseAPI.get(
        `/${companyId}/approval-schemes/${teamId}`,
      );
      const approvalScheme = shouldRemoveAoRule(data)
        ? removeAoRule(data)
        : data;
      dispatch(
        approvalActions.fetchSchemaSuccess({
          ...approvalScheme,
          rules: approvalScheme.rules.map(insertId),
        }),
      );
    } catch (error) {
      dispatch(approvalActions.fetchSchemaFailure(error.response.data));
    }
  };
};

export const saveSchema = (
  teamId: string,
  rules: ApprovalRule[],
  successMessage: string,
  errorMessage: string,
) => {
  return async (
    dispatch: ThunkDispatch<AppState, null, ApprovalAction>,
    getState: () => AppState,
  ): Promise<void> => {
    const companyId = getSelectedCompanyId(getState());
    const schema = {
      teamId,
      companyId,
      rules: toApiFormat(rules),
    };

    dispatch(approvalActions.saveSchemaRequest());
    try {
      const { data } = await baseAPI.post(
        `/${companyId}/approval-schemes/${teamId}`,
        schema,
      );
      const approvalScheme = shouldRemoveAoRule(data)
        ? removeAoRule(data)
        : data;
      dispatch(
        approvalActions.saveSchemaSuccess({
          ...approvalScheme,
          rules: approvalScheme.rules.map(insertId),
        }),
      );
      dispatch(
        addNotification({
          type: NotificationType.Success,
          message: successMessage,
        }),
      );
    } catch (error) {
      dispatch(approvalActions.saveSchemaFailure(error.response.data));
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: errorMessage,
        }),
      );
    }
  };
};
