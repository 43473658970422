import {
  Button,
  SkeletonAvatar,
  SkeletonButton,
  SkeletonText,
  Tag,
} from '@dev-spendesk/grapes';

import { useQueryStates } from 'src/core/api/hooks';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { ApprovalRulesContainer } from 'src/core/modules/company/structure/approval-flows';

import { useApprovalSchemeQuery } from '../../hooks/useApprovalScheme';
import { useMembersQuery } from '../../hooks/useMembersQuery';

type Props = {
  onCancel: () => void;
};

export const StandardApprovalFlowPanelContent = ({ onCancel }: Props) => {
  const queryStates = useQueryStates({
    states: {
      approvalScheme: useApprovalSchemeQuery('aoh2gdgyw39yk6'), // approvalSchemeId is mocked
      members: useMembersQuery(),
    },
  });

  return (
    <>
      <div>
        <Tag variant="primary">Standard approval workflow</Tag>
        <div className="mb-m mt-s text-neutral-darker body-s">
          This condition will be executed if no other workflow applies.
          <br />
          This ensures that any request has a reviewer.
        </div>
      </div>
      <div className="mb-xs text-complementary title-m">
        Approval required from
      </div>
      <div className="mb-m">
        <QuerySuspense
          loading={<Loading />}
          fallback={(error) => (
            <QueryError queryError={error} componentType="Callout" />
          )}
          queryState={queryStates}
        >
          {({ approvalScheme, members }) => {
            return (
              <ApprovalRulesContainer
                rules={approvalScheme.rules}
                members={members}
                costCenterOwnerId="21ce9b01-cd0a-4e56-9d22-c2522953cdb8" // costCenterOwnerId is mocked
                onChange={() => null}
                mode="approvalByDimensionsPanel"
              />
            );
          }}
        </QuerySuspense>
      </div>
      <div className="mt-auto flex justify-between gap-s">
        <Button
          text="Save"
          className="flex-grow"
          variant="primary"
          onClick={() => {
            onCancel();
          }}
        />
        <Button
          text="Cancel"
          className="flex-grow"
          variant="secondary"
          onClick={() => {
            onCancel();
          }}
        />
      </div>
    </>
  );
};

const Loading = () => {
  return (
    <div className="flex flex-col gap-xs pb-s">
      <SkeletonButton width="183px" />
      <div className="my-xs flex items-center gap-xs">
        <SkeletonAvatar />
        <SkeletonText size="m" width="180px" />
      </div>
      <SkeletonButton width="142px" />
      <SkeletonButton width="100%" />
    </div>
  );
};
