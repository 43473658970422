import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { addDefaultCity, type AllowanceLocation } from '../allowanceLocation';

export const useGetAllowanceLocationsQuery = (): QueryState<
  AllowanceLocation[]
> => {
  return useQuery<AllowanceLocation[], AllowanceLocation[]>({
    key: ['allowanceLocations'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/per-diem/legal-locations?year=2023`,
    },
    reshapeData: (data: AllowanceLocation[]) =>
      data.map((allowanceLocation) => {
        if (allowanceLocation.citiesWithAdjustedRates.length) {
          return addDefaultCity(allowanceLocation);
        }
        return allowanceLocation;
      }),
  });
};
