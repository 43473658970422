import type * as Money from 'ezmoney';

export enum SupplierBillStatus {
  InReview = 'in-review',
  ToSchedule = 'to-schedule',
  Paid = 'paid',
}

export type SupplierBill = {
  invoiceRequestId: string;
  invoiceNumber: string;
  amount: Money.MonetaryValue;
} & (
  | {
      status: SupplierBillStatus.InReview;
      requestId: string;
    }
  | {
      status: SupplierBillStatus.ToSchedule;
      paymentToScheduleId: string;
    }
  | {
      status: SupplierBillStatus.Paid;
      latestPaymentId: string;
    }
);
