import { useHistory } from 'react-router-dom';

import { routeBuilder } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';

type Params = {
  paymentId?: string;
};

export const useGoToPayment = () => {
  const getRoute = usePaymentRoute();
  const history = useHistory();

  return (params: Params) => {
    const route = getRoute(params);
    history.push(route);
  };
};

const usePaymentRoute = () => {
  const companyId = useCompanyId();
  return (params: Params): string => getPaymentRoute(companyId, params);
};

const getPaymentRoute = (companyId: string, { paymentId }: Params): string => {
  if (paymentId) {
    return routeBuilder('PAYMENTS_ALL', {
      company: companyId,
      id: paymentId,
    });
  }

  return routeBuilder('PAYMENTS_ALL', {
    company: companyId,
  });
};
