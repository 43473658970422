type CustomFieldId = string;
type CustomFieldValueId = string;
type CustomFieldValueLabel = string;

export type CustomFieldFiltersType = {
  [key: CustomFieldId]: {
    key: CustomFieldValueId;
    label: CustomFieldValueLabel;
  } | null;
};

export function convertCustomFieldFiltersToPayload(
  customFieldFilters: CustomFieldFiltersType | undefined,
):
  | {
      [key: CustomFieldId]: CustomFieldValueId | null;
    }
  | undefined {
  if (!customFieldFilters || !Object.keys(customFieldFilters).length) {
    return;
  }

  const customFieldsPayload: {
    [key: CustomFieldId]: CustomFieldValueId | null;
  } = {};

  Object.entries(customFieldFilters).forEach(([key, value]) => {
    customFieldsPayload[key] = value?.key ?? null;
  });

  return customFieldsPayload;
}
