import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { type ExpenseAccount } from '../../accounting';

export type Payload = {
  id?: string;
  name?: string;
  code: string;
  isArchived: boolean;
};

type RawData =
  | ({ outcome: 'notSet' } & (
      | {
          reason:
            | 'defaultAlreadyExists'
            | 'accountNotFound'
            | 'adapterMismatch'
            | 'emptyCode'
            | 'emptyName';
        }
      | { reason: 'codeAlreadyExists'; existingAccount: ExpenseAccount }
    ))
  | {
      outcome: 'set';
      account: ExpenseAccount;
    };

export type Result = RawData;

export const useSetDefaultExpenseAccountMutation = (): MutationState<
  Payload,
  Result
> => {
  return useMutation<Payload, Result, RawData>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint:
        '/accounting-integration/chart-of-accounts/expense-accounts/default',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ data, client }): void => {
        if (data.outcome === 'set') {
          client.invalidateQueries('useGetDefaultExpenseAccountQuery');
          client.invalidateQueries('useExpenseAccountsQuery'); // expense account list in Prepare
          client.invalidateQueries('useExpenseAccountQuery'); // expense account detail in Prepare
          client.invalidateQueries('useGetPayableQuery'); // payable details in Prepare
          client.invalidateQueries('payable'); // cache payable details in Prepare
          client.invalidateQueries('useTaxAndExpenseAccountsCountsQuery'); // account tips in Prepare
        }
      },
    },
  });
};
