import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import { type BankingProvider } from 'src/core/reducers/global';

import { ACCOUNT_TYPES } from '../models/accountTypes';
import { type CreatedEntity } from '../utils/createBranch';

type Payload =
  | {
      country: string;
      currency: string;
      is_legal_representative: true;
    }
  | {
      currency: string;
      name: string;
      address: {
        address: string;
        address2?: string;
        city: string;
        state?: string;
        country: string;
        zipcode: string;
      };
    };

type RawResponse = {
  id: string;
  banking_provider: BankingProvider;
};

export const useCreateSFSBranchEntityMutation = (): MutationState<
  Payload,
  CreatedEntity
> => {
  const companyId = useCompanyId();

  return useMutation<Payload, CreatedEntity, RawResponse>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'baseAPI',
      endpoint: `/${companyId}/branch/sfs?type=${ACCOUNT_TYPES.ENTITY}`,
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['companies']);
        client.invalidateQueries(['companyWalletSummaries']);
      },
    },
  });
};
