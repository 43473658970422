import React from 'react';

import { useUserScaEnrolledLazyQuery } from './hooks/useUserScaEnrolledLazyQuery';

export function withSCA<T>(
  WrappedComponent:
    | React.ComponentClass<T & { isUserEnrolled: boolean }>
    | React.FunctionComponent<T & { isUserEnrolled: boolean }>,
) {
  return function Sca(props: T) {
    const [fetchUserScaEnrolled, userScaEnrolledLazyQueryState] =
      useUserScaEnrolledLazyQuery();

    const isUserEnrolled =
      userScaEnrolledLazyQueryState.status === 'success' &&
      userScaEnrolledLazyQueryState.data.isScaUserEnrolled;

    React.useEffect(() => {
      fetchUserScaEnrolled();
    }, []);

    const passDownProps = { ...props, isUserEnrolled };

    return React.createElement(WrappedComponent, passDownProps);
  };
}
