import { type Invite, type RawInvite } from '../models/invite';

/**
 * Return both camelCase and snake_case, deeply
 * snake_case is used for legacy reasons and should not be called in new code
 */
export const reshapeInvite = (data: RawInvite): Invite => {
  return {
    companyId: data.company_id,
    companyIds: data.company_ids,
    createdAt: data.created_at,
    dataByCompany: data.data_by_company,
    email: data.email,
    id: data.id,
    invitedUser: {
      avatar: data.invited_user.avatar,
      companyIds: data.invited_user.company_ids,
      email: data.invited_user.email,
      firstName: data.invited_user.first_name,
      fullname: data.invited_user.fullname,
      gender: data.invited_user.gender,
      isOrganisationOwner: data.invited_user.is_organisation_owner,
      lastName: data.invited_user.last_name,
      nbCompanies: data.invited_user.nb_companies,
      pending: data.invited_user.pending,
    },
    invitedUserId: data.invited_user_id,
    key: data.key,
    lastSendAt: data.last_send_at,
    nbSendAuto: data.nb_send_auto,
    nbSendManual: data.nb_send_manual,
    organisationId: data.organisation_id,
    updatedAt: data.updated_at,
    userId: data.user_id,
  };
};
