import * as Money from 'ezmoney';

// amount availableForNewRequests === total - allocatedOnCards;
export type WalletAmountAllocation =
  | 'total'
  | 'availableForNewRequests'
  | 'allocatedOnCards';

export type WalletSummary = {
  id: string;
  name: string;
  totalAmount?: Money.MonetaryValue;
};

export type DetailedWalletSummary = WalletSummary & {
  totalAmount: Money.MonetaryValue;
  totalAllocated: Money.MonetaryValue;
  walletDetails: {
    allocatedOnCards: {
      totalAmount: Money.MonetaryValue;
      subscriptionCardsAmount: Money.MonetaryValue;
      physicalCardsAmount: Money.MonetaryValue;
      singlePurchaseCardsAmount: Money.MonetaryValue;
    };
    scheduledTransfers?: {
      totalAmount: Money.MonetaryValue;
      expenseClaimsAmount: Money.MonetaryValue;
      invoicesAmount: Money.MonetaryValue;
    };
    available: {
      totalAmount: Money.MonetaryValue;
    };
  };
};

export const hasDetailedWalletSummary = (
  walletSummary: WalletSummary | DetailedWalletSummary,
): walletSummary is DetailedWalletSummary => {
  return 'walletDetails' in walletSummary;
};

// TODO@financeOps: totalAllocated will always be present on detailed wallet when fully rolled out on the backend side
export const getWalletTotalAllocated = (
  walletSummary: DetailedWalletSummary,
): Money.MonetaryValue => {
  // TODO@financeOps: hotfix walletDetails was supposed to always be present...
  if (
    !('totalAllocated' in walletSummary) &&
    !('walletDetails' in walletSummary)
  ) {
    return Money.fromNumber(0, 'EUR');
  }

  return (
    walletSummary.totalAllocated ??
    walletSummary.walletDetails.allocatedOnCards.totalAmount
  );
};
