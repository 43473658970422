import { type MonetaryValue } from 'ezmoney';

import { usePayablesBucketsStatsQuery } from 'modules/bookkeep/apis/prepare-payable/usePayablesBucketsStatsQuery';

import { type BucketType } from '../models';

export const usePayablesBucketResolver = (): ((bucketId: BucketType) => {
  connectionId: string;
  totalCount: number;
  totalAmount: MonetaryValue<string>;
} | null) => {
  const payablesBucketsQueryResult = usePayablesBucketsStatsQuery();

  return (bucketId: BucketType) => {
    if (
      payablesBucketsQueryResult.status !== 'success' ||
      payablesBucketsQueryResult.data.outcome !== 'ok'
    ) {
      return null;
    }

    const {
      expensesFiltered,
      expensesWithProof,
      expensesWithoutProof,
      expensesMissingProof,
    } = payablesBucketsQueryResult.data?.bucketsStats ?? {};

    switch (bucketId) {
      case 'withProof':
        return expensesWithProof;
      case 'withoutProof':
        return expensesWithoutProof;
      case 'missingProof':
        return expensesMissingProof;
      default:
        break;
    }

    return expensesFiltered;
  };
};
