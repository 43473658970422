export type VerificationResult = {
  paymentId: string;
  responseDescription: string;
  response: 'fullMatch' | 'partialMatch' | 'noMatch' | 'unavailable';
  beneficiaryName: string;
  responseAccountHolderFullName?: string;
  suppressWarning: boolean;
};

const getKey = ({ paymentId, ...rest }: VerificationResult): string =>
  JSON.stringify(rest);

const isVerified = (verification: VerificationResult): boolean =>
  verification.response === 'fullMatch' || verification.suppressWarning;

const duplicatesReducer = (
  accumulator: VerificationResult[],
  verification: VerificationResult,
): VerificationResult[] => {
  const key = getKey(verification);
  if (accumulator.some((v) => getKey(v) === key)) {
    return accumulator;
  }
  return [...accumulator, verification];
};

export const filterUnverified = (
  verifications: VerificationResult[],
): VerificationResult[] =>
  verifications
    .filter((verification) => !isVerified(verification))
    .reduce(duplicatesReducer, []);
