import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

export type Role = {
  id: string;
  name: 'Account owner' | 'Requester' | 'Administrator' | 'Controller';
};

export type RawRole = {
  id: string;
  name: 'Account owner' | 'Requester' | 'Administrator' | 'Controller';
};

export type RawPermissions = {
  id: string;
  name: string | null;
  code: string;
  allow: boolean;
  is_default: boolean;
  is_user_custom: boolean;
  nb_users?: number;
  users: never[];
  scope_id: string;
  role_id: string;
  company_id: string | null;
  organisation_id: string | null;
  params: {
    approval_needed: 'always' | 'sometimes' | 'never';
    spending_limit: number;
    spending_types: string;
    transaction_max: number;
  };
}[];

export const translateRoleName = (
  roleName: 'Administrator' | 'Controller' | 'Requester' | 'Account owner',
  translator: TGlobalFunctionTyped,
): string => {
  switch (roleName) {
    case 'Administrator':
      return translator('misc.administrator');
    case 'Controller':
      return translator('misc.controller');
    case 'Requester':
      return translator('misc.requester');
    case 'Account owner':
      return translator('misc.accountOwner');
    default:
      return roleName;
  }
};
