import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { type IntegrationVatAccount } from '../../accounting';

export type Payload = {
  id?: string;
  name?: string;
  code: string;
  rate: string;
  isArchived?: boolean;
};

type RawData =
  | ({ outcome: 'notSet' } & (
      | {
          reason:
            | 'defaultAlreadyExists'
            | 'accountNotFound'
            | 'adapterMismatch'
            | 'emptyCode'
            | 'emptyName'
            | 'negativeTaxRate'
            | 'taxRateExceedsMaximum';
        }
      | {
          reason: 'nameAndRateAlreadyExists';
          existingAccount: IntegrationVatAccount;
        }
    ))
  | {
      outcome: 'set';
      account: IntegrationVatAccount;
    };

export type Result = RawData;

export const useSetDefaultTaxAccountMutation = (): MutationState<
  Payload,
  Result
> => {
  return useMutation<Payload, Result, RawData>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint:
        '/accounting-integration/chart-of-accounts/tax-accounts/default',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ data, client }): void => {
        if (data.outcome === 'set') {
          client.invalidateQueries('useGetDefaultTaxAccountQuery');
          client.invalidateQueries('useTaxAccountsQuery'); // tax account list in Prepare
          client.invalidateQueries('useGetPayableQuery'); // payable details in Prepare
          client.invalidateQueries('payable'); // cache payable details in Prepare
          client.invalidateQueries('useTaxAndExpenseAccountsCountsQuery'); // account tips in Prepare
        }
      },
    },
  });
};
