import { type CostCenter } from 'modules/company/cost-centers/costCenter';
import { type Gender } from 'modules/profile/models/gender';

import { type RawDataByCompany } from './member';
import type { Policy } from './policy';
import type { Role } from './roles';
import type { Team } from './teams';

export type Invite = {
  companyId: string;
  companyIds: string[];
  createdAt: string;
  dataByCompany: Record<string, RawDataByCompany>;
  email: string;
  id: string;
  invitedUser: {
    avatar: string | null;
    companyIds: string[];
    email: string;
    firstName: string | null;
    fullname: string;
    gender: Gender;
    isOrganisationOwner: boolean;
    lastName: string | null;
    nbCompanies: number;
    pending: boolean;
  };
  invitedUserId: string;
  key: string;
  lastSendAt: string;
  nbSendAuto: number;
  nbSendManual: number;
  organisationId: string;
  updatedAt: string;
  userId: string;
};

export type RawInvite = {
  company_id: string;
  company_ids: string[];
  created_at: string;
  data_by_company: Record<string, RawDataByCompany>;
  email: string;
  id: string;
  invited_user: {
    avatar: string | null;
    company_ids: string[];
    email: string;
    first_name: string | null;
    fullname: string;
    gender: Gender;
    is_organisation_owner: boolean;
    last_name: string | null;
    nb_companies: number;
    pending: boolean;
  };
  invited_user_id: string;
  key: string;
  last_send_at: string;
  nb_send_auto: number;
  nb_send_manual: number;
  organisation_id: string;
  updated_at: string;
  user_id: string;
};

export type InviteLink = {
  restrictDomain: string;
  key: string;
};

type Id = { id: string };

export type DataSources = {
  roles: Role[];
  policies: DisplayablePolicy[];
  teams: Team[];
  costCenters: CostCenter[];
};

export type InviteField = {
  value: string;
  index: number;
  valid?: boolean;
  duplicate?: boolean;
};

export type InviteSettings = {
  role?: Id;
  policy?: Id;
  team?: Id;
  costCenter?: { id: string | null };
};

export type SendInvitesPayload = {
  emails: string[];
  settings: InviteSettings;
};

export type EmailInvitationResult = {
  email: string;
  outcome: string;
};

export type InvitationResultMessage = {
  text: string;
  isError: boolean;
};

export type InviteType = 'email' | 'file_import';

export type SummaryContent =
  | {
      isDisplayed: true;
      type: InviteType;
      messages: InvitationResultMessage[];
    }
  | { isDisplayed: false };

export type InvitationResult =
  | {
      isSuccessful: true;
      numInvited: number;
      email?: string;
    }
  | {
      isSuccessful: false;
      type: InviteType;
      messages: InvitationResultMessage[];
    };

export type SettingsKeys = keyof Settings;

export type Settings = {
  role: Role;
  policy?: DisplayablePolicy;
  team?: Team;
  costCenter?: CostCenter;
};

/**
 * Defines a policy with a displayable name (i.e. non-nullable)
 *
 * An unnamed policy isn't selectable by the user.
 */
export type DisplayablePolicy = Omit<Policy, 'name'> & {
  name: NonNullable<Policy['name']>;
};

export function getWhetherInvitationIsSuccessful(invitation: {
  outcome: string;
}) {
  return [
    'newly_invited',
    'grafted_invite_to_company',
    'grafted_user_to_company',
  ].includes(invitation.outcome);
}
