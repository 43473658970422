import { FormField, AmountInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { getCurrencyOptionByKey } from 'src/core/utils/money';

import { type AccountFundingReminderForm } from './form';
import { type UserNotification } from '../../models';

type Props = {
  notification: UserNotification;
} & FormikProps<AccountFundingReminderForm>;

export const AccountFundingReminder = ({
  notification,
  // Formik props
  errors,
  values,
  setFieldValue,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const companyCurrency = useCompanyCurrency();

  return (
    <FormField label="" className="mb-s mt-xs" visuallyHideLabel>
      <AmountInput
        name="accountFundingThreshold"
        value={values.accountFundingThreshold}
        currency={getCurrencyOptionByKey(companyCurrency)}
        onBlur={() => handleSubmit()}
        onChange={(event) =>
          setFieldValue('accountFundingThreshold', event.target.valueAsNumber)
        }
        placeholder={t('notifications.accountFundingReminderPlaceholder')}
        isInvalid={Boolean(errors.accountFundingThreshold)}
        isDisabled={!notification.checked}
      />
    </FormField>
  );
};
