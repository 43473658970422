import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type { ExpenseCategory } from '../../models/expenseCategory';

type RawExpenseCategory = {
  id: string;
  name: string;
  isArchived: boolean;
};

export const useExpenseCategoriesQuery = ({
  withArchived,
}: {
  withArchived?: boolean;
}): QueryState<ExpenseCategory[]> => {
  return useQuery<ExpenseCategory[], RawExpenseCategory[]>({
    key: 'expenseCategories',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/expense-categories${
        withArchived ? '?includeArchived=true' : ''
      }`,
    },
    reshapeData: (data) => data,
  });
};
