export const PIN_REVEAL_IDS = {
  cardPin: 'mq-card-pin',
  toggleCardPin: 'mq-toggle-card-pin',
};

// Should be between 6 and 16 (hideTimeout from marqeta plus one second to take the minus one into account)
// cf https://www.marqeta.com/docs/developer-guides/using-marqeta-js
export const TIMEOUT_IN_SECONDS = 10;

export const bootstrapMarqetaPinReveal = async ({
  companyId,
  cardId,
  apiUrl,
  onRevealSuccess,
  onIframeSuccess,
  onIframeFailure,
}: {
  companyId: string;
  cardId: string;
  apiUrl: string;
  onRevealSuccess: () => void;
  onIframeSuccess?: () => void;
  onIframeFailure?: () => void;
}) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const marqetaOptions = {
      clientAccessToken: null,
      options: {
        cardholderVerificationMethod: 'OTHER',
      },
      component: {
        pinReveal: {
          cardPin: {
            domId: PIN_REVEAL_IDS.cardPin,
            styles: {
              span: {
                color: '#5d21d2',
                'font-family': 'Helvetica, Arial',
                'font-size': '56px',
                'font-weight': 400,
                background: 'transparent',
              },
            },
          },
          toggleCardPin: {
            domId: PIN_REVEAL_IDS.toggleCardPin,
            mode: 'transparent',
            onRevealSuccess,
          },
          // Not using the default marqeta timeout because it's buggy
          // (creates an error if we close the modal early)
        },
      },

      callbackEvents: {
        onSuccess: () => {
          if (onIframeSuccess) {
            onIframeSuccess();
          }
          resolve(undefined);
        },
        onFailure: (error: Error) => {
          if (onIframeFailure) {
            onIframeFailure();
          }
          reject(error);
        },
      },
    };

    const getTokenUrl = `${apiUrl}/${companyId}/cards/${cardId}/getCardSecret`;

    const response = await fetch(getTokenUrl, {
      credentials: 'include',
    });

    if (response.status < 200 || response.status >= 400) {
      return reject(new Error(response.statusText));
    }

    const body = await response.json();

    if (!body.provider || body.provider !== 'marqeta') {
      return reject(new Error('Expected provider to be marqeta'));
    }

    if (!body.data || !body.data.token) {
      return reject(new Error('Expected data to be { token: string }'));
    }
    marqetaOptions.clientAccessToken = body.data.token;

    window.marqeta.bootstrap(marqetaOptions);
  });
};
