import type { Compliances } from 'modules/bookkeep/prepare-payables/models/payable';

export type GetSpanishDPRFromCompliances = {
  hasSpanishDPR: boolean;
  state: 'ongoing' | 'done' | 'not-applicable';
};

export const getSpanishDPRFromCompliances = (
  compliances?: Compliances,
): GetSpanishDPRFromCompliances => {
  let state: GetSpanishDPRFromCompliances['state'] = 'not-applicable';

  if (compliances?.spainEreceipt) {
    state = 'ongoing';
  }

  if (
    compliances?.spainEreceipt?.receipt?.uniqueId &&
    compliances?.spainEreceipt?.receipt?.path
  ) {
    state = 'done';
  }

  return {
    hasSpanishDPR: state !== 'not-applicable',
    state,
  };
};
