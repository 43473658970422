import { type FormikErrors, useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications, NotificationType } from 'modules/app/notifications';

import { AccountFundingReminder } from './AccountFundingReminder';
import { type AccountFundingReminderForm } from './form';
import { useUpdateNotificationMutation } from '../../hooks/useUpdateNotificationMutation';
import { type UserNotification } from '../../models';

const ACCOUNT_FUNDING_DEFAULT_VALUE = 1000;

type Props = {
  notification: UserNotification;
};

export const AccountFundingReminderContainer = ({ notification }: Props) => {
  const { t } = useTranslation();
  const { pushNotif } = useNotifications();

  const [updateNotification] = useUpdateNotificationMutation();

  const formikProps = useFormik<AccountFundingReminderForm>({
    initialValues: {
      accountFundingThreshold: notification.value
        ? Number.parseInt(notification.value)
        : ACCOUNT_FUNDING_DEFAULT_VALUE,
    },
    validate: (values) => {
      const errors: FormikErrors<AccountFundingReminderForm> = {};
      if (!values.accountFundingThreshold) {
        errors.accountFundingThreshold = 'misc.required';
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await updateNotification({
          notif_code: notification.notifCode,
          checked: notification.checked,
          value: `${values.accountFundingThreshold}`,
        });
      } catch {
        pushNotif({
          type: NotificationType.Danger,
          message: t('notifications.editFailureNotification'),
        });
        return;
      }

      pushNotif({
        type: NotificationType.Success,
        message: t('notifications.editSuccessNotification'),
      });
    },
  });

  return (
    <AccountFundingReminder notification={notification} {...formikProps} />
  );
};
