import { AnalyticEventName, track } from 'src/core/utils/analytics';

export function trackPayableEdited({
  payableId,
  whatChanged,
  previousValue,
  nextValue,
  error,
  batchId,
}: {
  payableId: string;
  whatChanged:
    | 'supplier'
    | 'description'
    | 'accountPayable'
    | 'expenseAccount'
    | 'vatLine'
    | 'team'
    | 'costCenter'
    | 'customField';
  previousValue: unknown;
  nextValue: unknown;
  error: boolean;
  batchId?: string;
}): void {
  track(
    AnalyticEventName.BOOKKEEP_PREPARE_PAYABLE_EDITED,
    {
      payableId,
      whatChanged,
      previousValue,
      nextValue,
      error,
      batchId,
    },
    { Amplitude: { groups: { payableId } } },
  );
}

export function trackPayableSavedOrMarkedAsReady({
  payableId,
  markedAsReady,
  error,
  batchId,
}: {
  payableId: string;
  markedAsReady: boolean;
  error: boolean;
  batchId?: string;
}): void {
  track(
    AnalyticEventName.BOOKKEEP_PREPARE_PAYABLE_SAVED_OR_MARKED_AS_READY,
    {
      payableId,
      markedAsReady,
      error,
      batchId,
    },
    { Amplitude: { groups: { payableId } } },
  );
}

export function trackReceiptDeclaredAsInvalid({
  payableId,
}: {
  payableId: string;
}): void {
  track(AnalyticEventName.BOOKKEEP_PREPARE_RECEIPT_DECLARED_AS_INVALID, {
    payableId,
  });
}

export function trackAccountingDateEdited(
  payableId: string,
  oldDate: string | undefined,
  newDate: string,
): void {
  track(AnalyticEventName.BOOKKEEP_PREPARE_ACCOUNTING_DATE_EDITED, {
    payableId,
    oldDate,
    newDate,
  });
}
