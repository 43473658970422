import { type I18nKey } from 'common/hooks/useTranslation';

class UnknownContextError extends Error {
  constructor(context: never) {
    super(`Unknown context: ${context}`);
  }
}

export const getDescTranslationKey = (
  context: 'expense-claim' | 'invoice',
): I18nKey => {
  switch (context) {
    case 'expense-claim':
      return 'company.missingBankInfoForXmlSepaExpenseClaims';
    case 'invoice':
      return 'company.missingBankInfoForXmlSepaInvoices';
    default:
      throw new UnknownContextError(context);
  }
};
