/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */

import { Button, Panel } from '@dev-spendesk/grapes';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import { useTranslation } from 'common/hooks/useTranslation';
import { type Account } from 'modules/company/wallet/wallet';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  account: Account;
  country: string;
  displayTitleAndFooter?: boolean;
};

type DisplayMode = 'SCAN' | 'IBAN';

const getDefaultSchemeDisplayMode = (account: Account, country: string) => {
  const { iban, bic, sort_code, account_number } = account;

  if (iban && bic && !sort_code && !account_number) {
    return 'IBAN';
  }
  if (!iban && !bic && sort_code && account_number) {
    return 'SCAN';
  }
  if (country === 'GB') {
    return 'SCAN';
  }
  return 'IBAN';
};

export const CompanyBankInformation = ({
  account,
  country,
  displayTitleAndFooter = true,
}: Props) => {
  const { t } = useTranslation('global');
  const [copiedToClipBoard, copyToClipboard] = useCopyToClipboard();

  const [schemeDisplayMode, setSchemeDisplayMode] = useState<DisplayMode>(
    getDefaultSchemeDisplayMode(account, country),
  );
  const [lastCopyButtonClicked, setLastCopyButtonClicked] =
    useState<HTMLButtonElement>();

  // FIXME@Spendesk/finance-ops: remove once we can provide customers with IBANs for their GBP accounts
  const showOldIbanReference = useMemo(
    () =>
      account.version === 'v2' &&
      account.currency === 'GBP' &&
      schemeDisplayMode === 'IBAN' &&
      account.old_iban,
    [account, schemeDisplayMode],
  );

  const transferReferenceValue = useMemo<string | undefined>(
    () =>
      showOldIbanReference
        ? account.old_provider_account_id
        : account.provider_account_id,
    [showOldIbanReference],
  );

  const canToggleSchemeDisplayMode = useMemo(() => {
    const isIbanAvailable = Boolean(account.iban || account.old_iban);
    const isScanAvailable = Boolean(account.sort_code);
    return isIbanAvailable && isScanAvailable;
  }, [account]);

  useEffect(() => {
    if (!copiedToClipBoard.value || !lastCopyButtonClicked) {
      return;
    }

    lastCopyButtonClicked.textContent = t('misc.copiedText');
    setTimeout(() => {
      lastCopyButtonClicked.textContent = t('misc.copy');
    }, 2000);
  }, [copiedToClipBoard]);

  const toggleSchemeDisplayMode = () => {
    setSchemeDisplayMode(schemeDisplayMode === 'IBAN' ? 'SCAN' : 'IBAN');
  };

  const copyValue = (
    value: string | undefined,
    buttonClicked: HTMLButtonElement,
  ) => {
    if (!value) {
      return;
    }
    copyToClipboard(value);
    setLastCopyButtonClicked(buttonClicked);
  };

  const renderLine = (label: string, value: string | undefined) => {
    if (!value) {
      return null;
    }
    return (
      <div className="flex justify-evenly">
        <div className="flex flex-1 items-center">
          <span className="w-[25%] text-neutral-dark title-m">{label}</span>
          <span className="text-complementary body-m">{value}</span>
        </div>
        <Button
          text={t('misc.copy')}
          variant="ghost"
          onClick={(event) => {
            copyValue(value, event.target as HTMLButtonElement);
            track(AnalyticEventName.SETTINGS_WALLET_FUNDING_LINE_COPIED, {
              label,
            });
          }}
        />
      </div>
    );
  };

  const renderSchemeDisplayModeToggle = () => {
    return (
      <Button
        variant="ghost"
        text={`${t('wallet.bankDetails.toggleCta')} ${
          schemeDisplayMode === 'SCAN'
            ? 'IBAN'
            : t('wallet.bankDetails.sortCode')
        }`}
        onClick={toggleSchemeDisplayMode}
      />
    );
  };

  const getIbanLines = () => {
    // FIXME@Spendesk/finance-ops: remove showOldIbanReference check once we can provide customers with IBANs for their GBP accounts
    return (
      <>
        {renderLine(
          'IBAN',
          showOldIbanReference ? account.old_iban : account.iban,
        )}
        {renderLine(
          'BIC',
          showOldIbanReference ? account.old_bic : account.bic,
        )}
      </>
    );
  };

  return (
    <>
      {(displayTitleAndFooter || canToggleSchemeDisplayMode) && (
        <div className="mb-s flex justify-between text-m">
          {displayTitleAndFooter && <div>{t('wallet.bankDetails.title')}</div>}
          {canToggleSchemeDisplayMode && renderSchemeDisplayModeToggle()}
        </div>
      )}
      <Panel className="w-full">
        <div className="flex flex-col gap-xxs">
          {schemeDisplayMode === 'SCAN' && (
            <>
              {renderLine(t('wallet.bankDetails.sortCode'), account.sort_code)}
              {renderLine(
                t('wallet.bankDetails.accountNumber'),
                account.account_number,
              )}
            </>
          )}
          {schemeDisplayMode === 'IBAN' && getIbanLines()}
          {renderLine(
            t('wallet.bankDetails.beneficiary'),
            showOldIbanReference
              ? account.old_beneficiary
              : account.beneficiary,
          )}
          {renderLine(t('wallet.bankDetails.bankName'), account.bank_name)}
          {renderLine(
            t('wallet.bankDetails.bankAddress'),
            account.bank_address,
          )}
          {(account.version === 'v1' || showOldIbanReference) &&
            !!transferReferenceValue && (
              <div className="flex">
                <Trans
                  i18nKey={t(`wallet.bankDetails.transferReferenceTipStrong`)}
                  values={{ accountRef: transferReferenceValue }}
                  components={{
                    strong: <strong className="font-bold">-</strong>,
                  }}
                />

                <Button
                  text={t('misc.copy')}
                  variant="ghost"
                  onClick={(event) => {
                    copyValue(
                      transferReferenceValue,
                      event.target as HTMLButtonElement,
                    );
                  }}
                />
              </div>
            )}
        </div>
      </Panel>
      {displayTitleAndFooter && (
        <div className="mt-s text-m text-neutral-dark">
          {t('wallet.bankDetails.bankProcessingTimes')}{' '}
          <strong className="text-neutral-darker">
            {t('wallet.bankDetails.times')}
          </strong>
        </div>
      )}
    </>
  );
};
