import navLinks from 'src/core/modules/app/layout/components/Header/header-items';

type SettingsNavigationLink = (props: {
  user: {
    is_account_owner: boolean;
    is_organisation_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
  };
  company: {
    id: string;
    churning_at: string | null;
  };
  className?: string;
  onClick?: () => void;
}) => JSX.Element;

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getLeftNavigationItems({
  user,
  company,
  features,
}: {
  user: {
    is_account_owner: boolean;
    is_organisation_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
  };
  company: {
    id: string;
    churning_at: string | null;
  };
  features: {
    hasAccessToWallet: boolean;
    isCostCentersFeatureEnabled: boolean;
    hasCustomFieldsFeature: boolean;
    isBudgetsEnabled: boolean;
    isAccountingSettingsEnabled: boolean;
    isAccountsPayableSettingsEnabled: boolean;
    isAccountingExportsSettingsEnabled: boolean;
    isCostCentersSettingsEnabled: boolean;
  };
}): SettingsNavigationLink[] {
  const leftNavigationItems: SettingsNavigationLink[] = [];
  const {
    hasAccessToWallet,
    isCostCentersFeatureEnabled,
    hasCustomFieldsFeature,
    isBudgetsEnabled,
    isAccountingSettingsEnabled,
    isAccountsPayableSettingsEnabled,
    isAccountingExportsSettingsEnabled,
    isCostCentersSettingsEnabled,
  } = features;

  // Company billing
  if (
    user.is_organisation_owner ||
    user.is_account_owner ||
    user.is_controller
  ) {
    leftNavigationItems.push(navLinks.CompanyBillingLink);
  }

  // GeneralSettings
  if (user.is_account_owner || user.is_admin) {
    leftNavigationItems.push(navLinks.GeneralSettingsLink);
  }

  // Members
  if (user.is_account_owner || user.is_admin) {
    leftNavigationItems.push(navLinks.MembersLink);
  }

  // Bank
  if (hasAccessToWallet && (user.is_account_owner || user.is_controller)) {
    leftNavigationItems.push(navLinks.BankLink);
  }

  // Accounting
  if (
    isAccountingSettingsEnabled &&
    (user.is_account_owner || user.is_controller)
  ) {
    leftNavigationItems.push(navLinks.AccountingLink);
  }

  // AccountsPayable
  if (isAccountsPayableSettingsEnabled && user.is_controller) {
    leftNavigationItems.push(navLinks.AccountsPayableLink);
  }

  // Exports
  if (
    isAccountingExportsSettingsEnabled &&
    (user.is_account_owner || user.is_controller)
  ) {
    leftNavigationItems.push(navLinks.ExportsLink);
  }

  // CostCenters
  if (
    isCostCentersFeatureEnabled &&
    isCostCentersSettingsEnabled &&
    (user.is_account_owner || user.is_admin)
  ) {
    leftNavigationItems.push(navLinks.CostCentersLink);
  }

  // Categories
  if (
    (user.is_account_owner || user.is_admin || user.is_controller) &&
    hasCustomFieldsFeature
  ) {
    leftNavigationItems.push(navLinks.CategoriesLink);
  }

  // Budgets
  if (isBudgetsEnabled && (user.is_account_owner || user.is_controller)) {
    leftNavigationItems.push(navLinks.BudgetsLink);
  }

  // Integrations
  if (!company.churning_at && (user.is_account_owner || user.is_admin)) {
    leftNavigationItems.push(navLinks.IntegrationsLink);
  }

  return leftNavigationItems;
}

export function getResponsiveLeftNavigationItems({
  width,
  leftNavigationItems,
}: {
  width: number;
  leftNavigationItems: SettingsNavigationLink[];
}): SettingsNavigationLink[] {
  if (width > 1200) {
    return leftNavigationItems;
  }

  if (width > 900) {
    return leftNavigationItems.slice(0, 7);
  }

  return leftNavigationItems.slice(0, 4);
}

export function getPlusDropdownItems({
  width,
  leftNavigationItems,
}: {
  width: number;
  leftNavigationItems: SettingsNavigationLink[];
}): SettingsNavigationLink[] {
  if (width > 900) {
    return leftNavigationItems.slice(7);
  }
  return leftNavigationItems.slice(4);
}
