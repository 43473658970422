import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type Team } from '../../../models/team';

export const useTeamsQuery = (): QueryState<Team[], object> => {
  return useQuery<Team[]>({
    key: ['teams'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/groups',
    },
    reshapeData: (data) => data,
  });
};
