import { useSelector } from 'react-redux';

import {
  getBankingProvider,
  getCurrency,
} from 'modules/company/redux/selectors';

export const useIsCompanyOnSfsUk = () => {
  const bankingProvider = useSelector(getBankingProvider);
  const currency = useSelector(getCurrency);

  return bankingProvider === 'sfs' && currency === 'GBP';
};
