import { gql } from 'graphql-tag';

export const GET_SUPPLIERS = gql`
  query GetSuppliers($companyId: ID!) {
    company(id: $companyId) {
      suppliers {
        edges {
          node {
            id
            name
            thumbnailUrl
          }
        }
      }
    }
  }
`;
