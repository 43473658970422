import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useSignAffidavit = (paymentId: string): MutationState => {
  return useMutation({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: `/missing-receipt/${paymentId}/affidavit/signed`,
    },
  });
};
