import {
  Button,
  Callout,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { useExpenseCategoriesQuery } from 'modules/company/expense-categories/containers/EcContainer/hooks/useExpenseCategoriesQuery';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';
import { ExpenseCategoryTable } from 'src/core/modules/company/expense-categories/containers/EcContainer/ExpenseCategoryTable';
import { EcDeletionModalContainer } from 'src/core/modules/company/expense-categories/containers/EcDeletionModalContainer';
import {
  EcCreationFormModalContainer,
  EcEditFormModalContainer,
} from 'src/core/modules/company/expense-categories/containers/EcFormModalContainer';
import { type ExpenseCategory } from 'src/core/modules/company/expense-categories/expenseCategory';

import { SetupHubLayout } from '../../../components/SetupHubLayout';
import { VideoBlock } from '../../../components/VideoBlock';
import { routes } from '../../routes';

export const ControlSettingsExpenseCategoryList = () => {
  const { t } = useTranslation('global');
  const history = useHistory();

  const companyId = useCompanyId();

  const expenseCategoriesQueryState = useExpenseCategoriesQuery();
  const expenseCategories = unwrapQuery(expenseCategoriesQueryState) || [];

  const costCentersQueryState = useCostCentersQuery();
  const costCenters = unwrapQuery(costCentersQueryState) || [];

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingExpenseCategory, setEditingExpenseCategory] =
    useState<ExpenseCategory | null>(null);
  const [deletingExpenseCategory, setDeletingExpenseCategory] =
    useState<ExpenseCategory | null>(null);

  const hasSuccessfullyLoaded =
    expenseCategoriesQueryState.status === 'success' ||
    costCentersQueryState.status === 'success';

  const goToHub = () => {
    const hubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(hubPath);
  };

  const goBack = () => {
    const hubPath = routeFor(
      routes.CONTROL_SETTINGS_NEW_EXPENSE_CATEGORY.path,
      {
        company: companyId,
      },
    );
    history.push(hubPath);
  };

  return (
    <SetupHubLayout
      onBack={goBack}
      onNext={goToHub}
      rightContent={
        <VideoBlock
          title={t('setupHub.newExpenseCategory.video.title')}
          description={t('setupHub.newExpenseCategory.video.description')}
        />
      }
      scope="new-expense-category"
      stepIndex={1}
      stepTotal={2}
      title={t('setupHub.expenseCategoryList.title')}
    >
      <>
        <Callout
          className="mb-m"
          title={t('setupHub.expenseCategoryList.callout.title')}
        >
          <p>{t('setupHub.expenseCategoryList.callout.description')}</p>
        </Callout>
        {hasSuccessfullyLoaded ? (
          <>
            <ExpenseCategoryTable
              costCenters={costCenters}
              expenseCategories={expenseCategories}
              hasColumnSeparator
              isCostCentersFeatureEnabled
              isExpenseCategoryExpenseAccountRuleActivated={false}
              onDelete={setDeletingExpenseCategory}
              onEdit={setEditingExpenseCategory}
              rowHeight="normal"
              withBorder
            />
            <Button
              className="mt-s"
              onClick={() => setIsCreateModalOpen(true)}
              text={t('setupHub.expenseCategoryList.addCategoryCta')}
              variant="ghost"
              iconPosition="left"
              iconName="plus"
            />
          </>
        ) : (
          <SkeletonTable
            numberOfRows={1}
            columns={[
              { cell: <SkeletonText />, header: <SkeletonText /> },
              { cell: <SkeletonText />, header: <SkeletonText /> },
            ]}
            withColumnSeparator
            withHeader
          />
        )}
        <EcCreationFormModalContainer
          isOpen={isCreateModalOpen}
          onComplete={() => setIsCreateModalOpen(false)}
          onCancel={() => setIsCreateModalOpen(false)}
        />
        {editingExpenseCategory && (
          <EcEditFormModalContainer
            expenseCategory={editingExpenseCategory}
            isOpen={editingExpenseCategory !== null}
            onComplete={() => setEditingExpenseCategory(null)}
            onCancel={() => setEditingExpenseCategory(null)}
          />
        )}
        {deletingExpenseCategory && (
          <EcDeletionModalContainer
            expenseCategory={deletingExpenseCategory}
            isOpen={!!deletingExpenseCategory}
            onComplete={() => setDeletingExpenseCategory(null)}
            onClose={() => setDeletingExpenseCategory(null)}
          />
        )}
      </>
    </SetupHubLayout>
  );
};
