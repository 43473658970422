import { type Notification } from '../notification';

export const ADD_NOTIFICATION_TO_STACK = 'ADD_NOTIFICATION_TO_STACK';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export type AddNotificationToStack = Readonly<{
  type: typeof ADD_NOTIFICATION_TO_STACK;
  payload: Notification;
}>;

export type RemoveNotification = Readonly<{
  type: typeof REMOVE_NOTIFICATION;
  payload: number;
}>;

export type NotificationsActions = AddNotificationToStack | RemoveNotification;
