import { createSelector } from '@reduxjs/toolkit';

import type { BankingProvider } from 'src/core/reducers/global';

type Company = {
  id: string;
  banking_provider: BankingProvider;
  currency: string;
  country: string;
  isWireTransferFeatureActivated: boolean;
};

// FIXME: we can use the global AppState here because the company module
// doesn't follow our module architecture convention and exports an object
// which would cause circular dependencies in that case.
interface AppState {
  global: {
    company: Company | null;
  };
  billing: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bankInfo: any;
  };
}

export const getCompany = (state: AppState): Company => {
  // we assume that at this point the company will be loaded from the app bootstrap call
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return state.global.company!;
};

export const getCurrency = (state: AppState): string =>
  getCompany(state).currency;

export const getBankingProvider = (state: AppState): BankingProvider =>
  getCompany(state).banking_provider;

export const getSelectedId = (state: AppState): string => getCompany(state).id;

export const getIsMarqeta = (state: AppState): boolean =>
  Boolean(
    // company is null by default and this selector is used in LayoutContainer
    state.global.company &&
      // there is no limited access with bankable
      state.global.company.banking_provider === 'marqeta',
  );

export const getCountry = (state: AppState): string =>
  getCompany(state).country;

export const getCompanyBankInfo = (state: { billing: AppState['billing'] }) =>
  state.billing.bankInfo;

export const getIsUSMarket = createSelector(
  getIsMarqeta,
  getCurrency,
  (isMarqeta, currency) => isMarqeta && currency === 'USD',
);
