import { addDays, addMonths, addQuarters, addWeeks } from 'date-fns';

import { TypeGuardError } from 'src/core/utils/switchGuard';

import { type BookkeepingSettingsResponse } from '../../apis/useBookkeepingSettings';

export const getLockDate = (
  date: Date,
  lockAfter?: BookkeepingSettingsResponse['editPayablesAfterExport']['lockAfter'],
  lockAfterUnit?: BookkeepingSettingsResponse['editPayablesAfterExport']['lockAfterUnit'],
) => {
  if (!lockAfter || !lockAfterUnit) {
    return;
  }
  switch (lockAfterUnit) {
    case 'day':
      return addDays(date, lockAfter);
    case 'week':
      return addWeeks(date, lockAfter);
    case 'month':
      return addMonths(date, lockAfter);
    case 'quarter':
      return addQuarters(date, lockAfter);
    default:
      throw new TypeGuardError(lockAfterUnit, 'Invalid lockAfterUnit');
  }
};
