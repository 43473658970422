import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { type GraphQLError } from 'graphql';

import appConfig from 'src/core/config';
import { logger } from 'src/utils/datadog-log-wrapper';

import { expenseGroupToGraphQLDataId } from './expenseGroupToGraphQLDataId';
import introspectionQueryResultData from './fragmentTypes.json';
import { payableGroupToGraphQLDataId } from './payableGroupToGraphQLDataId';

// https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resolveCacheKeyFromObject = (object: any): string | null => {
  switch (object.__typename) {
    case 'ExpenseGroup': {
      return expenseGroupToGraphQLDataId(object);
    }
    case 'PayableGroup': {
      return payableGroupToGraphQLDataId(object);
    }
    case 'PayableConnection': {
      if (
        process.env.NODE_ENV === 'development' &&
        defaultDataIdFromObject(object) !== null
      ) {
        // eslint-disable-next-line no-console
        console.warn(`
          You queried a PayableConnection ID without using a GraphQL alias.
          This may result in unintended caching behaviour.
          Please visit the following page for more details: https://www.notion.so/spendesk/Apollo-Client-issues-bottlenecks-and-workarounds-0657662781124f82b47ea431ed969d71
        `);
      }

      // Safe guard ourselves against unintended caching of PayableConnections
      // by always returning `null` so the data is cached by query instead of
      // being normalized.
      return null;
    }
    default:
      return defaultDataIdFromObject(object);
  }
};

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: resolveCacheKeyFromObject,
});

const isAuthenticationError = (error: GraphQLError): boolean =>
  error?.extensions?.code === 'UNAUTHENTICATED';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors.some(isAuthenticationError)) {
      logger.info(
        'Redirect to login page with session-expired query param - graphQlError',
        {
          team: 'capture',
          scope: 'auth',
          currentPage: window.location.href,
        },
      );

      // Redirect to login if session has expired
      window.location.href = `/auth/login?session-expired=1&targetUrl=${encodeURIComponent(
        window.location.href,
      )}`;
      return;
    }

    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({
  uri: appConfig.apiUrls.graphqlV2,
  credentials: 'include',
});

export const graphQLClient = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV !== 'production',
  link: ApolloLink.from([errorLink, httpLink]),
  cache,
});
