import {
  isIntegrationStatusWithIntegration,
  type IntegrationStatus,
  getOAuth2OpenIdAuthorizationStatus,
  type OAuth2OpenIdAuthorizationStatus,
} from 'modules/bookkeep/integration/status';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { getAccountingIntegrationStatusQueryKey } from './query-key-registry';

export type AccountingIntegrationStatusResponse = IntegrationStatus;

export type AccountingIntegrationStatusQueryState =
  QueryState<IntegrationStatus>;

export const useAccountingIntegrationStatusQuery = (
  shouldRefresh = false,
  {
    isEnabled = true,
    staleTime = 10 * 60 * 1000 /* 10min */,
    cacheTime = 10 * 60 * 1000 /* 10min */,
  } = {},
): AccountingIntegrationStatusQueryState => {
  const companyId = useCompanyId();

  return useQuery<
    AccountingIntegrationStatusResponse,
    AccountingIntegrationStatusResponse
  >({
    key: getAccountingIntegrationStatusQueryKey(companyId),
    isEnabled,
    options: {
      cacheTime,
      staleTime,
      refetchInterval: (integrationStatus) => {
        if (shouldRefresh) {
          return 1000;
        }

        if (integrationStatus?.integration === 'switchInProgress') {
          return 2000;
        }

        if (
          integrationStatus &&
          isIntegrationStatusWithIntegration(integrationStatus) &&
          integrationStatus.authorization.kind !== 'noAuthorization'
        ) {
          return integrationStatus.authorization.status.status ===
            'fetchingTenants' ||
            integrationStatus.authorization.status.status ===
              'fetchingSubsidiaries'
            ? 1000
            : false;
        }

        return false;
      },
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/status',
    },
    reshapeData: (
      integration: AccountingIntegrationStatusResponse,
    ): AccountingIntegrationStatusResponse => {
      return handleOAuth2OpenIdRefresh(integration);
    },
  });
};

/**
 * Helpers
 */

const handleOAuth2OpenIdRefresh = (
  integration: AccountingIntegrationStatusResponse,
): AccountingIntegrationStatusResponse => {
  if (
    isIntegrationStatusWithIntegration(integration) &&
    (integration.authorization.kind === 'oauth2OpenId' ||
      integration.authorization.kind === 'oauth2ScopedOpenId')
  ) {
    return {
      ...integration,
      authorization: {
        ...integration.authorization,
        status: {
          ...integration.authorization.status,
          status: getOAuth2OpenIdAuthorizationStatus(
            integration.authorization.status,
          ),
        } as OAuth2OpenIdAuthorizationStatus,
      },
    };
  }

  return integration;
};
