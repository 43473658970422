import { Button, Modal } from '@dev-spendesk/grapes';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as mfaSelectors from 'common/components/ModalMFA/selectors';
import { type Token } from 'common/components/TokenInput';
import { useTranslation } from 'common/hooks/useTranslation';
import type { AppDispatch } from 'modules/app/redux/store';

import { ModalMFATokenContent } from './ModalMFATokenContent';
import {
  regenerateProcedure,
  validateMFAToken,
  resetTokenVerificationStates,
} from '../actions';

const MFA_TOKEN_LENGTH = 6;
const emptyMfaToken = Array.from({ length: MFA_TOKEN_LENGTH }, () => '');

type FlowProps =
  | { flow: 'auth'; scaToken: string }
  | { flow: 'standard'; scaToken?: never };

type MfaTokenModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onMfaTokenValidated: () => void;
} & FlowProps;

type TokenState = {
  token: Token;
  isTokenFilled: boolean;
};

const tokenReducer = (_: TokenState, action: Token) => {
  // make sure the new token size is always equal to MFA_TOKEN_LENGTH
  const newToken = action.concat(emptyMfaToken).slice(0, MFA_TOKEN_LENGTH);
  return {
    token: newToken,
    isTokenFilled: !newToken.includes(''),
  };
};

export const ModalMFAToken = ({
  isOpen,
  onClose,
  flow,
  scaToken,
  onMfaTokenValidated,
}: MfaTokenModalProps) => {
  const { t } = useTranslation('global');
  const dispatch = useDispatch<AppDispatch>();

  const isValidatingMfaToken = useSelector(mfaSelectors.isValidatingMfaToken);
  const isMfaTokenValid = useSelector(mfaSelectors.getIsMfaTokenValid);
  const isMfaTokenVerified = useSelector(mfaSelectors.getIsMfaTokenVerified);

  const [{ token, isTokenFilled }, setToken] = useReducer(tokenReducer, {
    token: emptyMfaToken,
    isTokenFilled: false,
  });
  const [hasRegeneratedMfaProcedure, setHasRegeneratedMfaProcedure] =
    useState<boolean>(false);

  const resetState = () => {
    setToken(emptyMfaToken);
    setHasRegeneratedMfaProcedure(false);
    dispatch(resetTokenVerificationStates());
  };

  useEffect(() => {
    if (isMfaTokenVerified && isMfaTokenValid && isOpen) {
      onMfaTokenValidated();
    }
  }, [isMfaTokenVerified, isMfaTokenValid]);

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen]);

  const handleValidateToken = () => {
    dispatch(validateMFAToken(token.join(''), flow, scaToken));
  };

  const handleRegenerateProcedure = async () => {
    setToken(emptyMfaToken);
    await dispatch(regenerateProcedure(flow, scaToken));
    setHasRegeneratedMfaProcedure(true);
  };

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="continue"
          onClick={handleValidateToken}
          text={t('mfa.modal.code.confirmCodeButton')}
          variant="primary"
          form="mfa-form"
          type="submit"
          isDisabled={!isTokenFilled || isValidatingMfaToken}
        />,
      ]}
      iconName="lock"
      iconVariant="primary"
      title={t('mfa.modal.code.title')}
      isOpen={isOpen}
    >
      <ModalMFATokenContent
        hasRegeneratedMfaProcedure={hasRegeneratedMfaProcedure}
        isMfaTokenVerified={isMfaTokenVerified}
        isMfaTokenValid={isMfaTokenValid}
        token={token}
        tokenLength={MFA_TOKEN_LENGTH}
        handleTokenChange={setToken}
        handleRegenerateProcedure={handleRegenerateProcedure}
      />
    </Modal>
  );
};
