import { Icon, type IconName, colors } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { SemiCircularSegmentedGauge } from './SemiCircularSegmentedGauge';

type Props = {
  incompletePaymentsLimit: number;
  overduePaymentsCount: number;
  isLate: boolean;
  className?: string;
};

export const LatePaymentAnalytics = ({
  incompletePaymentsLimit,
  overduePaymentsCount,
  isLate,
  className,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div
      className={classNames(className, 'box flex items-center gap-[80px]', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'bg-alert-lightest': isLate,
      })}
    >
      <SemiCircularSegmentedGauge
        totalNumberOfSegments={incompletePaymentsLimit}
        filledNumberOfSegments={overduePaymentsCount}
        text={
          isLate
            ? t('homepage.paymentsList.incompleteRequests')
            : t('homepage.paymentsList.latePayments')
        }
      />
      <div>
        <div
          className={classNames('mb-s title-m', {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'text-neutral-darker': !isLate,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'text-alert': isLate,
          })}
        >
          {isLate
            ? t('homepage.paymentsList.lostAccess')
            : t('homepage.paymentsList.soonLooseAccess')}
        </div>
        <div className="flex gap-m">
          <AnalyticsItem
            name={t('homepage.paymentsList.plasticCard')}
            iconName="plastic-card"
            isLate={isLate}
          />
          <AnalyticsItem
            name={t('homepage.paymentsList.virtualCard')}
            iconName="single-purchase-card"
            isLate={isLate}
          />
        </div>
      </div>
    </div>
  );
};

const AnalyticsItem = ({
  name,
  iconName,
  isLate,
}: {
  name: string;
  iconName: IconName;
  isLate: boolean;
}) => {
  return (
    <div className="flex items-center gap-xs text-neutral-darker body-m">
      <div
        className={classNames(
          'flex h-l w-l items-center justify-center rounded-[50%]',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'bg-white': isLate },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'bg-alert-lightest': !isLate },
        )}
      >
        <Icon color={colors.alert} name={iconName} />
      </div>
      {name}
    </div>
  );
};
