import { SkeletonText } from '@dev-spendesk/grapes';
import {
  multiply as moneyMultiply,
  toNumber as moneyToNumber,
  fromNumber as moneyFromNumber,
  equal as moneyEqual,
} from 'ezmoney';
import React from 'react';

import { type I18nKey } from 'common/hooks/useTranslation';
import {
  useAccountingIntegrationStatusQuery,
  useCheckReverseChargeTaxAccount,
} from 'modules/accounting-integration/apis';
import { useApplicableReverseChargeAccountRate } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/hooks/useApplicableReverseChargeAccountRate';
import { useTaxAccountsQuery } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/hooks/useTaxAccountsQuery';
import { computeNetAmountFromVat } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/utils/payableLines';
import {
  type Payable,
  type ItemLineVat,
  type ItemLineExpenseAccount,
} from 'modules/bookkeep/prepare-payables/models';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import {
  type AutoComputeVatAmount,
  PayableTaxAccountField,
} from './PayableTaxAccountField';
import { isReverseChargeAccount, type TaxAccount } from './tax';

export type ItemLine = {
  expense: ItemLineExpenseAccount | null;
  vat: ItemLineVat | null | undefined;
};

type Props = {
  payableItemLines: ItemLine[];
  initialPayableItemLines: ItemLine[];
  payableItemLineIndex: number;
  currency: string;
  walletCurrency: string;
  errors?: {
    accountId?: string;
    amount?: string;
  };
  hasAmountError: boolean;
  taxAccountPlaceholder?: I18nKey;
  onChange: (itemLines: ItemLine[]) => void;
  automation?: Payable['automation'];
  autoComputeVatAmount?: AutoComputeVatAmount;
} & (
  | {
      hideVatAmount?: false;
      payable: Pick<
        Payable,
        'originalAmount' | 'amount' | 'createdAt' | 'exchangeRate'
      >;
    }
  | {
      hideVatAmount: true;
    }
);

export const PayableTaxAccountFieldContainer = ({
  payableItemLines,
  initialPayableItemLines,
  payableItemLineIndex,
  errors,
  hasAmountError,
  currency,
  walletCurrency,
  hideVatAmount,
  taxAccountPlaceholder,
  onChange,
  autoComputeVatAmount: parentAutoComputeVatAmount,
  automation: parentAutomation,
  ...rest
}: Props) => {
  const integrationStatusQuery = useAccountingIntegrationStatusQuery();

  const taxAccountsQueryResult = useTaxAccountsQuery({
    includeArchived: false,
  });

  const taxAccounts = unwrapQuery(taxAccountsQueryResult) ?? [];

  const checkReverseChargeAccount = useCheckReverseChargeTaxAccount();

  const payableItemLine = payableItemLines[payableItemLineIndex];

  // Handle vat account change

  const handleChange = (vat: ItemLineVat, index: number) => {
    const isValueReverseChargeAccount = checkReverseChargeAccount(
      vat.accountId ?? '',
    );

    const nextLines: ItemLine[] = payableItemLines
      .map((itemLine, itemLineIndex) => {
        if (
          itemLineIndex !== index ||
          // Do not trigger a change if the account id/amount has not changed
          (vat.accountId === itemLine.vat?.accountId &&
            moneyToNumber(vat.amount ?? moneyFromNumber(0, currency)) ===
              moneyToNumber(
                itemLine.vat?.amount ?? moneyFromNumber(0, currency),
              ))
        ) {
          return itemLine;
        }

        const expense = {
          accountId: itemLine.expense?.accountId,
          amount:
            payableItemLines.length === 1 && 'payable' in rest
              ? computeNetAmountFromVat(
                  vat.amount,
                  rest.payable.originalAmount,
                  currency,
                )
              : itemLine.expense?.amount,
        };

        return { ...itemLine, vat, expense };
      })
      .filter(
        (itemLine) =>
          checkReverseChargeAccount(itemLine.vat?.accountId ?? '') ===
          isValueReverseChargeAccount,
      );

    onChange(nextLines);
  };

  // Handle vat amount computation

  const ids = payableItemLines
    .map((value) => value.vat?.accountId)
    .filter((id) => !!id) as string[];

  const linesTaxAccountsQueryResult = useTaxAccountsQuery({
    includeArchived: true,
    ids,
  });

  const linesTaxAccounts = unwrapQuery(linesTaxAccountsQueryResult) ?? [];

  let autoComputeVatAmount = parentAutoComputeVatAmount;
  const taxAccount = linesTaxAccounts.find(
    (account) => account.id === payableItemLine.vat?.accountId,
  );

  if (payableItemLines.length > 1 && payableItemLine.expense?.amount) {
    autoComputeVatAmount = {
      strategy: 'useNetAmount' as const,
      netAmount: payableItemLine.expense?.amount,
    };
  } else if (
    payableItemLines.length === 1 &&
    'payable' in rest &&
    rest.payable.amount
  ) {
    autoComputeVatAmount = {
      strategy: 'useGrossAmount' as const,
      grossAmount: rest.payable.originalAmount ?? rest.payable.amount,
    };
  } else {
    autoComputeVatAmount = { strategy: 'none' as const };
  }

  // Handle vat account automation
  const automation = getTaxAutomation(
    payableItemLine,
    initialPayableItemLines,
    payableItemLineIndex,
    { automation: parentAutomation },
  );

  // Handle reverse charge amount

  const reverseChargeAccountRateResult = useApplicableReverseChargeAccountRate(
    'payable' in rest ? new Date(rest.payable.createdAt) : undefined,
  );

  const reverseChargeAmount =
    payableItemLine.expense?.amount &&
    reverseChargeAccountRateResult?.outcome === 'valid' &&
    'payable' in rest
      ? reverseChargeAccountRateResult.computeReverseChargeAmount(
          moneyMultiply(
            payableItemLine.expense.amount,
            rest.payable.exchangeRate.amount,
            rest.payable.exchangeRate.precision,
          ),
        )
      : undefined;

  // Handle vat account restriction

  const computeVatAccountRestriction = (itemLines: ItemLine[]) => {
    if (itemLines.length) {
      return itemLines.some((itemLine) =>
        checkReverseChargeAccount(itemLine.vat?.accountId ?? ''),
      )
        ? 'reverseChargeOnly'
        : 'regularOnly';
    }
  };

  const vatAccountRestriction =
    reverseChargeAccountRateResult?.outcome === 'valid'
      ? computeVatAccountRestriction(
          payableItemLines.slice(0, payableItemLineIndex),
        )
      : 'regularOnly';

  let validTaxAccounts: TaxAccount[];

  switch (vatAccountRestriction) {
    case 'regularOnly':
      validTaxAccounts = taxAccounts.filter((t) => !isReverseChargeAccount(t));
      break;
    case 'reverseChargeOnly':
      validTaxAccounts = taxAccounts.filter((t) => isReverseChargeAccount(t));
      break;
    case undefined:
      validTaxAccounts = taxAccounts;
      break;
    default:
      throw new Error(
        `Unknown vatAccountRestriction: ${vatAccountRestriction}`,
      );
  }

  // Render

  if (!validTaxAccounts.length || integrationStatusQuery.status !== 'success') {
    return (
      <div className="flex flex-row">
        <SkeletonText className="mr-xs !h-[34px]" />
        <SkeletonText width="150px" className="!h-[34px]" />
      </div>
    );
  }

  return (
    <PayableTaxAccountField
      taxAccount={taxAccount}
      vatAccounts={validTaxAccounts}
      reverseChargeAmount={reverseChargeAmount}
      automation={automation}
      values={
        payableItemLine.vat ?? {
          accountId: null,
          amount: null,
          itemLineId: payableItemLineIndex,
          rate: null,
        }
      }
      initialValues={initialPayableItemLines[payableItemLineIndex]?.vat}
      errors={errors}
      hasAmountError={hasAmountError}
      onChange={(value) => handleChange(value, payableItemLineIndex)}
      currency={currency}
      walletCurrency={walletCurrency}
      hideVatAmount={hideVatAmount ?? false}
      taxAccountPlaceholder={taxAccountPlaceholder}
      autoComputeVatAmount={autoComputeVatAmount}
      integrationStatus={integrationStatusQuery.data}
    />
  );
};

/**
 * Helpers
 */

const getTaxAutomation = (
  payableItemLine: ItemLine,
  initialPayableItemLines: ItemLine[],
  payableItemLineIndex: number,
  payable: Pick<Payable, 'automation'>,
) => {
  return (payableItemLine.vat?.amount &&
  initialPayableItemLines[payableItemLineIndex]?.vat?.amount
    ? moneyEqual(
        payableItemLine.vat.amount,
        initialPayableItemLines[payableItemLineIndex].vat.amount,
      )
    : false) &&
    payableItemLine.vat?.accountId &&
    payableItemLine.vat?.accountId ===
      (initialPayableItemLines[payableItemLineIndex]?.vat?.accountId ?? null)
    ? payable.automation?.tax
    : undefined;
};
