import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type ListAccount } from './listAccount';

export function validateBankAccount(
  account: ListAccount,
  integration: AccountingSoftware,
):
  | {
      outcome: 'valid';
    }
  | {
      outcome: 'invalid';
      reason: 'required';
    } {
  switch (account.capability) {
    case 'localOnly':
      if (
        hasIntegrationFileBasedExport(integration) &&
        account.code.trim() === ''
      ) {
        return {
          outcome: 'invalid',
          reason: 'required',
        };
      }
      break;
    case 'pullAndSelect':
      break;
    default:
      return rejectUnexpectedValue('accountCapability', account);
  }

  return {
    outcome: 'valid',
  };
}
