import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { isRequestError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';

import { type BankInformation } from '../../../../models';

type RawBankInformation = {
  bank_country: string | null;
  iban: string | null;
  bic: string | null;
  cuc: string | null;
};

type BankInformationError = {
  code: string;
};

export const useGetBankInformationQuery = (): QueryState<
  BankInformation,
  BankInformationError
> => {
  const companyId = useCompanyId();

  return useQuery<BankInformation, RawBankInformation>({
    key: ['bankInformation', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/bank-info',
    },
    reshapeData: (rawBankInformation) => ({
      bankCountry: rawBankInformation.bank_country,
      iban: rawBankInformation.iban,
      bic: rawBankInformation.bic,
      cuc: rawBankInformation.cuc,
    }),
    reshapeError: (queryError) => {
      if (
        isRequestError(queryError) &&
        queryError.status === 404 &&
        queryError.data?.status === 'Not found'
      ) {
        return {
          ...queryError,
          data: { code: 'notFound' },
        };
      }
      return queryError;
    },
  });
};
