import * as Money from 'ezmoney';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type DetailedWalletSummary } from '../walletSummary';

type WalletDetailResponse = {
  walletSummary: DetailedWalletSummary;
  hasMissingFunds: boolean;
};

export const useFetchWalletDetailQuery = (
  companyId: string,
  isEnabled = true,
): QueryState<WalletDetailResponse> => {
  return useQuery<WalletDetailResponse>({
    key: 'walletDetail',
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/companies/${companyId}/wallet-summary`,
    },
    isEnabled,
    reshapeData: (data) => {
      // TODO@financeOps: data.totalAllocated will always be the source of truth when fully rolled out on the backend side
      // TODO@financeOps: hotfix, walletDetails should always be present...
      const allocatedFunds =
        data.totalAllocated ??
        data.walletDetails?.allocatedOnCards?.totalAmount ??
        Money.fromNumber(0, 'EUR');

      const hasMissingFunds =
        Boolean(data.walletDetails) &&
        Money.greaterThan(allocatedFunds, data.totalAmount);
      return {
        walletSummary: data,
        hasMissingFunds,
      };
    },
  });
};
