import { useFormik } from 'formik';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useNotifications } from 'modules/app/notifications';

import {
  type BankDetailsSuggestion,
  type BankInfosErrors,
  type DraftSupplier,
  type SupplierBankInfos,
  type SupplierDetails,
  getSupplierBankInfosFromSuggestion,
  isUpdateMode,
  validateSupplierBankInfos,
} from '../../models';
import { useCreateSupplier, useUpdateSupplier } from '../api';
import { useGetSupplierBankFields } from '../useGetSupplierBankFields';

type Props = {
  bankDetailsSuggestion?: BankDetailsSuggestion;
  draftSupplier: DraftSupplier;
  onSupplierCreationOrUpdateSuccess?(supplier: SupplierDetails): void;
};

export const useSupplierBankInfosForm = ({
  bankDetailsSuggestion,
  draftSupplier,
  onSupplierCreationOrUpdateSuccess,
}: Props) => {
  const { t } = useTranslation('global');
  const { successNotif } = useNotifications();
  const company = useCompany();
  const getSupplierBankFields = useGetSupplierBankFields();
  const [
    createSupplier,
    createSupplierQueryState,
    resetCreateSupplierQueryState,
  ] = useCreateSupplier({
    onSupplierCreationSuccess: (supplier) => {
      onSupplierCreationOrUpdateSuccess?.(supplier);
      successNotif(t('submitMyInvoice.suppliers.createSuccess'));
    },
  });
  const [
    updateSupplier,
    updateSupplierQueryState,
    resetUpdateSupplierQueryState,
  ] = useUpdateSupplier(draftSupplier.id ?? '', {
    onSupplierUpdateSuccess: (supplier) => {
      onSupplierCreationOrUpdateSuccess?.(supplier);
      successNotif(t('submitMyInvoice.suppliers.updateSuccess'));
    },
  });

  const resetQueryStates = () => {
    resetCreateSupplierQueryState();
    resetUpdateSupplierQueryState();
  };

  const initialValues =
    bankDetailsSuggestion && !!draftSupplier.bankInfos
      ? getSupplierBankInfosFromSuggestion(
          draftSupplier.bankInfos,
          bankDetailsSuggestion,
          company,
        )
      : draftSupplier.bankInfos ?? {};

  const formikProps = useFormik<SupplierBankInfos>({
    initialValues,
    onSubmit: async (values) => {
      resetQueryStates();
      const supplierToCreate: DraftSupplier = {
        ...draftSupplier,
        bankInfos: values,
      };
      try {
        return (await isUpdateMode(draftSupplier))
          ? await updateSupplier(supplierToCreate)
          : await createSupplier(supplierToCreate);
      } catch {
        // handled by the query state
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validate: async (values) => {
      return validateSupplierBankInfos(values, {
        supplierBankFields: getSupplierBankFields(values),
      });
    },
  });

  return {
    formikProps: {
      ...formikProps,
      errors: formikProps.errors as BankInfosErrors,
    },
    supplierBankFields: getSupplierBankFields(formikProps.values),
    createSupplierQueryState,
    updateSupplierQueryState,
  };
};
