import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { type BankingProvider } from 'src/core/reducers/global';

import {
  getBranchEntityFormSchema,
  type NewBranchEntityModalForm,
} from './form';
import { AccountTypeStep } from './steps/AccountTypeStep';
import { CountryAndCurrencyStep } from './steps/CountryAndCurrencyStep';
import { EntityAddressStep } from './steps/EntityAddressStep/EntityAddressStep';
import { EntityNameStep } from './steps/EntityNameStep';
import { useCreateBankableMarqetaBranchEntityMutation } from '../../hooks/useCreateBankableMarqetaBranchEntityMutation';
import { useCreateBranchExpenseEntityMutation } from '../../hooks/useCreateBranchExpenseEntityMutation';
import { useCreateSFSBranchEntityMutation } from '../../hooks/useCreateSFSBranchEntityMutation';
import { ACCOUNT_TYPES } from '../../models/accountTypes';
import {
  canCreateBranchEntityWith,
  canCreateSfsBranchEntityWith,
} from '../../utils/createBranch';

export type Step =
  | 'countryAndCurrency'
  | 'accountType'
  | 'entityName'
  | 'entityAddress';

type Props = {
  onCancel: () => void;
  onClose: () => void;
  refreshUserCompanies: () => void;
  setCreatedCompany: (company?: {
    id: string;
    banking_provider: BankingProvider;
  }) => void;
  setIsBranchCreationAvailable: (value: boolean) => void;
  setSelectedCountry: (value: string) => void;
};

export const NewBranchEntityModal = ({
  onCancel,
  onClose,
  refreshUserCompanies,
  setCreatedCompany,
  setIsBranchCreationAvailable,
  setSelectedCountry,
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const { pushNotif } = useNotifications();

  const [currentStep, setCurrentStep] = useState<Step>('countryAndCurrency');

  const canCreateExpenseEntity =
    company.banking_provider !== 'bankable' &&
    company.type !== 'branch_currency';

  const [createSFSBranchEntityMutation] = useCreateSFSBranchEntityMutation();
  const [createBankableMarqetaBranchEntityMutation] =
    useCreateBankableMarqetaBranchEntityMutation();
  const [createBranchExpenseEntityMutation] =
    useCreateBranchExpenseEntityMutation();

  const onConfirm = useCallback(
    async (countryKey: string, currency: string) => {
      let newCompanyData;

      const willBranchBeOnSfs = canCreateSfsBranchEntityWith(
        countryKey,
        currency,
      );

      if (willBranchBeOnSfs) {
        newCompanyData = await createSFSBranchEntityMutation({
          country: countryKey,
          currency,
          // eslint-disable-next-line camelcase
          is_legal_representative: true,
        });
      } else {
        newCompanyData = await createBankableMarqetaBranchEntityMutation({
          country: countryKey,
          currency,
          type: ACCOUNT_TYPES.ENTITY,
        });
      }

      setCreatedCompany(newCompanyData);
      refreshUserCompanies();
    },
    [],
  );

  const formikProps = useFormik<NewBranchEntityModalForm>({
    initialValues: {
      country: '',
      currency: '',
      address: {
        address: '',
        city: '',
        zipcode: '',
      },
      name: '',
    },
    validationSchema: getBranchEntityFormSchema(currentStep),
    onSubmit: async (values) => {
      const canCreateBranch = canCreateBranchEntityWith({
        bankingProvider: company.banking_provider,
        country: values.country,
        currency: values.currency,
      });

      if (!canCreateExpenseEntity || canCreateBranch) {
        if (canCreateBranch) {
          await onConfirm(values.country, values.currency);
        }

        setIsBranchCreationAvailable(canCreateBranch);
        setSelectedCountry(values.country);

        return;
      }

      if (currentStep === 'countryAndCurrency') {
        setCurrentStep('accountType');
        return;
      }

      if (currentStep === 'accountType') {
        setCurrentStep('entityName');
        return;
      }

      if (currentStep === 'entityName') {
        setCurrentStep('entityAddress');
        return;
      }

      if (currentStep === 'entityAddress') {
        try {
          const newExpenseEntity = await createBranchExpenseEntityMutation({
            name: values.name as string,
            currency: values.currency,
            address: {
              address: values.address.address,
              address2: values.address.address2 || undefined,
              city: values.address.city,
              zipcode: values.address.zipcode,
              state: values.address.state || undefined,
              country: values.country,
            },
          });
          const targetUrl = `/app/${newExpenseEntity.id}/home?expenseEntityWelcomeModal=true`;
          window.location.href = targetUrl;
        } catch (error) {
          pushNotif({
            type: NotificationType.Danger,
            message: t('misc.errors.unknownError'),
          });
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    },
  });

  if (currentStep === 'accountType') {
    return (
      <AccountTypeStep
        {...formikProps}
        onCancel={() => setCurrentStep('countryAndCurrency')}
        onClose={onClose}
      />
    );
  }

  if (currentStep === 'entityName') {
    return (
      <EntityNameStep
        {...formikProps}
        onCancel={() => setCurrentStep('accountType')}
        onClose={onClose}
      />
    );
  }

  if (currentStep === 'entityAddress') {
    return (
      <EntityAddressStep
        values={formikProps.values.address}
        errors={formikProps.errors.address ?? {}}
        setFieldValue={async (field, value) => {
          formikProps.setFieldValue(field, value);
        }}
        handleSubmit={formikProps.handleSubmit}
        isSubmitting={formikProps.isSubmitting}
        handleChange={formikProps.handleChange}
        country={formikProps.values.country}
        onCancel={() => setCurrentStep('entityName')}
        onClose={onClose}
      />
    );
  }

  return (
    <CountryAndCurrencyStep
      {...formikProps}
      onCancel={onCancel}
      onClose={onClose}
    />
  );
};
