import { Button, EmptyState } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";
import { openSupportChat } from "src/core/utils/supportChat";

type Props = {
  className?: string;
  title: string;
  description?: string;
};

export const ErrorState = ({ className, title, description }: Props) => {
  const { t } = useTranslation('global');

  return (
    <EmptyState
      className={className}
      title={title}
      subtitle={description ?? t('misc.errorState.subtitle')}
      iconName="warning"
      iconVariant="warning"
      actions={
        <Button
          variant="contrasted"
          text={t('misc.errorState.contactSupport')}
          onClick={() => {
            openSupportChat({
              message: t('misc.errorState.intercomMessage', {
                currentUrl: window.location.href,
              }),
            });
          }}
        />
      }
    />
  );
};
