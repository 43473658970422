import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ControlRule } from '../controlRule';

export const useUserControlRuleQuery = (): QueryState<ControlRule> => {
  return useQuery<ControlRule>({
    key: 'userControlRule',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/control-rules/me',
    },
    reshapeData: (data) => {
      return {
        name: data.name,
        completionDeadline: data.completionDeadline,
        incompletePaymentsLimit: data.incompletePaymentsLimit,
      };
    },
  });
};
