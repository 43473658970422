import { type BookkeepingExport } from '../types';

export const EXPORT_EXPENSES_REQUEST = 'export/EXPORT_EXPENSES_REQUEST';
export const EXPORT_EXPENSES_SUCCESS = 'export/EXPORT_EXPENSES_SUCCESS';
export const EXPORT_EXPENSES_FAILURE = 'export/EXPORT_EXPENSES_FAILURE';
export const EXPORT_EXPENSES_RESET = 'export/EXPORT_EXPENSES_RESET';
export const FETCH_UNREAD_EXPORTS_SUCCESS =
  'export/FETCH_UNREAD_EXPORTS_SUCCESS';

export type ExportExpensesRequest = {
  readonly type: typeof EXPORT_EXPENSES_REQUEST;
};

export type ExportExpenseSuccess = {
  readonly type: typeof EXPORT_EXPENSES_SUCCESS;
};

export type ExportExpensesFailure = {
  readonly type: typeof EXPORT_EXPENSES_FAILURE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly error: any;
};

export type ExportExpensesReset = {
  readonly type: typeof EXPORT_EXPENSES_RESET;
};

export type ExportActions =
  | ExportExpensesRequest
  | ExportExpenseSuccess
  | ExportExpensesFailure
  | ExportExpensesReset;

export type FetchUnreadExportsSuccess = {
  readonly type: typeof FETCH_UNREAD_EXPORTS_SUCCESS;
  readonly payload: BookkeepingExport[];
};

export type UnreadExportsActions = FetchUnreadExportsSuccess;
