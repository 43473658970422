import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { SearchFilter } from 'common/components/SearchFilter';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import FEATURES from "src/core/constants/features";

type Props = {
  policiesCount: number;
  searchPattern: string;
  setSearchPattern(search: string): void;
  onCreateClick(): void;
};

export function MembersApprovalPoliciesHeader({
  policiesCount,
  searchPattern,
  setSearchPattern,
  onCreateClick,
}: Props) {
  const { t } = useTranslation('global');
  const hasSpendingPoliciesFeature = useFeature(FEATURES.SPENDING_POLICIES);
  return (
    <div className="mb-s flex items-center gap-s">
      <div className="flex-1">
        <div className="leading-[20px] text-complementary title-xl">
          {t('members.approvalPolicies.title')}
        </div>
        <div className="text-neutral-dark body-m">
          {t('members.approvalPolicies.count', { count: policiesCount })}
        </div>
      </div>

      <SearchFilter
        placeholder={t('members.approvalPolicies.searchPlaceholder')}
        minLength={0}
        search={searchPattern}
        setSearch={(search) => setSearchPattern(search)}
      />

      {hasSpendingPoliciesFeature && (
        <Button
          text={t('members.approvalPolicies.createButton')}
          variant="primary"
          onClick={onCreateClick}
        />
      )}
    </div>
  );
}
