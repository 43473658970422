import {
  type FetchAllTemplatesFailure,
  type FetchAllTemplatesRequest,
  type FetchAllTemplatesSuccess,
  type FetchTemplateFailure,
  type FetchTemplateRequest,
  type FetchTemplateSuccess,
  type ResetOpenedTemplate,
  FETCH_ALL_TEMPLATES_FAILURE,
  FETCH_ALL_TEMPLATES_REQUEST,
  FETCH_ALL_TEMPLATES_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_REQUEST,
  FETCH_TEMPLATE_SUCCESS,
  RESET_OPENED_TEMPLATE,
} from './actionTypes';

export const fetchTemplateRequest = (): FetchTemplateRequest => ({
  type: FETCH_TEMPLATE_REQUEST,
});

export const fetchTemplateFailure = (): FetchTemplateFailure => ({
  type: FETCH_TEMPLATE_FAILURE,
});

export const fetchTemplateSuccess = (
  payload: FetchTemplateSuccess['payload'],
): FetchTemplateSuccess => ({
  type: FETCH_TEMPLATE_SUCCESS,
  payload,
});

export const fetchAllTemplatesRequest = (): FetchAllTemplatesRequest => ({
  type: FETCH_ALL_TEMPLATES_REQUEST,
});

export const fetchAllTemplatesFailure = (): FetchAllTemplatesFailure => ({
  type: FETCH_ALL_TEMPLATES_FAILURE,
});

export const fetchAllTemplatesSuccess = (
  payload: FetchAllTemplatesSuccess['payload'],
): FetchAllTemplatesSuccess => ({
  type: FETCH_ALL_TEMPLATES_SUCCESS,
  payload,
});

export const resetOpenedTemplate = (): ResetOpenedTemplate => ({
  type: RESET_OPENED_TEMPLATE,
});
