import type { PayableRequest } from '@finance-review/models/payable';
import { type MutationState as MutationStateType } from 'src/core/api/hooks/useMutation';
import { type MutationQueryState } from 'src/core/api/queryState';

import { useMutation as useRejectPayableRequestsMutation } from './useRejectPayableRequests';

export type Response = {
  successes: { id: PayableRequest.EntityId }[];
  errors: {
    requestId: PayableRequest.EntityId;
    errorCode: string;
    errorMessage: string;
  }[];
};
export type Payload = string;
export type QueryState = MutationQueryState<Response, unknown>;
export type MutationState = MutationStateType<Payload, Response, unknown>;

export const useMutation = (
  requestId: PayableRequest.EntityId,
  options: {
    onSuccess?: (requestId: PayableRequest.EntityId) => void;
    onError?: () => void;
  } = {},
): MutationStateType<Payload, Response, unknown> => {
  const [rejectInvoiceMutation, ...rest] =
    useRejectPayableRequestsMutation(options);

  return [
    async (rejectReason: string) =>
      rejectInvoiceMutation({
        rejectReason,
        requestIds: [requestId],
      }),
    ...rest,
  ];
};

export const hasFailed = (
  rejectInvoiceRequestQueryState: QueryState,
): boolean =>
  rejectInvoiceRequestQueryState.status === 'success' &&
  rejectInvoiceRequestQueryState.data.errors.length > 0;
