import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  onFinish: () => void;
};

export const AffidavitFinalModal = ({ onFinish }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      iconName="receipt-checked"
      onClose={onFinish}
      title={t('payments.affidavitModal.finalModal.title')}
      actions={[
        <Button
          key="finish"
          text={t('payments.affidavitModal.finalModal.continueButton')}
          onClick={onFinish}
        />,
      ]}
    >
      {t('payments.affidavitModal.finalModal.content')}
    </Modal>
  );
};
