import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { staleTime } from './constants';
import type { Team } from '../../members/models/teams';

const requestParams = {
  type: 'rest',
  target: 'companyAPI',
  endpoint: '/groups',
  method: 'get',
} as const;
const queryKey = ['groups'];

type Params = {
  isEnabled?: boolean;
};
export type UseTeamsQueryResponse = Team[];

export const useTeamsQuery = ({ isEnabled = true }: Params = {}): QueryState<
  UseTeamsQueryResponse,
  unknown
> => {
  return useQuery<UseTeamsQueryResponse, Team[] | Team>({
    key: queryKey,
    request: requestParams,
    options: {
      staleTime,
    },
    isEnabled,
    reshapeData: reshapeData,
  });
};

export const useTeamsQueryPromise = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const teamFetcher = () =>
    getFetcher<Team[] | Team>({
      companyId,
      getRequest: () => requestParams,
    });

  return async (): Promise<UseTeamsQueryResponse> => {
    const rawData = await queryClient.fetchQuery(queryKey, teamFetcher(), {
      staleTime,
    });

    return reshapeData(rawData);
  };
};

const reshapeData = (data: Team[] | Team): UseTeamsQueryResponse =>
  Array.isArray(data) ? data : [data];
