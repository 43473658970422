/* eslint-disable @typescript-eslint/naming-convention */

import { getRoutes } from 'src/core/constants/routes';

export const routes = getRoutes({
  SETUP_HUB_TASK_LIST: {
    path: '/app/:company/setup-hub',
    analytics: {
      category: 'Setup Hub',
      name: 'task_list',
    },
  },
  CONTROL_SETTINGS_NEW_EXPENSE_CATEGORY: {
    path: '/app/:company/setup-hub/control-settings/expense-categories/new',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_new_expense_category',
    },
  },
  CONTROL_SETTINGS_EXPENSE_CATEGORY_LIST: {
    path: '/app/:company/setup-hub/control-settings/expense-categories/list',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_expense_category_list',
    },
  },
  CONTROL_SETTINGS_CUSTOM_FIELD_NAME: {
    path: '/app/:company/setup-hub/control-settings/analytical-fields/name',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_custom_field_name',
    },
  },
  CONTROL_SETTINGS_CUSTOM_FIELD_VALUES: {
    path: '/app/:company/setup-hub/control-settings/analytical-fields/values',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_custom_field_values',
    },
  },
  CONTROL_SETTINGS_CUSTOM_FIELD_LIST: {
    path: '/app/:company/setup-hub/control-settings/analytical-fields/list',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_custom_field_list',
    },
  },
  CONTROL_SETTINGS_CONTROL_RULES: {
    path: '/app/:company/setup-hub/control-settings/control-rules',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_control_rules',
    },
  },
});
