import { isAfter , isBefore , isSameWeek , startOfWeek } from 'date-fns';
import * as Money from 'ezmoney';

import { type Bill } from './bill';
import {
  defaultPaymentMethod,
  type PaymentMethod,
  paymentMethodsSortedByPriority,
} from './paymentMethod';
import { type SchedulingProcess } from './schedulingProcess';

export type PaymentToSchedule = {
  id: string;
  amount: Money.MonetaryValue;
  dueDate: string;
  bill: Pick<Bill, 'id' | 'amount' | 'counterparty' | 'requestId'> & {
    documentaryEvidence: Pick<Bill['documentaryEvidence'], 'invoiceNumber'>;
  };
  currentIndex: number;
  relatedPaymentsCount: number;
  schedulingProcess: SchedulingProcess;
  paymentMethods: {
    [PaymentMethod.XmlSepa]:
      | {
          active: true;
        }
      | {
          active: false;
          reasons: {
            company: (
              | 'missingIban'
              | 'missingBic'
              | 'countryNotSupported'
              | 'currencyNotSupported'
            )[];
            counterparty: (
              | 'missingIban'
              | 'missingBic'
              | 'countryNotSupported'
            )[];
            payment: 'currencyNotSupported'[];
          };
        };
    [PaymentMethod.Csv]: {
      active: true;
    };
    [PaymentMethod.WireTransfer]?:
      | {
          active: true;
        }
      | {
          active: false;
          reasons: {
            company: (
              | 'noWireTransferFeature'
              | 'featureAvailableButNotActivated'
              | 'accountIsNotEligibleForWireTransfer'
              | 'ongoingKybProcess'
              | 'missingCompanyBankInfo'
              | 'providerSetupPending'
              | 'providerSetupFailed'
            )[];
            counterparty: (
              | 'missingIban'
              | 'missingBic'
              | 'countryNotSupported'
              | 'currencyNotSupported'
            )[];
            payment: 'currencyNotSupported'[];
          };
        };
  };
};

export type PaymentToScheduleDetails = {
  id: string;
  amount: Money.MonetaryValue;
  dueDate: string;
  bill: Bill;
  schedulingProcess: SchedulingProcess;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeline: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: any;
};

export const isUrgentPaymentToSchedule = (
  paymentToSchedule: PaymentToSchedule,
  now: Date,
): boolean => {
  const startOfCurrentWeek = startOfWeek(now);

  const dueDate = new Date(paymentToSchedule.dueDate);
  const startOfDueDateWeek = startOfWeek(dueDate);

  return (
    isSameWeek(startOfDueDateWeek, startOfCurrentWeek) ||
    isBefore(startOfDueDateWeek, startOfCurrentWeek)
  );
};

export const isUpcomingPaymentToSchedule = (
  paymentToSchedule: PaymentToSchedule,
  now: Date,
): boolean => {
  const startOfCurrentWeek = startOfWeek(now);

  const dueDate = new Date(paymentToSchedule.dueDate);
  const startOfDueDateWeek = startOfWeek(dueDate);

  return isAfter(startOfDueDateWeek, startOfCurrentWeek);
};

export const isPartialBillPaymentToSchedule = (
  paymentToSchedule: PaymentToSchedule | PaymentToScheduleDetails,
): boolean =>
  Money.compare(paymentToSchedule.amount, paymentToSchedule.bill.amount) === -1;

export const shouldDisplayRelatedPaymentsCount = (
  paymentToSchedule: PaymentToSchedule,
) => {
  return paymentToSchedule.relatedPaymentsCount > 1;
};

export const hasSomeWithActivePaymentMethod = (
  paymentsToSchedule: PaymentToSchedule[],
  paymentMethod: PaymentMethod,
): boolean =>
  paymentsToSchedule.some(
    (paymentToSchedule) =>
      paymentToSchedule.paymentMethods[paymentMethod]?.active,
  );

export const countIncompatiblePaymentsWithPaymentMethod = (
  paymentsToSchedule: PaymentToSchedule[],
  paymentMethod: PaymentMethod,
): number =>
  paymentsToSchedule.filter(
    (paymentToSchedule) =>
      !paymentToSchedule.paymentMethods[paymentMethod]?.active,
  ).length;

export const findHighestPriorityActivePaymentMethodInEveryPayment = (
  paymentsToSchedule: PaymentToSchedule[],
): PaymentMethod => {
  const paymentMethodsToActiveStatus = paymentsToSchedule.reduce(
    (accumulator, paymentToSchedule) => {
      paymentMethodsSortedByPriority.forEach((paymentMethod) => {
        if (!paymentToSchedule.paymentMethods[paymentMethod]?.active) {
          accumulator.set(paymentMethod, false);
        }
      });
      return accumulator;
    },
    new Map(
      paymentMethodsSortedByPriority.map((paymentMethod) => [
        paymentMethod,
        true,
      ]),
    ),
  );

  const activePaymentMethodWithHighestPriority = Array.from(
    paymentMethodsToActiveStatus,
  ).find(([_, isActive]) => isActive)?.[0];

  return activePaymentMethodWithHighestPriority ?? defaultPaymentMethod;
};

export const findHighestPriorityActivePaymentMethodInSomePayments = (
  paymentsToSchedule: PaymentToSchedule[],
): PaymentMethod => {
  const paymentMethodsToActiveStatus = new Map(
    paymentMethodsSortedByPriority.map((paymentMethod) => [
      paymentMethod,
      false,
    ]),
  );

  paymentsToSchedule.forEach((paymentToSchedule) => {
    paymentMethodsSortedByPriority.forEach((paymentMethod) => {
      if (paymentToSchedule.paymentMethods[paymentMethod]?.active) {
        paymentMethodsToActiveStatus.set(paymentMethod, true);
      }
    });

    // exit early if the payment method with highest priority has been found active
    if (paymentMethodsToActiveStatus.get(paymentMethodsSortedByPriority[0])) {
      return paymentMethodsSortedByPriority[0];
    }
  });

  const firstActivePaymentMethodWithHighestPriority = Array.from(
    paymentMethodsToActiveStatus,
  ).find(([_, isActive]) => isActive)?.[0];

  return firstActivePaymentMethodWithHighestPriority ?? defaultPaymentMethod;
};

export const computeAmountScheduled = (
  payments: {
    id: string;
    amount: number | null;
    date: Date | null;
  }[],
  currency: string,
): Money.MonetaryValue =>
  payments.reduce(
    (total, payment) =>
      Money.add(total, Money.fromNumber(payment.amount || 0, currency, 2)),
    Money.fromNumber(0, currency, 2),
  );

export const computeAmountLeftToSchedule = (
  amountToSchedule: Money.MonetaryValue,
  payments: {
    id: string;
    amount: number | null;
    date: Date | null;
  }[],
): Money.MonetaryValue =>
  Money.subtract(
    amountToSchedule,
    computeAmountScheduled(payments, amountToSchedule.currency),
  );

export const filterPaymentsWithActivePaymentMethod = (
  paymentsToSchedule: PaymentToSchedule[],
  paymentMethod: PaymentMethod,
): PaymentToSchedule[] =>
  paymentsToSchedule.filter(
    (paymentToSchedule) =>
      paymentToSchedule.paymentMethods[paymentMethod]?.active === true,
  );

export const isPaymentToScheduleCompatibleWithPaymentMethod = (
  paymentToSchedule: PaymentToSchedule,
  paymentMethod: PaymentMethod,
): boolean => paymentToSchedule.paymentMethods[paymentMethod]?.active === true;

export const sumAmountsOfPaymentMatchingCurrency = (
  paymentsToSchedule: PaymentToSchedule[],
  currency: string,
): Money.MonetaryValue =>
  paymentsToSchedule.reduce(
    (total, paymentToSchedule) =>
      paymentToSchedule.amount.currency === currency
        ? Money.add(total, paymentToSchedule.amount)
        : total,
    Money.fromNumber(0, currency, 2),
  );
