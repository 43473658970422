import { baseAPI } from 'src/core/api/axios';

export type UserFactor = {
  type: string;
  state: string;
};

export const getUserFactorsAndCheckIfPhoneActive =
  async (): Promise<boolean> => {
    const response = await baseAPI.get('/factors');
    const phoneUserFactor = response.data.find(
      (factor: UserFactor) =>
        factor.type === 'phone' && factor.state === 'active',
    );
    return phoneUserFactor !== undefined;
  };
