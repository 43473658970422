import { gql } from 'graphql-tag';

export const GET_ACCOUNT_PAYABLE = gql`
  query GetAccountPayable($accountPayableId: ID!) {
    supplierAccount(id: $accountPayableId) {
      id
      generalAccountCode
      isDefault
      isArchived
    }
  }
`;
