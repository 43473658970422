import isNil from 'lodash/isNil';

import {
  type User,
  type SupplierAddress,
  type SupplierBankInfos,
  type SupplierDetails,
  type SupplierLegalInfos,
} from '../../../models';

export type RawSupplier = {
  id: string;
  name: string;
  thumbnailUrl?: string;
  validated_at: string | null;
  updated_at: string | null;
  is_invoice_payable?: boolean | null;
  is_archived?: boolean | null;
  supplier_details: {
    account_holder_name: null | string;
    account_code: null | string;
    account_number: null | string;
    address: null | string;
    bank_country: null | string;
    bic: null | string;
    city: null | string;
    country: null | string;
    iban: null | string;
    id: null | string;
    legal_name: null | string;
    registration_number: null | string;
    routing_number: null | string;
    sort_code: null | string;
    vat_number: null | string;
    zipcode: null | string;
  } | null;
  parent_id: null | string;
  latestUpdater?: User;
};

export const transformSupplier = (
  rawSupplier: RawSupplier,
): SupplierDetails => ({
  id: rawSupplier.id,
  name: rawSupplier.name,
  thumbnailUrl: rawSupplier.thumbnailUrl,
  isSupplierValidated: Boolean(rawSupplier.validated_at),
  isInvoicePayable: rawSupplier.is_invoice_payable ?? false,
  address: getSupplierAddress(rawSupplier),
  bankInfos: getSupplierBankInfos(rawSupplier),
  legalInfos: getSupplierLegalInfos(rawSupplier),
  isArchived: rawSupplier.is_archived ?? false,
  latestUpdater: rawSupplier.latestUpdater,
  updatedAt: rawSupplier.updated_at,
});

const getSupplierBankInfos = (rawSupplier: RawSupplier): SupplierBankInfos => ({
  // when the supplier details are null, it means the supplier has not yet been created with bank infos. In this case, we init the accountHolderName with the supplier name
  accountHolderName: isNil(rawSupplier.supplier_details)
    ? rawSupplier.name
    : (rawSupplier.supplier_details?.account_holder_name ?? undefined),
  accountCode: rawSupplier.supplier_details?.account_code ?? undefined,
  accountNumber: rawSupplier.supplier_details?.account_number ?? undefined,
  bankCountry: rawSupplier.supplier_details?.bank_country ?? undefined,
  bic: rawSupplier.supplier_details?.bic ?? undefined,
  iban: rawSupplier.supplier_details?.iban ?? undefined,
  routingNumber: rawSupplier.supplier_details?.routing_number ?? undefined,
  sortCode: rawSupplier.supplier_details?.sort_code ?? undefined,
});

const getSupplierLegalInfos = (
  rawSupplier: RawSupplier,
): SupplierLegalInfos => ({
  legalName: rawSupplier.supplier_details?.legal_name ?? rawSupplier.name,
  registrationNumber:
    rawSupplier.supplier_details?.registration_number ?? undefined,
  vatNumber: rawSupplier.supplier_details?.vat_number ?? undefined,
});

const getSupplierAddress = ({
  supplier_details,
}: RawSupplier): SupplierAddress => ({
  address: supplier_details?.address ?? undefined,
  city: supplier_details?.city ?? undefined,
  country: supplier_details?.country ?? undefined,
  zipcode: supplier_details?.zipcode ?? undefined,
});
