import { type QueryKey } from 'react-query';

import type {
  InvoiceRequestData,
  InvoiceRequest,
} from '@finance-review/models/invoice';
import { type QueryClient } from 'src/core/api/client';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryError } from 'src/core/api/queryError';

import type { RawInvoiceData } from '../transformers';
import { transformInvoiceData } from '../transformers';

type Options = {
  isEnabled?: boolean;
  onSuccess?(invoiceRequestData: InvoiceRequestData): void;
  onError?(error: QueryError<unknown>): void;
};

export const useFetchDraftInvoiceRequest = (
  invoiceRequestId: InvoiceRequest.EntityId | undefined,
  { isEnabled = true, onSuccess, onError }: Options = {},
) => {
  return useQuery<InvoiceRequestData, RawInvoiceData>({
    key: getQueryKey(invoiceRequestId),
    isEnabled: Boolean(invoiceRequestId) && isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/invoice_requests/${invoiceRequestId}`,
    },
    options: {
      onSuccess: ({ data }) => {
        if (onSuccess) {
          onSuccess(data);
        }
      },
      onError: ({ error }) => {
        if (onError) {
          onError(error);
        }
      },
    },
    reshapeData: transformInvoiceData,
  });
};

export const updateInvoiceRequestQueryData = (
  queryClient: QueryClient,
  queryData: RawInvoiceData,
): void => {
  queryClient.setQueryData(getQueryKey(queryData.id), queryData);
};

export const getQueryKey = (
  invoiceRequestId?: InvoiceRequest.EntityId,
): QueryKey => ['invoice_requests', invoiceRequestId];
