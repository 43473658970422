import { Modal, Button } from '@dev-spendesk/grapes';
import type { TFunction, i18n as nativeI18n } from 'i18next';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  addNotification,
  NotificationType,
  type PushNotif,
} from 'modules/app/notifications';
import * as controllerHomePageActions from 'modules/homepage/ControllerHomepage/redux/actions';
import { getLastTermsAndConditions } from 'modules/homepage/ControllerHomepage/redux/selectors';
import { type TermsAndConditions } from 'modules/homepage/ControllerHomepage/redux/types';
import { getActiveLanguage } from 'src/core/config/i18n';
import { type AppState } from 'src/core/reducers';
import { track, AnalyticEventName } from 'src/core/utils/analytics';

type ModalAgreementProps = {
  pushNotif: PushNotif;
  fetchLastTermsAndConditions: (locale: string) => void;
  acknowledgeTermsAndConditions: (
    companyId: string,
    termsAndConditionsId: string,
  ) => Promise<void>;
  t: TFunction;
  i18n: nativeI18n;
  lastTermsAndConditions: TermsAndConditions | null;
  company: { id: string; organisation_id: string };
  user: { is_account_owner: boolean; id: string };
  tReady: boolean;
  supervisor: { email: string } | null;
};

type ModalAgreementState = {
  hasClosed: boolean;
};

class ModalAgreement extends Component<
  ModalAgreementProps,
  ModalAgreementState
> {
  constructor(props: ModalAgreementProps) {
    super(props);

    this.state = {
      hasClosed: false,
    };
  }

  componentDidMount() {
    const { i18n, user, fetchLastTermsAndConditions } = this.props;
    // Only the AO should approve the T&Cs
    if (user.is_account_owner) {
      fetchLastTermsAndConditions(getActiveLanguage(i18n));
    }
  }

  componentDidUpdate(
    previousProps: ModalAgreementProps,
    previousState: ModalAgreementState,
  ) {
    const previousShouldRender = this.shouldRender(
      previousProps,
      previousState,
    );
    const currentShouldRender = this.shouldRender(this.props, this.state);

    if (!previousShouldRender && currentShouldRender) {
      this.trackEvent(AnalyticEventName.TAC_MODAL_OPENED);
    }
  }

  trackEvent = (eventName: AnalyticEventName) => {
    track(eventName, {
      organisation_id: this.props.company.organisation_id,
      company_id: this.props.company.id,
      user_id: this.props.user.id,
    });
  };

  handleApproval = async () => {
    const {
      company,
      lastTermsAndConditions,
      t,
      pushNotif,
      supervisor,
      acknowledgeTermsAndConditions,
    } = this.props;

    if (lastTermsAndConditions === null) {
      return;
    }

    if (supervisor) {
      this.setState({ hasClosed: true });
      return;
    }

    try {
      await acknowledgeTermsAndConditions(
        company.id,
        lastTermsAndConditions.id,
      );

      this.trackEvent(AnalyticEventName.TAC_ACCEPTED);
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('termsAndConditions.acknowledgeErrorMessage'),
      });
    } finally {
      this.setState({ hasClosed: true });
    }
  };

  handleOpenTAC = () => {
    this.trackEvent(AnalyticEventName.TAC_LINK_OPENED);
  };

  shouldRender(
    props: ModalAgreementProps,
    state: ModalAgreementState,
  ): boolean {
    const { lastTermsAndConditions } = props;
    const { hasClosed } = state;

    return lastTermsAndConditions !== null && !hasClosed;
  }

  render() {
    const { t, lastTermsAndConditions } = this.props;
    const isOpen = this.shouldRender(this.props, this.state);

    const termsAndConditionsUrl = lastTermsAndConditions?.url;

    return (
      <Modal
        isOpen={isOpen}
        actions={[
          <Button
            key="openTAC"
            component="a"
            href={termsAndConditionsUrl || ''}
            target="_blank"
            text={t('termsAndConditions.termsAndServices')}
            variant="secondary"
            onClick={this.handleOpenTAC}
          />,
        ]}
        onClose={this.handleApproval}
        iconName="info"
        iconVariant="info"
        title={t('termsAndConditions.modalTitle')}
      >
        <p
          className="text-left text-neutral-dark body-m"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: lastTermsAndConditions?.modalContent || '',
          }}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  lastTermsAndConditions: getLastTermsAndConditions(state),
});

const mapDispatchToProps = {
  pushNotif: addNotification,
  acknowledgeTermsAndConditions:
    controllerHomePageActions.acknowledgeTermsAndConditions,
  fetchLastTermsAndConditions:
    controllerHomePageActions.fetchLastTermsAndConditions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ModalAgreement));
