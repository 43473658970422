import React, { useState, type ReactNode } from 'react';

import { type Company } from 'modules/app/hooks/useCompany';

import { GringottsIframe } from '../../components/GringottsIframe/GringottsIframe';

interface Props {
  companyId: string;
  bankingProvider: Company['banking_provider'];
  children(toggleGringottsIframe: () => void): ReactNode;
}

export const GringottsContainer = ({
  companyId,
  bankingProvider,
  children,
}: Props) => {
  const [isGringottsIframeOpened, setIsGringottsIframeOpened] =
    useState<boolean>(false);

  const toggleGringottsIframe = () => {
    if (isGringottsIframeOpened === false) {
      setIsGringottsIframeOpened(true);
    } else {
      setIsGringottsIframeOpened(false);
    }
  };

  return (
    <>
      {children(toggleGringottsIframe)}
      {isGringottsIframeOpened && (
        <GringottsIframe
          companyId={companyId}
          bankingProvider={bankingProvider}
          onClose={() => setIsGringottsIframeOpened(false)}
        />
      )}
    </>
  );
};
