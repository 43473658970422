import { Link, ListView, SwitchField } from '@dev-spendesk/grapes';
import { type SetStateAction } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type AutomaticArchivingSettingsProps = {
  automaticArchivingSelected: boolean;
  setAutomaticArchivingSelected: (value: SetStateAction<boolean>) => void;
};

export const AutomaticArchivingSettings = ({
  automaticArchivingSelected,
  setAutomaticArchivingSelected,
}: AutomaticArchivingSettingsProps) => {
  const { t } = useTranslation('global');

  return (
    <div>
      <ListView
        className="p-m"
        aria-label={t(
          'integration.kombo.settings.userArchivingOptIn.ariaLabel',
        )}
      >
        <SwitchField
          fit="parent"
          label={t('integration.kombo.settings.userArchivingOptIn.label')}
          helpText={
            <Trans i18nKey="integration.kombo.settings.userArchivingOptIn.helpText.text">
              text
              <Link
                className="text-complementary"
                isExternal
                href={t(
                  'integration.kombo.settings.userArchivingOptIn.helpText.helpCenterUrl',
                )}
              >
                link
              </Link>
            </Trans>
          }
          isChecked={automaticArchivingSelected}
          onChange={(event) => {
            setAutomaticArchivingSelected(event.target.checked);
          }}
        />
      </ListView>
    </div>
  );
};
