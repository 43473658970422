import {
  FETCH_EXPENSE_CLAIMS_COUNTS_REQUEST,
  FETCH_EXPENSE_CLAIMS_COUNTS_SUCCESS,
  FETCH_EXPENSE_CLAIMS_COUNTS_FAILURE,
  UPDATE_EXPENSE_CLAIMS_COUNTS,
  FETCH_USERS_SCHEDULED_PAYMENTS_REQUEST,
  FETCH_USERS_SCHEDULED_PAYMENTS_SUCCESS,
  FETCH_USERS_SCHEDULED_PAYMENTS_FAILURE,
  RESET_USERS_SCHEDULED_PAYMENTS,
  FETCH_USER_SCHEDULED_PAYMENTS_REQUEST,
  FETCH_USER_SCHEDULED_PAYMENTS_SUCCESS,
  RESET_OPENED_USER_SCHEDULED_PAYMENTS,
  TOGGLE_USER_SCHEDULED_PAYMENTS_SELECTION,
  SEND_TO_PAYMENT_REQUEST,
  SEND_TO_PAYMENT_SUCCESS,
  SEND_TO_PAYMENT_FAILURE,
  DOWNLOAD_CSV_REQUEST,
  DOWNLOAD_CSV_SUCCESS,
  DOWNLOAD_CSV_FAILURE,
  SEND_MISSING_BANK_INFO_REMINDERS_REQUEST,
  SEND_MISSING_BANK_INFO_REMINDERS_SUCCESS,
  SEND_MISSING_BANK_INFO_REMINDERS_FAILURE,
  FETCH_SCHEDULED_PAYMENTS_BATCHES_REQUEST,
  FETCH_SCHEDULED_PAYMENTS_BATCHES_SUCCESS,
  FETCH_SCHEDULED_PAYMENTS_BATCHES_FAILURE,
  CLEAR_SCHEDULED_PAYMENTS_BATCHES,
  REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REQUEST,
  REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REMOVE,
  REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_UPDATE,
  REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_FAILURE,
  FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_REQUEST,
  FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_SUCCESS,
  FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_FAILURE,
  FETCH_SCHEDULED_PAYMENT_REQUEST,
  FETCH_SCHEDULED_PAYMENT_SUCCESS,
  FETCH_SCHEDULED_PAYMENT_FAILURE,
  RESET_OPENED_SCHEDULED_PAYMENT,
  CONFIRM_SCHEDULED_PAYMENTS_BATCH_REQUEST,
  CONFIRM_SCHEDULED_PAYMENTS_BATCH_SUCCESS,
  CONFIRM_SCHEDULED_PAYMENTS_BATCH_FAILURE,
  RESET_SCHEDULED_PAYMENTS_CONFIRMATION_AUTH_TYPE,
  CONFIRM_SCHEDULED_PAYMENT_REQUEST,
  CONFIRM_SCHEDULED_PAYMENT_SUCCESS,
  CONFIRM_SCHEDULED_PAYMENT_FAILURE,
  CANCEL_SCHEDULED_PAYMENT_REQUEST,
  CANCEL_SCHEDULED_PAYMENT_SUCCESS,
  CANCEL_SCHEDULED_PAYMENT_FAILURE,
  CANCEL_SCHEDULED_PAYMENTS_BATCH_REQUEST,
  CANCEL_SCHEDULED_PAYMENTS_BATCH_SUCCESS,
  CANCEL_SCHEDULED_PAYMENTS_BATCH_FAILURE,
  DOWNLOAD_CSV_BATCH_DETAILS_REQUEST,
  DOWNLOAD_CSV_BATCH_DETAILS_SUCCESS,
  DOWNLOAD_CSV_BATCH_DETAILS_FAILURE,
  type FetchExpenseClaimsCountsRequest,
  type FetchExpenseClaimsCountsSuccess,
  type FetchExpenseClaimsCountsFailure,
  type FetchUsersScheduledPaymentsRequest,
  type FetchUsersScheduledPaymentsSuccess,
  type FetchUsersScheduledPaymentsFailure,
  type ResetUsersScheduledPayments,
  type FetchUserScheduledPaymentsRequest,
  type FetchUserScheduledPaymentsSuccess,
  type ResetOpenedUserScheduledPayments,
  type ToggleUserScheduledPaymentsSelection,
  type SendToPaymentRequest,
  type SendToPaymentSuccess,
  type SendToPaymentFailure,
  type DownloadCsvRequest,
  type DownloadCsvSuccess,
  type DownloadCsvFailure,
  type SendMissingBankInfoRemindersRequest,
  type SendMissingBankInfoRemindersSuccess,
  type SendMissingBankInfoRemindersFailure,
  type FetchScheduledPaymentsBatchesRequest,
  type FetchScheduledPaymentsBatchesSuccess,
  type FetchScheduledPaymentsBatchesFailure,
  type ClearScheduledPaymentsBatches,
  type RefreshOneScheduledPaymentsBatchRequest,
  type RefreshOneScheduledPaymentsBatchRemove,
  type RefreshOneScheduledPaymentsBatchUpdate,
  type RefreshOneScheduledPaymentsBatchFailure,
  type FetchScheduledPaymentsByBatchIdSuccess,
  type FetchScheduledPaymentsByBatchIdFailure,
  type FetchScheduledPaymentsByBatchIdRequest,
  type FetchScheduledPaymentRequest,
  type FetchScheduledPaymentSuccess,
  type FetchScheduledPaymentFailure,
  type ResetOpenedScheduledPayment,
  type ConfirmScheduledPaymentsBatchRequest,
  type ConfirmScheduledPaymentsBatchSuccess,
  type ConfirmScheduledPaymentsBatchFailure,
  type ConfirmScheduledPaymentRequest,
  type ConfirmScheduledPaymentSuccess,
  type ConfirmScheduledPaymentFailure,
  type CancelScheduledPaymentRequest,
  type CancelScheduledPaymentSuccess,
  type CancelScheduledPaymentFailure,
  type CancelScheduledPaymentsBatchRequest,
  type CancelScheduledPaymentsBatchSuccess,
  type CancelScheduledPaymentsBatchFailure,
  type DownloadCsvBatchDetailsRequest,
  type DownloadCsvBatchDetailsSuccess,
  type DownloadCsvBatchDetailsFailure,
  type UpdateExpenseClaimsCounts,
  type ResetScheduledPaymentsConfirmationAuthType,
} from './actionTypes';
import {
  type UserScheduledPayments,
  type ScheduledPaymentsBatch,
} from '../types';

export const fetchExpenseClaimsCountsRequest =
  (): FetchExpenseClaimsCountsRequest => ({
    type: FETCH_EXPENSE_CLAIMS_COUNTS_REQUEST,
  });

export const fetchExpenseClaimsCountsSuccess = (
  payload: FetchExpenseClaimsCountsSuccess['payload'],
): FetchExpenseClaimsCountsSuccess => ({
  type: FETCH_EXPENSE_CLAIMS_COUNTS_SUCCESS,
  payload,
});

export const fetchExpenseClaimsCountsFailure =
  (): FetchExpenseClaimsCountsFailure => ({
    type: FETCH_EXPENSE_CLAIMS_COUNTS_FAILURE,
  });

export const updateExpenseClaimsCounts = (
  payload: UpdateExpenseClaimsCounts['payload'],
): UpdateExpenseClaimsCounts => ({
  type: UPDATE_EXPENSE_CLAIMS_COUNTS,
  payload,
});

export const fetchUsersScheduledPaymentsRequest =
  (): FetchUsersScheduledPaymentsRequest => ({
    type: FETCH_USERS_SCHEDULED_PAYMENTS_REQUEST,
  });

export const fetchUsersScheduledPaymentsSuccess = (
  payload: FetchUsersScheduledPaymentsSuccess['payload'],
): FetchUsersScheduledPaymentsSuccess => ({
  type: FETCH_USERS_SCHEDULED_PAYMENTS_SUCCESS,
  payload,
});

export const fetchUsersScheduledPaymentsFailure =
  (): FetchUsersScheduledPaymentsFailure => ({
    type: FETCH_USERS_SCHEDULED_PAYMENTS_FAILURE,
  });

export const resetUsersScheduledPayments = (): ResetUsersScheduledPayments => ({
  type: RESET_USERS_SCHEDULED_PAYMENTS,
});

export const fetchUserScheduledPaymentsRequest =
  (): FetchUserScheduledPaymentsRequest => ({
    type: FETCH_USER_SCHEDULED_PAYMENTS_REQUEST,
  });

export const fetchUserScheduledPaymentsSuccess = (
  userScheduledPayments: UserScheduledPayments,
): FetchUserScheduledPaymentsSuccess => ({
  type: FETCH_USER_SCHEDULED_PAYMENTS_SUCCESS,
  payload: userScheduledPayments,
});

export const resetOpenedUserScheduledPayments =
  (): ResetOpenedUserScheduledPayments => ({
    type: RESET_OPENED_USER_SCHEDULED_PAYMENTS,
  });

export const toggleUserScheduledPaymentsSelection = (
  selectionNodeReference: ToggleUserScheduledPaymentsSelection['payload'],
): ToggleUserScheduledPaymentsSelection => ({
  type: TOGGLE_USER_SCHEDULED_PAYMENTS_SELECTION,
  payload: selectionNodeReference,
});

export const sendToPaymentRequest = (): SendToPaymentRequest => ({
  type: SEND_TO_PAYMENT_REQUEST,
});

export const sendToPaymentSuccess = (): SendToPaymentSuccess => ({
  type: SEND_TO_PAYMENT_SUCCESS,
});

export const sendToPaymentFailure = (): SendToPaymentFailure => ({
  type: SEND_TO_PAYMENT_FAILURE,
});

export const downloadCsvRequest = (): DownloadCsvRequest => ({
  type: DOWNLOAD_CSV_REQUEST,
});

export const downloadCsvSuccess = (): DownloadCsvSuccess => ({
  type: DOWNLOAD_CSV_SUCCESS,
});

export const downloadCsvFailure = (): DownloadCsvFailure => ({
  type: DOWNLOAD_CSV_FAILURE,
});

export const sendMissingBankInfoRemindersRequest =
  (): SendMissingBankInfoRemindersRequest => ({
    type: SEND_MISSING_BANK_INFO_REMINDERS_REQUEST,
  });

export const sendMissingBankInfoRemindersSuccess =
  (): SendMissingBankInfoRemindersSuccess => ({
    type: SEND_MISSING_BANK_INFO_REMINDERS_SUCCESS,
  });

export const sendMissingBankInfoRemindersFailure =
  (): SendMissingBankInfoRemindersFailure => ({
    type: SEND_MISSING_BANK_INFO_REMINDERS_FAILURE,
  });

export const fetchScheduledPaymentsBatchesRequest = (
  payload: FetchScheduledPaymentsBatchesRequest['payload'],
): FetchScheduledPaymentsBatchesRequest => ({
  type: FETCH_SCHEDULED_PAYMENTS_BATCHES_REQUEST,
  payload,
});

export const fetchScheduledPaymentsBatchesSuccess = (
  payload: FetchScheduledPaymentsBatchesSuccess['payload'],
): FetchScheduledPaymentsBatchesSuccess => ({
  type: FETCH_SCHEDULED_PAYMENTS_BATCHES_SUCCESS,
  payload,
});

export const fetchScheduledPaymentsBatchesFailure =
  (): FetchScheduledPaymentsBatchesFailure => ({
    type: FETCH_SCHEDULED_PAYMENTS_BATCHES_FAILURE,
  });

export const clearScheduledPaymentsBatches =
  (): ClearScheduledPaymentsBatches => ({
    type: CLEAR_SCHEDULED_PAYMENTS_BATCHES,
  });

export const refreshOneScheduledPaymentsBatchRequest =
  (): RefreshOneScheduledPaymentsBatchRequest => ({
    type: REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REQUEST,
  });

export const refreshOneScheduledPaymentsBatchRemove = (
  batchId: string,
): RefreshOneScheduledPaymentsBatchRemove => ({
  type: REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_REMOVE,
  payload: {
    batchId,
  },
});

export const refreshOneScheduledPaymentsBatchUpdate = (
  batch: ScheduledPaymentsBatch,
): RefreshOneScheduledPaymentsBatchUpdate => ({
  type: REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_UPDATE,
  payload: batch,
});

export const refreshOneScheduledPaymentsBatchFailure =
  (): RefreshOneScheduledPaymentsBatchFailure => ({
    type: REFRESH_ONE_SCHEDULED_PAYMENTS_BATCH_FAILURE,
  });

export const fetchScheduledPaymentsByBatchIdRequest = (
  payload: FetchScheduledPaymentsByBatchIdRequest['payload'],
): FetchScheduledPaymentsByBatchIdRequest => ({
  type: FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_REQUEST,
  payload,
});

export const fetchScheduledPaymentsByBatchIdSuccess = (
  payload: FetchScheduledPaymentsByBatchIdSuccess['payload'],
): FetchScheduledPaymentsByBatchIdSuccess => ({
  type: FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_SUCCESS,
  payload,
});

export const fetchScheduledPaymentsByBatchIdFailure = (
  payload: FetchScheduledPaymentsByBatchIdFailure['payload'],
): FetchScheduledPaymentsByBatchIdFailure => ({
  type: FETCH_SCHEDULED_PAYMENTS_BY_BATCH_ID_FAILURE,
  payload,
});

export const fetchScheduledPaymentRequest = (
  payload: FetchScheduledPaymentRequest['payload'],
): FetchScheduledPaymentRequest => ({
  type: FETCH_SCHEDULED_PAYMENT_REQUEST,
  payload,
});

export const fetchScheduledPaymentSuccess = (
  payload: FetchScheduledPaymentSuccess['payload'],
): FetchScheduledPaymentSuccess => ({
  type: FETCH_SCHEDULED_PAYMENT_SUCCESS,
  payload,
});

export const fetchScheduledPaymentFailure = (
  payload: FetchScheduledPaymentRequest['payload'],
): FetchScheduledPaymentFailure => ({
  type: FETCH_SCHEDULED_PAYMENT_FAILURE,
  payload,
});

export const resetOpenedScheduledPayment = (): ResetOpenedScheduledPayment => ({
  type: RESET_OPENED_SCHEDULED_PAYMENT,
});

export const confirmScheduledPaymentsBatchRequest =
  (): ConfirmScheduledPaymentsBatchRequest => ({
    type: CONFIRM_SCHEDULED_PAYMENTS_BATCH_REQUEST,
  });

export const confirmScheduledPaymentsBatchSuccess = (
  payload: ConfirmScheduledPaymentsBatchSuccess['payload'],
): ConfirmScheduledPaymentsBatchSuccess => ({
  type: CONFIRM_SCHEDULED_PAYMENTS_BATCH_SUCCESS,
  payload,
});

export const resetScheduledPaymentConfirmationAuthType =
  (): ResetScheduledPaymentsConfirmationAuthType => ({
    type: RESET_SCHEDULED_PAYMENTS_CONFIRMATION_AUTH_TYPE,
  });

export const confirmScheduledPaymentsBatchFailure =
  (): ConfirmScheduledPaymentsBatchFailure => ({
    type: CONFIRM_SCHEDULED_PAYMENTS_BATCH_FAILURE,
  });

export const confirmScheduledPaymentRequest =
  (): ConfirmScheduledPaymentRequest => ({
    type: CONFIRM_SCHEDULED_PAYMENT_REQUEST,
  });

export const confirmScheduledPaymentSuccess = (
  payload: ConfirmScheduledPaymentSuccess['payload'],
): ConfirmScheduledPaymentSuccess => ({
  type: CONFIRM_SCHEDULED_PAYMENT_SUCCESS,
  payload,
});

export const confirmScheduledPaymentFailure =
  (): ConfirmScheduledPaymentFailure => ({
    type: CONFIRM_SCHEDULED_PAYMENT_FAILURE,
  });

export const cancelScheduledPaymentRequest =
  (): CancelScheduledPaymentRequest => ({
    type: CANCEL_SCHEDULED_PAYMENT_REQUEST,
  });

export const cancelScheduledPaymentSuccess =
  (): CancelScheduledPaymentSuccess => ({
    type: CANCEL_SCHEDULED_PAYMENT_SUCCESS,
  });

export const cancelScheduledPaymentFailure =
  (): CancelScheduledPaymentFailure => ({
    type: CANCEL_SCHEDULED_PAYMENT_FAILURE,
  });

export const cancelScheduledPaymentsBatchRequest =
  (): CancelScheduledPaymentsBatchRequest => ({
    type: CANCEL_SCHEDULED_PAYMENTS_BATCH_REQUEST,
  });

export const cancelScheduledPaymentsBatchSuccess =
  (): CancelScheduledPaymentsBatchSuccess => ({
    type: CANCEL_SCHEDULED_PAYMENTS_BATCH_SUCCESS,
  });

export const cancelScheduledPaymentsBatchFailure =
  (): CancelScheduledPaymentsBatchFailure => ({
    type: CANCEL_SCHEDULED_PAYMENTS_BATCH_FAILURE,
  });

export const downloadCsvBatchDetailsRequest =
  (): DownloadCsvBatchDetailsRequest => ({
    type: DOWNLOAD_CSV_BATCH_DETAILS_REQUEST,
  });

export const downloadCsvBatchDetailsFailure =
  (): DownloadCsvBatchDetailsFailure => ({
    type: DOWNLOAD_CSV_BATCH_DETAILS_FAILURE,
  });

export const downloadCsvBatchDetailsSuccess =
  (): DownloadCsvBatchDetailsSuccess => ({
    type: DOWNLOAD_CSV_BATCH_DETAILS_SUCCESS,
  });
