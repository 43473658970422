import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export type CardAddress = {
  address: string;
  address2?: string;
  zipCode: string;
  city: string;
  country: string;
};

export const useCardAddressQuery = (
  cardId: string,
): QueryState<CardAddress> => {
  return useQuery<CardAddress, CardAddress>({
    key: ['cards', cardId, 'card-address'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/cards/${cardId}/card-address`,
    },
    reshapeData: (data) => data,
  });
};
