import React, { lazy, type ReactElement, Suspense, useMemo } from 'react';
import { useTimeout } from 'react-use';

/**
 * util to lazy load a component
 * Example usage:
  export const MyComponent = lazyLoad({
   loader: async () => ({
    default: (
      await import(
        \/* webpackChunkName: "MyComponent" *\/ './MyComponentPath'
        )
        ).MyComponent,
      }),
      loading: <EmptyStateLoading />,
      fallback: (props) => <EmptyStateError {...props} />,
    });
 */

// to avoid a screen flicker on fast connections, we only show the loading screen after 200ms
const showLoadingScreenDelay = 200;

// eslint-disable-next-line @typescript-eslint/ban-types
type Params<T extends {}> = {
  loader: Parameters<typeof lazy<React.ComponentType<T>>>[0];
  loading: ReactElement | null;
  fallback: ({ retry }: { retry(): void }) => ReactElement | null;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const lazyLoad = <T extends {}>({
  fallback,
  loader,
  loading,
}: Params<T>) => {
  // eslint-disable-next-line react/display-name
  return (props: T) => {
    const [getShowLoadingScreen] = useTimeout(showLoadingScreenDelay);
    const showLoadingScreen = getShowLoadingScreen();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const retry = () => setIsLoading(true);
    const LazyFallbackComponent = useMemo(
      () => Promise.resolve({ default: () => fallback({ retry }) }),
      [fallback],
    );

    const LoadingComponent = useMemo(
      () => (showLoadingScreen ? loading : null),
      [loading, showLoadingScreen],
    );

    const LazyComponent = useMemo(
      // we need to rebuild the lazy component when we retry the loading
      // see https://github.com/facebook/react/issues/14254
      () =>
        isLoading
          ? lazy(() =>
              // eslint-disable-next-line promise/prefer-await-to-then
              // @ts-expect-error: the fallback component is of a different type
              // eslint-disable-next-line promise/prefer-await-to-then
              loader().catch(() => {
                setIsLoading(false);
                return LazyFallbackComponent;
              }),
            )
          : lazy(() => LazyFallbackComponent),
      [loader, isLoading],
    ) as React.ComponentType<T>;

    return (
      <Suspense fallback={LoadingComponent}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};
