import { useMemo } from 'react';

import { NavigationLink } from 'common/components/NavigationLink';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { NavLink } from 'modules/app/layout/components/VerticalNavigation/NavLink/NavLink';
import { ReadyToSpendPopover } from 'modules/onboarding/setup-hub/components/ReadyToSpendPopover/ReadyToSpendPopover';
import { useSetupHubTasksQuery } from 'modules/onboarding/setup-hub/pages/SetupHubPage/hooks/useSetupHubTasksQuery';
import { type UserTaskType } from 'modules/onboarding/setup-hub/types/userTask';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';

export const READY_TO_SPEND_TASKS: UserTaskType[] = [
  'subscription',
  'single_use',
  'expense_claim',
  'upload_receipt',
  'learn_to_approve',
  'download_app',
];

export const SetupGuideNavigationItem = () => {
  const { t } = useTranslation('global');

  const company = useCompany();
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);
  const setupHubQueryState = useSetupHubTasksQuery();
  const isFullPage = useMemo(() => {
    return (
      setupHubQueryState.status === 'success' &&
      setupHubQueryState.data.length > 0
    );
  }, [setupHubQueryState]);
  const setupGuidePath = routeFor(routes.SETUP_HUB.path, {
    company: company.id,
  });

  if (isFullPage) {
    return hasNewNavigation ? (
      <NavLink
        href={setupGuidePath}
        iconName="expense-claim"
        activePaths={[routes.SETUP_HUB.path]}
      >
        {t('readyToSpend.setupHub')}
      </NavLink>
    ) : (
      <NavigationLink
        key="setup-guide"
        text={t('readyToSpend.setupHub')}
        path={setupGuidePath}
      />
    );
  }
  return <ReadyToSpendPopover />;
};
