import {
  Button,
  IconButton,
  Popover,
  Tag,
  TextArea,
  type TriggerProps,
} from '@dev-spendesk/grapes';
import { type ReactNode, useId, useState } from 'react';

import { usePublishNoteToProductBoard } from 'src/core/common/hooks/usePublishNoteToProductBoard/usePublishNoteToProductBoard';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './FeedbackPopover.module.css';
import Illustration from './illustration.svg';

type Props = {
  children: (triggerProps: TriggerProps) => ReactNode;
};

export function FeedbackPopover({ children }: Props) {
  const { t } = useTranslation('global');
  const [feedback, setFeedback] = useState('');
  const [publishNoteToProductBoard] = usePublishNoteToProductBoard();
  const titleId = useId();

  const handleSubmit =
    (closeDropDown: () => void) =>
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      await publishNoteToProductBoard({
        title: 'Navigation Feedback',
        content: feedback,
        tags: ['design-system', 'new_navigation'],
      });
      closeDropDown();
      setFeedback('');
    };

  return (
    <Popover
      placement="end-top"
      className="popover-hack-align w-full"
      renderTrigger={(triggerProps) => {
        return children(triggerProps);
      }}
    >
      {(closeDropdown) => (
        <form
          aria-labelledby={titleId}
          onSubmit={handleSubmit(closeDropdown)}
          className={styles.form}
        >
          <IconButton
            iconName="cross"
            variant="borderless"
            aria-label={t('misc.close')}
            className="ml-auto block text-white"
            onClick={closeDropdown}
          />
          <div className={styles.grid}>
            <img src={Illustration} width={150} className={styles.img} alt="" />
            <Tag variant="info" className={styles.tag}>
              {t('betaFeature.beta')}
            </Tag>
          </div>
          <h1 id={titleId} className="mt-s text-white title-xl">
            {t('navigation.feedback.title')}
          </h1>
          <p className="mt-s text-white body-m">
            {t('navigation.feedback.description')}
          </p>
          <TextArea
            value={feedback}
            onChange={(event) => setFeedback(event.target.value)}
            className="mt-s"
            aria-labelledby={titleId}
            placeholder={t('navigation.feedback.placeholder')}
          />
          <Button
            type="submit"
            text={t('navigation.feedback.submit')}
            variant="contrasted"
            fit="parent"
            className="mt-l"
            isDisabled={feedback.trim().length < 1}
          />
        </form>
      )}
    </Popover>
  );
}
