import React, { useEffect, useState } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useModal } from 'common/hooks/useModal';
import FEATURES from 'src/core/constants/features';
import {
  type Delegation,
  DelegationConfirmationModal,
} from 'src/core/modules/delegation';

import { MemberDeleteModalContainer } from '../../containers/MemberDeleteModalContainer';
import { MemberDetailsModalContainer } from '../../containers/MemberDetailsModalContainer';
import { MemberEditModalContainer } from '../../containers/MemberEditModalContainer';
import { MemberInviteModalContainer } from '../../containers/MemberInviteModalContainer';
import { MembersBulkEditModalContainer } from '../../containers/MembersBulkEditModalContainer/MembersBulkEditModalContainer';
import { MembersTableContainer } from '../../containers/MembersTableContainer';
import { type Member } from '../../models/member';
import { MembersPaginated } from '../MembersPaginated/MembersPaginated';

export const MembersTab = ({
  selectedMemberId,
  setSelectedMemberId,
}: {
  selectedMemberId: string | undefined;
  setSelectedMemberId(memberId: string | null): void;
}) => {
  useEffect(() => {
    if (selectedMemberId) {
      showMemberDetailsModal();
    }
  }, []);
  const hasTemporaryMembersTableRowQuery = useFeature(
    FEATURES.TMP_MEMBER_TABLE_ROWS_QUERY,
  );
  const [isMemberInviteModalOpen, setIsMemberInviteModalOpen] = useState(false);

  const [memberDetailsModal, showMemberDetailsModal, hideMemberDetailsModal] =
    useModal(() => {
      if (!selectedMemberId) {
        return null;
      }
      return (
        <MemberDetailsModalContainer
          memberId={selectedMemberId}
          onEdit={() => {
            hideMemberDetailsModal();
            showMemberEditModal();
          }}
          onDelete={() => {
            hideMemberDetailsModal();
            showMemberDeleteModal();
          }}
          onClose={() => {
            hideMemberDetailsModal();
            setSelectedMemberId(null);
          }}
        />
      );
    });

  const [memberEditModal, showMemberEditModal, hideMemberEditModal] = useModal(
    () => {
      if (!selectedMemberId) {
        return null;
      }
      return (
        <MemberEditModalContainer
          memberId={selectedMemberId}
          onClose={() => {
            hideMemberEditModal();
            hideDelegationConfirmationModal();
            showMemberDetailsModal();
          }}
          onEditDelegation={(role, onComplete) => {
            hideMemberEditModal();
            showDelegationConfirmationModal({ role, onComplete });
          }}
        />
      );
    },
  );

  const [memberDeleteModal, showMemberDeleteModal, hideMemberDeleteModal] =
    useModal(() => {
      if (!selectedMemberId) {
        return null;
      }
      return (
        <MemberDeleteModalContainer
          memberId={selectedMemberId}
          onClose={() => {
            hideMemberDeleteModal();
            showMemberDetailsModal();
          }}
        />
      );
    });

  const [
    membersBulkEditModal,
    showMembersBulkEditModal,
    hideMembersBulkEditModal,
  ] = useModal((members: Member[]) => {
    return (
      <MembersBulkEditModalContainer
        members={members}
        onClose={() => {
          hideMembersBulkEditModal();
        }}
      />
    );
  });

  const [
    delegationConfirmationModal,
    showDelegationConfirmationModal,
    hideDelegationConfirmationModal,
  ] = useModal(
    ({ role, onComplete }: { role: Delegation; onComplete: () => void }) => {
      if (!selectedMemberId) {
        return null;
      }

      return (
        <DelegationConfirmationModal
          delegation={{ userId: selectedMemberId, role }}
          onComplete={onComplete}
          onClose={() => {
            hideDelegationConfirmationModal();
            showMemberEditModal();
          }}
        />
      );
    },
  );

  return (
    <div>
      {hasTemporaryMembersTableRowQuery ? (
        <MembersPaginated
          onInviteClick={() => setIsMemberInviteModalOpen(true)}
          onMemberClick={(member: { id: string }) => {
            setSelectedMemberId(member.id);
            showMemberDetailsModal();
          }}
        />
      ) : (
        <MembersTableContainer
          onInviteClick={() => setIsMemberInviteModalOpen(true)}
          onMemberClick={(member) => {
            setSelectedMemberId(member.id);
            showMemberDetailsModal();
          }}
          onMemberEditClick={(member) => {
            setSelectedMemberId(member.id);
            showMemberEditModal();
          }}
          onBulkEditClick={showMembersBulkEditModal}
        />
      )}
      <MemberInviteModalContainer
        isOpen={isMemberInviteModalOpen}
        onClose={() => setIsMemberInviteModalOpen(false)}
      />
      {memberDetailsModal}
      {memberEditModal}
      {memberDeleteModal}
      {membersBulkEditModal}
      {delegationConfirmationModal}
    </div>
  );
};
