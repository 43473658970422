import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ProtectedRoute } from 'common/components/ProtectedRoute';
import { SettingsLayout } from 'common/components/layout';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { routes } from 'src/core/constants/routes';

import { UserNotificationsContainer } from './components/UserNotifications';
import { UserNotificationsPageNav } from './components/UserNotificationsPageNav';
import { ProfileSubNavigation } from '../app/layout/components/Header/ProfileSubNavigation';

export const UserNotificationsPage = () => {
  const companyId = useCompanyId();
  const { isController } = useUserRoles();
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  return (
    <div className="page__container">
      {hasNewNavigation && <ProfileSubNavigation />}
      <SettingsLayout>
        <UserNotificationsPageNav companyId={companyId} />
        <Switch>
          <Route path={routes.ACCOUNT_NOTIFICATIONS_GENERAL.path}>
            <UserNotificationsContainer category="General" />
          </Route>
          <ProtectedRoute
            path={routes.ACCOUNT_NOTIFICATIONS_CONTROL.path}
            isAccessAllowed={isController}
          >
            <UserNotificationsContainer category="Control" />
          </ProtectedRoute>
          <Route path={routes.ACCOUNT_NOTIFICATIONS_USAGE.path}>
            <UserNotificationsContainer category="Usage" />
          </Route>
          <Redirect to={routes.ACCOUNT_NOTIFICATIONS_GENERAL.path} />
        </Switch>
      </SettingsLayout>
    </div>
  );
};
