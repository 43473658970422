import { useAccountingIntegrationStatusQuery } from 'modules/accounting-integration/apis';

import { isIntegrationStatusWithIntegration } from '../integration/status';

export function useHasAuxiliaryAccountsEnabled(): boolean {
  const accountingIntegrationStatusQueryResult =
    useAccountingIntegrationStatusQuery();

  if (
    accountingIntegrationStatusQueryResult.status !== 'success' ||
    !isIntegrationStatusWithIntegration(
      accountingIntegrationStatusQueryResult.data,
    )
  ) {
    return false;
  }

  return Boolean(
    accountingIntegrationStatusQueryResult.data.capabilities
      .auxiliaryAccountsEnabled,
  );
}
