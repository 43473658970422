import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { OCRIcon } from './OCRIcon';

type Props = {
  optionalField?: boolean;
  showOCR?: boolean;
};

export const FormFieldHint = ({ optionalField, showOCR }: Props) => {
  const { t } = useTranslation('global');

  return (
    <>
      {optionalField && t('misc.optional')}
      {showOCR && <OCRIcon />}
    </>
  );
};
