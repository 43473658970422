import { useFeature } from 'common/hooks/useFeature';
import FEATURES from "src/core/constants/features";

export const useUsersCountLimitFeatures = (): boolean => {
  const isUsersCountLimitFeatureEnabled = useFeature(
    FEATURES.USERS_COUNT_LIMIT,
  );
  const isFreemiumUsersCountLimitFeatureEnabled = useFeature(
    FEATURES.FREEMIUM_USERS_COUNT_LIMIT,
  );
  return (
    isUsersCountLimitFeatureEnabled || isFreemiumUsersCountLimitFeatureEnabled
  );
};
