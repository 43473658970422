import { Skeleton, SkeletonButton, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export function TellUsBoxSkeleton() {
  return (
    <div className="box flex flex-col items-center justify-center gap-s bg-neutral-lightest p-m">
      <Skeleton height="70px" width="70px" />
      <SkeletonText size="m" width="100px" />
      <SkeletonButton />
    </div>
  );
}
