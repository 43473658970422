import { Navigation } from '@dev-spendesk/grapes';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { NavigationLink } from 'common/components/NavigationLink';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { SubnavigationItem } from '../../utils';
import type { RequestsStatByType } from '../../utils/requestStat';

type Props = {
  stats: RequestsStatByType;
};

export const ApproverRequestsSubNav = ({ stats }: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const { search } = useLocation();
  const hasSubmitMyInvoiceFeature = useFeature(FEATURES.SUBMIT_MY_INVOICE);
  const hasInboxInvoiceFeature = useFeature(FEATURES.BILL_INBOX);
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);
  const filters = search;

  const leftNavigationItems = [
    <NavigationLink
      key="sub_nav_requests_to_approve"
      path={`${routeFor(routes.REQUESTS.path, {
        company: companyId,
        type: SubnavigationItem.ToApprove,
      })}${filters}`}
      text={t('requests.toApprove')}
      count={stats.to_approve}
      withDivider
    />,
    <NavigationLink
      key="sub_nav_all_requests"
      path={`${routeFor(routes.REQUESTS.path, {
        company: companyId,
        type: SubnavigationItem.All,
      })}${filters}`}
      text={t('requests.allRequests')}
      count={stats.all}
    />,
    <NavigationLink
      key="sub_nav_your_requests"
      path={`${routeFor(routes.REQUESTS.path, {
        company: companyId,
        type: SubnavigationItem.Mine,
      })}${filters}`}
      text={t('requests.yourRequests')}
      count={stats.mine}
    />,
  ];

  // Drafts feature is only available for invoice requests
  if (hasSubmitMyInvoiceFeature) {
    leftNavigationItems.push(
      <NavigationLink
        key="sub_nav_draft_requests"
        path={routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: SubnavigationItem.Drafts,
        })}
        text={t('requests.yourDrafts')}
        count={stats.drafts}
      />,
    );
  }

  if (hasInboxInvoiceFeature && !hasNewNavigation) {
    leftNavigationItems.unshift(
      <NavigationLink
        key={SubnavigationItem.InboxInvoices}
        path={routeFor(routes.INBOX_INVOICES.path, {
          company: companyId,
        })}
        text={t('requests.inboxInvoicesSection')}
        count={stats.inbox_invoices}
      />,
    );
  }

  return (
    <Navigation
      variant="subNavigation"
      aria-label="Sub navigation"
      leftNavigationItems={leftNavigationItems}
      className="shrink-0"
    />
  );
};
