import React from 'react';

import {
  PanelLoader,
  PanelHeaderLoader,
} from 'src/core/common/components/Panel';

type Props = {
  className?: string;
};

export const PayablePanelLoader = ({ className }: Props) => {
  return <PanelLoader className={className} header={<PanelHeaderLoader />} />;
};
