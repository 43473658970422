import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type PayableExportId } from 'modules/payable-export/models';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { type Translations } from 'src/core/common/components/QueryError';

import { useInvalidateExportPayablesCurrentStateQueryCache } from './useExportPayablesCurrentState';

export type ExportPayablesError =
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason:
        | 'mfaNotEnabled'
        | 'notAllowed'
        | 'mfaFailed'
        | 'unknown'
        | 'notConnected'
        | 'accountingExportAlreadyExists'
        | 'invalidPayables'
        | 'noAccountingIntegration'
        | 'noPayablesFound'
        | 'notAllowed';
    };

export type ExportPayablesResponse = {
  exportId: PayableExportId;
};

export type ExportContentOption = 'bank' | 'purchase' | 'receipts';

export type ExportTemplatesOptions = {
  bankJournalTemplateId?: string;
  purchaseJournalTemplateId?: string;
};

export type ExportPayablesPayload = {
  period: { from: string; to: string };
  inboxVersion: number;
  includeUnexportedPast?: boolean;
  includeAlreadyExported?: boolean;
  includeDocumentaryEvidences?: boolean;
  markAsAccountedThroughSupervision?: boolean;
  selectedExportOptions: ExportContentOption[];
  templates?: ExportTemplatesOptions;
};

/**
 * Mutation
 **/

export const useExportPayables = () => {
  const companyId = useCompanyId();

  const invalidateExportPayablesCurrentStateQueryCache =
    useInvalidateExportPayablesCurrentStateQueryCache();

  return useMutation<
    ExportPayablesPayload,
    ExportPayablesResponse,
    ExportPayablesResponse,
    ExportPayablesError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/export/payables',
      method: 'post',
    },
    options: {
      throwOnError: true,

      onSuccess: () => {
        invalidateExportPayablesCurrentStateQueryCache(companyId);
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};

/**
 * Error messages
 **/

export const exportPayablesErrorTranslations: Translations<ExportPayablesError> =
  {
    requestError: ({ reason }) => {
      switch (reason) {
        default:
          return 'payableExport.api.exportPayables.error';
      }
    },
    serverError: 'payableExport.api.exportPayables.error',
  };
