import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';

export type InvoiceStatus =
  | 'paid'
  | 'posted'
  | 'voided'
  | 'pending'
  | 'paymentDue'
  | 'notPaid'
  | 'adjusted'
  | 'refunded'
  | 'refundDue';

export type Invoice = {
  createdAt: string;
  id: string;
  number: string;
  paymentInvoice?: {
    url: string;
  };
  status: InvoiceStatus;
  total: number;
};

export const formatInvoiceStatus = (
  status: InvoiceStatus,
  t: TGlobalFunctionTyped,
): string => {
  // For translations:
  // billing.invoicesList.statuses.paid
  // billing.invoicesList.statuses.posted
  // billing.invoicesList.statuses.voided
  // billing.invoicesList.statuses.pending
  // billing.invoicesList.statuses.paymentDue
  // billing.invoicesList.statuses.notPaid
  // billing.invoicesList.statuses.adjusted
  // billing.invoicesList.statuses.refunded
  // billing.invoicesList.statuses.refundDue
  switch (status) {
    case 'paid':
    case 'posted':
    case 'voided':
    case 'pending':
    case 'paymentDue':
    case 'notPaid':
    case 'adjusted':
    case 'refunded':
    case 'refundDue':
      return t(`billing.invoicesList.statuses.${status}`);
    default:
      return t('billing.invoicesList.statuses.notAvailable');
  }
};
