import { useLazyQuery } from 'src/core/api/hooks/useLazyQuery';
import { type LazyState } from 'src/core/api/queryState';

import { type Supplier, type SupplierId } from './supplier';

type RawSupplier = { id: string; name: string };

export const useSupplierLazyQuery = (
  supplierId: SupplierId | undefined,
): LazyState<Supplier> => {
  return useLazyQuery<Supplier, RawSupplier>({
    key: ['suppliers', supplierId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/suppliers/${supplierId}`,
    },
    reshapeData: reshapeRawSuppliers,
    options: {
      throwOnError: true,
    },
  });
};

const reshapeRawSuppliers = (rawSupplier: RawSupplier): Supplier => ({
  key: rawSupplier.id,
  label: rawSupplier.name,
});
