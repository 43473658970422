import React from 'react';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
};

export const PurchaseOrdersLink = ({
  company,
  className,
  isInDropdown,
  onClick,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavigationLink
      data-marketing="top-menu_purchase-orders"
      key="nav_purchase_orders"
      className={className}
      onClick={onClick}
      path={routeFor(routes.PURCHASE_ORDERS.path, {
        company: company.id,
      })}
      text={t('misc.purchaseOrders')}
      isInDropdown={isInDropdown}
    />
  );
};
