import { Button, Modal } from '@dev-spendesk/grapes';
import type { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ExpenseAccountErrorModal.module.css';

type ExpenseAccountWorksheetErrorPosition = {
  column: number;
  row: number;
};

type ExpenseAccountImportError = {
  position?: ExpenseAccountWorksheetErrorPosition;
  errorCode: string;
  message: string;
};

type ExpenseAccountErrorModalProps = {
  errors: ExpenseAccountImportError[];
  isOpen: boolean;
  action: () => void;
  close: () => void;
};

type ErrorBodyProps = {
  title: string;
  description: string;
  translate: TFunction<'translation', undefined>;
  error: ExpenseAccountImportError;
};

type BodyPropsByCode = {
  [key: string]: {
    title: string;
    description?: string;
  };
};

const ErrorBody = function ({
  title,
  description,
  translate,
  error,
}: ErrorBodyProps) {
  const getRowText = function (row: number) {
    const rowNumber = row + 1;
    return translate('accounting.expenseAccounts.errors.rowPositionError', {
      row: rowNumber,
    });
  };

  return (
    <div className={styles.ExpenseAccountImportError}>
      <h3>&quot;{title}&quot;</h3>
      <p>{description}</p>
      {error.position !== undefined ? getRowText(error.position.row) : null}
    </div>
  );
};

const translateErrorPath = 'accounting.expenseAccounts.errors';

const bodyPropsByCode: BodyPropsByCode = {
  empty_expense_account_name: {
    title: `${translateErrorPath}.emptyExpenseAccountName.title`,
    description: `${translateErrorPath}.emptyExpenseAccountName.description`,
  },
  empty_expense_account_code: {
    title: `${translateErrorPath}.emptyExpenseAccountCode.title`,
    description: `${translateErrorPath}.emptyExpenseAccountCode.description`,
  },
  duplicate_expense_account_name: {
    title: `${translateErrorPath}.duplicateExpenseAccountName.title`,
    description: `${translateErrorPath}.duplicateExpenseAccountName.description`,
  },
  duplicate_expense_account_code: {
    title: `${translateErrorPath}.duplicateExpenseAccountCode.title`,
    description: `${translateErrorPath}.duplicateExpenseAccountCode.description`,
  },
  invalid_file_format: {
    title: `${translateErrorPath}.invalidFileFormat.title`,
    description: `${translateErrorPath}.invalidFileFormat.description`,
  },
  invalid_file_type: {
    title: `${translateErrorPath}.invalidFileType.title`,
    description: `${translateErrorPath}.invalidFileType.description`,
  },
  empty_expense_accounts: {
    title: `${translateErrorPath}.emptyExpenseAccounts.title`,
    description: `${translateErrorPath}.emptyExpenseAccounts.description`,
  },
  invalid_form_data: {
    title: `${translateErrorPath}.invalidFormData.title`,
    description: `${translateErrorPath}.invalidFormData.description`,
  },
  default: {
    title: `${translateErrorPath}.default.title`,
  },
};

const createUniqueKeyFromError = (error: ExpenseAccountImportError): string => {
  if (!error.position) {
    return error.errorCode;
  }

  return `${error.errorCode}-${error.position.row}`;
};

export const ExpenseAccountErrorModal = function ({
  errors,
  action,
  isOpen,
  close,
}: ExpenseAccountErrorModalProps) {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen={isOpen}
      title={t('accounting.expenseAccounts.errors.modalTitle')}
      iconName="failure"
      iconVariant="alert"
      actions={[
        <Button
          key="cancel"
          text={t('accounting.expenseAccounts.errors.cancel')}
          variant="secondary"
          onClick={close}
        />,
        <Button
          key="try_again"
          text={t('accounting.expenseAccounts.errors.tryAgain')}
          onClick={action}
        />,
      ]}
    >
      {errors.map((error) => {
        const { description, title } =
          bodyPropsByCode[error.errorCode] ?? bodyPropsByCode.default;
        const descriptionText = description ? t(description) : error.message;
        return (
          <ErrorBody
            key={createUniqueKeyFromError(error)}
            error={error}
            translate={t}
            title={t(title)}
            description={descriptionText}
          />
        );
      })}
    </Modal>
  );
};
