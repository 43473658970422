import { useContext } from 'react';

import { useQuery } from 'src/core/api/hooks';
import { type QueryState } from 'src/core/api/queryState';

import { type PayablesBucketsQueryRawResult } from '../../../bookkeep/apis/prepare-payable';
import { makeGetPayablesBucketsStatsQuery } from '../../../bookkeep/apis/prepare-payable/usePayablesBucketsStatsQuery/query';
import { PreparePayablesFiltersContext } from '../../../bookkeep/prepare-payables/contexts';
import { toAPIPayableFilters } from '../../../bookkeep/prepare-payables/models';

type PayableStats = {
  payablesToReview: number;
};

export const useOrganisationEntityPayablesInfoQuery = (
  companyId: string,
  options: {
    isEnabled: boolean;
  } = {
    isEnabled: true,
  },
): QueryState<PayableStats> => {
  const { state: payablesToPrepageFilters } = useContext(
    PreparePayablesFiltersContext,
  );

  const variables = {
    companyId,
    filters: toAPIPayableFilters({
      filters: payablesToPrepageFilters,
      withBookkeepingStartDate: true,
    }),
  };

  // FIXME: This gql query retrieves lots of information about payables.
  // If there is a performance issue, we should consider to use the one
  // from src/core/modules/homepage/Homepage/hooks/useActionsCountsQuery.ts
  return useQuery<PayableStats, PayablesBucketsQueryRawResult>({
    key: ['organisation-reporting', companyId, 'payables-to-prepare'],
    request: {
      type: 'graphQL',
      target: 'v2',
      query: makeGetPayablesBucketsStatsQuery(variables),
      variables,
    },
    isEnabled: options.isEnabled,
    reshapeData: (data) => ({
      payablesToReview: data.company.payablesFiltered?.totalCount ?? 0,
    }),
  });
};
