import { useParams } from 'react-router-dom';

import { routes, routeFor } from 'src/core/constants/routes';
import history from 'src/core/history';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type Invoice } from '../models';

export const useGoToInvoice = () => {
  const { company: companyId }: { company: string } = useParams();

  return (invoice: Invoice) => {
    history.push(getInvoiceRoute(companyId, invoice));
  };
};

const getInvoiceRoute = (companyId: string, invoice: Invoice) => {
  switch (invoice.status) {
    case 'pending-approval':
      return routeFor(routes.REQUESTS.path, {
        company: companyId,
        type: 'all',
        id: invoice.requestId,
        newRequestType: 'invoice',
      });
    case 'in-review':
      return routeFor(routes.INVOICES_REVIEW.path, {
        company: companyId,
        id: invoice.requestId,
      });
    case 'to-schedule':
      return routeFor(routes.INVOICES_PAY.path, {
        company: companyId,
        paymentToScheduleId: invoice.paymentToScheduleId,
      });
    case 'paid':
      return routeFor(routes.PAYMENTS_ALL.path, {
        company: companyId,
        id: invoice.latestPaymentId,
      });
    default: {
      rejectUnexpectedValue('invoice status', invoice);
    }
  }
};
