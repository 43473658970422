import { ModalContent, ModalOverlay } from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  children: ReactNode;
};
export const FloatingRequestPanel = ({ children, isOpen }: Props) => {
  return (
    <ModalOverlay isOpen={isOpen}>
      <div className="relative h-full w-full" id="request-floating">
        <ModalContent className="absolute right-s top-s z-50 m-0 h-[calc(100%_-_32px)] w-[372px] overflow-y-auto">
          {children}
        </ModalContent>
      </div>
    </ModalOverlay>
  );
};
