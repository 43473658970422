import { useTranslation } from 'common/hooks/useTranslation';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { NotificationType, useNotifications } from '../../app/notifications';

type Payload = {
  user_id: string;
};

export const useTransferAccountMutation = (): MutationState<Payload> => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  return useMutation<Payload>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: '/grant',
    },
    options: {
      onSuccess: () => {
        pushNotif({
          type: NotificationType.Success,
          message: t('profile.transferAccountOwnershipModalSuccessMessage'),
        });
        window.location.reload();
      },
      onError: () => {
        pushNotif({
          type: NotificationType.Danger,
          message: t('profile.transferAccountOwnershipModalErrorMessage'),
        });
      },
      throwOnError: true,
    },
  });
};
