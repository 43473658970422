import { useCompany } from 'modules/app/hooks/useCompany';

import { useHasAccessToKycStateRelatedFeatures } from './useHasAccessToKycStateRelatedFeatures';

export const useShouldCompleteKyc = () => {
  const hasAccessToKycStateRelatedFeatures =
    useHasAccessToKycStateRelatedFeatures();
  const company = useCompany();

  if (company.type === 'branch_expense_entity') {
    return false;
  }
  return !hasAccessToKycStateRelatedFeatures;
};
