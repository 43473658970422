import { useDispatch } from 'react-redux';

import { requestMfaProcedureSuccess } from 'common/components/ModalMFA/actions';
import { useQueryError } from 'common/components/QueryError/useQueryError';
import { useTranslation } from 'common/hooks/useTranslation';
import { requestScaProcedureSuccess } from 'modules/StrongCustomerAuthentication/redux/actions';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';

import { type TransferId } from '../../models';

export type AuthType = 'sca' | 'mfa';

type ConfirmTransfersPayload = {
  transferIds: TransferId[];
};

export type ConfirmationError = {
  wtRequestId: string;
  transferSchedulingPaymentId: TransferId;
  error: string;
};

export type ConfirmTransferErrors =
  | {
      transferId: TransferId;
      reason:
        | 'walletAmountInsufficient'
        | 'alreadyCancelled'
        | 'alreadyConfirmed'
        | 'supplierNotFound'
        | 'supplierBankInfoInvalid'
        | 'notScreened'
        | 'screeningPending'
        | 'screeningBlocked';
      error?: string;
    }
  | {
      reason:
        | 'forbidden'
        | 'bankingProviderNotSupportedForInvoiceWireTransfers'
        | 'accountOwnerIsMissingMfaPhoneFactor'
        | 'unableToCreateScaProcedure';
    }
  | {
      reason: typeof GBP_BATCH_CONFIRMATION_NOT_ALLOWED_REASON;
      errors: ConfirmationError[];
    };

export const GBP_BATCH_CONFIRMATION_NOT_ALLOWED_REASON =
  'batchConfirmNotAllowedForGbpWireTransfer';

export const GBP_BATCH_CONFIRMATION_MAX_RETRIES = 10;

type ConfirmTransferResponse =
  | {
      authType: 'mfa';
      factorId: string;
      procedureId: string;
    }
  | {
      authType: 'sca';
      procedureId: string;
    };

export const useConfirmTransfers = () => {
  const dispatch = useDispatch();

  return useMutation<
    ConfirmTransfersPayload,
    ConfirmTransferResponse,
    ConfirmTransferResponse,
    ConfirmTransferErrors
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/transfer-scheduling/transfers/confirmation`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data }): void => {
        if (data.authType === 'mfa') {
          dispatch(
            requestMfaProcedureSuccess({
              id: data.procedureId,
              factorId: data.factorId,
            }),
          );
        } else {
          dispatch(
            requestScaProcedureSuccess({
              procedureId: data.procedureId,
            }),
          );
        }
      },
    },
    reshapeData: (data) => data,
  });
};

type TranslationsParams = {
  count: number;
};

export const useConfirmTransferErrorMessage = () => {
  const { t } = useTranslation('global');
  const getErrorMessage = useQueryError<
    ConfirmTransferErrors,
    TranslationsParams
  >({
    requestError: ({ reason }) => {
      switch (reason) {
        case 'walletAmountInsufficient':
          return 'invoices.transfer.confirm.walletAmountInsufficientError';

        case 'forbidden':
          return 'invoices.transfer.confirm.forbiddenError';

        case 'alreadyCancelled':
          return 'invoices.transfer.confirm.alreadyCancelledError';

        case 'alreadyConfirmed':
          return 'invoices.transfer.confirm.alreadyConfirmedError';

        case 'supplierNotFound':
          return 'invoices.transfer.confirm.supplierNotFoundError';

        case 'supplierBankInfoInvalid':
          return 'invoices.transfer.confirm.supplierBankInfoInvalidError';

        case 'bankingProviderNotSupportedForInvoiceWireTransfers':
          return 'invoices.transfer.confirm.bankingProviderNotSupportedForInvoiceWireTransfersError';

        case 'accountOwnerIsMissingMfaPhoneFactor':
        case 'unableToCreateScaProcedure':
          return 'invoices.transfer.confirm.accountOwnerIsMissingMfaPhoneFactorError';

        case 'notScreened':
        case 'screeningPending':
        case 'screeningBlocked':
          return 'invoices.transfer.confirm.userNotScreenedError';

        default:
          return 'invoices.transfer.confirm.error';
      }
    },
    serverError: 'invoices.transfer.confirm.error',
  });

  const shouldDisplayErrorNotification = (
    queryError: QueryError<ConfirmTransferErrors>,
    translationsParams: TranslationsParams,
  ) => {
    return (
      getErrorMessage(queryError, translationsParams) !==
      t('invoices.transfer.confirm.accountOwnerIsMissingMfaPhoneFactorError')
    );
  };

  const getMessage = (
    queryError: QueryError<ConfirmTransferErrors>,
    translationsParams: TranslationsParams,
  ): string => getErrorMessage(queryError, translationsParams);

  return { getMessage, shouldDisplayErrorNotification };
};
