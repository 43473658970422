import assignIn from 'lodash/assignIn';

import * as customFieldsTypes from 'src/core/actionTypes/customFields';

type AccountingLegacyState = {
  customFields: unknown[] | null;
  behavior: {
    errors: boolean | null;
    isLoading: boolean;
  };
};
const initialState: AccountingLegacyState = {
  customFields: null,
  behavior: {
    errors: null,
    isLoading: false,
  },
};

const accountingReducer = (
  state = initialState,
  action: {
    type: keyof typeof customFieldsTypes;
    payload: {
      customFields?: unknown[];
    };
  },
) => {
  switch (action.type) {
    case customFieldsTypes.FETCH_CF_LOADING: {
      return assignIn({}, state, {
        behavior: {
          ...state.behavior,
          isLoading: true,
        },
      });
    }

    case customFieldsTypes.FETCH_CF_SUCCESS: {
      return assignIn({}, state, {
        customFields: action.payload.customFields,
        behavior: {
          ...state.behavior,
          errors: null,
          isLoading: false,
        },
      });
    }

    case customFieldsTypes.FETCH_CF_FAILURE: {
      return assignIn({}, state, {
        behavior: {
          ...state.behavior,
          errors: true,
          isLoading: false,
        },
      });
    }

    default:
      return state;
  }
};

export default accountingReducer;
