import { Button, FormField, Modal, TextInput } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { useDebounce } from 'react-use';

import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { useTransferOwnership } from 'modules/onboarding/onboarding-hub/components/TransferSectionOwnershipModal/hooks/useTransferOwnership';
import { useCheckEmailsCanBeInvited } from 'modules/onboarding/onboarding-hub/hooks/useCheckEmailsCanBeInvited';
import { type HubSection } from 'modules/onboarding/types/hubSection';
import { type HubTask } from 'modules/onboarding/types/hubTask';
import isValidEmail from 'src/auth/utils/isValidEmail';
import { logger } from 'src/utils/datadog-log-wrapper';

const getSectionName = (section: HubSection<HubTask>): I18nKey | null => {
  if (section.id === 'account_setup') {
    return 'onboardingHub.accountSetupTask.name';
  }
  return null;
};

type Props = {
  section: HubSection<HubTask>;
  isOpen: boolean;
  onClose: () => void;
};

export const TransferSectionOwnershipModal = ({
  section,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');
  const user = useUser();

  const [transferOwnership] = useTransferOwnership();

  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const [hasSubmittedOnce, setHasSubmittedOnce] = useState<boolean>(false);
  const sectionName = getSectionName(section);
  const [checkEmailsCanBeInvited] = useCheckEmailsCanBeInvited();

  useDebounce(
    () => {
      if (hasSubmittedOnce) {
        setHasEmailError(!isEmailValid());
      }
    },
    500,
    [emailValue],
  );

  const getSubtitle = () => {
    if (isConfirmStep && sectionName) {
      return t('onboardingHub.transferOwnershipModal.confirmation', {
        email: emailValue,
        sectionName: t(sectionName),
      });
    }
    if (!isConfirmStep && section.id === 'account_setup') {
      return t(
        'onboardingHub.transferOwnershipModal.companySetupTransferDescription',
      );
    }
    return '';
  };

  const isEmailValid = () => {
    const trimmedEmailValue = emailValue.trim().toLowerCase();
    return (
      isValidEmail(trimmedEmailValue) &&
      user.email.trim().toLowerCase() !== trimmedEmailValue
    );
  };

  const validateEmailWithApi = async () => {
    const trimmedEmailValue = emailValue.trim().toLowerCase();

    const emailsCanBeInvitedResult = await checkEmailsCanBeInvited({
      emails: [trimmedEmailValue],
    });
    return !emailsCanBeInvitedResult.some(
      ({ email, isValid }) => email === trimmedEmailValue && !isValid,
    );
  };

  const onBack = () => {
    if (isConfirmStep) {
      setIsConfirmStep(false);
    } else {
      onClose();
    }
  };

  const onNext = async () => {
    setHasSubmittedOnce(true);
    if (isConfirmStep) {
      await transferOwnership({
        taskIds: section.tasks.map((task) => task.id),
        assigneeEmail: emailValue,
      });
      setIsConfirmStep(false);
      onClose();
    } else {
      const isValid = isEmailValid() && (await validateEmailWithApi());
      if (isValid) {
        setIsConfirmStep(true);
      } else {
        setHasEmailError(true);
      }
    }
  };

  if (!sectionName) {
    logger.error(
      `Trying to transfer a section that cannot be transfered - id : ${section.id}`,
      {
        scope: 'register',
        team: 'growth',
      },
    );
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        isConfirmStep
          ? t('onboardingHub.transferOwnershipModal.confirmationTitle', {
              sectionName: t(sectionName),
            })
          : t('onboardingHub.transferOwnershipModal.title', {
              sectionName: t(sectionName),
            })
      }
      subtitle={getSubtitle()}
      iconName="swap"
      iconVariant="primary"
      actions={[
        <Button
          key="cancel"
          variant="secondary"
          text={isConfirmStep ? t('misc.back') : t('misc.cancel')}
          onClick={onBack}
        />,
        <Button
          key="next"
          variant="primary"
          text={isConfirmStep ? t('misc.confirm') : t('misc.next')}
          onClick={onNext}
        />,
      ]}
    >
      {!isConfirmStep && (
        <FormField
          label={t('onboardingHub.transferOwnershipModal.emailLabel')}
          visuallyHideLabel
          alertMessage={
            hasEmailError
              ? t('onboardingHub.transferOwnershipModal.invalidEmail')
              : undefined
          }
        >
          <TextInput
            value={emailValue}
            onChange={(event) => {
              setEmailValue(event.target.value);
            }}
          />
        </FormField>
      )}
    </Modal>
  );
};
