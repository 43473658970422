import { ValidatePayableApi } from '@finance-review/apis/payable';
import { type InvoiceRequest } from '@finance-review/models/invoice';
import { useTranslation } from 'common/hooks/useTranslation';
import { type I18nKey } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type MutationState } from 'src/core/api/hooks/useMutation';
import { type MutationQueryState } from 'src/core/api/queryState';

import {
  useInvalidateInvoiceReviewListQuery,
  useRemoveInvoicesCacheEntries,
} from '../useInvoiceReviewListQuery';

type Errors =
  | 'scheduledPaymentsAmountDoesNotMatchInvoiceAmount'
  | 'directDebitForbiddenWithCreditNote'
  | 'amountIsNotPositive'
  | 'creditNoteOverAllocated'
  | 'supplierDetailsIsMissing';

export type Response = ValidatePayableApi.Response<Errors>;
export type QueryState = MutationQueryState<Response, unknown>;
export type Payload = InvoiceRequest.Entity;

export const useMutation = (
  options: {
    onSuccess?: (requestId: InvoiceRequest.EntityId) => void;
    onError?: () => void;
  } = {},
): MutationState<Payload, Response, unknown> => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const removeInvoicesCacheEntries = useRemoveInvoicesCacheEntries();
  const invalidateInvoiceListQuery = useInvalidateInvoiceReviewListQuery();

  return ValidatePayableApi.useMutation({
    onError: options.onError,
    onSuccess: (requestId) => {
      removeInvoicesCacheEntries([requestId]);
      invalidateInvoiceListQuery();
      pushNotif({
        type: NotificationType.Success,
        message: t('notifications.paymentValidation.accept.invoice.success'),
      });
      options.onSuccess?.(requestId);
    },
  });
};

// it would be nice if there was a dedicated endpoint that threw a 4** error
export const hasFailed = (
  validateInvoiceRequestQueryState: QueryState,
): boolean =>
  validateInvoiceRequestQueryState.status === 'success' &&
  validateInvoiceRequestQueryState.data.errors.length > 0;

export const getFailedMessage = (
  validateInvoiceRequestQueryState: QueryState,
): I18nKey => {
  const error =
    validateInvoiceRequestQueryState.status === 'success'
      ? validateInvoiceRequestQueryState.data.errors[0]
      : undefined;

  const errorType = error?.details?.[0];

  switch (errorType) {
    case 'scheduledPaymentsAmountDoesNotMatchInvoiceAmount':
      return 'payable.review.validate.errors.scheduledPaymentsAmountDoesNotMatchInvoiceAmount';
    case 'amountIsNotPositive':
      return 'payable.review.validate.errors.amountIsNotPositive';
    case 'creditNoteOverAllocated':
      return 'payable.review.validate.errors.creditNoteOverAllocated';
    case 'supplierDetailsIsMissing':
      return 'payable.review.validate.errors.supplierDetailsIsMissing';
    case 'directDebitForbiddenWithCreditNote':
      return 'payable.review.validate.errors.directDebitWithCreditNote';
    default:
      return 'submitMyInvoice.validate.error';
  }
};
