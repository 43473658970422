import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useResetMFA = (factorId: string): MutationState => {
  return useMutation({
    request: {
      type: 'rest',
      method: 'post',
      target: 'baseAPI',
      endpoint: `/factors/${factorId}/reset`,
    },
  });
};
