import { Button, Link, Modal } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { getAccountingIntegrationFullName } from '../accountingIntegration';

import './AccountingBaseSwitchFeedbackModal.css';

export type Props = {
  accountingIntegration: AccountingSoftware;
  isOpen: boolean;
  onClose: () => void;
};

export function AccountingBaseSwitchSuccessModal({
  accountingIntegration,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation('global');
  const history = useHistory();
  const companyId = useCompanyId();

  const isNativeIntegration = !hasIntegrationFileBasedExport(
    accountingIntegration,
  );
  const subtitleKey = isNativeIntegration
    ? 'bookkeep.integrations.settings.baseSwitch.successContentNative'
    : 'bookkeep.integrations.settings.baseSwitch.successContentFileBased';

  return (
    <Modal
      isOpen={isOpen}
      title={t('bookkeep.integrations.settings.baseSwitch.successTitle')}
      iconName="success"
      iconVariant="success"
      onClose={onClose}
      actions={
        isNativeIntegration
          ? null
          : [
              <Button
                key="confirm"
                text={t(
                  'bookkeep.integrations.settings.baseSwitch.successAction',
                )}
                variant="primary"
                onClick={() => {
                  history.push(
                    routeFor(routes.COMPANY_EXPORTS.path, {
                      company: companyId,
                    }),
                  );
                  onClose();
                }}
              />,
            ]
      }
    >
      <p
        className="AccountingBaseSwitchFeedbackModal__subtitle"
        data-testid="accounting-base-switch-feedback-modal-subtitle"
      >
        <Trans
          i18nKey={subtitleKey}
          values={{
            integrationName: getAccountingIntegrationFullName(
              accountingIntegration,
              t,
            ),
          }}
        >
          text
          <Link
            isExternal
            href="https://helpcenter.spendesk.com/collections/2383252-integrations-with-accounting-software"
            rel="noreferrer"
          >
            link
          </Link>
          text
        </Trans>
      </p>
    </Modal>
  );
}
