import { LOGOUT } from 'src/auth/constants/api';
import { authAPI } from 'src/core/api/axios';

// eslint-disable-next-line unicorn/no-anonymous-default-export
export default async () => {
  const response = await authAPI.post(LOGOUT);

  if (response?.data) {
    window.location.href = response.data;
    // To stop any future redirection that would override this one, we throw
    throw new Error('SAML logout redirection occured.');
  }

  // eslint-disable-next-line no-restricted-globals
  location.reload();
};
