/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormField, Select, type SelectProps } from '@dev-spendesk/grapes';
import React from 'react';

import type { BasicInputProps, Form } from './types';
import { useErrorMessage } from './utils/useErrorMessage';

type Props<TForm extends Form<any>> = BasicInputProps<TForm, string> &
  Omit<SelectProps<any>, 'value' | 'onSelect'>;

export function FormSelect<TForm extends Form<any>>({
  name,
  form,
  label,
  ...props
}: Props<TForm extends Form<any> ? TForm : never>) {
  const error = useErrorMessage({
    form,
    key: name,
  });
  return (
    <FormField label={label} alertMessage={error}>
      <Select
        name={name}
        fit="parent"
        {...props}
        value={props.options.find((option) => option.key === form.values[name])}
        onSelect={(selectedOption) => {
          form.setFieldValue(name, selectedOption.key);
        }}
      />
    </FormField>
  );
}
