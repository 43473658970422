import { useFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { EditControlRuleDetails } from '../../components/EditControlRuleDetails';
import { type ControlRule, type ControlRuleRequest } from '../../controlRule';
import * as controlRuleActions from '../../redux/thunks';
import { setErrors, validateForm } from '../../validator';

type Props = {
  controlRule: ControlRule;
  updateControlRule(controlRule: Partial<ControlRuleRequest>): void;
  onClose(): void;
  onNext(): void;
};

const Container = ({
  controlRule,
  updateControlRule,
  onClose,
  onNext,
}: Props) => {
  const { t } = useTranslation();
  const initialValues = {
    name: controlRule.name,
    limit: controlRule.incompletePaymentsLimit,
    delay: controlRule.completionDeadline,
    isDefault: controlRule.isInitial ? controlRule.isDefault : false,
  };
  const formikProps = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => validateForm({ values }, t),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      try {
        await updateControlRule({
          name: values.name,
          completionDeadline: values.delay,
          incompletePaymentsLimit: values.limit,
          isDefault: values.isDefault,
        });
        onNext();
      } catch (error) {
        setErrors(setFieldError, error.response?.data, t);
        throw error;
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <EditControlRuleDetails
      {...formikProps}
      isInitialRule={controlRule.isInitial}
      onCancel={onClose}
    />
  );
};

const mapDispatchToProps = {
  updateControlRule: controlRuleActions.updateControlRule,
};

export const EditControlRuleDetailsContainer = connect(
  null,
  mapDispatchToProps,
)(Container);
