import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import {
  type NewIntegrationVatAccount,
  type IntegrationVatAccount,
} from 'modules/bookkeep/settings/accounting/taxAccount';
import { type TaxAccountAddSuccess } from 'modules/bookkeep/settings/integrations/hooks/useSetTaxAccountMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { renderVatAddEditForm } from './renderAddEditForm';
import { CodeInUseCallout } from '../../../../components/CodeInUseCallout/CodeInUseCallout';
import { getVatAddEditDescription } from '../../translations';
import { type ModalState } from '../ModalState';
import styles from '../TaxAccountLocalOnlySection.module.css';

interface Props {
  onAdd: (
    taxAccount: NewIntegrationVatAccount,
  ) => Promise<TaxAccountAddSuccess>;
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
  integration: AccountingSoftware;
  duplicatedError: boolean;
  codeRateDuplicatedCurrentAccount?: IntegrationVatAccount | false;
  clearErrors: () => void;
}

export const VatAddModal = ({
  onAdd,
  formState,
  setFormState,
  integration,
  duplicatedError,
  codeRateDuplicatedCurrentAccount = false,
  clearErrors,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  return (
    <Modal
      title={t('bookkeep.integrations.settings.vatAccountsTable.addVatAccount')}
      isOpen={formState.kind === 'addVat'}
      iconName="plus"
      iconVariant="primary"
      actions={[
        <Button
          key="cancel"
          onClick={() => {
            setFormState({ kind: 'closed' });
            clearErrors();
          }}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="confirmSection"
          onClick={async () => {
            if (formState.kind === 'addVat') {
              setFormState({ ...formState, inputChanged: false });

              if (formState.error) {
                return;
              }

              try {
                const result = await onAdd({
                  name: formState.name,
                  code: formState.code,
                  rate: formState.rate,
                  type: 'vat',
                });

                if (result.outcome === 'set') {
                  setFormState({ kind: 'closed' });
                }
              } catch (e) {
                // There is nothing that we need to do with this error.
                // Previously it worked without try catch even, now it throws errors. Not sure what changed.
                // eslint-disable-next-line no-console
                console.log(e);
              }
            }
          }}
          text={t(
            'bookkeep.integrations.settings.vatAccountsTable.confirmSelection',
          )}
          variant="primary"
          isDisabled={
            formState.kind === 'addVat' &&
            (!formState.name ||
              !formState.rate ||
              !formState.code ||
              (duplicatedError && !formState.inputChanged))
          }
        />,
      ]}
    >
      <>
        <p className={styles.subtitle}>
          {getVatAddEditDescription(t, integration)}
        </p>

        {renderVatAddEditForm(integration, formState, setFormState)}

        {codeRateDuplicatedCurrentAccount && (
          <CodeInUseCallout
            accountInfo={`${codeRateDuplicatedCurrentAccount.name}, ${codeRateDuplicatedCurrentAccount.code} - ${codeRateDuplicatedCurrentAccount.rate}`}
            linkTo={routeFor(routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path, {
              company: companyId,
            })}
          />
        )}
      </>
    </Modal>
  );
};
