import React from 'react';

import ExportsContainer from '../ExportsContainer';

export const LegacyExportsPage = () => {
  return (
    <div className="page__container">
      <div className="relative flex grow justify-center bg-page-background p-xl pt-3xl">
        <ExportsContainer />
      </div>
    </div>
  );
};
