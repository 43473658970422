import { add, lessThan, subtract, type MonetaryValue } from 'ezmoney';

import {
  getPurchaseOrderAvailableAmount,
  type PurchaseOrder,
} from './purchaseOrder';

export type OverbillForecast = {
  amount: MonetaryValue;
  availableAmount: MonetaryValue;
  billedAmount: MonetaryValue;
  newBilledAmount: MonetaryValue;
} & (
  | { status: 'billed' }
  | {
      status: 'overbilled';
      overbilledAmount: MonetaryValue;
    }
);

export const getPurchaseOrderOverbillForecast = (
  purchaseOrder: PurchaseOrder,
  newInvoiceAmount: MonetaryValue,
  oldInvoiceAmount: MonetaryValue,
): OverbillForecast => {
  const { amount, billedAmount } = purchaseOrder;
  const availableAmount = add(
    getPurchaseOrderAvailableAmount(purchaseOrder),
    oldInvoiceAmount,
  );
  const isOverbilled = lessThan(availableAmount, newInvoiceAmount);
  const newBilledAmount = add(
    subtract(billedAmount, oldInvoiceAmount),
    newInvoiceAmount,
  );

  return {
    amount,
    billedAmount,
    availableAmount,
    newBilledAmount,
    ...(isOverbilled
      ? {
          status: 'overbilled',
          overbilledAmount: subtract(newBilledAmount, amount),
        }
      : { status: 'billed' }),
  };
};
