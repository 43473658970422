import { eachYearOfInterval, getMonth } from 'date-fns';

import { type TGlobalFunctionTyped } from "src/core/common/hooks/useTranslation";

export const getBudgetaryExerciseYears = (dateRange: {
  from: Date;
  to: Date;
}): string[] => {
  return eachYearOfInterval({ start: dateRange.from, end: dateRange.to }).map(
    (date) => `${date.getFullYear()}`,
  );
};

export const getTranslatedMonthOptions = (
  monthIndex: number,
  t: TGlobalFunctionTyped,
) => {
  const translatedMonthOptions: { key: string; label: string }[] = [
    { key: '0', label: t('misc.months.jan') },
    { key: '1', label: t('misc.months.feb') },
    { key: '2', label: t('misc.months.mar') },
    { key: '3', label: t('misc.months.apr') },
    { key: '4', label: t('misc.months.may') },
    { key: '5', label: t('misc.months.jun') },
    { key: '6', label: t('misc.months.jul') },
    { key: '7', label: t('misc.months.aug') },
    { key: '8', label: t('misc.months.sep') },
    { key: '9', label: t('misc.months.oct') },
    { key: '10', label: t('misc.months.nov') },
    { key: '11', label: t('misc.months.dec') },
  ];
  return translatedMonthOptions[monthIndex];
};

export const convertToDate = (monthIndex: number, yearString: string): Date => {
  const yearNumber = Number.parseInt(yearString);
  return new Date(yearNumber, monthIndex);
};

export const getMonthIndex = (date: Date) => {
  return getMonth(date);
};
