import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';

import {
  type Selection,
  type BucketId,
  type GroupId,
  type PayableId,
  type SelectionBucket,
  type SelectionGroup,
} from './selectionUpdater';

type Operation = 'include' | 'exclude';
type Refinement = {
  type: 'connection' | 'node';
  operation: Operation;
  ids: string[];
  refinements?: Refinement[];
};

type BatchIncludedUpdate = {
  type: 'batch';
  id: string;
};
type SingleIncludedUpdate = {
  type: 'single';
  id: string;
  version: number;
};
type IncludedUpdate = BatchIncludedUpdate | SingleIncludedUpdate;

export type ConnectionId = string;
export type ConnectionSelector = {
  connectionId: ConnectionId;
  refinements: Refinement[];
};

export type PayableConnectionSelector = {
  connectionId: ConnectionId;
  refinements: Refinement[];
  includedUpdates: IncludedUpdate[];
};

type BucketPair = [BucketId, SelectionBucket];
type GroupPair = [GroupId, SelectionGroup];
type PayablePair = [PayableId, boolean];

const toGroupRefinement = (
  groupId: GroupId,
  group: SelectionGroup,
  operationOnBucket: Operation,
  expenseIds: string[],
): Refinement => {
  let operationOnGroup: Operation = group.shouldAutoSelectChildren
    ? 'include'
    : 'exclude';

  if (groupId === 'COMMON') {
    operationOnGroup = group.shouldAutoSelectChildren ? 'exclude' : 'include';
  } else if (operationOnBucket === operationOnGroup) {
    operationOnGroup = 'exclude';
  } else if (operationOnBucket !== operationOnGroup) {
    operationOnGroup = 'include';
  }

  return {
    type: 'node',
    operation: operationOnGroup,
    ids: expenseIds,
  };
};

const toGroupRefinements = (
  groupId: GroupId,
  group: SelectionGroup,
  operationOnBucket: Operation,
): Refinement[] => {
  const expenses = group.payables
    ? (Object.entries(group.payables) as PayablePair[])
    : [];
  const [expensesIncluded, expensesExcluded] = partition(
    expenses,
    ([, isSelected]) => isSelected,
  );

  const refinements: Refinement[] = [];

  if (!isEmpty(expensesIncluded)) {
    refinements.push(
      toGroupRefinement(
        groupId,
        group,
        operationOnBucket,
        expensesIncluded.map(([expenseId]) => expenseId),
      ),
    );
  }

  if (!isEmpty(expensesExcluded)) {
    refinements.push(
      toGroupRefinement(
        groupId,
        group,
        operationOnBucket,
        expensesExcluded.map(([expenseId]) => expenseId),
      ),
    );
  }

  return refinements;
};

const toBucketRefinements = (
  bucketId: BucketId,
  bucket: SelectionBucket,
  getPayablesGroupConnectionId: (
    bucketId: BucketId,
    groupId: GroupId,
  ) => string | undefined,
): Refinement[] => {
  const groups = bucket.groups
    ? (Object.entries(bucket.groups) as GroupPair[])
    : [];
  const firstGroup: GroupPair | undefined = groups[0];
  const operation = bucket.shouldAutoSelectChildren ? 'exclude' : 'include';

  if (firstGroup) {
    const [groupId, group] = firstGroup;

    if (groupId === 'COMMON') {
      return toGroupRefinements(groupId, group, operation);
    }
  }

  return groups.map(([groupId, group]) => {
    const id = getPayablesGroupConnectionId(bucketId, groupId);
    return {
      type: 'connection',
      operation,
      ids: id ? [id] : [],
      refinements: toGroupRefinements(groupId, group, operation),
    };
  });
};

type ToAPIPayableConnectionSelectorsInput = {
  selection: Selection;
  resolvers: {
    getPayablesBucketConnectionId: (bucketId: BucketId) => ConnectionId;
    getPayablesGroupConnectionId: (
      bucketId: BucketId,
      groupId: GroupId,
    ) => ConnectionId | undefined;
  };
  cachedSinglePayablesStatus: { id: string; version: number }[];
  bulkProcessesIds: string[];
};

export const toAPIPayableConnectionSelectors = ({
  selection,
  resolvers: { getPayablesBucketConnectionId, getPayablesGroupConnectionId },
  cachedSinglePayablesStatus,
  bulkProcessesIds,
}: ToAPIPayableConnectionSelectorsInput): PayableConnectionSelector[] => {
  const bucketPairs = selection.buckets
    ? (Object.entries(selection.buckets) as BucketPair[])
    : [];
  return bucketPairs.map(([bucketId, bucket]) => {
    return {
      connectionId: getPayablesBucketConnectionId(bucketId),
      refinements: toBucketRefinements(
        bucketId,
        bucket,
        getPayablesGroupConnectionId,
      ),
      includedUpdates: [
        ...bulkProcessesIds.map<BatchIncludedUpdate>((processId) => ({
          id: processId,
          type: 'batch',
        })),
        ...cachedSinglePayablesStatus.map<SingleIncludedUpdate>((payable) => ({
          id: payable.id,
          version: payable.version,
          type: 'single',
        })),
      ],
    };
  });
};
