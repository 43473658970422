import {
  format as formatMoney,
  setPrecision,
  negate,
  lessThan,
  toNumber,
  type MonetaryValue,
  type SignDisplay,
  type MonetaryValueFormatOptions,
} from 'ezmoney';
import PropTypes from 'prop-types';

import i18n from "src/core/config/i18n";

type FormatMonetaryValueOptions = {
  usePlusSignForNegative: boolean;
  precision: number;
} & MonetaryValueFormatOptions;

export const formatMonetaryValue = (
  monetaryValue: MonetaryValue,
  options: Partial<FormatMonetaryValueOptions> = {},
): string => {
  const { precision = 2, usePlusSignForNegative = false } = options;

  const unifiedMonetaryValue = setPrecision(monetaryValue, precision);

  const zero = {
    amount: 0,
    currency: monetaryValue.currency,
    precision: monetaryValue.precision,
  };

  // if it's a negative amount and we want to display the '+' sign
  if (usePlusSignForNegative && lessThan(unifiedMonetaryValue, zero)) {
    // negate the monetary value (show positive value) and force the display of the sign
    return formatMoney(negate(unifiedMonetaryValue), i18n.language, {
      signDisplay: 'always' as SignDisplay,
    });
  }

  return formatMoney(unifiedMonetaryValue, i18n.language, options);
};

export const monetaryValuePropTypes = PropTypes.exact({
  amount: PropTypes.number.isRequired,
  precision: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
});

export const getLocalizedCurrencyName = (
  amount: MonetaryValue,
  locale: string,
): string | undefined => {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: amount.currency,
    currencyDisplay: 'name',
  });
  const formattedParts = numberFormat.formatToParts(toNumber(amount));
  const currencyNamePart = formattedParts.find(
    (part) => part.type === 'currency',
  );

  return currencyNamePart && currencyNamePart.value;
};
