import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import { getAccountingIntegrationStatusQueryKey } from 'src/core/modules/accounting-integration/apis';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { type JournalMappingType } from '../../accounting';

export type JournalMappingCreate = {
  type: JournalMappingType;
  journalId: string;
};

export const useCreateJournalMappingMutation =
  (): MutationState<JournalMappingCreate> => {
    const companyId = useCompanyId();

    return useMutation<JournalMappingCreate>({
      request: {
        type: 'rest',
        method: 'post',
        target: 'companyAPI',
        endpoint: '/accounting-integration/journals',
      },
      options: {
        throwOnError: true,
        // Optimistically update the cache value on mutate
        onMutate: async ({ client }): Promise<void> => {
          await client.cancelQueries('getJournals');
        },
        onSuccess({ client }): void {
          client.resetQueries(['getJournals']);
          client.invalidateQueries(
            getAccountingIntegrationStatusQueryKey(companyId),
          );
        },
      },
    });
  };
