import { type Location } from './perDiemLocation';

export interface AllowanceLocation {
  country: string;
  citiesWithAdjustedRates: ReadonlyArray<string>;
}

export const isCountryWithAdjustedRates = (
  location: Location,
  allowanceLocations: readonly AllowanceLocation[],
) => {
  const allowanceLocation = allowanceLocations.find((al) => {
    return al.country === location.country;
  });
  if (allowanceLocation) {
    return allowanceLocation.citiesWithAdjustedRates.length > 0;
  }
  return false;
};

export const addDefaultCity = (
  allowanceLocation: AllowanceLocation,
): AllowanceLocation => ({
  country: allowanceLocation.country,
  citiesWithAdjustedRates:
    allowanceLocation.citiesWithAdjustedRates.concat('default'),
});
