import {
  Button,
  Callout,
  type CalloutVariant,
  IconButton,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { CenteredLayout } from 'common/components/layout';
import { useFeature } from 'common/hooks/useFeature';
import { useModal } from 'common/hooks/useModalGrapes';
import { useTranslation } from 'common/hooks/useTranslation';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';
import { useCostCentersQuery } from 'modules/budgets/apis';
import type { CostCenter } from 'modules/budgets/models/costCenter';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import FEATURES from 'src/core/constants/features';

import { ExpenseCategoryTable } from './ExpenseCategoryTable';
import { useExpenseCategoriesQuery } from './hooks/useExpenseCategoriesQuery';
import { formatList } from '../../../../../common/utils/intl';
import { type CustomField } from '../../../../custom-fields/models/customField';
import { listCostCentersWithoutExpenseCategories } from '../../components/EcFormModal/listCostCentersWithoutExpenseCategories';
import { type ExpenseCategory } from '../../expenseCategory';
import { useGetDataForExpenseCategoryExpenseAccountRules } from '../../hooks/useGetDataForExpenseCategoryExpenseAccountRules';
import { useIsExpenseCategoryExpenseAccountRuleEnabled } from '../../hooks/useIsExpenseCategoryExpenseAccountRuleEnabled';
import { EcDeletionModalContainer } from '../EcDeletionModalContainer';
import {
  EcCreationFormModalContainer,
  EcEditFormModalContainer,
} from '../EcFormModalContainer';
import { ExpenseCategoriesEditSettingsContainer } from '../ExpenseCategoriesEditSettingsContainer';

import './EcContainer.css';

type Props = {
  expenseCategoryCustomField?: CustomField;
};

export const EcContainer = ({ expenseCategoryCustomField }: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();

  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const isExpenseCategoryExpenseAccountRuleActivated =
    useIsExpenseCategoryExpenseAccountRuleEnabled();

  const queryStates = useQueryStates({
    states: {
      expenseCategories: useExpenseCategoriesQuery(),
      costCenters: useCostCentersQuery(),
      dataForExpenseCategoryExpenseAccountRules:
        useGetDataForExpenseCategoryExpenseAccountRules(),
    },
    reshapeData: ({
      expenseCategories,
      costCenters,
      dataForExpenseCategoryExpenseAccountRules,
    }) => ({
      expenseCategories,
      costCenters,
      ...dataForExpenseCategoryExpenseAccountRules,
    }),
  });

  const renderExpenseCategoriesCallout = (
    expenseCategories: ExpenseCategory[],
    costCenters: CostCenter[],
  ) => {
    const standaloneCostCenters = listCostCentersWithoutExpenseCategories(
      costCenters,
      expenseCategories,
    );

    if (expenseCategories.length === 0 || standaloneCostCenters.length === 0) {
      return null;
    }

    const isExpenseCategoryOptional = !expenseCategoryCustomField?.isRequired;

    return renderExpenseCategoriesCalloutContent(standaloneCostCenters, {
      title: t('expenseCategories.standaloneCostCenters.title'),
      description: isExpenseCategoryOptional
        ? t('expenseCategories.standaloneCostCenters.description.optionalEcs')
        : t('expenseCategories.standaloneCostCenters.description.mandatoryEcs'),
      variant: 'info',
    });
  };

  const renderExpenseCategoriesCalloutContent = (
    standaloneCostCenters: CostCenter[],
    content: { title: string; variant: CalloutVariant; description: string },
  ) => {
    const { title, variant, description } = content;

    return (
      <Callout className="mx-s mb-s" title={title} variant={variant}>
        <div>
          <Trans i18nKey={description} />
          <div className="mt-s">
            {standaloneCostCenters.map(({ name }) => name).join(', ')}
          </div>
        </div>
      </Callout>
    );
  };

  const [createExpenseCategoryModal, showCreateExpenseCategoryModal] =
    useModal<{ expenseCategory: ExpenseCategory }>(({ isOpen, onClose }) => (
      <EcCreationFormModalContainer
        isOpen={isOpen}
        onComplete={onClose}
        onCancel={onClose}
      />
    ));

  const [editExpenseCategoryModal, showEditExpenseCategoryModal] = useModal<{
    expenseCategory: ExpenseCategory;
  }>(
    ({ isOpen, onClose, expenseCategory }) =>
      expenseCategory && (
        <EcEditFormModalContainer
          expenseCategory={expenseCategory}
          isOpen={isOpen}
          onComplete={onClose}
          onCancel={onClose}
        />
      ),
  );

  const [deletionModal, showDeletionModal] = useModal<{
    expenseCategory: ExpenseCategory;
  }>(({ isOpen, onClose, expenseCategory }) => {
    if (expenseCategory === undefined) {
      return <div />;
    }
    return (
      <EcDeletionModalContainer
        expenseCategory={expenseCategory}
        isOpen={isOpen}
        onComplete={onClose}
        onClose={onClose}
      />
    );
  });

  const [
    expenseCategoriesEditSettingsModal,
    showExpenseCategoriesEditSettingsModal,
  ] = useModal(({ isOpen, onClose }) => {
    if (expenseCategoryCustomField === undefined) {
      return <></>;
    }

    return (
      <ExpenseCategoriesEditSettingsContainer
        isOpen={isOpen}
        expenseCategoryCustomField={expenseCategoryCustomField}
        onClose={onClose}
        onComplete={onClose}
      />
    );
  });

  return (
    <QuerySuspense
      queryState={queryStates}
      fallback={() => (
        <CenteredLayout className="text-center" verticalAlign="middle">
          {t('misc.errors.networkError')}
        </CenteredLayout>
      )}
      loading={
        <SkeletonTable
          columns={[
            {
              cell: <SkeletonText width="80%" />,
              header: <SkeletonText width="100%" />,
              width: '33%',
            },
            {
              cell: <SkeletonText width="80%" />,
              header: <SkeletonText width="100%" />,
              width: '34%',
            },
            {
              cell: <SkeletonText width="80%" />,
              header: <SkeletonText width="100%" />,
              width: '33%',
            },
          ]}
          withHeader
          numberOfRows={5}
          rowHeight="compact"
        />
      }
    >
      {({
        costCenters,
        expenseCategories,
        expenseAccounts,
        expenseCategoryExpenseAccountRules,
      }) => {
        const tags: string[] = [];

        if (
          expenseCategoryCustomField?.type === 'list' &&
          isAnalyticalSplitActivated
        ) {
          tags.push(
            expenseCategoryCustomField.isSplittable
              ? t('customFields.settings.splittable')
              : t('customFields.settings.nonSplittable'),
          );
        }

        if (!expenseCategoryCustomField?.isRequired) {
          tags.push(t('misc.optional').toLocaleLowerCase());
        }

        const formattedTags = formatList(activeLanguage, tags, t);

        return (
          <div className="EcContainer">
            <div className="EcContainer__expenseCategory">
              <div className="flex items-end">
                <div className="flex w-full items-center justify-between gap-xs p-s">
                  <div className="flex flex-1 flex-col content-stretch gap-xxs">
                    <div className="title-l">
                      {t('expenseCategories.setup.title')}{' '}
                      {isAnalyticalSplitActivated ? (
                        <span className="text-neutral-dark body-l">
                          ({formattedTags})
                        </span>
                      ) : null}
                    </div>
                    <div className="text-neutral-dark body-m">
                      {t('expenseCategories.setup.subtitle')}
                    </div>
                  </div>
                  <Button
                    className="flex-2 whitespace-nowrap"
                    variant="primary"
                    text={t('expenseCategories.addExpenseCategory')}
                    onClick={() => showCreateExpenseCategoryModal()}
                  />
                  {expenseCategoryCustomField && (
                    <IconButton
                      iconName="settings"
                      variant="border"
                      onClick={() => showExpenseCategoriesEditSettingsModal()}
                      aria-label={t('misc.edit')}
                    />
                  )}
                </div>
              </div>
              {renderExpenseCategoriesCallout(expenseCategories, costCenters)}
              <div className="separator" />
              <ExpenseCategoryTable
                costCenters={costCenters}
                expenseAccounts={expenseAccounts}
                expenseCategories={expenseCategories}
                expenseCategoryExpenseAccountRules={
                  expenseCategoryExpenseAccountRules
                }
                isCostCentersFeatureEnabled={isCostCentersFeatureEnabled}
                isExpenseCategoryExpenseAccountRuleActivated={
                  isExpenseCategoryExpenseAccountRuleActivated
                }
                maxHeight={330}
                onDelete={(expenseCategory: ExpenseCategory) =>
                  showDeletionModal({ expenseCategory })
                }
                onEdit={(expenseCategory: ExpenseCategory) =>
                  showEditExpenseCategoryModal({ expenseCategory })
                }
              />
            </div>
            {createExpenseCategoryModal}
            {editExpenseCategoryModal}
            {deletionModal}
            {expenseCategoriesEditSettingsModal}
          </div>
        );
      }}
    </QuerySuspense>
  );
};
