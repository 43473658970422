import { gql } from 'graphql-tag';

import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

const SET_BOOKKEEPING_START_DATE = gql`
  mutation setBookkeepingStartDate(
    $companyId: ID!
    $payablesBookkeepingStartDate: Date
  ) {
    setPayablesBookkeepingStartDate(
      companyId: $companyId
      payablesBookkeepingStartDate: $payablesBookkeepingStartDate
    ) {
      ... on SetPayablesBookkeepingStartDateResultNotSet {
        reason
      }
    }
  }
`;

type Payload = {
  payablesBookkeepingStartDate: Date | undefined;
};

type RawResponse = {
  setPayablesBookkeepingStartDate: {
    reason?: string;
  };
};

export const useSetBookkeepingStartDateMutation =
  (): MutationState<Payload> => {
    return useMutation<Payload, RawResponse>({
      request: {
        type: 'graphQL',
        target: 'v2',
        query: SET_BOOKKEEPING_START_DATE,
      },
      reshapeData: (data) => {
        if (data.setPayablesBookkeepingStartDate.reason) {
          throw new Error(data.setPayablesBookkeepingStartDate.reason);
        }
      },
      options: {
        onSuccess: (data): void => {
          data.client.invalidateQueries('useGetBookkeepingStartDateQuery');
        },
      },
    });
  };
