import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useMigrateToCostCenters = (): MutationState => {
  return useMutation({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/cost-centers/migration',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['cost-centers', 'migration']);
      },
    },
  });
};
