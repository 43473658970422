import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { userToString } from 'modules/bookkeep/settings/accounting/utils';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from './ModalState';
import { useDeleteAccountPayableFromEmployeeMutation } from '../../../../../hooks/useDeleteAccountPayableFromEmployeeMutation';

interface Props {
  integration: AccountingSoftware;
  modalState: ModalState;
  setModalState: Dispatch<SetStateAction<ModalState>>;
}

export const EmployeeAccountDeleteModal = ({
  integration,
  modalState,
  setModalState,
}: Props) => {
  const { t } = useTranslation('global');

  const { pushNotif } = useNotifications();
  const [deleteAccountPayableFromEmployeeMutation] =
    useDeleteAccountPayableFromEmployeeMutation();

  async function handleDeleteAccountPayableFromEmployee({
    memberId,
    accountPayableId,
  }: {
    memberId: string;
    accountPayableId: string;
  }) {
    return deleteAccountPayableFromEmployeeMutation({
      accountPayableId,
      memberId,
    });
  }

  const getSubtitle = () => {
    if (
      hasIntegrationFileBasedExport(integration) ||
      integration === 'Netsuite'
    ) {
      return t('bookkeep.integrations.fileBased.confirmDeleteDescription');
    }

    return t(
      'bookkeep.integrations.settings.employeeAccountsTable.confirmSendBackToPrepareSubtitle',
    );
  };

  return (
    <Modal
      title={
        modalState.kind === 'confirmDelete'
          ? t(
              'bookkeep.integrations.settings.employeeAccountsTable.confirmDeleteAssociationTitle',
              {
                userName: modalState.employeeAccount.user
                  ? userToString(modalState.employeeAccount.user)
                  : t(
                      'bookkeep.integrations.settings.employeeAccountsTable.deletedEmployeeAccount',
                    ),
              },
            )
          : ''
      }
      subtitle={getSubtitle()}
      isOpen={modalState.kind === 'confirmDelete'}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          onClick={() => {
            if (
              modalState.kind === 'confirmDelete' &&
              modalState.employeeAccount.user?.id &&
              modalState.employeeAccount.id
            ) {
              handleDeleteAccountPayableFromEmployee({
                memberId: modalState.employeeAccount.user.id,
                accountPayableId: modalState.employeeAccount.id,
              });
              setModalState({ kind: 'closed' });
              pushNotif({
                type: NotificationType.Success,
                message: t(
                  'bookkeep.integrations.settings.employeeAccountsTable.removeEmployeeAccountSuccess',
                ),
              });
            }
          }}
          text={t(
            'bookkeep.integrations.settings.expenseAccountsTable.confirmDeletion',
          )}
          variant="alert"
        />,
      ]}
    />
  );
};
