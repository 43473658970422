import {
  AutocompleteNoOptions as GrapesAutocompleteNoOptions,
  DropdownItem,
  OptionGroup,
} from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';

import {
  type InputValueEntry,
  type InputValueId,
  isBooleanInputConfig,
  isSearchInputConfig,
  isSelectInputConfig,
  type PreparePayablesInputConfig,
} from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/panelInputConfig';
import { AutocompleteAsync } from 'src/core/common/components/AutocompleteAsync';
import { AutocompleteNoOptions } from 'src/core/common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';

export const createInputComponent = <T extends PreparePayablesInputConfig>(
  inputConfig: T,
  onChange: (newValue: InputValueId | boolean | null) => void,
  t: (key: string) => string,
  options?: {
    label?: string;
    allowNewValue?: boolean;
    allowMultipleValues?: boolean;
    openOnTop?: boolean;
    onCreateNewValue?: (newValue: string) => Promise<string | undefined>;
    error?: string;
    onInputChanged?: (value?: string) => void;
  },
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const inputOptions = {
    label: undefined,
    allowMultipleValues: true,
    allowNewValue: false,
    onCreateNewValue: () => {
      return Promise.resolve('');
    },
    openOnTop: true,
    ...options,
  };

  const placeholder = inputConfig.placeholder || inputConfig.name;

  if (isSelectInputConfig(inputConfig)) {
    const props = {
      placeholder,
      value: inputConfig.selectedValue
        ? {
            key: inputConfig.selectedValue,
            label:
              inputConfig.values.find(
                (v) => v.key === inputConfig.selectedValue,
              )?.label || '',
          }
        : undefined,
      options: inputConfig.values,
      fit: 'parent' as const,
      isDisabled: inputConfig.isReadOnly,
      isInvalid: options && !!options.error,
      onSelect: (selectedKey: { key: string; label: string } | undefined) => {
        if (selectedKey) {
          onChange(selectedKey.key);
        }
      },
      // TODO: remove code once Grapes autocomplete is fixed
      // and we can select undefined
      onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
          onChange(null);
        }
      },
      renderOption: (
        option: InputValueEntry,
        state: { isSelected: boolean; isHighlighted: boolean },
      ) => {
        const isDeleted = Boolean(option.archiveDate);
        return (
          <DropdownItem
            key={option.key}
            label={option.label}
            isSelected={state.isSelected && !isDeleted}
            isHighlighted={state.isHighlighted}
            isDisabled={isDeleted}
            suffix={isDeleted ? t('misc.deleted') : undefined}
          />
        );
      },
      ...inputConfig,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'data-testid': '',
    };

    return inputOptions.allowNewValue ? (
      <div data-testid={inputConfig['data-testid']}>
        <AutocompleteSearch
          {...props}
          onAddOption={async (newOptionLabel: string) => {
            const key = await inputOptions.onCreateNewValue(newOptionLabel);
            return { key: key ?? '', label: newOptionLabel };
          }}
          onInputChanged={options && options.onInputChanged}
          renderAddOption={(rawValue) => (
            <DropdownItem
              label={
                <Trans
                  i18nKey="misc.addAutocompleteOption"
                  values={{ option: rawValue }}
                  components={[
                    <span key="create" className="text-complementary" />,
                  ]}
                />
              }
            />
          )}
          renderNoOptions={(rawValue) => (
            <AutocompleteNoOptions value={rawValue} />
          )}
        />
      </div>
    ) : (
      <div data-testid={inputConfig['data-testid']}>
        <AutocompleteSearch
          {...props}
          renderNoOptions={(rawValue) => (
            <AutocompleteNoOptions value={rawValue} />
          )}
        />
      </div>
    );
  }

  if (isSearchInputConfig(inputConfig)) {
    const props = {
      placeholder,
      value: inputConfig.selectedValue
        ? {
            key: inputConfig.selectedValue,
            label: '',
          }
        : undefined,
      fit: 'parent' as const,
      isDisabled: inputConfig.isReadOnly,
      isInvalid: options && !!options.error,
      handleSelect: (
        selectedOption: { key: string; label: string } | undefined,
      ) => {
        if (selectedOption) {
          onChange(selectedOption.key);
        }
      },
      handleSearch: async (value: string | undefined) =>
        inputConfig.onSearchValues(value || ''),
      onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
          onChange(null);
        }
      },
      loadValues: async (ids: string[]) => {
        // The deleted selected value must be seen in the input, but the deleted values must not show up in the select
        const values = await inputConfig.onLoadValues(ids);
        return values.filter(
          (value) =>
            inputConfig.selectedValue === value.key || !value.archiveDate,
        );
      },
      ...inputConfig,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'data-testid': '',
    };

    return (
      <>
        <div data-testid={inputConfig['data-testid']}>
          {inputOptions.allowNewValue ? (
            <AutocompleteAsync
              {...props}
              onAddOption={async (newOptionLabel: string) => {
                const key = await inputOptions.onCreateNewValue(newOptionLabel);
                return { key: key ?? '', label: newOptionLabel };
              }}
              renderAddOption={(rawValue) => (
                <DropdownItem
                  label={
                    <Trans
                      i18nKey="misc.addAutocompleteOption"
                      values={{ option: rawValue }}
                      components={[
                        <span key="create" className="text-complementary" />,
                      ]}
                    />
                  }
                />
              )}
              renderNoOptions={() => {
                return (
                  <GrapesAutocompleteNoOptions>
                    {t('misc.fieldHasTooManyValues')}
                  </GrapesAutocompleteNoOptions>
                );
              }}
            />
          ) : (
            <AutocompleteAsync
              {...props}
              renderNoOptions={(rawValue) => (
                <AutocompleteNoOptions value={rawValue} />
              )}
            />
          )}
        </div>
      </>
    );
  }

  if (isBooleanInputConfig(inputConfig)) {
    const isStringBooleanCustomField = inputConfig.values?.some(
      ({ label }) => typeof label === 'string',
    );

    return isStringBooleanCustomField && inputConfig.values ? (
      <OptionGroup
        data-testid={inputConfig['data-testid']}
        name={inputConfig.id}
        options={[
          {
            value:
              inputConfig.values.find((value) => value.label === 'true')?.key ??
              '',
            label: t('misc.yes'),
          },
          {
            value:
              inputConfig.values.find((value) => value.label === 'false')
                ?.key ?? '',
            label: t('misc.no'),
          },
        ]}
        value={inputConfig.selectedValue || null}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
        }}
      />
    ) : (
      <OptionGroup
        data-testid={inputConfig['data-testid']}
        name={inputConfig.id}
        options={[
          { value: true, label: t('misc.yes') },
          { value: false, label: t('misc.no') },
        ]}
        value={
          typeof inputConfig.selectedValue === 'boolean'
            ? inputConfig.selectedValue === true
            : null
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value === 'true');
        }}
      />
    );
  }
};
