import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { JournalSelectAndInfo } from './JournalSelectAndInfo';
import { type JournalModalState } from './journalModalState';
import { type Journal, type JournalMapping } from '../../../../../accounting';

export const JournalSelectionModal = ({
  modalState,
  setModalState,
  availableJournals,
  initialJournal,
  onCreate,
}: {
  modalState: JournalModalState;
  setModalState: (modalState: JournalModalState) => void;
  availableJournals: Journal[];
  initialJournal: Journal | undefined;
  onCreate: (journalMapping: JournalMapping) => void;
}) => {
  const { t } = useTranslation();
  let isModalVisible = false;

  if (['map', 'mapped'].includes(modalState.modalKind)) {
    isModalVisible = true;
  }

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      actions={[
        <Button
          key="no"
          onClick={() => setModalState({ modalKind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          isDisabled={false}
          text={t('misc.saveChanges')}
          variant="primary"
          onClick={() => {
            if (modalState.modalKind === 'mapped') {
              if (modalState.mappedJournal !== initialJournal) {
                onCreate(modalState);
              } else {
                setModalState({ modalKind: 'closed' });
              }
            }
          }}
        />,
      ]}
      onClose={() => {
        setModalState({ modalKind: 'closed' });
      }}
      iconName="pen"
      title={t(
        'bookkeep.integrations.settings.chift.purchaseJournalExport.journalMapping',
      )}
      iconVariant="primary"
      isOpen={isModalVisible}
    >
      {isModalVisible && (
        <>
          <JournalSelectAndInfo
            modalState={modalState}
            setModalState={setModalState}
            journals={availableJournals}
          />
        </>
      )}
    </Modal>
  );
};
