import { Tag } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type SchedulingProcessEntry } from '../../../../models';

type Props = {
  schedulingProcessEntry: SchedulingProcessEntry;
};

export const StatusTag = ({ schedulingProcessEntry }: Props) => {
  const { t } = useTranslation('global');

  switch (schedulingProcessEntry.status) {
    case 'success':
      return (
        <Tag variant="success">
          {t('invoices.schedulingProcess.status.success')}
        </Tag>
      );

    case 'scheduled':
      return (
        <Tag variant="info">
          {t('invoices.schedulingProcess.status.scheduled')}
        </Tag>
      );

    case 'executing':
      return (
        <Tag variant="info">
          {t('invoices.schedulingProcess.status.executing')}
        </Tag>
      );

    case 'failed':
      return (
        <Tag variant="alert">
          {t('invoices.schedulingProcess.status.failed')}
        </Tag>
      );

    default:
      return (
        <Tag variant="info">
          {t('invoices.schedulingProcess.status.toSchedule')}
        </Tag>
      );
  }
};
