/**
 * Force to set date / time info to UTC (e.g. 2023-01-05 00:00:00 GTM+1 will become 2023-01-05 00:00:00 UTC)
 */
export const toUTCDate = (input: Date): Date => {
  const output = new Date(input);
  output.setUTCDate(input.getDate());
  output.setUTCMonth(input.getMonth());
  output.setUTCFullYear(input.getFullYear());
  output.setUTCHours(input.getHours());
  output.setUTCMinutes(input.getMinutes());
  output.setUTCSeconds(input.getSeconds());
  output.setUTCMilliseconds(input.getMilliseconds());

  return output;
};
