import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type TokenList, tokenListSchema } from '../domain/oauth2-token';

export const useGetOAuth2TokenList = (): QueryState<TokenList> => {
  const { company: companyId } = useParams();
  return useQuery<TokenList, TokenList>({
    key: ['oauth2TokenList', companyId],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/public-api-configuration/oauth2/list`,
    },
    options: {
      staleTime: 60_000,
      cacheTime: 60_000,
      refetchInterval: 60_000,
    },
    reshapeData(rawItems: unknown): TokenList {
      return tokenListSchema.parse(rawItems);
    },
  });
};
