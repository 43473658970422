import { type Payable } from 'src/core/modules/bookkeep/prepare-payables/models';

export function shouldDisplayInvoiceNumber(
  context: {
    integrationHasInvoiceNumberCapability: boolean;
    hasSpanishDPR: boolean;
  },
  payable: Pick<Payable, 'documentaryEvidence'>,
) {
  if (context.hasSpanishDPR) {
    return true;
  }

  // mandatory for integrations with invoice number capability, which
  // is the case for DATEV alone right now
  if (context.integrationHasInvoiceNumberCapability) {
    return true;
  }

  return (
    payable.documentaryEvidence?.type === 'invoice' ||
    payable.documentaryEvidence?.type === 'creditNote'
  );
}
