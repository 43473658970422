import { type TGlobalFunctionTyped } from '../../common/hooks/useTranslation';

export const spendingTypes = [
  'expenseClaim',
  'invoice',
  'subscription',
  'virtualCard',
  'physicalCard',
  'purchaseOrder',
] as const;

export type SpendingType = (typeof spendingTypes)[number];

export const getSpendingType = (
  spendingType: SpendingType,
  translator: TGlobalFunctionTyped,
): string => {
  switch (spendingType) {
    case 'expenseClaim':
      return translator('budget.breakdownSpendings.spendingType.expenseClaim');
    case 'invoice':
      return translator('budget.breakdownSpendings.spendingType.invoice');
    case 'subscription':
      return translator('budget.breakdownSpendings.spendingType.subscription');
    case 'virtualCard':
      return translator('budget.breakdownSpendings.spendingType.virtualCard');
    case 'physicalCard':
      return translator('budget.breakdownSpendings.spendingType.physicalCard');
    case 'purchaseOrder':
      return translator('budget.breakdownSpendings.spendingType.purchaseOrder');
    default:
      throw new Error('No spending type for the spending type key');
  }
};
