import { EmptyState, Link } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routeFor, routes } from 'src/core/constants/routes';

import illustration from './illustration.svg';

export const InvoiceDraftNoResultPromoInbox = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  return (
    <EmptyState
      title={t(
        'pageEmptyState.draftRequestsWithInvoiceForwardPromoInbox.title',
      )}
      subtitle={
        <Trans i18nKey="pageEmptyState.draftRequestsWithInvoiceForwardPromoInbox.subtitle">
          <Link
            as={ReactRouterLink}
            to={{
              pathname: routeFor(routes.INBOX_INVOICES.path, {
                company: companyId,
              }),
            }}
            className="text-complementary"
          >
            -
          </Link>
        </Trans>
      }
      illustration={<img src={illustration} alt="" />}
    />
  );
};
