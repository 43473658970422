import * as types from './actionTypes';
import { type MFAAction } from './actions';
import { type Factor, type Procedure } from './models';

type State = {
  procedure: Procedure;
  factor?: Factor;
  behavior: {
    isRequestingMfaProcedure: boolean;
    isValidatingMfaToken: boolean;
    isActivatingWireTransfer: boolean;
    isRegeneratingProcedure: boolean;
    isFetchingActiveFactor: boolean;
    isActiveFactorFetched: boolean;
  };
  token: {
    isValid: boolean;
    isVerified: boolean;
  };
};

export const initialState: State = {
  procedure: {
    id: '',
    factorId: '',
  },
  factor: undefined,
  behavior: {
    isRequestingMfaProcedure: false,
    isValidatingMfaToken: false,
    isActivatingWireTransfer: false,
    isRegeneratingProcedure: false,
    isFetchingActiveFactor: false,
    isActiveFactorFetched: false,
  },
  token: {
    isValid: false,
    isVerified: false,
  },
};

const mfaActivationReducer = (
  state: State = initialState,
  action: MFAAction,
): State => {
  switch (action.type) {
    // FETCH FACTOR
    case types.FETCH_PHONE_FACTOR_SUCCESS:
      return {
        ...state,
        factor: action.payload,
        behavior: {
          ...state.behavior,
          isFetchingActiveFactor: false,
          isActiveFactorFetched: true,
        },
      };

    case types.FETCH_PHONE_FACTOR_LOADING:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isFetchingActiveFactor: true,
        },
      };

    case types.FETCH_PHONE_FACTOR_ERROR:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isFetchingActiveFactor: false,
          isActiveFactorFetched: false,
        },
      };

    // REQUEST CODE
    case types.REQUEST_MFA_PROCEDURE_SUCCESS:
      return {
        ...state,
        procedure: action.payload,
        behavior: {
          ...state.behavior,
          isRequestingMfaProcedure: false,
        },
      };

    case types.REQUEST_MFA_PROCEDURE_LOADING:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isRequestingMfaProcedure: true,
        },
      };

    case types.REQUEST_MFA_PROCEDURE_ERROR:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isRequestingMfaProcedure: false,
        },
      };

    // VALIDATION
    case types.VALIDATE_MFA_TOKEN_LOADING:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isValidatingMfaToken: true,
        },
        token: {
          isValid: false,
          isVerified: false,
        },
      };

    case types.VALIDATE_MFA_TOKEN_SUCCESS:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isValidatingMfaToken: false,
        },
        token: {
          isValid: true,
          isVerified: true,
        },
      };

    case types.VALIDATE_MFA_TOKEN_ERROR:
      return {
        ...state,
        token: {
          isValid: false,
          isVerified: true,
        },
        behavior: {
          ...state.behavior,
          isValidatingMfaToken: false,
        },
      };

    // TOGGLE WIRETRANSFER FEATURE
    case types.TOGGLE_WIRETRANSFER_ACTIVATION_LOADING:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isActivatingWireTransfer: true,
        },
      };

    case types.TOGGLE_WIRETRANSFER_ACTIVATION_SUCCESS:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isActivatingWireTransfer: false,
        },
      };

    case types.TOGGLE_WIRETRANSFER_ACTIVATION_ERROR:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isActivatingWireTransfer: false,
        },
      };

    case types.RESET_TOKEN_VERIFICATION_STATES:
      return {
        ...state,
        token: {
          isValid: false,
          isVerified: false,
        },
      };

    case types.REGENERATE_PROCEDURE_LOADING:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isRegeneratingProcedure: true,
        },
      };

    case types.REGENERATE_PROCEDURE_ERROR:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isRegeneratingProcedure: false,
        },
      };

    case types.REGENERATE_PROCEDURE_SUCCESS:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isRegeneratingProcedure: false,
        },
        procedure: action.payload,
      };

    default:
      return state;
  }
};

export default mfaActivationReducer;
