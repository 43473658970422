import { SkeletonButton, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const AssignControlRuleLoader = () => (
  <div className="flex flex-col gap-xs">
    <SkeletonText width="80%" size="xl" />
    <SkeletonText size="l" />
    <SkeletonText className="mt-m" width="20%" size="l" />
    <SkeletonText size="xxl" />
    <div className="mt-m flex gap-xs">
      <SkeletonButton width="20%" />
      <SkeletonButton width="25%" />
    </div>
  </div>
);
