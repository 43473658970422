import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { type I18nKey } from 'common/hooks/useTranslation';
import { type QueryError as QueryErrorType } from 'src/core/api/queryError';

import { type CreateSupplierError } from '../../../hooks';

type Props = {
  createSupplierError: QueryErrorType<CreateSupplierError>;
  className?: string;
};

export const CreateSupplierQueryError = ({
  createSupplierError,
  className,
}: Props) => (
  <QueryError
    className={className}
    queryError={createSupplierError}
    componentType="Notification"
    translations={{
      serverError: 'submitMyInvoice.suppliers.createFailure',
      requestError: createSupplierErrorToTranslation,
    }}
  />
);

const createSupplierErrorToTranslation = (
  createSupplierError: CreateSupplierError,
): I18nKey => {
  switch (createSupplierError.reason) {
    case 'invalidBankInfos':
      return createSupplierError.errors?.includes('invalidAccountHolderName')
        ? 'submitMyInvoice.suppliers.invalidAccountHolderName'
        : 'submitMyInvoice.suppliers.invalidBankInfo';
    default:
      return 'submitMyInvoice.suppliers.createFailure';
  }
};
