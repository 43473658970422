import { PayableApiHooks } from '@finance-review/apis/payable';
import type {
  InvoiceRequest,
  InvoiceRequestData,
} from '@finance-review/models/invoice';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import { type MutationQueryState } from 'src/core/api/queryState';

import {
  transformInvoiceRequestToAPIPayload,
  type UpdateInvoiceRequestAPIPayload,
} from './updateInvoiceRequestTransformer';
import type { RawInvoiceData } from '../transformers';
import { transformInvoiceData } from '../transformers';
import { updateInvoiceRequestQueryData } from '../useFetchDraftInvoiceRequest';
import { useUpdateInvoiceCacheEntry } from '../useInvoiceReviewListQuery';

export type UpdateInvoiceRequestRequestError = {
  reason:
    | 'cannotUpdateAlreadyValidatedInvoice'
    | 'wireTransferIsNotAvailable'
    | 'requestNotFound'
    | 'requestIsNotEditable'
    | 'userNotAuthorizedToUpdateThisRequest';
  error?: string;
};

export type UpdateInvoiceRequestQueryState = MutationQueryState<
  InvoiceRequestData,
  UpdateInvoiceRequestRequestError
>;

export const useUpdateInvoiceRequest = (
  invoiceRequestId: InvoiceRequest.EntityId,
): MutationState<
  InvoiceRequest.Entity,
  InvoiceRequestData,
  UpdateInvoiceRequestRequestError
> => {
  const onPayableUpdate = PayableApiHooks.useOnUpdate();
  const onInvoiceUpdate = useUpdateInvoiceCacheEntry();
  const [updateInvoiceMutation, ...rest] = useMutation<
    UpdateInvoiceRequestAPIPayload,
    InvoiceRequestData,
    RawInvoiceData,
    UpdateInvoiceRequestRequestError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/invoice_requests/${invoiceRequestId}`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ rawData, client }): void => {
        updateInvoiceRequestQueryData(client, rawData);
        onInvoiceUpdate(rawData);
      },
    },
    reshapeData: transformInvoiceData,
  });

  return [
    async (invoiceRequest: InvoiceRequest.Entity) => {
      const result = await updateInvoiceMutation(
        transformInvoiceRequestToAPIPayload(invoiceRequest),
      );
      onPayableUpdate(invoiceRequest);
      return result;
    },
    ...rest,
  ];
};
