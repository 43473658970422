import { gql } from 'graphql-tag';

export const UPDATE_SUPPLIER_DETAILS = gql`
  mutation UpdateSupplierDetails(
    $companyId: ID!
    $supplierId: ID!
    $accountHolderName: String
    $bankDetails: SupplierBankDetailsUpdate
    $legalDetails: SupplierLegalDetailsUpdate
  ) {
    updateSupplierDetails(
      companyId: $companyId
      supplierId: $supplierId
      accountHolderName: $accountHolderName
      bankDetails: $bankDetails
      legalDetails: $legalDetails
    ) {
      ... on UpdateSupplierDetailsResultNotUpdated {
        reason
      }
    }
  }
`;
