import { AllocationPeriodField } from 'src/core/modules/budgets/components/AllocationPeriodField';
import { type AllocationPeriod } from 'src/core/modules/budgets/containers/AllocationPeriodContainer/AllocationPeriodContainer';

type Props = {
  value: AllocationPeriod;
  setFieldValue: (key: string, value: AllocationPeriod) => void;
};

export const PayableAllocationPeriodField = ({
  setFieldValue,
  value,
}: Props) => {
  return (
    <AllocationPeriodField
      onSubmitAllocationPeriod={(allocationPeriod) => {
        setFieldValue('allocationPeriod', allocationPeriod);
      }}
      allocationPeriod={value}
    />
  );
};
