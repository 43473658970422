import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type DelegationList } from '../models';

export const useFetchCompanyDelegationsQuery = (options: {
  isEnabled: boolean;
}): QueryState<DelegationList> => {
  return useQuery<DelegationList>({
    key: ['delegations'],
    isEnabled: options.isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/delegations`,
    },
  });
};
