import { useParams } from 'react-router-dom';
import { array } from 'zod';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ApiKeyListItem, apiKeyListItemSchema } from '../domain';

export const useGetApiKeys = (): QueryState<ApiKeyListItem[]> => {
  const { company: companyId } = useParams();
  return useQuery<ApiKeyListItem[], ApiKeyListItem[]>({
    key: ['apiKeysList', companyId],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/public-api-configuration/api-keys`,
    },
    options: {
      staleTime: 1000,
      cacheTime: 1000,
    },
    reshapeData(rawItems: unknown[]): ApiKeyListItem[] {
      return array(apiKeyListItemSchema).parse(rawItems);
    },
  });
};
