import { type ThunkDispatch } from '@reduxjs/toolkit';
import { type AxiosError } from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

import type { AppDispatch } from 'modules/app/redux/store';
import { companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import {
  type SuppliersAction,
  fetchSuppliersLoading,
  fetchSuppliersSuccess,
  fetchSuppliersFailure,
  createSupplierLoading,
  createSupplierSuccess,
  createSupplierFailure,
  updateSupplierLoading,
  updateSupplierSuccess,
  updateSupplierFailure,
} from './actions';
import { type ApiSupplier } from '../_shared/SupplierEditor/supplier';

export const fetchSuppliers =
  (filters = {}) =>
  async (
    dispatch: ThunkDispatch<AppState, void, SuppliersAction>,
    getState: () => AppState,
  ) => {
    dispatch(fetchSuppliersLoading());
    const state = getState();
    const companyId = getCompanyId(state);
    try {
      const qs = queryString.stringify(filters, { arrayFormat: 'index' });
      const { data: suppliers } = await companyAPI.get(
        `/invoice_suppliers?${qs}`,
        { companyId },
      );
      dispatch(fetchSuppliersSuccess(suppliers, !isEmpty(filters)));
    } catch (error) {
      dispatch(fetchSuppliersFailure(error));
    }
  };

type SupplierErrorResponse =
  | {
      reason: 'mimeTypeNotSupported';
      mimeTypeEntry: string;
      allowedMimeTypes: string[];
      supplierId: string;
    }
  | { reason: string }
  | { error: string };
type SupplierErrorOutcome = {
  outcome: 'failure';
  error: string;
  errorDetails:
    | {
        allowedMimeTypes: string[];
        uploadedMimeType: string;
        supplierId: string;
      }
    | undefined;
};
const getSupplierErrorOutcome = (
  error: AxiosError<SupplierErrorResponse>,
): SupplierErrorOutcome => {
  let responseError = get(error, 'response.data.error');
  let errorDetails;
  if (error.response?.status === 400) {
    responseError = get(error, 'response.data.reason');
    if (responseError === 'mimeTypeNotSupported') {
      errorDetails = {
        allowedMimeTypes: get(error, 'response.data.allowedMimeTypes'),
        uploadedMimeType: get(error, 'response.data.mimeTypeEntry'),
        supplierId: get(error, 'response.data.supplierId'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
    }
  }
  return {
    ...get(error, 'response.data'),
    outcome: 'failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: responseError as any,
    errorDetails,
  };
};

export type CreateSupplierResult =
  | { outcome: 'success'; supplier: ApiSupplier }
  | SupplierErrorOutcome;
/* eslint-disable @typescript-eslint/ban-types */
export const createSupplier =
  (payload: {}) =>
  /* eslint-enable @typescript-eslint/ban-types */
  async (
    dispatch: ThunkDispatch<AppState, void, SuppliersAction>,
    getState: () => AppState,
  ): Promise<CreateSupplierResult> => {
    dispatch(createSupplierLoading());
    const state = getState();
    const companyId = getCompanyId(state);
    try {
      const { data: supplier } = await companyAPI.post(
        '/invoice_suppliers',
        payload,
        { companyId },
      );
      dispatch(createSupplierSuccess(supplier));
      return { outcome: 'success', supplier };
    } catch (error) {
      dispatch(createSupplierFailure(error));
      return getSupplierErrorOutcome(error);
    }
  };

export type UpdateSupplierResult =
  | { outcome: 'success'; supplier: ApiSupplier }
  | SupplierErrorOutcome;
export const updateSupplier =
  (supplierId: string, payload = {}) =>
  async (
    dispatch: AppDispatch,
    getState: () => AppState,
  ): Promise<UpdateSupplierResult> => {
    dispatch(updateSupplierLoading());
    const state = getState();
    const companyId = getCompanyId(state);
    try {
      const { data: supplier } = await companyAPI.put(
        `/invoice_suppliers/${supplierId}`,
        payload,
        {
          companyId,
        },
      );
      dispatch(updateSupplierSuccess(supplier));
      return { outcome: 'success', supplier };
    } catch (error) {
      dispatch(updateSupplierFailure(error));
      return getSupplierErrorOutcome(error);
    }
  };
