import * as yup from 'yup';

import * as PayableErrors from './payableErrors';
import * as PayableRequest from './payableRequest';

export const amount = yup.number().required(PayableErrors.requiredError);

export const text = yup.string().required(PayableErrors.requiredError);
export const optionalText = yup.string().optional();

export const date = yup
  .date()
  .required(PayableErrors.requiredError)
  .min(
    PayableRequest.earliestAllowedDate,
    PayableErrors.dateOlderThanMinimumError,
  )
  .max(
    PayableRequest.latestAllowedDate,
    PayableErrors.dateLaterThanMaximumError,
  );
export const optionalDate = yup.date().optional();
