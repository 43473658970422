import {
  Button,
  FormField,
  Modal,
  RadioField,
  RadioGroup,
} from '@dev-spendesk/grapes';
import React, { type Dispatch } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { CostCenterInfo } from './CostCenterInfo';
import { CustomFieldSelectAndInfo } from './CustomFieldSelectAndInfo';
import { type ModalState } from './ModalState';
import { type AccountingSoftware } from '../../../../../../../integration/status';
import { type AnalyticalFieldMappingCreate } from '../../../../../hooks/useCreateAnalyticalFieldMappingMutation';
import { type CustomField } from '../../../../../hooks/useCustomFieldsQuery';
import { type AnalyticalFieldMappingUpdate } from '../../../../../hooks/useUpdateAnalyticalFieldMappingMutation';

interface Props {
  integration: AccountingSoftware;
  customFields: CustomField[];
  hasCostCenterFeature: boolean;
  hasCustomFieldsActive: boolean;
  hasCostCenterActive: boolean;
  modalState: ModalState;
  setModalState: Dispatch<ModalState>;
  onCreate: (
    editedAnalyticalField: AnalyticalFieldMappingCreate & {
      externalName: string;
    },
  ) => void;
  onUpdate: (
    editedAnalyticalField: AnalyticalFieldMappingUpdate & {
      externalName: string;
    },
  ) => void;
}

const noMappingSelectedKey = 'none';
const costCenterSelectedKey = 'costCenter';
const customFieldSelectedKey = 'customField';

export const PullAndMapWithValuesSectionModal = ({
  integration,
  customFields,
  hasCostCenterFeature,
  hasCustomFieldsActive,
  hasCostCenterActive,
  modalState,
  setModalState,
  onCreate,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();

  function getCurrentSelectValue() {
    if (modalState.modalKind === 'map' && modalState?.kind === 'mapped') {
      if (modalState.codeType === 'costCenter') {
        return costCenterSelectedKey;
      }

      if (modalState.codeType === 'customField') {
        return customFieldSelectedKey;
      }
    }

    return noMappingSelectedKey;
  }

  const currentSelectValue = getCurrentSelectValue();

  let isModalVisible = false;
  let isAnalyticalFieldMapped = false;
  let isAnalyticalFieldNotMapped = false;
  let isCostCenterAnalyticalField = false;
  let isCostCenterEnabled = false;
  let isCustomFieldAnalyticalField = false;
  let isCustomFieldEnabled = false;

  if (modalState.modalKind === 'map') {
    isModalVisible = true;
    if (modalState?.kind === 'mapped') {
      isAnalyticalFieldMapped = true;
      if (modalState.codeType === 'costCenter') {
        isCostCenterAnalyticalField = true;
        isCostCenterEnabled = hasCostCenterFeature && hasCostCenterActive;
      }
      if (modalState.codeType === 'customField') {
        isCustomFieldAnalyticalField = true;
        isCustomFieldEnabled = hasCustomFieldsActive;
      }
    } else if (modalState?.kind === 'notMapped') {
      isAnalyticalFieldNotMapped = true;
    }
  }

  function isSaveDisabled(): boolean {
    return (
      isModalVisible &&
      (isAnalyticalFieldNotMapped ||
        (isAnalyticalFieldMapped &&
          ((isCostCenterAnalyticalField && !isCostCenterEnabled) ||
            (isCustomFieldAnalyticalField && !isCustomFieldEnabled))))
    );
  }

  return (
    <Modal
      actions={[
        <Button
          key="no"
          onClick={() => setModalState({ modalKind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          isDisabled={isSaveDisabled()}
          text={t('misc.saveChanges')}
          variant="primary"
          onClick={() => {
            if (modalState.modalKind === 'map' && 'codeType' in modalState) {
              const existingCustomFieldIds = customFields.map(
                (customField) => customField.id,
              );

              if (
                'internalId' in modalState &&
                existingCustomFieldIds.includes(modalState.internalId)
              ) {
                onUpdate(modalState);
              } else {
                onCreate(modalState);
              }
            }
          }}
        />,
      ]}
      onClose={() => {
        setModalState({ modalKind: 'closed' });
      }}
      iconName="pen"
      title={t('bookkeep.integrations.settings.mapping', {
        externalName: modalState.modalKind === 'map' && modalState.externalName,
      })}
      iconVariant="primary"
      isOpen={isModalVisible}
    >
      {isModalVisible && (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.analyticalFieldModalSubtitlePullAndMapWithValues',
            )}
            htmlFor="set-mapping-autocomplete-input"
          >
            <RadioGroup
              name="codeType"
              value={currentSelectValue}
              onChange={(event) => {
                if (modalState.modalKind === 'map') {
                  if (event.target.value === 'costCenter') {
                    return setModalState({
                      companyId: modalState.companyId,
                      externalId: modalState.externalId,
                      externalName: modalState.externalName,
                      isArchived: modalState.isArchived,
                      modalKind: modalState.modalKind,
                      kind: 'mapped',
                      codeType: 'costCenter',
                    });
                  }

                  setModalState({
                    ...modalState,
                    kind: 'mapped',
                    internalName: '',
                    internalId: '',
                    codeType: 'customField',
                  });
                }
              }}
            >
              <RadioField
                value={costCenterSelectedKey}
                label={t(
                  'bookkeep.integrations.settings.analyticalFieldsMappingsTable.costCenters',
                )}
              />
              <RadioField
                value={customFieldSelectedKey}
                label={t(
                  'bookkeep.integrations.settings.analyticalFieldsMappingsTable.analyticalFields',
                )}
              />
            </RadioGroup>
          </FormField>

          <CustomFieldSelectAndInfo
            modalState={modalState}
            setModalState={setModalState}
            hasCustomFieldsActive={hasCustomFieldsActive}
            integration={integration}
            customFields={customFields}
          />
          <CostCenterInfo
            hasCostCenterFeature={hasCostCenterFeature}
            hasCostCenterActive={hasCostCenterActive}
            integration={integration}
            modalState={modalState}
          />
        </>
      )}
    </Modal>
  );
};
