import { InfoTip } from '@dev-spendesk/grapes';
import isNil from 'lodash/isNil';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import appConfig from 'src/core/config';
import { formatCardExpirationDate } from 'src/core/modules/cards/models/formatCardExpirationDate';
import { formatMoney } from 'src/core/utils/money';

import { PlasticCardActions } from './PlasticCardActions';
import { type ApiCard } from '../../../card';
import { CardDesign } from '../../../components/CardDesign';
import { SFSCardDesign } from '../../../components/SFSCardDesign/SFSCardDesign';
import { SFSWhiteCardDesign } from '../../../components/SFSCardDesign/SFSWhiteCardDesign';
import { type CardsAccess } from '../../../hooks/useCardAccessQuery';
import { getVisibleNumbers } from '../../../utils/card';

type Props = {
  card: ApiCard;
  cardsAccess: CardsAccess | undefined;
  syncActivity: () => void;
};

export const PlasticCardHeader = ({
  card,
  cardsAccess,
  syncActivity,
}: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();

  return (
    <div className="box my-s flex items-center justify-between p-xxl">
      <div>
        {card.banking_provider === 'sfs' ? (
          <>
            {company.currency === 'GBP' && card.design_type === 'FAST_CARD' ? (
              <SFSWhiteCardDesign
                width={300}
                name={card.card_name}
                numbers={getVisibleNumbers(
                  card.card_visible_number,
                  card.status,
                )}
                expDate={formatCardExpirationDate(card.card_exp_date)}
                state={card.activation_status}
              />
            ) : (
              <SFSCardDesign
                width={300}
                name={card.card_name}
                numbers={getVisibleNumbers(
                  card.card_visible_number,
                  card.status,
                )}
                expDate={formatCardExpirationDate(card.card_exp_date)}
                state={card.activation_status}
              />
            )}
          </>
        ) : (
          <CardDesign
            width={300}
            name={card.card_name}
            brand={appConfig.environmentName === 'demo' ? 'visa' : card.brand}
            numbers={getVisibleNumbers(card.card_visible_number, card.status)}
            expDate={formatCardExpirationDate(card.card_exp_date)}
            state={card.activation_status}
          />
        )}
      </div>
      <div>
        <div className="text-neutral-dark body-s">
          {t('cards.balanceTitle')}
        </div>
        <div className="leading-none text-neutral-darker body-xxl">
          {card.status !== 'PRE'
            ? formatMoney(card.available, company.currency)
            : '-'}
        </div>
        <div className="mt-m text-neutral-dark body-s">
          {t('cards.spentThisMonth')}
        </div>
        <div className="leading-none text-neutral-darker body-xxl">
          {card.status !== 'PRE'
            ? formatMoney(card.spent_month, company.currency)
            : '-'}
        </div>
        {!isNil(card.monthly_budget) && (
          <div className="mt-m flex items-center gap-xxs text-neutral-dark body-s">
            {t('cards.monthlyBudgetWithAmount', {
              amount: formatMoney(card.monthly_budget, company.currency),
            })}
            <InfoTip content={t('cards.monthlyBudgetTip')} />
          </div>
        )}
      </div>
      <PlasticCardActions
        card={card}
        cardsAccess={cardsAccess}
        syncActivity={syncActivity}
      />
    </div>
  );
};
