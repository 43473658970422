import { DATE_FORMAT, Icon, PanelSection } from '@dev-spendesk/grapes';
import { addDays } from 'date-fns';
import { format, fromNumber, toNumber } from 'ezmoney';

import { useTranslation } from 'common/hooks/useTranslation';

import { VerticalDottedLine } from '../../../requests/components/common/VerticalDottedLine';
import {
  type AllowanceLocation,
  isCountryWithAdjustedRates,
} from '../../allowanceLocation';
import {
  findNextArrival,
  getLastArrival,
  hasChangedLocation,
  isForeignAllowanceCanTakePrecedence,
  type PerDiem,
  type PerDiemDayStay,
  type PerDiemTripDay,
} from '../../perDiem';
import {
  getCountryName,
  type Location,
  toLocaleString,
} from '../../perDiemLocation';

import './PerDiemDaysBreakdown.css';

type Destination = {
  destination: string;
  subtotal: number;
  days: {
    date: string;
    mealString: string;
    dailyAmount: string;
  }[];
  currency: string;
  destinationNumber: number;
};

type Props = {
  perDiem: PerDiem;
  userLang: string;
  allowanceLocations: ReadonlyArray<AllowanceLocation>;
  companyCountry: string;
};

const getFirstDay = (request: PerDiem) => {
  return (
    request.tripDays[0].stays[0].departureTime ||
    request.tripDays[0].stays[1].departureTime
  );
};

const isOneDayTrip = (tripDays: PerDiemTripDay[]) => tripDays.length === 1;

const isLastTripDay = (tripDays: PerDiemTripDay[], index: number) =>
  index === tripDays.length - 1;

const hasMultipleDestinations = (dayStays: PerDiemDayStay[]) =>
  dayStays.length > 1;

const removeReturnDestination = (dayStays: PerDiemDayStay[]) => {
  return dayStays.slice(0, -1);
};

const findLastArrivalBeforeReturnDestination = (dayStays: PerDiemDayStay[]) =>
  getLastArrival(removeReturnDestination(dayStays));

const getLocation = (perDiemTripDays: PerDiemTripDay[], index: number) => {
  const { stays } = perDiemTripDays[index];
  if (isOneDayTrip(perDiemTripDays) && hasMultipleDestinations(stays)) {
    const stay = findLastArrivalBeforeReturnDestination(stays);
    if (stay) {
      return stay.location;
    }
  }
  const stay = getLastArrival(stays);
  if (stay) {
    return stay.location;
  }

  return findNextArrival(perDiemTripDays, index)?.location;
};

const lastMemorizedLocationIsDifferent = (
  oldLocation: Location | undefined,
  newLocation: Location | undefined,
) => {
  return (
    oldLocation?.country !== newLocation?.country &&
    oldLocation?.city !== newLocation?.city
  );
};

export const PerDiemDaysBreakdown = ({
  perDiem,
  userLang,
  allowanceLocations,
  companyCountry,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');

  const getMealString = (day: PerDiemTripDay) => {
    const days = [
      !day.mealsIncluded.isBreakfastIncluded &&
        `${t('forms.perDiemAllowance.mealSelector.breakfast')}`,
      !day.mealsIncluded.isLunchIncluded &&
        `${t('forms.perDiemAllowance.mealSelector.lunch')}`,
      !day.mealsIncluded.isDinnerIncluded &&
        `${t('forms.perDiemAllowance.mealSelector.dinner')}`,
    ];
    return days.filter((d) => !!d).join(' - ');
  };

  const locationToString = (location: Location) => {
    return isCountryWithAdjustedRates(location, allowanceLocations)
      ? toLocaleString(location, userLang)
      : getCountryName(location, userLang);
  };

  const { tripDays } = perDiem;

  const { location } = tripDays[0].stays[0];

  let computedDestinationNumber = 0;
  let memorizedLastLocationForReal: Location | undefined;
  const destinationsDescriptions = tripDays.reduce(
    (destinations: Destination[], day, index, days) => {
      let computedLocation: Location | undefined;
      if (index === 0) {
        computedLocation = getLocation(days, 0);
        memorizedLastLocationForReal = computedLocation;
      }
      if (
        index > 0 &&
        hasChangedLocation(day.stays) &&
        lastMemorizedLocationIsDifferent(
          memorizedLastLocationForReal,
          getLocation(days, index),
        ) &&
        !isLastTripDay(tripDays, index) &&
        !isForeignAllowanceCanTakePrecedence(day.stays, companyCountry)
      ) {
        computedDestinationNumber += 1;
        computedLocation = getLocation(days, index);
        memorizedLastLocationForReal = computedLocation;
      }
      if (
        days[index - 1] &&
        !hasChangedLocation(day.stays) &&
        lastMemorizedLocationIsDifferent(
          memorizedLastLocationForReal,
          getLocation(days, index - 1),
        ) &&
        isForeignAllowanceCanTakePrecedence(
          days[index - 1].stays,
          companyCountry,
        )
      ) {
        computedDestinationNumber += 1;
        computedLocation = getLocation(days, index - 1);
        memorizedLastLocationForReal = computedLocation;
      }

      const firstDate = getFirstDay(perDiem) || new Date();
      const date = localeFormat(addDays(firstDate, index), DATE_FORMAT.CUSTOM, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
      const mealString = getMealString(day);
      const dailyAmount = format(
        perDiem.allowance.dailyAllowances[index].finalAmount,
      );
      const currentDay = {
        date,
        mealString,
        dailyAmount,
      };

      if (!destinations[computedDestinationNumber]) {
        destinations[computedDestinationNumber] = {
          destination: computedLocation
            ? (computedLocation.description ??
              locationToString(computedLocation))
            : '',
          subtotal: 0,
          days: [],
          destinationNumber: computedDestinationNumber,
          currency:
            perDiem.allowance.dailyAllowances[index].finalAmount.currency,
        };
      }
      destinations[computedDestinationNumber].subtotal +=
        toNumber(perDiem.allowance.dailyAllowances[index].finalAmount) ?? 0;
      destinations[computedDestinationNumber].days.push(currentDay);
      return destinations;
    },
    [],
  );

  return (
    <PanelSection
      title={t('forms.perDiemAllowance.tripDetailsTitle')}
      isCollapsible
    >
      <div className="pt-m">
        <div className="mb-m">
          <div className="text-neutral-dark">
            {t('forms.perDiemAllowance.origin')}
          </div>
          <span className="text-complementary">
            {location.description ?? getCountryName(location, userLang)}
          </span>
        </div>
        {destinationsDescriptions.map(
          ({ destination, destinationNumber, days, subtotal, currency }) => (
            <div className="mb-s" key={destination + destinationNumber}>
              <div className="mb-s">
                <div className="text-neutral-dark">
                  {t('forms.perDiemAllowance.destinationCount', {
                    number:
                      destinationsDescriptions.length === 1
                        ? undefined
                        : destinationNumber + 1,
                  })}
                </div>
                <div className="text-complementary">{destination}</div>
              </div>
              <div className="rounded border-[1px] border-solid border-neutral-lighter p-s text-complementary">
                {days.map(({ dailyAmount, date, mealString }, index) => {
                  return (
                    <div
                      key={date}
                      className="PerDiemDaysBreakdown_DestinationDetailsBox mb-s"
                    >
                      <div className="relative mr-xs mt-xxs flex items-center self-start text-neutral-dark">
                        <Icon name="calendar-checked" />
                        {index !== days.length - 1 && (
                          <VerticalDottedLine
                            className="PerDiemDaysBreakdown_DestinationDetailsBox__dashedLine text-neutral"
                            numberOfDots={6}
                          />
                        )}
                      </div>
                      <div>
                        <div className="text-neutral-darker">{date}</div>
                        <div>{mealString}</div>
                      </div>
                      <div className="self-end text-right">{dailyAmount}</div>
                    </div>
                  );
                })}
                <div className="flex items-center rounded bg-neutral-lightest p-xs text-complementary title-m">
                  <span className="mr-auto">
                    {t('forms.perDiemAllowance.subtotal')}
                  </span>
                  {format(fromNumber(subtotal, currency, 1))}
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </PanelSection>
  );
};
