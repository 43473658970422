import { Panel } from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { type QueryError as IQueryError } from "src/core/api/queryError";

type Props = {
  queryError: IQueryError<unknown>;
  onClose(): void;
};
export const TransferPanelError = ({ queryError, onClose }: Props) => {
  return (
    <Panel title="-" onClose={onClose}>
      <QueryError queryError={queryError} componentType="ErrorState" />
    </Panel>
  );
};
