import { RequestState } from 'src/core/common/redux/requestState';
import { type AppState } from 'src/core/reducers';

import { type CustomField } from '../customField';

export const getOpenedTemplate = (state: AppState) =>
  state.exportSettings.openedTemplate.template;

export const getOpenedTemplateHasLoaded = (state: AppState): boolean =>
  state.exportSettings.openedTemplate.requestState === RequestState.Success;

export const getAvailableTemplateColumnsHasLoaded = (
  state: AppState,
): boolean => state.exportSettings.availableTemplateColumns.hasLoaded;

export const getAvailableTemplateColumns = (state: AppState) =>
  state.exportSettings.availableTemplateColumns.availableColumns;

export const getHasLoaded = (state: AppState): boolean => {
  return state.exportSettings.templates.requestState === RequestState.Success;
};

export const getBases = (state: AppState) => {
  if (!state.exports.bases) {
    return state.exports.bases;
  }

  // FIXME:@types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (state.exports.bases as any).filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (base: any) => base.type === 'purchase',
  );
};

export const getCustomExportsWithDefault = (state: AppState) => {
  const { templates } = state.exportSettings.templates;
  const hasLoaded = getHasLoaded(state);
  // The data is loaded async and is null initially
  if (!hasLoaded) {
    return undefined;
  }

  return templates;
};

export const getCustomFields = (state: AppState): CustomField[] => {
  return state.exports.customFields || [];
};
