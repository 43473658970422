import React from 'react';

import {
  EmptyStateError,
  EmptyStateLoading,
  lazyLoad,
} from 'src/core/common/components/LazyLoad';

export const Churn = lazyLoad({
  loader: async () => ({
    // eslint-disable-next-line unicorn/no-await-expression-member
    default: (await import(/* webpackChunkName: "Churn" */ './Churn')).default,
  }),
  loading: <EmptyStateLoading />,
  fallback: (props) => <EmptyStateError {...props} />,
});
