import { type Dispatch, type ThunkDispatch } from '@reduxjs/toolkit';

import { companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import { type ExportActions } from './actionTypes';
import {
  fetchTemplateRequest,
  fetchTemplateFailure,
  fetchTemplateSuccess,
  fetchAllTemplatesRequest,
  fetchAllTemplatesFailure,
  fetchAllTemplatesSuccess,
} from './actions';
import {
  type ApiTemplate,
  type ApiAvailableColumns,
  reshapeApiTemplate,
  reshapeApiTemplateItem,
  reshapeAvailableColumns,
  toApiTemplate,
} from './reshapers';
import {
  getAvailableTemplateColumnsHasLoaded,
  getAvailableTemplateColumns,
  getCustomFields,
} from './selectors';
import { type FormValues as TemplateFormValues } from '../components/TemplateEditorForm/formValues';
import { type AvailableColumn } from '../template';

export const fetchTemplate =
  (id: string | 'default', isTranslated: boolean) =>
  async (
    dispatch: Dispatch<ExportActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);
    const availableTemplateColumnsHasLoaded =
      getAvailableTemplateColumnsHasLoaded(state);
    const customFields = getCustomFields(state);

    dispatch(fetchTemplateRequest());

    let apiTemplate: ApiTemplate;
    let apiAvailableColumns: ApiAvailableColumns;
    let availableColumns: AvailableColumn[];

    if (!availableTemplateColumnsHasLoaded) {
      try {
        // We haven't already fetched the available columns so we fetch them
        // alongside the template.
        const [resTemplate, resAvailableColumns] = await Promise.all([
          companyAPI.get<ApiTemplate>(
            `/accounting-export-templates/${id === 'default' ? 'default?type=purchase' : id}`,
            {
              companyId,
            },
          ),
          companyAPI.get<ApiAvailableColumns>(
            '/accounting-export-templates/export-columns',
            { companyId },
          ),
        ]);
        apiTemplate = resTemplate.data;
        apiAvailableColumns = resAvailableColumns?.data;
      } catch (error) {
        dispatch(fetchTemplateFailure());
        throw error;
      }

      availableColumns = reshapeAvailableColumns(
        apiAvailableColumns,
        isTranslated,
      );
    } else {
      // We already fetched the available columns so we fetch only the template.
      try {
        const { data } = await companyAPI.get<ApiTemplate>(
          `/accounting-export-templates/${id === 'default' ? 'default?type=purchase' : id}`,
          {
            companyId,
          },
        );
        apiTemplate = data;
      } catch (error) {
        dispatch(fetchTemplateFailure());
        throw error;
      }

      availableColumns = getAvailableTemplateColumns(state);
    }

    const template = reshapeApiTemplate({
      template: apiTemplate,
      availableTemplateColumns: availableColumns,
      customFields,
      isDefault: apiTemplate.isDefault,
      isTranslated,
    });

    dispatch(
      fetchTemplateSuccess({
        template,
        ...(availableTemplateColumnsHasLoaded
          ? {}
          : { availableTemplateColumns: availableColumns }),
      }),
    );
  };

export const saveTemplate =
  (values: TemplateFormValues, templateId?: string) =>
  async (
    _: Dispatch<ExportActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    const payload = toApiTemplate(values, companyId);

    if (!templateId) {
      await companyAPI.post('/accounting-export-templates', payload, {
        companyId,
      });
    } else {
      await companyAPI.put(
        `/accounting-export-templates/${templateId}`,
        payload,
        {
          companyId,
        },
      );
    }
  };

export const fetchAllTemplates =
  () =>
  async (
    dispatch: Dispatch<ExportActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(fetchAllTemplatesRequest());

    let templates;
    try {
      const templatesRes = await companyAPI.get<ApiTemplate[]>(
        '/accounting-export-templates',
        {
          companyId,
        },
      );

      templates = templatesRes.data.map((template) =>
        reshapeApiTemplateItem(template),
      );
    } catch (error) {
      dispatch(fetchAllTemplatesFailure());
      throw error;
    }

    dispatch(fetchAllTemplatesSuccess({ templates }));
  };

export const deleteTemplate =
  (templateId: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExportActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    await companyAPI.delete(`/accounting-export-templates/${templateId}`, {
      companyId,
    });

    dispatch(fetchAllTemplates());
  };
