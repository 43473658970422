import { useEffect, useState } from 'react';

import { useIsEditablePayable } from 'modules/payable/hooks/api/useIsEditablePayable';
import { useIsLockedPayable } from 'modules/payable/hooks/api/useIsLockedPayable';
import { CreateSupplierQueryError } from 'modules/suppliers/components/SupplierBankInfoForm/CreateSupplierQueryError';
import { UpdateSupplierQueryError } from 'modules/suppliers/components/SupplierBankInfoForm/UpdateSupplierQueryError';
import { CountryFormField } from 'modules/suppliers/components/SupplierSpanishDprComplianceForm/CountryFormField';
import { SupplierFormField } from 'modules/suppliers/components/SupplierSpanishDprComplianceForm/SupplierFormField';
import { VatNumberFormField } from 'modules/suppliers/components/SupplierSpanishDprComplianceForm/VatNumberFormField';
import { useFetchSupplier } from 'modules/suppliers/hooks';
import { useSupplierEditForm } from 'modules/suppliers/hooks/useSupplierEditForm';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { PanelItemsSection } from 'src/core/common/components/Panel';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { type Payable } from '../../containers/PayablePanelContainer';
import { isSpanishDPRPayable } from '../../models/payable';

type Props = {
  payable: Payable;
};

export const PayableSupplierDetailsSection = ({ payable }: Props) => {
  const { t } = useTranslation('global');

  const hasEditPayablesAfterExportFeature = useFeature(
    FEATURES.TMP_EDIT_PAYABLES_AFTER_EXPORT,
  );
  const shouldDisplayDPRInformations = isSpanishDPRPayable(payable);

  const isLocked = useIsLockedPayable(payable);
  const isEditable = useIsEditablePayable(payable);

  const [selectedSupplier, setSelectedSupplier] = useState<
    | {
        id: string;
        name: string;
        thumbnailUrl?: string;
      }
    | undefined
  >(payable.supplier?.id !== 'unknown' ? payable.supplier : undefined);

  useEffect(() => {
    if (payable.supplier?.id !== selectedSupplier?.id) {
      setSelectedSupplier(
        payable.supplier?.id !== 'unknown' ? payable.supplier : undefined,
      );
    }
  }, [payable.supplier?.id]);

  const fetchSupplierQuery = useFetchSupplier(selectedSupplier?.id);

  const supplierData = unwrapQuery(fetchSupplierQuery);

  const { formikProps, updateSupplierQueryState, createSupplierQueryState } =
    useSupplierEditForm({
      supplier: {
        id: selectedSupplier?.id || '',
        name: selectedSupplier?.name || '',
        country: supplierData?.supplierDetails?.address?.country,
        vatNumber: supplierData?.supplierDetails?.legalInfos?.vatNumber,
      },
      onSupplierSelected: () => {},
      shouldDisplayDPRInformations,
    });

  const { setFieldValue, errors, values, submitForm, resetForm, validateForm } =
    formikProps;

  const allowEditDetails = payable.supplier || values.newSupplierName?.length;

  if (!shouldDisplayDPRInformations || payable.type !== 'claimedBill') {
    return null;
  }

  const items = [
    {
      label: t('misc.supplier'),
      value: payable.supplier?.name ?? '-',
      type: 'supplier',
    },
    {
      label: t(
        'suppliers.spanishDprCompliance.missingSupplierDetailsCallout.vatNumber.label',
      ),
      value: supplierData?.supplierDetails?.legalInfos?.vatNumber ?? '-',
    },
    {
      label: t(
        'suppliers.spanishDprCompliance.missingSupplierDetailsCallout.country.label',
      ),
      value: supplierData?.supplierDetails?.address?.country ?? '-',
    },
  ];

  const isEditableProps =
    hasEditPayablesAfterExportFeature && isEditable && !isLocked
      ? {
          isEditable: true as const,
          cancelTranslation: t('misc.cancel'),
          saveTranslation: t('misc.saveChanges'),
          editSection: (
            <form className="flex flex-col gap-xs">
              <SupplierFormField
                selectedSupplier={selectedSupplier}
                error={Boolean(errors.newSupplierName)}
                onChange={() => {}}
                isDisabled
              />

              <VatNumberFormField
                vatNumber={values.vatNumber ?? ''}
                error={Boolean(errors.vatNumber)}
                onChange={(vatNumber) => setFieldValue('vatNumber', vatNumber)}
                isDisabled={!allowEditDetails}
                className=""
              />

              <CountryFormField
                country={values.country ?? ''}
                error={Boolean(errors.country)}
                onChange={(country) => setFieldValue('country', country)}
                isDisabled={!allowEditDetails}
                className=""
              />

              {createSupplierQueryState.status === 'error' && (
                <CreateSupplierQueryError
                  createSupplierError={createSupplierQueryState.error}
                />
              )}

              {updateSupplierQueryState.status === 'error' && (
                <UpdateSupplierQueryError
                  updateSupplierError={updateSupplierQueryState.error}
                />
              )}
            </form>
          ),
          onCancel: () => resetForm(),
          onSave: async () => {
            const validationErrors = await validateForm();
            if (Object.keys(validationErrors).length > 0) {
              throw new Error('Validation error');
            }
            await submitForm();
          },
        }
      : { isEditable: false as const };

  return (
    <PanelItemsSection
      title={t('payables.panel.supplierDetails.title')}
      items={items}
      {...isEditableProps}
    />
  );
};
