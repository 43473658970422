import React from 'react';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routeFor, routes } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  className?: string;
  onClick?: () => void;
};

export const ExportsLink = ({ company, className, onClick }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavigationLink
      data-marketing="top-menu_exports"
      key="nav_exports"
      className={className}
      onClick={onClick}
      path={routeFor(routes.COMPANY_EXPORTS.path, {
        company: company.id,
      })}
      text={t('settings.links.exports')}
    />
  );
};
