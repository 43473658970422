import * as React from 'react';

import { ModalMFAToken } from './ModalMFAToken';
import { ModalMFAVerifyPhone } from './ModalMFAVerifyPhone';

export type PagesType = 'phone' | 'code' | 'finish';

type Props = {
  currentPage: PagesType;
  isPhoneNumberValid: boolean;
  isMobileNumber: boolean;
  isVerifyPhoneLoading: boolean;
  isOpen: boolean;
  phoneNumber: string;
  onVerifyPhone: () => void;
  onClose: () => void;
  onPhoneNumberChange: (phone: string) => void;
  onMfaTokenValidated: () => void;
};

export const ModalMFA = ({
  currentPage,
  isPhoneNumberValid,
  isMobileNumber,
  isVerifyPhoneLoading,
  isOpen,
  phoneNumber,
  onVerifyPhone,
  onClose,
  onPhoneNumberChange,
  onMfaTokenValidated,
}: Props) => (
  <>
    <ModalMFAVerifyPhone
      onClose={onClose}
      onVerifyPhone={onVerifyPhone}
      onPhoneNumberChange={onPhoneNumberChange}
      phoneNumber={phoneNumber}
      isPhoneNumberValid={isPhoneNumberValid}
      isMobileNumber={isMobileNumber}
      isLoading={isVerifyPhoneLoading}
      isOpen={isOpen && currentPage === 'phone'}
    />
    <ModalMFAToken
      flow="standard"
      onMfaTokenValidated={onMfaTokenValidated}
      onClose={onClose}
      isOpen={isOpen && currentPage === 'code'}
    />
  </>
);
