import React, { useEffect, useState } from 'react';

import {
  TransferConfirmationModal,
  type TransferConfirmationModalProps,
} from './TransferConfirmationModal';
import { ActiveModalStates, useConfirmModal } from './useConfirmModal';
import { TransferBatchLimitModal } from '../TransferBatchLimitModal';
import { MAX_PAYMENTS_PER_BATCH } from '../TransferBatchLimitModal/TransferBatchLimitModal';

type Props = Omit<
  TransferConfirmationModalProps,
  'onConfirmationPaymentsErrors'
>;

export const TransferConfirmationModalContainer = ({
  isOpen,
  transfers,
  onClose,
  onConfirm,
}: Props) => {
  const { activeModal, askConfirmation, showConfirmationErrors } =
    useConfirmModal();

  const [transfersToConfirm, setTransfersToConfirm] = useState(transfers);

  const onBatchLimitConfirmation = () => {
    setTransfersToConfirm(transfers.slice(0, MAX_PAYMENTS_PER_BATCH));
    askConfirmation(transfersToConfirm);
  };

  useEffect(() => {
    setTransfersToConfirm(transfers);
  }, [transfers]);

  useEffect(() => {
    if (isOpen) {
      askConfirmation(transfers);
    }
  }, [isOpen]);

  return (
    <>
      <TransferConfirmationModal
        transfers={transfersToConfirm}
        isOpen={activeModal === ActiveModalStates.confirmation && isOpen}
        isError={activeModal === ActiveModalStates.confirmationErrors && isOpen}
        onRetry={() => {
          askConfirmation(transfersToConfirm);
        }}
        onClose={onClose}
        onConfirm={onConfirm}
        onError={showConfirmationErrors}
      />

      <TransferBatchLimitModal
        isOpen={activeModal === ActiveModalStates.batchLimit && isOpen}
        onClose={onClose}
        onConfirm={onBatchLimitConfirmation}
      />
    </>
  );
};
