import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import {
  MAX_NUMBER_OF_LATE_RECEIPTS,
  MIN_NUMBER_OF_LATE_RECEIPTS,
} from 'src/core/modules/members/constants/controlRules';

import { type FormValues } from './types';

export const validate = (values: FormValues, t: TGlobalFunctionTyped) => {
  const errors: FormikErrors<FormValues> = {};
  const { name, incompletePaymentsLimit, userIds } = values;

  if (!name.trim()) {
    errors.name = t('controlRulesModal.errors.emptyName');
  }

  if (incompletePaymentsLimit === 0 || incompletePaymentsLimit === undefined) {
    errors.incompletePaymentsLimit = t('controlRulesModal.errors.emptyLimit');
  } else if (incompletePaymentsLimit > MAX_NUMBER_OF_LATE_RECEIPTS) {
    errors.incompletePaymentsLimit = t('controlRulesModal.errors.maxLimit');
  } else if (incompletePaymentsLimit < MIN_NUMBER_OF_LATE_RECEIPTS) {
    errors.incompletePaymentsLimit = t('controlRulesModal.errors.minLimit');
  }

  if (userIds.length === 0) {
    errors.userIds = t('controlRulesModal.members.noMembersSelected');
  }

  return errors;
};
