import {
  useMutation as useReactQueryMutation,
  useQueryClient,
} from 'react-query';

import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { type QueryClient } from '../client';
import { getMutationFetcher } from '../fetcher';
import { type MutationRequest } from '../mutation';
import { type QueryError } from '../queryError';
import { type MutationQueryState } from '../queryState';

export type Mutate<TPayload = void, TResult = void> = (
  payload: TPayload,
) => Promise<TResult>;

type Reset = () => void;

export type MutationState<TPayload = void, TResult = void, TError = unknown> = [
  Mutate<TPayload, TResult>,
  MutationQueryState<TResult, TError>,
  Reset,
];

export function useMutation(input: {
  request: MutationRequest;
  options?: {
    throwOnError?: boolean;
    onMutate?(mutateBag: { payload: void; client: QueryClient }): Promise<void>;
    onSuccess?(successBag: {
      rawData: void;
      data: void;
      client: QueryClient;
      payload: void;
    }): void;
    onError?(errorBag: {
      error: QueryError<unknown>;
      client: QueryClient;
      payload: void;
    }): void;
  };
}): MutationState;

export function useMutation<
  TPayload extends object & { endpointParams?: object },
>(input: {
  request: MutationRequest<TPayload>;
  options?: {
    throwOnError?: boolean;
    onMutate?(mutateBag: {
      payload: TPayload;
      client: QueryClient;
    }): Promise<void>;
    onSuccess?(successBag: {
      rawData: void;
      data: void;
      payload: TPayload;
      client: QueryClient;
    }): void;
    onError?(errorBag: {
      error: QueryError<unknown>;
      payload: TPayload;
      client: QueryClient;
    }): void;
  };
}): MutationState<TPayload>;

export function useMutation<TPayload, TRawResult extends object>(input: {
  request: MutationRequest;
  reshapeData(data: TRawResult): void;
  options?: {
    throwOnError?: boolean;
    onMutate?(mutateBag: {
      payload: TPayload;
      client: QueryClient;
    }): Promise<void>;
    onSuccess?(successBag: {
      rawData: TRawResult;
      data: void;
      client: QueryClient;
      payload: TPayload;
    }): void;
    onError?(errorBag: {
      error: QueryError<unknown>;
      client: QueryClient;
      payload: TPayload;
    }): void;
  };
}): MutationState<TPayload>;

export function useMutation<
  TPayload,
  TResult extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRawResult extends object = any,
>(input: {
  request: MutationRequest;
  reshapeData(data: TRawResult): TResult;
  options?: {
    throwOnError?: boolean;
    onMutate?(mutateBag: {
      payload: TPayload;
      client: QueryClient;
    }): Promise<void>;
    onSuccess?(successBag: {
      rawData: TRawResult;
      data: TResult;
      client: QueryClient;
      payload: TPayload;
    }): void;
    onError?(errorBag: {
      error: QueryError<unknown>;
      client: QueryClient;
      payload: TPayload;
    }): void;
  };
}): MutationState<TPayload, TResult>;

export function useMutation<
  TPayload extends object & { endpointParams?: object },
  TResult extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRawResult extends object = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
>(input: {
  request: MutationRequest<TPayload>;
  reshapeData(data: TRawResult): TResult;
  options?: {
    throwOnError?: boolean;
    onMutate?(mutateBag: {
      payload: TPayload;
      client: QueryClient;
    }): Promise<void>;
    onSuccess?(successBag: {
      rawData: TRawResult;
      data: TResult;
      client: QueryClient;
      payload: TPayload;
    }): void;
    onError?(errorBag: {
      error: QueryError<TError>;
      client: QueryClient;
      payload: TPayload;
    }): void;
  };
}): MutationState<TPayload, TResult, TError>;

/**
 * :warning: Be careful as the Payload won't keep the endpointsParams in the options callbacks
 */
export function useMutation<
  TPayload extends object & { endpointParams?: object },
  TResult extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRawResult extends object = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError extends object = any,
>({
  request,
  reshapeData,
  options,
}: {
  request: MutationRequest<TPayload>; // endpointParams is only for the request key
  reshapeData?(data: TRawResult): TResult;
  options?: {
    throwOnError?: boolean;
    onMutate?(mutateBag: {
      payload: TPayload; // :warning: No endpointParams
      client: QueryClient;
    }): Promise<void>;
    onSuccess?(successBag: {
      rawData: TRawResult | void;
      data: TResult | void;
      client: QueryClient;
      payload: TPayload; // :warning: No endpointParams
    }): void;
    onError?(errorBag: {
      error: QueryError<TError>;
      client: QueryClient;
      payload: TPayload; // :warning: No endpointParams
    }): void;
  };
}): MutationState<TPayload, TResult | void, TError> {
  const queryClient = useQueryClient();
  const companyId = useCompanyId();
  const mutationFetcher = getMutationFetcher<TRawResult, TPayload>({
    companyId,
    request,
  });
  const { mutateAsync, reset, status, data, error } = useReactQueryMutation<
    TRawResult,
    QueryError<TError>,
    TPayload
  >(mutationFetcher, {
    ...options,
    onMutate: (payload) =>
      options?.onMutate?.({
        payload,
        client: queryClient,
      }),
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onSuccess: (data, payload) =>
      options?.onSuccess?.({
        payload,
        rawData: data,
        data: wrapReshapeData(data, reshapeData),
        client: queryClient,
      }),
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onError: (error, payload) =>
      options?.onError?.({
        payload,
        error,
        client: queryClient,
      }),
  });

  const mutateAndReshape = async (
    payload: TPayload,
  ): Promise<TResult | void> => {
    const rawResult = await mutateAsync(payload);
    return wrapReshapeData(rawResult, reshapeData);
  };

  if (status === 'loading' || status === 'idle') {
    return [
      mutateAndReshape,
      {
        status,
      },
      reset,
    ];
  }

  if (status === 'error') {
    return [
      mutateAndReshape,
      {
        status: 'error',
        error,
      },
      reset,
    ];
  }

  return [
    mutateAndReshape,
    {
      status: 'success',
      data: reshapeData && data ? reshapeData(data) : undefined,
    },
    reset,
  ];
}

function wrapReshapeData<TRawResult, TResult>(
  data: TRawResult | undefined,
  reshapeData: ((data: TRawResult) => TResult) | undefined,
): TResult | void {
  try {
    return reshapeData && data ? reshapeData(data) : undefined;
  } catch {
    return undefined;
  }
}
