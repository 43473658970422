import { SkeletonText, SkeletonButton } from '@dev-spendesk/grapes';
import React from 'react';

export const SendToPaymentLoader = () => {
  return (
    <div className="m-auto flex h-[269px] flex-col place-content-center place-items-center">
      <SkeletonText size="xl" width="60%" className="mb-m" />
      <SkeletonText size="xxl" width="40%" className="mb-l" />
      <SkeletonText size="l" width="70%" className="mb-l" />
      <SkeletonButton width="100%" />
    </div>
  );
};
