import {
  AmountInput,
  Callout,
  DatePicker,
  IconButton,
} from '@dev-spendesk/grapes';
import * as Money from 'ezmoney';
import { type FormikProps, getIn } from 'formik';
import uniqueId from 'lodash/uniqueId';

import { useTranslation } from 'common/hooks/useTranslation';
import { type CurrenciesKey } from 'src/core/config/money';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { getCurrencyOptionByKey } from 'src/core/utils/money';
import { removeAt } from 'src/core/utils/toolbelt';

import { type FormValues } from './formValues';
import { computeAmountLeftToSchedule } from '../../models';

import './SchedulingProcessForm.css';

type Props = {
  currency: CurrenciesKey;
  amountToSchedule: Money.MonetaryValue;
} & FormikProps<FormValues>;

/**
 * TODO: we should probably use Formik's `FieldArray` but it doesn't seem to
 * work with `useFormik()`.
 */
export const SchedulingProcessForm = ({
  currency,
  amountToSchedule,
  setFieldValue,
  setFieldTouched,
  values,
  errors,
}: Props) => {
  const { t } = useTranslation();
  const amountLeftToSchedule = computeAmountLeftToSchedule(
    amountToSchedule,
    values.payments,
  );
  const hasAmountToScheduleError = typeof errors.payments === 'string';
  const handleAddPayment = () => {
    const amountLeftToScheduleValue = Money.toNumber(amountLeftToSchedule);

    setFieldValue(`payments[${values.payments.length}]`, {
      id: uniqueId(),
      amount: amountLeftToScheduleValue > 0 ? amountLeftToScheduleValue : null,
      date: null,
    });
  };
  const handleRemovePayment = (index: number) => {
    setFieldValue('payments', removeAt(values.payments, index));
  };

  return (
    <form>
      {values.payments.map((item, index) => (
        <div key={item.id} className="SchedulingProcessFormField">
          <AmountInput
            fit="parent"
            currency={getCurrencyOptionByKey(currency)}
            value={item.amount}
            isInvalid={getIn(errors, `payments[${index}].amount`)}
            placeholder={t('invoices.schedulingProcessForm.amount')}
            onChange={(_, amount) => {
              setFieldValue(`payments[${index}].amount`, amount);
            }}
            onBlur={() => {
              setFieldTouched(`payments[${index}].amount`, true);
            }}
            className="SchedulingProcessForm__field__amount"
          />
          <DatePicker
            placement="bottom-end"
            fit="parent"
            id={`schedule-item-date-${index}`}
            value={item.date ?? undefined}
            isInvalid={getIn(errors, `payments[${index}].date`)}
            onChange={(date) => {
              setFieldValue(`payments[${index}].date`, date);
            }}
            className="SchedulingProcessForm__field__date"
          />
          {index === 0 && (
            <IconButton
              onClick={handleAddPayment}
              iconName="plus-circle"
              aria-label={t('misc.add')}
            />
          )}
          {index > 0 && (
            <IconButton
              onClick={() => handleRemovePayment(index)}
              iconName="trash"
              aria-label={t('misc.delete')}
            />
          )}
        </div>
      ))}
      <span className={hasAmountToScheduleError ? 'text-alert' : ''}>
        {t('invoices.schedulingProcessForm.amountLeftToSchedule', {
          amount: formatMonetaryValue(amountLeftToSchedule, {
            // FIXME: can't access const enum SignDisplay with isolatedModules
            signDisplay: 'never' as Money.SignDisplay,
          }),
          context:
            Money.toNumber(amountLeftToSchedule) >= 0 ? 'positive' : 'negative',
        })}
      </span>
      {hasAmountToScheduleError && typeof errors.payments === 'string' && (
        <Callout variant="alert" className="mt-xs" title={t(errors.payments)} />
      )}
    </form>
  );
};
