import { Modal } from '@dev-spendesk/grapes';
import React from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { getAccountingIntegrationName } from '../accountingIntegration';

import './AccountingBaseSwitchInformationModal.css';

export type Props = {
  accountingIntegration:
    | AccountingSoftware
    | 'noIntegration'
    | 'switchInProgress';
  isInitialChoice: boolean;
  isOpen: boolean;
};

export function AccountingBaseSwitchingModal({
  accountingIntegration,
  isInitialChoice,
  isOpen,
}: Props) {
  const { t } = useTranslation('global');

  if (accountingIntegration === 'noIntegration') {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t(
        isInitialChoice
          ? 'bookkeep.integrations.settings.baseSwitch.settingTitle'
          : 'bookkeep.integrations.settings.baseSwitch.switchingTitle',
      )}
      iconName="sync"
      iconVariant="primary"
    >
      {t(
        accountingIntegration === 'switchInProgress' ||
          hasIntegrationFileBasedExport(accountingIntegration)
          ? 'bookkeep.integrations.settings.baseSwitch.switchingSubtitleFileBased'
          : 'bookkeep.integrations.settings.baseSwitch.switchingSubtitleNative',
        accountingIntegration !== 'switchInProgress'
          ? {
              integrationName: getAccountingIntegrationName(
                accountingIntegration,
                t,
              ),
            }
          : {},
      )}
    </Modal>
  );
}
