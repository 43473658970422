import { type Dispatch, type ThunkDispatch } from '@reduxjs/toolkit';
import { type AxiosResponse } from 'axios';

import { getSelectedCompanyId } from 'modules/company';
import { sendCostCenterRequestSubmissionEvent } from 'modules/company/cost-centers';
import { type AppState } from 'src/auth/reducers';
import { baseAPI } from 'src/core/api/axios';
import FEATURES from 'src/core/constants/features';
import { fetchRequestsStats } from 'src/core/modules/requests/redux/legacy/actions';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';

import * as perDiemActions from './actions';
import * as perDiemSelectors from './selectors';
import { addExpenseCategoryToCustomFieldAssociations } from '../../budgets/models/expenseCategory';
import { addDefaultCity, type AllowanceLocation } from '../allowanceLocation';
import { type PerDiemTripDay } from '../perDiem';

export function fetchAllowanceLocations() {
  return async (
    dispatch: Dispatch<perDiemActions.PerDiemAction>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getState: () => any,
  ): Promise<void> => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(perDiemActions.fetchAllowanceLocationsRequest());
    try {
      const { data }: AxiosResponse<AllowanceLocation[]> = await baseAPI.get(
        `/${companyId}/per-diem/legal-locations?year=2023`,
      );
      const allowanceLocations = data.map((allowanceLocation) => {
        if (allowanceLocation.citiesWithAdjustedRates.length) {
          return addDefaultCity(allowanceLocation);
        }
        return allowanceLocation;
      });
      dispatch(
        perDiemActions.fetchAllowanceLocationsSuccess(allowanceLocations),
      );
    } catch (error) {
      dispatch(
        perDiemActions.fetchAllowanceLocationsFailure(
          (error.response as AxiosResponse<perDiemActions.PerDiemHttpFailure>)
            .data,
        ),
      );
    }
  };
}

export function create(tripDays: PerDiemTripDay[], description: string) {
  return async (
    dispatch: ThunkDispatch<AppState, null, perDiemActions.PerDiemAction>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getState: () => any,
  ): Promise<void> => {
    const state = getState();
    const companyId = getSelectedCompanyId(state);
    dispatch(perDiemActions.createRequest());

    const teamId = perDiemSelectors.getSelectedTeamId(state);
    const costCenterId = perDiemSelectors.getSelectedCostCenterId(state);
    const customFields = perDiemSelectors.getSelectedCustomFields(state);
    const expenseCategoryId =
      perDiemSelectors.getSelectedExpenseCategoryId(state);

    const customFieldsAssociations =
      addExpenseCategoryToCustomFieldAssociations({
        customFieldAssociations: customFields.map((customField) => ({
          customFieldId: customField.customFieldId,
          customFieldValueId: customField.customFieldValueId,
          value: customField.value,
        })),
        expenseCategoryId,
        isExpenseCategoriesFeatureEnabled: getIsFeatureEnabled(
          state,
          FEATURES.EXPENSE_CATEGORIES,
        ),
        expenseCategoryCustomFieldId:
          state.requests.expenseCategoryCustomFieldId,
        expenseCategoryValue: '',
      });

    const clientTimezoneOffsetInMinutes = tripDays?.[0]?.stays?.[0]
      ?.departureTime
      ? new Date(tripDays[0].stays[0].departureTime).getTimezoneOffset()
      : new Date().getTimezoneOffset();
    const request = {
      tripReport: {
        tripDays,
        isTripWithNoOvernightStay: false,
      },
      type: 'per_diem_allowance',
      clientTimezoneOffsetInMinutes,
      description,
      group_id: teamId,
      cost_center_id: costCenterId,
      custom_fields_associations: customFieldsAssociations,
    };
    let response = null;
    try {
      response = await baseAPI.post(`/${companyId}/requests`, request);
    } catch (error) {
      dispatch(perDiemActions.createFailure(error.response.data));
      throw error;
    }
    dispatch(perDiemActions.createSuccess(response.data));
    // @ts-expect-error the dispatch typing is wrong
    dispatch(fetchRequestsStats());
    sendCostCenterRequestSubmissionEvent(
      state,
      response?.data?.id,
      costCenterId,
    );
    return response.data.id;
  };
}
