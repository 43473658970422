import React, { type ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ScaWireTransferActivationModal } from 'modules/StrongCustomerAuthentication/components/ScaWireTransferActivationModal/ScaWireTransferActivationModal';
import { useUserScaEnrolledLazyQuery } from 'modules/StrongCustomerAuthentication/hooks/useUserScaEnrolledLazyQuery';
import type { AppDispatch } from 'modules/app/redux/store';
import {
  getWireTransferActivationStatusForExpenses,
  getWireTransferActivationStatusForInvoices,
} from 'modules/company/billing-legacy/redux';
import { ModalMFAContainer } from "src/core/common/components/ModalMFA";
import { activateWireTransfer } from "src/core/common/components/ModalMFA/actions";
import { useFeature } from "src/core/common/hooks/useFeature";
import FEATURES from "src/core/constants/features";
import { track, AnalyticEventName } from "src/core/utils/analytics";

import { ForExpenses } from './components/ForExpenses';
import { ForInvoices } from './components/ForInvoices';
import { ModalWireTransferActivated } from './components/ModalWireTransferActivated';
import { ModalWireTransferWelcome } from './components/ModalWireTransferWelcome';
import { type WireTransferPayableEntity } from '../../models';

type WireTransferProps = {
  className?: string;
  wireTransferPayableEntity: WireTransferPayableEntity;
};
export const WireTransfer = ({
  className,
  wireTransferPayableEntity,
}: WireTransferProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const wireTransferActivationStatusForExpenses = useSelector(
    getWireTransferActivationStatusForExpenses,
  );

  const wireTransferActivationStatusForInvoices = useSelector(
    getWireTransferActivationStatusForInvoices,
  );

  const [isUserEnrolledError, setIsUserEnrolledError] = React.useState(false);
  const isUserScaFeatureEnabled = useFeature(
    FEATURES.STRONG_CUSTOMER_AUTHENTICATION,
  );
  const [isWireTransferModalOpened, setIsWireTransferModalOpened] =
    React.useState(false);

  const [fetchUserScaEnrolled, userScaEnrolledLazyQueryState] =
    useUserScaEnrolledLazyQuery();

  const isUserEnrolled =
    userScaEnrolledLazyQueryState.status === 'success' &&
    userScaEnrolledLazyQueryState.data.isScaUserEnrolled;

  const [wireTransferActivationStep, setWireTransferActivationStep] =
    React.useState<
      'welcome' | 'activationWithSca' | 'activationWithMfa' | 'success'
    >('welcome');

  React.useEffect(() => {
    fetchUserScaEnrolled();
  }, []);

  const handleActivateWireTransfer = async (): Promise<void> => {
    if (isUserScaFeatureEnabled && isUserEnrolled) {
      await handleWireTransferActivated();
    } else {
      onToggleWireTransferModal();
    }
  };

  const handleWireTransferActivated = async (): Promise<void> => {
    await dispatch(activateWireTransfer(wireTransferPayableEntity));
    setWireTransferActivationStep('success');

    if (wireTransferPayableEntity === 'invoices') {
      track(
        AnalyticEventName.SETTINGS_PAYMENT_METHODS_PAY_INVOICES_BY_WIRE_TRANSFER_ACTIVATED,
      );
    }
  };

  const gotoToWireTransferNextStep = (): void => {
    if (isUserScaFeatureEnabled && !isUserEnrolled) {
      setWireTransferActivationStep('activationWithSca');
    } else {
      setWireTransferActivationStep('activationWithMfa');
    }
  };

  const onToggleWireTransferModal = (): void => {
    setWireTransferActivationStep('welcome');
    setIsWireTransferModalOpened(
      (previousIsWireTransferModalOpened) => !previousIsWireTransferModalOpened,
    );
  };

  const handleEnrollUser = async (): Promise<void> => {
    const payload = await fetchUserScaEnrolled();
    if (payload && payload.isScaUserEnrolled) {
      handleWireTransferActivated();
    } else {
      setIsUserEnrolledError(true);
    }
  };

  const renderScaModal = (): ReactElement => {
    const isOpen =
      isWireTransferModalOpened &&
      wireTransferActivationStep === 'activationWithSca';

    return (
      <ScaWireTransferActivationModal
        isOpen={isOpen}
        isUserEnrolledError={isUserEnrolledError}
        onClose={onToggleWireTransferModal}
        onEnrollUser={handleEnrollUser}
      />
    );
  };

  const renderWireTransferWelcomeModal = (): ReactElement => {
    const isOpen =
      isWireTransferModalOpened && wireTransferActivationStep === 'welcome';

    return (
      <ModalWireTransferWelcome
        isOpen={isOpen}
        onContinue={gotoToWireTransferNextStep}
        onClose={onToggleWireTransferModal}
      />
    );
  };

  const renderMfaModal = (): ReactElement => {
    const isOpen =
      isWireTransferModalOpened &&
      wireTransferActivationStep === 'activationWithMfa';

    return (
      <ModalMFAContainer
        isOpen={isOpen}
        onModalClose={onToggleWireTransferModal}
        onFactorActivated={handleWireTransferActivated}
      />
    );
  };

  const renderSuccessModal = (): ReactElement => {
    const isOpen =
      isWireTransferModalOpened && wireTransferActivationStep === 'success';

    return (
      <ModalWireTransferActivated
        isOpen={isOpen}
        wireTransferPayableEntity={wireTransferPayableEntity}
        onClose={onToggleWireTransferModal}
      />
    );
  };

  return (
    <>
      {wireTransferPayableEntity === 'expenses' &&
        wireTransferActivationStatusForExpenses && (
          <ForExpenses
            className={className}
            wireTransferActivationStatus={
              wireTransferActivationStatusForExpenses
            }
            onWireTransferActivation={handleActivateWireTransfer}
          />
        )}

      {wireTransferPayableEntity === 'invoices' &&
        wireTransferActivationStatusForInvoices && (
          <ForInvoices
            className={className}
            wireTransferActivationStatus={
              wireTransferActivationStatusForInvoices
            }
            onWireTransferActivation={handleActivateWireTransfer}
          />
        )}

      {renderWireTransferWelcomeModal()}
      {renderScaModal()}
      {renderMfaModal()}
      {renderSuccessModal()}
    </>
  );
};
