import React from 'react';

import { type TaxAccount } from 'modules/bookkeep';
import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const TableFooter = ({
  taxAccountQueryState,
}: {
  taxAccountQueryState: QueryState<TaxAccount[]>;
}) => {
  const { t } = useTranslation('global');

  if (taxAccountQueryState.status === 'loading') {
    return <>{t('misc.loading')}</>;
  }
  if (
    taxAccountQueryState.status === 'success' &&
    taxAccountQueryState.data.length === 0
  ) {
    return <em>{t('bookkeep.integrations.settings.noAccounts')}</em>;
  }

  return null;
};
