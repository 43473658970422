import { Autocomplete, AutocompleteNoOptions } from '@dev-spendesk/grapes';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useRequestersQuery } from './hooks/useRequestersQuery';

type Option = {
  key: string;
  label: string;
};

type Props = {
  onSelect: (option: Option | undefined) => void;
  value: Option | undefined;
};

export const RequestRequesterFilter = ({ onSelect, value }: Props) => {
  const [searchPattern, setSearchPattern] = useState('');
  const { t } = useTranslation('global');
  const requestersQueryState = useRequestersQuery(searchPattern.toLowerCase());
  return (
    <Autocomplete
      onSelect={onSelect}
      placeholder={t('filters.requesters.placeholder')}
      fit="parent"
      onSearch={debounce((search) => {
        if (search) {
          setSearchPattern(search);
        }
      }, 500)}
      options={
        requestersQueryState.status === 'success'
          ? requestersQueryState.data.map((member) => ({
              key: member.id,
              label: `${member.firstName} ${member.lastName}`,
            }))
          : []
      }
      value={value}
      renderNoOptions={(rawValue) => (
        <AutocompleteNoOptions>
          {rawValue === '' ? (
            t('misc.startTyping')
          ) : (
            <Trans
              i18nKey="misc.noOptions"
              values={{ account: rawValue }}
              // eslint-disable-next-line react/jsx-key
              components={[<span />]}
            />
          )}
        </AutocompleteNoOptions>
      )}
    />
  );
};
