import { useFormik } from 'formik';
import { useRef } from 'react';

import { useNotifications } from 'modules/app/notifications';

import { type Form, type FormConfig, type FormValues } from './types';
import { getUpdatedValues } from './utils/getUpdatedValues';
import { useQueryError } from '../../components/QueryError';

export function useForm<Values extends FormValues, ErrorPayload = unknown>({
  onSubmitSuccess,
  successMessage,
  onSubmitError,
  errorMessage,
  throwOnError = false,
  onSubmit,
  validate,
  formikOptions: {
    validateOnBlur = false,
    validateOnChange,
    validateOnMount = false,
    enableReinitialize = true,
  } = {},
  initialValues,
  onCancel,
  ...props
}: FormConfig<Values, ErrorPayload>): Form<Values> {
  const { successNotif, dangerNotif } = useNotifications();
  const queryError = useQueryError({});

  const validateFormOnChangeReference = useRef(false);
  const form = useFormik<Values>({
    ...props,
    validate: (values) => {
      const result = validate?.(values) ?? {};

      /**
       * If `result` only contains falsy values, the form is valid and we return nothing
       * otherwise we return the errors
       */
      if (Object.values(result).some(Boolean)) {
        return result;
      }
    },
    onSubmit: async (data) => {
      try {
        await onSubmit(data, {
          form,
          updatedValues: getUpdatedValues({ form, data }),
        });

        onSubmitSuccess?.(data);

        if (successMessage) {
          successNotif(successMessage);
        }
      } catch (e) {
        onSubmitError?.({ type: e.type, data: e.data });
        if (errorMessage) {
          dangerNotif(errorMessage, queryError(e));
        }
        if (throwOnError) {
          throw e;
        }
      }
    },
    validateOnBlur,
    validateOnChange: validateOnChange ?? validateFormOnChangeReference.current,
    validateOnMount,
    enableReinitialize,
    initialValues: initialValues as Values, // this is a lie. initialValues is Partial<Values>
  }) as Form<Values>;

  validateFormOnChangeReference.current = form.submitCount > 0;

  form.cancel = onCancel;

  return form;
}
