import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { NavLink } from '../NavLink/NavLink';

type Props = {
  company: {
    id: string;
  };
};

export const MultiEntityHubLink = ({ company }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavLink
      href={routeFor(routes.ORGANISATION_REPORTING.path, {
        company: company.id,
      })}
      iconName="eye"
    >
      {t('organisation.reporting.links.nav')}
    </NavLink>
  );
};
