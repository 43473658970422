import { Button } from '@dev-spendesk/grapes';
import React, { type ReactNode } from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { Popover } from '../Popover';

import './BetaFeaturePopover.css';

type Props = {
  onClose(): void;
  title?: ReactNode;
  content?: ReactNode;
  isFeedbackMandatory: boolean;
};

export const BetaFeaturePopover = ({
  onClose,
  title,
  content,
  isFeedbackMandatory,
}: Props) => {
  const { t } = useTranslation('global');
  const popoverTitle = title ?? `${t('betaFeature.notification.title')} ✨`;
  const popoverContent = content ?? t('betaFeature.notification.description');

  return (
    <Popover
      hideCloseButton={isFeedbackMandatory}
      title={popoverTitle}
      content={popoverContent}
      actions={
        <Button
          text={t('betaFeature.notification.close')}
          variant="contrasted"
          onClick={onClose}
        />
      }
      className="BetaFeaturePopover"
      onClose={onClose}
      disableClickAwayClose={isFeedbackMandatory}
    />
  );
};
