import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ExpenseCategory } from '../../../expenseCategory';

export const useConditionalExpenseCategoriesQuery = (
  costCenterId: string | undefined,
): QueryState<ExpenseCategory[]> => {
  return useQuery<ExpenseCategory[]>({
    key: ['expenseCategories', costCenterId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/expense-categories${
        costCenterId ? `?costCenterId=${costCenterId}` : ''
      }`,
    },
    reshapeData: (data) => data,
  });
};
