import { Link } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from "src/core/common/hooks/useTranslation";
import { TypeGuardError } from "src/core/utils/switchGuard";

import { type Integration } from '../../../types';
import { getHelpCenter, isKomboIntegration } from '../../../utils';

type Props = {
  integration: Integration;
};

export const IntegrationHelpCenter = (props: Props) => {
  const { integration } = props;
  const { t } = useTranslation('global');

  if (isKomboIntegration(integration.id)) {
    return null;
  }

  switch (integration.id) {
    case 'travelperk':
      return (
        <div className="flex w-full flex-col gap-xs text-left text-complementary">
          <span className="flex title-m">
            {t('integration.connection.howToUse.title')}
          </span>
          <p className="flex-inline text-m">
            <Trans i18nKey="integration.connection.howToUse.helpCenterArticle">
              text
              <Link
                className="text-complementary"
                isExternal
                href={getHelpCenter(t, integration.id)}
                rel="noreferrer"
              >
                link
              </Link>
              text
            </Trans>
          </p>
        </div>
      );
    case 'spendesk-oauth2':
      return null;
    default:
      throw new TypeGuardError(
        integration.id,
        'IntegrationHelpCenterInModal: Unknown integrationId',
      );
  }
};
