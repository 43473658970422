import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type InvalidFileErrorDetails =
  | {
      reason: 'unreadableFile';
    }
  | {
      reason: 'fileStructure';
      subreason:
        | 'worksheetDiscrepancies'
        | 'rowDiscrepancies'
        | 'columnDiscrepancies';
      worksheetName?: string;
      removedValues: string[];
      addedValues: string[];
    }
  | {
      reason: 'dataValidation';
      subreason: 'invalidValue' | 'inconsistentValues';
      worksheetName: string;
      cellPosition: string;
    };

export type ImportBudgetRequestError =
  | {
      error: 'fileInvalid';
      details: InvalidFileErrorDetails[];
    }
  | {
      error: 'companyNotFound';
    }
  | {
      error: 'budgetaryExerciseNotFound';
    }
  | {
      error: 'costCentersNotSet';
    };

type ImportBudgetRequestSuccess = {
  warnings?: string[];
};

export const useImportBudgetMutation = (
  budgetaryExerciseId: string,
): MutationState<
  FormData,
  ImportBudgetRequestSuccess,
  ImportBudgetRequestError
> => {
  return useMutation<
    FormData,
    ImportBudgetRequestSuccess,
    ImportBudgetRequestSuccess,
    ImportBudgetRequestError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/budgets/budgetary-exercises/${budgetaryExerciseId}/budgets`,
      method: 'post',
    },
    reshapeData(data) {
      return data;
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries('budgetaryExercises');
        client.invalidateQueries('budgets'); // Invalidate all budget breakdowns
      },
    },
  });
};

export const reshapeToFormData = (completedBudgets: File): FormData => {
  const formData = new FormData();
  formData.append('completedBudgets', completedBudgets, completedBudgets.name);
  return formData;
};
