import React, {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { LocalStorageKey } from 'src/core/constants/storage';
import { getLocalItem, setLocalItem } from 'src/core/utils/storage';

import styles from './Navigation.module.css';

const NavigationContext = createContext<boolean | null>(null);

type Props = {
  children: ReactNode;
};

export const toggleForceToExpandNavigation = () => {
  if (document.documentElement.dataset.navExpanded === '1') {
    document.documentElement.dataset.navExpanded = '0';
    setLocalItem(LocalStorageKey.ExpandNavigation, '0', true);
  } else {
    document.documentElement.dataset.navExpanded = '1';
    setLocalItem(LocalStorageKey.ExpandNavigation, '1', true);
  }
};

export const NavigationProvider = ({ children }: Props) => {
  const navRef = useRef<HTMLElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useLayoutEffect(() => {
    const shouldBeExpanded =
      getLocalItem(LocalStorageKey.ExpandNavigation, true) === '1';
    if (shouldBeExpanded) {
      document.documentElement.dataset.navExpanded = '1';
    }
  }, []);

  useEffect(() => {
    const savedRef = navRef.current;
    if (savedRef) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            const contentBoxSize = entry.contentBoxSize[0];
            setIsExpanded(contentBoxSize.inlineSize > 230);
          }
        }
      });
      resizeObserver.observe(savedRef);
      return () => resizeObserver.unobserve(savedRef);
    }
  }, [navRef]);

  return (
    <NavigationContext.Provider value={isExpanded}>
      <nav
        id="vertical"
        className={styles.nav}
        ref={navRef}
        aria-label="Main navigation"
      >
        {children}
      </nav>
    </NavigationContext.Provider>
  );
};

export function useNavigationContext() {
  const context = useContext(NavigationContext);
  if (context === null) {
    throw new Error(
      'useNavigationContext needs to be used within a NavigationContext.Provider',
    );
  }
  return context;
}
