import { type FormikErrors } from 'formik';
import isEmpty from 'lodash/isEmpty';

import {
  type BankInfosValidationContext,
  validateSupplierBankInfos,
  type BankInfosErrors,
} from 'src/core/modules/suppliers/models';

type FormValues = {
  supplier: {
    id?: string;
    name: string;
  } | null;
  accountHolderName: string | null;
  legalName: string | null;
  supplierAccountCode: string | null;
  registrationNumber: string | null;
  vatNumber: string | null;
  address: string | null;
  zipcode: string | null;
  city: string | null;
  country: string | null;
  bankCountry: string | null;
  iban: string | null;
  bic: string | null;
  sortCode: string | null;
  routingNumber: string | null;
  accountNumber: string | null;
  accountCode: string | null;
};

export type ErrorTranslationVars = {
  length?: number;
};

export const validateForm = (
  values: FormValues,
  bankInfosValidationContext: BankInfosValidationContext,
) => {
  const errors: FormikErrors<FormValues> & BankInfosErrors = {};

  if (isEmpty(values.supplier)) {
    errors.supplier = 'forms.supplier.error';
  }

  if (isEmpty(values.legalName)) {
    errors.legalName = 'submitMyInvoice.fields.supplier.legalNameError';
  }

  const bankInfoErrors = validateSupplierBankInfos(
    {
      bankCountry: values.bankCountry ?? undefined,
      iban: values.iban ?? undefined,
      bic: values.bic ?? undefined,
      sortCode: values.sortCode ?? undefined,
      accountCode: values.accountCode ?? undefined,
      accountNumber: values.accountNumber ?? undefined,
      routingNumber: values.routingNumber ?? undefined,
      accountHolderName: values.accountHolderName ?? undefined,
    },
    bankInfosValidationContext,
  );

  return {
    ...errors,
    ...bankInfoErrors,
  };
};
