import { LogoutButton } from 'common/components/LogoutButton/LogoutButton';
import { useSupervisor } from 'modules/app/hooks/useSupervisor';
import { useUser } from 'modules/app/hooks/useUser';

import './Supervision.css';

export const Supervision = () => {
  const supervisor = useSupervisor();
  const user = useUser();
  if (!supervisor || !user) {
    return null;
  }

  const permissions = !supervisor.canWrite ? 'read-only' : 'read+write';

  return (
    <div className="Supervision">
      Welcome {supervisor.name}! You are logged in as {user.fullname} with{' '}
      {permissions} permissions. <LogoutButton className="px-xxs underline" />
    </div>
  );
};
