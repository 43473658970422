import { useInvalidateCustomFieldsQuery } from 'modules/budgets/apis';

import { type BudgetTrackingTraitModel } from '../../models/budgetTracking';
import { isNewCustomFieldAssociation } from '../../models/customFieldAssociation';

export const useOnBudgetTrackingBackEndUpdate = () => {
  const invalidateCustomFieldsQuery = useInvalidateCustomFieldsQuery();

  return async (budgetTrackingProps: BudgetTrackingTraitModel.Trait) => {
    const { customFieldsAssociations } = budgetTrackingProps;

    if (customFieldsAssociations.some(isNewCustomFieldAssociation)) {
      await invalidateCustomFieldsQuery();
    }
  };
};
