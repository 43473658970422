import {
  Button,
  Modal,
  Upload,
  Callout,
  Badge,
  FileCard,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import './CostCenterImportModal.css';

type Props = {
  onUpload(file: File): void;
  onCancel(): void;
  downloadLink: string;
  type: 'create' | 'edit';
  isOpen: boolean;
  showBackButton?: boolean;
};

class TypeGuardError extends Error {
  constructor(_switchCase: never, message: string) {
    super(message);
  }
}

function getTranslationKeyFromImportType(type: Props['type']): {
  title: I18nKey;
  subtitle: I18nKey;
} {
  switch (type) {
    case 'create':
      return {
        title: 'costCenters.importModal.title.create',
        subtitle: 'costCenters.importModal.subtitle.create',
      };
    case 'edit':
      return {
        title: 'costCenters.importModal.title.edit',
        subtitle: 'costCenters.importModal.subtitle.create',
      };
    default:
      throw new TypeGuardError(type, `Unknown import type ${type}`);
  }
}

export const CostCenterImportModal = ({
  onUpload,
  onCancel,
  downloadLink,
  type,
  isOpen,
  showBackButton = false,
}: Props) => {
  const { t } = useTranslation('global');
  const { title, subtitle } = getTranslationKeyFromImportType(type);

  const [costCenterFile, setCostCenterFile] = useState<File>();
  const [errorMissingFile, setErrorMissingFile] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      iconName="plus-circle"
      iconVariant="primary"
      title={t(title)}
      subtitle={t(subtitle)}
      actions={
        <>
          <Button
            key="no"
            onClick={onCancel}
            iconName={showBackButton ? 'caret-left' : undefined}
            iconPosition="left"
            text={showBackButton ? t('misc.back') : t('misc.cancel')}
            variant="secondary"
          />
          <Button
            key="yes"
            text={t(`costCenters.importModal.uploadButton`)}
            variant="primary"
            onClick={() => {
              if (!costCenterFile) {
                setErrorMissingFile(true);
                return;
              }
              onUpload(costCenterFile);
            }}
          />
        </>
      }
    >
      <div className="my-l">
        <div className="CostCenterImportModal__step title-m ">
          <Badge variant="primary">1</Badge>
          {t('costCenters.importModal.instructionsDownload')}
        </div>
        <div className="mb-l mt-m">
          <Button
            component="a"
            href={downloadLink}
            text={t('costCenters.importModal.downloadButton')}
            variant="secondary"
          />
        </div>
        <div className="CostCenterImportModal__step title-m ">
          <Badge variant="primary">2</Badge>
          {t('costCenters.importModal.instructionsUpload')}
        </div>
        <div
          className={cx('CostCenterImportModal__file_container', {
            CostCenterImportModal__file_container_error: errorMissingFile,
          })}
        >
          {!costCenterFile ? (
            <>
              <Upload
                data-testid="import-cost-center-file-upload"
                activeDragContent={
                  <>
                    {t('costCenters.importModal.uploadFiles.activeDragContent')}
                  </>
                }
                content={
                  <Trans i18nKey="costCenters.importModal.uploadFiles.content">
                    <br />-<span className="Upload__link">-</span>
                  </Trans>
                }
                onUpload={(files) => {
                  setErrorMissingFile(false);
                  setCostCenterFile(files[0]);
                }}
              />
              {errorMissingFile && (
                <Callout
                  className="mt-xs"
                  title={t('costCenters.importModal.errorMissingFile')}
                  variant="alert"
                />
              )}
            </>
          ) : (
            <FileCard
              data-testid="import-cost-center-file-preview"
              title={costCenterFile.name}
              onDelete={() => setCostCenterFile(undefined)}
            />
          )}
        </div>
        <div className="mt-m">
          <Callout
            iconName="info"
            variant="info"
            title={t('costCenters.importModal.approvalFlowCallout.title')}
          >
            {t('costCenters.importModal.approvalFlowCallout.content')}
          </Callout>
        </div>
      </div>
    </Modal>
  );
};
