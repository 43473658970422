import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import {
  fromCamelToSnakeCase,
  fromSnakeToCamelCase,
} from 'src/core/common/utils/string';

import {
  type SupplierDetailsField,
  type SupplierDetailsChange,
} from '../../../models';

export const useFetchSupplierDetailsHistory = (
  supplierId: string,
  fields?: SupplierDetailsField[],
): QueryState<SupplierDetailsChange[]> => {
  return useQuery<RawSupplierDetailsChange[]>({
    key: ['suppliers', supplierId, 'supplier-details-history'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      params: {
        fields: fields ? fields.map(fromCamelToSnakeCase) : [],
      },
      endpoint: `/suppliers/${supplierId}/supplier-details-history`,
    },
    reshapeData,
  });
};

type RawSupplierDetailsChange = SupplierDetailsChange & {
  field: string;
};

function reshapeData(
  data: RawSupplierDetailsChange[],
): SupplierDetailsChange[] {
  return data.map((change) => {
    return {
      ...change,
      field: fromSnakeToCamelCase(change.field) as SupplierDetailsField,
    };
  });
}
