export const REQUEST_MFA_PROCEDURE_SUCCESS = 'REQUEST_MFA_PROCEDURE_SUCCESS';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type REQUEST_MFA_PROCEDURE_SUCCESS =
  typeof REQUEST_MFA_PROCEDURE_SUCCESS;

export const REQUEST_MFA_PROCEDURE_LOADING = 'REQUEST_MFA_PROCEDURE_LOADING';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type REQUEST_MFA_PROCEDURE_LOADING =
  typeof REQUEST_MFA_PROCEDURE_LOADING;

export const REQUEST_MFA_PROCEDURE_ERROR = 'REQUEST_MFA_PROCEDURE_ERROR';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type REQUEST_MFA_PROCEDURE_ERROR = typeof REQUEST_MFA_PROCEDURE_ERROR;

export const VALIDATE_MFA_TOKEN_LOADING = 'VALIDATE_MFA_TOKEN_LOADING';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VALIDATE_MFA_TOKEN_LOADING = typeof VALIDATE_MFA_TOKEN_LOADING;

export const VALIDATE_MFA_TOKEN_ERROR = 'VALIDATE_MFA_TOKEN_ERROR';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VALIDATE_MFA_TOKEN_ERROR = typeof VALIDATE_MFA_TOKEN_ERROR;

export const VALIDATE_MFA_TOKEN_SUCCESS = 'VALIDATE_MFA_TOKEN_SUCCESS';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VALIDATE_MFA_TOKEN_SUCCESS = typeof VALIDATE_MFA_TOKEN_SUCCESS;

export const TOGGLE_WIRETRANSFER_ACTIVATION_LOADING =
  'TOGGLE_WIRETRANSFER_ACTIVATION_LOADING';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TOGGLE_WIRETRANSFER_ACTIVATION_LOADING =
  typeof TOGGLE_WIRETRANSFER_ACTIVATION_LOADING;

export const TOGGLE_WIRETRANSFER_ACTIVATION_ERROR =
  'TOGGLE_WIRETRANSFER_ACTIVATION_ERROR';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TOGGLE_WIRETRANSFER_ACTIVATION_ERROR =
  typeof TOGGLE_WIRETRANSFER_ACTIVATION_ERROR;

export const TOGGLE_WIRETRANSFER_ACTIVATION_SUCCESS =
  'TOGGLE_WIRETRANSFER_ACTIVATION_SUCCESS';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TOGGLE_WIRETRANSFER_ACTIVATION_SUCCESS =
  typeof TOGGLE_WIRETRANSFER_ACTIVATION_SUCCESS;

export const RESET_TOKEN_VERIFICATION_STATES =
  'RESET_TOKEN_VERIFICATION_STATES';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RESET_TOKEN_VERIFICATION_STATES =
  typeof RESET_TOKEN_VERIFICATION_STATES;

export const REGENERATE_PROCEDURE_LOADING = 'REGENERATE_PROCEDURE_LOADING';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type REGENERATE_PROCEDURE_LOADING = typeof REGENERATE_PROCEDURE_LOADING;

export const REGENERATE_PROCEDURE_ERROR = 'REGENERATE_PROCEDURE_ERROR';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type REGENERATE_PROCEDURE_ERROR = typeof REGENERATE_PROCEDURE_ERROR;

export const REGENERATE_PROCEDURE_SUCCESS = 'REGENERATE_PROCEDURE_SUCCESS';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type REGENERATE_PROCEDURE_SUCCESS = typeof REGENERATE_PROCEDURE_SUCCESS;

export const FETCH_PHONE_FACTOR_LOADING = 'FETCH_PHONE_FACTOR_LOADING';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FETCH_PHONE_FACTOR_LOADING = typeof FETCH_PHONE_FACTOR_LOADING;

export const FETCH_PHONE_FACTOR_ERROR = 'FETCH_PHONE_FACTOR_ERROR';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FETCH_PHONE_FACTOR_ERROR = typeof FETCH_PHONE_FACTOR_ERROR;

export const FETCH_PHONE_FACTOR_SUCCESS = 'FETCH_PHONE_FACTOR_SUCCESS';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FETCH_PHONE_FACTOR_SUCCESS = typeof FETCH_PHONE_FACTOR_SUCCESS;
