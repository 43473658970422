import React, { type ReactNode } from 'react';

import styles from './SettingsLayout.module.css';

type SettingsLayoutProps = {
  children: ReactNode;
};

export const SettingsLayout = ({ children }: SettingsLayoutProps) => {
  return <div className={styles.grid}>{children}</div>;
};
