import type {
  UserTask,
  UserCompanyTask,
  UserTaskType,
} from 'modules/onboarding/setup-hub/types/userTask';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { logger } from 'src/utils/datadog-log-wrapper';

type Payload = {
  endpointParams: { taskType: UserTaskType };
};

export const useCompleteOnboardingUserTaskMutation = () => {
  return useMutation<Payload, UserTask, UserTask>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: ({ taskType }) => `user-tasks/${taskType}/complete-by-type`,
      method: 'post',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
      onSuccess: ({ client, rawData }) => {
        client.setQueryData<UserCompanyTask[]>(
          ['onboardingUserTasks'],
          (tasks) => {
            if (!tasks) {
              return [];
            }
            return tasks.map((task) =>
              task.userTaskId === rawData.id
                ? { ...task, userTask: rawData }
                : task,
            );
          },
        );
      },
      onError: ({ error }) => {
        if (error.type !== 'NetworkError' && error.status !== 401) {
          logger.error('Error while marking user onboarding task as complete', {
            scope: 'ready-to-spend',
            team: 'growth',
            stack: error.stack,
          });
        }
      },
    },
  });
};
