import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

import { NavLink } from '../NavLink/NavLink';

type Props = {
  company: {
    id: string;
  };
};

export const PurchaseOrderLink = ({ company }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavLink
      href={routeFor(routes.PURCHASE_ORDERS.path, {
        company: company.id,
      })}
      iconName="document"
    >
      {t('misc.purchaseOrders')}
    </NavLink>
  );
};
