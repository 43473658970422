import { DeprecatedModalSlideshow } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import Slide1En from './slide-1.en.svg';
import Slide1Fr from './slide-1.fr.svg';
import Slide2En from './slide-2.en.svg';
import Slide2Fr from './slide-2.fr.svg';
import Slide3En from './slide-3.en.svg';
import Slide3Fr from './slide-3.fr.svg';
import Slide4En from './slide-4.en.svg';
import Slide4Fr from './slide-4.fr.svg';
import Slide5En from './slide-5.en.svg';
import Slide5Fr from './slide-5.fr.svg';

type Props = {
  onCloseModal(): void;
  onDone(): void;
  doneText: string;
};

export const BudgetSetupGuideModalContainer = ({
  onCloseModal,
  onDone,
  doneText,
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const isLanguageFr = activeLanguage === 'fr';
  return (
    <DeprecatedModalSlideshow
      slides={[
        {
          title: t('budget.onboarding.slide.one.title'),
          content: t('budget.onboarding.slide.one.content'),
          illustration: <img src={isLanguageFr ? Slide1Fr : Slide1En} alt="" />,
        },
        {
          title: t('budget.onboarding.slide.two.title'),
          content: t('budget.onboarding.slide.two.content'),
          illustration: <img src={isLanguageFr ? Slide2Fr : Slide2En} alt="" />,
        },
        {
          title: t('budget.onboarding.slide.three.title'),
          content: t('budget.onboarding.slide.three.content'),
          illustration: <img src={isLanguageFr ? Slide3Fr : Slide3En} alt="" />,
        },
        {
          title: t('budget.onboarding.slide.four.title'),
          content: t('budget.onboarding.slide.four.content'),
          illustration: <img src={isLanguageFr ? Slide4Fr : Slide4En} alt="" />,
        },
        {
          title: t('budget.onboarding.slide.five.title'),
          content: t('budget.onboarding.slide.five.content'),
          illustration: <img src={isLanguageFr ? Slide5Fr : Slide5En} alt="" />,
        },
      ]}
      isOpen
      translations={{
        cancel: t('budget.onboarding.cancel'),
        previous: t('budget.onboarding.previous'),
        next: t('budget.onboarding.next'),
        done: doneText,
      }}
      onCancel={onCloseModal}
      onClose={onCloseModal}
      onDone={onDone}
    />
  );
};
