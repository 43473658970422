import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { postResetPaymentPassword } from 'modules/cards/api/postResetPaymentPassword';
import { scaProcedureErrors } from 'modules/cards/models/sca';
import FEATURES from 'src/core/constants/features';

import glassHourGeorges from './glassHourGeorges.svg';
import { useGetPaymentPasswordProcedure } from '../../hooks/useGetPaymentPasswordProcedure';
import mobileImage from '../assets/mobile.png';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  password?: string;
};

export const SfsUkPasswordModal = ({ isOpen, onClose, password }: Props) => {
  const { t } = useTranslation('global');
  const hasScaUkEnabled = useFeature(FEATURES.TMP_SCA_PASSWORD_UK);
  const [procedureId, setProcedureId] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const { passwordToShow, procedureFailedReason, setProcedureFailedReason } =
    useGetPaymentPasswordProcedure(procedureId, () =>
      setIsResettingPassword(false),
    );

  const handleSCAResetPassword = async () => {
    try {
      setIsResettingPassword(true);
      const response = await postResetPaymentPassword();
      setProcedureId(response.procedureId);
    } catch {
      setProcedureFailedReason('initializationError');
      setIsResettingPassword(false);
    }
  };

  if (isResettingPassword || procedureFailedReason) {
    return (
      <Modal
        illustration={<img src={mobileImage} alt="" />}
        illustrationHeight="400px"
        title={t('cards.otpPasswordModal.titleReset')}
        isOpen={isOpen}
        onClose={onClose}
        actions={[
          <Button
            key="confirm"
            onClick={onClose}
            text={t('cards.otpPasswordModal.cancel')}
            variant="primary"
          />,
        ]}
      >
        {procedureId && !procedureFailedReason && (
          <p className="-mt-s">{t('cards.otpPasswordModal.subtitleReset')}</p>
        )}
        {procedureFailedReason && (
          <Callout
            title={t(scaProcedureErrors[procedureFailedReason])}
            variant="alert"
          />
        )}
      </Modal>
    );
  }

  return (
    <Modal
      illustration={<img src={glassHourGeorges} alt="" />}
      illustrationHeight="338px"
      // @ts-expect-error Grapes hacking time because Georges's arm is hanging out
      title={<div className="-mt-m">{t('requests.sfsUkModal.title')}</div>}
      subtitle={t('requests.sfsUkModal.description')}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <div key="ctas" className="flex flex-col items-center">
          <Button
            onClick={onClose}
            text={t('requests.sfsUkModal.cta')}
            variant="primary"
          />
          {hasScaUkEnabled && (
            <Button
              variant="ghost"
              text={t('requests.sfsUkModal.resetPasswordCta')}
              onClick={handleSCAResetPassword}
              className="mt-xs"
            />
          )}
        </div>,
      ]}
    >
      <div className="flex flex-col items-center">
        <div className="w-fit rounded-xs bg-neutral-lightest px-m py-xxs text-complementary body-xxl">
          {passwordToShow ?? password}
        </div>
      </div>
    </Modal>
  );
};
