import { TextInput } from '@dev-spendesk/grapes';
import React, { type ChangeEvent, useRef } from 'react';

export type Token = string[];

const deleteKeys = ['Backspace', 'Delete'];
const digitOrEmptyRegex = /^\d*$/;
const isDigitOrEmpty = (value: string) => digitOrEmptyRegex.test(value);

type TokenInputProps = {
  token: Token;
  tokenLength: number;
  hasError?: boolean;
  onTokenChange: (value: Token) => void;
};

export const TokenInput = ({
  token,
  tokenLength,
  hasError,
  onTokenChange,
}: TokenInputProps) => {
  const inputReferences = useRef<Array<HTMLInputElement | null>>([]);

  const changeDigit = (index: number, value: string) => {
    const newToken = [...token];
    newToken[index] = value;

    onTokenChange(newToken);
  };

  const deleteDigit = (index: number) => {
    const isCurrentInputEmpty = token[index] === '';
    const inputIndexToDelete =
      isCurrentInputEmpty && index > 0 ? index - 1 : index;
    changeDigit(inputIndexToDelete, '');
    changeFocus(inputIndexToDelete);
  };

  const changeFocus = (index: number) => {
    const inputElement = inputReferences.current[index];

    if (inputElement) {
      inputElement.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const data = event.clipboardData.getData('text/plain');
    if (isDigitOrEmpty(data)) {
      const newToken = data.split('');
      onTokenChange(newToken);
      changeFocus(Math.min(data.length, tokenLength - 1));
    }
  };

  const onInputChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const digitValue = event.target.value;
      const nextInputIndexToFocus = index + 1 < tokenLength ? index + 1 : index;

      if (isDigitOrEmpty(digitValue)) {
        changeDigit(index, digitValue);
        changeFocus(nextInputIndexToFocus);
      }
    };

  const onInputKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      const isDeleting = deleteKeys.includes(event.key);
      if (isDeleting) {
        deleteDigit(index);
      }
    };

  return (
    <div className="flex gap-xs">
      {Array.from({ length: tokenLength }, (_, index) => (
        <TextInput
          key={index}
          className="w-l"
          ref={(element) => {
            inputReferences.current[index] = element;
          }}
          onChange={onInputChange(index)}
          onKeyDown={onInputKeyDown(index)}
          value={token[index]}
          maxLength={1}
          onPaste={handlePaste}
          fit="content"
          isInvalid={hasError}
          textAlign="center"
        />
      ))}
    </div>
  );
};
