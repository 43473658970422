import { type MonetaryValue } from 'ezmoney';

import { useTranslation } from 'common/hooks/useTranslation';
import { type PerDiem } from 'src/core/modules/per-diem';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

type Props = {
  perDiem: PerDiem | null;
  amount: MonetaryValue;
};

export const PerDiemDetailsHeader = ({ perDiem, amount }: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="flex w-full items-center justify-between">
      <span
        data-testid="PerDiemDetails-amount"
        className="text-complementary body-xxl"
      >
        {formatMonetaryValue(amount)}
      </span>
      {perDiem && (
        <span className="text-neutral-dark body-xl">
          {perDiem.tripDays.length}&nbsp;
          {perDiem.tripDays.length > 1 ? (
            <span data-testid="PerDiemDetails-days">
              {t('expenseInbox.days')}
            </span>
          ) : (
            <span data-testid="PerDiemDetails-day">
              {t('expenseInbox.day')}
            </span>
          )}
        </span>
      )}
    </div>
  );
};
