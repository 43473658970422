import { apiV2Url } from 'src/core/utils/api';

export type SuccessResponse = {
  data: {
    status: string;
  };
};

export const postUpdateManagerAssociation = async ({
  companyId,
  userId,
  managerId,
}: {
  companyId: string;
  userId: string;
  managerId: string | null;
}): Promise<SuccessResponse> => {
  const response = await fetch(
    apiV2Url(`/users/${userId}/manager-associations`, companyId),
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: { managerId } }),
    },
  );

  if (response.status === 200) {
    return response.json();
  }

  throw new Error('Updating manager association failed');
};
