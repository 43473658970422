import {
  Timeline as GrapesTimeline,
  TimelineItem,
  type TimelineProps,
} from '@dev-spendesk/grapes';
import React, { useMemo } from 'react';

import { reverseChronologicalDateComparator } from "src/core/utils/date";

export type TimelineEvent = {
  key: string;
  date: Date;
  content: JSX.Element | string;
  status?: 'neutral' | 'pending' | 'alert' | 'success';
};

type Props = {
  events: TimelineEvent[];
} & Omit<TimelineProps, 'children'>;

export const Timeline = ({ events, ...rest }: Props) => {
  const sortedEvents = useMemo(
    () =>
      events.sort(({ date: dateA }, { date: dateB }) =>
        reverseChronologicalDateComparator(dateA, dateB),
      ),
    [events],
  );

  return (
    <GrapesTimeline {...rest}>
      {sortedEvents.map((event) => (
        <TimelineItem date={event.date} key={event.key}>
          {event.content}
        </TimelineItem>
      ))}
    </GrapesTimeline>
  );
};
