import { Button, Callout, Link } from '@dev-spendesk/grapes';
import React from 'react';
import { Link as NavLink } from 'react-router-dom';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useBookkeepingSettings } from 'modules/bookkeep/apis/useBookkeepingSettings';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { LocalStorageKey } from 'src/core/constants/storage';
import { useUserRoles } from 'src/core/modules/app/hooks/useUserRoles';

import { isEditAfterExportPromoActive } from './edit-after-export-promo';

type Props = {
  isPayableAccountable: boolean;
};

export const PayableEditAfterExportPromoContainer = ({
  isPayableAccountable,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const { isAccountOwner, isController } = useUserRoles();
  const isAccountOwnerOrController = isController || isAccountOwner;

  const [hasSeenCallout, setHasSeenCallout] = React.useState(
    localStorage.getItem(
      LocalStorageKey.EditAfterExportPromoCalloutDismissed,
    ) === 'true',
  );

  const dismissCallout = () => {
    localStorage.setItem(
      LocalStorageKey.EditAfterExportPromoCalloutDismissed,
      'true',
    );
    setHasSeenCallout(true);
  };

  const hasEditPayablesAfterExportFeature = useFeature(
    FEATURES.TMP_EDIT_PAYABLES_AFTER_EXPORT,
  );

  const bookkeepingSettingsQueryResult = useBookkeepingSettings({
    isEnabled: hasEditPayablesAfterExportFeature,
  });

  const bookkeepingSettings = unwrapQuery(bookkeepingSettingsQueryResult);

  if (
    !isAccountOwnerOrController ||
    hasSeenCallout ||
    !isEditAfterExportPromoActive(new Date()) ||
    isPayableAccountable ||
    !bookkeepingSettings ||
    bookkeepingSettings.editPayablesAfterExport.hasOptedIn ||
    bookkeepingSettings.editPayablesAfterExport.wasOptedIn
  ) {
    return null;
  }

  return (
    <>
      <Callout
        iconName="sparkles"
        title={t('payables.panel.callouts.editAfterExportPromoTitle')}
        variant="primary"
      >
        <Link
          as={NavLink}
          to={routeFor(routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path, {
            company: companyId,
          })}
        >
          <Button
            text={t('payables.panel.callouts.editAfterExportPromoLink')}
            fit="parent"
          />
        </Link>
        <Button
          text={t('payables.panel.callouts.editAfterExportPromoDismiss')}
          className="mt-xs"
          variant="contrasted"
          fit="parent"
          onClick={dismissCallout}
        />
      </Callout>
    </>
  );
};
