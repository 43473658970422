import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { CREATE_SUPPLIER } from '../queries/createSupplier';

type Payload = {
  supplier: {
    name: string;
    accountHolderName: string;
    bankDetails: {
      bankCountry: string;
      iban?: string;
      bicSwift?: string;
      sortCode?: string;
      routingNumber?: string;
      accountNumber?: string;
      accountCode?: string;
      bankAccountDetailsFile?: string | null;
    };
    legalDetails: {
      legalName: string;
    };
  };
};

type RawResponse = {
  createSupplier: {
    reason?: string;
    id?: string;
  };
};

type Response = {
  createSupplier: {
    reason?: string;
    id?: string;
  };
};

export const useCreateSupplierMutation = (): MutationState<
  Payload,
  Response
> => {
  return useMutation<Payload, Response, RawResponse>({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: CREATE_SUPPLIER,
    },
    reshapeData: (data) => {
      return data;
    },
  });
};
