import { SwitchField } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications, NotificationType } from 'modules/app/notifications';

import { useUpdateNotificationMutation } from '../../hooks/useUpdateNotificationMutation';
import { type UserNotification } from '../../models';
import { AccountFundingReminderContainer } from '../AccountFundingReminder';

type Props = {
  notification: UserNotification;
};

export const UserNotificationSetting = ({ notification }: Props) => {
  const { t } = useTranslation();
  const { pushNotif } = useNotifications();

  const [updateNotification] = useUpdateNotificationMutation();

  const showAccountFundingReminder =
    notification.notifCode === 'accountFundingReminder';

  return (
    <>
      <SwitchField
        id={notification.notifCode}
        fit="parent"
        isChecked={notification.checked}
        label={t(`notifications:${notification.title}`)}
        helpText={t(`notifications:${notification.user}`)}
        onChange={async (e) => {
          try {
            await updateNotification({
              notif_code: notification.notifCode,
              checked: e.target.checked,
              value: notification.value ?? null,
            });
          } catch {
            pushNotif({
              type: NotificationType.Danger,
              message: t('notifications.editFailureNotification'),
            });
            return;
          }

          pushNotif({
            type: NotificationType.Success,
            message: t('notifications.editSuccessNotification'),
          });
        }}
      />
      {showAccountFundingReminder && (
        <AccountFundingReminderContainer notification={notification} />
      )}
    </>
  );
};
