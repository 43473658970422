import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import DashboardIllustration from './assets/dashboard-illustration.svg';
import { useExternalUrlsQuery } from '../../hooks/useExternalUrlsQuery';
import { PlaceholderBox } from '../PlaceholderBox';

export const DashboardTipBox = () => {
  const externalUrlsQueryState = useExternalUrlsQuery();

  const { t } = useTranslation('global');

  return (
    <QuerySuspense
      queryState={externalUrlsQueryState}
      loading={<PlaceholderBox />}
      fallback={(queryError) => (
        <QueryError componentType="Callout" queryError={queryError} />
      )}
    >
      {(externalUrls) => {
        return (
          <div className="full-h box relative flex flex-col overflow-hidden bg-primary-lightest">
            <div className="full-h flex flex-col">
              <div className="flex max-w-[264px] flex-col content-stretch gap-xxs">
                <h3 className="text-primary title-l">
                  {t('homepage.tips.dataDashboardTitle')}
                </h3>
                <p className="leading-[20px] text-complementary body-m">
                  {t('homepage.tips.dataDashboard.text')}
                </p>
              </div>
              <img
                width="410"
                height="188"
                alt=""
                src={DashboardIllustration}
                className="absolute left-[55%] top-[50%] translate-y-[-50%]"
              />
              <div className="mt-s">
                <Button
                  variant="contrasted"
                  onClick={() =>
                    track(AnalyticEventName.HOMEPAGE_TIPS_CLICKED, {
                      tip: 'data_dashboard',
                    })
                  }
                  component="a"
                  href={externalUrls.controlDashboard}
                  text={t('homepage.tips.dataDashboard.cta')}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </div>
            </div>
          </div>
        );
      }}
    </QuerySuspense>
  );
};
