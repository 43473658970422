import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type Team, type TeamRaw } from '../models/teams';
import { reshapeTeam } from '../utils/reshapeTeam';

export const useTeamsQuery = (): QueryState<Team[], object> => {
  return useQuery<Team[], TeamRaw[]>({
    key: ['groups'],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/groups',
    },
    reshapeData: (data) => data.map(reshapeTeam),
  });
};
