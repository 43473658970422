import { useParams } from 'react-router-dom';

import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type DisableApiKeyPayload = { endpointParams: { id: string } };

export const useDisableApiKey = (): MutationState<
  DisableApiKeyPayload,
  object
> => {
  const { company: companyId } = useParams();

  return useMutation<DisableApiKeyPayload, object, object>({
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: ({ id }) =>
        `/${companyId}/public-api-configuration/api-keys/disable/${id}`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }) => {
        return client.invalidateQueries(['apiKeysList', companyId]);
      },
    },
    reshapeData: () => ({}),
  });
};
