import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';
import {
  type Translations,
  useQueryError,
} from 'src/core/common/components/QueryError';

export type SendMissingDocumentaryEvidencesReminderError =
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | { reason: 'unauthorized' | 'bookkeepingApiNotFound' | 'nothingToRemind' };

export type SendMissingDocumentaryEvidencesReminderResponse = {
  reminderSent: true;
};

export type SendMissingDocumentaryEvidencesReminderPayload = {
  userId: string;
  subject: string;
  body: string;
};

/**
 * Mutation
 **/

export const useSendMissingDocumentaryEvidencesReminder = () => {
  const companyId = useCompanyId();

  return useMutation<
    SendMissingDocumentaryEvidencesReminderPayload,
    SendMissingDocumentaryEvidencesReminderResponse,
    SendMissingDocumentaryEvidencesReminderResponse,
    SendMissingDocumentaryEvidencesReminderError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint:
        '/documentary-evidence/send-missing-documentary-evidences-reminder',
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client, payload }) => {
        const filters = {
          payers: { ids: [payload.userId] },
          state: ['created', 'unprepared'],
          documentaryEvidenceStatus: 'not_provided',
        };

        client.invalidateQueries(['usePayablesQuery', companyId, filters]);
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};

/**
 * Error messages
 **/

export const sendMissingDocumentaryEvidencesReminderErrorTranslations: Translations<SendMissingDocumentaryEvidencesReminderError> =
  {
    requestError: ({ reason }) => {
      switch (reason) {
        default:
          return 'documentaryEvidence.api.sendMissingDocumentaryEvidencesReminder.error';
      }
    },
    serverError:
      'documentaryEvidence.api.sendMissingDocumentaryEvidencesReminder.error',
  };

export const useSendMissingDocumentaryEvidencesReminderErrorMessage = () => {
  const getErrorMessage =
    useQueryError<SendMissingDocumentaryEvidencesReminderError>(
      sendMissingDocumentaryEvidencesReminderErrorTranslations,
    );

  return (
    queryError: QueryError<SendMissingDocumentaryEvidencesReminderError>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    translationParams?: {},
  ): string => getErrorMessage(queryError, translationParams);
};
