import identity from 'lodash/identity';

import { type Beneficiary } from 'modules/invoices/transfer/models';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type Error = unknown;

export type Payload = {
  scheduledPaymentIds: string[];
};

export type Response = Beneficiary.VerificationResult[];

export const useVerifyExpenseTransfersBeneficiariesQuery = (): MutationState<
  Payload,
  Response,
  Error
> => {
  return useMutation<Payload, Response, Response, Error>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/scheduled_payments/verify-beneficiaries`,
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
    reshapeData: identity,
  });
};
