import isEmpty from 'lodash/isEmpty';

import { type CustomFieldFiltersType } from 'modules/custom-fields';

export interface InvoicesPayFiltersTeamValue {
  key: string;
  label: string;
}

export interface InvoicesPayFiltersSupplierValue {
  key: string;
  label: string;
}

export interface InvoicesPayFiltersCustomFieldValue {
  key: string;
  label: string;
}

export interface InvoicesPayFiltersCostCenterValue {
  key: string;
  label: string;
}

export interface InvoicesPayFiltersPeriod {
  to: string;
  from: string;
}

export interface InvoicesPayFiltersValues {
  teams: InvoicesPayFiltersTeamValue[];
  suppliers: InvoicesPayFiltersSupplierValue[];
  customFields: CustomFieldFiltersType;
  costCenters: InvoicesPayFiltersCostCenterValue[];
  period: InvoicesPayFiltersPeriod | undefined;
}

export interface InvoicesFiltersApi {
  groups: InvoicesPayFiltersTeamValue[];
  suppliers: InvoicesPayFiltersSupplierValue[];
  customFieldsValues: InvoicesPayFiltersCustomFieldValue[];
  costCenters: InvoicesPayFiltersCostCenterValue[];
  from: string | undefined;
  to: string | undefined;
}

export const countActiveFilters = (
  selectedFilters: InvoicesPayFiltersValues,
): number =>
  Object.values(selectedFilters).reduce(
    (numberOfActiveFilters, filter) =>
      isEmpty(filter) ? numberOfActiveFilters : ++numberOfActiveFilters,
    0,
  );
