import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const SpendingLimitReachedMessage = () => {
  const { t } = useTranslation();

  return (
    <Callout
      className="mt-m"
      variant="info"
      title={t(
        'control.approvalPolicyWidget.spendingLimitReachedMessage.title',
      )}
    >
      <span>
        {t(
          'control.approvalPolicyWidget.spendingLimitReachedMessage.description',
        )}
      </span>
    </Callout>
  );
};
