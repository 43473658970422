// FIXME: the configuration and the sections/notifs to display should come from
// the backend

import FEATURES from 'src/core/constants/features';
import type Features from 'src/core/constants/features';

export type Notification = {
  checked?: boolean;
  user?: string;
  company?: string;
  userShow: ('owner' | 'controller' | 'admin' | 'requester')[];
  featuresShow?: Features[];
  category?: 'General' | 'Control' | 'Usage' | 'Receipt reminders';
  title?: string;
  notif_code: string;
  every_date?: number;
  below?: boolean;
  above?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  labelValue?: string;
};

export type Notifications = {
  [key: string]: Notification;
};

export const defaultNotifications: Notifications = {
  weeklyDigest: {
    checked: true,
    user: 'weeklyDigest.user',
    userShow: ['owner', 'controller', 'admin', 'requester'],
    category: 'General',
    title: 'weeklyDigest.title',
    notif_code: 'weeklyDigest',
  },
  notifyApprovalRightsApprover: {
    checked: false,
    company: 'notifyApprovalRightsApprover.company',
    user: 'notifyApprovalRightsApprover.user',
    userShow: ['owner', 'controller', 'admin'],
    category: 'General',
    title: 'notifyApprovalRightsApprover.title',
    notif_code: 'notifyApprovalRightsApprover',
  },
  userCreated: {
    checked: true,
    title: 'userCreated.title',
    user: 'userCreated.user',
    userShow: ['admin', 'owner'],
    category: 'Control',
    notif_code: 'userCreated',
  },
  cardPhysicalCreatedControl: {
    checked: true,
    userShow: ['owner', 'controller', 'admin'],
    category: 'Control',
    title: 'cardPhysicalCreatedControl.title',
    user: 'cardPhysicalCreatedControl.user',
    notif_code: 'cardPhysicalCreatedControl',
    featuresShow: [FEATURES.CARDS_ACCESS, FEATURES.PLASTIC_CARDS],
  },
  cardPhysicalStatusControl: {
    checked: true,
    user: 'cardPhysicalStatusControl.user',
    userShow: ['owner', 'controller', 'admin'],
    category: 'Control',
    title: 'cardPhysicalStatusControl.title',
    notif_code: 'cardPhysicalStatusControl',
    featuresShow: [FEATURES.CARDS_ACCESS, FEATURES.PLASTIC_CARDS],
  },
  invoiceUploaded: {
    checked: false,
    user: 'invoiceUploaded.user',
    category: 'Control',
    userShow: ['owner', 'controller'],
    title: 'invoiceUploaded.title',
    notif_code: 'invoiceUploaded',
  },
  invoiceMissing: {
    checked: true,
    user: 'invoiceMissing.user',
    category: 'Control',
    userShow: ['owner', 'controller'],
    title: 'invoiceMissing.title',
    notif_code: 'invoiceMissing',
  },
  walletRefilled: {
    checked: true,
    company: 'walletRefilled.company',
    user: 'walletRefilled.user',
    userShow: ['owner', 'controller'],
    category: 'Control',
    title: 'walletRefilled.title',
    notif_code: 'walletRefilled',
    featuresShow: [FEATURES.WALLET_ACCESS],
  },
  subscriptionRenewal: {
    checked: false,
    user: 'subscriptionRenewal.description',
    userShow: ['requester'],
    category: 'Usage',
    title: 'subscriptionRenewal.title',
    notif_code: 'subscriptionRenewal',
    featuresShow: [FEATURES.SUBSCRIPTION_RECURRENCE],
  },
  autoReminders: {
    checked: true,
    company: 'autoReminders.company',
    user: 'autoReminders.user',
    userShow: ['owner', 'controller'],
    title: 'autoReminders.title',
    category: 'Receipt reminders',
    notif_code: 'autoReminders',
  },
  accountFundingError: {
    checked: true,
    // title: 'insufficient funds',
    // user: "A payment fails because there is not enough funds on the Spendesk Wallet",
    // userShow: ['owner', 'controller'],
    userShow: [],
    // category: 'Control',
    notif_code: 'accountFundingError',
    every_date: 604_800,
    featuresShow: [FEATURES.WALLET_ACCESS],
  },
  accountFundingReminder: {
    checked: true,
    below: true,
    value: 1000,
    labelValue: 'Balance',
    user: 'accountFundingReminder.user',
    title: 'accountFundingReminder.title',
    userShow: ['controller', 'owner'],
    category: 'Control',
    notif_code: 'accountFundingReminder',
    every_date: 604_800,
    featuresShow: [FEATURES.WALLET_ACCESS],
  },
  // 'accountStatement': {
  //   checked: true,
  //   user: "A new monthly statement is now available for your company",
  //   category: 'Control',
  //   userShow: ['owner', 'controller'],
  //   notif_code: 'accountStatement'
  // },
  requestStatus: {
    checked: true,
    title: 'requestStatus.title',
    user: 'requestStatus.user',
    userShow: ['requester'],
    category: 'Usage',
    notif_code: 'requestStatus',
  },
  cardPhysicalCreatedRequester: {
    checked: true,
    userShow: [], // only trigger once in a lifetime so useless to show to anyone
    category: 'Usage',
    title: 'cardPhysicalCreatedRequester.title',
    user: 'cardPhysicalCreatedRequester.user',
    notif_code: 'cardPhysicalCreatedRequester',
    featuresShow: [FEATURES.CARDS_ACCESS, FEATURES.PLASTIC_CARDS],
  },
  cardPhysicalStatusRequester: {
    checked: true,
    user: 'cardPhysicalStatusRequester.user',
    userShow: ['requester'],
    category: 'Usage',
    title: 'cardPhysicalStatusRequester.title',
    notif_code: 'cardPhysicalStatusRequester',
    featuresShow: [FEATURES.CARDS_ACCESS, FEATURES.PLASTIC_CARDS],
  },
  requestExpired: {
    checked: true,
    title: 'requestExpired.title',
    user: 'requestExpired.user',
    userShow: ['requester'],
    category: 'Usage',
    notif_code: 'requestExpired',
    featuresShow: [FEATURES.CARDS_ACCESS],
  },
  paymentNewPhysicalCard: {
    checked: false,
    title: 'paymentNewPhysicalCard.title',
    user: 'paymentNewPhysicalCard.user',
    userShow: ['requester'],
    category: 'Usage',
    notif_code: 'paymentNewPhysicalCard',
    featuresShow: [FEATURES.CARDS_ACCESS, FEATURES.PLASTIC_CARDS],
  },
  paymentStatusRequester: {
    checked: true,
    // company: 'Notify users when they make a new payment or when one of their payments status is updated',
    // user: "You make a new payment or one of your payments status is updated",
    // category: 'Usage',
    // labelValue: 'Min. Amount',
    // userShow: ['requester'],
    userShow: [],
    above: true,
    value: 0,
    notif_code: 'paymentStatusRequester',
  },
  accountStatementCreated: {
    checked: true,
    userShow: ['owner', 'controller'],
    category: 'Control',
    title: 'accountStatementCreated.title',
    user: 'accountStatementCreated.user',
    notif_code: 'accountStatementCreated',
  },
  invoiceReminder: {
    checked: true,
    title: 'invoiceReminder.title',
    // user: "Someone in your company or your team reminds you to upload an invoice",
    // userShow: ['requester'],
    userShow: [],
    // category: 'Usage',
    notif_code: 'invoiceReminder',
  },
  invoiceCollectEmailFailed: {
    checked: true,
    userShow: [],
    notif_code: 'invoiceCollectEmailFailed',
  },
  debug: {
    checked: true,
    userShow: [],
    notif_code: 'debug',
  },
  subscriptionCardReloadPlanified: {
    checked: true,
    userShow: [],
    notif_code: 'subscriptionCardReloadPlanified',
  },
  accountFundingReloadCardsSuccess: {
    checked: true,
    userShow: [],
    notif_code: 'accountFundingReloadCardsSuccess',
  },
  passwordRecovery: {
    checked: true,
    userShow: [],
    notif_code: 'passwordRecovery',
  },
  passwordChanged: {
    checked: true,
    userShow: [],
    notif_code: 'passwordChanged',
  },
  inviteUser: {
    checked: true,
    userShow: [],
    notif_code: 'inviteUser',
  },
  inviteUserReminder1: {
    checked: true,
    userShow: [],
    notif_code: 'inviteUserReminder1',
  },
  inviteUserReminder2: {
    checked: true,
    userShow: [],
    notif_code: 'inviteUserReminder2',
  },
  inviteUserReminder3: {
    checked: true,
    userShow: [],
    notif_code: 'inviteUserReminder3',
  },
  companyCreated: {
    checked: true,
    userShow: [],
    notif_code: 'companyCreated',
  },
  accountCreated: {
    checked: true,
    userShow: [],
    notif_code: 'accountCreated',
  },
  default: {
    checked: true,
    userShow: [],
    notif_code: 'default',
  },
};
