import { useParams } from 'react-router-dom';

import { useLazyQuery } from 'src/core/api/hooks/useLazyQuery';
import { type LazyState } from 'src/core/api/queryState';
import {
  type ExpenseAccountRawData,
  reshapeExpenseAccountData,
} from 'src/core/modules/bookkeep/accounts-payable/hooks/useGetExpenseAccountsQuery';
import { GET_EXPENSE_ACCOUNTS_WITH_ARCHIVED_FIELD } from 'src/core/modules/bookkeep/accounts-payable/queries/getExpenseAccounts';
import { type ExpenseAccount } from 'src/core/modules/bookkeep/accounts-payable/types';

export const useGetExpenseAccountLazyQuery = (
  { includeArchived }: { includeArchived?: boolean } = {
    includeArchived: true,
  },
): LazyState<ExpenseAccount[]> => {
  const { company: companyId } = useParams();

  return useLazyQuery<ExpenseAccount[], ExpenseAccountRawData>({
    key: ['useGetExpenseAccountLazyQuery', companyId, includeArchived],
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_EXPENSE_ACCOUNTS_WITH_ARCHIVED_FIELD,
      variables: {
        filter: { includeArchived },
      },
    },
    reshapeData: reshapeExpenseAccountData,
  });
};
