import { type MonetaryValue } from 'ezmoney';

import type { CreditNoteId } from '@finance-review/models/credit-note';
import {
  DraftInvoiceRequest,
  type InvoiceRequest,
} from '@finance-review/models/invoice';
import { type QueryState } from 'src/core/api/queryState';

import { useFetchDraftInvoiceRequest } from './useFetchDraftInvoiceRequest';

export const useInvoiceRequestAttachedCreditQuery = (
  invoiceRequestId: InvoiceRequest.EntityId,
): QueryState<
  | {
      appliedCredit: MonetaryValue;
      appliedCreditNoteIds: CreditNoteId[];
    }
  | undefined
> => {
  const fetchDraftInvoiceRequestQueryState =
    useFetchDraftInvoiceRequest(invoiceRequestId);

  if (fetchDraftInvoiceRequestQueryState.status === 'success') {
    const { draftInvoiceRequest } = fetchDraftInvoiceRequestQueryState.data;

    return {
      ...fetchDraftInvoiceRequestQueryState,
      data: DraftInvoiceRequest.hasAppliedCredit(draftInvoiceRequest)
        ? {
            appliedCredit: draftInvoiceRequest.appliedCredit,
            appliedCreditNoteIds: draftInvoiceRequest.appliedCreditNoteIds,
          }
        : undefined,
    };
  }

  return fetchDraftInvoiceRequestQueryState;
};
