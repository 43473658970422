import { useMutation } from 'src/core/api/hooks/useMutation';

import { useInvalidateControlRulesQuery } from './useControlRulesQuery';
import { useInvalidateMemberQuery } from './useMemberQuery';

type Payload = {
  userIds: string[];
};

export const useBulkUnassignControlRuleMutation = () => {
  const invalidateMembers = useInvalidateMemberQuery();
  const invalidateControlRules = useInvalidateControlRulesQuery();
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: 'control-rules/unassign',
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ payload }): void => {
        invalidateMembers(payload.userIds);
        invalidateControlRules();
      },
    },
  });
};
