import React from 'react';

import { InvoiceReviewFormSkeleton } from '@finance-review/components/invoice/InvoiceReviewFormContainer/InvoiceReviewFormSkeleton';
import { InvoiceReviewListSkeleton } from '@finance-review/components/invoice/InvoiceReviewList/InvoiceReviewListSkeleton';

import './InvoicesToReviewPage.css';

export const InvoicesToReviewPageSkeleton = () => {
  return (
    <div className="ToReview__invoice-table">
      <InvoiceReviewListSkeleton />
      <InvoiceReviewFormSkeleton />
    </div>
  );
};
