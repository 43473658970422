import { useIntegrationStatusQuery } from 'modules/bookkeep/hooks';
import { reverseChargeApplicableCountries } from 'modules/bookkeep/utils/countries';

export const useDisplayReverseChargeQuery = (): boolean => {
  const integrationStatusQuery = useIntegrationStatusQuery();

  const integrationStatus =
    (integrationStatusQuery.status === 'success' &&
      integrationStatusQuery.data.integration !== 'noIntegration' &&
      integrationStatusQuery.data.integration !== 'switchInProgress' &&
      integrationStatusQuery.data) ||
    undefined;

  const hasTaxAccountsCapability =
    integrationStatus?.capabilities.taxAccounts !== undefined;

  const accountingCountry = integrationStatus?.accountingCountry;

  return (
    hasTaxAccountsCapability &&
    !!accountingCountry &&
    reverseChargeApplicableCountries.includes(accountingCountry)
  );
};
