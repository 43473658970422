import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { COUNTRIES } from 'src/core/config/country';
import { type CurrenciesKey } from 'src/core/config/money';
import { getBankInfoFieldsCombo } from 'src/core/utils/bankInfoFormats';

import { type Supplier } from '../supplier';

const buildSupplierAddress = (supplier: Supplier): string | undefined => {
  if (!supplier.details.address) {
    return;
  }

  const addressLine1 = supplier.details.address;
  const addressLine2 =
    supplier.details.zipcode && supplier.details.city
      ? `, ${supplier.details.zipcode} ${supplier.details.city}`
      : '';
  const country =
    supplier.details.country && COUNTRIES[supplier.details.country]
      ? `, ${COUNTRIES[supplier.details.country].name}`
      : '';

  return `${addressLine1}${addressLine2}${country}`;
};

export const buildSupplierInfoItems = ({
  supplier,
  translator,
}: {
  supplier: Supplier;
  translator: TGlobalFunctionTyped;
}) => {
  let companyInfoItems: {
    label: string;
    value: string;
  }[] = [
    {
      label: translator('submitMyInvoice.fields.supplier.legalName'),
      value: supplier.details.legalName || '-',
    },
    {
      label: translator('submitMyInvoice.fields.registrationNumber.labelShort'),
      value: supplier.details.registrationNumber || '-',
    },
  ];

  if (supplier.details.country !== 'US') {
    companyInfoItems = [
      ...companyInfoItems,
      {
        label: translator('submitMyInvoice.fields.vatNumber.label'),
        value: supplier.details.vatNumber || '-',
      },
    ];
  }

  companyInfoItems = [
    ...companyInfoItems,
    {
      label: translator('submitMyInvoice.supplier.companyAddress'),
      value: buildSupplierAddress(supplier) || '-',
    },
  ];

  return companyInfoItems;
};

export const buildSupplierBankInfoItems = ({
  supplier,
  companyCountry,
  companyCurrency,
  translator,
}: {
  supplier: Supplier;
  companyCountry: string;
  companyCurrency: CurrenciesKey;
  translator: TGlobalFunctionTyped;
}) => {
  const { bankDetails } = supplier.details;
  const bankInfoFields = getBankInfoFieldsCombo(
    companyCountry,
    bankDetails.country,
    companyCurrency,
  );
  const fieldTypes = {
    iban: {
      label: translator('submitMyInvoice.fields.iban.label'),
      value: bankDetails.iban || '-',
    },
    bic_swift: {
      label: translator('submitMyInvoice.fields.bic_swift.label'),
      value: bankDetails.bic || '-',
    },
    sort_code: {
      label: translator('submitMyInvoice.fields.sortCode.label'),
      value: bankDetails.sortCode || '-',
    },
    account_code: {
      label: translator('submitMyInvoice.fields.accountCode.label'),
      value: bankDetails.accountCode || '-',
    },
    account_number: {
      label: translator('submitMyInvoice.fields.accountNumber.label'),
      value: bankDetails.accountNumber || '-',
    },
    routing_number: {
      label: translator('submitMyInvoice.fields.routingNumber.label'),
      value: bankDetails.routingNumber || '-',
    },
    account_holder_name: {
      label: translator('submitMyInvoice.fields.accountHolderName.label'),
      value: supplier.details.accountHolderName || '',
    },
  };

  const bankInfoItems: {
    label: string;
    value: string;
  }[] = [
    {
      label: translator(
        'submitMyInvoice.fields.supplierBankCountry.labelShort',
      ),
      value:
        supplier.details.bankDetails.country &&
        COUNTRIES[supplier.details.bankDetails.country]
          ? COUNTRIES[supplier.details.bankDetails.country].name
          : '-',
    },
    ...bankInfoFields.map((field) => {
      return fieldTypes[field as keyof typeof fieldTypes]; // FIXME: should not typecast
    }),
  ];

  return bankInfoItems;
};
