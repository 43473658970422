import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function useBlockNavigation(condition: boolean): {
  shouldConfirmNavigation: boolean;
  cancelNavigation: () => void;
  confirmNavigation: () => void;
} {
  const history = useHistory();
  const locationReference = useRef(null);
  const unblockReference = useRef(() => {});
  const [shouldConfirmNavigation, setShouldConfirmNavigation] = useState(false);
  const cancelNavigation = (): void => {
    setShouldConfirmNavigation(false);
  };
  const confirmNavigation = (): void => {
    setShouldConfirmNavigation(false);
    unblockReference.current();
    history.push(locationReference.current);
  };

  useEffect(() => {
    unblockReference.current = condition
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        history.block((location: any) => {
          setShouldConfirmNavigation(true);
          locationReference.current = location;
          return false;
        })
      : () => {};

    return (): void => {
      unblockReference.current();
    };
  }, [condition]);

  return { shouldConfirmNavigation, cancelNavigation, confirmNavigation };
}
