import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { GET_SUPPLIERS } from '../queries/getSuppliers';

type RawData = {
  company: {
    suppliers: {
      edges: {
        node: {
          name: string;
        };
      }[];
    };
  };
};

export const useGetSupplierLowerCaseNamesQuery = (): QueryState<
  Set<string>
> => {
  const { company: companyId } = useParams();

  return useQuery<Set<string>, RawData>({
    key: ['useGetSupplierLowerCaseNamesQuery', companyId],
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_SUPPLIERS,
    },
    reshapeData: (rawData) => {
      const { edges } = rawData.company.suppliers;
      return new Set(edges.map(({ node }) => node.name.toLowerCase()));
    },
  });
};
