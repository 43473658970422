import { RejectPayablesApi } from '@finance-review/apis/payable';
import { type MutationState } from 'src/core/api/hooks/useMutation';

import { useInvalidateExpenses } from './useExpensesQuery';
import { useFetchLegacyExpenseClaimCounts } from './useFetchLegacyExpenseClaimCounts';

export type Payload = RejectPayablesApi.Payload;
export type Response = RejectPayablesApi.Response;

export const useMutation = (
  options: {
    onError?(): void;
    onSuccess?(): void;
    onComplete?(): void;
  } = {},
): MutationState<Payload, Response, unknown> => {
  const legacyFetchCount = useFetchLegacyExpenseClaimCounts();
  const invalidateExpenses = useInvalidateExpenses();

  return RejectPayablesApi.useMutation({
    onSuccess: options.onSuccess,
    onComplete: () => {
      legacyFetchCount();
      invalidateExpenses();
      options.onComplete?.();
    },
    onError: options.onError,
  });
};
