import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import { useCostCentersQuery } from '../../../apis';
import type { BudgetaryExercise } from '../../../models/budgetaryExercise';
import type { CostCenter } from '../../../models/costCenter';
import { useBudgetaryExercisesQuery } from '../../hooks/useBudgetaryExercisesQuery';

export const usePageDataQuery = (): QueryState<{
  costCenters: CostCenter[];
  budgetaryExercises: BudgetaryExercise[];
}> => {
  return useQueryStates({
    states: {
      costCenters: useCostCentersQuery({ withDeleted: true }),
      budgetaryExercises: useBudgetaryExercisesQuery(),
    },
    reshapeData: ({ costCenters, budgetaryExercises }) => {
      const enrichedBudgetaryExercises = budgetaryExercises.map(
        (budgetaryExercise) => {
          return {
            ...budgetaryExercise,
            budgets: budgetaryExercise.budgets.map((budget) => {
              const costCenter = costCenters.find(
                (cc) => cc.id === budget.costCenterId,
              );
              return {
                ...budget,
                name: costCenter?.name ?? '',
                isUnavailable: costCenter?.isDeleted,
              };
            }),
          };
        },
      );
      return {
        costCenters,
        budgetaryExercises: enrichedBudgetaryExercises,
      };
    },
  });
};
