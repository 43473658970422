import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { NavigationLink } from 'src/core/common/components/NavigationLink';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
    is_organisation_owner: boolean;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
};

const getDefaultSettingsRoute = (
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
    is_organisation_owner: boolean;
  },
  company: {
    churning_at: string | null;
  },
  features: {
    hasAccessToWallet: boolean;
  },
) => {
  let defaultSettingsRoute: string = routes.ACCOUNT_ME_PROFILE.path;

  // Different destination according to the user role
  if (user.is_account_owner) {
    defaultSettingsRoute = routes.COMPANY_BILLING_PLAN.path;
  } else if (user.is_admin) {
    defaultSettingsRoute = routes.COMPANY_MEMBERS.path;
  } else if (user.is_controller) {
    defaultSettingsRoute = features.hasAccessToWallet
      ? routes.COMPANY_BANK.path
      : routes.COMPANY_BILLING_PLAN.path;
  }

  // For churning companies, OO, AO & Controllers
  // are redirected to the "company billing" settings page
  if (
    company.churning_at &&
    (user.is_organisation_owner || user.is_account_owner || user.is_controller)
  ) {
    defaultSettingsRoute = routes.COMPANY_BILLING_PLAN.path;
  }

  return defaultSettingsRoute;
};

export const SettingsLink = ({
  company,
  user,
  className,
  isInDropdown,
  onClick,
}: Props) => {
  const { t } = useTranslation('global');
  const hasAccessToWallet = useFeature(FEATURES.WALLET_ACCESS);
  const features = {
    hasAccessToWallet,
  };
  const { pathname: currentPath } = useLocation();

  return (
    <NavigationLink
      data-marketing="top-menu_settings"
      key="nav_settings"
      className={className}
      onClick={onClick}
      path={routeFor(getDefaultSettingsRoute(user, company, features), {
        company: company.id,
      })}
      text={t('misc.setting_plural')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [
            routes.COMPANY_BILLING.path,
            routes.COMPANY_GENERAL_SETTINGS.path,
            routes.COMPANY_MEMBERS.path,
            routes.COMPANY_TEAMS.path,
            routes.COMPANY_POLICIES.path,
            routes.COMPANY_CONTROL_RULES.path,
            routes.COMPANY_BANK.path,
            routes.COMPANY_ACCOUNTING.path,
            routes.COMPANY_ACCOUNTS_PAYABLE.path,
            routes.COMPANY_EXPORTS.path,
            routes.COMPANY_CATEGORIES.path,
            routes.BUDGETS.path,
            routes.BUDGETARY_EXERCISES.path,
            routes.COST_CENTERS.path,
            routes.COMPANY_INTEGRATIONS.path,
          ],
        }),
      )}
      isInDropdown={isInDropdown}
    />
  );
};
