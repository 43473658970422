import { Callout, Tag, Button, Upload, FileCard } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { useSendBulkInvitesMutation } from 'modules/members/hooks/useSendBulkInvitesMutation';
import { type InvitationResult } from 'modules/members/models/invite';
import { getBulkImportErrorReasons } from 'modules/members/utils/fileImportErrorMessages';
import { useParams } from 'src/core/common/hooks/useParams';
import appConfig from 'src/core/config';
import { routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  handleInviteCreationResult: (result: InvitationResult) => void;
  onClose: () => void;
};

export const MemberInviteFileContent = ({
  handleInviteCreationResult,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');
  const [bulkImportFile, setBulkImportFile] = useState<File>();
  const { company: companyId }: { company: string } = useParams(
    routes.COMPANY_MEMBERS.path,
  );
  const [sendBulkInvites, sendBulkInvitesMutationState] =
    useSendBulkInvitesMutation();

  const handleFileUpload = (files: File[]) => {
    setBulkImportFile(files[0]);
    track(AnalyticEventName.SETTINGS_MEMBERS_INVITE_BULK_TEMPLATE_UPLOADED);
  };

  const uploadInviteFile = async () => {
    if (bulkImportFile) {
      const formData = new FormData();
      formData.append('memberInvites', bulkImportFile, bulkImportFile.name);
      try {
        const result = await sendBulkInvites(formData);
        if (result.success) {
          handleInviteCreationResult({
            isSuccessful: true,
            numInvited: result.invitedNumber,
          });
        } else {
          handleInviteCreationResult({
            isSuccessful: false,
            type: 'file_import',
            messages: getBulkImportErrorReasons(result, t),
          });
        }
      } catch (error) {
        const messages = error.data
          ? getBulkImportErrorReasons(error.data, t)
          : [
              {
                text: t('members.fileUploadErrors.unknownError'),
                isError: true,
              },
            ];
        handleInviteCreationResult({
          isSuccessful: false,
          type: 'file_import',
          messages,
        });
      }
    } else {
      handleInviteCreationResult({
        isSuccessful: false,
        type: 'file_import',
        messages: [
          { text: t('members.fileUploadErrors.fileEmpty'), isError: true },
        ],
      });
    }
  };

  const removeUploadedFile = () => {
    setBulkImportFile(undefined);
  };

  return (
    <>
      <Callout
        className="mt-s"
        title={t('members.fileUpload.instructions')}
        variant="info"
        iconName="info"
      />
      <section>
        <div className="mt-m flex items-start gap-s">
          <Tag variant="primary" isRounded>
            1
          </Tag>
          <span className="text-left text-neutral-darker title-m">
            {t('members.fileUpload.step1')}
          </span>
        </div>
        <Button
          className="mt-s"
          text={t('members.fileUpload.downloadTemplate')}
          variant="secondary"
          component="a"
          href={`${appConfig.apiUrls.api}/${companyId}/invites/template`}
        />
      </section>
      <section>
        <div className="mt-m flex items-start gap-s">
          <Tag variant="primary" isRounded>
            2
          </Tag>
          <span className="text-left text-neutral-darker title-m">
            {t('members.fileUpload.step2', {
              count: appConfig.maxBulkImportMembers,
            })}
          </span>
        </div>
      </section>
      <section>
        <div className="mt-m flex items-start gap-s">
          <Tag variant="primary" isRounded>
            3
          </Tag>
          <span className="text-left text-neutral-darker title-m">
            {t('members.fileUpload.step3')}
          </span>
        </div>
        {bulkImportFile ? (
          <FileCard
            className="mt-m"
            title={bulkImportFile.name}
            onDelete={removeUploadedFile}
          />
        ) : (
          <Upload
            className="mt-s"
            activeDragContent={t('forms.uploadFiles.activeDragContent')}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            data-testid="bulk-import-file-upload"
            content={
              <Trans i18nKey="forms.uploadFiles.content">
                <br />-<span>-</span>
              </Trans>
            }
            onUpload={handleFileUpload}
          />
        )}
      </section>
      <div className="mt-l flex justify-center gap-m">
        <Button variant="secondary" text={t('misc.cancel')} onClick={onClose} />
        <Button
          text={t('members.fileUpload.startImport')}
          isLoading={sendBulkInvitesMutationState.status === 'loading'}
          isDisabled={!bulkImportFile}
          onClick={uploadInviteFile}
        />
      </div>
    </>
  );
};
