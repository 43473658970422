import { Callout } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import type { IntegrationError } from 'src/core/modules/integrations/types';

type Props = {
  integrationError: IntegrationError | undefined;
};

export const StatusCallout = ({ integrationError }: Props) => {
  const { t } = useTranslation('global');

  if (!integrationError) {
    return null;
  }

  switch (integrationError) {
    case 'notConnected':
      return (
        <Callout
          variant="alert"
          title={t('integration.kombo.settings.connectionErrorCallout.title')}
        >
          {t('integration.kombo.settings.connectionErrorCallout.description')}
        </Callout>
      );
    default:
      return null;
  }
};
