import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { type QueryState } from 'src/core/api/queryState';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { convertToCompanyCurrency } from 'src/core/utils/money';

import {
  type CurrencyRatesQueryError,
  useCurrencyRatesQuery,
} from '../useCurrencyRatesQuery';

interface ConversionDetails {
  convertedAmount: number;
  conversionRate: number;
}

export const useConvertToCompanyCurrencyQuery = ({
  date,
  amount,
  amountCurrency,
}: {
  date: Date;
  amount: number;
  amountCurrency: string;
}): QueryState<ConversionDetails, CurrencyRatesQueryError> => {
  const company = useCompany();
  const companyCurrency = useCompanyCurrency();
  const currencyRatesQueryState = useCurrencyRatesQuery({
    date,
    currency: companyCurrency,
  });

  if (currencyRatesQueryState.status === 'success') {
    const currencyRates = currencyRatesQueryState.data;
    return {
      ...currencyRatesQueryState,
      data: {
        convertedAmount: convertToCompanyCurrency(
          amount,
          amountCurrency,
          company,
          {
            currencyRates,
          },
        ),
        conversionRate: currencyRates[amountCurrency],
      },
    };
  }

  return currencyRatesQueryState;
};
