import { useInvalidateCostCentersQuery } from 'modules/budgets/apis';
import { useMutation } from 'src/core/api/hooks/useMutation';

import { useInvalidateMemberQuery } from './useMemberQuery';

type Payload = {
  userIds: string[];
  costCenterId: string | null;
};

export const useBulkUpdateCostCenterMutation = () => {
  const invalidateCostCenters = useInvalidateCostCentersQuery();
  const invalidateMembers = useInvalidateMemberQuery();

  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users/assign-cost-center`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ payload }): void => {
        invalidateCostCenters();
        invalidateMembers(payload.userIds);
      },
    },
  });
};
