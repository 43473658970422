import { isAfter } from 'date-fns';

import { useBookkeepingSettings } from 'modules/bookkeep/apis/useBookkeepingSettings';
import {
  getIsPayableExported,
  type PayableState,
} from 'modules/bookkeep/payables/models/payable';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { getLockDate } from 'src/core/modules/bookkeep/settings/bookkeeping/get-lock-date';

export const useIsLockedPayable = (payable?: {
  creationDate: Date;
  state: PayableState;
}): boolean => {
  const hasEditPayablesAfterExportFeature = useFeature(
    FEATURES.TMP_EDIT_PAYABLES_AFTER_EXPORT,
  );

  const bookkeepingSettingsQueryResult = useBookkeepingSettings({
    isEnabled: hasEditPayablesAfterExportFeature,
  });

  if (!payable) {
    return false;
  }

  const bookkeepingSettings = unwrapQuery(bookkeepingSettingsQueryResult);

  const isPayableExported = getIsPayableExported(payable);

  const payableLockDate = getLockDate(
    payable.creationDate,
    bookkeepingSettings?.editPayablesAfterExport?.lockAfter,
    bookkeepingSettings?.editPayablesAfterExport?.lockAfterUnit,
  );

  if (
    !hasEditPayablesAfterExportFeature ||
    !isPayableExported ||
    !bookkeepingSettings?.editPayablesAfterExport?.hasOptedIn ||
    !payableLockDate
  ) {
    return false;
  }

  return isAfter(new Date(), payableLockDate);
};
