import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { type FormValues } from './types';

export const validate = ({
  values,
  t,
}: {
  values: FormValues;
  t: TGlobalFunctionTyped;
}) => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.name.trim()) {
    errors.name = t('customFields.creation.nameRequired');
  }

  return errors;
};
