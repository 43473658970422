import { useSelector } from 'react-redux';

import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import {
  getWireTransferActivationStatusForExpenses,
  getWireTransferActivationStatusForInvoices,
} from 'modules/company/billing-legacy/redux';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import {
  isWireTransferFeatureAvailable,
  type WireTransferPayableEntity,
} from '../../../../models';

export const useShouldDisplayWireTransferPaymentMethod = () => {
  const { isAccountOwner } = useUserRoles();

  const isWireTransferForExpenseClaimsEnabled = useFeature(
    FEATURES.WIRE_TRANSFER_FOR_EXPENSE_CLAIMS,
  );

  const isWireTransferForInvoicesEnabled = useFeature(
    FEATURES.WIRE_TRANSFER_FOR_INVOICES,
  );

  const wireTransferActivationStatusForExpenses = useSelector(
    getWireTransferActivationStatusForExpenses,
  );

  const wireTransferActivationStatusForInvoices = useSelector(
    getWireTransferActivationStatusForInvoices,
  );

  return (wireTransferPayableEntity: WireTransferPayableEntity): boolean => {
    const isWireTransferFeatureEnabled =
      wireTransferPayableEntity === 'expenses'
        ? isWireTransferForExpenseClaimsEnabled
        : isWireTransferForInvoicesEnabled;
    const wireTransferActivationStatus =
      wireTransferPayableEntity === 'expenses'
        ? wireTransferActivationStatusForExpenses
        : wireTransferActivationStatusForInvoices;

    return Boolean(
      isWireTransferFeatureEnabled &&
        wireTransferActivationStatus &&
        isAccountOwner &&
        isWireTransferFeatureAvailable(wireTransferActivationStatus),
    );
  };
};
