import { Button, Callout, Popover, Tag } from '@dev-spendesk/grapes';
import React, { type ReactNode, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { DropdownSwitcherContent } from 'common/components/DropdownSwitcherContent';
import withErrorBoundary from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useModal } from 'common/hooks/useModal';
import { useModal as useModalGrapes } from 'common/hooks/useModalGrapes';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { CostCenterActivationModal } from 'modules/purchase-orders/pages/PurchaseOrdersPage/CostCenterActivationModal';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import styles from './BudgetaryExercisesPageContainer.module.css';
import { useIsUserAllowedToAccessBudgetaryExercise } from './hooks/useIsUserAllowedToAccessBudgetaryExercise';
import { usePageDataQuery } from './hooks/usePageDataQuery';
import { BudgetPageLoader } from '../../components/BudgetPageLoader';
import { BudgetaryExercisePage } from '../../components/BudgetaryExercisePage';
import { BudgetaryExercisePageEmptyState } from '../../components/BudgetaryExercisePageEmptyState';
import { ImportBudgetModalContainer } from '../../components/ImportBudgetModal';
import {
  type BudgetaryExercise,
  getCurrentOrDefaultBudgetaryExercise,
  getBudgetaryExerciseAmount,
  isActiveBudgetaryExercise,
  isFutureBudgetaryExercise,
  isPastBudgetaryExercise,
} from '../../models/budgetaryExercise';
import { type PeriodRange } from '../../models/period';
import { BudgetSetupGuideModalContainer } from '../BudgetSetupGuideModalContainer';
import { CreateBudgetaryExerciseModalContainer } from '../CreateBudgetaryExerciseModalContainer';
import { DeleteBudgetaryExerciseModalContainer } from '../DeleteBudgetaryExerciseModalContainer';
import { EditBudgetaryExerciseModalContainer } from '../EditBudgetaryExerciseModalContainer';
import { useRedirectToBudget } from '../hooks/useRedirectToBudget';
import { useRedirectToBudgetaryExercise } from '../hooks/useRedirectToBudgetaryExercise';

export const BudgetaryExercisesPageContainer = withErrorBoundary({
  team: 'budget-owner',
  scope: 'budgetary-exercises',
})(() => {
  return (
    <div className="page__container">
      <BudgetaryExercisesPageContent />
    </div>
  );
});

const BudgetaryExercisesPageContent = () => {
  const { t } = useTranslation('global');
  const location = useLocation();
  const isCostCentersActivated = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const { isAdmin, isAccountOwner, isController } = useUserRoles();
  const { id: selectedBudgetaryExerciseId, company: companyId } = useParams();
  const isUserAllowedToAccessBudgetaryExercise =
    useIsUserAllowedToAccessBudgetaryExercise(selectedBudgetaryExerciseId);
  const history = useHistory();
  const redirectToBudgetaryExercise = useRedirectToBudgetaryExercise();
  const redirectToBudget = useRedirectToBudget();
  const pageDataQueryState = usePageDataQuery();

  const [selectedPeriodRange, setSelectedPeriodRange] = useState<PeriodRange>(
    location.state?.selectedPeriodRange ?? {
      key: 'currentMonth',
    },
  );

  useEffect(() => {
    if (
      selectedBudgetaryExerciseId &&
      isUserAllowedToAccessBudgetaryExercise.status === 'success' &&
      isUserAllowedToAccessBudgetaryExercise.data === false
    ) {
      history.replace(routeFor(routes.HOMEPAGE.path, { company: companyId }));
      return;
    }

    // if there is no budgetary exercise ID in the URL, push the active one
    if (
      pageDataQueryState.status === 'success' &&
      pageDataQueryState.data.budgetaryExercises.length > 0 &&
      !selectedBudgetaryExerciseId
    ) {
      const currentOrDefaultBudgetaryExercise =
        getCurrentOrDefaultBudgetaryExercise(
          pageDataQueryState.data.budgetaryExercises,
        );

      if (!currentOrDefaultBudgetaryExercise) {
        return;
      }

      const budgetsFromAllBudgetaryExercises =
        pageDataQueryState.data.budgetaryExercises.flatMap(
          ({ budgets }) => budgets,
        );

      // if there's only one visible budget overall, and it's in the current exercise,
      // we redirect to overview page of this budget
      const shouldRedirectToBudgetOverviewPage =
        budgetsFromAllBudgetaryExercises.length === 1 &&
        currentOrDefaultBudgetaryExercise.budgets.length === 1 &&
        !(isAdmin || isAccountOwner || isController);

      if (shouldRedirectToBudgetOverviewPage) {
        redirectToBudget(currentOrDefaultBudgetaryExercise.budgets[0].id);
      } else {
        redirectToBudgetaryExercise(currentOrDefaultBudgetaryExercise.id);
      }
    }
  }, [
    isUserAllowedToAccessBudgetaryExercise,
    pageDataQueryState,
    selectedBudgetaryExerciseId,
  ]);

  const [
    budgetaryExerciseCreationModal,
    showBudgetaryExerciseCreationModal,
    hideBudgetaryExerciseCreationModal,
  ] = useModalGrapes(({ isOpen }: { isOpen: boolean }) => {
    if (!isOpen) {
      return <></>;
    }
    return (
      <CreateBudgetaryExerciseModalContainer
        onHide={() => {
          hideBudgetaryExerciseCreationModal();
        }}
      />
    );
  });

  const [
    budgetaryExerciseEditionModal,
    showBudgetaryExerciseEditionModal,
    hideBudgetaryExerciseEditionModal,
  ] = useModal(
    ({ budgetaryExercise }: { budgetaryExercise: BudgetaryExercise }) => {
      return (
        <EditBudgetaryExerciseModalContainer
          budgetaryExercise={budgetaryExercise}
          onHide={() => hideBudgetaryExerciseEditionModal()}
        />
      );
    },
  );

  const [
    budgetaryExerciseDeletionConfirmationModal,
    showBudgetaryExerciseDeletionConfirmationModal,
    hideBudgetaryExerciseDeletionConfirmationModal,
  ] = useModal(
    ({ budgetaryExercise }: { budgetaryExercise: BudgetaryExercise }) => {
      return (
        <DeleteBudgetaryExerciseModalContainer
          budgetaryExercise={budgetaryExercise}
          onHide={() => hideBudgetaryExerciseDeletionConfirmationModal()}
        />
      );
    },
  );

  const [budgetSetupGuideModal, showSetupGuideModal, hideSetupGuideModal] =
    useModalGrapes(
      ({
        isOpen,
        hasCostCenters,
      }: {
        isOpen: boolean;
        hasCostCenters: boolean;
      }) => {
        if (!isOpen) {
          return <> </>;
        }
        return (
          <BudgetSetupGuideModalContainer
            onCloseModal={() => hideSetupGuideModal()}
            doneText={
              hasCostCenters
                ? t('budget.onboarding.defineBudgetaryPeriod')
                : t('budget.onboarding.defineCostCenters')
            }
            onDone={() => {
              if (!hasCostCenters) {
                history.push(
                  routeFor(routes.COST_CENTERS.path, {
                    company: companyId,
                  }),
                );
                return;
              }
              hideSetupGuideModal();
              showBudgetaryExerciseCreationModal();
            }}
          />
        );
      },
    );

  const [
    createBudgetExpenseCategoriesModal,
    showCreateBudgetExpenseCategoriesModal,
    hideCreateBudgetExpenseCategoriesModal,
  ] = useModal(
    ({ budgetaryExercise }: { budgetaryExercise: BudgetaryExercise }) => {
      return (
        <ImportBudgetModalContainer
          type="create"
          onSuccess={() => hideCreateBudgetExpenseCategoriesModal()}
          budgetaryExerciseId={budgetaryExercise.id}
          onExit={() => hideCreateBudgetExpenseCategoriesModal()}
        />
      );
    },
  );

  const [
    editBudgetExpenseCategoriesModal,
    showEditBudgetExpenseCategoriesModal,
    hideEditBudgetExpenseCategoriesModal,
  ] = useModal(
    ({ budgetaryExercise }: { budgetaryExercise: BudgetaryExercise }) => {
      return (
        <ImportBudgetModalContainer
          type="edit"
          onSuccess={() => hideEditBudgetExpenseCategoriesModal()}
          budgetaryExerciseId={budgetaryExercise.id}
          onExit={() => hideEditBudgetExpenseCategoriesModal()}
        />
      );
    },
  );

  const [
    costCenterActivationModal,
    showCostCenterActivationModal,
    hideCostCenterActivationModal,
  ] = useModalGrapes(({ isOpen }: { isOpen: boolean }) => {
    return (
      <CostCenterActivationModal
        isOpen={isOpen}
        title={t('purchaseOrders.activation.modal.title', {
          name: t('purchaseOrders.activation.modal.budget'),
        })}
        description={t('purchaseOrders.activation.modal.description', {
          name: t('purchaseOrders.activation.modal.budget'),
        })}
        onClose={() => hideCostCenterActivationModal()}
      />
    );
  });

  if (
    selectedBudgetaryExerciseId &&
    isUserAllowedToAccessBudgetaryExercise.status === 'error'
  ) {
    return (
      <Callout
        variant="alert"
        title={t('budget.budgetaryExercise.errors.unknown')}
        className="mx-xs my-0"
      />
    );
  }

  if (
    (selectedBudgetaryExerciseId &&
      isUserAllowedToAccessBudgetaryExercise.status === 'loading') ||
    pageDataQueryState.status !== 'success'
  ) {
    return <BudgetPageLoader />;
  }

  const {
    data: { costCenters, budgetaryExercises },
  } = pageDataQueryState;

  const availableCostCenters = costCenters.filter((cc) => !cc.isDeleted);

  if (budgetaryExercises.length === 0) {
    return (
      <>
        <BudgetaryExercisePageEmptyState
          onActionClick={() => {
            if (isCostCentersActivated) {
              showSetupGuideModal({
                isOpen: true,
                hasCostCenters: availableCostCenters.length > 0,
              });
            } else {
              showCostCenterActivationModal();
            }
          }}
        />
        {budgetSetupGuideModal}
        {costCenterActivationModal}
        {budgetaryExerciseCreationModal}
      </>
    );
  }

  if (!selectedBudgetaryExerciseId) {
    return <BudgetPageLoader />;
  }

  const budgetaryExercise = budgetaryExercises.find(
    (be) => be.id === selectedBudgetaryExerciseId,
  );
  const otherBudgetaryExercises = budgetaryExercises.filter(
    (be) => be.id !== selectedBudgetaryExerciseId,
  );

  if (!budgetaryExercise) {
    return <BudgetPageLoader />;
  }

  const getBudgetaryExerciseDropdownListItem = (
    exercise: BudgetaryExercise,
  ) => {
    const budgetaryExerciseAmount = getBudgetaryExerciseAmount(exercise);

    return {
      id: exercise.id,
      left: (
        <div className="mr-xs flex items-center">
          <span
            className="flex-initial overflow-hidden text-ellipsis whitespace-nowrap"
            data-testid="BudgetaryExerciseDropdownListItem"
          >
            {exercise.name}
          </span>
          {getTag(exercise, t)}
        </div>
      ),
      right: budgetaryExerciseAmount
        ? formatMonetaryValue(budgetaryExerciseAmount)
        : undefined,
    };
  };

  const getTitle = () => {
    return (
      <Popover
        className="CompanyDropdown"
        renderTrigger={(triggerProps) => {
          return (
            <Button
              {...triggerProps}
              variant="ghost"
              text={budgetaryExercise.name}
              className={styles.BudgetaryExercisesPageContainerButton}
              hasNegativeMargins
              data-testid="BudgetaryExercisesPageContainerButton"
            />
          );
        }}
      >
        {(closeDropdown) => {
          return (
            <DropdownSwitcherContent
              activeItem={getBudgetaryExerciseDropdownListItem(
                budgetaryExercise,
              )}
              items={otherBudgetaryExercises.map((otherBudgetaryExercise) => ({
                ...getBudgetaryExerciseDropdownListItem(otherBudgetaryExercise),
                onClick: () => {
                  setSelectedPeriodRange({ key: 'currentMonth' });
                  redirectToBudgetaryExercise(otherBudgetaryExercise.id);
                  closeDropdown();
                },
              }))}
              button={
                isAdmin || isAccountOwner
                  ? {
                      label: t('budget.budgetaryExercise.createButton'),
                      onClick: () => {
                        showBudgetaryExerciseCreationModal();
                        closeDropdown();
                      },
                    }
                  : undefined
              }
            />
          );
        }}
      </Popover>
    );
  };

  return (
    <>
      <BudgetaryExercisePage
        title={getTitle()}
        budgetaryExercise={budgetaryExercise}
        selectedPeriodRange={
          budgetaryExercise.periodicity === 'yearly'
            ? { key: 'overall' }
            : selectedPeriodRange
        }
        setSelectedPeriodRange={setSelectedPeriodRange}
        onEditBudgetaryExerciseClick={() =>
          showBudgetaryExerciseEditionModal({ budgetaryExercise })
        }
        onDeleteBudgetaryExerciseClick={() =>
          showBudgetaryExerciseDeletionConfirmationModal({ budgetaryExercise })
        }
        onCreateBudgetExpenseCategoriesClick={() => {
          showCreateBudgetExpenseCategoriesModal({ budgetaryExercise });
        }}
        onEditBudgetExpenseCategoriesClick={() =>
          showEditBudgetExpenseCategoriesModal({ budgetaryExercise })
        }
        costCenters={availableCostCenters}
      />
      {budgetaryExerciseCreationModal}
      {budgetaryExerciseEditionModal}
      {budgetaryExerciseDeletionConfirmationModal}
      {createBudgetExpenseCategoriesModal}
      {editBudgetExpenseCategoriesModal}
    </>
  );
};

const getTag = (
  budgetaryExercise: BudgetaryExercise,
  translator: TGlobalFunctionTyped,
): ReactNode | null => {
  if (isActiveBudgetaryExercise(budgetaryExercise)) {
    return (
      <Tag variant="info" className="ml-xs">
        {translator('budget.budgetaryExercise.active')}
      </Tag>
    );
  }
  if (isFutureBudgetaryExercise(budgetaryExercise)) {
    return (
      <Tag variant="neutral" className="ml-xs">
        {translator('budget.budgetaryExercise.future')}
      </Tag>
    );
  }
  if (isPastBudgetaryExercise(budgetaryExercise)) {
    return (
      <Tag variant="neutral" className="ml-xs">
        {translator('budget.budgetaryExercise.old')}
      </Tag>
    );
  }
  return null;
};
