const keys = ['travelperk'];

export const isTravelperkPayable = (payable: {
  description?: string;
  supplier?: {
    name: string;
  };
  allocations?: {
    settlement?: {
      transaction: {
        description: string;
      };
    };
  }[];
}) =>
  keys.some((key) => {
    const matchedDescription = (
      payable.description?.toLowerCase() || ''
    ).includes(key);
    const matchedSupplier = (
      payable?.supplier?.name.toLowerCase() || ''
    ).includes(key);
    const matchedTransaction = payable?.allocations
      ? payable.allocations.some((allocation) =>
          (
            allocation?.settlement?.transaction?.description.toLocaleLowerCase() ||
            ''
          ).includes(key),
        )
      : false;
    return matchedDescription || matchedSupplier || matchedTransaction;
  });
