/* eslint-disable promise/prefer-await-to-then */
import { createAction, type Dispatch } from '@reduxjs/toolkit';

import * as types from 'src/core/actionTypes/policies';
import { companyAPI } from 'src/core/api/axios';

import { type AppState } from '../reducers';

const fetchPoliciesLoading = createAction(types.FETCH_POLICIES_LOADING);
const fetchPoliciesSuccess = createAction(types.FETCH_POLICIES_SUCCESS);

export const fetchPolicies =
  () => (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getState().global.company?.id ?? '';

    dispatch(fetchPoliciesLoading());

    return companyAPI
      .get('/policies', { companyId })
      .then((response) => response.data)
      .then((policies) => dispatch(fetchPoliciesSuccess(policies)));
  };
