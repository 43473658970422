import { FormField } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import {
  SupplierAsyncAutocomplete,
  type SupplierAsyncAutocompleteProps,
  type SupplierAsyncAutocompletePropsWithSupplierCreation,
} from './SupplierAsyncAutocomplete';
import type { SupplierError, SupplierId } from '../models';

type OnChangeSupplier = ({
  supplierId,
  supplierName,
}: {
  supplierId: SupplierId | undefined;
  supplierName: string | undefined;
}) => void;

export type SupplierFormFieldProps = SupplierAsyncAutocompleteProps &
  SupplierAsyncAutocompletePropsWithSupplierCreation & {
    supplierId: SupplierId | undefined;
    supplierName: string | undefined;
    error?: SupplierError;
    onChangeSupplier: OnChangeSupplier;
    className?: string;
  };

export const SupplierFormField = ({
  error,
  supplierId,
  supplierName,
  onChangeSupplier,
  className,
  ...props
}: SupplierFormFieldProps) => {
  const { t } = useTranslation('global');

  const getMessageFromError = (errorMessage: SupplierError): string => {
    switch (errorMessage) {
      case 'notValidated':
        return t('submitMyInvoice.fields.supplier.error.notValidated');
      case 'required':
        return t('submitMyInvoice.fields.supplier.error.required');
      default:
        return t('submitMyInvoice.fields.supplier.error.required');
    }
  };

  return (
    <FormField
      label={t('forms.supplier.label')}
      alertMessage={error ? getMessageFromError(error) : undefined}
      className={className}
      visuallyHideLabel
    >
      <SupplierAsyncAutocomplete
        name="supplier"
        fit="parent"
        value={
          supplierName
            ? {
                key: supplierId ?? supplierName,
                label: supplierName,
              }
            : undefined
        }
        placeholder={t('forms.supplier.placeholder')}
        onSelect={(selectedSupplier) => {
          // the component has key === label when a new item is created. But in this case I want undefined for the key
          const selectedSupplierId =
            selectedSupplier?.key === selectedSupplier?.label
              ? undefined
              : selectedSupplier?.key;
          onChangeSupplier({
            supplierId: selectedSupplierId,
            supplierName: selectedSupplier?.label,
          });
        }}
        isInvoicePayable={false}
        showSupplierCreationWhenNew
        showUnarchiveButtonWhenArchived
        {...props}
      />
    </FormField>
  );
};
