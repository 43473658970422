import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as usersActions from 'src/core/actions/users';
import { COUNTRIES } from 'src/core/config/country';

import { NewCurrencyBranchFlow } from './NewCurrencyBranchFlow';
import {
  AccountChooserModal,
  type InitialSelectableAccountType,
} from './modals/AccountChooserModal/AccountChooserModal';
import { CannotCreateBranchModal } from './modals/CannotCreateBranchModal';
import { NewBranchEntityModal } from './modals/NewBranchEntityModal';
import { NoBankableEntityModal } from './modals/NoBankableEntityModal';
import OnboardingModal from './modals/OnboardingModal';
import { ACCOUNT_TYPES } from './models/accountTypes';
import { type CreatedEntity } from './utils/createBranch';
import { useCompany } from '../../../hooks/useCompany';

type Props = {
  onClose: () => void;
  refreshUserCompanies: () => void;
};

const NewBranchComponent = ({ onClose, refreshUserCompanies }: Props) => {
  const company = useCompany();

  const [selectedAccountType, setSelectedAccountType] =
    useState<InitialSelectableAccountType | null>(null);

  const [selectedCountry, setSelectedCountry] = useState('');

  const [isBranchCreationAvailable, setIsBranchCreationAvailable] =
    useState(true);

  const [createdCompany, setCreatedCompany] = useState<
    CreatedEntity | undefined
  >(undefined);

  const handleClose = () => {
    setSelectedCountry('');
    setSelectedAccountType(null);
    setCreatedCompany(undefined);
    onClose();
  };

  if (company.banking_provider === 'bankable') {
    return <NoBankableEntityModal onClose={onClose} />;
  }

  if (!selectedAccountType) {
    return (
      <AccountChooserModal
        onSelect={async (accountType) => {
          setSelectedAccountType(accountType);
        }}
        onCancel={() => handleClose()}
      />
    );
  }

  if (selectedAccountType === ACCOUNT_TYPES.ENTITY) {
    if (!selectedCountry) {
      return (
        <NewBranchEntityModal
          refreshUserCompanies={refreshUserCompanies}
          setCreatedCompany={setCreatedCompany}
          setIsBranchCreationAvailable={setIsBranchCreationAvailable}
          setSelectedCountry={setSelectedCountry}
          onCancel={() => setSelectedAccountType(null)}
          onClose={handleClose}
        />
      );
    }

    if (!isBranchCreationAvailable) {
      return (
        <CannotCreateBranchModal
          handleClose={() => setSelectedCountry('')}
          countryName={COUNTRIES[selectedCountry].name}
        />
      );
    }
  }

  if (selectedAccountType === ACCOUNT_TYPES.CURRENCY) {
    return (
      <NewCurrencyBranchFlow
        handleClose={() => setSelectedAccountType(null)}
        handleCloseTheWholeFlow={() => {
          handleClose();
        }}
        refreshUserCompanies={refreshUserCompanies}
      />
    );
  }

  if (createdCompany) {
    return (
      <OnboardingModal
        bankingProvider={createdCompany.banking_provider}
        companyId={createdCompany.id}
        onClose={handleClose}
      />
    );
  }

  return null;
};

const mapDispatchToProps = {
  refreshUserCompanies: usersActions.refreshUserCompanies,
};

export const NewBranch = connect(null, mapDispatchToProps)(NewBranchComponent);
