import appConfig from 'src/core/config';

/**
 * Composes an API URL according to a given path and a company ID
 *
 * @param  {String} path       The endpoint to compose
 * @param  {String} companyId  The company ID for which to compose the endpoint
 * @return {String}            A proper API URL for the desired path
 */
const apiUrl = (path: string, companyId: string | undefined): string => {
  if (!companyId) {
    throw new Error('Company not provided for the API URL');
  }

  return `${appConfig.apiUrls.api}/${companyId}${path}`;
};

const apiV2Url = (path: string, companyId: string = ''): string => {
  return `${appConfig.apiUrls.apiV2}${companyId ? `/${companyId}` : ''}${path}`;
};

/**
 * Execute a GraphQL request.
 *
 * @param {String} query The body of the GraphQL request
 * @param {Object} variables Key-Value object of request variables
 * @return {Promise} The promise of the HTTP request
 */
const graphql = (
  query: string,
  variables: unknown,
  endpoint = appConfig.apiUrls.graphqlV1,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> =>
  fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query, variables }),
  })
    // eslint-disable-next-line promise/prefer-await-to-then
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    // eslint-disable-next-line promise/prefer-await-to-then
    .then((json) => {
      if (json.errors && json.errors.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error = new Error('FunctionalError') as any;
        error.body = json.errors;
        throw error;
      }
      return json.data;
    });

export { apiUrl, apiV2Url, graphql };
