import { useParams } from 'react-router-dom';

import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { GET_SUPPLIERS_WITHOUT_ACCOUNT_PAYABLE } from '../queries/getSuppliersWithoutAccountPayable';
import { type Supplier } from '../types';

type RawData = {
  company: {
    suppliers: {
      totalCount: number;
      pageInfo: {
        hasNextPage: boolean;
      };
      edges: {
        cursor: string;
        node: {
          thumbnailUrl: string;
          name: string;
          id: string;
        };
      }[];
    };
  };
};

export const useGetSuppliersWithoutAccountPayableQuery = (
  accountPayableId: string,
  search: string,
  archivalStatus: 'current' | 'archived' | undefined,
): InfiniteQueryState<Supplier[]> => {
  const { company: companyId } = useParams();

  return useInfiniteQuery<Supplier, RawData>({
    key: ['useGetSuppliersWithoutAccountPayableQuery', companyId, search],
    isEnabled: accountPayableId === '-',
    getRequest: (cursor) => ({
      type: 'graphQL',
      target: 'v2',
      query: GET_SUPPLIERS_WITHOUT_ACCOUNT_PAYABLE,
      variables: {
        filter: {
          hasSupplierAccount: false,
          search,
          ...(archivalStatus === undefined
            ? {}
            : { isArchived: archivalStatus === 'archived' }),
        },
        after: cursor,
      },
    }),
    getNextPageParam: (data) => {
      const { edges, pageInfo } = data.company.suppliers;
      const lastEdge = edges.at(-1);

      if (pageInfo.hasNextPage && lastEdge) {
        return lastEdge.cursor;
      }
      return undefined;
    },
    reshapeData: (rawData) => {
      const { edges } = rawData.company.suppliers;

      return edges.map(({ node }) => ({
        supplierName: node.name,
        logosUrls: [node.thumbnailUrl],
        supplierId: node.id,
      }));
    },
  });
};
