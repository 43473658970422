import { Button, Panel } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';

import type { EligibleType } from 'common/components/CustomFieldsSelector/CustomFieldsSelector';
import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { useNotifications } from 'modules/app/notifications';
import type { CustomFieldAssociation } from 'modules/budgets/models/customFieldAssociation';
import { type RawCustomField } from 'modules/custom-fields/models/customField';
import { getEligibleCustomFields } from 'src/core/utils/custom-fields';

import { BulkEditPayments } from './BulkEditPayments';
import { type Payment } from './paymentType';
import {
  useBulkUpdatePaymentsMutation,
  type Payload,
} from '../../hooks/useBullkUpdatePaymentsMutation';

type Props = {
  payments: Payment[];
  groups: { id: string; name: string }[];
  customFields: RawCustomField[];
  onClose: () => void;
  onDone: () => void;
};

export const BulkEditPaymentsContainer = ({
  payments,
  groups,
  customFields,
  onClose,
  onDone,
}: Props) => {
  const { t } = useTranslation('global');
  const { successNotif } = useNotifications();

  const user = useUser();

  const [bulkUpdatePayments] = useBulkUpdatePaymentsMutation();

  const hasMultipleSuppliers =
    new Set(
      payments
        .map(
          (payment) =>
            (payment.supplier && payment.supplier.id) || payment.supplier_id,
        )
        .filter(Boolean) as string[],
    ).size > 1;

  const hasMultipleDescriptions = payments.some(
    (payment) => payment.description.trim() !== payments[0]?.description.trim(),
  );

  const hasMultipleTeams = payments.some(
    (payment) => payment.group?.databaseId !== payments[0]?.group?.databaseId,
  );

  const canEditDescription = payments.every(
    (payment) => payment.updatableStatus?.isDescriptionEditable,
  );

  const canEditSupplier = payments.every(
    (payment) =>
      !['mileage_allowance', 'per_diem_allowance'].includes(
        payment.request?.type ?? '',
      ) && payment.updatableStatus?.isSupplierAddable,
  );

  const canEditTeam = payments.every(
    (payment) => payment.updatableStatus?.isTeamEditable,
  );

  const canEditCustomFields = payments.every(
    (payment) => payment.updatableStatus?.isCustomFieldsEditable,
  );

  const teamIds = [
    ...new Set(
      payments
        .map((payment) => payment.group?.databaseId)
        .filter(Boolean) as string[],
    ),
  ];

  const customFieldTypes: EligibleType[] = ['payment'];
  if (payments.some((payment) => payment.request_id && !payment.card_id)) {
    customFieldTypes.push('expense');
  }
  const eligibleCustomFields = getEligibleCustomFields(customFields, {
    user,
    types: customFieldTypes,
    teamIds,
  }).filter((customField) => {
    // Check if the custom field can be edited for all payments' teams
    const eligibleTeams = customField.scopes
      .filter(
        (scope: { entity_type: string; entity_id: string }) =>
          scope.entity_type === 'team',
      )
      .map(
        (scope: { entity_type: string; entity_id: string }) => scope.entity_id,
      );

    return (
      customField.is_all_scopes ||
      teamIds.filter((teamId) => !eligibleTeams.includes(teamId)).length === 0
    );
  });

  const formikProps = useFormik<{
    description: string;
    supplier: { key: string; label: string } | undefined;
    group: { key: string; label: string } | undefined;
    customFields: CustomFieldAssociation[];
  }>({
    initialValues: {
      description: hasMultipleDescriptions ? '' : payments[0]?.description,
      supplier:
        !hasMultipleSuppliers && payments[0]?.supplier
          ? { key: payments[0].supplier.id, label: payments[0].supplier.name }
          : undefined,
      group:
        !hasMultipleTeams && payments[0]?.group
          ? { key: payments[0].group.databaseId, label: payments[0].group.name }
          : undefined,
      customFields: getInitialCustomFields(eligibleCustomFields, payments),
    },
    onSubmit: async (values) => {
      const payload: Payload = {
        payments: payments.map((payment) => payment.databaseId),
      };

      if (canEditDescription && values.description.trim() !== '') {
        payload.description = values.description;
      }

      if (canEditSupplier && values.supplier) {
        payload.supplier_id = values.supplier.key;
        payload.supplier_name = values.supplier.label;
      }

      if (canEditTeam && values.group) {
        payload.group = values.group.key;
      }

      if (canEditCustomFields && values.customFields.length > 0) {
        payload.customFieldAssociations = values.customFields.filter(
          (cfa) =>
            cfa.customFieldValueId !== undefined &&
            cfa.customFieldValueId !==
              formikProps.initialValues.customFields.find(
                (cf) => cf.customFieldId === cfa.customFieldId,
              )?.customFieldValueId,
        );
      }

      await bulkUpdatePayments(payload);
      successNotif(t('payments.actions.successBulkUpdate'));
      onDone();
    },
  });

  return (
    <Panel
      className="h-full"
      title={t('payments.actions.selectedWithCount', {
        count: payments.length,
      })}
      onClose={onClose}
      footer={
        <div className="flex flex-col gap-xs">
          <Button
            type="submit"
            text={t('payments.actions.bulkEditPaymentsSubmitButton')}
            form="bulk-edit-payments"
            isLoading={formikProps.isSubmitting}
          />
          <Button
            variant="secondary"
            text={t('misc.cancel')}
            onClick={onClose}
          />
        </div>
      }
    >
      <BulkEditPayments
        className="p-xxs"
        groups={groups}
        customFields={eligibleCustomFields}
        teamIds={teamIds}
        customFieldTypes={customFieldTypes}
        canEditSupplier={canEditSupplier}
        canEditTeam={canEditTeam}
        canEditDescription={canEditDescription}
        canEditCustomFields={canEditCustomFields}
        {...formikProps}
      />
    </Panel>
  );
};

function getInitialCustomFields(
  eligibleCustomFields: RawCustomField[],
  payments: Payment[],
): CustomFieldAssociation[] {
  return eligibleCustomFields
    .map((eligibleCustomField) => {
      const firstPaymentCustomField = payments[0]?.custom_fields?.find(
        (customField) =>
          customField.field.databaseId === eligibleCustomField.id,
      );

      const hasMultipleValues = payments.some((payment) => {
        const paymentCustomField = payment.custom_fields?.find(
          (customField) =>
            customField.field.databaseId === eligibleCustomField.id,
        );
        return (
          paymentCustomField?.value.databaseId !==
          firstPaymentCustomField?.value.databaseId
        );
      });

      return hasMultipleValues
        ? undefined
        : {
            customFieldId: eligibleCustomField.id,
            customFieldValueId: firstPaymentCustomField?.value.databaseId,
            value: firstPaymentCustomField?.value.value,
          };
    })
    .filter(Boolean) as CustomFieldAssociation[];
}
