import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense/';

import { usePurchaseOrderQuery } from '../../detail/hooks';
import { type PurchaseOrderProgress } from '../../models';

type Props = {
  purchaseOrderId: string;
  children: (
    purchaseOrderProgress: PurchaseOrderProgress | undefined,
  ) => React.ReactElement | null;
};

export const PurchaseOrderMessageByProgress = ({
  purchaseOrderId,
  children,
}: Props) => {
  const purchaseOrderQueryState = usePurchaseOrderQuery(purchaseOrderId);

  return (
    <QuerySuspense
      queryState={purchaseOrderQueryState}
      loading={null}
      fallback={() => null}
    >
      {(purchaseOrder) => children(purchaseOrder.progress)}
    </QuerySuspense>
  );
};
