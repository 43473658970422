import * as NEA from '@dev-spendesk/general-type-helpers/NonEmptyArray';
import * as Money from 'ezmoney';

import { type CreditNote } from '@finance-review/models/credit-note';
import { type DocumentaryEvidenceFile } from 'common/components/DocumentaryEvidence';
import {
  type CustomField,
  customFieldToCustomFieldAssociation,
} from 'modules/budgets/models/customField';

export type RawCreditNote = {
  id: string;
  description: string;
  supplier: {
    id: string;
    name: string;
  };
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  amount: Money.MonetaryValue;
  amountInCompanyCurrency: Money.MonetaryValue;
  availableCreditAmount: Money.MonetaryValue;
  creditNoteRequest: {
    id: string;
    companyId: string;
    state: 'inReview' | 'partiallyAllocated' | 'allocated' | 'reimbursed';
    requestId: string;
    creditNoteNumber: string;
    referenceInvoiceRequestId: string | null; // TODO@financeAccountant@creditNotes: use `referenceRequestId` instead
    referenceRequestId: string | null;
    issueDate: string;
    isArchived: boolean;
    validationAction:
      | {
          kind: 'deductFromInvoiceRequests';
          invoiceRequests: {
            invoiceRequestId: string;
            amount: Money.MonetaryValue;
          }[];
        }
      | {
          kind: 'recordForFutureInvoices';
        }
      | {
          kind: 'reimburseOutsideSpendesk';
        };
  };
  // null meaning the credit note request has not been validated by controller yet
  team?: {
    id: string;
    name: string;
  };
  customFields?: CustomField[];
  documentaryEvidences?: DocumentaryEvidenceFile[];
  costCenter?: {
    id: string;
    name: string;
  };
};

export const transformRawCreditNote = (
  rawCreditNoteRequest: RawCreditNote,
): CreditNote => {
  return {
    id: rawCreditNoteRequest.id,
    creditNoteDetailsId: rawCreditNoteRequest.creditNoteRequest.id,
    creditNoteNumber: rawCreditNoteRequest.creditNoteRequest.creditNoteNumber,
    referenceInvoiceRequestId:
      rawCreditNoteRequest.creditNoteRequest.referenceInvoiceRequestId ||
      undefined,
    referenceInvoiceId:
      rawCreditNoteRequest.creditNoteRequest.referenceRequestId || 'none',
    issueDate: new Date(rawCreditNoteRequest.creditNoteRequest.issueDate),
    availableCreditAmount: rawCreditNoteRequest.availableCreditAmount,
    amount: Money.toNumber(rawCreditNoteRequest.amount),
    amountInCompanyCurrency: Money.toNumber(
      rawCreditNoteRequest.amountInCompanyCurrency,
    ),
    currency: rawCreditNoteRequest.amount.currency,
    supplierId: rawCreditNoteRequest.supplier.id,
    supplierName: rawCreditNoteRequest.supplier.name,
    requesterId: rawCreditNoteRequest.user.id,
    requesterName: `${rawCreditNoteRequest.user.firstName} ${rawCreditNoteRequest.user.lastName}`,
    description: rawCreditNoteRequest.description,
    teamId: rawCreditNoteRequest.team?.id || undefined,
    customFieldsAssociations: (rawCreditNoteRequest.customFields ?? []).map(
      customFieldToCustomFieldAssociation,
    ),
    documentaryEvidences: NEA.fromArray(
      rawCreditNoteRequest.documentaryEvidences ?? [],
    ),
    costCenterId: rawCreditNoteRequest.costCenter?.id,
    validationAction:
      rawCreditNoteRequest.creditNoteRequest.validationAction?.kind,
    deductedInvoiceRequests: NEA.fromArray(
      rawCreditNoteRequest.creditNoteRequest.validationAction?.kind ===
        'deductFromInvoiceRequests'
        ? rawCreditNoteRequest.creditNoteRequest.validationAction?.invoiceRequests.map(
            ({ invoiceRequestId, amount: deductedAmount }) => ({
              invoiceRequestId,
              deductedAmount,
            }),
          )
        : [],
    ),
    state: rawCreditNoteRequest.creditNoteRequest.state,
  };
};
