import { formatISO } from 'date-fns';

import { type CreditNote } from '@finance-review/models/credit-note';
import { type CustomFieldAssociation } from 'src/core/modules/bookkeep/prepare-payables/models';

export type UpdateCreditNoteRequestApiPayload = {
  cost_center_id?: string;
  group_id?: string;
  amount_declared?: number;
  currency_declared?: string;
  description: string;
  supplier?: {
    id?: string;
    name?: string;
  };
  custom_fields_associations?: CustomFieldAssociation[];
  creditNoteRequest: {
    creditNoteNumber: string;
    referenceInvoiceRequestId: string | undefined;
    issueDate: string;
    validationAction:
      | 'deductFromReferenceInvoiceRequest'
      | 'recordForFutureInvoices'
      | 'reimburseOutsideSpendesk'
      | undefined;
  };
};

export const transformCreditNoteToUpdateApiPayload = (
  creditNote: CreditNote,
): UpdateCreditNoteRequestApiPayload => ({
  cost_center_id: creditNote.costCenterId,
  group_id: creditNote.teamId,
  amount_declared: creditNote.amount,
  currency_declared: creditNote.currency,
  description: creditNote.description,
  supplier: {
    id: creditNote.supplierId,
    name: creditNote.supplierName,
  },
  custom_fields_associations: creditNote.customFieldsAssociations,
  creditNoteRequest: {
    creditNoteNumber: creditNote.creditNoteNumber,
    referenceInvoiceRequestId: creditNote.referenceInvoiceRequestId,
    issueDate: formatISO(creditNote.issueDate, {
      representation: 'date',
    }),
    validationAction:
      creditNote.validationAction === 'deductFromInvoiceRequests'
        ? 'deductFromReferenceInvoiceRequest'
        : creditNote.validationAction,
  },
});
