import { Callout, IconButton, Link, Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import compact from 'lodash/compact';
import replace from 'lodash/replace';
import React, { type ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';

import {
  FileViewerToolbar,
  type Modes,
} from '../FileViewerToolbar/FileViewerToolbar';
import { InvalidProofBanner } from '../InvalidProofBanner/InvalidProofBanner';

import './PDFViewer.css';

export type Props = {
  url: string;
  className?: string;
  toolbarMode?: Modes;
  withDeleteTooltip?: ReactNode;
  isInvalid?: boolean;
  invalidProofReason?: string;
  leftActions?: ReactNode[];
  onDownload?: () => void;
  onDelete?: () => void;
  onClose?: () => void;
};

export const PDFViewer = ({
  url,
  className = '',
  toolbarMode,
  withDeleteTooltip,
  isInvalid = false,
  invalidProofReason,
  leftActions = [],
  onDownload,
  onDelete,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');

  const mainActions = compact([
    onDownload ? (
      <IconButton
        key="download"
        variant="borderless"
        iconName="download"
        onClick={onDownload}
        aria-label={t('misc.download')}
      />
    ) : null,
    onDelete ? (
      <Tooltip
        key="delete"
        content={withDeleteTooltip}
        placement="right"
        maxWidth={544}
        isDisabled={!withDeleteTooltip}
        triggerAsChild
      >
        <IconButton
          variant="borderless"
          onClick={onDelete}
          iconName="trash"
          isDisabled={Boolean(withDeleteTooltip)}
          aria-label={t('misc.delete')}
        />
      </Tooltip>
    ) : null,
  ]);

  const getLeftActions = () => {
    return onClose ? leftActions.concat(mainActions) : leftActions;
  };

  const getRightActions = () => {
    return onClose
      ? [
          <IconButton
            key="close"
            variant="borderless"
            onClick={onClose}
            iconName="cross"
            aria-label={t('misc.close')}
          />,
        ]
      : mainActions;
  };

  const downloadLink = replace(url, /\/view/, '');

  return (
    <div className={cx('PDFViewer', className)}>
      <FileViewerToolbar
        leftActions={getLeftActions()}
        rightActions={getRightActions()}
        mode={toolbarMode}
      />
      {isInvalid && <InvalidProofBanner reason={invalidProofReason} />}
      <object
        key={url} // force the re-render of the HTML tag (hide the error if the new url is valid)
        data={url}
        type="application/pdf"
        className="PDFViewer__iframe"
        title="PDFViewerIframe"
      >
        <Callout
          variant="alert"
          className="PDFViewer__iframe-error"
          title={
            <Trans i18nKey="fileViewer.errorViewer">
              <Link
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                -
              </Link>
            </Trans>
          }
        />
      </object>
    </div>
  );
};
