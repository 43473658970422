import { gql } from 'graphql-tag';

export const BATCH_MARK_PAYABLES_AS_PREPARED = gql`
  mutation BatchMarkPayablesAsPrepared($selectors: [ConnectionSelector!]!) {
    batchMarkPayablesAsPrepared(selectors: $selectors) {
      __typename
      ... on BatchMarkPayablesAsPreparedResultPrepareDeferred {
        processId
      }
      ... on BatchMarkPayablesAsPreparedResultNotPrepared {
        reason
      }
    }
  }
`;

export const GET_BATCH_MARK_AS_PREPARED_PAYABLES_PROCESS = gql`
  query GetBatchMarkPayablesAsPreparedProcess($processId: ID!) {
    batchUpdatePayablesProcessStatus(processId: $processId) {
      __typename
      ... on BatchUpdatePayablesProcessStatusCompleted {
        payablesProcessed {
          totalCount
          edges {
            node {
              status
              statusReason
              payable {
                id
                state
                version
                __typename
              }
            }
          }
        }
        aggregateSummary {
          totalCount
          byStatus {
            status
            count
            byStatusReason {
              statusReason
              count
            }
          }
        }
      }
    }
  }
`;
