import { DropdownItem } from '@dev-spendesk/grapes';
import React, { type FocusEventHandler, useMemo } from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type PurchaseOrder } from '../../../models';

type PurchaseOrderId = string;

export type PurchaseOrderAutocompleteSearchProps = {
  purchaseOrders: PurchaseOrder[];
  placeholder: string;
  noPurchaseOrderPlaceholder: string;
  className?: string;
  fit?: 'content' | 'parent';
  id?: string;
  isInvalid?: boolean;
  isForcedDisabled?: boolean;
  name?: string;
  value: PurchaseOrderId | undefined;
  showClearSelectionButton?: boolean;
  onSelect(value: PurchaseOrder | undefined): void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  inputVariant?: 'magicGradient' | 'default';
};

export const PurchaseOrderAutocompleteSearch = ({
  onSelect,
  purchaseOrders,
  value: selectedPurchaseOrderId,
  noPurchaseOrderPlaceholder,
  placeholder,
  isForcedDisabled = false,
  ...props
}: PurchaseOrderAutocompleteSearchProps) => {
  const selectedPurchaseOrder = !selectedPurchaseOrderId
    ? undefined
    : purchaseOrders.find(
        (purchaseOrder) => purchaseOrder.id === selectedPurchaseOrderId,
      );
  const isDisabled = purchaseOrders.length === 0 || isForcedDisabled;

  const options = useMemo(
    () =>
      purchaseOrders.map((purchaseOrder) => ({
        key: purchaseOrder.id,
        label: getAutocompleteLabel(purchaseOrder),
      })),
    [purchaseOrders],
  );

  return (
    <AutocompleteSearch
      {...props}
      isDisabled={isDisabled}
      placeholder={isDisabled ? noPurchaseOrderPlaceholder : placeholder}
      value={
        selectedPurchaseOrder && {
          key: selectedPurchaseOrder.id,
          label: getAutocompleteLabel(selectedPurchaseOrder),
        }
      }
      onSelect={(value) =>
        onSelect(
          purchaseOrders.find(
            (purchaseOrder) => purchaseOrder.id === value?.key,
          ),
        )
      }
      options={options}
      renderOption={(purchaseOrder, state) => (
        <DropdownItem label={purchaseOrder.label} {...state} />
      )}
      renderNoOptions={(rawValue) => <AutocompleteNoOptions value={rawValue} />}
    />
  );
};

const getAutocompleteLabel = (purchaseOrder: PurchaseOrder): string => {
  try {
    return `${purchaseOrder.purchaseOrderNumber} | ${
      purchaseOrder.supplier.name
    } | ${formatMonetaryValue(purchaseOrder.amount)}`;
  } catch {
    return purchaseOrder.purchaseOrderNumber;
  }
};
