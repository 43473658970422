import { apiV2Url } from 'src/core/utils/api';

type SuccessResponse = {
  data: {
    successIds: string[];
    errorIds: string[];
  };
};

export type BulkCreateManagerAssociationPayload = {
  userId: string;
  managerId: string;
}[];

export const postBulkCreateManagerAssociation = async ({
  companyId,
  payload,
}: {
  companyId: string;
  payload: BulkCreateManagerAssociationPayload;
}): Promise<SuccessResponse> => {
  const response = await fetch(
    apiV2Url(`/users/manager-associations`, companyId),
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: payload }),
    },
  );

  if (response.status === 200) {
    return response.json();
  }

  throw new Error('Creating manager associations failed');
};
