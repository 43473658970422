import { type Dispatch } from '@reduxjs/toolkit';
import { type AxiosResponse } from 'axios';

import { getSelectedCompanyId } from 'modules/company';
import { baseAPI, companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';

import * as groupActions from './actions';
import { type GroupActions, type Group } from '../types';

export const fetchAll = () => {
  return async (dispatch: Dispatch<GroupActions>, getState: () => AppState) => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(groupActions.fetchAllRequest());
    try {
      const { data }: AxiosResponse<Group[]> = await baseAPI.get(
        `/${companyId}/groups/`,
      );
      dispatch(groupActions.fetchAllSuccess(data));
    } catch (error) {
      dispatch(groupActions.fetchAllFailure(error.response.data));
    }
  };
};

export const fetchOne = (groupId: string) => {
  return async (dispatch: Dispatch<GroupActions>, getState: () => AppState) => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(groupActions.fetchOneRequest());
    try {
      const { data }: AxiosResponse<Group> = await baseAPI.get(
        `/${companyId}/groups/${groupId}`,
      );
      dispatch(groupActions.fetchOneSuccess(data));
      return data;
    } catch (error) {
      dispatch(groupActions.fetchAllFailure(error.response.data));
    }
  };
};

export const create = (name: string, userIds: string[]) => {
  return async (dispatch: Dispatch<GroupActions>, getState: () => AppState) => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(groupActions.createRequest());
    try {
      const { data }: AxiosResponse<Group> = await companyAPI.post('/groups', {
        admin_ids: userIds,
        name,
        companyId,
      });
      dispatch(groupActions.createSuccess(data));
      return data;
    } catch (error) {
      dispatch(groupActions.createFailure(error.response.data));
    }
  };
};

export const update = (group: Group) => {
  return async (dispatch: Dispatch<GroupActions>, getState: () => AppState) => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(groupActions.updateRequest(group));
    try {
      const { data }: AxiosResponse<Group> = await companyAPI.put(
        `/groups/${group.id}`,
        {
          companyId,
          ...group,
        },
      );
      dispatch(groupActions.updateSuccess(data));
    } catch (error) {
      dispatch(groupActions.updateFailure(error.response.data));
    }
  };
};

export const destroy = (groupId: string) => {
  return async (dispatch: Dispatch<GroupActions>, getState: () => AppState) => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(groupActions.destroyRequest());
    try {
      await baseAPI.delete(`/${companyId}/groups/${groupId}`);
      dispatch(groupActions.destroySuccess(groupId));
    } catch (error) {
      dispatch(groupActions.destroyFailure(error.response.data));
    }
  };
};

export const patch = (partialGroup: Partial<Group>) => {
  return async (dispatch: Dispatch<GroupActions>, getState: () => AppState) => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(groupActions.patchRequest());
    try {
      const {
        data: { team },
      }: AxiosResponse<{ team: Group }> = await baseAPI.patch(
        `/${companyId}/groups/${partialGroup.id}`,
        [
          {
            op: 'replace',
            field: 'admins',
            value: partialGroup.admin_ids,
          },
        ],
      );
      if (team) {
        dispatch(groupActions.patchSuccess(team));
      }
    } catch (error) {
      dispatch(groupActions.patchFailure(error.response.data.error));
    }
  };
};
