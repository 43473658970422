import { IconButton } from '@dev-spendesk/grapes';
import { useHistory } from 'react-router-dom';

import { useParams } from 'src/core/common/hooks/useParams';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type Language } from 'src/core/config/i18n';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { CostCenterEditForm } from '../../../components/CostCenterEditForm';
import { useCostCenterQuery } from '../../hooks/useCostCenterQuery';
import { type Member } from '../../member';
import { useMembersQuery } from '../CostCentersPageContainer/hooks/useMembersQuery';

export const CostCenterEditPage = () => {
  const companyId = useCompanyId();
  const { t, activeLanguage } = useTranslation('global');
  const { id } = useParams(routes.COST_CENTER_EDIT_PAGE.path);
  const membersQueryState = useMembersQuery();

  const members =
    membersQueryState.status === 'success'
      ? sortMembers(membersQueryState.data, activeLanguage)
      : [];

  const costCenterQueryState = useCostCenterQuery(id);
  const history = useHistory();

  if (costCenterQueryState.status !== 'success') {
    // TODO: Add loading state (and use QuerySuspense)
    return null;
  }

  return (
    <div className="flex justify-center pt-xxl">
      <div className="w-[520px]">
        <div className="text-neutral-darker body-m">
          {t('costCenters.costCenter')}
        </div>
        <div className="relative mt-xs flex items-center text-complementary title-xxl">
          <IconButton
            iconName="arrow-left"
            aria-hidden="true"
            variant="borderless"
            className="absolute -left-xxl"
            onClick={() => {
              history.push(
                routeFor(routes.COST_CENTERS.path, {
                  company: companyId,
                }),
              );
            }}
          />
          {costCenterQueryState.data.name}
        </div>
        <CostCenterEditForm
          costCenter={costCenterQueryState.data}
          members={members}
          onSubmit={async (values) => {
            // TODO: Implement submit
            // eslint-disable-next-line no-console
            console.log(values);
          }}
        />
      </div>
    </div>
  );
};

const sortMembers = (members: Member[], activeLanguage: Language) => {
  return members.sort((m1, m2) => {
    return (
      m1.fullname.localeCompare(m2.fullname, activeLanguage, {
        sensitivity: 'base',
      }) ||
      m1.email.localeCompare(m2.email, activeLanguage, {
        sensitivity: 'base',
      })
    );
  });
};
