import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { NavigationLink } from 'src/core/common/components/NavigationLink';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

type Props = {
  company: {
    id: string;
  };
};

export const UserProfileLink = ({ company }: Props) => {
  const { t } = useTranslation('global');
  const { pathname: currentPath } = useLocation();

  return (
    <NavigationLink
      data-marketing="top-menu_user-profile"
      key="nav_user_profile"
      path={routeFor(routes.ACCOUNT_ME_PROFILE.path, {
        company: company.id,
      })}
      text={t('settings.links.account')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [routes.ACCOUNT_ME.path],
        }),
      )}
    />
  );
};
