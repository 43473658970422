import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { type User } from 'src/core/modules/app/hooks/useUser';

export type FormattedUser = {
  id: string;
  fullName: string;
};

export const useGetControllersQuery = (): QueryState<FormattedUser[]> => {
  const companyId = useCompanyId();

  return useQuery<FormattedUser[], User[]>({
    key: ['users', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/users',
    },
    reshapeData: (rawUsers) => {
      return rawUsers
        .filter((user) => {
          const userDataForCompany = user.data_by_company[companyId];
          return (
            userDataForCompany.is_account_owner ||
            userDataForCompany.is_controller
          );
        })
        .map((rawUser) => ({
          id: rawUser.id,
          fullName: rawUser.fullname,
        }));
    },
  });
};
