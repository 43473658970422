import { type ThunkDispatch } from '@reduxjs/toolkit';

import { companyAPI } from 'src/core/api/axios';
import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import {
  FETCH_LAST_TERMS_AND_CONDITIONS_SUCCESS,
  FETCH_TIPS_FILTERS_FAILURE,
  FETCH_TIPS_FILTERS_SUCCESS,
  SIGN_TERMS_AND_CONDITIONS_FAILURE,
  SIGN_TERMS_AND_CONDITIONS_LOADING,
  SIGN_TERMS_AND_CONDITIONS_SUCCESS,
} from './actionTypes';
import { fetchCustomExports, fetchPaymentsInformation } from './dataFetcher';
import { type TermsAndConditions } from './types';

type TipsFilters = {
  hasPaymentsWithoutReceipts: boolean;
  isAutocatEnabled: boolean;
  hasCustomExports: boolean;
  hasPaymentsWithVirtualCreditCards: boolean;
  isExpenseClaimsEnabled: boolean;
};

type FetchTipsFiltersFailure = {
  type: typeof FETCH_TIPS_FILTERS_FAILURE;
};
type FetchTipsFiltersSuccess = {
  type: typeof FETCH_TIPS_FILTERS_SUCCESS;
  payload: { tipsFilters: TipsFilters };
};

export type ControllerHomepageAction =
  | FetchTipsFiltersSuccess
  | FetchTipsFiltersFailure
  | SignTermsAndConditionsLoading
  | SignTermsAndConditionsFailure
  | SignTermsAndConditionsSuccess
  | FetchLastTermsAndConditions;

type SignTermsAndConditionsLoading = {
  type: typeof SIGN_TERMS_AND_CONDITIONS_LOADING;
};

type SignTermsAndConditionsSuccess = {
  type: typeof SIGN_TERMS_AND_CONDITIONS_SUCCESS;
};

type SignTermsAndConditionsFailure = {
  type: typeof SIGN_TERMS_AND_CONDITIONS_FAILURE;
  payload: { errorCode: string };
};

export const acknowledgeTermsAndConditions =
  (companyId: string, termsAndConditionsId: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ControllerHomepageAction>,
  ): Promise<void> => {
    dispatch({ type: SIGN_TERMS_AND_CONDITIONS_LOADING });

    try {
      await companyAPI.put(
        `/terms-and-conditions/${termsAndConditionsId}/acknowledge`,
        null,
        {
          companyId,
        },
      );
    } catch (error) {
      const { errorCode } = error.response?.data ?? 'unknown';
      dispatch({ type: SIGN_TERMS_AND_CONDITIONS_FAILURE, payload: errorCode });
      throw error;
    }

    dispatch({ type: SIGN_TERMS_AND_CONDITIONS_SUCCESS });
  };

type FetchLastTermsAndConditions = {
  type: typeof FETCH_LAST_TERMS_AND_CONDITIONS_SUCCESS;
  payload: TermsAndConditions | null;
};

export const fetchLastTermsAndConditions =
  (locale: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ControllerHomepageAction>,
    getState: () => AppState,
  ): Promise<void> => {
    const companyId = getState()?.global?.company?.id as string;

    let lastTermsAndConditions;
    let statusCode;
    try {
      const res = await companyAPI.get<TermsAndConditions>(
        '/terms-and-conditions',
        { companyId, params: { locale } },
      );
      lastTermsAndConditions = res.data;
      statusCode = res.status;
    } catch {
      return;
    }

    dispatch({
      type: FETCH_LAST_TERMS_AND_CONDITIONS_SUCCESS,
      // Handle 204 differently because the response body is an empty string
      payload: statusCode === 204 ? null : lastTermsAndConditions,
    });
  };

const fetchTipsFiltersFailure = (): FetchTipsFiltersFailure => ({
  type: FETCH_TIPS_FILTERS_FAILURE,
});
const fetchTipsFiltersSuccess = (
  filters: TipsFilters,
): FetchTipsFiltersSuccess => ({
  type: FETCH_TIPS_FILTERS_SUCCESS,
  payload: { tipsFilters: filters },
});

export const fetchTipsFilters =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, ControllerHomepageAction>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    try {
      const [paymentsInformation, customExports] = await Promise.all([
        fetchPaymentsInformation(companyId),
        fetchCustomExports(companyId),
      ]);

      dispatch(
        fetchTipsFiltersSuccess({
          isExpenseClaimsEnabled: getIsFeatureEnabled(
            state,
            FEATURES.EXPENSE_CLAIMS,
          ),
          isAutocatEnabled: getIsFeatureEnabled(state, FEATURES.AUTO_CAT),
          hasPaymentsWithoutReceipts:
            !!paymentsInformation.paymentsWithoutReceipts,
          hasPaymentsWithVirtualCreditCards:
            !!paymentsInformation.paymentsWithVirtualCreditCard ||
            !!paymentsInformation.subscriptions,
          hasCustomExports: !!customExports.length,
        }),
      );
    } catch {
      dispatch(fetchTipsFiltersFailure());
    }
  };
