import { Callout } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

import {
  BudgetOverviewButton,
  BudgetOverviewLoader,
} from '../../components/BudgetOverview';
import { dateRangeToPeriodRange, getBudgetLimit } from '../../models/breakdown';
import { type PeriodRange } from '../../models/period';
import { useBreakdownQuery } from '../hooks/useBreakdownQuery';

type Props = {
  budget: {
    id: string;
    name: string;
  };
  periodRange: PeriodRange;
  budgetaryExercisePeriodicity: 'yearly' | 'monthly' | 'quarterly';
};

export const BudgetOverviewButtonContainer = ({
  budget,
  periodRange,
  budgetaryExercisePeriodicity,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const breakdownQueryState = useBreakdownQuery(
    budget.id,
    dateRangeToPeriodRange(
      budgetaryExercisePeriodicity === 'yearly'
        ? { key: 'overall' }
        : periodRange,
    ),
  );

  return (
    <QuerySuspense
      queryState={breakdownQueryState}
      loading={<BudgetOverviewLoader />}
      fallback={() => (
        <Callout title={t('budget.breakdownFetchError')} variant="alert" />
      )}
    >
      {(breakdown) => (
        <BudgetOverviewButton
          budget={{
            ...budget,
            amount: getBudgetLimit(breakdown),
            breakdown,
          }}
          onClick={() => {
            const budgetsPageRoute = routeFor(routes.BUDGET_OVERVIEW.path, {
              id: budget.id,
              company: companyId,
            });
            history.push({
              pathname: budgetsPageRoute,
              state: {
                hasBackButton: true,
                selectedPeriodRange: periodRange,
              },
            });
          }}
        />
      )}
    </QuerySuspense>
  );
};
