import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useLazyQuery } from 'src/core/api/hooks/useLazyQuery';
import { type LazyState } from 'src/core/api/queryState';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useUnlinkSlackQuery = (): LazyState<{}> => {
  const companyId = useCompanyId();

  // eslint-disable-next-line @typescript-eslint/ban-types
  return useLazyQuery<{}, {}>({
    key: ['slackProviderSettings', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/unlink-slack',
    },
    reshapeData: (rawData) => {
      return rawData;
    },
  });
};
