import { formatISO } from 'date-fns';
import { type MonetaryValue } from 'ezmoney';

import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import type { SpendingType } from '../../../breakdownSpendings';
import { type BreakdownPart } from '../../../models/breakdown';
import { type ExpenseCategoryBreakdown } from '../../../models/expenseCategoryBreakdown';
import type { PeriodRangePayload } from '../../../models/period';

export type BreakdownSpending = {
  id: string;
  date: Date;
  amount: MonetaryValue;
  globalAmount: MonetaryValue;
  initialGlobalAmount: MonetaryValue;
  payer: {
    firstName: string;
    lastName: string;
    avatar: string | undefined;
  };
  document?: {
    attachedAt: string;
    filename: string;
    mediaType: string;
  };
  supplier: string | undefined;
  description: string | undefined;
  budgetId: string;
  expenseCategoryId?: string;
  netAmountStatus: NetAmountStatus;
  grossAmount: MonetaryValue;
  isSplit: boolean;
};

export type NetAmountStatus =
  | 'verified'
  | 'estimated'
  | 'unknown'
  | 'editedManually'
  | 'editedByRequester'
  | 'editedByController';

const pageSize = 20; // hardcoded in the backend side

export const useBudgetBreakdownSpendingsQuery = (parameters: {
  budgetId: string;
  breakdownPart: BreakdownPart;
  spendingType: SpendingType;
  breakdownComputedAt: Date;
  periodRange: PeriodRangePayload;
  expenseCategory?: ExpenseCategoryBreakdown['expenseCategory'];
}): InfiniteQueryState<BreakdownSpending[]> => {
  const params = transformToQueryParameters(parameters);

  return useInfiniteQuery<BreakdownSpending, BreakdownSpending[]>({
    key: ['budgets', parameters.budgetId, 'breakdown', 'spendings', params],
    getRequest(page = '1') {
      return {
        type: 'rest',
        target: 'companyAPI',
        endpoint: `/budgets/budgets/${parameters.budgetId}/breakdown/spendings`,
        params: { ...params, page },
      };
    },
    getNextPageParam(lastPage, allPages) {
      if (lastPage.length < pageSize) {
        return undefined;
      }
      const nextPage = allPages.length + 1;
      return nextPage.toString();
    },
    reshapeData: (data) => {
      return data.map((breakdownSpending) => ({
        ...breakdownSpending,
        budgetId: parameters.budgetId,
      }));
    },
  });
};

/**
 * Transform query arguments into query parameters that will be appended to rest API URL.
 * @param arguments Query arguments.
 * @returns the query parameters of the useBudgetBreakdownSpendings query as an Object.
 * @info useQuery ignores query parameters with `undefined` value.
 */
const transformToQueryParameters = ({
  periodRange,
  expenseCategory,
  ...queryArguments
}: Parameters<typeof useBudgetBreakdownSpendingsQuery>['0']) => {
  // If the expense category is defined (`isOther` is falsy), we don't want `unassignedOnly` query parameter
  const unassignedOnly = expenseCategory?.isOther || undefined;

  const periodParams = periodRange && {
    startDate:
      periodRange.from &&
      formatISO(periodRange.from, { representation: 'date' }),
    endDate:
      periodRange.to && formatISO(periodRange.to, { representation: 'date' }),
  };

  return {
    ...queryArguments,
    ...periodParams,
    expenseCategoryId: expenseCategory?.id,
    unassignedOnly,
  };
};
