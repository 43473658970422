export const isSharedDraftInvoice = (invoiceRequest: {
  id?: string;
  user?: { id?: string };
}) => {
  return !invoiceRequest.id || !invoiceRequest.user?.id;
};

export const canUserHandleSharedDraftInvoice = (user: {
  is_account_owner: boolean;
  is_controller: boolean;
  is_requester: boolean;
}) => {
  // Only account owner & controllers that also have the requester role can edit shared invoice drafts
  return user.is_account_owner || (user.is_controller && user.is_requester);
};
