import React, { useState } from 'react';

import { useMembersQueryRaw } from 'modules/members/hooks/useMembersQueryRaw';
import { useRemainingSeats } from 'modules/members/hooks/useRemainingSeats';
import { useUsersCountLimitFeatures } from 'modules/members/hooks/useUsersCountLimitFeatures';

import { MemberInviteContent } from './MemberInviteContent';
import { MemberInviteSummary } from './MemberInviteSummary';
import {
  type InvitationResult,
  type SummaryContent,
} from '../../models/invite';

type Props = {
  summaryContent: SummaryContent;
  hideSummary: () => void;
  onClose(): void;
  handleInviteCreationResult: (result: InvitationResult) => void;
};

export const MemberInviteModal = ({
  summaryContent,
  hideSummary,
  onClose,
  handleInviteCreationResult,
}: Props) => {
  const [openedTabIndex, setOpenedTabIndex] = useState(0);
  const hasUsersCountLimitFeature = useUsersCountLimitFeatures();
  const useMembersQueryRawResult = useMembersQueryRaw(
    hasUsersCountLimitFeature,
  );
  const nbMembers =
    useMembersQueryRawResult.status === 'success'
      ? useMembersQueryRawResult.data.length
      : undefined;
  const remainingSeatsForUsersCount = useRemainingSeats(nbMembers);

  const openBackImport = () => {
    if (summaryContent.isDisplayed) {
      if (summaryContent.type === 'email') {
        setOpenedTabIndex(0);
      } else if (summaryContent.type === 'file_import') {
        setOpenedTabIndex(2);
      }
      hideSummary();
    }
  };

  return summaryContent.isDisplayed ? (
    <MemberInviteSummary
      type={summaryContent.type}
      messages={summaryContent.messages}
      remainingSeatsForUsersCount={remainingSeatsForUsersCount}
      onBackToImport={openBackImport}
      onClose={onClose}
    />
  ) : (
    <MemberInviteContent
      remainingSeatsForUsersCount={remainingSeatsForUsersCount}
      openedTabIndex={openedTabIndex}
      handleInviteCreationResult={handleInviteCreationResult}
      onClose={onClose}
    />
  );
};
