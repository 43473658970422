import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { useBudgetaryExercisesQuery } from 'modules/budgets/containers/hooks/useBudgetaryExercisesQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { routes } from 'src/core/constants/routes';
import { useUser, type User } from 'src/core/modules/app/hooks/useUser';
import { useAccessibleCardsLinks } from 'src/core/pages/CardsPage/CardsPage';
import { getHasHomepage } from 'src/core/selectors/globalSelectors';

import { AllExpensesLink } from './NavItems/AllExpensesLink';
import { BookkeepLink } from './NavItems/BookkeepLink';
import { BudgetsLink } from './NavItems/BudgetsLink';
import { CardLink } from './NavItems/CardLink';
import { ExpendituresLink } from './NavItems/Expenditures';
import { HomeLink } from './NavItems/HomeLink';
import { InboxLink } from './NavItems/InboxLink';
import { MultiEntityHubLink } from './NavItems/MultiEntityHubLink';
import { ProcurementLink } from './NavItems/ProcurementLink';
import { PurchaseOrderLink } from './NavItems/PurchaseOrderLink';
import { RequestLink } from './NavItems/RequestLink';
import { SettingsLink } from './NavItems/SettingsLink';
import { useCompany } from '../../../hooks/useCompany';

type MainNavigationLink = (props: {
  user: {
    is_account_owner: boolean;
    is_organisation_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
  };
  company: {
    id: string;
    churning_at: string | null;
  };
}) => JSX.Element | null;

type MainNavigationItem = { name: string; component: MainNavigationLink };

function useHasVisibleBudgets(enableQuery: boolean): boolean {
  const allBudgetaryExercicesQueryState =
    useBudgetaryExercisesQuery(enableQuery);

  // Check if at least one budget is returned to display the link accordingly
  return useMemo(() => {
    if (
      allBudgetaryExercicesQueryState.status !== 'success' ||
      allBudgetaryExercicesQueryState.data.length === 0
    ) {
      return false;
    }
    return allBudgetaryExercicesQueryState.data.some(
      ({ budgets }) => budgets.length > 0,
    );
  }, [allBudgetaryExercicesQueryState]);
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function usePrimaryNavigationLinks(user: User): MainNavigationItem[] {
  const isProcurementEnabled = useFeature(FEATURES.PROCUREMENT);
  const isBookkeepEnabled = useFeature(FEATURES.BOOKKEEP);
  const isBudgetsFeatureEnabled = useFeature(FEATURES.BUDGETS);
  const isInvoiceInboxEnabled = useFeature(FEATURES.BILL_INBOX);
  const isPurchaseOrderActivated = useFeature(
    FEATURES.PURCHASE_ORDERS_ACTIVATED,
  );
  const accessibleCardsLinks = useAccessibleCardsLinks();

  const isAoOrController = user.is_account_owner || user.is_controller;
  const hasVisibleBudgets = useHasVisibleBudgets(
    isBudgetsFeatureEnabled && !isAoOrController,
  );
  const hasHomepage = useSelector(getHasHomepage);

  const primaryNavigationLinks: MainNavigationItem[] = [];

  if (hasHomepage) {
    primaryNavigationLinks.push({
      name: 'homepage',
      component: HomeLink,
    });
  }

  /**
   * Display Expenditure entry for AO and Controller.
   * Otherwise, display each item as the first level.
   */
  if (isAoOrController) {
    primaryNavigationLinks.push({
      name: 'expenditures',
      component: ExpendituresLink,
    });
  } else {
    if (isInvoiceInboxEnabled) {
      primaryNavigationLinks.push({
        name: 'inbox',
        component: InboxLink,
      });
    }

    if (user.is_requester) {
      primaryNavigationLinks.push({
        name: 'request',
        component: RequestLink,
      });
    }

    primaryNavigationLinks.push({
      name: 'allexpenses',
      component: AllExpensesLink,
    });

    if (isPurchaseOrderActivated) {
      primaryNavigationLinks.push({
        name: 'purchase-order',
        component: PurchaseOrderLink,
      });
    }
  }

  if (isAoOrController && isBookkeepEnabled) {
    primaryNavigationLinks.push({
      name: 'bookkeep',
      component: BookkeepLink,
    });
  }

  if (accessibleCardsLinks.length > 0) {
    primaryNavigationLinks.push({ name: 'card', component: CardLink });
  }

  if (isProcurementEnabled) {
    primaryNavigationLinks.push({
      name: 'procurement',
      component: ProcurementLink,
    });
  }

  if (isBudgetsFeatureEnabled && (isAoOrController || hasVisibleBudgets)) {
    primaryNavigationLinks.push({
      name: 'budgets',
      component: BudgetsLink,
    });
  }

  if (user.is_organisation_owner || user.is_admin || user.is_account_owner) {
    primaryNavigationLinks.push({
      name: 'settings',
      component: SettingsLink,
    });
  }

  return primaryNavigationLinks;
}

function shouldDisplayNewRequestButton(
  company: {
    id: string;
    churning_at: string | null;
  },
  user: {
    is_account_owner: boolean;
    is_requester: boolean;
  },
) {
  return !(
    company.churning_at ||
    (!user.is_requester && !user.is_account_owner)
  );
}

export function useNavigationItems(): {
  canCreateRequest: boolean;
  links: MainNavigationItem[];
} {
  const company = useCompany();
  const user = useUser();
  const links = usePrimaryNavigationLinks(user);

  const isMultiEntityHubPage = useIsMultiEntityHubPage();

  if (isMultiEntityHubPage) {
    // The user can only see the Dashboard link, cannot create requests
    return {
      canCreateRequest: false,
      links: [{ name: 'multi-entity-hub', component: MultiEntityHubLink }],
    };
  }

  return {
    canCreateRequest: shouldDisplayNewRequestButton(company, user),
    links,
  };
}

export function useIsMultiEntityHubPage(): boolean {
  return !!matchPath(useLocation().pathname, routes.ORGANISATION_REPORTING);
}
