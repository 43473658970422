import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type QueryClient } from 'src/core/api/client';
import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import {
  type Result as DefaultSupplierAccountQueryResult,
  useGetDefaultSupplierAccountQueryKey,
} from './useGetDefaultSupplierAccountQuery';
import {
  type DefaultSupplierAccount,
  type AccountPayable,
  type SupplierAccountDefaultFor,
} from '../../accounting';

export type Payload = DefaultSupplierAccount;

type RawData =
  | ({ outcome: 'notSet' } & (
      | {
          reason:
            | 'defaultAlreadyExists'
            | 'accountNotFound'
            | 'adapterMismatch'
            | 'emptyCode'
            | 'emptyName';
        }
      | { reason: 'codeAlreadyExists'; existingAccount: AccountPayable }
    ))
  | {
      outcome: 'set';
      account: DefaultSupplierAccount;
    };

export type Result = RawData;

export const useSetDefaultSupplierAccountMutation = (): MutationState<
  Payload,
  Result
> => {
  const companyId = useCompanyId();

  return useMutation<Payload, Result, RawData>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint:
        '/accounting-integration/chart-of-accounts/supplier-accounts/default',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ data, client }): void => {
        invalidateGetDefaultSupplierAccountQuery(client, data, companyId);
        client.invalidateQueries([
          'accountsPayable',
          'useGetSupplierAccountsQuery',
        ]);
        client.invalidateQueries('useIntegrationStatusQuery');
        client.invalidateQueries('useGetPayableQuery'); // payable details in Prepare
        client.invalidateQueries('payable'); // cache payable details in Prepare
      },
    },
  });
};

const invalidateGetDefaultSupplierAccountQuery = (
  client: QueryClient,
  data: RawData,
  companyId: string,
) => {
  const queryKey = useGetDefaultSupplierAccountQueryKey;

  if (data.outcome !== 'set') {
    client.invalidateQueries(queryKey);
    return;
  }

  try {
    const defaultSupplierAccountQueryKey = [queryKey, companyId];
    const defaultSupplierAccountQueryData =
      client.getQueryData<DefaultSupplierAccountQueryResult>(
        defaultSupplierAccountQueryKey,
      );
    // Manually update default supplier account query data if it's been responded from mutation
    if (defaultSupplierAccountQueryData && data.account) {
      const { defaultFor } = data.account;
      client.setQueryData(defaultSupplierAccountQueryKey, {
        ...defaultSupplierAccountQueryData,
        [getDefaultSupplierAccountQueryDataProperty(defaultFor)]: data.account,
      });
    }
    // If not responded from mutation, invalidate and refetch from BE
    else {
      client.invalidateQueries(queryKey);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Error occured while invalidateGetDefaultSupplierAccountQuery: ${
        error && error.message
      } `,
    );
    // In case of any error, be sure query is invalidated
    client.invalidateQueries(queryKey);
  }
};

const getDefaultSupplierAccountQueryDataProperty = (
  defaultFor: SupplierAccountDefaultFor,
) => {
  switch (defaultFor) {
    case 'cardSupplier':
      return 'supplierAccountForCard';
    case 'invoiceSupplier':
      return 'supplierAccountForInvoice';
    default:
      throw new Error(
        'Property not found on default supplier account query data',
      );
  }
};
