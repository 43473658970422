import { useFeature } from 'common/hooks/useFeature';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import FEATURES from 'src/core/constants/features';

export const useCanAccessInvoicesPage = (): boolean => {
  const { isAccountOwner, isAdmin, isController } = useUserRoles();
  const isSubmitMyInvoiceFeatureEnabled = useFeature(
    FEATURES.SUBMIT_MY_INVOICE,
  );
  const isControlOnSpendingMethodEnabled = useFeature(
    FEATURES.CONTROL_ON_SPENDING_METHODS,
  );

  if (!isSubmitMyInvoiceFeatureEnabled && isControlOnSpendingMethodEnabled) {
    return isAccountOwner || isAdmin;
  }

  return isSubmitMyInvoiceFeatureEnabled && (isAccountOwner || isController);
};
