import React from 'react';

const authorize =
  <TProps extends object>(
    predicate: (props: TProps) => boolean,
    renderFallback: (props: TProps) => JSX.Element,
  ) =>
  (WrappedComponent: React.ComponentType<TProps>) => {
    return function Authorize(props: TProps) {
      if (!predicate(props)) {
        return renderFallback(props);
      }
      return <WrappedComponent {...props} />;
    };
  };

export default authorize;
