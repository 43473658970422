import { useDispatch } from 'react-redux';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { dismissWelcomeDashboard } from 'src/core/actions/global';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useMarkWelcomeDashboardAsDoneMutation = (): MutationState => {
  const companyId = useCompanyId();
  const dispatch = useDispatch();

  return useMutation({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: `/onboarding-tasks/welcome-dashboard/dismiss`,
    },
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['welcomeDashboard', companyId]);
        dispatch(dismissWelcomeDashboard());
      },
    },
  });
};
