import { SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

type Props = {
  numberOfRows?: number;
};

export const TaxAccountSkeletonTable = ({ numberOfRows }: Props) => (
  <SkeletonTable
    numberOfRows={numberOfRows}
    columns={[
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
      },
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
      },
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
      },
    ]}
    withColumnSeparator
    withHeader
  />
);
