import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ApiPartner, apiPartnerSchema } from '../domain/oauth2-token';

export const useGetOAuth2ApiPartnerByTokenData = (
  id: string | null,
  code: string | null,
): QueryState<ApiPartner> => {
  const { company: companyId } = useParams();
  return useQuery<ApiPartner, ApiPartner>({
    key: ['oauth2TokenData', companyId],
    isEnabled: !!(id && code),
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/public-api-configuration/oauth2/data/${id}/${code}`,
    },
    options: {
      staleTime: 60_000,
      cacheTime: 60_000,
      refetchInterval: 60_000,
    },
    reshapeData(rawApiPartner: unknown): ApiPartner {
      return apiPartnerSchema.parse(rawApiPartner);
    },
  });
};
