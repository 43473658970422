import { useHistory } from 'react-router-dom';

import {
  routeFor,
  routes,
  type AppRouteKeys,
  type RouteParams,
  type RoutesUrl,
  type ParseUrlParams,
} from 'src/core/constants/routes';

export function useGoTo(): <Key extends AppRouteKeys>(
  route: Key,
  params: RouteParams<Key>,
  searchParams?: URLSearchParams,
) => void {
  const history = useHistory();

  return <Key extends AppRouteKeys>(
    route: Key,
    params: RouteParams<Key>,
    searchParams?: URLSearchParams,
  ): void => {
    const url = routeBuilder(route, params, searchParams);
    history.push(url);
  };
}

export function routeBuilder<Key extends AppRouteKeys>(
  route: Key,
  params: ParseUrlParams<RoutesUrl>,
  searchParams?: URLSearchParams,
): string {
  let url = routeFor(routes[route].path as string, params);
  if (searchParams) {
    url = `${url}?${searchParams.toString()}`;
  }
  return url;
}
