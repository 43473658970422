import { colors, Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { READY_TO_SPEND_TASKS } from 'modules/onboarding/setup-hub/components/SetupGuideNavigationItem/SetupGuideNavigationItem';
import { CONTROL_SETTINGS_TASKS } from 'modules/onboarding/setup-hub/pages/SetupHubPage/tasks/ControlSettingHubTaskItem/ControlSettingHubTaskItem';
import {
  type UserTask,
  type UserTaskType,
} from 'modules/onboarding/setup-hub/types/userTask';
import { type HubTask } from 'modules/onboarding/types/hubTask';

import styles from './HubTaskWrapper.module.css';

type Props = {
  title: string;
  description: string;
  children?: React.ReactNode;
  task: HubTask | UserTask;
  duration?: string;
  disabledMessage?: string;
  actions?: React.ReactNode[];
};

export const HubTaskWrapper = ({
  title,
  description,
  children,
  task,
  duration,
  disabledMessage,
  actions,
}: Props) => {
  const { t } = useTranslation('global');
  const isDisabled = task.status === 'disabled';
  const showCompletedTitle =
    [...READY_TO_SPEND_TASKS, ...CONTROL_SETTINGS_TASKS].includes(
      task.type as UserTaskType,
    ) && task.status === 'completed';

  return (
    <div className={styles.task}>
      <h3
        className={cx('flex items-center gap-xs title-l', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'text-neutral-dark': isDisabled || showCompletedTitle,
          'line-through': showCompletedTitle,
        })}
      >
        {showCompletedTitle && <Icon name="success" color={colors.success} />}
        {title}
      </h3>
      {isDisabled && !!disabledMessage && (
        <span className="mt-xs flex items-center title-m">
          <Icon size="m" name="lock" className="mr-xs" />
          <span>{disabledMessage}</span>
        </span>
      )}
      {duration && (
        <span className="mt-xs flex items-center text-neutral-dark body-s">
          <Icon size="m" name="clock-full" className="mr-xs" />
          {t('onboardingHub.misc.timeNeeded', { minutes: duration })}
        </span>
      )}
      {!isDisabled && <p className="mt-s text-neutral-dark">{description}</p>}
      {children}
      <div className="mt-s flex gap-s empty:hidden">{actions}</div>
    </div>
  );
};
