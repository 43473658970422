import {
  Button,
  Modal,
  Upload,
  Callout,
  Badge,
  CheckboxField,
  FileCard,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  onUpload(file: File, prefixWithCode: boolean): Promise<void>;
  onCancel(): void;
  downloadLink: string;
  isOpen: boolean;
};

export const BulkImportExpenseAccount = ({
  onUpload,
  onCancel,
  downloadLink,
  isOpen,
}: Props) => {
  const { t } = useTranslation('global');
  const [expenseAccountFile, setExpenseAccountFile] = useState<File>();
  const [errorMissingFile, setErrorMissingFile] = useState(false);
  const [prefixWithCode, setPrefixWithCode] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      iconName="plus-circle"
      iconVariant="primary"
      title={t(
        'bookkeep.integrations.settings.expenseAccountsTable.import.title',
      )}
      subtitle={t(
        'bookkeep.integrations.settings.expenseAccountsTable.import.subtitle',
      )}
      actions={[
        <Button
          key="no"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          text={t(
            `bookkeep.integrations.settings.expenseAccountsTable.import.uploadButton`,
          )}
          variant="primary"
          onClick={async () => {
            if (!expenseAccountFile) {
              setErrorMissingFile(true);
              return;
            }
            await onUpload(expenseAccountFile, prefixWithCode);
          }}
        />,
      ]}
    >
      <div className="my-l">
        <div className="mb-s flex items-center gap-s text-left text-complementary title-m">
          <Badge variant="primary">1</Badge>
          {t(
            'bookkeep.integrations.settings.expenseAccountsTable.import.instructionsDownload',
          )}
        </div>
        <div className="mb-l mt-m">
          <Button
            component="a"
            href={downloadLink}
            text={t(
              'bookkeep.integrations.settings.expenseAccountsTable.import.downloadButton',
            )}
            variant="secondary"
          />
        </div>
        <div className="mb-s flex items-center gap-s text-left text-complementary title-m">
          <Badge variant="primary">2</Badge>
          {t(
            'bookkeep.integrations.settings.expenseAccountsTable.import.instructionsUpload',
          )}
        </div>
        <div>
          {!expenseAccountFile ? (
            <>
              <Upload
                activeDragContent={
                  <>{t('forms.uploadFiles.activeDragContent')}</>
                }
                content={
                  <Trans i18nKey="forms.uploadFiles.content">
                    <br />-
                    <span className="text-complementary underline title-m">
                      -
                    </span>
                  </Trans>
                }
                onUpload={(files) => {
                  setErrorMissingFile(false);
                  setExpenseAccountFile(files[0]);
                }}
              />
              {errorMissingFile && (
                <Callout
                  className="mt-xs"
                  title={t(
                    'bookkeep.integrations.settings.expenseAccountsTable.import.errorMissingFile',
                  )}
                  variant="alert"
                />
              )}
            </>
          ) : (
            <FileCard
              title={expenseAccountFile.name}
              onDelete={() => setExpenseAccountFile(undefined)}
            />
          )}
        </div>
        <CheckboxField
          className="mt-s w-full text-left"
          isChecked={prefixWithCode}
          label={t(
            'bookkeep.integrations.settings.expenseAccountsTable.import.prefixWithCode',
          )}
          onChange={(event) => setPrefixWithCode(event.target.checked)}
        />
      </div>
    </Modal>
  );
};
