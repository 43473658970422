import { Callout } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type SupportedKomboFilterDimension } from 'src/core/modules/integrations/types';

import { isInvalidValue } from '../utils';

export const FilterError = ({
  dimensionValues,
  selectedDimension,
  shouldDisplayErrors,
}: {
  dimensionValues: string[];
  selectedDimension: SupportedKomboFilterDimension | null;
  shouldDisplayErrors: boolean;
}) => {
  const { t } = useTranslation('global');

  if (
    (selectedDimension && !isInvalidValue(dimensionValues)) ||
    !shouldDisplayErrors
  ) {
    return null;
  }

  return (
    <div className="flex justify-center px-xs pt-xs">
      <div className="w-full pl-[55px] pr-xxl">
        {selectedDimension ? (
          <Callout
            id="empty-values"
            variant="alert"
            title={t('integration.kombo.settings.filters.errors.emptyValue')}
          />
        ) : (
          <Callout
            id="empty-dimension"
            variant="alert"
            title={t(
              'integration.kombo.settings.filters.errors.emptyDimension',
            )}
          />
        )}
      </div>
    </div>
  );
};
