import { useSelector } from 'react-redux';

import { getSupervisor } from 'src/core/selectors/globalSelectors';

export type Supervisor =
  | { name: string; email: string; canRead: boolean; canWrite: boolean }
  | undefined;

export const useSupervisor = (): Supervisor => {
  return useSelector(getSupervisor);
};
