import { colors, Icon } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { track, AnalyticEventName } from 'src/core/utils/analytics';

import { useDisplayMode, useSwitchButtonIcon } from '../../../../hooks';
import { SwitchButton } from '../SwitchButton';

export const DisplayModeSwitch = () => {
  const { t } = useTranslation('global');

  const history = useHistory();

  const displayMode = useDisplayMode();
  const icon = useSwitchButtonIcon({ displayMode });

  return (
    <SwitchButton
      activeButton={displayMode === 'controller' ? 'left' : 'right'}
      leftButton={{
        label: (
          <>
            {t('homepage.welcome.controllerView')}
            {icon === 'warningOrange' && (
              <Icon
                size="m"
                name="warning"
                className="ml-xs"
                color={colors.warning}
              />
            )}
          </>
        ),
        onClick: () => {
          track(AnalyticEventName.HOMEPAGE_SWITCH_MODE_CLICKED, {
            from: 'requester',
            to: 'controller',
            icon,
          });
          history.push({ search: '?mode=controller' });
        },
      }}
      rightButton={{
        label: (
          <>
            {t('homepage.welcome.requesterView')}
            {icon === 'warningRed' && (
              <Icon
                size="m"
                name="warning"
                className="ml-xs"
                color={colors.alert}
              />
            )}
            {icon === 'cardBlocked' && (
              <Icon
                size="m"
                name="card-lock"
                className="ml-xs"
                color={colors.alert}
              />
            )}
          </>
        ),
        onClick: () => {
          track(AnalyticEventName.HOMEPAGE_SWITCH_MODE_CLICKED, {
            from: 'controller',
            to: 'requester',
            icon,
          });
          history.push({ search: '?mode=requester' });
        },
      }}
    />
  );
};
