import { FormField, TextInput, Icon, Avatar } from '@dev-spendesk/grapes';

import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useHasAuxiliaryAccountsEnabled } from 'modules/bookkeep/hooks';
import { type User } from 'modules/bookkeep/types';

import { type ModalState } from './ModalState';
import { EmployeeAccountAdvice } from '../../../../../../../components/AccountAdvice';
import { type IntegrationStatusWithIntegration } from '../../../../../../../integration/status';
import { userToString } from '../../../../../../accounting/utils';
import { useGetUsersWithoutEmployeeAccountQuery } from '../../../../../hooks/useGetUsersWithoutEmployeeAccountQuery';
import { CodeInUseCallout } from '../../../components/CodeInUseCallout/CodeInUseCallout';
import { getCodeInUseLinkForAccountsPayable } from '../../../utils';
import styles from '../EmployeeAccountsSection.module.css';

interface Props {
  fixedUser?: User;
  integrationStatus: IntegrationStatusWithIntegration;
  modalState: ModalState;
  setModalState: (modalState: ModalState) => void;
}

export const EmployeeAccountsAddEditForm = ({
  fixedUser,
  integrationStatus,
  modalState,
  setModalState,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();

  const usersWithoutEmployeeAccountQuery =
    useGetUsersWithoutEmployeeAccountQuery();

  const users =
    usersWithoutEmployeeAccountQuery.status === 'success'
      ? usersWithoutEmployeeAccountQuery.data
      : [];

  const userOptions = users.map((user) => ({
    key: user.id,
    label: userToString(user),
  }));

  const handleEmployeeAccountSelection = (option?: { key: string }) => {
    if (modalState.kind !== 'add') {
      return;
    }

    setModalState({
      ...modalState,
      employeeAccount: {
        ...modalState.employeeAccount,
        user: option ? userById.get(option.key) : undefined,
      },
    });
  };

  const userById = new Map(users.map((user) => [user.id, user]));
  return (
    <div>
      {modalState.kind === 'add' && (
        <>
          {fixedUser === undefined && (
            <FormField
              label={t(
                'bookkeep.integrations.settings.employeeAccountsTable.employeeLabel',
              )}
            >
              <AutocompleteSearch
                data-testid="employee-account-select"
                fit="parent"
                placeholder={t(
                  'bookkeep.integrations.settings.employeeAccountsTable.autocomplatePlaceholder',
                )}
                options={userOptions}
                isLoading={
                  usersWithoutEmployeeAccountQuery.status === 'loading'
                }
                value={
                  modalState.employeeAccount.user
                    ? {
                        key: modalState.employeeAccount.user.id,
                        label: userToString(modalState.employeeAccount.user),
                      }
                    : undefined
                }
                renderPrefix={(option) =>
                  option ? (
                    <Avatar
                      size="s"
                      className={styles.avatar}
                      // @ts-expect-error: Not an helpful comment
                      text={userToString(userById.get(option.key))}
                    />
                  ) : (
                    <Icon size="l" name="team" />
                  )
                }
                onSelect={handleEmployeeAccountSelection}
                renderNoOptions={() => <div>No user found</div>}
              />
            </FormField>
          )}

          <div className={styles.defaultForm__inputWrapper}>
            <FormField
              label={
                auxiliaryAccountsEnabled
                  ? t(
                      'bookkeep.integrations.settings.employeeAccountsTable.accountCodeLabel',
                    )
                  : t(
                      'bookkeep.integrations.settings.employeeAccountsTable.code',
                    )
              }
              className={styles.defaultForm__input}
            >
              <TextInput
                data-testid="employee-account-code"
                value={modalState.employeeAccount.generalAccountCode ?? ''}
                placeholder={t(
                  'bookkeep.integrations.settings.employeeAccountsTable.accountCodePlaceholder',
                )}
                isInvalid={
                  modalState.error !== 'none' && !modalState.inputChanged
                }
                onChange={(event) => {
                  setModalState({
                    ...modalState,
                    employeeAccount: {
                      ...modalState.employeeAccount,
                      generalAccountCode: event.target.value,
                    },
                    inputChanged: true,
                  });
                }}
              />
            </FormField>
            {auxiliaryAccountsEnabled && (
              <FormField
                label={t(
                  'bookkeep.integrations.settings.employeeAccountsTable.auxiliaryAccountCode',
                )}
                hint="Optional"
                className={styles.defaultForm__input}
              >
                <TextInput
                  value={modalState.employeeAccount.auxiliaryAccountCode ?? ''}
                  placeholder={t(
                    'bookkeep.integrations.settings.employeeAccountsTable.accountCodePlaceholder',
                  )}
                  isInvalid={
                    modalState.error !== 'none' && !modalState.inputChanged
                  }
                  onChange={(event) => {
                    setModalState({
                      ...modalState,
                      employeeAccount: {
                        ...modalState.employeeAccount,
                        auxiliaryAccountCode: event.target.value,
                      },
                      inputChanged: true,
                    });
                  }}
                />
              </FormField>
            )}
          </div>

          {modalState.error === 'codeAlreadyInUse' &&
            modalState.codeInUseAccount &&
            !modalState.inputChanged && (
              <CodeInUseCallout
                className={styles.formCalloutInModal}
                accountInfo={`${t(
                  // For translations: this refers to the keys:
                  // bookkeep.integrations.settings.supplierAccountsTable.employeeAccount
                  // bookkeep.integrations.settings.supplierAccountsTable.supplierAccount
                  `bookkeep.integrations.settings.supplierAccountsTable.${modalState.codeInUseAccount.kind}`,
                )} - ${modalState.codeInUseAccount.account.generalAccountCode}`}
                linkTo={getCodeInUseLinkForAccountsPayable(
                  {
                    error: 'codeAlreadyExists',
                    existingAccount: {
                      kind: modalState.codeInUseAccount.kind,
                      ...modalState.codeInUseAccount.account,
                    },
                  },
                  companyId,
                )}
              />
            )}

          {(modalState.error !== 'codeAlreadyInUse' ||
            modalState.inputChanged) && (
            <EmployeeAccountAdvice
              className={styles.formCalloutInModal}
              integrationStatus={integrationStatus}
              showError={
                modalState.error === 'invalidPattern' &&
                !modalState.inputChanged
              }
            />
          )}
        </>
      )}
    </div>
  );
};
