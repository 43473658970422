import { useMutation } from 'src/core/api/hooks/useMutation';

export const useDeleteCustomFieldMutation = (id: string) => {
  return useMutation({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/custom-fields-package/custom-fields/${id}`,
      method: 'delete',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['customFields']);
      },
    },
  });
};
