import { useUserId } from 'modules/app/hooks/useUserId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useUploadAvatarMutation = (): MutationState<FormData> => {
  const userId = useUserId();

  return useMutation<FormData>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: `/users/${userId}/avatar`,
    },
  });
};
