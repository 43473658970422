import { type FormikProps, useFormik } from 'formik';

import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';
import { type CustomField } from 'modules/custom-fields/models/customField';

type FormValues = {
  isRequired: boolean;
  isSplittable?: boolean;
};

export const useExpenseCategoriesEditSettingsFormikProps = (formParameters: {
  expenseCategoryCustomField: CustomField;
  onSubmit(editedxpenseCategoryCustomField: CustomField): Promise<void>;
}): FormikProps<FormValues> => {
  const { expenseCategoryCustomField, onSubmit } = formParameters;
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();

  return useFormik<FormValues>({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      isRequired: expenseCategoryCustomField.isRequired,
      isSplittable: isAnalyticalSplitActivated
        ? expenseCategoryCustomField.isSplittable
        : false,
    },
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const editedxpenseCategoryCustomField = {
        ...expenseCategoryCustomField,
        ...values,
      } satisfies CustomField;
      await onSubmit(editedxpenseCategoryCustomField);

      setSubmitting(false);
    },
  });
};
