import { useFeature } from 'common/hooks/useFeature';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import FEATURES from 'src/core/constants/features';

import type { CardsAccess, CardsAccessRaw } from '../models/cardsAccess';

export const useMembersCardsAccessQuery = (
  memberIds: string[],
): QueryState<CardsAccess[]> => {
  const params = { userId: memberIds.join(',') };
  const isCardsAccessEnabled = useFeature(FEATURES.CARDS_ACCESS);
  const queryState = useQuery<CardsAccess[], CardsAccessRaw>({
    key: ['cards', 'access', params],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/cards/access',
      params,
    },
    reshapeData(data) {
      return data.accesses ?? [];
    },
  });

  return isCardsAccessEnabled ? queryState : { status: 'success', data: [] };
};
