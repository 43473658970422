import { Button, Callout } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { type KybStatusData } from 'modules/kyc/models/kybStatus';

import styles from './KybStatus.module.css';

type Props = {
  kybStatus: KybStatusData;
};

export const KybStatus = ({ kybStatus }: Props) => {
  if (
    !['information-needed', 'signature-requested', 'completed'].includes(
      kybStatus.state.code,
    )
  ) {
    return null;
  }

  return (
    <Callout
      className={classNames('relative mt-s', styles.container)}
      variant="warning"
      title={kybStatus.state.title}
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: kybStatus.state.description,
        }}
      />
      {kybStatus.state.redirectionCta &&
        kybStatus.state.redirectionLocation && (
          <div className={styles.ctaContainer}>
            <Button
              variant="contrasted"
              component="a"
              text={kybStatus.state.redirectionCta}
              href={kybStatus.state.redirectionLocation}
              target="_blank"
              rel="noopener noreferrer"
            />
          </div>
        )}
    </Callout>
  );
};
