import cx from 'classnames';
import React from 'react';

import { DownloadAppBadges } from 'common/components/DownloadAppBadges';

import styles from './DownloadAppIllustration.module.css';
import CaroleCooper from './images/carole-cooper.svg';
import QrCode from './images/qr-code.svg';

interface Props {
  className?: string;
  hasBottomMargin?: boolean;
}

export const DownloadAppIllustration = ({
  className,
  hasBottomMargin,
}: Props) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.scanContainer}>
        <img className="w-full" src={QrCode} alt="QR code" />
      </div>
      <DownloadAppBadges
        className={cx(
          'mt-xl',
          'gap-l',
          'flex',
          styles.badges,
          hasBottomMargin ? 'mb-m' : '',
        )}
      />
      <img
        className={styles.caroleCooper}
        src={CaroleCooper}
        alt="Working woman holding phone"
      />
    </div>
  );
};
