import { endOfYear, startOfYear, format } from 'date-fns';
import { useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type BudgetaryExerciseInput } from './budgetaryExerciseInput';
import { useCreateBudgetaryExercise } from './hooks/useCreateBudgetaryExercise';
import { validate } from './validator';
import { CreateBudgetaryExerciseModal } from '../../components/CreateBudgetaryExerciseModal';
import { useBudgetaryExercisesQuery } from '../hooks/useBudgetaryExercisesQuery';
import { useRedirectToBudgetaryExercise } from '../hooks/useRedirectToBudgetaryExercise';

type Props = {
  onHide(): void;
};

export const CreateBudgetaryExerciseModalContainer = ({ onHide }: Props) => {
  const { t } = useTranslation('global');
  const redirectToBudgetaryExercise = useRedirectToBudgetaryExercise();
  const [createBudgetaryExercise] = useCreateBudgetaryExercise();
  const budgetaryExercisesQueryState = useBudgetaryExercisesQuery();
  const budgetaryExercises =
    budgetaryExercisesQueryState.status === 'success'
      ? budgetaryExercisesQueryState.data
      : [];

  const formikProps = useFormik<BudgetaryExerciseInput>({
    initialValues: {
      name: '',
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
      periodicity: undefined,
    },
    validateOnChange: false,
    validate: (values) => {
      return validate(values, budgetaryExercises, t);
    },
    onSubmit: async (values) => {
      const budgetaryExercise = await createBudgetaryExercise({
        name: values.name,
        periodicity: values.periodicity,
        endDate: values.endDate
          ? format(values.endDate, 'yyyy-MM-dd')
          : undefined,
        startDate: values.startDate
          ? format(values.startDate, 'yyyy-MM-dd')
          : undefined,
      });
      redirectToBudgetaryExercise(budgetaryExercise.id);
      onHide();
    },
  });

  return <CreateBudgetaryExerciseModal {...formikProps} onHide={onHide} />;
};
