import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type ExpenseCategoryExpenseAccountRule } from '../../../hooks/useGetExpenseCategoryExpenseAccountRulesLazyQuery';

type Payload = {
  expenseAccountIds: string[];
  expenseCategoryId: string;
};

export const useCreateOrUpdateExpenseCategoryExpenseAccountRule =
  (): MutationState<Payload, ExpenseCategoryExpenseAccountRule> => {
    return useMutation<
      Payload,
      ExpenseCategoryExpenseAccountRule,
      ExpenseCategoryExpenseAccountRule
    >({
      request: {
        type: 'rest',
        method: 'post',
        target: 'companyAPI',
        endpoint: '/bookkeeping/automation/expense-accounts-expense-categories',
      },
      reshapeData: (data) => data,
      options: {
        throwOnError: true,
        onSuccess({ client }): void {
          client.invalidateQueries(['expenseCategories']);
          client.refetchQueries(['expenseCategoryExpenseAccountRules']);
        },
      },
    });
  };
