import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type QueryClient } from 'src/core/api/client';
import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import {
  type Result as DefaultEmployeeAccountQueryResult,
  useGetDefaultEmployeeAccountQueryKey,
} from './useGetDefaultEmployeeAccountQuery';
import {
  type DefaultEmployeeAccount,
  type AccountPayable,
} from '../../accounting';

export type Payload = {
  id?: string;
  name?: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  isArchived: boolean;
};

type RawData =
  | ({ outcome: 'notSet' } & (
      | {
          reason:
            | 'defaultAlreadyExists'
            | 'accountNotFound'
            | 'adapterMismatch'
            | 'emptyCode'
            | 'emptyName';
        }
      | { reason: 'codeAlreadyExists'; existingAccount: AccountPayable }
    ))
  | {
      outcome: 'set';
      account: DefaultEmployeeAccount;
    };

export type Result = RawData;

export const useSetDefaultEmployeeAccountMutation = (): MutationState<
  Payload,
  Result
> => {
  const companyId = useCompanyId();

  return useMutation<Payload, Result, RawData>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint:
        '/accounting-integration/chart-of-accounts/employee-accounts/default',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ data, client }): void => {
        if (data.outcome === 'set') {
          invalidateGetDefaultEmployeeAccountQuery(client, data, companyId);
          client.invalidateQueries('useGetPayableQuery'); // payable details in Prepare
          client.invalidateQueries('payable'); // cache payable details in Prepare
        }
      },
    },
  });
};

const invalidateGetDefaultEmployeeAccountQuery = (
  client: QueryClient,
  data: RawData,
  companyId: string,
) => {
  if (data.outcome !== 'set') {
    return;
  }
  const queryKey = useGetDefaultEmployeeAccountQueryKey;

  try {
    const defaultEmployeeAccountQueryKey = [queryKey, companyId];
    const defaultEmployeeAccountQueryData =
      client.getQueryData<DefaultEmployeeAccountQueryResult>(
        defaultEmployeeAccountQueryKey,
      );
    // Manually update default employee account query data if it's been responded from mutation
    if (defaultEmployeeAccountQueryData && data.account) {
      client.setQueryData(defaultEmployeeAccountQueryKey, {
        ...defaultEmployeeAccountQueryData,
        employeeAccount: data.account,
      });
    }
    // If not responded from mutation, invalidate and refetch from BE
    else {
      client.invalidateQueries(queryKey);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Error occured while invalidateGetDefaultEmployeeAccountQuery: ${
        error && error.message
      } `,
    );
    // In case of any error, be sure query is invalidated
    client.invalidateQueries(queryKey);
  }
};
