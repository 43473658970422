import { useContext } from 'react';

import { PreparePayablesValidationContext } from '../contexts';

export const useCachedSinglePayablesStatus = () => {
  const validationContext = useContext(PreparePayablesValidationContext);

  return () => {
    // TODO: fetch payables which are already in the cache, which selection
    // context, for all buckets?
    // const payables: any[] | undefined = queryClient.getQueryData([
    //   `usePayablesQuery`,
    // ]);

    const payables = Object.entries(validationContext.state)
      .filter(([_, { refreshedVersion }]) => refreshedVersion !== undefined)
      .map(([id, { refreshedVersion: version }]) => ({ id, version })) as {
      id: string;
      version: number;
    }[];

    return payables.map((item) => {
      return {
        id: item.id,
        version: item.version,
      };
    });
  };
};
