import { useDispatch } from 'react-redux';

import type { AppDispatch } from 'modules/app/redux/store';
import { fetchExpenseClaimsCounts } from 'modules/reimbursements';

export const useFetchLegacyExpenseClaimCounts = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>();

  return (): void => {
    dispatch(fetchExpenseClaimsCounts());
  };
};
