import type { MonetaryValue } from 'ezmoney';

import { type RawInvoiceData } from '@finance-review/apis/invoice/transformers';
import { useUpdateCacheWhenCreditChange } from '@finance-review/hooks/invoice';
import type { CreditNoteId } from '@finance-review/models/credit-note';
import type { InvoiceRequest } from '@finance-review/models/invoice';
import { useQueryError, type Translations } from 'common/components/QueryError';
import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';

export type ApplyAvailableCreditError = {
  outcome: 'notApplied';
} & (
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason:
        | 'invalidValidationAction'
        | 'inconsistentCurrency'
        | 'noInvoiceRequests'
        | 'nothingToApply'
        | 'invalidTransactionCommit'
        | 'invalidTransactionRollback';
    }
  | {
      reason:
        | 'invalidValidationAction'
        | 'invalidState'
        | 'invalidAvailableCreditAmount'
        | 'inconsistentCurrency';
      creditNoteId: string;
    }
  | {
      reason: 'invoiceRequestNotFound';
      invoiceRequestId: string;
    }
  | {
      reason:
        | 'requestNotFound'
        | 'invalidRequestStatus'
        | 'cannotReplaceScheduledPayments';
      requestId: string;
    }
);

type Payload = {
  invoiceRequestId: InvoiceRequest.EntityId;
};

export type ApplyAvailableCreditResponse = {
  outcome: 'applied';
  appliedCredit: MonetaryValue;
  appliedCreditNoteIds: CreditNoteId[];
  invoiceRequest: RawInvoiceData;
};

export const useApplyAvailableCredit = (): MutationState<
  Payload,
  ApplyAvailableCreditResponse,
  ApplyAvailableCreditError
> => {
  const updateCacheWhenCreditChange = useUpdateCacheWhenCreditChange();

  return useMutation<
    Payload,
    ApplyAvailableCreditResponse,
    ApplyAvailableCreditResponse,
    ApplyAvailableCreditError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/credit_note_requests/apply_available_credit`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data }) => {
        if (data.outcome === 'applied') {
          updateCacheWhenCreditChange(data.invoiceRequest);
        }
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};

export const applyAvailableCreditNoteErrorTranslations: Translations<ApplyAvailableCreditError> =
  {
    requestError: ({ reason }) => {
      switch (reason) {
        case 'nothingToApply':
        case 'invalidAvailableCreditAmount':
          return 'creditNote.query.applyAvailableCredit.noCreditAvailable';

        case 'invalidState':
          return 'creditNote.query.applyAvailableCredit.invoiceAlreadyScheduled';

        default:
          return 'creditNote.query.applyAvailableCredit.error';
      }
    },
    serverError: 'creditNote.query.applyAvailableCredit.error',
  };

export const useApplyAvailableAvailableCreditNoteErrorMessage = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const getErrorMessage = useQueryError<ApplyAvailableCreditError, {}>(
    applyAvailableCreditNoteErrorTranslations,
  );

  return (queryError: QueryError<ApplyAvailableCreditError>): string =>
    getErrorMessage(queryError);
};
