import { gql } from 'graphql-tag';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type CheckOverduePayments = {
  hasOverduePayments: boolean;
};

export const useCheckOverduePaymentsQuery =
  (): QueryState<CheckOverduePayments> => {
    return useQuery<CheckOverduePayments>({
      key: 'checkOverduePayments',
      request: {
        type: 'graphQL',
        target: 'v1',
        query: gql`
          query CheckOverduePayments($companyId: String!) {
            viewer {
              payments(
                company_id: $companyId
                first: 1
                filters: [ToComplete]
                orderBy: CREATED_DATE_ASC
              ) {
                edges {
                  node {
                    databaseId
                    completionDeadline {
                      status
                    }
                  }
                }
              }
            }
          }
        `,
      },
      reshapeData: (data) => {
        const firstEdge = (data?.viewer?.payments?.edges ?? []).pop();
        return {
          hasOverduePayments:
            firstEdge?.node?.completionDeadline?.status === 'late',
        };
      },
    });
  };
