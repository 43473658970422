/* eslint-disable @typescript-eslint/naming-convention */
export type BudgetGaugeConfiguration = {
  tooltip?: { mode: TooltipMode };
  highlightSegmentOnHover?: boolean;
  enforceWarningState?: boolean;
  hasAnimation?: boolean;
};

// eslint-disable-next-line no-shadow
export enum BudgetGaugeBreakdownKey {
  USED = 'used',
  COMMITTED = 'committed',
  AVAILABLE = 'available',
}

export type ClickableGaugeBreakdownKey = Exclude<
  BudgetGaugeBreakdownKey,
  'available'
>;

export type TooltipMode = 'normal' | 'compact';
