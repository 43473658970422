/* eslint-disable promise/prefer-await-to-then */
import { Button, Callout, FormField } from '@dev-spendesk/grapes';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';

import { PhoneNumberInput } from 'common/components/PhoneNumberInput';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUser } from 'modules/app/hooks/useUser';
import { useNotifications } from 'modules/app/notifications';
import { apiUrl } from 'src/core/utils/api';

import { type ApiCard } from '../../../../card';
import { MarqetaSetPin } from '../../PlasticCard/MarqetaSetPin';

type Props = {
  card: ApiCard;
  goToNextStep: () => void;
  onFinish: () => void;
};

export const Step3 = ({ card, goToNextStep, onFinish }: Props) => {
  const { t } = useTranslation();
  const { dangerNotif, successNotif } = useNotifications();

  const companyId = useCompanyId();
  const user = useUser();

  const [isUpdating, setIsUpdating] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    user && user.mobile_ext ? `+${user.mobile_ext}${user.mobile_no}` : '',
  );

  const onGetPinCodeClick = () => {
    let parsedPhoneNumber;
    try {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    } catch {
      dangerNotif(t('errors:somethingWrong_short'));
      return;
    }
    const mobile = {
      mobile_ext: parsedPhoneNumber.countryCallingCode,
      mobile_no: parsedPhoneNumber.nationalNumber,
    };

    setIsUpdating(true);
    fetch(apiUrl(`/users/${user.id}`, companyId), {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(mobile),
    })
      .then(handleError)
      .then(() =>
        fetch(apiUrl(`/cards/${card.id}/pinsms`, companyId), {
          method: 'PUT',
          credentials: 'include',
          // eslint-disable-next-line promise/no-nesting
        }).then(handleError),
      )
      .then(() => goToNextStep())
      .catch(() => setIsUpdating(false));
  };

  const handleError = (response: Response) => {
    if (response.status < 200 || response.status >= 300) {
      dangerNotif(t('errors:somethingWrong_short'));
      throw new Error(response.statusText);
    }
    return response;
  };

  const setPinCode = card.banking_provider === 'marqeta';

  return (
    <div className="w-[560px]">
      <Callout
        className="mb-m"
        variant="success"
        title={t('cards.activation.step3.cardActivated')}
      />
      <h1 className="text-complementary title-xxl">
        {setPinCode
          ? t('cards.activation.step3.setPinCode')
          : t('cards.activation.step3.confirmYourNumber')}
      </h1>
      <p className="mb-m">
        {setPinCode
          ? t('cards.activation.step3.setPinCodeSubtitle')
          : t('cards.activation.step3.verifyNumber')}
      </p>
      <div className="box flex flex-col items-center gap-m">
        {setPinCode && (
          <MarqetaSetPin
            cardId={card.id}
            companyId={companyId}
            onFinish={() => {
              successNotif(t('cards.activation.step3.setPinCodeSuccess'));
              onFinish();
            }}
          />
        )}
        {!setPinCode && (
          <>
            <FormField
              className="w-full"
              label={t('cards.activation.step3.phoneInputLabel')}
              description={t('cards.activation.step3.receiveSms')}
            >
              <PhoneNumberInput
                fit="parent"
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </FormField>

            <Button
              onClick={onGetPinCodeClick}
              text={
                isUpdating
                  ? t('cards.activation.step3.sendingLoading')
                  : t('cards.activation.step3.sendSmsCta')
              }
              isDisabled={!isValidPhoneNumber(phoneNumber)}
            />
          </>
        )}
      </div>
    </div>
  );
};
