// note: This file exposes the supplier coming from the API instead of the supplier defined in supplier.ts
// this is necessary because some modules are relying on this shape
// ideally it should only rely on the supplier defined in supplier.ts

export type RawSupplier = {
  id: string;
  name: string;
  thumbnailUrl?: string;
  validated_at: string | null;
  updated_at: string | null;
  supplier_details: {
    account_holder_name: string | null;
    account_code: null | string;
    account_number: null | string;
    address: null | string;
    bank_country: null | string;
    bic: null | string;
    city: null | string;
    country: null | string;
    iban: null | string;
    id: null | string;
    legal_name: null | string;
    registration_number: null | string;
    routing_number: null | string;
    sort_code: null | string;
    vat_number: null | string;
    zipcode: null | string;
  } | null;
  parent_id: null | string;
};

export const buildRawSupplierFromName = (
  supplierName: string,
): RawSupplier => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: null as any,
  name: supplierName,
  validated_at: null,
  updated_at: null,
  supplier_details: {
    account_holder_name: supplierName,
    account_code: null,
    account_number: null,
    address: null,
    bank_country: null,
    bic: null,
    city: null,
    country: null,
    iban: null,
    id: null,
    legal_name: null,
    registration_number: null,
    routing_number: null,
    sort_code: null,
    vat_number: null,
    zipcode: null,
  },
  parent_id: null,
});
