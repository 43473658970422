import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  user_id: string;
};

export const useTransferOrganisationMutation = (): MutationState<Payload> => {
  const { pushNotif } = useNotifications();
  const { t } = useTranslation('global');

  return useMutation<Payload>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'baseAPI',
      endpoint: '/grant-organisation-owner',
    },
    options: {
      onSuccess: () => {
        window.location.reload();
      },
      onError: () => {
        pushNotif({
          type: NotificationType.Danger,
          message: t('profile.transferOrganisationOwnershipModalErrorMessage'),
        });
      },
      throwOnError: true,
    },
  });
};
