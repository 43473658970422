import { Button } from '@dev-spendesk/grapes';
import { addWeeks, differenceInMilliseconds, formatDistance } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { es } from 'date-fns/locale/es';
import { fr } from 'date-fns/locale/fr';
import { it } from 'date-fns/locale/it';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type Language } from 'src/core/config/i18n';
import { routeFor, routes } from 'src/core/constants/routes';

import './Congrats.css';

const dateLocales = { es, fr, en: enGB, de, it };

const getDefaultExpectedDeliveryDate = () => addWeeks(new Date(), 3);

type Props = {
  numberOfCardsOrdered: number;
  expectedDeliveryDate?: number;
  companyNbPlasticCards?: number;
  clearCurrentOrder?: () => void;
};

export const Congrats = ({
  numberOfCardsOrdered,
  expectedDeliveryDate,
  companyNbPlasticCards = 1,
  clearCurrentOrder = () => {}, // for backward compatibility.
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');

  const companyId = useCompanyId();
  const history = useHistory();

  const goToCompanyCards = () => {
    history.push(routeFor(routes.CARDS.path, { company: companyId }));
  };

  useEffect(() => {
    return () => {
      clearCurrentOrder();
    };
  }, []);

  return (
    <div className="Congrats">
      <div className="box m-auto w-[500px] text-center">
        <h1 className="mb-m leading-10 text-complementary title-xxl">
          {companyNbPlasticCards === 0 && numberOfCardsOrdered === 1 ? (
            <Trans i18nKey="cardorder.congratsTitleFirstCard" />
          ) : (
            <Trans
              i18nKey="cardorder.congratsTitle"
              i18nContext={{ count: numberOfCardsOrdered }}
            />
          )}
        </h1>
        <p className="mb-m leading-6 text-complementary body-l">
          <Trans
            i18nKey="cardorder.congratsContent"
            values={{
              count: numberOfCardsOrdered,
              deliveryDate: formatDeliveryDate(
                expectedDeliveryDate
                  ? new Date(expectedDeliveryDate)
                  : getDefaultExpectedDeliveryDate(),
                activeLanguage,
              ),
            }}
          />
        </p>

        <Button onClick={goToCompanyCards} text={t('cardorder.cardsLink')} />
      </div>
    </div>
  );
};

const formatDeliveryDate = (
  expectedDeliveryDate: Date,
  activeLanguage: Language,
) => {
  const difference = differenceInMilliseconds(
    new Date(),
    new Date(expectedDeliveryDate),
  );
  return formatDistance(0, difference, {
    locale: dateLocales[activeLanguage] || dateLocales.en,
  });
};
