import { Button } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import {
  type Integration,
  type CompanyKomboConfigurationReshaped,
  type KomboAvailableFilterByDimension,
} from 'src/core/modules/integrations/types';

import { AutomaticArchivingSettings } from './AutomaticArchivingSettings';
import { KomboIntegrationConfigurationPanel } from './KomboIntegrationConfigurationPanel';
import { SettingsModal } from './SettingsModal';
import { type CurrentConfiguration } from '../types';
import { isInvalidValue } from '../utils';

type Props = {
  integration: Integration;
  companyConfiguration: CompanyKomboConfigurationReshaped;
  availableFiltersByDimension: KomboAvailableFilterByDimension;
};

export const KomboIntegrationConfiguration = ({
  integration,
  companyConfiguration,
  availableFiltersByDimension,
}: Props) => {
  const { t } = useTranslation('global');
  const { persistedFilters, automaticArchiving, syncReportingManagers } =
    companyConfiguration;

  const [configuration, setConfiguration] = useState<CurrentConfiguration>({
    currentFilters: persistedFilters,
    hasNewFilter: !persistedFilters,
  });
  // TODO:  Rename in a different PR to configuration
  const [isSettingsModalOpen, setIsSettingsModalOpen] =
    useState<boolean>(false);
  const [checkErrors, setCheckErrors] = useState<boolean>(false);

  const [syncReportingManagersSelected, setSyncReportingManagersSelected] =
    useState<boolean>(syncReportingManagers);

  const [automaticArchivingSelected, setAutomaticArchivingSelected] =
    useState<boolean>(automaticArchiving);

  const shouldDisableSaveButton =
    integration.error && integration.error !== 'pendingAction';

  return (
    <>
      {isSettingsModalOpen && (
        <SettingsModal
          currentFilters={configuration.currentFilters}
          automaticArchiving={automaticArchivingSelected}
          closeModal={() => setIsSettingsModalOpen(false)}
          isOpen={isSettingsModalOpen}
          availableFiltersByDimension={availableFiltersByDimension}
          syncReportingManagers={syncReportingManagersSelected}
        />
      )}
      <KomboIntegrationConfigurationPanel
        integration={integration}
        checkErrors={checkErrors}
        setCheckErrors={setCheckErrors}
        configuration={configuration}
        setConfiguration={setConfiguration}
        availableFiltersByDimension={availableFiltersByDimension}
        syncReportingManagersSelected={syncReportingManagersSelected}
        setSyncReportingManagersSelected={setSyncReportingManagersSelected}
      />
      <AutomaticArchivingSettings
        automaticArchivingSelected={automaticArchivingSelected}
        setAutomaticArchivingSelected={setAutomaticArchivingSelected}
      />
      <div className="flex justify-end">
        <Button
          isDisabled={shouldDisableSaveButton}
          text={t('integration.kombo.settings.save')}
          onClick={() => {
            if (!configuration.currentFilters) {
              setIsSettingsModalOpen(true);
              setCheckErrors(false);
            } else {
              const hasValidFilters =
                configuration.currentFilters.length > 0 &&
                configuration.currentFilters.every(
                  ({ values }) => !isInvalidValue(values),
                );

              setCheckErrors(!hasValidFilters);
              setIsSettingsModalOpen(hasValidFilters);
              setConfiguration({
                ...configuration,
                hasNewFilter: configuration.currentFilters.length < 1,
              });
            }
          }}
        />
      </div>
    </>
  );
};
