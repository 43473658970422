import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
  };
};

export const UserNotificationsLink = ({ company }: Props) => {
  const { t } = useTranslation('global');
  const { pathname: currentPath } = useLocation();

  return (
    <NavigationLink
      data-marketing="top-menu_user-notifications"
      key="nav_user_notifications"
      path={routeFor(routes.ACCOUNT_NOTIFICATIONS_GENERAL.path, {
        company: company.id,
      })}
      text={t('settings.links.notifications')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [routes.ACCOUNT_NOTIFICATIONS.path],
        }),
      )}
    />
  );
};
