import { useMutation } from 'src/core/api/hooks/useMutation';

export const useNotifyTransfersApprovers = () => {
  return useMutation({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/finance-controllers/notification/transfers`,
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
  });
};
