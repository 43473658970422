import { Button, Modal } from '@dev-spendesk/grapes';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { routes, routeFor } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useSupervisor } from 'src/core/modules/app/hooks/useSupervisor';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import { useTranslation } from '../../../../../common/hooks/useTranslation';

export const ApiOAuth2AuthorizePage = () => {
  const companyId = useCompanyId();
  const user = useUser();
  const supervisor = useSupervisor();
  const { t } = useTranslation('global');

  const urlQueryParams = new URLSearchParams(
    new URL(window.location.href).search,
  );
  const tokenId = urlQueryParams.get('id') as string;
  const authCode = urlQueryParams.get('code') as string;
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState<{
    pathname: string;
    search: string;
  }>();

  // We're simply redirecting to the Integrations page where the connection
  // will be handled
  useEffect(() => {
    if (user.is_account_owner) {
      const pathname = routeFor(routes.COMPANY_INTEGRATIONS_ALL.path, {
        company: companyId,
      });

      const search = queryString.stringify({
        oauth2Authorize: true,
        id: tokenId,
        code: authCode,
      });

      setRedirectTo({ pathname, search });
    }
  }, [user.is_account_owner]);

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  if (!user.is_account_owner || supervisor) {
    return (
      <Modal
        title={t('publicApi.flowOAuth2.partner.noAoModal.title')}
        iconVariant="alert"
        iconName="cross"
        isOpen
        actions={[
          <Button
            variant="primary"
            text={t('publicApi.flowOAuth2.partner.noAoModal.returnToHomepage')}
            key={0}
            onClick={() =>
              setRedirectTo({
                pathname: routeFor(routes.HOMEPAGE.path, {
                  company: companyId,
                }),
                search: '',
              })
            }
          />,
        ]}
      >
        {t('publicApi.flowOAuth2.partner.noAoModal.content')}
      </Modal>
    );
  }

  return <></>;
};
