import type * as NEA from '@dev-spendesk/general-type-helpers/NonEmptyArray';
import { useHistory } from 'react-router-dom';

import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useNotifications } from 'src/core/modules/app/notifications';

type Payload =
  | {
      supplierIds: NEA.NonEmptyArray<string>;
    }
  | {
      accountPayableIds: NEA.NonEmptyArray<string>;
    };

export const useBulkUnarchiveSupplierMutation = (): MutationState<Payload> => {
  const history = useHistory();
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const { successNotif, dangerNotif } = useNotifications();

  return useMutation<Payload>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/suppliers/bulk-unarchive',
    },
    options: {
      onError() {
        dangerNotif(
          t(
            'bookkeep.accountsPayable.panel.supplierUnarchivedErrorNotification',
          ),
        );
      },
      onSuccess({ client, payload }): void {
        successNotif(
          'supplierIds' in payload
            ? t(
                'bookkeep.accountsPayable.panel.supplierUnarchivedNotification',
                {
                  count: payload.supplierIds.length,
                },
              )
            : t(
                'bookkeep.accountsPayable.panel.accountPayableSupplierUnarchivedNotification',
              ),
        );

        history.push(
          routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
            company: companyId,
          }),
        );

        client.invalidateQueries(['useAccountPayableSuppliersListQuery']);
        client.invalidateQueries([
          'useGetSuppliersWithoutAccountPayableListQuery',
        ]);

        if ('supplierIds' in payload) {
          for (const supplierId of payload.supplierIds) {
            client.invalidateQueries([
              'suppliers',
              'suppliersDetails',
              supplierId,
            ]);
          }
        }
      },
    },
  });
};
