import React from 'react';

type Props = {
  imgSrc: string;
  title: string;
  description: string;
};

export const Block = ({ imgSrc, title, description }: Props) => {
  return (
    <div className="flex flex-col items-center gap-xs">
      <div>
        <img src={imgSrc} alt="" />
      </div>
      <h3 className="text-complementary title-l">{title}</h3>
      <p className="text-center text-neutral-dark body-m">{description}</p>
    </div>
  );
};
