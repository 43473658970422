import { useCostCenterMembersQuery } from 'modules/budgets/apis';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import { useControlRulesQuery } from './useControlRulesQuery';
import { useInvitesQuery } from './useInvitesQuery';
import { useMembersQueryRaw } from './useMembersQueryRaw';
import { usePoliciesQuery } from './usePoliciesQuery';
import { useTeamsQuery } from './useTeamsQuery';
import { useCompany } from '../../app/hooks/useCompany';
import type { Member } from '../models/member';
import { reshapeMember } from '../utils/reshapeMember';

export const useMembersQuery = (): QueryState<Member[]> => {
  const company = useCompany();

  return useQueryStates({
    states: {
      members: useMembersQueryRaw(),
      teams: useTeamsQuery(),
      controlRules: useControlRulesQuery(),
      policies: usePoliciesQuery(),
      invites: useInvitesQuery(),
      membersCostCenter: useCostCenterMembersQuery(),
    },
    reshapeData: ({
      members,
      teams,
      controlRules,
      policies,
      invites,
      membersCostCenter,
    }) => {
      return members.map((item) =>
        reshapeMember(item, {
          company,
          teams,
          controlRules,
          policies,
          invites,
          membersCostCenter,
        }),
      );
    },
  });
};
