export enum DocumentaryEvidenceType {
  invoice = 'invoice',
  declarationOfMissingInvoice = 'declarationOfMissingInvoice',
  creditNote = 'creditNote',
  mileageEntry = 'mileageEntry',
  perDiemEntry = 'perDiemEntry',
}

export type DocumentaryEvidenceValidity = {
  valid: boolean;
  reason?: string;
};

export type RawPayableDocumentaryEvidenceAttachment = {
  mediaType: string;
  url: string;
  downloadUrl: string;
  thumbnailUrl: string;
  attachedAt: string;
  path: string;
};
