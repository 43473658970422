import { FormField, TextInput } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useGetValidTaxCodesQuery } from '../../../../../../hooks/useGetValidTaxCodesQuery';
import { type ModalState } from '../ModalState';
import styles from '../TaxAccountLocalOnlySection.module.css';

interface Props {
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
}

export const DatevReverseChargeAddEditForm = ({
  formState,
  setFormState,
}: Props) => {
  const { t } = useTranslation();

  const useGetValidTaxCodes = useGetValidTaxCodesQuery(true);

  const reverseChargeBuCodes =
    useGetValidTaxCodes &&
    useGetValidTaxCodes.status === 'success' &&
    useGetValidTaxCodes.data
      ? useGetValidTaxCodes.data.buCodes
      : [];

  return (
    <>
      {formState.kind === 'addReverseCharge' && (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.reverseChargeAccountsTable.accountName',
            )}
            className={styles.formField}
          >
            <TextInput
              value={formState.name}
              onChange={(e) =>
                setFormState({ ...formState, name: e.target.value })
              }
            />
          </FormField>
          <FormField
            label={t('bookkeep.integrations.datev.reverseChargeAddEdit.buCode')}
            className={styles.formField}
          >
            <AutocompleteSearch
              fit="parent"
              options={reverseChargeBuCodes.map((buCode) => ({
                key: buCode.code,
                label: buCode.code,
              }))}
              value={
                formState.codeDebit
                  ? { key: formState.codeDebit, label: formState.codeDebit }
                  : undefined
              }
              onSelect={(option) => {
                if (option) {
                  setFormState({
                    ...formState,
                    codeDebit: option.key,
                    codeCredit: option.key,
                    inputChanged: true,
                  });
                }
              }}
              renderNoOptions={() => (
                <>
                  {t(
                    'bookkeep.integrations.datev.reverseChargeAddEdit.noOption',
                  )}
                </>
              )}
            />
          </FormField>
        </>
      )}
    </>
  );
};
