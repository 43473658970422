import { useMutation } from 'src/core/api/hooks/useMutation';

import { useInvalidateControlRulesQuery } from './useControlRulesQuery';
import { useInvalidateMemberQuery } from './useMemberQuery';

type Payload = {
  userIds: string[];
  endpointParams: {
    controlRuleId: string;
  };
};

export const useBulkUpdateControlRuleMutation = () => {
  const invalidateMembers = useInvalidateMemberQuery();
  const invalidateControlRules = useInvalidateControlRulesQuery();
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: ({ controlRuleId }) =>
        `control-rules/${controlRuleId}/bulk-assign`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ payload }): void => {
        invalidateMembers(payload.userIds);
        invalidateControlRules();
      },
    },
  });
};
