import { PageModal, Button } from '@dev-spendesk/grapes';
import { type MouseEventHandler, useMemo } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { type PartnerOnboardingError } from 'modules/kyc/hooks/usePartnerOnboardingErrorsQuery';
import { BASE_PATH, routes } from 'src/kyc/common/constants/routes';

type Props = {
  isOpen: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  partnerOnboardingErrors: PartnerOnboardingError[];
};

const sortByCode = (a: PartnerOnboardingError, b: PartnerOnboardingError) => {
  if (a.partnerErrorCode < b.partnerErrorCode) {
    return -1;
  }
  if (a.partnerErrorCode > b.partnerErrorCode) {
    return 1;
  }
  return 0;
};

export const MissingInformationModal = ({
  isOpen,
  onClose,
  partnerOnboardingErrors,
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const user = useUser();

  const companyErrors = useMemo(
    () =>
      partnerOnboardingErrors
        .filter(({ entityType }) => entityType === 'ORGANISATION')
        .sort(sortByCode),
    [partnerOnboardingErrors],
  );

  const getOnboardingErrorSection = (
    onboardingErrors: PartnerOnboardingError[],
    title: string,
  ) => {
    if (!onboardingErrors.length) {
      return null;
    }

    return (
      <div className="w-full rounded-xs border-[1px] border-solid border-neutral-light bg-white p-s">
        <h3 className="text-l">{title}</h3>
        <ul>
          {onboardingErrors.map((error) => (
            <li key={error.partnerErrorCode}>{error.errorDescription}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <PageModal isOpen={isOpen} onClose={onClose}>
      <div className="mx-auto my-l max-w-[822px]">
        <h2 className="text-xxl text-neutral-darker">
          {t('onboardingHub.kycTask.adyen.hello', {
            name: user.display_name,
          })}
        </h2>
        <p className="mt-m text-xl text-neutral-darker">
          {t('onboardingHub.kycTask.adyen.missingInformationList', {
            count: companyErrors.length,
            companyName: company.name,
          })}
        </p>

        <div className="mt-m flex flex-col gap-s">
          {getOnboardingErrorSection(
            companyErrors,
            t('onboardingHub.kycTask.adyen.companyErrors'),
          )}

          <p className="mt-s">{t('onboardingHub.kycTask.adyen.updateNote')}</p>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 w-full border-x-0 border-b-0 border-solid border-neutral-lighter bg-white">
        <Button
          key="open-adyen"
          component="a"
          className="mx-auto my-s flex w-fit"
          variant="primary"
          text={t('onboardingHub.kycTask.adyen.updateOnAdyen')}
          href={`${BASE_PATH}${routes.ADYEN_REDIRECT.path.replace(':companyId', company.id)}`}
          target="_blank"
        />
      </div>
    </PageModal>
  );
};
