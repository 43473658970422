import { useTranslation } from 'react-i18next';

import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';

type Props = {
  closeMfaModal: () => void;
  fetchUserHasValidMfa: () => Promise<boolean | undefined>;
  setHasMfaUserError: (hasError: boolean) => void;
};

export const useMfaModalActions = ({
  fetchUserHasValidMfa,
  setHasMfaUserError,
  closeMfaModal,
}: Props) => {
  const { pushNotif } = useNotifications();
  const { t } = useTranslation();

  const handleMfaSetup = async (): Promise<void> => {
    const payload = await fetchUserHasValidMfa();
    if (payload) {
      setHasMfaUserError(false);
      closeMfaModal();
      pushNotif({
        type: NotificationType.Success,
        message: t('bookkeep.integrations.xero.modal.mfa.activateMfa.success'),
      });
    } else {
      setHasMfaUserError(true);
    }
  };

  return {
    actions: {
      handleMfaSetup,
    },
  };
};
