import cx from 'classnames';
import React from 'react';

import './LegacySubscriptionItem.css';

export const LegacySubscriptionItem = ({
  base,
  extra = [],
  isAddon = false,
}: {
  base: string;
  extra: string[];
  isAddon?: boolean;
}) => {
  return (
    <li
      className={cx('LegacySubscriptionItem', {
        'LegacySubscriptionItem--addon': isAddon,
      })}
    >
      <div className="LegacySubscriptionItem__content">
        <span className="LegacySubscriptionItem__base">{base}</span>
        <ul className="LegacySubscriptionItem__extra">
          {extra?.map((extraItem) => (
            <li key={extraItem} className="LegacySubscriptionItem__extra-item">
              {extraItem}
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};
