import { gql } from 'graphql-tag';

export const SET_EXPENSE_ACCOUNT_SUPPLIER_RULE = gql`
  mutation SetExpenseAccountSupplierRule(
    $companyId: ID!
    $supplierId: ID!
    $expenseAccountId: ID!
  ) {
    setExpenseAccountSupplierRule(
      companyId: $companyId
      supplierId: $supplierId
      expenseAccountId: $expenseAccountId
    ) {
      __typename
      ... on SetExpenseAccountSupplierRuleResultNotSet {
        reason
      }
    }
  }
`;
