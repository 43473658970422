import * as controlRuleActions from './actions';
import { type ControlRule } from '../controlRule';

interface ControlRulesState {
  controlRules: ControlRule[];
  selectedControlRule: ControlRule | undefined;
  behavior: {
    isFetchingUserRule: boolean;
    // administration loading states
    isFetching: boolean;
    isCreating: boolean;
    isRemoving: boolean;
    isUpdating: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

const initialState: ControlRulesState = {
  controlRules: [],
  selectedControlRule: undefined,
  behavior: {
    isFetchingUserRule: false,
    isFetching: false,
    isCreating: false,
    isRemoving: false,
    isUpdating: false,
  },
  error: undefined,
};

const fetchControlRulesRequest = (
  state: ControlRulesState,
): ControlRulesState => {
  return {
    ...state,
    behavior: {
      ...state.behavior,
      isFetching: true,
    },
  };
};

const fetchControlRulesSuccess = (
  state: ControlRulesState,
  { payload }: controlRuleActions.FetchControlRulesSuccess,
): ControlRulesState => ({
  ...state,
  behavior: {
    ...state.behavior,
    isFetching: false,
  },
  controlRules: payload,
});

const fetchControlRulesFailure = (
  state: ControlRulesState,
  { payload }: controlRuleActions.FetchControlRulesFailure,
): ControlRulesState => ({
  ...state,
  behavior: {
    ...state.behavior,
    isFetching: false,
  },
  error: payload,
});

const createControlRuleRequest = (
  state: ControlRulesState,
): ControlRulesState => {
  return {
    ...state,
    behavior: {
      ...state.behavior,
      isCreating: true,
    },
  };
};

const createControlRuleSuccess = (
  state: ControlRulesState,
  { payload }: controlRuleActions.CreateControlRuleSuccess,
): ControlRulesState => {
  const { controlRuleCreated, userId, previousControlRule } = payload;

  const controlRulesWithNew = [...state.controlRules, controlRuleCreated];

  const updatedControlRules = controlRulesWithNew.map((controlRule) => {
    if (previousControlRule && controlRule.id === previousControlRule.id) {
      return {
        ...controlRule,
        userIds: previousControlRule.userIds.filter((uid) => uid !== userId),
      };
    }
    return controlRule;
  });

  return {
    ...state,
    behavior: {
      ...state.behavior,
      isCreating: false,
    },
    controlRules: updatedControlRules,
    selectedControlRule: undefined,
  };
};

const createControlRuleFailure = (
  state: ControlRulesState,
  { payload }: controlRuleActions.CreateControlRuleFailure,
): ControlRulesState => ({
  ...state,
  behavior: {
    ...state.behavior,
    isCreating: false,
  },
  error: payload,
});

const removeControlRuleSuccess = (
  state: ControlRulesState,
  { payload }: controlRuleActions.RemoveControlRuleSuccess,
): ControlRulesState => ({
  ...state,
  controlRules: state.controlRules.filter(
    (controlRule) => controlRule.id !== payload,
  ),
  behavior: {
    ...state.behavior,
    isRemoving: false,
  },
});

const removeControlRuleRequest = (
  state: ControlRulesState,
): ControlRulesState => ({
  ...state,
  behavior: {
    ...state.behavior,
    isRemoving: true,
  },
});

const updateControlRuleRequest = (
  state: ControlRulesState,
): ControlRulesState => {
  return {
    ...state,
    behavior: {
      ...state.behavior,
      isUpdating: true,
    },
  };
};

const updateControlRuleSuccess = (
  state: ControlRulesState,
  {
    payload: { controlRuleUpdated, previousControlRule, userId },
  }: controlRuleActions.UpdateControlRuleSuccess,
): ControlRulesState => {
  const updatedControlRules = state.controlRules.map((controlRule) => {
    // if there is an updated control rule, replace it in the list
    if (controlRule.id === controlRuleUpdated?.id) {
      return controlRuleUpdated;
    }
    // remove the user from the previous control rule
    if (previousControlRule && controlRule.id === previousControlRule.id) {
      return {
        ...controlRule,
        userIds: previousControlRule.userIds.filter((uid) => uid !== userId),
      };
    }
    return controlRule;
  });

  let selectedControlRule;
  if (controlRuleUpdated && !controlRuleUpdated.isCustom) {
    selectedControlRule =
      state.selectedControlRule?.id === controlRuleUpdated.id
        ? controlRuleUpdated
        : state.selectedControlRule;
  }

  return {
    ...state,
    behavior: {
      ...state.behavior,
      isUpdating: false,
    },
    controlRules: updatedControlRules,
    selectedControlRule,
  };
};

const setSelectedControlRule = (
  state: ControlRulesState,
  { payload }: controlRuleActions.SetSelectedControlRule,
): ControlRulesState => ({
  ...state,
  selectedControlRule: state.controlRules.find(
    (rule: ControlRule) => rule.id === payload,
  ),
});

const resetSelectedControlRule = (
  state: ControlRulesState,
): ControlRulesState => ({
  ...state,
  selectedControlRule: undefined,
});

export const reducer = (
  state: ControlRulesState = initialState,
  action: controlRuleActions.ControlRuleAction,
): ControlRulesState => {
  switch (action.type) {
    case controlRuleActions.FETCH_CONTROL_RULES_REQUEST:
      return fetchControlRulesRequest(state);
    case controlRuleActions.FETCH_CONTROL_RULES_SUCCESS:
      return fetchControlRulesSuccess(state, action);
    case controlRuleActions.FETCH_CONTROL_RULES_FAILURE:
      return fetchControlRulesFailure(state, action);
    case controlRuleActions.CREATE_CONTROL_RULE_REQUEST:
      return createControlRuleRequest(state);
    case controlRuleActions.CREATE_CONTROL_RULE_SUCCESS:
      return createControlRuleSuccess(state, action);
    case controlRuleActions.CREATE_CONTROL_RULE_FAILURE:
      return createControlRuleFailure(state, action);
    case controlRuleActions.REMOVE_CONTROL_RULE_SUCCESS:
      return removeControlRuleSuccess(state, action);
    case controlRuleActions.REMOVE_CONTROL_RULE_REQUEST:
      return removeControlRuleRequest(state);
    case controlRuleActions.UPDATE_CONTROL_RULE_REQUEST:
      return updateControlRuleRequest(state);
    case controlRuleActions.UPDATE_CONTROL_RULE_SUCCESS:
      return updateControlRuleSuccess(state, action);
    case controlRuleActions.SET_SELECTED_CONTROL_RULE:
      return setSelectedControlRule(state, action);
    case controlRuleActions.RESET_SELECTED_CONTROL_RULE:
      return resetSelectedControlRule(state);
    default:
      return state;
  }
};
