import React from 'react';

import { PageEmptyState } from "src/core/common/components/PageEmptyState";
import { useTranslation } from "src/core/common/hooks/useTranslation";

import illustrationSourceDe from './illustration_de.svg';
import illustrationSourceEn from './illustration_en.svg';
import illustrationSourceFr from './illustration_fr.svg';

type Props = {
  onActionClick(): void;
};

export const BudgetaryExercisePageEmptyState = ({ onActionClick }: Props) => {
  const { t, activeLanguage } = useTranslation('global');

  let illustrationSource;
  switch (activeLanguage) {
    case 'de':
      illustrationSource = illustrationSourceDe;
      break;
    case 'en':
      illustrationSource = illustrationSourceEn;
      break;
    default:
      illustrationSource = illustrationSourceFr;
      break;
  }

  return (
    <PageEmptyState
      illustrationSrc={illustrationSource}
      title={t('budget.budgetaryExercise.empty.title')}
      description={t('budget.budgetaryExercise.empty.text')}
      buttonLabel={t('budget.budgetaryExercise.empty.button')}
      onClick={onActionClick}
    />
  );
};
