import { authAPI } from 'src/core/api/axios';

export const validateMfaProcedureWithSca = async (
  mfaToken: string,
  scaToken: string,
): Promise<void> => {
  await authAPI.post(
    '/sca/validate-procedure-with-mfa',
    { token: mfaToken },
    { headers: { Authorization: `Bearer ${scaToken}` } },
  );
};
