import { Callout, SkeletonButton } from '@dev-spendesk/grapes';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense/';
import { useTranslation } from 'common/hooks/useTranslation';

import {
  PurchaseOrderAutocompleteSearch,
  type PurchaseOrderAutocompleteSearchProps,
} from './PurchaseOrderAutocompleteSearch';
import { useFetchPurchaseOrdersQuery } from '../../hooks';
import { NoAvailablePurchaseOrderMessage } from '../NoAvailablePurchaseOrderMessage';

type PurchaseOrderAutocompleteProps = Omit<
  PurchaseOrderAutocompleteSearchProps,
  'purchaseOrders'
> & {
  showNoAvailablePurchaseOrderMessage: boolean;
};

export const PurchaseOrderAutocomplete = ({
  showNoAvailablePurchaseOrderMessage,
  ...autocompleteProps
}: PurchaseOrderAutocompleteProps) => {
  // TODO https://spendesk.atlassian.net/browse/EMP-1035
  const openPurchaseOrdersQueryState = useFetchPurchaseOrdersQuery(
    {
      status: 'open',
    },
    1500,
  );
  const { t } = useTranslation('global');

  return (
    <QuerySuspense
      queryState={openPurchaseOrdersQueryState}
      loading={
        <SkeletonButton
          width={autocompleteProps.fit === 'parent' ? '100%' : undefined}
        />
      }
      fallback={() => (
        <Callout
          title={t('forms.purchaseOrder.errorLoading')}
          variant="alert"
        />
      )}
    >
      {(purchaseOrders) => (
        <>
          <PurchaseOrderAutocompleteSearch
            purchaseOrders={purchaseOrders}
            {...autocompleteProps}
          />
          {purchaseOrders.length === 0 &&
            showNoAvailablePurchaseOrderMessage && (
              <NoAvailablePurchaseOrderMessage className="mt-xs" />
            )}
        </>
      )}
    </QuerySuspense>
  );
};
