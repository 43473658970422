import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  username: string;
};

export const useSendPasswordResetMutation = (): MutationState<Payload> => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'authAPI',
      endpoint: '/password/forgotten',
    },
  });
};
