import React, { useMemo } from 'react';

import { ErrorState } from 'src/core/common/components/ErrorState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { OperationalOnboardingContent } from './OperationalOnboardingContent/OperationalOnboardingContent';
import { OperationalOnboardingHeader } from './OperationalOnboardingHeader';
import { OperationalOnboardingSkeleton } from './OperationalOnboardingSkeleton/OperationalOnboardingSkeleton';
import { type useGetWelcomeDashboardQuery } from '../../../hooks/useGetWelcomeDashboardQuery';
import styles from '../OperationalOnboarding.module.css';

export type Props = {
  getWelcomeDashboardQueryState: ReturnType<typeof useGetWelcomeDashboardQuery>;
};

export const OperationalOnboarding = ({
  getWelcomeDashboardQueryState,
}: Props) => {
  const { t } = useTranslation('global');

  const { status } = getWelcomeDashboardQueryState;
  const isLoading = status === 'loading';
  const displayErrorState =
    status === 'error' ||
    (status === 'success' && !getWelcomeDashboardQueryState.data);
  const hasRemainingSteps = useMemo(() => {
    if (getWelcomeDashboardQueryState.status === 'success') {
      return getWelcomeDashboardQueryState.data?.tasks.some(
        (task) => task.status === 'ongoing',
      );
    }
  }, [getWelcomeDashboardQueryState]);

  if (isLoading) {
    return <OperationalOnboardingSkeleton />;
  }

  if (!hasRemainingSteps) {
    return null;
  }

  return (
    <div className="box w-full p-0">
      <OperationalOnboardingHeader
        operationalOnboarding={
          status === 'success' ? getWelcomeDashboardQueryState.data : undefined
        }
      />
      {status === 'success' && getWelcomeDashboardQueryState.data && (
        <OperationalOnboardingContent
          operationalOnboarding={getWelcomeDashboardQueryState.data}
        />
      )}

      {displayErrorState && (
        <ErrorState
          className={styles.errorState}
          title={t('homepage.welcomeDashboard.loadErrorTitle')}
          description={t('homepage.welcomeDashboard.loadErrorDescription')}
        />
      )}
    </div>
  );
};
