import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { MARK_ANNOUNCEMENT_SEEN } from './mutations';
import { GET_COMPANY } from './queries';
import { reshapeCompanyData } from '../models';
import { type Company } from '../types';

export type CompanyQueryVariables = { companyId: string };
export type CompanyQueryResponse = { company: Company };

export const useGetCompanyQuery = (companyId: string): QueryState<Company> => {
  const variables: CompanyQueryVariables = { companyId };

  return useQuery<Company, CompanyQueryResponse>({
    key: ['useGetCompanyQuery', companyId],
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_COMPANY,
      variables,
    },
    reshapeData: reshapeCompanyData,
  });
};

type MarkAnnouncementSeenPayload = {
  companyId: string;
  code: string;
};

type MarkAnnouncementSeenRawResponse = {
  markAnnouncementSeen: {
    outcome:
      | 'success'
      | 'company_not_found'
      | 'code_not_found'
      | 'already_seen';
    announcement?: {
      code: string;
      lastSeenAt?: string;
    };
  };
};

type MarkAnnouncementSeenResponse = {
  markAnnouncementSeen: {
    outcome:
      | 'success'
      | 'company_not_found'
      | 'code_not_found'
      | 'already_seen';
    announcement?: {
      code: string;
      lastSeenAt?: string;
    };
  };
};

export const useMarkAnnouncementSeen = (
  companyId: string,
): MutationState<MarkAnnouncementSeenPayload, MarkAnnouncementSeenResponse> => {
  return useMutation<
    MarkAnnouncementSeenPayload,
    MarkAnnouncementSeenResponse,
    MarkAnnouncementSeenRawResponse
  >({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: MARK_ANNOUNCEMENT_SEEN,
    },
    options: {
      onSuccess: ({ client }): void => {
        client.invalidateQueries(['useGetCompanyQuery', companyId]);
      },
    },
    reshapeData: (data) => data,
  });
};
