import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { type CurrenciesKey } from 'src/core/config/money';

export type CardPayment = {
  id: string;
  description: string;
  supplier?: {
    name: string;
  };
  amount_declared: number;
  currency_declared: CurrenciesKey;
  paid_at?: string;
  created_at: string;
};

export const useCardPaymentsQuery = (
  cardId: string,
): QueryState<CardPayment[]> => {
  const params = { 'payment[card_id]': cardId };

  return useQuery<CardPayment[], CardPayment[]>({
    key: ['payments', params],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/payments',
      params,
    },
    reshapeData: (data) => data,
  });
};
