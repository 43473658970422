import { Button, Input, Icon, Avatar, Tag } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { type MouseEventHandler } from 'react';

import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { getAccountingIntegrationDetails } from '../../../../integrations/helper';

import './AccountingBaseInput.css';

const getAccountingIntegrationIcon = (
  logoPath: string,
  translatedI18nTitle: string,
): React.ReactNode => (
  <span className="AccountingBaseInput__leftIcon">
    <Avatar
      className="AccountingBaseInput__avatar"
      variant="square"
      src={logoPath}
      fallbackSrc={fallbackSupplierLogoSrc}
      text={translatedI18nTitle}
      size="s"
    />
  </span>
);

export function AccountingBaseInput({
  value,
  onClick,
  className,
}: {
  value: AccountingSoftware;
  onClick?: MouseEventHandler<HTMLElement>;
  className?: string;
}) {
  const { t } = useTranslation();

  const {
    i18nSelectTitle: i18nTitle,
    logoPath,
    i18nSelectType: i18nType,
    isBeta,
  } = getAccountingIntegrationDetails(value);
  return (
    <div
      data-testid="accounting-base-input"
      className={classNames('AccountingBaseInput', className)}
    >
      <div
        data-testid="accounting-base-input-input"
        className="AccountingBaseInput__input"
      >
        {getAccountingIntegrationIcon(logoPath, t(i18nTitle))}
        <span className="AccountingBaseInput_integration body-m">
          {t(i18nTitle)}
        </span>
        &nbsp;
        <span className="AccountingBaseInput_integrationType body-m">
          {t(i18nType)}
        </span>
        {isBeta && (
          <>
            &nbsp;
            <Tag variant="primary">{t('bookkeep.integrations.openBeta')}</Tag>
          </>
        )}
      </div>
      <Button
        className="AccountingBaseInput__edit"
        variant="secondary"
        text={t('bookkeep.integrations.settings.baseSelect.switch')}
        onClick={(event) => onClick && onClick(event)}
      />
    </div>
  );
}

export function AccountingBaseToggle({
  value,
}: {
  value: AccountingSoftware | 'noIntegration';
}) {
  const { t } = useTranslation();

  if (value === 'noIntegration') {
    return (
      <Input
        className="AccountingBaseToggle"
        value=""
        isReadOnly
        type="text"
        fit="parent"
        placeholder={t('bookkeep.integrations.settings.baseSelect.placeholder')}
        rightAddon={
          <span className="AccountingBaseInput__rightIcon">
            <Icon color="currentColor" name="caret-down" size="m" />
          </span>
        }
      />
    );
  }

  const { i18nSelectTitle: i18nTitle, logoPath } =
    getAccountingIntegrationDetails(value);
  return (
    <Input
      className="AccountingBaseToggle"
      value={t(i18nTitle)}
      isReadOnly
      type="text"
      fit="parent"
      placeholder={t('bookkeep.integrations.settings.baseSelect.placeholder')}
      leftAddon={getAccountingIntegrationIcon(logoPath, t(i18nTitle))}
      rightAddon={
        <span className="AccountingBaseInput__rightIcon">
          <Icon color="currentColor" name="caret-down" size="m" />
        </span>
      }
    />
  );
}
