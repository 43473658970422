import React, { useState, useEffect } from 'react';

import Modal from 'common/components/legacy/Modal/Modal';
import { useTranslation } from 'common/hooks/useTranslation';

import { MarqetaSetPin } from './MarqetaSetPin';
import { MarqetaSetPinSuccess } from './MarqetaSetPinSuccess';

import './MarqetaSetPinModal.css';

type Props = {
  cardId: string;
  companyId: string;
  onClose(): void;
};

export const MarqetaSetPinModal = ({ cardId, companyId, onClose }: Props) => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setIsSuccess(false);
  }, [cardId]);

  return (
    <Modal
      className="MarqetaSetPinModal"
      title={t('cards.setPinWidget.title')}
      exitAction={onClose}
      outsideClickAction={onClose}
    >
      {isSuccess ? (
        <MarqetaSetPinSuccess onClose={onClose} />
      ) : (
        <MarqetaSetPin
          cardId={cardId}
          companyId={companyId}
          onFinish={() => {
            setIsSuccess(true);
          }}
        />
      )}
    </Modal>
  );
};
