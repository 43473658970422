import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type CustomFieldUpdatePayload = {
  value: string;
  fieldValueId: string;
};

type CustomFieldCreateResponse = {
  custom_field_id: string;
  id: string;
  value: string;
};

export const useUpdateCustomFieldValueMutation = (
  customFieldId: string,
): MutationState<CustomFieldUpdatePayload> => {
  return useMutation<CustomFieldUpdatePayload, CustomFieldCreateResponse>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/custom-fields/values',
      method: 'put',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['useCustomFieldValuesQuery', customFieldId]);
      },
    },
  });
};
