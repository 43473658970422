export type ExpenseAccount = {
  id: string;
  name: string;
  code: string;
  isDefault: boolean;
  isArchived: boolean;
  isNew?: boolean;
  isAvailable?: boolean;
};

export type ExpenseAccountUpdate = {
  id?: string;
  code: string;
  name: string;
  isArchived: boolean;
};

export const getDefaultExpenseAccountName = (
  accountingCountry: string,
): string => {
  switch (accountingCountry) {
    case 'FR':
      return 'Compte par défaut';
    case 'DE':
      return 'Sammelkonto (Sachkonten)';
    default:
      return 'Default account';
  }
};
