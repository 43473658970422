import { Icon, Tooltip } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';

import { useJiraLink } from './useJiraLink';

import './JiraIssueReporter.css';

export function JiraIssueReporter() {
  const { t } = useTranslation('global');
  const link = useJiraLink();

  if (link === null) {
    return null;
  }

  return (
    <Tooltip content={t('jiraIssueReporter.buttonTooltip')} triggerAsChild>
      <a
        href={link}
        target="_about"
        className="JiraIssueReporter__link"
        aria-label="Report issue on Jira"
      >
        <Icon name="service-bell" aria-hidden="true" />
      </a>
    </Tooltip>
  );
}
