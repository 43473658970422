import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useAccountingIntegrationName } from 'src/core/modules/accounting-integration/apis';
import { SupplierOrMemberSyncStatus } from 'src/core/modules/bookkeep/components/SupplierOrMemberSyncStatus';
import {
  getCounterpartySuppliersTitle,
  getCounterpartySuppliersTooltipText,
} from 'src/core/modules/bookkeep/payables/components/PayablePanel/translations';
import { useSupplierSyncStatus } from 'src/core/modules/suppliers/hooks';

export const SupplierSyncStatusForIntegration = ({
  supplierId,
}: {
  supplierId: string | undefined;
}) => {
  const { t } = useTranslation('global');
  const supplierSyncStatus = useSupplierSyncStatus(supplierId);
  const integrationName = useAccountingIntegrationName();

  return (
    <>
      {supplierSyncStatus.status === 'success' && (
        <div className="-mb-xs">
          <SupplierOrMemberSyncStatus
            label={
              supplierSyncStatus.data.synced
                ? getCounterpartySuppliersTitle(t, integrationName).synced
                : getCounterpartySuppliersTitle(t, integrationName).notSynced
            }
            tooltipText={getCounterpartySuppliersTooltipText(
              t,
              integrationName,
            )}
            {...supplierSyncStatus.data}
          />
        </div>
      )}
    </>
  );
};
