import { Callout } from '@dev-spendesk/grapes';
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { useModal } from 'common/hooks/useModal';
import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { useBudgetQuery } from './hooks/useBudgetQuery';
import { useExpenseCategoriesWithArchivedQuery } from './hooks/useExpenseCategoriesWithArchivedQuery';
import { useIsUserAllowedToAccessBudgets } from './hooks/useIsUserAllowedToAccessBudgets';
import { BudgetOverviewPage } from '../../components/BudgetOverviewPage';
import { BudgetPageLoader } from '../../components/BudgetPageLoader';
import { DeleteBudgetModalContainer } from '../DeleteBudgetModalContainer';
import { useBudgetaryExerciseQuery } from '../hooks/useBudgetaryExerciseQuery';

export const BudgetOverviewPageContainer = withErrorBoundary({
  team: 'budget-owner',
  scope: 'budget-overview',
})(() => {
  const { id: budgetId, company } = useParams();

  const { t } = useTranslation('global');
  const history = useHistory();
  const isUserAllowedToAccessBudgets =
    useIsUserAllowedToAccessBudgets(budgetId);
  const budgetQueryState = useBudgetQuery(budgetId);
  const expenseCategoriesState = useExpenseCategoriesWithArchivedQuery();
  const budgetaryExerciseQueryState = useBudgetaryExerciseQuery(
    budgetQueryState.status === 'success'
      ? budgetQueryState.data.budgetaryExerciseId
      : undefined,
  );

  useEffect(() => {
    if (
      isUserAllowedToAccessBudgets.status === 'success' &&
      isUserAllowedToAccessBudgets.data === false
    ) {
      history.replace(routeFor(routes.HOMEPAGE.path, { company }));
    }
  }, [isUserAllowedToAccessBudgets]);

  const [deleteBudgetModal, showDeleteBudgetModal, hideDeleteBudgetModal] =
    useModal(() => {
      if (budgetQueryState.status !== 'success') {
        return null;
      }
      return (
        <DeleteBudgetModalContainer
          budget={budgetQueryState.data}
          onClose={() => hideDeleteBudgetModal()}
        />
      );
    });

  if (isUserAllowedToAccessBudgets.status === 'error') {
    return (
      <Callout
        variant="alert"
        title={t('budget.errors.unknown')}
        className="BudgetaryExercisesPageContainer__error"
      />
    );
  }

  if (
    isUserAllowedToAccessBudgets.status === 'loading' ||
    budgetQueryState.status !== 'success' ||
    budgetaryExerciseQueryState.status !== 'success' ||
    !budgetaryExerciseQueryState.data ||
    expenseCategoriesState.status !== 'success'
  ) {
    return <BudgetPageLoader />;
  }

  return (
    <>
      <BudgetOverviewPage
        hasExpenseCategories={expenseCategoriesState.data.length > 0}
        budget={budgetQueryState.data}
        budgetaryExercise={budgetaryExerciseQueryState.data}
        onDeleteBudget={showDeleteBudgetModal}
      />
      {deleteBudgetModal}
    </>
  );
});
