import React, { useEffect, useState } from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { logger } from 'src/utils/datadog-log-wrapper';

import { useCreateCurrencyBranchMutation } from './hooks/useCreateCurrencyBranchMutation';
import { useCreateSFSCurrencyBranchMutation } from './hooks/useCreateSFSCurrencyBranchMutation';
import { useWaitForCompanyAppFirstAccess } from './hooks/useWaitForCompanyAppFirstAccess';
import OnboardingModal from './modals/OnboardingModal';
import { SfsBranchCurrencyFormModal } from './modals/SfsBranchCurrencyFormModal';
import { ACCOUNT_TYPES } from './models/accountTypes';
import { type CreatedEntity } from './utils/createBranch';

type Props = {
  handleCloseTheWholeFlow: () => void;
  handleClose: () => void;
  refreshUserCompanies: () => void;
};

/**
 * Bankable / Marqeta: Directly creates the currency branch, display the Onboarding modal for the company name / currency / bank informations
 *
 * SFS: Only EUR is handled, so only display a modal to warn about that fact, then ask for company name, then create the currency branch, then redirects to the new company's dashboard
 */
export const NewCurrencyBranchFlow = ({
  handleCloseTheWholeFlow,
  handleClose,
  refreshUserCompanies,
}: Props) => {
  const company = useCompany();
  const [createdCompany, setCreatedCompany] = useState<
    CreatedEntity | undefined
  >(undefined);

  const [createSFSCurrencyBranch] = useCreateSFSCurrencyBranchMutation();
  const [createCurrencyBranchMutation] = useCreateCurrencyBranchMutation();
  const [errored, setErrored] = useState(false);

  const waitForAppFirstAccess = useWaitForCompanyAppFirstAccess();

  const submitSFSBranchCurrencyCreation = async (companyName: string) => {
    setErrored(false);
    try {
      const newCompanyData = await createSFSCurrencyBranch({
        parentCompanyId: company.id,
        name: companyName,
        currency: company.currency,
      });

      const isAccessOpened = await waitForAppFirstAccess(newCompanyData.id);
      if (isAccessOpened) {
        window.location.href = `/app/${newCompanyData.id}`;
        return;
      }
      refreshUserCompanies();
    } catch (error) {
      setErrored(true);
      logger.error(error, {
        scope: 'new-currency-branch',
        team: 'kyc',
      });
      throw error;
    }
  };

  const createNonSFSBranchCurrencyCreation = async () => {
    try {
      const newCompanyData = await createCurrencyBranchMutation({
        type: ACCOUNT_TYPES.CURRENCY,
      });
      refreshUserCompanies();
      setCreatedCompany(newCompanyData);
    } catch (error) {
      setErrored(true);
      logger.error(error, {
        scope: 'new-currency-branch',
        team: 'kyc',
      });
      throw error;
    }
  };

  useEffect(() => {
    if (company.banking_provider !== 'sfs') {
      createNonSFSBranchCurrencyCreation();
    }
  }, [company.banking_provider]);

  if (company.banking_provider === 'sfs') {
    return (
      <SfsBranchCurrencyFormModal
        handleClose={() => handleClose()}
        handleCreateCurrencyBranch={async (companyName: string) =>
          submitSFSBranchCurrencyCreation(companyName)
        }
        errored={errored}
      />
    );
  }

  if (createdCompany) {
    return (
      <OnboardingModal
        companyId={createdCompany.id}
        onClose={handleCloseTheWholeFlow}
        bankingProvider={createdCompany.banking_provider}
      />
    );
  }

  return null;
};
