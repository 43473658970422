import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { type I18nKey } from 'common/hooks/useTranslation';
import { type QueryError as QueryErrorType } from 'src/core/api/queryError';

import { type UpdateSupplierError } from '../../../hooks';

type Props = {
  updateSupplierError: QueryErrorType<UpdateSupplierError>;
  className?: string;
};

export const UpdateSupplierQueryError = ({
  updateSupplierError,
  className,
}: Props) => (
  <QueryError
    className={className}
    queryError={updateSupplierError}
    componentType="Notification"
    translations={{
      serverError: 'submitMyInvoice.suppliers.updateFailure',
      requestError: createSupplierErrorToTranslation,
    }}
  />
);

const createSupplierErrorToTranslation = (
  updateSupplierError: UpdateSupplierError,
): I18nKey => {
  switch (updateSupplierError.reason) {
    case 'invalidBankInfos':
      return updateSupplierError.errors?.includes('invalidAccountHolderName')
        ? 'submitMyInvoice.suppliers.invalidAccountHolderName'
        : 'submitMyInvoice.suppliers.invalidBankInfo';
    default:
      return 'submitMyInvoice.suppliers.createFailure';
  }
};
