import { Skeleton, SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

const LOADER_ROWS = 6;

export const AccountPayableSuppliersListLoader = () => {
  return (
    <SkeletonTable
      numberOfRows={LOADER_ROWS}
      withHeader
      columns={[
        {
          cell: <SkeletonText size="l" width="60%" />,
          header: <SkeletonText size="l" width="60%" />,
          width: '40%',
        },
        {
          cell: (
            <div className="flex w-full items-center gap-s">
              <Skeleton width="var(--sizing-m)" height="var(--sizing-m)" />
              <SkeletonText size="l" width="60%" />
            </div>
          ),
          header: <SkeletonText size="l" width="60%" />,
          width: '40%',
        },
        {
          cell: (
            <div className="flex w-full justify-end">
              <SkeletonText size="l" width="60%" />
            </div>
          ),
          header: (
            <div className="flex w-full justify-end">
              <SkeletonText size="l" width="60%" />
            </div>
          ),
          width: '20%',
        },
      ]}
    />
  );
};
