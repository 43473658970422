import { useFeature } from 'common/hooks/useFeature';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import FEATURES from 'src/core/constants/features';

import type { CardsAccess, CardsAccessRaw } from '../models/cardsAccess';

export const useCardsAccessQuery = (
  memberId: string,
): QueryState<CardsAccess | undefined> => {
  const isCardsAccessEnabled = useFeature(FEATURES.CARDS_ACCESS);
  const params = { userId: memberId };

  const queryState = useQuery<CardsAccess, CardsAccessRaw>({
    key: ['cards', 'access', params],
    isEnabled: isCardsAccessEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/cards/access',
      params,
    },
    reshapeData: (data) => data.accesses[0],
  });

  return isCardsAccessEnabled
    ? queryState
    : { status: 'success', data: undefined };
};
