import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routeFor, routes } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_account_owner: boolean;
    is_controller: boolean;
  };
  className?: string;
  onClick?: () => void;
};

export const IntegrationsLink = ({ company, className, onClick }: Props) => {
  const { t } = useTranslation('global');
  const { pathname: currentPath } = useLocation();

  return (
    <NavigationLink
      data-marketing="top-menu_integrations"
      key="nav_integrations"
      className={className}
      onClick={onClick}
      path={routeFor(routes.COMPANY_INTEGRATIONS.path, {
        company: company.id,
      })}
      text={t('settings.links.integrations')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [routes.COMPANY_INTEGRATIONS.path],
        }),
      )}
    />
  );
};
