import styles from './PreviewCard.module.css';

type Props = {
  title: string;
  description: string;
  illustration: React.ReactNode;
};

export const PreviewCard = ({ title, description, illustration }: Props) => (
  <div className={styles.root}>
    <div>{illustration}</div>
    <div className="py-m pl-xs pr-m">
      <p className="uppercase text-neutral-dark title-s">After</p>
      <p className="mb-xs leading-none text-primary title-xl">{title}</p>
      <p className="text-neutral-dark body-m">{description}</p>
    </div>
  </div>
);
