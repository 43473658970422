import cx from 'classnames';
import replace from 'lodash/replace';

import {
  type TCardFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import type { CardActivationStatus } from '../../card';
import {
  getIsCardBlocked,
  getIsCardBlockedByCompletionRule,
  getIsCardBlockedBySchedule,
  getIsCardOrdered,
} from '../../utils/card';

import './CardDesign.css';

/*
 * Below: Theses constants are taken from measurements from the card design,
 * in order to have a reference sizing of elements inside, in order to be able to scale them responsively with the card width.
 */
const CARD_WIDTH = 380;
const CARD_HEIGHT = 240;
const TEXT_SHADOW_Y = 1;
const NUMBERS_FONT_SIZE = 22;
const NUMBERS_FONT_SIZE_PHYSICAL = 22;
const NUMBERS_LINE_HEIGHT = 30;
const NUMBERS_LETTER_SPACING = 1.32;
const EXP_DATE_FONT_SIZE = 20;
const EXP_DATE_LINE_HEIGHT = 23;
const EXP_DATE_LETTER_SPACING = -1.7;
const NAME_FONT_SIZE = 20;
const NAME_LINE_HEIGHT = 23;
const NUMBERS_X = 25;
const NUMBERS_Y = 135;
const NUMBERS_X_PHYSICAL = 20;
const NUMBERS_Y_PHYSICAL = 150;
const EXP_DATE_X = 25;
const EXP_DATE_Y = 168;
const NAME_X = 25;
const NAME_Y = 198;
const BLOCKED_X = 33;
const BLOCKED_Y = 25;
const BACK_DIGIT_WIDTH = 13;
const BACK_DIGIT_SPACE = 18;

const getDeactivationLabel = (
  cardState: NonNullable<Props['state']>,
  translator: TCardFunctionTyped,
) => {
  if (getIsCardBlocked(cardState)) {
    return translator('blocked');
  }
  if (getIsCardOrdered(cardState)) {
    return translator('ordered');
  }
  if (getIsCardBlockedBySchedule(cardState)) {
    return translator('limited');
  }
  if (getIsCardBlockedByCompletionRule(cardState)) {
    return translator('restricted');
  }
};

type Props = {
  numbers?: string;
  expDate?: string;
  name?: string;
  state?: CardActivationStatus;
  isActivating?: boolean;
  width?: number;
  brand?: string;
  className?: string;
};

export const CardDesign = ({
  className,
  width = CARD_WIDTH,
  numbers,
  expDate,
  name,
  brand,
  isActivating = false,
  state: cardState = 'active',
}: Props) => {
  const { t } = useTranslation('card');

  const height = (width * CARD_HEIGHT) / CARD_WIDTH;
  const textShadowY = (height * TEXT_SHADOW_Y) / CARD_HEIGHT;
  const numbersFontSize = (height * NUMBERS_FONT_SIZE) / CARD_HEIGHT;
  const numbersFontSizePhysical =
    (height * NUMBERS_FONT_SIZE_PHYSICAL) / CARD_HEIGHT;
  const numbersLineHeight = (height * NUMBERS_LINE_HEIGHT) / CARD_HEIGHT;
  const numbersLetterSpacing = (width * NUMBERS_LETTER_SPACING) / CARD_WIDTH;
  const expDateFontSize = (height * EXP_DATE_FONT_SIZE) / CARD_HEIGHT;
  const expDateLineHeight = (height * EXP_DATE_LINE_HEIGHT) / CARD_HEIGHT;
  const expDateLetterSpacing = (width * EXP_DATE_LETTER_SPACING) / CARD_WIDTH;
  const nameFontSize = (height * NAME_FONT_SIZE) / CARD_HEIGHT;
  const nameLineHeight = (height * NAME_LINE_HEIGHT) / CARD_HEIGHT;
  const numbersX = (width * NUMBERS_X) / CARD_WIDTH;
  const numbersY = (height * NUMBERS_Y) / CARD_HEIGHT;
  const numbersXPhysical = (width * NUMBERS_X_PHYSICAL) / CARD_WIDTH;
  const numbersYPhysical = (height * NUMBERS_Y_PHYSICAL) / CARD_HEIGHT;
  const expDateX = (width * EXP_DATE_X) / CARD_WIDTH;
  const expDateY = (height * EXP_DATE_Y) / CARD_HEIGHT;
  const nameX = (width * NAME_X) / CARD_WIDTH;
  const nameY = (height * NAME_Y) / CARD_HEIGHT;
  const blockedX = (width * BLOCKED_X) / CARD_WIDTH;
  const blockedY = (height * BLOCKED_Y) / CARD_HEIGHT;
  const digitSpanWidth = (width * BACK_DIGIT_WIDTH) / CARD_WIDTH;
  const digitGroupSpace = (width * BACK_DIGIT_SPACE) / CARD_WIDTH;

  const cardStyle = {
    width,
    height,
  };

  if (isActivating) {
    let numbersWithNoSpaces = replace(
      replace(numbers ?? '', /\s/g, ''),
      /\./g,
      '•',
    );
    if (numbersWithNoSpaces.length < 16) {
      numbersWithNoSpaces = `${numbersWithNoSpaces}${'•'.repeat(
        16 - numbersWithNoSpaces.length,
      )}`;
    }
    // We wrap each number in fixed width spans
    // to make the selected font
    // fixed width even if it isn't
    const numbersWithinSpans = (
      <>
        {[...numbersWithNoSpaces].map((character, index) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              width: `${digitSpanWidth}px`,
              marginLeft: `${
                index > 0 && index % 4 === 0 ? digitGroupSpace : 0
              }px`,
              opacity: index <= 11 ? 0.5 : 1,
            }}
          >
            {character}
          </span>
        ))}
      </>
    );

    return (
      <div
        className={cx(
          'CardDesign__card',
          'CardDesign__physical',
          'CardDesign__activating',
          className,
        )}
        style={cardStyle}
      >
        <p
          className="CardDesign__numbers"
          style={{
            textShadow: `0px ${textShadowY}px rgba(42, 38, 79, .5)`,
            letterSpacing: numbersLetterSpacing,
            lineHeight: `${numbersLineHeight}px`,
            fontSize: numbersFontSizePhysical,
            top: numbersYPhysical,
            left: numbersXPhysical,
          }}
        >
          {numbersWithinSpans}
        </p>
      </div>
    );
  }

  const isDisabled =
    getIsCardBlocked(cardState) ||
    getIsCardOrdered(cardState) ||
    getIsCardBlockedBySchedule(cardState) ||
    getIsCardBlockedByCompletionRule(cardState);

  return (
    <div
      className={cx(
        'CardDesign__card CardDesign__physical',
        className,
        isDisabled && 'CardDesign__disabled',
        brand === 'visa' && 'CardDesign__visa',
      )}
      style={cardStyle}
    >
      {getDeactivationLabel(cardState, t) && (
        <div
          className={cx(
            'CardDesign-disabled',
            getIsCardBlocked(cardState) && 'CardDesign-disabled__blocked',
            getIsCardOrdered(cardState) && 'CardDesign-disabled__ordered',
            getIsCardBlockedBySchedule(cardState) &&
              'CardDesign-disabled__limited',
            getIsCardBlockedByCompletionRule(cardState) &&
              'CardDesign-disabled__restricted',
          )}
          style={{
            top: blockedY,
            left: blockedX,
          }}
        >
          {getDeactivationLabel(cardState, t)}
        </div>
      )}
      <p
        className="CardDesign__numbers"
        style={{
          textShadow: `0px ${textShadowY}px rgba(42, 38, 79, .5)`,
          letterSpacing: numbersLetterSpacing,
          lineHeight: `${numbersLineHeight}px`,
          fontSize: numbersFontSize,
          top: numbersY,
          left: numbersX,
        }}
      >
        {replace(numbers ?? '', /\./g, '•')}
      </p>
      <p
        className="CardDesign__expDate"
        style={{
          textShadow: `0px ${textShadowY}px rgba(42, 38, 79, .5)`,
          lineHeight: `${expDateLineHeight}px`,
          letterSpacing: expDateLetterSpacing,
          fontSize: expDateFontSize,
          top: expDateY,
          left: expDateX,
        }}
      >
        {expDate}
      </p>
      <p
        className="CardDesign__name"
        style={{
          textShadow: `0px ${textShadowY}px rgba(42, 38, 79, .5)`,
          lineHeight: `${nameLineHeight}px`,
          fontSize: nameFontSize,
          top: nameY,
          left: nameX,
        }}
      >
        {name?.toUpperCase() ?? ''}
      </p>
    </div>
  );
};
