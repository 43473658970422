import { Button, Tooltip } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useNotifications } from 'modules/app/notifications';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { MarqetaSetPinModal } from './MarqetaSetPin';
import { SFSRevealPinButton } from './SFSRevealPin/SFSRevealPinButton';
import { SFSUkRevealOtpButton } from './SFSUkRevealOtpButton/SFSUkRevealOtpButton';
import { type ApiCard } from '../../../card';
import {
  BlockUnblock,
  CardReport,
  TopUpModal,
} from '../../../components/CardActions';
import { type CardsAccess } from '../../../hooks/useCardAccessQuery';
import { doCardAction, getIsCardBlocked } from '../../../utils/card';

const TOPUP_MODAL_QUERY = 'topupModal';

type Props = {
  card: ApiCard;
  cardsAccess: CardsAccess | undefined;
  syncActivity: () => void;
};

export const PlasticCardActions = ({
  card,
  cardsAccess,
  syncActivity,
}: Props) => {
  const { t } = useTranslation();
  const { successNotif, dangerNotif } = useNotifications();
  const location = useLocation();
  const history = useHistory();

  const company = useCompany();

  const [isPinBeingSentBySms, setIsPinBeingSentBySms] = useState(false);
  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
  const [isMarqetaPinWidgetModalOpen, setIsMarqetaPinWidgetModalOpen] =
    useState(false);
  const [isCardBeingBlockedOrUnblocked, setIsCardBeingBlockedOrUnblocked] =
    useState(false);

  useEffect(() => {
    const { isTopUpButtonDisplayed, isTopUpButtonDisabled } =
      computeTopUpButtonState();
    const params = new URLSearchParams(location.search);
    if (
      isTopUpModalOpen === false &&
      isTopUpButtonDisplayed &&
      !isTopUpButtonDisabled &&
      params.has(TOPUP_MODAL_QUERY)
    ) {
      setIsTopUpModalOpen(true);
    }
  }, []);

  const isNonActivatedSfsCard =
    card.banking_provider === 'sfs' && card.status === 'PRE';

  const computeTopUpButtonState = () => {
    const isCardBlocked = getIsCardBlocked(card.activation_status);
    const isCardsAccessManuallyRestricted =
      cardsAccess?.hasAccess === false && cardsAccess.initiator === 'user';

    const isTopUpButtonDisplayed =
      cardsAccess && (!isCardBlocked || isCardsAccessManuallyRestricted);
    const isTopUpButtonDisabled =
      isCardBeingBlockedOrUnblocked ||
      isCardBlocked ||
      isCardsAccessManuallyRestricted ||
      isNonActivatedSfsCard;
    return {
      isTopUpButtonDisplayed,
      isTopUpButtonDisabled,
    };
  };

  const isCardBlocked = getIsCardBlocked(card.activation_status);

  const isCardsAccessManuallyRestricted =
    cardsAccess?.hasAccess === false && cardsAccess.initiator === 'user';

  const showSMSButtonForNonSFSBankingProvider = card.banking_provider !== 'sfs';
  const isSendSmsButtonDisplayed =
    showSMSButtonForNonSFSBankingProvider &&
    cardsAccess &&
    (!isCardBlocked || isCardsAccessManuallyRestricted);

  const isBlockUnblockButtonDisplayed = cardsAccess;
  const isCardReportButtonDisplayed =
    cardsAccess && isCardBlocked && !isCardsAccessManuallyRestricted;

  const isSendSmsButtonDisabled =
    isCardBeingBlockedOrUnblocked ||
    isPinBeingSentBySms ||
    isCardBlocked ||
    isCardsAccessManuallyRestricted ||
    isNonActivatedSfsCard;

  const { isTopUpButtonDisplayed, isTopUpButtonDisabled } =
    computeTopUpButtonState();

  const showMarqetaPinWidget = () => {
    track(AnalyticEventName.CARD_ACCESS_PIN, {});
    setIsMarqetaPinWidgetModalOpen(true);
  };

  const updateBlockedUnblockedStatus = () => {
    setIsCardBeingBlockedOrUnblocked(false);
    syncActivity();
    closeTopUpModal();
  };

  const closeTopUpModal = () => {
    setIsTopUpModalOpen(false);
    const params = new URLSearchParams(location.search);
    params.delete(TOPUP_MODAL_QUERY);
    history.replace({
      search: params.toString(),
    });
  };

  const openTopUpModal = () => {
    setIsTopUpModalOpen(true);
    const params = new URLSearchParams(location.search);
    params.append(TOPUP_MODAL_QUERY, 'open');
    history.replace({
      search: params.toString(),
    });
  };

  const renderModals = () => {
    if (isTopUpModalOpen) {
      return (
        <TopUpModal
          card={card}
          mode="requester"
          onProcessed={() => {
            closeTopUpModal();
            syncActivity();
          }}
          onCancel={() => closeTopUpModal()}
        />
      );
    }
    if (isMarqetaPinWidgetModalOpen) {
      return (
        <MarqetaSetPinModal
          cardId={card.id}
          companyId={company.id}
          onClose={() => {
            setIsMarqetaPinWidgetModalOpen(false);
            syncActivity();
          }}
        />
      );
    }

    return null;
  };

  const getPinBySms = () => {
    if (!isPinBeingSentBySms) {
      track(AnalyticEventName.CARD_ACCESS_PIN, {});

      setIsPinBeingSentBySms(true);

      doCardAction(company.id, card.id, 'pinsms', null, (error, data) => {
        if (data) {
          syncActivity();
          successNotif(t('cards.successPinSentByText'));
          // @ts-expect-error: Not an helpful comment
        } else if (error && error === 'missing_phone') {
          dangerNotif(t('cards.errorPinMissingPhone'));
        } else {
          dangerNotif(t('errors:somethingWrong'));
        }
        setIsPinBeingSentBySms(false);
      });
    }
  };

  return (
    <div className="min-w-[200px]">
      <div className="flex flex-col gap-xs">
        {isTopUpButtonDisplayed && (
          <Button
            fit="parent"
            iconName="card-load"
            text={t('cards.actions.topUpLimit')}
            isDisabled={isTopUpButtonDisabled}
            onClick={openTopUpModal}
          />
        )}
        {isSendSmsButtonDisplayed && (
          <Tooltip
            content={t('cards.disabledActionBlockedCardTip')}
            isDisabled={!isCardBlocked}
          >
            <Button
              fit="parent"
              iconName="mail"
              variant="secondary"
              text={
                // eslint-disable-next-line no-nested-ternary
                isPinBeingSentBySms
                  ? t('cards.loadingPinCode')
                  : // eslint-disable-next-line unicorn/no-nested-ternary
                    card.banking_provider === 'bankable'
                    ? t('cards.actions.sendPinCode')
                    : t('cards.actions.resetPinCode')
              }
              isDisabled={isSendSmsButtonDisabled}
              isLoading={isPinBeingSentBySms}
              onClick={
                card.banking_provider === 'bankable'
                  ? getPinBySms
                  : showMarqetaPinWidget
              }
            />
          </Tooltip>
        )}
        <SFSRevealPinButton
          isBlocked={isCardBlocked}
          cardStatus={card.status}
        />
        <SFSUkRevealOtpButton
          isBlocked={isCardBlocked}
          cardStatus={card.status}
        />

        {isBlockUnblockButtonDisplayed && (
          <BlockUnblock
            card={card}
            cardsAccess={cardsAccess}
            isCardBlocked={isCardBlocked}
            isCardBeingBlockedOrUnblocked={isCardBeingBlockedOrUnblocked}
            setIsCardBeingBlockedOrUnblocked={setIsCardBeingBlockedOrUnblocked}
            onProcessed={updateBlockedUnblockedStatus}
          />
        )}
      </div>

      {isCardReportButtonDisplayed && (
        <CardReport
          isModalMode
          card={card}
          withExplanation={false}
          onCardReported={afterReportingCard}
        />
      )}

      {renderModals()}
    </div>
  );
};

const afterReportingCard = () => {
  // Redirect towards the app root url, as the card do not exist anymore,
  // the current page access being now useless / dangerous
  setTimeout(() => {
    window.location.href = `${window.location.origin}/app`;
  }, 1500);
};
