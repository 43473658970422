import {
  SkeletonAvatar,
  SkeletonCheckbox,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import './ScheduledPaymentsByUserListSkeleton.css';

type ScheduledPaymentsByUserListSkeletonProps = {
  numberOfRows: number;
};

export const ScheduledPaymentsByUserListSkeleton = ({
  numberOfRows,
}: ScheduledPaymentsByUserListSkeletonProps) => (
  <SkeletonTable
    numberOfRows={numberOfRows}
    columns={[
      {
        cell: (
          <div className="ScheduledPaymentsByUserListSkeleton__table-cell">
            <SkeletonCheckbox />
            <SkeletonAvatar variant="circle" />
            <div className="ScheduledPaymentsByUserListSkeleton__table-cell__description">
              <SkeletonText width="30%" size="l" />
              <SkeletonText width="80%" size="s" />
            </div>
            <SkeletonText size="s" />
          </div>
        ),
        header: (
          <>
            <SkeletonCheckbox />
            <SkeletonText
              width="30%"
              size="m"
              className="ScheduledPaymentsByUserListSkeleton__table-header__title"
            />
          </>
        ),
      },
    ]}
    withHeader
  />
);
