import type { PayableRequest } from '@finance-review/models/payable';
import { useOnBudgetTrackingBackEndUpdate } from 'modules/budgets/hooks/budget-tracking';

export const useOnUpdate = () => {
  const onBudgetTrackingBackEndUpdate = useOnBudgetTrackingBackEndUpdate();

  return (payableRequest: PayableRequest.Entity) => {
    onBudgetTrackingBackEndUpdate(payableRequest);
  };
};
