import React from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';

import { WelcomePanelButtons } from './WelcomePanelButtons/WelcomePanelButtons';
import type { ActionsCounts } from '../../../../../models/actionCounts';

type Props = {
  actionsCounts: ActionsCounts;
};

export const WelcomePanelActions = ({ actionsCounts }: Props) => {
  const user = useUser();
  const company = useCompany();

  const isNewAccount = company.spending_total === 0;

  return (
    <div className="flex flex-wrap gap-s">
      <WelcomePanelButtons
        actions={actionsCounts}
        companyId={company.id}
        isNewAccount={isNewAccount}
        user={user}
      />
    </div>
  );
};
