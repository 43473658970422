import { useCompany } from 'modules/app/hooks/useCompany';
import { type KybStatusCode } from 'modules/kyc/models/kybStatus';
import { useGetProcedureQuery } from 'modules/procedures/hooks';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { useGetKybStatusQuery } from './useGetKybStatusQuery';

export const useKycStatusCode = (): KybStatusCode | undefined => {
  const company = useCompany();
  const isExpenseEntity = company.type === 'branch_expense_entity';

  const procedureState = useGetProcedureQuery({
    isEnabled: !company.is_kyb_validated,
  });
  const kybStatusQuery = useGetKybStatusQuery({
    isEnabled: !company.is_kyb_validated && !isExpenseEntity,
  });

  if (company.is_kyb_validated) {
    return 'completed';
  }

  if (isExpenseEntity) {
    return undefined;
  }

  if (
    ['loading', 'error'].includes(procedureState.status) ||
    ['loading', 'error'].includes(kybStatusQuery.status)
  ) {
    return undefined;
  }

  const procedure = unwrapQuery(procedureState); // procedure is not defined when companies didn't start their kyc
  const kybStatus = unwrapQuery(kybStatusQuery); // kybStatus is not defined when companies didn't start their kyc (or for companies that opened an account before kyc-status endpoint implementation)

  if (procedure && !kybStatus) {
    // companies that opened an account before kyc-status endpoint implementation (!kybStatus)
    return 'in-review';
  }
  if (kybStatus) {
    return kybStatus.state.code as KybStatusCode;
  }
  return 'not-started';
};
