import {
  Callout,
  CheckboxInput,
  GridContainer,
  GridItem,
} from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from '../../../../../../common/hooks/useTranslation';
import {
  type PublicApiScopeListEntry,
  getPublicApiScopeTranslation,
} from '../../../../domain/public-api/scopes';

type Props = {
  mode: 'view' | 'edit';
  scopes: PublicApiScopeListEntry[];
  onScopeChange: (scopeName: string) => void;
  selectedScopes: Set<string>;
  formError?: string;
};

const ScopesForm = ({
  mode,
  scopes,
  selectedScopes,
  onScopeChange,
  formError,
}: Props) => {
  const { t } = useTranslation('global');

  const getScopeName = getPublicApiScopeTranslation({
    t,
    scopeEntries: scopes,
    type: 'title',
  });
  const getScopeDescription = getPublicApiScopeTranslation({
    t,
    scopeEntries: scopes,
    type: 'description',
  });

  return (
    <>
      <h3 className="mb-xs title-m">{t('publicApi.scopes.title')}</h3>
      <p className="mb-m body-m">{t('publicApi.scopes.description')}</p>

      <GridContainer>
        <GridItem
          columnSpan={4}
          className="border-b-solid m-xs border-b-2 border-neutral-lighter text-neutral-dark"
        >
          {t('publicApi.scopes.name')}
        </GridItem>
        <GridItem
          columnSpan={6}
          className="border-b-solid m-xs border-b-2 border-neutral-lighter text-neutral-dark"
        >
          {t('misc.description')}
        </GridItem>
        <GridItem
          columnSpan={2}
          className="border-b-solid m-xs border-b-2 border-neutral-lighter text-center text-neutral-dark"
        >
          {t('publicApi.scopes.read')}
        </GridItem>

        <GridItem columnSpan={12}>
          <div className="separator" />
        </GridItem>

        {scopes.map((scope) => (
          <>
            <GridItem
              key={`${scope.name}-name`}
              data-testid={`${scope.name}-name`}
              columnSpan={4}
              className="m-xs"
            >
              {getScopeName(scope.name)}
            </GridItem>
            <GridItem
              key={`${scope.name}-description`}
              data-testid={`${scope.name}-description`}
              columnSpan={6}
              className="m-xs"
            >
              {getScopeDescription(scope.name)}
            </GridItem>
            <GridItem
              key={`${scope.name}-read-permission`}
              data-testid={`${scope.name}-read-permission`}
              className="m-xs flex justify-center"
              columnSpan={2}
            >
              <CheckboxInput
                isChecked={selectedScopes.has(scope.name)}
                onChange={() => onScopeChange(scope.name)}
                isDisabled={mode === 'view'}
              />
            </GridItem>
            <GridItem columnSpan={12}>
              <div className="separator" />
            </GridItem>
          </>
        ))}
      </GridContainer>
      {formError && (
        <Callout title={formError} variant="alert" className="mt-xs" />
      )}
    </>
  );
};

export default ScopesForm;
