import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

export type Props = {
  isOpen: boolean;
  reason: string | undefined;
  onClose: () => void;
};

export function AccountingBaseSwitchFailureModal({
  isOpen,
  onClose,
  reason,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const companyId = useCompanyId();

  const reasonMessage = getSwitchErrorMessage({ reason });

  const getModalActions = (): JSX.Element | undefined => {
    switch (reason) {
      case 'cannotCreateExpenseAccountDuplicates':
        return (
          <>
            <Button
              variant="secondary"
              text={t('misc.close')}
              onClick={onClose}
            />
            <Button
              variant="primary"
              text={t(
                'bookkeep.integrations.settings.baseSwitch.error.userRedirectCTA',
              )}
              onClick={() => {
                history.push(
                  routeFor(routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path, {
                    company: companyId,
                  }),
                );
                onClose();
              }}
            />
          </>
        );
      default:
        return (
          <Button
            variant="secondary"
            text={t('misc.close')}
            onClick={onClose}
          />
        );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('bookkeep.integrations.settings.baseSwitch.error.title')}
      iconName="failure"
      iconVariant="alert"
      actions={getModalActions()}
    >
      {reasonMessage ? (
        <>
          <p>{t(`${reasonMessage}.cause`)}</p>
          <Trans i18nKey={`${reasonMessage}.fix`}>
            text
            <strong className="text-complementary title-m">path</strong>
            text
          </Trans>
        </>
      ) : (
        <p>{t('bookkeep.integrations.settings.baseSwitch.error.generic')}</p>
      )}
    </Modal>
  );
}

const getSwitchErrorMessage = ({
  reason,
}: {
  reason: string | undefined;
}): string | null => {
  switch (reason) {
    case 'cannotCreateExpenseAccountDuplicates':
      return 'bookkeep.integrations.settings.baseSwitch.error.cannotCreateExpenseAccountDuplicates';
    default:
      return null;
  }
};
