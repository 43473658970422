import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';

import { type CardActivity } from '../../hooks/useCardActivityQuery';
import { type CardLoad } from '../../hooks/useCardLoadsQuery';

export const getLoadedBy = (
  load: CardLoad,
  translator: TGlobalFunctionTyped,
) => {
  let additionalString = '';
  if (!load.request) {
    return translator('cards.feed.loadedBy', { name: load.by_user });
  }

  if (load.request.auto_approved) {
    additionalString = translator('cards.feed.auto');
  } else {
    const appraisals = load.request.appraisals.filter(
      (appr) => appr.approver_id !== load.by_user_id,
    );
    additionalString = translator('cards.feed.approvedBy', {
      name: appraisals.map((a) => a.approver.fullname).join(', '),
    });
  }

  return `${translator('cards.feed.loadedBy', {
    name: load.by_user,
  })} ${additionalString}`;
};

export const titleFromActivityType = (
  activity: CardActivity,
  t: TGlobalFunctionTyped,
) => {
  switch (activity.activity_type) {
    case 'ordered':
      return t('cards.feed.ordered', { name: activity.user.fullname });
    case 'activated':
      return t('cards.feed.activated', { name: activity.user.fullname });
    case 'blocked':
      return t('cards.feed.blocked', { name: activity.user.fullname });
    case 'unblocked':
      return t('cards.feed.unblocked', { name: activity.user.fullname });
    case 'paused':
      return t('cards.feed.paused');
    case 'unpaused':
      return t('cards.feed.unpaused');
    case 'lost':
      return t('cards.feed.lost');
    case 'stolen':
      return t('cards.feed.stolen');
    case 'damaged':
      return t('cards.feed.damaged');
    case 'sms-pin-sent':
      return t('cards.feed.smsPinSent');
    case 'sms-pin-set':
      return t('cards.feed.smsPinSet');
    case 'budget-changed':
      return t('cards.feed.budgetChanged');
    case 'allow-withdrawal':
      return t('cards.feed.allowWithdrawal', {
        name: activity.user.fullname,
      });
    case 'deny-withdrawal':
      return t('cards.feed.denyWithdrawal', { name: activity.user.fullname });
    case 'allow-onlineUsage':
      return t('cards.feed.allowOnlineUsage', {
        name: activity.user.fullname,
      });
    case 'deny-onlineUsage':
      return t('cards.feed.denyOnlineUsage', {
        name: activity.user.fullname,
      });
    case 'allow-contactless':
      return t('cards.feed.allowContactless', {
        name: activity.user.fullname,
      });
    case 'deny-contactless':
      return t('cards.feed.denyContactless', {
        name: activity.user.fullname,
      });

    case 'active':
      return t('cards.feed.active');

    default:
      return null;
  }
};
