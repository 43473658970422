import type { PayableRequest } from '@finance-review/models/payable';
import {
  type MutationState,
  useMutation as useQueryMutation,
} from 'src/core/api/hooks/useMutation';
import { type MutationQueryState } from 'src/core/api/queryState';

export type Response<TError = string> = {
  successes: { id: string }[];
  errors: {
    details?: TError[];
    requestId: string;
    errorCode: string;
    errorMessage: string;
  }[];
};
export type Payload = {
  id: PayableRequest.EntityId;
  amount: number;
  currency: string;
}[];

type _Payload = {
  requests: {
    amount: number;
    currency: string;
    requestId: PayableRequest.EntityId;
  }[];
};

export type QueryState = MutationQueryState<Response, unknown>;

export const useMutation = <TError = string>(
  options: {
    onSuccess?: (requestId: PayableRequest.EntityId) => void;
    onComplete?: () => void;
    onError?: () => void;
  } = {},
): MutationState<Payload, Response<TError>, unknown> => {
  const [validatePayableRequestMutation, ...rest] = useQueryMutation<
    _Payload,
    Response<TError>,
    Response<TError>,
    unknown
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/validate-by-controller`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess({ data }) {
        if (data.errors.length > 0) {
          options.onError?.();
        }
        if (data.successes.length > 0 && options.onSuccess) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          data.successes.forEach(({ id }) => options.onSuccess!(id));
        }
        options.onComplete?.();
      },
      onError: () => {
        options.onError?.();
      },
    },
    reshapeData(data) {
      return data;
    },
  });

  return [
    async (payableRequests) =>
      validatePayableRequestMutation({
        requests: payableRequests.map(({ amount, currency, id }) => ({
          amount,
          currency,
          requestId: id,
        })),
      }),
    ...rest,
  ];
};
