import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchWireTransferActivationStatus,
  getWireTransferActivationStatusForExpenses,
} from 'modules/company/billing-legacy';
import { useFeature } from "src/core/common/hooks/useFeature";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import FEATURES from "src/core/constants/features";
import type { AppDispatch } from "src/core/modules/app/redux/store";

import { isWireTransferFeatureAvailable } from '../../models';
import { WireTransfer } from '../WireTransfer';

export const WireTransferPage = () => {
  const { t } = useTranslation('global');
  const dispatch = useDispatch<AppDispatch>();
  const isWireTransferForExpenseClaimsEnabled = useFeature(
    FEATURES.WIRE_TRANSFER_FOR_EXPENSE_CLAIMS,
  );
  const isExpenseClaimsEnabled = useFeature(FEATURES.EXPENSE_CLAIMS);

  const wireTransferActivationStatus = useSelector(
    getWireTransferActivationStatusForExpenses,
  );

  useEffect(() => {
    dispatch(fetchWireTransferActivationStatus());
  }, [isExpenseClaimsEnabled]);

  return (
    <section aria-labelledby="wireTransfer">
      <h1 id="wireTransfer" className="text-complementary title-xl">
        {t('generalSettings.wireTransfer.title')}
      </h1>
      <p className="text-neutral-dark body-m">
        {t('generalSettings.wireTransfer.description')}
      </p>
      {isWireTransferForExpenseClaimsEnabled &&
        wireTransferActivationStatus &&
        isWireTransferFeatureAvailable(wireTransferActivationStatus) && (
          <div className="box mt-m">
            <WireTransfer wireTransferPayableEntity="expenses" />
          </div>
        )}
    </section>
  );
};
