import { DatePicker, FormField } from '@dev-spendesk/grapes';
import { format } from 'date-fns';

import { getValueDate } from 'src/core/modules/bookkeep/prepare-payables/components/PreparePayablesInbox/utils/date';

type Props = {
  label: string;
  value: string;
  setFieldValue: (key: string, value: string) => void;
  error: string | undefined;
};

/**
 * Component responsible for rendering a datepicker field,
 * allowing the user to select a value for the accounting date field.
 *
 * @param {Props} Props   Component properties, including
 *                        - value (string): the date value
 *                        - setFieldValue (function): the callback function
 *                        - label (string): the label to display
 *                        - error (string): the error message to display
 * @returns {JSX.Element} FormField component
 */
export const PayableCreationDateField = ({
  label,
  value,
  setFieldValue,
  error,
}: Props) => {
  const creationDate = getValueDate(value);

  return (
    <FormField
      className="PayableForm__creation-date"
      label={label}
      alertMessage={error}
    >
      <DatePicker
        fit="parent"
        value={creationDate}
        onChange={(newDate) => {
          if (newDate) {
            setFieldValue('creationDate', format(newDate, 'yyyy-MM-dd'));
          }
        }}
      />
    </FormField>
  );
};
