import { SkeletonTable, SkeletonText, Table } from '@dev-spendesk/grapes';
import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import {
  filterIntegrationReverseChargeAccounts,
  type IntegrationReverseChargeAccount,
  type TaxAccount,
} from 'modules/bookkeep/settings/accounting';
import { type QueryState } from 'src/core/api/queryState';

import { TaxAccountErrorWarning } from '../../TaxAccountErrorWarning';
import { TableFooter } from '../common/CommonFooter';

interface Props {
  taxAccountQueryState: QueryState<TaxAccount[]>;
}

export const NetsuiteReverseChargePullTable = ({
  taxAccountQueryState,
}: Props) => {
  const { t } = useTranslation('global');
  return (
    <QuerySuspense
      queryState={taxAccountQueryState}
      loading={<TaxAccountSkeletonTable />}
      fallback={() => <TaxAccountErrorWarning />}
    >
      {(taxAccounts) => {
        const reverseChargeAccounts =
          filterIntegrationReverseChargeAccounts(taxAccounts);
        return (
          <Table<IntegrationReverseChargeAccount>
            rowHeight="compact"
            hasColumnSeparator
            columns={[
              {
                id: 'vat-account-code',
                header: t('bookkeep.integrations.settings.vatTable.code'),
                width: '33.333%',
                renderCell(row: IntegrationReverseChargeAccount) {
                  return row.codeDebit;
                },
              },
              {
                id: 'vat-account-description',
                header: t(
                  'bookkeep.integrations.settings.vatTable.description',
                ),
                width: '66.666%',
                renderCell(row: IntegrationReverseChargeAccount) {
                  return <EllipsisTooltip text={row.description ?? ''} />;
                },
              },
            ]}
            data={reverseChargeAccounts}
            footer={
              taxAccounts.length === 0 ? (
                <TableFooter taxAccountQueryState={taxAccountQueryState} />
              ) : undefined
            }
          />
        );
      }}
    </QuerySuspense>
  );
};

const TaxAccountSkeletonTable = () => (
  <SkeletonTable
    columns={[
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '33.333%',
      },
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '66.666%',
      },
    ]}
    withColumnSeparator
    withHeader
  />
);
