import { useQueryClient } from 'react-query';

import { type Group } from '../../types';
import { type BucketType, type GroupId } from '../models';

export const usePayablesGroupResolver = () => {
  const queryClient = useQueryClient();

  return (bucketId: BucketType, groupId: GroupId): Group | undefined => {
    return queryClient.getQueryData<Group>(['payableGroup', groupId, bucketId]);
  };
};
