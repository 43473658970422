import { type Dispatch } from '@reduxjs/toolkit';

import type * as actionTypes from './actionTypes';
import * as actions from './actions';
import { type NotificationPayload, NotificationType } from '../notification';

let nextNotificationId: number = 0;

export const addNotification = (notification: NotificationPayload) => {
  return (dispatch: Dispatch<actionTypes.NotificationsActions>): void => {
    const id = nextNotificationId++;

    const {
      message,
      description,
      type = NotificationType.Success,
      canExpire = true,
      onClose,
      action,
    } = notification;

    const enrichedNotification = {
      id,
      type,
      message,
      description,
      onClose,
      action,
      canExpire,
    };
    dispatch(actions.addNotificationToStack(enrichedNotification));
  };
};
