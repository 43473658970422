import { Panel, SkeletonText } from '@dev-spendesk/grapes';

export const LoadingState = () => (
  <Panel>
    <div className="h-full p-m">
      <div className="flex justify-between">
        <SkeletonText size="l" width="35%" />
        <SkeletonText size="l" width="16px" />
      </div>
    </div>
  </Panel>
);
