import { type ParseBulkMemberInviteOutcome } from 'modules/members/models/fileInviteResult';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useSendBulkInvitesMutation = (): MutationState<
  FormData,
  ParseBulkMemberInviteOutcome
> => {
  return useMutation<
    FormData,
    ParseBulkMemberInviteOutcome,
    ParseBulkMemberInviteOutcome
  >({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/invites/import',
    },
    reshapeData: (data) => data,
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['invites']);
      },
    },
  });
};
