/* eslint-disable @typescript-eslint/no-explicit-any */
import { type AllowanceLocation } from '../allowanceLocation';
import { type PerDiem } from '../perDiem';
import { type Location, type SuggestedLocation } from '../perDiemLocation';
import { type PerDiemFormErrors } from '../perDiemValidation';

export const FETCH_ALLOWANCE_LOCATIONS_REQUEST =
  'per-diem/FETCH_ALLOWANCE_LOCATIONS_REQUEST';
export const FETCH_ALLOWANCE_LOCATIONS_SUCCESS =
  'per-diem/FETCH_ALLOWANCE_LOCATIONS_SUCCESS';
export const FETCH_ALLOWANCE_LOCATIONS_FAILURE =
  'per-diem/FETCH_ALLOWANCE_LOCATIONS_FAILURE';
export const FETCH_LEGAL_LOCATION_REQUEST =
  'per-diem/FETCH_LEGAL_LOCATION_REQUEST';
export const FETCH_LEGAL_LOCATION_SUCCESS =
  'per-diem/FETCH_LEGAL_LOCATION_SUCCESS';
export const FETCH_LEGAL_LOCATION_FAILURE =
  'per-diem/FETCH_LEGAL_LOCATION_FAILURE';
export const FETCH_LOCATIONS_SUCCESS = 'per-diem/FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'per-diem/FETCH_LOCATIONS_FAILURE';
export const CREATE_REQUEST = 'per-diem/CREATE_REQUEST';
export const CREATE_SUCCESS = 'per-diem/CREATE_SUCCESS';
export const CREATE_FAILURE = 'per-diem/CREATE_FAILURE';
export const DESCRIPTION_CHANGED = 'per-diem/DESCRIPTION_CHANGED';
export const DEPARTURE_CHANGED = 'per-diem/DEPARTURE_CHANGED';
export const ARRIVAL_CHANGED = 'per-diem/ARRIVAL_CHANGED';
export const BREAKFAST_CHANGED = 'per-diem/BREAKFAST_CHANGED';
export const LUNCH_CHANGED = 'per-diem/LUNCH_CHANGED';
export const DINNER_CHANGED = 'per-diem/DINNER_CHANGED';
export const LOCATION_CHANGED = 'per-diem/LOCATION_CHANGED';
export const ADD_DESTINATION = 'per-diem/ADD_DESTINATION';
export const DELETE_DESTINATION = 'per-diem/DELETE_DESTINATION';
export const RESET_UI_STATE = 'per-diem/RESET_UI_STATE';
export const SELECT_TEAM = 'per-diem/SELECT_TEAM';
export const SELECT_COST_CENTER = 'per-diem/SELECT_COST_CENTER';
export const SELECT_EXPENSE_CATEGORY = 'per-diem/SELECT_EXPENSE_CATEGORY';
export const CUSTOM_FIELDS_CHANGED = 'per-diem/CUSTOM_FIELDS_CHANGED';
export const FORM_ERRORS_CHANGED = 'per-diem/FORM_ERRORS_CHANGED';
export const COMPUTE_MEALS = 'per-diem/COMPUTE_MEALS';
export const DISPLAY_MEALS = 'per-diem/DISPLAY_MEALS';
export const DISPLAY_TRIP = 'per-diem/DISPLAY_TRIP';
export const HOTEL_ALLOWANCE_CHANGED = 'per-diem/HOTEL_ALLOWANCE_CHANGED';

export const fetchAllowanceLocationsRequest =
  (): FetchAllowanceLocationsRequest => ({
    type: FETCH_ALLOWANCE_LOCATIONS_REQUEST,
  });

export const fetchAllowanceLocationsSuccess = (
  allowanceLocations: ReadonlyArray<AllowanceLocation>,
): FetchAllowanceLocationsSuccess => ({
  type: FETCH_ALLOWANCE_LOCATIONS_SUCCESS,
  payload: allowanceLocations,
});

export const fetchAllowanceLocationsFailure = (
  error: PerDiemHttpFailure,
): FetchAllowanceLocationsFailure => ({
  type: FETCH_ALLOWANCE_LOCATIONS_FAILURE,
  payload: error,
});

export const fetchLegalLocationRequest = (
  dayStayId: string,
): FetchLegalLocationRequest => ({
  type: FETCH_LEGAL_LOCATION_REQUEST,
  payload: dayStayId,
});

export const fetchLegalLocationSuccess = (
  location: Location,
  dayStayId: string,
): FetchLegalLocationSuccess => ({
  type: FETCH_LEGAL_LOCATION_SUCCESS,
  payload: { location, dayStayId },
});

export const fetchLegalLocationFailure = (
  error: PerDiemHttpFailure,
  dayStayId: string,
): FetchLegalLocationFailure => ({
  type: FETCH_LEGAL_LOCATION_FAILURE,
  payload: {
    error,
    dayStayId,
  },
});

export const fetchLocationsSuccess = (
  locations: SuggestedLocation[],
  dayStayId: string,
): FetchLocationsSuccess => ({
  type: FETCH_LOCATIONS_SUCCESS,
  payload: {
    locations,
    dayStayId,
  },
});

export const fetchLocationsFailure = (
  error: PerDiemHttpFailure,
): FetchLocationsFailure => ({
  type: FETCH_LOCATIONS_FAILURE,
  payload: error,
});

export const descriptionChanged = (desc: string): DescriptionChanged => ({
  type: DESCRIPTION_CHANGED,
  payload: desc,
});

export const departureChanged = (
  departureTime: Date,
  dayStayId: string,
): DepartureChanged => ({
  type: DEPARTURE_CHANGED,
  payload: { departureTime, dayStayId },
});

export const arrivalChanged = (
  arrivalTime: Date,
  dayStayId: string,
): ArrivalChanged => ({
  type: ARRIVAL_CHANGED,
  payload: { arrivalTime, dayStayId },
});

export const breakfastChanged = (
  isBreakfastIncluded: boolean,
  day: number,
): BreakfastChanged => ({
  type: BREAKFAST_CHANGED,
  payload: { isBreakfastIncluded, day },
});

export const lunchChanged = (
  isLunchIncluded: boolean,
  day: number,
): LunchChanged => ({
  type: LUNCH_CHANGED,
  payload: { isLunchIncluded, day },
});

export const dinnerChanged = (
  isDinnerIncluded: boolean,
  day: number,
): DinnerChanged => ({
  type: DINNER_CHANGED,
  payload: {
    isDinnerIncluded,
    day,
  },
});

export const locationChanged = (
  location: Location,
  dayStayId: string,
): LocationChanged => ({
  type: LOCATION_CHANGED,
  payload: {
    location,
    dayStayId,
  },
});

export const customFieldsChanged = (
  customFields: {
    customFieldId: string;
    customFieldValueId: string;
    value: string;
  }[],
): CustomFieldsChanged => ({
  type: CUSTOM_FIELDS_CHANGED,
  payload: {
    customFields,
  },
});

export const formErrorsChanged = (
  formErrors: PerDiemFormErrors,
): FormErrorsChanged => ({
  type: FORM_ERRORS_CHANGED,
  payload: formErrors,
});

export const addDestination = (
  location: Location,
  dayStayId: string,
): AddDestination => ({
  type: ADD_DESTINATION,
  payload: { location, dayStayId },
});

export const deleteDestination = (dayStayId: string): DeleteDestination => ({
  type: DELETE_DESTINATION,
  payload: dayStayId,
});

export const resetUiState = (): ResetUiState => ({
  type: RESET_UI_STATE,
});

export const selectTeam = (teamId: string): SelectTeam => ({
  type: SELECT_TEAM,
  payload: teamId,
});

export const selectCostCenter = (costCenterId: string): SelectCostCenter => ({
  type: SELECT_COST_CENTER,
  payload: costCenterId,
});

export const selectExpenseCategory = (
  expenseCategoryId: string,
): SelectExpenseCategory => ({
  type: SELECT_EXPENSE_CATEGORY,
  payload: expenseCategoryId,
});

export const computeMeals = (): ComputeMeals => ({
  type: COMPUTE_MEALS,
});

export const displayMeals = (): DisplayMeals => ({
  type: DISPLAY_MEALS,
});

export const displayTrip = (): DisplayTrip => ({
  type: DISPLAY_TRIP,
});

export const hotelAllowanceChanged = (
  hasHotelAllowance: boolean,
): HotelAllowanceChanged => ({
  type: HOTEL_ALLOWANCE_CHANGED,
  payload: hasHotelAllowance,
});

export const createRequest = (): CreateRequest => ({
  type: CREATE_REQUEST,
});

export const createSuccess = (perDiemRequest: {
  perDiem: PerDiem;
}): CreateSuccess => ({
  type: CREATE_SUCCESS,
  payload: perDiemRequest,
});

export const createFailure = (error: any): CreateFailure => ({
  type: CREATE_FAILURE,
  payload: error,
});

export interface FetchAllowanceLocationsRequest {
  readonly type: 'per-diem/FETCH_ALLOWANCE_LOCATIONS_REQUEST';
}

export interface FetchAllowanceLocationsSuccess {
  readonly type: 'per-diem/FETCH_ALLOWANCE_LOCATIONS_SUCCESS';
  readonly payload: ReadonlyArray<AllowanceLocation>;
}

export interface FetchAllowanceLocationsFailure {
  readonly type: 'per-diem/FETCH_ALLOWANCE_LOCATIONS_FAILURE';
  readonly payload: PerDiemHttpFailure;
}

export interface FetchLegalLocationRequest {
  readonly type: 'per-diem/FETCH_LEGAL_LOCATION_REQUEST';
  readonly payload: string;
}

export interface FetchLegalLocationSuccess {
  readonly type: 'per-diem/FETCH_LEGAL_LOCATION_SUCCESS';
  readonly payload: { location: Location; dayStayId: string };
}

export interface FetchLegalLocationFailure {
  readonly type: 'per-diem/FETCH_LEGAL_LOCATION_FAILURE';
  readonly payload: {
    error: PerDiemHttpFailure;
    dayStayId: string;
  };
}

export interface FetchLocationsRequest {
  readonly type: 'per-diem/FETCH_LOCATIONS_REQUEST';
}

export interface FetchLocationsSuccess {
  readonly type: 'per-diem/FETCH_LOCATIONS_SUCCESS';
  readonly payload: Readonly<{
    locations: SuggestedLocation[];
    dayStayId: string;
  }>;
}

export interface FetchLocationsFailure {
  readonly type: 'per-diem/FETCH_LOCATIONS_FAILURE';
  readonly payload: any;
}

export type CreateRequest = {
  readonly type: 'per-diem/CREATE_REQUEST';
};

export type CreateSuccess = {
  readonly type: 'per-diem/CREATE_SUCCESS';
  readonly payload: { perDiem: PerDiem };
};

export type CreateFailure = {
  readonly type: 'per-diem/CREATE_FAILURE';
  readonly payload: any;
};

export interface DescriptionChanged {
  readonly type: 'per-diem/DESCRIPTION_CHANGED';
  readonly payload: string;
}

export interface DepartureChanged {
  readonly type: 'per-diem/DEPARTURE_CHANGED';
  readonly payload: Readonly<{
    departureTime: Date;
    dayStayId: string;
  }>;
}

export interface ArrivalChanged {
  readonly type: 'per-diem/ARRIVAL_CHANGED';
  readonly payload: Readonly<{
    arrivalTime: Date;
    dayStayId: string;
  }>;
}

export interface BreakfastChanged {
  readonly type: 'per-diem/BREAKFAST_CHANGED';
  readonly payload: Readonly<{
    isBreakfastIncluded: boolean;
    day: number;
  }>;
}

export interface LunchChanged {
  readonly type: 'per-diem/LUNCH_CHANGED';
  readonly payload: Readonly<{
    isLunchIncluded: boolean;
    day: number;
  }>;
}

export interface DinnerChanged {
  readonly type: 'per-diem/DINNER_CHANGED';
  readonly payload: Readonly<{
    isDinnerIncluded: boolean;
    day: number;
  }>;
}

export interface LocationChanged {
  readonly type: 'per-diem/LOCATION_CHANGED';
  readonly payload: Readonly<{
    location: Location;
    dayStayId: string;
  }>;
}

export interface CustomFieldsChanged {
  readonly type: 'per-diem/CUSTOM_FIELDS_CHANGED';
  readonly payload: Readonly<{
    customFields: {
      customFieldId: string;
      customFieldValueId: string;
      value: string;
    }[];
  }>;
}

export interface AddDestination {
  readonly type: 'per-diem/ADD_DESTINATION';
  readonly payload: {
    location: Location;
    dayStayId: string;
  };
}

export interface DeleteDestination {
  readonly type: 'per-diem/DELETE_DESTINATION';
  readonly payload: string;
}

export interface ResetUiState {
  readonly type: 'per-diem/RESET_UI_STATE';
}

export interface SelectTeam {
  readonly type: 'per-diem/SELECT_TEAM';
  readonly payload: string;
}

export interface SelectCostCenter {
  readonly type: 'per-diem/SELECT_COST_CENTER';
  readonly payload: string;
}

export interface SelectExpenseCategory {
  readonly type: 'per-diem/SELECT_EXPENSE_CATEGORY';
  readonly payload: string;
}

export interface PerDiemHttpFailure {
  readonly code: string;
  readonly message: string;
}

export interface FormErrorsChanged {
  readonly type: 'per-diem/FORM_ERRORS_CHANGED';
  readonly payload: PerDiemFormErrors;
}

export interface ComputeMeals {
  readonly type: 'per-diem/COMPUTE_MEALS';
}

export interface DisplayMeals {
  readonly type: 'per-diem/DISPLAY_MEALS';
}

export interface DisplayTrip {
  readonly type: 'per-diem/DISPLAY_TRIP';
}

export interface HotelAllowanceChanged {
  readonly type: 'per-diem/HOTEL_ALLOWANCE_CHANGED';
  readonly payload: boolean;
}

export type PerDiemAction =
  | FetchAllowanceLocationsRequest
  | FetchAllowanceLocationsSuccess
  | FetchAllowanceLocationsFailure
  | FetchLegalLocationRequest
  | FetchLegalLocationSuccess
  | FetchLegalLocationFailure
  | FetchLocationsRequest
  | FetchLocationsSuccess
  | FetchLocationsFailure
  | CreateRequest
  | CreateSuccess
  | CreateFailure
  | DescriptionChanged
  | DepartureChanged
  | ArrivalChanged
  | BreakfastChanged
  | LunchChanged
  | DinnerChanged
  | LocationChanged
  | AddDestination
  | DeleteDestination
  | ResetUiState
  | SelectTeam
  | SelectExpenseCategory
  | SelectCostCenter
  | CustomFieldsChanged
  | FormErrorsChanged
  | ComputeMeals
  | DisplayMeals
  | DisplayTrip
  | HotelAllowanceChanged;
