import { Avatar, Table, type TableColumn } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import { useTranslation } from 'common/hooks/useTranslation';

import styles from './MembersPaginatedTable.module.css';
import { type MemberStatus, type MemberTableRow } from '../../models/member';

type Props = {
  members: MemberTableRow[];
  onMemberClick?({ id }: { id: string }): void;
  isCompanyManagedByIntegration?: boolean;
};

export const MembersPaginatedTable = ({
  members,
  onMemberClick,
  isCompanyManagedByIntegration,
}: Props) => {
  const { t } = useTranslation('global');
  const onRowClick = React.useCallback(
    (row: MemberTableRow) => !!onMemberClick && onMemberClick({ id: row.id }),
    [onMemberClick],
  );
  const getRowId = React.useCallback((member: MemberTableRow) => member.id, []);

  const memberColumns: TableColumn<MemberTableRow>[] = React.useMemo(() => {
    const columns: TableColumn<MemberTableRow>[] = [
      {
        header: t('members.table.name'),
        id: 'name',
        width: '280px',
        renderCell: (member) => (
          <div className="flex items-center overflow-hidden">
            {!isCompanyManagedByIntegration && (
              <div className="mr-s">
                <Avatar
                  src={member.avatar}
                  text={getMemberName(member)}
                  size="m"
                />
              </div>
            )}
            <div className="grid">
              <EllipsisTooltip
                className="overflow-hidden overflow-ellipsis whitespace-nowrap text-complementary body-m"
                text={getMemberName(member)}
              />
              {showExtraInformation(member.status) && (
                <EllipsisTooltip
                  className="text-neutral-dark body-s"
                  text={member.email || member.deletedEmail}
                  tooltipMaxWidth={400}
                />
              )}
            </div>
          </div>
        ),
        getSortValue: (member: MemberTableRow) => getMemberName(member),
      },
    ];
    return columns;
  }, []);

  return (
    <Table
      className={cx(styles.membersTable, {
        [styles.membersTablePending]:
          members.length > 0 && members[0].status === 'pending',
      })}
      columns={memberColumns}
      data={members}
      getRowId={getRowId}
      getIsRowDisabled={(row) => row.status === 'archived'}
      onRowClick={onRowClick}
      emptyState={{
        title: t('members.table.noResults.title'),
        subtitle: t('members.table.noResults.subtitle'),
      }}
      defaultSortState={{
        columnId: 'name',
        direction: 'ascending',
      }}
      nextSortDirection={{
        none: 'descending',
        ascending: 'descending',
        descending: 'ascending',
      }}
    />
  );
};

const showExtraInformation = (memberStatus: MemberStatus) => {
  return memberStatus !== 'pending';
};

const getMemberName = (member: MemberTableRow) => {
  return member.status === 'pending'
    ? member.email
    : `${member.firstName} ${member.lastName}`;
};
