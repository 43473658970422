import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { LayoutContainer } from 'modules/app/layout/LayoutContainer';
import { fetchMe } from 'modules/app/layout/redux/actions';
import { type AppDispatch } from 'src/core/modules/app/redux/store';

import { getUrlCompanyId } from './getUrlCompanyId';
import { useCompany } from '../hooks/useCompany';
import { useUser } from '../hooks/useUser';

// This component is not doing any Layout. Its purpose is only to call
// endpoints related to company switch and /me fetch.
export const LayoutCompanyIdUrl = () => {
  const location: Location = useLocation();
  const company = useCompany();
  const user = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const [companyIdMatchesUrlId, setCompanyIdMatchesUrlId] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    const urlCompanyId = getUrlCompanyId(pathname);

    dispatch(fetchMe(urlCompanyId));
  }, []);

  useEffect(() => {
    // Do not run if the companyId was already inserted or if we don't have the
    // needed data yet.
    if (!user || !company || companyIdMatchesUrlId) {
      return;
    }

    const { pathname, search } = location;
    const urlCompanyId = getUrlCompanyId(pathname);

    if (!(urlCompanyId && user.company_ids.includes(urlCompanyId))) {
      const restOfPathname = pathname.replace(
        new RegExp(`/app/?${urlCompanyId ? `${urlCompanyId}/?` : ''}`),
        '',
      );

      const fixedUrl = `/app/${company.id}/${restOfPathname}${search}`;

      history.push(fixedUrl);
    }
    setCompanyIdMatchesUrlId(true);
  }, [history, location, user, company, companyIdMatchesUrlId]);

  if (!companyIdMatchesUrlId) {
    return null;
  }

  return <LayoutContainer />;
};
