import { FormField, Label } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { AutocompleteNoOptions } from 'src/core/common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { getShortHourLocalized } from 'src/core/utils/date';

import { type FormattedUser } from '../../hooks/useGetControllersQuery/useGetControllersQuery';

export type Props = {
  users: FormattedUser[];
} & FormikProps<{ schedulePattern?: string | null }>;

const patternToValues = (pattern?: string | null): (string | undefined)[] => {
  if (!pattern) {
    return [undefined];
  }
  return (pattern || '').split('|');
};

const valuesToPattern = (
  day: string = '2',
  hour: string = '10',
  user: string = '',
): string => `${day}|${hour}|${user}`;

export const NotificationReceiptReminders = ({
  users,
  // Formik props
  values,
  setFieldValue,
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');

  const onScheduleChange = ({
    day,
    hour,
    user,
  }: {
    day?: string;
    hour?: string;
    user?: string;
  }) => {
    // Calculate the schedule pattern with new value (day or hour) combined with existing value
    const [dayValue, hourValue, userValue] = patternToValues(
      values.schedulePattern,
    );
    let newSchedulePattern;
    if (day) {
      newSchedulePattern = valuesToPattern(day, hourValue, userValue);
    }
    if (hour) {
      newSchedulePattern = valuesToPattern(dayValue, hour, userValue);
    }
    if (user) {
      newSchedulePattern = valuesToPattern(dayValue, hourValue, user.trim());
    }

    setFieldValue('schedulePattern', newSchedulePattern);
  };

  const days = [
    { key: '1', label: t('misc.days.mon') },
    { key: '2', label: t('misc.days.tue') },
    { key: '3', label: t('misc.days.wed') },
    { key: '4', label: t('misc.days.thu') },
    { key: '5', label: t('misc.days.fri') },
    { key: '6', label: t('misc.days.sat') },
    { key: '7', label: t('misc.days.sun') },
  ];

  const hours = Array.from({ length: 24 }, (_, hour) => ({
    key: `${hour}`,
    label: getShortHourLocalized(hour, activeLanguage),
  }));

  const pattern = values.schedulePattern || valuesToPattern();
  const [dayValue, hourValue, userValue] = patternToValues(pattern);
  const ccUser = users.find((user) => user.id === userValue);

  return (
    <div className="mb-s mt-xs rounded bg-neutral-lightest p-s">
      <Label
        label={t(
          'generalSettings.notifications.autoReminders.receiptReminderTitle',
        )}
      />
      <div className="mb-s mt-xs flex items-center">
        <AutocompleteSearch
          fit="parent"
          placeholder={t(
            'generalSettings.notifications.autoReminders.receiptReminderDayPlaceholder',
          )}
          onSelect={(selectedOption) => {
            if (selectedOption?.key !== dayValue) {
              onScheduleChange({ day: selectedOption?.key });
            }
          }}
          options={days}
          value={days.find((d) => d.key === dayValue)}
          renderNoOptions={(rawValue) => (
            <AutocompleteNoOptions value={rawValue} />
          )}
        />
        <span className="mx-s">{t('misc.at')}</span>
        <AutocompleteSearch
          fit="parent"
          placeholder={t(
            'generalSettings.notifications.autoReminders.receiptReminderHourPlaceholder',
          )}
          onSelect={(selectedOption) => {
            if (selectedOption?.key !== hourValue) {
              onScheduleChange({ hour: selectedOption?.key });
            }
          }}
          options={hours}
          value={hours.find((hour) => hour.key === hourValue)}
          renderNoOptions={(rawValue) => (
            <AutocompleteNoOptions value={rawValue} />
          )}
        />
      </div>
      <FormField
        label={t(
          'generalSettings.notifications.autoReminders.receiptReminderCopy',
        )}
        hint={t('misc.optional')}
      >
        {/* TODO: add user thumbnail */}
        <AutocompleteSearch
          fit="parent"
          onSelect={(selectedOption) => {
            if (selectedOption?.key !== ccUser?.id) {
              onScheduleChange({
                user: selectedOption?.key || '',
              });
            }
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (e.target.value === '' && ccUser) {
              onScheduleChange({
                user: ' ',
              });
            }
          }}
          placeholder={t(
            'generalSettings.notifications.autoReminders.receiptReminderCopyPlaceholder',
          )}
          options={users.map((user) => ({
            key: user.id,
            label: user.fullName,
          }))}
          value={
            ccUser ? { key: ccUser.id, label: ccUser.fullName } : undefined
          }
          renderNoOptions={(rawValue) => (
            <AutocompleteNoOptions value={rawValue} />
          )}
        />
      </FormField>
    </div>
  );
};
