import { configureStore } from '@reduxjs/toolkit';

import reducers from '../../../reducers';

const store = configureStore({
  reducer: reducers,
  devTools: !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__,
});

export type AppDispatch = ReturnType<typeof store.dispatch>;

export default store;
