import { useParams } from 'react-router-dom';

import { routes, routeFor } from "src/core/constants/routes";
import history from "src/core/history";

import { usePurchaseOrderFilter } from './usePurchaseOrderFilter';
import {
  applyFilter,
  getSearchParamsFromFilter,
  getFilterFromPurchaseOrder,
  type PurchaseOrder,
} from '../models';

export const useGoToPurchaseOrder = () => {
  const { company: companyId }: { company: string } = useParams();
  const currentFilter = usePurchaseOrderFilter();

  return (purchaseOrder: PurchaseOrder) => {
    const baseRoute = routeFor(routes.PURCHASE_ORDERS.path, {
      id: purchaseOrder.id,
      company: companyId,
    });
    const specificFilter = getFilterFromPurchaseOrder(purchaseOrder);
    const searchParams = getSearchParamsFromFilter(
      applyFilter(currentFilter, specificFilter),
    );
    const routeParams = searchParams.toString();

    history.push(`${baseRoute}?${routeParams}`);
  };
};
