import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';

type Props = {
  label: string;
  value: string | React.ReactChild;
};

export const PanelSectionListItem = ({ label, value }: Props) => {
  return (
    <div className="flex justify-between gap-m text-m leading-6">
      <div className="flex-1 truncate text-neutral-dark">
        <EllipsisTooltip text={label} tooltipMaxWidth={350} />
      </div>
      <div className="w-2/5 truncate text-right text-complementary">
        <EllipsisTooltip text={value} tooltipMaxWidth={350} />
      </div>
    </div>
  );
};
