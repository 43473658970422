import {
  type MileageAllowanceExpense,
  type SupplierExpense,
  type ExpenseClaim,
  type PerDiemAllowanceExpense,
  type ExpenseType,
} from '../types';

const userCounterpartyExpenseTypes = [
  'expense_claim',
  'mileage_allowance',
  'per_diem_allowance',
];
const supplierCounterpartyExpenseTypes = ['card_purchase', 'invoice_purchase'];

export const isUserCounterpartyExpense = (expense: {
  type: ExpenseType;
}): expense is
  | ExpenseClaim
  | PerDiemAllowanceExpense
  | MileageAllowanceExpense => {
  return userCounterpartyExpenseTypes.includes(expense.type);
};

export const isSupplierCounterpartyExpense = (expense: {
  type: ExpenseType;
}): expense is SupplierExpense => {
  return supplierCounterpartyExpenseTypes.includes(expense.type);
};
