import { hasApproverSubNav, SubnavigationItem } from 'modules/requests/utils';
import { routes, routeFor } from 'src/core/constants/routes';

export const getRequestsLink = (
  user: {
    is_approver: boolean;
    is_account_owner: boolean;
  },
  companyId: string,
): string => {
  const type = hasApproverSubNav(user)
    ? SubnavigationItem.ToApprove
    : SubnavigationItem.Mine;

  return routeFor(routes.REQUESTS.path, {
    type,
    company: companyId,
  });
};
