import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import type { ApiApprovalPolicySavePayload } from './approvalPolicyPayload';
import type { ApiApprovalPolicy } from '../reshapers';

export const useCreatePolicy = (): MutationState<
  ApiApprovalPolicySavePayload,
  ApiApprovalPolicy
> => {
  return useMutation<
    ApiApprovalPolicySavePayload,
    ApiApprovalPolicy,
    ApiApprovalPolicy
  >({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/policies',
    },
    reshapeData(data) {
      return data;
    },
  });
};
