import identity from 'lodash/identity';

import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type Payload = {
  paymentIds: string[];
};

export type Error = {
  error: 'payments-not-found';
};

export const useSuppressWarningsForInvoiceBeneficiariesQuery =
  (): MutationState<Payload, object, Error> => {
    return useMutation<Payload, object, object, Error>({
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: `/transfer-scheduling/payments/suppress-warnings-for-beneficiaries`,
        method: 'post',
      },
      options: {
        throwOnError: true,
      },
      reshapeData: identity,
    });
  };
