export type Role = { name: RoleName; permissions: Permissions };

export type RoleName = 'accountOwner' | 'admin' | 'controller' | 'requester';

type AdminPermissions = typeof adminPermissions;
type AccountOwnerPermissions = typeof accountOwnerPermissions;
type ControllerPermissions = typeof controllerPermissions;
type RequesterPermissions = typeof requesterPermissions;

type Permissions =
  | AccountOwnerPermissions
  | AdminPermissions
  | ControllerPermissions
  | RequesterPermissions;

export type Permission =
  | AccountOwnerPermissions[number]
  | AdminPermissions[number]
  | ControllerPermissions[number]
  | RequesterPermissions[number];

const accountOwnerPermissions = [
  'manageMembers',
  'updateMembersPolicies',
  'manageTeams',
  'viewPayments',
  'sendReminders',
  'manageSuppliers',
  'viewPolicies',
  'makeRequests',
  'uploadInvoices',
  'viewSharedInvoiceDraft',
] as const;

const adminPermissions = [
  'manageMembers',
  'updateMembersPolicies',
  'manageTeams',
] as const;

const controllerPermissions = [
  'viewPayments',
  'sendReminders',
  'manageSuppliers',
  'viewPolicies',
  'viewSharedInvoiceDraft',
] as const;

const requesterPermissions = ['makeRequests', 'uploadInvoices'] as const;

export const accountOwnerRole: Role = {
  name: 'accountOwner',
  permissions: accountOwnerPermissions,
};

export const adminRole: Role = {
  name: 'admin',
  permissions: adminPermissions,
};

export const controllerRole: Role = {
  name: 'controller',
  permissions: controllerPermissions,
};

export const requesterRole: Role = {
  name: 'requester',
  permissions: requesterPermissions,
};
