import { gql } from 'graphql-tag';

export const GET_EXPENSE_ACCOUNTS = gql`
  query GetExpenseAccounts($companyId: ID!, $filter: ExpenseAccountFilter) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        expenseAccounts(filter: $filter) {
          edges {
            node {
              id
              code
              name
              isDefault
              isArchived
              isAvailable
            }
          }
        }
      }
    }
  }
`;
