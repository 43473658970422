import { gql } from 'graphql-tag';

export const CREATE_SUPPLIER = gql`
  mutation CreateSupplier($companyId: ID!, $supplier: SupplierCreate!) {
    createSupplier(companyId: $companyId, supplier: $supplier) {
      ... on CreateSupplierResultCreated {
        id
      }
      ... on CreateSupplierResultNotCreated {
        reason
      }
    }
  }
`;
