export type GaugeSegment<KeyIdentifier extends string = string> = {
  key: KeyIdentifier;
  value: number;
  fillColor: string;
  onClick?: () => void;
  tooltipContent?: React.ReactChild;
};

export type GaugeMilestone<MilestoneIdentifier extends string = string> = Omit<
  GaugeSegment<MilestoneIdentifier>,
  'onClick'
>;

const MININMAL_DISPLAYED_PERCENTAGE = 2;
const MININMAL_DISPLAYED_WIDTH = '6px';

export const getSegmentWidth = (percentage: number) => {
  const isBelowMininmal = percentage <= MININMAL_DISPLAYED_PERCENTAGE;
  return isBelowMininmal ? MININMAL_DISPLAYED_WIDTH : `${percentage}%`;
};
