import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { type AppState } from 'src/core/reducers';

import { ValidReceiptModal } from './ValidReceiptModal';
import {
  saveWhatsAValidReceiptPopupSeenState,
  fetchFirstUserPayment,
} from './actions';

// FIXME: this method is not used in this component anymore
// But it's called by `LayoutContainer` to prioritize the display between modals, so we need to keep it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isVisible = (company: { id: string }, user: any) => {
  const userCompanyData = user.data_by_company[company.id];

  const hasSeenPopup =
    userCompanyData.seen_popups.has_seen_what_a_valid_receipt;

  const { nbPaymentsFromBeginning } = userCompanyData;

  return nbPaymentsFromBeginning > 0 && !hasSeenPopup;
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any; // FIXME: use proper type
  company: { id: string };
  firstUserPaymentId: string | null;
  saveWhatsAValidReceiptPopupSeenState: () => void;
  fetchFirstUserPayment: (userId: string) => void;
};

const ValidReceiptModalContainer = ({
  user,
  company,
  firstUserPaymentId,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  saveWhatsAValidReceiptPopupSeenState,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  fetchFirstUserPayment,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchFirstUserPayment(user.id);
  }, [fetchFirstUserPayment, user.id]);

  useEffect(() => {
    const userCompanyData = user.data_by_company[company.id];
    const hasSeenModal =
      userCompanyData.seen_popups.has_seen_what_a_valid_receipt;

    if (firstUserPaymentId && !hasSeenModal) {
      setIsModalVisible(true);
      saveWhatsAValidReceiptPopupSeenState();
    }
  }, [
    company.id,
    firstUserPaymentId,
    saveWhatsAValidReceiptPopupSeenState,
    user,
  ]);

  return isModalVisible ? (
    <ValidReceiptModal
      company={company}
      firstUserPaymentId={firstUserPaymentId}
    />
  ) : null;
};

const mapDispatchToProps = {
  saveWhatsAValidReceiptPopupSeenState,
  fetchFirstUserPayment,
};

export default connect(
  (state: AppState) => ({
    firstUserPaymentId: state.payments.firstUserPaymentId,
  }),
  mapDispatchToProps,
)(ValidReceiptModalContainer);
