import { useLocation } from 'react-router-dom';

import { getFilterFromSearchParams, type Filter } from '../models';

export const usePurchaseOrderFilter = (): Filter => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  return getFilterFromSearchParams(urlSearchParams);
};
