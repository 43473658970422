/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/no-nesting */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable promise/always-return */
import { createAction, type Dispatch } from '@reduxjs/toolkit';
import i18next from 'i18next';
import noop from 'lodash/noop';
import queryString from 'query-string';

import { companyAPI } from 'src/core/api/axios';
import {
  addNotification,
  NotificationType,
} from 'src/core/modules/app/notifications';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { apiUrl, graphql } from 'src/core/utils/api';

import * as types from './actionTypes';
import { fetchAllPayments as fetchAllPaymentsRequest } from '../graphql';
import { serialize } from '../utils/convertFiltersForApi';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Filter = {
  type: string;
  value: string;
};
const lastRequests: {
  fetchAllPayments: Promise<unknown> | null;
  fetchAllPaymentsCounters: Promise<unknown> | null;
  fetchEditablePaymentsCounters: Promise<unknown> | null;
} = {
  fetchAllPayments: null,
  fetchAllPaymentsCounters: null,
  fetchEditablePaymentsCounters: null,
};

// Reset
export const hidePaymentPanel = createAction(types.HIDE_PAYMENT_PANEL);
export const resetAllPayments = createAction(types.RESET_ALL_PAYMENTS);
export const resetAllPaymentsSelection = createAction(
  types.RESET_ALL_PAYMENTS_SELECTION,
);

// Filters and selection
const internalUpdateFilters = createAction<unknown>(types.UPDATE_FILTERS);
export const updateFilters =
  (...arguments_: unknown[]) =>
  (dispatch: Dispatch) => {
    dispatch(resetAllPaymentsSelection());
    // @ts-expect-error should probably not give the spread arguments directly
    dispatch(internalUpdateFilters(...arguments_));
  };
export const updateAllPaymentsSelection = createAction(
  types.UPDATE_ALL_PAYMENTS_SELECTION,
);

// Fetch all payments
// Fetch payments
const fetchAllPaymentsLoading = createAction(types.FETCH_ALL_PAYMENTS_LOADING);
const fetchAllPaymentsSuccess = createAction<unknown>(
  types.FETCH_ALL_PAYMENTS_SUCCESS,
);
const fetchAllPaymentsFailure = createAction(types.FETCH_ALL_PAYMENTS_FAILURE);

const isCompletionDeadlineFilter = ({ type }: Filter) => {
  return type === 'completionDeadline';
};

const toFiltersV1 = (filters?: Filter[]) => {
  const completionDeadlineFilter = filters?.find(isCompletionDeadlineFilter);
  if (completionDeadlineFilter?.value?.includes('late')) {
    return ['LateReceipt'];
  }
  return [];
};

const toFiltersV2 = (filters?: Filter[]) => {
  return filters?.filter((filter) => !isCompletionDeadlineFilter(filter));
};

export const fetchAllPayments =
  (
    options: {
      first?: number;
      after?: string;
      filters?: Filter[];
    } = {},
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(fetchAllPaymentsLoading());

    const variables = {
      companyId,
      first: options.first ?? 60,
      after: options.after,
      filtersV1: toFiltersV1(options.filters),
      filtersV2: toFiltersV2(options.filters),
    };
    const request = fetchAllPaymentsRequest(graphql, variables);

    lastRequests.fetchAllPayments = request;

    try {
      const result = await request;
      if (result && lastRequests.fetchAllPayments === request) {
        dispatch(fetchAllPaymentsSuccess(result.company));
      }
    } catch (error) {
      if (lastRequests.fetchAllPayments === request) {
        dispatch(fetchAllPaymentsFailure(error));
      }
    }

    return request;
  };

const fetchAllPaymentsCountersLoading = createAction(
  types.FETCH_ALL_PAYMENTS_COUNTERS_LOADING,
);
const fetchAllPaymentsCountersSuccess = createAction(
  types.FETCH_ALL_PAYMENTS_COUNTERS_SUCCESS,
);
const fetchAllPaymentsCountersFailure = createAction(
  types.FETCH_ALL_PAYMENTS_COUNTERS_FAILURE,
);
export const fetchAllPaymentsCounters =
  (
    options: {
      filters: Filter[];
      selection?: string;
    } = {
      filters: [],
    },
  ) =>
  (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(fetchAllPaymentsCountersLoading());

    const genericFilters = [
      ...options.filters,
      { type: 'selection', value: options.selection },
    ];

    const variables = {
      companyId: getState().global.company?.id,
      filtersBookkeeping: [
        ...genericFilters,
        { type: 'bookkeepable', value: true },
      ],
      filtersRemindInvoice: [
        ...genericFilters,
        { type: 'remindable_for_invoice', value: true },
      ],
      filtersDownload: [
        ...genericFilters,
        { type: 'downloadable', value: true },
      ],
      filtersEdit: [...genericFilters, { type: 'editable', value: true }],
    };
    const request = `
      query FetchPaymentsAndStats($companyId: String!, $filtersBookkeeping: [JSON], $filtersRemindInvoice: [JSON], $filtersDownload: [JSON], $filtersEdit: [JSON]) {
        company(id: $companyId) {
          bookkeepable: payments(filters_v2: $filtersBookkeeping) { total }
          remindable_for_invoice: payments(filters_v2: $filtersRemindInvoice) { total }
          downloadable: payments(filters_v2: $filtersDownload) { total }
          editable: payments(filters_v2: $filtersEdit) { total }
        }
      }
    `;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const request_: Promise<unknown> = graphql(request, variables)
      .catch(
        (error) =>
          lastRequests.fetchAllPaymentsCounters === request_ &&
          dispatch(fetchAllPaymentsCountersFailure(error)),
      )
      .then(
        (result) =>
          lastRequests.fetchAllPaymentsCounters === request_ &&
          dispatch(fetchAllPaymentsCountersSuccess(result.company)),
      );

    lastRequests.fetchAllPaymentsCounters = request_;

    return request_;
  };

const fetchPaymentLoading = createAction<string>(
  types.FETCH_SINGLE_PAYMENT_LOADING,
);
const fetchPaymentSuccess = createAction(types.FETCH_SINGLE_PAYMENT_SUCCESS);
const fetchPaymentFailure = createAction<string>(
  types.FETCH_SINGLE_PAYMENT_FAILURE,
);
export const fetchPayment =
  (paymentId: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getState().global.company?.id ?? '';
    const handlerAction = fetchPaymentSuccess;

    dispatch(fetchPaymentLoading(paymentId));

    let payment;
    try {
      // we fetch and aggregate payment + related missing receipt data
      const [{ data: paymentData }, missingReceipt] = await Promise.all([
        companyAPI.get(`/payments/${paymentId}`, { companyId }),
        fetchMissingReceipt({ companyId, paymentId }),
      ]);
      payment = {
        ...paymentData,
        missingReceipt,
      };
    } catch (error) {
      dispatch(fetchPaymentFailure(paymentId));
      throw error;
    }

    dispatch(handlerAction(payment));
  };

const fetchMissingReceipt = async ({
  companyId,
  paymentId,
}: {
  companyId: string;
  paymentId: string;
}) => {
  const {
    data: { missingReceipt },
  } = await companyAPI.get(`/missing-receipt/${paymentId}`, {
    companyId,
  });

  if (missingReceipt?.affidavit?.filename) {
    const { data: affidavitDocumentData } = await companyAPI.get(
      `/documentary-evidence/document/${missingReceipt.affidavit.filename}`,
      {
        companyId,
      },
    );
    return {
      ...missingReceipt,
      affidavitDocument: affidavitDocumentData,
    };
  }

  return missingReceipt;
};

export const updatePaymentLocally = createAction(
  types.UPDATE_SINGLE_PAYMENT_LOCALLY,
);

// Fetch a single payment's invoices
const fetchPaymentInvoicesLoading = createAction(
  types.FETCH_SINGLE_PAYMENT_INVOICES_LOADING,
);
const fetchPaymentInvoicesSuccess = createAction<{
  paymentId: string;
  invoices: unknown[];
}>(types.FETCH_SINGLE_PAYMENT_INVOICES_SUCCESS);
const fetchPaymentInvoicesToExportSuccess = createAction(
  types.FETCH_SINGLE_PAYMENT_TO_EXPORT_INVOICES_SUCCESS,
);
export const fetchPaymentInvoices =
  (
    paymentId: string,
    toExport: boolean = false,
    options: {
      emptyWhileRefreshing?: boolean;
      retryUntilSizeMatches?: number;
    },
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getState().global.company?.id ?? '';

    if (options?.emptyWhileRefreshing ?? true) {
      dispatch(fetchPaymentInvoicesLoading());
    }

    const retryUntilSizeMatches = options?.retryUntilSizeMatches || false;
    const shouldRetryUntilSizeMatches = retryUntilSizeMatches !== false;
    const isInvoiceSizeMatchingExpectedSize = (invoicelist: unknown[]) =>
      invoicelist.length === retryUntilSizeMatches;

    let invoices;
    let attempts = 0;
    do {
      try {
        const res = await companyAPI.get(`/payments/${paymentId}/invoices`, {
          companyId,
        });
        invoices = res.data;

        if (
          shouldRetryUntilSizeMatches &&
          !isInvoiceSizeMatchingExpectedSize(invoices)
        ) {
          await new Promise((resolve) =>
            setTimeout(async () => {
              resolve(undefined);
            }, 2000),
          );
        }
      } catch {
        // FIXME: handle the error?
        invoices = [];
      }
      attempts += 1;
    } while (
      shouldRetryUntilSizeMatches &&
      !isInvoiceSizeMatchingExpectedSize(invoices) &&
      attempts < 15
    );

    if (toExport) {
      dispatch(fetchPaymentInvoicesToExportSuccess(invoices));
    } else {
      dispatch(
        fetchPaymentInvoicesSuccess({
          paymentId,
          invoices,
        }),
      );
    }
  };

// Delete invoice
const deleteInvoiceLoading = createAction(types.DELETE_INVOICE_LOADING);
const deleteInvoiceSuccess = createAction(types.DELETE_INVOICE_SUCCESS);
export const deleteInvoice =
  (invoiceId: string, callback = noop) =>
  (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getState().global.company?.id ?? '';

    dispatch(deleteInvoiceLoading());

    return fetch(apiUrl(`/invoices/${invoiceId}`, companyId), {
      method: 'DELETE',
      credentials: 'include',
    }).then((res) => {
      callback(res);

      res.json().then((json) => {
        if (!json.canDelete && json.reason === 'noOtherReceipt') {
          dispatch(
            // @ts-expect-error should not dispatch a notification here, + typing with dispatch does not work
            addNotification({
              type: NotificationType.Danger,
              message: i18next.t('payments.actions.noOtherReceiptError'),
            }),
          );

          return;
        }

        dispatch(deleteInvoiceSuccess(json));
      });
    });
  };

// FIXME: this should be handled by an action handling the invoices upload
export const incrementPaymentInvoices = createAction(
  types.INCREMENT_PAYMENT_INVOICES,
);

const downloadPaymentsLoading = createAction(types.DOWNLOAD_PAYMENTS_LOADING);
const downloadPaymentsSuccess = createAction(types.DOWNLOAD_PAYMENTS_SUCCESS);
export const downloadPayments =
  ({
    selection,
    filters,
    withReceipts,
  }: {
    selection: string;
    filters: Filter[];
    withReceipts: boolean;
  }) =>
  (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(downloadPaymentsLoading());

    const allFilters = [
      ...serialize(filters),
      { type: 'selection', value: selection },
    ];

    const params = {
      filters: JSON.stringify(allFilters),
      withReceipts,
    };

    const baseUrl = apiUrl('/payments/download', getState().global.company?.id);
    window.open(`${baseUrl}?${queryString.stringify(params)}`, '_blank');

    dispatch(downloadPaymentsSuccess());
  };

export const resetBulkEditPayments = createAction(
  types.RESET_BULK_EDIT_PAYMENTS,
);
const bulkEditPaymentsLoading = createAction(types.BULK_EDIT_PAYMENTS_LOADING);
const bulkEditPaymentsSuccess = createAction(types.BULK_EDIT_PAYMENTS_SUCCESS);
const bulkEditPaymentsFailure = createAction(types.BULK_EDIT_PAYMENTS_FAILURE);
export const bulkEditPayments =
  (
    options: {
      selection?: string;
      filters: Filter[];
    } = {
      filters: [],
    },
  ) =>
  (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(bulkEditPaymentsLoading());

    const genericFilters = [
      ...serialize(options.filters),
      { type: 'selection', value: options.selection },
    ];

    const variables = {
      companyId: getState().global.company?.id,
      filtersEdit: [...genericFilters, { type: 'editable', value: true }],
    };
    const request = `
      query FetchPaymentsAndStats($companyId: String!, $filtersEdit: [JSON]) {
        company(id: $companyId) {
          editable: payments(filters_v2: $filtersEdit) {
            edges {
              node {
                databaseId
                description
                request_id
                card_id
                supplier_id
                supplier {
                  name
                  url
                }
                group {
                  databaseId
                  name
                }
                vat_type
                expense_account_id
                nature {
                  id
                  label
                  accounting_code
                }
                custom_fields {
                  field {
                    databaseId
                    name
                    type
                  }
                  value {
                    databaseId
                    value
                  }
                }
                request {
                  type
                }
                updatableStatus {
                  isReceiptDeletable
                  isReceiptUploadable
                  isSupplierAddable
                  isDescriptionEditable
                  isTeamEditable
                  isCostCenterEditable
                  isCustomFieldsEditable
                  isTaxAccountEditable
                  isAccountable
                }
              }
            }
          }
        }
      }
    `;

    const request_: Promise<unknown> = graphql(request, variables)
      .catch(
        (error) =>
          lastRequests.fetchEditablePaymentsCounters === request_ &&
          dispatch(bulkEditPaymentsFailure(error)),
      )
      .then(
        (result) =>
          lastRequests.fetchEditablePaymentsCounters === request_ &&
          dispatch(bulkEditPaymentsSuccess(result.company)),
      );

    lastRequests.fetchEditablePaymentsCounters = request_;

    return request_;
  };

const bulkMarkAsMissingLoading = createAction(
  types.MARK_PAYMENTS_MISSING_RECEIPT_LOADING,
);
const bulkMarkAsMissingFinish = createAction(
  types.MARK_PAYMENTS_MISSING_RECEIPT_FINISH,
);
export const bulkMarkAsMissing =
  ({ selection, filters }: { selection: string; filters: Filter[] }) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(bulkMarkAsMissingLoading());

    const companyId = getCompanyId(getState());
    const allFilters = [
      ...serialize(filters),
      { type: 'selection', value: selection },
    ];

    let result;
    try {
      const { data } = await companyAPI.post(
        '/payments/missing-receipts',
        allFilters,
        {
          companyId,
        },
      );
      result = data;
    } finally {
      dispatch(bulkMarkAsMissingFinish());
    }

    // @ts-expect-error typing error with fn that return a dispatch
    dispatch(refreshAllPayments());
    return result;
  };

const remindInvoicesLoading = createAction(types.REMIND_INVOICES_LOADING);
const remindInvoicesSuccess = createAction(types.REMIND_INVOICES_SUCCESS);
const remindInvoicesFailure = createAction(types.REMIND_INVOICES_FAILURE);
export const remindInvoices =
  ({ selection, filters }: { selection: string; filters: Filter[] }) =>
  (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(remindInvoicesLoading());

    // @ts-expect-error typing error with the serialize function regarding filters
    filters = serialize(filters);
    const variables = {
      input: {
        company_id: getState().global.company?.id,
        filters: [...filters, { type: 'selection', value: selection }],
      },
    };

    const request = `
      mutation PaymentsRemindInvoice($input: PaymentsRemindInvoiceInput!) {
        paymentsRemindInvoice(input: $input) {
          nb_payments
        }
      }
    `;

    return graphql(request, variables)
      .catch((error) => dispatch(remindInvoicesFailure(error)))
      .then((result) => {
        const res = dispatch(
          remindInvoicesSuccess(result.paymentsPushBookkeeping),
        );
        // @ts-expect-error typing error with fn that return a dispatch
        dispatch(fetchAllPaymentsCounters({ filters, selection }));
        return res;
      });
  };

export const refreshAllPayments =
  () => (dispatch: Dispatch, getState: () => AppState) => {
    const filters = serialize(getState().payments.filters);
    dispatch(resetAllPayments());
    // @ts-expect-error typing error with fn that return a dispatch
    dispatch(fetchAllPayments({ filters }));
  };

export const setPaymentsAllOpenedPayment = createAction(
  types.SET_PAYMENTS_ALL_OPENED_PAYMENT,
);

const deleteDocumentaryEvidenceLoading = createAction(
  types.DELETE_DOCUMENTARY_EVIDENCE_LOADING,
);
const deleteDocumentaryEvidenceSuccess = createAction<{
  paymentId: string;
  documentaryEvidenceId: string;
}>(types.DELETE_DOCUMENTARY_EVIDENCE_SUCCESS);
const deleteDocumentaryEvidenceFailure = createAction(
  types.DELETE_DOCUMENTARY_EVIDENCE_FAILURE,
);

export const deleteDocumentaryEvidence = ({
  paymentId,
  documentaryEvidenceId,
}: {
  paymentId: string;
  documentaryEvidenceId: string;
}) => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(deleteDocumentaryEvidenceLoading());
    try {
      const companyId = getCompanyId(getState());
      await companyAPI.delete(
        `/documentary-evidence/${documentaryEvidenceId}`,
        {
          companyId,
        },
      );
    } catch (error) {
      dispatch(deleteDocumentaryEvidenceFailure(error));
      throw error;
    }
    dispatch(
      deleteDocumentaryEvidenceSuccess({ paymentId, documentaryEvidenceId }),
    );
  };
};
