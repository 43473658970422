import { type MonetaryValue } from 'ezmoney';
import React, { type ReactElement } from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense/';
import { usePurchaseOrderOverbillForecast } from 'modules/purchase-orders/hooks';

import { type OverbillForecast } from '../../models';

type Props = {
  purchaseOrderId: string;
  newInvoiceAmount: MonetaryValue;
  oldInvoiceAmount?: MonetaryValue;
  children: (overbillForecast: OverbillForecast) => ReactElement | null;
};

export const PurchaseOrderOverbillForecastWarning = ({
  purchaseOrderId,
  newInvoiceAmount,
  oldInvoiceAmount,
  children,
}: Props) => {
  const purchaseOrderOverbillForecastQueryState =
    usePurchaseOrderOverbillForecast(purchaseOrderId)(
      newInvoiceAmount,
      oldInvoiceAmount,
    );

  return (
    <QuerySuspense
      queryState={purchaseOrderOverbillForecastQueryState}
      loading={null}
      fallback={() => null}
    >
      {children}
    </QuerySuspense>
  );
};
