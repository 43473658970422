/* eslint-disable unicorn/no-await-expression-member */
import React from 'react';

import { EmptyStateError, lazyLoad } from 'common/components/LazyLoad';

import { ProfilePageSkeleton } from '../components/ProfilePageSkeleton';

export const ProfilePage = lazyLoad({
  loader: async () => ({
    default: (
      await import(/* webpackChunkName: "ProfilePage" */ './ProfilePage')
    ).ProfilePage,
  }),
  loading: <ProfilePageSkeleton />,
  fallback: (props) => <EmptyStateError {...props} />,
});
