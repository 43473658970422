import { Redirect, Switch, Route } from 'react-router';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import type { NavigationLinkProps } from 'src/core/common/components/NavigationLink';
import { ProtectedRoute } from 'src/core/common/components/ProtectedRoute';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import {
  CostCentersActivationPageContainer,
  CostCentersPageContainer,
} from 'src/core/modules/company/cost-centers';
import { CustomFieldsContainer } from 'src/core/modules/custom-fields';

import { SettingsOrganisationMembersPage } from './SettingsOrganisationMembersPage/SettingsOrganisationMembersPage';
import { SettingsOrganisationTeamsPage } from './SettingsOrganisationTeamsPage/SettingsOrganisationTeamsPage';

export const SettingsOrganisationPage = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const company = useCompany();
  const user = useUser();
  const isCostCentersFeatureEnabled = useFeature(FEATURES.COST_CENTERS);
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);
  const isCostCentersSettingsEnabled = useFeature(
    FEATURES.SETTINGS_COST_CENTERS,
  );
  const hasTeamsFeature = useFeature(FEATURES.TEAMS);

  const isAoOrController = user.is_account_owner || user.is_controller;

  const links: NavigationLinkProps[] = [];

  if (!company.churning_at && (user.is_account_owner || user.is_admin)) {
    links.push({
      key: 'members',
      path: routeFor(routes.COMPANY_MEMBERS.path, {
        company: company.id,
        id: '',
      }),
      text: t('settings.links.members'),
    });
  }

  if (
    isCostCentersFeatureEnabled &&
    isCostCentersSettingsEnabled &&
    (user.is_account_owner || user.is_admin)
  ) {
    links.push({
      key: 'cost-centers',
      path: routeFor(routes.COST_CENTERS.path, {
        company: company.id,
      }),
      text: t('settings.links.costCenters'),
    });
  }

  if (hasTeamsFeature) {
    links.push({
      key: 'company-teams',
      text: t('members.navLinks.teams'),
      path: routeFor(routes.COMPANY_TEAMS.path, {
        company: companyId,
      }),
    });
  }

  if (hasCustomFieldsFeature && (isAoOrController || user.is_admin)) {
    links.push({
      key: 'custom-fields',
      path: routeFor(routes.COMPANY_CATEGORIES.path, {
        company: companyId,
      }),
      text: t('settings.links.customFields'),
    });
  }

  const isAoOrAdmin = user.is_account_owner || user.is_admin;

  const isAccessAllowed = isAoOrAdmin;

  const hasCostCentersFeature = useFeature(FEATURES.COST_CENTERS);
  const hasCostCentersActivated = useFeature(FEATURES.COST_CENTERS_ACTIVATED);

  return (
    <ErrorBoundary context={{ scope: 'members-settings-page', team: 'growth' }}>
      <div className="page__container">
        <HeaderWithNavigation links={links}>
          {t('navigation.organisation')}
        </HeaderWithNavigation>
        <div className="grow p-3xl">
          <div className="flex flex-col gap-xxl">
            <Switch>
              <ProtectedRoute
                isAccessAllowed={isAccessAllowed}
                path={routes.COMPANY_MEMBERS.path}
              >
                <SettingsOrganisationMembersPage />
              </ProtectedRoute>
              <ProtectedRoute
                isAccessAllowed={isAccessAllowed}
                path={routes.COMPANY_TEAMS.path}
              >
                <SettingsOrganisationTeamsPage />
              </ProtectedRoute>

              <ProtectedRoute
                path={routes.COST_CENTERS.path}
                isAccessAllowed={hasCostCentersFeature}
              >
                {hasCostCentersActivated ? (
                  <CostCentersPageContainer />
                ) : (
                  <CostCentersActivationPageContainer />
                )}
              </ProtectedRoute>

              <Route path={routes.COMPANY_CATEGORIES.path}>
                <CustomFieldsContainer />
              </Route>

              <Redirect to={routes.HOMEPAGE.path} />
            </Switch>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
