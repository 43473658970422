import { GET_SETTLEMENTS_IN_PERIOD } from 'modules/bookkeep/export/graphql/queries/settlements';
import { useQuery } from 'src/core/api/hooks/useQuery';

import { getExportSettlementsCountQueryKey } from './query-key-registry';

export type ExportSettlementsCountRawResponse = {
  company: {
    id: string;
    settlements: {
      totalCount: number;
    };
  };
};

export type ExportSettlementsCountResponse = { totalCount: number };

export type ExportSettlementsCountVariables = {
  companyId: string;
  excludeNonPreparedPayables: boolean;
  includeAlreadyExported: boolean;
  fromCreationDate: string;
  toCreationDate: string;
};

export const useExportSettlementsCount = (
  variables: ExportSettlementsCountVariables,
) =>
  useQuery<{ totalCount: number }, ExportSettlementsCountRawResponse>({
    key: getExportSettlementsCountQueryKey(variables.companyId, variables),
    request: {
      type: 'graphQL',
      target: 'v2',
      variables,
      query: GET_SETTLEMENTS_IN_PERIOD,
    },
    reshapeData: ({
      company: {
        settlements: { totalCount },
      },
    }) => ({ totalCount }),
  });
