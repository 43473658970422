import { useFormik } from 'formik';
import React from 'react';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from "src/core/common/hooks/useTranslation";

import { type BudgetaryExerciseInput } from './budgetaryExerciseInput';
import { useUpdateBudgetaryExerciseMutation } from './hooks/updateBudgetaryExerciseMutation';
import { validate } from './validator';
import { EditBudgetaryExerciseModal } from '../../components/EditBudgetaryExerciseModal';
import { type BudgetaryExercise } from '../../models/budgetaryExercise';

type Props = {
  budgetaryExercise: BudgetaryExercise;
  onHide(): void;
};

export const EditBudgetaryExerciseModalContainer = ({
  budgetaryExercise,
  onHide,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const [updateBudgetaryExercise] = useUpdateBudgetaryExerciseMutation(
    budgetaryExercise.id,
  );

  const formikProps = useFormik<BudgetaryExerciseInput>({
    initialValues: {
      name: budgetaryExercise.name,
    },
    validateOnChange: false,
    validate: (values) => {
      return validate(values, t);
    },
    onSubmit: async (values) => {
      const formErrors = await formikProps.validateForm(values);
      if (Object.keys(formErrors).length === 0) {
        try {
          await updateBudgetaryExercise({
            name: values.name,
          });
          pushNotif({
            type: NotificationType.Success,
            message: t('budget.budgetaryExercise.edit.notifSuccess', {
              name: values.name,
            }),
          });
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('budget.budgetaryExercise.edit.notifFailure'),
          });
        }
        onHide();
      }
    },
  });

  return (
    <EditBudgetaryExerciseModal
      {...formikProps}
      budgetaryExercise={budgetaryExercise}
      onHide={onHide}
    />
  );
};
