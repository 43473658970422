import { useDispatch } from 'react-redux';

import { requestMfaProcedureSuccess } from 'common/components/ModalMFA/actions';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';
import { useQueryError } from 'src/core/common/components/QueryError';

import { useNotifications } from '../../app/notifications';
import { type Delegation } from '../models';

type InitiateDelegationPayload = {
  userId: string;
  role: Delegation;
};

type InitiateDelegationResponse = {
  authType: 'mfa';
  factorId: string;
  procedureId: string;
};

type InitiateDelegationError = {
  error: 'factorError';
};

export const useInitiateDelegation = () => {
  const dispatch = useDispatch();
  const { dangerNotif } = useNotifications();

  const getErrorMessage = useGetErrorMessage();

  return useMutation<
    InitiateDelegationPayload,
    InitiateDelegationResponse,
    InitiateDelegationResponse,
    InitiateDelegationError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/delegations/initiate`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data }): void => {
        dispatch(
          requestMfaProcedureSuccess({
            id: data.procedureId,
            factorId: data.factorId,
          }),
        );
      },
      onError(queryError) {
        dangerNotif(getErrorMessage(queryError.error));
      },
    },
    reshapeData: (data) => data,
  });
};

export const useGetErrorMessage = () => {
  const getErrorMessage = useQueryError<InitiateDelegationError>({
    requestError: ({ error }) => {
      switch (error) {
        case 'factorError':
          return 'delegation.factorError';
        default:
          return 'delegation.instantiateError';
      }
    },
    serverError: 'invoices.transfer.cancel.error',
  });

  return (queryError: QueryError<InitiateDelegationError>): string =>
    getErrorMessage(queryError);
};
