import { Callout, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { useUser } from 'modules/app/hooks/useUser';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useCostCentersQuery } from 'modules/budgets/apis';
import {
  usePoliciesQuery,
  useRolesQuery,
  useTeamsQuery,
  useMembersQuery,
} from 'modules/members/hooks';
import { useUsersCountLimitFeatures } from 'modules/members/hooks/useUsersCountLimitFeatures';
import { type Member } from 'modules/members/models/member';
import isValidEmail from 'src/auth/utils/isValidEmail';
import { useQueryStates } from 'src/core/api/hooks';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { track, AnalyticEventName } from 'src/core/utils/analytics';

import { MemberInviteEmailContent } from '../../components/MemberInviteEmailContent/MemberInviteEmailContent';
import { useSendInvitesMutation } from '../../hooks/useSendInvitesMutation';
import {
  type DataSources,
  type DisplayablePolicy,
  type EmailInvitationResult,
  type InviteSettings,
  type SendInvitesPayload,
  getWhetherInvitationIsSuccessful,
  type InvitationResult,
} from '../../models/invite';

type Props = {
  showTip(): void;
  remainingSeatsForUsersCount: number | undefined;
  handleInviteCreationResult: (result: InvitationResult) => void;
  onClose(): void;
};

type Features = {
  isTeamsFeatureEnabled: boolean;
  isCostCenterActivatedFeatureEnabled: boolean;
};

const toQueryIds = (value?: { id: string }) => (value ? [value.id] : null);

const convertInviteSettingsForQuery = (
  features: Features,
  settings: InviteSettings,
) => ({
  role_ids: toQueryIds(settings.role),
  policy_ids: toQueryIds(settings.policy),
  ...(features.isCostCenterActivatedFeatureEnabled && {
    default_cost_center_id: settings.costCenter ? settings.costCenter.id : null,
  }),
  ...(features.isTeamsFeatureEnabled && {
    team_ids: settings.team ? toQueryIds(settings.team) : null,
  }),
});

const getEmailImportErrorReason = (
  metadata: { outcome: string },
  t: TGlobalFunctionTyped,
) => {
  const { outcome } = metadata;

  switch (outcome) {
    case 'invited_in_another_organisation':
      return t('members.isAlreadyInvitedAnotherOrganisation');
    case 'already_invited_to_company':
      return t('members.isAlreadyInvitedSameCompany');
    case 'registered_in_another_organisation':
      return t('members.isAlreadyMemberAnotherOrganisation');
    case 'already_registered_in_company':
      return t('members.isAlreadyMemberSameCompany');
    // ignoring 'grafted_invite_to_company' & 'grafted_user_to_company'
    default:
      return '';
  }
};

export const MemberInviteEmailContentContainer = ({
  showTip,
  remainingSeatsForUsersCount,
  handleInviteCreationResult,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const companyDomain = useUser().email.split('@')[1];
  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);
  const isCostCenterActivatedFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const hasUsersCountLimitFeature = useUsersCountLimitFeatures();

  const propertiesQueryStates = useQueryStates({
    states: {
      members: useMembersQuery(),
      teams: useTeamsQuery(),
      roles: useRolesQuery(),
      policies: usePoliciesQuery(),
      costCenters: useCostCentersQuery(),
    },
  });
  const [sendInvites] = useSendInvitesMutation();

  const handleSendInvitationResults = (results: EmailInvitationResult[]) => {
    const areAllInvitationsSuccessful = results.every(
      getWhetherInvitationIsSuccessful,
    );
    if (areAllInvitationsSuccessful) {
      handleInviteCreationResult({
        isSuccessful: true,
        numInvited: results.length,
        email: results[0].email,
      });
    } else {
      const sortedInvites = [...results].sort((email) =>
        getWhetherInvitationIsSuccessful(email) ? 1 : -1,
      );
      const messages = sortedInvites.map((result) => {
        const isSuccessful = getWhetherInvitationIsSuccessful(result);
        const text = `${result.email} ${
          !isSuccessful ? getEmailImportErrorReason(result, t) : ''
        }`;
        return { text, isError: !isSuccessful };
      });

      handleInviteCreationResult({
        isSuccessful: false,
        type: 'email',
        messages,
      });
    }
  };

  const handleSendInvitations = async (payload: SendInvitesPayload) => {
    const emails = payload.emails.filter(isValidEmail).join(',');
    if (emails.length === 0) {
      return;
    }

    try {
      track(AnalyticEventName.SETTINGS_MEMBERS_INVITE_SEND_BUTTON_CLICKED, {
        nbOfEmails: emails.length,
      });

      const invitationResults = await sendInvites({
        emails,
        ...convertInviteSettingsForQuery(
          {
            isTeamsFeatureEnabled,
            isCostCenterActivatedFeatureEnabled,
          },
          payload.settings,
        ),
      });
      handleSendInvitationResults(invitationResults);
    } catch (error) {
      pushNotif({
        type: NotificationType.Danger,
        message: t('misc.somethingWrong'),
      });
      throw new Error(error);
    }
  };

  const render = (members: Member[], dataSources: DataSources) => (
    <>
      {hasUsersCountLimitFeature && (
        <Callout
          className="mb-s"
          title={t('members.seatsRemaining', {
            count: remainingSeatsForUsersCount,
          })}
          variant="info"
        />
      )}
      <MemberInviteEmailContent
        companyDomain={companyDomain}
        members={members}
        settingsDataSources={dataSources}
        accountOwner={members?.find((member) => member.isAccountOwner)}
        isTeamsFeatureEnabled={isTeamsFeatureEnabled}
        isCostCenterActivatedFeatureEnabled={
          isCostCenterActivatedFeatureEnabled
        }
        remainingSeatsForUsersCount={remainingSeatsForUsersCount}
        sendInvites={handleSendInvitations}
        showMembersTipModal={showTip}
        onCancel={onClose}
      />
    </>
  );

  return (
    <QuerySuspense
      queryState={propertiesQueryStates}
      loading={<SkeletonText size="xl" className="mt-s" />}
      fallback={(error) => (
        <QueryError componentType="ErrorState" queryError={error} />
      )}
    >
      {({ members, policies, costCenters, roles, teams }) =>
        render(members, {
          policies: policies.filter(
            (policy): policy is DisplayablePolicy => !!policy.name,
          ),
          costCenters,
          roles,
          teams,
        })
      }
    </QuerySuspense>
  );
};
