import { Tag, Button, Link, Callout, Avatar } from '@dev-spendesk/grapes';
import React, { useId } from 'react';
import { Trans } from 'react-i18next';

import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { useTranslation } from 'common/hooks/useTranslation';
import { type Integration } from 'modules/integrations/types';
import {
  getIntegrationDescriptionKey,
  getHelpCenter,
  getIntegrationLogo,
  getIntegrationName,
  isKomboIntegration,
} from 'src/core/modules/integrations/utils';

type Props = {
  integration: Integration & {
    active: boolean;
    available: boolean;
  };
  selectIntegration: (integration: Integration) => void;
};

export function IntegrationBox(props: Props) {
  const { integration, selectIntegration } = props;
  const { active, available, id, error } = integration;

  const { t } = useTranslation('global');

  const titleId = useId();
  const logo = getIntegrationLogo(id);
  const name = getIntegrationName(t, id);
  const helpCenter = getHelpCenter(t, id);
  const descriptionKey = getIntegrationDescriptionKey(id);

  const shouldRenderHrIntegrationsErrorCallout =
    active && isKomboIntegration(integration.id) && error === 'notConnected';

  const renderActiveStatusTag = () => {
    if (!error) {
      return (
        <Tag className="ml-auto" variant="success">
          <Trans i18nKey="integration.tags.connected" />
        </Tag>
      );
    }

    switch (error) {
      case 'notConnected':
        return (
          <Tag className="ml-auto" variant="alert">
            <Trans i18nKey="integration.tags.notConnected" />
          </Tag>
        );
      case 'pendingAction':
        return (
          <Tag className="ml-auto" variant="warning">
            <Trans i18nKey="integration.tags.pendingAction" />
          </Tag>
        );
      default:
        return null;
    }
  };

  return (
    <article className="box flex flex-col gap-s p-m" aria-labelledby={titleId}>
      <div className="flex flex-row items-center gap-xs">
        <Avatar
          variant="square"
          text={name}
          src={logo}
          size="m"
          fallbackSrc={fallbackSupplierLogoSrc}
        />
        <h1 className="text-complementary title-l" id={titleId}>
          {name}
        </h1>
        {active && renderActiveStatusTag()}
      </div>
      <div className="w-full grow text-neutral-dark">
        {shouldRenderHrIntegrationsErrorCallout ? (
          <Callout
            title={t(
              'integration.errors.hrIntegrationAuthenticationFailed.details',
            )}
            variant="alert"
          />
        ) : (
          <>
            <Trans i18nKey={descriptionKey}>
              text
              <Link
                className="text-complementary"
                isExternal
                href={helpCenter}
                rel="noreferrer"
              >
                link
              </Link>
              text
            </Trans>
            {error === 'pendingSync' && (
              <Callout
                className="mt-s"
                title={t('integration.errors.pendingSync.title')}
                variant="primary"
                iconName="sync"
              />
            )}
          </>
        )}
      </div>
      <div className="w-full">
        {available ? (
          <Button
            variant="secondary"
            fit="parent"
            iconName={active ? 'settings' : 'sync'}
            text={t(active ? 'integration.manage' : 'integration.connect')}
            onClick={() => selectIntegration(integration)}
          />
        ) : (
          error && (
            <Callout
              title={t(`integration.errors.${error}.details`)}
              variant="info"
            />
          )
        )}
      </div>
    </article>
  );
}
