import { useQuery } from 'src/core/api/hooks/useQuery';

export type AllowedShippingCountries =
  | { restricted: false }
  | { restricted: true; countries: string[] };

export function useAllowedShippingCountries() {
  return useQuery<AllowedShippingCountries>({
    key: ['cards', 'allowedCountries'],
    request: {
      target: 'companyAPI',
      type: 'rest',
      endpoint: '/cards/allowed-countries-for-order',
    },
  });
}
