import { useParams } from 'react-router-dom';

import { type TaxAccount } from 'modules/payable/components';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { GET_TAX_ACCOUNTS } from './query';
import {
  reshapeTaxAccount,
  type ReverseChargeAccountApi,
  type VatAccountApi,
} from './reshaper';

type RawData = {
  company: {
    chartOfAccounts: {
      taxAccounts: {
        edges: {
          node: VatAccountApi | ReverseChargeAccountApi;
        }[];
      };
    };
  };
};

export const useTaxAccountsQuery = (
  filter: { includeArchived: boolean; ids?: string[] | undefined } = {
    includeArchived: false,
    ids: undefined,
  },
): QueryState<TaxAccount[]> => {
  const { company: companyId } = useParams();

  // TODO: use infinite query
  return useQuery<TaxAccount[], RawData>({
    key: ['useTaxAccountsQuery', companyId, filter],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_TAX_ACCOUNTS,
      variables: {
        filter,
      },
    },
    reshapeData: (rawData) => {
      const { edges } = rawData.company.chartOfAccounts.taxAccounts;
      return edges.map(({ node }) => reshapeTaxAccount(node));
    },
  });
};
