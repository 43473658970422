import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type SlackProviderSettings } from '../../../../models';

type RawSlackProviderSettings = {
  clientId: string;
  permissions: string;
};

export const useGetSlackProviderSettingsQuery =
  (): QueryState<SlackProviderSettings> => {
    const companyId = useCompanyId();

    return useQuery<SlackProviderSettings, RawSlackProviderSettings>({
      key: ['slackProviderSettings', companyId],
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: '/slack-provider-settings',
      },
      reshapeData: (rawData) => {
        return {
          clientId: rawData.clientId,
          permissions: rawData.permissions,
        };
      },
    });
  };
