import { convertValues } from '../../utils/convertValues';

export const MAX_CHAR_PER_CUSTOM_FIELDS = 400_000;
export const MAX_VALUES_PER_CUSTOM_FIELD = 25_000;

type FormValuesError = {
  tooManyCustomFieldValues?: boolean;
  oneCustomFieldValueRequired?: boolean;
  tooManyCharacters?: boolean;
};

export const validate = (
  values: {
    customFieldValues?: string;
  },
  oldValuesLength?: number,
): FormValuesError => {
  const errors: FormValuesError = {};
  const newValues = convertValues(values.customFieldValues);
  const currentNumberOfValues = newValues?.length ?? 0;
  if (
    currentNumberOfValues >
    MAX_VALUES_PER_CUSTOM_FIELD - (oldValuesLength ?? 0)
  ) {
    errors.tooManyCustomFieldValues = true;
  }
  if (currentNumberOfValues === 0) {
    errors.oneCustomFieldValueRequired = true;
  }
  if ((values.customFieldValues?.length ?? 0) > MAX_CHAR_PER_CUSTOM_FIELDS) {
    errors.tooManyCharacters = true;
  }

  return errors;
};
