import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ExpenseAccount } from '../../accounting';

type RawData = {
  expenseAccount: ExpenseAccount | undefined;
};

export type Result = ExpenseAccount | undefined;

export const useGetDefaultExpenseAccountQuery = (
  { isEnabled } = { isEnabled: true },
): QueryState<Result> => {
  const { company: companyId } = useParams();

  return useQuery<Result, RawData>({
    key: ['useGetDefaultExpenseAccountQuery', companyId],
    isEnabled,
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint:
        '/accounting-integration/chart-of-accounts/expense-accounts/default',
    },
    reshapeData(data) {
      return data.expenseAccount;
    },
  });
};
