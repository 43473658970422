import {
  Button,
  colors,
  DropdownItem,
  Icon,
  Input,
  Tag,
} from '@dev-spendesk/grapes';
import React from 'react';

import { useGetOrganisationEntities } from 'modules/company/billing/components/CompanyPlan/hooks/useGetOrganisationEntities';
import { DropdownSwitcherContent } from 'src/core/common/components/DropdownSwitcherContent';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { sortByNameAsc } from 'src/core/common/utils/sortByNameAsc';
import { routeFor, routes } from 'src/core/constants/routes';
import { useIsMultiEntityHubPage } from 'src/core/modules/app/layout/components/VerticalNavigation/helpers';
import { OrganisationReportingEntityListSearch } from 'src/core/modules/organisation-reporting/components/OrganisationReportingPage/OrganisationReportingEntityList/OrganisationReportingEntityListSearch';
import { useHasAccessToMultiEntityHub } from 'src/core/modules/organisation-reporting/hooks/useHasAccessToMultiEntityHub';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  type CompanyWalletSummary,
  isCreatingCompany,
  isInReviewCompany,
} from '../../../companyWalletSummary';
import { hasDetailedWalletSummary } from '../../../wallet/walletSummary';
import { CompanyDropdownLimitedAccess } from '../CompanyDropdownLimitedAccess';
import { CompanyDropdownWalletDetails } from '../CompanyDropdownWalletDetails';

import './CompanyDropdownContent.css';

type Props = {
  currentCompany: CompanyWalletSummary;
  otherCompanies: CompanyWalletSummary[];
  onCompanyClick(company: CompanyWalletSummary): void;
  onNewCompanyClick?(): void;
  closeDropdown(): void;
};

export const CompanyDropdownContent = ({
  currentCompany,
  otherCompanies,
  onCompanyClick,
  onNewCompanyClick,
  closeDropdown,
}: Props) => {
  const { t } = useTranslation('global');
  const hasAccessToMultiEntityHub = useHasAccessToMultiEntityHub();
  const isMultiEntityHubPage = useIsMultiEntityHubPage();

  const multiEntityHubLink =
    !isMultiEntityHubPage && hasAccessToMultiEntityHub ? (
      <Button
        fit="parent"
        component="a"
        href={routeFor(routes.ORGANISATION_REPORTING.path, {
          company: currentCompany.id,
        })}
        onClick={() => {
          closeDropdown();
          track(
            AnalyticEventName.ORGANISATION_REPORTING_COMPANY_DROPDOWN_LINK_CLIKED,
          );
        }}
        variant="primary"
        iconName="dashboard"
        iconPosition="left"
        text={t('organisation.reporting.links.button')}
      />
    ) : null;

  const { isAllowedToOpenEntity } = useGetOrganisationEntities();
  const openNewEntityButton = onNewCompanyClick && {
    label: t('wallet.walletDetails.addAccount'),
    onClick: () => onNewCompanyClick(),
  };

  if (hasAccessToMultiEntityHub) {
    const companies = otherCompanies.concat(
      isMultiEntityHubPage ? [currentCompany] : [],
    );

    return (
      <CompanyDropdownContentWithSearch
        companies={companies}
        multiEntityHubLink={multiEntityHubLink}
        openNewEntityButton={openNewEntityButton}
        onCompanyClick={onCompanyClick}
        closeDropdown={closeDropdown}
      />
    );
  }

  const activeItem = {
    left: currentCompany.name,
    right:
      !isCreatingCompany(currentCompany) && currentCompany.totalAmount
        ? formatMonetaryValue(currentCompany.totalAmount)
        : undefined,
    details: getActiveItemDetails(currentCompany, closeDropdown),
  };

  // TOREMOVE: This component will be removed when vertical navigation is GA
  return (
    <DropdownSwitcherContent
      activeItem={activeItem}
      items={getDropdownItems(otherCompanies, onCompanyClick, t)}
      button={isAllowedToOpenEntity ? openNewEntityButton : undefined}
    >
      {multiEntityHubLink}
    </DropdownSwitcherContent>
  );
};

const getDropdownItems = (
  companies: CompanyWalletSummary[],
  onCompanyClick: (company: CompanyWalletSummary) => void,
  t: TGlobalFunctionTyped,
) => {
  return sortByNameAsc(companies).map((company) => {
    let right;
    let onClick: (() => void) | undefined = () => onCompanyClick(company);

    if (isCreatingCompany(company)) {
      right = (
        <Tag variant="primary" className="CompanyDropdownContent__tag">
          {t('wallet.warningStepsRemaining')}
        </Tag>
      );
    } else if (
      isInReviewCompany(company) &&
      company.bankingProvider === 'sfs'
    ) {
      right = (
        <Tag variant="primary" className="CompanyDropdownContent__tag">
          {t('wallet.companyInReview')}
        </Tag>
      );
      onClick = undefined;
    } else {
      right = company.totalAmount && formatMonetaryValue(company.totalAmount);
    }
    return {
      id: company.id,
      left: company.name,
      right,
      onClick,
    };
  });
};

type CompanyDropdownContentWithSearchProps = {
  companies: CompanyWalletSummary[];
  multiEntityHubLink: React.ReactNode | null;
  onCompanyClick(company: CompanyWalletSummary): void;
  openNewEntityButton: { label: string; onClick: () => void } | undefined;
  closeDropdown(): void;
};

const CompanyDropdownContentWithSearch = ({
  companies,
  multiEntityHubLink,
  onCompanyClick,
  openNewEntityButton,
  closeDropdown,
}: CompanyDropdownContentWithSearchProps) => {
  const { t } = useTranslation('global');

  return (
    <div className="flex w-[338px] flex-col content-stretch">
      <div className="m-s mb-xs flex flex-col gap-xs">{multiEntityHubLink}</div>
      <OrganisationReportingEntityListSearch
        entities={companies}
        searchableFields={['name']}
        sortFn={(a, b) => a.name.localeCompare(b.name)}
      >
        {({ filteredEntities, search, setSearch }) => (
          <div className="mb-xs flex flex-col">
            <Input
              className="mx-s my-xs min-w-[306px]"
              leftAddon={
                <Icon
                  color={colors.neutral}
                  name="search"
                  className="ml-s mr-xs"
                />
              }
              placeholder={t(
                'organisation.reporting.page.entities.searchPlaceholder',
              )}
              onChange={(event) => setSearch(event.target.value)}
              value={search}
            />
            <div className="flex max-h-[60vh] flex-col content-stretch overflow-auto">
              {filteredEntities.map((entity) => (
                <button
                  key={entity.id}
                  type="button"
                  className="border-none bg-white outline-none"
                  onClick={() => {
                    onCompanyClick(entity);
                    closeDropdown();
                  }}
                >
                  <DropdownItem
                    label={entity.name}
                    className="p-s text-complementary title-m"
                  />
                </button>
              ))}
            </div>
          </div>
        )}
      </OrganisationReportingEntityListSearch>
      {openNewEntityButton && (
        <div
          className="flex flex-col content-stretch gap-s border-neutral-light"
          style={{
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        >
          <button
            type="button"
            className="flex w-full justify-center border-none bg-neutral-lightest outline-none"
            onClick={openNewEntityButton.onClick}
          >
            <DropdownItem
              label={openNewEntityButton.label}
              className="p-s text-complementary title-m"
            />
          </button>
        </div>
      )}
    </div>
  );
};

const getActiveItemDetails = (
  company: CompanyWalletSummary,
  closeDropdown: () => void,
) => {
  if (isInReviewCompany(company)) {
    return <CompanyDropdownLimitedAccess />;
  }

  if (!hasDetailedWalletSummary(company)) {
    return null;
  }

  return (
    <CompanyDropdownWalletDetails
      company={company}
      onRedirectToWalletSettings={closeDropdown}
    />
  );
};
