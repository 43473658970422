import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { thunks as teamThunks } from 'modules/company/structure/teams';
import { fetchUsers } from 'src/core/actions/users';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { type AppDispatch } from 'src/core/modules/app/redux/store';
import { TeamsContainer } from 'src/core/modules/company/members';

export const SettingsOrganisationTeamsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const hasTemporaryMembersTableRowQuery = useFeature(
    FEATURES.TMP_MEMBER_TABLE_ROWS_QUERY,
  );

  useEffect(() => {
    dispatch(teamThunks.fetchAll());
    if (!hasTemporaryMembersTableRowQuery) {
      dispatch(fetchUsers(true));
    }
  }, []);
  return <TeamsContainer />;
};
