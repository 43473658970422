import { useCreateCustomControlRuleMutation } from '../../../hooks/useCreateCustomControlRuleMutation';
import { useUnassignControlRuleMutation } from '../../../hooks/useUnassignControlRuleMutation';
import { useUpdateControlRuleMutation } from '../../../hooks/useUpdateControlRuleMutation';
import { type ControlRule } from '../../../models/controlRule';

export const useEditControlRuleMutation = () => {
  const [createCustomControlRuleMutation] =
    useCreateCustomControlRuleMutation();
  const [updateControlRule] = useUpdateControlRuleMutation();
  const [unassignControlRule] = useUnassignControlRuleMutation();
  return [
    async (
      newControlRule: ControlRule | undefined,
      oldControlRule: ControlRule | undefined,
      memberId: string,
    ) => {
      if (!newControlRule && oldControlRule) {
        // 'No control rule' option was selected
        await unassignControlRule({
          userIds: [memberId],
        });
        return;
      }

      if (
        newControlRule &&
        oldControlRule?.id !== newControlRule.id &&
        !newControlRule.isCustom
      ) {
        // Switch the control rule to the new one
        await updateControlRule({
          userId: memberId,
          endpointParams: {
            controlRuleId: newControlRule.id,
          },
        });
        return;
      }

      if (
        newControlRule?.isCustom &&
        (newControlRule.completionDeadline !==
          oldControlRule?.completionDeadline ||
          newControlRule.incompletePaymentsLimit !==
            oldControlRule?.incompletePaymentsLimit ||
          newControlRule.id !== oldControlRule.id)
      ) {
        // An edition of a custom rule in the interface is a creation in the API
        await createCustomControlRuleMutation({
          completionDeadline: newControlRule.completionDeadline,
          incompletePaymentsLimit: newControlRule.incompletePaymentsLimit,
          userId: memberId,
        });
      }
    },
  ];
};
