/* eslint-disable @typescript-eslint/naming-convention */
import { HighlightIcon, Tag, Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';
import { formatMonetaryValue } from "src/core/utils/monetaryValue";

import {
  type BreakdownPart,
  getBudgetLimit,
  getSpentAmount,
} from '../../models/breakdown';
import {
  type ExpenseCategoryBreakdown,
  getExpenseCategoryName,
  getExpenseCategorySpentPercentage,
  isExpenseCategoryNotInBudget,
} from '../../models/expenseCategoryBreakdown';
import { BudgetGauge } from '../BudgetGauge';

type Props = {
  breakdown: ExpenseCategoryBreakdown;
  isExpenseCategoryArchived: boolean;
  onGaugeSegmentClick: (
    breakdownPart: 'committed' | 'used',
    breakdown: ExpenseCategoryBreakdown,
  ) => void;
};

export const BudgetExpenseCategoriesBreakdownRow = ({
  breakdown,
  isExpenseCategoryArchived,
  onGaugeSegmentClick,
}: Props) => {
  const { t } = useTranslation('global');

  const { expenseCategory } = breakdown;

  return (
    <div
      className="flex h-[56px] items-center justify-between gap-s"
      key={expenseCategory.id}
    >
      <div className="w-[25%] max-w-[231px] text-neutral-dark body-m">
        <EllipsisTooltip
          text={getExpenseCategoryName(expenseCategory, t)}
          placement="top"
        />
      </div>
      <div
        className={cx('w-[30%] body-m', {
          'text-neutral-dark': isExpenseCategoryArchived,
        })}
      >
        {getExpenseCategoryAmountCell(breakdown)}
      </div>
      <div className="w-[35%]">
        {renderGaugeColumn(
          breakdown,
          isExpenseCategoryArchived,
          t,
          onGaugeSegmentClick,
        )}
      </div>
      <div
        className={cx('w-[10%] whitespace-nowrap body-m', {
          'text-neutral-dark': isExpenseCategoryArchived,
        })}
      >
        {!isExpenseCategoryArchived &&
          getExpenseCategoryPercentageCell(breakdown)}
      </div>
    </div>
  );
};

const renderGaugeColumn = (
  breakdown: ExpenseCategoryBreakdown,
  isExpenseCategoryArchived: boolean,
  t: TGlobalFunctionTyped,
  onGaugeSegmentClick: (
    selectedBreakdownPart: BreakdownPart,
    selectedBreakdown: ExpenseCategoryBreakdown,
  ) => void,
) => {
  const { expenseCategory } = breakdown;

  if (isExpenseCategoryArchived) {
    return (
      <Tooltip
        content={t('budget.overviewPage.unavailableTooltip')}
        triggerAsChild
      >
        <Tag iconName="info" variant="neutral">
          {t('budget.overviewPage.unavailable')}
        </Tag>
      </Tooltip>
    );
  }

  return (
    <BudgetGauge
      budgetBreakdown={breakdown}
      gaugeConfiguration={{
        tooltip: { mode: 'normal' },
        enforceWarningState: expenseCategory.isOther,
      }}
      onGaugeSegmentClick={(breakdownPart) =>
        onGaugeSegmentClick(breakdownPart, breakdown)
      }
    />
  );
};

const getExpenseCategoryPercentageCell = (
  breakdown: ExpenseCategoryBreakdown,
): React.ReactChild | string | null => {
  if (
    isExpenseCategoryNotInBudget(breakdown) ||
    breakdown.expenseCategory.isOther
  ) {
    return null;
  }

  const percentage = getExpenseCategorySpentPercentage(breakdown);
  if (percentage > 100) {
    return (
      <>
        <span className="text-warning title-m">{percentage}%</span>
        <HighlightIcon
          className="ml-xs"
          name="warning"
          size="s"
          variant="warning"
        />
      </>
    );
  }
  return `${percentage}%`;
};

const getExpenseCategoryAmountCell = (
  breakdown: ExpenseCategoryBreakdown,
): React.ReactChild => {
  const amount = breakdown.expenseCategory.isOther
    ? { ...breakdown.available, amount: 0 }
    : getBudgetLimit(breakdown);

  return (
    <span>
      {formatMonetaryValue(getSpentAmount(breakdown))} /{' '}
      <span className="text-neutral-dark">{formatMonetaryValue(amount)}</span>
    </span>
  );
};
