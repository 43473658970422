import {
  Autocomplete,
  Callout,
  FormField,
  PanelSection,
} from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { AutocompleteNoOptions } from 'src/core/common/components/AutocompleteNoOptions';
import { useDebouncedState } from 'src/core/common/hooks/useDebounceState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import {
  getAccountPayableSupplierManualMappingError,
  getAccountPayableSupplierMapVendorMessage,
  getAccountPayableSuppliersLeftLabel,
  getAccountPayableSuppliersMapVendorFormLabel,
} from './translations';
import { useIntegrationStatusQuery } from '../../../hooks';
import { getIntegrationName } from '../../../integration/name';
import { type AccountingSoftware } from '../../../integration/status';
import { type Vendor } from '../../../settings/accounting/vendor';
import { useAssignVendorToSupplierMutation } from '../../../settings/integrations/hooks/useAssignVendorToSupplierMutation';
import { useDeleteVendorSupplierMapMutation } from '../../../settings/integrations/hooks/useDeleteVendorSupplierMapMutation';
import { useGetVendorsQuery } from '../../../settings/integrations/hooks/useGetVendorsQuery';
import { type AutocompleteOption } from '../../../settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/AuthSection/DefaultVendorsSection';
import { type SupplierDetails } from '../../types';

type Props = {
  supplier: SupplierDetails;
  integrationName: AccountingSoftware;
};

export const AccountPayableSuppliersPanelVendorSelect = ({
  supplier,
  integrationName,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const [assignVendorToSupplier] = useAssignVendorToSupplierMutation();
  const [deleteVendorSupplierMap] = useDeleteVendorSupplierMapMutation();

  const accountingIntegrationStatusQueryResult =
    useIntegrationStatusQuery(false);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [search, setSearch] = useDebouncedState<string>('', 300);

  const vendorsQueryState = useGetVendorsQuery(search, undefined, 'synced');

  const currentlyMappedVendorQuery = useGetVendorsQuery(
    undefined,
    undefined,
    'synced',
    [supplier.id],
  );

  const blankOption = {
    key: '-',
    label: '-',
  };

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [initialOption, setInitialOption] =
    useState<AutocompleteOption>(blankOption);

  const [selectedOption, setSelectedOption] =
    useState<AutocompleteOption>(blankOption);

  const [options, setOptions] = useState<AutocompleteOption[]>([blankOption]);

  const isSelectedOptionChanged = (newOption: AutocompleteOption) => {
    return (
      selectedOption?.key !== newOption.key ||
      selectedOption?.label !== newOption.label
    );
  };

  useEffect(() => {
    if (
      currentlyMappedVendorQuery.status !== 'success' ||
      !currentlyMappedVendorQuery.data?.length
    ) {
      if (isSelectedOptionChanged(blankOption)) {
        setSelectedOption(blankOption);
        setInitialOption(blankOption);
      }
      setIsDisabled(false);
      return;
    }

    const vendor = currentlyMappedVendorQuery.data[0];
    const newOption = { key: vendor.id, label: vendor.name };
    if (isSelectedOptionChanged(newOption)) {
      setSelectedOption(newOption);
      setInitialOption(newOption);
    }
    // If only one supplierId synced, do not allow to change
    setIsDisabled(vendor.supplierIds?.length === 1);
  }, [currentlyMappedVendorQuery]);

  useEffect(() => {
    const newOptions = search ? [] : [blankOption];
    if (vendorsQueryState.status === 'success') {
      newOptions.push(
        ...vendorsQueryState.data.map((vendor: Vendor) => {
          return {
            key: vendor.id,
            label: vendor.name,
          };
        }),
      );
    }
    setOptions(newOptions);
  }, [vendorsQueryState]);

  const onSave = async () => {
    if (!selectedOption || selectedOption === initialOption) {
      return;
    }

    if (isDisabled) {
      pushNotif({
        type: NotificationType.Danger,
        message: getAccountPayableSupplierMapVendorMessage(integrationName, t)
          .failure,
      });
      // Throwing an error will cause the panel section to render it
      throw new Error();
    }

    try {
      if (selectedOption.key !== blankOption.key) {
        await assignVendorToSupplier({
          vendorId: selectedOption.key,
          supplierId: supplier.id,
        });
      } else {
        await deleteVendorSupplierMap({ supplierId: supplier.id });
      }

      pushNotif({
        type: NotificationType.Success,
        message: getAccountPayableSupplierMapVendorMessage(integrationName, t)
          .success,
      });
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: getAccountPayableSupplierMapVendorMessage(integrationName, t)
          .failure,
      });
    }
  };

  return (
    <div className="mt-l">
      <PanelSection
        title={
          accountingIntegrationStatusQueryResult.status === 'success' &&
          getIntegrationName(
            t,
            accountingIntegrationStatusQueryResult.data.integration,
          )
        }
        cancelTranslation={t('misc.cancel')}
        saveTranslation={t('misc.saveChanges')}
        onCancel={() => {
          setSelectedOption(initialOption);
        }}
        renderError={() => {
          return (
            <Callout
              variant="alert"
              title={
                getAccountPayableSupplierManualMappingError(integrationName, t)
                  .title
              }
            >
              <div>
                {
                  getAccountPayableSupplierManualMappingError(
                    integrationName,
                    t,
                  ).text
                }
              </div>
            </Callout>
          );
        }}
        onSave={onSave}
        isEditable
        editSection={
          <div>
            <FormField
              className="my-s"
              label={getAccountPayableSuppliersMapVendorFormLabel(
                integrationName,
                t,
              )}
            >
              <Autocomplete
                fit="parent"
                isLoading={
                  vendorsQueryState.status === 'loading' ||
                  currentlyMappedVendorQuery.status === 'loading'
                }
                value={isFocused ? undefined : selectedOption}
                options={options}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onSearch={(keyword) => setSearch(keyword ?? '')}
                onSelect={(vendor) => {
                  if (vendor && isSelectedOptionChanged(vendor)) {
                    setSelectedOption(vendor);
                  }
                }}
                renderNoOptions={(rawValue) => (
                  <AutocompleteNoOptions value={rawValue} />
                )}
              />
            </FormField>
          </div>
        }
      >
        <div className="-ml-xs -mr-xs mb-s flex items-center justify-between px-xs body-m last:mb-0">
          <span className="text-neutral-dark">
            {getAccountPayableSuppliersLeftLabel(integrationName, t)}
          </span>
          <span className="max-w-[55%] overflow-hidden text-ellipsis whitespace-nowrap text-complementary">
            {selectedOption && selectedOption.label}
          </span>
        </div>
      </PanelSection>
    </div>
  );
};
