import { useSelector } from 'react-redux';

import type { CurrenciesKey } from 'src/core/config/money';
import { type BankingProvider } from 'src/core/reducers/global';
import { getCompany } from 'src/core/selectors/globalSelectors';

import { type PaymentMethodsByIssuer } from '../../company';

export type Company = {
  id: string;
  name: string;
  city: string;
  address: string;
  address2: string;
  zipcode: string;
  country: string;
  accounting_setting: {
    are_vat_accounts_enabled: boolean;
  };
  vat: string;
  currency: CurrenciesKey;
  is_kyb_validated: boolean;
  isWireTransferFeatureActivated: boolean;
  kyb_version: number;
  has_access_happened: boolean;
  has_public_banking_address: boolean;
  spending_total: number;
  suggest_to_reload_amount: number;
  balance_loaded_cards: number;
  balance_pending_cards: number;
  balance_loaded_reloadable_cards: number;
  balance_available_all_accounts: number;
  mileage_scheme: {
    id: string;
    distanceUnit: 'km' | 'mi';
    fixedRates: { vehicleType: 'bike' | 'car' | 'motorcycle'; rate: number }[];
    schemeType: 'FR' | 'DE' | 'UK' | 'FX';
    currency: string;
  } | null;
  created_from: string;
  created_at: string;
  churned_at: string | null;
  churning_at: string | null;
  validated_at: string | null;
  organisation_id: string;
  rates: Record<CurrenciesKey, number>;
  organisation: {
    id: string;
  };
  banking_provider: BankingProvider;
  first_load_at: string | null;
  paymentMethods: PaymentMethodsByIssuer;
} & (
  | {
      type: 'initial';
      created_from: null;
    }
  | {
      type: 'branch_entity' | 'branch_currency' | 'branch_expense_entity';
      created_from: string;
    }
);

export const useCompany = (): Company => useSelector(getCompany) as Company;
