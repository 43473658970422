import { Link } from '@dev-spendesk/grapes';
import type * as Money from 'ezmoney';
import { t } from 'i18next';
import React from 'react';
// assets
import './FundsInEscrowNotification.css';

import { useAccountsQuery } from 'modules/app/hooks/useAccountQuery';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { type Account } from 'modules/company/wallet/wallet';
import { useFetchCompanyLastBalanceQuery } from 'src/core/modules/company/wallet/hooks/useFetchCompanyLastBalanceQuery';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

function lookupArticleForAccount(activeAccount: Account) {
  /* eslint-disable @typescript-eslint/naming-convention */
  const helpLinks: Record<string, Record<string, number>> = {
    v1: {
      NATWEST: 9_690_913,
    },
    v2: {
      'Transact Payments Limited': 9_691_025,
      SFPMEI: 9_691_032,
    },
  };
  /* eslint-enable @typescript-eslint/naming-convention */
  if (
    activeAccount.version in helpLinks &&
    activeAccount.bank_name in helpLinks[activeAccount.version]
  ) {
    return helpLinks[activeAccount.version][activeAccount.bank_name];
  }
  return 9_690_913;
}

export const FundsInEscrowNotification = () => {
  const company = useCompany();
  const user = useUser();

  const showBanner =
    company.banking_provider === 'bankable' &&
    company.churning_at !== null &&
    user.is_account_owner;

  const fetchCompanyLastBalanceQueryState = useFetchCompanyLastBalanceQuery(
    company.id,
    showBanner,
  );

  const accountQuery = useAccountsQuery(showBanner);

  if (fetchCompanyLastBalanceQueryState.status !== 'success') {
    return null;
  }

  const lastBalance = fetchCompanyLastBalanceQueryState.data;

  const totalAvailableForRequest = lastBalance
    ? formatMonetaryValue(lastBalance, {
        signDisplay: 'never' as Money.SignDisplay,
      })
    : '-';

  if (showBanner && accountQuery.status === 'success') {
    const activeAccount = accountQuery.data;
    const articleID = lookupArticleForAccount(activeAccount);

    const linkUrl = `https://helpcenter.spendesk.com/articles/${articleID}`;
    if (activeAccount.banking_provider === 'bankable') {
      return (
        <section className="FundsInEscrowNotification">
          <p>
            {t('homepage.escrowInfo.description', {
              balance: totalAvailableForRequest,
            })}
            <span>
              {t('homepage.escrowInfo.preLink')}
              &nbsp;
              <Link href={linkUrl} target="_blank">
                {t('homepage.escrowInfo.link')}
              </Link>
              &nbsp;
              {t('homepage.escrowInfo.postLink')}
            </span>
          </p>
        </section>
      );
    }
  }

  return null;
};
