import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

export const useValidateExportMutation = (): MutationState => {
  return useMutation({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: '/accounting-integration/export-validation/validate',
    },
    options: {
      throwOnError: false,
      onSuccess({ client }): void {
        client.invalidateQueries(['useGetExportValidationStatusQuery']);
      },
    },
  });
};
