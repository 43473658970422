import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { GET_REVERSE_CHARGE_TAX_ACCOUNT_RATES } from './query';
import {
  reshapeReverseChargeTaxAccountRate,
  type ReverseChargeTaxAccountRateApi,
  type ReverseChargeTaxAccountRate,
} from './reshaper';

type RawData = {
  company: {
    chartOfAccounts: {
      reverseChargeTaxAccountRates: ReverseChargeTaxAccountRateApi[];
    };
  };
};

export const useReverseChargeTaxAccountRatesQuery = (
  { isEnabled } = { isEnabled: true },
): QueryState<ReverseChargeTaxAccountRate[]> => {
  const { company: companyId } = useParams();

  return useQuery<ReverseChargeTaxAccountRate[], RawData>({
    key: ['useReverseChargeTaxAccountRatesQuery', companyId],
    isEnabled,
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_REVERSE_CHARGE_TAX_ACCOUNT_RATES,
    },
    reshapeData: (rawData) => {
      return rawData.company.chartOfAccounts.reverseChargeTaxAccountRates.map(
        (reverseChargeTaxAccountRate) => {
          return reshapeReverseChargeTaxAccountRate(
            reverseChargeTaxAccountRate,
          );
        },
      );
    },
  });
};
