import { Button, DeprecatedPreview, Modal } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { PaymentErrorTag } from './PaymentErrorTag';
import styles from './WireTransferConfirmationErrorModal.module.css';

export type ConfirmationError = {
  id: string;
  amount: MonetaryValue;
  description: string;
  error: string;
};

type WireTransferConfirmationErrorModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onRetry?: () => void;
  payments: ConfirmationError[];
};

export const WireTransferConfirmationErrorModal = ({
  isOpen,
  onCancel,
  onRetry,
  payments,
}: WireTransferConfirmationErrorModalProps) => {
  const { t } = useTranslation('global');

  const actions = [
    <Button
      key="cancel"
      text={t('wiretransfer.confirmation.errorModal.cancelButton')}
      variant="secondary"
      onClick={onCancel}
    />,
  ];

  if (onRetry) {
    actions.push(
      <Button
        key="retry"
        text={t('wiretransfer.confirmation.errorModal.retryButton')}
        variant="primary"
        onClick={onRetry}
      />,
    );
  }

  return (
    <Modal
      actions={actions}
      iconName="failure"
      iconVariant="alert"
      title={t('wiretransfer.confirmation.errorModal.title')}
      subtitle={t('wiretransfer.confirmation.errorModal.subtitle')}
      isOpen={isOpen}
    >
      <div className="mb-xs text-left">
        <span className="font-medium text-neutral-darker">
          {t('wiretransfer.confirmation.errorModal.listTitle')}
        </span>
      </div>

      <div className={classnames(styles.payments, 'overflow-auto')}>
        {payments.map((payment) => (
          <DeprecatedPreview
            className="mb-xs bg-neutral-lightest"
            key={payment.id}
            fit="parent"
            primaryText={formatMonetaryValue(payment.amount)}
            secondaryText={payment.description}
            rightAddon={<PaymentErrorTag error={payment.error} />}
          />
        ))}
      </div>
    </Modal>
  );
};
