import {
  Callout,
  DATE_FORMAT,
  InfoTip,
  Upload,
  FileCard,
} from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  file?: string | File | null;
  fileMediaType?: string;
  onDelete: () => void;
  onUpload: (files: File[]) => void;
};
const BankProofUpload = ({
  file,
  fileMediaType,
  onDelete,
  onUpload,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');

  const hasNoFile =
    file === null ||
    file === undefined ||
    (typeof file === 'string' && file.trim().length === 0);
  return (
    <Callout
      title={
        <span className="align-middle">
          {t('requests.panel.addIbanToSupplier')}{' '}
          <InfoTip
            content={t('requests.panel.addIbanToSupplierInfo')}
            className="ml-xxs translate-y-[1px] text-neutral-dark"
          />
        </span>
      }
      iconName="bank"
      variant="primary"
      className="mt-xs"
    >
      {hasNoFile ? (
        <Upload
          activeDragContent="Upload file(s)"
          content={
            <Trans i18nKey="forms.uploadFiles.content">
              <br />-<span className="Upload__link">-</span>
            </Trans>
          }
          onUpload={onUpload}
        />
      ) : (
        <FileCard
          title={t('requests.panel.uploadedOn', {
            date: localeFormat(new Date(), DATE_FORMAT.SHORT),
          })}
          mimeType={fileMediaType}
          onDelete={onDelete}
        />
      )}
    </Callout>
  );
};

export default BankProofUpload;
