import {
  SkeletonTable,
  SkeletonText,
  SkeletonAvatar,
  SkeletonTag,
} from '@dev-spendesk/grapes';
import React from 'react';

import './PurchaseOrdersTableSkeleton.css';

type Props = {
  className?: string;
  title: string;
};

export const PurchaseOrdersTableSkeleton = ({ className, title }: Props) => (
  <SkeletonTable
    className={className}
    columns={[
      {
        cell: (
          <div className="PurchaseOrdersTableSkeleton__row">
            <SkeletonAvatar
              variant="square"
              className="PurchaseOrdersTableSkeleton__row__avatar"
            />
            <div className="PurchaseOrdersTableSkeleton__row__content">
              <div className="PurchaseOrdersTableSkeleton__row__line">
                <SkeletonText size="m" width="30%" />
                <SkeletonText size="m" width="15%" />
              </div>
              <div className="PurchaseOrdersTableSkeleton__row__line">
                <SkeletonText size="s" width="50%" />
                <SkeletonText size="s" width="8%" />
              </div>
              <SkeletonTag className="PurchaseOrdersTableSkeleton__row__tag" />
            </div>
          </div>
        ),
        header: title,
      },
    ]}
    withHeader
  />
);
