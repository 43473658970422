import axios, { type AxiosError } from 'axios';
import { print as printGqlQuery } from 'graphql';

import appConfig from 'src/core/config';

import { getAxiosInstance } from './axios';
import { fromAxiosError, fromFetchError } from './queryError';
import {
  type GraphQlRequest,
  type Request as RequestType,
  type RestRequest,
} from './request';
import { rejectUnexpectedValue } from '../utils/switchGuard';

const fetchWrapper = async (
  url: RequestInfo,
  options?: RequestInit,
): Promise<Response> => {
  const request = new Request(url, options);
  try {
    const response = await fetch(request);
    if (response.ok) {
      return response;
    }
    throw await fromFetchError(request, response);
  } catch (e) {
    throw await fromFetchError(request, e);
  }
};

const getRestFetcher = async <TResult>({
  companyId,
  request,
}: {
  companyId: string;
  request: RestRequest;
}): Promise<TResult> => {
  const source = axios.CancelToken.source();
  const options = {
    companyId,
    method: request.method,
    url:
      typeof request.endpoint === 'function'
        ? request.endpoint(request.endpointParams)
        : request.endpoint,
    params: request.params,
    data: request.data,
    cancelToken: source.token,
    credentials: 'include',
    ...(request.responseType ? { responseType: request.responseType } : {}),
  };
  const axiosInstance = getAxiosInstance(request.target);
  const promise = axiosInstance.request(options);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (promise as any).cancel = source.cancel;
  return (
    promise
      // eslint-disable-next-line promise/prefer-await-to-then
      .then((response) => response.data)
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch(async (axiosError: AxiosError) => {
        throw await fromAxiosError(axiosError);
      })
  );
};

const getGraphQlFetcher = async <TResult>({
  companyId,
  request,
}: {
  companyId: string;
  request: GraphQlRequest;
}): Promise<TResult> => {
  const url =
    request.target === 'v1'
      ? appConfig.apiUrls.graphqlV1
      : appConfig.apiUrls.graphqlV2;
  const abortController = new AbortController();
  const options = {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as const,
    body: JSON.stringify({
      query: printGqlQuery(request.query),
      variables: {
        companyId,
        ...request.variables,
      },
    }),
    signal: abortController.signal,
  };
  const promise = fetchWrapper(url, options);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (promise as any).cancel = abortController.abort;
  return (
    promise
      // eslint-disable-next-line promise/prefer-await-to-then
      .then((response) => response.json())
      // eslint-disable-next-line promise/prefer-await-to-then
      .then((response) => response.data)
  );
};

const getFetcherFromRequestType = async <TResult>({
  companyId,
  request,
}: {
  companyId: string;
  request: RequestType;
}): Promise<TResult> => {
  switch (request.type) {
    case 'rest':
      return getRestFetcher({ companyId, request });
    case 'graphQL':
      return getGraphQlFetcher({ companyId, request });
    default:
      return rejectUnexpectedValue('request', request);
  }
};

export const getFetcher =
  <TResult>({
    companyId,
    getRequest,
  }: {
    companyId: string;
    getRequest(): RequestType;
  }) =>
  async (): Promise<TResult> => {
    const request = getRequest();

    return getFetcherFromRequestType({ companyId, request });
  };

export const getMutationFetcher =
  <TResult, TVariables extends object & { endpointParams?: object }>({
    companyId,
    request,
  }: {
    companyId: string;
    request: RequestType<TVariables>;
  }) =>
  (variables: TVariables): Promise<TResult> => {
    const enrichedRequest = {
      ...(request.type === 'rest' && variables ? { data: variables } : {}),
      ...(request.type === 'graphQL' && variables ? { variables } : {}),
      ...request,
    };
    if (
      enrichedRequest.type === 'rest' &&
      enrichedRequest.data &&
      'endpointParams' in enrichedRequest.data
    ) {
      enrichedRequest.endpointParams =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (enrichedRequest.data as any).endpointParams;
      // Delete to make sure the endpoint parameters do not propagate into the payload packet
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (enrichedRequest.data as any).endpointParams;
    }

    return getFetcherFromRequestType({ companyId, request: enrichedRequest });
  };
