import { useCompany } from 'modules/app/hooks/useCompany';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type CompanySubscription } from '../../../../models';

type RawCompanySubscription = CompanySubscription | undefined;

export const useGetCompanySubscriptionQuery = (): QueryState<
  CompanySubscription | undefined
> => {
  const company = useCompany();

  return useQuery<CompanySubscription | undefined, RawCompanySubscription>({
    key: ['companyPlan', company.id],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/company-subscription',
    },
    options: {
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 60,
    },
    reshapeData: (rawCompanySubscription) => rawCompanySubscription,
    // For SFS users, we check the procedure state beforehand
    // For Bankable users, the query is always run
    isEnabled:
      company.is_kyb_validated || company.banking_provider === 'bankable',
  });
};
