import { FormField, Input } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type BankInfosErrors, type SupplierBankInfos } from '../../../models';
import { FormFieldHint } from '../FormFieldHint';

type Props = {
  className?: string;
  isOptional?: boolean;
  bic: SupplierBankInfos['bic'];
  bicSuggestion?: SupplierBankInfos['bic'];
  error?: BankInfosErrors['bic'];
  onChange(bic: SupplierBankInfos['bic']): void;
};

export const BicSwiftFormField = ({
  className,
  isOptional,
  bic,
  bicSuggestion,
  error,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const isValueFromSuggestion = Boolean(bicSuggestion) && bic === bicSuggestion;

  const getMessageFromError = (rawError: BankInfosErrors['bic']): string => {
    switch (rawError) {
      case 'invalid': {
        return t('submitMyInvoice.fields.bic.error');
      }
      default:
        return t('misc.requiredField');
    }
  };

  return (
    <FormField
      className={className}
      label={t('submitMyInvoice.fields.bic_swift.label')}
      alertMessage={error ? getMessageFromError(error) : undefined}
      hint={
        <FormFieldHint
          optionalField={isOptional}
          showOCR={isValueFromSuggestion}
        />
      }
    >
      <Input
        name="bic"
        value={bic ?? ''}
        placeholder={t('submitMyInvoice.fields.bic_swift.placeholder')}
        maxLength={11}
        onChange={(event) => {
          if (event.target.checkValidity()) {
            onChange(event.target.value);
          }
        }}
      />
    </FormField>
  );
};
