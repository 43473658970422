import { useDispatch } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUser } from 'modules/app/hooks/useUser';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { updateMeLocally } from 'src/core/actions/users';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  endpointParams: {
    delegateId: string;
  };
  delegateId: string;
};

export const useDelegateApprovalRightsMutation = (): MutationState<Payload> => {
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const user = useUser();
  const { pushNotif } = useNotifications();
  const { t } = useTranslation('global');

  return useMutation<Payload>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: ({ delegateId }) => `/delegation?delegateId=${delegateId}`,
    },
    options: {
      onSuccess: ({ payload }) => {
        const delegateInfo = {
          is_delegating: true,
          user_delegate: payload.delegateId,
        };

        const dataByCompany = user.data_by_company;

        dispatch(
          // @ts-expect-error: Not an helpful comment
          updateMeLocally({
            data_by_company: {
              ...dataByCompany,
              [companyId]: {
                ...dataByCompany[companyId],
                ...delegateInfo,
              },
            },
            ...delegateInfo,
          }),
        );
      },
      onError: () => {
        pushNotif({
          type: NotificationType.Danger,
          message: t('profile.delegateApprovalRightsErrorMessage'),
        });
      },
      throwOnError: true,
    },
  });
};
