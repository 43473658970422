import { Banner, Tag } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './ReportingManagerMarketingBanner.module.css';
import { ReactComponent as BannerIllustration } from './bannerIllustration.svg';

type Props = {
  variant: 'modal' | 'page';
  title: string;
  description: string;
  className?: string;
};

export const ReportingManagerMarketingBanner = ({
  variant,
  title,
  description,
  className,
}: Props) => {
  const { t } = useTranslation('global');
  return (
    <div>
      <Banner
        illustration={<BannerIllustration className="shrink-0" />}
        variant="info"
        title=""
        actionText={
          variant === 'page' ? t('costCenters.banner.actionText') : ''
        }
        onClick={() => {
          if (variant === 'page') {
            window.open(
              'https://helpcenter.spendesk.com/articles/9910293-approval-workflows',
              '_blank',
            );
          }
        }}
        className={classNames(
          'text-left',
          className,
          {
            [styles.hideButton]: variant === 'modal',
          },
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'gap-l': variant === 'modal',
          },
        )}
      >
        <div>
          <div className="-mt-xs mb-xs flex items-center gap-xs text-complementary title-xl">
            {title}
            <Tag variant="primary">{t('misc.new')}</Tag>
          </div>
          {description}
        </div>
      </Banner>
    </div>
  );
};
