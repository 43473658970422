import type { Form, SubmitUpdatedValues } from '../types';

/**
 * returns only the values that are different than the values given in initial values
 * @example
 *{
 *  id: {
 *    changed: true;
 *    value: "123";
 *  },
 *  name: {
 *    changed: false;
 *    value: undefined
 *  }
 *}
 */
export function getUpdatedValues<
  TForm extends Form<TData>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData extends Record<string, any>,
>({ form, data }: { form: TForm; data: TData }) {
  const changedValues: Partial<SubmitUpdatedValues<TData>> = {};

  for (const key in data) {
    changedValues[key] = {
      changed: data[key] !== form.initialValues[key],
      value: data[key],
    };
  }

  return changedValues as SubmitUpdatedValues<TData>;
}
