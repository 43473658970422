import { useParams } from 'react-router-dom';

import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  endpointParams: {
    tokenId: string;
  };
};

export const useRevokeOAuth2Token = () => {
  const { company: companyId } = useParams();
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: ({ tokenId }) =>
        `/${companyId}/public-api-configuration/oauth2/revoke/${tokenId}`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }) => {
        return client.invalidateQueries(['oauth2TokenList', companyId]);
      },
    },
  });
};
