import {
  type CustomFieldAssociation,
  buildCustomFieldAssociations,
} from './customFieldAssociation';

export type ExpenseCategoryId = string;

export type ExpenseCategory = {
  id: ExpenseCategoryId;
  name: string;
  isArchived?: boolean;
};

export const shouldDisplayExpenseCategoryField = ({
  isExpenseCategoriesFeatureEnabled,
  isCostCentersFeatureEnabled,
  costCenters,
  selectedCostCenter,
  eligibleTypes = [],
  requestType,
}: {
  isExpenseCategoriesFeatureEnabled: boolean;
  isCostCentersFeatureEnabled: boolean;
  costCenters: object[];
  selectedCostCenter: object | string | undefined;
  eligibleTypes?: { type: string }[];
  requestType?: string;
}): boolean => {
  if (
    requestType &&
    !eligibleTypes.map(({ type }) => type).includes(requestType)
  ) {
    return false;
  }
  if (isExpenseCategoriesFeatureEnabled) {
    if (costCenters.length > 0 && selectedCostCenter) {
      return true;
    }
    if (costCenters.length === 0 || !isCostCentersFeatureEnabled) {
      return true;
    }
  }

  return false;
};

export const addExpenseCategoryToCustomFieldAssociations = ({
  customFieldAssociations,
  expenseCategoryId,
  expenseCategoryCustomFieldId,
  isExpenseCategoriesFeatureEnabled,
  expenseCategoryValue = '',
}: {
  customFieldAssociations: CustomFieldAssociation[];
  expenseCategoryId: string | undefined;
  expenseCategoryCustomFieldId: string | undefined;
  isExpenseCategoriesFeatureEnabled: boolean;
  expenseCategoryValue?: string;
}): CustomFieldAssociation[] => {
  if (isExpenseCategoriesFeatureEnabled && expenseCategoryCustomFieldId) {
    return buildCustomFieldAssociations([
      ...customFieldAssociations,
      {
        customFieldId: expenseCategoryCustomFieldId,
        // expenseCategoryId can be optional
        customFieldValueId: expenseCategoryId ?? '',
        value: expenseCategoryValue,
      },
    ]);
  }

  return customFieldAssociations;
};

export const removeExpenseCategoryFromCustomFieldAssociations = ({
  customFieldAssociations,
  expenseCategoryCustomFieldId,
}: {
  customFieldAssociations: CustomFieldAssociation[];
  expenseCategoryCustomFieldId: string | undefined;
}): [CustomFieldAssociation[], CustomFieldAssociation | undefined] => {
  let expenseCategoryCustomFieldAssociation;

  return [
    customFieldAssociations.filter((customFieldAssociation) => {
      if (
        expenseCategoryCustomFieldId &&
        customFieldAssociation.customFieldId === expenseCategoryCustomFieldId
      ) {
        expenseCategoryCustomFieldAssociation = customFieldAssociation;
        return false;
      }
      return true;
    }),
    expenseCategoryCustomFieldAssociation,
  ];
};
