import { Accordion, type AccordionHandle } from '@dev-spendesk/grapes';
import React, { useEffect, useRef } from 'react';

import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { type OperationalOnboardingData } from '../../../../../models';
import { OperationalOnboardingItem } from '../OperationalOnboardingItem';

type Props = {
  operationalOnboarding: OperationalOnboardingData;
};

export const OperationalOnboardingContent = ({
  operationalOnboarding,
}: Props) => {
  const ref = useRef<AccordionHandle | null>(null);

  const firstOngoingItemIndex = operationalOnboarding.tasks.findIndex(
    (task) => task.status === 'ongoing',
  );
  const defaultAccordionItemIndexes =
    firstOngoingItemIndex !== -1 ? new Set([firstOngoingItemIndex]) : undefined;

  const closeAccordion = () => {
    ref.current?.closeAccordion();
  };

  const closeAccordionAndOpenNext = (currentIndex: number) => {
    ref.current?.closeAccordion();
    const followingOngoingTasks = operationalOnboarding.tasks
      .slice(currentIndex + 1)
      .filter((task) => task.status === 'ongoing');

    if (followingOngoingTasks.length > 0) {
      const nextTaskIndex = operationalOnboarding.tasks.indexOf(
        followingOngoingTasks[0],
      );
      ref.current?.openAccordion(new Set([nextTaskIndex]));
    }
  };

  useEffect(() => {
    track(AnalyticEventName.WELCOME_DASHBOARD_LOAD, {
      taskList: operationalOnboarding.tasks.map((task) => ({
        type: task.type,
        status: task.status,
      })),
    });
  }, []);

  useEffect(() => {
    ref.current?.openAccordion(new Set([firstOngoingItemIndex]));
  }, [firstOngoingItemIndex]);

  return (
    <>
      <Accordion
        ref={ref}
        defaultAccordionItemIndexes={defaultAccordionItemIndexes}
      >
        {operationalOnboarding.tasks.map((task, index) => (
          <OperationalOnboardingItem
            key={task.type}
            task={task}
            closeAccordion={closeAccordion}
            closeAccordionAndOpenNext={() => closeAccordionAndOpenNext(index)}
          />
        ))}
      </Accordion>
    </>
  );
};
