import isEmpty from 'lodash/isEmpty';

import { PaymentMethod } from 'modules/company';
import { type SupplierBankInfos } from 'modules/suppliers';

import {
  type PaymentsBatchPaymentDetails,
  PaymentsBatchPaymentStatus,
} from './paymentsBatch';
import { type Beneficiary } from './schedulingProcess';
import {
  type WireTransferError,
  type BeneficiaryBankInfoError,
  isBeneficiaryBankInfoError,
  getBankFieldsRelevantToBeneficiaryBankInfoError,
} from './wireTransferError';

export interface FailedWireTransferPayment extends PaymentsBatchPaymentDetails {
  paymentMethod: PaymentMethod.WireTransfer;
  status: PaymentsBatchPaymentStatus.Failed;
  beneficiary: Beneficiary;
  failureReason?: WireTransferError;
}

export const isFailed = (
  payment: PaymentsBatchPaymentDetails,
): payment is FailedWireTransferPayment =>
  payment.paymentMethod === PaymentMethod.WireTransfer &&
  payment.status === PaymentsBatchPaymentStatus.Failed;

export const isBeneficiaryBankInfoErrorResolved = (
  payment: FailedWireTransferPayment & {
    failureReason: BeneficiaryBankInfoError;
  },
  supplierBankInfos: SupplierBankInfos,
): boolean => {
  const { beneficiary } = payment;
  const relevantBankFields = getBankFieldsRelevantToBeneficiaryBankInfoError(
    payment.failureReason,
  );

  // compares each beneficiary bank field with the current supplier bank field value
  // returns OK if one of the bank field that caused the error has been updated
  for (const bankField of relevantBankFields) {
    if (
      !isEmpty(supplierBankInfos[bankField]) &&
      beneficiary[bankField] !== supplierBankInfos[bankField]
    ) {
      return true;
    }
  }

  return false;
};

export const hasFailedFromBeneficiaryBankInfoError = (
  payment: FailedWireTransferPayment,
): payment is FailedWireTransferPayment & {
  failureReason: BeneficiaryBankInfoError;
} =>
  payment.failureReason !== undefined &&
  isBeneficiaryBankInfoError(payment.failureReason);
