import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';

import { useGraphDataQuery } from '../../hooks/useGraphDataQuery';
import { Graph } from '../Graph';

import './GraphDashboard.css';

export const GraphDashboard = () => {
  const { t } = useTranslation('global');

  const company = useCompany();

  const graphDataQueryState = useGraphDataQuery();

  if (graphDataQueryState.status !== 'success') {
    return null;
  }

  if (graphDataQueryState.data.length === 0) {
    return (
      <div className="box p-xl">
        <div className="GraphDashboard__graphPlaceholder p-m text-neutral-dark body-m">
          <img
            className="mb-xs h-l"
            src="/static/img/homepage/big-picture.png"
            alt=""
          />
          <h4 className="text-complementary title-l">
            {t('homepage.welcome.placeholderTitle')}
          </h4>
          <p>{t('homepage.welcome.placeholderDescription')}</p>
        </div>
      </div>
    );
  }

  return (
    <Graph
      companyId={company.id}
      currency={company.currency}
      graphItems={graphDataQueryState.data}
    />
  );
};
