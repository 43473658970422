import FEATURES from "src/core/constants/features";

const spendingMethods = [
  FEATURES.EXPENSE_CLAIMS,
  FEATURES.SUBMIT_MY_INVOICE,
  FEATURES.PURCHASE_ORDERS_ACTIVATED,
  FEATURES.PER_DIEM_ALLOWANCES,
  FEATURES.MILEAGE_ALLOWANCES,
] as const;
export type SpendingMethod = (typeof spendingMethods)[number];

export function isSpendingMethodAllowed(
  spendingMethod: SpendingMethod,
  country: string,
  {
    isControlOnPurchaseOrderEnabled,
  }: {
    isControlOnPurchaseOrderEnabled: boolean;
  },
): boolean {
  switch (spendingMethod) {
    case FEATURES.PURCHASE_ORDERS_ACTIVATED:
      return isControlOnPurchaseOrderEnabled;
    case FEATURES.PER_DIEM_ALLOWANCES:
      return country === 'DE';
    default:
      return true;
  }
}

export function isSpendingMethodDisabled(
  featureCode: SpendingMethod,
  {
    isPurchaseOrderEnabled,
    isCostCenterActivated,
    hasMileageScheme,
    isInvoiceEnabled,
  }: {
    isPurchaseOrderEnabled: boolean;
    isCostCenterActivated: boolean;
    hasMileageScheme: boolean;
    isInvoiceEnabled: boolean;
  },
): boolean {
  switch (featureCode) {
    case FEATURES.SUBMIT_MY_INVOICE:
      return isPurchaseOrderEnabled;
    case FEATURES.PURCHASE_ORDERS_ACTIVATED:
      return !isCostCenterActivated || !isInvoiceEnabled;
    case FEATURES.MILEAGE_ALLOWANCES:
      return !hasMileageScheme;
    default:
      return false;
  }
}

type getAllowedSpendingMethodsResult = {
  featureCode: SpendingMethod;
  isDisabled: boolean;
}[];
export function getAllowedSpendingMethods(
  country: string,
  {
    isInvoiceEnabled,
    isControlOnPurchaseOrderEnabled,
    isPurchaseOrderEnabled,
    isCostCenterActivated,
    hasMileageScheme,
  }: {
    isInvoiceEnabled: boolean;
    isControlOnPurchaseOrderEnabled: boolean;
    isPurchaseOrderEnabled: boolean;
    isCostCenterActivated: boolean;
    hasMileageScheme: boolean;
  },
): getAllowedSpendingMethodsResult {
  return spendingMethods
    .filter((spendingMethod) =>
      isSpendingMethodAllowed(spendingMethod, country, {
        isControlOnPurchaseOrderEnabled,
      }),
    )
    .map((featureCode) => {
      return {
        featureCode,
        isDisabled: isSpendingMethodDisabled(featureCode, {
          isInvoiceEnabled,
          isPurchaseOrderEnabled,
          isCostCenterActivated,
          hasMileageScheme,
        }),
      };
    });
}
