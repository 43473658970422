import { gql } from 'graphql-tag';

export const ASSIGN_DEFAULT_EMPLOYEE_ACCOUNT_TO_MEMBER = gql`
  mutation setDefaultEmployeeAccountToMember($companyId: ID!, $memberId: ID!) {
    setDefaultEmployeeAccountToMember(
      companyId: $companyId
      memberId: $memberId
    ) {
      ... on SetDefaultEmployeeAccountToMemberResultNotSet {
        reason
      }
    }
  }
`;

export const SET_ACCOUNTING_INTEGRATION = gql`
  mutation setAccountingIntegration(
    $companyId: ID!
    $accountingSoftware: AccountingSoftware!
  ) {
    setAccountingIntegration(
      companyId: $companyId
      accountingSoftware: $accountingSoftware
    ) {
      ... on SetAccountingIntegrationResultNotSet {
        reason
      }
    }
  }
`;

export const SWITCH_ACCOUNTING_INTEGRATION = gql`
  mutation switchAccountingIntegration(
    $companyId: ID!
    $accountingSoftware: AccountingSoftware!
  ) {
    switchAccountingIntegration(
      companyId: $companyId
      accountingSoftware: $accountingSoftware
    ) {
      ... on SwitchAccountingIntegrationResultNotSwitched {
        reason
        error
      }
    }
  }
`;
