import { z } from 'zod';

import {
  type I18nKey,
  type TGlobalFunctionTyped,
} from '../../../../common/hooks/useTranslation';

export const publicApiScopeListEntry = z.object({
  name: z.string(),
  translationKey: z.string(),
  experimental: z.boolean().optional(),
});

export type PublicApiScopeListEntry = z.infer<typeof publicApiScopeListEntry>;

const getTranslationKeysByScopeName = (
  scopeEntries: PublicApiScopeListEntry[],
): Map<string, string> =>
  new Map(
    scopeEntries.map(({ name, translationKey }) => [name, translationKey]),
  );

export const getPublicApiScopeTranslation = ({
  t,
  scopeEntries,
  type,
}: {
  t: TGlobalFunctionTyped;
  scopeEntries: PublicApiScopeListEntry[];
  type: 'title' | 'description';
}) => {
  const scopeTranslationKeysByScopeName =
    getTranslationKeysByScopeName(scopeEntries);

  return (scopeName: string): string => {
    const translationKey = scopeTranslationKeysByScopeName.get(scopeName);

    if (!translationKey) {
      // TODO decide what to do in case of scope not found
      return '';
    }

    // We have to cast here because we won't always have the keys in global.json
    // right away
    return t(`publicApi.scopes.list.${translationKey}.${type}` as I18nKey);
  };
};
