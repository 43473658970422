import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type TaxAccount } from '../../accounting';

export const useTaxAccountsQuery = (): QueryState<TaxAccount[]> => {
  const { company: companyId } = useParams();

  return useQuery<TaxAccount[]>({
    key: ['getTaxAccounts', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/chart-of-accounts/tax-accounts',
      params: {
        includeDefault: false,
      },
    },
    reshapeData(taxAccounts) {
      return taxAccounts;
    },
  });
};
