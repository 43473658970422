import { gql } from 'graphql-tag';

export const MARK_ANNOUNCEMENT_SEEN = gql`
  mutation MarkAnnouncementSeen($companyId: ID!, $code: String!) {
    markAnnouncementSeen(companyId: $companyId, code: $code) {
      outcome
      announcement {
        code
      }
    }
  }
`;
