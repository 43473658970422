import React from 'react';

import { EmptyStateError, lazyLoad } from 'common/components/LazyLoad';

import { InvoicesPageSkeleton } from './InvoicesPageSkeleton';

export const InvoicesPageContainer = lazyLoad({
  loader: async () => ({
    default:
      /* eslint-disable unicorn/no-await-expression-member */
      (
        await import(
          /* webpackChunkName: "InvoicesPageContainer" */ './InvoicesPageContainer'
        )
      ).InvoicesPageContainer,
    /* eslint-enable unicorn/no-await-expression-member */
  }),
  loading: <InvoicesPageSkeleton />,
  fallback: (props) => <EmptyStateError {...props} />,
});
