import { useHistory, useLocation, useParams } from 'react-router-dom';

import { routeFor, routes } from 'src/core/constants/routes';

export const usePreparePayablesRouter = () => {
  const history = useHistory();
  const location = useLocation();
  const { company: companyId } = useParams();

  const goToPreparePage = (options?: { search: string }): void => {
    history.push({
      pathname: routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
        company: companyId,
        payableId: '',
      }),
      search: location.search,
      ...options,
    });
  };

  const goToPreparePageWithPayable = (payableId: string): void => {
    history.push({
      pathname: routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
        company: companyId,
        payableId,
      }),
      search: location.search,
    });
  };

  const applyQueryParams = (params: string): void => {
    history.replace({
      search: params,
    });
  };

  return { goToPreparePage, goToPreparePageWithPayable, applyQueryParams };
};
