import type React from 'react';
import { createContext } from 'react';

import { type PayableValidation } from '../../models';

export const PreparePayablesValidationContext = createContext<{
  state: PayableValidation;
  setState: React.Dispatch<React.SetStateAction<PayableValidation>>;
}>({
  state: {},
  setState: () => {},
});
