import { IconButton } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  customFieldValueId: string;
  showUpdateCustomFieldValueModal: ({
    customFieldValueId,
    customFieldValueName,
  }: {
    customFieldValueId: string;
    customFieldValueName: string;
  }) => void;
  showDeleteCustomFieldValueModal: ({
    customFieldValueId,
    customFieldValueName,
  }: {
    customFieldValueId: string;
    customFieldValueName: string;
  }) => void;
  customFieldValueName: string;
};

export const CustomFieldRowActions = ({
  customFieldValueId,
  showUpdateCustomFieldValueModal,
  showDeleteCustomFieldValueModal,
  customFieldValueName,
}: Props) => {
  const { t } = useTranslation('global');
  return (
    <div className="CustomFieldValuesList__row__actions">
      <IconButton
        variant="borderless"
        iconName="pen"
        onClick={() =>
          showUpdateCustomFieldValueModal({
            customFieldValueId,
            customFieldValueName,
          })
        }
        aria-label={t('misc.edit')}
      />
      <IconButton
        variant="borderless"
        iconName="trash"
        onClick={() =>
          showDeleteCustomFieldValueModal({
            customFieldValueId,
            customFieldValueName,
          })
        }
        aria-label={t('misc.delete')}
      />
    </div>
  );
};
