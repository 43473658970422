import type { BudgetTrackingTraitModel } from 'modules/budgets/models/budgetTracking';

export const earliestAllowedDate = new Date('2010');
export const latestAllowedDate = new Date('2060');

export type EntityId = string;

export interface Entity extends BudgetTrackingTraitModel.Trait {
  id: string;
  amount: number;
  currency: string;
  description: string;
  requesterId: string;
}
