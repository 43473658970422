import {
  Icon,
  type IconName,
  PanelSection,
  type PanelSectionProps,
  colors,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { type ReactNode } from 'react';

type Props = DistributiveOmit<PanelSectionProps, 'children'> & {
  items: {
    label: string;
    value: string;
    icon: IconName;
    variant: 'info' | 'success';
  }[];
  panelActions?: ReactNode;
};

export function PanelIconItemsSection({
  items,
  panelActions,
  ...panelSectionProps
}: Props) {
  if (items.length === 0 && !panelActions) {
    return null;
  }

  return (
    <PanelSection {...panelSectionProps}>
      <ul>
        {items.map((item) => (
          <li key={item.label} className="mb-xs flex justify-between last:mb-0">
            <span
              className={cx('mr-xs flex items-center title-m', {
                'text-info': item.variant === 'info',
                'text-success': item.variant === 'success',
              })}
            >
              <Icon
                name={item.icon}
                color={variantToColor(item.variant)}
                className="mr-xs"
              />
              {item.label}
            </span>
            <span className="overflow-hidden text-ellipsis whitespace-nowrap text-neutral-dark body-m">
              {item.value}
            </span>
          </li>
        ))}
      </ul>
      {panelActions}
    </PanelSection>
  );
}

const variantToColor = (variant: Props['items'][number]['variant']) => {
  if (variant === 'success') {
    return colors.success;
  }
  return colors.info;
};
