import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type Payload = {
  scaProcedure: {
    id: string;
    state: 'pending' | 'approved' | 'rejected' | 'timeout';
  };
};

export const useScaProcedure = ({
  procedureId,
  shouldRefresh,
  shouldFetch,
}: {
  procedureId: string | undefined;
  shouldRefresh: boolean;
  shouldFetch: boolean;
}): QueryState<Payload> => {
  return useQuery<Payload>({
    key: ['sca', 'authProcedures', procedureId],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/sca/auth-procedures/${procedureId}`,
    },
    isEnabled: shouldFetch,
    options: {
      ...(shouldRefresh ? { refetchInterval: 3000 } : {}),
    },
  });
};
