import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { useIsReimburseViaSepaEnabled } from './useIsReimburseViaSepaEnabled';

export const useShouldDisplayBankInformation = () => {
  // SMI includes XML SEPA for SMI but that's only useful for EUR companies
  const isSubmitMyInvoiceEnabled = useFeature(FEATURES.SUBMIT_MY_INVOICE);
  const isReimburseExpensesViaSepaEnabled = useIsReimburseViaSepaEnabled();

  const companyCurrency = useCompanyCurrency();

  return (
    (isSubmitMyInvoiceEnabled && companyCurrency === 'EUR') ||
    isReimburseExpensesViaSepaEnabled
  );
};
