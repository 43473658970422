import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { type FormValues } from './types';

export const validate = ({
  values,
  usedNames,
  t,
}: {
  values: FormValues;
  usedNames: string[];
  t: TGlobalFunctionTyped;
}) => {
  const errors: FormikErrors<FormValues> = {};
  const { name, costCenterIds, assignToAllCostCenters } = values;

  if (!name.trim()) {
    errors.name = t('expenseCategories.form.nameEmptyError');
  } else if (usedNames.includes(name)) {
    errors.name = t('customFields.creation.duplicateValues');
  }

  if (!assignToAllCostCenters && costCenterIds.length === 0) {
    errors.costCenterIds = t('expenseCategories.form.selectOneCostCenter');
  }

  return errors;
};
