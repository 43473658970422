import { DATE_FORMAT } from '@dev-spendesk/grapes';
import React from 'react';

import { FileReceipt, type MediaType } from 'common/components/FileReceipt';
import { PanelPreviewsSection } from 'common/components/Panel';
import { useTranslation } from 'common/hooks/useTranslation';

import { type TransferDetails } from '../../../models';

type Props = {
  transfer: TransferDetails;
};

export const ReceiptSection = ({ transfer }: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const {
    bill: { documentaryEvidence },
  } = transfer;

  return (
    <PanelPreviewsSection
      title={t('invoices.transfer.panel.receipt')}
      previews={documentaryEvidence.attachments.map((attachment) => (
        <FileReceipt
          key={attachment.id}
          previewName={t('invoices.transfer.panel.uploadedOn', {
            date: localeFormat(
              new Date(attachment.attachedAt),
              DATE_FORMAT.SHORT,
            ),
          })}
          fileUrl={attachment.location}
          fileMediaType={attachment.mimeType as MediaType}
        />
      ))}
      noPreviewContent={t('invoices.transfer.panel.noReceipts')}
    />
  );
};
