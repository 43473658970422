import { createAction, type Dispatch } from '@reduxjs/toolkit';

import { companyAPI } from 'src/core/api/axios';
import { updateCompany } from 'src/core/modules/company';
import { type AppState } from 'src/core/reducers';

import * as types from './actionTypes';

// Invoice pattern update
const updateInvoicePatternLoading = createAction(
  types.UPDATE_INVOICE_PATTERN_LOADING,
);
const updateInvoicePatternFailure = createAction(
  types.UPDATE_INVOICE_PATTERN_FAILURE,
);
const updateInvoicePatternSuccess = createAction(
  types.UPDATE_INVOICE_PATTERN_SUCCESS,
);
export const updateInvoicePattern =
  (pattern: string[]) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getState().global.company?.id ?? '';
    if (!pattern) {
      return;
    }

    dispatch(updateInvoicePatternLoading());

    try {
      await companyAPI.patch(
        '/invoice-pattern',
        { pattern },
        {
          companyId,
        },
      );
    } catch (error) {
      dispatch(updateInvoicePatternFailure(error));
      throw error;
    }

    dispatch(
      updateCompany({ ...getState().global.company, invoices: pattern }),
    );
    dispatch(updateInvoicePatternSuccess());
  };
