import {
  Avatar,
  Callout,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
} from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import {
  type KomboEmployeePreview,
  type KomboSettingsPreview,
} from 'src/core/modules/integrations/hooks/useGetKomboSettingsPreviewQuery';

type Props = {
  settingsPreviewResult: KomboSettingsPreview;
};

export const Preview = ({ settingsPreviewResult }: Props) => {
  const { t } = useTranslation('global');
  // We display specific callouts if there are no employees or if the changes have no effect
  const shouldRenderWarningCallout =
    settingsPreviewResult.total === 0 || settingsPreviewResult.hasNoEffect;

  const renderWarningCallout = () => {
    if (settingsPreviewResult.total === 0) {
      return (
        <Callout
          title={t(
            'integration.kombo.settings.settingsModal.noEmployees.title',
          )}
          variant="alert"
        >
          {t(
            'integration.kombo.settings.settingsModal.noEmployees.description',
          )}
        </Callout>
      );
    }
    if (settingsPreviewResult.hasNoEffect) {
      return (
        <Callout
          title={t('integration.kombo.settings.settingsModal.noChange.title')}
          variant="warning"
        >
          {t('integration.kombo.settings.settingsModal.noChange.description')}
        </Callout>
      );
    }
  };

  const renderEmployeesTable = (employeeList: KomboEmployeePreview[]) => {
    return (
      <Table
        className="border-0"
        data={reshapePreviewData(employeeList)}
        rowHeight="compact"
        getIsRowDisabled={(employee) => employee.fullName === null}
        columns={[
          {
            id: 'fullName',
            header: t(
              'integration.kombo.settings.settingsModal.syncPreview.sample.columns.employee',
            ),
            renderCell: (employee) =>
              employee.fullName !== null ? (
                <div className="flex items-center">
                  <div className="mr-xs mt-xs">
                    <Avatar text={employee.fullName} size="m" />
                  </div>
                  <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {employee.fullName}
                  </span>
                </div>
              ) : (
                <></>
              ),
          },
          {
            id: 'workEmail',
            header: t(
              'integration.kombo.settings.settingsModal.syncPreview.sample.columns.email',
            ),
            renderCell: (employee) =>
              employee.workEmail !== null ? (
                <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {employee.workEmail}
                </div>
              ) : (
                <></>
              ),
          },
        ]}
      />
    );
  };

  return (
    <>
      <div>
        <div className="h-m text-left font-medium text-neutral-darker">
          {t('integration.kombo.settings.settingsModal.syncPreview.title')}
        </div>
        <div className="flex flex-col gap-s rounded-xxs bg-neutral-lightest p-s text-left font-normal text-neutral-darker">
          <div>
            {settingsPreviewResult.total === 1 ? (
              <Trans
                i18nKey={t(
                  'integration.kombo.settings.settingsModal.syncPreview.description.singular',
                )}
                components={{
                  strong: <strong className="font-medium">-</strong>,
                }}
              />
            ) : (
              <Trans
                i18nKey={t(
                  'integration.kombo.settings.settingsModal.syncPreview.description.plural',
                )}
                values={{ total: settingsPreviewResult.total }}
                components={{
                  strong: <strong className="font-medium">-</strong>,
                }}
              />
            )}
          </div>

          <div>
            <div>
              <Trans
                i18nKey={t(
                  'integration.kombo.settings.settingsModal.syncPreview.description.invites',
                )}
                values={{ total: settingsPreviewResult.invites.total }}
                components={{
                  strong: <strong className="font-medium">-</strong>,
                }}
              />
            </div>
            {settingsPreviewResult.archives && (
              <div>
                <Trans
                  i18nKey={t(
                    'integration.kombo.settings.settingsModal.syncPreview.description.archives',
                  )}
                  values={{ total: settingsPreviewResult.archives.total }}
                  components={{
                    strong: <strong className="font-medium">-</strong>,
                  }}
                />
              </div>
            )}
            <div>
              <Trans
                i18nKey={t(
                  'integration.kombo.settings.settingsModal.syncPreview.description.outOfSyncs',
                )}
                values={{ total: settingsPreviewResult.outOfSyncs.total }}
                components={{
                  strong: <strong className="font-medium">-</strong>,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {shouldRenderWarningCallout ? (
          renderWarningCallout()
        ) : (
          <>
            <div className="mb-xxs text-left font-medium text-neutral-darker">
              {t(
                'integration.kombo.settings.settingsModal.syncPreview.sample.title',
              )}
            </div>
            <div className="mb-xs text-left font-normal text-neutral-dark">
              {t(
                'integration.kombo.settings.settingsModal.syncPreview.sample.description',
              )}
            </div>

            <div className="rounded-xs border border-solid border-neutral">
              <Tabs>
                <TabList isFitted>
                  <Tab>
                    {t(
                      'integration.kombo.settings.settingsModal.syncPreview.sample.tabs.invites',
                    )}
                  </Tab>
                  {settingsPreviewResult.archives && (
                    <Tab>
                      {t(
                        'integration.kombo.settings.settingsModal.syncPreview.sample.tabs.archives',
                      )}
                    </Tab>
                  )}
                  <Tab>
                    {t(
                      'integration.kombo.settings.settingsModal.syncPreview.sample.tabs.outOfSyncs',
                    )}
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    {renderEmployeesTable(
                      settingsPreviewResult.invites.employees,
                    )}
                  </TabPanel>
                  {settingsPreviewResult.archives && (
                    <TabPanel>
                      {renderEmployeesTable(
                        settingsPreviewResult.archives.employees,
                      )}
                    </TabPanel>
                  )}
                  <TabPanel>
                    {renderEmployeesTable(
                      settingsPreviewResult.outOfSyncs.employees,
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const reshapePreviewData = (employeePreviewList: KomboEmployeePreview[]) => {
  if (employeePreviewList.length >= 5) {
    return employeePreviewList;
  }

  // if we have less than 5 employees, we need to fill the list with empty employees
  const placeholderEmployees = Array.from({
    length: 5 - employeePreviewList.length,
  }).map(() => ({
    fullName: null,
    workEmail: null,
  }));

  return [...employeePreviewList, ...placeholderEmployees];
};
