import * as types from './actionTypes';

export const addNotificationToStack = (
  payload: types.AddNotificationToStack['payload'],
): types.AddNotificationToStack => ({
  type: types.ADD_NOTIFICATION_TO_STACK,
  payload,
});

export const removeNotification = (
  payload: types.RemoveNotification['payload'],
): types.RemoveNotification => ({ type: types.REMOVE_NOTIFICATION, payload });
