import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  address1: string | null;
  address2: string | null;
  city: string | null;
  country: string | null;
  name: string | null;
  zipcode: string | null;
  is_billing_info_same_as_company: boolean;
  email: string | null;
  vat: string | null;
  company_id: string;
  organisation_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  id: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type Response = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type RawResponse = {};

export const useUpdateBillingInformationMutation = (): MutationState<
  Payload,
  Response
> => {
  const companyId = useCompanyId();

  return useMutation<Payload, Response, RawResponse>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: '/billing/address',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['billingInformation', companyId]);
      },
    },
  });
};
