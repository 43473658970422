import React from 'react';
import { Redirect } from 'react-router';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { useParams } from 'common/hooks/useParams';
import { routes } from 'src/core/constants/routes';

import { useGetIntegrations } from '../../../hooks';
import { type IntegrationId } from '../../../types';

const ExternalConnectRedirect = () => {
  const integrationsQuery = useGetIntegrations();

  const params = useParams(routes.COMPANY_INTEGRATION_EXTERNAL_CONNECT.path);
  const integration = params.integration as IntegrationId;

  const defaultRedirectURL = `${appConfig.wwwBaseUrl}/app/${params.company}/company/integrations`;

  if (integrationsQuery.status === 'loading') {
    return <></>;
  }

  if (integrationsQuery.status === 'error') {
    return <Redirect to={defaultRedirectURL} />;
  }

  const selectedIntegration = integrationsQuery.data.available.find(
    (index) => index.id === integration,
  );

  if (!selectedIntegration) {
    return <Redirect to={defaultRedirectURL} />;
  }

  window.location.href = selectedIntegration.redirectionUrl;
  return <></>;
};

export const ExternalConnectRedirectPage = withErrorBoundary({
  team: 'api-integration',
  scope: 'external-connect-redirect',
})(ExternalConnectRedirect);
