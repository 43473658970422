import {
  type Team,
  type TeamRaw,
  type TeamUser,
  type TeamUserRaw,
} from '../models/teams';

export function reshapeTeam(rawTeam: TeamRaw): Team {
  return {
    id: rawTeam.id,
    adminIds: rawTeam.admin_ids,
    admins: rawTeam.admins.map(formatUser),
    companyId: rawTeam.company_id,
    creatorId: rawTeam.creator_id,
    members: rawTeam.members,
    name: rawTeam.name,
    organisationId: rawTeam.organisation_id,
    scope: rawTeam.scope,
    users: rawTeam.users.map(formatUser),
  };
}

const formatUser = (rawUser: TeamUserRaw): TeamUser => {
  return {
    fullname: rawUser.fullname,
    firstName: rawUser.first_name,
    lastName: rawUser.last_name,
    gender: rawUser.gender,
    lang: rawUser.lang,
    pending: rawUser.pending || false,
    email: rawUser.email,
    id: rawUser.id,
    companyIds: rawUser.company_ids,
    isOrganisationOwner: rawUser.is_organisation_owner,
    nbCompanies: rawUser.nb_companies,
  };
};
