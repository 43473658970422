import * as approvalActions from './actions';
import type {
  ApprovalAction,
  FetchSchemaSuccess,
  FetchSchemaFailure,
  SaveSchemaSuccess,
  SaveSchemaFailure,
} from './actions';
import * as approvalModels from '../models';
import { type ApprovalState } from '../types';

export const initialState: ApprovalState = {
  byTeamId: {},
  isFetching: false,
  isSaving: false,
  error: null,
};

const fetchSchemaRequest = (state: ApprovalState): ApprovalState => ({
  ...state,
  isFetching: true,
});

const fetchSchemaSuccess = (
  state: ApprovalState,
  action: FetchSchemaSuccess,
): ApprovalState => {
  const { teamId, rules } = action.payload;
  return {
    ...state,
    isFetching: false,
    byTeamId: {
      ...state.byTeamId,
      [teamId]: approvalModels.normalizeRules(rules),
    },
  };
};

const fetchSchemaFailure = (
  state: ApprovalState,
  action: FetchSchemaFailure,
): ApprovalState => ({
  ...state,
  isFetching: false,
  error: action.payload,
});

const saveSchemaRequest = (state: ApprovalState): ApprovalState => ({
  ...state,
  isSaving: true,
});

const saveSchemaSuccess = (
  state: ApprovalState,
  action: SaveSchemaSuccess,
): ApprovalState => {
  const { teamId, rules } = action.payload;
  return {
    ...state,
    isSaving: false,
    byTeamId: {
      ...state.byTeamId,
      [teamId]: approvalModels.normalizeRules(rules),
    },
  };
};

const saveSchemaFailure = (
  state: ApprovalState,
  action: SaveSchemaFailure,
): ApprovalState => ({
  ...state,
  isSaving: false,
  error: action.payload,
});

const reducer = (
  state: ApprovalState = initialState,
  action: ApprovalAction,
): ApprovalState => {
  switch (action.type) {
    case approvalActions.FETCH_SCHEMA_REQUEST:
      return fetchSchemaRequest(state);
    case approvalActions.FETCH_SCHEMA_SUCCESS:
      return fetchSchemaSuccess(state, action);
    case approvalActions.FETCH_SCHEMA_FAILURE:
      return fetchSchemaFailure(state, action);
    case approvalActions.SAVE_SCHEMA_REQUEST:
      return saveSchemaRequest(state);
    case approvalActions.SAVE_SCHEMA_SUCCESS:
      return saveSchemaSuccess(state, action);
    case approvalActions.SAVE_SCHEMA_FAILURE:
      return saveSchemaFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
