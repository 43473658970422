import { getCompanyId } from './globalSelectorsTyped';
import { type Factor } from '../common/components/ModalMFA/models';
import { type User } from '../modules/app/hooks/useUser';
import { type AppState } from '../reducers';

// should be fixed at some point, once reducer users is typec
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUsers = (state: AppState): any => state.users.users;

export const getUsersIsLoading = (state: AppState) =>
  state.users.isLoadingUsers;

// Assuming user will always be defined, otherwise types explodes all over the app :)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSelf = (state: AppState): any => state.global.user as User;

export const getUserApprovalData = (state: AppState) =>
  state.profile.userApprovalData;

export const getUserSlackProviderSettings = (state: AppState) =>
  state.profile.slackProviderSettings;

export const getUserPhoneNumber = (state: AppState) => {
  const { user } = state.global;
  return user?.mobile_no && !user?.is_mobile_needed
    ? `+${user?.mobile_ext}${user?.mobile_no}`
    : '';
};

export const getUserActiveFactorPhoneNumber = (state: AppState) => {
  const { factor } = state.mfaActivation;
  return factor && factor.type === 'phone' && factor.params;
};

export const getFactorPhoneNumber = (state: AppState) => {
  if (
    state.mfaActivation.factor &&
    state.mfaActivation.factor.state === 'active'
  ) {
    return state.mfaActivation.factor.params;
  }
};

export const getPhoneFactor = (state: AppState) =>
  state.mfaActivation.factor as Factor;

export const getIsMobileNumber = (state: AppState) => {
  const { user } = state.global;
  return user?.is_mobile_needed as boolean;
};

export const getDefaultAccountHolderName = (state: AppState) =>
  getSelf(state).fullname;

export const getUserLang = (state: AppState) =>
  state.global.config?.user?.lang ?? '';

export const getUserId = (state: AppState) => getSelf(state).id;

export const isAccountOwner = (state: AppState) =>
  getSelf(state).is_account_owner;

export const isOrganisationOwner = (state: AppState) =>
  getSelf(state).is_organisation_owner;

export const isController = (state: AppState) => getSelf(state).is_controller;

export const isAdmin = (state: AppState) => getSelf(state).is_admin;

export const getUserPaymentCount = (state: AppState, companyId: string) => {
  const user = getSelf(state);
  const userCompanyData = user?.data_by_company[companyId];
  return userCompanyData?.nbPaymentsFromBeginning ?? 0;
};

export const getIsUserOrganisationOwner = (state: AppState) =>
  getSelf(state).is_organisation_owner;

export const getUserGroupIds = (state: AppState) => {
  const companyId = getCompanyId(state);
  const user = getSelf(state);
  const userCompanyData = user?.data_by_company[companyId];

  //
  return userCompanyData?.groups_ids ?? [];
};
