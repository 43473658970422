import { gql } from 'graphql-tag';

export const GET_PAYABLES_TO_PREPARE_COUNTS = gql`
  query GetPayablesToPrepareCount(
    $companyId: ID!
    $prepareFilters: PayableFilter!
    $exportFilters: PayableFilter!
  ) {
    company(id: $companyId) {
      id
      toPreparePayables: payables(filter: $prepareFilters) {
        totalCount
      }
      toExportPayables: payables(filter: $exportFilters) {
        totalCount
      }
    }
  }
`;
