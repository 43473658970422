import map from 'lodash/map';
import merge from 'lodash/merge';

import * as types from './actionTypes';
import {
  type SuppliersAction,
  type FetchSuppliersSuccess,
  type CreateSupplierSuccess,
  type UpdateSupplierSuccess,
} from './actions';
import { type Supplier, type SupplierError } from './supplier';

type SuppliersState = {
  openedSupplier: Supplier | undefined;
  suppliers: Supplier[] | undefined;
  hasSelectedFilters: boolean;
  areSuppliersLoading: boolean;
  isSupplierLoading: boolean;
  supplierError: SupplierError;
};

const initialState: SuppliersState = {
  openedSupplier: undefined,
  suppliers: undefined,
  hasSelectedFilters: false,
  areSuppliersLoading: true,
  isSupplierLoading: true,
  supplierError: undefined,
};

const fetchSuppliersLoading = (state: SuppliersState): SuppliersState => ({
  ...state,
  areSuppliersLoading: true,
});

const fetchSuppliersSuccess = (
  state: SuppliersState,
  action: FetchSuppliersSuccess,
): SuppliersState => ({
  ...state,
  suppliers: action.payload.suppliers,
  hasSelectedFilters: action.payload.hasSelectedFilters,
  areSuppliersLoading: false,
});

const fetchSuppliersFailure = (state: SuppliersState): SuppliersState => ({
  ...state,
  areSuppliersLoading: false,
});

const createSupplierSuccess = (
  state: SuppliersState,
  action: CreateSupplierSuccess,
): SuppliersState => {
  const { newSupplier } = action.payload;

  // Add the new supplier in the list
  const suppliers = state.suppliers
    ? [...state.suppliers, newSupplier]
    : [newSupplier];
  const suppliersSorted = suppliers.sort((supplier1, supplier2) => {
    return supplier1.name.localeCompare(
      supplier2.name,
      undefined, // ignore language
      {
        sensitivity: 'base',
      },
    );
  });

  return { ...state, suppliers: suppliersSorted };
};

const updateSupplierSuccess = (
  state: SuppliersState,
  action: UpdateSupplierSuccess,
): SuppliersState => {
  const { updatedSupplier } = action.payload;

  // Update supplier in the list
  const suppliers = map(state.suppliers, (supplier) => {
    if (supplier.id === updatedSupplier.id) {
      return merge({}, supplier, updatedSupplier);
    }
    return supplier;
  });

  // Update opened supplier
  const openedSupplier =
    state.openedSupplier && state.openedSupplier.id === updatedSupplier.id
      ? merge({}, state.openedSupplier, updatedSupplier)
      : state.openedSupplier;

  return { ...state, suppliers, openedSupplier };
};

function reducer(
  state: SuppliersState = initialState,
  action: SuppliersAction,
): SuppliersState {
  switch (action.type) {
    case types.FETCH_SUPPLIERS_LOADING:
      return fetchSuppliersLoading(state);
    case types.FETCH_SUPPLIERS_SUCCESS:
      return fetchSuppliersSuccess(state, action);
    case types.FETCH_SUPPLIERS_FAILURE:
      return fetchSuppliersFailure(state);
    case types.CREATE_SUPPLIER_SUCCESS:
      return createSupplierSuccess(state, action);
    case types.UPDATE_SUPPLIER_SUCCESS:
      return updateSupplierSuccess(state, action);
    default:
      return state;
  }
}

export default reducer;
