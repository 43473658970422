import { type I18nKey } from 'common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type AccountingSoftware } from '../../../../../../integration/status';

export function getExpenseAccountsDescriptionI18nKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero.expenseAccountsDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.expenseAccountsDescription';
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return 'bookkeep.integrations.fileBased.expenseAccountsDescription';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.expenseAccountsDescription';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks.expenseAccountsDescription';
    case 'Sage100':
    case 'ACD': // TODO
      // TODO: Check content
      return 'bookkeep.integrations.chift.expenseAccountsDescription';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getExpenseAccountsHelpCenterLink(
  integration: AccountingSoftware,
): string {
  switch (integration) {
    case 'Datev':
      return 'https://helpcenter.spendesk.com/articles/5556274-set-up-expense-accounts';
    default:
      return 'https://helpcenter.spendesk.com/articles/4136555-set-up-your-accounting-codes';
  }
}
