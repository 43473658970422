import {
  track,
  AnalyticEventName,
  type AnalyticEventPayloadDefinition,
} from 'src/core/utils/analytics';

import { type MemberDetails, type DraftMember } from '../models/member';
import { type Role } from '../models/roles';

const getTrackingPayloadFromMember = (member: MemberDetails) => ({
  userId: member.id,
  additionalRights: [
    ...(member.canConfirmPayments ? ['canConfirmPayments'] : []),
  ],
  roles: member.roles.map((role) => role.name),
});

const getTrackingPayloadFromDraftMember = (
  draftMember: DraftMember,
  roles: Role[],
) => {
  const memberRoles = draftMember.roleIds.reduce(
    (memberRoles_: Role[], roleId: string): Role[] => {
      const role = roles.find((r) => r.id === roleId);
      if (role) {
        memberRoles_.push(role);
      }
      return memberRoles_;
    },
    [],
  );

  return {
    additionalRights: [
      ...(draftMember.canConfirmPayments ? ['canConfirmPayments'] : []),
    ],
    roles: memberRoles.map((role) => role.name),
  };
};

export const onMemberDetailsModalOpened = ({
  member,
}: {
  member: MemberDetails;
}): void => {
  track(
    AnalyticEventName.MEMBER_DETAILS_MODAL_OPENED,
    getTrackingPayloadFromMember(
      member,
    ) as AnalyticEventPayloadDefinition[AnalyticEventName.MEMBER_DETAILS_MODAL_OPENED],
  );
};

export const onEditMemberDetailsModalOpened = ({
  member,
}: {
  member: MemberDetails;
}): void => {
  track(
    AnalyticEventName.MEMBER_EDIT_DETAILS_MODAL_OPENED,
    getTrackingPayloadFromMember(
      member,
    ) as AnalyticEventPayloadDefinition[AnalyticEventName.MEMBER_EDIT_DETAILS_MODAL_OPENED],
  );
};

export const onEditMemberDetailsModalClosed = (): void => {
  track(AnalyticEventName.MEMBER_EDIT_DETAILS_MODAL_CLOSED);
};

export const onEditMemberDetailsSaved = ({
  member,
  draftMember,
  roles,
}: {
  member: MemberDetails;
  draftMember: DraftMember;
  roles: Role[];
}): void => {
  const payload = {
    userId: member.id,
    valueBefore: getTrackingPayloadFromMember(member),
    valueAfter: getTrackingPayloadFromDraftMember(draftMember, roles),
  };
  track(AnalyticEventName.MEMBER_EDIT_DETAILS_SAVED, payload);
};

export const onEditMemberDetailsChangesApplied = ({
  member,
  draftMember,
  roles,
}: {
  member: MemberDetails;
  draftMember: DraftMember;
  roles: Role[];
}): void => {
  const payload = {
    userId: member.id,
    valueBefore: getTrackingPayloadFromMember(member),
    valueAfter: getTrackingPayloadFromDraftMember(draftMember, roles),
  };
  track(AnalyticEventName.MEMBER_EDIT_DETAILS_CHANGES_APPLIED, payload);
};
