import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { type User } from 'src/core/modules/app/hooks/useUser';

type WalletDetails = {
  monthlyBudget: number;
  subscription: {
    loaded: number;
    total: number;
  };
  physicalCards: {
    loaded: number;
    total: number;
  };
  singlePurchaseCards: {
    loadedPending: number;
    loadedRemaining: number;
  };
};

export const useWalletDetailsQuery = (
  user: User,
): QueryState<WalletDetails> => {
  return useQuery<WalletDetails>({
    isEnabled: user.is_admin || user.is_controller || user.is_account_owner,
    key: 'walletDetails',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/wallet-details',
    },
    reshapeData: (data) => data,
  });
};
