import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type IntegrationVatAccount } from '../../accounting';

type RawData = {
  taxAccount: IntegrationVatAccount | undefined;
};

export type Result = IntegrationVatAccount | undefined;

export const useGetDefaultTaxAccountQuery = (
  { isEnabled } = { isEnabled: true },
): QueryState<Result> => {
  const { company: companyId } = useParams();

  return useQuery<Result, RawData>({
    key: ['useGetDefaultTaxAccountQuery', companyId],
    isEnabled,
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint:
        '/accounting-integration/chart-of-accounts/tax-accounts/default',
    },
    reshapeData(data) {
      return data.taxAccount;
    },
  });
};
