import { useMemo } from 'react';

import { QueryError } from 'common/components/QueryError';
import { TaskList } from 'modules/onboarding/components/TaskList';
import { WelcomeBanner } from 'modules/onboarding/components/WelcomeBanner';
import { READY_TO_SPEND_TASKS } from 'modules/onboarding/setup-hub/components/SetupGuideNavigationItem/SetupGuideNavigationItem';
import { useGetOnboardingUserTasksQuery } from 'modules/onboarding/setup-hub/hooks/useGetOnboardingUserTasksQuery';
import type {
  UserTask,
  UserTaskType,
} from 'modules/onboarding/setup-hub/types/userTask';
import type {
  HubSection,
  SectionDefinition,
} from 'modules/onboarding/types/hubSection';
import {
  type HubTask,
  type HubTaskType,
} from 'modules/onboarding/types/hubTask';

import { useSetupHubTasksQuery } from './hooks/useSetupHubTasksQuery';
import { CONTROL_SETTINGS_TASKS } from './tasks/ControlSettingHubTaskItem/ControlSettingHubTaskItem';

const SECTIONS: SectionDefinition<HubTaskType | UserTaskType>[] = [
  {
    id: 'ready_to_spend',
    tasks: READY_TO_SPEND_TASKS,
    title: 'setupHub.sections.readyToSpend.title',
    isTransferable: false,
  },
  {
    id: 'control_settings',
    tasks: CONTROL_SETTINGS_TASKS,
    title: 'setupHub.sections.controlSettings.title',
    isTransferable: false,
  },
];

export const SetupHubPage = () => {
  const setupHubTasksQueryState = useSetupHubTasksQuery();
  const onboardingUserTasksQueryState = useGetOnboardingUserTasksQuery();

  const sections: HubSection<HubTask | UserTask>[] = useMemo(() => {
    const result = [];
    if (
      onboardingUserTasksQueryState.status === 'success' &&
      onboardingUserTasksQueryState.data.length
    ) {
      const tasks = READY_TO_SPEND_TASKS.map((taskType) =>
        onboardingUserTasksQueryState.data.find(
          (task) => task.type === taskType,
        ),
      ).filter((task) => task !== undefined);
      result.push({ ...SECTIONS[0], tasks });
    }
    if (
      setupHubTasksQueryState.status === 'success' &&
      setupHubTasksQueryState.data.length
    ) {
      result.push({ ...SECTIONS[1], tasks: setupHubTasksQueryState.data });
      // todo : when we have accounting, filter by category 'control_settings'
    }
    return result;
  }, [onboardingUserTasksQueryState, setupHubTasksQueryState]);

  if (setupHubTasksQueryState.status === 'error') {
    return (
      <QueryError
        componentType="ErrorState"
        queryError={setupHubTasksQueryState.error}
        translations={{
          networkError: 'misc.errors.networkError',
          serverError: 'misc.errors.serverError',
          requestError: () => 'misc.errors.networkError',
        }}
      />
    );
  }

  return (
    <div className="page__container h-full overflow-auto bg-page-background">
      <div className="mx-auto my-3xl flex w-[60%] max-w-[822px] flex-col gap-m px-s">
        <WelcomeBanner />
        <TaskList tasksBySection={sections} />
      </div>
    </div>
  );
};
