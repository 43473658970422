import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import type { HubTask } from 'modules/onboarding/types/hubTask';
import { useMutation } from 'src/core/api/hooks';

type Payload = {
  taskIds: string[];
  assigneeEmail: string;
};

export const useTransferOwnership = () => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  return useMutation<Payload, HubTask[], HubTask[]>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: 'onboarding-hub/transfer-tasks',
      method: 'post',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
      onSuccess: ({ client, rawData }) => {
        client.setQueryData<HubTask[]>(
          ['onboarding-hub', 'tasks', 'onboarding'],
          (tasks) => {
            if (!tasks) {
              return [];
            }
            return tasks.map((task) => {
              const updatedTask = rawData.find((data) => data.id === task.id);
              return updatedTask || task;
            });
          },
        );
      },
      onError: () => {
        pushNotif({
          type: NotificationType.Danger,
          message: t('misc.errors.unknownError'),
        });
      },
    },
  });
};
