import { FormField, TextInput } from '@dev-spendesk/grapes';
import React from 'react';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type DefaultSupplierAccount } from 'modules/bookkeep/settings/accounting/supplierAccount';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

type EditDefaultAccountFormProps = {
  editedAccount: DefaultSupplierAccount;
  setEditedAccount: (account: DefaultSupplierAccount) => void;
  error?: 'none' | 'codeAlreadyExists' | 'invalidPattern' | 'required';
};

function getErrorTranslation(
  error: 'none' | 'codeAlreadyExists' | 'invalidPattern' | 'required',
  t: TGlobalFunctionTyped,
): string | undefined {
  switch (error) {
    case 'required':
      return t(
        'bookkeep.integrations.settings.supplierAccountsTable.requiredError',
      );
    default:
      return undefined;
  }
}

export const EditDefaultAccountForm = (props: EditDefaultAccountFormProps) => {
  const { editedAccount, setEditedAccount, error } = props;
  const { t } = useTranslation('global');

  return (
    <FormField
      label={t(
        'bookkeep.integrations.settings.supplierAccountsTable.miscellaneousAccountName',
      )}
      htmlFor="set-account-input"
      alertMessage={error ? getErrorTranslation(error, t) : undefined}
    >
      <TextInput
        value={editedAccount.generalAccountCode}
        isInvalid={Boolean(error)}
        placeholder={t(
          'bookkeep.integrations.settings.supplierAccountsTable.miscellaneousAccountName',
        )}
        onChange={(e) =>
          setEditedAccount({
            ...editedAccount,
            generalAccountCode: e.target.value,
          })
        }
        id="set-account-input"
      />
    </FormField>
  );
};
