import { Banner, Button, Tag } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchFilter } from 'common/components/SearchFilter';
import { useFeature } from 'common/hooks/useFeature';
import { CreateControlRuleModalContainer } from 'modules/company/members/components/ControlRules';
import { BetaFeatureFeedbackModal } from 'src/core/common/components/BetaFeatureNotice/BetaFeatureNotice/BetaFeatureFeedbackPopover/BetaFeatureFeedbackModal';
import { useModal } from 'src/core/common/hooks/useModalGrapes';
import FEATURES from 'src/core/constants/features';

import { TableControlRulesContainer } from './TableControlRules/TableControlRulesContainer';
import Illustration from './assets/cop.svg';

import './MembersControlRulesTab.css';

type Props = {
  controlRulesCount: number;
  searchPattern: string;
  setSearchPattern(argument: string): void;
};

export const MembersControlRulesTab = ({
  controlRulesCount,
  searchPattern,
  setSearchPattern,
}: Props) => {
  const { t } = useTranslation();
  const hasPlayByRulesFeature = useFeature(FEATURES.PLAY_BY_RULES);
  const hasPlayByTheRulesReceiptAndFieldsFeature = useFeature(
    FEATURES.PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS,
  );
  const hasPlayByRulesMultipleFeature = useFeature(
    FEATURES.PLAY_BY_RULES_MULTIPLE,
  );
  const [showCreateModal, setShowCreateModal] = useState(false);
  const canCreateControlRule =
    hasPlayByRulesFeature &&
    (hasPlayByRulesMultipleFeature || controlRulesCount === 0);

  const [feedbackModal, showFeedbackModal, hideFeedbackModal] = useModal(
    ({ isOpen }) => {
      return (
        <BetaFeatureFeedbackModal
          isOpen={isOpen}
          onCancel={() => {
            hideFeedbackModal();
          }}
          onConfirm={() => {
            hideFeedbackModal();
          }}
          feedbackTags={['pbrv2']}
        />
      );
    },
  );

  if (!hasPlayByRulesFeature) {
    return null;
  }

  return (
    <div className="MembersControlRulesTab">
      {feedbackModal}
      <div className="MembersControlRulesTab__table">
        <div className="MembersControlRulesTab__top">
          <div className="MembersControlRulesTab__top__description">
            <div className="MembersControlRulesTab__top__description__title title-xl">
              {t('controlRules.create.title')}
            </div>
            <span className="MembersControlRulesTab__top__description__count body-m">
              {t('members.rulesCount', { count: controlRulesCount })}
            </span>
          </div>

          {canCreateControlRule && (
            <>
              <SearchFilter
                className="mr-s"
                minLength={0}
                search={searchPattern}
                setSearch={(search) => setSearchPattern(search)}
                placeholder={t('controlRules.searchPlaceholder')}
              />
              <Button
                text={t('controlRules.create.newRule')}
                onClick={() => setShowCreateModal(true)}
              />
            </>
          )}
        </div>
        {hasPlayByTheRulesReceiptAndFieldsFeature && (
          <Banner
            variant="info"
            title={
              <div className="flex items-center gap-xs">
                {t('controlRules.mandatoryFields.marketingBanner.title')}
                <Tag variant="info" className="uppercase">
                  {t('misc.new')}
                </Tag>
              </div>
            }
            actionText={t(
              'controlRules.mandatoryFields.marketingBanner.button',
            )}
            onClick={() => {
              showFeedbackModal();
            }}
            illustration={<img src={Illustration} alt="" />}
            className="mb-m"
          >
            <div className="text-neutral-darker">
              {t('controlRules.mandatoryFields.marketingBanner.description')}{' '}
              <b>
                {t(
                  'controlRules.mandatoryFields.marketingBanner.descriptionBold',
                )}
              </b>
            </div>
          </Banner>
        )}
        <TableControlRulesContainer searchPattern={searchPattern} />
        {showCreateModal && (
          <CreateControlRuleModalContainer
            isOpen={showCreateModal}
            onSuccess={() => {
              setShowCreateModal(false);
            }}
            onCancel={() => {
              setShowCreateModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
