import { type CurrenciesKey } from 'src/core/config/money';

import { type ApprovalRule } from '../structure/approval-flows';

export type CostCenter = {
  id: string;
  name: string;
  ownerId: string;
  viewerIds: string[];
  approvalSchemeId: string;
  approvalRules: ApprovalRule[];
};

export type CostCenterWithBudgets = CostCenter & { hasBudgets: boolean };

export type Budget = {
  id: string;
  costCenterId: string;
};

export const createInitialApprovalRule = (
  companyCurrency: CurrenciesKey,
): ApprovalRule[] => {
  return [
    {
      id: '1',
      from: { value: null, currency: companyCurrency },
      upTo: { value: null, currency: companyCurrency },
      steps: [
        {
          rights: [
            {
              approverType: 'costCenterOwner',
            },
          ],
        },
      ],
    },
  ];
};
