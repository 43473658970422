import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { type Invoice, type InvoiceStatus } from '../../../../models';

const INVOICES_LIMIT = 10;

type RawInvoicesResponse = {
  data: {
    created_at: string;
    id: string;
    number: string;
    payment_invoice: {
      url: string;
    };
    status: InvoiceStatus;
    total: number;
  }[];
  total: number;
  offset: number;
};

export const useGetInvoicesQuery = (): InfiniteQueryState<Invoice[]> => {
  const companyId = useCompanyId();

  return useInfiniteQuery<Invoice, RawInvoicesResponse>({
    key: ['invoices', companyId],
    getRequest: (cursor) => ({
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/billing/invoices/paginated',
      params: {
        offset: Number(cursor) || undefined,
        limit: INVOICES_LIMIT,
      },
    }),
    getNextPageParam(data) {
      if (data.offset >= data.total) {
        return undefined;
      }

      return `${data.offset}`;
    },
    reshapeData: (rawInvoices) =>
      rawInvoices.data.map((rawInvoice) => ({
        createdAt: rawInvoice.created_at,
        id: rawInvoice.id,
        number: rawInvoice.number,
        paymentInvoice: rawInvoice.payment_invoice,
        status: rawInvoice.status,
        total: rawInvoice.total,
      })),
  });
};
