import { useMemo } from 'react';

import { type QueryError } from './queryError';

export type QueryIdleState = {
  status: 'idle';
};

export type QueryLoadingState = {
  status: 'loading';
};

export type QuerySuccessState<TResult> = {
  status: 'success';
  data: TResult;
};

export type QueryErrorState<TError> = {
  status: 'error';
  error: QueryError<TError>;
};

export type QueryState<TResult = void, TError = unknown> =
  | QueryLoadingState
  | QuerySuccessState<TResult>
  | QueryErrorState<TError>;

export type LazyQueryState<TResult = void, TError = unknown> =
  | QueryIdleState
  | QueryState<TResult, TError>;

export type LazyState<TResult = void, TError = unknown> = [
  () => Promise<TResult | undefined>,
  LazyQueryState<TResult, TError>,
];

export type MutationQueryState<TResult = void, TError = unknown> =
  | QueryState<TResult, TError>
  | QueryIdleState;

export type InfiniteQueryState<TResult> = QueryState<TResult> & {
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage(): Promise<void>;
};

export function isQueryLoadingState(
  state: QueryState<unknown>,
): state is QueryLoadingState {
  return state.status === 'loading';
}

export function isQuerySuccessState<TResult>(
  state: QueryState<TResult>,
): state is QuerySuccessState<TResult> {
  return state.status === 'success';
}

export function isQueryErrorState<TError>(
  state: QueryState<TError>,
): state is QueryErrorState<TError> {
  return state.status === 'error';
}

export function reshapeQueryState<TRawResult, TResult>(
  state: QueryState<TRawResult>,
  reshapeData: (data: TRawResult) => TResult,
): QueryState<TResult> {
  return isQuerySuccessState(state)
    ? { status: 'success', data: reshapeData(state.data) }
    : state;
}

export const useMemoizedReshapeQueryState = <TRawResult, TResult>(
  state: QueryState<TRawResult>,
  reshapeData: (data: TRawResult) => TResult,
) => useMemo(() => reshapeQueryState(state, reshapeData), [state, reshapeData]);
