import {
  type ComponentDecorator,
  withFormik,
  type FormikProps,
  type FormikErrors,
} from 'formik';

import {
  type ParsedCheckBankStatementFormValues,
  type CheckBankStatementFormValues,
} from '../../types';

type Props = {
  showLinkLater?: boolean;
  onLinkLater?: () => void;
  onUpdateBankInfo?: () => void;
  currency: string;
  hasError: boolean;
  isPendingAchSourceLate: boolean;
  checkBankStatement: (
    values: ParsedCheckBankStatementFormValues,
  ) => Promise<void>;
  getAchFundingSources: () => void;
};

export const withCheckBankStatementForm = (): ComponentDecorator<
  Props,
  Props & FormikProps<CheckBankStatementFormValues>
> =>
  withFormik<Props, CheckBankStatementFormValues>({
    mapPropsToValues: () => ({
      firstAmount: '',
      secondAmount: '',
    }),
    validate: (values) => {
      const errors: FormikErrors<CheckBankStatementFormValues> = {};

      if (!Number.parseFloat(values.firstAmount)) {
        errors.firstAmount = 'firstAmount';
      }

      if (!Number.parseFloat(values.secondAmount)) {
        errors.secondAmount = 'secondAmount';
      }

      return errors;
    },
    handleSubmit: async (
      values,
      { setSubmitting, props: { checkBankStatement, getAchFundingSources } },
    ) => {
      setSubmitting(true);
      await checkBankStatement({
        firstAmount: Number.parseFloat(values.firstAmount),
        secondAmount: Number.parseFloat(values.secondAmount),
      });
      await getAchFundingSources();
      setSubmitting(false);
    },
  });
