import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import {
  type BudgetaryExercise,
  type RawBudgetaryExercise,
  reshapeRawBudgetaryExercise,
} from '../../models/budgetaryExercise';

export const USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE =
  'userNotAllowedToAccessBudget';

type RawBudgetQueryError = {
  errorCode: typeof USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE;
};

export const useBudgetaryExerciseQuery = (
  id: string | undefined,
): QueryState<BudgetaryExercise | undefined, RawBudgetQueryError> => {
  return useQuery<
    BudgetaryExercise | undefined,
    RawBudgetaryExercise | undefined,
    RawBudgetQueryError
  >({
    key: ['budgetaryExercises', id],
    isEnabled: Boolean(id),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/budgets/budgetary-exercises/${id}`,
    },
    reshapeError: (queryError) => {
      return {
        ...queryError,
        data: { errorCode: USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE },
      };
    },
    reshapeData: (data) => {
      if (!data) {
        return undefined;
      }
      return reshapeRawBudgetaryExercise(data);
    },
  });
};
