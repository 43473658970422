import React from 'react';

import { FormAutocomplete } from './FormAutocomplete';
import type { Form } from './types';
import { type Option } from '../../components/AutocompleteSearch/option';
import { useSearch } from '../../components/AutocompleteSearch/useSearch';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export type Props<TForm extends Form<any>, T extends Option> = Omit<
  Parameters<typeof FormAutocomplete>[0],
  'onSearch' | 'value'
> & { hint?: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FormAutocompleteSearch<TForm extends Form<any>>({
  options,
  ...props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: Props<TForm extends Form<any> ? TForm : never, Option>) {
  const { filteredOptions, handleSearch } = useSearch(options);

  return (
    <FormAutocomplete
      options={filteredOptions}
      onSearch={handleSearch}
      {...props}
    />
  );
}
