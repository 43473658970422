import React, { useEffect } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { TopNotif } from 'modules/app/components/TopNotif/TopNotif';

import styles from './HeaderLoadNotif.module.css';

export const HeaderLoadNotif = () => {
  const { t } = useTranslation('global');

  useEffect(() => {
    const reactSpxElement = document.getElementById('react__spx');
    if (reactSpxElement) {
      reactSpxElement.classList.add('has-flash-message');
    }
  }, []);

  return (
    <div className={styles.headerLoadNotif}>
      <TopNotif type="pending">{t('headerLoadNotif.inReview')}</TopNotif>
    </div>
  );
};
