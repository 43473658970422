/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ControlRule } from '../controlRule';

export const FETCH_CONTROL_RULES_REQUEST =
  'control-rules/FETCH_CONTROL_RULES_REQUEST';
export const FETCH_CONTROL_RULES_SUCCESS =
  'control-rules/FETCH_CONTROL_RULES_SUCCESS';
export const FETCH_CONTROL_RULES_FAILURE =
  'control-rules/FETCH_CONTROL_RULES_FAILURE';

export const CREATE_CONTROL_RULE_REQUEST =
  'control-rules/CREATE_CONTROL_RULE_REQUEST';
export const CREATE_CONTROL_RULE_SUCCESS =
  'control-rules/CREATE_CONTROL_RULE_SUCCESS';
export const CREATE_CONTROL_RULE_FAILURE =
  'control-rules/CREATE_CONTROL_RULE_FAILURE';

export const REMOVE_CONTROL_RULE_SUCCESS =
  'control-rules/REMOVE_CONTROL_RULE_SUCCESS';
export const REMOVE_CONTROL_RULE_REQUEST =
  'control-rules/REMOVE_CONTROL_RULE_REQUEST';
export const UPDATE_CONTROL_RULE_REQUEST =
  'control-rules/UPDATE_CONTROL_RULE_REQUEST';

export const UPDATE_CONTROL_RULE_SUCCESS =
  'control-rules/UPDATE_CONTROL_RULE_SUCCESS';

export const SET_SELECTED_CONTROL_RULE =
  'control-rules/SET_SELECTED_CONTROL_RULE';

export const RESET_SELECTED_CONTROL_RULE =
  'control-rules/RESET_SELECTED_CONTROL_RULE';

export const fetchControlRulesRequest = (): FetchControlRulesRequest => ({
  type: FETCH_CONTROL_RULES_REQUEST,
});

export const fetchControlRulesSuccess = (
  controlRules: ControlRule[],
): FetchControlRulesSuccess => ({
  type: FETCH_CONTROL_RULES_SUCCESS,
  payload: controlRules,
});

export const fetchControlRulesFailure = (
  error: any,
): FetchControlRulesFailure => ({
  type: FETCH_CONTROL_RULES_FAILURE,
  payload: error,
});

export const createControlRuleRequest = (): CreateControlRuleRequest => ({
  type: CREATE_CONTROL_RULE_REQUEST,
});

export const createControlRuleSuccess = (
  controlRuleCreated: ControlRule,
  previousControlRule?: ControlRule,
  userId?: string,
): CreateControlRuleSuccess => ({
  type: CREATE_CONTROL_RULE_SUCCESS,
  payload: { controlRuleCreated, previousControlRule, userId },
});

export const createControlRuleFailure = (
  error: any,
): CreateControlRuleFailure => ({
  type: CREATE_CONTROL_RULE_FAILURE,
  payload: error,
});

export const removeControlRuleSuccess = (
  id: string,
): RemoveControlRuleSuccess => ({
  type: REMOVE_CONTROL_RULE_SUCCESS,
  payload: id,
});

export const removeControlRuleRequest = (): RemoveControlRuleRequest => ({
  type: REMOVE_CONTROL_RULE_REQUEST,
});

export const updateControlRuleRequest = (): UpdateControlRuleRequest => ({
  type: UPDATE_CONTROL_RULE_REQUEST,
});

export const updateControlRuleSuccess = (
  controlRuleUpdated: ControlRule,
  previousControlRule?: ControlRule,
  userId?: string,
): UpdateControlRuleSuccess => ({
  type: UPDATE_CONTROL_RULE_SUCCESS,
  payload: { controlRuleUpdated, previousControlRule, userId },
});

export const setSelectedControlRule = (id: string): SetSelectedControlRule => ({
  type: SET_SELECTED_CONTROL_RULE,
  payload: id,
});

export const resetSelectedControlRule = (): ResetSelectedControlRule => ({
  type: RESET_SELECTED_CONTROL_RULE,
});

interface FetchControlRulesRequest {
  readonly type: 'control-rules/FETCH_CONTROL_RULES_REQUEST';
}

export interface FetchControlRulesSuccess {
  readonly type: 'control-rules/FETCH_CONTROL_RULES_SUCCESS';
  readonly payload: ControlRule[];
}

export interface FetchControlRulesFailure {
  readonly type: 'control-rules/FETCH_CONTROL_RULES_FAILURE';
  readonly payload: any;
}

interface CreateControlRuleRequest {
  readonly type: 'control-rules/CREATE_CONTROL_RULE_REQUEST';
}

export interface CreateControlRuleSuccess {
  readonly type: 'control-rules/CREATE_CONTROL_RULE_SUCCESS';
  readonly payload: {
    controlRuleCreated: ControlRule;
    previousControlRule?: ControlRule;
    userId?: string;
  };
}

export interface CreateControlRuleFailure {
  readonly type: 'control-rules/CREATE_CONTROL_RULE_FAILURE';
  readonly payload: any;
}

export interface RemoveControlRuleSuccess {
  readonly type: 'control-rules/REMOVE_CONTROL_RULE_SUCCESS';
  readonly payload: string;
}

interface RemoveControlRuleRequest {
  readonly type: 'control-rules/REMOVE_CONTROL_RULE_REQUEST';
}

interface UpdateControlRuleRequest {
  readonly type: 'control-rules/UPDATE_CONTROL_RULE_REQUEST';
}

export interface UpdateControlRuleSuccess {
  readonly type: 'control-rules/UPDATE_CONTROL_RULE_SUCCESS';
  readonly payload: {
    controlRuleUpdated: ControlRule;
    previousControlRule?: ControlRule;
    userId?: string;
  };
}

export interface SetSelectedControlRule {
  readonly type: 'control-rules/SET_SELECTED_CONTROL_RULE';
  readonly payload: string;
}

interface ResetSelectedControlRule {
  readonly type: 'control-rules/RESET_SELECTED_CONTROL_RULE';
}

export type ControlRuleAction =
  | FetchControlRulesRequest
  | FetchControlRulesSuccess
  | FetchControlRulesFailure
  | CreateControlRuleRequest
  | CreateControlRuleSuccess
  | CreateControlRuleFailure
  | RemoveControlRuleSuccess
  | RemoveControlRuleRequest
  | UpdateControlRuleRequest
  | UpdateControlRuleSuccess
  | SetSelectedControlRule
  | ResetSelectedControlRule;
