import { createAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { UPDATE_FEATURE_SET } from 'modules/app/layout/redux/actionTypes';
import type FEATURES from 'src/core/constants/features';

export const useDisableFeature = () => {
  const dispatch = useDispatch();

  return (feature: FEATURES): void => {
    dispatch(
      createAction<Record<string, boolean>>(UPDATE_FEATURE_SET)({
        [feature]: false,
      }),
    );
  };
};
