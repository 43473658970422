import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import appConfig from 'src/core/config';
import { postRedirect } from 'src/core/utils/postRedirect';

export const useBuildScopedConsentUrlMutation = (): [
  (tenantExternalId: string) => void,
] => {
  const companyId = useCompanyId();
  return [
    (tenantExternalId: string): void => {
      postRedirect(
        `${appConfig.apiUrls.api}/${companyId}/accounting-integration/oauth2/redirect-to-scoped-consent-url?tenantExternalId=${tenantExternalId}`,
      );
    },
  ];
};
