import { compose } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { routes, routeFor } from 'src/core/constants/routes';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { getSelf } from 'src/core/selectors/users';

import { MarqetaSetPin } from './PlasticCard/MarqetaSetPin';

import './MyCardSetPinContainer.css';

type Props = {
  companyId: string;
  user: {
    has_plastic_card: boolean;
    data_by_company: {
      [companyId: string]: {
        plastic_card: {
          id: string;
          banking_provider: 'bankable' | 'marqeta';
        };
      };
    };
  };
};

const Container = ({ companyId, user }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const card = user.data_by_company[companyId]?.plastic_card;
  const { callbackUrl } = queryString.parse(location.search);

  // for now, we only manage set PIN for Marqeta cards
  if (!card || card.banking_provider !== 'marqeta') {
    return;
  }

  const handleRedirect = () => {
    if (callbackUrl && typeof callbackUrl === 'string') {
      window.location.href = callbackUrl;
    } else {
      history.push(
        routeFor(routes.CARD.path, {
          company: companyId,
        }),
      );
    }
  };

  return (
    <div className="MyCardSetPinContainer">
      <MarqetaSetPin
        cardId={card.id}
        companyId={companyId}
        onFinish={handleRedirect}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: getSelf(state),
  companyId: getCompanyId(state),
});

export const MyCardSetPinContainer = compose<
  (p: Omit<Props, 'user' | 'companyId'>) => JSX.Element
>(
  connect(mapStateToProps),
  withErrorBoundary({
    scope: 'my-card-set-pin',
    team: 'capture',
  }),
)(Container);
