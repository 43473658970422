import { Button, Label } from '@dev-spendesk/grapes';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import './HomepageFirstFundingInProgress.css';

type Props = {
  company: {
    id: string;
  };
};

export const HomepageFirstFundingInProgress = ({ company }: Props) => {
  const { t } = useTranslation('global');
  return (
    <div className="HomepageFirstFundingInProgress">
      <Label
        className="HomepageFirstFundingInProgress__title"
        label={t('homepage.firstFundingInProgress.title')}
      />
      <p className="HomepageFirstFundingInProgress__description">
        {t('homepage.firstFundingInProgress.description')}
      </p>
      <Link
        to={routeFor(routes.COMPANY_BANK_FUNDING_SOURCE.path, {
          company: company.id,
        })}
      >
        <Button
          className="HomepageFirstFundingInProgress__button"
          iconName="clock"
          iconPosition="left"
          isDisabled
          text={t('homepage.firstFundingInProgress.cta')}
          variant="contrasted"
        />
      </Link>
    </div>
  );
};
