import { FormField, Input } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type BankInfosErrors, type SupplierBankInfos } from '../../../models';
import { mask, unmask, type Mask } from '../helper';

const SORT_CODE_MASK: Mask = {
  blocks: [2, 2, 2],
  delimiter: '-',
};

type Props = {
  className?: string;
  isOptional?: boolean;
  sortCode: SupplierBankInfos['sortCode'];
  error?: BankInfosErrors['sortCode'];
  onChange(sortCode: SupplierBankInfos['sortCode']): void;
};

export const SortCodeFormField = ({
  className,
  isOptional,
  sortCode,
  error,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const getMessageFromError = (
    fieldError: BankInfosErrors['sortCode'],
  ): string => {
    switch (fieldError) {
      case 'invalid': {
        return t('submitMyInvoice.fields.sortCode.error');
      }
      default:
        return t('misc.requiredField');
    }
  };

  return (
    <FormField
      className={className}
      label={t('submitMyInvoice.fields.sortCode.label')}
      alertMessage={error ? getMessageFromError(error) : undefined}
      hint={isOptional ? t('misc.optional') : undefined}
    >
      <Input
        name="sortCode"
        value={mask(sortCode ?? '', SORT_CODE_MASK)}
        placeholder={t('submitMyInvoice.fields.sortCode.placeholder')}
        onChange={(event) =>
          onChange(unmask(event.target.value, SORT_CODE_MASK))
        }
      />
    </FormField>
  );
};
