import { ModalContent, ModalOverlay } from '@dev-spendesk/grapes';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { connect } from 'react-redux';

import * as usersActions from 'src/core/actions/users';
import { getOnboardingUrl } from 'src/core/utils/onboarding';

import styles from './OnboardingModal.module.css';

type Props = {
  companyId: string;
  bankingProvider: 'bankable' | 'marqeta' | 'sfs' | null;
  refreshUserCompanies: () => void;
  onClose: () => void;
};
const OnboardingModal = ({
  companyId,
  bankingProvider,
  refreshUserCompanies,
  onClose,
}: Props) => {
  const queryClient = useQueryClient();

  const onboardingUrl = getOnboardingUrl({
    pathname: companyId,
    bankingProvider: bankingProvider,
  });

  const onWindowMessage = async (event: MessageEvent) => {
    if (
      event.origin === window.location.origin &&
      event.data === 'onboarding:close'
    ) {
      await Promise.all([
        refreshUserCompanies(),
        queryClient.invalidateQueries('companies'),
        queryClient.invalidateQueries('companyWalletSummaries'),
      ]);
      onClose();
      return;
    }

    if (
      event.origin === window.location.origin &&
      event.data === 'onboarding:redirectToCompany'
    ) {
      window.location.href = `/app/${companyId}`;
      return;
    }

    if (
      event.origin === window.location.origin &&
      event.data === 'onboarding:redirectToLogin'
    ) {
      window.location.href = '/auth/login';
    }
  };

  useEffect(() => {
    window.addEventListener('message', onWindowMessage);

    return () => {
      window.removeEventListener('message', onWindowMessage);
    };
  }, []);

  return (
    <ModalOverlay isOpen>
      <ModalContent className={styles.modalContent}>
        {companyId ? (
          <iframe
            className={styles.iframe}
            title="Spendesk - Onboarding"
            src={onboardingUrl}
          />
        ) : null}
      </ModalContent>
    </ModalOverlay>
  );
};

const mapDispatchToProps = {
  refreshUserCompanies: usersActions.refreshUserCompanies,
};

export default connect(null, mapDispatchToProps)(OnboardingModal);
