import { type AppState } from "src/core/reducers";

import { type ApprovalRule } from '../types';

export const getRulesByTeamId = (
  state: AppState,
  teamId: string | null,
): ApprovalRule[] => {
  if (!teamId) {
    return [];
  }
  const rules = state.approval.byTeamId[teamId];
  if (!rules) {
    return [];
  }
  return rules;
};

export const getIsFetching = (state: AppState): boolean => {
  return state.approval.isFetching;
};
