import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUpdateGetPayableQueryCache } from 'modules/bookkeep/apis/prepare-payable/useGetPayableQuery';
import { type PayableId } from 'modules/payable/models';
import { useMutation } from 'src/core/api/hooks/useMutation';

import { useInvalidateBookkeepingNavbarCounters } from '../../hooks/useInvalidateBookkeepNavbarCounters';
import { useUpdatePayablesQueryCache } from '../../prepare-payables/components/PreparePayablesBuckets/hooks/usePayablesQuery/usePayablesQuery';

type UndoMarkPayablesAsPreparedError = {
  outcome: 'notUndidMarkPayablesAsPrepared';
} & (
  | {
      reason: 'expectationFailure';
      error: Error;
    }
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason:
        | 'unauthorized'
        | 'notFound'
        | 'invalidState'
        | 'wrongVersion'
        | 'updateInProgress'
        | 'nothingToUpdate';
    }
);

export type UndoMarkPayablesAsPreparedResponse = {
  outcome: 'undidMarkPayablesAsPrepared';
};

export type UndoMarkPayablesAsPreparedPayload = {
  payableId: PayableId;
  payableVersion: number;
}[];

/**
 * Mutation
 **/

export const useUndoMarkPayablesAsPrepared = () => {
  const companyId = useCompanyId();
  const updateGetPayableQueryCache = useUpdateGetPayableQueryCache();
  const updatePayablesQueryCache = useUpdatePayablesQueryCache();
  const invalidateBookkeepingNavbarCounters =
    useInvalidateBookkeepingNavbarCounters();

  return useMutation<
    UndoMarkPayablesAsPreparedPayload,
    UndoMarkPayablesAsPreparedResponse,
    UndoMarkPayablesAsPreparedResponse,
    UndoMarkPayablesAsPreparedError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/bookkeeping/payable/undo-mark-as-prepared-all`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ data, payload }) => {
        if (data.outcome === 'undidMarkPayablesAsPrepared') {
          for (const payable of payload) {
            const update = {
              version: payable.payableVersion + 1,
              state: 'unprepared' as const,
            };

            updateGetPayableQueryCache(companyId, payable.payableId, update);
            updatePayablesQueryCache(companyId, payable.payableId, update);
          }

          invalidateBookkeepingNavbarCounters({
            action: 'INCREMENT_PAYABLES_TO_PREPARE_COUNT',
            value: payload.length,
          });
        }
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};
