export const ADD_CARD_TO_ORDER = 'ADD_CARD_TO_ORDER';
export const REMOVE_CARD_TO_ORDER = 'REMOVE_CARD_TO_ORDER';

export const CHANGE_DELIVERY_ADDRESS_FOR_ORDER =
  'CHANGE_DELIVERY_ADDRESS_FOR_ORDER';
export const CHANGE_DELIVERY_METHOD_FOR_ORDER =
  'CHANGE_DELIVERY_METHOD_FOR_ORDER';

export const ORDER_CARDS_LOADING = 'ORDER_CARDS_LOADING';
export const ORDER_CARDS_FAILURE = 'ORDER_CARDS_FAILURE';
export const ORDER_CARDS_SUCCESS = 'ORDER_CARDS_SUCCESS';

export const CLEAR_CURRENT_ORDER = 'CLEAR_CURRENT_ORDER';

export const FETCH_EXPRESS_DELIVERY_PRICINGS_LOADING =
  'FETCH_EXPRESS_DELIVERY_PRICINGS_LOADING';
export const FETCH_EXPRESS_DELIVERY_PRICINGS_FAILURE =
  'FETCH_EXPRESS_DELIVERY_PRICINGS_FAILURE';
export const FETCH_EXPRESS_DELIVERY_PRICINGS_SUCCESS =
  'FETCH_EXPRESS_DELIVERY_PRICINGS_SUCCESS';
