import { baseAPI } from 'src/core/api/axios';
import { POLL_STRATEGIES, poll } from 'src/core/utils/async';
import { logger } from 'src/utils/datadog-log-wrapper';

export const useWaitForCompanyAppFirstAccess = () => {
  return async (companyId: string) => {
    try {
      const lastResult = await poll({
        fn: baseAPI.get,
        fnParams: [`/${companyId}/app-first-access`],
        predicate: (result?: { data: { isAccessOpened: boolean } }) => {
          return result?.data.isAccessOpened ?? false;
        },
        initialWaitDuration: 1000,
        timeStrategy: POLL_STRATEGIES.LINEAR,
        maxIterations: 30, // 30 seconds
      });

      if (lastResult?.data.isAccessOpened) {
        await baseAPI.post(`/${companyId}/app-first-access/access-happened`);
        return true;
      }
    } catch (error) {
      logger.error(
        'app-first-access polling ended without having access opened',
        {
          team: 'kyc',
          scope: 'new-branch',
          error: error,
        },
      );
    }
    return false;
  };
};
