import { Button, Tooltip } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { useNotifications } from 'modules/app/notifications';

import { type ApiCard } from '../../card';
import { type CardsAccess } from '../../hooks/useCardAccessQuery';
import { doCardAction, getCardBlockingAction } from '../../utils/card';

type Props = {
  card: ApiCard;
  cardsAccess: CardsAccess | undefined;
  isCardBlocked: boolean;
  isCardBeingBlockedOrUnblocked: boolean;
  setIsCardBeingBlockedOrUnblocked: (
    isCardBeingBlockedOrUnblocked: boolean,
  ) => void;
  onProcessed: (updatedCard?: ApiCard) => void;
};

export const BlockUnblock = ({
  card,
  cardsAccess,
  isCardBlocked,
  isCardBeingBlockedOrUnblocked,
  setIsCardBeingBlockedOrUnblocked,
  onProcessed,
}: Props) => {
  const { t } = useTranslation();
  const { successNotif, dangerNotif } = useNotifications();

  const company = useCompany();
  const user = useUser();

  const ownCardHasBeenBlockedBySomeoneElse =
    isCardBlocked && card.user_id === user.id && card.blocker_id !== user.id;

  const isCardsAccessManuallyRestricted =
    cardsAccess?.hasAccess === false && cardsAccess.initiator === 'user';

  const isNonActivatedSfsCard =
    card.banking_provider === 'sfs' && card.status === 'PRE';

  const isButtonDisabled =
    isCardsAccessManuallyRestricted ||
    isCardBeingBlockedOrUnblocked ||
    ownCardHasBeenBlockedBySomeoneElse ||
    isNonActivatedSfsCard;

  let blockUnblockCtaText;
  if (isCardBeingBlockedOrUnblocked) {
    blockUnblockCtaText = isCardBlocked
      ? t('cards.loadingUnBlocking')
      : t('cards.loadingBlocking');
  } else {
    blockUnblockCtaText = isCardBlocked
      ? t('cards.actions.unblock')
      : t('cards.actions.block');
  }

  const blockOrUnblockCard = () => {
    if (!isCardBeingBlockedOrUnblocked) {
      setIsCardBeingBlockedOrUnblocked(true);
      const actionType = getCardBlockingAction(card);

      doCardAction(
        company.id,
        card.id,
        actionType,
        null,
        (error, updatedCard) => {
          if (!error && updatedCard) {
            const message =
              updatedCard.status === 'BLO'
                ? t('cards.actions.successBlock')
                : t('cards.actions.successUnblock');
            successNotif(message);

            setIsCardBeingBlockedOrUnblocked(false);
            onProcessed(updatedCard);
          } else if (error) {
            const message = error.registered
              ? `${t([
                  `errors:registry.${error.name}`,
                  'errors:somethingWrong',
                ])} (${t('errors:code')} ${error.name})`
              : t('errors:somethingWrong');
            dangerNotif(message);

            setIsCardBeingBlockedOrUnblocked(false);
            onProcessed();
          }
        },
      );
    }
  };

  return (
    <Tooltip
      content={
        card.blocker?.fullname
          ? t('cards.disabledActionBlocked3rdPartyWithName', {
              name: card.blocker.fullname,
            })
          : t('cards.disabledActionBlockedCardTip')
      }
      isDisabled={!ownCardHasBeenBlockedBySomeoneElse}
    >
      <Button
        fit="parent"
        iconName={isCardBlocked ? 'unlock' : 'lock'}
        text={blockUnblockCtaText}
        variant={isCardBlocked ? 'primary' : 'secondary'}
        isDisabled={isButtonDisabled}
        isLoading={isCardBeingBlockedOrUnblocked}
        onClick={blockOrUnblockCard}
      />
    </Tooltip>
  );
};
