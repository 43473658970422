import { useExpenseWireTransferActivationStatusQuery } from 'modules/company/general-settings';
import { type QueryState } from 'src/core/api/queryState';

export const useCanActivateExpenseWireTransferQuery =
  (): QueryState<boolean> => {
    const wireTransferActivationStatusQueryState =
      useExpenseWireTransferActivationStatusQuery();

    if (wireTransferActivationStatusQueryState.status === 'success') {
      return {
        ...wireTransferActivationStatusQueryState,
        data:
          wireTransferActivationStatusQueryState.data.toggle.canToggle &&
          wireTransferActivationStatusQueryState.data.isActivated === false,
      };
    }

    return wireTransferActivationStatusQueryState;
  };
