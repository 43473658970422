import { useQueryClient } from 'react-query';

import { type Translations } from 'common/components/QueryError';
import { useQuery } from 'src/core/api/hooks/useQuery';

import { type RawSupplier, transformSupplier } from './supplierTransformer';
import { type SupplierDetails, type SupplierId } from '../../../models';

export type SupplierData = {
  rawSupplier: RawSupplier;
  supplierDetails: SupplierDetails;
};

type Options = {
  isEnabled?: boolean;
};

export const useFetchSupplier = (
  supplierId: SupplierId | undefined,
  { isEnabled = true }: Options = {},
) => {
  return useQuery<SupplierData, RawSupplier>({
    key: ['suppliers', supplierId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/suppliers/${supplierId}`,
    },
    isEnabled: Boolean(supplierId) && isEnabled,
    reshapeData: reshaper,
  });
};

const reshaper = (rawSupplier: RawSupplier): SupplierData => ({
  rawSupplier,
  supplierDetails: transformSupplier(rawSupplier),
});

export const useInvalidateSupplierQuery = () => {
  const queryClient = useQueryClient();

  return (supplierId: SupplierId): void => {
    queryClient.invalidateQueries(['suppliers', supplierId]);
  };
};

export const useSetSupplierQueryData = () => {
  const queryClient = useQueryClient();

  return (supplier: RawSupplier): void => {
    queryClient.setQueryData(['suppliers', supplier.id], supplier);
  };
};

export const supplierErrorTranslations: Translations<unknown> = {
  requestError: () => {
    return 'suppliers.fetchError';
  },
  serverError: 'suppliers.fetchError',
};
