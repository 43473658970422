import { type QueryState } from 'src/core/api/queryState';

import { type SupplierDetailsChange } from '../../../models';
import { useFetchSupplierDetailsHistory } from '../useFetchSupplierDetailsHistory';

export const useFetchSupplierBankInfosHistory = (
  supplierId: string,
): QueryState<SupplierDetailsChange[]> => {
  return useFetchSupplierDetailsHistory(supplierId, [
    'bankCountry',
    'iban',
    'bic',
    'sortCode',
    'accountNumber',
    'accountCode',
    'routingNumber',
    'accountHolderName',
  ]);
};
