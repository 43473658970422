import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { useHasAccountingIntegrationCapability } from '../../accounting-integration/apis';

export type SupplierSyncStatus =
  | {
      synced: false;
    }
  | {
      synced: true;
      url: string | null;
    };

export const useSupplierSyncStatus = (
  supplierId: string | undefined,
): QueryState<SupplierSyncStatus> => {
  const { company: companyId } = useParams();
  const hasSupplierSyncCapability =
    useHasAccountingIntegrationCapability('supplierSync');

  return useQuery<SupplierSyncStatus>({
    key: [
      'suppliers',
      'vendors',
      'useSuppliersSyncStatus',
      companyId,
      supplierId,
    ],
    isEnabled: hasSupplierSyncCapability && Boolean(supplierId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/suppliers/${supplierId}/sync-status`,
    },
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
  });
};
