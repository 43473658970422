import {
  defaultNotifications,
  type Notification,
  type Notifications,
} from 'src/core/config/notifications';

import * as types from './actionTypes';
import { type NotificationAction } from './actions';

type NotificationState = {
  loader: boolean;
  error: string;
  notifications: Notifications;
};

const initialState: NotificationState = {
  loader: true,
  error: '',
  notifications: defaultNotifications,
};

const isAutoRemindersNotif = (notif: Notification) =>
  notif.notif_code === 'autoReminders';

export const notificationsReducer = (
  state = initialState,
  action: NotificationAction,
) => {
  switch (action.type) {
    case types.FETCH_NOTIFICATIONS_REQUEST: {
      return { ...state, loader: true };
    }
    case types.FETCH_NOTIFICATIONS_SUCCESS: {
      const { payload } = action;
      const newNotifications = { ...state.notifications };
      const isAutoRemindersNotifEnabled = payload.some(isAutoRemindersNotif);

      // Hide the auto reminders notif section if the backend doesn't return the
      // 'autoReminders' notif entry/key. A company can be not eligible to auto
      // reminders depending on its control rules configuration.
      if (!isAutoRemindersNotifEnabled) {
        delete newNotifications.autoReminders;
      }

      payload.forEach((notif) => {
        newNotifications[notif.notif_code] = {
          ...newNotifications[notif.notif_code],
          ...notif,
        };
      });

      return {
        ...state,
        loader: false,
        notifications: newNotifications,
      };
    }
    case types.FETCH_NOTIFICATIONS_FAILURE: {
      // FIXME: OMG don't mutate
      state.loader = false;
      return { ...state, error: action.error.toString() };
    }
    default: {
      return state;
    }
  }
};
