import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useSupervisor } from 'modules/app/hooks/useSupervisor';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type SAMLConfig = {
  isEnabled: boolean;
  isEnforced: boolean;
};

export const useGetSamlConfigQuery = (): QueryState<SAMLConfig> => {
  const companyId = useCompanyId();
  const supervisor = useSupervisor();

  return useQuery<SAMLConfig, SAMLConfig>({
    key: ['samlConfig', companyId],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/saml-config`,
    },
    isEnabled: supervisor !== undefined,
    reshapeData: (data) => ({
      isEnabled: data.isEnabled,
      isEnforced: data.isEnforced,
    }),
  });
};
