import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import type { AppDispatch } from 'modules/app/redux/store';
import { fetchControlRules } from 'modules/company/members/components/ControlRules/redux/thunks';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ControlRule } from '../models/controlRule';

export const useControlRulesQuery = (): QueryState<ControlRule[]> => {
  return useQuery<ControlRule[], ControlRule[]>({
    key: ['control-rules'],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/control-rules`,
    },
  });
};

export const useInvalidateControlRulesQuery = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  return () => {
    queryClient.invalidateQueries(['control-rules']);
    dispatch(fetchControlRules());
  };
};
