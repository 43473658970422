import { type MonetaryValue } from 'ezmoney';

import { type PerDiem } from 'modules/per-diem';

import {
  type UkVehicleFuelType,
  type UkVehicleEngineSize,
} from '../requests/mileage/creation/model';

enum SelectionStatus {
  All = 'ALL',
  Partial = 'PARTIAL',
  None = 'NONE',
}

export enum ExpenseProofStatus {
  Provided = 'PROVIDED',
  NotProvided = 'NOT_PROVIDED',
  Missing = 'MISSING',
  Invalid = 'INVALID',
}

export type ExpenseType =
  | 'card_purchase'
  | 'invoice_purchase'
  | 'expense_claim'
  | 'mileage_allowance'
  | 'per_diem_allowance'
  | 'reversal';

export enum PayableType {
  cardPurchase = 'cardPurchase',
  invoicePurchase = 'invoicePurchase',
  cardReversal = 'cardReversal',
  creditNote = 'creditNote',
  claimedBill = 'claimedBill',
  mileageExpense = 'mileageExpense',
  reverseBill = 'reverseBill',
  perDiemExpense = 'perDiemExpense',
}

enum PayableSubType {
  plasticCard = 'plasticCard',
  subscriptionCard = 'subscriptionCard',
  singleUseCard = 'singleUseCard',
  creditNote = 'creditNote',
}

export enum LegacyExpenseType {
  subscription = 'subscription',
  singlePurchase = 'single_purchase',
  perDiemAllowance = 'per_diem_allowance',
  invoice = 'invoice',
  mileageAllowance = 'mileage_allowance',
  expense = 'expense',
}

type ExpenseHistoryEvent = {
  id: string;
  type: string;
  occuredAt: string;
  user: {
    givenName: string;
    familyName: string;
    email: string;
  };
  data?: {
    scheduledAmount: MonetaryValue;
    executionDate: string;
    paymentMethod?: 'directDebit' | 'wireTransfer';
  };
};

// FIXME: we should have different types for the users, like in the schema
export type User = {
  id: string;
  givenName?: string;
  familyName?: string;
  email?: string;
};

type ExpenseProof = {
  id: string;
  mediaType: string;
  url: string;
  downloadUrl: string;
  thumbnailUrl: string;
  certifiedAt: string | null;
  createdAt: string;
};

interface BaseRawCustomField {
  customField: {
    id: string;
    name: string;
    optional: boolean;
  };
}

export interface RawCustomFieldSingleValue extends BaseRawCustomField {
  value: boolean | null;
}

export interface RawCustomFieldMultipleValue extends BaseRawCustomField {
  values: {
    id: string;
    value: string;
    default: boolean;
  }[];
}

export type RawCustomField =
  | RawCustomFieldSingleValue
  | RawCustomFieldMultipleValue;

export type ExpenseCustomField = {
  name: string;
  value: string;
  id: string;
};

type EmployeeAccount = {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode?: string | undefined;
};

// Mileage types

type Coordinates = {
  lat: number;
  lng: number;
};

type Position = {
  coordinates: Coordinates;
  address: string;
};

type Distance = {
  value: number;
  unit: string;
};

export type Vehicle = {
  type: 'car' | 'motorcycle' | 'bike';
  taxHorsepower: number | null;
  totalDistanceOverYear: Distance;
  electric: boolean;
  engineSize: UkVehicleEngineSize;
  fuelType: UkVehicleFuelType;
  ownership?: 'company' | 'personal';
};

export type MileageScheme = {
  type: 'french' | 'german' | 'uk' | 'custom';
  rate: string | null;
};

interface Expense {
  id: string;
  isExported: boolean;
  type: ExpenseType;
  subType: PayableSubType | undefined;
  description: string;
  amount: MonetaryValue;
  originalAmount?: MonetaryValue;
  expenseAccount: string[];
  vat: string[];
  team: string | null;
  costCenter: string | null;
  costCentersFromItemLines: string[];
  history: ExpenseHistoryEvent[];
  proofs: ExpenseProof[];
  hasInvalidProof: boolean;
  customFields: ExpenseCustomField[];
  customFieldsFromItemLines: Map<string, string[]>;
  version?: number; // Use by the new Foundations API
  payer: User | null;
  exportedAt: string | null;
  accountedBy: User | null;
}

export interface SupplierExpense extends Expense {
  supplierName?: string;
  supplierId?: string;
  supplierImageSrc?: string;
  categories: string[];
  supplierAccount: {
    id: string;
    generalAccountCode: string;
    auxiliaryAccountCode: string | undefined;
    supplier?: { id: string };
  } | null;
}

export interface ExpenseClaim extends Expense {
  supplierName?: string;
  supplierImageSrc?: string;
  categories: string[];
  employeeAccount: EmployeeAccount | null;
}

export type MileageAllowanceDetails = {
  departure: Position;
  arrival: Position;
  distance: Distance;
  scheme: MileageScheme;
  travelDate: string;
  vehicle: Vehicle;
  passengers: number;
  journey?: { lat: number; lng: number }[];
};

export type MileageAllowanceExpense = Expense &
  MileageAllowanceDetails & { employeeAccount: EmployeeAccount | null };

export interface PerDiemAllowanceExpense extends Expense {
  perDiem: PerDiem;
  employeeAccount: EmployeeAccount | null;
}

export type Payable = {
  id: string;
  label: string;
  sublabel: string;
  amount: MonetaryValue;
  originalAmount: MonetaryValue;
  date: string;
  proofStatus: ExpenseProofStatus;
};

export type Group = {
  id: string;
  label: React.ReactChild | string;
  thumbnailUrl: string;
  totalCount: number;
  totalAmount: MonetaryValue;
  connectionId: string;
};

export type GroupWithContext = Group & {
  selectionStatus: SelectionStatus;
};

export type CostCenter = {
  id: string;
  name: string;
};
