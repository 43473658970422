import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import type {
  BankDetailsSuggestion,
  DraftSupplier,
  SupplierDetails,
} from '../../models';
import { SupplierBankInfoForm } from '../SupplierBankInfoForm';

type Props = {
  className: string;
  bankDetailsSuggestion?: BankDetailsSuggestion;
  draftSupplier: DraftSupplier;
  onCreate(): void;
  onCreateSupplierSuccess(supplier: SupplierDetails): void;
};

export const NewSupplierCreationWarning = ({
  className,
  bankDetailsSuggestion,
  draftSupplier,
  onCreate,
  onCreateSupplierSuccess,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Callout
      className={className}
      title={t('submitMyInvoice.supplier.newSupplierCreated', {
        supplierName: draftSupplier.name,
      })}
      variant="primary"
      iconName="supplier"
    >
      <SupplierBankInfoForm
        bankDetailsSuggestion={bankDetailsSuggestion}
        draftSupplier={draftSupplier}
        onSupplierCreationOrUpdateSuccess={onCreateSupplierSuccess}
      />
      <Button
        className="mt-s"
        text={t('submitMyInvoice.supplier.addGeneralInfos')}
        variant="ghost"
        hasNegativeMargins
        onClick={onCreate}
      />
    </Callout>
  );
};
