import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import { createElement } from 'react';
import { withRouter, type RouteComponentProps } from 'react-router-dom';

type Props<CompProps> = {
  Comp: string | React.ComponentType<Readonly<CompProps>>;
  componentCanBeShown?: boolean | ((props: CompProps) => boolean);
  redirectUrl?: string | ((props: CompProps) => string);
};

/**
 * -------------------------------------------------------------------------------------------
 * THIS HIGHER ORDER COMPONENT HAS BEEN DEPRECATED IN FAVOR OF app-desktop/src/core/utils/authorize.
 * PLEASE DO NOT USE IT ANYMORE.
 * -------------------------------------------------------------------------------------------
 * Authorize a given component accoring to a specific logic. Usage:
 *
 *   const PaymentsView = (props) => <div>Foo bar</div>;
 *
 *   const authorizedComponent = authorizeComponent({
 *     Comp: PaymentsView,
 *     componentCanBeShown: (props) => Privileges.check(SharedPrivilege.PAYMENTS_OVERVIEW_READ),
 *     redirectUrl: (props) => routeFor(routes.REQUESTS.path, { company: props.company.id }),
 *   });
 *
 *   const mapStateToProps = ({ global: { company } }) => ({ company });
 *
 *   export default connect(mapStateToProps, null)(withRouter(authorizedComponent));
 *
 * @param  {ReactComp} Comp                           The component to authorize
 * @param  {Function || Boolean} componentCanBeShown  Whether or not to show the comp given a context
 * @param  {Function || String} redirectUrl           The URL on which to automatically redirect if unauthorized (optional)
 * @return {Comp || null}                             Component || null depending on authorization
 * @deprecated                                        Use app-desktop/src/core/utils/authorize instead
 */
const authComponent = <CompProps>({
  Comp,
  componentCanBeShown,
  redirectUrl,
}: Props<CompProps>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  withRouter<CompProps & RouteComponentProps<any>>((props) => {
    // Check whether the component is authorized
    if (
      !componentCanBeShown ||
      (isFunction(componentCanBeShown) && !componentCanBeShown(props))
    ) {
      // Eventually redirect to another URL
      if (redirectUrl) {
        const nextUrl = isFunction(redirectUrl)
          ? redirectUrl(props)
          : redirectUrl;

        // Redirect using React router history, fallback to hard redirect
        if (isString(nextUrl) && props.history) {
          props.history.push(nextUrl);
        } else if (isString(nextUrl)) {
          window.location.href = `${window.location.origin}${nextUrl}`;
        }
      }
      return null;
    }

    // Show the component as it's been authorized
    return createElement(Comp, props);
  });

export default authComponent;
