import map from 'lodash/map';

import {
  type Company,
  AnnouncementCode,
  type CompanyAnnouncement,
} from './types';

const reshapeCompanyAnnouncementData = (
  announcementData: CompanyAnnouncement,
): CompanyAnnouncement => {
  return {
    code: announcementData.code,
    lastSeenAt: announcementData.lastSeenAt,
  };
};

export const reshapeCompanyData = (data: { company: Company }): Company => {
  const { company } = data;
  return {
    id: company.id,
    name: company.name,
    currency: company.currency,
    announcements: map(company.announcements, reshapeCompanyAnnouncementData),
    automaticReceiptReminders: company.automaticReceiptReminders,
  };
};

export const getAnnouncements = (company: Company) => company.announcements;

const findAnnouncement = (code: AnnouncementCode, company: Company) =>
  getAnnouncements(company).find((a) => a.code === code);

export const hasRecordedOnExpenseInbox = (company: Company): boolean => {
  const announcement = findAnnouncement(
    AnnouncementCode.ExpenseInboxFirstRecord,
    company,
  );
  if (!announcement) {
    return false;
  }
  return Boolean(announcement.lastSeenAt);
};
