import React from 'react';

import { EmptyStateError, lazyLoad } from 'common/components/LazyLoad';

import { ExpenseClaimsPageSkeleton } from './ExpenseClaimsPageSkeleton';

export const ExpenseClaimsPageContainer = lazyLoad({
  loader: async () => ({
    /* eslint-disable unicorn/no-await-expression-member */
    default: (
      await import(
        /* webpackChunkName: "ExpenseClaimsPageContainer" */ './ExpenseClaimsPageContainer'
      )
    ).default,
    /* eslint-enable unicorn/no-await-expression-member */
  }),
  loading: <ExpenseClaimsPageSkeleton />,
  fallback: (props) => <EmptyStateError {...props} />,
});
