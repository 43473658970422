import React from 'react';

import { ExpenseReviewListSkeleton } from '@finance-review/components/expense/ExpenseReview/ExpenseReviewListSkeleton';
import { ExpenseReviewFormSkeleton } from '@finance-review/components/expense/ExpenseReviewFormContainer/ExpenseReviewFormSkeleton';

export const ExpenseReviewPageSkeleton = () => {
  return (
    <>
      <div style={{ paddingRight: '12px' }}>
        <ExpenseReviewListSkeleton />
      </div>
      <div>
        <ExpenseReviewFormSkeleton />
      </div>
    </>
  );
};
