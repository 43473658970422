import type { PayableRequest } from '@finance-review/models/payable';
import { type MutationState } from 'src/core/api/hooks/useMutation';
import { type MutationQueryState } from 'src/core/api/queryState';

import { useMutation as useValidatePayableRequestsMutation } from './useValidatePayableRequests';

export type Response<TError = string> = {
  successes: { id: string }[];
  errors: {
    details?: TError[];
    requestId: string;
    errorCode: string;
    errorMessage: string;
  }[];
};
export type Payload = {
  id: PayableRequest.EntityId;
  amount: number;
  currency: string;
};
export type QueryState = MutationQueryState<Response, unknown>;

export const useMutation = <TError = string>(
  options: {
    onSuccess?: (requestId: PayableRequest.EntityId) => void;
    onError?: () => void;
  } = {},
): MutationState<Payload, Response<TError>, unknown> => {
  const [validatePayableRequestMutation, ...rest] =
    useValidatePayableRequestsMutation<TError>(options);

  return [
    async ({ id, amount, currency }) =>
      validatePayableRequestMutation([
        {
          amount,
          currency,
          id,
        },
      ]),
    ...rest,
  ];
};

export const hasFailed = (
  validateInvoiceRequestQueryState: QueryState,
): boolean =>
  validateInvoiceRequestQueryState.status === 'success' &&
  validateInvoiceRequestQueryState.data.errors.length > 0;

export const getFailedMessage = (
  validateInvoiceRequestQueryState: QueryState,
): string | undefined => {
  const error =
    validateInvoiceRequestQueryState.status === 'success'
      ? validateInvoiceRequestQueryState.data.errors[0]
      : undefined;

  if (
    error?.errorCode === 'invalid_data' &&
    error?.details?.[0] === 'supplierDetailsIsMissing'
  ) {
    return 'payable.review.validate.errors.supplierDetailsIsMissing';
  }
};
