import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import {
  type KomboAvailableFilterByDimension,
  type CompanyKomboConfigurationReshaped,
} from '../types';

import {
  useGetKomboConfigurationQuery,
  useGetKomboAvailableFiltersQuery,
} from '.';

export const useIntegrationDetailsDataQuery = (
  isEnabled: boolean,
): QueryState<{
  companyConfiguration: CompanyKomboConfigurationReshaped;
  komboAvailableFilters: KomboAvailableFilterByDimension;
}> => {
  return useQueryStates({
    states: {
      companyConfiguration: useGetKomboConfigurationQuery(isEnabled),
      komboAvailableFilters: useGetKomboAvailableFiltersQuery(isEnabled),
    },
  });
};
