import { useReducer } from 'react';

import { useCompanyCurrency } from 'src/core/modules/app/hooks/useCompanyCurrency';

import { type Transfer } from '../../models';
import { MAX_PAYMENTS_PER_BATCH } from '../TransferBatchLimitModal/TransferBatchLimitModal';

export enum ActiveModalStates {
  confirmationErrors = 'confirmationErrors',
  confirmation = 'confirmation',
  batchLimit = 'batchLimit',
}

type ActiveModalState =
  | ActiveModalStates.confirmationErrors
  | ActiveModalStates.confirmation
  | ActiveModalStates.batchLimit;

type ModalAction =
  | {
      type: 'confirmation';
      payload: {
        transfers: Transfer[];
        companyCurrency: string;
      };
    }
  | {
      type: 'showErrors';
    };

const reducer = (
  state: ActiveModalState,
  action: ModalAction,
): ActiveModalState => {
  switch (action.type) {
    case 'confirmation': {
      const {
        payload: { companyCurrency, transfers },
      } = action;

      if (
        companyCurrency === 'GBP' &&
        transfers.length > MAX_PAYMENTS_PER_BATCH
      ) {
        return ActiveModalStates.batchLimit;
      }

      return ActiveModalStates.confirmation;
    }
    case 'showErrors': {
      return ActiveModalStates.confirmationErrors;
    }
    default:
      return state;
  }
};

export const useConfirmModal = () => {
  const companyCurrency = useCompanyCurrency();

  const [activeModal, dispatch] = useReducer(
    reducer,
    ActiveModalStates.confirmation,
  );
  const askConfirmation = (transfers: Transfer[]) => {
    dispatch({
      type: 'confirmation',
      payload: { transfers, companyCurrency },
    });
  };

  const showConfirmationErrors = () => {
    dispatch({
      type: 'showErrors',
    });
  };

  return {
    activeModal,
    askConfirmation,
    showConfirmationErrors,
  };
};
