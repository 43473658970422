import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type CreatedEntity } from '../utils/createBranch';

type Payload = {
  type: string;
  country: string;
  currency: string;
};

type RawResponse = CreatedEntity;

export const useCreateBankableMarqetaBranchEntityMutation = (): MutationState<
  Payload,
  CreatedEntity
> => {
  const companyId = useCompanyId();

  return useMutation<Payload, CreatedEntity, RawResponse>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'baseAPI',
      endpoint: `/${companyId}/branch`,
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['companies']);
        client.invalidateQueries(['companyWalletSummaries']);
      },
    },
  });
};
