import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type CustomFieldListItem } from '../../../models/customFieldListItem';
import {
  type Payable,
  type CustomFieldList,
} from '../../PayablePanelContainer';

export const useCustomFieldsQuery = (
  payable: Payable,
): QueryState<CustomFieldListItem[]> => {
  const params = {
    includeArchived: true,
    valuesToFetch: {
      ...Object.fromEntries(
        payable.customFields
          .filter((cf): cf is CustomFieldList => cf.type === 'list')
          .map((cf) => [cf.id, cf.values.map(({ id }) => id)]),
      ),
      ...payable.itemLines.reduce(
        (accumulator: { [k: string]: string[] }, itemLine) => {
          itemLine.analyticalFieldAssociations
            .filter((afa) => afa.fieldKind === 'customField')
            .forEach((afa) => {
              if (!accumulator[afa.fieldEntityId]) {
                accumulator[afa.fieldEntityId] = [];
              }
              accumulator[afa.fieldEntityId].push(afa.fieldEntityValueId);
            });

          return accumulator;
        },
        {},
      ),
    },
  };

  return useQuery<CustomFieldListItem[]>({
    key: [`custom-fields`, params],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/custom-fields`,
      params,
    },
    reshapeData: (data) => {
      const customFieldsIds = payable.customFields.map(({ id }) => id);
      return data.filter(
        ({ id, deleted_at }: { id: string; deleted_at: string | null }) =>
          customFieldsIds.includes(id) || deleted_at === null,
      );
    },
  });
};
