import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';

import { TransferPanel } from './TransferPanel';
import { TransferPanelError } from './TransferPanelError';
import { TransferPanelSkeleton } from './TransferPanelSkeleton';
import { useTransferQuery } from '../../hooks';
import { type TransferId } from '../../models';

type Props = {
  transferId: TransferId;
  onClose(): void;
};

export const TransferPanelContainer = ({ transferId, onClose }: Props) => {
  const transferQueryState = useTransferQuery(transferId);

  return (
    <QuerySuspense
      queryState={transferQueryState}
      loading={<TransferPanelSkeleton onClose={onClose} />}
      fallback={(queryError) => (
        <TransferPanelError queryError={queryError} onClose={onClose} />
      )}
    >
      {(transfer) => <TransferPanel transfer={transfer} onClose={onClose} />}
    </QuerySuspense>
  );
};
