import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_organisation_owner: boolean;
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
  };
  className?: string;
  onClick?: () => void;
};

export const CompanyBillingLink = ({ className, company, onClick }: Props) => {
  const { t } = useTranslation('global');
  const { pathname: currentPath } = useLocation();

  return (
    <NavigationLink
      data-marketing="top-menu_billing"
      className={className}
      key="nav_settings_billing"
      path={routeFor(routes.COMPANY_BILLING_PLAN.path, {
        company: company.id,
      })}
      text={t('settings.links.billing')}
      onClick={onClick}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [routes.COMPANY_BILLING.path],
        }),
      )}
    />
  );
};
