import { AccordionItem, Button, colors, Icon } from '@dev-spendesk/grapes';
import { useHistory } from 'react-router-dom';

import {
  type UserTask,
  type UserTaskType,
} from 'modules/onboarding/setup-hub/types/userTask';
import { useCardsAccessQuery } from 'modules/physical-cards/hooks/useCardAccessQuery';
import { useCardAccessRestricted } from 'modules/requests/hooks/useCardAccessRestricted';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import { getContentByTaskType } from './utils';

type Props = {
  setModalType: (type: UserTaskType | null) => void;
  task: UserTask;
  openMFAModal: (callback: () => void) => void;
};

export const ReadyToSpendTaskItem = ({
  setModalType,
  task,
  openMFAModal,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation('global');

  const company = useCompany();
  const user = useUser();

  const cardsAccessQueryState = useCardsAccessQuery(user.id);

  const cardAccessRestricted = useCardAccessRestricted({
    cardsAccess:
      cardsAccessQueryState.status === 'success'
        ? {
            isLoading: false,
            ...cardsAccessQueryState.data,
          }
        : { isLoading: true },
    company,
  });

  const onClick = async () => {
    switch (task.type) {
      case 'download_app':
      case 'learn_to_approve':
      case 'upload_receipt':
        setModalType(task.type);
        break;
      case 'expense_claim': {
        const path = routeFor(routes.REQUESTS.path, {
          company: company.id,
          type: 'all',
          id: 'new',
          newRequestType: 'reimbursement',
        });
        history.push(path, { productTourToDisplay: 'reimbursement' });
        break;
      }
      case 'single_use': {
        const openSinglePurchase = () => {
          const path = routeFor(routes.REQUESTS.path, {
            company: company.id,
            type: 'all',
            id: 'new',
            newRequestType: 'virtual',
          });
          history.push(path, { productTourToDisplay: 'single_purchase' });
        };
        if (
          cardAccessRestricted === 'mfaMissing' ||
          cardAccessRestricted === 'userPhoneMissing'
        ) {
          openMFAModal(openSinglePurchase);
        } else {
          openSinglePurchase();
        }
        break;
      }
      case 'subscription': {
        const openSubscription = () => {
          const path = routeFor(routes.REQUESTS.path, {
            company: company.id,
            type: 'all',
            id: 'new',
            newRequestType: 'virtual',
          });
          history.push(`${path}?recurring=true`, {
            productTourToDisplay: 'subscription',
          });
        };
        if (
          cardAccessRestricted === 'mfaMissing' ||
          cardAccessRestricted === 'userPhoneMissing'
        ) {
          openMFAModal(openSubscription);
        } else {
          openSubscription();
        }
        break;
      }
      default:
        break;
    }
  };

  const content = getContentByTaskType(task.type);
  if (!content) {
    return null;
  }
  const { title, decription, cta } = content;

  return (
    <div className="border-0 border-t border-solid border-neutral-light py-s">
      <AccordionItem
        renderHeader={() => (
          <div className="-ml-m flex items-center gap-xs">
            {task.status === 'completed' ? (
              <Icon name="success" size="l" color={colors.success} />
            ) : (
              <div className="h-m w-m rounded-[24px] border border-solid border-neutral" />
            )}
            <p className="title-m">{t(title)}</p>
          </div>
        )}
      >
        <div className="flex flex-col gap-s">
          <div className="text-neutral-dark">{t(decription)}</div>
          <Button
            className="mb-xs self-end"
            onClick={onClick}
            variant="primary"
            text={t(cta)}
          />
        </div>
      </AccordionItem>
    </div>
  );
};
