import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { NotificationType, useNotifications } from '../../app/notifications';
import { type IntegrationId } from '../types';
import { getIntegrationName } from '../utils';

export const useDisconnectTravelperkMutation = (
  integrationId: IntegrationId,
): MutationState<{
  silent: boolean;
}> => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const integrationName = getIntegrationName(t, integrationId);

  return useMutation<{
    silent: boolean;
  }>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint: `/integrations/${integrationId}/disconnect`,
    },
    options: {
      onSuccess({ payload, client }): void {
        if (!payload.silent) {
          pushNotif({
            type: NotificationType.Success,
            message: t('integration.notifications.disconnect.success', {
              integrationName,
            }),
          });
          client.invalidateQueries('integrationsStatus');
        }
      },
      onError() {
        pushNotif({
          type: NotificationType.Danger,
          message: t('integration.notifications.disconnect.error', {
            integrationName,
          }),
        });
      },
    },
  });
};
