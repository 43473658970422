import React from 'react';

import { FeedbackModal } from "src/core/common/components/FeedbackModal";
import { FeedbackModalWithRating } from "src/core/common/components/FeedbackModalWithRating";
import { useDisableFeature } from "src/core/common/hooks/useDisableFeature";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import type FEATURES from "src/core/constants/features";

import type { FeedbackModalVariant } from '../BetaFeatureFeedbackPopover';

type Props = {
  featureName: FEATURES;
  isOpen: boolean;
  feedbackTags: string[];
  className?: string;
  modalVariant?: FeedbackModalVariant;
  onCancel(): void;
  onConfirm(): void;
};

export const BetaFeatureGetPreviousVersionModal = ({
  featureName,
  modalVariant,
  onConfirm,
  ...rest
}: Props) => {
  const { t } = useTranslation('global');
  const disableFeature = useDisableFeature();
  const handleConfirm = () => {
    disableFeature(featureName);
    onConfirm();
  };

  switch (modalVariant) {
    case 'withRating':
      return (
        <FeedbackModalWithRating
          {...rest}
          onConfirm={handleConfirm}
          title={t('bookkeep.export.datev.bankJournal.feedback.popoverTitle')}
          confirmText={t(
            'betaFeature.feedbackModal.submitAndSwitchToOldVersion',
          )}
          cancelText={t('betaFeature.feedbackModal.cancel')}
          feedbackLabel={t(
            'bookkeep.export.datev.bankJournal.feedback.feedbackLabel',
          )}
        />
      );
    default:
      return (
        <FeedbackModal
          {...rest}
          onConfirm={handleConfirm}
          title={t('betaFeature.getPreviousVersionModal.title')}
          subtitle={t('betaFeature.getPreviousVersionModal.subtitle')}
          confirmText={t(
            'betaFeature.getPreviousVersionModal.submitAndSwitchToOldVersion',
          )}
          cancelText={t('betaFeature.getPreviousVersionModal.cancel')}
          feedbackLabel={t('betaFeature.getPreviousVersionModal.textLabel')}
        />
      );
  }
};
