import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ExpenseCategory } from '../../../expenseCategory';

export const useExpenseCategoriesQuery = (): QueryState<ExpenseCategory[]> => {
  return useQuery<ExpenseCategory[]>({
    key: 'expenseCategories',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/expense-categories',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reshapeData(data: any[]) {
      return data.map((expenseCategory) => {
        return {
          id: expenseCategory.id,
          name: expenseCategory.name,
          costCenterIds: expenseCategory.costCenterIds,
        };
      });
    },
  });
};
