import { fromNumber } from 'ezmoney';

import { type TGlobalFunctionTyped as TFunctionTyped } from "src/core/common/hooks/useTranslation";
import { formatMonetaryValue } from "src/core/utils/monetaryValue";

import { type SpendingType } from './spendingTypes';

export type ApprovalNeeded = 'always' | 'sometimes' | 'never';

export type ApprovalPolicyParams = {
  approvalNeeded: ApprovalNeeded;
  spendingTypes: SpendingType[];
  amountPerTransaction: number;
  amountPerMonth: number;
};

export type ApprovalPolicy = {
  id: string;
  name: string | null;
  isDefault: boolean;
  isCustom: boolean;
  params: ApprovalPolicyParams;
};

export const getReadableApprovalPolicy = ({
  approvalNeeded,
  spendingLimit,
  transactionMax,
  companyCurrency,
  translator,
}: {
  approvalNeeded: ApprovalNeeded;
  spendingLimit: number;
  transactionMax: number;
  companyCurrency: string;
  translator: TFunctionTyped;
}): string => {
  const transactionMaxFormatted = formatMonetaryValue(
    fromNumber(transactionMax, companyCurrency, 0),
    { precision: 0 },
  );
  const spendingLimitFormatted = formatMonetaryValue(
    fromNumber(spendingLimit, companyCurrency, 0),
    { precision: 0 },
  );
  switch (approvalNeeded) {
    case 'sometimes':
      return translator('approvalPolicy.table.sometimes', {
        transactionMax: transactionMaxFormatted,
        spendingLimit: spendingLimitFormatted,
      });
    case 'always':
      return translator('approvalPolicy.table.always');
    case 'never':
      return translator('approvalPolicy.table.never');
    default:
      return '';
  }
};
