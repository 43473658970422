import deepEqual from 'fast-deep-equal';
import { useEffect } from 'react';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { logger } from 'src/utils/datadog-log-wrapper';

import { GET_AVAILABLE_ACCOUNTING_INTEGRATIONS } from '../graphql/queries';
import {
  type AccountingSoftware,
  type AccountingSoftwareGraphQl,
  type IntegrationStatusWithIntegration,
  toAccountingSoftware,
} from '../integration/status';

type CoreCapabilitySet = {
  [key in keyof IntegrationStatusWithIntegration['capabilities']]: boolean;
};

export type GQLRawData = {
  company: {
    availableAccountingIntegrationsV2: {
      accountingIntegration: AccountingSoftwareGraphQl;
      coreCapabilitySet: CoreCapabilitySet;
    }[];
  };
};

export type RESTRawData = {
  accountingIntegration: AccountingSoftwareGraphQl;
  coreCapabilitySet: CoreCapabilitySet;
}[];

function reshapeDataGQL(
  rawData: GQLRawData,
): AvailableAccountingIntegrationsResponse {
  return rawData.company.availableAccountingIntegrationsV2.map((data) => {
    const accountingIntegration = toAccountingSoftware(
      data.accountingIntegration,
    );

    if (accountingIntegration === 'noIntegration') {
      throw new Error('Invalid accounting integration');
    }

    return { ...data, accountingIntegration };
  });
}

const reshapeDataRest = (
  rawData: RESTRawData,
): AvailableAccountingIntegrationsResponse => {
  return rawData.map((data) => {
    const accountingIntegration = toAccountingSoftware(
      data.accountingIntegration,
    );

    if (accountingIntegration === 'noIntegration') {
      throw new Error('Invalid accounting integration');
    }

    return { ...data, accountingIntegration };
  });
};

export type AvailableAccountingIntegrationsResponse = {
  accountingIntegration: AccountingSoftware;
  coreCapabilitySet: CoreCapabilitySet;
}[];

export const useAvailableAccountingIntegrations = (idempotencyKey: string) => {
  const rest = useQuery<AvailableAccountingIntegrationsResponse, RESTRawData>({
    key: 'useAvailableAccountingIntegrationsREST',
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      endpoint: `/bookkeeping/available-accounting-integrations`,
      target: 'companyAPI',
    },
    reshapeData: reshapeDataRest,
  });

  const GQL = useQuery<AvailableAccountingIntegrationsResponse, GQLRawData>({
    key: 'useAvailableAccountingIntegrationsGQL',
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_AVAILABLE_ACCOUNTING_INTEGRATIONS,
    },
    reshapeData: reshapeDataGQL,
  });

  const hasGetAvailableIntegrationsWithRest = useFeature(
    FEATURES.TMP_GET_AVAILABLE_ACCOUNTING_INTEGRATIONS_USING_REST,
  );
  const hasDiffCheckFlag = useFeature(
    FEATURES.TMP_GET_AVAILABLE_ACCOUNTING_INTEGRATIONS_DIFF_CHECK,
  );

  useEffect(() => {
    if (rest.status == 'error') {
      if (rest.error.type === 'RequestError' && rest.error.status === 401) {
        // Ignore logout errors
        return;
      }

      logger.error('UseAvailableAccountIntegrations with REST failed', {
        scope: `UseAvailableAccountIntegrations-REST-${idempotencyKey}`,
        team: 'accounting-integration',
      });
    }
  }, [rest.status]);

  useEffect(() => {
    if (GQL.status == 'error') {
      if (GQL.error.type === 'RequestError' && GQL.error.status === 401) {
        // Ignore logout errors
        return;
      }
      logger.error('UseAvailableAccountIntegrations with GQL failed', {
        scope: `UseAvailableAccountIntegrations-REST-${idempotencyKey}`,
        team: 'accounting-integration',
      });
    }
  }, [GQL.status]);

  if (hasDiffCheckFlag && rest.status == 'success' && GQL.status == 'success') {
    // this is the only property used from GQL and REST endpoints
    const restPayablesSettlementsReExport = rest.data
      .filter((x) => x.coreCapabilitySet.payablesSettlementsReExport)
      .map(
        (x) =>
          x.accountingIntegration +
          x.coreCapabilitySet.payablesSettlementsReExport,
      );

    const GQLPayablesSettlementsReExport = GQL.data
      .filter((x) => x.coreCapabilitySet.payablesSettlementsReExport)
      .map(
        (x) =>
          x.accountingIntegration +
          x.coreCapabilitySet.payablesSettlementsReExport,
      );
    if (
      !deepEqual(
        restPayablesSettlementsReExport,
        GQLPayablesSettlementsReExport,
      )
    ) {
      logger.warn(
        'UseAvailableAccountIntegrations.coreCapabilitySet.payablesSettlementsReExport GQL and REST do not match',
        {
          scope: `UseAvailableAccountIntegrations-REST-${idempotencyKey}`,
          team: 'accounting-integration',
        },
      );
    }
  }
  return hasGetAvailableIntegrationsWithRest && rest.status != 'error'
    ? rest
    : GQL;
};
