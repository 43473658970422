import { type MonetaryValue } from 'ezmoney';
import React, { type MouseEventHandler } from 'react';

import { BudgetOverview } from './BudgetOverview';
import styles from './BudgetOverviewButton.module.css';
import { type Breakdown } from '../../models/breakdown';

type Props = {
  budget: {
    name: string;
    amount: MonetaryValue;
    breakdown: Breakdown;
    isUnavailable?: boolean;
  };
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const BudgetOverviewButton = ({ budget, onClick }: Props) => {
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      type="button"
      className={styles.BudgetOverviewButton}
      onClick={onClick}
    >
      <BudgetOverview
        name={budget.name}
        amount={budget.amount}
        isUnavailable={budget.isUnavailable}
        breakdown={budget.breakdown}
        withHoverInteractions={false}
        mode="medium"
      />
    </button>
  );
};
