import { FormField, Upload, FileCard } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { ImageViewer, PDFViewer } from 'common/components/FileViewer';
import { usePageViewer } from 'common/components/layout/SiderPageLayout/viewerContext';
import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  isEditing: boolean;
  previewName: string;
  fileUrl?: string | null;
  fileMediaType?: string | null;
  withLabel?: boolean;
  onUpload?: (files: File[]) => void | Promise<void>;
  onDelete?: () => void;
};

export const AccountPayableSuppliersFile = ({
  isEditing,
  previewName,
  fileUrl,
  fileMediaType,
  withLabel,
  onUpload,
  onDelete,
}: Props) => {
  const setViewerContent = usePageViewer({
    team: 'finance-accountant',
    scope: 'pageViewerContext::AccountPayableSuppliersFile',
  });

  const { t } = useTranslation('global');

  const showFileViewer = () => {
    setViewerContent(renderFileViewerModal());
  };

  const hideFileViewer = () => {
    setViewerContent(undefined);
  };

  const renderFileViewerModal = () => {
    if (!fileUrl) {
      return null;
    }

    if (fileMediaType === 'application/pdf') {
      return (
        <PDFViewer
          className="w-full"
          url={fileUrl}
          onDownload={() => window.open(fileUrl, '_blank')}
          onClose={hideFileViewer}
        />
      );
    }

    return (
      <ImageViewer
        withoutCrossOrigin
        url={fileUrl}
        withZoom
        withRotate
        onDownload={() => window.open(fileUrl, '_blank')}
        onClose={hideFileViewer}
      />
    );
  };

  return (
    <>
      {isEditing && !fileUrl && onUpload && (
        <FormField
          className="my-s"
          label={withLabel ? t('bookkeep.accountsPayable.panel.fileLabel') : ''}
          infoTipContent={
            withLabel ? t('bookkeep.accountsPayable.panel.fileHint') : undefined
          }
          visuallyHideLabel={!withLabel}
        >
          <Upload
            content={
              <Trans i18nKey="forms.uploadFiles.content">
                <br />-<span className="Upload__link">-</span>
              </Trans>
            }
            activeDragContent={<>{t('forms.uploadFiles.activeDragContent')}</>}
            onUpload={onUpload}
          />
        </FormField>
      )}
      {fileUrl && (
        <FileCard
          title={previewName}
          mimeType={fileMediaType ?? undefined}
          onClick={showFileViewer}
          onDelete={isEditing && onDelete ? onDelete : undefined}
        />
      )}
    </>
  );
};
