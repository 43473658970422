import * as Money from 'ezmoney';
import React from 'react';
import { Trans } from 'react-i18next';

import {
  creditAvailableQueryErrorTranslations,
  useCreditAvailableQuery,
} from '@finance-review/apis/credit-note';
import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type SupplierId } from '../models';

type Props = {
  supplierId: SupplierId;
  availableCreditCurrency: string;
};

export const SupplierAvailableCredit = ({
  supplierId,
  availableCreditCurrency,
}: Props) => {
  const creditAvailableQueryState = useCreditAvailableQuery(
    { supplierId },
    { isEnabled: true },
  );

  return (
    <QuerySuspense
      queryState={creditAvailableQueryState}
      loading={null}
      fallback={(queryError) => (
        <QueryError
          queryError={queryError}
          componentType="Text"
          translations={creditAvailableQueryErrorTranslations}
        />
      )}
    >
      {({ availableCredit }) => {
        const availableCreditOfCurrency =
          availableCredit[availableCreditCurrency] ??
          Money.fromNumber(0, availableCreditCurrency, 2);

        return (
          <div className="mt-xxs leading-4">
            <Trans
              i18nKey="submitMyInvoice.supplier.availableCredit"
              components={[
                <span
                  key="success"
                  className={
                    Money.toNumber(availableCreditOfCurrency) > 0
                      ? 'text-success'
                      : ''
                  }
                />,
              ]}
              values={{
                amount: formatMonetaryValue(availableCreditOfCurrency),
              }}
            />
          </div>
        );
      }}
    </QuerySuspense>
  );
};
