import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { type AnalyticalFieldMapping } from '../../accounting';

export type AnalyticalFieldMappingUpdate =
  | {
      externalId: string;
      kind: 'mapped';
      codeType: 'customField';
      internalId: string;
      internalName: string;
      sendOnLine?: boolean;
      sendOnHeader?: boolean;
    }
  | {
      externalId: string;
      kind: 'mapped';
      codeType: 'costCenter';
      sendOnLine?: boolean;
      sendOnHeader?: boolean;
    }
  | {
      externalId: string;
      kind: 'notMapped';
      sendOnLine?: boolean;
      sendOnHeader?: boolean;
    };

export const useUpdateAnalyticalFieldMappingMutation = (): MutationState<
  AnalyticalFieldMappingUpdate[]
> => {
  return useMutation<AnalyticalFieldMappingUpdate[]>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint:
        '/accounting-integration/chart-of-accounts/analytical-fields-mappings',
    },
    options: {
      throwOnError: true,
      // Optimistically update the cache value on mutate
      onMutate: async ({ payload, client }): Promise<void> => {
        await client.cancelQueries('getAnalyticalFieldMappings');

        client.setQueryData<AnalyticalFieldMapping[]>(
          'getAnalyticalFieldMappings',
          (rawCachedMappings) => {
            let cachedMappings: AnalyticalFieldMapping[] =
              rawCachedMappings ?? [];

            for (const update of payload) {
              cachedMappings = cachedMappings.map(
                (cachedMapping): AnalyticalFieldMapping => {
                  if (update.externalId !== cachedMapping.externalId) {
                    return cachedMapping;
                  }

                  const baseCachedMapping = {
                    externalId: cachedMapping.externalId,
                    externalName: cachedMapping.externalName,
                    companyId: cachedMapping.companyId,
                    isArchived: cachedMapping.isArchived,
                  };

                  if (update.kind === 'notMapped') {
                    return {
                      ...baseCachedMapping,
                      kind: 'notMapped',
                    };
                  }
                  if (update.codeType === 'costCenter') {
                    return {
                      ...baseCachedMapping,
                      kind: 'mapped',
                      codeType: 'costCenter',
                    };
                  }

                  return {
                    ...baseCachedMapping,
                    kind: 'mapped',
                    codeType: 'customField',
                    internalId: update.internalId,
                    internalName: update.internalName,
                  };
                },
              );
            }

            return cachedMappings;
          },
        );
      },
      onSuccess({ client }): void {
        client.invalidateQueries(['getAnalyticalFieldMappings']);
      },
    },
  });
};
