import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import set from 'lodash/set';
import { Component } from 'react';

import { type CustomFieldDefinition } from 'modules/budgets/models/customFieldDefinition';
import { CustomFieldFormField } from 'src/core/common/components/CustomFieldFormField';
import {
  useCompany,
  type Company,
} from 'src/core/modules/app/hooks/useCompany';
import { useUser, type User } from 'src/core/modules/app/hooks/useUser';
import {
  getCfLabel,
  getEligibleCustomFields,
  harmonizeCustomFieldsAssociations,
} from 'src/core/utils/custom-fields';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from '../../hooks/useTranslation';
import './CustomFieldsSelector.css';

export type EligibleType = 'request' | 'expense' | 'payment' | 'subscription';

type CustomFieldValue = {
  field: { id: string };
  value: { id: string | null; value: string };
};
type CustomFieldAssociation = {
  customFieldId: string;
  customFieldValueId: string | null;
  value: string;
  hasMultipleMatchingValues?: boolean;
  updated?: boolean;
};

type Props = {
  user: User;
  company: Company;
  customFields?: CustomFieldDefinition[];
  customFieldsValues?: CustomFieldValue[];
  types?: EligibleType[];
  team?: string;
  teamIds?: string[];
  isDisabled?: boolean;
  errors?: { [key: string]: string | boolean };
  onChange: (
    customFieldAssociations: CustomFieldAssociation[],
    customField: CustomFieldDefinition,
  ) => void;
  fit?: 'parent' | 'content';
  placement?: 'bottom-start' | 'top-end' | 'top-start';
  withAllChanges?: boolean;
  t: TGlobalFunctionTyped;
};

type State = {
  eligibleCustomFields: CustomFieldDefinition[];
  customFieldsAssociations: CustomFieldAssociation[] | null;
};

class CustomFieldsSelectorClassComponent extends Component<Props, State> {
  static defaultProps = {
    customFields: [],
    customFieldsValues: [],
    types: [],
    team: null,
    teamIds: [],
    isDisabled: false,
    errors: {},
    onChange: noop,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      eligibleCustomFields: this.getEligibleCustomFields(props),
      customFieldsAssociations: harmonizeCustomFieldsAssociations(
        props.customFieldsValues,
      ),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const changes: Partial<State> = {};
    const userHasChanged = !isEqual(this.props.user, nextProps.user);
    const teamHasChanged = !isEqual(this.props.team, nextProps.team);
    const customFieldsHaveChanged = !isEqual(
      this.props.customFields,
      nextProps.customFields,
    );
    const customFieldsValuesHaveChanged = !isEqual(
      this.props.customFieldsValues,
      nextProps.customFieldsValues,
    );

    if (customFieldsValuesHaveChanged) {
      changes.customFieldsAssociations = harmonizeCustomFieldsAssociations(
        nextProps.customFieldsValues,
      );
    }

    if (userHasChanged || teamHasChanged || customFieldsHaveChanged) {
      changes.eligibleCustomFields = this.getEligibleCustomFields(nextProps);
    }

    if (!isEmpty(changes)) {
      // @ts-expect-error state things
      this.setState(changes);
    }
  }

  // Update chosen value locally & propagate changes
  handleSelectValue = (
    customField: CustomFieldDefinition,
    value: string | { key: string; name: string } | undefined,
  ) => {
    const { customFieldsAssociations } = this.state;
    const { withAllChanges } = this.props;
    const matchingCfAssocIndex = (customFieldsAssociations ?? []).findIndex(
      (item) => item.customFieldId === customField.id,
    );

    const updatedAssociation = {
      customFieldId: customField.id,
      customFieldValueId: isObject(value) ? value.key : null,
      value: isObject(value) ? value.name : value,
      updated: true,
    };

    if (matchingCfAssocIndex !== -1) {
      // Existing CF association mapping
      set(
        customFieldsAssociations ?? [],
        matchingCfAssocIndex,
        updatedAssociation,
      );
    } else {
      // New CF association mapping
      (customFieldsAssociations ?? []).push(
        updatedAssociation as CustomFieldAssociation,
      );
    }

    this.setState({ customFieldsAssociations }, () => {
      if (withAllChanges) {
        const sanitizedAssociations = map(customFieldsAssociations, (a) =>
          omit(a, ['hasMultipleMatchingValues', 'updated']),
        );
        return this.props.onChange(sanitizedAssociations, customField);
      }
      // Clear utils variables before propagating changes
      const updatedAssociations = filter(
        customFieldsAssociations,
        ({ updated }) => updated,
      );
      // @ts-expect-error bordel
      const sanitizedAssociations = map(
        updatedAssociations,
        (a: CustomFieldAssociation) =>
          omit(a, ['hasMultipleMatchingValues', 'updated']),
      ) as CustomFieldAssociation[];
      return this.props.onChange(sanitizedAssociations, customField);
    });
  };

  getEligibleCustomFields = ({ customFields, user, team, types }: Props) => {
    // @ts-expect-error state things
    const teamIds = this.props.teamIds.length ? this.props.teamIds : [];

    // @ts-expect-error state things
    if (team && !teamIds.length) {
      // @ts-expect-error state things
      teamIds.push(team);
    }

    const options = { user, types, teamIds };

    return getEligibleCustomFields(customFields, options);
  };

  getPlaceholder = (customFieldValue: CustomFieldAssociation | undefined) => {
    const { t, isDisabled } = this.props;
    const fieldHasManySelectedValues = get(
      customFieldValue,
      'hasMultipleMatchingValues',
    );
    if (fieldHasManySelectedValues) {
      return t('misc.multipleValues');
    }
    if (isDisabled) {
      return t('misc.noValueSelected');
    }
    return t('misc.chooseValue');
  };

  renderField = (
    customField: CustomFieldDefinition,
    customFieldValue: CustomFieldAssociation | undefined,
  ) => {
    const { errors, isDisabled, fit, placement } = this.props;
    const hasError = !!errors?.[customField.id];

    return (
      <CustomFieldFormField
        className="CustomFieldsSelector__item"
        key={`${customField.id}_${get(customFieldValue, 'customFieldValueId')}`}
        customField={customField}
        customFieldAssociation={customFieldValue}
        label={getCfLabel(customField)}
        placeholder={this.getPlaceholder(customFieldValue)}
        isDisabled={isDisabled || Boolean(customField.deleted_at)}
        isInvalid={hasError}
        onSelectCustomFieldValue={(newCustomFieldValue) => {
          this.handleSelectValue(customField, newCustomFieldValue);
        }}
        onSelectNewCustomFieldValue={(rawValue: string) =>
          this.handleSelectValue(customField, rawValue)
        }
        fit={fit}
        placement={placement}
      />
    );
  };

  render() {
    const { eligibleCustomFields, customFieldsAssociations } = this.state;
    if (isEmpty(eligibleCustomFields)) {
      return null;
    }

    return (
      <div>
        {map(eligibleCustomFields, (customField) => {
          const customFieldValue = find(
            customFieldsAssociations,
            ({ customFieldId }) => customFieldId === customField.id,
          );

          // The field has been deleted and no value was assigned before
          if (customField.deleted_at && !customFieldValue) {
            return null;
          }

          return this.renderField(customField, customFieldValue);
        })}
      </div>
    );
  }
}

export const CustomFieldsSelector = (
  props: Omit<Props, 'user' | 'company' | 't'>,
) => {
  const user = useUser();
  const company = useCompany();
  const { t } = useTranslation('global');
  return (
    <CustomFieldsSelectorClassComponent
      {...props}
      user={user}
      company={company}
      t={t}
    />
  );
};
