import { Button, FormField, Modal, TextInput } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import React from 'react';

import { AutocompleteMultipleSearch } from 'common/components/AutocompleteSearch';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { type TeamUserRaw } from 'modules/members/models/teams';
import FEATURES from 'src/core/constants/features';

type FormValues = {
  teamName: string;
  selectedAdmins: { key: string; label: string }[];
};

type Props = {
  users: TeamUserRaw[];
  teams: { name: string }[];
  onCreate: (teamName: string, selectedAdmins: string[]) => Promise<void>;
  onCancel?: () => void;
};

export const CreateNewTeamModal = ({
  users,
  teams,
  onCreate,
  onCancel = () => {},
}: Props) => {
  const { t } = useTranslation('global');
  const isTeamManagerFeatureEnabled = useFeature(FEATURES.TEAM_MANAGER);

  const formikProps = useFormik<FormValues>({
    initialValues: {
      teamName: '',
      selectedAdmins: [],
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.teamName.trim()) {
        errors.teamName = t('misc.requiredField');
      }
      if (teams.some((team) => team.name === values.teamName)) {
        errors.teamName = t('members.nameAlreadyTaken');
      }
      if (isTeamManagerFeatureEnabled && !values.selectedAdmins.length) {
        errors.selectedAdmins = t('misc.requiredField');
      }
      return errors;
    },
    onSubmit: async (values) => {
      await onCreate(
        values.teamName,
        values.selectedAdmins.map((value) => value.key),
      );
    },
  });

  return (
    <Modal
      isOpen
      title={t('members.createANewTeam')}
      iconName="team"
      iconVariant="primary"
      onClose={onCancel}
      actions={[
        <Button
          key="cancel"
          variant="secondary"
          text={t('misc.cancel')}
          onClick={onCancel}
        />,
        <Button
          key="create"
          text={t('misc.create')}
          type="submit"
          form="create-new-team"
          isLoading={formikProps.isSubmitting}
        />,
      ]}
    >
      <form
        className="text-left"
        id="create-new-team"
        onSubmit={formikProps.handleSubmit}
      >
        <FormField
          label={t('members.teamName')}
          alertMessage={formikProps.errors.teamName}
        >
          <TextInput
            placeholder={t('members.nameTheTeam')}
            name="teamName"
            value={formikProps.values.teamName}
            onChange={formikProps.handleChange}
          />
        </FormField>
        {isTeamManagerFeatureEnabled && (
          <FormField
            className="mt-s"
            label={t('members.teamManagers')}
            infoTipContent={t('members.aTeamManagerCan')}
            alertMessage={
              typeof formikProps.errors.selectedAdmins === 'string'
                ? formikProps.errors.selectedAdmins
                : undefined
            }
          >
            <AutocompleteMultipleSearch
              fit="parent"
              values={formikProps.values.selectedAdmins}
              options={users.map((user) => ({
                key: user.id,
                label: user.pending ? user.email : user.display_name,
              }))}
              translations={{
                selectAll: t('misc.selectAll'),
                selected: t('approvalPolicy.members.selected', {
                  count: formikProps.values.selectedAdmins.length,
                }),
              }}
              placeholder={t('teams.selectManagers')}
              onSelect={(selectedAdmins) =>
                formikProps.setFieldValue('selectedAdmins', selectedAdmins)
              }
            />
          </FormField>
        )}
      </form>
    </Modal>
  );
};
