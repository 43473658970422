import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  userIds: string[];
};

export const useUnassignControlRuleMutation = () => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/control-rules/unassign',
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
  });
};
