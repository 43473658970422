import { useQueryClient } from 'react-query';

import { useLazyQuery } from 'src/core/api/hooks/useLazyQuery';
import { type LazyState } from 'src/core/api/queryState';

import { type Supplier } from './supplier';

type RawSupplier = { id: string; name: string };

export type SupplierSearchOptions = {
  isInvoicePayable: boolean;
  includeArchived?: boolean;
};

export const useSearchSuppliersLazyQuery = (
  search: string | undefined,
  searchOptions: SupplierSearchOptions,
): LazyState<Supplier[]> => {
  const params = getQueryParams(search, searchOptions);
  return useLazyQuery<Supplier[], RawSupplier[]>({
    key: ['suppliers', params],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/suppliers`,
      params,
    },
    reshapeData: (data) => {
      return reshapeRawSuppliers(data);
    },
    options: {
      throwOnError: true,
      cancelRefetch: true,
    },
  });
};

export const useSearchSuppliersCache = (
  searchOptions: SupplierSearchOptions,
) => {
  const queryClient = useQueryClient();

  return (search: string | undefined): Supplier[] | undefined => {
    const params = getQueryParams(search, searchOptions);
    const queryKey = ['suppliers', params];

    const rawSuppliers: RawSupplier[] | undefined =
      queryClient.getQueryData(queryKey);
    return rawSuppliers ? reshapeRawSuppliers(rawSuppliers) : undefined;
  };
};

const reshapeRawSuppliers = (rawSuppliers: RawSupplier[]): Supplier[] => {
  return rawSuppliers.map((rawSupplier) => ({
    key: rawSupplier.id,
    label: rawSupplier.name,
  }));
};

const getQueryParams = (
  search: string | undefined,
  searchOptions: SupplierSearchOptions,
) => ({
  is_invoice_payable: searchOptions.isInvoicePayable,
  includeArchived: searchOptions.includeArchived,
  withBrands: false,
  search,
});
