import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useParams } from 'src/core/common/hooks/useParams';
import { routes } from 'src/core/constants/routes';

export type ExportValidationStatusItem = {
  kind: string;
  error: string;
};

export type ExportValidationStatus = ExportValidationStatusItem[];

export const useGetExportValidationStatusQuery =
  (): QueryState<ExportValidationStatus> => {
    const { company: companyId } = useParams(
      routes.COMPANY_ACCOUNTING_INTEGRATION.path,
    );

    return useQuery<ExportValidationStatus>({
      key: ['useGetExportValidationStatusQuery', companyId],
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: '/accounting-integration/export-validation/status',
      },
    });
  };
