export function getNextEntityId(
  entities: { id: string }[],
  currentEntityId: string,
): string | null {
  for (let index = 0; index < entities.length; index++) {
    if (entities[index].id === currentEntityId) {
      const nextExpense = entities[index + 1];

      if (nextExpense !== undefined) {
        return nextExpense.id;
      }
      return null;
    }
  }

  return null;
}
