import React from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { KycWidgetSkeleton } from 'modules/homepage/ControllerHomepage/components/KycWidgetSkeleton';
import { useKycStatusCode } from 'modules/kyc/hooks/useKycStatusCode';

import { KycCompletion } from '../KycCompletion';
import { KycTimeline } from '../KycTimeline';

export const KycWidget = () => {
  const kycStatusCode = useKycStatusCode();
  const { isAccountOwner } = useUserRoles();
  const company = useCompany();

  if (!isAccountOwner || company.type === 'branch_expense_entity') {
    return null;
  }

  if (!kycStatusCode) {
    return <KycWidgetSkeleton />;
  }

  if (['not-started', 'initiated'].includes(kycStatusCode)) {
    return <KycCompletion />;
  }

  if (
    ['in-review', 'information-needed', 'signature-requested'].includes(
      kycStatusCode,
    )
  ) {
    return <KycTimeline kybStatusCode={kycStatusCode} />;
  }

  // kyc completed or error
  return null;
};
