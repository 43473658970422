import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import {
  useHasWireTransferDelegationFeature,
  useCompanyHasDelegationsQuery,
} from 'modules/delegation/hooks';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

export const useShowDelegationFeatureCallout = (): QueryState<boolean> => {
  const { isAccountOwner } = useUserRoles();
  const hasWireTransferDelegationFeature =
    useHasWireTransferDelegationFeature();
  const useCompanyHasDelegationsQueryState = useCompanyHasDelegationsQuery();

  return useQueryStates({
    states: {
      companyHasDelegations: useCompanyHasDelegationsQueryState,
    },
    reshapeData: ({ companyHasDelegations }) => {
      return (
        isAccountOwner &&
        hasWireTransferDelegationFeature &&
        !companyHasDelegations
      );
    },
  });
};
