import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { GET_ACCOUNT_PAYABLE } from '../queries/getAccountPayable';
import { type SupplierAccount } from '../types';

type RawData = {
  supplierAccount: {
    id: string;
    generalAccountCode: string;
    auxiliaryAccountCode: string | undefined;
    isArchived: boolean;
    isDefault: boolean;
  };
};

export const useAccountPayableQuery = (
  accountPayableId: string,
): QueryState<SupplierAccount> => {
  return useQuery<SupplierAccount, RawData>({
    key: ['accountPayable', accountPayableId],
    isEnabled: accountPayableId !== '-',
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_ACCOUNT_PAYABLE,
      variables: { accountPayableId },
    },
    reshapeData: (rawData) => {
      return {
        id: rawData.supplierAccount.id,
        generalAccountCode: rawData.supplierAccount.generalAccountCode,
        auxiliaryAccountCode: rawData.supplierAccount.auxiliaryAccountCode,
        isDefault: rawData.supplierAccount.isDefault,
        isArchived: rawData.supplierAccount.isArchived,
      };
    },
  });
};
