import { Skeleton, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const BudgetOverviewLoader = () => {
  return (
    <div className="box flex w-full flex-col">
      <SkeletonText className="mb-s" width="137px" size="xl" />
      <Skeleton className="mb-s" width="239px" height="26px" />
      <SkeletonText className="mb-s max-w-full" width="445px" size="l" />
      <div className="flex gap-xs">
        <SkeletonText width="106px" size="xl" />
        <SkeletonText width="106px" size="xl" />
      </div>
    </div>
  );
};
