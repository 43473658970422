import { type ApprovalRuleApi } from 'modules/company/structure/approval-flows';
import { apiUrl } from 'src/core/utils/api';

type EditionPayload = {
  name: string;
  ownerId: string;
  approvalRules: ApprovalRuleApi[];
};

export const editCostCenter = async ({
  id,
  payload,
  companyId,
}: {
  id: string;
  payload: EditionPayload;
  companyId: string;
}): Promise<{ approvalSchemeId: string }> => {
  const response = await fetch(apiUrl(`/cost-centers/${id}`, companyId), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error('Impossible to edit a cost center');
  }
  return response.json();
};
