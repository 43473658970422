import React, { useMemo } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { BudgetExpenseCategoriesBreakdownRow } from './BudgetExpenseCategoriesBreakdownRow';
import {
  type BreakdownPart,
  isBudgetBreakdownEmpty,
} from '../../models/breakdown';
import { type ExpenseCategory } from '../../models/expenseCategory';
import {
  type ExpenseCategoryBreakdown,
  isArchivedExpenseCategory,
  sortExpenseCategoryBreakdownsByExpenseCategory,
} from '../../models/expenseCategoryBreakdown';

type Props = {
  expenseCategoryBreakdown: ExpenseCategoryBreakdown[];
  expenseCategories: ExpenseCategory[];
  onGaugeSegmentClick: (
    breakdownPart: BreakdownPart,
    expenseCategoryBreakdown: ExpenseCategoryBreakdown,
  ) => void;
};

export const BudgetExpenseCategoriesBreakdownList = ({
  expenseCategoryBreakdown,
  expenseCategories,
  onGaugeSegmentClick,
}: Props) => {
  const { t } = useTranslation('global');

  const expenseCategoryBreakdownRows = useMemo(
    () => expenseCategoryBreakdown.filter((row) => shoudlDisplayBreakdown(row)),
    [expenseCategoryBreakdown],
  );

  if (expenseCategoryBreakdownRows.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col items-stretch gap-xs rounded-xs border border-solid border-neutral-light bg-white py-xs px-m pt-m shadow-10">
      <div className="text-complementary title-l">
        {t('budget.overviewPage.expenseCategories')}
      </div>
      <div className="flex flex-col items-stretch">
        {sortExpenseCategoryBreakdownsByExpenseCategory(
          expenseCategoryBreakdownRows,
          t,
        ).map((breakdown) => (
          <div
            key={`breakdownItem--${breakdown.expenseCategory.id}`}
            className="border-0 border-b border-solid border-b-neutral-lighter last:border-b-0"
          >
            <BudgetExpenseCategoriesBreakdownRow
              breakdown={breakdown}
              isExpenseCategoryArchived={isArchivedExpenseCategory(
                breakdown.expenseCategory.id,
                expenseCategories,
              )}
              onGaugeSegmentClick={onGaugeSegmentClick}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const shoudlDisplayBreakdown = (breakdown: ExpenseCategoryBreakdown): boolean =>
  !isBudgetBreakdownEmpty(breakdown) &&
  breakdown.expenseCategory.id !== 'default';
