import { type RawMember } from 'modules/members/models/member';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

export const useMembersQueryRaw = (
  isEnabled = true,
): QueryState<RawMember[]> => {
  return useQuery<RawMember[], RawMember[]>({
    key: ['users'],
    isEnabled,
    options: { staleTime: 5 * 60 * 1000, cacheTime: 5 * 60 * 1000 },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/users?includePending=true',
    },
  });
};
