import type {
  DraftExpense,
  Expense,
  RawExpense,
} from '@finance-review/models/expense';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';

import {
  type ExpensePayload,
  transformExpenseToApiPayload,
  transformRawExpense,
} from './transformer';
import { updateExpenseQueryData } from './useExpenseQuery';
import { REQUESTS_REIMBURSEMENTS_KEY } from './useExpensesQuery';

export const useUpdateExpense = (
  id: string,
  {
    onError,
    onSuccess,
  }: {
    onError?(queryError: QueryError<unknown>): void;
    onSuccess?(): void;
  } = {},
): MutationState<Expense, DraftExpense> => {
  const [updateExpense, ...rest] = useMutation<
    ExpensePayload,
    DraftExpense,
    RawExpense
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/${id}`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client, rawData }) => {
        updateExpenseQueryData(client, rawData);
        client.invalidateQueries(REQUESTS_REIMBURSEMENTS_KEY);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: ({ error }) => {
        if (onError) {
          onError(error);
        }
      },
    },
    reshapeData: transformRawExpense,
  });

  return [
    (expense: Expense) => updateExpense(transformExpenseToApiPayload(expense)),
    ...rest,
  ];
};
