import { useTeamsQuery, useTeamsQueryPromise } from 'modules/budgets/apis';
import { useIsTeamFeatureEnabled } from 'modules/budgets/features';
import { type QueryState, reshapeQueryState } from 'src/core/api/queryState';

import {
  getDefaultTeam,
  getIsTeamRequired,
  type Team,
} from '../../models/team';

export type TeamData = {
  teams: Team[];
  defaultTeam: Team | null;
  isTeamRequired: boolean;
};

type UseTeamData =
  | {
      teamsQueryPromise: () => Promise<TeamData>;
      teamsQueryState: QueryState<TeamData>;
      isTeamEnabled: true;
    }
  | {
      isTeamEnabled: false;
    };

export const useTeamData = (): UseTeamData => {
  const isTeamEnabled = useIsTeamFeatureEnabled();
  const teamsQueryState = useTeamsQuery({
    isEnabled: isTeamEnabled,
  });
  const teamsQueryPromise = useTeamsQueryPromise();

  return isTeamEnabled
    ? {
        isTeamEnabled,
        teamsQueryState: reshapeQueryState(teamsQueryState, toTeamData),

        teamsQueryPromise: () =>
          // eslint-disable-next-line promise/prefer-await-to-then
          teamsQueryPromise().then(toTeamData),
      }
    : {
        isTeamEnabled,
      };
};

const toTeamData = (teams: Team[]): TeamData => ({
  defaultTeam: getDefaultTeam(teams),
  isTeamRequired: getIsTeamRequired(teams),
  teams,
});
