import { format } from 'date-fns';

import { type InvoiceRequest } from '@finance-review/models/invoice';

const formatDatePattern = 'yyyy-MM-dd';

export type UpdateInvoiceRequestAPIPayload = {
  cost_center_id?: string;
  purchase_order_id?: string;
  amount_declared?: number;
  currency_declared?: string;
  description: string;
  supplier?: {
    id?: string;
    name?: string;
  };
  invoice_request?: {
    emission_date?: string | null;
    due_date?: string | null;
    invoice_number?: string;
    paymentMethod?: 'directDebit' | 'wireTransfer' | null;
    paymentDate?: string;
    payment_reference?: string;
  };
  scheduled_payments?: {
    id?: string;
    amount?: number;
    date?: string;
  }[];
  custom_fields_associations?: {
    customFieldId?: string;
    customFieldValueId?: string | null;
    value?: string | boolean;
  }[];
  group?: {
    id?: string;
    name?: string;
    key?: string;
  };
};

export const transformInvoiceRequestToAPIPayload = (
  invoiceRequest: InvoiceRequest.Entity,
): UpdateInvoiceRequestAPIPayload => ({
  cost_center_id: invoiceRequest.costCenterId,
  purchase_order_id: invoiceRequest.purchaseOrderId,
  amount_declared: invoiceRequest.amount,
  currency_declared: invoiceRequest.currency,
  description: invoiceRequest.description,
  supplier: {
    id: invoiceRequest.supplierId,
  },
  invoice_request: {
    emission_date: format(invoiceRequest.emissionDate, formatDatePattern),
    due_date: invoiceRequest.dueDate
      ? format(invoiceRequest.dueDate, formatDatePattern)
      : undefined,
    invoice_number: invoiceRequest.invoiceNumber,
    paymentMethod: invoiceRequest.paymentMethod,
    paymentDate: invoiceRequest.paymentDate
      ? format(invoiceRequest.paymentDate, formatDatePattern)
      : undefined,
    payment_reference: invoiceRequest.paymentReference,
  },
  scheduled_payments: invoiceRequest.paymentSchedule
    ? invoiceRequest.paymentSchedule.map((scheduledPayment) => ({
        ...scheduledPayment,
        date: format(scheduledPayment.date, formatDatePattern),
      }))
    : undefined,
  custom_fields_associations: invoiceRequest.customFieldsAssociations,
  group: { id: invoiceRequest.teamId },
});
