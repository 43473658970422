import { InfoTip } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { formatMoney } from "src/core/utils/money";

import { type Member } from '../../../../models/member';

type Props = {
  member: Member;
  company: { currency: string };
};

export const MemberDetailsModalPolicy = ({ member, company }: Props) => {
  const { t } = useTranslation('global');
  const { isAccountOwner, policy } = member;

  if (isAccountOwner) {
    return (
      <div className="text-neutral-dark">
        {t('members.namedAccountOwnerNoLimit', {
          userName: member.fullname,
        })}
      </div>
    );
  }

  if (!policy) {
    return (
      <div className="text-neutral-dark">
        {t('members.memberHasNoSpendingPolicy')}
      </div>
    );
  }

  let tooltipContent: React.ReactChild | React.ReactChild[] = '';

  switch (policy.params?.approvalNeeded) {
    case 'always':
      tooltipContent = t('approvalPolicy.table.always') as string;
      break;
    case 'never':
      tooltipContent = t('approvalPolicy.table.never') as string;
      break;
    // eslint-disable-next-line unicorn/no-useless-switch-case
    case 'sometimes':
    default:
      tooltipContent = (
        <ul>
          <li>
            {t('members.countAmountPerTransaction', {
              amount: formatMoney(
                policy.params?.amountPerTransaction ?? 0,
                company.currency,
              ),
            })}
          </li>
          <li>
            {t('members.countSpendingLimit', {
              amount: formatMoney(
                policy.params?.amountPerMonth ?? 0,
                company.currency,
              ),
            })}
          </li>
        </ul>
      );
      break;
  }

  if (member.isRequester) {
    return (
      <div className="flex items-center gap-xs text-complementary">
        {policy.isCustom ? t('members.customApprovalPolicy') : policy.name}
        <InfoTip content={tooltipContent} />
      </div>
    );
  }

  return null;
};
