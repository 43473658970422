import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  type DetailedWalletSummary,
  type WalletSummary,
  getWalletTotalAllocated,
  hasDetailedWalletSummary,
} from './walletSummary';

export function buildShortfallCalloutTitle({
  walletSummary,
  t,
}: {
  walletSummary: WalletSummary | DetailedWalletSummary;
  t: TGlobalFunctionTyped;
}) {
  if (!walletSummary.totalAmount) {
    return '';
  }

  const hasWalletDetails = hasDetailedWalletSummary(walletSummary);

  const totalAllocated = hasWalletDetails
    ? formatMonetaryValue(getWalletTotalAllocated(walletSummary))
    : '-';

  const hasScheduledTransfersDetails =
    hasWalletDetails && !!walletSummary.walletDetails?.scheduledTransfers;

  const totalWallet = formatMonetaryValue(walletSummary.totalAmount);

  return hasScheduledTransfersDetails
    ? t('wallet.walletDetails.expectedTotalAllocatedShortfallCallout', {
        totalAmount: totalWallet,
        totalAllocated,
      })
    : t('wallet.walletDetails.expectedShortfallCallout', {
        totalAmount: totalWallet,
        allocatedOnCardsAmount: totalAllocated,
      });
}
