import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { SET_ACCOUNTS_PAYABLE } from '../queries/setAccountsPayable';

type Payload = {
  accountsUpdates: {
    id?: string;
    name?: string;
    kind: 'supplierAccount' | 'employeeAccount';
    generalAccountCode: string;
    auxiliaryAccountCode?: string | undefined;
    isDefault: boolean;
    isArchived: boolean;
    memberIds?: string[];
  }[];
};

export type SetAccountsPayableMutationFailureReason =
  | 'codeAlreadyExists'
  | 'required'
  | 'integrationValidationFailed'
  | 'invalidPattern';

type RawResponse = {
  setAccountsPayable: {
    reason?: SetAccountsPayableMutationFailureReason;
    accountsPayable?: { id: string }[];
  };
};

export type SetAccountsPayableMutationResponse = {
  reason?: SetAccountsPayableMutationFailureReason;
  id?: string;
};

export const useSetAccountsPayableMutation = (): MutationState<
  Payload,
  SetAccountsPayableMutationResponse
> => {
  return useMutation<Payload, SetAccountsPayableMutationResponse, RawResponse>({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: SET_ACCOUNTS_PAYABLE,
    },
    reshapeData: (data) => {
      if (data.setAccountsPayable.reason) {
        return {
          reason: data.setAccountsPayable.reason,
        };
      }

      return {
        id: data.setAccountsPayable.accountsPayable
          ? data.setAccountsPayable.accountsPayable[0]?.id
          : undefined,
      };
    },
    options: {
      onSuccess: (data): void => {
        data.client.invalidateQueries('accountsPayable');
      },
    },
  });
};
