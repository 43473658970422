import React from 'react';

import { SiderPageLayout } from 'common/components/layout/SiderPageLayout';

import { PurchaseOrdersTableSkeleton } from './PurchaseOrdersTable/PurchaseOrdersTableSkeleton';

type Props = {
  className?: string;
};

export const PurchaseOrdersPageSkeleton = ({ className }: Props) => {
  return (
    <SiderPageLayout>
      <PurchaseOrdersTableSkeleton title="" className={className} />
    </SiderPageLayout>
  );
};
