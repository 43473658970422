import { RadioInput } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { type ReactNode } from 'react';

import styles from './AccountTypeButton.module.css';

type AccountTypeButtonProps = {
  image: string;
  title: string;
  subtitle: ReactNode;
  onClick: (accountType: string) => void;
  isDisabled?: boolean;
  isChecked: boolean;
  name: string;
  value: string;
};

export const AccountTypeButton = ({
  name,
  image,
  title,
  subtitle,
  isChecked,
  onClick,
  isDisabled = false,
  value,
}: AccountTypeButtonProps) => {
  return (
    <label
      className={cx(styles.AccountTypeButton, {
        [styles.disabled]: isDisabled,
        [styles.selected]: isChecked,
      })}
    >
      <RadioInput
        onChange={(event) => {
          onClick(event.target.value);
        }}
        name={name}
        value={value}
        isChecked={isChecked}
        className={styles.radio}
        isDisabled={isDisabled}
      />
      <img className={styles.AccountTypeButton__image} src={image} alt="" />
      <h4 className="text-complementary title-m">{title}</h4>
      <p className="body-m">{subtitle}</p>
    </label>
  );
};
