import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import { t } from 'i18next';
import React, { useMemo, useState } from 'react';

import { WarningRow } from 'common/components/WarningRow/WarningRow';
import { useTranslation } from 'common/hooks/useTranslation';

type DeletionStep = 'first' | 'second';

type Props = {
  name: string;
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
};

export const CustomFieldDeletionModal = ({
  name,
  isOpen,
  onClose,
  onSubmit,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { t } = useTranslation();
  const [step, setStep] = useState<DeletionStep>('first');

  const isFirstStep = useMemo(() => step === 'first', [step]);

  return (
    <Modal
      isOpen={isOpen}
      iconVariant="alert"
      title={t('customFields.deletion.title')}
      subtitle={
        step == 'second'
          ? t('customFields.deletion.subtitle', { name })
          : undefined
      }
      iconName="failure"
      actions={[
        <Button
          key="cancel-button"
          text={t('customFields.deletion.cancel')}
          type="button"
          variant="secondary"
          onClick={onClose}
        />,
        isFirstStep ? (
          <Button
            key="continue-button"
            text={t('customFields.deletion.continue')}
            iconName="caret-right"
            iconPosition="right"
            type="button"
            variant="alert"
            onClick={() => setStep('second')}
          />
        ) : (
          <Button
            key="submit-button"
            text={t('customFields.deletion.submit')}
            type="button"
            variant="alert"
            onClick={() => onSubmit()}
          />
        ),
      ]}
    >
      {isFirstStep ? (
        t('customFields.deletion.description')
      ) : (
        <DeletionDetails />
      )}
    </Modal>
  );
};

const DeletionDetails = () => {
  return (
    <div>
      <div className="mb-xs text-left title-m">
        {t('customFields.deletion.transactionsLabel')}
      </div>
      <Callout
        className="mx-xs"
        variant="success"
        title={t('costCenters.deletion.alreadyExported')}
      />
      <WarningRow text={t('customFields.deletion.warningPayments')} />
      <WarningRow text={t('customFields.deletion.warningApprovedRequests')} />
      <WarningRow text={t('customFields.deletion.warningPendingRequests')} />
      <WarningRow text={t('customFields.deletion.warningSubscriptions')} />
    </div>
  );
};
