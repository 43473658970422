import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { type DefaultVendorTypes } from '../../accounting/vendor';

type Payload = {
  type: DefaultVendorTypes;
};

export const useDeleteDefaultVendorMutation = (): MutationState<Payload> => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'delete',
      endpoint: '/accounting-integration/supplier-sync/default',
    },
    options: {
      onSuccess({ client }): void {
        client.invalidateQueries('useGetDefaultVendorsQuery');
      },
    },
  });
};
