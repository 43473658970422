import appConfig from 'src/core/config';

import { type Language } from '../../config/i18n';

export const getLegalNoticeLink = (): string => {
  return 'https://helpcenter.spendesk.com/en/articles/4168878-our-legal-notice';
};

export const getTermsOfServiceLink = (activeLanguage: Language): string => {
  return `${appConfig.wwwBaseUrl}/${activeLanguage}/legals/terms`;
};

export const getPrivacyPolicyLink = (activeLanguage: Language): string => {
  return `${appConfig.wwwBaseUrl}/${activeLanguage}/legals/privacy`;
};

export const getCookiePolicyLink = (activeLanguage: Language): string => {
  return `${appConfig.wwwBaseUrl}/${
    // There is no page for German yet
    ['fr', 'en'].includes(activeLanguage) ? activeLanguage : 'en'
  }/legals/cookies-policy`;
};
