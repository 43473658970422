import {
  Button,
  Callout,
  FormField,
  ModalFooter,
  TextArea,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import isValidEmail from 'src/auth/utils/isValidEmail';
import appConfig from 'src/core/config';

import { type InviteField } from '../../../models/invite';

type Props = {
  resetSettings: () => void;
  setEmailInputType: (emailInputType: 'fields' | 'text') => void;
  setInviteFields: (inviteFields: InviteField[]) => void;
};

type EmailListState =
  | {
      hasTooManyEmails: true;
      numInviting: number;
    }
  | {
      hasTooManyEmails: false;
    };

export const EmailsInputText = ({
  setEmailInputType,
  resetSettings,
  setInviteFields,
}: Props) => {
  const [emailsListValue, setEmailsListValue] = useState('');
  const [emailListState, setEmailListState] = useState<EmailListState>({
    hasTooManyEmails: false,
  });
  const { t } = useTranslation('global');
  const MAX_EMAIL_NUMBER = appConfig.maxBulkImportMembers;

  const setFieldsFromText = () => {
    const emails = [
      ...new Set(emailsListValue.split(',').map((email) => email.trim())),
    ].filter(Boolean);

    if (emails.length > MAX_EMAIL_NUMBER) {
      setEmailListState({
        hasTooManyEmails: true,
        numInviting: emails.length,
      });
      return;
    }

    const emailFields = emails.map((value, index) => ({
      value,
      index,
      valid: isValidEmail(value),
    }));

    setEmailsListValue('');
    setEmailInputType('fields');
    resetSettings();

    setInviteFields(emailFields);
  };

  const renderActions = () => (
    <>
      <Button
        variant="secondary"
        text={t('misc.cancel')}
        onClick={() => setEmailInputType('fields')}
      />
      <Button text={t('members.addInvitees')} onClick={setFieldsFromText} />
    </>
  );

  return (
    <>
      <FormField
        className="mt-s"
        label={t('members.enterMultipleEmailAddresses')}
        hint={t('members.emailAddressesHint', {
          count: MAX_EMAIL_NUMBER,
        })}
        alertMessage={
          emailListState.hasTooManyEmails
            ? t('members.tooManyEmailsError', {
                inviteLimit: MAX_EMAIL_NUMBER,
                numInviting: emailListState.numInviting,
              })
            : ''
        }
      >
        <TextArea
          value={emailsListValue}
          isInvalid={emailListState.hasTooManyEmails}
          onChange={(event) => {
            setEmailListState({ hasTooManyEmails: false });
            setEmailsListValue(event.target.value);
          }}
        />
      </FormField>
      <Callout
        className="mt-s"
        title={`${t('members.tip')}: ${t(
          'members.separateAddressesWithCommas',
        )}`}
      />
      <ModalFooter>{renderActions()}</ModalFooter>
    </>
  );
};
