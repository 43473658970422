import { type FormikErrors } from 'formik';

import { type CostCenter } from 'modules/company/cost-centers/costCenter';
import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { type FormValues } from './formValues';
import { validate as validateApprovalRules } from '../../../structure/approval-flows/models';

type FormErrors = FormikErrors<FormValues>;

const hasCostCenterWithSameName = (
  name: string,
  costCenterId: string | undefined,
  costCenters: CostCenter[],
): boolean => {
  return costCenters.some(
    (costCenter) =>
      costCenter.id !== costCenterId &&
      costCenter.name.toLowerCase() === name.toLowerCase(),
  );
};

export const validateCostCenterForm = (
  values: FormValues,
  translator: TGlobalFunctionTyped,
  costCenterId: string | undefined,
  costCenters: CostCenter[],
  costCenterOwnerId: string,
): FormErrors => {
  const errors: FormErrors = {};

  if (hasCostCenterWithSameName(values.name, costCenterId, costCenters)) {
    errors.name = translator('costCenters.form.errors.nameAlreadyExists', {
      name: values.name,
    });
  }

  if (values.name?.trim() === '') {
    errors.name = translator('costCenters.form.errors.nameEmpty');
  }

  if (!values.ownerId) {
    errors.ownerId = translator('costCenters.form.errors.ownerEmpty');
  }

  if (values.approvalRules.length === 0) {
    errors.approvalRules = translator(
      'costCenters.form.errors.approvalFlowEmpty',
    );
  }

  if (
    values.approvalRules.some((rule) =>
      rule.steps.some((step) => step.rights.length === 0),
    )
  ) {
    errors.approvalRules = translator(
      'costCenters.form.errors.approvalFlowEmpty',
    );
  }

  const approvalRulesContainDuplicateApprovers = values.approvalRules.some(
    (approvalRule) => {
      const approverIds = approvalRule.steps.flatMap((step) => {
        return step.rights.map((right) => {
          if (right.approverType === 'costCenterOwner') {
            return `${right.approverType}-${costCenterOwnerId}`;
          }
          if (right.approverType === 'user') {
            return `${right.approverType}-${right.approverId}`;
          }
          if (right.approverType === 'reportingManager') {
            return right.approverType;
          }
          return '';
        });
      });
      return approverIds.length !== new Set(approverIds).size;
    },
  );

  if (approvalRulesContainDuplicateApprovers) {
    errors.approvalRules = translator(
      'costCenters.form.errors.approvalFlowDuplicateApprovers',
    );
  }

  const erroredRuleIds = validateApprovalRules(values.approvalRules);
  if (erroredRuleIds.length > 0) {
    errors.approvalRules = erroredRuleIds;
  }

  return errors;
};
