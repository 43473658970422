import { format } from 'date-fns';

import { type Expense } from '@finance-review/models/expense';
import type { CustomFieldAssociation } from 'modules/budgets/models/customFieldAssociation';

type ExpenseClaimPayload = {
  amount_declared: number;
  amount_to_refund: number;
  currency_declared: string;
  description: string;
  custom_fields_associations?: CustomFieldAssociation[];
  cost_center_id?: string;
  group_id?: string;
  supplier_id: string | undefined;
  supplier_name: string;
  expense_paid_at: string;
  // @SpendingFlows: to remove once the new form fully rolled out
  tmp_new_form?: boolean;
};

type MileageExpensePayload = {
  description: string;
  custom_fields_associations?: CustomFieldAssociation[];
  cost_center_id?: string;
  group_id?: string;
  // @SpendingFlows: to remove once the new form fully rolled out
  tmp_new_form?: boolean;
};

export type ExpensePayload = ExpenseClaimPayload | MileageExpensePayload;

export const transformExpenseToApiPayload = (
  expense: Expense,
): ExpensePayload => {
  if (expense.type === 'expense_claim') {
    return {
      amount_declared: expense.amount,
      amount_to_refund: expense.amountToReimburse,
      currency_declared: expense.currency,
      description: expense.description,
      custom_fields_associations: expense.customFieldsAssociations,
      cost_center_id: expense.costCenterId,
      group_id: expense.teamId,
      expense_paid_at: format(expense.expenseDate, 'yyyy-MM-dd'),
      supplier_id: expense.supplier.id,
      supplier_name: expense.supplier.name,
      tmp_new_form: true,
    };
  }

  return {
    description: expense.description,
    custom_fields_associations: expense.customFieldsAssociations,
    cost_center_id: expense.costCenterId,
    group_id: expense.teamId,
    tmp_new_form: true,
  };
};
