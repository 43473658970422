import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  endpointParams?: { transferringUserId: string | undefined };
};

export const useDeleteMemberMutation = (
  memberId: string,
): MutationState<Payload> => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: (params): string =>
        `/users/${memberId}?transfering_user_id=${params?.transferringUserId}`,
      method: 'delete',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }): void => {
        client.invalidateQueries(['users'], { exact: true });
        client.invalidateQueries(['users', memberId]);
      },
    },
  });
};
