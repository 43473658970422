/* eslint-disable unicorn/no-await-expression-member */
import React from 'react';

import { EmptyStateError, lazyLoad } from 'src/core/common/components/LazyLoad';
import { Spinner } from 'src/core/common/components/Spinner/Spinner';

export const LazyLoadedSignature = lazyLoad({
  loader: async () => ({
    default: (await import(/* webpackChunkName: "Signature" */ './Signature'))
      .Signature,
  }),
  loading: (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner />
    </div>
  ),
  fallback: (props) => <EmptyStateError {...props} />,
});
