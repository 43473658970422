import type { RawMember } from 'modules/members/models/member';
import { useQuery } from 'src/core/api/hooks';
import type { QueryState } from 'src/core/api/queryState';

export const useMemberQuery = (
  memberId: string | null,
): QueryState<RawMember> => {
  return useQuery<RawMember, RawMember>({
    key: ['users', memberId],
    isEnabled: !!memberId,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users/${memberId}`,
    },
  });
};
