import identity from 'lodash/identity';

import { type Beneficiary } from 'modules/invoices/transfer/models';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type Payload = {
  endpointParams: {
    batchId: string;
  };
};

export type Error = unknown;
export type Response = Beneficiary.VerificationResult[];

export const useVerifyExpenseTransfersBatchBeneficiariesQuery =
  (): MutationState<Payload, Response, Error> => {
    return useMutation<Payload, Response, Response, Error>({
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: ({ batchId }) =>
          `/scheduled_payments/batches/${batchId}/verify-beneficiaries`,
        method: 'post',
      },
      options: {
        throwOnError: true,
      },
      reshapeData: identity,
    });
  };
