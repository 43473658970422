import { gql } from 'graphql-tag';

export const GET_ACTIVE_ACCOUNTING_INTEGRATION = gql`
  query GetActiveAccountingIntegration($companyId: ID!) {
    company(id: $companyId) {
      id
      activeAccountingIntegration
    }
  }
`;

export const GET_ACCOUNTING_INTEGRATION_HISTORY = gql`
  query GetAccountingIntegrationHistory($companyId: ID!) {
    company(id: $companyId) {
      id
      accountingIntegrationHistory {
        accountingIntegrationId
        accountingCountry
        from
        to
      }
    }
  }
`;

export const GET_AVAILABLE_ACCOUNTING_INTEGRATIONS = gql`
  query GetAvailableAccountingIntegrations($companyId: ID!) {
    company(id: $companyId) {
      id
      availableAccountingIntegrationsV2 {
        accountingIntegration
        coreCapabilitySet {
          spendeskBankAccount
          fundingBankAccount
          bankFeesAccount
          employeeAccounts
          expenseAccounts
          supplierAccounts
          taxAccounts
          analyticalFields
          settingsRefresh
          settingsValidation
          invoiceNumber
          settlements
          payables
          payablesSettlementsReExport
          continuousAccounting
          customExports
          bankFeeExport
          switchIntegration
        }
      }
    }
  }
`;
