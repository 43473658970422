import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type NotePayload = {
  title: string;
  content: string;
  tags?: string[];
};

export const usePublishNoteToProductBoard = (): MutationState<NotePayload> => {
  return useMutation<NotePayload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/product-board/publish-note`,
      method: 'post',
    },
    options: {},
  });
};
