import { wwwBaseUrl } from "src/core/config";

export enum MobileDeviceTypes {
  IOS,
  Android,
  Unknown,
}

export const mobileAppDownloadLinks: Record<MobileDeviceTypes, string> = {
  [MobileDeviceTypes.IOS]:
    'https://apps.apple.com/fr/app/spendesk/id1189271166',
  [MobileDeviceTypes.Android]:
    'https://play.google.com/store/apps/details?id=com.spendesk.spendesk',
  [MobileDeviceTypes.Unknown]: `${wwwBaseUrl}/mobile`,
};

export const isAndroid = (userAgent: string): boolean =>
  /android/i.test(userAgent);

export const isIOS = (userAgent: string): boolean =>
  /ipad|iphone|ipod/i.test(userAgent);

export const getMobileDeviceType = (userAgent: string): MobileDeviceTypes => {
  if (isAndroid(userAgent)) {
    return MobileDeviceTypes.Android;
  }

  if (isIOS(userAgent)) {
    return MobileDeviceTypes.IOS;
  }

  return MobileDeviceTypes.Unknown;
};

export const getMobileAppDownLoadLink = (userAgent: string): string => {
  const mobileDeviceType = getMobileDeviceType(userAgent);
  return mobileAppDownloadLinks[mobileDeviceType];
};
