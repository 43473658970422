import * as R from '@dev-spendesk/general-type-helpers/Result';
import { Callout } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUserRoles } from 'src/core/modules/app/hooks/useUserRoles';

import * as MemberModel from '../../models/member';

type Props = {
  member: MemberModel.MemberDetails;
  isControllerValue: boolean;
};

export const AddionalRightsNotEditableCallout = ({
  member,
  isControllerValue,
}: Props) => {
  const { t } = useTranslation('global');
  const currentUserRoles = useUserRoles();
  const canEditAdditionalRights =
    MemberModel.canEditAdditionalRights(currentUserRoles);
  const memberEligibilityToWireTransferDelegation =
    MemberModel.getEligibilityToWireTransferDelegation(
      member,
      isControllerValue,
    );

  function getCalloutDescription() {
    if (!canEditAdditionalRights) {
      return t('members.editModal.additionalRights.nonEditableCallout');
    }
    if (R.isFailure(memberEligibilityToWireTransferDelegation)) {
      const { reason } = memberEligibilityToWireTransferDelegation.error;
      if (reason === 'notController') {
        return t('members.editModal.additionalRights.memberNotController');
      }
    }
  }
  const description = getCalloutDescription();

  return description ? <Callout variant="info" title={description} /> : null;
};
