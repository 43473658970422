import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type IntegrationStatus } from '../types';
import { integrationStatusSchema } from '../types/integration-status';

export const useGetIntegrations = (): QueryState<IntegrationStatus> => {
  const { company: companyId } = useParams();
  return useQuery<IntegrationStatus, IntegrationStatus>({
    key: ['integrationsStatus', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/integrations/status',
    },
    reshapeData(rawResponse) {
      return integrationStatusSchema.parse(rawResponse);
    },
  });
};
