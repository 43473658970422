import React from 'react';

import { ImageViewer, PDFViewer } from 'common/components/FileViewer';

export type MediaType =
  | 'application/pdf'
  | 'image/png'
  | 'image/jpeg'
  | 'image/gif';

type Props = {
  className?: string;
  fileUrl: string;
  fileMediaType: MediaType;
};

export const SupplierFileViewer = ({
  className,
  fileUrl,
  fileMediaType,
}: Props) => (
  <div className="box mt-s p-xs">
    {fileMediaType === 'application/pdf' ? (
      <PDFViewer
        className={className}
        url={fileUrl}
        onDownload={() => window.open(fileUrl, '_blank')}
      />
    ) : (
      <ImageViewer
        withoutCrossOrigin
        className={className}
        url={fileUrl}
        withZoom
        onDownload={() => window.open(fileUrl, '_blank')}
      />
    )}
  </div>
);
