import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { GET_CUSTOM_FIELD_VALUES } from './query';

export type CustomFieldValue = {
  id: string;
  name: string;
  archiveDate: null | string;
};

type RawData = {
  company: {
    customFields: {
      values: {
        edges: {
          node: CustomFieldValue;
        }[];
      };
    }[];
  };
};

export const useValuesOfCustomFieldsQuery = (
  filter: { customFieldValuesIds: string[]; customFieldIds?: string[] } = {
    customFieldValuesIds: [],
    customFieldIds: [],
  },
): QueryState<Map<string, CustomFieldValue>> => {
  const { company: companyId } = useParams();

  return useQuery<Map<string, CustomFieldValue>, RawData>({
    key: ['useValuesOfCustomFieldsQuery', companyId, filter],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_CUSTOM_FIELD_VALUES,
      variables: {
        filter,
      },
    },
    reshapeData: (rawData) => {
      const idsSet = new Set(filter.customFieldValuesIds);
      const customFieldValuesInfos = new Map<string, CustomFieldValue>();

      if (!Array.isArray(rawData.company?.customFields)) {
        return new Map();
      }

      rawData.company.customFields.forEach((cf) => {
        if (!Array.isArray(cf.values?.edges)) {
          return;
        }

        cf.values.edges.forEach((cfv) => {
          if (idsSet.has(cfv.node.id)) {
            customFieldValuesInfos.set(cfv.node.id, cfv.node);
          }
        });
      });

      return customFieldValuesInfos;
    },
  });
};
