import { gql } from 'graphql-tag';

export const query = gql`
  query PayablesCount(
    $companyId: ID!
    $payableStates: [PayableState!]
    $settlementState: SettlementState
    $documentaryEvidenceStatus: DocumentaryEvidenceStatus
    $search: String
    $costCenter: String
    $creationDate: PartialDateRange
    $payableTypes: [PayableType!]
  ) {
    company(id: $companyId) {
      payables(
        sort: desc
        filter: {
          state: $payableStates
          settlementState: $settlementState
          documentaryEvidenceStatus: $documentaryEvidenceStatus
          search: $search
          costCenter: $costCenter
          creationDate: $creationDate
          types: $payableTypes
        }
      ) {
        totalCount
      }
    }
  }
`;

export type RawResult = {
  company: {
    payables: {
      totalCount: number;
    };
  };
};
