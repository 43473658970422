import { matchPath, useLocation } from 'react-router-dom';

import { routes as appRoutes } from 'src/core/constants/routes';

export const useIsOnboardingHubPath = () => {
  const location = useLocation();
  return Boolean(
    matchPath(location.pathname, {
      path: appRoutes.ONBOARDING_HUB.path,
      exact: false,
      strict: false,
    }),
  );
};
