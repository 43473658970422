import queryString from 'query-string';

import * as types from './actionTypes';

// Allow to indicate that the app is loaded from a webview
const { webview } = queryString.parse(window.location.search);
const isWebviewMode = webview === 'true';

export type LayoutState = {
  displayMode: 'webview' | 'browser';
  budget: number | null;
};

const initialState: LayoutState = {
  displayMode: isWebviewMode ? 'webview' : 'browser',
  // FIXME: the data below shouldn't be stored in the app layout state
  budget: null,
};

export const layoutReducer = (
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: { type: string; payload: any },
): LayoutState => {
  switch (action.type) {
    case types.FETCH_MONTHLY_BUDGET_SUCCESS:
      return {
        ...state,
        budget: action.payload.budget,
      };
    default:
      return state;
  }
};
