/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ApprovalSchemeApi } from '../types';

interface SaveSchemaRequest {
  type: 'approval/SAVE_SCHEMA_REQUEST';
}

export interface SaveSchemaSuccess {
  type: 'approval/SAVE_SCHEMA_SUCCESS';
  payload: ApprovalSchemeApi;
}

export interface SaveSchemaFailure {
  type: 'approval/SAVE_SCHEMA_FAILURE';
  payload: any;
}

interface FetchSchemaRequest {
  type: 'approval/FETCH_SCHEMA_REQUEST';
}

export interface FetchSchemaSuccess {
  type: 'approval/FETCH_SCHEMA_SUCCESS';
  payload: ApprovalSchemeApi;
}

export interface FetchSchemaFailure {
  type: 'approval/FETCH_SCHEMA_FAILURE';
  payload: any;
}

export type ApprovalAction =
  | SaveSchemaRequest
  | SaveSchemaSuccess
  | SaveSchemaFailure
  | FetchSchemaRequest
  | FetchSchemaSuccess
  | FetchSchemaFailure;

export const FETCH_SCHEMA_REQUEST = 'approval/FETCH_SCHEMA_REQUEST';
export const FETCH_SCHEMA_SUCCESS = 'approval/FETCH_SCHEMA_SUCCESS';
export const FETCH_SCHEMA_FAILURE = 'approval/FETCH_SCHEMA_FAILURE';
export const SAVE_SCHEMA_REQUEST = 'approval/SAVE_SCHEMA_REQUEST';
export const SAVE_SCHEMA_SUCCESS = 'approval/SAVE_SCHEMA_SUCCESS';
export const SAVE_SCHEMA_FAILURE = 'approval/SAVE_SCHEMA_FAILURE';

export const fetchSchemaRequest = (): FetchSchemaRequest => ({
  type: FETCH_SCHEMA_REQUEST,
});

export const fetchSchemaSuccess = (
  schema: ApprovalSchemeApi,
): FetchSchemaSuccess => ({
  type: FETCH_SCHEMA_SUCCESS,
  payload: schema,
});

export const fetchSchemaFailure = (error: any): FetchSchemaFailure => ({
  type: FETCH_SCHEMA_FAILURE,
  payload: error,
});

export const saveSchemaRequest = (): SaveSchemaRequest => ({
  type: SAVE_SCHEMA_REQUEST,
});

export const saveSchemaSuccess = (
  schema: ApprovalSchemeApi,
): SaveSchemaSuccess => ({
  type: SAVE_SCHEMA_SUCCESS,
  payload: schema,
});

export const saveSchemaFailure = (error: any): SaveSchemaFailure => ({
  type: SAVE_SCHEMA_FAILURE,
  payload: error,
});
