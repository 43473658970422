import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { useSynchronizeRequestRemoval } from './reduxStoreSynchronization';
import { type RequestId } from '../models';

type Props = {
  onSuccess?(): void;
};

export const useArchiveRequest = (
  requestId: RequestId,
  { onSuccess }: Props = {},
): MutationState<void, void, unknown> => {
  const synchronizeRequestRemoval = useSynchronizeRequestRemoval();
  return useMutation({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/${requestId}/archive`,
      method: 'put',
    },
    options: {
      onSuccess: () => {
        synchronizeRequestRemoval(requestId);
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  });
};
