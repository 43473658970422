import { Button } from '@dev-spendesk/grapes';
import get from 'lodash/get';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { TokenInput } from 'common/components/TokenInput';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { routes, routeFor } from 'src/core/constants/routes';

import BackgroundCardActivation from './img/background.png';
import { CardDesign } from '../../../components/CardDesign';
import * as cardActions from '../../redux/actions';

import './RecardActivation.css';

type Props = {
  card: {
    id: string;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    city: string;
    country: string;
    brand: string;
  };
  fetchPlasticCard: (cardId: string) => void;
  activateCard: (
    recardId: string,
    digits: string,
  ) => Promise<{ outcome: string; card?: Card }>;
};

type Card = {
  card_name: string;
  card_visible_number: string;
  status: string;
  card_exp_date: string;
  activation_status: string;
};

const RecardActivation = ({ card, activateCard, fetchPlasticCard }: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();

  const [activationStep, setActivationStep] = useState(1);
  const [digitsValue, setDigitsValue] = useState(['', '', '', '']);
  const [activating, setActivating] = useState(false);

  const [newCard, setNewCard] = useState<Card | undefined>();
  const [fetchingNewCard, setFetchingNewCard] = useState(false);
  const [confettiShown, setConfettiShown] = useState(false);

  const activate = useCallback(async () => {
    setActivating(true);
    const recardId: string = get(
      card,
      'recard_request.next_card_order.card_id',
      '',
    );
    const result = await activateCard(recardId, digitsValue.join(''));
    if (result.outcome === 'error') {
      setActivating(false);
    } else {
      setNewCard(result.card);
      setActivationStep(3); // Show success
    }
  }, [activateCard, card, digitsValue]);

  const isValid =
    digitsValue.length === 4 &&
    digitsValue.every((value) => value && value !== '');

  useEffect(() => {
    const onWindowKeydown = (event: KeyboardEvent) => {
      if (
        activationStep === 2 &&
        isValid &&
        event.keyCode === 13 &&
        !activating
      ) {
        activate();
      }
    };
    window.addEventListener('keydown', onWindowKeydown);
    return () => {
      window.removeEventListener('keydown', onWindowKeydown);
    };
  }, [activate, activating, activationStep, isValid]);

  if (activationStep === 1) {
    return (
      <div className="RecardActivation mx-auto mt-l w-[420px]">
        <h1 className="mb-xs text-complementary title-xl">
          {t('recard.activation.step1.title')}
        </h1>
        <p className="mb-m text-neutral-darker body-m">
          {t('recard.activation.step1.subtitle')}
        </p>
        <div className="box">
          <p className="title-m">{t('recard.activation.step1.notice.title')}</p>
          <ul className="mt-m list-disc body-m">
            <li className="mb-s ml-m">
              {t('recard.activation.step1.notice.pendingPayments')}
            </li>
            <li className="mb-s ml-m">
              {t('recard.activation.step1.notice.updateCard')}
            </li>
          </ul>
          <p className="mb-l text-neutral-dark body-s">
            {t('recard.activation.step1.notice.footnote')}
          </p>
          <div className="flex justify-between">
            <Link to={routeFor(routes.CARD.path, { company: company.id })}>
              <Button
                variant="secondary"
                text={t('recard.activation.step1.doLater')}
              />
            </Link>
            <Button
              text={t('recard.activation.step1.continue')}
              onClick={() => setActivationStep(2)}
            />
          </div>
        </div>
      </div>
    );
  }

  if (activationStep === 2) {
    return (
      <div className="RecardActivation mx-auto mt-l w-[420px]">
        <h1 className="mb-xs text-complementary title-xl">
          {t('recard.activation.step2.title')}
        </h1>
        <p className="mb-m text-neutral-darker body-m">
          {t('recard.activation.step2.subtitle1')}
          <br />
          {t('recard.activation.step2.subtitle2')}
        </p>
        <div className="box relative overflow-hidden p-0">
          <div>
            <img
              className="w-full"
              src={BackgroundCardActivation}
              alt="Card activation background"
            />
            <CardDesign
              width={257}
              numbers={`............${digitsValue.join('')}`}
              isActivating
            />
          </div>
          <div className="p-m">
            <h2 className="mb-xs text-neutral-darker title-m">
              {t('recard.activation.step2.enterDigits')}
            </h2>
            <TokenInput
              token={digitsValue}
              tokenLength={4}
              onTokenChange={setDigitsValue}
            />
            <div className="mt-l flex justify-between">
              <Button
                variant="secondary"
                text={t('recard.activation.step2.back')}
                isDisabled={activating}
                onClick={() => {
                  setActivationStep(1);
                  setDigitsValue(['', '', '', '']);
                }}
              />
              <Button
                text={
                  activating
                    ? t('recard.activation.step2.activating')
                    : t('recard.activation.step2.activate')
                }
                onClick={activate}
                isDisabled={activating || !isValid}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!confettiShown) {
    setConfettiShown(true);
    // TODO: add toast @romeoSpendesk @hrohou-spendesk
  }

  return (
    <div className="RecardActivation mx-auto mt-l w-[420px]">
      <h1 className="mb-xs text-complementary title-xl">
        {t('recard.activation.step3.title')}
      </h1>
      <p className="mb-m text-neutral-darker body-m">
        {t('recard.activation.step3.subtitle')}
      </p>
      <div className="RecardActivated box relative text-center body-m">
        {newCard && (
          <CardDesign width={350} name={newCard.card_name} brand={card.brand} />
        )}
        <p>{t('recard.activation.step3.card.deactivated')}</p>
        <p>{t('recard.activation.step3.card.throw')}</p>
        <Button
          className="mt-m"
          fit="parent"
          text={t('recard.activation.step3.card.show')}
          onClick={() => {
            setFetchingNewCard(true);
            const recardId: string = get(
              card,
              'recard_request.next_card_order.card_id',
              '',
            );
            fetchPlasticCard(recardId);
          }}
          isDisabled={fetchingNewCard}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  activateCard: cardActions.activateCard,
  fetchPlasticCard: cardActions.fetchPlasticCard,
};

const ConnectedRecardActivation = connect(
  null,
  mapDispatchToProps,
)(RecardActivation);

export { ConnectedRecardActivation as RecardActivation };
