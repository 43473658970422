export const openSupportChat = ({
  message,
}: { message?: string } = {}): void => {
  // TODO: remove this check once we use ContactUs button everywhere
  if (!isSupportChatAvailable()) {
    return;
  }

  if (message) {
    window.Intercom('showNewMessage', message);
  } else {
    window.Intercom('show');
  }
};

export const isSupportChatAvailable = (): boolean =>
  typeof window.Intercom === 'function';
