import { authScaAPI } from 'src/core/api/axios';

type PostScaProcedureResult =
  | { outcome: 'success' }
  | {
      outcome: 'error';
      code: 'failedWithSca' | 'failedWithMfa' | 'unknown';
    };

export const postScaProcedure = async (
  scaToken: string,
  useMfaFallback = false,
): Promise<PostScaProcedureResult> => {
  try {
    await authScaAPI.post(
      '/sca/procedures',
      { useMfaFallback },
      {
        headers: {
          Authorization: `Bearer ${scaToken}`,
        },
      },
    );

    return { outcome: 'success' };
  } catch (error) {
    if (
      ['failedWithSca', 'failedWithMfa'].includes(error?.response?.data?.code)
    ) {
      return {
        outcome: 'error',
        code: error?.response?.data?.code,
      };
    }

    return {
      outcome: 'error',
      code: 'unknown',
    };
  }
};
