import React, { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useBudgetaryExercisesQuery } from 'modules/budgets/containers/hooks/useBudgetaryExercisesQuery';
import { NavigationLink } from "src/core/common/components/NavigationLink";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = Readonly<{
  company: {
    id: string;
  };
  user: {
    is_account_owner: boolean;
    is_controller: boolean;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
}>;

export const ViewersBudgetsLink = ({
  company,
  className,
  isInDropdown,
  onClick,
}: Props) => {
  const { t } = useTranslation('global');

  const allBudgetaryExercicesQueryState = useBudgetaryExercisesQuery();
  const { pathname: currentPath } = useLocation();

  // Check if at least one budget is returned to display the link accordingly
  const visibleBudgets = useMemo(() => {
    if (
      allBudgetaryExercicesQueryState.status !== 'success' ||
      allBudgetaryExercicesQueryState.data.length === 0
    ) {
      return [];
    }
    return allBudgetaryExercicesQueryState.data.flatMap(
      ({ budgets }) => budgets,
    );
  }, [allBudgetaryExercicesQueryState]);

  if (visibleBudgets.length === 0) {
    // don't show link if no budgets
    return null;
  }

  return (
    <NavigationLink
      data-marketing="top-menu_budget"
      key="nav_viewers_budgets"
      className={className}
      onClick={onClick}
      path={routeFor(routes.BUDGETARY_EXERCISES.path, {
        company: company.id,
      })}
      text={t('budget.mainMenuItem')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [routes.BUDGETS.path, routes.BUDGETARY_EXERCISES.path],
        }),
      )}
      isInDropdown={isInDropdown}
    />
  );
};
