import { Button, HighlightIcon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { KycWidgetSkeleton } from 'modules/homepage/ControllerHomepage/components/KycWidgetSkeleton';
import { useOpenUIStateMutation } from 'modules/homepage/ControllerHomepage/hooks/useOpenUIStateMutation';
import { useKycStatusCode } from 'modules/kyc/hooks/useKycStatusCode';
import { useStartOrContinueProcedure } from 'modules/procedures';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import styles from './KycCompletion.module.css';

export const KycCompletion = () => {
  const { t } = useTranslation('global');

  const company = useCompany();

  const kycStatus = useKycStatusCode();
  const startOrContinueProcedure = useStartOrContinueProcedure();
  const [uiStateMutation, uiStateMutationState] = useOpenUIStateMutation();

  const [completionRatio, setCompletionRatio] = useState<number>(0);

  useEffect(() => {
    const setKycRatio = async () => {
      const { data } = await uiStateMutation({ domain: 'kyb' });

      if (data.steps === undefined) {
        return;
      }

      const stepValues = Object.values(data.steps);
      const stepCount = stepValues.length;
      if (stepCount === 0) {
        return;
      }

      const completedStepCount = stepValues.filter(
        ({ completed }) => completed,
      ).length;
      const ratio = Math.floor((completedStepCount / stepCount) * 100);
      setCompletionRatio(ratio);
    };

    if (!company.is_kyb_validated) {
      setKycRatio();
    }
  }, []);

  if (company.is_kyb_validated) {
    return null;
  }

  if (uiStateMutationState.status === 'loading') {
    return <KycWidgetSkeleton />;
  }

  return (
    <div className="box p-0">
      <div className={cx('p-m', styles.header)}>
        <h2 className="text-complementary title-xl">
          {t('homepage.completeKyc.title')}
        </h2>
        {completionRatio >= 0 && (
          <p className="text-neutral-dark">
            {t('homepage.completeKyc.completion', { ratio: completionRatio })}
          </p>
        )}
      </div>

      <div className="flex p-m">
        <HighlightIcon name="bank" className="self-center" variant="primary" />
        <div className="ml-s">
          <p className="text-l">{t('homepage.completeKyc.message')}</p>
          <p className="text-m text-neutral-dark">
            {t('homepage.completeKyc.info')}
          </p>
        </div>
        <Button
          className="ml-auto h-fit self-center"
          text={
            kycStatus === 'not-started'
              ? t('misc.getStarted')
              : t('misc.continue')
          }
          onClick={() => {
            if (kycStatus !== 'not-started') {
              track(AnalyticEventName.KYC_RESUME, { completionRatio });
            }
            startOrContinueProcedure();
          }}
        />
      </div>
    </div>
  );
};
