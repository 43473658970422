import { useParams } from 'react-router-dom';
import { object, string, type infer as Infer } from 'zod';

import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type AssociateOAuth2TokenPayload = {
  tokenId: string;
  authCode: string;
  companyId: string;
};

const associateResponseSchema = object({
  fullRedirectUrl: string(),
});

type AssociateOAuth2TokenResponse = Infer<typeof associateResponseSchema>;

export const useAssociateOAuth2Token = (): MutationState<
  AssociateOAuth2TokenPayload,
  AssociateOAuth2TokenResponse
> => {
  const { company: companyId } = useParams();

  return useMutation<
    AssociateOAuth2TokenPayload,
    AssociateOAuth2TokenResponse,
    object,
    unknown
  >({
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/public-api-configuration/oauth2`,
      method: 'post',
    },
    options: { throwOnError: true },
    reshapeData: associateResponseSchema.parse,
  });
};
