import { useEffect, useState } from 'react';

import { getPaymentPasswordProcedure } from '../api/getPaymentPasswordProcedure';

export const useGetPaymentPasswordProcedure = (
  procedureId: string | undefined,
  callback?: () => void,
) => {
  const [procedureFailedReason, setProcedureFailedReason] = useState<
    'initializationError' | 'unknown' | 'timeout' | 'rejected' | undefined
  >(undefined);
  const [passwordToShow, setPasswordToShow] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (procedureId) {
      const interval = setInterval(async () => {
        try {
          const response = await getPaymentPasswordProcedure({
            procedureId,
          });
          if (response.procedureStatus === 'approved') {
            setPasswordToShow(response.paymentPassword);
            clearInterval(interval);
            callback?.();
          }
          if (response.procedureStatus === 'rejected') {
            setProcedureFailedReason('rejected');
            clearInterval(interval);
            callback?.();
          }
          if (response.procedureStatus === 'timeout') {
            setProcedureFailedReason('timeout');
            clearInterval(interval);
            callback?.();
          }
        } catch {
          setProcedureFailedReason('unknown');
          callback?.();
        }
      }, 2500);
      return () => clearInterval(interval);
    }
  }, [procedureId]);

  return {
    procedureFailedReason,
    setProcedureFailedReason,
    passwordToShow,
  };
};
