import {
  SkeletonAvatar,
  SkeletonCheckbox,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import './PaymentsToScheduleListSkeleton.css';

type PaymentsToScheduleListSkeletonProps = {
  numberOfRows: number;
};

export const PaymentsToScheduleListSkeleton = ({
  numberOfRows,
}: PaymentsToScheduleListSkeletonProps) => (
  <SkeletonTable
    numberOfRows={numberOfRows}
    columns={[
      {
        cell: (
          <div className="PaymentsToScheduleListSkeleton__table-cell">
            <SkeletonCheckbox />
            <SkeletonAvatar variant="circle" />
            <div className="PaymentsToScheduleListSkeleton__table-cell__description">
              <SkeletonText width="40%" size="l" />
              <SkeletonText width="30%" size="s" />
            </div>
            <div>
              <SkeletonText size="s" />
              <SkeletonText size="s" />
            </div>
          </div>
        ),
        header: (
          <>
            <SkeletonCheckbox />
            <SkeletonText
              width="50%"
              size="m"
              className="PaymentsToScheduleListSkeleton__table-header__title"
            />
          </>
        ),
      },
    ]}
    withHeader
  />
);
