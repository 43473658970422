import React from 'react';

import { QuerySuspense } from "src/core/common/components/QuerySuspense";
import { useGetTravelperkPayableDataQuery } from "src/core/modules/integrations/hooks";
import { type PayableId } from "src/core/modules/payable";

import { ViewTrip } from './ViewTrip';

type Props = {
  payableId: PayableId;
};

export const Receipt = (props: Props) => {
  const { payableId } = props;
  const travelperkPayableQuery = useGetTravelperkPayableDataQuery(payableId);
  return (
    <QuerySuspense
      queryState={travelperkPayableQuery}
      loading={null}
      fallback={() => null}
    >
      {({ url }) => <ViewTrip externalUrl={url} />}
    </QuerySuspense>
  );
};
