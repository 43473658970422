import { Button, Label } from '@dev-spendesk/grapes';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { getI18nContext } from 'src/core/selectors/i18n';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import './HomepageWalletDetailKybNotValidated.css';

export const HomepageWalletDetailKybNotValidated = () => {
  const { t } = useTranslation('global');
  const i18nContext = useSelector(getI18nContext);
  const { company } = useParams();
  return (
    <div className="HomepageWalletDetailKybNotValidated">
      <Label
        className="HomepageWalletDetailKybNotValidated__title"
        label={t('homepage.wallet.emptyTitle', { ...i18nContext })}
      />

      <p className="HomepageWalletDetailKybNotValidated__description">
        {t('homepage.wallet.emptyKybNotValidatedDescription', {
          ...i18nContext,
        })}
      </p>
      <Link
        to={routeFor(routes.COMPANY_BANK_FUNDING.path, { company })}
        onClick={() => {
          track(AnalyticEventName.HOMEPAGE_WALLET_ADD_FUNDS_BUTTON_CLICKED);
        }}
      >
        <Button
          className="HomepageWalletDetailKybNotValidated__button"
          iconName="clock"
          iconPosition="left"
          isDisabled
          text={t('homepage.wallet.addFunds', { ...i18nContext })}
          variant="primary"
        />
      </Link>
    </div>
  );
};
