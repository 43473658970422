import { compareDesc } from 'date-fns';

import {
  type DraftExpense,
  type DraftExpenses,
  type RawExpense,
} from '@finance-review/models/expense';

import { transformRawExpense } from './transformRawExpense';

export const transformRawExpenses = (
  rawExpenses: RawExpense[],
): DraftExpenses => {
  const expenses = rawExpenses.map((rawExpense) =>
    transformRawExpense(rawExpense),
  );
  const groupedByRequester = expenses.reduce(
    (result, expense) => {
      const requesterId = expense.requester.id;
      if (!result[requesterId]) {
        result[requesterId] = [];
      }
      result[requesterId].push(expense);
      return result;
    },
    {} as Record<string, DraftExpense[]>,
  );
  const formattedGroup = Object.keys(groupedByRequester)
    .map((userId) => {
      const requesterExpenses = groupedByRequester[userId].sort((a, b) =>
        a.expenseDate && b.expenseDate
          ? compareDesc(a.expenseDate, b.expenseDate)
          : 0,
      );

      return {
        requester: requesterExpenses[0].requester,
        expenses: requesterExpenses,
      };
    })
    .sort((expensesByRequester1, expensesByRequester2) => {
      return expensesByRequester1.requester.fullname.localeCompare(
        expensesByRequester2.requester.fullname,
      );
    });

  return {
    expenses,
    expensesByRequester: formattedGroup,
  };
};
