import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type EditPayload = {
  name: string;
  costCenterIds: string[];
};

export const useEditEcQuery = (
  expenseCategoryId: string,
): MutationState<EditPayload> => {
  return useMutation<EditPayload>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: `/expense-categories/${expenseCategoryId}`,
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['expenseCategories']);
      },
    },
  });
};
