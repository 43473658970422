import * as Money from 'ezmoney';

import { type BucketsStats } from '../../../prepare-payables/models';

type RawPayableBucketStats = {
  connectionId: string;
  totalCount: number;
  aggregate: {
    sum: {
      functionalAmount: Money.MonetaryValue;
    };
  };
};

export type RawPayablesBucketsStats = {
  company: {
    currency: string;
    payables: {
      totalCount: number;
    };
    payablesFiltered?: RawPayableBucketStats;
    payablesWithProof?: RawPayableBucketStats;
    payablesWithoutProof?: RawPayableBucketStats;
    payablesMissingProof?: RawPayableBucketStats;
  };
};

export const reshapePayablesBucketsStatsResultData = (
  data: RawPayablesBucketsStats,
): BucketsStats => {
  const {
    currency,
    payables,
    payablesFiltered,
    payablesWithProof,
    payablesWithoutProof,
    payablesMissingProof,
  } = data.company;

  const zero = Money.fromNumber(0, currency);

  return {
    // TODO: change to payables
    expenses: {
      totalCount: payables.totalCount,
    },
    expensesFiltered: {
      connectionId: payablesFiltered?.connectionId ?? '',
      totalCount: payablesFiltered?.totalCount ?? 0,
      totalAmount: payablesFiltered?.aggregate.sum.functionalAmount ?? zero,
    },
    expensesWithProof: {
      connectionId: payablesWithProof?.connectionId ?? '',
      totalCount: payablesWithProof?.totalCount ?? 0,
      totalAmount: payablesWithProof?.aggregate.sum.functionalAmount ?? zero,
    },
    expensesWithoutProof: {
      connectionId: payablesWithoutProof?.connectionId ?? '',
      totalCount: payablesWithoutProof?.totalCount ?? 0,
      totalAmount: payablesWithoutProof?.aggregate.sum.functionalAmount ?? zero,
    },
    expensesMissingProof: {
      connectionId: payablesMissingProof?.connectionId ?? '',
      totalCount: payablesMissingProof?.totalCount ?? 0,
      totalAmount: payablesMissingProof?.aggregate.sum.functionalAmount ?? zero,
    },
  };
};
