import { Button } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { CountryFormField } from 'modules/suppliers/components/SupplierSpanishDprComplianceForm/CountryFormField';
import { SupplierFormField } from 'modules/suppliers/components/SupplierSpanishDprComplianceForm/SupplierFormField';
import { VatNumberFormField } from 'modules/suppliers/components/SupplierSpanishDprComplianceForm/VatNumberFormField';

import { useSupplierEditForm } from '../../hooks/useSupplierEditForm';
import { CreateSupplierQueryError } from '../SupplierBankInfoForm/CreateSupplierQueryError';
import { UpdateSupplierQueryError } from '../SupplierBankInfoForm/UpdateSupplierQueryError';

type Props = {
  supplier?: {
    id: string;
    name: string;
    country?: string;
    vatNumber?: string;
  };
  onSupplierSelected: (supplier: { id: string; name: string }) => void;
  shouldDisplayDPRInformations?: boolean;
  isSupplierEditable?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const SupplierEditForm = ({
  supplier,
  onSupplierSelected,
  onCancel,
  onConfirm,
  shouldDisplayDPRInformations,
  isSupplierEditable,
}: Props) => {
  const { t } = useTranslation('global');

  const selectedSupplier = supplier?.id !== 'unknown' ? supplier : undefined;

  const { formikProps, updateSupplierQueryState, createSupplierQueryState } =
    useSupplierEditForm({
      supplier: selectedSupplier,
      onSupplierSelected,
      shouldDisplayDPRInformations,
    });

  const { setFieldValue, errors, values, submitForm } = formikProps;

  const allowEditDetails = supplier || values.newSupplierName?.length;

  return (
    <form className="flex flex-col gap-xs">
      <SupplierFormField
        selectedSupplier={selectedSupplier}
        error={Boolean(errors.newSupplierName)}
        onChange={(change) => {
          setFieldValue(
            'newSupplierName',
            'newSupplierName' in change ? change.newSupplierName : undefined,
          );

          if ('existingSupplier' in change) {
            onSupplierSelected(change.existingSupplier);
          }
        }}
        showLabel={false}
        isDisabled={!isSupplierEditable}
      />

      {shouldDisplayDPRInformations && (
        <>
          <VatNumberFormField
            vatNumber={values.vatNumber ?? ''}
            error={Boolean(errors.vatNumber)}
            onChange={(vatNumber) => setFieldValue('vatNumber', vatNumber)}
            isDisabled={!allowEditDetails}
            showLabel={false}
            className=""
          />

          <CountryFormField
            country={values.country ?? ''}
            error={Boolean(errors.country)}
            onChange={(country) => setFieldValue('country', country)}
            isDisabled={!allowEditDetails}
            showLabel={false}
            className=""
          />
        </>
      )}

      <div className="mt-xs flex flex-row justify-between gap-s">
        <Button
          data-testid="cancel-edit-supplier"
          text={t('misc.cancel')}
          variant="contrasted"
          onClick={onCancel}
          disabled={formikProps.isSubmitting}
        />

        <Button
          data-testid="submit-edit-supplier"
          text={t('misc.save')}
          variant="primary"
          onClick={async () => {
            await submitForm();
            await onConfirm();
          }}
          disabled={formikProps.isSubmitting}
        />
      </div>

      {createSupplierQueryState.status === 'error' && (
        <CreateSupplierQueryError
          createSupplierError={createSupplierQueryState.error}
        />
      )}

      {updateSupplierQueryState.status === 'error' && (
        <UpdateSupplierQueryError
          updateSupplierError={updateSupplierQueryState.error}
        />
      )}
    </form>
  );
};
