import { type AutocompleteMultipleProps } from '@dev-spendesk/grapes';
import { useMemo } from 'react';

import type { CostCenter } from 'modules/budgets/models/costCenter';
import { AutocompleteMultipleSearch } from 'src/core/common/components/AutocompleteSearch';
import { type Option } from 'src/core/common/components/AutocompleteSearch/option';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  costCenters: CostCenter[];
  onSelect: AutocompleteMultipleProps<Option>['onSelect'];
  selectedCostCenterIds: string[];
};

export const MultipleCostCenterAutocomplete = ({
  costCenters,
  onSelect,
  selectedCostCenterIds,
}: Props) => {
  const { t } = useTranslation('global');

  const costCentersById: Record<string, Option> = useMemo(() => {
    return costCenters.reduce(
      (accumulator, costCenter) => ({
        ...accumulator,
        [costCenter.id]: {
          key: costCenter.id,
          label: costCenter.name,
        } as Option,
      }),
      {},
    );
  }, [costCenters]);

  const selectedOptions = useMemo(
    () => selectedCostCenterIds.map((id) => costCentersById[id]),
    [costCentersById, selectedCostCenterIds],
  );

  return (
    <AutocompleteMultipleSearch
      fit="parent"
      aria-label="cost-centers"
      placeholder={t(
        'expenseCategories.form.costCenterAssignation.placeholder',
      )}
      options={costCenters.map((costCenter) => ({
        key: costCenter.id,
        label: costCenter.name,
      }))}
      values={selectedOptions}
      onSelect={onSelect}
      translations={{
        selected: t('expenseCategories.form.costCenterAssignation.selected', {
          count: selectedCostCenterIds.length,
        }),
      }}
    />
  );
};
