import { ValidatePayableApi } from '@finance-review/apis/payable';
import type {
  CreditNoteId,
  CreditNote,
} from '@finance-review/models/credit-note';
import { type MutationState } from 'src/core/api/hooks/useMutation';

import {
  useRemoveCreditNotesCacheEntries,
  useInvalidateCreditNoteListQuery,
} from './useCreditNoteListQuery';

type Payload = CreditNote;
type Response = ValidatePayableApi.Response;

export const useValidateCreditNoteRequest = (
  options: {
    onSuccess?: (requestId: CreditNoteId) => void;
    onError?: () => void;
  } = {},
): MutationState<Payload, Response, unknown> => {
  const removeCreditNotesCacheEntries = useRemoveCreditNotesCacheEntries();
  const invalidateCreditNoteListQuery = useInvalidateCreditNoteListQuery();

  return ValidatePayableApi.useMutation({
    onSuccess: async (requestId) => {
      removeCreditNotesCacheEntries([requestId]);
      invalidateCreditNoteListQuery();
      options.onSuccess?.(requestId);
    },
    onError: options.onError,
  });
};
