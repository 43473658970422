import React from 'react';

import { useGetKybStatusQuery } from 'modules/kyc/hooks/useGetKybStatusQuery';

import { KybStatus } from './KybStatus';

export const KybStatusContainer = () => {
  const getKybStatusQuery = useGetKybStatusQuery({ isEnabled: true });

  // TODO: add loader and error states
  if (getKybStatusQuery.status !== 'success') {
    return null;
  }

  if (!getKybStatusQuery.data) {
    return null;
  }

  return <KybStatus kybStatus={getKybStatusQuery.data} />;
};
