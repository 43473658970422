import { Button, Link } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";
import {
  getIntegrationLogo,
  getIntegrationName,
} from "src/core/modules/integrations/utils";

type Props = {
  externalUrl: string;
};

export const ViewTrip = ({ externalUrl }: Props) => {
  const { t } = useTranslation('global');
  return (
    <div className="flex flex-row items-center gap-xs rounded-xxs bg-neutral-lightest p-xs">
      <div className="flex h-m w-m">
        <img
          src={getIntegrationLogo('travelperk')}
          alt={getIntegrationName(t, 'travelperk')}
          className="h-m w-m"
        />
      </div>
      <div className="mr-auto text-left">
        <span className="text-s text-neutral-dark">
          {t('payables.panel.automaticallyCollectedReceipt')}
        </span>
      </div>
      <Link href={externalUrl} isExternal>
        <Button
          className="h-m"
          fit="content"
          variant="ghost"
          text={t('integration.travelperk.viewTrip')}
        />
      </Link>
    </div>
  );
};
