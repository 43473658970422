import { FormField, TextInput } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type IntegrationVatAccount } from 'src/core/modules/bookkeep/settings/accounting';

import styles from './TaxAccountPullAndSetSection.module.css';
import { type ModalState } from './chift/ChiftReverseChargeSetSection';

interface Props {
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
  availableTaxAccounts: {
    debit: IntegrationVatAccount[];
    credit: IntegrationVatAccount[];
  };
}

export const PullAndSetReverseChargeAddEditForm = ({
  formState,
  setFormState,
  availableTaxAccounts,
}: Props) => {
  const { t } = useTranslation();

  const debitAutoCompleteOptions = availableTaxAccounts.debit.map(
    (taxAccount) => {
      return {
        key: taxAccount.id,
        label: taxAccount.code,
      };
    },
  );
  const creditAutoCompleteOptions = availableTaxAccounts.credit.map(
    (taxAccount) => {
      return {
        key: taxAccount.id,
        label: taxAccount.code,
      };
    },
  );

  return (
    <>
      {formState.kind === 'addReverseCharge' && (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.reverseChargeAccountsTable.accountName',
            )}
            className={styles.formField}
          >
            <TextInput
              value={formState.name}
              onChange={(e) =>
                setFormState({ ...formState, name: e.target.value })
              }
            />
          </FormField>
          <FormField
            label={t(
              'bookkeep.integrations.settings.reverseChargeAccountsTable.debitCode',
            )}
            className={styles.formField}
          >
            <AutocompleteSearch
              fit="parent"
              value={{
                key: formState.codeDebit,
                label: formState.codeDebit,
              }}
              onSelect={(taxAccount) => {
                if (taxAccount) {
                  const selectedTaxAccount = availableTaxAccounts.debit.find(
                    (availableTaxAccount) =>
                      availableTaxAccount.id === taxAccount.key,
                  );
                  if (selectedTaxAccount) {
                    setFormState({
                      ...formState,
                      codeDebit: selectedTaxAccount.code,
                      inputChanged: true,
                    });
                  }
                }
              }}
              options={debitAutoCompleteOptions}
              renderNoOptions={() => <></>}
              showClearSelectionButton
            />
          </FormField>
          <FormField
            label={t(
              'bookkeep.integrations.settings.reverseChargeAccountsTable.creditCode',
            )}
            className={styles.formField}
          >
            <AutocompleteSearch
              fit="parent"
              value={{
                key: formState.codeCredit,
                label: formState.codeCredit,
              }}
              onSelect={(taxAccount) => {
                if (taxAccount) {
                  const selectedTaxAccount = availableTaxAccounts.credit.find(
                    (availableTaxAccount) =>
                      availableTaxAccount.id === taxAccount.key,
                  );
                  if (selectedTaxAccount) {
                    setFormState({
                      ...formState,
                      codeCredit: selectedTaxAccount.code,
                      inputChanged: true,
                    });
                  }
                }
              }}
              options={creditAutoCompleteOptions}
              renderNoOptions={() => <></>}
              showClearSelectionButton
            />
          </FormField>
        </>
      )}
    </>
  );
};
