import { Tag } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { useGetErrorTranslation } from './useGetErrorTranslation';

type PaymentErrorTagProps = {
  error: string;
};

export const PaymentErrorTag = ({ error }: PaymentErrorTagProps) => {
  const { t } = useTranslation('global');

  const getErrorTranslation = useGetErrorTranslation();
  const translatedError = getErrorTranslation(error);

  if (translatedError) {
    return <Tag variant="warning">{translatedError}</Tag>;
  }

  return (
    <Tag variant="alert">
      {t('wiretransfer.confirmation.errorModal.unknownError')}
    </Tag>
  );
};
