import {
  Button,
  IconButton,
  Table,
  type TableColumn,
} from '@dev-spendesk/grapes';
import { fromNumber } from 'ezmoney';
import { useMemo } from 'react';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import i18n from 'src/core/config/i18n';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  formatInvoiceStatus,
  type Invoice,
  type InvoiceStatus,
} from '../../../../models';

type Props = {
  invoices: Invoice[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
};

type PreparedRow = {
  number: string;
  total: number;
  date: string;
  status: InvoiceStatus;
  download: JSX.Element | null;
};

const renderIconButton = (
  downloadLink: string | undefined,
): JSX.Element | null => {
  if (!downloadLink) {
    return null;
  }

  return (
    <IconButton
      variant="borderless"
      iconName="download"
      onClick={() => window.open(downloadLink, '_blank')}
      aria-label={i18n.t('misc.download')}
    />
  );
};

const formatRow = (invoice: Invoice): PreparedRow => ({
  number: invoice.number,
  total: invoice.total,
  date: invoice.createdAt,
  status: invoice.status,
  download: renderIconButton(invoice.paymentInvoice?.url),
});

const reshapeColumns = (
  t: TGlobalFunctionTyped,
  locale: string,
  companyCurrency: string,
): TableColumn<PreparedRow>[] => [
  {
    id: 'number',
    header: t('billing.invoicesList.number'),
    renderCell: ({ number }) => number,
    width: '30%',
  },
  {
    id: 'total',
    header: t('billing.invoicesList.total'),
    renderCell: ({ total }) =>
      // This displays the wrong currency if the invoice has a different currency than the company
      formatMonetaryValue(fromNumber(total, companyCurrency, 2)),
    width: '20%',
  },
  {
    id: 'date',
    header: t('billing.invoicesList.date'),
    renderCell: ({ date }) => {
      const formattedDate = new Date(date).toLocaleDateString(locale, {
        month: 'long',
        year: 'numeric',
      });
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },
    width: '20%',
  },
  {
    id: 'status',
    header: t('billing.invoicesList.status'),
    renderCell: ({ status }) => formatInvoiceStatus(status, t),
    width: '20%',
  },
  {
    id: 'download',
    header: '',
    renderCell: ({ download }) => download || <></>,
    width: '10%',
    align: 'right',
  },
];

export const CompanyInvoices = ({
  invoices,
  hasNextPage,
  fetchNextPage,
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const companyCurrency = useCompanyCurrency();

  const data = useMemo(
    () => invoices.map((invoice) => formatRow(invoice)),
    invoices,
  );

  return (
    <Table
      className="mt-s"
      data={data}
      columns={reshapeColumns(t, activeLanguage, companyCurrency)}
      rowHeight="compact"
      footer={
        hasNextPage ? (
          <Button
            variant="secondary"
            text={t('misc.loadMore')}
            onClick={fetchNextPage}
          />
        ) : undefined
      }
    />
  );
};
