import { type BillingInformation } from '../../../../models';

type ApiBillingInformation = {
  is_billing_info_same_as_company: boolean;
  email: string | null;
  vat: string | null;
  company_id: string;
  organisation_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  id: string;
};

export const reshapeApiBillingInformation = (
  billingInformation: BillingInformation,
): ApiBillingInformation => ({
  is_billing_info_same_as_company:
    billingInformation.isBillingInfoSameAsCompany,
  email: billingInformation.email,
  vat: billingInformation.vat,
  company_id: billingInformation.companyId,
  organisation_id: billingInformation.organisationId,
  created_at: billingInformation.createdAt,
  updated_at: billingInformation.updatedAt,
  deleted_at: billingInformation.deletedAt,
  id: billingInformation.id,
});
