import omit from 'lodash/omit';

import * as groupActions from './actions';
import {
  type GroupActions,
  type GroupState,
  type CreateFailure,
  type CreateSuccess,
  type FetchAllSuccess,
  type FetchAllFailure,
  type FetchOneSuccess,
  type FetchOneFailure,
  type UpdateSuccess,
  type UpdateFailure,
  type DestroySuccess,
  type DestroyFailure,
  type PatchSuccess,
  type PatchFailure,
  type Group,
} from '../types';

export const initialState: GroupState = {
  byId: {},
  isFetching: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const fetchAllRequest = (state: GroupState) => ({ ...state, isFetching: true });

const fetchAllSuccess = (state: GroupState, { payload }: FetchAllSuccess) => {
  const groupsById = payload.reduce<Record<string, Group>>((res, group) => {
    res[group.id] = group;
    return res;
  }, {});
  return {
    ...state,
    isFetching: false,
    byId: groupsById,
  };
};

const fetchAllFailure = (state: GroupState, { payload }: FetchAllFailure) => ({
  ...state,
  isFetching: false,
  error: payload,
});

const fetchOneRequest = (state: GroupState) => ({ ...state, isFetching: true });

const fetchOneSuccess = (state: GroupState, { payload }: FetchOneSuccess) => ({
  ...state,
  isFetching: false,
  byId: {
    ...state.byId,
    [payload.id]: payload,
  },
});

const fetchOneFailure = (state: GroupState, { payload }: FetchOneFailure) => ({
  ...state,
  isFetching: false,
  error: payload,
});

const createRequest = (state: GroupState) => ({ ...state, isCreating: true });

const createSuccess = (state: GroupState, action: CreateSuccess) => {
  const { payload: group } = action;
  return {
    ...state,
    isCreating: false,
    byId: {
      ...state.byId,
      [group.id]: group,
    },
    isInviteModalShown: true,
  };
};

const createFailure = (state: GroupState, action: CreateFailure) => ({
  ...state,
  isCreating: false,
  error: action.payload,
});

const updateRequest = (state: GroupState) => ({
  ...state,
  isUpdating: true,
});

const updateSuccess = (
  state: GroupState,
  { payload }: UpdateSuccess | PatchSuccess,
) => ({
  ...state,
  isUpdating: false,
  byId: {
    ...state.byId,
    [payload.id]: payload,
  },
});

const updateFailure = (
  state: GroupState,
  { payload }: UpdateFailure | PatchFailure,
) => ({
  ...state,
  isUpdating: false,
  error: payload,
});

const destroyRequest = (state: GroupState) => ({
  ...state,
  isDeleting: true,
});

const destroySuccess = (state: GroupState, { payload }: DestroySuccess) => ({
  ...state,
  isDeleting: false,
  byId: omit(state.byId, payload),
});

const destroyFailure = (state: GroupState, { payload }: DestroyFailure) => ({
  ...state,
  isDeleting: false,
  error: payload,
});

const reducer = (
  state: GroupState = initialState,
  action: GroupActions,
): GroupState => {
  switch (action.type) {
    case groupActions.FETCH_ALL_REQUEST:
      return fetchAllRequest(state);
    case groupActions.FETCH_ALL_SUCCESS:
      return fetchAllSuccess(state, action);
    case groupActions.FETCH_ALL_FAILURE:
      return fetchAllFailure(state, action);
    case groupActions.FETCH_ONE_REQUEST:
      return fetchOneRequest(state);
    case groupActions.FETCH_ONE_SUCCESS:
      return fetchOneSuccess(state, action);
    case groupActions.FETCH_ONE_FAILURE:
      return fetchOneFailure(state, action);
    case groupActions.CREATE_REQUEST:
      return createRequest(state);
    case groupActions.CREATE_SUCCESS:
      return createSuccess(state, action);
    case groupActions.CREATE_FAILURE:
      return createFailure(state, action);
    case groupActions.UPDATE_REQUEST:
      return updateRequest(state);
    case groupActions.UPDATE_SUCCESS:
      return updateSuccess(state, action);
    case groupActions.UPDATE_FAILURE:
      return updateFailure(state, action);
    case groupActions.DESTROY_REQUEST:
      return destroyRequest(state);
    case groupActions.DESTROY_SUCCESS:
      return destroySuccess(state, action);
    case groupActions.DESTROY_FAILURE:
      return destroyFailure(state, action);
    case groupActions.PATCH_REQUEST:
      return updateRequest(state);
    case groupActions.PATCH_SUCCESS:
      return updateSuccess(state, action);
    case groupActions.PATCH_FAILURE:
      return updateFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
