import { Button } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';

import { useConfirmTransfersTrackingEvents } from '../../../../hooks';
import { selectors, actions } from '../../../../redux';
import { TransferCancelModal } from '../../../TransferCancelModal';

export const CancelTransfersButton = () => {
  const { t } = useTranslation('global');
  const confirmTransfersTrackingEvents = useConfirmTransfersTrackingEvents();

  const transfersSelected = useSelector(selectors.selectTransfersSelected);
  const transfersSelectedCount = useSelector(
    selectors.selectTransfersSelectedCount,
  );

  const [isCancelTransfersModalOpen, setIsCancelTransfersModalOpen] =
    useState<boolean>(false);
  const openCancelTransfersModal = () => {
    setIsCancelTransfersModalOpen(true);
    confirmTransfersTrackingEvents.onConfirmTransfersRejectionModalOpened({
      transfers: transfersSelected,
    });
  };
  const closeCancelTransfersModal = () => {
    setIsCancelTransfersModalOpen(false);
    confirmTransfersTrackingEvents.onConfirmTransfersRejectionModalClosed({
      transfers: transfersSelected,
    });
  };

  const dispatch = useDispatch();

  const resetTransfersSelection = () => {
    dispatch(actions.resetTransfersSelection());
  };

  return (
    <>
      <Button
        text={t('invoices.transfer.actions.cancelTransfer', {
          count: transfersSelectedCount,
        })}
        variant="secondary"
        onClick={openCancelTransfersModal}
        className="mr-m"
      />
      <TransferCancelModal
        transfers={transfersSelected}
        isOpen={isCancelTransfersModalOpen}
        onClose={closeCancelTransfersModal}
        onConfirm={resetTransfersSelection}
      />
    </>
  );
};
