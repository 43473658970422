import { useFeature } from 'common/hooks/useFeature';
import {
  FREEMIUM_PLAN_SEAT_LIMIT,
  STARTER_PLAN_SEAT_LIMIT,
} from 'modules/members/constants/seatLimits';
import FEATURES from "src/core/constants/features";

export const useSeatLimit = (): number | undefined => {
  const isUsersCountLimitFeatureEnabled = useFeature(
    FEATURES.USERS_COUNT_LIMIT,
  );
  const isFreemiumUsersCountLimitFeatureEnabled = useFeature(
    FEATURES.FREEMIUM_USERS_COUNT_LIMIT,
  );
  if (isUsersCountLimitFeatureEnabled) {
    return STARTER_PLAN_SEAT_LIMIT;
  }
  if (isFreemiumUsersCountLimitFeatureEnabled) {
    return FREEMIUM_PLAN_SEAT_LIMIT;
  }
  return undefined;
};
