import {
  AmountInput,
  Button,
  FormField,
  Modal,
  TextArea,
} from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { formatMoney, getCurrencyOptionByKey } from 'src/core/utils/money';

import { type ApiCard } from '../../card';
import { useRequestCardTopupMutation } from '../../hooks/useRequestCardTopupMutation';
import { useTopupCardMutation } from '../../hooks/useTopupCardMutation';

type FormValues = {
  amount: number | null;
  description: string;
};

type Props = {
  card: ApiCard;
  mode: 'admin' | 'requester';
  onProcessed: (card?: ApiCard) => void;
  onCancel: () => void;
};

export const TopUpModal = ({ card, mode, onProcessed, onCancel }: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();

  const [topupCard] = useTopupCardMutation(card.id);
  const [requestCardTopup] = useRequestCardTopupMutation();

  const formikProps = useFormik<FormValues>({
    initialValues: {
      amount: null,
      description: '',
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (
        !values.amount ||
        Number.isNaN(values.amount) ||
        values.amount < 0 ||
        !/^\d+(\.|,)?\d*$/.test(String(values.amount))
      ) {
        errors.amount = t('cards.actions.errorAmountFormatManager');
      }

      const maxLoad = card.ceilings.maxLoadsAmount;
      const maxLoadYear = card.ceilings.maxLoadsAmountPerYear;

      if (values.amount && values.amount > maxLoad) {
        errors.amount = t('cards.actions.warningTopUpTooHighWithAmount', {
          amount: formatMoney(maxLoad, card.currency),
        });
      }

      if (values.amount && card.load_year + values.amount > maxLoadYear) {
        errors.amount = t(
          'cards.actions.warningYearlyBudgetTooHighWithAmount',
          {
            amount: formatMoney(maxLoadYear, card.currency),
          },
        );
      }

      if (mode === 'requester' && !values.description.trim()) {
        errors.description = t('misc.requiredField');
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (!values.amount) {
        return;
      }

      if (mode === 'admin') {
        // Admin mode, only the amount is required
        const updatedCard = await topupCard({ amount: values.amount });
        onProcessed(updatedCard);
      }
      if (mode === 'requester') {
        // Requester mode, we need both amount and description
        await requestCardTopup({
          type: 'card_load',
          currency_declared: company.currency,
          amount_declared: values.amount,
          description: values.description,
          user_message: '',
          custom_fields_associations: [],
          get_card_allowed: false,
          is_approved: false,
          is_single_purchase: false,
          is_subscription: false,
          is_card_load: true,
        });
        onProcessed();
      }
    },
  });

  return (
    <Modal
      isOpen
      title={
        mode === 'admin'
          ? t('cards.actions.topUpModalTitleWithName', {
              name: card.user.first_name,
            })
          : t('cards.actions.topUpModalTitle')
      }
      iconName="card-load"
      actions={[
        <Button
          key="cancel"
          variant="secondary"
          text={t('misc.cancel')}
          onClick={onCancel}
          isDisabled={formikProps.isSubmitting}
        />,
        <Button
          key="submit"
          text={t('cards.actions.topUpCardLimit')}
          form="card-load"
          type="submit"
          isLoading={formikProps.isSubmitting}
        />,
      ]}
    >
      <form id="card-load" onSubmit={formikProps.handleSubmit}>
        <FormField
          label={t('forms.topupCard.labelLimit')}
          alertMessage={formikProps.errors.amount}
        >
          <AmountInput
            placeholder={t('forms.topupCard.placeholder')}
            value={formikProps.values.amount}
            fit="parent"
            currency={getCurrencyOptionByKey(company.currency)}
            onChange={(_, newValue) => {
              formikProps.setFieldValue('amount', newValue);
            }}
          />
        </FormField>
        {mode === 'requester' && (
          <FormField
            className="mt-m"
            label={t('forms.topupCard.descriptionLabel')}
            alertMessage={formikProps.errors.description}
          >
            <TextArea
              name="description"
              placeholder={t('forms.topupCard.descriptionPlaceholder')}
              maxLength={255}
              value={formikProps.values.description}
              onChange={formikProps.handleChange}
            />
          </FormField>
        )}
      </form>
    </Modal>
  );
};
