import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import {
  type BookkeepingLockAfterUnit,
  useInvalidateBookkeepingSettingsQueryCache,
  useUpdateBookkeepingSettingsQueryCache,
} from './useBookkeepingSettings';

export type Payload = {
  bookkeepingStartDate?: Date | null;
  editPayablesAfterExport?: {
    hasOptedIn: boolean;
    lockAfter?: number | null;
    lockAfterUnit?: BookkeepingLockAfterUnit | null;
  };
};

type RawData = object;

export type Result = RawData;

export const useSetBookkeepingSettings = (): MutationState<Payload, Result> => {
  const companyId = useCompanyId();

  const invalidateBookkeepingSettingsQueryCache =
    useInvalidateBookkeepingSettingsQueryCache();

  const updateBookkeepingSettingsQueryCache =
    useUpdateBookkeepingSettingsQueryCache();

  return useMutation<Payload, Result, RawData>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint: '/bookkeeping/settings',
    },
    reshapeData: (data) => {
      return data;
    },
    options: {
      onSuccess: ({ payload }): void => {
        updateBookkeepingSettingsQueryCache(payload);
        invalidateBookkeepingSettingsQueryCache(companyId);
      },
    },
  });
};
