import { SideNavigation, NavigationItem } from '@dev-spendesk/grapes';
import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { useTranslation } from "src/core/common/hooks/useTranslation";
import { routes, routeFor } from "src/core/constants/routes";

type Props = {
  companyId: string;
};

type BillingPageNavLink = {
  id: string;
  text: string;
  url: string;
};

export const BillingPageNav = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const { url: currentUrl } = useRouteMatch();

  const links: BillingPageNavLink[] = [
    {
      id: 'billing-company-plan',
      text: t('billing.navLinks.companyPlan'),
      url: routeFor(routes.COMPANY_BILLING_PLAN.path, {
        company: companyId,
      }),
    },
    {
      id: 'billing-company-information',
      text: t('billing.navLinks.billingInformation'),
      url: routeFor(routes.COMPANY_BILLING_INFORMATION.path, {
        company: companyId,
      }),
    },
  ];

  return (
    <SideNavigation>
      {links.map((link) => {
        return (
          <NavigationItem
            component={NavLink}
            text={link.text}
            isActive={currentUrl === link.url}
            key={link.id}
            to={link.url}
          />
        );
      })}
    </SideNavigation>
  );
};
