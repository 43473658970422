import { type Translations, useQueryError } from 'common/components/QueryError';
import { type Beneficiary } from 'modules/invoices/transfer/models';
import { useVerifyExpenseTransfersBatchBeneficiariesQuery } from 'modules/reimbursements/hooks/useVerifyExpenseTransfersBatchBeneficiariesQuery';
import { useVerifyExpenseTransfersBeneficiariesQuery } from 'modules/reimbursements/hooks/useVerifyExpenseTransfersBeneficiariesQuery';
import { type QueryError } from 'src/core/api/queryError';

import * as VerifyInvoiceTransfersBeneficiariesApi from './useVerifyInvoiceTransfersBeneficiariesQuery';

export type Payload =
  | {
      paymentIds: string[];
      type: 'invoice' | 'expense';
      batchId?: never;
    }
  | {
      type: 'expense';
      batchId: string;
      paymentIds?: never;
    };

export type Response = Beneficiary.VerificationResult[];

export const useVerifyBeneficiariesQueryFactory = () => {
  const [verifyInvoiceTransfersQuery] =
    VerifyInvoiceTransfersBeneficiariesApi.useVerifyInvoiceTransfersBeneficiariesQuery();
  const [verifyExpenseTransfersQuery] =
    useVerifyExpenseTransfersBeneficiariesQuery();
  const [verifyExpenseBatchTransfersQuery] =
    useVerifyExpenseTransfersBatchBeneficiariesQuery();

  return (payload: Payload): Promise<Response> => {
    if (payload.batchId) {
      return verifyExpenseBatchTransfersQuery({
        endpointParams: {
          batchId: payload.batchId,
        },
      });
    }

    return payload.type === 'invoice'
      ? verifyInvoiceTransfersQuery({
          paymentIds: payload.paymentIds,
        })
      : verifyExpenseTransfersQuery({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          scheduledPaymentIds: payload.paymentIds!,
        });
  };
};

const errorTranslations: Translations<VerifyInvoiceTransfersBeneficiariesApi.Error> =
  {
    requestError: ({ error }) => {
      switch (error) {
        case 'payments-not-found':
          return 'wiretransfer.confirmation.verificationOfBeneficiariesApi.errors.payments-not-found';
        default:
          return undefined;
      }
    },
    serverError:
      'wiretransfer.confirmation.verificationOfBeneficiariesApi.error',
  };

export const useVerifyTransfersBeneficiariesErrorMessage = () => {
  const getErrorMessage =
    useQueryError<VerifyInvoiceTransfersBeneficiariesApi.Error>(
      errorTranslations,
    );

  return (
    queryError: QueryError<VerifyInvoiceTransfersBeneficiariesApi.Error>,
    translationParams?: Record<string, unknown>,
  ): string => getErrorMessage(queryError, translationParams);
};
