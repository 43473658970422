import type {
  Timeline,
  TimelineEvent,
  TimelineEventAction,
} from '@finance-review/models/invoice';
import type { RawSupplier } from 'modules/suppliers';

export type RawTimeline = RawTimelineEvent[];
export type RawTimelineEvent = RawTimelineEventBase & RawTimelineEventAction;
interface RawTimelineEventBase {
  id: string;
  occured_at: string;
  user: {
    id: string;
    first_name: string;
    fullname: string;
    last_name: string;
  };
}

type RawTimelineEventAction =
  | {
      action_type: 'invoice_request.updated';
      data: {
        from: {
          amount_declared: number;
          currency_declared: string;
          description: string;
          group: object;
          invoice_request: object;
          supplier: object;
        };
        to: {
          amount_declared: number;
          currency_declared: string;
          description: string;
          group: object;
          invoice_request: object;
          supplier: object;
        };
      };
    }
  | {
      action_type: 'invoice_request.updateSchedules';
      data: {
        from: {
          amount: number;
          date: string;
        };
        to: {
          amount: number;
          date: string;
        };
      };
    }
  | {
      action_type: 'invoice_request.addSchedule';
      data: null;
    }
  | {
      action_type: 'invoice_request.createSchedule';
      data: null;
    }
  | {
      action_type: 'request.create';
      data: {
        amountDeclared: number;
        currency: string;
      };
    }
  | {
      action_type: 'request.approve';
      data: {
        amountApproved: number;
        currency: string;
      };
    }
  | {
      action_type: 'request.invoice';
      data: null;
    }
  | {
      action_type: 'request.sent_back_to_review';
      data: {
        requestId: string;
        billId: string;
      };
    }
  | {
      action_type: 'request.validate_by_controller';
      data: Record<string, never>;
    }
  | {
      action_type: 'invoice_supplier.create';
      data: {
        supplierName: string;
      };
    }
  | {
      action_type: 'invoice_supplier.update';
      data: {
        supplierName: string;
      };
    }
  | {
      action_type: 'invoice_supplier.uploadBankDetails';
      data: null;
    }
  | {
      action_type: 'invoice_supplier_bank_info.create';
      data: null;
    }
  | {
      action_type: 'invoice_supplier_bank_info.update';
      data: {
        from: {
          name: string;
          supplier_details: RawSupplier['supplier_details'];
        };
        to: {
          name: string;
          supplier_details: RawSupplier['supplier_details'];
        };
      };
    };

export const transformTimeline = (rawTimeline: RawTimeline): Timeline =>
  rawTimeline.map(transformTimelineEvent).filter(Boolean) as Timeline;

const transformTimelineEvent = (
  rawTimelineEvent: RawTimelineEvent,
): TimelineEvent | null => {
  const timelineEventAction = transformTimelineEventAction(rawTimelineEvent);
  return timelineEventAction
    ? {
        id: rawTimelineEvent.id,
        occuredAt: new Date(rawTimelineEvent.occured_at),
        user: transformTimelineUser(rawTimelineEvent.user),
        ...timelineEventAction,
      }
    : null;
};

const transformTimelineUser = (
  rawUser: RawTimelineEvent['user'],
): TimelineEvent['user'] => ({
  id: rawUser.id,
  fullName: rawUser.fullname,
  firstName: rawUser.first_name,
  lastName: rawUser.last_name,
});

const transformTimelineEventAction = (
  rawTimelineEvent: RawTimelineEvent,
): TimelineEventAction | null => {
  switch (rawTimelineEvent.action_type) {
    case 'invoice_request.updated': {
      const { from, to } = rawTimelineEvent.data;
      return {
        actionType: 'invoice_request.updated',
        data: {
          from: {
            amountDeclared: from.amount_declared,
            currencyDeclared: from.currency_declared,
            description: from.description,
          },
          to: {
            amountDeclared: to.amount_declared,
            currencyDeclared: to.currency_declared,
            description: to.description,
          },
        },
      };
    }
    case 'invoice_request.updateSchedules': {
      const { from, to } = rawTimelineEvent.data;

      return {
        actionType: 'invoice_request.updateSchedules',
        data: {
          from: {
            amount: from.amount,
            date: new Date(from.date),
          },
          to: {
            amount: to.amount,
            date: new Date(to.date),
          },
        },
      };
    }

    case 'request.create': {
      return {
        actionType: 'request.create',
        data: {
          amountDeclared: rawTimelineEvent.data.amountDeclared,
          currency: rawTimelineEvent.data.currency,
        },
      };
    }

    case 'request.approve': {
      return {
        actionType: 'request.approve',
        data: {
          amountApproved: rawTimelineEvent.data.amountApproved,
          currency: rawTimelineEvent.data.currency,
        },
      };
    }

    case 'request.invoice': {
      return {
        actionType: 'request.invoice',
        data: {},
      };
    }

    case 'request.sent_back_to_review': {
      return {
        actionType: 'request.sent_back_to_review',
        data: {
          requestId: rawTimelineEvent.data.requestId,
          billId: rawTimelineEvent.data.billId,
        },
      };
    }

    case 'request.validate_by_controller': {
      return {
        actionType: 'request.validate_by_controller',
        data: {},
      };
    }

    case 'invoice_request.addSchedule':
    case 'invoice_request.createSchedule': {
      return {
        actionType: rawTimelineEvent.action_type,
        data: {},
      };
    }

    case 'invoice_supplier.create':
    case 'invoice_supplier.update': {
      return {
        actionType: rawTimelineEvent.action_type,
        data: { supplierName: rawTimelineEvent.data.supplierName },
      };
    }

    case 'invoice_supplier.uploadBankDetails': {
      return {
        actionType: 'invoice_supplier.uploadBankDetails',
        data: {},
      };
    }
    case 'invoice_supplier_bank_info.create': {
      return {
        actionType: 'invoice_supplier_bank_info.create',
        data: {},
      };
    }

    case 'invoice_supplier_bank_info.update': {
      return {
        actionType: 'invoice_supplier_bank_info.update',
        data: rawTimelineEvent.data,
      };
    }

    default: {
      return null;
    }
  }
};
