import isEqual from 'lodash/isEqual';
import type React from 'react';
import { createContext, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routeFor, routes } from 'src/core/constants/routes';

import { filtersToUrlQueryString } from './filtersToUrlQueryString';
import { urlQueryStringToFilters } from './urlQueryStringToFilters';
import {
  type Filters as State,
  type PeriodFilter,
  type SortFilter,
  type GroupFilter,
  type AdditionalFilter,
  defaultSortFilter,
} from '../../models';

const getDefaultState = (): State => ({
  sort: defaultSortFilter,
  additional: [],
});

export const getFiltersFromUrl = (location: Location): State =>
  urlQueryStringToFilters(getDefaultState(), location.search);

type Action =
  | { type: 'SET_SORT'; sort: SortFilter }
  | { type: 'SET_GROUP'; group: GroupFilter }
  | {
      type: 'SET_PERIOD';
      period: PeriodFilter;
    }
  | { type: 'SET_SEARCH'; search: string }
  | { type: 'SET_ADDITIONAL'; additional: AdditionalFilter[] }
  | { type: 'RESET_FILTERS' };

const getInitialState = (
  filtersFromUrl: ReturnType<typeof getFiltersFromUrl>,
): State => {
  let sort: SortFilter = filtersFromUrl.sort || defaultSortFilter;

  if (filtersFromUrl.group) {
    sort = 'natural';
  }

  return {
    sort,
    group: filtersFromUrl.group,
    period: filtersFromUrl.period,
    search: filtersFromUrl.search,
    additional: filtersFromUrl.additional || [],
  };
};

export const usePreparePayablesFiltersContextReducer = (): [
  State,
  React.Dispatch<Action>,
] => {
  const history = useHistory();
  const companyId = useCompanyId();

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'SET_SORT': {
        return { ...state, sort: action.sort };
      }
      case 'SET_GROUP': {
        return {
          ...state,
          group: state.group === action.group ? undefined : action.group,
        };
      }
      case 'SET_PERIOD': {
        return {
          ...state,
          period: {
            startDate:
              action.period.startDate &&
              new Date(action.period.startDate).toISOString(),
            endDate:
              action.period.endDate &&
              new Date(action.period.endDate).toISOString(),
          },
        };
      }
      case 'SET_SEARCH': {
        return { ...state, search: action.search };
      }
      case 'SET_ADDITIONAL': {
        return { ...state, additional: action.additional };
      }
      case 'RESET_FILTERS': {
        return { ...getDefaultState(), search: '' };
      }
      default:
        throw new Error(`Unexpected action ${action}`);
    }
  };

  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(getFiltersFromUrl(history.location)),
  );

  const previousState = usePrevious(state);

  // Navigate back to the index page if filters has changed but we are not on first render
  useEffect(() => {
    if (previousState && !isEqual(previousState, state)) {
      history.replace({
        pathname: routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
          company: companyId,
        }),
        search: `?${filtersToUrlQueryString(state)}`,
      });
    }
  }, [state]);

  return [state, dispatch];
};

export const actionsFactory = (dispatch: (action: Action) => void) => ({
  setSortFilter: (sort: SortFilter): void => {
    dispatch({ type: 'SET_SORT', sort });
  },
  setGroupFilter: (group: GroupFilter): void => {
    dispatch({ type: 'SET_GROUP', group });
  },
  setPeriodFilter: (period: PeriodFilter): void => {
    dispatch({ type: 'SET_PERIOD', period });
  },
  setSearch: (search: string): void => {
    dispatch({ type: 'SET_SEARCH', search });
  },
  setAdditionalFilters: (additional: AdditionalFilter[]): void => {
    dispatch({ type: 'SET_ADDITIONAL', additional });
  },
  reset: (): void => {
    dispatch({ type: 'RESET_FILTERS' });
  },
});

export const PreparePayablesFiltersContext: React.Context<{
  state: State;
  actions: ReturnType<typeof actionsFactory>;
}> = createContext({
  state: getDefaultState(),
  actions: actionsFactory(() => {}),
});
