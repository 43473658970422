export const replaceAt = <T>(array: T[], index: number, value: T) => {
  const copy = [...array];
  copy.splice(index, 1, value);
  return copy;
};

export const insertAt = <T>(array: T[], index: number, value: T) => [
  ...array.slice(0, index),
  value,
  ...array.slice(index),
];
