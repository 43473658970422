import { type DraftSupplier } from '../../../models';

export type CreateSupplierAPIPayload = {
  name: string;
  supplier_details: {
    account_holder_name?: string | null;
    account_code?: string | null;
    account_number?: string | null;
    address?: string | null;
    bank_country?: string | null;
    bic?: string | null;
    city?: string | null;
    country?: string | null;
    iban?: string | null;
    legal_name?: string | null;
    registration_number?: string | null;
    routing_number?: string | null;
    sort_code?: string | null;
    vat_number?: string | null;
    zipcode?: string | null;
  };
};

export const transformDraftSupplierToAPIPayload = ({
  name,
  address,
  bankInfos,
  legalInfos,
}: DraftSupplier): CreateSupplierAPIPayload => ({
  name,
  supplier_details: {
    account_holder_name: bankInfos?.accountHolderName ?? undefined,
    account_code: bankInfos?.accountCode ?? undefined,
    account_number: bankInfos?.accountNumber ?? undefined,
    address: address.address ?? undefined,
    bank_country: bankInfos?.bankCountry ?? undefined,
    bic: bankInfos?.bic ?? undefined,
    city: address.city ?? undefined,
    country: address.country ?? undefined,
    iban: bankInfos?.iban ?? undefined,
    legal_name: legalInfos?.legalName ?? undefined,
    registration_number: legalInfos?.registrationNumber ?? undefined,
    routing_number: bankInfos?.routingNumber ?? undefined,
    sort_code: bankInfos?.sortCode ?? undefined,
    vat_number: legalInfos.vatNumber ?? undefined,
    zipcode: address.zipcode ?? undefined,
  },
});
