import { useState, useEffect } from 'react';

import { type LeaderboardItem } from '../../leaderboardItem';

type PagingInfos = {
  currentPage: number;
  nbOfPages: number;
  nextPage: () => void;
  previousPage: () => void;
};

type AllKeys = keyof Omit<LeaderboardItem, 'user'> | 'user.fullname';

const ITEMS_PER_PAGE = 5;
const compareBy = (key: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (a: any, b: any) => {
    if (getKey(a, key) < getKey(b, key)) {
      return -1;
    }
    if (getKey(a, key) > getKey(b, key)) {
      return 1;
    }
    return 0;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getKey = (object: any, key: string) => {
  const splittedKey = key.split('.');
  return splittedKey.reduce(
    (previous, current) => previous && previous[current],
    object,
  );
};

export const usePaging = (
  leaderboardItems: LeaderboardItem[],
): [LeaderboardItem[], (key: AllKeys) => void, PagingInfos] => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortKey, setSortKey] = useState<AllKeys | ''>('');
  const [items, setItems] = useState(leaderboardItems);
  const [visibleItems, setVisibleItems] = useState<LeaderboardItem[]>([]);

  const nbOfPages = Math.ceil(leaderboardItems.length / ITEMS_PER_PAGE);

  useEffect(() => {
    setItems(leaderboardItems);
  }, [leaderboardItems]);

  useEffect(() => {
    const start = ITEMS_PER_PAGE * (currentPage - 1);
    setVisibleItems(items.slice(start, start + ITEMS_PER_PAGE));
  }, [currentPage, items]);

  const nextPage = (): void => {
    if (ITEMS_PER_PAGE * currentPage < items.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = (): void => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const sortBy = (key: AllKeys): void => {
    const sortedItems = [...items];
    if (sortKey === key) {
      sortedItems.reverse();
    } else {
      sortedItems.sort(compareBy(key));
    }

    setSortKey(key);
    setItems(sortedItems);
  };

  return [
    visibleItems,
    sortBy,
    { currentPage, nextPage, previousPage, nbOfPages },
  ];
};
