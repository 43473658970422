import React from 'react';

import { CalloutError, lazyLoad } from 'src/core/common/components/LazyLoad';

import { EmptyStateGraph } from './EmptyStateGraph';

export const LazyLoadedGraph = lazyLoad({
  loader: async () => ({
    // eslint-disable-next-line unicorn/no-await-expression-member
    default: (await import(/* webpackChunkName: "Graph" */ './Graph')).Graph,
  }),
  loading: <EmptyStateGraph />,
  fallback: (props) => <CalloutError {...props} />,
});
