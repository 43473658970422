import { useQuery } from 'src/core/api/hooks/useQuery';

export type Response = {
  endpointsRollout: {
    getDocumentaryEvidencesByPayableId: boolean;
    getPayable: boolean;
  };
};

export function useRestEndpoints() {
  const queryResult = useQuery<Response>({
    key: 'GET /bookkeeping/rest-endpoint-migration-access',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/bookkeeping/rest-endpoint-migration-access',
    },
    options: {
      cacheTime: 10 * 60 * 1000 /* 10min */,
      staleTime: 10 * 60 * 1000 /* 10min */,
    },
  });

  return queryResult.status === 'success'
    ? queryResult.data.endpointsRollout
    : undefined;
}
