import { getCompany } from 'modules/company';
import { type AppState } from 'src/core/reducers';
import { getUsers } from 'src/core/selectors/users';

import { type Member } from '../member';

// FIXME: move this selector in the right place!
export const getMembers = (state: AppState): Member[] => {
  const company = getCompany(state);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const users: any[] = getUsers(state);
  return (
    users?.map((user) => {
      const userCompanyData = user.data_by_company[company.id];
      const teamNames = userCompanyData.groups_names;
      return {
        id: user.id,
        email: user.email,
        fullname: user.fullname,
        pending: user.pending,
        avatar: user.avatar,
        gender: user.gender,
        teamNames,
      };
    }) ?? []
  );
};
