import React from 'react';

import { ErrorState } from 'common/components/ErrorState';
import { useTranslation } from 'common/hooks/useTranslation';

export const TransferListErrorState = () => {
  const { t } = useTranslation();

  return (
    <ErrorState
      className="h-full justify-center"
      title={t('invoices.transfer.errorState.fetchTransfersErrorTitle')}
    />
  );
};
