import { type AnyAction, type Reducer } from '@reduxjs/toolkit';

import { RequestState } from './requestState';

type ActionTypes = {
  request: string;
  success: string;
  failure: string;
  reset?: string;
};

export type Pagination<TItem> = {
  limit: number;
  nextCursor: number | string | null;
  requestState: RequestState;
  items: TItem[];
};

/**
 * This helper creates a standardized Redux reducer for paginated entities.
 * The pagination system it uses is designed for a "Load more" experience,
 * meaning it doesn't actually use "pages" but relies on a cursor and limit
 * parameters to load data in batches.
 * The cursor is meant to be API-directed and should not be considered as
 * stable in the front-end.
 * It only focuses on managing the state of the pagination itself and doesn't
 * handle anything related to the actual data fetching of the paginated entities
 * itself.
 */
// TODO: add unit tests
export const createPaginatedReducer = <
  TItem,
  TState extends Pagination<TItem> = Pagination<TItem>,
  TAction extends AnyAction = AnyAction,
>(
  actionTypes: ActionTypes,
  initialState: TState,
  // Enable extending the behavior of the paginated reducer
  customReducer: Reducer<typeof initialState, TAction> = (
    state = initialState,
  ): TState => state,
): Reducer<TState, TAction> => {
  const { request, success, failure, reset } = actionTypes;

  return (state = initialState, action: TAction): TState => {
    switch (action.type) {
      case request:
        return customReducer(
          {
            ...state,
            requestState: RequestState.Loading,
          },
          action,
        );
      case success:
        return customReducer(
          {
            ...state,
            nextCursor: action.payload.nextCursor,
            requestState: RequestState.Success,
            items: [...state.items, ...action.payload.items],
          },
          action,
        );
      case failure:
        return customReducer(
          {
            ...state,
            requestState: RequestState.Failure,
          },
          action,
        );
      case reset:
        return customReducer(initialState, action);
      default:
        return customReducer(state, action);
    }
  };
};

/**
 * Expose a few utils to be used as a selectors to read values from the
 * paginated state.
 */
export const getNextCursor = <TItem>(p: Pagination<TItem>) => p.nextCursor;
export const getLimit = <TItem>(p: Pagination<TItem>) => p.limit;
export const getRequestState = <TItem>(p: Pagination<TItem>) => p.requestState;
export const getItems = <TItem>(p: Pagination<TItem>) => p.items;
export const getHasMoreItems = <TItem>(p: Pagination<TItem>) =>
  Boolean(getNextCursor(p));
