import { useParams, useHistory } from 'react-router-dom';

import { routes, routeFor } from "src/core/constants/routes";

export const useRedirectToBudgetaryExercises = (): (() => void) => {
  const history = useHistory();
  const { company: companyId } = useParams();

  return (): void => {
    history.push(
      routeFor(routes.BUDGETARY_EXERCISES.path, {
        company: companyId,
      }),
    );
  };
};
