import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { postShowPaymentPassword } from 'modules/cards/api/postShowPaymentPassword';
import { scaProcedureErrors } from 'modules/cards/models/sca';

import { useGetPaymentPasswordProcedure } from '../../hooks/useGetPaymentPasswordProcedure';
import { SfsUkPasswordModal } from '../SfsUkPasswordModal/SfsUkPasswordModal';
import mobileImage from '../assets/mobile.png';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SFSUkScaPasswordModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');
  const [procedureId, setProcedureId] = useState<string | undefined>(undefined);
  const { passwordToShow, procedureFailedReason, setProcedureFailedReason } =
    useGetPaymentPasswordProcedure(procedureId);

  useEffect(() => {
    const handleSCAShowPassword = async () => {
      try {
        const response = await postShowPaymentPassword();
        setProcedureId(response.procedureId);
      } catch {
        setProcedureFailedReason('initializationError');
      }
    };
    handleSCAShowPassword();
  }, []);

  if (passwordToShow) {
    return (
      <SfsUkPasswordModal
        isOpen={isOpen}
        onClose={onClose}
        password={passwordToShow}
      />
    );
  }

  return (
    <Modal
      illustration={<img src={mobileImage} alt="" />}
      illustrationHeight="400px"
      title={t('cards.otpPasswordModal.title')}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button
          key="confirm"
          onClick={onClose}
          text={t('cards.otpPasswordModal.cancel')}
          variant="primary"
        />,
      ]}
    >
      {procedureId && !procedureFailedReason && (
        <p className="-mt-s">{t('cards.otpPasswordModal.subtitle')}</p>
      )}
      {procedureFailedReason && (
        <Callout
          title={t(scaProcedureErrors[procedureFailedReason])}
          variant="alert"
        />
      )}
    </Modal>
  );
};
