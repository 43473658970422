import { Callout, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useFeature } from "src/core/common/hooks/useFeature";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import FEATURES from "src/core/constants/features";

import styles from './MemberInviteTipModal.module.css';

const Description = ({
  title,
  description,
}: {
  title?: string;
  description: string;
}) => (
  <div className={styles.description}>
    {title && <div className={styles.descriptionTitle}>{title}</div>}
    {description}
  </div>
);

const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <div className="mb-l text-left">
    <div className="mb-xs title-xl">{title}</div>
    {children}
  </div>
);

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export const MemberInviteTipModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');
  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);
  const isCostCenterActivatedFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('members.inviteSettingsModalTitle')}
      iconName="info"
      onClose={onClose}
    >
      <Section title={t('members.rolesTitle')}>
        <Description
          title={t('members.admin')}
          description={t('members.adminDescription')}
        />
        <Description
          title={t('members.controller')}
          description={t('members.controllerDescription')}
        />
        <Description
          title={t('members.requester')}
          description={t('members.requesterDescription')}
        />
        <Callout title={t('members.rolesNotif')} />
      </Section>
      <Section title={t('members.policiesTitle')}>
        <Description description={t('members.policiesDescription')} />
      </Section>
      {(isCostCenterActivatedFeatureEnabled && (
        <Section title={t('members.costCenterTitle')}>
          <Description description={t('members.costCenterDescription')} />
        </Section>
      )) ||
        (isTeamsFeatureEnabled && (
          <Section title={t('members.teamsTitle')}>
            <Description description={t('members.teamsDescription')} />
          </Section>
        ))}
    </Modal>
  );
};
