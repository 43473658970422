import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type BudgetaryExerciseInput = {
  name: string;
  startDate?: string;
  endDate?: string;
  periodicity?: 'monthly' | 'quarterly' | 'yearly';
};
type RawNewBudgetaryExercise = {
  id: string;
};

type NewBudgetaryExercise = {
  id: string;
};

export const useCreateBudgetaryExercise = (): MutationState<
  BudgetaryExerciseInput,
  NewBudgetaryExercise
> => {
  return useMutation<
    BudgetaryExerciseInput,
    NewBudgetaryExercise,
    RawNewBudgetaryExercise
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/budgets/budgetary-exercises',
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['budgetaryExercises']);
      },
    },
    reshapeData(data) {
      return {
        id: data.id,
      };
    },
  });
};
