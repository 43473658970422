import {
  Button,
  HighlightIcon,
  Icon,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Avatar,
  DATE_FORMAT,
  Tooltip,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { useEffect } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { capitalize } from 'common/utils/string';
import { type Company } from 'modules/app/hooks/useCompany';
import { onMemberDetailsModalOpened as trackOnMemberDetailsModalOpened } from 'modules/members/tracking';
import FEATURES from 'src/core/constants/features';
import { type AccountingSoftware } from 'src/core/modules/bookkeep/integration/status';
import { useHasWireTransferDelegationFeature } from 'src/core/modules/delegation/hooks/useHasWireTransferDelegationFeature';
import { useIsUserManagedByKombo } from 'src/core/modules/integrations/hooks';

import { MemberDetailsRow as Row } from './MemberDetailsModalRow';
import { MemberDetailsModalControlRule } from './components/MemberDetailsModalControlRule';
import { MemberDetailsModalPolicy } from './components/MemberDetailsModalPolicy';
import { ROLE_NAMES_TO_DISPLAY } from '../../constants/roles';
import {
  getMemberName,
  getMemberPhoneNumber,
  getMemberRoles,
  getMemberTeams,
  type MemberDetails,
} from '../../models/member';
import { translateRoleName } from '../../models/roles';
import { MemberSyncStatus } from '../MemberSyncStatus/MemberSyncStatus';

import './MemberDetailsModal.css';

type Props = {
  member: MemberDetails;
  company: Company;
  isCurrentUser: boolean;
  memberSync?: {
    isEnabled: boolean;
    integrationName?: AccountingSoftware;
    status?: { synced: boolean; url: string | null };
  };
  onEdit(): void;
  onDelete(): void;
  onResendInvitation(): void;
  onUpdateCardsAccess(hasAccess: boolean): Promise<void>;
  onClose(): void;
};

export function MemberDetailsModal({
  member,
  company,
  isCurrentUser,
  memberSync,
  onEdit,
  onDelete,
  onResendInvitation,
  onUpdateCardsAccess,
  onClose,
}: Props) {
  useEffect(() => {
    trackOnMemberDetailsModalOpened({ member });
  }, []);

  const { t, localeFormat } = useTranslation('global');

  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);
  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const isPlayByRulesFeatureEnabled = useFeature(FEATURES.PLAY_BY_RULES);
  const isCardsAccessFeatureEnabled = useFeature(FEATURES.CARDS_ACCESS);

  const hasReportingManagerFeature = useFeature(FEATURES.REPORTING_MANAGERS);
  const hasWireTransferConfirmationFeature =
    useHasWireTransferDelegationFeature();

  const isCardsAccessManuallyRestricted =
    member.cardsAccess?.hasAccess === false &&
    member.cardsAccess.initiator === 'user';

  const hasPlasticCard = Boolean(member.plasticCard);

  const isManagedByKombo = useIsUserManagedByKombo({ userId: member.id });

  const memberRoles = getMemberRoles(member);
  const memberTeams = getMemberTeams(member);

  return (
    <ModalOverlay isOpen>
      <ModalContent
        aria-labelledby="MemberDetailsModal"
        className={cx({
          'MemberDetailsModal--alert': isCardsAccessManuallyRestricted,
        })}
        onClose={onClose}
      >
        <ModalBody>
          <div className="text-left">
            <div className="text-center">
              <Avatar
                src={member.avatar}
                text={getMemberName(member)}
                size="l"
              />

              <div
                id="MemberDetailsModal"
                className="MemberDetailsModal__header__name title-xl"
              >
                {getMemberName(member)}
                {isCardsAccessManuallyRestricted && (
                  <HighlightIcon
                    size="s"
                    variant="alert"
                    name="card-lock"
                    className="ml-xs"
                  />
                )}
              </div>

              {memberSync?.isEnabled && memberSync.status && (
                <MemberSyncStatus
                  status={memberSync.status}
                  integrationName={memberSync.integrationName}
                />
              )}

              <div className="MemberDetailsModal__header__container">
                <div className="flex items-center">
                  <div className="text-neutral-dark">
                    {t(
                      member.isPending
                        ? 'members.invitedOn'
                        : 'members.memberSince',
                      {
                        date: localeFormat(
                          new Date(member.createdAt),
                          DATE_FORMAT.SHORT,
                        ),
                      },
                    )}
                  </div>
                  {hasPlasticCard && (
                    <>
                      <div className="MemberDetailsModal__verticalDivider" />
                      {t('members.countPlasticCards', { count: 1 })}
                    </>
                  )}

                  {member.subscriptionsCount > 0 && (
                    <>
                      <div className="MemberDetailsModal__verticalDivider" />
                      {t('members.countActiveSubscriptions', {
                        count: member.subscriptionsCount,
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="separator my-s" />

            <div className="pb-xs">
              <div className="MemberDetailsModal__main__heading title-l">
                {t('members.detailsModal.memberInformation')}
                <Button
                  variant="primary"
                  text={t('members.detailsModal.editButton')}
                  onClick={() => onEdit()}
                />
              </div>

              <Row label={t('misc.emailAddress')}>
                <div className="text-complementary">{member.email}</div>
              </Row>

              <Row label={t('misc.phoneNumber')}>
                {!member.mobileNo || !member.mobileExt ? (
                  <div className="text-neutral-dark">
                    {t('members.detailsModal.noMobileNumber')}
                  </div>
                ) : (
                  <div className="text-complementary">
                    {getMemberPhoneNumber(member)}
                  </div>
                )}
              </Row>

              <Row label={t('misc.roles')}>
                {memberRoles.length === 0 ? (
                  <div className="text-neutral-dark">
                    {t('members.memberHasNoRole')}
                  </div>
                ) : (
                  <div className="text-complementary">
                    {memberRoles
                      .filter((role) =>
                        ROLE_NAMES_TO_DISPLAY.includes(role.name),
                      )
                      .map((role) =>
                        capitalize(translateRoleName(role.name, t)),
                      )
                      .join(', ')}
                  </div>
                )}
              </Row>
              {hasReportingManagerFeature && (
                <Row label={t('members.detailsModal.manager')}>
                  <div className="text-neutral-dark">
                    {member.manager
                      ? member.manager.fullNameOrEmail
                      : t('members.detailsModal.noManager')}
                  </div>
                </Row>
              )}

              {hasWireTransferConfirmationFeature && (
                <Row label={t('members.detailsModal.additionalRights')}>
                  {member.canConfirmPayments ? (
                    <div className="text-complementary">
                      {t('members.detailsModal.confirmPayments')}
                    </div>
                  ) : (
                    <div className="text-neutral-dark">
                      {t('members.detailsModal.noAdditionalRights')}
                    </div>
                  )}
                </Row>
              )}

              {isCostCentersFeatureEnabled && (
                <Row label={t('members.detailsModal.costCenter')}>
                  {!member.costCenter ? (
                    <div className="text-neutral-dark">
                      {t('members.detailsModal.noCostCenter')}
                    </div>
                  ) : (
                    <div className="text-complementary">
                      {member.costCenter?.name}

                      {member.costCenter.selectionMode === 'suggested' && (
                        <span> ({t('members.suggestedCostCenter')})</span>
                      )}
                    </div>
                  )}
                </Row>
              )}

              {isPlayByRulesFeatureEnabled && (
                <Row label={t('members.detailsModal.controlRule')}>
                  <MemberDetailsModalControlRule
                    controlRule={member.controlRule}
                  />
                </Row>
              )}

              {isTeamsFeatureEnabled && (
                <Row label={t('misc.teams')}>
                  {memberTeams.length === 0 ? (
                    <div className="text-neutral-dark">
                      {t('members.memberHasNoGroup')}
                    </div>
                  ) : (
                    <div className="text-complementary">
                      {memberTeams.map((team) => team.name).join(', ')}
                    </div>
                  )}
                </Row>
              )}

              <Row label={t('misc.policy')}>
                <MemberDetailsModalPolicy member={member} company={company} />
              </Row>

              {company.type !== 'branch_expense_entity' && (
                <Row
                  label={t('members.bankInfo')}
                  containerClassNames={
                    member.bankInfo
                      ? ''
                      : 'MemberDetailsModal__warningContainer'
                  }
                >
                  {member.bankInfo ? (
                    <div className="text-complementary">
                      {t('members.bankInfoCorrect')}
                    </div>
                  ) : (
                    <div className="flex items-center justify-between">
                      {t('members.detailsModal.noBankInfos')}
                      <Icon name="warning" size="s" />
                    </div>
                  )}
                </Row>
              )}
            </div>
            <div className="separator my-s" />

            <div className="MemberDetailsModal__actions">
              {member.isPending && (
                <div className="flex items-center gap-s">
                  <Button
                    iconName="bell"
                    variant="secondary"
                    text={t('members.resendInvitation')}
                    onClick={() => onResendInvitation()}
                  />
                  <Tooltip
                    content={t(
                      'integration.members.invitationForHrManagedMemberCantBeRevokedTooltip',
                    )}
                    placement="top"
                    isDisabled={!isManagedByKombo}
                    maxWidth={320}
                  >
                    <Button
                      iconName="minus-circle"
                      variant="secondary"
                      text={t('members.revokeInvitation')}
                      onClick={() => onDelete()}
                      isDisabled={isManagedByKombo}
                    />
                  </Tooltip>
                </div>
              )}

              {!member.isPending &&
                !isCurrentUser &&
                !member.isAccountOwner &&
                !member.isOrganisationOwner && (
                  <div className="flex items-center gap-s">
                    {isCardsAccessFeatureEnabled && (
                      <Button
                        variant="secondary"
                        iconName={
                          isCardsAccessManuallyRestricted ? 'lock' : 'unlock'
                        }
                        text={
                          isCardsAccessManuallyRestricted
                            ? t('members.detailsModal.allowCardsAccess')
                            : t('members.detailsModal.restrictCardsAccess')
                        }
                        onClick={() =>
                          onUpdateCardsAccess(isCardsAccessManuallyRestricted)
                        }
                      />
                    )}
                    <Tooltip
                      content={t(
                        'integration.members.hrManagedMemberCantBeDeletedTooltip',
                      )}
                      placement="top"
                      isDisabled={!isManagedByKombo}
                      maxWidth={320}
                    >
                      <Button
                        iconName="minus-circle"
                        variant="secondary"
                        text={t('members.detailsModal.removeMemberButton')}
                        onClick={onDelete}
                        isDisabled={isManagedByKombo}
                      />
                    </Tooltip>
                  </div>
                )}
              {member.isAccountOwner && (
                <div className="MemberDetailsModal__actions_ao-cant-be-deleted text-neutral-dark">
                  {t('members.detailsModal.aoCantBeDeleted')}
                </div>
              )}
              {member.isOrganisationOwner && (
                <div className="MemberDetailsModal__actions_ao-cant-be-deleted text-neutral-dark">
                  {t('members.detailsModal.ooCantBeDeleted')}
                </div>
              )}
              {!member.isAccountOwner &&
                !member.isOrganisationOwner &&
                isCurrentUser && (
                  <div className="MemberDetailsModal__actions_ao-cant-be-deleted text-neutral-dark">
                    {t('members.detailsModal.cantDeleteOwnAccount')}
                  </div>
                )}
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
}
