import { Icon } from '@dev-spendesk/grapes';

import { generateJiraLink } from 'src/core/modules/jira-issue-reporter/useJiraLink';

import { UnexpectedError } from './UnexpectedError';

import './UnexpectedErrorContainer.css';

export const UnexpectedErrorContainer = () => {
  return (
    <div className="UnexpectedErrorContainer">
      <UnexpectedError
        actions={
          window.Spx &&
          window.Spx.ssdInfo && (
            <a
              href={generateJiraLink({
                companyId: window.Spx.ssdInfo?.companyId,
                companyName: window.Spx.ssdInfo?.companyName,
                userEmail: window.Spx.ssdInfo?.userEmail,
              })}
              target="_about"
              className="flex items-center text-complementary"
            >
              <Icon
                size="l"
                name="service-bell"
                className="mr-xs"
                aria-hidden="true"
              />
              Create an issue on SSD
            </a>
          )
        }
      />
    </div>
  );
};
