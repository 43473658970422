import { useMemo } from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';

import {
  type BankDetailsSuggestion,
  type BankDetailsSuggestionMatch,
  type SupplierBankInfos,
  compareBankDetailsSuggestionToSupplierBankInfo,
} from '../../models';

type Params = {
  bankDetailsSuggestion: BankDetailsSuggestion;
  supplierBankInfos: SupplierBankInfos;
};

export const useCompareBankDetailsSuggestionToSupplierBankInfo = ({
  bankDetailsSuggestion,
  supplierBankInfos,
}: Params): BankDetailsSuggestionMatch => {
  const company = useCompany();

  return useMemo(
    () =>
      compareBankDetailsSuggestionToSupplierBankInfo(
        bankDetailsSuggestion,
        supplierBankInfos,
        company,
      ),
    [bankDetailsSuggestion, supplierBankInfos, company],
  );
};
