export type InvoicesCounts = Readonly<{
  submittedInvoicesToAssess: number | undefined;
  submittedCreditNotesToAssess: number | undefined;
  paymentsToSchedule: number | undefined;
  transfersToConfirm?: number | undefined;
  scheduledPaymentsToConfirm: number | undefined;
  invoicesPaymentsCount?: number;
}>;

export type InvoiceStatus =
  | 'approved'
  | 'to-approve'
  | 'to-review'
  | 'to-pay'
  | 'partially-paid'
  | 'paid';

export const executeOnIndividualDateFlag = 'due-date';
