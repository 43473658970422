import { gql } from 'graphql-tag';

export const GET_COMPANY = gql`
  query GetCompany($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      currency
      announcements {
        code
        lastSeenAt
      }
      automaticReceiptReminders
    }
  }
`;
