import cx from 'classnames';
import React from 'react';

import { Img } from 'src/core/common/components/Img';

import { type Supplier } from '../../types';

import './AccountPayableSuppliersRowIcon.css';

type Props = Pick<Supplier, 'logosUrls'>;

const fallbackSource = '/static/img/avatars/supplier.svg';

export const AccountPayableSuppliersRowIcon = ({ logosUrls }: Props) => {
  return (
    <span
      className={cx('AccountPayableSuppliersRowIcon__icon-container', {
        'AccountPayableSuppliersRowIcon__icon-container-single':
          logosUrls.length === 1,
      })}
    >
      <Img
        className={
          logosUrls.length === 1
            ? 'AccountPayableSuppliersRowIcon__icon-single'
            : 'AccountPayableSuppliersRowIcon__icon'
        }
        src={logosUrls[0]}
        alt=""
        fallbackSrc={fallbackSource}
      />
      {/* Account payable entry on global suppliers list provide at leat 2 icons */}
      {logosUrls.length > 1 && (
        <Img
          className="AccountPayableSuppliersRowIcon__icon-background-img"
          src={logosUrls[1]}
          alt=""
          fallbackSrc={fallbackSource}
        />
      )}
    </span>
  );
};
