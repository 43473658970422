import { type ApiApprovalPolicy } from '../../company/members/components/ApprovalPolicies';

class TypeGuardError extends Error {
  constructor(_switchCase: never, message: string) {
    super(message);
  }
}

export type Policy = {
  id: string;
  name: string | null;
  isDefault: boolean;
  isCustom: boolean;
  usersCount?: number;
  params: PolicyParams;
};

const availableSpendingTypes = <const>[
  'singlePurchase',
  'subscription',
  'expenseClaim',
  'invoice',
  'cardLoad',
  'purchaseOrder',
];

export type SpendingType = (typeof availableSpendingTypes)[number];

export type ApprovalNeeded = 'always' | 'sometimes' | 'never';

export type PolicyParams = {
  approvalNeeded: ApprovalNeeded;
  spendingTypes: SpendingType[];
  amountPerTransaction: number;
  amountPerMonth: number;
};

export const reshapeToApprovalPolicy = (policy: ApiApprovalPolicy): Policy => {
  return {
    id: policy.id,
    name: policy.name,
    isDefault: policy.is_default,
    isCustom: policy.is_user_custom,
    usersCount: policy.nb_users,
    params: {
      spendingTypes: reshapeToSpendingTypes(policy.params.spending_types),
      approvalNeeded: policy.params.approval_needed,
      amountPerMonth: policy.params.spending_limit,
      amountPerTransaction: policy.params.transaction_max,
    },
  };
};

export type ApiSpendingType =
  | 'single_purchase'
  | 'card_load'
  | 'expense'
  | 'invoice'
  | 'subscription'
  | 'purchase_order';

export const reshapeToSpendingTypes = (
  spendingTypesString: string | ApiSpendingType[],
): SpendingType[] => {
  let types: ApiSpendingType[] = [];

  if (typeof spendingTypesString === 'string') {
    types = spendingTypesString.split('|').filter(Boolean) as ApiSpendingType[];
  } else {
    types = spendingTypesString;
  }
  if (!types) {
    return [];
  }
  return types.map((spendingType: ApiSpendingType) => {
    switch (spendingType) {
      case 'single_purchase':
        return 'singlePurchase';
      case 'card_load':
        return 'cardLoad';
      case 'expense':
        return 'expenseClaim';
      case 'invoice':
        return 'invoice';
      case 'subscription':
        return 'subscription';
      case 'purchase_order':
        return 'purchaseOrder';
      default:
        throw new TypeGuardError(
          spendingType,
          `Unknown spending type ${spendingType}`,
        );
    }
  });
};
