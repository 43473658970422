import React from 'react';
import { connect, useSelector } from 'react-redux';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import * as layoutActions from 'modules/app/layout/redux/actions';
import { type AppState } from "src/core/reducers";
import { getHasHomepage } from "src/core/selectors/globalSelectors";

import { CompanySwitcher } from './CompanySwitcher';
import { getBudget } from '../../redux/selectors';

type Props = {
  fetchMonthlyBudget: () => void;
  budget?: number | null;
};

const CompanySwitcherContainer = ({ budget, fetchMonthlyBudget }: Props) => {
  const hasHomepage = useSelector(getHasHomepage);

  return (
    <ErrorBoundary context={{ scope: 'company-switcher', team: 'kyc' }}>
      <CompanySwitcher
        budget={budget}
        hasHomepage={hasHomepage}
        fetchMonthlyBudget={fetchMonthlyBudget}
      />
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: AppState) => ({
  budget: getBudget(state),
});

const mapDispatchToProps = {
  fetchMonthlyBudget: layoutActions.fetchMonthlyBudget,
};

const ConnectedCompanySwitcherContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanySwitcherContainer);

export { ConnectedCompanySwitcherContainer as CompanySwitcherContainer };
