import { type BookkeepingExport, BookkeepingExportStatus } from '../types';

const processingStatuses = [
  BookkeepingExportStatus.Pending,
  BookkeepingExportStatus.Processing,
];
const successStatuses = [
  BookkeepingExportStatus.Processed,
  BookkeepingExportStatus.Sent,
];
const failureStatuses = [BookkeepingExportStatus.Failed];

export const isExportProcessing = (
  bookkeepingExport: BookkeepingExport,
): boolean => {
  return processingStatuses.includes(bookkeepingExport.status);
};

export const isExportSuccess = (
  bookkeepingExport: BookkeepingExport,
): boolean => {
  return successStatuses.includes(bookkeepingExport.status);
};

export const isExportFailure = (
  bookkeepingExport: BookkeepingExport,
): boolean => {
  return failureStatuses.includes(bookkeepingExport.status);
};
