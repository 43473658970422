/* eslint-disable @typescript-eslint/no-redeclare */
export const FETCH_USERS_LOADING = 'FETCH_USERS_LOADING';
export type FETCH_USERS_LOADING = typeof FETCH_USERS_LOADING;
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export type FETCH_USERS_SUCCESS = typeof FETCH_USERS_SUCCESS;
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export type FETCH_USERS_FAILURE = typeof FETCH_USERS_FAILURE;
export const UPDATE_ME_LOCALLY = 'UPDATE_ME_LOCALLY';
export type UPDATE_ME_LOCALLY = typeof UPDATE_ME_LOCALLY;

export const FETCH_USER_LOADING = 'FETCH_USER_LOADING';
export type FETCH_USER_LOADING = typeof FETCH_USER_LOADING;
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export type FETCH_USER_SUCCESS = typeof FETCH_USER_SUCCESS;
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export type FETCH_USER_FAILURE = typeof FETCH_USER_FAILURE;

export const SAVE_ME_BANK_INFO_LOADING = 'SAVE_ME_BANK_INFO_LOADING';
export type SAVE_ME_BANK_INFO_LOADING = typeof SAVE_ME_BANK_INFO_LOADING;
export const SAVE_ME_BANK_INFO_SUCCESS = 'SAVE_ME_BANK_INFO_SUCCESS';
export type SAVE_ME_BANK_INFO_SUCCESS = typeof SAVE_ME_BANK_INFO_SUCCESS;
export const SAVE_ME_BANK_INFO_FAILURE = 'SAVE_ME_BANK_INFO_FAILURE';
export type SAVE_ME_BANK_INFO_FAILURE = typeof SAVE_ME_BANK_INFO_FAILURE;

export const FETCH_ME_BANK_INFO_LOADING = 'FETCH_ME_BANK_INFO_LOADING';
export type FETCH_ME_BANK_INFO_LOADING = typeof FETCH_ME_BANK_INFO_LOADING;
export const FETCH_ME_BANK_INFO_SUCCESS = 'FETCH_ME_BANK_INFO_SUCCESS';
export type FETCH_ME_BANK_INFO_SUCCESS = typeof FETCH_ME_BANK_INFO_SUCCESS;
export const FETCH_ME_BANK_INFO_FAILURE = 'FETCH_ME_BANK_INFO_FAILURE';
export type FETCH_ME_BANK_INFO_FAILURE = typeof FETCH_ME_BANK_INFO_FAILURE;
