import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type SupplierBankInfos } from '../../../models';
import { BankInfoPreview } from '../BankInfoPreview';

type Props = {
  iban: SupplierBankInfos['iban'];
};

export const IbanPreview = ({ iban }: Props) => {
  const { t } = useTranslation('global');

  return (
    <BankInfoPreview
      primaryText={t('submitMyInvoice.fields.iban.label')}
      secondaryText={iban}
    />
  );
};
