import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { getExportBankJournalCurrentStateQueryKey } from './query-key-registry';

export type StandardFileExportError =
  | { tag: 'fileExportError'; kind: 'unknown'; rawError: unknown }
  | {
      tag: 'fileExportError';
      kind: 'integrationError';
      rawError: unknown;
      reason: string;
    }
  | { tag: 'fileExportError'; kind: 'zipFailed'; rawError: unknown }
  | { tag: 'fileExportError'; kind: 'uploadFailed'; rawError: unknown }
  | { tag: 'fileExportError'; kind: 'nonParsableError' };

export type StandardFileExportStatus =
  | { status: 'created'; id: string; payload: unknown }
  | {
      status: 'failed';
      id: string;
      error: StandardFileExportError;
      payload: unknown;
    }
  | { status: 'uploading'; id: string; payload: unknown }
  | {
      status: 'downloadReady';
      downloadUrl: string;
      id: string;
      payload: unknown;
    };

export type FileExportPollingStatus =
  | {
      isPolling: false;
      exportId: undefined;
      showFileExportSuccessPage: false;
    }
  | {
      isPolling: boolean;
      exportId: string;
      showFileExportSuccessPage: boolean;
    };

export const useExportBankJournalCurrentState = ({
  isPolling,
  exportId,
}: FileExportPollingStatus): QueryState<StandardFileExportStatus> => {
  const companyId = useCompanyId();

  return useQuery<StandardFileExportStatus>({
    key: getExportBankJournalCurrentStateQueryKey(companyId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/accounting-integration/file-export/${exportId}`,
    },
    isEnabled: isPolling,
    options: { refetchInterval: isPolling ? 1000 : undefined },
    reshapeData: (body) => body,
  });
};
