import * as actionTypes from './actionTypes';
import {
  type Account,
  type AccountLoad,
  type WalletInfos,
  type UserFactor,
} from '../wallet';

type State = {
  account: Account | undefined;
  behavior: {
    error: boolean;
    isAccountLoadsLoading: boolean;
    isLoading: boolean;
    isWalletInfosLoading: boolean;
  };
  walletInfos: WalletInfos;
  loads: AccountLoad[];
  phoneUserFactor: UserFactor | undefined;
  isLoadingPhoneUserFactor: boolean;
};

const initialState: State = {
  account: undefined,
  behavior: {
    error: false,
    isAccountLoadsLoading: false,
    isLoading: false,
    isWalletInfosLoading: false,
  },
  walletInfos: {
    subscription: {
      loaded: 0,
      total: 0,
    },
    physicalCards: {
      loaded: 0,
      total: 0,
    },
    singlePurchaseCards: {
      loadedPending: 0,
      loadedRemaining: 0,
    },
    monthlyBudget: 0,
  },
  loads: [],
  phoneUserFactor: undefined,
  isLoadingPhoneUserFactor: true,
};

export const reducer = (
  state: State = initialState,
  action: actionTypes.Actions,
): State => {
  switch (action.type) {
    case actionTypes.FETCH_WALLET_INFOS_LOADING:
      return {
        ...state,
        behavior: { ...state.behavior, isWalletInfosLoading: true },
      };
    case actionTypes.FETCH_WALLET_INFOS_FAILURE:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isWalletInfosLoading: false,
          error: true,
        },
      };
    case actionTypes.FETCH_WALLET_INFOS_SUCCESS: {
      return {
        ...state,
        walletInfos: action.payload.walletInfos,
        behavior: {
          ...state.behavior,
          isWalletInfosLoading: false,
          error: false,
        },
      };
    }

    case actionTypes.FETCH_ACCOUNT_LOADS_LOADING:
      return {
        ...state,
        behavior: { ...state.behavior, isAccountLoadsLoading: true },
      };
    case actionTypes.FETCH_ACCOUNT_LOADS_FAILURE:
      return {
        ...state,
        behavior: {
          ...state.behavior,
          isAccountLoadsLoading: false,
          error: true,
        },
      };
    case actionTypes.FETCH_ACCOUNT_LOADS_SUCCESS:
      return {
        ...state,
        loads: action.payload.loads,
        behavior: {
          ...state.behavior,
          isAccountLoadsLoading: false,
          error: false,
        },
      };

    case actionTypes.FETCH_ACCOUNT_LOADING:
      return { ...state, behavior: { ...state.behavior, isLoading: true } };
    case actionTypes.FETCH_ACCOUNT_FAILURE:
      return {
        ...state,
        behavior: { ...state.behavior, isLoading: false, error: true },
      };
    case actionTypes.FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload.account,
        behavior: { ...state.behavior, isLoading: false, error: false },
      };
    case actionTypes.FETCH_USER_MFA_LOADING:
      return {
        ...state,
        phoneUserFactor: undefined,
        isLoadingPhoneUserFactor: true,
      };
    case actionTypes.FETCH_USER_MFA_FAILURE:
      return {
        ...state,
        phoneUserFactor: undefined,
        isLoadingPhoneUserFactor: false,
      };
    case actionTypes.FETCH_USER_MFA_SUCCESS:
      return {
        ...state,
        phoneUserFactor: action.payload.phoneUserFactor,
        isLoadingPhoneUserFactor: false,
      };

    default:
      return state;
  }
};
