import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { useActivateKomboIntegrationMutation } from '../../../hooks';
import { useRedirectToHRIntegrationSettings } from '../../../hooks/useRedirectToHRIntegrationSettings';
import { type Integration } from '../../../types';

type Props = { integration: Integration; closeModal: () => void };

export const KomboConnectionModal = ({ integration, closeModal }: Props) => {
  const iframeReference = useRef<HTMLIFrameElement>(null);
  const [activateKomboIntegration] = useActivateKomboIntegrationMutation();
  const { t } = useTranslation('global');
  const redirectToHRIntegrationSettings = useRedirectToHRIntegrationSettings();
  // At the moment, we are receiving multiple success messages. This flag will prevent multiple calls to activate the integration as it will be true after the first success message.
  const hasAlreadyActivatedReference = useRef<boolean>(false);

  // Note: this will handle the 'message' event. It contains the code that handles the messages received from the iframe.
  const onWindowMessage = async ({
    source,
    data: { data, type },
  }: MessageEvent) => {
    if (
      (iframeReference.current &&
        source !== iframeReference.current.contentWindow) ||
      hasAlreadyActivatedReference.current
    ) {
      return;
    }

    if (type === 'success') {
      hasAlreadyActivatedReference.current = true;
      await activateKomboIntegration({ token: data.activation_token });
      closeModal();
      redirectToHRIntegrationSettings(integration.id);
    }

    // Note: close modal in case of a failed response
    if (type === 'close') {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener('message', onWindowMessage);

    return () => {
      window.removeEventListener('message', onWindowMessage);
    };
  }, [iframeReference]);
  return (
    <iframe
      ref={iframeReference}
      title={t('integration.connection.title')}
      className="fixed bottom-0 left-0 right-0 top-0 z-10 h-full w-full border-0"
      allow="clipboard-read; clipboard-write"
      src={integration.redirectionUrl}
    />
  );
};
