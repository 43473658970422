import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { type FormValues } from './CostCenterEditForm';

type FormErrors = FormikErrors<FormValues>;

export const validateCostCenterForm = (
  values: FormValues,
  translator: TGlobalFunctionTyped,
): FormErrors => {
  const errors: FormErrors = {};

  // TODO: implement check for same name cost center

  if (values.name?.trim() === '') {
    errors.name = translator('costCenters.form.errors.nameEmpty');
  }

  if (!values.ownerId) {
    errors.ownerId = translator('costCenters.form.errors.ownerEmpty');
  }

  return errors;
};
