import { type MonetaryValue } from 'ezmoney';
import React, { useState } from 'react';

import { WireTransfersSentToPaymentModal } from './WireTransfersSentToPaymentModal';
import { WireTransfersSummaryModal } from './WireTransfersSummaryModal';

enum ControllerModalStep {
  Summary,
  Confirmation,
}

type Props = {
  amount: MonetaryValue;
  count: number;
  showNotEnoughFundsWarning: boolean;
  isSendingToPayment: boolean;
  isOpen: boolean;
  onSendToPayment: (options?: { onSuccess: () => void }) => void;
  onClose: () => void;
  onComplete: () => void;
};

const WireTransferControllerModal = ({
  amount,
  count,
  showNotEnoughFundsWarning,
  isSendingToPayment,
  isOpen,
  onSendToPayment,
  onClose,
  onComplete,
}: Props) => {
  const [currentStep, setCurrentStep] = useState<ControllerModalStep>(
    ControllerModalStep.Summary,
  );

  const handleSendToPayment = async () => {
    await onSendToPayment({
      onSuccess: () => {
        setCurrentStep(ControllerModalStep.Confirmation);
      },
    });
  };

  const handleComplete = () => {
    handleClose();
    onComplete();
  };

  const handleClose = () => {
    onClose();
    setCurrentStep(ControllerModalStep.Summary);
  };

  return (
    <>
      <WireTransfersSummaryModal
        amount={amount}
        count={count}
        isApproving={isSendingToPayment}
        canApprove={false}
        showNotEnoughFundsWarning={showNotEnoughFundsWarning}
        onApprove={handleSendToPayment}
        onClose={handleClose}
        isOpen={isOpen && currentStep === ControllerModalStep.Summary}
      />
      <WireTransfersSentToPaymentModal
        count={count}
        onComplete={handleComplete}
        isOpen={isOpen && currentStep === ControllerModalStep.Confirmation}
      />
    </>
  );
};

export default WireTransferControllerModal;
