import {
  onLCP,
  onFID,
  onCLS,
  onFCP,
  onINP,
  onTTFB,
  type Metric,
} from 'web-vitals';

import { AnalyticEventName, track } from '.';

type WEB_VITAL_PAYLOAD = Omit<Metric, 'navigationType' | 'entries'> & {
  pathname: string;
  company_id?: string;
};

type Params = {
  path: string;
  companyId?: string;
};

function trackMetric(name: AnalyticEventName, metric: Metric, params: Params) {
  const payload: WEB_VITAL_PAYLOAD = {
    name: metric.name,
    delta: metric.delta,
    id: metric.id,
    rating: metric.rating,
    value: metric.value,
    pathname: params.path,
    company_id: params.companyId,
  };

  track(name, payload);
}
export const reportCoreWebVitals = (params: Params) => {
  onCLS((metric) =>
    trackMetric(AnalyticEventName.PERFS_WEB_VITALS_CLS, metric, params),
  );
  onFID((metric) =>
    trackMetric(AnalyticEventName.PERFS_WEB_VITALS_FID, metric, params),
  );
  onLCP((metric) =>
    trackMetric(AnalyticEventName.PERFS_WEB_VITALS_LCP, metric, params),
  );
  onFCP((metric) =>
    trackMetric(AnalyticEventName.PERFS_WEB_VITALS_FCP, metric, params),
  );
  onINP((metric) =>
    trackMetric(AnalyticEventName.PERFS_WEB_VITALS_INP, metric, params),
  );
  onTTFB((metric) =>
    trackMetric(AnalyticEventName.PERFS_WEB_VITALS_TTFB, metric, params),
  );
};
