import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type ExpenseCategory } from '../../../expenseCategory';

type CreationPayload = {
  name: string;
  costCenterIds: string[];
};

export const useCreateEcQuery = (): MutationState<
  CreationPayload,
  ExpenseCategory
> => {
  return useMutation<CreationPayload, ExpenseCategory, ExpenseCategory>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/expense-categories',
    },
    reshapeData: (data) => data,
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['expenseCategories']);
        client.invalidateQueries(['welcomeDashboard']);
      },
    },
  });
};
