import * as actionTypes from './actionTypes';
import { type Notification } from '../notification';

type NotificationsStackState = Notification[];

const notificationsStackInitialState: NotificationsStackState = [];

export const notificationsStackReducer = (
  state: NotificationsStackState = notificationsStackInitialState,
  action: actionTypes.NotificationsActions,
): NotificationsStackState => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION_TO_STACK: {
      const notification = action.payload;

      if (state.some((notif: Notification) => notif.id === notification.id)) {
        return state; // Avoid duplicate messages
      }

      return [...state, notification];
    }

    case actionTypes.REMOVE_NOTIFICATION: {
      const notificationId = action.payload;

      return state.filter((notif: Notification) => notif.id !== notificationId);
    }

    default:
      return state;
  }
};
