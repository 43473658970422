import { SkeletonAvatar, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export function PanelHeaderLoader() {
  return (
    <>
      <div className="mb-xl flex items-center">
        <SkeletonAvatar variant="square" />
        <SkeletonText className="m-0 ml-xs" width="96px" size="l" />
      </div>
      <SkeletonText className="mb-l" width="151px" size="xxl" />
      <SkeletonText className="mb-[2px] block" width="104px" size="xl" />
    </>
  );
}
