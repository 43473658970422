import { gql } from 'graphql-tag';

export const GET_CUSTOM_FIELD_VALUES = gql`
  query GetCustomFieldValues(
    $companyId: ID!
    $customFieldId: ID!
    $customFieldValuesIds: [ID!]
    $search: String
    $after: ID
    $includeArchivedValues: Boolean!
  ) {
    company(id: $companyId) {
      id
      customFields(filter: { ids: [$customFieldId], includeArchived: false }) {
        __typename
        ... on CustomFieldList {
          values(
            first: 100
            after: $after
            filter: {
              ids: $customFieldValuesIds
              includeArchived: $includeArchivedValues
              search: $search
            }
          ) {
            totalCount
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                id
                name: value
              }
            }
          }
        }
      }
    }
  }
`;
