import {
  Button,
  Modal,
  Select,
  FormField,
  SkeletonTag,
  Avatar,
} from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';

import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { useTranslation } from 'common/hooks/useTranslation';

import styles from './PreloginModal.module.css';
import { rejectUnexpectedValue } from '../../../../../../../../utils/switchGuard';
import {
  type NativeAccountingIntegration,
  type SubsidiaryChooserOpenState,
  type SubsidiaryChooserState,
} from '../../../../../../integration/status';
import { getNativeAccountingIntegrationDetails } from '../../../../helper';

interface Props {
  brandName: string;
  chooserState: SubsidiaryChooserState;
  onChooseSubsidiary: (subsidiaryId: string) => Promise<void>;
  integration: NativeAccountingIntegration;
}

export const ChooseSubsidiaryModal = ({
  brandName,
  chooserState,
  onChooseSubsidiary,
  integration,
}: Props) => {
  const [subsidiaryId, setSubsidiaryId] = useState<string | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [manualIsOpen, manualSetIsOpen] = useState<boolean>(true);
  // TODO: hackfix: chooserState isn't originated from a state, so when its value changes, this useState doesn't update.
  // need for change: The modal openness state should be held by AuthSection or whichever container holds all the modals
  // and the chooserState and other logics.
  const isOpen = manualIsOpen && chooserState.kind !== 'closed';

  const [canClose, setCanClose] = useState(false);

  const { t } = useTranslation();

  // Allow closing of modal after 20s of being open
  useEffect(() => {
    const timer = setTimeout(() => setCanClose(true), 20_000);
    return () => clearTimeout(timer);
  });

  const handleChooseSubsidiary = async () => {
    if (!subsidiaryId) {
      throw new Error('Expecting subsidiaryId to have a value once submitting');
    }

    setIsLoading(true);
    await onChooseSubsidiary(subsidiaryId);
    setIsLoading(false);
  };

  const { logoPath, i18nSelectTitle } =
    getNativeAccountingIntegrationDetails(integration);

  return (
    <Modal
      className={styles.chooseSubsidiaryModal}
      illustration={
        <div className={styles.integrationIllustrationContainer}>
          <Avatar
            variant="square"
            className={styles.integrationIllustrationAvatar}
            src={logoPath}
            fallbackSrc={fallbackSupplierLogoSrc}
            text={t(i18nSelectTitle)}
            size="l"
            badgeProps={{
              variant: 'circle',
              text: 'Spendesk',
              src: '/static/img/spendesk_logo.svg',
            }}
          />
        </div>
      }
      illustrationHeight="80"
      isOpen={isOpen}
      title={t('bookkeep.integrations.settings.subsidiaryModal.title', {
        brandName,
      })}
      subtitle={t(
        'bookkeep.integrations.settings.subsidiaryModal.description',
        {
          brandName,
        },
      )}
      actions={[
        <Button
          key="connect"
          text={t('misc.close')}
          variant="alert"
          onClick={() => manualSetIsOpen(false)}
          isDisabled={!canClose}
          data-testid="test_connectCloseButton"
        />,
        <Button
          key="connect"
          text={t('bookkeep.integrations.settings.subsidiaryModal.connect')}
          variant="primary"
          onClick={handleChooseSubsidiary}
          isDisabled={isLoading || !subsidiaryId}
          data-testid="test_connectButton"
        />,
      ]}
    >
      {chooserState.kind !== 'closed' ? (
        <FormContent
          chooserState={chooserState}
          subsidiaryId={subsidiaryId}
          setSubsidiaryId={setSubsidiaryId}
        />
      ) : undefined}
    </Modal>
  );
};

const FormContent = ({
  chooserState,
  subsidiaryId,
  setSubsidiaryId,
}: {
  chooserState: SubsidiaryChooserOpenState;
  subsidiaryId: string | undefined;
  setSubsidiaryId: (newId: string) => void;
}) => {
  const { t } = useTranslation('global');

  switch (chooserState.kind) {
    case 'loading':
      return <SkeletonTag />;
    case 'chooseSubsidiary': {
      const reshapedSubsidiaries = (
        chooserState.kind === 'chooseSubsidiary'
          ? chooserState.subsidiaries
          : []
      ).map((subsidiary) => ({
        key: subsidiary.id,
        label: subsidiary.subsidiaryName,
      }));
      const selectedSubsidiary = reshapedSubsidiaries.find(
        (subsidiary) => subsidiary.key === subsidiaryId,
      );

      return (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.subsidiaryModal.selectLabel',
            )}
          >
            <Select
              fit="parent"
              placeholder={t(
                'bookkeep.integrations.settings.subsidiaryModal.placeholder',
              )}
              options={reshapedSubsidiaries}
              value={selectedSubsidiary}
              onSelect={(subsidiary) => setSubsidiaryId(subsidiary?.key)}
            />
          </FormField>
        </>
      );
    }
    default:
      return rejectUnexpectedValue('chooserState', chooserState);
  }
};
