import React from 'react';

import { DatevAccountFormAdvice } from 'modules/bookkeep/components/DatevAccountFormAdvice/DatevAccountFormAdvice';

import {
  type IntegrationStatus,
  type IntegrationStatusWithIntegration,
} from '../../../integration/status';
import { getAccountingCodesLength } from '../util/datev';

interface Props {
  className: string;
  integrationStatus: IntegrationStatusWithIntegration | IntegrationStatus;
  showError: boolean;
  title?: string;
}

export const SupplierAccountAdvice = ({
  className,
  integrationStatus,
  showError,
  title = undefined,
}: Props) => {
  switch (integrationStatus.integration) {
    case 'Datev': {
      return (
        <DatevAccountFormAdvice
          className={className}
          accountLength={
            getAccountingCodesLength(
              integrationStatus.descriptor.settingsAccountingHeaderFields,
            ).accountsPayableLength
          }
          isAccountsPayable
          isAlert={showError}
          title={title}
        />
      );
    }
    default:
      return null;
  }
};
