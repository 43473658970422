/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
import React from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import { updateUsersPolicy } from 'modules/company/members/redux/actions';
import { fetchPolicies } from "src/core/actions/policies";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import type { AppDispatch } from "src/core/modules/app/redux/store";

import { CreateApprovalPolicyModal } from './CreateApprovalPolicyModal';
import { type ApprovalPolicyFormValues } from '../../formValues';
import { useCreatePolicy } from '../../hooks/useCreatePolicy';
import {
  type ApiMember,
  reshapeToMember,
  reshapeFromSpendingTypes,
} from '../../reshapers';

type Props = {
  members: ApiMember[];
  onSuccess(): void;
  onCancel(): void;
};

export const CreateApprovalPolicyModalContainer = ({
  members,
  onSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { pushNotif } = useNotifications();
  const [createPolicy] = useCreatePolicy();
  const queryClient = useQueryClient();

  const handleCreatePolicy = async (values: ApprovalPolicyFormValues) => {
    try {
      const policy = await createPolicy({
        name: values.name ?? '',
        is_default: false,
        is_user_custom: false,
        params: {
          approval_needed: values.approvalNeeded,
          spending_types: reshapeFromSpendingTypes(values.spendingTypes),
          spending_limit: values.amountPerMonth!,
          transaction_max: values.amountPerTransaction!,
        },
      });
      await dispatch(updateUsersPolicy(policy, values.membersIds));
      dispatch(fetchPolicies());

      queryClient.invalidateQueries(['policies'], { exact: true });
      queryClient.invalidateQueries(['users'], { exact: true });

      pushNotif({
        type: NotificationType.Success,
        message: t('approvalPolicy.errors.createSuccessful'),
      });
      onSuccess();
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('approvalPolicy.errors.cantCreate'),
      });
    }
  };

  return (
    <CreateApprovalPolicyModal
      members={members.map(reshapeToMember)}
      onSuccess={handleCreatePolicy}
      onCancel={onCancel}
    />
  );
};
