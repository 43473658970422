import { fromNumber, type MonetaryValue } from 'ezmoney';
import { type QueryKey, useQueryClient } from 'react-query';

import { type Translations } from 'common/components/QueryError';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type CreditAvailableQueryResponse = {
  availableCredit: { [currency: string]: MonetaryValue | undefined };
};

export type CreditAvailableQueryError = {
  reason: 'missingCurrency' | 'invalidCurrency';
};

type Options = {
  isEnabled?: boolean;
  onSuccess?(data: CreditAvailableQueryResponse): void;
};

type Params = {
  supplierId: string;
  currency?: string;
};

export type CreditAvailableQueryState = QueryState<
  CreditAvailableQueryResponse,
  CreditAvailableQueryError
>;

const getQueryKey = (params?: Params): QueryKey => {
  return [
    'credit_note_requests',
    'available_credit',
    params?.supplierId,
    params?.currency,
  ].filter(Boolean);
};

export const useCreditAvailableQuery = (
  params: Params,
  { isEnabled = true, onSuccess }: Options = {},
): CreditAvailableQueryState =>
  useQuery<CreditAvailableQueryResponse, CreditAvailableQueryError>({
    key: getQueryKey(params),
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/credit_note_requests/available_credit`,
      params,
    },
    options: {
      onSuccess: ({ data }): void => {
        const { currency } = params;
        if (
          currency !== undefined &&
          !Object.hasOwn(data.availableCredit, currency)
        ) {
          data.availableCredit[currency] = fromNumber(0, currency, 0);
        }
        onSuccess?.(data);
      },
    },
  });

export const creditAvailableQueryErrorTranslations: Translations<CreditAvailableQueryError> =
  {
    requestError: ({ reason }) => {
      switch (reason) {
        case 'missingCurrency':
          return 'creditNote.query.creditAvailable.missingCurrency';

        case 'invalidCurrency':
          return 'creditNote.query.creditAvailable.invalidCurrency';

        default:
          return 'creditNote.query.creditAvailable.error';
      }
    },
    serverError: 'creditNote.query.creditAvailable.error',
  };

export const useInvalidateCreditAvailableQuery = () => {
  const queryClient = useQueryClient();

  return async (params?: Params): Promise<void> => {
    await queryClient.invalidateQueries<CreditAvailableQueryResponse>(
      getQueryKey(params),
    );
  };
};
