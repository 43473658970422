import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { AccountCodeFormField } from 'modules/suppliers/components/SupplierBankInfoForm/AccountCodeFormField';
import { AccountHolderNameFormField } from 'modules/suppliers/components/SupplierBankInfoForm/AccountHolderNameFormField';
import { AccountNumberFormField } from 'modules/suppliers/components/SupplierBankInfoForm/AccountNumberFormField';
import { BankCountryFormField } from 'modules/suppliers/components/SupplierBankInfoForm/BankCountryFormField';
import { BicSwiftFormField } from 'modules/suppliers/components/SupplierBankInfoForm/BicSwiftFormField';
import { IbanFormField } from 'modules/suppliers/components/SupplierBankInfoForm/IbanFormField';
import { RoutingNumberFormField } from 'modules/suppliers/components/SupplierBankInfoForm/RoutingNumberFormField';
import { SortCodeFormField } from 'modules/suppliers/components/SupplierBankInfoForm/SortCodeFormField';
import { type BankInfosErrors } from 'modules/suppliers/models';
import { useGetSupplierBankFields } from 'src/core/modules/suppliers/hooks';
import { BankFields } from 'src/core/utils/bankInfoFormats';

import { getShouldUseSuggestion, getIsBankInfoRecommended } from './helpers';
import { type BankInfoOcrResult } from '../supplier';

export type BankInfo = {
  bankCountry: string | null;
  accountHolderName: string | null;
  iban: string | null;
  bic: string | null;
  sortCode: string | null;
  routingNumber: string | null;
  accountNumber: string | null;
  accountCode: string | null;
};

type Props = {
  bankInfoSuggestion: {
    iban: BankInfoOcrResult | null;
    bic: BankInfoOcrResult | null;
  };
  values: BankInfo;
  errors: BankInfosErrors;
  setFieldValue: (field: keyof BankInfo, value: string) => void;
};

export const SupplierFormBankInfoFields = ({
  bankInfoSuggestion,
  values,
  errors,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation('global');

  const getSupplierBankFields = useGetSupplierBankFields();
  const supplierBankFields = getSupplierBankFields({
    bankCountry: values.bankCountry ?? undefined,
    iban: values.iban ?? undefined,
    bic: values.bic ?? undefined,
    sortCode: values.sortCode ?? undefined,
    accountCode: values.accountCode ?? undefined,
    accountNumber: values.accountNumber ?? undefined,
    routingNumber: values.routingNumber ?? undefined,
    accountHolderName: values.accountHolderName ?? undefined,
  });

  const company = useCompany();

  const shouldUseSuggestion = getShouldUseSuggestion({
    companyCountry: company.country,
    bankCountry: values.bankCountry,
    companyCurrency: company.currency,
  });

  const handleBankCountryChange = (bankCountry: string) => {
    setFieldValue('bankCountry', bankCountry);
    setFieldValue(
      'iban',
      shouldUseSuggestion && bankInfoSuggestion.iban
        ? bankInfoSuggestion.iban.text
        : '',
    );
    setFieldValue(
      'bic',
      shouldUseSuggestion && bankInfoSuggestion.bic
        ? bankInfoSuggestion.bic.text
        : '',
    );
    setFieldValue('sortCode', '');
    setFieldValue('accountCode', '');
    setFieldValue('accountNumber', '');
    setFieldValue('routingNumber', '');
  };

  return (
    <>
      <BankCountryFormField
        className="mt-s"
        bankCountry={values.bankCountry ?? undefined}
        error={errors.bankCountry}
        onChange={(bankCountry) => {
          handleBankCountryChange(bankCountry ?? '');
        }}
      />
      {getIsBankInfoRecommended({
        companyCurrency: company.currency,
        bankCountry: values.bankCountry,
      }) && (
        <Callout
          variant="info"
          className="mt-xs"
          title={t('submitMyInvoice.supplier.supplierBankInfoRecommendation')}
        />
      )}

      <AccountHolderNameFormField
        className="mt-s"
        isOptional={
          supplierBankFields[BankFields.AccountHolderName]?.isOptional ?? false
        }
        accountHolderName={values.accountHolderName ?? ''}
        error={errors.accountHolderName}
        onChange={(accountHolderName) => {
          setFieldValue('accountHolderName', accountHolderName ?? '');
        }}
      />

      {supplierBankFields[BankFields.AccountNumber] && (
        <AccountNumberFormField
          isOptional={
            supplierBankFields[BankFields.AccountNumber]?.isOptional ?? false
          }
          className="mt-s"
          accountNumber={values.accountNumber ?? ''}
          error={errors.accountNumber}
          onChange={(accountNumber) =>
            setFieldValue('accountNumber', accountNumber ?? '')
          }
        />
      )}

      {supplierBankFields[BankFields.AccountCode] && (
        <AccountCodeFormField
          isOptional={
            supplierBankFields[BankFields.AccountCode]?.isOptional ?? false
          }
          className="mt-s"
          accountCode={values.accountCode ?? ''}
          error={errors.accountCode}
          onChange={(accountCode) =>
            setFieldValue('accountCode', accountCode ?? '')
          }
        />
      )}

      {supplierBankFields[BankFields.Iban] && (
        <IbanFormField
          isOptional={supplierBankFields[BankFields.Iban]?.isOptional ?? false}
          className="mt-s"
          bankCountry={values.bankCountry ?? ''}
          iban={values.iban ?? ''}
          ibanSuggestion={
            shouldUseSuggestion && bankInfoSuggestion.iban
              ? bankInfoSuggestion.iban.text
              : undefined
          }
          error={errors.iban}
          onChange={(iban) => setFieldValue('iban', iban ?? '')}
        />
      )}

      {supplierBankFields[BankFields.BicSwift] && (
        <BicSwiftFormField
          isOptional={
            supplierBankFields[BankFields.BicSwift]?.isOptional ?? false
          }
          className="mt-s"
          bic={values.bic ?? ''}
          bicSuggestion={
            shouldUseSuggestion && bankInfoSuggestion.bic
              ? bankInfoSuggestion.bic.text
              : undefined
          }
          error={errors.bic}
          onChange={(bic) => setFieldValue('bic', bic ?? '')}
        />
      )}

      {supplierBankFields[BankFields.SortCode] && (
        <SortCodeFormField
          isOptional={
            supplierBankFields[BankFields.SortCode]?.isOptional ?? false
          }
          className="mt-s"
          sortCode={values.sortCode ?? ''}
          error={errors.sortCode}
          onChange={(sortCode) => setFieldValue('sortCode', sortCode ?? '')}
        />
      )}

      {supplierBankFields[BankFields.RoutingNumber] && (
        <RoutingNumberFormField
          isOptional={
            supplierBankFields[BankFields.RoutingNumber]?.isOptional ?? false
          }
          className="mt-xs"
          routingNumber={values.routingNumber ?? ''}
          error={errors.routingNumber}
          onChange={(routingNumber) =>
            setFieldValue('routingNumber', routingNumber ?? '')
          }
        />
      )}
    </>
  );
};
