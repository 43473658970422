import { getFileTypeFromMime } from 'src/core/utils/files';

export const FILE_SIZES_CONFIGURATION = {
  pdf: { maxSizeInBytes: 10_485_760 },
  image: {
    maxSizeInBytes: 10_485_760,
  },
  other: {
    maxSizeInBytes: 10_485_760,
  },
};

export const validateFileSizes = (
  files: File[] = [],
  sizesConfig: Record<string, { maxSizeInBytes: number }>,
):
  | { status: 'ok' }
  | {
      status: 'tooLarge';
      fileName: string;
      maxSizeInMegaBytes: number;
    } => {
  for (const file of files) {
    const mimeType = getFileTypeFromMime(file.type)?.toLowerCase();
    // Files with non accepted mimetype should not be size validated
    if (!mimeType) {
      continue;
    }

    const maxSizeInBytes =
      sizesConfig[mimeType]?.maxSizeInBytes ?? sizesConfig.other.maxSizeInBytes;
    if (file.size > maxSizeInBytes) {
      return {
        status: 'tooLarge',
        fileName: file.name,
        maxSizeInMegaBytes: maxSizeInBytes / 1_000_000,
      };
    }
  }
  return { status: 'ok' };
};
