import { useSeatLimit } from 'modules/members/hooks/useSeatLimit';
/**
 * Number of seats available in the company. Used only for companies on Starter and Freemium plan, which have a feature toggle.
 * Returns 0 if for any reason the result is negative.
 */
export const useRemainingSeats = (
  membersCount: number | undefined,
): number | undefined => {
  const seatLimit = useSeatLimit();

  if (membersCount === undefined || seatLimit === undefined) {
    return undefined;
  }
  return Math.max(0, seatLimit - membersCount);
};
