import { type LocationDescriptorObject } from 'history';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { getSelectedId } from 'modules/company/redux/selectors';
import { routeFor, routes } from 'src/core/constants/routes';

interface RedirectTo extends Omit<LocationDescriptorObject, 'search'> {
  search?: URLSearchParams;
}

interface Props {
  path: string | string[];
  isAccessAllowed: boolean;
  redirectTo?: RedirectTo;
  preserveSearchParamsOnRedirect?: boolean;
  children: React.ReactNode;
}

const combineSearchParams = (
  searchParamsA: URLSearchParams,
  searchParamsB: URLSearchParams,
): URLSearchParams => {
  for (const [key, value] of searchParamsB.entries()) {
    searchParamsA.set(key, value);
  }

  return searchParamsA;
};

export const ProtectedRoute = ({
  redirectTo,
  isAccessAllowed,
  preserveSearchParamsOnRedirect = true,
  children,
  ...rest
}: Props) => {
  const companyId = useSelector(getSelectedId);
  const location = useLocation();
  const finalRedirectTo = redirectTo
    ? {
        ...redirectTo,
        search:
          preserveSearchParamsOnRedirect && redirectTo.search && location.search
            ? combineSearchParams(
                new URLSearchParams(location.search),
                redirectTo.search,
              ).toString()
            : redirectTo.search?.toString(),
      }
    : {
        pathname: routeFor(routes.NOT_FOUND.path, {
          company: companyId,
        }),
      };

  return (
    <Route
      {...rest}
      render={() =>
        isAccessAllowed ? children : <Redirect to={finalRedirectTo} />
      }
    />
  );
};
