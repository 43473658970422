import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { getRequestsLink } from 'modules/app/layout/components/Header/helpers';
import { NavigationLink } from 'src/core/common/components/NavigationLink';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes } from 'src/core/constants/routes';

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_controller: boolean;
    is_requester: boolean;
    is_approver: boolean;
    is_account_owner: boolean;
  };
  className?: string;
  isInDropdown?: boolean;
  onClick?: () => void;
};

export const RequestsLink = ({
  company,
  user,
  className,
  isInDropdown,
  onClick,
}: Props) => {
  const { t } = useTranslation('global');
  const { pathname: currentPath } = useLocation();

  return (
    <NavigationLink
      data-marketing="top-menu_requests"
      key="nav_requests"
      className={className}
      onClick={onClick}
      path={getRequestsLink(user, company.id)}
      text={t('misc.request_plural')}
      isActive={Boolean(
        matchPath(currentPath, {
          path: [routes.REQUESTS.path],
        }),
      )}
      isInDropdown={isInDropdown}
    />
  );
};
