import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

type Payload = {
  user_ids: string[];
};

export const useUpdateUsersPolicy = (id: string): MutationState<Payload> => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: `/userpolicies/${id}`,
    },
    options: {
      throwOnError: true,
    },
  });
};
