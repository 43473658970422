import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const AccountOwnerSpendingPolicy = () => {
  const { t } = useTranslation('global');

  return (
    <div>{t('control.approvalPolicyWidget.spendingLimitAccountOwner')}</div>
  );
};
