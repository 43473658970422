import { connect } from 'react-redux';

import { getCountry } from 'modules/company/redux/selectors';
import { type AppState } from 'src/core/reducers';
import { getUserLang } from 'src/core/selectors/users';

import { PerDiemDetails } from '../components/PerDiemDetails';
import { getAllowanceLocations } from '../redux/selectors';
import { fetchAllowanceLocations } from '../redux/thunks';

const mapStateToProps = (state: AppState) => ({
  userLang: getUserLang(state),
  allowanceLocations: getAllowanceLocations(state),
  companyCountry: getCountry(state),
});

const mapDispatchToProps = {
  onFetchAllowanceLocations: fetchAllowanceLocations,
};

export const PerDiemDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PerDiemDetails);
