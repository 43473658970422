import { useSelector } from 'react-redux';

import { getSelf as getCurrentUser } from 'src/core/selectors/users';
import { getEligibleCustomFields } from 'src/core/utils/custom-fields';

import type { CustomFieldEligibleType } from '../../models/customFieldAssociation';
import {
  type CustomFieldDefinition,
  type CustomFieldId,
  isCustomFieldRequired,
} from '../../models/customFieldDefinition';
import type { TeamId } from '../../models/team';

export const getRequiredCustomFields = (
  customFields: CustomFieldDefinition[],
  user: { is_requester?: boolean; is_controller?: boolean; is_admin?: boolean },
  teamIds: TeamId[],
  eligibleTypes: CustomFieldEligibleType[],
): CustomFieldId[] => {
  const eligibleCustomFields: CustomFieldDefinition[] = getEligibleCustomFields(
    customFields,
    { types: eligibleTypes, teamIds, user },
  );

  return eligibleCustomFields.reduce(
    (
      requiredCustomFields: CustomFieldId[],
      customField: CustomFieldDefinition,
    ) => {
      if (isCustomFieldRequired(customField)) {
        requiredCustomFields.push(customField.id);
      }
      return requiredCustomFields;
    },
    [],
  );
};

export const useRequiredCustomFields = () => {
  const user = useSelector(getCurrentUser);

  return (
    customFields: CustomFieldDefinition[],
    teamIds: TeamId[],
    eligibleTypes: CustomFieldEligibleType[],
  ): CustomFieldId[] =>
    getRequiredCustomFields(customFields, user, teamIds, eligibleTypes);
};
