import type {
  CreditNote,
  CreditNoteId,
} from '@finance-review/models/credit-note';
import type { QueryClient } from 'src/core/api/client';
import { useQuery } from 'src/core/api/hooks/useQuery';

import type { RawCreditNote } from './transformers';
import { transformRawCreditNote } from './transformers';

export const useCreditNoteRequestQuery = (
  creditNoteRequestId: CreditNoteId | undefined,
) => {
  return useQuery<CreditNote, RawCreditNote>({
    key: ['credit_notes_requests', creditNoteRequestId],
    isEnabled: Boolean(creditNoteRequestId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/credit_note_requests/${creditNoteRequestId}`,
    },
    reshapeData: transformRawCreditNote,
  });
};

export const updateCreditNoteRequestQueryData = (
  queryClient: QueryClient,
  queryData: RawCreditNote,
): void => {
  queryClient.setQueryData(['credit_notes_requests', queryData.id], queryData);
};
