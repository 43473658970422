import { z } from 'zod';

export const komboIntegrations = [
  'alexishr',
  'bamboohr',
  'breathehr',
  'cezannehr',
  'charliehr',
  'deel',
  'eurecia',
  'factorial',
  'googleworkspace',
  'hibob',
  'humaans',
  'kenjo',
  'lucca',
  'entraid', // Microsoft Entra ID (Azure AD)
  'officient',
  'okta',
  'payfitpartner',
  'personio',
  'remotecom',
  'sagehr',
  'successfactors',
  'workday',
] as const;
const komboIntegrationsSchema = z.enum(komboIntegrations);
export type KomboIntegration = z.infer<typeof komboIntegrationsSchema>;

const integrationIdSchema = z.enum([
  ...komboIntegrations,
  'travelperk',
  'spendesk-oauth2',
]);
export type IntegrationId = z.infer<typeof integrationIdSchema>;

const integrationErrorSchema = z.enum([
  'wrongPlan',
  'notConnected',
  'pendingSync',
  'pendingAction',
  'hrIntegrationAlreadyConnected',
  'unknown',
]);
export type IntegrationError = z.infer<typeof integrationErrorSchema>;

export const integrationSchema = z.object({
  id: integrationIdSchema,
  redirectionUrl: z.string().url(),
  error: integrationErrorSchema.optional(),
});
export type Integration = z.infer<typeof integrationSchema>;
