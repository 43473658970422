import * as Money from 'ezmoney';

import { vatFromAmountAndRate } from 'src/core/utils/money';

type BaseTaxAccount = {
  id: string;
  name: string;
  isArchived: boolean;
};

export type VatAccount = BaseTaxAccount & {
  rate: number;
  code: string;
  isDefault: boolean;
  __typename: 'VatAccount';
};

type ReverseChargeAccount = BaseTaxAccount & {
  __typename: 'ReverseChargeAccount';
};

export type TaxAccount = VatAccount | ReverseChargeAccount;

type VatItem = {
  itemLineId: number;
  accountId: string | null;
  amount: Money.MonetaryValue | null;
  rate: number | null;
};

export const isReverseChargeAccount = (
  taxAccount: TaxAccount,
): taxAccount is ReverseChargeAccount =>
  taxAccount.__typename === 'ReverseChargeAccount';

export const isExemptTaxAccount = (taxAccount: TaxAccount): boolean =>
  !isReverseChargeAccount(taxAccount) && taxAccount.rate === 0;

export const getVatAccount = (
  vatAccounts: TaxAccount[],
  vatItem: VatItem,
): TaxAccount | undefined =>
  vatAccounts.find((vatAccount) => vatAccount.id === vatItem.accountId);

export const computeVatAmountFromGrossAmount = (
  amount: Money.MonetaryValue,
  rate: number,
): Money.MonetaryValue => {
  const vatAmount: number = vatFromAmountAndRate(
    Money.toNumber(amount),
    rate,
    '',
    '',
  );

  return Money.fromNumber(vatAmount, amount.currency, 2);
};

export const computeVatAmountFromNetAmount = (
  amount: Money.MonetaryValue,
  rate: number,
): Money.MonetaryValue => {
  return Money.fromNumber(
    Money.toNumber(amount) * (rate / 100),
    amount.currency,
    2,
  );
};
