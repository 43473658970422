import React, { useEffect, useState } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { CreateNewTeamModal } from './Teams/CreateNewTeamModal';
import { EditTeamNameModal } from './Teams/EditTeamNameModal';
import { TeamApprovalRulesModal } from './Teams/TeamApprovalRulesModal';
import { TeamDeleteModal } from './Teams/TeamDeleteModal';
import { TeamDetailsModal } from './Teams/TeamDetailsModal';
import { type ApprovalScheme, TeamList } from './Teams/TeamList';
import { TeamManagerModal } from './Teams/TeamManagerModal';
import { TeamMembersModal } from './Teams/TeamMembersModal';
import { type TeamRaw, type TeamUserRaw } from '../../../members/models/teams';
import { type ApprovalRule } from '../../structure/approval-flows';

export type TeamModal =
  | 'createTeam'
  | 'approvers'
  | 'teamMembers'
  | 'teamDetails'
  | 'teamManager'
  | 'editName'
  | 'teamDelete';

export type TeamsTabProps = {
  users: TeamUserRaw[];
  groups: TeamRaw[];
  approvalSchemes: ApprovalScheme[];

  // actions
  fetchGroup: (groupId: string) => Promise<TeamRaw>;
  createGroup: (name: string, userIds: string[]) => Promise<TeamRaw>;
  updateGroup: (group: TeamRaw) => Promise<void>;
  patchGroup: (payload: { id: string; admin_ids: string[] }) => Promise<void>;
  removeGroup: (groupId: string) => Promise<void>;
  addUsersToGroup: (payload: {
    user_ids: string[];
    group_id: string;
  }) => Promise<void>;
  removeUsersFromGroup: (payload: {
    user_ids: string[];
    group_id: string;
  }) => Promise<void>;
  fetchApprovalScheme: (groupId: string) => Promise<void>;
  saveApprovalScheme: (
    teamId: string,
    rules: ApprovalRule[],
    successMessage: string,
    errorMessage: string,
  ) => Promise<void>;
};

type Props = {
  openModal: TeamModal | null;
  setOpenModal: (modal: TeamModal | null) => void;
} & TeamsTabProps;

export const TeamsTab = ({
  users,
  groups,
  approvalSchemes,
  openModal,

  // actions
  fetchGroup,
  createGroup,
  updateGroup,
  patchGroup,
  removeGroup,
  addUsersToGroup,
  removeUsersFromGroup,
  fetchApprovalScheme,
  saveApprovalScheme,
  setOpenModal,
}: Props) => {
  const { t } = useTranslation('global');

  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );

  const [selectedGroup, setSelectedGroup] = useState<TeamRaw | undefined>();
  const [isTeamCreation, setIsTeamCreation] = useState(false);

  useEffect(() => {
    if (selectedGroup) {
      if (isTeamCreation) {
        if (openModal === 'createTeam') {
          setOpenModal('teamMembers');
        } else if (
          !isCostCentersFeatureEnabled &&
          openModal === 'teamMembers'
        ) {
          setOpenModal('approvers');
        } else {
          setIsTeamCreation(false);
          closeModals();
        }
      } else {
        setOpenModal('teamDetails');
      }
    }
  }, [selectedGroup]);

  const closeModals = () => {
    setOpenModal(null);
  };

  const onEditGroup = async (groupId: string) => {
    const [newGroup] = await Promise.all([
      fetchGroup(groupId),
      fetchApprovalScheme(groupId),
    ]);

    setSelectedGroup(newGroup);
  };

  const renderTeamNameModal = () => {
    if (openModal !== 'editName' || !selectedGroup) {
      return null;
    }

    return (
      <EditTeamNameModal
        group={selectedGroup}
        groups={groups}
        onEditName={async (name: string) => {
          await updateGroup({ ...selectedGroup, name });
          await onEditGroup(selectedGroup.id);
        }}
        onCancel={() => {
          setOpenModal('teamDetails');
        }}
      />
    );
  };

  const renderTeamManagerModal = () => {
    if (openModal !== 'teamManager' || !selectedGroup) {
      return;
    }

    return (
      <TeamManagerModal
        group={selectedGroup}
        users={users}
        onSave={async (adminIds) => {
          await patchGroup({
            id: selectedGroup.id,
            admin_ids: adminIds,
          });
          await onEditGroup(selectedGroup.id);
        }}
        onCancel={() => {
          setOpenModal('teamDetails');
        }}
      />
    );
  };

  const renderTeamApproversModal = () => {
    if (openModal !== 'approvers' || !selectedGroup) {
      return;
    }

    return (
      <TeamApprovalRulesModal
        onSave={async (
          teamId: string,
          rules: ApprovalRule[],
          successMessage: string,
          errorMessage: string,
        ) => {
          await saveApprovalScheme(teamId, rules, successMessage, errorMessage);
          await onEditGroup(teamId);
        }}
        onCancel={() => {
          setOpenModal('teamDetails');
        }}
        selectedGroupId={selectedGroup.id}
      />
    );
  };

  const renderTeamDetailsModal = () => {
    if (openModal !== 'teamDetails' || !selectedGroup) {
      return;
    }

    const scheme = approvalSchemes.find((approvalScheme) => {
      return approvalScheme.teamId === selectedGroup?.id;
    });

    return (
      <TeamDetailsModal
        users={users}
        group={selectedGroup}
        approvalScheme={scheme}
        onEditName={() => {
          setOpenModal('editName');
        }}
        onManageApprovers={() => {
          setOpenModal('approvers');
        }}
        onSelectManager={() => {
          setOpenModal('teamManager');
        }}
        onDelete={() => {
          setOpenModal('teamDelete');
        }}
        teamInvite={() => {
          setOpenModal('teamMembers');
        }}
        onCancel={closeModals}
        fetchApprovalScheme={fetchApprovalScheme}
      />
    );
  };

  const renderTeamMembersModal = () => {
    if (openModal !== 'teamMembers' || !selectedGroup) {
      return;
    }

    const title = isTeamCreation
      ? t('members.teamInviteMembers', { teamName: selectedGroup.name })
      : t('members.teamManageMembers', { teamName: selectedGroup.name });

    return (
      <TeamMembersModal
        title={title}
        users={users}
        group={selectedGroup}
        onSelectMembers={async ({ membersToAdd, membersToRemove, group }) => {
          const addUsers = addUsersToGroup({
            user_ids: membersToAdd.map((m) => m.id),
            group_id: group.id,
          });

          const removeUsers = removeUsersFromGroup({
            user_ids: membersToRemove.map((m) => m.id),
            group_id: group.id,
          });

          await Promise.all([addUsers, removeUsers]);
          await onEditGroup(group.id);
        }}
        onCancel={() => {
          setOpenModal('teamDetails');
        }}
      />
    );
  };

  const renderTeamDeleteModal = () => {
    if (openModal !== 'teamDelete' || !selectedGroup) {
      return;
    }

    return (
      <TeamDeleteModal
        group={selectedGroup}
        users={users}
        onDelete={async (groupId: string) => {
          await removeGroup(groupId);
          closeModals();
        }}
        onCancel={() => {
          setOpenModal('teamDelete');
        }}
      />
    );
  };

  const renderNewTeamModal = () => {
    if (openModal !== 'createTeam') {
      return;
    }

    return (
      <CreateNewTeamModal
        users={users}
        onCreate={async (name, userIds) => {
          setIsTeamCreation(true);
          const group = await createGroup(name, userIds);
          fetchApprovalScheme(group.id);
          setSelectedGroup(group);
        }}
        onCancel={closeModals}
        teams={groups}
      />
    );
  };

  return (
    <div>
      {renderTeamDetailsModal()}
      {renderNewTeamModal()}
      {renderTeamMembersModal()}
      {renderTeamDeleteModal()}
      {renderTeamNameModal()}
      {renderTeamManagerModal()}
      {renderTeamApproversModal()}
      <TeamList
        teams={groups}
        approvalSchemes={approvalSchemes}
        users={users}
        onEditGroup={onEditGroup}
      />
    </div>
  );
};
