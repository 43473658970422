import { type IllustrationName } from 'common/components/Illustration/Illustration';

import { type TGlobalFunctionTyped } from '../../hooks/useTranslation';

class UnknownMode extends Error {
  constructor(mode: never) {
    super(`Unknown mode ${mode}`);
  }
}

export type Mode =
  | 'payment'
  | 'request'
  | 'draftRequest'
  | 'mineRequest'
  | 'toApproveRequest';

export function modeToTitle(
  mode: Mode,
  hasFiltersApplied: boolean = false,
  translator: TGlobalFunctionTyped,
): string {
  switch (mode) {
    case 'request':
      return hasFiltersApplied
        ? translator('pageEmptyState.requests.titleFiltersApplied')
        : translator('pageEmptyState.requests.title');
    case 'mineRequest':
      return hasFiltersApplied
        ? translator('pageEmptyState.requests.titleFiltersApplied')
        : translator('pageEmptyState.yourRequests.title');
    case 'toApproveRequest':
      return hasFiltersApplied
        ? translator('pageEmptyState.requests.titleFiltersApplied')
        : translator('pageEmptyState.toApproveRequests.title');
    case 'draftRequest':
      return hasFiltersApplied
        ? translator('pageEmptyState.requests.titleFiltersApplied')
        : translator('pageEmptyState.draftRequests.title');
    case 'payment':
      return hasFiltersApplied
        ? translator('pageEmptyState.payments.titleFiltersApplied')
        : translator('pageEmptyState.payments.title');
    default:
      throw new UnknownMode(mode);
  }
}

export function modeToSubtitle(
  mode: Mode,
  isUserOnlyRequester: boolean,
  translator: TGlobalFunctionTyped,
): string {
  switch (mode) {
    case 'request': {
      if (isUserOnlyRequester) {
        return translator('pageEmptyState.requests.subtitleOnlyRequester');
      }
      return translator('pageEmptyState.requests.subtitle');
    }
    case 'mineRequest':
      return translator('pageEmptyState.yourRequests.subtitle');
    case 'toApproveRequest':
      return translator('pageEmptyState.toApproveRequests.subtitle');
    case 'draftRequest': {
      return translator('pageEmptyState.draftRequests.subtitle');
    }
    case 'payment': {
      if (isUserOnlyRequester) {
        return translator('pageEmptyState.payments.subtitleOnlyRequester');
      }
      return translator('pageEmptyState.payments.subtitle');
    }
    default:
      throw new UnknownMode(mode);
  }
}

export function modeToIllustration(mode: Mode): IllustrationName {
  switch (mode) {
    case 'request':
    case 'mineRequest':
    case 'toApproveRequest':
    case 'draftRequest':
      return 'request';
    case 'payment':
      return 'payment';
    default:
      throw new UnknownMode(mode);
  }
}
