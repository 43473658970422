import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { type IntegrationStatus } from '../../../integration/status';

type RawResponse = {
  url: string;
};

type Response = {
  url: string;
};

type ErrorResponse = {
  rag: string;
  reason: string;
  rawError: unknown;
};

export const useGetOauth1ConsentUrlMutation = (): MutationState<
  {
    accountId: string;
    consumerId: string;
    consumerSecret: string;
  },
  Response,
  unknown
> => {
  return useMutation<
    {
      accountId: string;
      consumerId: string;
      consumerSecret: string;
    },
    RawResponse,
    Response,
    ErrorResponse
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/oauth1/build-consent-url',
      method: 'post',
    },
    reshapeData(data) {
      return data;
    },
    options: {
      throwOnError: false,
      onSuccess({ client }): void {
        client.invalidateQueries(['useIntegrationStatusQuery']);
      },
      onError({ client }) {
        client.cancelQueries('useIntegrationStatusQuery');

        const currentStatus = client.getQueryData<IntegrationStatus>(
          'useIntegrationStatusQuery',
        );

        if (
          !currentStatus ||
          currentStatus.integration === 'noIntegration' ||
          currentStatus.integration === 'switchInProgress'
        ) {
          return;
        }

        if (currentStatus.authorization.kind !== 'oauth1') {
          return;
        }

        client.setQueryData<IntegrationStatus>('useIntegrationStatusQuery', {
          ...currentStatus,
          authorization: {
            ...currentStatus.authorization,
            status: {
              status: 'connectionError',
              error: '',
            },
          },
        });
      },
    },
  });
};
