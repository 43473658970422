export type EmployeeAccount = {
  id?: string;
  user?: User;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  // name?: string | undefined;
  // Only used on legacy:
  countPreparedExpenses?: number;
  // Only used for Foundations:
  isArchived?: boolean;
  isDefault?: false;
  isDirty?: boolean;
};

export type EmployeeAccountCode = {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  isDefault?: boolean;
  isArchived?: boolean;
};

export type DefaultEmployeeAccount = {
  id?: string;
  name: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  // Only used for Foundations:
  isArchived: boolean;
  isDefault?: true;

  // the `code` should not be here, and not be used, but currently the REST API is still returning it
  // TODO: delete this after deleting it from the REST API
  code?: string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string | null;
  gender?: 'M' | 'F';
};

export const getDefaultEmployeeAccountName = (
  accountingCountry: string,
): string => {
  switch (accountingCountry) {
    case 'FR':
      return 'Compte par défaut';
    case 'DE':
      return 'Sammelkonto (Mitarbeiter)';
    default:
      return 'Default account';
  }
};
