import { useSupplierSyncStatus } from 'modules/suppliers/hooks';

import { useHasAccountingIntegrationCapability } from '../../accounting-integration/apis';
import { type PayableSubType } from '../prepare-payables/models';
import { useGetDefaultVendorsQuery } from '../settings/integrations/hooks/useGetDefaultVendorsQuery';

export type SyncStatus =
  | {
      synced: true;
      anchor: 'simple';
      url: string | null;
    }
  | {
      synced: false;
      anchor: 'simple';
    }
  | {
      synced: true;
      anchor: 'defaultVendor';
      defaultVendorName: string;
      url: string | null;
    }
  | {
      synced: false;
      anchor: 'defaultVendor';
    }
  | undefined;

export const useGetSupplierSyncWithDefault = (
  supplierId: string | undefined,
  payableType?: PayableSubType,
): SyncStatus => {
  const hasSupplierSyncCapability =
    useHasAccountingIntegrationCapability('supplierSync');
  const defaultVendors = useGetDefaultVendorsQuery();
  const supplierSyncStatus = useSupplierSyncStatus(supplierId);

  if (!hasSupplierSyncCapability) {
    return;
  }

  let defaultVendorType;
  switch (payableType) {
    case 'plasticCard':
      defaultVendorType = 'physical' as const;
      break;

    case 'subscriptionCard':
      defaultVendorType = 'subscription' as const;
      break;

    case 'singleUseCard':
      defaultVendorType = 'singlePurchase' as const;
      break;

    default:
      break;
  }

  const defaultVendor =
    defaultVendorType &&
    defaultVendors.status === 'success' &&
    defaultVendors.data[defaultVendorType];

  if (defaultVendor) {
    if (defaultVendor.status === 'synced') {
      return {
        synced: true,
        anchor: 'defaultVendor',
        defaultVendorName: defaultVendor.name,
        url: defaultVendor.url,
      };
    }

    return {
      synced: false,
      anchor: 'defaultVendor',
    };
  }

  if (
    defaultVendors.status === 'success' &&
    supplierSyncStatus.status === 'success'
  ) {
    return { anchor: 'simple', ...supplierSyncStatus.data };
  }
};
