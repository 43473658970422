import { gql } from 'graphql-tag';

export const GET_REVERSE_CHARGE_TAX_ACCOUNT_RATES = gql`
  query GetReverseChargeTaxAccountRates($companyId: ID!) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        reverseChargeTaxAccountRates {
          rate
          validFrom
        }
      }
    }
  }
`;
