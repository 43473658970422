import { validDateSchema } from '@dev-spendesk/general-type-helpers/Date';
import { z } from 'zod';

export const apiKeyListItemSchema = z.object({
  apiId: z.string(),
  name: z.string(),
  description: z.string(),
  createdAt: validDateSchema,
  updatedAt: validDateSchema,
  active: z.boolean(),
  expiredAt: validDateSchema,
  scope: z.string(),
  creatorId: z.string(),
  lastUpdaterId: z.string(),
});

export type ApiKeyListItem = z.infer<typeof apiKeyListItemSchema>;
