import { HighlightIcon } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { type ControlRule } from '../../controlRule';
import { type PaymentToComplete } from '../../paymentToComplete';
import { OverdueSection } from '../OverdueSection';
import { PaymentsList } from '../PaymentsList';
import { PaymentsListEmptyState } from '../PaymentsListEmptyState';

type Props = {
  context: {
    companyId: string;
    userHasMadePayments: boolean;
  };
  payments: PaymentToComplete[];
  controlRule: ControlRule;
  overduePaymentsCount: number;
};

export const PaymentsListSection = ({
  context,
  payments,
  controlRule,
  overduePaymentsCount,
}: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();

  const handleNewRequestClick = () => {
    track(AnalyticEventName.REQUEST_NEW_BUTTON_CLICKED, { from: 'filter_bar' });
    const requestRoute = routeFor(routes.REQUESTS.path, {
      company: context.companyId,
      id: 'new',
      type: 'all',
    });
    history.push(requestRoute);
  };

  return (
    <>
      {payments.length > 0 && ( // we don't need header if there are no payments
        <div className="mb-m flex items-center gap-s">
          <HighlightIcon size="m" variant="info" name="eye" />
          <div>
            <div className="leading-[16px] text-complementary title-l">
              {t('homepage.paymentsList.title', {
                count: payments.length,
              })}
            </div>
            <div className="leading-[17px] text-neutral-dark body-m">
              {t('homepage.paymentsList.subTitle')}
            </div>
          </div>
        </div>
      )}
      {overduePaymentsCount > 0 && (
        <div className="mb-s">
          <OverdueSection
            incompletePaymentsLimit={controlRule.incompletePaymentsLimit}
            overduePaymentsCount={overduePaymentsCount}
          />
        </div>
      )}
      {payments.length === 0 ? (
        <PaymentsListEmptyState
          userHasMadePayments={context.userHasMadePayments}
          onButtonClick={handleNewRequestClick}
        />
      ) : (
        <PaymentsList
          payments={payments.map((payment) => ({
            ...payment,
            routeLink: routeFor(routes.PAYMENTS_ALL.path, {
              company: context.companyId,
              id: payment.id,
            }),
          }))}
        />
      )}
    </>
  );
};
