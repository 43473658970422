import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useParams } from 'src/core/common/hooks/useParams';
import { routes } from 'src/core/constants/routes';

import { type Vendor, type VendorStatus } from '../../accounting/vendor';

export const useGetVendorsQuery = (
  search: string | undefined,
  limit: number | undefined = undefined,
  status: VendorStatus | undefined = undefined,
  supplierIds: string[] | undefined = undefined,
): QueryState<Vendor[]> => {
  const { company: companyId } = useParams(
    routes.COMPANY_ACCOUNTING_INTEGRATION.path,
  );

  return useQuery<Vendor[]>({
    key: ['useGetVendorsQuery', companyId, search, limit, status, supplierIds],
    isEnabled: !search || search.length >= 2,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/supplier-sync',
      params: {
        first: limit ?? 100,
        ...(search ? { search } : undefined),
        ...(status ? { status } : undefined),
        ...(supplierIds ? { supplierIds } : undefined),
      },
    },
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
  });
};
