import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useUserScaDeviceUnenrollMutation } from 'src/core/common/hooks/useUserScaDeviceUnenroll';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';
import { useIsCompanyOnSfsUk } from 'src/core/modules/company/hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const WireTransfersDeclinedModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const [unenrollScaDevice] = useUserScaDeviceUnenrollMutation();
  const { pushNotif } = useNotifications();
  const isUkCompany = useIsCompanyOnSfsUk();

  const ukActions = [
    <Button
      key="cancel"
      text={t('wiretransfer.modal.errors.transfersDeclined.cancel')}
      variant="secondary"
      onClick={onClose}
    />,
    <Button
      key="refreshSca"
      text={t('wiretransfer.modal.errors.transfersDeclined.refresh')}
      variant="primary"
      onClick={async () => {
        try {
          await unenrollScaDevice({ data: { adyen: { kind: 'reset' } } });
          pushNotif({
            type: NotificationType.Success,
            message: t(
              'wiretransfer.modal.errors.transfersDeclined.refreshSuccessToast',
            ),
          });
          onClose();
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('misc.errors.unknownError'),
          });
        }
      }}
    />,
  ];

  return (
    <Modal
      isOpen={isOpen}
      iconName="failure"
      iconVariant="alert"
      title={t('wiretransfer.modal.errors.transfersDeclined.title')}
      subtitle={
        isUkCompany
          ? t('wiretransfer.modal.errors.transfersDeclined.desc_sfsUk')
          : t('wiretransfer.modal.errors.transfersDeclined.desc')
      }
      actions={
        isUkCompany
          ? ukActions
          : [
              <Button
                key="continue"
                text={t('wiretransfer.modal.errors.transfersDeclined.continue')}
                variant="primary"
                onClick={onClose}
              />,
            ]
      }
    />
  );
};
