import { SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const BudgetExpenseCategoriesBreakdownListLoader = () => {
  return (
    <SkeletonTable
      columns={[
        {
          cell: <SkeletonText width="80%" />,
          width: '25%',
        },
        {
          cell: <SkeletonText width="80%" />,
          width: '30%',
        },
        {
          cell: <SkeletonText width="80%" />,
          width: '35%',
        },
        {
          cell: (
            <div className="flex w-full justify-end">
              <SkeletonText width="80%" />
            </div>
          ),
          width: '10%',
        },
      ]}
    />
  );
};
