import { type IntegrationStatus } from 'modules/bookkeep/integration/status';
import { type DefaultSupplierAccount } from 'modules/bookkeep/settings/accounting/supplierAccount';
import { type QueryState } from 'src/core/api/queryState';

import {
  type InputValueEntry,
  type PreparePayablesInputConfig,
  type SupplierInputValueEntry,
} from '../../panelInputConfig';

export const findCurrentSupplier = (
  supplierInput: PreparePayablesInputConfig | undefined,
  supplierAccount?: string,
) => {
  if (supplierInput?.type === 'select') {
    const existingSupplierAccount: SupplierInputValueEntry | undefined =
      supplierInput.values.find(
        (localExistingSupplierAccount) =>
          localExistingSupplierAccount.key === supplierAccount,
      );

    return existingSupplierAccount;
  }
};

export const checkIfDefaultSupplierAccount = (
  currentSelectedSupplierAccount: false | InputValueEntry | undefined,
  defaultSupplierAccounts:
    | {
        items: DefaultSupplierAccount[];
      }
    | undefined,
): boolean =>
  !!(
    !defaultSupplierAccounts?.items[0]?.isArchived &&
    currentSelectedSupplierAccount &&
    defaultSupplierAccounts?.items[0]?.id === currentSelectedSupplierAccount.key
  );

export const checkIfSupplierAccountDeleted = (
  supplierAccount: string | undefined,
  supplierInput: PreparePayablesInputConfig | undefined,
): boolean =>
  !!(
    supplierAccount &&
    supplierInput?.type === 'select' &&
    !findCurrentSupplier(supplierInput, supplierAccount)
  );

export const checkIfSupplierAccountInvalid = (
  integrationStatusQuery: QueryState<IntegrationStatus, unknown>,
  supplierAccount?: string,
): boolean =>
  !!(
    supplierAccount &&
    integrationStatusQuery.status === 'success' &&
    integrationStatusQuery.data.integration !== 'noIntegration' &&
    integrationStatusQuery.data.integration !== 'switchInProgress' &&
    integrationStatusQuery.data.settingsValidation.supplierAccounts.some(
      (invalidSupplierAccount) =>
        invalidSupplierAccount.error === 'invalidAccount' &&
        invalidSupplierAccount.id === supplierAccount,
    )
  );
