import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type { ApiCardSettings } from '../card';

export const useCardSettingsQuery = (
  cardId: string,
): QueryState<ApiCardSettings> => {
  return useQuery<ApiCardSettings, ApiCardSettings>({
    key: ['cards', cardId, 'control-settings'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/cards/${cardId}/control-settings`,
    },
    reshapeData: (data) => data,
  });
};
