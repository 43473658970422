import React from 'react';

import { ErrorBoundary } from 'common/components/withErrorBoundary';

import { OperationalOnboarding } from './OperationalOnboarding/OperationalOnboarding';
import { useGetWelcomeDashboardQuery } from '../../hooks/useGetWelcomeDashboardQuery';

export const OperationalOnboardingContainer = () => {
  const getWelcomeDashboardQueryState = useGetWelcomeDashboardQuery();

  return (
    <ErrorBoundary context={{ team: 'growth', scope: 'welcome-dashboard' }}>
      <OperationalOnboarding
        getWelcomeDashboardQueryState={getWelcomeDashboardQueryState}
      />
    </ErrorBoundary>
  );
};
