import { Modal, Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import { type ImportCostCenterRequestError } from './useImportCostCentersMutation';
import './CostCenterImportModalError.css';

class TypeGuardError extends Error {
  constructor(_switchCase: never, message: string) {
    super(message);
  }
}

type ErrorTypesToDisplay =
  | 'fileInvalid'
  | 'costCenterAlreadyExisting'
  | 'userNotFound'
  | 'costCenterAlreadyDefined';

const getErrorMessageTranslationKeys = (
  errorTypeToDisplay: ErrorTypesToDisplay,
): { message: string; errorName: string; type: ErrorTypesToDisplay } => {
  switch (errorTypeToDisplay) {
    case 'fileInvalid':
      return {
        type: 'fileInvalid',
        errorName: 'costCenters.importModal.errors.fileInvalid.title',
        message: 'costCenters.importModal.errors.fileInvalid.message',
      };
    case 'costCenterAlreadyExisting':
      return {
        type: 'costCenterAlreadyExisting',
        errorName:
          'costCenters.importModal.errors.costCenterAlreadyExisting.title',
        message:
          'costCenters.importModal.errors.costCenterAlreadyExisting.message',
      };
    case 'costCenterAlreadyDefined':
      return {
        type: 'costCenterAlreadyDefined',
        errorName:
          'costCenters.importModal.errors.costCenterAlreadyDefined.title',
        message:
          'costCenters.importModal.errors.costCenterAlreadyDefined.message',
      };
    case 'userNotFound':
      return {
        type: 'userNotFound',
        errorName: 'costCenters.importModal.errors.userNotFound.title',
        message: 'costCenters.importModal.errors.userNotFound.message',
      };
    default:
      throw new TypeGuardError(
        errorTypeToDisplay,
        `Unknown error type ${errorTypeToDisplay}`,
      );
  }
};

type Props = {
  onRetry(): void;
  onCancel(): void;
  error: Extract<
    ImportCostCenterRequestError,
    { reason: 'fileInvalid' } | { reason: 'fileDataInvalid' }
  >;
};

export const CostCenterImportModalError = ({
  onCancel,
  onRetry,
  error,
}: Props) => {
  const { t } = useTranslation();

  const errorsToDisplay: {
    type: ErrorTypesToDisplay;
    message: string;
    errorName: string;
    entities?: string[];
  }[] = [];

  if (error.reason === 'fileDataInvalid') {
    for (const fileDataInvalidError of error.errors) {
      const alreadyExistingError = errorsToDisplay.find(
        (errorToDisplay) => errorToDisplay.type === fileDataInvalidError.type,
      );

      if (alreadyExistingError?.entities) {
        alreadyExistingError.entities.push(fileDataInvalidError.value);
        continue;
      }

      errorsToDisplay.push({
        ...getErrorMessageTranslationKeys(fileDataInvalidError.type),
        entities: [fileDataInvalidError.value],
      });
    }
  } else {
    errorsToDisplay.push(getErrorMessageTranslationKeys(error.reason));
  }

  return (
    <Modal
      isOpen
      iconName="failure"
      iconVariant="alert"
      title={
        errorsToDisplay.length > 1
          ? t('costCenters.importModal.errors.title.multiple')
          : t('costCenters.importModal.errors.title.single')
      }
      actions={[
        <Button
          key="cancel-button"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="try-again-button"
          text={t('misc.tryAgain')}
          variant="primary"
          onClick={onRetry}
        />,
      ]}
    >
      {errorsToDisplay.map(({ message, errorName, entities }) => (
        <div key={message} className="CostCenterImportModalError__error">
          <p>“{t(errorName)}”</p>
          {t(message)}
          {entities?.length ? (
            <ul className="CostCenterImportModalError__entities_list">
              {entities.map((entity) => (
                <li key={entity}>{entity}</li>
              ))}
            </ul>
          ) : null}
        </div>
      ))}
    </Modal>
  );
};
