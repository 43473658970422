import { type MonetaryValue } from 'ezmoney';

import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { GET_ACCOUNT_PAYABLE_SUPPLIERS } from '../queries/getAccountPayableSuppliers';
import { type Supplier } from '../types';

type RawData = {
  supplierAccount: {
    suppliers: {
      pageInfo: {
        hasNextPage: boolean;
      };
      edges: {
        cursor: string;
        node: {
          thumbnailUrl: string;
          name: string;
          id: string;
          payablesAggregate: {
            sum: {
              functionalAmount: MonetaryValue;
            };
          };
        };
      }[];
    };
  };
};

export const useAccountPayableSuppliersQuery = (
  accountPayableId: string,
  search?: string,
  archivalStatus?: 'current' | 'archived' | undefined,
): InfiniteQueryState<Supplier[]> => {
  let archivalStatusFilter = {};

  switch (archivalStatus) {
    case 'current':
      archivalStatusFilter = { isArchived: false };
      break;
    case 'archived':
      archivalStatusFilter = { isArchived: true };
      break;
    default:
      break;
  }

  return useInfiniteQuery<Supplier, RawData>({
    key: ['accountPayableSuppliers', accountPayableId],
    isEnabled: accountPayableId !== '-',
    getRequest: (cursor) => ({
      type: 'graphQL',
      target: 'v2',
      query: GET_ACCOUNT_PAYABLE_SUPPLIERS,
      variables: {
        accountPayableId,
        after: cursor,
        filter: {
          search,
          ...archivalStatusFilter,
        },
      },
    }),
    getNextPageParam: (data) => {
      const { edges, pageInfo } = data.supplierAccount.suppliers;
      const lastEdge = edges.at(-1);

      if (pageInfo.hasNextPage && lastEdge) {
        return lastEdge.cursor;
      }
      return undefined;
    },
    reshapeData: (rawData) => {
      const { edges } = rawData.supplierAccount.suppliers;
      return edges.map(({ node }) => ({
        supplierName: node.name,
        logosUrls: [node.thumbnailUrl],
        supplierId: node.id,
        totalAmount: node.payablesAggregate.sum.functionalAmount,
      }));
    },
  });
};
