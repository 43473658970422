import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import { UNSET_EXPENSE_ACCOUNT_SUPPLIER_RULE } from '../queries/unsetExpenseAccountSupplierRule';

type Payload = {
  supplierId: string;
};

type RawResponse = {
  unsetExpenseAccountSupplierRule: {
    reason?: string;
  };
};

type Response = {
  unsetExpenseAccountSupplierRule: {
    reason?: string;
  };
};

export const useUnsetExpenseAccountSupplierRuleMutation = (): MutationState<
  Payload,
  Response
> => {
  return useMutation<Payload, Response, RawResponse>({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: UNSET_EXPENSE_ACCOUNT_SUPPLIER_RULE,
    },
    reshapeData: (data) => {
      return data;
    },
  });
};
