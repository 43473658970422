export type MonthStats = {
  month: number | null;
  year: number | null;
  total_count: number;
  total_amount_billed: number;
  total_fx_fee_amount: number;
};

export function mergeStatsByMonth(
  monthStats1: MonthStats[],
  monthStats2: MonthStats[],
): MonthStats[] {
  const allMonthStats = [...monthStats1, ...monthStats2];

  let result: MonthStats[] = [];

  for (const currentMonthStats of allMonthStats) {
    // find an identical month stats item
    const sameMonthStatsIndex = result.findIndex((monthStats) => {
      return (
        monthStats.month === currentMonthStats.month &&
        monthStats.year === currentMonthStats.year
      );
    });
    const sameMonthStats = result[sameMonthStatsIndex];

    // if there is an identical item, then merge the "total" numbers
    if (sameMonthStats) {
      result = Object.assign([], result, {
        [sameMonthStatsIndex]: {
          month: sameMonthStats.month,
          year: sameMonthStats.year,
          total_count:
            sameMonthStats.total_count + currentMonthStats.total_count,
          total_amount_billed:
            sameMonthStats.total_amount_billed +
            currentMonthStats.total_amount_billed,
          total_fx_fee_amount:
            sameMonthStats.total_fx_fee_amount +
            currentMonthStats.total_fx_fee_amount,
        },
      });
      continue;
    }

    // otherwise it's a new month stats item
    result = [...result, currentMonthStats];
  }

  return result;
}
