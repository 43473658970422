import type { TriggerProps } from '@dev-spendesk/grapes';
import React from 'react';

import { type Company } from 'modules/app/hooks/useCompany';
import { type User } from 'modules/app/hooks/useUser';
import type { getReloadType } from 'src/core/utils/wallet';

import { CompanySwitcherButton } from './CompanySwitcherButton';
import { CompanySwitcherPopover } from './CompanySwitcherPopover';

type Props = {
  user: User;
  company: Company;
  reloadType: ReturnType<typeof getReloadType>;
  showWallet: boolean;
  hasHomepage: boolean;
};

export const CompanySwitcherMenu = ({
  user,
  company,
  reloadType,
  showWallet,
  hasHomepage,
}: Props) => {
  return (
    <CompanySwitcherPopover
      user={user}
      company={company}
      reloadType={reloadType}
      showWallet={showWallet}
    >
      {({ isDropdown, ...triggerProps }: TriggerProps) => (
        <CompanySwitcherButton
          company={company}
          reloadType={reloadType}
          showBalance={showWallet && !hasHomepage}
          triggerProps={triggerProps}
        />
      )}
    </CompanySwitcherPopover>
  );
};
