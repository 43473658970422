import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from "src/core/common/hooks/useTranslation";

import { useDeleteBudgetMutation } from './hooks/useDeleteBudgetMutation';
import { useRedirectToBudgetaryExercise } from '../hooks/useRedirectToBudgetaryExercise';

type Props = {
  budget: {
    id: string;
    name: string;
    budgetaryExerciseId: string;
  };
  onClose(): void;
};

export const DeleteBudgetModalContainer = ({ budget, onClose }: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const [deleteBudget] = useDeleteBudgetMutation(budget.id);
  const redirectToBudgetaryExercise = useRedirectToBudgetaryExercise();

  return (
    <Modal
      isOpen
      iconName="failure"
      iconVariant="alert"
      title={t('budget.removeBudget.title')}
      actions={[
        <Button
          key="1"
          variant="secondary"
          text={t('budget.removeBudget.buttonCancel')}
          onClick={onClose}
        />,
        <Button
          key="2"
          variant="alert"
          text={t('budget.removeBudget.buttonConfirm')}
          onClick={async () => {
            try {
              await deleteBudget();
              redirectToBudgetaryExercise(budget.budgetaryExerciseId);
            } catch {
              pushNotif({
                type: NotificationType.Danger,
                message: t('budget.removeBudget.error'),
              });
            }
          }}
        />,
      ]}
    >
      {t('budget.removeBudget.subTitle', { costCenterName: budget.name })}
    </Modal>
  );
};
