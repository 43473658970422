import { type MonetaryValue, subtract } from 'ezmoney';

import type { CostCenter } from 'modules/budgets/models/costCenter';

import { type Supplier } from './supplier';
import { type Team } from './team';
import { type User } from './user';
import { type PurchaseOrderRequestItem } from '../../requests/models';

export type PurchaseOrderStatus = 'open' | 'closed' | 'cancelled';

export type PurchaseOrderId = string;

export type PurchaseOrderProgress =
  | 'waitingForDelivery'
  | 'lateInvoice'
  | 'partiallyBilled'
  | 'billed'
  | 'overbilled';

interface PurchaseOrderBase {
  id: PurchaseOrderId;
  amount: MonetaryValue;
  netAmount: MonetaryValue;
  billedAmount: MonetaryValue;
  purchaseOrderNumber: string;
  description: string;
  status: PurchaseOrderStatus;
  progress?: PurchaseOrderProgress;
  startDate: string;
  endDate: string;
  createdAt: string;
  supplier: Supplier;
  requester: User;
  team?: Team;
  costCenter?: CostCenter;
  items?: PurchaseOrderRequestItem[];
}
export interface OpenedPurchaseOrder extends PurchaseOrderBase {
  status: 'open';
  progress:
    | 'waitingForDelivery'
    | 'lateInvoice'
    | 'partiallyBilled'
    | 'billed'
    | 'overbilled';
}
export interface ClosedPurchaseOrder extends PurchaseOrderBase {
  status: 'closed';
  progress: 'partiallyBilled' | 'billed';
}

export interface CancelledPurchaseOrder extends PurchaseOrderBase {
  status: 'cancelled';
  progress: undefined;
}

export type PurchaseOrder =
  | OpenedPurchaseOrder
  | ClosedPurchaseOrder
  | CancelledPurchaseOrder;

export const getPurchaseOrderAvailableAmount = (
  purchaseOrder: PurchaseOrder,
): MonetaryValue => subtract(purchaseOrder.amount, purchaseOrder.billedAmount);

export const getCanAttachInvoices = (purchaseOrder: PurchaseOrder): boolean =>
  purchaseOrder.status === 'open';
