import { type FormikErrors, useFormik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import truncate from 'lodash/truncate';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { routes, routeFor } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { ShippingForm } from './ShippingForm';
import { type Values } from './types';
import { reportAndDeleteCard } from '../../../list/redux/actions';
import { type Recipient } from '../../recipient';
import { orderRecard } from '../../redux/actions';

type Props = {
  card: {
    id: string;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    city: string;
    country: string;
    monthly_budget: number;
    recard_request?: {
      next_card_id: string;
    };
  };
  reOrder: boolean;
  reportAndDeleteCardFromProps: (
    cardId: string,
    actionType:
      | 'pause'
      | 'unpause'
      | 'reload'
      | 'lost'
      | 'damaged'
      | 'stolen'
      | 'cancelled',
    callback?: (error: unknown, updatedCard: unknown) => void,
  ) => Promise<void>;
  orderRecardFromProps: (cardId: string, recipient: Recipient) => void;
  fetchPlasticCard: (cardId: string) => void;
};

const Shipping = ({
  card,
  reOrder,
  reportAndDeleteCardFromProps,
  orderRecardFromProps,
  fetchPlasticCard,
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const user = useUser();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const isWebviewActive = urlParams.has('webview');
  const { pushNotif } = useNotifications();
  // Read initial address lines
  const initialAddressLine1 =
    card.addressLine1 ??
    (window.localStorage.getItem('RecardOrder__shipping__addressLine1') ||
      company.address ||
      '');
  const initialAddressLine2 =
    card.addressLine2 ??
    (window.localStorage.getItem('RecardOrder__shipping__addressLine2') || '');

  // Truncate address in two lines if it's necessary (if first line > 35 characters)
  const truncatedAddressLine1 = truncate(initialAddressLine1, {
    length: 35,
    omission: '',
    separator: ' ',
  });
  const restAddressLine1 = initialAddressLine1.replace(
    truncatedAddressLine1,
    '',
  );
  const addressLine2 =
    restAddressLine1.length === 0 ? initialAddressLine2 : restAddressLine1;

  // bankable only sypport zipcode with <= 9 characters.
  const zipCodeMaxLength = 9;
  const zipCode =
    card.zipCode ??
    (window.localStorage.getItem('RecardOrder__shipping__zipCode') ||
      company.zipcode ||
      '');

  const initialValues = {
    phoneNumber: user ? `+${user.mobile_ext}${user.mobile_no}` : '',
    recipient: '',
    addressLine1: truncatedAddressLine1.trim(),

    addressLine2: addressLine2.trim(),

    zipCode: zipCode.slice(0, Math.max(0, zipCodeMaxLength)),

    city:
      card.city ??
      (window.localStorage.getItem('RecardOrder__shipping__city') ||
        company.city ||
        ''),

    country:
      card.country ??
      (window.localStorage.getItem('RecardOrder__shipping__country') ||
        company.country ||
        ''),
    recipientName: user.fullname,
  };

  const onSubmitHandler = async (values: Values) => {
    if (reOrder && card.recard_request) {
      await reportAndDeleteCardFromProps(
        card.recard_request.next_card_id,
        'lost',
        (error) => {
          if (error) {
            return pushNotif({
              type: NotificationType.Danger,
              message: t('recard.order.errorDeleteReOrder'),
            });
          }
        },
      );

      track(AnalyticEventName.CARD_NOT_RECEIVED_ORDER_NEW, {
        cardId: card.recard_request.next_card_id,
      });
    } else {
      track(AnalyticEventName.RECARD_ORDERED, {
        userId: user.id,
        companyId: company.id,
        previousCardId: card.id,
        displayMode: isWebviewActive ? 'mobile' : 'desktop',
      });
    }

    // Phone number has already been parsed in validation
    const phoneNumber = parsePhoneNumber(values.phoneNumber);
    const recipient = {
      ...values,
      phoneNumber: {
        mobile_no: phoneNumber.nationalNumber,
        mobile_ext: phoneNumber.countryCallingCode,
      },
      owner: {
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        phoneNumber: {
          mobile_no: phoneNumber.nationalNumber,
          mobile_ext: phoneNumber.countryCallingCode,
        },
      },
      setUpMonthlyReload: !!card.monthly_budget,
      budget: card.monthly_budget,
    };

    await orderRecardFromProps(card.id, recipient);

    await fetchPlasticCard(card.id);
    history.push(routeFor(routes.CARD.path, { company: company.id }));
  };

  const formikProps = useFormik<Values>({
    initialValues,
    validate: (values) => {
      const errors: FormikErrors<Values> = {};
      if (!values.recipientName) {
        errors.recipientName = 'required';
      }
      if (!values.addressLine1) {
        errors.addressLine1 = 'required';
      }
      if (!values.zipCode) {
        errors.zipCode = 'required';
      }
      if (!values.city) {
        errors.city = 'required';
      }
      try {
        parsePhoneNumber(values.phoneNumber);
      } catch {
        errors.phoneNumber = 'invalid';
      }
      if (!values.country) {
        errors.country = 'required';
      }
      return errors;
    },
    onSubmit: async (values) => {
      await onSubmitHandler(values);
    },
  });

  return (
    <ShippingForm
      isRecipientDisabled
      backButtonLink={
        reOrder
          ? routeFor(routes.RECARD.path, {
              company: company.id,
              status: 'card_lost_validation',
            })
          : routeFor(routes.CARD.path, { company: company.id })
      }
      {...formikProps}
    />
  );
};

const mapDispatchToProps = {
  orderRecardFromProps: (cardId: string, recipient: Recipient) =>
    orderRecard(cardId, recipient),
  reportAndDeleteCardFromProps: (
    cardId: string,
    actionType: string,
    callback?: (error: unknown, updatedCard: unknown) => void,
  ) => reportAndDeleteCard(cardId, actionType, callback),
};

const ConnectedShipping = connect(null, mapDispatchToProps)(Shipping);

export { ConnectedShipping as Shipping };
