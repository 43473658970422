import React, { useState } from 'react';

import { type Member } from 'modules/members/models/member';

import { EmailsInputFields } from './EmailsInput/EmailsInputFields';
import { EmailsInputText } from './EmailsInput/EmailsInputText';
import { getDefaultSettings } from './utils';
import {
  type DataSources,
  type InviteField,
  type SendInvitesPayload,
  type Settings,
} from '../../models/invite';

type Props = {
  companyDomain: string;
  members: Member[];
  settingsDataSources: DataSources;
  accountOwner: { email: string } | undefined;
  isTeamsFeatureEnabled: boolean;
  isCostCenterActivatedFeatureEnabled: boolean;
  remainingSeatsForUsersCount?: number;
  sendInvites: (payload: SendInvitesPayload) => Promise<void>;
  onCancel?: () => void;
  showMembersTipModal?: () => void;
};

export const MemberInviteEmailContent = ({
  accountOwner,
  members,
  settingsDataSources,
  isTeamsFeatureEnabled,
  isCostCenterActivatedFeatureEnabled,
  sendInvites,
  companyDomain,
  showMembersTipModal,
  onCancel,
  remainingSeatsForUsersCount,
}: Props) => {
  const accountOwnerDomain = (() => {
    if (accountOwner) {
      return accountOwner.email.split('@')[1];
    }
    return companyDomain;
  })();

  const defaultSettings = getDefaultSettings(settingsDataSources);
  const [emailInputType, setEmailInputType] = useState<'fields' | 'text'>(
    'fields',
  );
  const [settings, setSettings] = useState<Settings>(defaultSettings);
  const [inviteFields, setInviteFields] = useState<InviteField[]>([
    {
      value: `@${accountOwnerDomain}`,
      index: 0,
      valid: true,
      duplicate: false,
    },
  ]);

  if (emailInputType === 'fields') {
    return (
      <EmailsInputFields
        members={members}
        settingsDataSources={settingsDataSources}
        isTeamsFeatureEnabled={isTeamsFeatureEnabled}
        isCostCenterActivatedFeatureEnabled={
          isCostCenterActivatedFeatureEnabled
        }
        sendInvites={sendInvites}
        inviteFields={inviteFields}
        setInviteFields={setInviteFields}
        settings={settings}
        setSettings={setSettings}
        setEmailInputType={setEmailInputType}
        accountOwnerDomain={accountOwnerDomain}
        showMembersTipModal={showMembersTipModal}
        onCancel={onCancel}
        remainingSeatsForUsersCount={remainingSeatsForUsersCount}
      />
    );
  }

  return (
    <EmailsInputText
      setEmailInputType={setEmailInputType}
      resetSettings={() => setSettings(defaultSettings)}
      setInviteFields={setInviteFields}
    />
  );
};
