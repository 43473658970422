import { Icon, Panel, type PanelProps, colors } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useTranslation } from '../../hooks/useTranslation';

export type DroppablePanelProps = PanelProps & {
  onUploadFiles(files: File[]): void | Promise<void>;
  canUploadFiles?: boolean;
  allowMultipleFiles?: boolean;
};

export const DroppablePanel = ({
  onUploadFiles,
  canUploadFiles = true,
  allowMultipleFiles = false,
  className,
  children,
  ...rest
}: DroppablePanelProps) => {
  const [isDragActive, setDragActive] = React.useState(false);

  const currentDragTarget = React.useRef<EventTarget | null>(null);

  const handleDragLeave = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const { target } = event;
      // DragEnter is triggered before DragLeave, so currentDragTarget is the latest known target
      // If we leave this target, it means we are leaving the droppable area
      if (currentDragTarget.current === target) {
        setDragActive(false);
      }
    },
    [],
  );

  const handleDragEnter = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const { target } = event;
      currentDragTarget.current = target;
      setDragActive(true);
    },
    [],
  );

  const handleDragOver = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const { dataTransfer } = event;
      if (dataTransfer) {
        try {
          dataTransfer.dropEffect = 'copy';
        } catch {
          // Do nothing, some browsers can have readonly dropEffect
        }
      }
    },
    [],
  );

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    const filesArray = Array.from(files).filter((_, index) =>
      allowMultipleFiles ? true : index < 1,
    );
    onUploadFiles(filesArray);
    setDragActive(false);
  };

  return (
    <Panel
      {...rest}
      className={classNames(
        className,
        'transition-colors',
        isDragActive
          ? 'border-dashed border-primary bg-primary-lightest [&_div]:h-full'
          : '',
      )}
      {...(canUploadFiles
        ? {
            onDrop: handleDrop,
            onDragLeave: handleDragLeave,
            onDragEnter: handleDragEnter,
            onDragOver: handleDragOver,
            'aria-dropeffect': 'copy',
          }
        : {})}
      {...(isDragActive
        ? {
            header: undefined,
            footer: undefined,
            title: undefined,
            footerSummary: undefined,
            onClose: undefined,
          }
        : {})}
    >
      {isDragActive ? <ActiveDragContent /> : children}
    </Panel>
  );
};

/**
 * Helpers
 */

const ActiveDragContent = () => {
  const { t } = useTranslation('global');
  return (
    <div className="flex h-full flex-col items-center justify-center gap-s p-m">
      <Icon color={colors.primary} name="drag-and-drop" size="l" />
      <span>{t('misc.dragAndDropFiles')}</span>
    </div>
  );
};
