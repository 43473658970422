import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type Response = {
  isScaUserEnrolled: boolean;
  providerName: 'modirum' | 'entrust';
};

export const useUserScaEnrolledQuery = (): QueryState<Response> => {
  return useQuery({
    key: ['sca', 'users', 'enrol'],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: '/sca/users/enrol',
    },
    reshapeData: (data) => data,
    options: {
      cacheTime: 10 * 60 * 1000,
    },
  });
};
