import {
  Avatar,
  colors,
  Icon,
  SkeletonAvatar,
  SkeletonText,
  Tag,
} from '@dev-spendesk/grapes';

import { useQueryStates } from 'src/core/api/hooks';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import {
  type ApprovalRule,
  type ApprovalRight,
} from 'src/core/modules/company/structure/approval-flows';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { useApprovalSchemeQuery } from '../../hooks/useApprovalScheme';
import { type Member, useMembersQuery } from '../../hooks/useMembersQuery';

export const ApprovalSchemeNodePreview = () => {
  const queryStates = useQueryStates({
    states: {
      approvalScheme: useApprovalSchemeQuery('aoh2gdgyw39yk6'), // approvalSchemeId is mocked
      members: useMembersQuery(),
    },
  });

  return (
    <QuerySuspense
      queryState={queryStates}
      loading={
        <div className="box mt-s rounded bg-white p-m">
          <div className="flex items-center gap-xs">
            <SkeletonAvatar size="m" />
            <SkeletonText width="100px" />
          </div>
        </div>
      }
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {({ approvalScheme, members }) => {
        return (
          <div className="box mt-s rounded bg-white p-m">
            <div className="flex items-center gap-xs">
              <div className="flex h-m w-m items-center justify-center rounded-[50%] border border-solid border-neutral-light bg-neutral-lightest">
                <Icon color={colors.neutralDark} name="magic-wand" size="s" />
              </div>
              <div className="text-neutral-darker title-s">
                Approval is required from
              </div>
            </div>
            <div>
              {approvalScheme.rules.map((rule) => {
                return (
                  <div key={rule.id}>
                    <div className="mb-xxs mt-xs text-neutral-darker title-s">
                      {getThresholdTitle(rule)}
                    </div>
                    <div>
                      {getRuleApprovers(rule.steps, members).map((approver) => {
                        return (
                          <div key={approver.id}>
                            <Tag
                              isRounded
                              key={approver.id}
                              variant="neutral"
                              className="mb-xxs mr-xxs inline-block"
                            >
                              <Avatar
                                src={approver.avatar ?? undefined}
                                size="xs"
                                className="mr-xxs"
                                text={
                                  approver.isPending
                                    ? approver.email
                                    : approver.fullname
                                }
                              />
                              {approver.isPending
                                ? approver.email
                                : approver.fullname}
                            </Tag>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </QuerySuspense>
  );
};

const getThresholdTitle = (rule: ApprovalRule) => {
  const { from, upTo } = rule;

  // Display only 'up to' value
  if (from?.value === 0 && upTo.value) {
    return `Up to ${formatMonetaryValue({
      amount: upTo.value,
      currency: from.currency,
      precision: 0,
    })}`;
  }

  // Display only 'from' value
  if (from?.value && upTo.value === null) {
    return `From ${formatMonetaryValue({
      amount: from.value,
      currency: from.currency,
      precision: 0,
    })}`;
  }

  // Display From .. to .. value
  if (from?.value && upTo?.value) {
    return `From ${formatMonetaryValue({
      amount: from.value,
      currency: from.currency,
      precision: 0,
    })} to ${formatMonetaryValue({
      amount: upTo.value,
      currency: upTo.currency,
      precision: 0,
    })}`;
  }

  return '';
};

const getRuleApprovers = (
  steps: { rights: ApprovalRight[] }[],
  members: Member[],
): Member[] => {
  const stepApproverIds = steps
    .flatMap((step) => step.rights)
    .filter((right) => right.approverType === 'user')
    .map((right) => right.approverId);
  return members.filter((member) => stepApproverIds.includes(member.id));
};
