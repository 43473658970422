import {
  SwitchField,
  Modal,
  Button,
  Callout,
  Link,
} from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';

import { useDisableFeature } from '../../hooks/useDisableFeature';
import { useEnableFeature } from '../../hooks/useEnableFeature';
import type { SpendingMethod as SpendingMethodType } from '../../spendingMethods';

function getTranslationKeyFromFeatureCode(
  featureCode: SpendingMethodType,
  isCostCenterActivated: boolean,
) {
  switch (featureCode) {
    default:
    case FEATURES.EXPENSE_CLAIMS:
      return {
        titleKey: 'payments.settings.expenseClaims.title',
        descriptionKey: 'payments.settings.expenseClaims.description',
      };
    case FEATURES.SUBMIT_MY_INVOICE:
      return {
        titleKey: 'payments.settings.invoices.title',
        descriptionKey: 'payments.settings.invoices.description',
        warningKey: 'payments.settings.invoices.warning',
      };
    case FEATURES.PURCHASE_ORDERS_ACTIVATED:
      return {
        titleKey: 'payments.settings.purchaseOrders.title',
        descriptionKey: 'payments.settings.purchaseOrders.description',
        warningKey: isCostCenterActivated
          ? 'payments.settings.purchaseOrders.warning_invoice_disabled'
          : 'payments.settings.purchaseOrders.warning',
      };
    case FEATURES.PER_DIEM_ALLOWANCES:
      return {
        titleKey: 'payments.settings.perDiemAllowances.title',
        descriptionKey: 'payments.settings.perDiemAllowances.description',
      };
    case FEATURES.MILEAGE_ALLOWANCES:
      return {
        titleKey: 'payments.settings.mileageAllowances.title',
        descriptionKey: 'payments.settings.mileageAllowances.description',
        warningKey: 'payments.settings.mileageAllowances.warning',
      };
  }
}

type Props = {
  featureCode: SpendingMethodType;
  isDisabled: boolean;
};
export const SpendingMethod = ({ featureCode, isDisabled }: Props) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const hasFeature = useFeature(featureCode);
  const isCostCenterActivated = useFeature(FEATURES.COST_CENTERS_ACTIVATED);

  const { pushNotif } = useNotifications();

  const [disableFeature] = useDisableFeature(featureCode);
  const [enableFeature] = useEnableFeature();

  async function handlePrimaryAction() {
    setModalOpen(false);
    try {
      if (hasFeature) {
        await disableFeature();
      } else {
        await enableFeature({ featureCode });
      }
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('errors:somethingWrong'),
      });
    }
  }

  const { titleKey, descriptionKey, warningKey } =
    getTranslationKeyFromFeatureCode(featureCode, isCostCenterActivated);

  const title = t(titleKey);
  const description = t(descriptionKey);

  /**
   * Only used by Purchase order spending method.
   * Unfortunately, route has to be defined for the Link component below
   */
  const route = routeFor(routes.COST_CENTERS.path, {
    company: companyId,
  });

  return (
    <>
      <SwitchField
        id={featureCode}
        fit="parent"
        isChecked={hasFeature}
        label={title}
        helpText={description}
        isDisabled={isDisabled}
        onChange={() => {
          setModalOpen(true);
        }}
      />
      {isDisabled && warningKey && (
        <Callout
          variant="info"
          title={
            <Trans
              i18nKey={warningKey}
              // eslint-disable-next-line react/jsx-key
              components={[<Link as={ReactRouterLink} to={route} />]}
            />
          }
          className="mb-s"
        />
      )}
      <Modal
        actions={[
          <Button
            key="no"
            onClick={() => setModalOpen(false)}
            text={t('misc.cancel')}
            variant="secondary"
          />,
          <Button
            key="yes"
            text={
              hasFeature
                ? t('payments.settings.modal.deactivation.action', {
                    payment: title,
                  })
                : t('payments.settings.modal.activation.action', {
                    payment: title,
                  })
            }
            variant="primary"
            onClick={handlePrimaryAction}
          />,
        ]}
        onClose={() => setModalOpen(false)}
        iconName="lock"
        iconVariant="primary"
        title={
          hasFeature
            ? t('payments.settings.modal.deactivation.title', {
                payment: title,
              })
            : t('payments.settings.modal.activation.title', { payment: title })
        }
        subtitle={
          hasFeature
            ? t('payments.settings.modal.deactivation.subtitle', {
                payment: title,
              })
            : t('payments.settings.modal.activation.subtitle', {
                payment: title,
              })
        }
        isOpen={isModalOpen}
      />
    </>
  );
};
