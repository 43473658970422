import { useCostCentersQuery } from 'modules/budgets/apis';
import { isRequestError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';

import {
  useBudgetaryExercisesQuery,
  USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE,
} from '../../../../../budgets/containers/hooks/useBudgetaryExercisesQuery';
import type {
  Budget,
  CostCenter,
  CostCenterWithBudgets,
} from '../../../costCenter';

const enrichCostCentersWithHasBudgets = (
  costCenters: CostCenter[],
  budgets: Budget[],
): CostCenterWithBudgets[] => {
  return costCenters.map((costCenter) => {
    return {
      ...costCenter,
      hasBudgets: budgets.some(
        (budget) => budget.costCenterId === costCenter.id,
      ),
    };
  });
};

export const useEnrichedCostCentersQuery = (): QueryState<
  CostCenterWithBudgets[]
> => {
  const budgetaryExercisesQuery = useBudgetaryExercisesQuery();
  const costCentersQuery = useCostCentersQuery();

  const isOneQueryLoading =
    budgetaryExercisesQuery.status === 'loading' ||
    costCentersQuery.status === 'loading';
  if (isOneQueryLoading) {
    return {
      status: 'loading',
    };
  }

  if (costCentersQuery.status === 'error') {
    return {
      status: 'error',
      error: costCentersQuery.error,
    };
  }

  // If the error is about the user not being allowed to access budgets,
  // it shouldn't prevent them from getting cost centers
  if (
    budgetaryExercisesQuery.status === 'error' &&
    isRequestError(budgetaryExercisesQuery.error) &&
    budgetaryExercisesQuery.error.data.errorCode !==
      USER_NOT_ALLOWED_TO_ACCESS_BUDGET_ERROR_CODE
  ) {
    return {
      status: 'error',
      error: budgetaryExercisesQuery.error,
    };
  }

  const budgetaryExercises =
    budgetaryExercisesQuery.status === 'success'
      ? budgetaryExercisesQuery.data
      : [];

  const budgets = budgetaryExercises.flatMap((be) => be.budgets);
  const costCentersWithHasBudgets = enrichCostCentersWithHasBudgets(
    costCentersQuery.data,
    budgets,
  );

  return {
    status: 'success',
    data: costCentersWithHasBudgets,
  };
};
