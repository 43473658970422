import { Icon, Tooltip, colors } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const OCRIcon = () => {
  const { t } = useTranslation('global');

  return (
    <Tooltip
      content={t('submitMyInvoice.fields.ocrResultTooltip')}
      triggerAsChild
    >
      <Icon className="ml-xs" color={colors.success} name="robot" size="s" />
    </Tooltip>
  );
};
