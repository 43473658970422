import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { CenteredLayout } from 'common/components/layout';
import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';

import { useCreateEcQuery } from './hooks/useCreateEcQuery';
import { useCreateOrUpdateExpenseCategoryExpenseAccountRule } from './hooks/useCreateOrUpdateExpenseCategoryExpenseAccountRule';
import { EcFormModal } from '../../components/EcFormModal';
import { type FormValues } from '../../components/EcFormModal/formValues';
import { useGetDataForExpenseCategoryExpenseAccountRules } from '../../hooks/useGetDataForExpenseCategoryExpenseAccountRules';
import { useIsExpenseCategoryExpenseAccountRuleEnabled } from '../../hooks/useIsExpenseCategoryExpenseAccountRuleEnabled';
import { useExpenseCategoriesQuery } from '../EcContainer/hooks/useExpenseCategoriesQuery';

type Props = {
  isOpen: boolean;
  onComplete(): void;
  onCancel(): void;
};

export const EcCreationFormModalContainer = ({
  isOpen,
  onComplete,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const [createExpenseCategory] = useCreateEcQuery();
  const isExpenseCategoryExpenseAccountRuleEnabled =
    useIsExpenseCategoryExpenseAccountRuleEnabled();
  const [createExpenseCategoryExpenseAccountRule] =
    useCreateOrUpdateExpenseCategoryExpenseAccountRule();

  const queryStates = useQueryStates({
    states: {
      costCenters: useCostCentersQuery(),
      expenseCategories: useExpenseCategoriesQuery(),
      dataForExpenseCategoryExpenseAccountRules:
        useGetDataForExpenseCategoryExpenseAccountRules(),
    },
    reshapeData: ({
      costCenters,
      expenseCategories,
      dataForExpenseCategoryExpenseAccountRules,
    }) => ({
      costCenters,
      expenseCategories,
      ...dataForExpenseCategoryExpenseAccountRules,
    }),
  });

  const handleSubmit = async ({
    name,
    costCenterIds,
    linkedExpenseAccountIds,
  }: FormValues) => {
    const createdExpenseCategory = await createExpenseCategory({
      name,
      costCenterIds,
    });

    if (
      isExpenseCategoryExpenseAccountRuleEnabled &&
      linkedExpenseAccountIds.length > 0
    ) {
      try {
        await createExpenseCategoryExpenseAccountRule({
          expenseAccountIds: linkedExpenseAccountIds,
          expenseCategoryId: createdExpenseCategory.id,
        });
      } catch {
        pushNotif({
          type: NotificationType.Danger,
          message: t('expenseCategories.form.createLinkedExpenseAccountError', {
            name: createdExpenseCategory.name,
          }),
        });
      }
    }
  };

  return (
    <QuerySuspense
      queryState={queryStates}
      fallback={() => (
        <CenteredLayout className="text-center" verticalAlign="middle">
          {t('misc.errors.networkError')}
        </CenteredLayout>
      )}
      loading={null}
    >
      {({ costCenters, expenseCategories, expenseAccounts }) => (
        <EcFormModal
          costCenters={costCenters}
          expenseAccounts={expenseAccounts}
          expenseCategoryNames={expenseCategories.map((ec) => ec.name)}
          isOpen={isOpen}
          onSubmit={async (values) => {
            try {
              await handleSubmit(values);
              onComplete();
            } catch {
              pushNotif({
                type: NotificationType.Danger,
                message: t('expenseCategories.form.creationError'),
              });
            }
          }}
          onCancel={onCancel}
        />
      )}
    </QuerySuspense>
  );
};
