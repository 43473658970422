import {
  Avatar,
  colors,
  DATE_FORMAT,
  HighlightIcon,
  Icon,
  Tooltip,
} from '@dev-spendesk/grapes';
import cx from 'classnames';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import {
  fallbackSupplierLogoSrc,
  getLogoUrlFromName,
} from 'common/components/SupplierLogo';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import FEATURES from 'src/core/constants/features';
import { metersToDistanceUnit } from 'src/core/utils/geo';
import { formatMoney } from 'src/core/utils/money';

import {
  getPaymentTypeIcon,
  getInvoiceIconFromPayment,
} from '../../utils/paymentUtils';
import { type Payment } from '../all/paymentType';

type Props = {
  payment: Payment;
  titleId: string;
};

export const PaymentListRow = ({ payment, titleId }: Props) => {
  const { localeFormat, t } = useTranslation('global');

  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);

  const company = useCompany();

  const renderDescription = () => {
    const {
      description,
      is_atm_withdrawal: isWithdrawal,
      transaction,
      user,
    } = payment;
    const paymentDescription =
      description || (transaction ? transaction.clean_description : '');
    const requestType = payment.request?.type;

    let origin;
    if (requestType === 'mileage_allowance') {
      const distanceMeters =
        payment.request?.mileage_allowance_request?.distance ?? 0;
      const distanceUnit = company.mileage_scheme?.distanceUnit ?? 'km';
      origin = (
        <span>
          {t('payables.panel.types.mileage')}
          <span className="mx-xs">•</span>
          {`${metersToDistanceUnit({
            distanceMeters,
            distanceUnit,
          })} ${distanceUnit}`}
        </span>
      );
    } else if (requestType === 'per_diem_allowance') {
      origin = (
        <span>
          {t('payables.panel.types.perDiem')}
          {/* TODO: @CORE-5111 add number of trip days, impossible for now with fetch from grahql v1
          <span className="mx-xs">•</span>
          {number of trip days} 
          */}
        </span>
      );
    }
    // User already in the top row for expense payments
    else if (requestType !== 'expense') {
      origin = user?.full_name;
    } else if (isWithdrawal) {
      origin = t('misc.atmWithdrawal');
    }

    return origin ? (
      <span title={paymentDescription}>
        {origin} <span className="mx-xxs">•</span> {paymentDescription}
      </span>
    ) : (
      <span title={paymentDescription}>{paymentDescription}</span>
    );
  };

  const renderStatus = () => {
    if (payment.state !== 'authorised') {
      return;
    }

    let tooltipMessage = t('payments.awaitingMerchantConfirmation');
    if (
      payment.request &&
      ['expense', 'invoice'].includes(payment.request.type)
    ) {
      tooltipMessage = t('payments.awaitingWireTransfer');
    }

    return (
      <Tooltip content={tooltipMessage} placement="right" triggerAsChild>
        <Icon
          className="pending-payment-clock"
          name="clock"
          color={colors.neutralLight}
        />
      </Tooltip>
    );
  };

  const renderPaymentTypeIcon = () => {
    const { icon, color, tooltip } = getPaymentTypeIcon(payment, t);

    return (
      <Tooltip content={tooltip} triggerAsChild>
        <Icon name={icon} color={color} />
      </Tooltip>
    );
  };

  const renderAmount = () => {
    const {
      amount_declared: amountDeclared,
      currency_declared: currencyDeclared,
      amount_billed: amountBilled,
      fx_fee_amount: fxFeesAmount,
    } = payment;

    const total =
      Number.parseFloat(amountBilled) + Number.parseFloat(fxFeesAmount);
    const totalFormatted =
      total < 0
        ? // We want to show refunds with a +
          formatMoney(Math.abs(total), company.currency, true)
        : formatMoney(total, company.currency);

    if (company.currency !== currencyDeclared) {
      const formattedAmountDeclared =
        Number.parseFloat(amountDeclared) < 0
          ? formatMoney(
              Math.abs(Number.parseFloat(amountDeclared)),
              currencyDeclared,
              true,
            )
          : formatMoney(amountDeclared, currencyDeclared);

      return (
        <span className="flex items-center gap-xs">
          <span className="text-neutral-dark body-m">
            {formattedAmountDeclared}
          </span>
          <span className="text-neutral-light">|</span>
          <span className="title-m">{totalFormatted}</span>
        </span>
      );
    }

    return <span className="title-m">{totalFormatted}</span>;
  };

  const renderDate = () => {
    return (
      <span>
        {localeFormat(
          new Date(payment.paid_at || payment.created_at),
          DATE_FORMAT.CUSTOM,
          { dateStyle: 'medium', timeZone: 'UTC' },
        )}
      </span>
    );
  };

  const renderPaymentStatus = () => {
    const { icon, variant, tooltip } = getInvoiceIconFromPayment(payment, t);
    return (
      <Tooltip content={tooltip} triggerAsChild>
        <HighlightIcon name={icon} variant={variant} size="s" />
      </Tooltip>
    );
  };

  function renderAvatar() {
    let supplierName = payment.supplier?.name ?? '';
    if (payment.supplier?.id === 'unknown') {
      supplierName = '';
    }

    if (
      payment.request &&
      ['mileage_allowance', 'per_diem_allowance', 'expense'].includes(
        payment.request.type,
      )
    ) {
      return (
        <Avatar
          text={payment.user?.full_name ?? ''}
          src={payment.user?.avatar ?? ''}
          badgeProps={
            payment.supplier
              ? {
                  text: supplierName,
                  src: getLogoUrlFromName(supplierName),
                }
              : undefined
          }
          className="shrink-0"
          lazyLoad
        />
      );
    }

    return (
      <Avatar
        variant="square"
        text={supplierName}
        src={getLogoUrlFromName(supplierName)}
        fallbackSrc={fallbackSupplierLogoSrc}
        badgeProps={
          payment.user
            ? {
                text: payment.user.full_name,
                src: payment.user.avatar ?? '',
              }
            : undefined
        }
        className="shrink-0"
        lazyLoad
      />
    );
  }

  function getCounterpartyName() {
    if (
      payment.request &&
      ['mileage_allowance', 'per_diem_allowance', 'expense'].includes(
        payment.request.type,
      )
    ) {
      return payment.user?.full_name;
    }

    return payment.supplier?.name ?? t('misc.unknown');
  }

  return (
    <div className="flex min-w-0 items-center gap-s py-xxs">
      {renderAvatar()}
      <div className="min-w-0 flex-grow">
        <div
          className={cx('flex items-center gap-xs text-complementary body-m', {
            'text-neutral-dark': payment.state === 'authorised',
          })}
          id={titleId}
        >
          <EllipsisTooltip
            className="max-w-[50%] shrink-0"
            text={getCounterpartyName()}
          />

          {isTeamsFeatureEnabled && payment.team && (
            <>
              <span>•</span>
              <EllipsisTooltip text={payment.team.name} />
            </>
          )}

          {payment.costCenter && (
            <>
              <span>•</span>
              <EllipsisTooltip text={payment.costCenter.name} />
            </>
          )}

          {renderStatus()}
        </div>

        <div className="flex items-center gap-xs text-neutral-dark body-s">
          <EllipsisTooltip text={renderDescription()} />
          {renderPaymentTypeIcon()}
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-s text-right">
        <div>
          <div
            className={cx('text-complementary title-m', {
              'text-neutral-dark': payment.state === 'authorised',
            })}
          >
            {renderAmount()}
          </div>
          <div className="text-neutral-dark body-s">{renderDate()}</div>
        </div>
        {renderPaymentStatus()}
      </div>
    </div>
  );
};
