import { Button, FormField, Modal, TextInput } from '@dev-spendesk/grapes';
import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type IntegrationStatus } from 'modules/bookkeep/integration/status';
import { routeFor, routes } from 'src/core/constants/routes';

import { type ExpenseAccount } from '../../settings/accounting';
import { useSetExpenseAccountMutation } from '../../settings/integrations/hooks/useSetExpenseAccountMutation';
import { CodeInUseCallout } from '../../settings/integrations/pages/LegacyIntegrationsAccountingPage/components/CodeInUseCallout/CodeInUseCallout';
import styles from '../../settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/ExpenseAccountsSection/ExpenseAccountLocalOnlySection.module.css';
import { ExpenseAccountAdvice } from '../AccountAdvice';

type ModalState = {
  accountName: string;
  accountCode: string;
  inputChanged: boolean;
  accountCurrentlyUsingTheCode?: ExpenseAccount;
};

const ExpenseAccountCodeAlreadyInUse = ({
  account,
}: {
  account: ExpenseAccount;
}) => {
  const companyId = useCompanyId();

  return (
    <CodeInUseCallout
      accountInfo={`${account && account.name} - ${account && account.code}`}
      linkTo={routeFor(routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path, {
        company: companyId,
      })}
      className={styles.formCallout}
    />
  );
};

const defaultState: ModalState = {
  accountName: '',
  accountCode: '',
  inputChanged: false,
};

export const CreateExpenseAccountModal = ({
  integrationStatus,
  isOpen,
  onClose,
  defaultAccountName,
}: {
  integrationStatus: IntegrationStatus;
  isOpen: boolean;
  onClose: (newAccount?: ExpenseAccount) => void;
  defaultAccountName?: string;
}) => {
  const { t } = useTranslation('global');
  const [modalState, setModalState] = useState<ModalState>(defaultState);
  const [setExpenseAccount, setExpenseAccountQueryState, reset] =
    useSetExpenseAccountMutation();

  useEffect(() => {
    if (isOpen && defaultAccountName) {
      setModalState({
        ...modalState,
        accountName: defaultAccountName,
      });
    }
  }, [isOpen, defaultAccountName]);

  const handleSaveNewExpenseAccount = async () => {
    setModalState({ ...modalState, inputChanged: false });

    try {
      const result = await setExpenseAccount({
        name: modalState.accountName,
        code: modalState.accountCode,
        isArchived: false,
      });

      if (
        result.outcome === 'notSet' &&
        result.reason === 'codeAlreadyExists'
      ) {
        setModalState({
          ...modalState,
          inputChanged: false,
          accountCurrentlyUsingTheCode: result.existingAccount,
        });
      }

      if (result.outcome === 'set') {
        handleCloseModal(result.accounts[0]);
      }
    } catch {
      // Nothing to do on error
    }
  };

  const handleCloseModal = (newAccount?: ExpenseAccount) => {
    setModalState(defaultState);
    reset();
    onClose(newAccount);
  };

  const wrongPatternError = useMemo(
    () =>
      setExpenseAccountQueryState.status === 'error' &&
      setExpenseAccountQueryState.error.type === 'RequestError' &&
      setExpenseAccountQueryState.error.data.reason ===
        'integrationValidationFailed',
    [],
  );

  const codeInUseError = useMemo(
    () => !!modalState.accountCurrentlyUsingTheCode && !modalState.inputChanged,
    [modalState.accountCurrentlyUsingTheCode, modalState.inputChanged],
  );

  const isSaveDisabled = useMemo(
    () =>
      modalState.accountName.length < 1 ||
      modalState.accountCode.length < 1 ||
      ((codeInUseError || wrongPatternError) && !modalState.inputChanged),
    [modalState, codeInUseError, wrongPatternError],
  );

  const handleChange =
    (key: 'accountName' | 'accountCode') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setModalState({
        ...modalState,
        [key]: event.target.value,
        inputChanged: true,
      });
    };

  return (
    <Modal
      title={t(
        'bookkeep.integrations.settings.expenseAccountsTable.addExpenseAccount',
      )}
      isOpen={isOpen}
      iconVariant="primary"
      iconName="plus"
      actions={[
        <Button
          key="cancel"
          onClick={() => handleCloseModal()}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="saveChanges"
          onClick={handleSaveNewExpenseAccount}
          text={t('misc.saveChanges')}
          variant="primary"
          isDisabled={isSaveDisabled}
        />,
      ]}
    >
      {isOpen && (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.expenseAccountsTable.accountName',
            )}
            className={styles.formField}
          >
            <TextInput
              value={modalState.accountName}
              onChange={handleChange('accountName')}
            />
          </FormField>

          <FormField
            label={t(
              'bookkeep.integrations.settings.expenseAccountsTable.accountCode',
            )}
            className={styles.formField}
          >
            <TextInput
              isInvalid={
                (wrongPatternError || codeInUseError) &&
                !modalState.inputChanged
              }
              value={modalState.accountCode}
              onChange={handleChange('accountCode')}
            />
          </FormField>
          {!codeInUseError && (
            <ExpenseAccountAdvice
              integrationStatus={integrationStatus}
              showError={wrongPatternError}
              className={styles.formCallout}
            />
          )}
          {codeInUseError &&
            modalState.accountCurrentlyUsingTheCode &&
            !modalState.inputChanged && (
              <ExpenseAccountCodeAlreadyInUse
                account={modalState.accountCurrentlyUsingTheCode}
              />
            )}
        </>
      )}
    </Modal>
  );
};
