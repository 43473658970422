import { NavigationItem, SideNavigation } from '@dev-spendesk/grapes';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { routeFor, routes } from 'src/core/constants/routes';

type Props = {
  companyId: string;
};

type UserNotificationsPageNavLink = {
  id: string;
  text: string;
  url: string;
};

export const UserNotificationsPageNav = ({ companyId }: Props) => {
  const { t } = useTranslation('global');
  const location = useLocation();
  const { isController } = useUserRoles();

  const links: UserNotificationsPageNavLink[] = [
    {
      id: 'user-notifications-general',
      text: t('notifications.generalLinkText'),
      url: routeFor(routes.ACCOUNT_NOTIFICATIONS_GENERAL.path, {
        company: companyId,
      }),
    },
  ];

  if (isController) {
    links.push({
      id: 'user-notifications-control',
      text: t('notifications.controlLinkText'),
      url: routeFor(routes.ACCOUNT_NOTIFICATIONS_CONTROL.path, {
        company: companyId,
      }),
    });
  }

  links.push({
    id: 'user-notifications-usage',
    text: t('notifications.usageLinkText'),
    url: routeFor(routes.ACCOUNT_NOTIFICATIONS_USAGE.path, {
      company: companyId,
    }),
  });

  return (
    <SideNavigation>
      {links.map((link) => {
        return (
          <NavigationItem
            component={NavLink}
            text={link.text}
            isActive={location.pathname === link.url}
            key={link.id}
            to={link.url}
          />
        );
      })}
    </SideNavigation>
  );
};
