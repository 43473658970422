import React from 'react';

import { PaymentsListRow } from './PaymentsListRow';
import { type PaymentToComplete } from '../../paymentToComplete';

type Props = {
  payments: (PaymentToComplete & { routeLink: string })[];
};

export const PaymentsList = ({ payments }: Props) => {
  return (
    <div className="PaymentsList">
      {payments.map((payment) => (
        <PaymentsListRow key={payment.id} payment={payment} />
      ))}
    </div>
  );
};
