import { useQueryError } from 'common/components/QueryError/useQueryError';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';

import { useInvalidateTransferQueries } from './useInvalidateTransferQueries';
import { type TransferId } from '../../models';

type CancelTransferPayload = {
  transferIds: TransferId[];
};

export type CancelTransferErrors =
  | {
      transferId: TransferId;
      reason: 'alreadyConfirmed' | 'alreadyCancelled';
      error?: string;
    }
  | {
      reason: 'forbidden';
    };

export const useCancelTransfers = () => {
  const invalidateTransferQueries = useInvalidateTransferQueries();

  return useMutation<
    CancelTransferPayload,
    object,
    object,
    CancelTransferErrors
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/transfer-scheduling/transfers/cancellation`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: (): void => {
        invalidateTransferQueries();
      },
    },
    reshapeData: (data) => data,
  });
};

type TranslationsParams = {
  count: number;
};

export const useCancelTransferErrorMessage = () => {
  const getErrorMessage = useQueryError<
    CancelTransferErrors,
    TranslationsParams
  >({
    requestError: ({ reason }) => {
      switch (reason) {
        case 'alreadyConfirmed':
          return 'invoices.transfer.cancel.alreadyConfirmedError';

        case 'alreadyCancelled':
          return 'invoices.transfer.cancel.alreadyCancelledError';

        case 'forbidden':
          return 'invoices.transfer.cancel.forbiddenError';

        default:
          return 'invoices.transfer.cancel.error';
      }
    },
    serverError: 'invoices.transfer.cancel.error',
  });

  return (
    queryError: QueryError<CancelTransferErrors>,
    translationsParams: TranslationsParams,
  ): string => getErrorMessage(queryError, translationsParams);
};
