import identity from 'lodash/identity';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { type CurrencyRates } from 'src/core/config/money';

type CurrencyRatesQueryPayload = {
  currency: string;
  date: Date;
};

export type CurrencyRatesQueryError = {
  reason: 'invalidPayload';
};

export const useCurrencyRatesQuery = (
  payload: CurrencyRatesQueryPayload,
): QueryState<CurrencyRates, CurrencyRatesQueryError> => {
  return useQuery<CurrencyRates, CurrencyRates, CurrencyRatesQueryError>({
    key: ['currency-rates', payload],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/currency-rates`,
      params: payload,
    },
    reshapeData: identity,
    options: {
      staleTime: 5 * 60 * 1000,
    },
  });
};
