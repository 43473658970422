import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type IntegrationFailedSettlement,
  type Settlement,
} from 'modules/bookkeep/export/pages/IntegrationExportPage/settlement';
import { useQuery } from 'src/core/api/hooks/useQuery';

import { getFailedExportSettlementsQueryKey } from './query-key-registry';

/**
 * Query and cache config
 */

export type FailedExportSettlementsRawResponse = {
  settlement: Settlement;
  error: {
    reason: string;
    errorData?: {
      errorMessage?: string;
    };
    rawError?: {
      errorMessage?: string;
    };
  };
}[];

export type FailedExportSettlementsResponse = IntegrationFailedSettlement[];

export const useUpdateFailedExportSettlementsQueryCache = () => {
  const queryClient = useQueryClient();

  return async (
    companyId: string,
    settlements: FailedExportSettlementsResponse,
  ): Promise<void> => {
    const queryKey = getFailedExportSettlementsQueryKey(companyId);
    queryClient.setQueryData(queryKey, settlements);
  };
};

export const useInvalidateFailedExportSettlementsQueryCache = () => {
  const queryClient = useQueryClient();

  return async (companyId: string): Promise<void> => {
    await queryClient.invalidateQueries(
      getFailedExportSettlementsQueryKey(companyId),
    );
  };
};

/**
 * REST query hook
 */

export const useFailedExportSettlements = (isEnabled = true) => {
  const companyId = useCompanyId();

  return useQuery<
    FailedExportSettlementsResponse,
    FailedExportSettlementsRawResponse
  >({
    isEnabled,
    key: getFailedExportSettlementsQueryKey(companyId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/export/failed-settlements',
    },
    reshapeData: (rawFailedSettlements) =>
      rawFailedSettlements.map((rawFailedSettlement) => {
        let errorData: undefined | { errorMessage: string };

        if (rawFailedSettlement?.error?.rawError?.errorMessage) {
          errorData = {
            errorMessage: rawFailedSettlement.error.rawError.errorMessage,
          };
        } else if (rawFailedSettlement?.error.errorData?.errorMessage) {
          errorData = {
            errorMessage: rawFailedSettlement?.error.errorData?.errorMessage,
          };
        }

        return {
          settlement: rawFailedSettlement.settlement,
          error: {
            reason: rawFailedSettlement.error.reason,
            rawError: rawFailedSettlement.error.rawError,
            errorData,
          },
        };
      }),
  });
};
