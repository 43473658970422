import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type Notification } from '../../../../models';

type RawNotification = {
  checked: boolean;
  notif_code: string;
  value: string | null;
};

export const useGetNotificationsQuery = (): QueryState<Notification[]> => {
  const companyId = useCompanyId();

  return useQuery<Notification[], RawNotification[]>({
    key: ['notifications', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/notifications',
    },
    reshapeData: (rawNotifications) => {
      return rawNotifications.map((rawNotification) => ({
        notifCode: rawNotification.notif_code,
        checked: rawNotification.checked,
        value: rawNotification.value,
      }));
    },
  });
};
