// external
import React from 'react';
import { Link } from 'react-router-dom';

// components
import { TopNotif } from 'modules/app/components/TopNotif/TopNotif';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
// utils
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
// hooks & selectors

import {
  type DebitCardMigrationInfo,
  useDebitCardMigrationInfoQuery,
} from '../../hooks/useDebitCardMigrationInfoQuery';

// assets
import './DebitCardMigrationNotification.css';

const RenewMySubscriptionsLink = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  return (
    <a
      className="DebitCardMigrationNotification__link"
      href={routeFor(routes.SUBSCRIPTIONS.path, { company: companyId })}
      onClick={() => {
        track(AnalyticEventName.RECARD_BANNER_CLICKED, {
          recard: 'subscription',
        });
      }}
    >
      {t('debitCardMigrationNotification.renewMySubscriptions')}
    </a>
  );
};

const RenewMyPlasticCardLink = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  return (
    <a
      className="DebitCardMigrationNotification__link"
      href={routeFor(routes.CARD.path, { company: companyId })}
      onClick={() => {
        track(AnalyticEventName.RECARD_BANNER_CLICKED, {
          recard: 'physical',
        });
      }}
    >
      {t('debitCardMigrationNotification.renewMyPlasticCard')}
    </a>
  );
};

const RenewPlasticCardsLinkAdmin = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  return (
    <Link
      className="DebitCardMigrationNotification__link"
      to={`${routeFor(routes.CARDS.path, { company: companyId })}?status=REC`}
      onClick={() => {
        track(AnalyticEventName.RECARD_BANNER_CLICKED, {
          recard: 'physical',
        });
      }}
    >
      {t('debitCardMigrationNotification.renewPlasticCardsAdmin')}
    </Link>
  );
};

const SubscriptionsAndPlasticCardMigration = () => {
  const { t } = useTranslation('global');
  return (
    <>
      {t('debitCardMigrationNotification.renewSubscriptionsAndPlasticCard')}
      {': '}
      <RenewMySubscriptionsLink />
      {` ${t('misc.and')} `}
      <RenewMyPlasticCardLink />
    </>
  );
};

const SubscriptionsMigration = () => {
  const { t } = useTranslation('global');
  return (
    <>
      {t('debitCardMigrationNotification.renewSubscriptionCards')}
      {': '}
      <RenewMySubscriptionsLink />
    </>
  );
};

const PlasticCardMigration = () => {
  const { t } = useTranslation('global');
  return (
    <>
      {t('debitCardMigrationNotification.renewPlasticCard')}
      {': '}
      <RenewMyPlasticCardLink />
    </>
  );
};

const PlasticCardMigrationForAdmin = () => {
  const { t } = useTranslation('global');
  return (
    <>
      {t('debitCardMigrationNotification.renewPlasticCardAdmin')}
      {': '}
      <RenewPlasticCardsLinkAdmin />
    </>
  );
};

const getNotificationContent = ({
  subscriptionCardsExistToMigrate,
  plasticCardsExistToMigrate,
  isAdminAndCompanyhasPhysicalCardsToRecard,
}: DebitCardMigrationInfo) => {
  if (subscriptionCardsExistToMigrate && plasticCardsExistToMigrate) {
    return <SubscriptionsAndPlasticCardMigration />;
  }

  if (subscriptionCardsExistToMigrate) {
    return <SubscriptionsMigration />;
  }

  if (plasticCardsExistToMigrate) {
    return <PlasticCardMigration />;
  }

  if (isAdminAndCompanyhasPhysicalCardsToRecard) {
    return <PlasticCardMigrationForAdmin />;
  }

  return null;
};

export const DebitCardMigrationNotification = () => {
  const debitCardMigrationInfoQueryState = useDebitCardMigrationInfoQuery();

  if (debitCardMigrationInfoQueryState.status === 'success') {
    const { data } = debitCardMigrationInfoQueryState;

    const notificationContent = getNotificationContent(data);

    return (
      notificationContent && (
        <section className="DebitCardMigrationNotification">
          <TopNotif type="warning">{notificationContent}</TopNotif>
        </section>
      )
    );
  }

  return null;
};
