import { createReducer } from '@reduxjs/toolkit';

import * as types from './actionTypes';

type NewInvoiceState = {
  invoiceRequest: { [key: string]: unknown };
  isUploadingInvoice: boolean;
};

export const initialState: NewInvoiceState = {
  invoiceRequest: {},
  isUploadingInvoice: false,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(types.NUKE_NEW_INVOICE_STATE, () => initialState)
    .addCase(types.SUBMIT_DRAFT_INVOICE_REQUEST_LOADING, (state) => {
      state.isUploadingInvoice = true;
    })
    .addCase(types.SUBMIT_DRAFT_INVOICE_REQUEST_SUCCESS, (state) => {
      state.isUploadingInvoice = false;
    })
    .addCase(types.SUBMIT_DRAFT_INVOICE_REQUEST_FAILURE, (state) => {
      state.isUploadingInvoice = false;
    });
});
