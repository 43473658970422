import React from 'react';

import { CustomFieldValuesListContainer } from '../../containers/CustomFieldValuesListContainer';
import { type CustomField } from '../../models/customField';
import { BooleanCustomField } from '../fields/BooleanCustomField';

import './CustomFields.css';

type Props = {
  customFields: CustomField[];
  groups: { id: string; name: string }[];
};

export const CustomFields = ({ customFields, groups }: Props) => {
  return (
    <div id="custom-fields">
      {customFields.map((field) => {
        if (field.type === 'list') {
          return (
            <CustomFieldValuesListContainer
              key={field.id}
              customField={field}
            />
          );
        }
        if (field.type === 'boolean') {
          return (
            <BooleanCustomField
              key={field.id}
              customField={field}
              groups={groups}
            />
          );
        }
        return null;
      })}
    </div>
  );
};
