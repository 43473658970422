import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

export const useActivateKomboIntegrationMutation = (): MutationState<{
  token: string;
}> => {
  return useMutation<{
    token: string;
  }>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: `/integrations/kombo/activate-integration`,
    },
    options: {
      async onSuccess({ client }) {
        await client.invalidateQueries('integrationsStatus');
      },
    },
  });
};
