import { useParams } from 'react-router-dom';

import { routeBuilder } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import history from 'src/core/history';

import { type RequestId } from '../models';
import { type SubnavigationItem } from '../utils';

type Params =
  | {
      requestId?: RequestId;
      type?: SubnavigationItem;
      newRequestType?: never;
    }
  | {
      requestId: 'new';
      newRequestType?:
        | 'purchase-order'
        | 'virtual'
        | 'invoice'
        | 'reimbursement';
      type?: SubnavigationItem;
    };

export const useGotoRequests = () => {
  const getRequestsRoute = useRequestsRoute();

  return (params: Params = {}): void => {
    history.push(getRequestsRoute(params));
  };
};

const useRequestsRoute = () => {
  const companyId = useCompanyId();
  const { type: typeFromUrlParams } = useParams();

  return (params: Params = {}): string =>
    getRequestsRoute(companyId, {
      ...params,
      type: params.type ?? typeFromUrlParams,
    });
};

const getRequestsRoute = (
  companyId: string,
  { requestId, newRequestType, type }: Params & Required<Pick<Params, 'type'>>,
): string => {
  return routeBuilder('REQUESTS', {
    company: companyId,
    ...(requestId ? { id: requestId } : {}),
    ...(newRequestType ? { newRequestType } : {}),
    type,
  });
};
