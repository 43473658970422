import type {
  I18nKey,
  TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';

import { type Day } from '../../../card';

const dayToTranslationKey: { [day in Day]: I18nKey } = {
  mon: 'cards.controlSettings.sections.schedule.monday',
  tue: 'cards.controlSettings.sections.schedule.tuesday',
  wed: 'cards.controlSettings.sections.schedule.wednesday',
  thu: 'cards.controlSettings.sections.schedule.thursday',
  fri: 'cards.controlSettings.sections.schedule.friday',
  sat: 'cards.controlSettings.sections.schedule.saturday',
  sun: 'cards.controlSettings.sections.schedule.sunday',
};

const formatDay = (day: Day, translator: TGlobalFunctionTyped) => {
  return translator(dayToTranslationKey[day]);
};

export const formatDays = (days: Day[], translator: TGlobalFunctionTyped) => {
  if (days.length === 0) {
    return '';
  }
  if (days.length > 1) {
    const formattedDays = days
      .slice(0, -1)
      .map((day) => formatDay(day, translator))
      .join(', ');
    const formattedLastDay = formatDay(days.at(-1) as Day, translator);
    return `${formattedDays} & ${formattedLastDay}`;
  }
  return formatDay(days[0], translator);
};

export const formatHour = (hour: number | undefined): string => {
  if (hour === undefined || hour > 24) {
    return 'Invalid date';
  }
  if (hour < 0) {
    return formatHour(-hour);
  }
  return `${String(hour % 24).padStart(2, '0')}:00`;
};

const daysEnum = new Map([
  ['mon', 0],
  ['tue', 1],
  ['wed', 2],
  ['thu', 3],
  ['fri', 4],
  ['sat', 5],
  ['sun', 6],
]);

const isConsecutive = (days: number[]) => {
  let isCon = true;
  days.forEach((d, index) => {
    if (index !== 0 && !(d === days[index - 1] + 1)) {
      isCon = false;
    }
  });
  return isCon;
};

const keyToDayTranslation = (key: string): I18nKey => {
  switch (key) {
    case 'mon':
      return 'misc.days.mon';
    case 'tue':
      return 'misc.days.tue';
    case 'wed':
      return 'misc.days.wed';
    case 'thu':
      return 'misc.days.thu';
    case 'fri':
      return 'misc.days.fri';
    case 'sat':
      return 'misc.days.sat';
    case 'sun':
      return 'misc.days.sun';
    default:
      return 'misc.unknown';
  }
};

const keyToTranslation = (key: string): I18nKey => {
  switch (key) {
    case 'all':
      return 'cards.controlSettings.sections.schedule.all';
    case 'weekend':
      return 'cards.controlSettings.sections.schedule.weekend';
    case 'weekdays':
      return 'cards.controlSettings.sections.schedule.weekdays';
    case 'from':
      return 'cards.controlSettings.sections.schedule.from';
    case 'to':
      return 'cards.controlSettings.sections.schedule.to';
    default:
      return 'misc.unknown';
  }
};

export const daysToString = (
  days: string[],
  translator: TGlobalFunctionTyped,
): string => {
  const convertedToEnumDays: number[] = [];
  days.forEach((day) => {
    const dayIndex = daysEnum.get(day);

    if (dayIndex !== undefined) {
      convertedToEnumDays.push(dayIndex);
    }
  });

  if (isConsecutive(convertedToEnumDays)) {
    if (convertedToEnumDays.length === 7) {
      return translator(keyToTranslation('all'));
    }
    if (convertedToEnumDays.length === 2 && convertedToEnumDays[0] === 5) {
      // checks if first day is Saturday
      return translator(keyToTranslation('weekend'));
    }
    if (convertedToEnumDays.length === 5 && convertedToEnumDays[0] === 0) {
      // checks if first day is Monday
      return translator(keyToTranslation('weekdays'));
    }

    const from = `${translator(keyToTranslation('from'))} ${translator(
      keyToDayTranslation(days[0]),
    )} `;
    const to = `${translator(keyToTranslation('to'))} ${translator(
      keyToDayTranslation(days.at(-1) as string),
    )}`;
    return from + to;
  }

  return days
    .map((d) => translator(keyToDayTranslation(d)))
    .join(', ')
    .replace(/, ([^,]*)$/, ' & $1'); // replaces the last "," with "&"
};
