import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { type RawRole, type Role } from '../models/roles';
import { reshapeRole } from '../utils/reshapeRole';

export const useRolesQuery = (): QueryState<Role[], object> => {
  const companyId = useCompanyId();
  return useQuery<Role[], RawRole[]>({
    key: ['companies', companyId, 'roles'],
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/companies/${companyId}/roles`,
    },
    reshapeData: (data) => data.map(reshapeRole),
  });
};
