import { NavigationItem } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import appConfig from 'src/core/config';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  className?: string;
  isInDropdown?: boolean;
};

export const ProcurementLink = ({ className, isInDropdown }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavigationItem
      data-marketing="top-menu_procurement"
      key="nav_procurement"
      className={className}
      variant={isInDropdown ? 'subNavigation' : 'mainNavigation'}
      onClick={() => {
        track(AnalyticEventName.HEADER_PROCUREMENT_BUTTON_CLICKED);
      }}
      text={t('misc.procurementTitle')}
      isActive={false}
      component="a"
      href={appConfig.procurementBaseUrl}
    />
  );
};
