import { Button, IconButton, Tooltip } from '@dev-spendesk/grapes';

import { SearchFilter } from 'common/components/SearchFilter';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useSeatLimit } from 'modules/members/hooks/useSeatLimit';
import { apiUrl } from 'src/core/utils/api';

import { useCurrentMember } from '../../hooks';

import './MembersTableHeader.css';

type Props = {
  canInviteMembers: boolean;
  membersCount?: number;
  selectedMembersCount: number;
  searchPattern: string;
  setSearchPattern(search: string): void;
  onInviteClick(): void;
  onEditMembersClick(): void;
};
export function MembersTableHeader({
  canInviteMembers,
  membersCount,
  selectedMembersCount,
  searchPattern,
  setSearchPattern,
  onInviteClick,
  onEditMembersClick,
}: Props) {
  const company = useCompany();
  const currentMember = useCurrentMember();
  const { t } = useTranslation('global');
  const seatLimit = useSeatLimit();

  return (
    <div className="MembersTableHeader">
      <div className="MembersTableHeader__countContainer">
        <div className="MembersTableHeader__countContainer_title title-xl">
          {t('members.table.title')}
        </div>
        {membersCount && (
          <div className="MembersTableHeader__countContainer_count body-m">
            {t('members.table.count', { count: membersCount })}
          </div>
        )}
      </div>
      <SearchFilter
        placeholder={t('members.table.searchPlaceholder')}
        minLength={0}
        className="mr-s"
        search={searchPattern}
        setSearch={(search) => {
          setSearchPattern(search);
        }}
      />
      <div className="MembersTableHeader__actions">
        {selectedMembersCount > 0 && (
          <>
            <Button
              text={t('members.table.bulkEditButton', {
                count: selectedMembersCount,
              })}
              variant="contrasted"
              aria-label="Edit members button"
              onClick={onEditMembersClick}
            />
            {/* not implemented
            {selectedTab === 'active' ? (
              <Tooltip
                maxWidth={248}
                content={t('members.table.bulkDeleteButton', {
                  count: selectedMembersCount,
                  companyName: company.name,
                })}
                triggerAsChild
              >
                <IconButton
                  iconName="minus-circle"
                  variant="border"
                  onClick={onDeleteMembersClick}
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip
                  maxWidth={248}
                  content={t('members.table.bulkResendInviteButton', {
                    count: selectedMembersCount,
                  })}
                  triggerAsChild
                >
                  <IconButton
                    iconName="bell"
                    variant="border"
                    onClick={onResendInviteMembersClick}
                  />
                </Tooltip>
                <Tooltip
                  maxWidth={248}
                  content={t('members.table.bulkRevokeButton', {
                    count: selectedMembersCount,
                  })}
                  triggerAsChild
                >
                  <IconButton
                    iconName="minus-circle"
                    variant="border"
                    onClick={onDeleteMembersClick}
                  />
                </Tooltip>
              </>
            )} */}
          </>
        )}
        {selectedMembersCount === 0 && (
          <>
            <Tooltip
              isDisabled={canInviteMembers}
              maxWidth={248}
              content={t('members.seatsAlreadyUsedToolTip', {
                count: seatLimit,
              })}
            >
              <Button
                text={t('members.table.inviteMemberButton')}
                variant="primary"
                isDisabled={!canInviteMembers}
                onClick={onInviteClick}
              />
            </Tooltip>
            {(currentMember.isAccountOwner || currentMember.isAdmin) && (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={apiUrl('/users/export', company.id)}
                aria-label={t('members.downloadMembersList')}
              >
                <Tooltip
                  content={t('members.downloadMembersList')}
                  triggerAsChild
                >
                  <IconButton
                    iconName="download"
                    variant="border"
                    aria-hidden="true"
                  />
                </Tooltip>
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
}
