import { Button, Callout, Modal, TextArea } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type DeclarationData } from './declarationData';

type Props = Readonly<{
  onCancel: () => void;
  onBack: () => void;
  onContinue: () => void;
}>;

export const AffidavitReasonModal = ({
  onCancel,
  onBack,
  onContinue,
  // formik props
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
  dirty,
}: Props & FormikProps<DeclarationData>) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      onClose={onCancel}
      iconName="receipt-question-mark"
      title={t('payments.affidavitModal.reasonModal.title')}
      actions={[
        <Button
          key="back"
          variant="secondary"
          text={t('payments.affidavitModal.reasonModal.backButton')}
          onClick={onBack}
        />,
        <Button
          key="continue"
          text={t('payments.affidavitModal.reasonModal.continueButton')}
          onClick={onContinue}
          isDisabled={Boolean(errors.reason || !dirty)}
        />,
      ]}
    >
      <TextArea
        rows={5}
        name="reason"
        value={values.reason}
        placeholder={t('payments.affidavitModal.reasonModal.inputPlaceholder')}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {errors.reason && touched.reason && (
        <Callout title={errors.reason} variant="alert" />
      )}
    </Modal>
  );
};
