import { type AnalyticalFieldMapping } from 'modules/bookkeep/settings/accounting';
import { type QueryState } from 'src/core/api/queryState';

import { useAnalyticalFieldMappingsQuery } from '../../../../../hooks/useAnalyticalFieldMappingsQuery';
import {
  useCustomFieldsQuery,
  type CustomField,
} from '../../../../../hooks/useCustomFieldsQuery';

type CombinedQueryState = {
  customFields: CustomField[];
  analyticalFieldMapping: AnalyticalFieldMapping[];
};

function getCombinedQueryState({
  customFieldsQueryState,
  analyticalCodeMappingQueryState,
}: {
  customFieldsQueryState: QueryState<CustomField[], unknown>;
  analyticalCodeMappingQueryState: QueryState<
    AnalyticalFieldMapping[],
    unknown
  >;
}): QueryState<CombinedQueryState, unknown> {
  if (
    customFieldsQueryState.status === 'loading' ||
    analyticalCodeMappingQueryState.status === 'loading'
  ) {
    return { status: 'loading' };
  }

  if (customFieldsQueryState.status === 'error') {
    return { status: 'error', error: customFieldsQueryState.error };
  }

  if (analyticalCodeMappingQueryState.status === 'error') {
    return { status: 'error', error: analyticalCodeMappingQueryState.error };
  }

  return {
    status: 'success',
    data: {
      customFields: customFieldsQueryState.data,
      analyticalFieldMapping: analyticalCodeMappingQueryState.data,
    },
  };
}

export function useCustomFieldAndAnalyticalFieldQueries(): QueryState<
  CombinedQueryState,
  unknown
> {
  const customFieldsQueryState = useCustomFieldsQuery();
  const analyticalCodeMappingQueryState = useAnalyticalFieldMappingsQuery();

  return getCombinedQueryState({
    customFieldsQueryState,
    analyticalCodeMappingQueryState,
  });
}
