import { Button } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

import arrowPath from './assets/arrow.svg';
import feature1Path from './assets/feature-1.png';
import feature2Path from './assets/feature-2.png';
import feature3Path from './assets/feature-3.png';

import './NoAccountingIntegrationFeedback.css';

export function NoAccountingIntegrationFeedback() {
  const { t } = useTranslation('global');

  const companyId = useCompanyId();

  const history = useHistory();

  const goToAccountingIntegrationSettings = () =>
    history.push(
      routeFor(routes.COMPANY_ACCOUNTING.path, { company: companyId }),
    );

  return (
    <div className="NoAccountingIntegrationFeedback">
      <div className="NoAccountingIntegrationFeedback__container">
        <h1 className="NoAccountingIntegrationFeedback__title title-xl">
          {t('accounting-integration.noAccountingIntegration.title')}
        </h1>

        <p className="NoAccountingIntegrationFeedback__content body-m">
          {t('accounting-integration.noAccountingIntegration.content')}
        </p>

        <div className="NoAccountingIntegrationFeedback__features">
          <div>
            <img alt="first feature" src={feature1Path} width={250} />
            <p className="NoAccountingIntegrationFeedback__features-content body-m">
              {t('accounting-integration.noAccountingIntegration.feature1')}
            </p>
          </div>

          <img
            alt="arrow"
            className="NoAccountingIntegrationFeedback__features-arrow"
            src={arrowPath}
          />

          <div>
            <img alt="second feature" src={feature2Path} width={250} />
            <p className="NoAccountingIntegrationFeedback__features-content body-m">
              {t('accounting-integration.noAccountingIntegration.feature2')}
            </p>
          </div>

          <img
            alt="arrow"
            className="NoAccountingIntegrationFeedback__features-arrow"
            src={arrowPath}
          />

          <div>
            <img alt="third feature" src={feature3Path} width={250} />
            <p className="NoAccountingIntegrationFeedback__features-content body-m">
              {t('accounting-integration.noAccountingIntegration.feature3')}
            </p>
          </div>
        </div>

        <Button
          className="NoAccountingIntegrationFeedback__features-cta"
          variant="primary"
          text={t('accounting-integration.noAccountingIntegration.cta')}
          onClick={goToAccountingIntegrationSettings}
        />
      </div>
    </div>
  );
}
