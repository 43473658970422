import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocalStorage } from 'react-use';

import { type QueryState } from 'src/core/api/queryState';
import { type CurrenciesKey, currencyOptions } from 'src/core/config/money';
import { LocalStorageKey } from 'src/core/constants/storage';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { OrganisationReportingEntityFilters } from './OrganisationReportingEntityFilters/OrganisationReportingEntityFilters';
import { OrganisationReportingEntityListEmptyState } from './OrganisationReportingEntityListEmptyState';
import { OrganisationReportingEntityListErrorCallout } from './OrganisationReportingEntityListErrorCallout';
import { OrganisationReportingEntityListFilter } from './OrganisationReportingEntityListFilter';
import { OrganisationReportingEntityListSearch } from './OrganisationReportingEntityListSearch';
import { type OrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import {
  isExtendedOrganisationReportingEntity,
  type ExtendedOrganisationReportingEntity,
  type OrganisationReportingEntity,
} from '../../../types';
import { OrganisationReportingGroupSection } from '../../OrganisationReportingGroupSection/OrganisationReportingGroupSection';
import { OrganisationReportingEntityStatistics } from '../OrganisationReportingEntityStatistics';

export const OrganisationReportingEntityList = ({
  features,
  entities,
  onDataLoaded,
}: {
  features: OrganisationFeatures;
  entities: OrganisationReportingEntity[];
  onDataLoaded: () => void;
}) => {
  const extendedEntities: (
    | OrganisationReportingEntity
    | ExtendedOrganisationReportingEntity
  )[] = entities;

  const [isFilteringEnabled, setIsFilteringEnabled] = useState(false);
  const orgCurrencies = [
    ...new Set(extendedEntities.map(({ currency }) => currency)),
  ];
  const orgCurrencyOptions = currencyOptions.filter(({ key }) =>
    orgCurrencies.includes(key as CurrenciesKey),
  ) as { key: CurrenciesKey; label: string }[];

  const [queryStates, setQueryStates] = useState<
    { entityId: string; status: QueryState['status'] }[]
  >([]);

  const entityIds = entities.map(({ id }) => id);
  useEffect(() => {
    const hasAllQueriesSucceeded = entityIds.every((id) =>
      queryStates.find(({ entityId }) => id === entityId),
    );

    if (hasAllQueriesSucceeded) {
      setIsFilteringEnabled(true);
      onDataLoaded();
      return () => {};
    }
  }, [queryStates]);

  const onEntityDataLoaded = (
    entityData:
      | OrganisationReportingEntity
      | ExtendedOrganisationReportingEntity,
    status: QueryState['status'],
  ) => {
    setQueryStates((previousQueryStates) => [
      ...previousQueryStates,
      { entityId: entityData.id, status },
    ]);

    if (status === 'success') {
      const entityIndex = extendedEntities.findIndex(
        ({ id }) => id === entityData.id,
      );
      if (entityIndex !== -1) {
        extendedEntities[entityIndex] = {
          ...extendedEntities[entityIndex],
          ...entityData,
        };
      }
    }
  };

  const [pinnedEntitiesFromStorage, setPinnedEntitiesFromStorage] =
    useLocalStorage<string[]>(
      LocalStorageKey.OrganisationReportingPinnedEntities,
      [],
    );
  const handleTogglePinnedEntity = (entityId: string) => {
    const currentPinnedEntities = pinnedEntitiesFromStorage ?? [];
    const newPinnedEntities = currentPinnedEntities.includes(entityId)
      ? currentPinnedEntities.filter((id) => id !== entityId)
      : [...currentPinnedEntities, entityId];
    setPinnedEntitiesFromStorage(newPinnedEntities);

    track(AnalyticEventName.ORGANISATION_REPORTING_PIN_ENTITY_BUTTON_CLICKED, {
      pinnedEntities: newPinnedEntities.length,
      totalEntities: entities.length,
    });
  };
  const isEntityPinned = ({ id }: OrganisationReportingEntity) =>
    pinnedEntitiesFromStorage?.includes(id) ?? false;

  return (
    <OrganisationReportingEntityListSearch
      entities={extendedEntities}
      searchableFields={['name']}
    >
      {({
        filteredEntities: filteredEntitiesFromSearch,
        search,
        setSearch,
      }) => (
        <OrganisationReportingEntityListFilter
          entities={filteredEntitiesFromSearch}
          sortFn={(a, b) => entitySortFunction(a, b, isEntityPinned)}
        >
          {({
            filteredEntities: filteredEntitiesFromFilters,
            filters,
            setFilters,
          }) => (
            <div className="flex flex-col gap-s">
              <div className="flex flex-col gap-xxs">
                <OrganisationReportingEntityFilters
                  filters={filters}
                  setFilters={setFilters}
                  filtersConfig={{
                    currencyOptions: orgCurrencyOptions,
                  }}
                  search={search}
                  setSearch={setSearch}
                  isFilteringEnabled={isFilteringEnabled}
                />
                <hr className="separator" style={{ borderTop: 'none' }} />
              </div>
              <h2 className="text-neutral-dark title-xl">
                <Trans
                  i18nKey="organisation.reporting.page.titleCount"
                  values={{
                    count: filteredEntitiesFromFilters.length,
                    total: entities.length,
                  }}
                  components={{
                    strong: <strong className="text-complementary">-</strong>,
                  }}
                />
              </h2>

              <OrganisationReportingGroupSection
                isDataFullyLoaded={isFilteringEnabled}
                entityDetails={filteredEntitiesFromFilters.filter(
                  isExtendedOrganisationReportingEntity,
                )}
              />

              <OrganisationReportingEntityListErrorCallout
                entityIdsWithFailure={queryStates
                  .filter(({ status }) => status === 'error')
                  .map(({ entityId }) => entityId)}
                total={entities.length}
              />

              {filteredEntitiesFromFilters.map((entity) => (
                <OrganisationReportingEntityStatistics
                  isPinned={
                    pinnedEntitiesFromStorage?.includes(entity.id) ?? false
                  }
                  togglePinnedEntity={handleTogglePinnedEntity}
                  key={entity.id}
                  entity={entity}
                  features={features}
                  onDataLoaded={onEntityDataLoaded}
                />
              ))}

              {filteredEntitiesFromFilters.length === 0 && (
                <OrganisationReportingEntityListEmptyState />
              )}
            </div>
          )}
        </OrganisationReportingEntityListFilter>
      )}
    </OrganisationReportingEntityListSearch>
  );
};

/**
 * Pinned items come first alphabetically, then the rest sorted alphabetically
 */
const entitySortFunction = (
  reference: OrganisationReportingEntity,
  compare: OrganisationReportingEntity,
  hasPriorityFunction: (entity: OrganisationReportingEntity) => boolean,
) => {
  const isReferencePinned = hasPriorityFunction(reference);
  const isComparePinned = hasPriorityFunction(compare);

  if (isReferencePinned && isComparePinned) {
    return reference.name.localeCompare(compare.name);
  }
  if (isReferencePinned) {
    return -1;
  }
  if (isComparePinned) {
    return 1;
  }
  return reference.name.localeCompare(compare.name);
};
