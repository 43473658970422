import { Avatar } from '@dev-spendesk/grapes';
import React from 'react';

import { AvatarMenuPopover } from 'src/core/common/components/AvatarMenu/AvatarMenuPopover';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import './ProfileEntry.css';

export const ProfileEntry = () => {
  const { t } = useTranslation('global');
  const user = useUser();

  return (
    <AvatarMenuPopover className="popover-hack-align w-full" position="end-top">
      {({ isDropdown, ...triggerProps }) => (
        <button
          {...triggerProps}
          className="navigation-item navigation-item-profile"
          type="button"
        >
          <Avatar
            variant="circle"
            src={user.avatar ?? ''}
            text={user.fullname}
            size="s"
            className="shrink-0"
          />
          <span className="navigation-toggle-display grow">
            {t('profile.profileTitle')}
          </span>
        </button>
      )}
    </AvatarMenuPopover>
  );
};
