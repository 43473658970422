import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { PbrRuleTimeline } from 'modules/company/members/components/ControlRules/components/PbrRuleTimeline';

import './SpenderRulesModal.css';

type Props = {
  incompletePaymentsLimit: number;
  completionDeadline: number;
};

export const SpenderRulesModal = ({
  incompletePaymentsLimit,
  completionDeadline,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <PbrRuleTimeline
      className="SpenderRulesModal__content__timeline"
      content={{
        title1: t('pbrTimeline.homepage.sectionTitle1'),
        desc1: t('pbrTimeline.homepage.sectionDesc1', {
          count: completionDeadline,
        }),
        title2: (
          <Trans
            i18nKey="pbrTimeline.homepage.sectionTitle2"
            count={completionDeadline - 1}
            values={{ count: completionDeadline - 1 }}
            components={[
              <span key="1" />,
              <span
                key="2"
                className="PbrRuleTimeline__desc__row__text__urgent"
              />,
              <span key="3" />,
            ]}
          />
        ),
        desc2: t('pbrTimeline.homepage.sectionDesc2'),
        title3: (
          <Trans
            i18nKey="pbrTimeline.homepage.sectionTitle3"
            count={completionDeadline}
            values={{ count: completionDeadline }}
            components={[
              <span key="1" />,
              <span
                key="2"
                className="PbrRuleTimeline__desc__row__text__overdue"
              />,
            ]}
          />
        ),
        desc3: t('pbrTimeline.homepage.sectionDesc3'),
        title4: (
          <Trans
            i18nKey="pbrTimeline.homepage.sectionTitle4"
            count={incompletePaymentsLimit}
            values={{ count: incompletePaymentsLimit }}
            components={[
              <span key="1" />,
              <span
                key="2"
                className="PbrRuleTimeline__desc__row__text__overdue"
              />,
            ]}
          />
        ),
        desc4: t('pbrTimeline.homepage.sectionDesc4'),
        title5: t('pbrTimeline.homepage.sectionTitle5'),
        desc5: t('pbrTimeline.homepage.sectionDesc5'),
      }}
    />
  );
};
