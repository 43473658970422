import { EmptyState } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';

import { PendingKycEmptyStateWithCta } from './PendingKycEmptyStateWithCta';

export const PendingKycEmptyState = () => {
  const { t } = useTranslation('global');
  const user = useUser();
  const isUserAccountOwner = user.is_account_owner;

  if (isUserAccountOwner) {
    return <PendingKycEmptyStateWithCta />;
  }
  return (
    <EmptyState
      iconName="info"
      title={t('pageEmptyState.pendingKyc.requester.title')}
      subtitle={t('pageEmptyState.pendingKyc.requester.message')}
    />
  );
};
