import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { type UserDetails } from 'common/components/form/models/user';
import {
  useCostCentersQuery,
  useCostCentersQueryPromise,
} from 'modules/budgets/apis';
import { useIsCostCenterFeatureEnabled } from 'modules/budgets/features';
import { type QueryState, reshapeQueryState } from 'src/core/api/queryState';
import { getSelf as getCurrentUser } from 'src/core/selectors/users';

import {
  type CostCenter,
  getIsCostCenterRequired,
  getUserDefaultCostCenter,
} from '../../models/costCenter';

export type CostCenterData = {
  costCenters: CostCenter[];
  defaultCostCenter: CostCenter | null;
  isCostCenterRequired: boolean;
};

type UseCostCenterData =
  | {
      costCenterQueryPromise: () => Promise<CostCenterData>;
      costCentersQueryState: QueryState<CostCenterData>;
      isCostCenterEnabled: true;
    }
  | {
      isCostCenterEnabled: false;
    };

export const useCostCenterData = (): UseCostCenterData => {
  const user = useSelector(getCurrentUser);
  const isCostCenterEnabled = useIsCostCenterFeatureEnabled();
  const reshaper = useCallback(toCostCenterData(user), [
    user,
    toCostCenterData,
  ]);
  const costCentersQueryState = useCostCentersQuery();
  const costCenterQueryPromise = useCostCentersQueryPromise();

  return isCostCenterEnabled
    ? {
        isCostCenterEnabled,
        costCentersQueryState: reshapeQueryState(
          costCentersQueryState,
          reshaper,
        ),

        costCenterQueryPromise: () =>
          // eslint-disable-next-line promise/prefer-await-to-then
          costCenterQueryPromise().then(reshaper),
      }
    : {
        isCostCenterEnabled,
      };
};

const toCostCenterData =
  (user: UserDetails) =>
  (costCenters: CostCenter[]): CostCenterData => ({
    defaultCostCenter: getUserDefaultCostCenter(costCenters, user),
    costCenters,
    isCostCenterRequired: getIsCostCenterRequired(costCenters),
  });
