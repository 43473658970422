import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { GET_DEFAULT_SUPPLIER_ACCOUNTS } from '../../accounting/graphql/queries';
import { type DefaultSupplierAccount } from '../../accounting/supplierAccount';

type RawData = {
  company: {
    chartOfAccounts: {
      supplierAccounts: {
        edges: {
          cursor: string;
          node: {
            generalAccountCode: string;
            isDefault: boolean;
            isArchived: boolean;
            id: string;
          };
        }[];
      };
    };
  };
};

export const useGetDefaultSupplierAccountQuery__deprecated = (): QueryState<
  DefaultSupplierAccount[]
> => {
  const { company: companyId } = useParams();

  return useQuery<DefaultSupplierAccount[], RawData>({
    key: ['accountsPayable', 'getDefaultSupplierAccountsQuery', companyId],
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_DEFAULT_SUPPLIER_ACCOUNTS,
    },
    reshapeData(data) {
      return data.company.chartOfAccounts.supplierAccounts.edges.map(
        ({ node }) => {
          return {
            id: node.id,
            generalAccountCode: node.generalAccountCode,
            name: 'Miscellaneous',
            defaultFor: 'cardSupplier',
          };
        },
      );
    },
  });
};
