import { Icon } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useGetOrganisationEntities } from 'modules/company/billing/components/CompanyPlan/hooks/useGetOrganisationEntities';

import styles from './CompanySwitcherNewBranchButton.module.css';
import { NewBranchButton } from '../NewBranch';

type Props = {
  handleClick: () => void;
};

export const CompanySwitcherNewBranchButton = ({ handleClick }: Props) => {
  const { t } = useTranslation('global');

  const { isAllowedToOpenEntity } = useGetOrganisationEntities();
  if (!isAllowedToOpenEntity) {
    return null;
  }

  return (
    <NewBranchButton>
      <button
        type="button"
        className={styles.CompanySwitcherMenu__button}
        onClick={handleClick}
      >
        <Icon
          className={styles.CompanySwitcherMenu__buttonIcon}
          name="plus-circle"
        />
        {t('wallet.addAccount')}
      </button>
    </NewBranchButton>
  );
};
