import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import set from 'lodash/set';

import { type BucketType } from './buckets';

export type BucketId = BucketType;
export type GroupId = 'COMMON' | string;
export type PayableId = string; // FIXME: should come from the model

export type SelectionGroup = {
  isPartial: boolean;
  shouldAutoSelectChildren: boolean;
  payables?: Record<string, boolean>;
};
type SelectionGroups = { [groupId in GroupId]?: SelectionGroup };

export type SelectionBucket = {
  isPartial: boolean;
  shouldAutoSelectChildren: boolean;
  groups?: SelectionGroups;
  totalSelected?: number;
};
type SelectionBuckets = { [bucketType in BucketId]?: SelectionBucket };

export type Selection = {
  buckets?: SelectionBuckets;
};

export const updateOnBucketSelectChange = (
  selection: Selection,
  bucketId: BucketId,
): Selection => {
  const buckets = cloneDeep(get(selection, 'buckets', {})) as SelectionBuckets;

  // Bucket doesn't exist
  if (!buckets[bucketId]) {
    buckets[bucketId] = {
      isPartial: false,
      shouldAutoSelectChildren: true,
    };

    return { buckets };
  }

  const nextBuckets = omit(buckets, bucketId);
  if (isEmpty(nextBuckets)) {
    return {};
  }
  return { buckets: nextBuckets };
};

export const updateOnGroupSelectChange = (
  selection: Selection,
  bucketId: BucketId,
  groupId: GroupId,
): Selection => {
  const buckets = cloneDeep(get(selection, 'buckets', {})) as SelectionBuckets;
  const bucket = buckets[bucketId];

  // Bucket doesn't exist
  if (!bucket) {
    buckets[bucketId] = {
      isPartial: true,
      shouldAutoSelectChildren: false,
      groups: {
        [groupId]: {
          isPartial: false,
          shouldAutoSelectChildren: true,
        },
      },
    };
    return { buckets };
  }

  // Group doesn't exist
  if (!bucket.groups || !bucket.groups[groupId]) {
    bucket.isPartial = true;

    // If the bucket should auto select children
    if (bucket.shouldAutoSelectChildren) {
      // We unselect the related group
      set(buckets, `${bucketId}.groups[${groupId}]`, {
        isPartial: false,
        shouldAutoSelectChildren: false,
      });
    } else {
      // Otherwise, we select the related group
      set(buckets, `${bucketId}.groups[${groupId}]`, {
        isPartial: false,
        shouldAutoSelectChildren: true,
      });
    }
    return { buckets };
  }

  // Remove group from selected group
  bucket.groups = omit(bucket.groups, groupId);

  // If no group in bucket, mark bucket as not partial
  if (isEmpty(bucket.groups)) {
    bucket.isPartial = false;
    delete bucket.groups;
  }

  // If bucket should not auto select children and no group in bucket
  if (!bucket.shouldAutoSelectChildren && isEmpty(bucket.groups)) {
    // Remove bucket from buckets
    const nextBuckets = omit(buckets, bucketId);
    if (isEmpty(nextBuckets)) {
      return {};
    }
    return { buckets: nextBuckets };
  }

  return { buckets };
};

export const updateOnPayableSelectChange = (
  selection: Selection,
  bucketId: BucketId,
  groupId: GroupId = 'COMMON',
  payableId: string,
): Selection => {
  const buckets = cloneDeep(get(selection, 'buckets', {})) as SelectionBuckets;
  const bucket = buckets[bucketId];

  // Bucket doesn't exist
  if (!bucket) {
    buckets[bucketId] = {
      isPartial: true,
      shouldAutoSelectChildren: false,
      groups: {
        [groupId]: {
          isPartial: true,
          shouldAutoSelectChildren: false,
          payables: {
            [payableId]: true,
          },
        },
      },
    };
    return { buckets };
  }

  const group = bucket.groups?.[groupId];

  // Group doesn't exist
  if (!group) {
    bucket.isPartial = true;
    set(buckets, `${bucketId}.groups[${groupId}]`, {
      isPartial: true,
      shouldAutoSelectChildren: bucket.shouldAutoSelectChildren,
      payables: {
        [payableId]: !bucket.shouldAutoSelectChildren,
      },
    });
    return { buckets };
  }

  // Payment doesn't exist
  if (!group.payables || group.payables[payableId] === undefined) {
    group.isPartial = true;

    // If the group should not auto select
    if (!group.shouldAutoSelectChildren) {
      // We select the related payable
      set(
        buckets,
        `${bucketId}.groups[${groupId}].payables[${payableId}]`,
        true,
      );
    } else {
      // Otherwise, we unselect the related payable
      set(
        buckets,
        `${bucketId}.groups[${groupId}].payables[${payableId}]`,
        false,
      );
    }
    return { buckets };
  }

  // Remove payable from selected payables
  group.payables = omit(group.payables, payableId);

  // If no payable in group, mark group as not partial
  if (isEmpty(group.payables)) {
    group.isPartial = false;
    delete group.payables;
  }

  // If group should not auto select children and no payable in group
  if (
    !group.shouldAutoSelectChildren &&
    isEmpty(group.payables) &&
    !bucket.shouldAutoSelectChildren
  ) {
    // Remove group from groups
    delete bucket.groups?.[groupId];
  }

  if (isEmpty(bucket.groups) && !bucket.shouldAutoSelectChildren) {
    delete buckets[bucketId];
  }

  if (isEmpty(buckets)) {
    return {};
  }

  return { buckets };
};

export const clear = (): Selection => ({});
