import { useAccountingIntegrationStatusQuery } from 'modules/accounting-integration/apis';
import {
  isIntegrationStatusWithIntegration,
  type AccountingSoftware,
} from 'modules/bookkeep/integration/status';

export function useAccountingIntegrationName(): AccountingSoftware | undefined {
  const integrationStatusQuery = useAccountingIntegrationStatusQuery();

  if (
    integrationStatusQuery.status !== 'success' ||
    !isIntegrationStatusWithIntegration(integrationStatusQuery.data)
  ) {
    return;
  }

  return integrationStatusQuery.data.integration;
}
