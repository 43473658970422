import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type ValidateControlRulePayload = {
  name: string;
  completionDeadline: number;
  incompletePaymentsLimit: number;
};

export const useValidateControlRuleMutation =
  (): MutationState<ValidateControlRulePayload> => {
    return useMutation<ValidateControlRulePayload>({
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: '/control-rules/validate',
        method: 'post',
      },
      options: {
        throwOnError: true,
      },
    });
  };
