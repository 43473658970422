import { type ThunkDispatch } from '@reduxjs/toolkit';

import { baseAPI, companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import * as actionTypes from './actionTypes';
import { type UserFactor } from '../wallet';

export const fetchWalletInfosLoading =
  (): actionTypes.FetchWalletInfosLoading => ({
    type: actionTypes.FETCH_WALLET_INFOS_LOADING,
  });

export const fetchWalletInfosFailure =
  (): actionTypes.FetchWalletInfosFailure => ({
    type: actionTypes.FETCH_WALLET_INFOS_FAILURE,
  });

export const fetchWalletInfosSuccess = (
  payload: actionTypes.FetchWalletInfosSuccess['payload'],
): actionTypes.FetchWalletInfosSuccess => ({
  type: actionTypes.FETCH_WALLET_INFOS_SUCCESS,
  payload,
});

export const fetchAccountLoadsLoading =
  (): actionTypes.FetchAccountLoadsLoading => ({
    type: actionTypes.FETCH_ACCOUNT_LOADS_LOADING,
  });

export const fetchAccountLoadsFailure =
  (): actionTypes.FetchAccountLoadsFailure => ({
    type: actionTypes.FETCH_ACCOUNT_LOADS_FAILURE,
  });

export const fetchAccountLoadsSuccess = (
  payload: actionTypes.FetchAccountLoadsSuccess['payload'],
): actionTypes.FetchAccountLoadsSuccess => ({
  type: actionTypes.FETCH_ACCOUNT_LOADS_SUCCESS,
  payload,
});

export const fetchAccountLoading = (): actionTypes.FetchAccountLoading => ({
  type: actionTypes.FETCH_ACCOUNT_LOADING,
});

export const fetchAccountFailure = (): actionTypes.FetchAccountFailure => ({
  type: actionTypes.FETCH_ACCOUNT_FAILURE,
});

export const fetchAccountSuccess = (
  payload: actionTypes.FetchAccountSuccess['payload'],
): actionTypes.FetchAccountSuccess => ({
  type: actionTypes.FETCH_ACCOUNT_SUCCESS,
  payload,
});

export const fetchUserMfaLoading = (): actionTypes.FetchUserMfaLoading => ({
  type: actionTypes.FETCH_USER_MFA_LOADING,
});

export const fetchUserMfaFailure = (): actionTypes.FetchUserMfaFailure => ({
  type: actionTypes.FETCH_USER_MFA_FAILURE,
});

export const fetchUserMfaSuccess = (
  payload: actionTypes.FetchUserMfaSuccess['payload'],
): actionTypes.FetchUserMfaSuccess => ({
  type: actionTypes.FETCH_USER_MFA_SUCCESS,
  payload,
});

export const fetchWalletInfos =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, actionTypes.Actions>,
    getState: () => AppState,
  ): Promise<void> => {
    dispatch(fetchWalletInfosLoading());
    let walletInfos;
    try {
      const companyId = getCompanyId(getState());
      const res = await companyAPI.get(`/wallet-details`, {
        companyId,
      });
      walletInfos = res.data;
    } catch (error) {
      dispatch(fetchWalletInfosFailure());
      throw error;
    }
    dispatch(fetchWalletInfosSuccess({ walletInfos }));
  };

export const fetchAccountLoads =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, actionTypes.Actions>,
    getState: () => AppState,
  ): Promise<void> => {
    const companyId = getCompanyId(getState());

    dispatch(fetchAccountLoadsLoading());

    let loads;
    try {
      const res = await companyAPI.get('/accounts/loads', {
        companyId,
      });
      loads = res.data;
    } catch (error) {
      dispatch(fetchAccountLoadsFailure());
      throw error;
    }

    dispatch(
      fetchAccountLoadsSuccess({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        loads: loads.map((rawAccountLoad: any) => ({
          amount: rawAccountLoad.amount,
          balanceAfter: rawAccountLoad.balance_after,
          currency: rawAccountLoad.currency,
          entryDateTime: rawAccountLoad.entry_date_time,
          loadType: rawAccountLoad.load_type,
          pending: rawAccountLoad.pending,
          userName: rawAccountLoad.userName,
        })),
      }),
    );
  };

export const fetchAccount =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, actionTypes.Actions>,
    getState: () => AppState,
  ): Promise<void> => {
    const companyId = getCompanyId(getState());

    dispatch(fetchAccountLoading());

    let account;
    try {
      const res = await companyAPI.get('/accounts', {
        companyId,
      });
      account = res.data;
    } catch (error) {
      dispatch(fetchAccountFailure());
      throw error;
    }

    dispatch(fetchAccountSuccess({ account }));
  };

export const fetchUserFactors =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, actionTypes.Actions>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => AppState,
  ): Promise<void> => {
    dispatch(fetchUserMfaLoading());

    let phoneUserFactor: UserFactor | undefined;
    let userFactors: UserFactor[];
    try {
      const res = await baseAPI.get('/factors');
      userFactors = res.data;
    } catch (error) {
      dispatch(fetchUserMfaFailure());
      throw error;
    }
    const phoneUserFactors: UserFactor[] = userFactors.filter(
      (factor) => factor.type === 'phone' && factor.state === 'active',
    );
    if (phoneUserFactors.length) {
      phoneUserFactor = phoneUserFactors[0];
    } else {
      phoneUserFactor = undefined;
    }

    dispatch(fetchUserMfaSuccess({ phoneUserFactor }));
  };
