import { type QueryKey } from 'react-query';

export const getAccountingIntegrationStatusQueryKey = (
  companyId: string,
): QueryKey => {
  return ['useIntegrationStatusQuery', companyId];
};

export const getAvailableExportTemplateQueryKey = (
  companyId: string,
): QueryKey => {
  return ['useAvailableExportTemplatesQuery', companyId];
};

export const getAmortisationSchemesQueryKey = (companyId: string): QueryKey => {
  return ['useAmortisationSchemesQuery', companyId];
};
