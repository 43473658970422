import { useQueryClient } from 'react-query';

import { getFetcher } from 'src/core/api/fetcher';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { type ConnectionStatus } from '../../../integration/status';

export const useRefreshServerStatusFetcher = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const refreshServerStatus = () =>
    getFetcher<ConnectionStatus>({
      companyId,
      getRequest: () => ({
        type: 'rest',
        method: 'get',
        target: 'companyAPI',
        endpoint: '/accounting-integration/refresh-server-status',
      }),
    });

  return async () => {
    await queryClient.fetchQuery(
      ['useRefreshServerStatusFetcher'],
      refreshServerStatus(),
    );

    queryClient.resetQueries('useIntegrationStatusQuery');
  };
};
