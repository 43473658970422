import { startOfDay, endOfDay, format } from 'date-fns';

import {
  convertCustomFieldFiltersToPayload,
  type CustomFieldFiltersType,
} from 'src/core/modules/custom-fields';

export type Filters = {
  minAmount?: number | null;
  maxAmount?: number | null;
  fromPeriod?: Date;
  toPeriod?: Date;
  requester?: { key: string; label: string };
  supplier?: { key: string; label: string; thumbnailUrl?: string };
  statuses?: { key: string; label: string }[];
  types?: { key: string; label: string }[];
  costCenters?: { key: string; label: string }[];
  teams?: { key: string; label: string }[];
  customFields?: CustomFieldFiltersType;
};

export function convertFiltersToPayload(filters: Filters): {
  requester: string | undefined;
  supplier: string | undefined;
  status: string[] | undefined;
  type: string[] | undefined;
  costCenter: string[] | undefined;
  team: string[] | undefined;
  period?: string;
  amount?: string;
  custom_fields?: { [key in string]: string | null };
} {
  const customFields = convertCustomFieldFiltersToPayload(filters.customFields);

  const period =
    filters.fromPeriod && filters.toPeriod
      ? {
          period: `${format(
            startOfDay(filters.fromPeriod),
            "yyyy-MM-dd'T'HH:mm:ss'Z'",
          )}/${format(endOfDay(filters.toPeriod), "yyyy-MM-dd'T'HH:mm:ss'Z'")}`,
        }
      : {};

  const amount =
    (!Number.isNaN(filters.minAmount) && filters.minAmount !== undefined) ||
    (!Number.isNaN(filters.maxAmount) && filters.maxAmount !== undefined)
      ? { amount: `${filters.minAmount}/${filters.maxAmount}` }
      : {};

  return {
    requester: filters.requester?.key,
    supplier: filters.supplier?.key,
    status: filters.statuses?.map(({ key }) => key),
    type: filters.types?.map(({ key }) => key),
    costCenter: filters.costCenters?.map(({ key }) => key),
    team: filters.teams?.map(({ key }) => key),
    ...(customFields ? { custom_fields: customFields } : {}),
    ...period,
    ...amount,
  };
}
