import { useParams } from 'react-router-dom';

import { routeBuilder } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import history from 'src/core/history';

import { getRequestTabFromUrlParams } from '../utils';

type Params = {
  requestType?: string;
};

export const useNewRequestRoute = ({ requestType }: Params) => {
  const { type } = useParams();
  const companyId = useCompanyId();
  const requestTab = getRequestTabFromUrlParams(type);

  return routeBuilder('REQUESTS', {
    company: companyId,
    id: 'new',
    ...(requestType ? { newRequestType: requestType } : {}),
    type: requestTab,
  });
};

export const useGoToNewRequest = (params: Params) => {
  const newRequestRoute = useNewRequestRoute(params);

  return (): void => {
    history.push(newRequestRoute);
  };
};
