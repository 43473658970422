import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type CardsAccessBlockingReason =
  | { initiator: 'user'; blockerId: string }
  | { initiator: 'controlRuleProcess' };

type CardsAccess =
  | { hasAccess: true }
  | ({ hasAccess: false } & CardsAccessBlockingReason);

type CardsAccessRaw = {
  accesses: CardsAccess[];
};

export const useCardsAccessQuery = (): QueryState<CardsAccess> => {
  return useQuery<CardsAccess, CardsAccessRaw>({
    key: ['cards', 'access'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/cards/access',
    },
    reshapeData: (data) => data.accesses[0],
  });
};
