import * as R from '@dev-spendesk/general-type-helpers/Result';

import { Beneficiary } from 'modules/invoices/transfer/models';

import { VerifyBeneficiariesQueryFactory } from './api';
import { useShouldVerifyTransfersBeneficiaries } from './useShouldVerifyTransfersBeneficiaries';

export type Payload =
  | {
      paymentIds: string[];
      type: 'invoice' | 'expense';
      batchId?: never;
    }
  | {
      type: 'expense';
      batchId: string;
      paymentIds?: never;
    };

export type Result = R.Result<
  {
    reason: 'unverified-beneficiaries';
    failedVerifications: Beneficiary.VerificationResult[];
  },
  undefined
>;

export const useVerifyTransfersBeneficiaries = () => {
  const shouldVerifyTransfersBeneficiaries =
    useShouldVerifyTransfersBeneficiaries();
  const verifyBeneficiariesQuery =
    VerifyBeneficiariesQueryFactory.useVerifyBeneficiariesQueryFactory();

  return async (payload: Payload): Promise<Result> => {
    if (!shouldVerifyTransfersBeneficiaries) {
      return R.toSuccess(undefined);
    }

    const response = await verifyBeneficiariesQuery(payload);
    const unverifiedBeneficiaries = Beneficiary.filterUnverified(response);

    return unverifiedBeneficiaries.length > 0
      ? R.toFailure({
          reason: 'unverified-beneficiaries',
          failedVerifications: unverifiedBeneficiaries,
        })
      : R.toSuccess(undefined);
  };
};
