import { DimensionApprovalFlowPanelContent } from './DimensionApprovalFlowPanelContent';
import { StandardApprovalFlowPanelContent } from './StandardApprovalFlowPanelContent';
import { FloatingPanel } from '../FloatingPanel/FloatingPanel';

export type NodeSettingsFormProps = {
  condition: string;
  conditionValue: string;
};

type Props = {
  isOpen: boolean;
  selectedNodeId: string | undefined;
  panelType: 'standardApprovalFlow' | 'dimensionApprovalFlow';
  onClose: () => void;
  onSubmitted: (values: NodeSettingsFormProps, nodeId: string) => void;
};

export const NodeSettingsPanel = ({
  isOpen,
  panelType,
  onClose,
  // onSubmitted,
}: Props) => {
  return (
    <FloatingPanel isOpen={isOpen} onClose={onClose}>
      {panelType === 'standardApprovalFlow' && (
        <StandardApprovalFlowPanelContent onCancel={onClose} />
      )}
      {panelType === 'dimensionApprovalFlow' && (
        <DimensionApprovalFlowPanelContent onCancel={onClose} />
      )}
    </FloatingPanel>
  );
};
