import { Button, Modal, Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { PhoneNumberInput } from 'common/components/PhoneNumberInput';
import { useTranslation } from 'common/hooks/useTranslation';

type ModalMFAVerifyPhoneProps = {
  onClose: () => void;
  onVerifyPhone: () => void;
  onPhoneNumberChange: (phone: string) => void;
  phoneNumber: string;
  isPhoneNumberValid: boolean;
  isMobileNumber: boolean;
  isLoading: boolean;
  isOpen: boolean;
};

export const ModalMFAVerifyPhone = ({
  onClose,
  onVerifyPhone,
  onPhoneNumberChange,
  phoneNumber,
  isPhoneNumberValid,
  isMobileNumber,
  isLoading,
  isOpen,
}: ModalMFAVerifyPhoneProps) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="continue"
          text={
            isMobileNumber
              ? t('mfa.modal.phone.buttonAdd')
              : t('mfa.modal.phone.buttonConfirm')
          }
          variant="primary"
          onClick={onVerifyPhone}
          isDisabled={!isPhoneNumberValid || isLoading}
        />,
      ]}
      iconName="phone"
      iconVariant="primary"
      title={t('mfa.modal.phone.title')}
      isOpen={isOpen}
      subtitle={
        isMobileNumber
          ? t('mfa.modal.phone.desc')
          : t('mfa.modal.phone.descMobile')
      }
    >
      <PhoneNumberInput
        fit="parent"
        placeholder="X XX XX XX XX"
        className="ModalMFA__phone-input"
        value={phoneNumber}
        onChange={onPhoneNumberChange}
      />
      <Callout
        variant="primary"
        title={t('mfa.modal.phone.calloutTitle')}
        className="mt-m"
      >
        {t('mfa.modal.phone.calloutDesc')}
      </Callout>
    </Modal>
  );
};
