import { type FinanceController } from '../../../models';

export type RawFinanceController = {
  avatar: string;
  email: string;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
};

export const transformRawFinanceController = (
  rawFinanceController: RawFinanceController,
): FinanceController => ({
  ...rawFinanceController,
  firstName: rawFinanceController.first_name,
  fullName: rawFinanceController.full_name,
  lastName: rawFinanceController.last_name,
});
