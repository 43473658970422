import React from 'react';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { apiUrl } from 'src/core/utils/api';

import { ImportBudgetModal } from './ImportBudgetModal';
import { ImportBudgetModalError } from './ImportBudgetModalError';
import { ImportBudgetModalLoader } from './ImportBudgetModalLoader';
import { ImportBudgetModalWarning } from './ImportBudgetModalWarning';
import {
  useImportBudgetMutation,
  reshapeToFormData,
} from './useImportBudgetMutation';

type Props = {
  budgetaryExerciseId: string;
  type: 'create' | 'edit';
  onExit(): void;
  onSuccess(): void;
};
export const ImportBudgetModalContainer = ({
  budgetaryExerciseId,
  onExit,
  onSuccess,
  type,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const { pushNotif } = useNotifications();

  const [importBudget, mutationState, resetImportBudgetMutation] =
    useImportBudgetMutation(budgetaryExerciseId);

  async function handleUpload(file: File) {
    const payload = reshapeToFormData(file);
    try {
      const { warnings } = await importBudget(payload);

      if (warnings?.length) {
        return;
      }

      pushNotif({
        type: NotificationType.Success,
        message: t('budget.budgetaryExercise.importModal.successMessage'),
      });
      onSuccess();
    } catch {
      // caught below as mutationState.error
    }
  }

  if (mutationState.status === 'loading') {
    return <ImportBudgetModalLoader />;
  }

  if (
    mutationState.status === 'error' &&
    mutationState.error.type === 'RequestError'
  ) {
    return (
      <ImportBudgetModalError
        onRetry={resetImportBudgetMutation}
        onCancel={onExit}
        error={mutationState.error.data}
      />
    );
  }

  if (mutationState.status === 'error') {
    pushNotif({
      type: NotificationType.Danger,
      message: t(
        'budget.budgetaryExercise.importModal.uploadErrorNotification',
      ),
    });
    resetImportBudgetMutation();
  }

  if (
    mutationState.status === 'success' &&
    mutationState.data.warnings?.length
  ) {
    return <ImportBudgetModalWarning onContinue={onExit} />;
  }

  return (
    <ImportBudgetModal
      downloadLink={apiUrl(
        `/budgets/budgetary-exercises/${budgetaryExerciseId}/budgets?fileFormat=xlsx`,
        companyId,
      )}
      onUpload={handleUpload}
      onCancel={onExit}
      type={type}
    />
  );
};
