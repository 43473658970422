import { useSelector } from 'react-redux';

import { getUserRoles } from 'src/core/selectors/globalSelectors';

export type UserRoles = {
  isRequester: boolean;
  isAdmin: boolean;
  isController: boolean;
  isAccountOwner: boolean;
};

export const useUserRoles = (): UserRoles => {
  return useSelector(getUserRoles);
};
