import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import Illustration from './assets/illustration.svg';

import './RestrictedAccessPanel.css';

export const RestrictedAccessPanel = () => {
  const { t } = useTranslation('global');

  return (
    <div className="RestrictedAccessPanel box p-m text-center">
      <img width="166" height="149" alt="" src={Illustration} />
      <div className="mt-s text-complementary title-l">
        {t('homepage.restrictedAccessPanel.title')}
      </div>
      <div className="mt-xxs text-neutral-dark body-m">
        {t('homepage.restrictedAccessPanel.text')}
      </div>
    </div>
  );
};
