export type Mask = {
  blocks: number[];
  delimiter?: string;
};

export const unmask = (value: string, { blocks, delimiter = '\\s' }: Mask) => {
  if (blocks.length === 0 || blocks.some((blockValue) => blockValue < 0)) {
    return '';
  }

  if (delimiter === '.') {
    delimiter = '\\.';
  }
  const maxStringLength = blocks.reduce(
    (accumulator, block) => accumulator + block,
    0,
  );
  return value
    .replaceAll(new RegExp(delimiter, 'gi'), '')
    .slice(0, maxStringLength);
};

/**
 *
 * Algorithm to mask a string value
 * - Given a string value and a mask
 * - for each block OR until we are reaching the end of the string with the current block size, slice the value and add the delimiter
 * - add a slice containing the remaining characters
 * - merge all the slices and trim to eliminate the last delimiter
 */
export const mask = (
  value: string,
  { blocks, delimiter = ' ' }: Mask,
): string => {
  if (blocks.length === 0 || blocks.some((blockValue) => blockValue < 0)) {
    return '';
  }

  const slices = [];
  let valueOffset = 0;
  let maskIndex = 0;

  for (
    ;
    maskIndex < blocks.length &&
    valueOffset + blocks[maskIndex] <= value.length;
    valueOffset += blocks[maskIndex], maskIndex++
  ) {
    const slice = value
      .slice(valueOffset, valueOffset + blocks[maskIndex])
      .concat(delimiter);
    slices.push(slice);
  }

  if (valueOffset < value.length && maskIndex < blocks.length) {
    const lastSlice = value.slice(valueOffset, valueOffset + blocks[maskIndex]);
    slices.push(lastSlice);
  }

  const newValue = slices.join('');
  return newValue.endsWith(delimiter)
    ? newValue.slice(0, -delimiter.length)
    : newValue;
};
