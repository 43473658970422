import { useMemo } from 'react';
import {
  type QueryKey,
  useQuery as useReactQuery,
  useQueryClient,
} from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';

import { type QueryClient } from '../client';
import { getFetcher } from '../fetcher';
import { type QueryRequest } from '../query';
import { type QueryError } from '../queryError';
import {
  type LazyState,
  type QueryIdleState,
  type QueryState,
} from '../queryState';

const defaultReshapeError = <TRawError, TError>(
  error: QueryError<TRawError>,
): QueryError<TError> => error as QueryError<TError>;

export function useLazyQuery<
  TResult extends object | undefined,
  TRawResult extends object | undefined = undefined,
  TError = unknown,
  TRawError = TError,
>({
  key,
  request,
  reshapeData,
  reshapeError = defaultReshapeError,
  options,
}: {
  key: QueryKey;
  request: QueryRequest;
  reshapeData(data: TRawResult): TResult;
  reshapeError?(error: QueryError<TRawError>): QueryError<TError>;
  options?: {
    onSuccess?(successBag: {
      rawData: TRawResult;
      data: TResult;
      client: QueryClient;
    }): void;
    onError?(errorBag: {
      error: QueryError<TError>;
      client: QueryClient;
    }): void;
    refetchInterval?: number | false;
    throwOnError?: boolean;
    cancelRefetch?: boolean;
  };
}): LazyState<TResult, TError> {
  const queryClient = useQueryClient();

  const companyId = useCompanyId();

  const fetcher = getFetcher<TRawResult>({
    companyId,
    getRequest: () => ({
      ...request,
      method: 'get',
    }),
  });

  const { refetch, ...queryState } = useReactQuery<
    TRawResult,
    QueryError<TRawError>
  >(key, fetcher, {
    ...options,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) =>
      options?.onSuccess?.({
        rawData: data,
        data: reshapeData(data),
        client: queryClient,
      }),
    onError: (error) =>
      options?.onError?.({
        error: reshapeError(error),
        client: queryClient,
      }),
  });

  const toLazyState = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    queryState: QueryIdleState | QueryState<TResult, TError>,
  ): LazyState<TResult, TError> => {
    const reshapedRefetch = async () => {
      const result = await refetch({
        throwOnError: options?.throwOnError,
        cancelRefetch: options?.cancelRefetch,
      });
      if (result.data) {
        return reshapeData(result.data);
      }
    };

    return [reshapedRefetch, queryState];
  };

  return useMemo(() => {
    if (queryState.status === 'loading' || queryState.status === 'idle') {
      return toLazyState({
        status: queryState.status,
      });
    }
    if (queryState.status === 'error') {
      return toLazyState({
        status: 'error',
        error: reshapeError(queryState.error),
      });
    }

    return toLazyState({
      status: 'success',
      data: reshapeData(queryState.data),
    });
  }, [queryState.data, queryState.status, reshapeError, reshapeData]);
}
