import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';

import illustration from '../assets/mobile-settings-qr-illustration.png';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SFSUkMobileRedirectModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');
  return (
    <Modal
      illustration={<img alt="" src={illustration} className="h-[317px]" />}
      illustrationHeight="317px"
      isOpen={isOpen}
      onClose={onClose}
      title={t('requests.sfsUkModal.title')}
      subtitle={t('requests.sfsUkModal.descriptionUK')}
      actions={
        <Button
          onClick={onClose}
          text={t('requests.sfsUkModal.cta')}
          variant="primary"
        />
      }
    />
  );
};
