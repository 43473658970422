/* eslint-disable unicorn/no-await-expression-member */

import { EmptyStateError, lazyLoad } from 'src/core/common/components/LazyLoad';

import { PanelPaymentLoader } from './PanelPaymentLoader';

export const LazyPanelPayment = lazyLoad({
  loader: async () => ({
    default: (
      await import(
        /* webpackChunkName: "PanelPayment", webpackPrefetch: true */ './PanelPayment'
      )
    ).default,
  }),
  loading: <PanelPaymentLoader />,
  fallback: (props) => <EmptyStateError {...props} />,
});
