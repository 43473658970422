import React from 'react';

type Props = {
  className?: string;
  numberOfDots?: number;
};

export const VerticalDottedLine = ({ className, numberOfDots = 3 }: Props) => (
  <svg
    className={className}
    width="12"
    height={numberOfDots * 4}
    viewBox={`0 0 12 ${numberOfDots * 4}`}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    {Array.from({ length: numberOfDots }, (_, key) => (
      <rect key={key} x="5" y={key * 5} width="2" height="2" rx="1" />
    ))}
  </svg>
);
