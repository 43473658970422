import type { QueryState } from 'src/core/api/queryState';

import type {
  SupplierAsyncAutocompleteProps,
  SupplierAsyncAutocompletePropsWithBankInfoUpload,
  SupplierAsyncAutocompletePropsWithSupplierCreation,
} from './SupplierAsyncAutocomplete';
import type { Supplier } from '../../../../common/hooks/useSearchSuppliers';
import type { SupplierDetails } from '../../models';

type VisibleCallout =
  | { kind: 'none' }
  | ({
      kind: 'supplierCreationWarning';
      value: Supplier;
      showSupplierModal: boolean;
    } & SupplierAsyncAutocompletePropsWithSupplierCreation)
  | { kind: 'supplierArchivedWarning' }
  | ({
      kind: 'bankInfoUpload';
    } & SupplierAsyncAutocompletePropsWithBankInfoUpload);

const isSupplierNew = (supplier: Supplier | undefined) =>
  supplier?.label !== undefined &&
  (supplier?.label === supplier?.key || !supplier?.key);

export function getVisibleCallout({
  props,
  supplierByNameQuery,
  isSupplierModalVisible,
}: {
  props: SupplierAsyncAutocompleteProps & { value: Supplier | undefined };
  supplierByNameQuery: QueryState<SupplierDetails | undefined, unknown>;
  isSupplierModalVisible: boolean;
}): VisibleCallout {
  const { value } = props;
  const hasValue = value?.label !== undefined;
  const isNewSupplier = isSupplierNew(value);

  const showSupplierCreationWarning =
    supplierByNameQuery.status === 'success' &&
    (!supplierByNameQuery.data ||
      supplierByNameQuery.data.isArchived === false) &&
    props.showSupplierCreationWhenNew &&
    isNewSupplier;

  const showSupplierModal =
    isSupplierModalVisible &&
    (props.showSupplierCreationWhenNew ?? false) &&
    hasValue;

  if (showSupplierCreationWarning && hasValue) {
    return {
      kind: 'supplierCreationWarning',
      documentaryEvidence: props.documentaryEvidence,
      bankDetailsSuggestion: props.bankDetailsSuggestion,
      onCreateSupplierSuccess: props.onCreateSupplierSuccess,
      value,
      showSupplierModal,
    };
  }

  const showBankInfoUpload =
    supplierByNameQuery.status === 'success' &&
    (!supplierByNameQuery.data ||
      supplierByNameQuery.data.isArchived === false) &&
    props.showBankInfoUploadWhenNew &&
    !props.showSupplierCreationWhenNew &&
    isNewSupplier;

  if (showBankInfoUpload) {
    return {
      kind: 'bankInfoUpload',
      newSupplierIban: props.newSupplierIban,
      onBankInfoUploaded: props.onBankInfoUploaded,
      onBankInfoDeleted: props.onBankInfoDeleted,
    };
  }

  const showSupplierArchivedWarning =
    supplierByNameQuery.status === 'success' &&
    supplierByNameQuery.data?.isArchived === true &&
    (props.showWarningWhenIsArchived ?? true);

  if (showSupplierArchivedWarning) {
    return {
      kind: 'supplierArchivedWarning',
    };
  }

  return { kind: 'none' };
}
