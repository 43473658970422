import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { isTheLastRule } from '../../models';
import { type ApprovalRule, type Member } from '../../types';
import { ApprovalThreshold } from '../ApprovalThreshold';

type Props = {
  rules: ApprovalRule[];
  members: Member[];
  erroredRulesIds: string[];
  costCenterOwnerId?: string;
  mode?: 'approvalByDimensionsPanel' | 'default';
  onCreateRule(): void;
  onUpdateRule(ruleIndex: number, rule: ApprovalRule): void;
  onDeleteRule(ruleId: string): void;
};

export const ApprovalRules = ({
  rules,
  members,
  erroredRulesIds,
  costCenterOwnerId,
  mode,
  onCreateRule,
  onUpdateRule,
  onDeleteRule,
}: Props) => {
  const { t } = useTranslation('global');
  const hasOnlyOneRule = rules.length === 1;
  const hasMoreThanOneRule = rules.length > 1;

  const renderAddThresholdButton = () => (
    <Button
      onClick={onCreateRule}
      fit="parent"
      text={`+ ${t('members.addThreshold')}`}
      variant="secondary"
      className="mb-s"
    />
  );

  return (
    <div className="w-full">
      {rules.map((rule, ruleIndex) => (
        <div key={rule.id}>
          {isTheLastRule(rules, ruleIndex) && hasMoreThanOneRule
            ? renderAddThresholdButton()
            : null}
          <ApprovalThreshold
            approvalRule={{
              ...rule,
              index: ruleIndex,
              isLastRule: isTheLastRule(rules, ruleIndex),
            }}
            mode={mode}
            members={members}
            costCenterOwnerId={costCenterOwnerId}
            hasError={erroredRulesIds.includes(rule.id)}
            onUpdateRule={onUpdateRule}
            onDeleteRule={onDeleteRule}
          />
        </div>
      ))}
      {hasOnlyOneRule && renderAddThresholdButton()}
    </div>
  );
};
