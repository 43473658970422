// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import {
  type ApprovalPolicyParamsFormValues,
  validateApprovalPolicyParamsFormValues,
} from '../../formValues';
import { ApprovalPolicyParamsContent } from '../ApprovalPolicyParamsContent';

type Props = {
  values: ApprovalPolicyParamsFormValues | undefined;
  onSuccess(values: ApprovalPolicyParamsFormValues): void;
  onCancel(): void;
};

export const CustomApprovalPolicyModal = ({
  values,
  onSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<ApprovalPolicyParamsFormValues>({
    initialValues: {
      approvalNeeded: values?.approvalNeeded ?? 'always',
      spendingTypes: values?.spendingTypes ?? [],
      amountPerMonth:
        values?.approvalNeeded === 'sometimes' ? values?.amountPerMonth : 0,
      amountPerTransaction:
        values?.approvalNeeded === 'sometimes'
          ? values?.amountPerTransaction
          : 0,
    },
    validateOnChange: false,
    validate: (params) => validateApprovalPolicyParamsFormValues(params, t),
    onSubmit: onSuccess,
  });

  return (
    <Modal
      isOpen
      iconName="badge"
      iconVariant="primary"
      title={t('approvalPolicy.custom.modalTitle')}
      actions={[
        <Button
          key="1"
          variant="secondary"
          text={t('approvalPolicy.custom.cancel')}
          onClick={onCancel}
        />,
        <Button
          key="2"
          variant="primary"
          text={t('approvalPolicy.custom.define')}
          onClick={() => formikProps.handleSubmit()}
        />,
      ]}
    >
      <ApprovalPolicyParamsContent
        values={formikProps.values}
        errors={formikProps.errors}
        setFieldValue={formikProps.setFieldValue}
      />
    </Modal>
  );
};
