import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useInvalidateBookkeepingNavbarCounters } from 'modules/bookkeep/hooks';

import { useInvalidatePayableGroupsQueryCache } from '../../../prepare-payables/components/PreparePayablesBuckets/hooks/usePayableGroupsQuery/usePayableGroupsQuery';
import { useInvalidatePayblesQueryCache } from '../../../prepare-payables/components/PreparePayablesBuckets/hooks/usePayablesQuery/usePayablesQuery';

export const useRefreshPayableBuckets = () => {
  const companyId = useCompanyId();

  const invalidateBookkeepingNavbarCounters =
    useInvalidateBookkeepingNavbarCounters();

  const invalidatePayableGroupsQueryCache =
    useInvalidatePayableGroupsQueryCache();

  const invalidatePayblesQueryCache = useInvalidatePayblesQueryCache();

  return async (
    action?: Parameters<typeof invalidateBookkeepingNavbarCounters>[0],
  ) => {
    if (action) {
      invalidateBookkeepingNavbarCounters(action);
    }

    return Promise.all([
      // Payables list
      invalidatePayblesQueryCache(companyId),
      // Counter and payables in group
      invalidatePayableGroupsQueryCache(companyId),
    ]);
  };
};
