import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useUser } from 'src/core/modules/app/hooks/useUser';

export const useSFSRevealPinAccess = () => {
  const hasPlasticCardsFeature = useFeature(FEATURES.PLASTIC_CARDS);
  const user = useUser();
  const companyId = useCompanyId();

  const card = user.data_by_company[companyId]?.plastic_card;
  return hasPlasticCardsFeature && card && card.banking_provider === 'sfs';
};
