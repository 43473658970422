import {
  AutocompleteNoOptions,
  Button,
  Callout,
  CheckboxField,
  FormField,
  ListItem,
  ListView,
  RadioField,
  RadioGroup,
  Select,
  SkeletonText,
  TextInput,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { type FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useWindowSize } from 'react-use';

import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { useTranslation } from 'common/hooks/useTranslation';
import { getHelpCenterBase } from 'common/utils/help-center/getHelpCenterBase';
import { ActiveAccountingIntegrationApi } from 'modules/bookkeep/hooks';
import {
  TemplateBuilderV2,
  type InternalColumn,
} from 'modules/bookkeep/settings/export-legacy/components/TemplateBuilder';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { languages } from 'src/core/config/i18n';
import FEATURES from 'src/core/constants/features';

import { type FormValues } from './formValues';
import { type CustomField } from '../../customField';
import {
  type Column,
  type CompositeOption,
  dateDelimiters,
  dateFormats,
  decimalDelimiters,
  columnDelimiters,
  dateDelimiterToTranslationKey,
  decimalDelimiterToTranslationKey,
  columnDelimiterToTranslationKey,
  parseDateFormat,
  languageToTranslationKey,
  type PreviewRow,
  rowDisplayOptions,
  type RowDisplayOption,
  fileTypeEncodingOptions,
} from '../../template';

import './TemplateEditorForm.css';

const LOADER_ITEMS = 6;

type Props = {
  rows: PreviewRow[];
  compositePatternPartOptions: CompositeOption[];
  isTemplateEditionMode: boolean;
  isLoading: boolean;
  isComputingRows: boolean;
  searchAvailableColumns: (columns: Column[], query: string) => Column[];
  onCancel: () => void;
  customFields: CustomField[];
  expenseCategoryCustomFieldId: CustomField['id'] | undefined;
  availableAccountingSoftwares: { key: string; label: string }[];
} & FormikProps<FormValues>;

export const TemplateEditorForm = ({
  rows,
  compositePatternPartOptions,
  isTemplateEditionMode,
  isLoading: isDefaultLoading,
  isComputingRows,
  searchAvailableColumns,
  onCancel,
  customFields,
  expenseCategoryCustomFieldId,
  availableAccountingSoftwares,
  // FormikProps
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const formReference = useRef<HTMLFormElement>(null);
  const { width } = useWindowSize();
  const [cannotReorderColumn, setCannotReorderColumn] =
    useState<InternalColumn | null>(null);
  const shouldDisplayCustomSoftwareInputField =
    values.accountingSoftware === t('exports.others');

  const activeAccountingIntegration =
    ActiveAccountingIntegrationApi.useActiveAccountingIntegration();
  const isLoading =
    isDefaultLoading || activeAccountingIntegration.status === 'loading';
  const totalFixtureLines =
    activeAccountingIntegration.status === 'success' &&
    activeAccountingIntegration.data.activeAccountingIntegration ===
      'SpendeskAccountingSingleEntry'
      ? 3
      : 9;

  const dateFormatOptions = dateFormats.map((format) => ({
    key: format.join(values.dateDelimiter),
    label: format.join(values.dateDelimiter),
  }));

  const languageOptions = languages
    // TODO: temporary fix to avoid selecting IT & ES languages, since the backend is not ready yet
    .filter(
      (language) => language === 'en' || language === 'fr' || language === 'de',
    );

  const rowColSelectable = useFeature(FEATURES.TMP_TEMPLATE_ROW_COL_SELECT);

  const isDoubleEntry =
    activeAccountingIntegration.status === 'success' &&
    activeAccountingIntegration.data.activeAccountingIntegration ===
      'SpendeskAccounting';

  const isSingleEntry =
    activeAccountingIntegration.status === 'success' &&
    activeAccountingIntegration.data.activeAccountingIntegration ===
      'SpendeskAccountingSingleEntry';

  const noRowDisplaySelected =
    rowColSelectable &&
    isDoubleEntry &&
    (values.rowDisplayOptions || []).filter((o) => o.active).length === 0;

  return isLoading ? (
    <>
      <div className="flex items-center">
        <div className="TemplateEditorForm__badge body-l">1</div>
        <div className="ml-xs text-complementary title-xl">
          {t('bookkeep.settings.export.setupPreferences')}
        </div>
      </div>
      <ListView className="mt-s">
        {Array.from({ length: LOADER_ITEMS }, (_, key) => (
          <ListItem
            key={key}
            className={classNames('flex flex-col', {
              'pt-s': key !== 0,
              'pb-s': key !== LOADER_ITEMS - 1,
            })}
          >
            <SkeletonText width="30%" className="mb-xs" />
            <SkeletonText width="50%" />
          </ListItem>
        ))}
      </ListView>
    </>
  ) : (
    <form
      onSubmit={handleSubmit}
      noValidate
      className="TemplateEditorForm__form"
      ref={formReference}
    >
      <fieldset className="border-none">
        <legend className="flex items-center">
          <div className="TemplateEditorForm__badge body-l">1</div>
          <div className="ml-xs text-complementary title-xl">
            {t('bookkeep.settings.export.setupPreferences')}
          </div>
        </legend>
        <ListView className="mb-m mt-m">
          <ListItem>
            <div className="mb-xs mt-xs">
              <FormField
                label={t('bookkeep.settings.export.nameTemplateLabel')}
                className="mb-s w-[436px]"
                alertMessage={errors.name}
              >
                <TextInput
                  placeholder={t(
                    'bookkeep.settings.export.nameTemplatePlaceholder',
                  )}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  isInvalid={Boolean(errors.name)}
                />
              </FormField>
              <div className="flex gap-x-m">
                <FormField
                  label={t('exports.accountingSoftwareNameLabel')}
                  className="mb-s w-[436px]"
                  alertMessage={errors.accountingSoftware}
                >
                  <AutocompleteSearch
                    showClearSelectionButton
                    value={
                      values.accountingSoftware
                        ? {
                            key: values.accountingSoftware,
                            label: values.accountingSoftware,
                          }
                        : undefined
                    }
                    fit="parent"
                    options={availableAccountingSoftwares}
                    isInvalid={!!errors.accountingSoftware}
                    placeholder={t('exports.accountingSoftwareNamePlaceholder')}
                    onSelect={(accountingSoftware) => {
                      setFieldValue(
                        'accountingSoftware',
                        accountingSoftware?.label,
                      );
                    }}
                    renderNoOptions={(search) => (
                      <AutocompleteNoOptions>
                        <>
                          <p>
                            <Trans
                              i18nKey="exports.errors.unknownAccountingSoftware.noResults"
                              values={{ search }}
                              components={{
                                em: <strong className="title-m" />,
                              }}
                            />
                          </p>
                          <p>
                            <Trans
                              i18nKey="exports.errors.unknownAccountingSoftware.tooltip"
                              values={{ others: t('exports.others') }}
                              components={{
                                em: <strong className="title-m" />,
                              }}
                            />
                          </p>
                        </>
                      </AutocompleteNoOptions>
                    )}
                  />
                </FormField>
                {shouldDisplayCustomSoftwareInputField && (
                  <FormField
                    label={t('bookkeep.settings.export.customSoftwareName')}
                    className="mb-s w-[436px]"
                    alertMessage={errors.customSoftwareName}
                  >
                    <TextInput
                      placeholder={t(
                        'bookkeep.settings.export.customSoftwareNamePlaceholder',
                      )}
                      name="customSoftwareName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.customSoftwareName ?? ''}
                      isInvalid={!!errors.customSoftwareName}
                    />
                  </FormField>
                )}
              </div>
            </div>
          </ListItem>
          <ListItem>
            <p
              className="mb-xs mt-xs text-neutral-dark body-m"
              style={
                formReference.current
                  ? { width: `${formReference.current.offsetWidth}px` }
                  : undefined
              }
            >
              File
            </p>
            <FormField
              label={t('bookkeep.settings.export.fileTypeEncodingLabel')}
              className="mb-xs w-[288px]"
            >
              <Select
                fit="parent"
                value={{
                  key: values.fileTypeEncoding,
                  label: values.fileTypeEncoding,
                }}
                options={fileTypeEncodingOptions.map((value) => ({
                  key: value,
                  label: value,
                }))}
                onSelect={(option) =>
                  setFieldValue('fileTypeEncoding', option.key)
                }
                onBlur={() => setFieldTouched('fileTypeEncoding', true)}
              />
            </FormField>
            <FormField
              label={t('bookkeep.settings.export.columnDelimiterLabel')}
              infoTipContent={t(
                'bookkeep.settings.export.columnDelimiterTooltip',
              )}
              className="mb-xs"
            >
              <RadioGroup
                name="columnDelimiter"
                value={values.columnDelimiter}
                onChange={handleChange}
              >
                {columnDelimiters.map((delimiter) => (
                  <RadioField
                    value={delimiter}
                    key={delimiter}
                    label={t(columnDelimiterToTranslationKey(delimiter))}
                  />
                ))}
              </RadioGroup>
            </FormField>
            <FormField
              label={t('bookkeep.settings.export.languageLabel')}
              infoTipContent={t('bookkeep.settings.export.languageTooltip')}
              className="mb-xs"
            >
              <RadioGroup
                name="language"
                value={values.language}
                onChange={handleChange}
              >
                {languageOptions.map((language) => (
                  <RadioField
                    value={language}
                    key={language}
                    label={t(languageToTranslationKey(language))}
                  />
                ))}
              </RadioGroup>
            </FormField>

            <CheckboxField
              isChecked={values.shouldOutputHeaders}
              onChange={(e) =>
                setFieldValue('shouldOutputHeaders', e.target.checked)
              }
              label="Show column headers in the export?" // TODO: translate
              className="AutoCompleteMulti__list__option__checkbox mb-xs w-[436px]"
            />
          </ListItem>
          <ListItem>
            <p
              className="mb-xs mt-xs text-neutral-dark body-m"
              style={
                formReference.current
                  ? { width: `${formReference.current.offsetWidth}px` }
                  : undefined
              }
            >
              Formatting
            </p>
            <FormField
              label={t('bookkeep.settings.export.dateDelimiterLabel')}
              infoTipContent={t(
                'bookkeep.settings.export.dateDelimiterTooltip',
              )}
              className="mb-xs"
            >
              <RadioGroup
                name="dateDelimiter"
                value={values.dateDelimiter}
                onChange={handleChange}
              >
                {dateDelimiters.map((delimiter) => (
                  <RadioField
                    value={delimiter}
                    key={delimiter}
                    label={t(dateDelimiterToTranslationKey(delimiter))}
                  />
                ))}
              </RadioGroup>
            </FormField>
            <FormField
              label={t('bookkeep.settings.export.dateFormatLabel')}
              className="mb-s w-[288px]"
            >
              <Select
                fit="parent"
                value={{
                  key: values.dateFormat.join(values.dateDelimiter),
                  label: values.dateFormat.join(values.dateDelimiter),
                }}
                options={dateFormatOptions}
                placeholder={t(
                  'bookkeep.settings.export.dateFormatPlaceholder',
                )}
                onSelect={(option) =>
                  setFieldValue(
                    'dateFormat',
                    parseDateFormat(option.key, values.dateDelimiter),
                  )
                }
                onBlur={() => setFieldTouched('dateFormat', true)}
              />
            </FormField>
            <FormField
              label={t('bookkeep.settings.export.decimalDelimiterLabel')}
              infoTipContent={t(
                'bookkeep.settings.export.decimalDelimiterTooltip',
              )}
              className="mb-xs"
            >
              <RadioGroup
                name="decimalDelimiter"
                value={values.decimalDelimiter}
                onChange={handleChange}
              >
                {decimalDelimiters.map((delimiter) => (
                  <RadioField
                    value={delimiter}
                    key={delimiter}
                    label={t(decimalDelimiterToTranslationKey(delimiter))}
                  />
                ))}
              </RadioGroup>
            </FormField>
          </ListItem>
          {rowColSelectable && isDoubleEntry && (
            <ListItem>
              <p
                className="mb-xs mt-xs text-neutral-dark body-m"
                style={
                  formReference.current
                    ? { width: `${formReference.current.offsetWidth}px` }
                    : undefined
                }
              >
                Rows
              </p>
              <FormField
                label={t(
                  `bookkeep.settings.export.rowDisplayOptions.headerFormLabel`,
                )}
                infoTipContent={t(
                  'bookkeep.settings.export.rowDisplayOptions.headerFormLabelTooltip',
                )}
                alertMessage={
                  noRowDisplaySelected
                    ? t(
                        `bookkeep.settings.export.rowDisplayOptions.selectRowAlert`,
                      )
                    : ''
                }
              >
                <div className="flex flex-col">
                  {rowDisplayOptions.map(({ group }) => {
                    const rowDisplayOptionIndex =
                      values.rowDisplayOptions?.findIndex(
                        (r) => r.group === group,
                      );

                    let rowDisplayOptionValue: RowDisplayOption | undefined;
                    if (
                      (rowDisplayOptionIndex || rowDisplayOptionIndex === 0) &&
                      rowDisplayOptionIndex !== -1
                    ) {
                      rowDisplayOptionValue =
                        values.rowDisplayOptions?.[rowDisplayOptionIndex];
                    }

                    const checkbox = (
                      <CheckboxField
                        isChecked={!!rowDisplayOptionValue?.active}
                        key={group}
                        onChange={(e) => {
                          const currentRowDisplayOptions = [
                            ...(values.rowDisplayOptions ?? []),
                          ];

                          if (
                            (rowDisplayOptionIndex ||
                              rowDisplayOptionIndex === 0) &&
                            rowDisplayOptionValue
                          ) {
                            currentRowDisplayOptions[rowDisplayOptionIndex] = {
                              ...rowDisplayOptionValue,
                              active: e.target.checked,
                            };
                          }

                          setFieldValue(
                            'rowDisplayOptions',
                            currentRowDisplayOptions,
                          );
                        }}
                        label={t(
                          `bookkeep.settings.export.rowDisplayOptions.${group}`,
                        )}
                        className="AutoCompleteMulti__list__option__checkbox w-[436px]"
                      />
                    );

                    if (
                      rowDisplayOptionValue?.group === 'analytical' &&
                      rowDisplayOptionValue?.active
                    ) {
                      return (
                        <div
                          key={`${group}_line_with_analyticalCommonLine`}
                          className="flex flex-col"
                        >
                          {checkbox}
                          <CheckboxField
                            isChecked={
                              !!rowDisplayOptionValue?.analyticalCommonLine
                                ?.active
                            }
                            key={`${group}_analyticalCommonLine`}
                            onChange={(e) => {
                              const currentRowDisplayOptions = [
                                ...(values.rowDisplayOptions ?? []),
                              ];

                              if (
                                (rowDisplayOptionIndex ||
                                  rowDisplayOptionIndex === 0) &&
                                rowDisplayOptionValue
                              ) {
                                currentRowDisplayOptions[
                                  rowDisplayOptionIndex
                                ] = {
                                  ...rowDisplayOptionValue,
                                  analyticalCommonLine: {
                                    active: e.target.checked,
                                  },
                                };
                              }

                              setFieldValue(
                                'rowDisplayOptions',
                                currentRowDisplayOptions,
                              );
                            }}
                            label={t(
                              `bookkeep.settings.export.rowDisplayOptions.analyticalCommonLine`,
                            )}
                            className="pl-m"
                          />
                        </div>
                      );
                    }

                    return checkbox;
                  })}
                </div>
              </FormField>
            </ListItem>
          )}
        </ListView>
      </fieldset>
      <fieldset className="border-none">
        <legend className="flex items-center">
          <div className="TemplateEditorForm__badge body-l">2</div>
          <div className="ml-xs text-complementary title-xl">
            {t('bookkeep.settings.export.selectColumnsLabel')}
          </div>
        </legend>
        <p
          className="mt-s text-neutral-dark body-m"
          style={
            formReference.current
              ? { width: `${formReference.current.offsetWidth}px` }
              : undefined
          }
        >
          <Trans
            i18nKey="bookkeep.settings.export.exportColumnsInfo"
            components={[
              <a
                key="help"
                className="text-neutral-dark underline"
                href={`${getHelpCenterBase({
                  lang: activeLanguage,
                })}articles/4152880`}
              >
                -
              </a>,
            ]}
          />
        </p>
        <div
          style={
            formReference.current
              ? { width: `${formReference.current.offsetWidth}px` }
              : undefined
          }
        >
          <Callout
            className="mb-xs mt-s"
            title={t('exports.templatePreviewFlashMessage')}
          />
          {!isDoubleEntry && !isSingleEntry && cannotReorderColumn && (
            <Callout
              variant="warning"
              className="my-xs"
              title={t('exports.cannotReorderColumnFlashMessage', {
                name: cannotReorderColumn.name,
              })}
            />
          )}
        </div>
        <TemplateBuilderV2
          customFields={customFields}
          expenseCategoryCustomFieldId={expenseCategoryCustomFieldId}
          setCannotReorderColumn={setCannotReorderColumn}
          totalFixtureLines={totalFixtureLines}
          columns={values.columns}
          onChange={(columns) => {
            setFieldValue('columns', columns);
          }}
          rowDisplayOptions={values.rowDisplayOptions}
          rows={rows}
          isLoading={isComputingRows}
          compositePatternPartOptions={compositePatternPartOptions}
          searchAvailableColumns={searchAvailableColumns}
          style={
            formReference.current
              ? {
                  width: `${width}px`,
                  marginLeft: `-${
                    (width - formReference.current.offsetWidth) / 2
                  }px`,
                }
              : undefined
          }
        />
      </fieldset>
      <div>
        <Button
          variant="secondary"
          text={t('misc.cancel')}
          onClick={onCancel}
        />
        <Button
          variant="primary"
          className="ml-s"
          type="submit"
          text={
            isTemplateEditionMode
              ? t('bookkeep.settings.export.saveExisting')
              : t('bookkeep.settings.export.saveNew')
          }
          isLoading={isSubmitting}
          isDisabled={isSubmitting || noRowDisplaySelected}
        />
      </div>
    </form>
  );
};
