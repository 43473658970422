import * as yup from 'yup';

import { type Step } from './NewBranchEntityModal';

export type EntityAddressForm = {
  address: string;
  address2?: string;
  city: string;
  state?: string;
  zipcode: string;
};

export type NewBranchEntityModalForm = {
  country: string;
  currency: string;
  name: string;
  address: EntityAddressForm;
};

export const getBranchEntityFormSchema = (step: Step) => {
  return yup.object().shape({
    country: yup.string().required().length(2).trim(),
    currency: yup.string().required().min(1).trim(),
    name:
      step === 'entityName'
        ? yup.string().required().min(1).trim()
        : yup.string().optional(),
    address:
      step === 'entityAddress'
        ? yup.object().shape({
            address: yup.string().required().trim(),
            address2: yup.string().optional().trim(),
            city: yup.string().required().trim(),
            state: yup.string().optional().trim(),
            zipcode: yup.string().required().trim(),
          })
        : yup.object().optional(),
  });
};
