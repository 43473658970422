import { DeprecatedPreview } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation, type I18nKey } from 'common/hooks/useTranslation';
import { PaymentMethod } from 'modules/company';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { StatusTag } from './StatusTag';
import { useGoToSchedulingProcessEntry } from '../../../hooks';
import { type SchedulingProcessEntry } from '../../../models';

type Props = {
  schedulingProcessEntry: SchedulingProcessEntry;
  isActive: boolean;
};

export const SchedulingProcessViewEntry = ({
  // isActive - not used for now
  schedulingProcessEntry,
}: Props) => {
  const { t } = useTranslation('global');

  const goToSchedulingProcessEntry = useGoToSchedulingProcessEntry()(
    schedulingProcessEntry,
  );

  return (
    <DeprecatedPreview
      fit="parent"
      onClick={goToSchedulingProcessEntry}
      primaryText={formatMonetaryValue(schedulingProcessEntry.amount)}
      secondaryText={
        schedulingProcessEntry.status !== 'toSchedule' &&
        schedulingProcessEntry.method
          ? t(getPaymentMethodTranslationKey(schedulingProcessEntry.method))
          : undefined
      }
      rightAddon={<StatusTag schedulingProcessEntry={schedulingProcessEntry} />}
    />
  );
};

const getPaymentMethodTranslationKey = (
  paymentMethod: PaymentMethod,
): I18nKey => {
  switch (paymentMethod) {
    case PaymentMethod.WireTransfer:
      return 'invoices.schedulingProcess.paymentMethod.wiretransfer';

    case PaymentMethod.XmlSepa:
      return 'invoices.schedulingProcess.paymentMethod.xml-sepa';

    case PaymentMethod.Csv:
      return 'invoices.schedulingProcess.paymentMethod.csv';

    default:
      rejectUnexpectedValue(
        'Unexpected payment method on SchedulingProcessEntry: ',
        paymentMethod,
      );
  }
};
