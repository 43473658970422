export type Attachment = {
  id: string;
  mimeType: string;
  attachedAt: string;
  fileName: string;
  certified: boolean;
  thumbnailLocation: string;
  location: string;
};

export const getAttachmentType = (attachment: Attachment): 'pdf' | 'image' =>
  attachment.mimeType.toLowerCase().includes('pdf') ? 'pdf' : 'image';
