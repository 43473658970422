import { Button, EmptyState } from '@dev-spendesk/grapes';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useKycStatusCode } from 'modules/kyc/hooks/useKycStatusCode';
import { useStartOrContinueProcedure } from 'modules/procedures';
import { routes } from 'src/core/constants/routes';

export const PendingKycEmptyStateWithCta = () => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const startOrContinueProcedure = useStartOrContinueProcedure();
  const kycStatusCode = useKycStatusCode();

  const emptyStateReason = useMemo(() => {
    if (kycStatusCode && ['not-started', 'initiated'].includes(kycStatusCode)) {
      return 'kycNotSubmitted';
    }
    if (
      kycStatusCode &&
      ['in-review', 'information-needed', 'signature-requested'].includes(
        kycStatusCode,
      )
    ) {
      return 'kycNotValidated';
    }
  }, [kycStatusCode]);

  const title = useMemo(() => {
    if (emptyStateReason === 'kycNotSubmitted') {
      return t('pageEmptyState.pendingKyc.kycNotSubmitted.title');
    }
    if (emptyStateReason === 'kycNotValidated') {
      return t('pageEmptyState.pendingKyc.kycNotValidated.title');
    }
    return '';
  }, [emptyStateReason]);
  const message = useMemo(() => {
    if (emptyStateReason === 'kycNotSubmitted') {
      return t('pageEmptyState.pendingKyc.kycNotSubmitted.message');
    }
    if (emptyStateReason === 'kycNotValidated') {
      return t('pageEmptyState.pendingKyc.kycNotValidated.message');
    }
    return '';
  }, [emptyStateReason]);
  const ctaLabel = useMemo(() => {
    if (emptyStateReason === 'kycNotSubmitted') {
      return t('pageEmptyState.pendingKyc.kycNotSubmitted.cta');
    }
    if (emptyStateReason === 'kycNotValidated') {
      return t('pageEmptyState.pendingKyc.kycNotValidated.cta');
    }
    return '';
  }, [emptyStateReason]);

  const ctaClicked = () => {
    if (emptyStateReason === 'kycNotSubmitted') {
      startOrContinueProcedure();
    } else if (emptyStateReason === 'kycNotValidated') {
      history.push(routes.HOMEPAGE.path);
    }
  };

  if (
    !emptyStateReason ||
    title.length === 0 ||
    message.length === 0 ||
    ctaLabel.length === 0
  ) {
    return null;
  }

  return (
    <EmptyState
      actions={
        <Button text={ctaLabel} variant="contrasted" onClick={ctaClicked} />
      }
      iconName="info"
      title={title}
      subtitle={message}
    />
  );
};
