/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { BankFields } from 'src/core/utils/bankInfoFormats';

import { AccountCodeFormField } from './AccountCodeFormField';
import { AccountHolderNameFormField } from './AccountHolderNameFormField';
import { AccountNumberFormField } from './AccountNumberFormField';
import { BankCountryFormField } from './BankCountryFormField';
import { BicSwiftFormField } from './BicSwiftFormField';
import { CreateSupplierQueryError } from './CreateSupplierQueryError';
import { IbanFormField } from './IbanFormField';
import { RoutingNumberFormField } from './RoutingNumberFormField';
import { SortCodeFormField } from './SortCodeFormField';
import { UpdateSupplierQueryError } from './UpdateSupplierQueryError';
import { useSupplierBankInfosForm } from '../../hooks';
import {
  type BankDetailsSuggestion,
  type DraftSupplier,
  type SupplierDetails,
  changeSupplierBankInfoBankCountry,
  isUpdateMode,
} from '../../models';

type Props = {
  bankDetailsSuggestion?: BankDetailsSuggestion;
  draftSupplier: DraftSupplier;
  onSupplierCreationOrUpdateSuccess?(supplier: SupplierDetails): void;
};

export const SupplierBankInfoForm = ({
  bankDetailsSuggestion,
  draftSupplier,
  onSupplierCreationOrUpdateSuccess,
}: Props) => {
  const { t } = useTranslation('global');
  const {
    formikProps,
    supplierBankFields,
    createSupplierQueryState,
    updateSupplierQueryState,
  } = useSupplierBankInfosForm({
    bankDetailsSuggestion,
    draftSupplier,
    onSupplierCreationOrUpdateSuccess,
  });

  if (!formikProps) {
    return <></>;
  }

  const { setFieldValue, setValues, errors, values, submitForm } = formikProps;

  return (
    <>
      <BankCountryFormField
        className="my-s"
        bankCountry={values.bankCountry}
        bankCountrySuggestion={bankDetailsSuggestion?.country ?? undefined}
        error={errors.bankCountry}
        onChange={(bankCountry) => {
          setValues(changeSupplierBankInfoBankCountry(values, bankCountry));
        }}
      />

      <AccountHolderNameFormField
        className="my-s"
        isOptional={
          supplierBankFields[BankFields.AccountHolderName]?.isOptional ?? false
        }
        accountHolderName={values.accountHolderName}
        error={errors.accountHolderName}
        onChange={(accountHolderName) => {
          setFieldValue('accountHolderName', accountHolderName);
        }}
      />

      {supplierBankFields[BankFields.AccountNumber] && (
        <AccountNumberFormField
          isOptional={supplierBankFields[BankFields.AccountNumber]!.isOptional}
          className="my-s"
          accountNumber={values.accountNumber}
          error={errors.accountNumber}
          onChange={(accountNumber) =>
            setFieldValue('accountNumber', accountNumber)
          }
        />
      )}

      {supplierBankFields[BankFields.AccountCode] && (
        <AccountCodeFormField
          isOptional={supplierBankFields[BankFields.AccountCode]!.isOptional}
          className="my-s"
          accountCode={values.accountCode}
          error={errors.accountCode}
          onChange={(accountCode) => setFieldValue('accountCode', accountCode)}
        />
      )}

      {supplierBankFields[BankFields.Iban] && (
        <IbanFormField
          isOptional={supplierBankFields[BankFields.Iban]!.isOptional}
          className="my-s"
          bankCountry={values.bankCountry}
          iban={values.iban}
          ibanSuggestion={bankDetailsSuggestion?.iban?.text ?? undefined}
          error={errors.iban}
          onChange={(iban) => setFieldValue('iban', iban)}
        />
      )}

      {supplierBankFields[BankFields.BicSwift] && (
        <BicSwiftFormField
          isOptional={supplierBankFields[BankFields.BicSwift]!.isOptional}
          className="my-s"
          bic={values.bic}
          bicSuggestion={bankDetailsSuggestion?.bic?.text ?? undefined}
          error={errors.bic}
          onChange={(bic) => setFieldValue('bic', bic)}
        />
      )}

      {supplierBankFields[BankFields.SortCode] && (
        <SortCodeFormField
          isOptional={supplierBankFields[BankFields.SortCode]!.isOptional}
          className="my-s"
          sortCode={values.sortCode}
          error={errors.sortCode}
          onChange={(sortCode) => setFieldValue('sortCode', sortCode)}
        />
      )}

      {supplierBankFields[BankFields.RoutingNumber] && (
        <RoutingNumberFormField
          isOptional={supplierBankFields[BankFields.RoutingNumber]!.isOptional}
          className="my-s"
          routingNumber={values.routingNumber}
          error={errors.routingNumber}
          onChange={(routingNumber) =>
            setFieldValue('routingNumber', routingNumber)
          }
        />
      )}

      <Button
        className="mt-xs"
        text={
          // if the supplier is not validated, for the controller it is equivalent to a supplier creation
          isUpdateMode(draftSupplier) && draftSupplier.isSupplierValidated
            ? t('submitMyInvoice.supplier.update')
            : t('submitMyInvoice.supplier.create')
        }
        variant="contrasted"
        onClick={() => submitForm()}
      />

      {createSupplierQueryState.status === 'error' && (
        <CreateSupplierQueryError
          createSupplierError={createSupplierQueryState.error}
        />
      )}

      {updateSupplierQueryState.status === 'error' && (
        <UpdateSupplierQueryError
          updateSupplierError={updateSupplierQueryState.error}
        />
      )}
    </>
  );
};
