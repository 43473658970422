import { print } from 'graphql';
import { useContext } from 'react';

import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import appConfig from 'src/core/config';
import { graphql } from 'src/core/utils/api';
import { poll } from 'src/core/utils/async';

import {
  BATCH_MARK_PAYABLES_AS_PREPARED,
  GET_BATCH_MARK_AS_PREPARED_PAYABLES_PROCESS,
} from './queries';
import { type PayableState } from '../../../payables/models/payable';
import { trackPayableSavedOrMarkedAsReady } from '../../../prepare-payables/analytics';
import {
  PreparePayablesValidationContext,
  PreparePayablesSelectionContext,
} from '../../../prepare-payables/contexts';
import { usePayablesConnectionSelectors } from '../../../prepare-payables/hooks/usePayablesConnectionSelectors';
import {
  bulkUpdateProcessesStore,
  type PayableConnectionSelector,
} from '../../../prepare-payables/models';
import { useRefreshPayableBuckets } from '../useRefreshPayableBuckets';

type BatchMarkPayablesAsPrepareResult = {
  batchMarkPayablesAsPrepared:
    | {
        processId: string;
        __typename: 'BatchMarkPayablesAsPreparedResultPrepareDeferred';
      }
    | {
        reason: string;
        __typename: 'BatchMarkPayablesAsPreparedResultNotPrepared';
      }
    | {
        __typename: 'BatchMarkPayablesAsPreparedResultNothingToPrepare';
      };
};

export type StatusReasons =
  | 'notFound'
  | 'invalidState'
  | 'wrongVersion'
  | 'updateInProgress'
  | 'taxAccountDeleted'
  | 'expenseAccountDeleted'
  | 'missingExpenseAccount'
  | 'missingRequiredCustomField'
  | 'missingTaxAccount'
  | 'missingAccountPayable'
  | 'missingAccountingDate'
  | 'missingInvoiceNumber'
  | 'missingSupplierCountry'
  | 'missingSupplierVatNumber'
  | 'nonUniqueItemLines'
  | 'payableNotFullySettled'
  | 'payableNotReconciledCorrectly'
  | 'payableHasTaxAdjustment'
  | 'incorrectVat'
  | 'defaultAccountNotAttachedToSupplier'
  | 'nothingToUpdate'
  | 'invalidTaxAccounts'
  | 'invalidItemLines'
  | 'invalidExpenseAccounts'
  | 'invalidAccountPayable'
  | 'unmatchingVatRate';

type BatchUpdatePayablesProcessStatusProcessing = {
  batchUpdatePayablesProcessStatus: {
    __typename: 'BatchUpdatePayablesProcessStatusProcessing';
  };
};

type BatchPayablesUpdateProcessStatusCompleted = {
  batchUpdatePayablesProcessStatus: {
    payablesProcessed: {
      edges: {
        node: (
          | {
              status: 'prepared';
            }
          | {
              status: 'notPrepared';
              statusReason: StatusReasons;
            }
        ) & {
          payable: {
            id: string;
            state: PayableState;
            version: number;
            __typename: string;
          };
        };
      }[];
    };
    aggregateSummary: {
      totalCount: number;
      byStatus: {
        status: 'prepared' | 'notPrepared';
        count: number;
        byStatusReason: {
          statusReason: StatusReasons;
          count: number;
        }[];
      }[];
    };
    __typename: 'BatchUpdatePayablesProcessStatusCompleted';
  };
};

type Payload = Partial<{ selectors: PayableConnectionSelector[] }>;

export const useMarkPayablesAsPrepared = (): MutationState<
  Payload,
  BatchMarkPayablesAsPrepareResult
> => {
  return useMutation<
    Payload,
    BatchMarkPayablesAsPrepareResult,
    BatchMarkPayablesAsPrepareResult
  >({
    request: {
      type: 'graphQL',
      target: 'v2',
      query: BATCH_MARK_PAYABLES_AS_PREPARED,
    },
    reshapeData: (data) => {
      return data;
    },
  });
};

export const useMarkPayablesAsPreparedMutation = () => {
  const selectors =
    usePayablesConnectionSelectors() as PayableConnectionSelector[];
  const refreshPayableBuckets = useRefreshPayableBuckets();
  const validationContext = useContext(PreparePayablesValidationContext);
  const selectionContext = useContext(PreparePayablesSelectionContext);

  const [batchMarkPayablesAsPreparedMutation] = useMarkPayablesAsPrepared();

  return async (): Promise<{
    data: {
      countSuccesses: number;
      countFailures: number;
    };
    errors: {
      details: Record<string, StatusReasons>;
      summary: StatusReasons[];
    };
  }> => {
    const batchMarkPayablesAsPreparedResult =
      await batchMarkPayablesAsPreparedMutation({
        selectors,
      });

    if (
      batchMarkPayablesAsPreparedResult.batchMarkPayablesAsPrepared
        ?.__typename !== 'BatchMarkPayablesAsPreparedResultPrepareDeferred'
    ) {
      // FIXME: improve error handling
      throw new Error('Could not batch mark as prepared payables.');
    }

    const { processId } =
      batchMarkPayablesAsPreparedResult.batchMarkPayablesAsPrepared;
    // Store process id
    bulkUpdateProcessesStore.add(processId);

    // Poll for batch update process completion
    const {
      batchUpdatePayablesProcessStatus: { aggregateSummary, payablesProcessed },
    } = (await poll({
      fn: graphql,
      fnParams: [
        print(GET_BATCH_MARK_AS_PREPARED_PAYABLES_PROCESS),
        {
          processId,
        },
        appConfig.apiUrls.graphqlV2,
      ],
      predicate: (
        pollData:
          | BatchUpdatePayablesProcessStatusProcessing
          | BatchPayablesUpdateProcessStatusCompleted,
      ) => {
        return (
          pollData.batchUpdatePayablesProcessStatus.__typename ===
          'BatchUpdatePayablesProcessStatusCompleted'
        );
      },
      initialWaitDuration: 100,
      timeStrategy: (previous) => previous + 150,
      maxIterations: 20,
    })) as BatchPayablesUpdateProcessStatusCompleted;

    const preparedPayablesCount =
      aggregateSummary.byStatus.find((status) => status.status === 'prepared')
        ?.count ?? 0;

    const notPreparedPayablesCount = aggregateSummary.byStatus.reduce(
      (accumulator, status) => {
        if (status.status !== 'prepared') {
          return accumulator + status.count;
        }
        return accumulator;
      },
      0,
    );

    payablesProcessed.edges.forEach(({ node: { payable, ...validation } }) => {
      trackPayableSavedOrMarkedAsReady({
        payableId: payable.id,
        markedAsReady: true,
        error: validation.status === 'notPrepared',
        batchId: processId,
      });
    });

    const validation = Object.fromEntries(
      payablesProcessed.edges.map(({ node: { payable, ...subValidation } }) => [
        payable.id,
        subValidation,
      ]),
    );

    const errorDetails: Record<string, StatusReasons> = Object.fromEntries(
      Object.entries(validation)
        .filter(
          (
            payload,
          ): payload is [
            string,
            {
              status: 'notPrepared';
              statusReason: StatusReasons;
            },
          ] => payload[1].status === 'notPrepared',
        )
        .map(([id, { statusReason }]) => [id, statusReason]),
    );

    const errorSummary = Array.from(new Set(Object.values(errorDetails)));

    const reshapedData = {
      countSuccesses: preparedPayablesCount,
      countFailures: notPreparedPayablesCount,
    };

    if (reshapedData.countSuccesses > 0) {
      refreshPayableBuckets({
        action: 'DECREMENT_PAYABLES_TO_PREPARE_COUNT',
        value: preparedPayablesCount,
      });

      selectionContext.actions.clear();
    }

    validationContext.setState(validation);

    return {
      data: reshapedData,
      errors: {
        details: errorDetails,
        summary: errorSummary,
      },
    };
  };
};
