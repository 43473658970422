import { DATE_FORMAT } from '@dev-spendesk/grapes';
import { differenceInDays, isPast, isToday } from 'date-fns';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type Transfer } from '../../../../models';

export const TransferListRowDueDate = ({
  transfer,
}: {
  transfer: Transfer;
}) => {
  const { t, localeFormat } = useTranslation('global');

  const today = new Date();
  const transferScheduledForDate = new Date(transfer.scheduledForDate);
  const isTransferLate = isPast(transferScheduledForDate);
  const isTransferToday = isToday(transferScheduledForDate);

  if (isTransferToday) {
    return (
      <div className="TransferListRowDueDate text-alert">
        {t('invoices.transfer.dueToday')}
      </div>
    );
  }

  if (isTransferLate) {
    return (
      <div className="TransferListRowDueDate text-alert">
        {t('invoices.transfer.daysLate', {
          count: differenceInDays(today, transferScheduledForDate),
        })}
      </div>
    );
  }

  return (
    <div>{localeFormat(transferScheduledForDate, DATE_FORMAT.MEDIUM)}</div>
  );
};
