import { apiV2Url } from 'src/core/utils/api';

export type Response = {
  procedureId: string;
};

export const postResetPaymentPassword = async (): Promise<Response> => {
  const response = await fetch(apiV2Url('/cards/payment-password/reset'), {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.status === 200) {
    return response.json();
  }
  throw new Error('Payment password reset failed');
};
