import { Button } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useNotifications } from 'modules/app/notifications';
import { postGeneratePaymentPassword } from 'modules/cards/api/postGeneratePaymentPassword';
import { SFSUkMobileRedirectModal } from 'modules/cards/components/SFSUkMobileRedirectModal/SFSUkMobileRedirectModal';
import { SfsUkPasswordEducation } from 'modules/cards/components/SfsUkPasswordEducation/SfsUkPasswordEducation';
import { SfsUkPasswordModal } from 'modules/cards/components/SfsUkPasswordModal/SfsUkPasswordModal';
import { SfsUkPhoneNumberModal } from 'modules/cards/components/SfsUkPhoneNumberModal/SfsUkPhoneNumberModal';
import { getUserFactorsAndCheckIfPhoneActive } from 'modules/physical-cards/apis/getUserFactorsAndCheckIfPhoneActive';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { SessionStorageKey } from 'src/core/constants/storage';
import { SFSUkScaPasswordModal } from 'src/core/modules/cards/components/SFSUkScaPasswordModal/SFSUkScaPasswordModal';
import type { ApiCard } from 'src/core/modules/physical-cards/card';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { getSessionItem as getItem, removeItem } from 'src/core/utils/storage';
import { logger } from 'src/utils/datadog-log-wrapper';

import { usePlasticCard } from '../../../hooks/usePlasticCard';

type Props = {
  isBlocked: boolean;
  cardStatus: ApiCard['status'];
};

const SFSUkRevealOtpButton = ({ isBlocked, cardStatus }: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();
  const hasScaUkEnabled = useFeature(FEATURES.TMP_SCA_PASSWORD_UK);

  useEffect(() => {
    const cardActivated = getItem(SessionStorageKey.CardActivated);
    if (cardActivated) {
      setIsPasswordEducationOpen(true);
      removeItem(SessionStorageKey.CardActivated, sessionStorage);
    }
  }, []);

  const company = useCompany();
  const card = usePlasticCard();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState<string | undefined>();
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);
  const [isPasswordEducationOpen, setIsPasswordEducationOpen] = useState(false);

  const isSFSUk = company.banking_provider === 'sfs' && card.currency === 'GBP';
  if (!isSFSUk || !card || isBlocked) {
    return null;
  }

  const isNonActivatedSfsCard =
    card.banking_provider === 'sfs' && cardStatus === 'PRE';

  const isButtonDisabled = isNonActivatedSfsCard || isLoading;

  return (
    <>
      <Button
        fit="parent"
        variant="secondary"
        iconName="eye"
        text={t('cards.actions.seeOTPModal')}
        onClick={async () => {
          try {
            setIsLoading(true);
            const isMfaActive = await getUserFactorsAndCheckIfPhoneActive();
            if (!isMfaActive) {
              setIsPhoneNumberModalOpen(true);
              return;
            }
            const response = await postGeneratePaymentPassword({
              companyId: company.id,
            });
            setPassword(response.password);
            track(AnalyticEventName.CARD_SEE_OTP_REVEAL);
            setIsOpen(true);
          } catch {
            logger.warn('Failed to generate payment password', {
              team: 'capture',
              scope: 'card::sfs-uk-otp-reveal',
            });
            dangerNotif(t('misc.somethingWrong'));
          } finally {
            setIsLoading(false);
          }
        }}
        isDisabled={isButtonDisabled}
        isLoading={isLoading}
      />
      {isPhoneNumberModalOpen && (
        <SfsUkPhoneNumberModal
          onClose={() => setIsPhoneNumberModalOpen(false)}
          isOpen={isPhoneNumberModalOpen}
        />
      )}
      {isPasswordEducationOpen && (
        <SfsUkPasswordEducation
          onClose={() => {
            setIsPasswordEducationOpen(false);
          }}
          onShowPasswordModal={() => {
            setIsPasswordEducationOpen(false);
            setIsOpen(true);
          }}
        />
      )}
      {password ? (
        <SfsUkPasswordModal
          isOpen={isOpen}
          onClose={() => {
            track(AnalyticEventName.CARD_CLOSE_OTP_REVEAL, {
              trigger: 'user',
            });
            setIsOpen(false);
          }}
          password={password}
        />
      ) : (
        <>
          {hasScaUkEnabled && isOpen && (
            <SFSUkScaPasswordModal
              isOpen
              onClose={() => {
                setIsOpen(false);
              }}
            />
          )}
          {!hasScaUkEnabled && (
            <SFSUkMobileRedirectModal
              isOpen={isOpen}
              onClose={() => {
                setIsOpen(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

const SFSUkRevealOtpButtonWithErrorBoundary = withErrorBoundary({
  team: 'capture',
  scope: 'card::sfs-uk-otp-reveal',
})(SFSUkRevealOtpButton);

export { SFSUkRevealOtpButtonWithErrorBoundary as SFSUkRevealOtpButton };
