/* eslint-disable promise/prefer-await-to-then */
import { type Dispatch, createAction } from '@reduxjs/toolkit';

import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { getUserId } from 'src/core/selectors/users';
import { apiUrl } from 'src/core/utils/api';

import * as types from './actionTypes';

const fetchSlackProviderSettingsLoading = createAction(
  types.FETCH_SLACK_PROVIDER_SETTINGS_LOADING,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchSlackProviderSettingsSuccess = createAction<any>(
  types.FETCH_SLACK_PROVIDER_SETTINGS_SUCCESS,
);
export const fetchSlackProviderSettings =
  () => (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(fetchSlackProviderSettingsLoading());

    return fetch(apiUrl('/slack-provider-settings', companyId), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((slackProviderSettings) =>
        dispatch(fetchSlackProviderSettingsSuccess({ slackProviderSettings })),
      );
  };

const fetchUserApprovalDataLoading = createAction(
  types.FETCH_USER_APPROVAL_DATA_LOADING,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchUserApprovalDataSuccess = createAction<any>(
  types.FETCH_USER_APPROVAL_DATA_SUCCESS,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchUserApprovalDataFailure = createAction<any>(
  types.FETCH_USER_APPROVAL_DATA_FAILURE,
);
export const fetchUserApprovalData =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const userId = getUserId(state);
    const companyId = getCompanyId(state);

    dispatch(fetchUserApprovalDataLoading());

    let userApprovalData;
    try {
      const res = await fetch(apiUrl(`/users/${userId}/approval`, companyId), {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (res.status >= 300) {
        // eslint-disable-next-line no-throw-literal
        throw { response: await res.json() };
      }
      userApprovalData = await res.json();
    } catch (error) {
      return dispatch(fetchUserApprovalDataFailure(error));
    }

    dispatch(fetchUserApprovalDataSuccess(userApprovalData));
  };
