export const FilterLayout = ({
  groupOperator,
  dimension,
  values,
  actions,
}: {
  groupOperator: React.ReactNode;
  dimension: React.ReactNode;
  values: React.ReactNode;
  actions: React.ReactNode;
}) => (
  <div className="flex items-center justify-between gap-s">
    <div className="w-[100px]">{groupOperator}</div>
    <div className="w-full grow">{dimension}</div>
    <div className="w-full grow">{values}</div>
    <div className="w-xl">{actions}</div>
  </div>
);
