import { z } from 'zod';

export const apiPartnerSchema = z
  .object({
    name: z.string().nonempty(),
    logo: z.string().default(''),
    website: z.string().default(''),
    scope: z.string().nonempty(),
  })
  .required();

export type ApiPartner = z.infer<typeof apiPartnerSchema>;

export const tokenListSchema = z.array(
  z
    .object({
      id: z.string().nonempty(),
      creatorId: z.string().optional(),
      active: z.boolean(),
      created: z.date({ coerce: true }),
      updated: z.date({ coerce: true }),
    })
    .merge(apiPartnerSchema)
    .required(),
);
export type TokenList = z.infer<typeof tokenListSchema>;
