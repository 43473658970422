import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

export type UsersManagedByKomboHr = {
  managedUsers: Map<string, boolean>;
  managedFields: string[];
  usedTool: string;
};
export type RawUsersManagedByKomboHr = {
  managedEmployees: {
    userId: string;
    managedByIntegration: boolean;
  }[];
  managedFields: string[];
  usedTool: string | null;
};

export const useGetManagedUsersByKomboHrIntegrationQuery = (): QueryState<
  UsersManagedByKomboHr | undefined,
  unknown
> => {
  const isKomboEnabled = useFeature(FEATURES.HR_INTEGRATIONS);

  return useQuery<UsersManagedByKomboHr | undefined, RawUsersManagedByKomboHr>({
    key: ['integrations', 'kombo', 'managed-employees'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/integrations/kombo/managed-employees`,
    },
    options: {
      // NOTE: For now, our job with updates run every 5 mins. Review this after outbox event migration
      cacheTime: 5 * 60 * 1000, // 5min,
      staleTime: 5 * 60 * 1000, // 5min,
    },
    isEnabled: isKomboEnabled,
    reshapeData: (data) => {
      if (!data.usedTool) {
        return undefined;
      }
      const managedEmployeesMap = data.managedEmployees.reduce(
        (accumulator, employee) => {
          if (employee.userId) {
            accumulator.set(
              employee.userId,
              employee.managedByIntegration ?? false,
            );
          }
          return accumulator;
        },
        new Map<string, boolean>(),
      );

      return {
        managedUsers: managedEmployeesMap,
        managedFields: data.managedFields,
        usedTool: data.usedTool,
      };
    },
  });
};
