import { useCardsAccessQuery } from './useCardsAccessQuery';
import { useDisplayMode } from './useDisplayMode';

export const useHomepageMode = (): 'normal' | 'danger' => {
  const displayMode = useDisplayMode();

  const cardsAccessQueryState = useCardsAccessQuery();
  const blockedCardsAccess =
    cardsAccessQueryState.status === 'success' &&
    !cardsAccessQueryState.data.hasAccess;

  return displayMode === 'requester' && blockedCardsAccess
    ? 'danger'
    : 'normal';
};
