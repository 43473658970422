/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { SelectionState } from './createSelectionReducer';

export enum SelectionNodeState {
  None = 'none',
  Partial = 'partial',
  Selected = 'selected',
}

export type SelectionNodeRef = string;

export type Selection = Map<SelectionNodeRef, SelectionNode>;

type SelectionNode = {
  state: SelectionNodeState;
  parentRef?: SelectionNodeRef;
  children?: Set<SelectionNodeRef>;
  selectedChildrenCount?: number;
};

type SelectionEntry = [SelectionNodeRef, SelectionNode];

type SelectionInput = { [key: string]: SelectionInput };

function* walkSelectionInput(
  node: SelectionInput,
  state: SelectionNodeState.Selected | SelectionNodeState.None,
  parentReference?: SelectionNodeRef,
): IterableIterator<SelectionEntry> {
  // Loop through key/values of the current node
  for (const [key, value] of Object.entries(node)) {
    const children = new Set(Object.keys(value));
    const hasChildren = children.size > 0;
    // Build a selection entry object for the current node
    const selectionEntry: SelectionEntry = [
      key,
      {
        state,
        ...(parentReference ? { parentRef: parentReference } : {}),
        ...(hasChildren ? { children } : {}),
        ...(hasChildren
          ? {
              selectedChildrenCount:
                state === SelectionNodeState.Selected ? children.size : 0,
            }
          : {}),
      },
    ];

    // Yield the result so we're done with this node
    yield selectionEntry;

    // Recursively process child nodes
    yield* walkSelectionInput(value, state, key);
  }
}

export const buildSelection = (
  input: SelectionInput,
  defaultState:
    | SelectionNodeState.Selected
    | SelectionNodeState.None = SelectionNodeState.None,
): Selection => {
  const selection: Selection = new Map();

  // Extract all nested key/values from the input and push nodes to selection
  for (const [key, value] of walkSelectionInput(input, defaultState)) {
    selection.set(key, value);
  }

  return selection;
};

const updateNode = (
  selectionNodeReference: SelectionNodeRef,
  update: Partial<SelectionNode>,
  selection: Selection,
): Selection => {
  const previousNode = selection.get(selectionNodeReference);

  if (!previousNode) {
    throw new Error(`Could not find ${selectionNodeReference} in selection`);
  }

  let nextSelectedChildrenCount;

  if (
    previousNode.children &&
    update.state !== undefined &&
    update.state !== previousNode.state
  ) {
    // If the node has children and we change its state, then we need to
    // update its count of selected children accordingly (their state will
    // reflect their parent's)

    if (update.state === SelectionNodeState.None) {
      nextSelectedChildrenCount = 0;
    }
    if (update.state === SelectionNodeState.Selected) {
      nextSelectedChildrenCount = update.children
        ? update.children.size
        : previousNode.children.size;
    }
  }

  const nextNode = {
    ...previousNode,
    ...update,
    ...(nextSelectedChildrenCount !== undefined
      ? { selectedChildrenCount: nextSelectedChildrenCount }
      : {}),
  };

  return selection.set(selectionNodeReference, nextNode);
};

const updateChildNodes = (
  selectionNodeReference: SelectionNodeRef,
  nextState: SelectionNodeState,
  selection: Selection,
): Selection => {
  const node = selection.get(selectionNodeReference)!;

  if (!node.children) {
    return selection;
  }

  // Turn children's Set to an Array so we can reduce it
  const childNodesReferences = Array.from(node.children);

  return childNodesReferences.reduce((finalSelection, childNodeReference) => {
    // Reflect new parent's state to its child nodes
    const nextSelection = updateNode(
      childNodeReference,
      { state: nextState },
      finalSelection,
    );

    // Recursively reflect the state to nested child nodes
    return updateChildNodes(childNodeReference, nextState, nextSelection);
  }, selection);
};

const updateParentNodes = (
  selectionNode: SelectionNode,
  operation: number,
  selection: Selection,
): Selection => {
  const { parentRef } = selectionNode;

  if (!parentRef) {
    return selection;
  }

  const parentNode = selection.get(parentRef);

  if (!parentNode) {
    throw new Error(`Could not find ${parentRef} in selection`);
  }

  // Apply the operation on the count of selected children
  const nextParentNodeSelectedChildrenCount =
    parentNode.selectedChildrenCount! + operation;

  let nextParentNodeState;
  let nextOperation;

  // Determine the new state of the current node
  if (nextParentNodeSelectedChildrenCount === parentNode.children!.size) {
    // If all children are now selected, the parent should reflect that state
    nextParentNodeState = SelectionNodeState.Selected;
  } else if (nextParentNodeSelectedChildrenCount === 0) {
    // If all children are now unselected, the parent should reflect that state
    nextParentNodeState = SelectionNodeState.None;
  } else {
    // If not all children have the same state, then parent's state is partial
    nextParentNodeState = SelectionNodeState.Partial;
  }

  // Based on the state transition of the current node, determine the next
  // operation to apply to the current's node parent
  if (
    parentNode.state === SelectionNodeState.None &&
    (nextParentNodeState === SelectionNodeState.Selected ||
      nextParentNodeState === SelectionNodeState.Partial)
  ) {
    // If state was none and now is selected or partial, we will increment the
    // parent's node selected chilren count
    nextOperation = +1;
  } else if (
    parentNode.state === SelectionNodeState.Selected &&
    (nextParentNodeState === SelectionNodeState.None ||
      nextParentNodeState === SelectionNodeState.Partial)
  ) {
    // If state was selected and now is none  or partial, we will decrement the
    // parent's node selected children count
    nextOperation = -1;
  } else {
    // In all other cases, the parent's node children will stay unchanged
    nextOperation = 0;
  }

  // Update the current node
  const nextParentNode = {
    ...parentNode,
    selectedChildrenCount: nextParentNodeSelectedChildrenCount,
    state: nextParentNodeState,
  };
  const nextSelection = selection.set(parentRef, nextParentNode);

  // Recursively update all parent nodes of the current node
  return updateParentNodes(parentNode, nextOperation, nextSelection);
};

export const handleSelectionNodeStateChange = (
  selectionNodeReference: SelectionNodeRef,
  nextState: SelectionNodeState.Selected | SelectionNodeState.None,
  selection: Selection,
): Selection => {
  const node = selection.get(selectionNodeReference);

  if (!node) {
    throw new Error(`Could not find ${selectionNodeReference} in selection`);
  }

  const updatedNode = updateNode(
    selectionNodeReference,
    { state: nextState },
    new Map(selection),
  );
  const updatedChildNodes: Selection = updateChildNodes(
    selectionNodeReference,
    nextState,
    updatedNode,
  );
  return updateParentNodes(
    node,
    nextState === SelectionNodeState.Selected ? +1 : -1,
    updatedChildNodes,
  );
};

const appendChildNodes = (
  parentReference: SelectionNodeRef,
  childNodesReferences: SelectionNodeRef[],
  targetState: SelectionNodeState,
  selection: Selection,
): Selection => {
  return childNodesReferences.reduce((nextSelection, ref) => {
    // Add each child node to the selection
    return nextSelection.set(ref, {
      state: targetState,
      parentRef: parentReference,
    });
  }, selection);
};

export const insertSelectionNodes = (
  selection: Selection,
  parentReference: SelectionNodeRef,
  input: SelectionInput,
): Selection => {
  const parentNode = selection.get(parentReference);

  if (!parentNode) {
    throw new Error(`Could not find ${parentReference} in selection`);
  }

  const targetState = parentNode.state;
  const childNodesReferences = Object.keys(input);
  const nextParentNodeChildren = new Set([
    ...(parentNode.children || []),
    ...childNodesReferences,
  ]);

  const updatedParentNode = updateNode(
    parentReference,
    { children: nextParentNodeChildren },
    new Map(selection),
  );
  return appendChildNodes(
    parentReference,
    childNodesReferences,
    targetState,
    updatedParentNode,
  );
};

export const isSelectionNodeState = (
  selectionNodeReference: SelectionNodeRef,
  state: SelectionNodeState,
  selection: SelectionState,
): boolean => {
  // We allow for not passing a selection because we will often call this before
  // the selection has been created
  if (!selection) {
    return false;
  }

  const node = selection.get(selectionNodeReference);

  if (!node) {
    return false;
  }

  return node.state === state;
};

export const getSelectedNodeRefs = (
  selection: Selection,
  nodeReference: SelectionNodeRef,
): string[] => {
  return Array.from(selection)
    .filter(
      ([_, value]) =>
        value.parentRef === nodeReference &&
        value.state === SelectionNodeState.Selected,
    )
    .map(([key]) => key);
};

export const removeSelectionNodes = (
  selection: Selection,
  nodeReferencesToRemove: SelectionNodeRef[],
): Selection => {
  let nextSelection = new Map(selection); // Make a copy of the selection first

  for (const nodeReference of nodeReferencesToRemove) {
    const node = nextSelection.get(nodeReference);

    if (!node) {
      // Trying to remove a node that doesn't belong to a selection doesn't
      // really make sense but it's harmless. It's simpler to ignore it than
      // to enforce the consumer to first check that the node belongs to the
      // selection.
      continue;
    }

    const parentNode = nextSelection.get(node.parentRef!);

    if (!parentNode) {
      throw new Error(`Could not find ${node.parentRef} in selection`);
    }

    const nextParentNodeChildren = new Set(parentNode.children);
    nextParentNodeChildren.delete(nodeReference);
    const nextParentNodeSelectedChildrenCount =
      node.state === SelectionNodeState.Selected
        ? parentNode.selectedChildrenCount! - 1
        : parentNode.selectedChildrenCount;
    let nextParentNodeState;

    if (nextParentNodeSelectedChildrenCount === 0) {
      // If all children are now unselected, the parent should reflect that state
      nextParentNodeState = SelectionNodeState.None;
    } else if (
      nextParentNodeSelectedChildrenCount === nextParentNodeChildren!.size
    ) {
      // If all children are now selected, the parent should reflect that state
      nextParentNodeState = SelectionNodeState.Selected;
    } else {
      // If not all children have the same state, then parent's state is partial
      nextParentNodeState = SelectionNodeState.Partial;
    }

    nextSelection = updateNode(
      node.parentRef!,
      {
        state: nextParentNodeState,
        children: nextParentNodeChildren,
        selectedChildrenCount: nextParentNodeSelectedChildrenCount,
      },
      nextSelection,
    );

    nextSelection.delete(nodeReference);
  }

  return nextSelection;
};
