import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { type DefaultSupplierAccount } from 'modules/bookkeep/settings/accounting/supplierAccount';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from '../ModalState';

type ConfirmDeleteSupplierAccountModalProps = {
  setModalState: Dispatch<SetStateAction<ModalState>>;
  modalState: ModalState;
  defaultSupplierAccount: DefaultSupplierAccount[];
  handleDeleteSpendeskAccount: () => void;
};

export const ConfirmDeleteSupplierAccountModal = ({
  setModalState,
  modalState,
  defaultSupplierAccount,
  handleDeleteSpendeskAccount,
}: ConfirmDeleteSupplierAccountModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t(
        'bookkeep.integrations.settings.supplierAccountsTable.confirmDefaultDeleteTitle',
        {
          code: defaultSupplierAccount[0]
            ? defaultSupplierAccount[0].generalAccountCode
            : '',
        },
      )}
      subtitle={t(
        'bookkeep.integrations.settings.supplierAccountsTable.confirmDefaultDeleteSubTitle',
      )}
      isOpen={modalState.kind === 'confirmDelete'}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          id="confirm-delete-supplier-account"
          onClick={() => handleDeleteSpendeskAccount()}
          text={t(
            'bookkeep.integrations.settings.supplierAccountsTable.confirmDelition',
          )}
          variant="alert"
        />,
      ]}
    />
  );
};
