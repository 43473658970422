import * as requestActions from './actions';
import {
  type RequestState,
  type UpdateFailure,
  type RequestAction,
} from '../types';

export const initialState: RequestState = {
  reimbursements: [],
  isFetching: false,
  isUpdating: false,
  error: null,
};

const updateRequest = (state: RequestState) => ({
  ...state,
  isUpdating: true,
});

const updateSuccess = (state: RequestState) => ({
  ...state,
  isUpdating: false,
});

const updateFailure = (state: RequestState, action: UpdateFailure) => ({
  ...state,
  isUpdating: false,
  error: action.payload,
});

export const reducer = (
  state: RequestState = initialState,
  action: RequestAction,
): RequestState => {
  switch (action.type) {
    case requestActions.UPDATE_REQUEST:
      return updateRequest(state);
    case requestActions.UPDATE_SUCCESS:
      return updateSuccess(state);
    case requestActions.UPDATE_FAILURE:
      return updateFailure(state, action);
    default:
      return state;
  }
};
