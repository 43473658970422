import { Button, Modal } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { type AppDispatch } from 'modules/app/redux/store';
import { useUpdateProfileMutation } from 'modules/profile/hooks';
import { updateMeLocally } from 'src/core/actions/users';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import glassHourGeorges from './glassHourGeorges.svg';
import { useInvalidateScreeningInformationStatusQuery } from '../../hooks/useScreeningInformationStatusQuery';
import { ScreeningForm } from '../MemberScreeningModal/ScreeningForm';

type Props = {
  isOpen: boolean;
  onContinue: () => void;
  onClose: () => void;
};

export const MemberScreeningStepModal = ({
  isOpen,
  onContinue,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');
  const user = useUser();

  const dispatch = useDispatch<AppDispatch>();
  const [updateProfileMutation] = useUpdateProfileMutation();

  const invalidateScreeningInformationStatusQuery =
    useInvalidateScreeningInformationStatusQuery(user.id);

  return (
    <Modal
      illustration={<img src={glassHourGeorges} alt="" />}
      illustrationHeight="338px"
      // @ts-expect-error Grapes hacking time because Georges's arm is hanging out
      title={<div className="-mt-m">{t('members.screeningModal.title')}</div>}
      // @ts-expect-error Grapes hacking time for Trans component
      subtitle={
        <Trans
          i18nKey="members.screeningModal.subtitle"
          components={{
            a: (
              <a
                href="https://helpcenter.spendesk.com/articles/8866182"
                target="_blank"
                rel="noreferrer"
              >
                -
              </a>
            ),
          }}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <ScreeningForm
        user={user}
        onSuccess={async (data) => {
          await updateProfileMutation(data);
          dispatch(updateMeLocally(data));
          await invalidateScreeningInformationStatusQuery();
          onContinue();
        }}
        renderButtons={(isLoading, onClick) => (
          <Button
            text={t('misc.continue')}
            onClick={onClick}
            isLoading={isLoading}
          />
        )}
      />
    </Modal>
  );
};
