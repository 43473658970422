import { useState } from 'react';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type MigrationStatus =
  | 'migratedByStaff'
  | 'migratedByUser'
  | 'pending'
  | 'inProgress'
  | 'failed'
  | 'migratable'
  | 'notMigratable'
  | 'unexpectedStatus';

type Migration = {
  status: MigrationStatus;
};

export const useMigrationStatus = (): QueryState<Migration, Migration> => {
  const [refetchInterval, setRefetchInterval] = useState<number>();

  return useQuery<Migration, Migration>({
    key: ['cost-centers', 'migration'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/cost-centers/migration',
    },
    options: {
      refetchInterval,
      onSuccess({ data }): void {
        // if pending/inProgress status, we poll the API every 2s
        switch (data.status) {
          case 'pending':
          case 'inProgress':
            setRefetchInterval(2000);
            break;
          default:
            setRefetchInterval(undefined);
        }
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};
