import { IconButton } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useState } from 'react';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { DangerConfirmationModal } from 'src/core/common/components/DangerConfirmationModal';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './CustomExportsListItem.module.css';
import { type TemplateItem } from '../../../template';

type Props = {
  templateItem: TemplateItem;
  deleteTemplate: (templateId: string) => Promise<void>;
  setEditingCustomExport: (template: TemplateItem) => void;
  setIsCreateExportModalActive: (isActive: boolean) => void;
};

export const CustomExportsListItem = ({
  templateItem,
  deleteTemplate,
  setEditingCustomExport,
  setIsCreateExportModalActive,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const [isDeleteCeModalOpen, setIsDeleteCeModalOpen] = useState(false);
  const [deletingCustomExport, setDeletingCustomExport] =
    useState<TemplateItem | null>(null);

  const deleteCustomExport = async (targetId: string) => {
    try {
      await deleteTemplate(targetId);
    } catch (error) {
      pushNotif({
        type: NotificationType.Danger,
        message: t('exports.messages.errorDeleteExport'),
      });
      throw error;
    }

    setIsDeleteCeModalOpen(false);
    setDeletingCustomExport(null);
    pushNotif({
      message: t('exports.messages.successDeleteExport'),
    });
  };

  const { name, isDefault } = templateItem;

  return (
    <div
      className={classNames(
        'flex items-center justify-between',
        styles.customExportsListItem,
      )}
      key={templateItem.id}
    >
      {name}
      {!isDefault && (
        <div
          className={classNames(
            '-mr-xs hidden',
            styles.customExportsListItemActions,
          )}
        >
          <IconButton
            variant="borderless"
            iconName="pen"
            onClick={() => {
              setEditingCustomExport(templateItem);
              setIsCreateExportModalActive(true);
            }}
            aria-label={t('misc.edit')}
          />
          <IconButton
            variant="borderless"
            iconName="trash"
            onClick={() => {
              setIsDeleteCeModalOpen(true);
              setDeletingCustomExport(templateItem);
            }}
            aria-label={t('misc.delete')}
          />
          {deletingCustomExport && (
            <DangerConfirmationModal
              isOpen={isDeleteCeModalOpen}
              title={t('exports.deleteModalTitle')}
              description={t('exports.deleteModalContent', {
                name: deletingCustomExport.name,
              })}
              withRejectionMessage={false}
              actionText={t('misc.yesConfirm')}
              onConfirm={() => deleteCustomExport(deletingCustomExport.id)}
              onClose={() => setIsDeleteCeModalOpen(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};
