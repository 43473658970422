import { useFeature } from 'common/hooks/useFeature';
import { useAccountingIntegrationStatusQuery } from 'modules/accounting-integration/apis';
import FEATURES from 'src/core/constants/features';

import {
  type AccountingSoftware,
  type IntegrationStatus,
} from '../../integration/status';

// This hook is useful to check if a company is eligible to the opt-in analytical split feature
// In order to check if a company has the feature activated you shoul use the `useAnalyticalSplitStatus()` hook
// See src/core/modules/bookkeep/hooks/useAnalyticalSplitStatus.ts

export function useIsAnalyticalSplitAvailable(
  providedAccountingIntegration?:
    | AccountingSoftware
    | 'noIntegration'
    | 'switchInProgress',
) {
  let accountingIntegration;

  const accountingIntegrationQueryResult =
    useAccountingIntegrationStatusQuery();

  if (providedAccountingIntegration) {
    accountingIntegration = providedAccountingIntegration;
  } else {
    accountingIntegration =
      accountingIntegrationQueryResult.status === 'success'
        ? accountingIntegrationQueryResult.data.integration
        : null;
  }

  const isAccountingIntegrationAllowed =
    (accountingIntegration &&
      !forbiddenAccountingIntegrations.includes(accountingIntegration)) ||
    false;

  return (
    useFeature(FEATURES.ANALYTICAL_SPLIT) && isAccountingIntegrationAllowed
  );
}

export function useHasOptedInToAnalyticalSplit() {
  return useFeature(FEATURES.HAS_OPTED_IN_TO_ANALYTICAL_SPLIT);
}

const forbiddenAccountingIntegrations: IntegrationStatus['integration'][] = [
  'noIntegration',
  'switchInProgress',
  'Netsuite',
  'Quickbooks',
];
