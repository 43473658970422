import { useHasAccountingIntegrationCapability } from 'modules/accounting-integration/apis';

// TODO: Double check in different PR if we still need to check in tax accounts for the accounts payable multiple suppliers capability.
export const useHasDefaultForCapability = () => {
  const hasTaxAccountsCapability = useHasAccountingIntegrationCapability(
    'taxAccounts',
    'localOnlyWithDefaultAccounts',
  );
  const hasSupplierAccountsCapability = useHasAccountingIntegrationCapability(
    'supplierAccounts',
    'pullWithDefaultAccounts',
  );

  return hasTaxAccountsCapability || hasSupplierAccountsCapability;
};
