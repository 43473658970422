import { Modal, Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

type Props = {
  message?: string;
  onClose: () => void;
  onRetry?: () => void;
};

export const ModalError = ({ message, onClose, onRetry }: Props) => {
  const { t } = useTranslation();

  const CloseButton = (
    <Button
      text={t('errorModal.closeButton')}
      onClick={onClose}
      variant="secondary"
      key="close-button"
    />
  );
  const RetryButton = (
    <Button
      text={t('errorModal.retryButton')}
      onClick={onRetry}
      variant="primary"
      key="retry-button"
    />
  );
  const actions = [CloseButton, ...(onRetry ? [RetryButton] : [])];

  return (
    <Modal
      actions={actions}
      iconName="failure"
      iconVariant="alert"
      title={t('errorModal.title')}
      subtitle={message}
      isOpen
    />
  );
};
