import {
  type GetAchFundingSourcesLoading,
  type GetAchFundingSourcesFailure,
  type GetAchFundingSourcesSuccess,
  type CheckBankStatementLoading,
  type CheckBankStatementFailure,
  type CheckBankStatementSuccess,
  type GetAutomatedFundingLoading,
  type GetAutomatedFundingFailure,
  type GetAutomatedFundingSuccess,
  type CreateFundingRequestLoading,
  type CreateFundingRequestFailure,
  type CreateFundingRequestSuccess,
  type CreateAutomatedFundingRuleLoading,
  type CreateAutomatedFundingRuleFailure,
  type CreateAutomatedFundingRuleSuccess,
  type DeleteAutomatedFundingRuleLoading,
  type DeleteAutomatedFundingRuleFailure,
  type DeleteAutomatedFundingRuleSuccess,
  type ValidateOneOffFundingRequestPinLoading,
  type ValidateOneOffFundingRequestPinSuccess,
  type ValidateOneOffFundingRequestPinFailure,
  type ValidateAutomatedFundingRulePinLoading,
  type ValidateAutomatedFundingRulePinSuccess,
  type ValidateAutomatedFundingRulePinFailure,
  type GetGpaOrdersLoading,
  type GetGpaOrdersFailure,
  type GetGpaOrdersSuccess,
  type SetMfaProcedureId,
  type SetMfaType,
  type SetMfaParams,
  GET_ACH_FUNDING_SOURCES_LOADING,
  GET_ACH_FUNDING_SOURCES_FAILURE,
  GET_ACH_FUNDING_SOURCES_SUCCESS,
  CHECK_BANK_STATEMENT_LOADING,
  CHECK_BANK_STATEMENT_FAILURE,
  CHECK_BANK_STATEMENT_SUCCESS,
  GET_AUTOMATED_FUNDING_RULE_LOADING,
  GET_AUTOMATED_FUNDING_RULE_FAILURE,
  GET_AUTOMATED_FUNDING_RULE_SUCCESS,
  CREATE_FUNDING_REQUEST_LOADING,
  CREATE_FUNDING_REQUEST_FAILURE,
  CREATE_FUNDING_REQUEST_SUCCESS,
  CREATE_AUTOMATED_FUNDING_RULE_LOADING,
  CREATE_AUTOMATED_FUNDING_RULE_FAILURE,
  CREATE_AUTOMATED_FUNDING_RULE_SUCCESS,
  DELETE_AUTOMATED_FUNDING_RULE_LOADING,
  DELETE_AUTOMATED_FUNDING_RULE_FAILURE,
  DELETE_AUTOMATED_FUNDING_RULE_SUCCESS,
  SET_MFA_PROCEDURE_ID,
  SET_MFA_TYPE,
  SET_MFA_PARAMS,
  VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_LOADING,
  VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_FAILURE,
  VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_SUCCESS,
  VALIDATE_AUTOMATED_FUNDING_RULE_PIN_LOADING,
  VALIDATE_AUTOMATED_FUNDING_RULE_PIN_FAILURE,
  VALIDATE_AUTOMATED_FUNDING_RULE_PIN_SUCCESS,
  SET_WALLET_FUNDING_STEP,
  SET_WALLET_AUTOMATED_FUNDING_STEP,
  SET_WALLET_AUTOMATED_FUNDING_CREATE_STEP,
  SET_WALLET_AUTOMATED_FUNDING_DELETE_STEP,
  GET_GPA_ORDERS_LOADING,
  GET_GPA_ORDERS_FAILURE,
  GET_GPA_ORDERS_SUCCESS,
  type SetWalletFundingStep,
  type SetWalletAutomatedFundingStep,
  type SetWalletAutomatedFundingCreateStep,
  type SetWalletAutomatedFundingDeleteStep,
  type OneOffFundingRequestCreateSteps,
  type AutomatedFundingRuleLoadingSteps,
  type AutomatedFundingRuleCreateSteps,
  type AutomatedFundingRuleDeleteSteps,
  type GpaOrder,
  type AutomatedFundingRule,
} from '../types';

export const getAchFundingSourcesLoading = (): GetAchFundingSourcesLoading => ({
  type: GET_ACH_FUNDING_SOURCES_LOADING,
});

export const getAchFundingSourcesFailure = (
  error: Error,
): GetAchFundingSourcesFailure => ({
  type: GET_ACH_FUNDING_SOURCES_FAILURE,
  payload: error,
});

export const getAchFundingSourcesSuccess = ({
  achSourceToken,
  achSourceAccountSuffix,
  hasPendingAch,
  hasValidatedAch,
  pendingAchCreatedAt,
}: GetAchFundingSourcesSuccess['payload']): GetAchFundingSourcesSuccess => ({
  type: GET_ACH_FUNDING_SOURCES_SUCCESS,
  payload: {
    achSourceToken,
    achSourceAccountSuffix,
    hasPendingAch,
    hasValidatedAch,
    pendingAchCreatedAt,
  },
});

export const checkBankStatementLoading = (): CheckBankStatementLoading => ({
  type: CHECK_BANK_STATEMENT_LOADING,
});

export const checkBankStatementFailure = (
  error: Error,
): CheckBankStatementFailure => ({
  type: CHECK_BANK_STATEMENT_FAILURE,
  payload: error,
});

export const checkBankStatementSuccess = (): CheckBankStatementSuccess => ({
  type: CHECK_BANK_STATEMENT_SUCCESS,
});

export const getAutomatedFundingRuleLoading =
  (): GetAutomatedFundingLoading => ({
    type: GET_AUTOMATED_FUNDING_RULE_LOADING,
  });

export const getAutomatedFundingRuleFailure = (
  error: Error,
): GetAutomatedFundingFailure => ({
  type: GET_AUTOMATED_FUNDING_RULE_FAILURE,
  payload: error,
});

export const getAutomatedFundingRuleSuccess = (
  automatedFundingRule: AutomatedFundingRule,
): GetAutomatedFundingSuccess => ({
  type: GET_AUTOMATED_FUNDING_RULE_SUCCESS,
  payload: automatedFundingRule,
});

export const createFundingRequestLoading = (): CreateFundingRequestLoading => ({
  type: CREATE_FUNDING_REQUEST_LOADING,
});

export const createFundingRequestFailure = (
  error: Error,
): CreateFundingRequestFailure => ({
  type: CREATE_FUNDING_REQUEST_FAILURE,
  payload: error,
});

export const createFundingRequestSuccess = (
  mfaProcedureId: string,
): CreateFundingRequestSuccess => ({
  type: CREATE_FUNDING_REQUEST_SUCCESS,
  payload: {
    mfaProcedureId,
  },
});

export const createAutomatedFundingRuleLoading =
  (): CreateAutomatedFundingRuleLoading => ({
    type: CREATE_AUTOMATED_FUNDING_RULE_LOADING,
  });

export const createAutomatedFundingRuleFailure = (
  error: Error,
): CreateAutomatedFundingRuleFailure => ({
  type: CREATE_AUTOMATED_FUNDING_RULE_FAILURE,
  payload: error,
});

export const createAutomatedFundingRuleSuccess = (
  mfaProcedureId: string,
  amount: number,
  threshold: number,
  date: string,
): CreateAutomatedFundingRuleSuccess => ({
  type: CREATE_AUTOMATED_FUNDING_RULE_SUCCESS,
  payload: {
    mfaProcedureId,
    amount,
    threshold,
    date,
  },
});

export const deleteAutomatedFundingRuleLoading =
  (): DeleteAutomatedFundingRuleLoading => ({
    type: DELETE_AUTOMATED_FUNDING_RULE_LOADING,
  });

export const deleteAutomatedFundingRuleFailure = (
  error: Error,
): DeleteAutomatedFundingRuleFailure => ({
  type: DELETE_AUTOMATED_FUNDING_RULE_FAILURE,
  payload: error,
});

export const deleteAutomatedFundingRuleSuccess =
  (): DeleteAutomatedFundingRuleSuccess => ({
    type: DELETE_AUTOMATED_FUNDING_RULE_SUCCESS,
  });

export const setMfaType = (mfaType: string): SetMfaType => ({
  type: SET_MFA_TYPE,
  payload: mfaType,
});

export const setMfaParams = (mfaParams: string): SetMfaParams => ({
  type: SET_MFA_PARAMS,
  payload: mfaParams,
});

export const setMfaProcedureId = (
  mfaProcedureId: string,
): SetMfaProcedureId => ({
  type: SET_MFA_PROCEDURE_ID,
  payload: mfaProcedureId,
});

export const validateOneOffFundingRequestPinLoading =
  (): ValidateOneOffFundingRequestPinLoading => ({
    type: VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_LOADING,
  });

export const validateOneOffFundingRequestPinFailure = (
  error: Error,
): ValidateOneOffFundingRequestPinFailure => ({
  type: VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_FAILURE,
  payload: error,
});

export const validateOneOffFundingRequestPinSuccess =
  (): ValidateOneOffFundingRequestPinSuccess => ({
    type: VALIDATE_ONE_OFF_FUNDING_REQUEST_PIN_SUCCESS,
  });

export const validateAutomatedFundingRulePinLoading =
  (): ValidateAutomatedFundingRulePinLoading => ({
    type: VALIDATE_AUTOMATED_FUNDING_RULE_PIN_LOADING,
  });

export const validateAutomatedFundingRulePinFailure = (
  error: Error,
): ValidateAutomatedFundingRulePinFailure => ({
  type: VALIDATE_AUTOMATED_FUNDING_RULE_PIN_FAILURE,
  payload: error,
});

export const validateAutomatedFundingRulePinSuccess =
  (): ValidateAutomatedFundingRulePinSuccess => ({
    type: VALIDATE_AUTOMATED_FUNDING_RULE_PIN_SUCCESS,
  });

export const setWalletOneOffFundingStep = (
  oneOffFundingRequestCreateStep: OneOffFundingRequestCreateSteps,
): SetWalletFundingStep => ({
  type: SET_WALLET_FUNDING_STEP,
  payload: oneOffFundingRequestCreateStep,
});

export const setWalletAutomatedFundingStep = (
  automatedFundingRuleLoadingStep: AutomatedFundingRuleLoadingSteps,
): SetWalletAutomatedFundingStep => ({
  type: SET_WALLET_AUTOMATED_FUNDING_STEP,
  payload: automatedFundingRuleLoadingStep,
});

export const setWalletAutomatedFundingCreateRuleStep = (
  automatedFundingRuleCreateStep: AutomatedFundingRuleCreateSteps,
): SetWalletAutomatedFundingCreateStep => ({
  type: SET_WALLET_AUTOMATED_FUNDING_CREATE_STEP,
  payload: automatedFundingRuleCreateStep,
});

export const setWalletAutomatedFundingDeleteRuleStep = (
  automatedFundingRuleDeleteStep: AutomatedFundingRuleDeleteSteps,
): SetWalletAutomatedFundingDeleteStep => ({
  type: SET_WALLET_AUTOMATED_FUNDING_DELETE_STEP,
  payload: automatedFundingRuleDeleteStep,
});

export const getGpaOrdersLoading = (): GetGpaOrdersLoading => ({
  type: GET_GPA_ORDERS_LOADING,
});

export const getGpaOrdersFailure = (error: Error): GetGpaOrdersFailure => ({
  type: GET_GPA_ORDERS_FAILURE,
  payload: error,
});

export const getGpaOrdersSuccess = (
  gpaOrders: GpaOrder[],
): GetGpaOrdersSuccess => ({
  type: GET_GPA_ORDERS_SUCCESS,
  gpaOrders,
});
