import { Navigation } from '@dev-spendesk/grapes';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useCompany } from 'modules/app/hooks/useCompany';
import { routes } from 'src/core/constants/routes';

import navLinks from '../header-items';

export const ProfileSubNavigation = () => {
  const company = useCompany();

  const links = () => (
    <Navigation
      variant="subNavigation"
      aria-label="Sub navigation"
      leftNavigationItems={[
        <navLinks.UserProfileLink key="userProfile" company={company} />,
        <navLinks.UserNotificationsLink
          key="userNotifications"
          company={company}
        />,
      ]}
    />
  );

  return (
    <Switch>
      <Route path={routes.ACCOUNT_ME.path} component={links} />
      <Route path={routes.ACCOUNT_NOTIFICATIONS.path} component={links} />
    </Switch>
  );
};
