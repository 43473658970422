import { gql } from 'graphql-tag';

export const SET_ACCOUNTS_PAYABLE = gql`
  mutation setAccountsPayable(
    $companyId: ID!
    $accountsUpdates: [AccountPayableUpdate!]!
  ) {
    setAccountsPayable(
      companyId: $companyId
      accountsUpdates: $accountsUpdates
    ) {
      ... on SetAccountsPayableResultSet {
        accountsPayable {
          id
        }
      }
      ... on SetAccountsPayableResultNotSet {
        reason
      }
    }
  }
`;
