import queryString from 'query-string';
import { type QueryKey, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import type {
  CreditNoteId,
  CreditNote,
} from '@finance-review/models/credit-note';
import { type InvoiceRequest } from '@finance-review/models/invoice';
import { type CustomFieldId } from 'modules/budgets/models/customFieldDefinition';
import { type InvoiceReviewFilterState } from 'modules/invoices/redux/reducer';
import { fetchInvoicesCounts } from 'modules/invoices/redux/thunks';
import { type SupplierId } from 'modules/suppliers';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryRequest } from 'src/core/api/query';
import { type QueryState } from 'src/core/api/queryState';
import type { AppDispatch } from 'src/core/modules/app/redux/store';
import { type AppState } from 'src/core/reducers';

import type { RawCreditNote } from './transformers';
import { transformRawCreditNote } from './transformers';
import { updateCreditNoteRequestQueryData } from './useCreditNoteRequestQuery';

type RawResult = { requests: RawCreditNote[] };

type Result = CreditNote[];

type CreditNoteListRawFilters = {
  states?: CreditNote['state'][];
  reference_invoice_request_ids?: InvoiceRequest.EntityId[];
  deducted_invoice_request_ids?: InvoiceRequest.EntityId[];
  supplier_reviewed?: '0' | '1';
  custom_fields?: { [customFieldId: CustomFieldId]: unknown }[];
  supplier_ids?: SupplierId[];
  search?: string;
  request_ids?: CreditNoteId[];

  // TODO@financeAccountants@creditNotes add filters to the backend
  currency?: string;
  order_by?: 'issue_date';
};

export type CreditNoteListFilters = {
  states?: CreditNote['state'][];
  referenceInvoiceRequestIds?: InvoiceRequest.EntityId[];
  deductedInvoiceRequestIds?: InvoiceRequest.EntityId[];
  supplierReviewed?: '0' | '1';
  customFields?: { [customFieldId: CustomFieldId]: unknown }[];
  supplierIds?: SupplierId[];
  search?: string;
  requestIds?: CreditNoteId[];

  // TODO@financeAccountants@creditNotes add filters to the backend
  currency?: string;
  orderBy?: 'issue_date';
};

const reshapeData = (data: RawResult): Result => {
  return data.requests.map(transformRawCreditNote);
};

export type CreditNoteListQueryState = QueryState<Result>;

type Options = {
  isEnabled?: boolean;
  onSuccess?({
    rawCreditNotes,
    creditNotes,
  }: {
    rawCreditNotes: RawCreditNote[];
    creditNotes: CreditNote[];
  }): void;
  staleTime?: number;
};

const getCreditNoteListRequestConfig = (
  filters: CreditNoteListFilters,
): QueryRequest => ({
  type: 'rest',
  target: 'companyAPI',
  endpoint: `/credit_note_requests`,
  params: { ...toRawCreditNoteFilters(filters), count: 50 },
});

export const useCreditNoteListQuery = (
  filters: CreditNoteListFilters,
  { isEnabled = true, staleTime = 5000, ...options }: Options = {},
): QueryState<Result> => {
  filters.states ??= ['inReview'];
  const queryKey = getQueryKey(filters);

  // TODO@financeOps@creditNotes: handle pagination
  return useQuery<Result, RawResult>({
    key: queryKey,
    isEnabled,
    request: getCreditNoteListRequestConfig(filters),
    options: {
      staleTime,
      structuralSharing: false, // fix a race condition in `CreditNotesForSupplierModal` (https://github.com/TanStack/query/issues/1653)
      onSuccess: ({ data, rawData, client }): void => {
        rawData.requests.map((localRawData) =>
          updateCreditNoteRequestQueryData(client, localRawData),
        );
        options.onSuccess?.({
          rawCreditNotes: rawData.requests,
          creditNotes: data,
        });
      },
    },
    reshapeData,
  });
};

export const useInvalidateCreditNoteListQuery = (): (() => Promise<void>) => {
  const queryClient = useQueryClient();

  return async (): Promise<void> => {
    await queryClient.invalidateQueries<RawCreditNote[]>(getQueryKey());
  };
};

export const useUpdateCreditNoteCacheEntry = () => {
  const filters = toCreditNoteFilters(
    useSelector((state: AppState) => state.invoices.filters),
  );
  const queryClient = useQueryClient();

  return (rawCreditNote: RawCreditNote): void => {
    const queryKey = getQueryKey(filters);

    const previousData = queryClient.getQueryData<RawResult>(queryKey);

    const previousCreditNotes = previousData?.requests || [];

    if (previousCreditNotes) {
      const newCreditNotes = previousCreditNotes.map((creditNote) =>
        creditNote.id === rawCreditNote.id ? rawCreditNote : creditNote,
      );

      queryClient.setQueryData(queryKey, {
        ...previousData,
        requests: newCreditNotes,
      });
    }
  };
};

export const useRemoveCreditNotesCacheEntries = () => {
  const dispatch = useDispatch<AppDispatch>();
  const filters = toCreditNoteFilters(
    useSelector((state: AppState) => state.invoices.filters),
  );
  const queryClient = useQueryClient();

  return (
    creditNoteIds: CreditNoteId[],
    { onComplete }: { onComplete?(): void } = {},
  ): void => {
    const queryKey = getQueryKey(filters);

    const previousData = queryClient.getQueryData<RawResult>(queryKey);

    const previousCreditNotes = previousData?.requests || [];

    const newCreditNotes = previousCreditNotes.filter(
      (creditNote: RawCreditNote) => !creditNoteIds.includes(creditNote.id),
    );

    queryClient.setQueryData(queryKey, {
      ...previousData,
      requests: newCreditNotes,
    });

    dispatch(fetchInvoicesCounts());

    onComplete?.();
  };
};

const getQueryKey = (filters?: CreditNoteListFilters): QueryKey => {
  const key = 'credit-note-requests';
  if (!filters) {
    return [key];
  }
  return [key, queryString.stringify(filters, { arrayFormat: 'index' })];
};

export const toCreditNoteFilters = (
  filters: InvoiceReviewFilterState,
): CreditNoteListFilters => {
  const toState = (
    status: InvoiceReviewFilterState['status'],
  ): CreditNote['state'] | undefined => {
    if (status === 'in_review') {
      return 'inReview';
    }
  };

  const toOrderBy = (
    orderBy: InvoiceReviewFilterState['order_by'],
  ): CreditNoteListFilters['orderBy'] | undefined => {
    if (orderBy === 'emission_date') {
      return 'issue_date';
    }
  };
  return {
    states: [toState(filters.status)].filter(Boolean) as CreditNote['state'][],
    orderBy: toOrderBy(filters.order_by),
    supplierReviewed:
      filters.supplier_reviewed as CreditNoteListFilters['supplierReviewed'],
    customFields:
      filters.custom_fields as CreditNoteListFilters['customFields'],
    search: filters.search as CreditNoteListFilters['search'],
  };
};

export const toRawCreditNoteFilters = (
  filters: CreditNoteListFilters,
): CreditNoteListRawFilters => {
  return {
    states: filters.states,
    reference_invoice_request_ids: filters.referenceInvoiceRequestIds,
    deducted_invoice_request_ids: filters.deductedInvoiceRequestIds,
    supplier_reviewed: filters.supplierReviewed,
    custom_fields: filters.customFields,
    supplier_ids: filters.supplierIds,
    search: filters.search,
    currency: filters.currency,
    order_by: filters.orderBy,
    request_ids: filters.requestIds,
  };
};
