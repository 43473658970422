import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type SupplierBankInfos } from '../../../models';
import { BankInfoPreview } from '../BankInfoPreview';

type Props = {
  bic: SupplierBankInfos['bic'];
};

export const BicPreview = ({ bic }: Props) => {
  const { t } = useTranslation('global');

  return (
    <BankInfoPreview
      primaryText={t('submitMyInvoice.fields.bic.label')}
      secondaryText={bic}
    />
  );
};
