import { Button, Table, type TableColumn } from '@dev-spendesk/grapes';
import queryString from 'query-string';
import React from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';

import { useParams } from 'common/hooks/useParams';
import { useTranslation } from 'common/hooks/useTranslation';
import { useAccountPayableQuery } from 'modules/bookkeep/accounts-payable/hooks/useAccountPayableQuery';
import { useAccountPayableSuppliersQuery } from 'modules/bookkeep/accounts-payable/hooks/useAccountPayableSuppliersQuery';
import { useGetSuppliersWithoutAccountPayableQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSuppliersWithoutAccountPayableQuery';
import { routes, routeFor } from 'src/core/constants/routes';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { getTopNotificationOffset } from 'src/core/utils/topNotificationOffset';

import { AccountPayableSuppliersNameCell } from '../../../components/AccountPayableSuppliersNameCell';
import { type Supplier } from '../../../types';
import { AccountPayableSuppliersSubListLoader } from '../AccountPayableSuppliersSubListLoader';

import './AccountPayableSuppliersSubList.css';

type PreparedRow = {
  supplierName: JSX.Element;
  totalAmount: string;
  rowId: string;
  isActive: boolean;
};

const reshapeAccountSuppliersRows = (
  suppliers: Supplier[],
  supplierIdParameter: string,
): PreparedRow[] =>
  suppliers.map((row) => ({
    supplierName: (
      <AccountPayableSuppliersNameCell
        totalCount={1}
        supplierName={row.supplierName}
        logosUrls={row.logosUrls}
      />
    ),
    totalAmount: row.totalAmount ? formatMonetaryValue(row.totalAmount) : '',
    rowId: row.supplierId,
    isActive: row.supplierId === supplierIdParameter,
  }));

const reshapeAccountSuppliersColumns = (
  accountPayableCode: string,
): TableColumn<PreparedRow>[] => [
  {
    id: 'supplierName',
    header: accountPayableCode,
    renderCell: ({ supplierName }) => supplierName,
    width: '100%',
  },
];

export const AccountPayableSuppliersSubList = ({
  archivalStatus,
  supplierSelectionToBulkArchive,
  setSupplierSelectionToBulkArchive,
  onAllSuppliersToBulkArchiveSelectionChange,
}: {
  archivalStatus?: 'current' | 'archived' | undefined;
  supplierSelectionToBulkArchive: string[];
  setSupplierSelectionToBulkArchive: (
    row: PreparedRow,
    rowId: string,
    isChecked: boolean,
  ) => void;
  setAccountPayableIdToBulkArchive: (accountPayableId?: string) => void;
  onAllSuppliersToBulkArchiveSelectionChange: (
    rows: {
      supplierName: JSX.Element;
      totalAmount: string;
      rowId: string;
      isActive: boolean;
    }[],
    rowIds: string[],
    isChecked: boolean,
  ) => void;
}) => {
  const { t } = useTranslation('global');
  const { company, accountPayableId } = useParams(
    routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path,
  );
  const history = useHistory();
  const location = useLocation();

  const search =
    (queryString.parse(location.search).search as string | undefined) || '';

  // Resolve supplierId param outside of its route for table selected row
  const match = matchPath<{ supplierId: string }>(location.pathname, {
    path: routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path,
    exact: true,
  });
  const supplierId = match?.params.supplierId ?? '';

  const accountPayablesSuppliersQuery = useAccountPayableSuppliersQuery(
    accountPayableId,
    search,
    archivalStatus,
  );

  const accountPayableQuery = useAccountPayableQuery(accountPayableId);
  const suppliersWithoutAccountPayableQuery =
    useGetSuppliersWithoutAccountPayableQuery(
      accountPayableId,
      search,
      archivalStatus,
    );

  if (
    (accountPayableId !== '-' &&
      accountPayablesSuppliersQuery.status === 'loading') ||
    (accountPayableId === '-' &&
      suppliersWithoutAccountPayableQuery.status === 'loading')
  ) {
    return <AccountPayableSuppliersSubListLoader />;
  }

  const handleSupplierRowClick = (row: PreparedRow) => {
    let rowData: Supplier | undefined;
    if (accountPayablesSuppliersQuery.status === 'success') {
      rowData = accountPayablesSuppliersQuery.data.find(
        (supplier) => row.rowId === supplier.supplierId,
      );
    }

    if (suppliersWithoutAccountPayableQuery.status === 'success') {
      rowData = suppliersWithoutAccountPayableQuery.data.find(
        (supplier) => row.rowId === supplier.supplierId,
      );
    }

    const pathname = routeFor(
      routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path,
      {
        company,
        accountPayableId,
        supplierId: rowData?.supplierId || '',
      },
    );

    const isCurrentRoute = history.location.pathname === pathname;

    if (!isCurrentRoute) {
      history.push({
        pathname,
        search: location.search,
      });
    }
  };

  return (
    <div className="AccountPayableSuppliersSubList">
      {accountPayableId !== '-' &&
        accountPayablesSuppliersQuery.status === 'success' &&
        accountPayableQuery.status === 'success' && (
          <Table
            data-testid="suppliers-sub-table"
            columns={reshapeAccountSuppliersColumns(
              accountPayableQuery.data.generalAccountCode,
            )}
            data={reshapeAccountSuppliersRows(
              accountPayablesSuppliersQuery.data,
              supplierId,
            )}
            onRowClick={handleSupplierRowClick}
            selectedRowIds={supplierSelectionToBulkArchive}
            onRowSelectionChange={setSupplierSelectionToBulkArchive}
            onAllRowsSelectionChange={
              onAllSuppliersToBulkArchiveSelectionChange
            }
            getRowId={(row: PreparedRow) => row.rowId}
            maxHeight={`calc(100vh - 302px - ${getTopNotificationOffset()}px)`}
            footer={
              accountPayablesSuppliersQuery.hasNextPage ? (
                <Button
                  variant="secondary"
                  text={t('bookkeep.accountsPayable.list.loadMore')}
                  onClick={accountPayablesSuppliersQuery.fetchNextPage}
                />
              ) : undefined
            }
            getIsRowActive={(row) => row.isActive}
          />
        )}
      {accountPayableId === '-' &&
        suppliersWithoutAccountPayableQuery.status === 'success' && (
          <Table
            data-testid="suppliers-sub-table"
            columns={reshapeAccountSuppliersColumns(
              t('bookkeep.accountsPayable.undefined'),
            )}
            data={reshapeAccountSuppliersRows(
              suppliersWithoutAccountPayableQuery.data,
              supplierId,
            )}
            onRowClick={handleSupplierRowClick}
            selectedRowIds={supplierSelectionToBulkArchive}
            onRowSelectionChange={setSupplierSelectionToBulkArchive}
            onAllRowsSelectionChange={
              onAllSuppliersToBulkArchiveSelectionChange
            }
            getRowId={(row: PreparedRow) => row.rowId}
            maxHeight={`calc(100vh - 302px - ${getTopNotificationOffset()}px)`}
            footer={
              suppliersWithoutAccountPayableQuery.hasNextPage ? (
                <Button
                  variant="secondary"
                  text={t('bookkeep.accountsPayable.list.loadMore')}
                  onClick={suppliersWithoutAccountPayableQuery.fetchNextPage}
                />
              ) : undefined
            }
            getIsRowActive={(row) => row.isActive}
          />
        )}
    </div>
  );
};
