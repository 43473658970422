import { useState, useCallback } from 'react';

type ModalState<T> = { isShown: false } | { isShown: true; parameters: T };

type RenderFunctionWithNoParams = () => React.ReactElement | null;
type RenderFunctionWithParams<T> = (params: T) => React.ReactElement | null;

type ShowModalWithNoParams = () => void;
type ShowModalWithParams<T> = (params: T) => void;

/**
 * @example
 * import { useModal } from 'common/hooks/useModal';
 * const MyComponent = ({ onSuccess }) => {
 * 	 const [
 *     myModal,
 *     showMyModal,
 *     hideMyModal,
 *   ] = useModal((successValue) => (
 *     <MyModal
 *       onConfirm={() => onSuccess(successValue)}
 *       onCancel={() => hideMyModal()}
 *     />
 *   ));
 *
 *   return (
 * 		<>
 * 			<button onClick={() => showMyModal('successValue')}>Show modal</buttton>
 * 			{myModal}
 * 		</>
 * 	);
 * };
 */
export function useModal(
  renderComponent: RenderFunctionWithNoParams,
): [React.ReactElement | null, ShowModalWithNoParams, () => void];
export function useModal<T>(
  renderComponent: RenderFunctionWithParams<T>,
): [React.ReactElement | null, ShowModalWithParams<T>, () => void];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useModal(renderComponent: any): any {
  const [modalState, setModalState] = useState<ModalState<unknown | undefined>>(
    {
      isShown: false,
    },
  );
  const hideModal = useCallback(
    () =>
      setModalState({
        isShown: false,
      }),
    [],
  );

  return [
    modalState.isShown ? renderComponent(modalState.parameters) : null,
    (parameters: unknown | undefined): void => {
      setModalState({
        isShown: true,
        parameters: parameters ?? undefined,
      });
    },
    hideModal,
  ];
}
