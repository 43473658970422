import { apiUrl } from 'src/core/utils/api';

export type Response = {
  password?: string;
};

export const postGeneratePaymentPassword = async ({
  companyId,
}: {
  companyId: string;
}): Promise<Response> => {
  const response = await fetch(
    apiUrl('/cards/payment-password/generate', companyId),
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 201) {
    return response.json();
  }
  if (response.status === 204) {
    return { password: undefined };
  }
  throw new Error('Payment password generate failed');
};
