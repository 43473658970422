import { useHasAccountingIntegrationCapability } from 'modules/accounting-integration/apis';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useParams } from 'src/core/common/hooks/useParams';
import { routes } from 'src/core/constants/routes';

import { type DefaultVendors } from '../../accounting/vendor';

export const useGetDefaultVendorsQuery = (): QueryState<DefaultVendors> => {
  const { company: companyId } = useParams(
    routes.COMPANY_ACCOUNTING_INTEGRATION.path,
  );
  const hasSupplierSyncCapability =
    useHasAccountingIntegrationCapability('supplierSync');

  return useQuery<DefaultVendors>({
    key: ['useGetDefaultVendorsQuery', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/supplier-sync/default',
    },
    isEnabled: hasSupplierSyncCapability,
    options: {
      cacheTime: 10 * 60 * 1000, // 10min,
      staleTime: 10 * 60 * 1000, // 10min,
    },
  });
};
