import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import './CompanyDropdownLimitedAccess.css';

type Props = {
  className?: string;
};

export const CompanyDropdownLimitedAccess = ({ className }: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className={cx('CompanyDropdownLimitedAccess', className)}>
      <h3 className="text-neutral-darker title-m ">
        {t('limitedAccess.headerReviewingContractTitle')}
      </h3>
      <p className="text-neutral-dark body-s ">
        {t('limitedAccess.headerReviewingContract')}
      </p>
    </div>
  );
};
