import { DATE_FORMAT, type DateFormatter } from '@dev-spendesk/grapes';
import { type MonetaryValue } from 'ezmoney';

import i18n from 'src/core/config/i18n';

import {
  type User,
  type SuspendedUser,
  type Invitee,
  type Supplier,
  isUser,
  isInvitee,
  isSupplier,
  DocumentaryEvidenceType,
  type DocumentaryEvidenceValidity,
  type PayableProofStatus,
  type PayableRow,
  type PayableType,
  type PayableSubType,
  type BucketType,
} from '../../../../models';

export type RawPayable = {
  id: string;
  grossAmount: MonetaryValue;
  netAmount: MonetaryValue;
  functionalAmount: MonetaryValue;
  creationDate: string;
  documentaryEvidence: {
    type: DocumentaryEvidenceType;
    validity: DocumentaryEvidenceValidity;
  } | null;
  member: User | SuspendedUser | Invitee | null;
  counterparty: User | SuspendedUser | Invitee | Supplier;
  supplier: {
    id: string;
    name: string;
    thumbnailUrl: string | null;
  } | null;
  state: string;
  type: PayableType;
  subtype: PayableSubType | null;
  description: string;
};

const getLabelFromCounterParty = (
  counterparty: User | SuspendedUser | Invitee | Supplier,
): string => {
  if (isUser(counterparty)) {
    return counterparty.givenName && counterparty.familyName
      ? `${counterparty.givenName} ${counterparty.familyName}`
      : counterparty.email || i18n.t('misc.unknownMember');
  }

  if (isInvitee(counterparty)) {
    return counterparty.email;
  }

  if (isSupplier(counterparty)) {
    return counterparty.name;
  }

  return i18n.t('misc.unknownMember');
};

const reshapeMember = (
  member: User | SuspendedUser | Invitee | null,
): string => {
  return member && isUser(member) && member.givenName && member.familyName
    ? `${member.givenName} ${member.familyName}`
    : (member && isUser(member) && member.email) ||
        i18n.t('misc.unknownMember');
};

const getSublabel = (payable: RawPayable): string => {
  if (['mileage_allowance', 'per_diem_allowance'].includes(payable.type)) {
    return payable.description;
  }

  return isSupplier(payable.counterparty)
    ? reshapeMember(payable.member)
    : payable.supplier?.name || '';
};

const getProofStatus = (
  documentaryEvidence: {
    type: DocumentaryEvidenceType;
    validity?: DocumentaryEvidenceValidity;
  } | null,
  bucketType: BucketType,
): PayableProofStatus => {
  // No need to ask the backend for details, if we queried with a filter
  // on the documentaryEvidenceStatus, we know what result we'll have
  if (bucketType === 'missingProof') {
    return 'missing';
  }

  if (!documentaryEvidence) {
    return 'not_provided';
  }

  if (documentaryEvidence.validity?.valid === false) {
    return 'invalid';
  }

  switch (documentaryEvidence.type) {
    case DocumentaryEvidenceType.invoice:
    case DocumentaryEvidenceType.creditNote:
    case DocumentaryEvidenceType.mileageEntry:
    case DocumentaryEvidenceType.perDiemEntry:
      return 'provided';
    case DocumentaryEvidenceType.declarationOfMissingInvoice:
      return 'missing';
    default:
      return 'not_provided';
  }
};

export const reshapePayable = (
  payable: RawPayable,
  bucketType: BucketType,
  localeFormat: DateFormatter,
): PayableRow => {
  return {
    id: payable.id,
    label: getLabelFromCounterParty(payable.counterparty),
    sublabel: getSublabel(payable),
    amount: payable.functionalAmount,
    originalAmount: payable.grossAmount,
    date: localeFormat(new Date(payable.creationDate), DATE_FORMAT.CUSTOM, {
      dateStyle: 'medium',
      timeZone: 'UTC',
    }),
    proofStatus: getProofStatus(payable.documentaryEvidence, bucketType),
    subtype: payable.subtype,
  };
};
