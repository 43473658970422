import { useQueryClient } from 'react-query';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type { PurchaseOrderDetails, Appraisal, ApprovalState } from '../models';

type PurchaseOrderDetailsRequestError = {
  errorCode: string;
};

export type RawPurchaseOrderDetails = PurchaseOrderDetails & {
  // FIXME: needed for backward compatibility, to remove after the rollout
  // of the related backend changes
  appraisals: Appraisal[];
  approval_state: ApprovalState;
};

export const usePurchaseOrderQuery = (
  purchaseOrderId?: string,
): QueryState<PurchaseOrderDetails, PurchaseOrderDetailsRequestError> => {
  return useQuery<
    PurchaseOrderDetails,
    RawPurchaseOrderDetails,
    PurchaseOrderDetailsRequestError
  >({
    key: ['purchaseOrders', purchaseOrderId],
    isEnabled: Boolean(purchaseOrderId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/purchase-orders/${purchaseOrderId}`,
    },
    reshapeData: (data) => {
      if (data.approval) {
        return data;
      }
      // FIXME: needed for backward compatibility, to remove after the rollout
      // of the related backend changes
      return {
        ...data,
        approval: {
          autoApproved: false,
          appraisals: data.appraisals,
          state: data.approval_state,
        },
      };
    },
  });
};

export const useInvalidatePurchaseOrderQuery = () => {
  const queryClient = useQueryClient();

  return async (purchaseOrderId: string): Promise<void> => {
    await queryClient.invalidateQueries(['purchaseOrders', purchaseOrderId]);
  };
};
