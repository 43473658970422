import { useQueryClient } from 'react-query';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useNotifications } from '../../app/notifications';
import { type UserDelegationsList } from '../models';

export const useFetchUserDelegationsQuery = (
  userId: string,
  options: {
    isEnabled: boolean;
  } = { isEnabled: true },
): QueryState<UserDelegationsList> => {
  const { dangerNotif } = useNotifications();
  const { t } = useTranslation('global');

  return useQuery<UserDelegationsList>({
    key: ['delegations', 'user', userId],
    isEnabled: options.isEnabled,
    options: {
      onError() {
        dangerNotif(t('delegation.userDelegationErrorNotification'));
      },
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/delegations/user/${userId}`,
    },
  });
};

export const useOptimisticallyApprovedWtConfirmationQuery = () => {
  const queryClient = useQueryClient();

  return (userId: string): void => {
    const queryKey = ['delegations', 'user', userId];
    queryClient.setQueryData<UserDelegationsList | undefined>(
      queryKey,
      (oldData) => {
        if (!oldData) {
          return;
        }
        return {
          ...oldData,
          delegationList: ['wt_confirmation'],
        };
      },
    );
  };
};

export const useInvalidateUserDelegationQuery = () => {
  const queryClient = useQueryClient();
  return (userId: string): void => {
    const queryKey = ['delegations', 'user', userId];
    queryClient.invalidateQueries(queryKey);
  };
};
