import queryString from 'query-string';

import { apiUrl } from "src/core/utils/api";

export type SlackProviderSettings = {
  clientId: string;
  permissions: string;
};

export const getSlackAuthorizeUrl = ({
  clientId,
  companyId,
  scope,
}: {
  clientId: string;
  companyId: string;
  scope: string;
}): string | null => {
  const SLACK_OAUTH_URL = 'https://slack.com/oauth/v2/authorize';

  if (!clientId || !companyId) {
    return null;
  }

  return `${SLACK_OAUTH_URL}?${queryString.stringify({
    scope,
    granular_bot_scope: 1,
    client_id: clientId,
    redirect_uri: apiUrl('/activate-slack', companyId),
    state: 'enable',
  })}`;
};
