import { useInvoiceWireTransferActivationStatusQuery } from 'modules/company/general-settings';
import { type QueryState } from 'src/core/api/queryState';

export const useIsCompanyEligibleToInvoicesWireTransferQuery =
  (): QueryState<boolean> => {
    const wireTransferActivationStatusQueryState =
      useInvoiceWireTransferActivationStatusQuery();

    if (wireTransferActivationStatusQueryState.status === 'success') {
      return {
        ...wireTransferActivationStatusQueryState,
        data:
          wireTransferActivationStatusQueryState.data.isActivated ||
          wireTransferActivationStatusQueryState.data.toggle.canToggle,
      };
    }

    return wireTransferActivationStatusQueryState;
  };
