import { combineReducers, type Reducer } from '@reduxjs/toolkit';
import { type CancelTokenSource } from 'axios';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { type InvoiceRequest } from '@finance-review/models/invoice';
import {
  createPaginatedReducer as createCursorPaginatedReducer,
  type Pagination as CursorPagination,
} from 'common/redux/paginateCursor';
import { RequestState } from 'common/redux/requestState';
import { PaymentMethod } from 'modules/company';

import {
  CLEAR_PAYMENTS_BATCHES,
  FETCH_INVOICES_COUNTS_FAILURE,
  FETCH_INVOICES_COUNTS_REQUEST,
  FETCH_INVOICES_COUNTS_SUCCESS,
  FETCH_PAYMENT_DETAILS_SUCCESS,
  FETCH_PAYMENT_TO_SCHEDULE_DETAILS_SUCCESS,
  FETCH_PAYMENTS_BATCHES_FAILURE,
  FETCH_PAYMENTS_BATCHES_REQUEST,
  FETCH_PAYMENTS_BATCHES_SUCCESS,
  FETCH_PAYMENTS_TO_SCHEDULE_FAILURE,
  FETCH_PAYMENTS_TO_SCHEDULE_REQUEST,
  FETCH_PAYMENTS_TO_SCHEDULE_SUCCESS,
  type InvoicesActions,
  RESET_PAYMENT_DETAILS,
  RESET_PAYMENT_TO_SCHEDULE_DETAILS,
  RESET_PAYMENTS_TO_SCHEDULE,
  SCHEDULE_PAYMENTS_FAILURE,
  SCHEDULE_PAYMENTS_REQUEST,
  SCHEDULE_PAYMENTS_SUCCESS,
  SET_INVOICE_REVIEW_FILTERS,
  SET_SELECTED_PAYMENT_METHOD,
  SET_SELECTED_PAYMENTS_TO_SCHEDULE,
  SET_PAYMENT_COMPLIANCE,
  RESET_PAYMENT_COMPLIANCE,
} from './actionTypes';
import { reducer as schedulePaymentsFiltersReducer } from './schedulePaymentsFiltersSlice';
import {
  defaultPaymentMethod,
  filterPaymentsWithActivePaymentMethod,
  findHighestPriorityActivePaymentMethodInEveryPayment,
  findHighestPriorityActivePaymentMethodInSomePayments,
  type InvoicesCounts,
  type PaymentsBatch,
  type PaymentsBatchPaymentDetails,
  type PaymentToSchedule,
  type PaymentToScheduleDetails,
} from '../models';
import { type PaymentComplianceResult } from '../payment/models';
import { reducer as transfersReducer } from '../transfer';

export type InvoicesCountsState = {
  requestState: RequestState;
  data: InvoicesCounts;
};

export type PaymentsToScheduleState = CursorPagination<PaymentToSchedule>;

export type PaymentsSchedulingState = {
  isLoading: boolean;
  selectedPaymentMethod: PaymentMethod;
  selectedPaymentsToSchedule: PaymentToSchedule[];
  paymentCompliance: PaymentComplianceResult[];
};

export interface PaymentsBatchesState extends CursorPagination<PaymentsBatch> {
  cancelToken: CancelTokenSource | undefined;
}

export type PaymentToScheduleDetailsState = PaymentToScheduleDetails | null;

export type PaymentDetailsState = PaymentsBatchPaymentDetails | null;

export type InvoiceReviewFilterState = {
  status: InvoiceRequest.Status;
  order_by: string;
  pageSize?: number;
  search?: string;
  [key: string]: unknown;
};

// Exported for testing purposes
export const invoicesCountsInitialState: InvoicesCountsState = {
  requestState: RequestState.NotAsked,
  data: {
    submittedInvoicesToAssess: undefined,
    submittedCreditNotesToAssess: undefined,
    paymentsToSchedule: undefined,
    scheduledPaymentsToConfirm: undefined,
    invoicesPaymentsCount: undefined,
    transfersToConfirm: undefined,
  },
};

// Exported for testing purposes
export const invoicesCountsReducer: Reducer<
  InvoicesCountsState,
  InvoicesActions
> = (state = invoicesCountsInitialState, action): InvoicesCountsState => {
  switch (action.type) {
    case FETCH_INVOICES_COUNTS_REQUEST:
      return {
        ...state,
        requestState: RequestState.Loading,
      };
    case FETCH_INVOICES_COUNTS_SUCCESS:
      return {
        requestState: RequestState.Success,
        data: action.payload,
      };
    case FETCH_INVOICES_COUNTS_FAILURE:
      return {
        requestState: RequestState.Failure,
        data: {
          submittedInvoicesToAssess: undefined,
          submittedCreditNotesToAssess: undefined,
          paymentsToSchedule: undefined,
          scheduledPaymentsToConfirm: undefined,
          invoicesPaymentsCount: undefined,
          transfersToConfirm: undefined,
        },
      };
    case FETCH_PAYMENTS_BATCHES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          invoicesPaymentsCount: action.payload.invoicesPaymentsCount,
        },
      };
    default:
      return state;
  }
};

const paymentsToScheduleInitialState: PaymentsToScheduleState = {
  limit: 20,
  nextCursor: null,
  requestState: RequestState.NotAsked,
  items: [],
};

const paymentsToScheduleReducer =
  createCursorPaginatedReducer<PaymentToSchedule>(
    {
      request: FETCH_PAYMENTS_TO_SCHEDULE_REQUEST,
      success: FETCH_PAYMENTS_TO_SCHEDULE_SUCCESS,
      failure: FETCH_PAYMENTS_TO_SCHEDULE_FAILURE,
      reset: RESET_PAYMENTS_TO_SCHEDULE,
    },
    paymentsToScheduleInitialState,
  );

// Exported for testing purposes
export const paymentToScheduleDetailsInitialState: PaymentToScheduleDetailsState =
  null;

// Exported for testing purposes
export const paymentToScheduleDetailsReducer = (
  state: PaymentToScheduleDetailsState = paymentToScheduleDetailsInitialState,
  action: InvoicesActions,
): PaymentToScheduleDetailsState => {
  switch (action.type) {
    case FETCH_PAYMENT_TO_SCHEDULE_DETAILS_SUCCESS:
      return action.payload.paymentToScheduleDetails;
    case RESET_PAYMENT_TO_SCHEDULE_DETAILS:
      return paymentToScheduleDetailsInitialState;
    default:
      return state;
  }
};

// Exported for testing purposes
export const paymentsSchedulingInitialState: PaymentsSchedulingState = {
  isLoading: false,
  selectedPaymentMethod: PaymentMethod.Csv,
  selectedPaymentsToSchedule: [],
  paymentCompliance: [],
};

const getPaymentMethodFromPaymentSelection = (
  state: PaymentsSchedulingState,
  {
    isPaymentListFullySelected = false,
    newPaymentToScheduleSelection,
  }: {
    isPaymentListFullySelected?: boolean;
    newPaymentToScheduleSelection: PaymentToSchedule[];
  },
): PaymentMethod => {
  const isNewSelection =
    state.selectedPaymentsToSchedule.length === 0 &&
    newPaymentToScheduleSelection.length > 0;
  const isResetingSelection = newPaymentToScheduleSelection.length === 0;

  if (isNewSelection) {
    return isPaymentListFullySelected
      ? findHighestPriorityActivePaymentMethodInSomePayments(
          newPaymentToScheduleSelection,
        )
      : findHighestPriorityActivePaymentMethodInEveryPayment(
          newPaymentToScheduleSelection,
        );
  }

  if (isResetingSelection) {
    return defaultPaymentMethod;
  }

  return state.selectedPaymentMethod;
};

export const paymentsSchedulingReducer: Reducer<
  PaymentsSchedulingState,
  InvoicesActions
> = (state = paymentsSchedulingInitialState, action) => {
  switch (action.type) {
    case SCHEDULE_PAYMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SCHEDULE_PAYMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SCHEDULE_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_SELECTED_PAYMENT_METHOD: {
      return {
        ...state,
        selectedPaymentMethod: action.payload.paymentMethod,
        selectedPaymentsToSchedule: filterPaymentsWithActivePaymentMethod(
          state.selectedPaymentsToSchedule,
          action.payload.paymentMethod,
        ),
        paymentCompliance: [],
      };
    }
    case SET_SELECTED_PAYMENTS_TO_SCHEDULE: {
      const selectedPaymentMethod = getPaymentMethodFromPaymentSelection(
        state,
        {
          newPaymentToScheduleSelection: action.payload.paymentsToSchedule,
          isPaymentListFullySelected: action.payload.isPaymentListFullySelected,
        },
      );

      return {
        ...state,
        selectedPaymentsToSchedule: filterPaymentsWithActivePaymentMethod(
          action.payload.paymentsToSchedule,
          selectedPaymentMethod,
        ),
        selectedPaymentMethod,
        paymentCompliance: [],
      };
    }

    case RESET_PAYMENTS_TO_SCHEDULE: {
      return {
        ...state,
        selectedPaymentsToSchedule: [],
        paymentCompliance: [],
        selectedPaymentMethod: getPaymentMethodFromPaymentSelection(state, {
          newPaymentToScheduleSelection: [],
        }),
      };
    }

    case SET_PAYMENT_COMPLIANCE: {
      return {
        ...state,
        paymentCompliance: action.payload,
      };
    }

    case RESET_PAYMENT_COMPLIANCE: {
      return {
        ...state,
        paymentCompliance: [],
      };
    }

    default:
      return state;
  }
};

const paymentsBatchesInitialState: PaymentsBatchesState = {
  limit: 20,
  nextCursor: null,
  requestState: RequestState.NotAsked,
  items: [],
  cancelToken: undefined,
};

const paymentsBatchesCustomReducer = (
  state: PaymentsBatchesState = paymentsBatchesInitialState,
  action: InvoicesActions,
): PaymentsBatchesState => {
  switch (action.type) {
    case FETCH_PAYMENTS_BATCHES_REQUEST:
      return {
        ...state,
        cancelToken: action.payload.cancelToken,
      };
    case FETCH_PAYMENTS_BATCHES_SUCCESS:
      return {
        ...state,
        cancelToken: undefined,
      };
    case FETCH_PAYMENTS_BATCHES_FAILURE:
      return {
        ...state,
        cancelToken: undefined,
      };
    default:
      return state;
  }
};

const paymentsBatchesReducer = createCursorPaginatedReducer<
  PaymentsBatch,
  PaymentsBatchesState,
  InvoicesActions
>(
  {
    request: FETCH_PAYMENTS_BATCHES_REQUEST,
    success: FETCH_PAYMENTS_BATCHES_SUCCESS,
    failure: FETCH_PAYMENTS_BATCHES_FAILURE,
    reset: CLEAR_PAYMENTS_BATCHES,
  },
  paymentsBatchesInitialState,
  paymentsBatchesCustomReducer,
);

// Exported for testing purposes
export const paymentDetailsInitialState: PaymentDetailsState = null;

// Exported for testing purposes
export const paymentDetailsReducer = (
  state: PaymentDetailsState = paymentDetailsInitialState,
  action: InvoicesActions,
): PaymentDetailsState => {
  switch (action.type) {
    case FETCH_PAYMENT_DETAILS_SUCCESS:
      return action.payload.paymentDetails;
    case RESET_PAYMENT_DETAILS:
      return paymentDetailsInitialState;
    default:
      return state;
  }
};

const invoiceReviewsFilterInitialState: InvoiceReviewFilterState = {
  status: 'in_review',
  order_by: 'emission_date',
  // most of the companies would have less than a 100 invoices in review. Since the pagination is not fully supported (for example with filters)
  // we only set it for companies with a large number of invoices to review, so that the experience remains the same for the majority of the others
  pageSize: 100,
};

const reviewsFiltersReducer = (
  state: InvoiceReviewFilterState = invoiceReviewsFilterInitialState,
  action: InvoicesActions,
): InvoiceReviewFilterState => {
  switch (action.type) {
    case SET_INVOICE_REVIEW_FILTERS: {
      const newFilters = omitBy(action.payload ?? {}, (value) =>
        typeof value === 'string' ? isEmpty(value) : isNil(value),
      );
      const hasFilters = Object.keys(newFilters).length > 0;

      return {
        ...newFilters,
        ...invoiceReviewsFilterInitialState,
        ...(hasFilters ? { pageSize: undefined } : {}), // back-end does not support pagination with filters
      };
    }
    default:
      return state;
  }
};

export const reducer = combineReducers({
  counts: invoicesCountsReducer,
  paymentsToSchedule: paymentsToScheduleReducer,
  paymentToScheduleDetails: paymentToScheduleDetailsReducer,
  paymentsScheduling: paymentsSchedulingReducer,
  paymentsBatches: paymentsBatchesReducer,
  paymentDetails: paymentDetailsReducer,
  filters: reviewsFiltersReducer,
  transfers: transfersReducer,
  schedulePaymentsFilters: schedulePaymentsFiltersReducer,
});
