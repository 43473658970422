import { type MutationState, useMutation } from 'src/core/api/hooks';

type Payload = { data: { adyen: { kind: 'reset' } } };
export const useUserScaDeviceUnenrollMutation = (): MutationState<
  Payload,
  void,
  unknown
> => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'authAPI',
      endpoint: '/sca/users/unenroll',
      method: 'post',
    },
  });
};
