import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type Payable as RawPayable } from 'modules/bookkeep/export/pages/IntegrationExportPage/payable';
import { type PayableId } from 'modules/bookkeep/prepare-payables/models';
import { type Payable } from 'modules/payable/models';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { getExportPayablesCurrentStateQueryKey } from './query-key-registry';

/**
 * Query and cache config
 */

export type RawIntegrationExportStatus =
  | { outcome: 'noExport' }
  | { outcome: 'ongoing'; progress: number }
  | RawIntegrationExportDoneStatus
  | {
      outcome: 'failed';
      id: string;
      reason: 'notConnected' | 'serviceUnavailable';
    };

type RawIntegrationExportDoneStatus = {
  outcome: 'exported';
  id: string;
  outcomeByPayableId: Record<
    string,
    | { outcome: 'success' }
    | {
        outcome: 'error';
        reason: string;
        errorData?: {
          errorMessage: string;
          unmappedFieldDetails: {
            name: string;
            value: string;
          };
        };
        faultyPayableId?: PayableId;
        suggestion:
          | 'retry'
          | 'markAsAccountedManually'
          | 'sendBackToPrepare'
          | 'contactSupport';
      }
  >;
  payableByPayableId: Record<string, RawPayable>;
};

type IntegrationExportStatus =
  | { outcome: 'noExport' }
  | { outcome: 'ongoing'; progress: number }
  | IntegrationExportDoneStatus
  | {
      outcome: 'failed';
      id: string;
      reason: 'notConnected' | 'serviceUnavailable';
    };

export type IntegrationExportDoneStatus = {
  outcome: 'exported';
  id: string;
  outcomeByPayableId: Record<
    string,
    | { outcome: 'success' }
    | {
        outcome: 'error';
        reason: string;
        errorData?: {
          errorMessage: string;
          unmappedFieldDetails: {
            name: string;
            value: string;
          };
        };
        faultyPayableId?: PayableId;
        suggestion:
          | 'retry'
          | 'markAsAccountedManually'
          | 'sendBackToPrepare'
          | 'contactSupport';
      }
  >;
  payableByPayableId: Record<string, Payable>;
};

export const useInvalidateExportPayablesCurrentStateQueryCache = () => {
  const queryClient = useQueryClient();

  return async (companyId: string): Promise<void> => {
    await queryClient.invalidateQueries(
      getExportPayablesCurrentStateQueryKey(companyId),
    );
  };
};

export const useCancelExportPayablesCurrentStateQueryCache = () => {
  const queryClient = useQueryClient();

  return async (companyId: string): Promise<void> => {
    await queryClient.cancelQueries(
      getExportPayablesCurrentStateQueryKey(companyId),
    );
  };
};

export const useUpdateExportPayablesCurrentStateQueryCache = () => {
  const queryClient = useQueryClient();

  return async (
    companyId: string,
    {
      payableIdsToRemove,
      exportState,
    }: {
      payableIdsToRemove?: PayableId[];
      exportState?: IntegrationExportStatus;
    },
  ): Promise<void> => {
    const queryKey = getExportPayablesCurrentStateQueryKey(companyId);

    if (payableIdsToRemove) {
      const previousData =
        queryClient.getQueryData<IntegrationExportStatus>(queryKey);

      if (previousData && previousData.outcome === 'exported') {
        queryClient.setQueryData<IntegrationExportStatus>(queryKey, {
          ...previousData,
          outcomeByPayableId: Object.fromEntries(
            Object.entries(previousData.outcomeByPayableId).filter(
              ([id]) => !payableIdsToRemove.includes(id),
            ),
          ),
          payableByPayableId: Object.fromEntries(
            Object.entries(previousData.payableByPayableId).filter(
              ([id]) => !payableIdsToRemove.includes(id),
            ),
          ),
        });
      }
    }

    if (exportState) {
      queryClient.setQueryData<IntegrationExportStatus>(queryKey, exportState);
    }
  };
};

/**
 * REST query hook
 */

export const useExportPayablesCurrentState =
  (): QueryState<IntegrationExportStatus> => {
    const companyId = useCompanyId();

    return useQuery<IntegrationExportStatus, RawIntegrationExportStatus>({
      key: getExportPayablesCurrentStateQueryKey(companyId),
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: '/accounting-integration/current-export/state',
      },
      options: {
        refetchInterval: (data) => {
          if (data?.outcome === 'ongoing') {
            return 3000;
          }

          return false;
        },
      },
      reshapeData,
    });
  };

/**
 * Helpers
 */

const reshapeData = (
  exportStatus: RawIntegrationExportStatus,
): IntegrationExportStatus => {
  if (exportStatus.outcome === 'exported') {
    return {
      ...exportStatus,
      payableByPayableId: Object.fromEntries(
        Object.entries(exportStatus.payableByPayableId).map(([id, payable]) => [
          id,
          reshapePayable(payable),
        ]),
      ),
    };
  }

  return exportStatus;
};

// TODO@financeAccountant@CORE-4887 improve payable shape returned by the backend

const reshapePayable = (rawPayable: RawPayable): Payable => {
  let subtype: Payable['subtype'] | undefined;
  if (rawPayable.subType === 'subscription') {
    subtype = 'subscriptionCard';
  }
  if (rawPayable.subType === 'singlePurchase') {
    subtype = 'singleUseCard';
  }
  if (rawPayable.subType === 'physical') {
    subtype = 'plasticCard';
  }
  if (rawPayable.subType === 'creditNote') {
    subtype = 'creditNote';
  }

  return {
    id: rawPayable.id,
    version: rawPayable.version,
    kind: rawPayable.type === 'reverseBill' ? 'reversal' : 'expense',
    subtype,
    description: rawPayable.description,
    counterparty:
      rawPayable.counterparty.type === 'supplier'
        ? {
            thumbnailUrl: rawPayable.counterparty.thumbnailUrl,
            name: rawPayable.counterparty.name,
            type: 'supplier',
          }
        : {
            avatar: '',
            email: '',
            givenName: '',
            familyName: rawPayable.counterparty.name,
            type: 'user',
          },
    member: rawPayable.member
      ? {
          avatar: '',
          email: '',
          givenName: '',
          familyName: rawPayable.member.name,
          type: 'user',
        }
      : undefined,
    supplier: rawPayable.supplier
      ? {
          name: rawPayable.supplier.name,
          thumbnailUrl: rawPayable.supplier.thumbnailUrl,
        }
      : undefined,
    grossAmount: rawPayable.amount,
    functionalAmount: rawPayable.functionalAmount,
    mileageDetails: undefined,
    perDiem: undefined,
    documentaryEvidence: undefined,
    creationDate: new Date(rawPayable.date),
  };
};
