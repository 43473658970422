import { useQuery } from 'src/core/api/hooks/useQuery';

import { useHasAccountingIntegrationCapability } from '../../accounting-integration/apis';

type MemberSyncStatus = {
  synced: boolean;
  url: string | null;
};

export const useMemberSyncQuery = (memberId: string) => {
  return useQuery<MemberSyncStatus, MemberSyncStatus>({
    key: ['users', memberId, 'sync-status', 'useMemberSyncQuery'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users/${memberId}/sync-status`,
    },
    isEnabled:
      useHasAccountingIntegrationCapability('memberSync') && Boolean(memberId),
    options: {
      cacheTime: 60_000, // 1 min,
      staleTime: 60_000, // 1 min,
    },
  });
};
