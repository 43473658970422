import { InfoTip } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type ControlRule } from 'modules/company/members/components/ControlRules';

import { translateControlRuleName } from '../../../../utils/translateControlRuleName';

type Props = {
  controlRule: ControlRule | undefined;
};

export const MemberDetailsModalControlRule = ({ controlRule }: Props) => {
  const { t } = useTranslation('global');

  if (!controlRule) {
    return (
      <div className="text-secondary">
        {t('members.memberHasNoControlRule')}
      </div>
    );
  }

  const { completionDeadline, incompletePaymentsLimit } = controlRule;

  const tagName = translateControlRuleName(controlRule, t);

  const tooltipContent = (
    <ul>
      <li>
        {t('members.incompletePaymentsLimit', {
          count: incompletePaymentsLimit,
        })}
      </li>
      <li>
        {t('members.completionDeadline', {
          count: completionDeadline,
        })}
      </li>
    </ul>
  );

  return (
    <div className="flex items-center gap-xs text-complementary">
      {tagName}
      <InfoTip content={tooltipContent} />
    </div>
  );
};
