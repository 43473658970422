import { gql } from 'graphql-tag';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type CheckPaymentsToComplete = {
  hasPaymentsToComplete: boolean;
};

export const useCheckPaymentsToCompleteQuery =
  (): QueryState<CheckPaymentsToComplete> => {
    return useQuery<CheckPaymentsToComplete>({
      key: 'checkPaymentsToComplete',
      request: {
        type: 'graphQL',
        target: 'v1',
        query: gql`
          query CheckPaymentsToComplete($companyId: String!) {
            viewer {
              payments(
                company_id: $companyId
                first: 1
                filters: [ToComplete]
                orderBy: CREATED_DATE_ASC
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        `,
      },
      reshapeData: (data) => ({
        hasPaymentsToComplete: (data?.viewer?.payments?.edges ?? []).length > 0,
      }),
    });
  };
