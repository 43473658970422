import React from 'react';

export const CountryFlag = ({
  country,
  className,
}: {
  country: string;
  className: string;
}) => (
  <img
    className={className}
    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
    alt={`${country}-flag`}
  />
);
