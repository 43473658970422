export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FETCH_NOTIFICATIONS_REQUEST = typeof FETCH_NOTIFICATIONS_REQUEST;

export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FETCH_NOTIFICATIONS_SUCCESS = typeof FETCH_NOTIFICATIONS_SUCCESS;

export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FETCH_NOTIFICATIONS_FAILURE = typeof FETCH_NOTIFICATIONS_FAILURE;
