import { useParams } from 'react-router-dom';

import { routes, routeFor } from "src/core/constants/routes";
import history from "src/core/history";

import { usePurchaseOrderFilter } from './usePurchaseOrderFilter';
import { applyFilter, getSearchParamsFromFilter, type Filter } from '../models';

export const usePurchaseOrdersRoute = () => {
  const { company: companyId }: { company: string } = useParams();
  const currentFilter = usePurchaseOrderFilter();
  const baseRoute = routeFor(routes.PURCHASE_ORDERS.path, {
    company: companyId,
  });

  return (filterToApply?: Partial<Filter>) => {
    const filter = filterToApply
      ? applyFilter(currentFilter, filterToApply)
      : currentFilter;
    const searchParams = getSearchParamsFromFilter(filter);

    return `${baseRoute}?${searchParams.toString()}`;
  };
};

export const useGoToPurchaseOrders = () => {
  const getPurchaseOrderRoute = usePurchaseOrdersRoute();

  return (filterToApply?: Partial<Filter>) => {
    const purchaseOrderRoute = getPurchaseOrderRoute(filterToApply);
    history.push(purchaseOrderRoute);
  };
};
