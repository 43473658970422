import React, { useState } from 'react';

import { BreakdownSpendingsPageDrawer } from '../../components/BreakdownSpendingsPageDrawer';
import { BudgetOverview } from '../../components/BudgetOverview';
import { BudgetPageLoader } from '../../components/BudgetPageLoader';
import {
  type BreakdownPart,
  getBudgetLimit,
  getCommittedAmount,
  getUsedAmount,
} from '../../models/breakdown';
import { type Budget } from '../../models/budget';
import { type PeriodRangePayload } from '../../models/period';
import { useBreakdownQuery } from '../hooks/useBreakdownQuery';

type Props = {
  budget: Budget;
  periodRange: PeriodRangePayload;
};

export const BudgetOverviewContainer = ({ budget, periodRange }: Props) => {
  const breakdownQueryState = useBreakdownQuery(budget.id, periodRange);
  const [chosenBreakdownPart, setChosenBreakdownPart] =
    useState<BreakdownPart>();

  if (breakdownQueryState.status !== 'success') {
    return <BudgetPageLoader />;
  }

  const handleBreakdownPartClick = (breakdownPart: BreakdownPart) => {
    setChosenBreakdownPart(breakdownPart);
  };

  return (
    <>
      <BudgetOverview
        withHoverInteractions
        name={budget.name}
        breakdown={breakdownQueryState.data}
        amount={getBudgetLimit(breakdownQueryState.data)}
        isUnavailable={budget.isUnavailable}
        onBreakdownPartClick={handleBreakdownPartClick}
        mode="full"
      />
      {chosenBreakdownPart && (
        <BreakdownSpendingsPageDrawer
          isOpen={Boolean(chosenBreakdownPart)}
          budgetId={budget.id}
          title={budget.name}
          periodRange={periodRange}
          budgetTotalAmount={
            chosenBreakdownPart === 'committed'
              ? getCommittedAmount(breakdownQueryState.data)
              : getUsedAmount(breakdownQueryState.data)
          }
          breakdownPart={chosenBreakdownPart}
          breakdownComputedAt={breakdownQueryState.data.breakdownComputedAt}
          onCloseDrawer={() => setChosenBreakdownPart(undefined)}
        />
      )}
    </>
  );
};
