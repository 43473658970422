/* eslint-disable @typescript-eslint/no-explicit-any */
import { differenceInYears, parse, isValid, isAfter } from 'date-fns';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import trim from 'lodash/trim';

/**
 * Perform validation of each element in `values` and returns a new object containing only the keys for which the
 * `validation` did not pass, with `message` as value.
 *
 * @param {Object} values An object where keys are form field names and values are their associated form field values.
 * @param {Function} validation A validation function to execute on each element of `values`.
 * @param {String} message The error message to set as value of each element of the returned object.
 * @returns {Object} An object containing only keys for which validation did not pass and with `message` as values.
 */

export const getValidationErrors = <T>(
  values: { [key: string]: T },
  validation: (argument: T) => boolean,
  message: string,
): { [key: string]: string } =>
  reduce(
    values,
    (errors, value, key) => {
      if (!validation(value)) {
        return { ...errors, [key]: message };
      }

      return errors;
    },
    {},
  );

/**
 * Checks if value is not empty.
 *
 * @param {String} [value] A string to check for emptyness.
 * @returns {Boolean}
 */
export const isRequiredValid = (value: string): boolean =>
  size(trim(value)) > 0;

/**
 * Checks if value is a valid SSN
 *
 * @param {String} [value] A string to check for SSN validation.
 * @returns {Boolean}
 */
export const isSSNValid = (value: string): boolean =>
  /^(?!666|000|9\d{2})\d{3}-?(?!00)\d{2}-?(?!0{4})\d{4}$/.test(value);

/**
 * Checks if a date is a valid date in a given format.
 *
 * @param {String} value The value to validate.
 * @param {String} format Date format string.
 * @returns {Boolean}
 */
export const isDateValid = (value: string, format: string): boolean => {
  // https://github.com/date-fns/date-fns/issues/942
  if (value.length !== format.length) {
    return false;
  }
  const asDate = parse(value, format, new Date());
  return isValid(asDate);
};

/**
 * Checks if a date is a valid birth date.
 *
 * @param {String} date The date to validate.
 * @param {String} format Date format string.
 * @returns {Boolean}
 */
export const isValidBirthDate = (date: string, format: string): boolean => {
  if (!isDateValid(date, format)) {
    return false;
  }

  const birthDate = parse(date, format, new Date());

  if (differenceInYears(new Date(), birthDate) < 18) {
    return false;
  }

  const minDate = new Date('1900-01-01');
  return isAfter(birthDate, minDate);
};

/**
 * Checks if value is a positive number
 *
 * @param {Number} value The number to check for positiveness.
 * @returns {Boolean}
 */
const isPositiveNumberValid = (value: number): boolean =>
  Number.isFinite(value) && value > 0;

/**
 * Checks if value can represent shares amount
 * This is more strict than isPositiveNumberValid as parseFloat('10aaaaa') will be 10.0 (float)
 *
 * @param {any} value The number to check for positiveness.
 * @param {Number} min Minimum value (included)
 * @param {Number} max Maximum value (included)
 * @returns {Boolean}
 */
export const isSharesValid = (value: any, min = 0, max = 100): boolean =>
  isPositiveNumberValid(Number.parseFloat(value)) &&
  // eslint-disable-next-line security/detect-unsafe-regex
  /^\d{1,3}(\.\d+)?$/.test(value) &&
  value <= max &&
  value >= min;

/**
 * Returns the arguments to give to i18next if shares are invalid
 *
 * @param {any} value The value that fails on `isSharesValid`
 * @param {Number} min Minimum value
 * @param {Number} max Maximum value
 */
export const getInvalidSharesError = (
  value: any,
  min = 0,
  max = 100,
): string | undefined => {
  const isNumberValid =
    isPositiveNumberValid(Number.parseFloat(value)) &&
    // we don't have the same regex because we want 1000 to be out of range and not invalid
    // eslint-disable-next-line security/detect-unsafe-regex
    /^\d+(\.\d+)?$/.test(value);

  if (!isNumberValid) {
    return 'misc.valueShouldBePositive';
  }

  if (value < min) {
    return 'misc.valueShouldBeGreaterThan';
  }

  if (value > max) {
    return 'misc.valueShouldBeSmallerThan';
  }
};
/**
 * Checks if value is a valid EIN number
 *
 * @param {unknown} value The EIN number to check
 */
export const isEin = (value: unknown): boolean =>
  /^\d{2}-?\d{7}$/g.test(String(value));

export const isInUnion = <T>(union: T[], value: any): value is T =>
  union.includes(value);
