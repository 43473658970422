import { type BreakdownGaugePreparedBar } from './BreakdownGauge';
import { BreakdownGaugeLegendItem } from './BreakdownGaugeLegendItem';

export type Props = {
  preparedBars: BreakdownGaugePreparedBar[];
};

export const BreakdownGaugeLegend = ({ preparedBars }: Props) => (
  <div className="flex w-full flex-auto flex-row flex-wrap justify-between gap-xxs">
    {preparedBars.map((preparedBar, index, bars) => (
      <BreakdownGaugeLegendItem
        key={`legend-item-${preparedBar.key}`}
        preparedBar={preparedBar}
        color={preparedBar.valueLabelColor ?? undefined}
        isLastItem={index === bars.length - 1}
      />
    ))}
  </div>
);
