import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import {
  type SupportedKomboFilterDimension,
  type IntegrationId,
} from 'src/core/modules/integrations/types';

import { type DimensionOption } from './types';

type UnsupportedDimensionsByIntegration = {
  [key in IntegrationId]?: SupportedKomboFilterDimension[];
};
const integrationsWithUnsupportedDimensions: UnsupportedDimensionsByIntegration =
  {
    breathehr: ['work_location'],
    eurecia: ['legal_entity'],
    factorial: ['work_location'],
    googleworkspace: ['work_location'],
    hibob: ['cost_center', 'legal_entity'],
    officient: ['legal_entity'],
    okta: ['work_location'],
    sagehr: ['work_location', 'legal_entity'],
  };

export const isInvalidValue = (value: string[]) =>
  value.length === 0 || value.includes('');

export function getFilteredDimensionOptionsByIntegrationName(
  t: TGlobalFunctionTyped,
  integrationId: IntegrationId,
) {
  const dimensionOptions: DimensionOption[] = [
    {
      key: 'work_location',
      label: t(
        'integration.kombo.settings.filters.dimensions.workLocation.label',
      ),
    },
    {
      key: 'legal_entity',
      label: t(
        'integration.kombo.settings.filters.dimensions.legalEntity.label',
      ),
    },
    {
      key: 'cost_center',
      label: t(
        'integration.kombo.settings.filters.dimensions.costCenter.label',
      ),
    },
    {
      key: 'department',
      label: t(
        'integration.kombo.settings.filters.dimensions.department.label',
      ),
    },
    {
      key: 'team',
      label: t('integration.kombo.settings.filters.dimensions.team.label'),
    },
  ];

  const dimensionsToBeFilteredOut =
    integrationsWithUnsupportedDimensions[integrationId];

  if (dimensionsToBeFilteredOut) {
    return dimensionOptions.filter(
      (dimension) => !dimensionsToBeFilteredOut.includes(dimension.key),
    );
  }

  return dimensionOptions;
}
