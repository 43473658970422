import { type QueryState } from 'src/core/api/queryState';

import { useFetchCompanyDelegationsQuery } from './useFetchCompanyDelegationsQuery';
import { useHasWireTransferDelegationFeature } from './useHasWireTransferDelegationFeature';

export const useCompanyHasDelegationsQuery = (): QueryState<boolean> => {
  const hasWireTransferDelegationFeature =
    useHasWireTransferDelegationFeature();

  const companyDelegationsQueryState = useFetchCompanyDelegationsQuery({
    isEnabled: hasWireTransferDelegationFeature,
  });

  if (!hasWireTransferDelegationFeature) {
    return {
      status: 'success',
      data: false,
    };
  }

  if (companyDelegationsQueryState.status === 'success') {
    return {
      ...companyDelegationsQueryState,
      data: companyDelegationsQueryState.data.length > 0,
    };
  }

  return companyDelegationsQueryState;
};
