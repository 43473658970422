import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { type ControlRule } from 'modules/company/members/components/ControlRules/controlRule';
import { getControlRules } from 'modules/company/members/components/ControlRules/redux/selectors';
import { useMarkLocationAsViewedMutation } from 'modules/homepage';
import { fetchUsers as fetchUsersAction } from "src/core/actions/users";
import { type AppState } from "src/core/reducers";
import { getIsWelcomeDashboardDismissed } from "src/core/selectors/globalSelectors";

import { MembersControlRulesTab } from '../../components/MembersControlRulesTab/MembersControlRulesTab';
import { searchControlRulesWithPattern } from '../../components/MembersControlRulesTab/TableControlRules/TableControlRulesContainer';

type Props = {
  controlRules: ControlRule[];
  fetchUsers: () => void;
};

const mapDispatchToProps = {
  fetchUsers: fetchUsersAction,
};

const mapStateToProps = (state: AppState) => ({
  controlRules: getControlRules(state),
});

export const MembersControlRulesTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  // eslint-disable-next-line @typescript-eslint/no-shadow
)(({ controlRules, fetchUsers }: Props) => {
  useEffect(() => {
    fetchUsers();
  }, []);
  const [searchPattern, setSearchPattern] = useState('');
  const [markLocationAsViewed] =
    useMarkLocationAsViewedMutation('control-rules');
  const isWelcomeDashboardDismissed = useSelector(
    getIsWelcomeDashboardDismissed,
  );

  useEffect(() => {
    if (!isWelcomeDashboardDismissed) {
      markLocationAsViewed({});
    }
  }, []);

  const controlRulesCount = searchControlRulesWithPattern(
    controlRules,
    searchPattern,
  ).length;

  return (
    <ErrorBoundary
      context={{
        scope: 'members-control-rules-tab',
        team: 'finance-controller',
      }}
    >
      <MembersControlRulesTab
        setSearchPattern={setSearchPattern}
        searchPattern={searchPattern}
        controlRulesCount={controlRulesCount}
      />
    </ErrorBoundary>
  );
});
