import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import {
  switchFromXeroBookkeepImpact,
  switchFromDatevBookkeepImpact,
  switchFromFileBasedBookkeepImpact,
  switchFromXeroSettingsImpact,
  switchFromDatevSettingsImpact,
  switchFromFileBasedSettingsImpact,
  switchFromQuickbooksBookkeepImpact,
  switchFromQuickbooksSettingsImpact,
  integrationsOnEMVPFlow,
} from './helper';
import { getAccountingIntegrationDetails } from '../../../../integrations/helper';

import './AccountingBaseSwitchWarningModal.css';

export function AccountingBaseSwitchWarningModal({
  initialAccountingIntegration,
  accountingIntegration,
  isOpen,
  numberOfPayablesInExportTab,
  onClose,
  onConfirm,
}: {
  accountingIntegration: AccountingSoftware;
  initialAccountingIntegration: AccountingSoftware | 'noIntegration';
  isOpen: boolean;
  numberOfPayablesInExportTab: number;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}) {
  const { t } = useTranslation('global');

  const isDesiredIntegrationFileBased = hasIntegrationFileBasedExport(
    accountingIntegration,
  );

  const isSwitchFromAndToFileBased =
    isDesiredIntegrationFileBased &&
    (initialAccountingIntegration === 'noIntegration' ||
      hasIntegrationFileBasedExport(initialAccountingIntegration));

  const confirmButtonLabel = isDesiredIntegrationFileBased
    ? t('bookkeep.integrations.settings.baseSwitch.summaryValidation', {
        accountingIntegration: getAccountingIntegrationDetails(
          accountingIntegration,
        ).i18nSwitchTitle,
      })
    : t('misc.continue');

  const getBookkeepingImpact = () => {
    if (initialAccountingIntegration === 'Quickbooks') {
      return switchFromQuickbooksBookkeepImpact(accountingIntegration, t);
    }

    if (initialAccountingIntegration === 'Xero') {
      return switchFromXeroBookkeepImpact(accountingIntegration, t);
    }

    if (initialAccountingIntegration === 'Datev') {
      return switchFromDatevBookkeepImpact(accountingIntegration, t);
    }

    // switch from file based
    return switchFromFileBasedBookkeepImpact(accountingIntegration, t);
  };

  const getSettingsImpact = () => {
    if (initialAccountingIntegration === 'Quickbooks') {
      return switchFromQuickbooksSettingsImpact(accountingIntegration, t);
    }

    if (initialAccountingIntegration === 'Xero') {
      return switchFromXeroSettingsImpact(accountingIntegration, t);
    }

    if (initialAccountingIntegration === 'Datev') {
      return switchFromDatevSettingsImpact(accountingIntegration, t);
    }

    // switch from file based
    return switchFromFileBasedSettingsImpact(accountingIntegration, t);
  };

  const isCalloutVisible =
    integrationsOnEMVPFlow.includes(accountingIntegration) &&
    numberOfPayablesInExportTab > 0;

  return (
    <Modal
      isOpen={isOpen}
      title={t(
        getAccountingIntegrationDetails(accountingIntegration)
          .i18nSwitchWarningTitle,
      )}
      iconName="warning"
      iconVariant="warning"
      actions={[
        <Button
          key="back"
          variant="secondary"
          onClick={() => onClose()}
          text={t('misc.cancel')}
        />,
        <Button
          key="confirm"
          text={confirmButtonLabel}
          variant="warning"
          onClick={async () => {
            await onConfirm();
          }}
          iconPosition="right"
        />,
      ]}
    >
      <div>
        {isCalloutVisible && (
          <Callout
            title={t(
              'bookkeep.integrations.settings.sage100SwitchWarningCallout.title',
              { count: numberOfPayablesInExportTab },
            )}
            iconName="warning"
            variant="warning"
          >
            <div className="IntegrationInfoCallout__warning">
              {t(
                'bookkeep.integrations.settings.sage100SwitchWarningCallout.content',
              )}
            </div>
          </Callout>
        )}

        <div
          className={classNames(
            'AccountingBaseSwitchSummaryModal__content body-m',
            isCalloutVisible &&
              'AccountingBaseSwitchSummaryModal__content--with-callout',
          )}
          data-testid="accounting-base-switch-summary-modal-content-body"
        >
          <h2 className="AccountingBaseSwitchSummaryModal__title title-m">
            {isSwitchFromAndToFileBased
              ? t(
                  'bookkeep.integrations.settings.baseSwitch.summaryImpactPurchaseJournalTitle',
                )
              : t('bookkeep.integrations.settings.baseSwitch.bookkeepImpact')}
          </h2>
          <ul className="AccountingBaseSwitchSummaryModal__list">
            {getBookkeepingImpact()}
          </ul>
          <div className="separator my-s" />
          <h2 className="AccountingBaseSwitchSummaryModal__title title-m">
            {isSwitchFromAndToFileBased
              ? t(
                  'bookkeep.integrations.settings.baseSwitch.summaryImpactBankJournalTitle',
                )
              : t(
                  'bookkeep.integrations.settings.baseSwitch.accountingSettingsImpact',
                )}
          </h2>
          <ul className="AccountingBaseSwitchSummaryModal__list">
            {getSettingsImpact()}
          </ul>
        </div>
      </div>
    </Modal>
  );
}
