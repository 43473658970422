import { useMutation } from 'src/core/api/hooks/useMutation';

import { useInvalidateMemberQuery } from './useMemberQuery';

type Payload = {
  first_name?: string;
  last_name?: string;
  cost_center_id?: string | null;
};

export const useUpdateMemberProfileMutation = (
  memberId: string,
  refreshOnSuccess = true,
) => {
  const invalidateMemberQuery = useInvalidateMemberQuery();
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/users/${memberId}`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: (): void => {
        if (refreshOnSuccess) {
          invalidateMemberQuery(memberId);
        }
      },
    },
  });
};
