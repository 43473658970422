import { Button, Callout, SkeletonText, Tag } from '@dev-spendesk/grapes';
import React, { useMemo, useState } from 'react';

import { QueryError } from 'common/components/QueryError';
import { useTranslation } from 'common/hooks/useTranslation';
import { usePartnerOnboardingErrorsQuery } from 'modules/kyc/hooks/usePartnerOnboardingErrorsQuery';
import { type HubTask } from 'modules/onboarding/types/hubTask';
import { useGetProcedureQuery } from 'modules/procedures/hooks';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { BASE_PATH } from 'src/kyc/common/constants/routes';

import { MissingInformationModal } from '../MissingInformationModal';
import { StartOrContinueButton } from '../StartOrContinueButton';

type Props = {
  task: HubTask;
};

export const AdyenKycTaskContent = ({ task }: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();

  const procedureQueryState = useGetProcedureQuery({
    isEnabled: task.status === 'ongoing',
    accountType: 'kyb',
  });

  const hasSubmittedPreKyc = useMemo(() => {
    return (
      procedureQueryState.status === 'success' &&
      !!procedureQueryState.data?.state.lastSentToPartnerAt
    );
  }, [procedureQueryState]);

  const partnerOnboardingErrorQuery = usePartnerOnboardingErrorsQuery({
    isEnabled:
      procedureQueryState.status === 'success' &&
      task.status === 'ongoing' &&
      procedureQueryState.data?.state.lastSentToPartnerAt !== undefined,
  });

  const hasPartnerOnboardingErrors =
    partnerOnboardingErrorQuery.status === 'success' &&
    partnerOnboardingErrorQuery.data.partnerOnboardingErrors.length > 0;

  const [isMissingInformationModalOpen, setIsMissingInformationModalOpen] =
    useState(false);

  if (task.status === 'completed') {
    return null;
  }

  if (procedureQueryState.status === 'loading') {
    return <SkeletonText width="80%" className="mt-s" />;
  }

  if (procedureQueryState.status === 'error') {
    return (
      <QueryError
        componentType="ErrorState"
        queryError={procedureQueryState.error}
        translations={{
          networkError: 'misc.errors.networkError',
          serverError: 'misc.errors.serverError',
          requestError: () => 'misc.errors.networkError',
        }}
      />
    );
  }

  if (hasSubmittedPreKyc && !hasPartnerOnboardingErrors) {
    return (
      <div className="flex items-baseline gap-m">
        <Tag variant="neutral" iconName="clock" isRounded className="mt-m">
          {t('onboardingHub.kycTask.pendingStatus')}
        </Tag>
        <Button
          className="h-fit"
          variant="secondary"
          component="a"
          iconName="eye"
          text={t('onboardingHub.kycTask.adyen.reviewInformationCta')}
          href={`${BASE_PATH}/${company.id}`}
          target="_blank"
        />
      </div>
    );
  }

  if (hasSubmittedPreKyc && hasPartnerOnboardingErrors) {
    return (
      <>
        <Callout
          title={t('onboardingHub.kycTask.adyen.missingInformation')}
          variant="warning"
          className="mt-s"
        >
          <p>
            {t('onboardingHub.kycTask.adyen.missingInformationDescription')}
          </p>
          <Button
            className="mt-s"
            variant="warning"
            text={t('onboardingHub.kycTask.adyen.missingInformationCta')}
            onClick={() => setIsMissingInformationModalOpen(true)}
          />
        </Callout>
        {partnerOnboardingErrorQuery.status === 'success' && (
          <MissingInformationModal
            isOpen={isMissingInformationModalOpen}
            onClose={() => setIsMissingInformationModalOpen(false)}
            partnerOnboardingErrors={
              partnerOnboardingErrorQuery.data.partnerOnboardingErrors
            }
          />
        )}
      </>
    );
  }

  return (
    <div className="flex flex-row gap-s">
      <StartOrContinueButton />
    </div>
  );
};
