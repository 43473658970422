import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routes, routeFor } from "src/core/constants/routes";

import { type TransferId } from '../../models';

export const useTransferRoute = () => {
  const companyId = useCompanyId();

  return (transferId?: TransferId) =>
    transferId
      ? routeFor(routes.INVOICES_CONFIRM.path, {
          company: companyId,
          transferId,
        })
      : routeFor(routes.INVOICES_CONFIRM.path, {
          company: companyId,
        });
};
