import { Icon, colors } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { PieChart } from '../PieChart';

import './OverdueSection.css';

type Props = {
  incompletePaymentsLimit: number;
  overduePaymentsCount: number;
};

export const OverdueSection = ({
  incompletePaymentsLimit,
  overduePaymentsCount,
}: Props) => {
  const { t } = useTranslation('global');
  const isRestricted = overduePaymentsCount >= incompletePaymentsLimit;

  return (
    <div className="OverdueSection">
      <PieChart
        size={120}
        sectionsCount={incompletePaymentsLimit}
        activeSectionsCount={overduePaymentsCount}
        color={colors.alertLight}
        icon="warning"
      />
      <div className="ml-m">
        <div className="OverdueSection__info__title">
          {t('homepage.overdue.title', { count: overduePaymentsCount })}
        </div>
        <div className="OverdueSection__info__desc title-m">
          {t('homepage.overdue.desc', { count: incompletePaymentsLimit })}
        </div>
      </div>
      <div className="OverdueSection__careful">
        <div className="OverdueSection__careful__title title-m">
          {isRestricted
            ? t('homepage.overdue.restrictedTitle')
            : t('homepage.overdue.carefulTitle')}
        </div>
        <div className="mt-s flex">
          <div className="OverdueSection__careful__item">
            <div
              className={cx('OverdueSection__careful__icon', {
                'OverdueSection__careful__icon--restricted': isRestricted,
              })}
            >
              <Icon name="plastic-card" color={colors.alertLight} />
            </div>
            <div className="OverdueSection__careful__text body-m">
              {t('forms.paymentType.plasticCard')}
            </div>
          </div>
          <div className="OverdueSection__careful__item">
            <div
              className={cx('OverdueSection__careful__icon', {
                'OverdueSection__careful__icon--restricted': isRestricted,
              })}
            >
              <Icon name="single-purchase-card" color={colors.alertLight} />
            </div>
            <div className="OverdueSection__careful__text body-m">
              {t('forms.paymentType.singlePurchase')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
