import { usePhoneFactorQuery } from 'common/components/ModalMFA/hooks/usePhoneFactorQuery';
import { useFeature } from 'common/hooks/useFeature';
import { useUserScaEnrolledQuery } from 'modules/StrongCustomerAuthentication/hooks/useUserScaEnrolledQuery';
import type { Company } from 'modules/app/hooks/useCompany';
import FEATURES from 'src/core/constants/features';

type Props = {
  cardsAccess?: {
    isLoading: boolean;
    hasAccess?: boolean;
    initiator?: 'controlRuleProcess' | 'user';
  };
  company: Company;
};

export type CardAccess =
  | 'restrictedAccess'
  | 'mfaMissing'
  | 'userPhoneMissing'
  | undefined;

/**
 * Returns true when user isn't enrolled or doesn't have an active MFA
 * Returns false otherwise
 * @returns boolean
 */
const shouldRestrictedUserForMissingMFA = (
  isUserEnrolled: boolean,
  isUserScaFeatureEnabled: boolean,
  phoneFactorState: 'inactive' | 'pending' | 'active' | undefined,
) => {
  const isMFAInactiveOrUndefined = phoneFactorState !== 'active';

  return isUserScaFeatureEnabled && isMFAInactiveOrUndefined && !isUserEnrolled;
};

function shouldProvidePhoneNumber(company: Company) {
  return (
    (company.currency === 'GBP' && company.banking_provider === 'sfs') ||
    (company.currency === 'USD' && company.banking_provider === 'marqeta')
  );
}

export const useCardAccessRestricted = ({
  cardsAccess,
  company,
}: Props): CardAccess => {
  const phoneFactorQuery = usePhoneFactorQuery();
  const isUserScaFeatureEnabled = useFeature(
    FEATURES.STRONG_CUSTOMER_AUTHENTICATION,
  );

  const userScaEnrolledQuery = useUserScaEnrolledQuery();

  const isUserEnrolled =
    userScaEnrolledQuery.status === 'success' &&
    userScaEnrolledQuery.data.isScaUserEnrolled;
  const phoneFactorState =
    phoneFactorQuery.status === 'success'
      ? phoneFactorQuery.data?.state
      : undefined;

  if (
    userScaEnrolledQuery.status !== 'success' ||
    cardsAccess?.isLoading ||
    userScaEnrolledQuery.status !== 'success'
  ) {
    return undefined;
  }

  const isCardsAccessRestricted =
    cardsAccess?.hasAccess === false && cardsAccess?.initiator;

  if (isCardsAccessRestricted) {
    return 'restrictedAccess';
  }
  if (phoneFactorState !== 'active' && shouldProvidePhoneNumber(company)) {
    return 'userPhoneMissing';
  }

  const isMissingMFA = shouldRestrictedUserForMissingMFA(
    isUserEnrolled,
    isUserScaFeatureEnabled,
    phoneFactorState,
  );
  if (isMissingMFA) {
    return 'mfaMissing';
  }

  return undefined;
};
