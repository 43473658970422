import { Autocomplete, FormField, SkeletonText } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { normalizeString } from 'src/core/common/utils/normalizeString';

import { useConditionalExpenseCategoriesQuery } from './hooks/useConditionalExpenseCategoriesQuery';
import { type ExpenseCategory } from '../../expenseCategory';

type Props = {
  costCenterId: string | undefined;
  expenseCategoryId: string | undefined | null;
  isExpenseCategoryValid: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  className?: string;
  onSelectExpenseCategory(expenseCategory: { id: string; name: string }): void;
};

export const ExpenseCategoryContainer = ({
  costCenterId,
  expenseCategoryId,
  isExpenseCategoryValid,
  isRequired = true,
  isDisabled = false,
  className = '',
  onSelectExpenseCategory,
}: Props) => {
  const { t } = useTranslation();
  const [searchPattern, setSearchPattern] = useState('');
  const conditionalExpenseCategoriesQueryState =
    useConditionalExpenseCategoriesQuery(costCenterId);

  if (conditionalExpenseCategoriesQueryState.status !== 'success') {
    return (
      // 63px - height of the form field to prevent "jumping" of the block
      <div className={classNames('h-[63px]', className)}>
        <SkeletonText size="s" width="50px" />
        <SkeletonText size="xxl" width="100%" />
      </div>
    );
  }

  if (conditionalExpenseCategoriesQueryState.data.length === 0) {
    // do not display field when no expense categories retrieved
    return null;
  }

  let list = conditionalExpenseCategoriesQueryState.data;
  if (searchPattern) {
    const normalisedSearch = normalizeString(searchPattern);
    list = list.filter((item) =>
      normalizeString(item.name ?? '').includes(normalisedSearch),
    );
  }

  const label =
    expenseCategoryId &&
    getConditionalExpenseCategory(
      expenseCategoryId,
      conditionalExpenseCategoriesQueryState.data,
    )?.name;

  const value = label ? { key: expenseCategoryId, label } : undefined;

  return (
    <FormField
      className={className}
      label={t('expenseCategories.requestPanel.expenseCategory')}
      hint={isRequired ? null : t('misc.optional')}
      alertMessage={
        !isExpenseCategoryValid
          ? t('expenseCategories.requestPanel.expenseCategoryError')
          : undefined
      }
    >
      <Autocomplete
        key={
          // remount when the value change to properly switch to uncontrolled component when value is undefined and therefore should reset the input field
          value?.key
        }
        fit="parent"
        isDisabled={isDisabled}
        placeholder={t(
          'expenseCategories.requestPanel.expenseCategoryPlaceholder',
        )}
        options={list.map((conditionalExpenseCategory) => ({
          key: conditionalExpenseCategory.id,
          label: conditionalExpenseCategory.name,
        }))}
        value={value}
        onSearch={(search) => {
          setSearchPattern(search || '');
        }}
        onSelect={(option) => {
          onSelectExpenseCategory({
            id: option?.key ?? '',
            name: option?.label ?? '',
          });
        }}
        showClearSelectionButton={!isRequired}
      />
    </FormField>
  );
};

const getConditionalExpenseCategory = (
  expenseCategoryId: string,
  expenseCategories: ExpenseCategory[],
) => {
  const expenseCategory = expenseCategories.find(
    (conditionalExpenseCategory) =>
      expenseCategoryId === conditionalExpenseCategory.id,
  );
  if (expenseCategory) {
    return expenseCategory;
  }
};
