export type Member = {
  id: string;
  email: string;
  avatar: string | undefined;
  gender: string;
  fullname: string | undefined;
  isPending: boolean;
  isAccountOwner: boolean;
};

export const getMemberLabel = (member: Member): string => {
  if (member.isPending || !member.fullname?.trim()) {
    return member.email;
  }
  return member.fullname;
};
