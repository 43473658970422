import React from 'react';
import './GringottsButton.css';

interface Props {
  onToggleGringottsIframe: () => void;
}

export const GringottsButton = ({ onToggleGringottsIframe }: Props) => {
  return (
    <button
      className="GringottsButton"
      type="button"
      onClick={() => onToggleGringottsIframe()}
    >
      <span className="GringottsButton-emoji" role="img" aria-label="Gringotts">
        🏦
      </span>
    </button>
  );
};
