import { RejectPayableApi } from '@finance-review/apis/payable';
import { type InvoiceRequest } from '@finance-review/models/invoice';
import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type MutationState } from 'src/core/api/hooks/useMutation';

import {
  useRemoveInvoicesCacheEntries,
  useInvalidateInvoiceReviewListQuery,
} from '../useInvoiceReviewListQuery';

export type Payload = RejectPayableApi.Payload;
export type Response = RejectPayableApi.Response;

export const useMutation = (
  invoiceRequestId: InvoiceRequest.EntityId,
  options: {
    onSuccess?: (requestId: InvoiceRequest.EntityId) => void;
    onError?: () => void;
  } = {},
): MutationState<Payload, Response, unknown> => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const removeInvoicesCacheEntries = useRemoveInvoicesCacheEntries();
  const invalidateInvoiceListQuery = useInvalidateInvoiceReviewListQuery();

  return RejectPayableApi.useMutation(invoiceRequestId, {
    onSuccess: async (requestId) => {
      pushNotif({
        type: NotificationType.Success,
        message: t('notifications.paymentValidation.reject.invoice.success'),
      });
      removeInvoicesCacheEntries([requestId]);
      invalidateInvoiceListQuery();
      options.onSuccess?.(requestId);
    },
    onError: options.onError,
  });
};
